import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  BasicUserInfo,
  CategoryBehavior,
} from "../../../../../../store/onboarding/types";
import {
  BehaviorTally,
  Fba,
  GoalTypes,
  InterventionGroup,
  InterventionGroupRequest,
  StudentGoalOptions,
} from "../../../../../../store/onboarding/cases/types";
import { getBehaviorTallyName } from "../abc-utils";
import { useHypothesis } from "../hooks";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { Form, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import ReactDatePicker from "react-datepicker";
import CustomDateInput from "../../../../../common/onboarding/third-step/set-goal-modal/common/CustomDateInput";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedFba,
  changeSelectedInterventionGroup,
  createInterventionGroup,
  openSetGoalChooseConcernModal,
  updateFba,
} from "../../../../../../store/onboarding/cases/actions";
import { ApplicationState } from "../../../../../../store";
import { toastr } from "react-redux-toastr";

type OwnProps = {
  fba: Fba;
  student?: BasicUserInfo;
  problemBehavior: BehaviorTally | undefined;
  selectedAntecedent:
    | { abcId: number; antecedents: number[]; other_antecedents?: string[] }
    | undefined;
  selectedConsequence:
    | { abcId: number; consequence: number; other_sequence?: string }
    | undefined;
  replacementBehavior: CategoryBehavior | undefined;
  defaultValues:
    | Pick<
        StudentGoalOptions,
        "context" | "desiredChange" | "measuredBy" | "targetDate"
      >
    | undefined;
};

type Props = OwnProps;

const SetGoalBody: FunctionComponent<Props> = (props) => {
  const {
    fba,
    student,
    problemBehavior,
    selectedAntecedent,
    selectedConsequence,
    replacementBehavior,
    defaultValues,
  } = props;

  const dispatch = useDispatch();

  const problem = useMemo(() => {
    return problemBehavior ? getBehaviorTallyName(problemBehavior) : undefined;
  }, []);

  const { hypothesis } = useHypothesis({
    studentName: getFullName(student),
    behTally: problemBehavior!,
    selectedAntecedent,
    selectedConsequence,
  });

  const [context, setContext] = useState<string>("");
  const [desiredChange, setDesiredChange] = useState<string>("");
  const [measuredBy, setMeasuredBy] = useState<string>("");
  const [targetDate, setTargetDate] = useState<Date | undefined>();

  useEffect(() => {
    if (defaultValues) {
      setContext(defaultValues.context);
      setDesiredChange(defaultValues.desiredChange);
      setMeasuredBy(defaultValues.measuredBy);
      setTargetDate(defaultValues.targetDate);
    }
  }, [defaultValues]);

  const showSetGoalModal = (interventionGroup: InterventionGroup) => {
    dispatch(
      openSetGoalChooseConcernModal(interventionGroup, undefined, {
        fbaId: fba.id,
        goalStatement: goalStatementText,

        problemBehavior: problemBehavior,
        selectedAntecedent: selectedAntecedent,
        selectedConsequence: selectedConsequence,
        replacementBehavior: replacementBehavior,

        context: context,
        desiredChange: desiredChange,
        measuredBy: measuredBy,
        targetDate: targetDate,
      })
    );
  };

  const createInterventionGroupLoading = useSelector(
    (s: ApplicationState) => s.cases.isLoading.createInterventionGroup
  );
  const updateFbaLoading = useSelector(
    (s: ApplicationState) => s.cases.isLoading.updateFba
  );

  const goalStatementText = useMemo(() => {
    return `When ${student?.first_name} is ${context ||
      `<Context>`}, he/she will ${desiredChange ||
      `<Desired Change>`} ${measuredBy || `<Quantify the Change>`} by ${
      targetDate ? moment(targetDate).format("MMMM Do, YYYY") : `<Date>`
    }.`;
  }, [student?.first_name, context, desiredChange, measuredBy, targetDate]);

  const teachers = useSelector(
    (s: ApplicationState) => s.onboarding.teachersRoster
  );

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!targetDate) {
      datePickerRef.current?.setOpen(true);
      return;
    }

    if (fba.intervention_group) {
      showSetGoalModal(fba.intervention_group);
    } else {
      const group: InterventionGroupRequest = {
        teacher_assignment: teachers.find((t) => t.user.id === fba.teacher.id)
          ?.user.profile.current_assignment?.id,
        students: [fba.student.id!],
        goal_type: GoalTypes.BEHAVIOR,
        name: `${fba.student.first_name} ${fba.student.last_name} - Behavior`,
        finished: true,
      };
      (dispatch(createInterventionGroup(group, false, true)) as any).then(
        (group: InterventionGroup) => {
          (dispatch(
            updateFba({
              ...fba,
              intervention_group: group,
              replacement_behavior: replacementBehavior?.id,
            })
          ) as any).then(
            (fba: Fba) => {
              showSetGoalModal(fba.intervention_group ?? group);
              dispatch(changeSelectedFba(fba));
            },
            (err: string) => {
              toastr.error("Failed to update the FBA", err);
            }
          );
        },
        (err: string) => {
          toastr.error("Failed to create intervention group", err);
        }
      );
    }
  };

  const contextRef = useRef<HTMLInputElement>(null);
  const desiredChangeRef = useRef<HTMLInputElement>(null);
  const measuredByRef = useRef<HTMLInputElement>(null);
  const datePickerRef = useRef<ReactDatePicker>(null);

  const goalStatement = useMemo(() => {
    return (
      <>
        When {student?.first_name} is{" "}
        <span className="goalTag" onClick={() => contextRef.current?.focus()}>
          {context || <i>Context</i>}
        </span>
        , he/she will{" "}
        <span
          className="goalTag"
          onClick={() => desiredChangeRef.current?.focus()}
        >
          {desiredChange || <i>Desired Change</i>}
        </span>{" "}
        <span
          className="goalTag"
          onClick={() => measuredByRef.current?.focus()}
        >
          {measuredBy || <i>Quantify the Change</i>}
        </span>{" "}
        by{" "}
        <span
          className="goalTag"
          onClick={() => datePickerRef.current?.setOpen(true)}
        >
          {targetDate ? (
            moment(targetDate).format("MMMM Do, YYYY")
          ) : (
            <i>Date</i>
          )}
        </span>
        .
      </>
    );
  }, [student?.first_name, context, desiredChange, measuredBy, targetDate]);

  return (
    <div>
      <div className="smart-goal-container">
        <div>
          <h3 className="text-center font-weight-bold">
            Develop a SMART goal for {student?.first_name}
          </h3>
        </div>
        <div className="d-flex">
          <div>
            <h3 className="font-weight-bold mb-2">Problem Behaviors</h3>

            <div className="behaviorsArea">
              <div className="fileCell">
                <div className="fileCellName">{problem}</div>
              </div>
            </div>

            <div className="mt-3">
              <h3 className="text-center font-weight-bold">Your hypothesis:</h3>
              <p className="text-center">{hypothesis}</p>
            </div>
          </div>
          <div>
            <h3 className="font-weight-bold">A SMART goal is:</h3>
            <h3 className="font-weight-bold">
              Specific, Measurable, Achievable, Relevant, & Time-bound
            </h3>

            <p className="m-3 font-italic">
              For example: When Lillie is in small groups, she will raise her
              hand instead of shouting out 2 times per class by the end of next
              month.
            </p>

            <h3 className="font-weight-bold mb-3">
              Fill in the blanks to create SMART goal for {student?.first_name}:
            </h3>

            <Form id="smart-form" onSubmit={handleFormSubmit}>
              <Form.Group>
                <Form.Label>When {student?.first_name} is...</Form.Label>
                <Form.Control
                  ref={contextRef}
                  type="text"
                  required
                  placeholder="describe the context (time, location, circumstance)..."
                  value={context}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setContext(e.target.value)
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>He/she will...</Form.Label>
                <Form.Control
                  ref={desiredChangeRef}
                  type="text"
                  required
                  placeholder="describe the desired change in their behavior..."
                  value={desiredChange}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setDesiredChange(e.target.value)
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Measured by...</Form.Label>
                <Form.Control
                  ref={measuredByRef}
                  type="text"
                  required
                  placeholder="quantify the change (ex. 2 times per class)"
                  value={measuredBy}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setMeasuredBy(e.target.value)
                  }
                />
              </Form.Group>

              <div>
                <h4 className="mb-2">To be completed by...</h4>

                <DatePicker
                  ref={datePickerRef}
                  customInput={<CustomDateInput />}
                  selected={targetDate}
                  minDate={new Date()}
                  onChange={(date: Date) => setTargetDate(date)}
                />
              </div>
            </Form>
          </div>
        </div>
      </div>

      <div className="my-3">
        <h3 className="font-weight-bold text-center">
          {student?.first_name}'s Goal:
        </h3>

        <p className="text-center">{goalStatement}</p>
      </div>

      <div className="text-center">
        <button className="blueBtnSm" type="submit" form="smart-form">
          Choose How to Observe Progress
          {(createInterventionGroupLoading || updateFbaLoading) && (
            <Spinner animation="border" size="sm" className="ml-1" />
          )}
        </button>
      </div>
    </div>
  );
};

export default SetGoalBody;
