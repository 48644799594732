import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faBell,
  faCalendar,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {Form, Badge, OverlayTrigger, Popover } from "react-bootstrap";
import { bindActionCreators, Dispatch } from "redux";
import {
  getNotifications,
  markAsReadNotification,
} from "../../store/notifications/actions";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { Notification } from "../../store/notifications/types";
import LoadingIndicator from "./onboarding/LoadingIndicator";
import UserAvatarSmall from "./UserAvatarSmall";
import {
  formatDate,
  getDifferenceWithCurrentDate,
} from "../utils/DateTimeUtils";
import { useIntl } from "react-intl";

type PropsFromState = {
  notifications: Array<Notification>;
  isLoading: {
    getNotifications: boolean;
    markAsReadNotification: boolean;
  };
  errors: {
    getNotifications?: string;
    markAsReadNotification?: string;
  };
};

type DispatchProps = {
  getNotifications: () => any;
  markAsReadNotification: (notificationIds: Array<number>) => any;
};

type Props = PropsFromState & DispatchProps;

const INTERVAL = 60000;

const NotificationsDropDown: FunctionComponent<Props> = ({
  isLoading,
  notifications,
  getNotifications,
  markAsReadNotification,
}) => {
  const intl = useIntl();
  const [newestToOldest, setNewestToOldest] = useState(true);
  const [notificationList, setNotificationList] = useState<Notification[]>([]);

  useEffect(() => {
    getNotifications();
    const interval = setInterval(() => {
      getNotifications();
    }, INTERVAL);
    return () => clearInterval(interval);

  }, []);

  useEffect(()=>{
    const notificationList = [...notifications].sort((a:any,b:any)=> newestToOldest ? (b.id - a.id) : (a.id - b.id))
    setNotificationList(notificationList);
  }, [notifications, newestToOldest])
  
  const handleAllNotificationsMarkAsRead = () => {
    const unreadNotificationIds = notificationList
    .filter((n) => !n.read)
    .map((n) => n.id);
    if (unreadNotificationIds.length) {
      markAsReadNotification(unreadNotificationIds);
    }
  };
  
  const handleNotificationMarkAsRead = (notificationId: number) => {
    markAsReadNotification([notificationId]);
  };

  const onToggleFun = (newestToOldest:boolean) => {
    setNewestToOldest(newestToOldest)
  }
  
  const popover = (
    <Popover id="popover-notifications" style={{ width: 600 }}>
      <Popover.Title className="d-flex align-items-center justify-content-between">
        <h4 className="font-weight-bold">
          {intl.formatMessage({
            id: "app.dashboard.notifications.title",
          })}
        </h4>
        <span className="d-flex">
          <a className="btn" onClick={() => onToggleFun(!newestToOldest)}>
            {newestToOldest ? <span>Newest <FontAwesomeIcon icon={faArrowDown} /></span> : <span>Oldest <FontAwesomeIcon icon={faArrowDown} /></span>}</a>
          {/* <span className="pointer mr-1" onClick={()=> newestToOldest && document.getElementById('sortSwitch')?.click()}>Oldest</span> 
            <Form.Check 
              type="switch"
              className="pointer"
              id="sortSwitch"
              aria-label="option 1"
              checked={newestToOldest ?? false}
              onChange={(e) => {
                const checked = e.target.checked;
                setNewestToOldest(checked);
              }}
            />
          <span className="pointer" onClick={()=> !newestToOldest && document.getElementById('sortSwitch')?.click()}>Newest</span> */}
        </span>
        <span
          className="blueTitle pointer"
          onClick={handleAllNotificationsMarkAsRead}
          >
          {intl.formatMessage({
            id: "app.dashboard.notifications.markAsRead.label",
          })}
        </span>
      </Popover.Title>
      <Popover.Content className="notificationsContainer">
        { !notificationList.length && isLoading.getNotifications ? (
          <LoadingIndicator />
        ) : (notificationList.length > 0) ? (
          <div>
            {notificationList.map((notification) => (
              <div
                key={`notification_${notification.id}`}
                className={`d-flex align-items-center ${
                  notification.read ? "" : "unread"
                }`}
                onClick={() =>
                  !notification.read &&
                  handleNotificationMarkAsRead(notification.id)
                }
              >
                {notification.from_user && (
                  <div className="mr-3">
                    <UserAvatarSmall
                      size={60}
                      userInfo={notification.from_user}
                    />
                  </div>
                )}
                <div>
                  <p>{notification.body}</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-3">
                      {notification.email_sent_at ? (
                        <div>
                          <span className="mr-2">
                            <FontAwesomeIcon icon={faEnvelope} />
                          </span>
                          {getDifferenceWithCurrentDate(
                            notification.email_sent_at
                          )}
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                    {notification.scheduled_date && (
                      <div>
                        <span className="mr-2">
                          <FontAwesomeIcon icon={faCalendar} />
                        </span>
                        {formatDate(notification.scheduled_date)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="mx-3 my-2">
            <i>
              {intl.formatMessage({
                id: "app.dashboard.notifications.noNotificationMessage",
              })}
            </i>
          </div>
        )}
      </Popover.Content>
    </Popover>
  );

  const unreadMessagesCount = useMemo(
    () =>
      notificationList.reduce((pV: number, cV: Notification) => {
        if (!cV.read) {
          return pV + 1;
        }
        return pV;
      }, 0),
    [notificationList]
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={popover}
      onEnter={() => {
        getNotifications();
      }}
      rootClose
    >
      <div className="pointer ml-4">
        <FontAwesomeIcon icon={faBell} />
        {!!unreadMessagesCount && (
          <Badge variant="danger">{unreadMessagesCount}</Badge>
        )}
      </div>
    </OverlayTrigger>
  );
};

const mapStateToProps = ({
  notifications,
}: ApplicationState): PropsFromState => {
  return {
    notifications: notifications.notifications,
    // notifications: [
    //   {
    //     id: 1,
    //     is_active: true,
    //     read: false,
    //     body: "hi!",
    //     from_user: auth.userInfo!,
    //     to_user: auth.userInfo,
    //     email_sent_at: "2020-03-05T08:02:14.015976Z",
    //     scheduled_date: "2020-03-05T08:02:14.015976Z"
    //   },
    //   {
    //     id: 2,
    //     is_active: true,
    //     read: false,
    //     body: "hi!",
    //     from_user: auth.userInfo!,
    //     to_user: auth.userInfo,
    //     email_sent_at: "2020-03-05T08:02:14.015976Z",
    //     scheduled_date: "2020-03-05T08:02:14.015976Z"
    //   }
    // ],
    isLoading: {
      getNotifications: notifications.isLoading.getNotifications,
      markAsReadNotification: notifications.isLoading.markAsReadNotification,
    },
    errors: {
      getNotifications: notifications.errors.getNotifications,
      markAsReadNotification: notifications.errors.markAsReadNotification,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getNotifications: getNotifications,
      markAsReadNotification: markAsReadNotification,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsDropDown);
