import {
  UserAssignmentRole,
  UserAssignmentRoleDisplayedNames,
  UserInfo,
} from "../store/auth/types";
import {
  InviteStatusesDisplayedNames,
  Measurement,
  MeasurementType,
  Student,
  StudentInfo,
  TeacherInfo,
} from "../store/onboarding/types";

export const getFullName = (
  userInfo: UserInfo | StudentInfo | Student | undefined
) => (userInfo ? `${userInfo.first_name} ${userInfo.last_name}` : "");

export const getFirstName = (
  userInfo: UserInfo | StudentInfo | Student | undefined
) => (userInfo ? `${userInfo.first_name}` : "");

export const getLoggedBy = (
  user: number | UserInfo | undefined,
  myId?: number
) => {
  if (user !== undefined) {
    if (typeof user === "number") {
      return user === myId ? "Me" : "Other User";
    } else {
      return getFullName(user);
    }
  }
  return "Other User";
};

export const getInviteStatusDisplay = (teacher: TeacherInfo): string => {
  if (teacher?.invite) {
    return InviteStatusesDisplayedNames[teacher.invite.status];
  } else {
    return "";
  }
};

export const getUserAssigmentRoleDisplayedName = (
  role?: UserAssignmentRole
) => {
  return role ? UserAssignmentRoleDisplayedNames[role] : "";
};

export const getTitleByMeasurement = (
  value: string | number,
  measurement?: Measurement
) => {
  if (!measurement || measurement.type !== MeasurementType.CATEGORY) {
    return value;
  }
  return (
    measurement.possible_values?.find((pv) => pv.order == value)
      ?.display_name ?? value
  );
};

export const generateInterventionAbbrev = (name: string) => {
  return name
    .split(" ")
    .map((word: string) => (word.startsWith("(") ? null : word[0]))
    .reduce((pV, cV) => {
      return cV ? pV + cV : pV;
    }, "");
};

export const generateAbbrev = (word: string) => {
  return word.match(/\b([A-z/-])|([A-Z])+/gm)?.join("");
};
