import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { getTitleByMeasurement } from "../../../../../../utils/NamesUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {
  LoggedProgress,
  StudentGoalProgress,
} from "../../../../../../store/onboarding/meeting-module/types";
import {
  InterventionGroup,
  StudentGoal,
} from "../../../../../../store/onboarding/cases/types";
import DatePicker from "react-datepicker";
import CustomDateBoxInput from "../common/CustomDateBoxInput";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import GoalInputGroup from "../goal-progress/GoalInputGroup";
import { connect, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { updateProgressTowardsGoal } from "../../../../../../store/onboarding/meeting-module/actions";
import { Spinner } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { dateToFormatStringISO8601 } from "../../../../../utils/DateTimeUtils";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";
import { useDispatch } from "react-redux";
import { deleteLoggedProgress } from "../../../../../../store/onboarding/cases/actions";
import { ApplicationState } from "../../../../../../store";
import useInterventionGroupAccess from "../../../../../../utils/hooks/useInterventionGroupAccess";

type OwnProps = {
  hideActions?: boolean;
  lp: LoggedProgress;
  sgp: StudentGoalProgress;
  interventionGroup: InterventionGroup;
  isStudentDetail?: boolean;
  isGroupDetail?: boolean;
  innerIndex?: number;
  fromInterventionFidelityChart?: boolean;
};

type DispatchProps = {
  updateProgressTowardsGoal: (
    loggedProgressId: number,
    loggedProgress: Partial<LoggedProgress>
  ) => any;
  deleteLoggedProgress?: any;
};

type Props = OwnProps & DispatchProps;

const LoggedProgressRow: FunctionComponent<Props> = ({
  hideActions,
  lp,
  sgp,
  interventionGroup,
  updateProgressTowardsGoal,
  deleteLoggedProgress,
  isStudentDetail,
  isGroupDetail,
  innerIndex,
  fromInterventionFidelityChart
}) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [observedDate, setObservedDate] = useState<Date | undefined>(undefined);
  const [studentGoal, setStudentGoal] = useState<
    (StudentGoal & LoggedProgress) | undefined
  >(undefined);

  const dispatch = useDispatch();

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(interventionGroup?.id);

  const handleShow = (id: any) => {
    dispatch(
      showConfirmDialog({
        centered: true,
        onConfirm: () => {
          deleteLoggedProgress(id);
        },
      })
    );
  };

  useEffect(() => {
    if (editing) {
      setObservedDate(moment(lp.date).toDate());
      const goal = interventionGroup.student_goals?.find(
        (sg) => sg.id === sgp.student_goal_id
      );
      if (goal) {
        setStudentGoal({ ...goal, ...lp, goal_id: sgp.student_goal_id });
      }
    }
  }, [editing]);

  const handleGoalLogChange = (event: React.ChangeEvent<any>) => {
    const { value } = event.target as HTMLInputElement;
    setStudentGoal((s) => (s ? { ...s, value: value } : undefined));
  };

  const handleUpdateLoggedProgress = () => {
    if (studentGoal) {
      setLoading(true);
      updateProgressTowardsGoal(studentGoal.id, {
        value: studentGoal.value,
        date: observedDate && dateToFormatStringISO8601(observedDate),
      }).then(
        () => {
          setEditing(false);
          setLoading(false);
        },
        (err: string) => {
          toastr.error("Failed to update the progress", err);
          setLoading(false);
        }
      );
    }
  };

  return editing ? (
    <tr>
      <td colSpan={3} className="text-nowrap">
        <div className="flex-wrap-container">
          <DatePicker
            maxDate={new Date()}
            customInput={<CustomDateBoxInput />}
            selected={observedDate}
            onChange={(date: Date) => setObservedDate(date)}
          />
          {studentGoal && (
            <div style={{ minWidth: "8.125rem" }}>
              <GoalInputGroup
                measurement={studentGoal.measurement}
                value={studentGoal.value}
                onChange={handleGoalLogChange}
              />
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center">
          <button
            className="whiteBtnSm"
            onClick={() => setEditing(false)}
          >
            Cancel
          </button>
          <button
            className="blueBtnSm ml-3 d-flex align-items-center"
            onClick={handleUpdateLoggedProgress}
          >
            Save
            {loading && (
              <Spinner animation="border" size="sm" className="ml-3" />
            )}
          </button>
          </div>
        </div>
      </td>
    </tr>
  ) : (
    <tr>
      <td className={`text-right ${innerIndex == 0 ? 'border-0' : ''} w-100`}>{moment.utc(lp.date).format("MM/DD/YYYY")}</td>
      <td className={`text-right ${innerIndex == 0 ? 'border-0' : ''}`}>{getTitleByMeasurement(lp.value, sgp.measurement)}</td>
      <td className={`text-center ${innerIndex == 0 ? 'border-0' : ''}`}>
        {!hideActions 
        && !isStudentDetail 
        && !isGroupDetail 
        && !isIntGrpIDsWithReadAccess 
        && !fromInterventionFidelityChart
        && (
          <>
            <span className="pointer mr-1" onClick={() => setEditing(true)}>
              <FontAwesomeIcon icon={faEdit} size="1x" className="purpleText" />
            </span>
            <span className="pointer" onClick={() => handleShow(lp?.id)}>
              <FontAwesomeIcon
                icon={faTrashAlt}
                size="1x"
                className="purpleText"
              />
            </span>
          </>
        )}
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      updateProgressTowardsGoal: updateProgressTowardsGoal,
      deleteLoggedProgress: deleteLoggedProgress,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(LoggedProgressRow);
