import React, { FunctionComponent, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { toastr } from "react-redux-toastr";
import LoadingIndicator from "../../common/onboarding/LoadingIndicator";
import {
  changeSelectedSpellingTest,
  getSpellingTest,
  getSpellingTestGroup,
} from "../../../store/spelling-tests/actions";
import SpellingTestUpsertPage from "./spelling-test-upsert-form/SpellingTestUpsertPage";

type Props = {};

const SpellingTestGroup: FunctionComponent<Props> = (props) => {
  const { testId, testGroupId, subPage } = useParams<{
    testId?: string;
    testGroupId?: string;
    subPage: string;
  }>();

  const dispatch = useDispatch();
  const history = useHistory();

  const isLoading = useSelector(
    (s: ApplicationState) =>
      s.spellingTests.isLoading.getSpellingTestGroup ||
      s.spellingTests.isLoading.getSpellingTest
  );

  const pStudent = useSelector(
    (s: ApplicationState) => s.spellingTests.selectedStudent
  );

  useEffect(() => {
    if (subPage === "new-test") {
      if (testGroupId) {
        if (!pStudent) {
          history.push("/spelling-test/groups");
        } else {
          dispatch(changeSelectedSpellingTest(undefined));
          (dispatch(getSpellingTestGroup(Number(testGroupId))) as any).then(
            null,
            (err: string) => {
              toastr.error("Failed to get test group", err);
              history.push("/spelling-test/groups");
            }
          );
        }
      }
    } else {
      if (testId) {
        (dispatch(getSpellingTest(Number(testId))) as any).then(
          null,
          (err: string) => {
            toastr.error("Failed to get spelling test", err);
            history.push("/spelling-test/groups");
          }
        );
      }
    }
  }, [subPage]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return <SpellingTestUpsertPage />;
};

export default SpellingTestGroup;
