import jsPDF from "jspdf";
// @ts-ignore
import html2pdf from "html2pdf.js";

const usePDFGenerate = () => {
  const reportGenerate = (contentRef:React.RefObject<HTMLDivElement | null>, pdfFileName: string, pageName?: string):void => {
      if (contentRef.current) {
        //console.log(contentRef.current)
          const doc = new jsPDF("p", "pt", "letter", false);
          // will save the file in the current working directory
          const opt: any = {
           margin: [8, 8, 8, 8],
            //autoPaging: 'text',
            enableLinks: false,
            pagebreak: {
                avoid: ["div"],
               mode: ["css", "legacy"]
            },
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
                allowTaint: true,
                dpi: 300,
                letterRendering: true,
                logging: false,
                scale: pageName == 'equityBenchmarks' ? .55 : .64,
                scrollX: 0,
                scrollY: 0,
            },
        };
          doc.html(contentRef.current, opt).then(() => doc.save(pdfFileName));
          
        //   const opt = {
        //       margin: [8, 8, 8, 8],
        //       filename: pdfFileName,
        //       enableLinks: false,
        //       pagebreak: {
        //           avoid: ["div"],
        //           mode: ["css", "legacy"]
        //         },
        //         image: { type: "jpeg", quality: 1 },
        //         html2canvas: {
        //             allowTaint: true,
        //             dpi: 96,
        //             letterRendering: true,
        //             logging: false,
        //             scale: 4,
        //             scrollX: 0,
        //             scrollY: 0,
        //         },
        //     };
        //   html2pdf()
        //   .from(contentRef.current)
        //   .set(opt)
        //   .toPdf()
        //   .get("pdf")
        //   .save();
      }
  };

  return {
      reportGenerate
  }
}

export default usePDFGenerate;