import React, { FunctionComponent, useMemo } from "react";
import { IS_READY_COACH } from "../../constants";
import LanguageSelector from "./localization/LanguageSelector";
import { Link } from "react-router-dom";

const Footer:  FunctionComponent = () => {

  const currentYear = useMemo(() => {
    let currentDate = new Date(); 
    let currentYearFromDate = currentDate. getFullYear(); 
    return currentYearFromDate;
  },[]);
  
  return (IS_READY_COACH ? (
    <footer
      className="footer"
      
    >
      <div className="row">
        <div className="col-3">
      <img
        style={{
          width: 200,
          height: "auto",
        }}
        src="/media/readycoach-logo.png"
        alt="ReadyCoach"
      />

        </div>
        <div className="col-7 text-center">
          {/* <LanguageSelector /> */}
          <a className="ml-3 localeBtn" href="/app/privacy">
            <span>Privacy Policy</span>
          </a>
          <a className="ml-3 localeBtn" href="/app/user-agreement">
            <span>User Agreement</span>
          </a>
          
        </div>
        <div className="col-2 text-right">
          
      <div>Copyright © {currentYear}</div>
        </div>
      </div>
    </footer>
  ) : null);
}

export default Footer;
