import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import {
  changeSetGoalsModal,
  createStudentGoalForStudent,
  getAssessmentStaticData,
  getMeasurementStaticData,
} from "../../../../store/onboarding/cases/actions";
import { OverlayTrigger, Spinner, Tab, Tooltip } from "react-bootstrap";
import SetGoalsBehaviors from "./set-goals-behaviors/SetGoalsBehaviors";
import SetGoalsMeasure from "./set-goals-measure/SetGoalsMeasure";
import {
  Assessment,
  EvidenceAssessment,
  KnownAssessment,
  Measurement,
  Student,
  StudentEntry,
} from "../../../../store/onboarding/types";
import { getTargetBehaviorStudentEntries } from "../../../../utils/Selectors";
import {
  CasesState,
  ConcernArea,
  ObservationTypes,
  ObservationTypesDisplayedNames,
  StaticMeasurementTypes,
  StaticMeasurementTypesDisplayedNames,
  StudentGoalForGroup,
} from "../../../../store/onboarding/cases/types";
import { dateToFormatString } from "../../../utils/DateTimeUtils";
import {
  getDefaultProgressMonitoring,
  getEvidenceAssessments,
} from "../../../../store/onboarding/actions";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import { EventKey } from "react-bootstrap/esm/types";

enum SetGoalModalStep {
  BEHAVIORS = "behaviors",
  MEASURE = "measure",
}

export enum BehaviorsMeasureType {
  RC_INDICATORS,
  OBSERVATION,
  BEHAVIOR_RATING_SCALES,
}

export type GoalsData = {
  behaviorsMeasureType: BehaviorsMeasureType | null;
  student: Student;
  targetDate: Date | null;
  goal_statement: string | null;
  targetValue: string | null;
  measurementType: StaticMeasurementTypes | null;
  observationType: ObservationTypes | null;
  brsMeasurement: Measurement | null;
  currentBaseline: string | null;
  isNew?: boolean;
};

const NewSetGoalsModal = () => {
  const {
    staticData,
    selectedStudentGoal,
    selectedInterventionGroup,
    modalsState: { setGoalsModal: showModal },
  } = useSelector<ApplicationState, CasesState>((s) => s.cases);

  const students = useMemo(() => {
    if (selectedInterventionGroup) {
      return selectedInterventionGroup.students.filter(
        (st) =>
          !selectedInterventionGroup.student_goals.some(
            (sg) => sg.student.id === st.id
          )
      );
    }
    return [];
  }, [selectedInterventionGroup]);

  const evidenceAssessments = useSelector<
    ApplicationState,
    EvidenceAssessment[]
  >((s) => s.onboarding.evidenceAssessments);

  const isLoading = useSelector<ApplicationState, boolean>(
    (s) =>
      s.cases.isLoading.createStudentGoalForInterventionGroup ||
      s.cases.isLoading.createStudentGoalForStudent
  );

  const studentEntries = useSelector<ApplicationState, StudentEntry[]>(
    getTargetBehaviorStudentEntries
  );

  const defaultProgressMonitoring = useSelector<
    ApplicationState,
    Measurement | undefined
  >((s) => s.onboarding.defaultProgressMonitoring);

  const getGoalsDataForStudents = (): GoalsData[] => {
    if (selectedStudentGoal == null) {
      return students.map((st) => ({
        behaviorsMeasureType: null,
        student: st,
        targetDate: null,
        goal_statement: null,
        targetValue: null,
        measurementType: null,
        observationType: null,
        brsMeasurement: null,
        currentBaseline: null,
        isNew: true,
      }));
    } else {
      const { student, measurement, target_value } = selectedStudentGoal;

      if (!measurement) {
        return [
          {
            behaviorsMeasureType: null,
            student: student,
            targetDate: moment(selectedStudentGoal.target_date).toDate(),
            goal_statement: selectedStudentGoal.goal_statement,
            targetValue: selectedStudentGoal.target_value,
            measurementType: null,
            observationType: null,
            brsMeasurement: null,
            currentBaseline: null,
          },
        ];
      }

      const measurements: Measurement[] = evidenceAssessments.reduce(
        (pV, cV) => [...pV, ...cV.measurements],
        [] as Measurement[]
      );

      if (
        measurement.display_name === "Indicator" &&
        measurement.known_assessment === KnownAssessment.ReadyCoach_Indicator &&
        (target_value == null || target_value.length === 0)
      ) {
        //RC_INDICATORS
        return [
          {
            behaviorsMeasureType: BehaviorsMeasureType.RC_INDICATORS,
            student: student,
            targetDate: moment(selectedStudentGoal.target_date).toDate(),
            goal_statement: selectedStudentGoal.goal_statement,
            targetValue: null,
            measurementType: null,
            observationType: null,
            brsMeasurement: null,
            currentBaseline: null,
          },
        ];
      }

      const smtEntries = Object.entries(StaticMeasurementTypesDisplayedNames);
      const measurementType = Number(
        smtEntries.find(([, value]) =>
          value.includes(measurement.display_name)
        )?.[0]
      );
      let observationType: ObservationTypes | null = null;

      if (StaticMeasurementTypes.DURATION === measurementType) {
        observationType = ObservationTypes.DURATION;
      }
      if (StaticMeasurementTypes.LATENCY === measurementType) {
        observationType = ObservationTypes.LATENCY;
      }
      if (
        [
          StaticMeasurementTypes.TOTAL_COUNT,
          StaticMeasurementTypes.RATE,
        ].includes(measurementType)
      ) {
        observationType = ObservationTypes.FREQUENCY_OR_EVENT;
      }
      if (
        [
          StaticMeasurementTypes.PARTIAL_INTERVAL_RECORDING,
          StaticMeasurementTypes.FULL_INTERVAL_RECORDING,
          StaticMeasurementTypes.MOMENTARY_TIME_SAMPLING,
        ].includes(measurementType)
      ) {
        observationType = ObservationTypes.INTERVAL;
      }

      if (measurementType && observationType) {
        //OBSERVATIONS
        return [
          {
            behaviorsMeasureType: BehaviorsMeasureType.OBSERVATION,
            student: student,
            targetDate: moment(selectedStudentGoal.target_date).toDate(),
            goal_statement: selectedStudentGoal.goal_statement,
            targetValue: selectedStudentGoal.target_value,
            measurementType: measurementType,
            observationType: observationType,
            brsMeasurement: null,
            currentBaseline: null,
          },
        ];
      }

      const brsMeasurement = measurements.find((m) => m.id === measurement.id);
      if (brsMeasurement) {
        //BRS
        return [
          {
            behaviorsMeasureType: BehaviorsMeasureType.BEHAVIOR_RATING_SCALES,
            student: student,
            targetDate: moment(selectedStudentGoal.target_date).toDate(),
            goal_statement: selectedStudentGoal.goal_statement,
            targetValue: selectedStudentGoal.target_value,
            measurementType: null,
            observationType: null,
            brsMeasurement: brsMeasurement,
            currentBaseline: null,
          },
        ];
      }

      return [
        {
          behaviorsMeasureType: null,
          student: student,
          targetDate: moment(selectedStudentGoal.target_date).toDate(),
          goal_statement: selectedStudentGoal.goal_statement,
          targetValue: selectedStudentGoal.target_value,
          measurementType: null,
          observationType: null,
          brsMeasurement: null,
          currentBaseline: null,
        },
      ];
    }
  };

  const [activeKey, setActiveKey] = useState<SetGoalModalStep>(
    SetGoalModalStep.BEHAVIORS
  );
  const [goalsDataList, setGoalsDataList] = useState<GoalsData[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEvidenceAssessments());
    dispatch(getAssessmentStaticData());
    dispatch(getMeasurementStaticData());
    dispatch(getDefaultProgressMonitoring());
  }, []);

  useEffect(() => {
    if (showModal) {
      dispatch(getEvidenceAssessments());
      dispatch(getAssessmentStaticData());
      dispatch(getMeasurementStaticData());
      dispatch(getDefaultProgressMonitoring());
      setActiveKey(SetGoalModalStep.BEHAVIORS);
      setGoalsDataList(getGoalsDataForStudents());
    }
  }, [showModal]);

  const onHideModal = () => {
    dispatch(changeSetGoalsModal(false));
  };

  const modalTitle = useMemo(() => {
    const action = selectedStudentGoal ? "Update Goals" : "Set Goals";
    switch (activeKey) {
      case SetGoalModalStep.BEHAVIORS:
        return `${action}: Describe Desired Behaviors`;
      case SetGoalModalStep.MEASURE:
        return `${action}: Measure Progress`;
      default:
        return "";
    }
  }, [activeKey, selectedStudentGoal]);

  const isBehaviorsIncomplete = useMemo(
    () =>
      goalsDataList.some(
        (data) =>
          data.targetDate === null ||
          data.goal_statement == null ||
          data.goal_statement.trim().length === 0
      ),
    [goalsDataList]
  );

  const isMeasureIncomplete = useMemo(
    () =>
      goalsDataList.some((data) => {
        switch (data.behaviorsMeasureType) {
          case BehaviorsMeasureType.RC_INDICATORS:
            return !data.targetDate || !data.goal_statement;
          case BehaviorsMeasureType.OBSERVATION:
            return (
              !data.observationType ||
              !data.measurementType ||
              !data.targetDate ||
              !data.targetValue ||
              !data.goal_statement
            );
          case BehaviorsMeasureType.BEHAVIOR_RATING_SCALES:
            return (
              !data.brsMeasurement ||
              !data.targetDate ||
              !data.targetValue ||
              !data.goal_statement
            );
          case null:
            return true;
        }
      }),
    [goalsDataList]
  );

  const setRcIndicatorsGoal = ({
    goal_statement,
    targetDate,
    currentBaseline,
    student,
  }: GoalsData) => {
    if (goal_statement && targetDate && selectedInterventionGroup) {
      if (!defaultProgressMonitoring) {
        dispatch(getDefaultProgressMonitoring());
        return;
      }
      const newStudentGoal: StudentGoalForGroup = {
        concern_area: ConcernArea.BEHAVIOR,
        measurement_id: defaultProgressMonitoring.id!,
        goal_statement: goal_statement,
        target_date: dateToFormatString(targetDate),
        current_baseline: currentBaseline ?? undefined,
      };
      return dispatch<any>(
        createStudentGoalForStudent(
          selectedInterventionGroup.id!,
          student.id!,
          newStudentGoal
        )
      );
    }
  };

  const setObservationsGoal = ({
    observationType,
    measurementType,
    targetDate,
    targetValue,
    goal_statement,
    currentBaseline,
    student,
  }: GoalsData) => {
    if (
      observationType &&
      measurementType &&
      targetDate &&
      targetValue &&
      goal_statement
    ) {
      const staticAssessments: (Assessment & {
        measurements: Measurement[];
      })[] = staticData.measurements.reduce(
        (pV, cV) => {
          return pV.map((x: any) => {
            if (x.id === cV.assessment!.id) {
              return {
                ...x,
                measurements: x.measurements ? [...x.measurements, cV] : [cV],
              };
            }
            return x;
          });
        },
        staticData.assessments.map((x) => ({
          ...x,
          measurements: [],
        }))
      );
      const selectedAssessment = staticAssessments.find((assessment) =>
        assessment.name.includes(
          ObservationTypesDisplayedNames[observationType]
        )
      );
      if (selectedAssessment && selectedInterventionGroup) {
        const selectedMeasurement = selectedAssessment.measurements.find((x) =>
          x.display_name.includes(
            StaticMeasurementTypesDisplayedNames[measurementType]
          )
        );
        if (selectedMeasurement) {
          const newStudentGoal: StudentGoalForGroup = {
            concern_area: ConcernArea.BEHAVIOR,
            measurement_id: selectedMeasurement.id!,
            target_date: dateToFormatString(targetDate),
            goal_statement: goal_statement,
            target_value: +targetValue,
            current_baseline: currentBaseline ?? undefined,
          };
          return dispatch<any>(
            createStudentGoalForStudent(
              selectedInterventionGroup.id!,
              student.id!,
              newStudentGoal
            )
          );
        }
      }
    }
  };

  const setBrsGoal = ({
    brsMeasurement,
    targetDate,
    targetValue,
    goal_statement,
    currentBaseline,
    student,
  }: GoalsData) => {
    if (
      brsMeasurement &&
      targetDate &&
      targetValue &&
      goal_statement &&
      selectedInterventionGroup
    ) {
      const newStudentGoal: StudentGoalForGroup = {
        concern_area: ConcernArea.BEHAVIOR,
        measurement_id: brsMeasurement.id!,
        target_date: dateToFormatString(targetDate),
        target_value: +targetValue,
        goal_statement: goal_statement,
        current_baseline: currentBaseline ?? undefined,
      };
      return dispatch<any>(
        createStudentGoalForStudent(
          selectedInterventionGroup.id!,
          student.id!,
          newStudentGoal
        )
      );
    }
  };

  const onSubmitBehaviors = (e: React.FormEvent) => {
    e.preventDefault();
    if (!isBehaviorsIncomplete) {
      setActiveKey(SetGoalModalStep.MEASURE);
    }
  };

  const handleFinishGoals = (e: React.FormEvent) => {
    e.preventDefault();
    if (!isLoading) {
      goalsDataList
        .reduce(async (previousPromise, data) => {
          await previousPromise;
          switch (data.behaviorsMeasureType) {
            case BehaviorsMeasureType.RC_INDICATORS:
              return setRcIndicatorsGoal(data);
            case BehaviorsMeasureType.OBSERVATION:
              return setObservationsGoal(data);
            case BehaviorsMeasureType.BEHAVIOR_RATING_SCALES:
              return setBrsGoal(data);
          }
        }, Promise.resolve())
        .then(onHideModal, (error: string) => toastr.error("Error", error));

      // Promise.all(
      //   goalsDataList.map((data) => {
      //     switch (data.behaviorsMeasureType) {
      //       case BehaviorsMeasureType.RC_INDICATORS:
      //         return setRcIndicatorsGoal(data);
      //       case BehaviorsMeasureType.OBSERVATION:
      //         return setObservationsGoal(data);
      //       case BehaviorsMeasureType.BEHAVIOR_RATING_SCALES:
      //         return setBrsGoal(data);
      //     }
      //   })
      // ).then(onHideModal, (error: string) => toastr.error("Error", error));
    }
  };

  const modalFooter = useMemo(() => {
    switch (activeKey) {
      case SetGoalModalStep.BEHAVIORS:
        return (
          <div className="w-100 d-flex justify-content-end">
            <OverlayTrigger
              trigger={isBehaviorsIncomplete ? ["hover", "focus"] : []}
              overlay={
                <Tooltip id="nextStepTooltip" className="customInfoTooltip">
                  Please complete all fields.
                </Tooltip>
              }
            >
              <button
                className={
                  isBehaviorsIncomplete
                    ? "whiteBtnSm cursor-not-allowed"
                    : "blueBtnSm"
                }
                type="submit"
                form="set-goals-behaviors-form"
              >
                Next: How to Measure
              </button>
            </OverlayTrigger>
          </div>
        );
      case SetGoalModalStep.MEASURE:
        return (
          <div className="w-100 d-flex justify-content-between">
            <button
              className="blueBtnSm"
              disabled={isLoading}
              onClick={() => setActiveKey(SetGoalModalStep.BEHAVIORS)}
            >
              Change Goals
            </button>
            <OverlayTrigger
              trigger={isMeasureIncomplete ? ["hover", "focus"] : []}
              overlay={
                <Tooltip id="nextStepTooltip" className="customInfoTooltip">
                  Please complete all fields.
                </Tooltip>
              }
            >
              <button
                className={
                  isMeasureIncomplete
                    ? "whiteBtnSm cursor-not-allowed"
                    : "blueBtnSm"
                }
                type="submit"
                form="set-goals-measurement-form"
              >
                {selectedStudentGoal
                  ? "Update Goals "
                  : "Finish Setting Goals "}
                {isLoading && <Spinner animation="border" size="sm" />}
              </button>
            </OverlayTrigger>
          </div>
        );
      default:
        return null;
    }
  }, [
    activeKey,
    isLoading,
    isBehaviorsIncomplete,
    isMeasureIncomplete,
    selectedStudentGoal,
  ]);

  return (
    <Modal
      enforceFocus={false}
      show={showModal}
      size="lg"
      onHide={!isLoading ? onHideModal : undefined}
      animation={false}
      backdropClassName={"customDarkModalBackdrop in"}
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container activeKey={activeKey as EventKey}>
          <Tab.Content>
            <Tab.Pane eventKey={SetGoalModalStep.BEHAVIORS}>
              <SetGoalsBehaviors
                studentEntries={studentEntries}
                goalsDataList={goalsDataList}
                setGoalsDataList={setGoalsDataList}
                onSubmit={onSubmitBehaviors}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={SetGoalModalStep.MEASURE}>
              <SetGoalsMeasure
                goalsDataList={goalsDataList}
                setGoalsDataList={setGoalsDataList}
                onSubmit={handleFinishGoals}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>{modalFooter}</Modal.Footer>
    </Modal>
  );
};

export default NewSetGoalsModal;
