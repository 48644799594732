import React, { FunctionComponent } from "react";
import UserAvatarSmall from "../../../../../common/UserAvatarSmall";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import InterventionGroupName from "./InterventionGroupName";

type OwnProps = {
  interventionGroup?: InterventionGroup;
};

type Props = OwnProps;

const InterventionGroupCoachAndTeacher: FunctionComponent<Props> = ({
  interventionGroup,
}) => {
  return (
    <div className="d-flex flex-column">
      <div>
        <h3 className="mb-0">
          <UserAvatarSmall
            userInfo={interventionGroup?.coach_assignment?.user}
            size={25}
            className="mx-2"
          />
          {getFullName(interventionGroup?.coach_assignment?.user)}
        </h3>
      </div>
      <div>
        <h2 className="font-weight-semibold my-1 d-flex align-items-center">
          <UserAvatarSmall
            userInfo={interventionGroup?.teacher_assignment?.user}
            size={35}
            className="mx-2"
          />
          {getFullName(interventionGroup?.teacher_assignment?.user)}
          {": "}
          <InterventionGroupName interventionGroup={interventionGroup!} />
        </h2>
      </div>
    </div>
  );
};

export default InterventionGroupCoachAndTeacher;
