import React, { useEffect, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import {
  ObservationTypes,
  StaticMeasurementTypes,
} from "../../../../../store/onboarding/cases/types";
import { Measurement } from "../../../../../store/onboarding/types";
import { BehaviorsMeasureType, GoalsData } from "../NewSetGoalsModal";
import ObservationsPopover from "./observations/ObservationsPopover";
import BRSPopover from "./behavior-rating-scale/BRSPopover";
import RCIndicatorsPopover from "./rc-indicators/RCIndicatorsPopover";

enum MeasurePopoverBody {
  MEASURE_FOR_ALL,
  RC_INDICATORS,
  OBSERVATION,
  BEHAVIOR_RATING_SCALES,
}

type Props = {
  objectRef: React.MutableRefObject<any>;
  showDropdown: boolean;
  goalsDataList: GoalsData[];
  setGoalsDataList: (goalsData: GoalsData[]) => void;
  setShowDropdown: (show: boolean) => void;
};

const MeasureForAllPopover = ({
  objectRef,
  showDropdown,
  goalsDataList,
  setGoalsDataList,
  setShowDropdown,
}: Props) => {
  const [overlayBody, setOverlayBody] = useState<MeasurePopoverBody | null>(
    null
  );

  useEffect(() => {
    if (showDropdown) {
      setOverlayBody(MeasurePopoverBody.MEASURE_FOR_ALL);
    }
  }, [showDropdown]);

  const onSubmitRcIndicatorsPopover = () => {
    setGoalsDataList(
      goalsDataList.map((oldData) => ({
        ...oldData,
        behaviorsMeasureType: BehaviorsMeasureType.RC_INDICATORS,
        targetValue: null,
        measurementType: null,
        observationType: null,
        brsMeasurement: null,
      }))
    );
    setShowDropdown(false);
  };

  const onSubmitObservationPopover = (
    measurementType: StaticMeasurementTypes,
    observationType: ObservationTypes
  ) => {
    setGoalsDataList(
      goalsDataList.map((oldData) => ({
        ...oldData,
        behaviorsMeasureType: BehaviorsMeasureType.OBSERVATION,
        measurementType,
        observationType,
        targetValue: null,
        brsMeasurement: null,
      }))
    );
    setShowDropdown(false);
  };

  const onSubmitBrsPopover = (selectedBrsMeasurement: Measurement) => {
    setGoalsDataList(
      goalsDataList.map((oldData) => ({
        ...oldData,
        behaviorsMeasureType: BehaviorsMeasureType.BEHAVIOR_RATING_SCALES,
        brsMeasurement: selectedBrsMeasurement,
        targetValue: null,
        measurementType: null,
        observationType: null,
      }))
    );
    setShowDropdown(false);
  };
  switch (overlayBody) {
    case MeasurePopoverBody.MEASURE_FOR_ALL:
      return (
        <Overlay
          placement="auto"
          flip
          rootClose={true}
          onHide={() => setShowDropdown(false)}
          show={showDropdown}
          target={objectRef.current}
        >
          <Popover
            id="popover-all-measure"
            className="w-100 measure-for-all-popover"
          >
            <Popover.Content>
              <div className="d-flex justify-content-around">
                <button
                  className="blueBtnSm mr-2"
                  onClick={() =>
                    setOverlayBody(MeasurePopoverBody.RC_INDICATORS)
                  }
                >
                  ReadyCoach Indicators
                </button>
                <button
                  className="blueBtnSm mr-2"
                  onClick={() => setOverlayBody(MeasurePopoverBody.OBSERVATION)}
                >
                  Observations
                </button>
                <button
                  className="blueBtnSm"
                  onClick={() =>
                    setOverlayBody(MeasurePopoverBody.BEHAVIOR_RATING_SCALES)
                  }
                >
                  Behavior Rating Scale
                </button>
              </div>
            </Popover.Content>
          </Popover>
        </Overlay>
      );
    case MeasurePopoverBody.RC_INDICATORS:
      return (
        <RCIndicatorsPopover
          objectRef={objectRef}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          onSubmitPopover={onSubmitRcIndicatorsPopover}
        />
      );
    case MeasurePopoverBody.OBSERVATION:
      return (
        <ObservationsPopover
          objectRef={objectRef}
          showDropdown={showDropdown}
          goalObservation={null}
          goalMeasurement={null}
          setShowDropdown={setShowDropdown}
          onSubmitPopover={onSubmitObservationPopover}
        />
      );
    case MeasurePopoverBody.BEHAVIOR_RATING_SCALES:
      return (
        <BRSPopover
          objectRef={objectRef}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          onSubmitPopover={onSubmitBrsPopover}
        />
      );
  }
  return null;
};

export default MeasureForAllPopover;
