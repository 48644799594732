import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Steps } from "./ThreeStepGuide";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

type OwnProps = {
  icon: IconDefinition;
  index: number;
  title: string;
  details: string;
  step: Steps;
  currentStep: Steps;
  completed?: boolean;
  onClick: (e: any) => any;
};

type Props = OwnProps;

const StepItem: FunctionComponent<Props> = (props) => {
  const {
    index,
    icon,
    title,
    details,
    step,
    currentStep,
    onClick,
    completed,
  } = props;

  const className = completed
    ? "completed"
    : currentStep === step
    ? "active"
    : undefined;

  return (
    <div
      data-cy={`guide-step-${index}`}
      className={className}
      onClick={onClick}
    >
      <span>
        <FontAwesomeIcon
          style={!completed ? { opacity: ".33" } : undefined}
          icon={icon}
          size={"2x"}
        />
      </span>
      <div>
        <h3>
          {index + 1}. {title}
          {completed && (
            <FontAwesomeIcon
              icon={faCheckCircle}
              size={"sm"}
              className="ml-1"
            />
          )}
        </h3>
        <small>{details}</small>
      </div>
    </div>
  );
};

export default StepItem;
