import React, { FunctionComponent, useMemo } from "react";
import VerticalBarChart from "./VerticalBarChart";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { GoalSummary } from "../../../../../../store/onboarding/types";
import _ from "lodash";

type OwnProps = {
  label: string;
  goals: GoalSummary[];
};

type Props = OwnProps;

const GoalsPerSchoolChart: FunctionComponent<Props> = (props) => {
  const { label, goals } = props;
  let uniqueSchools:any[] = [];

  for (let i = 0; i < goals.length; i++) {
    const element = goals[i];
    if(element!.coach!.profile!.current_assignment!.schools.length > 0) {
        for (let j = 0; j < element!.coach!.profile!.current_assignment!.schools.length; j++) {
            const ele = element!.coach!.profile!.current_assignment!.schools[j];
            if(!uniqueSchools.includes(ele.id)) {
                uniqueSchools.push(ele.id)
            }  
        }
    }
  }

  //console.log('uniqueSchools', uniqueSchools);
  
  let goalCountsBySchool:any = {};
  for (let k = 0; k < uniqueSchools.length; k++) {
    let coachCountBySchool = 0;
    for (let i = 0; i < goals.length; i++) {
      const element = goals[i];
      if(element!.coach!.profile!.current_assignment!.schools.length > 0) {
        for (let j = 0; j < element!.coach!.profile!.current_assignment!.schools.length; j++) {
            const ele = element!.coach!.profile!.current_assignment!.schools[j];
            if(uniqueSchools[k] === ele.id) {
              goalCountsBySchool[ele.name] = ++coachCountBySchool;
            }  
          }
        }
      }
    }
  //console.log('goalCountsBySchool', goalCountsBySchool)
  let labels:any = [];
  let data:any = [];
  
  for (const key in goalCountsBySchool) {
    labels.push(key);
    data.push(goalCountsBySchool[key])
  }

  if (!data.length) {
    return null;
  }

  return (
    <div className="district-summary-chart-container">
      <h3 className="text-center">{label}</h3>
      <div>
        <VerticalBarChart labels={labels} data={data} isNumeric />
      </div>
    </div>
  );
};

export default GoalsPerSchoolChart;
