import React, { FunctionComponent, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import {
  CategoryBehavior,
  StudentEntry,
} from "../../../../../../../store/onboarding/types";
import { FormControl, Overlay, Popover, Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  addCustomBehavior,
  addStudentBehaviorsToDataPeriod,
} from "../../../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

type StateProps = {
  isLoading: {
    addCustomBehavior?: boolean;
    addStudentBehaviorsToDataPeriod?: boolean;
  };
};

type DispatchProps = {
  addCustomBehavior: (behaviorName: string) => any;
  addStudentBehaviorsToDataPeriod: (
    behaviors: Array<CategoryBehavior>,
    studentId: number,
    selectedDataPeriodId?: number
  ) => any;
};

type OwnProps = {
  isReadonly?: boolean;
  selectedBehavior: CategoryBehavior | { name: string };
  customName?: string;
  studentEntry?: StudentEntry;
};

type Props = StateProps & DispatchProps & OwnProps;

const BehaviorTag: FunctionComponent<Props> = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const tagNode = useRef<any>(null);
  const [customBehaviorName, setCustomBehaviorName] = useState<string>("");

  const handleBehaviorReplace = () => {
    props.addCustomBehavior(customBehaviorName).then(
      (customBehavior: CategoryBehavior) => {
        if (props.studentEntry) {
          const behaviors = (props.studentEntry.behavior_tags || []).map(
            (bt) => {
              if (bt.id === (props.selectedBehavior as any)?.id) {
                return customBehavior;
              }
              return bt;
            }
          );

          props
            .addStudentBehaviorsToDataPeriod(
              behaviors,
              props.studentEntry.student_id
            )
            .then(() => {
              toastr.success("Saved!", "", { timeOut: 3000 });
              setShowPopup(false);
            });
        }
      },
      (err: string) => toastr.error("Failed to add the custom behavior", err)
    );
  };

  const handleChange = (event: React.FormEvent<any>) => {
    const { value } = event.target as HTMLInputElement;
    setCustomBehaviorName(value);
  };

  const handleEdit = (e: any) => {
    e.stopPropagation();
    setShowPopup(true);
    setCustomBehaviorName(props.selectedBehavior.name);
  };

  return (
    <React.Fragment>
      {/*<div ref={tagNode} className="d-flex-inline align-items-center">*/}
      <span>{props.customName || props.selectedBehavior.name}</span>
      {!props.isReadonly && (
        <span ref={tagNode}>
          <FontAwesomeIcon
            // ref={tagNode}
            style={{ color: "inherit" }}
            icon={faEdit}
            className="ml-1 pointer"
            onClick={handleEdit}
          />
        </span>
      )}
      {/*</div>*/}

      <Overlay
        target={tagNode && tagNode.current}
        show={showPopup}
        // placement={"bottom"}
        rootClose
        rootCloseEvent={"click"}
        onHide={() => setShowPopup(false)}
      >
        <Popover id="popover-contained" onClick={(e) => e.stopPropagation()}>
          <Popover.Content>
            <h3 className="font-weight-bold mb-2">Customize behavior:</h3>
            <div className="d-flex">
              <FormControl
                value={customBehaviorName}
                onChange={handleChange}
                autoFocus
              />
              <button
                disabled={!customBehaviorName.length}
                className="blueBtnSm ml-2 d-flex align-items-center"
                onClick={handleBehaviorReplace}
              >
                Save
                {(props.isLoading.addCustomBehavior ||
                  props.isLoading.addStudentBehaviorsToDataPeriod) && (
                  <Spinner animation="border" size="sm" className="ml-1" />
                )}
              </button>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </React.Fragment>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    isLoading: {
      addCustomBehavior: onboarding.isLoading.addCustomBehavior,
      addStudentBehaviorsToDataPeriod:
        onboarding.isLoading.addStudentBehaviorsToDataPeriod,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      addCustomBehavior: addCustomBehavior,
      addStudentBehaviorsToDataPeriod: addStudentBehaviorsToDataPeriod,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BehaviorTag);
