import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { ApplicationState } from "../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  changeSelectedInterventionGroupId,
  markInterventionFidelityTourAsShown,
} from "../../../../store/tour/actions";
import { connect } from "react-redux";
import { InterventionGroup } from "../../../../store/onboarding/cases/types";
import Joyride, { CallBackProps, Step } from "react-joyride";

type StateProps = {
  interventionGroups: InterventionGroup[];
  implementMeetingTourShown?: boolean;
  interventionFidelityTourShown?: boolean;
  selectedInterventionGroupId?: number;
};
type DispatchProps = {
  markInterventionFidelityTourAsShown: () => any;
  changeSelectedInterventionGroupId: (intGroupId?: number) => any;
};

type Props = StateProps & DispatchProps;

const InterventionFidelityTour: FunctionComponent<Props> = (props) => {
  const groupWithMonitoringStage:
    | InterventionGroup
    | undefined = useMemo(() => {
    return props.interventionGroups.find(
      (ig) =>
        ig.finished &&
        (ig.skipped_meeting ||
          (ig.latest_meeting?.meeting_notes &&
            ig.latest_meeting?.planning_notes))
    );
  }, [props.interventionGroups]);

  const steps: Step[] = useMemo(
    () => [
      {
        disableBeacon: true,
        target:
          '#groups-container .assigmentInfo > div[data-stage="2"] #monitoring-panel > div:last-child > span:nth-of-type(1)',
        content: <>You can track intervention implementation fidelity here.</>,
      },

      {
        disableBeacon: true,
        target:
          '#groups-container .assigmentInfo > div[data-stage="2"] #monitoring-panel > div:last-child > span:nth-of-type(2)',
        content: (
          <>
            You can track feedback notes you gave your teacher here. You can
            also plan a meeting and take notes beforehand to refer to later when
            you meet the teacher.
          </>
        ),
      },
      {
        disableBeacon: true,
        target:
          '#groups-container .assigmentInfo > div[data-stage="2"] #monitoring-panel > div:last-child > span:nth-of-type(3)',
        content: (
          <>
            You can track goal progress here. Once there is enough data, the
            graph to the left will populate with your intervention and goal
            data.
          </>
        ),
      },
    ],
    []
  );

  const [runTour, setRunTour] = useState(false);

  useEffect(() => {
    if (
      // !!props.implementMeetingTourShown &&
      !props.interventionFidelityTourShown &&
      groupWithMonitoringStage
    ) {
      props.changeSelectedInterventionGroupId(groupWithMonitoringStage.id);
    } else {
      setRunTour(false);
    }
  }, [
    groupWithMonitoringStage,
    // props.interventionFidelityTourShown,
    props.implementMeetingTourShown,
  ]);

  useEffect(() => {
    if (
      // !!props.implementMeetingTourShown &&
      !props.interventionFidelityTourShown &&
      groupWithMonitoringStage &&
      props.selectedInterventionGroupId
    ) {
      setTimeout(() => setRunTour(true), 500);
    }
  }, [props.selectedInterventionGroupId]);

  const handleTourChange = (data: CallBackProps) => {
    if (["skip", "reset"].includes(data.action)) {
      props.markInterventionFidelityTourAsShown();
    }
  };

  return (
    <Joyride
      steps={steps}
      callback={handleTourChange}
      disableScrolling
      showSkipButton
      floaterProps={{ disableAnimation: true }}
      continuous
      run={runTour}
      styles={{
        options: {
          primaryColor: "#00265B",
        },
      }}
    />
  );
};

const mapStateToProps = ({ tour, cases }: ApplicationState): StateProps => {
  return {
    interventionGroups: cases.interventionGroups,
    implementMeetingTourShown: tour.implementMeetingTourShown,
    interventionFidelityTourShown: tour.interventionFidelityTourShown,
    selectedInterventionGroupId: tour.selectedInterventionGroupId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      markInterventionFidelityTourAsShown: markInterventionFidelityTourAsShown,
      changeSelectedInterventionGroupId: changeSelectedInterventionGroupId,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterventionFidelityTour);
