import React, { FunctionComponent } from "react";
import { getGoalTitle } from "../../../../utils";
import { getFullName } from "../../../../../utils/NamesUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  InterventionGroup,
  StudentGoal,
} from "../../../../../store/onboarding/cases/types";
import SelectStudentGoalsPopover from "./SelectStudentGoalsPopover";

type OwnProps = {
  group: InterventionGroup;
  goal?: StudentGoal;
  onGoalEdit: (goal?: StudentGoal) => any;
};

type Props = OwnProps;

const StudentGoalRecord: FunctionComponent<Props> = (props) => {
  const { goal, group, onGoalEdit } = props;

  const title = getGoalTitle(goal ?? group.student_goals[0]);
  return (
    <h4>
      <strong>{goal ? getFullName(goal.student) : "All Students"}: </strong>
      {title}{" "}
      {!goal ? (
        <SelectStudentGoalsPopover group={group} onGoalEdit={onGoalEdit} />
      ) : (
        <span onClick={() => onGoalEdit(goal)} className="pointer">
          <FontAwesomeIcon icon={faEdit} />
        </span>
      )}
    </h4>
  );
};

export default StudentGoalRecord;
