import React, { FunctionComponent, useCallback, useMemo } from "react";
import {
  GrammarType,
  GrammarTypeDisplayValues,
  SpellingTestGroup,
} from "../../../../store/spelling-tests/types";
import { Spinner, Table } from "react-bootstrap";
import {
  getFullWordsAnswersStatistics,
  getGrammarPartsAnswersStatistics,
} from "../utils";
import { getFullName } from "../../../../utils/NamesUtils";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../../utils/hooks/useLoading";
import { ApplicationState } from "../../../../store";
import {
  generateStudentReadingDiagnostics,
  hideSpellingTestSummaryModal,
} from "../../../../store/spelling-tests/actions";
import { useHistory } from "react-router";
import { getDataPeriods } from "../../../../store/onboarding/actions";

type Props = {
  group: SpellingTestGroup;
};

const PROFICIENCY = 0.8;

const GeneralSpellingTestGroupStatisticsTable: FunctionComponent<Props> = (
  props
) => {
  const { group } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const selectedGrammarTypes = useMemo(() => {
    const grammarTypeOrder = Object.values(GrammarType);

    return Array.from(
      new Set(
        group.tests
          .flatMap((test) => test.answers)
          .flatMap((answer) => answer.grammar_parts)
          .map((answerGrammarPart) => answerGrammarPart.grammar_type)
      )
    ).sort((a, b) => grammarTypeOrder.indexOf(a) - grammarTypeOrder.indexOf(b));
  }, [group.tests]);

  const groupedStatisticsByStudent = useMemo(() => {
    return group.tests.map((test) => {
      return {
        student: test.student,
        statistics: {
          full_words: getFullWordsAnswersStatistics(test.answers),
          ...getGrammarPartsAnswersStatistics(test.answers),
        },
      };
    });
  }, [group.tests]);

  const studentsNumberByProficiency = useMemo(
    () =>
      groupedStatisticsByStudent.reduce((pV, cV) => {
        return Object.entries(cV.statistics).reduce((pV, [key, value]) => {
          const isOver80percent = value.correct / value.total >= PROFICIENCY;

          return {
            ...pV,
            [key]: isOver80percent ? (pV[key] ?? 0) + 1 : pV[key] ?? 0,
          };
        }, pV);
      }, {} as any),
    [groupedStatisticsByStudent]
  );

  const getPercent = (stat?: { correct: number; total: number }) => {
    if (!stat) {
      return null;
    }
    return getStatPercent(stat.correct, stat.total);
  };

  const getStatPercent = (correct: number, total: number) => {
    return `${((correct / total) * 100).toFixed(2).replace(".00", "")}%`;
  };

  const generating = useSelector(
    (s: ApplicationState) =>
      s.spellingTests.isLoading.generateStudentReadingDiagnostics
  );
  const generatingError = useSelector(
    (s: ApplicationState) =>
      s.spellingTests.errors.generateStudentReadingDiagnostics
  );

  const onSuccess = useCallback(() => {
    dispatch(hideSpellingTestSummaryModal());
    dispatch(getDataPeriods());
    history.push("/data");
  }, []);

  useLoading(generating, generatingError, onSuccess);
  const handleGenerateStudentReadingDiagnostics = () => {
    dispatch(generateStudentReadingDiagnostics(group.id)); //todo maybe replace with string of spelling test ids
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>Number of Students: {groupedStatisticsByStudent.length}</h2>

        <button
          className="blueBtnMd"
          onClick={handleGenerateStudentReadingDiagnostics}
        >
          Generate Student Reading Diagnostics{" "}
          {generating && <Spinner animation="border" size="sm" />}
        </button>
      </div>

      <Table
        striped
        bordered
        responsive
        size="sm"
        className="spelling-test-group-statistics"
      >
        <thead>
          <tr>
            <th />
            <th>Full Words</th>
            {selectedGrammarTypes.map((gt) => (
              <th key={gt}>{GrammarTypeDisplayValues[gt]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Number of students at or above 80%</td>
            <td>{studentsNumberByProficiency.full_words}</td>
            {selectedGrammarTypes.map((gt) => (
              <td key={gt}>{studentsNumberByProficiency[gt]}</td>
            ))}
          </tr>
          <tr>
            <td>% of class at or above 80% proficiency</td>
            <td>
              {getStatPercent(
                studentsNumberByProficiency.full_words ?? 0,
                groupedStatisticsByStudent.length
              )}
            </td>
            {selectedGrammarTypes.map((gt) => (
              <td key={gt}>
                {getStatPercent(
                  studentsNumberByProficiency[gt] ?? 0,
                  groupedStatisticsByStudent.length
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>

      <hr />

      <Table
        hover
        striped
        bordered
        responsive
        size="sm"
        className="spelling-test-group-statistics"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Student Name</th>
            <th>Full Words</th>
            {selectedGrammarTypes.map((gt) => (
              <th key={gt}>{GrammarTypeDisplayValues[gt]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {groupedStatisticsByStudent.map((s, index) => (
            <tr key={s.student?.id ?? index}>
              <td>{index + 1}</td>
              <td>{getFullName(s.student)}</td>
              <td>{getPercent(s.statistics.full_words)}</td>
              {selectedGrammarTypes.map((gt) => (
                <td key={gt}>{getPercent(s.statistics[gt])}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default GeneralSpellingTestGroupStatisticsTable;
