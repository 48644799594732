import "core-js/stable";
import "regenerator-runtime/runtime";
import "core-js/es/map";
import "core-js/es/set";
import "raf/polyfill";

import "react-app-polyfill/ie9";

import React, { useEffect, useMemo } from "react";
import { Provider, useSelector } from "react-redux";
import { push } from "connected-react-router";
import axios, { AxiosError } from "axios";
import { ConnectedRouter } from "connected-react-router";
import { HOST_URL, IS_READY_COACH } from "./constants";
import configureStore, { ApplicationState, history } from "./store";
import RCRoutes from "./routes/RCRoutes";
import "bootstrap/dist/css/bootstrap.css";
import "./Common/bootstrap-overrides.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import Header from "./components/common/Header";
import PopUpAlert from "./components/common/PopUpAlert";
import { logoutUser } from "./store/auth/actions";
import Footer from "./components/common/Footer";
import Localization from "./components/common/localization/Localization";
import { LOCALE } from "./store/help/types";
import { createErrorMessage } from "./utils";

import { toastr } from "react-redux-toastr";

import * as Sentry from "@sentry/react";
import NetworkDetect from "./components/utils/NetworkDetect";

const deployEnv = process.env.REACT_APP_DEPLOY_ENV;
const isLocal = process.env.REACT_APP_LOCAL === "TRUE";

if (deployEnv && !isLocal) {
  let environment;
  let sentryDSN =
    "https://74683a61a4b041d0a79b82a07530dd6a@o1110002.ingest.sentry.io/6245980";
  if (deployEnv === "staging") {
    environment = "staging";
  } else if (deployEnv === "production") {
    environment = "production";
  } else if (deployEnv === "pre-prod") {
    environment = "pre-prod";
  } else if (deployEnv === "newstaging"){
    environment = "newstaging"
  }
  Sentry.init({
    dsn: sentryDSN,
    environment: environment,
  });
}

// if (isLocal) {
//   let sentryDSN;
//   sentryDSN =
//     "https://74683a61a4b041d0a79b82a07530dd6a@o1110002.ingest.sentry.io/6245980";
//   Sentry.init({
//     dsn: sentryDSN,
//     environment: "localhost",
//   });
// }

if (IS_READY_COACH) {
  document.title = "ReadyCoach";
} else {
  document.title = "NJTSS Early Reading";
}

const store = configureStore();
axios.defaults.baseURL = HOST_URL;
axios.defaults.headers["content-type"] = "application/json";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
axios.create({ cancelToken: source.token});

axios.defaults.xsrfCookieName = process.env.REACT_APP_XSRF_COOKIE_NAME;
axios.defaults.xsrfHeaderName = "X-CSRFToken"
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.headers.common["Accept-Language"] =
  (store.getState() as ApplicationState).help.locale || LOCALE.ENGLISH;


axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    // logout and cancel all request if get 401
    if (
      error.response?.status === 401 &&
      (store.getState() as ApplicationState).auth.isAuthenticated
    ) {
      if(!(store.getState() as ApplicationState).auth.isLoading.changePassword) {
        source.cancel();
        store.dispatch(logoutUser() as any);
      }
    }

    if (
      error.response?.status === 403
      && (JSON.stringify(error.response.data).includes('Authentication credentials were not provided.'))
  ) {
    if(!(store.getState() as ApplicationState).auth.isLoading.changePassword) {
      source.cancel();
      store.dispatch(logoutUser() as any);
      window.location.reload();
    }
  }

    if(error.response?.status == 500 &&
      (store.getState() as ApplicationState).auth.isAuthenticated
    ) {
      toastr.error("Oops!!", "Something went wrong.");
    }

    return Promise.reject({
      ...error,
      message: createErrorMessage(error.response?.data || error.message),
    });
  }
);

axios.get("csrf", { withCredentials: false, withXSRFToken: false });

console.log("Axios configured");

window.addEventListener("error", function(event: ErrorEvent) {
  if (event.error.hasBeenCaught !== undefined) {
    return false;
  }
  event.error.hasBeenCaught = true;
  toastr.error("Oops! Something went wrong.", event.message);
  store.dispatch(push("/data"));

  return false;
});

const App = () => {

  useEffect(() => {
    const stylePrefix =
      process.env.REACT_APP_APPLICATION == "readycoach" ? "rc" : "ri";
    require(`../src/Common/${stylePrefix}.style.css`);
  }, []);

  const routes = useMemo(() => {
    return <RCRoutes />;
  }, []);

  return (
    <Provider store={store}>
      <NetworkDetect/>
      <Localization>
        <ConnectedRouter history={history}>
          <div className="container-box">
            <Header />
            {routes}
            {/* <button onClick={methodDoesNotExist7}>Break the world</button> */}
          </div>
          <Footer />
          <PopUpAlert />
        </ConnectedRouter>
      </Localization>
    </Provider>
  );
};

export default App;
