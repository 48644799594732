import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Image } from "react-bootstrap";
import LoadingIndicator from "../../LoadingIndicator";
import YouTube from "react-youtube";
import graphic from "../../../../../Images/graphic.png";
import useUserRole from "../../../../../utils/hooks/useUserRole";
import { UserAssignmentRole } from "../../../../../store/auth/types";

type OwnProps = {
  onClick: () => any;
};

type Props = OwnProps;

const IntroPanel: FunctionComponent<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  const { role, isCoach, isTeacher, isDataManager, isDistrictOwner } = useUserRole();

  const info = useMemo(() => {
    switch (role) {
      case UserAssignmentRole.COACH:
      case UserAssignmentRole.TEACHER:
        return (
          <>
            We provide a spreadsheet interface called the Data Table. <br />
            You can upload a file or type in scores to populate your table.
          </>
        );
      case UserAssignmentRole.SUPERVISOR:
        return (
          <>
            We provide a spreadsheet interface called the Data Table. <br />
            Wait until teachers and coaches upload data or type in scores.
          </>
        );
      default:
        return null;
    }
  }, [role]);

  const afterwards = useMemo(() => {
    switch (role) {
      case UserAssignmentRole.COACH:
      case UserAssignmentRole.TEACHER:
        return "Afterwards, you’ll use this table to make grouping decisions.";
      case UserAssignmentRole.SUPERVISOR:
        return (
          <>
            Afterwards, you’ll use this table to review your coaches and
            teachers progress with students.
          </>
        );
      default:
        return null;
    }
  }, [role]);

  return (
    <div className="text-center p-3" data-cy="data-table-intro">
      <div>
        <h2 className="font-weight-bold">
          Coaching with ReadyCoach Begins with Data or Evidence
        </h2>
        <p>{info}</p>
        <h3 className="font-weight-bold">{afterwards}</h3>
      </div>

      {/* <div className="d-flex flex-wrap align-items-center justify-content-around">
        <Image
          src={graphic}
          className="m-x-auto flex-1"
          style={{ maxWidth: "45em" }}
        />
        <div className="flex-1 d-flex align-items-center justify-content-center">
          {loading && <LoadingIndicator />}
          <div style={loading ? { display: "none", height: 0 } : undefined}>
            <YouTube
              onReady={() => setLoading(false)}
              videoId="CqW7DKD3uK0"
              // opts={{ width: "50%" }}
            />
          </div>
        </div>
      </div> */}

      {(isCoach || isTeacher || isDataManager || isDistrictOwner) && (
        <button className="blueBtnLg mt-2" onClick={props.onClick} data-cy="start">
          Get Started
        </button>
      )}
    </div>
  );
};

export default IntroPanel;
