import React, { FunctionComponent, useMemo } from "react";
import { FbaABC } from "../../../../../../store/onboarding/cases/types";
import {
  useAntecedentNaming,
  useBehaviorsNaming,
  useConsequenceNaming,
} from "../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import _ from "lodash";
import CellGroup from "./CellGroup";

type Props = {
  abc: FbaABC;
  selectedAntecedent:
    | { abcId: number; antecedents: number[]; other_antecedents?: string[] }
    | undefined;
  setSelectedAntecedent: React.Dispatch<
    React.SetStateAction<
      | { abcId: number; antecedents: number[]; other_antecedents?: string[] }
      | undefined
    >
  >;
  selectedConsequence:
    | { abcId: number; consequence: number; other_sequence?: string }
    | undefined;
  setSelectedConsequence: React.Dispatch<
    React.SetStateAction<
      | { abcId: number; consequence: number; other_sequence?: string }
      | undefined
    >
  >;
};

const AbcItemRow: FunctionComponent<Props> = (props) => {
  const {
    abc,
    selectedAntecedent,
    setSelectedAntecedent,
    selectedConsequence,
    setSelectedConsequence,
  } = props;

  const antecedents = useAntecedentNaming(
    abc.antecedents,
    abc.other_antecedents
  );
  const behavior = useBehaviorsNaming(abc.behaviors, abc.other_behavior);
  const consequence = useConsequenceNaming(abc.consequence, abc.other_sequence);

  const isActiveAntecedents = useMemo(() => {
    return (
      selectedAntecedent &&
      // selectedAntecedent.abcId === abc.id &&
      _.isEqual(selectedAntecedent.antecedents, abc.antecedents) &&
      _.isEqual(selectedAntecedent.other_antecedents, abc.other_antecedents)
    );
  }, [selectedAntecedent, abc]);

  const isActiveConsequence = useMemo(() => {
    return (
      selectedConsequence &&
      // selectedConsequence.abcId === abc.id &&
      _.isEqual(selectedConsequence.consequence, abc.consequence) &&
      _.isEqual(selectedConsequence.other_sequence, abc.other_sequence)
    );
  }, [selectedConsequence, abc]);

  const handleAntecedentsCellGroupClick = () => {
    setSelectedAntecedent((antecedent) =>
      antecedent && antecedent.abcId === abc.id
        ? undefined
        : {
            abcId: abc.id,
            antecedents: abc.antecedents,
            other_antecedents: abc.other_antecedents,
          }
    );
  };
  const handleConsequenceCellGroupClick = () => {
    setSelectedConsequence((consequence) =>
      consequence?.abcId === abc.id
        ? undefined
        : {
            abcId: abc.id,
            consequence: abc.consequence,
            other_sequence: abc.other_sequence,
          }
    );
  };

  return (
    <tr className="abc-row">
      <CellGroup onClick={handleAntecedentsCellGroupClick}>
        <td>
          <span>
            <FontAwesomeIcon
              icon={isActiveAntecedents ? faDotCircle : faCircle}
            />
          </span>
        </td>
        <td style={{ width: "33.33%" }}>{antecedents}</td>
      </CellGroup>
      <td style={{ width: "33.33%" }}>{behavior}</td>
      <CellGroup onClick={handleConsequenceCellGroupClick}>
        <td>
          <span>
            <FontAwesomeIcon
              icon={isActiveConsequence ? faDotCircle : faCircle}
            />
          </span>
        </td>
        <td style={{ width: "33.33%" }}>
          {consequence?.map((c) => c.consequence).join(", ")}
        </td>

        <td style={{ width: "33.33%" }}>
          {consequence?.map((c) => c.function).join(", ")}
        </td>
      </CellGroup>
    </tr>
  );
};

export default AbcItemRow;
