export const getBooleanFromLocalStorage = (
  key: string,
  defaultValue?: boolean
) => {
  return Boolean(
    JSON.parse(localStorage.getItem(key) ?? Boolean(defaultValue).toString())
  );
};

export enum LocalStorageKeys {
  CurrentUser = "app:current_user",

  Locale = "rc.app.ui:language",
  ShowTopBar = "rc.app.ui:show_top_bar",

  StartWorkingIntro = "rc.app.onboarding:start_working_intro_hidden",
  NoGroupsIntro = "rc.app.onboarding:no_groups_intro_hidden",
  NoUnsavedGroupsIntro = "rc.app.onboarding:no_unsaved_groups_intro_hidden",
  NoAbcDataCollectionIntro = "rc.app.onboarding:no_abc_data_collection_intro_hidden",

  DisplayedColumns = "rc.app.onboarding:displayed_columns",
  DisplayedGroups = "rc.app.onboarding:displayed_groups",
  WelcomePanelHidden = "rc.app.onboarding:welcome_panel_hidden",
  ThreeStepProgressHidden = "rc.app.onboarding:three_step_progress_hidden",
  GroupingStudentInfoHidden = "rc.app.onboarding:grouping_student_info_hidden",

  CollapsedAssignments = "rc.app.groups:collapsed_assignments",
  CollapsedInnerAssignments = "rc.app.groups:collapsed_inner_assignments",
  DistrictSummaryCollapsedAreas = "rc.app.district-summary:collapsed_areas",
  DistrictSummaryDisplayGraphs = "rc.app.district-summary:display_graphs",
  ShowRecommendedGroups = "rc.app.groups:show_recommended_groups",
  ShowDiagnosedStudents = "rc.app.groups:show_daignosed_students",
  ActiveGroupsView = "rc.app.groups:active_groups_view",

  FirstUploadTour = "rc.app.tours:first_upload_tour_shown",
  SidebarPositionTour = "rc.app.tours:sidebar_position_tour_shown",
  PrepareMeetingTour = "rc.app.tours:prepare_meeting_tour_shown",
  ImplementMeetingTour = "rc.app.tours:implement_meeting_tour_shown",
  InterventionFidelityTour = "rc.app.tours:intervention_fidelity_tour_shown",
  FidelityChartTour = "rc.app.tours:fidelity_chart_tour_shown",
  EmptyDataPeriodViewTour = "rc.app.tours:empty_data_period_view_tour_shown",
}
