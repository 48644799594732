import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Col,
  Form,
  ListGroup,
  Nav,
  Row,
  Spinner,
  TabContainer,
  TabContent,
  TabPane,
} from "react-bootstrap";
import { FeedbackMeeting } from "../../../../../../store/onboarding/meeting-module/types";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  createFeedbackMeeting,
  hideProvidingFeedbackModal,
  openMeetingPromptModal,
  openPraiseEducatorModal,
  updateFeedbackMeeting,
} from "../../../../../../store/onboarding/meeting-module/actions";
import { connect } from "react-redux";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import MeetingPromptModal from "./MeetingPromptModal";
import { formatDate } from "../../../../../utils/DateTimeUtils";
import { EventKey } from "react-bootstrap/esm/types";

type StateProps = {
  selectedPraise?: string;
  feedbackMeetings: Array<FeedbackMeeting>;
  isLoading: {
    createFeedbackMeeting: boolean;
    updateFeedbackMeeting: boolean;
  };
  interventionGroup?: InterventionGroup;
  selectedFeedbackMeeting?: FeedbackMeeting;
};

type DispatchProps = {
  hideProvidingFeedbackModal: () => any;
  openPraiseEducatorModal: () => any;
  openMeetingPromptModal: () => any;
  createFeedbackMeeting: (feedbackMeeting: Partial<FeedbackMeeting>) => any;
  updateFeedbackMeeting: (feedbackMeeting: Partial<FeedbackMeeting>) => any;
};

type OwnProps = {};

type Props = OwnProps & StateProps & DispatchProps;

enum TabKeys {
  PLANNING_NOTES = "PLANNING_NOTES",
  PREVIOUS_MEETINGS = "PREVIOUS_MEETINGS",
}

const MeetingModalBody: FunctionComponent<Props> = ({
  feedbackMeetings,
  interventionGroup,
  isLoading: {
    createFeedbackMeeting: loadingCreateFeedbackMeeting,
    updateFeedbackMeeting: loadingUpdateFeedbackMeeting,
  },
  selectedPraise,
  selectedFeedbackMeeting,

  createFeedbackMeeting,
  updateFeedbackMeeting,
  openMeetingPromptModal,
  openPraiseEducatorModal,
  hideProvidingFeedbackModal,
}) => {
  const [strengths, setStrengths] = useState("");
  const [improvements, setImprovements] = useState("");
  const [additionalModeling, setAdditionalModeling] = useState("");
  const [additionalPractice, setAdditionalPractice] = useState("");
  const [nextSteps, setNextSteps] = useState("");
  const [activeTab, setActiveTab] = useState<string | null>(
    TabKeys.PLANNING_NOTES
  );
  const [isDone, setDone] = useState(false);
  const [isFirstRender, setFirstRender] = useState(true);
  const [editing, setEditing] = useState(false);
  const [selectedPrevMeeting, selectPrevMeeting] = useState<
    FeedbackMeeting | undefined
  >(undefined);

  useEffect(() => {
    if (!isFirstRender) {
      if (isDone) {
        //todo open modal "Your meeting notes have been saved!"
        openMeetingPromptModal();
        // hideProvidingFeedbackModal();
      } else {
        hideProvidingFeedbackModal();
      }
      return;
    }
    setFirstRender(false);
  }, [selectedFeedbackMeeting]);
  useEffect(() => {
    if (
      !isFirstRender &&
      !loadingUpdateFeedbackMeeting &&
      selectedPrevMeeting
    ) {
      selectPrevMeeting(
        feedbackMeetings.find(
          (meeting) => meeting.id === selectedPrevMeeting.id
        )
      );
      setEditing(false);
    }
  }, [loadingUpdateFeedbackMeeting]);

  if (!selectedFeedbackMeeting) return null;

  const onFeedbackMeetingSave = (isDone: boolean) => () => {
    setDone(isDone);
    const feedbackMeeting: Partial<FeedbackMeeting> = {
      meeting_type: 1,
      strengths: strengths,
      improvements: improvements,
      additional_modeling: additionalModeling,
      additional_practice: additionalPractice,
      next_steps: nextSteps,
      praise: selectedPraise,
      intervention_group: interventionGroup!.id,
      planning_meeting: selectedFeedbackMeeting!.id,
    };
    createFeedbackMeeting(feedbackMeeting);
  };

  const onFeedbackMeetingUpdate = () => {
    const feedbackMeeting: Partial<FeedbackMeeting> = {
      ...selectedPrevMeeting,
      planning_meeting: selectedFeedbackMeeting.planning_meeting
        ? (selectedFeedbackMeeting.planning_meeting as FeedbackMeeting).id
        : selectedFeedbackMeeting.planning_meeting,
      strengths: strengths,
      improvements: improvements,
      additional_modeling: additionalModeling,
      additional_practice: additionalPractice,
      next_steps: nextSteps,
      praise: selectedPraise,
    };
    updateFeedbackMeeting(feedbackMeeting);
  };

  const handleEditBtnClick = (meeting?: FeedbackMeeting) => (
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    selectPrevMeeting(meeting);
    setEditing(!!meeting);
    if (meeting) {
      setStrengths(meeting.strengths);
      setImprovements(meeting.improvements);
      setAdditionalModeling(meeting.additional_modeling);
      setAdditionalPractice(meeting.additional_practice);
      setNextSteps(meeting.next_steps || "");
    } else {
      setStrengths("");
      setImprovements("");
      setAdditionalModeling("");
      setAdditionalPractice("");
      setNextSteps("");
    }
  };

  const saveBtnDisabled =
    !strengths ||
    !improvements ||
    !additionalModeling ||
    !additionalPractice ||
    !nextSteps;

  return (
    <>
      <Row>
        <Col sm={5}>
          {" "}
          <TabContainer activeKey={activeTab as EventKey} onSelect={setActiveTab}>
            <Nav variant="pills" className="justify-content-center text-center">
              <Nav.Item>
                <Nav.Link
                  eventKey={TabKeys.PLANNING_NOTES}
                  style={{ height: "unset" }}
                  onSelect={() => {
                    selectPrevMeeting(undefined);
                    setEditing(false);
                  }}
                >
                  <div>Planning Notes</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey={TabKeys.PREVIOUS_MEETINGS}
                  style={{ height: "unset" }}
                >
                  <div>Previous Meetings</div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <TabContent>
              <TabPane eventKey={TabKeys.PLANNING_NOTES}>
                <div className="planningNotesContainer">
                  <h4 className="font-weight-bold">Strengths</h4>
                  <p>{selectedFeedbackMeeting.strengths}</p>
                  <h4 className="font-weight-bold">Improvements areas</h4>
                  <p>{selectedFeedbackMeeting.improvements}</p>
                  <h4 className="font-weight-bold">
                    Additional Modeling Notes
                  </h4>
                  <p>{selectedFeedbackMeeting.additional_modeling}</p>
                  <h4 className="font-weight-bold">
                    Additional Practice Needs
                  </h4>
                  <p>{selectedFeedbackMeeting.additional_practice}</p>
                </div>
              </TabPane>
              <TabPane eventKey={TabKeys.PREVIOUS_MEETINGS}>
                <div className="notesListContainer">
                  <ListGroup>
                    {feedbackMeetings.map((meeting) => (
                      <ListGroup.Item
                        key={meeting.id}
                        action
                        active={
                          selectedPrevMeeting &&
                          selectedPrevMeeting.id === meeting.id
                        }
                        onClick={() => {
                          selectPrevMeeting(meeting);
                          setEditing(false);
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h4>
                              {formatDate(meeting.created, "YYYY-MM-DD hh:mm")}
                            </h4>
                            <h3 className="font-weight-bold">
                              Meeting details
                            </h3>
                          </div>
                          <span onClick={handleEditBtnClick(meeting)}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </span>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </TabPane>
            </TabContent>
          </TabContainer>
        </Col>
        <Col>
          <h3 className="font-weight-bold">
            Planning Notes with{" "}
            {getFullName(interventionGroup!.teacher_assignment!.user)}
          </h3>
          {(!selectedPrevMeeting || editing) && (
            <div className="greyContainer my-2">
              <button
                className="blueBtnSm"
                onClick={() => openPraiseEducatorModal()}
              >
                Praise educator
              </button>
            </div>
          )}
          <Form>
            <Form.Group>
              <Form.Label>Strength</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.strengths}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={strengths}
                  onChange={(e) =>
                    setStrengths((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Improvement areas</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.improvements}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={improvements}
                  onChange={(e) =>
                    setImprovements((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Additional Modeling Needs</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.additional_modeling}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={additionalModeling}
                  onChange={(e) =>
                    setAdditionalModeling((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Additional Practice Needs</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.additional_practice}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={additionalPractice}
                  onChange={(e) =>
                    setAdditionalPractice((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Next Steps</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.next_steps}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={nextSteps}
                  onChange={(e) =>
                    setNextSteps((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
          </Form>
          <p>
            <FontAwesomeIcon icon={faInfoCircle} /> Your interview notes are
            visible to relevant educators and supervisors.
          </p>
        </Col>
      </Row>
      <hr />
      <div className="btnActions mt-3">
        <button className="whiteBtnSm" onClick={hideProvidingFeedbackModal}>
          Cancel
        </button>
        {!selectedPrevMeeting ? (
          <div>
            <button
              className="blueBtnSm mr-3"
              onClick={onFeedbackMeetingSave(false)}
              disabled={saveBtnDisabled}
            >
              Save{" "}
              {!isDone && loadingCreateFeedbackMeeting && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
            <button
              className="blueBtnSm"
              onClick={onFeedbackMeetingSave(true)}
              disabled={saveBtnDisabled}
            >
              Done{" "}
              {isDone && loadingCreateFeedbackMeeting && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          </div>
        ) : (
          editing && (
            <button
              className="blueBtnSm"
              onClick={onFeedbackMeetingUpdate}
              disabled={saveBtnDisabled}
            >
              Update{" "}
              {loadingUpdateFeedbackMeeting && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          )
        )}
      </div>
      <MeetingPromptModal />
    </>
  );
};

const mapStateToProps = ({
  cases,
  meetingModuleReducer,
}: ApplicationState): StateProps => {
  return {
    interventionGroup: cases.selectedInterventionGroup,
    feedbackMeetings: meetingModuleReducer.feedbackMeetings.filter(
      (meeting) => meeting.meeting_type
    ),
    isLoading: {
      createFeedbackMeeting:
        meetingModuleReducer.isLoading.createFeedbackMeeting,
      updateFeedbackMeeting:
        meetingModuleReducer.isLoading.updateFeedbackMeeting,
    },
    selectedPraise: meetingModuleReducer.selectedPraise,
    selectedFeedbackMeeting: meetingModuleReducer.selectedMeetingOld as FeedbackMeeting,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hideProvidingFeedbackModal: hideProvidingFeedbackModal,
      openPraiseEducatorModal: openPraiseEducatorModal,
      createFeedbackMeeting: createFeedbackMeeting,
      updateFeedbackMeeting: updateFeedbackMeeting,
      openMeetingPromptModal: openMeetingPromptModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingModalBody);
