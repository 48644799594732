import React, { Component } from "react";
import { Modal, Row } from "react-bootstrap";
import CardButton from "./CardButton";
import { faKeyboard, faUpload } from "@fortawesome/free-solid-svg-icons";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../../store";
import {
  hidePickDataSourceModal,
  openEnterDataManuallyModal,
  openUploadStudentDataModal,
} from "../../../../../store/onboarding/actions";
import UploadStudentDataModal from "../upload-student-data/UploadStudentDataModal";
import EnterDataManuallyModal from "./EnterDataManuallyModal";
import CreateAssessmentModal from "./CreateAssessmentModal";

type PropsFromState = {
  showModal: boolean;
};

type DispatchProps = {
  hidePickDataSourceModal: () => void;
  openUploadStudentDataModal: () => any;
  openEnterDataManuallyModal: () => any;
};

type Props = PropsFromState & DispatchProps;

class PickDataSourceModal extends Component<Props> {
  // componentDidUpdate(
  //   prevProps: Readonly<PropsFromState & DispatchProps>,
  //   prevState: Readonly<{}>
  // ): void {
  //   //MODAL OVERLAY CLASS HACKS
  //   if (!prevProps.showModal && this.props.showModal) {
  //     document.body.style.overflow = "hidden";
  //     document.body.classList.add("modal-open");
  //   }
  //   if (prevProps.showModal && !this.props.showModal) {
  //     document.body.style.overflow = "";
  //     document.body.classList.remove("modal-open");
  //   }
  // }

  handleModalHide = () => {
    this.props.hidePickDataSourceModal();
  };

  render() {
    const { showModal } = this.props;
    return (
      <>
        {
          //@ts-ignore
          <Modal
            show={showModal}
            size="lg"
            onHide={this.handleModalHide}
            // onShow={this.handleModalShow}
            animation={false}
            backdropClassName="customDarkModalBackdrop in"
          >
            <Modal.Header closeButton className="purpleModalHeader">
              <Modal.Title>Enter / Upload Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h2 className="text-center font-weight-semibold">
                How would you like to add data?
              </h2>
              <Row>
                <div className="collectEvidenceEmptyContainerBtns">
                  <CardButton
                    faIcon={faUpload}
                    title="Upload a File"
                    text="Upload a CSV file with a header row that includes Student ID
                  and data for at least one assessment."
                    onClick={this.props.openUploadStudentDataModal}
                  />
                  <CardButton
                    faIcon={faKeyboard}
                    title="Enter Manually"
                    text="Enter student data for an assessment manually."
                    onClick={this.props.openEnterDataManuallyModal}
                  />
                </div>
              </Row>
            </Modal.Body>
          </Modal>
        }
        <CreateAssessmentModal />
        <UploadStudentDataModal />
        <EnterDataManuallyModal />
      </>
    );
  }
}

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => ({
  showModal: onboarding.modalsState.pickDataSourceModal,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hidePickDataSourceModal: hidePickDataSourceModal,
      openUploadStudentDataModal: openUploadStudentDataModal,
      openEnterDataManuallyModal: openEnterDataManuallyModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickDataSourceModal);
