import { Student } from "../../../../../../store/onboarding/types";
import { GroupRecommendation } from "../../../../../../store/onboarding/cases/types";
import React from "react";

const useGroupCardDragging = () => {
  let shadowImageRef: HTMLDivElement;

  const getShadowImageDiv = () => {
    if (shadowImageRef) return shadowImageRef;
    const divElement = document.createElement("div");
    const text = document.createTextNode("Move 1 student");
    divElement.className = "ghostImage";
    divElement.appendChild(text);
    shadowImageRef = divElement;
    return shadowImageRef;
  };

  const onDragStart = (student: Student, prevGroup: GroupRecommendation) => (
    event: React.DragEvent<any>
  ) => {
    event.dataTransfer.setData("student", JSON.stringify(student));
    event.dataTransfer.setData("prevGroup", JSON.stringify(prevGroup));
    const divElement = getShadowImageDiv();
    event.dataTransfer.setDragImage(divElement, 175, 25);
    document.body.appendChild(divElement);
  };

  const onDragEnd = () => {
    shadowImageRef && document.body.removeChild(shadowImageRef);
  };

  return {
    onDragStart,
    onDragEnd,
  };
};

export default useGroupCardDragging;
