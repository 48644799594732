import React, { useMemo, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { dateToFormatString, secondsToHms } from "../../../../../utils/DateTimeUtils";
import {
  createUser,
  getAllDistricts,
  setShowUserModal,
  updateUser,
} from "../../../../../../store/superuser/actions";
import {
  SuperuserState,
  User_Request,
} from "../../../../../../store/superuser/types";
import Select from "react-select";
import {
  District,
  UserAssignmentRole,
  UserAssignmentRoleDisplayedNames,
  UserInfo,
} from "../../../../../../store/auth/types";
import { ValueType } from "react-select/src/types";
import _ from "lodash";
import { ReactSelectOption } from "../../../../../../store/onboarding/types";
import { useLoading } from "../../../../../../utils/hooks/useLoading";

const defaultValue: User_Request = {
  first_name: "",
  last_name: "",
  email: "",
};

type Props = {};

const UpsertUserModal: React.FC<Props> = () => {
  const {
    selectedUser,
    modalsState: {
      upsertUpsertModal: {
        show,
        district,
        role,
        name,
        onSuccess: onSuccessAdd,
      },
    },
    isLoading: { getAllDistricts: loadingGetAllDistricts },
  } = useSelector<ApplicationState, SuperuserState>((s) => s.superuser);

  const dispatch = useDispatch();

  const [user, setUser] = useState<User_Request>(defaultValue);
  const [selectedDistrictId, setSelectedDistrictId] = useState<
    number | undefined
  >();
  const [selectedRole, setSelectedRole] = useState<
    UserAssignmentRole | undefined
  >();
  const roles = useMemo(() => {
    return _.chain(UserAssignmentRole)
      .values()
      .map((key) => ({
        value: key,
        label: UserAssignmentRoleDisplayedNames[key],
      }))
      .value();
  }, []);
  const selectedRoleValue = useMemo(() => {
    return selectedRole
      ? roles.find((r) => r.value === selectedRole)
      : undefined;
  }, [roles, selectedRole]);

  const handleShow = () => {
    if (selectedUser) {
      setUser({
        first_name: selectedUser.first_name,
        last_name: selectedUser.last_name,
        email: selectedUser.email,
      });
      setSelectedDistrictId(selectedUser.profile?.district?.id);
      setSelectedRole(selectedUser.profile?.current_role);
      setAccountDisabled(!!selectedUser.account_disabled);
    } else {
      const nameParts = name?.split(" ");
      setUser({
        ...defaultValue,
        first_name: nameParts?.[0] ?? "",
        last_name: nameParts?.[1] ?? "",
      });
      setSelectedDistrictId(district ?? undefined);
      setSelectedRole(role ?? undefined);
      setAccountDisabled(false);
    }
    dispatch(getAllDistricts());
  };

  const handleClose = () => {
    if (!loading) {
      dispatch(setShowUserModal({ show: false, user: null }));
      setUser(defaultValue);
      setSelectedDistrictId(undefined);
      setSelectedRole(undefined);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (selectedUser) {
      dispatch(
        updateUser({
          ...user,
          id: selectedUser.id,
          district: selectedDistrictId!,
          role: selectedRole!,
          account_disabled: accountDisabled,
        })
      );
    } else {
      dispatch<any>(
        createUser({
          ...user,
          district: selectedDistrictId!,
          role: selectedRole!,
          account_disabled: accountDisabled,
        })
      ).then(
        (user: UserInfo) => {
          onSuccessAdd && onSuccessAdd(user.id);
        },
        () => {
          //todo
        }
      );
    }
  };

  const loading = useSelector<ApplicationState, boolean>((s) =>
    selectedUser
      ? s.superuser.isLoading.updateUser
      : s.superuser.isLoading.createUser
  );
  const error = useSelector<ApplicationState, string | undefined>((s) =>
    selectedUser ? s.superuser.errors.updateUser : s.superuser.errors.createUser
  );
  const onSuccess = () => {
    dispatch(setShowUserModal({ show: false, user: null }));
  };
  useLoading(loading, error, onSuccess);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const key = event.currentTarget.name as keyof User_Request;
    setUser((prevState) => ({ ...prevState, [key]: value }));
  };

  const districts = useSelector<ApplicationState, District[]>(
    (s) => s.superuser.districts
  );

  const selectedDistrict = useMemo(() => {
    return selectedDistrictId
      ? districts.find((d) => d.id === selectedDistrictId)
      : undefined;
  }, [selectedDistrictId, districts]);

  const handleSelectedDistrictChange = (value: ValueType<District, false>) => {
    setSelectedDistrictId(value?.id);
  };

  const handleSelectedRoleChange = (
    value: ValueType<ReactSelectOption<UserAssignmentRole>, false>
  ) => {
    setSelectedRole(value?.value);
  };

  const [accountDisabled, setAccountDisabled] = useState<boolean>(false);
  const handleAccountDisabledCheck = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = e.target.checked;
    setAccountDisabled(checked);
  };

  return (
    <Modal
      show={show}
      onShow={handleShow}
      onHide={handleClose}
      animation={false}
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="purpleModalHeader font-weight-bold">
        <Modal.Title>{selectedUser ? "Edit User" : "Create User"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="userModalForm" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label className="font-weight-bold" column={false}>
              First Name
            </Form.Label>
            <Form.Control
              required
              placeholder="Enter first name..."
              name="first_name"
              onChange={handleChange}
              value={user.first_name}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="font-weight-bold" column={false}>
              Last Name
            </Form.Label>
            <Form.Control
              required
              placeholder="Enter last name..."
              name="last_name"
              onChange={handleChange}
              value={user.last_name}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="font-weight-bold" column={false}>
              Email
            </Form.Label>
            <Form.Control
              required
              type="email"
              pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
              placeholder="Enter email..."
              name="email"
              onChange={handleChange}
              value={user.email}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="font-weight-bold" column={false}>
              District
            </Form.Label>
            <Select
              isDisabled={!!district}
              isLoading={loadingGetAllDistricts}
              isClearable
              name="district"
              placeholder="Select District..."
              options={districts}
              getOptionLabel={(d) => d.name}
              getOptionValue={(d) => d.id.toString()}
              value={selectedDistrict}
              onChange={handleSelectedDistrictChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="font-weight-bold" column={false}>
              Role
            </Form.Label>
            <Select
              isDisabled={!!role}
              name="role"
              placeholder="Select Role..."
              options={roles}
              value={selectedRoleValue}
              onChange={handleSelectedRoleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="switch"
              id="account-disabled-switch"
              label="Account Disabled"
              checked={accountDisabled}
              onChange={handleAccountDisabledCheck}
            />
        
          </Form.Group>
          <div className="bdr table-responsive">
            <table className="table table-bordered table-striped">
              <tbody>

                <tr>
                  <th>Data uploaded</th><td> {(selectedUser?.login_history_stats?.datafile_upload_count! > 0 ? 'Yes' : 'No') || "-"}</td>
                </tr>
                <tr>
                  <th>Time stamp of last login</th>
                    <td> { selectedUser?.login_history_stats?.last_login ?
                      dateToFormatString(selectedUser?.login_history_stats?.last_login!, "MM-DD-YYYY h:mm a"): "N/A"}
                    </td>
                </tr>
                <tr>
                  <th>Total number of logins</th><td> {selectedUser?.login_history_stats?.number_of_logins || "-"}</td>
                </tr>
                <tr>
                  <th>Average duration of logins</th><td> {selectedUser !== null ? secondsToHms(selectedUser.login_history_stats.avg_duration_of_logins) : "-"}</td>
                </tr>
              </tbody>
            </table>

          </div>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="blueBtnSm"
          type="submit"
          form="userModalForm"
          disabled={loading}
        >
          Save {loading && <Spinner animation="border" size="sm" />}
        </button>
        <button
          className="whiteBtnSm"
          type="reset"
          onClick={handleClose}
          disabled={loading}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpsertUserModal;
