import React, { FunctionComponent, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  AttachedResource,
  AttachedResourceType,
  AttachedToType,
} from "../../../../../../../../store/onboarding/cases/types";
import UploadUrlModal from "./UploadUrlModal";
import { toastr } from "react-redux-toastr";
import { bindActionCreators, Dispatch } from "redux";
import { uploadDocument } from "../../../../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import AttachedResourceItem from "./AttachedResource";

type DispatchProps = {
  uploadDocument: (attachedResource: AttachedResource) => any;
};

type OwnProps = {
  attachments?: AttachedResource[];
  onAttachmentsAdd: (resources: AttachedResource[]) => any;
  onAttachmentRemove?: (resourceId: number) => any;
};

type Props = OwnProps & DispatchProps;

const AddResourcesForm: FunctionComponent<Props> = (props) => {
  const { attachments, onAttachmentsAdd, onAttachmentRemove } = props;

  const uploadFileBtnRef = useRef<HTMLInputElement>(null!);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [showIEnterUrlForm, setShowEnterUrlForm] = useState<boolean>(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files; 
    
    const acceptedTypes = [
      ".doc",
      ".docx",
      ".jpg",
      ".jpeg",
      ".png",
      ".xlsx",
      ".pptx",
      ".pdf",
      ".txt",
      ".csv",
      ".ppt",
      ".pps"
    ];
    if (files) {
      for(let i in files){
        if(+i<=files.length) {
        const file =files[+i];
        
      
        const fileSizeInBytes = file?.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024); // Convert bytes to MB
  
      if (fileSizeInMB > 5) {
        toastr.error("Alert!!", "File "+file.name+" size exceeds the maximum limit of 5MB.");
        // return; // Exit the function if the file size exceeds the limit
      }
      else{
        const resourceFileType = file?.name.slice(file.name.lastIndexOf(".")).toLowerCase();
      if (acceptedTypes.includes(resourceFileType)) {
        const attachedResource: AttachedResource = {
          attached_to_type: AttachedToType.CHECKLIST_ITEM,
          resource_type: AttachedResourceType.FILE,
          attachment: file,
          title: file.name,
          mime_type: file.type,
        };
  
        setFileUploading(true);
        props.uploadDocument(attachedResource)
          .then(
            (resource: AttachedResource) => {
              onAttachmentsAdd([resource]);
            },
            (err: string) => {
              toastr.error("Failed to upload the attached file", err);
            }
          )
          .finally(() => {
            setFileUploading(false);
          });
      } else {
        toastr.error(`File type "${resourceFileType}" is not allowed.`, '');
      }
      }
      }
    }}
  };
  
  return (
    <>
      <div className="interventionStepDetailResourceContainerEmpty">
        <p className="font-weight-bold">Add Resources (Optional)</p>
        {attachments && (
          <div className="interventionStepFileContainer">
            {attachments.map((attachment) => (
              <AttachedResourceItem
                attachment={attachment}
                onAttachmentRemove={onAttachmentRemove}
                key={attachment.id}
              />
            ))}
          </div>
        )}
        <p className="mb-3">
          <span className="font-weight-bold">Accepted file types :</span> .doc, .docx, .jpg, .jpeg, .png, .xlsx, .pptx, .pdf, .txt, .csv, .ppt, .pps 
        </p>
        <button
          className="blueBtnSm"
          onClick={() => uploadFileBtnRef.current.click()}
        >
          Upload File{" "}
          {fileUploading && <Spinner animation="border" size="sm" />}
        </button>
        <input
        accept=".doc, .docx, .jpg, .jpeg, .png, .xlsx, .pptx, .pdf, .txt, .csv, .ppt, .pps"
          multiple
          ref={uploadFileBtnRef}
          style={{ display: "none" }}
          type="file"
          onChange={handleFileChange}
        />
        <button className="blueBtnSm" onClick={() => setShowEnterUrlForm(true)}>
          Enter URL
        </button>
      </div>

      {showIEnterUrlForm && (
        <UploadUrlModal
          onClose={() => setShowEnterUrlForm(false)}
          onAttachmentsAdd={onAttachmentsAdd}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      uploadDocument: uploadDocument,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(AddResourcesForm);
