import React, { Component, useEffect, useMemo, useState } from "react";
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/auth/actions";
import { Button, Modal } from "react-bootstrap";
import { push } from "connected-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignal, faTimesCircle, faUndoAlt } from "@fortawesome/free-solid-svg-icons";

type Props = {};


const NetworkDetect: React.FunctionComponent<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false)

    const handleDialogHide = () => {
     setOpen(false)
    };

    const connectionDetect = () => {
      if (!navigator.onLine) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }

    useEffect(() => {
      const interval = setInterval(() => {
        connectionDetect();
      }, 500)
  
      return () => {
        clearInterval(interval)
      }
    })

  return (
    <>
      <Modal
        id={"network-detect-modal"}
        size="sm"
        show={open}
        animation={false}
        backdropClassName="customDarkModalBackdrop in"
        onHide={handleDialogHide}
        backdrop={'static'}
        keyboard={false}
      >
        <Modal.Header
            className={`alert-danger`}
        >
            <Modal.Title>
              <h4 style={{color: "#721c24"}}><FontAwesomeIcon
                        icon={faSignal}
                        style={{color: "#721c24"}}
                        size="lg"
                        /> No internet connection</h4>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please re-connect to the internet to continue using ReadyCoach.
            If you encounter problems:
          </p>
          <ul>
            <li> Try to restart internet connection on this device. </li>
            <li> Move closer to your wireless access point. </li>
          </ul>
        <div className="text-center">
            <button className="dangerBtnSm" onClick={connectionDetect}>
               Try Again 
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
};

export default NetworkDetect;