import React, { FunctionComponent, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import {
  AttachedResource,
  AttachedResourceType,
  AttachedToType,
} from "../../../../store/onboarding/cases/types";
import { useDispatch, useSelector } from "react-redux";
import { uploadDocument } from "../../../../store/onboarding/actions";
import { addChatMessage } from "../../../../store/chat/actions";
import { useIntl } from "react-intl";
import { ApplicationState } from "../../../../store";
import { UserInfo } from "../../../../store/auth/types";

type OwnProps = {
  currentThreadId: number;
};

type Props = OwnProps;

const MessageInput: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { currentThreadId } = props;

  const [message, setMessage] = useState("");
  const [attachments, setAttachments] = useState<Array<AttachedResource>>([]);
  const [documentUploading, setDocumentUploading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null!);

  const currentUser = useSelector<ApplicationState, UserInfo | undefined>(
    (s) => s.auth.userInfo
  );

  const handleAddMessage = async () => {
    if (!message && !attachments.length) return;

    const resultMessage = {
      thread: currentThreadId,
      user: currentUser!.id,
      is_active: true,
      text: message || "",
      attachments: [],
    };

    if (attachments.length) {
      setDocumentUploading(true);
      await Promise.all(
        attachments.map((a) => dispatch<any>(uploadDocument(a)))
      )
        .then(
          (a: AttachedResource[]) =>
            (resultMessage.attachments = a.map((a) => a.id!) as any),
          (err: string) =>
            toastr.error("Failed to upload the attached files", err)
        )
        .then(() => {
          setDocumentUploading(false);
        });
    }

    dispatch<any>(addChatMessage(resultMessage)).then(() => {
      setMessage("");
      setAttachments([]);
    });
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList) {
      const files: Array<File> = Array.from(fileList);
      const attachedResources: Array<AttachedResource> = files.map((file) => ({
        attached_to_type: AttachedToType.CHAT_MESSAGE,
        resource_type: AttachedResourceType.FILE,
        attachment: file,
        title: file.name,
        mime_type: file.type,
      }));
      setAttachments((attachments) => [...attachments, ...attachedResources]);
    }
  };

  const onAttachmentRemove = (index: number) => () => {
    setAttachments((attachments) =>
      attachments.filter((a, idx) => idx !== index)
    );
  };

  return (
    <div>
      {!!attachments.length && (
        <div className="attachedFilesContainer mb-2">
          {attachments.map((attachment, index) => (
            <div className="fileCell" key={attachment.id || index}>
              <div className="fileCellName">{attachment.title}</div>
              <div
                className="fileCellRemove"
                onClick={onAttachmentRemove(index)}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  size="1x"
                  style={{ color: "#ffffff" }}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="spaceBetween">
        <div className="displayCentered">
          <span
            className="pointer mr-3"
            onClick={() => fileInputRef.current.click()}
          >
            <FontAwesomeIcon icon={faPaperclip} size={"lg"} />
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={onFileChange}
            />
          </span>
        </div>
        <input
          className="fullWidthInput mr-3"
          type="text"
          value={message}
          placeholder="Type something..."
          onKeyPress={(e) =>
            e.key === "Enter" ? handleAddMessage() : undefined
          }
          onChange={(e) => setMessage((e.target as HTMLInputElement).value)}
        />
        <div className="blueBtnMd" onClick={handleAddMessage}>
          {intl.formatMessage({
            id: "app.forms.buttons.send",
          })}
          {documentUploading && (
            <Spinner animation="border" size="sm" className="ml-1" />
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
