import React, { FunctionComponent, useMemo } from "react";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { GroupInfo } from "../../../../../../store/onboarding/types";
import BootstrapTable from "react-bootstrap-table-next";

type OwnProps = {
  groupInfo: GroupInfo[];
};

type Props = OwnProps;

const AlertsPerCoachTable: FunctionComponent<Props> = (props) => {
  const { groupInfo } = props;

  const columns = useMemo(
    () => [
      {
        dataField: "coachId",
        text: "Coach Id",
        hidden: true,
      },
      {
        dataField: "coach",
        text: "Coach Name",
        headerStyle: { width: "50%" },
        sort: true,
      },
      {
        dataField: "with_alerts_count",
        text: 'Number of groups with "alert_status"',
        sort: true,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      groupInfo.map((gi) => ({
        coachId: gi.coach.id,
        coach: getFullName(gi.coach),
        with_alerts_count: gi.with_alerts_count,
      })),
    [groupInfo]
  );

  return (
    <BootstrapTable
      classes="rosterTable"
      keyField="id"
      bordered={false}
      bootstrap4
      data={data}
      columns={columns}
      noDataIndication={"No data"}
    />
  );
};

export default AlertsPerCoachTable;
