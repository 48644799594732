import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretRight,
  faInfoCircle,
  faPlusCircle,
  faTimes,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Collapse,
  Form,
  ListGroup,
  Row,
  Spinner,
  Tab,
} from "react-bootstrap";
import { faCheckCircle, faCircle } from "@fortawesome/free-regular-svg-icons";
import {
  InterviewStudent,
  Student,
} from "../../../../../../../store/onboarding/types";
import { UserInfo } from "../../../../../../../store/auth/types";
import {
  AttachedResource,
  AttachedResourceType,
  AttachedToType,
} from "../../../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../../../store";
import { connect } from "react-redux";
import AddStudentsToInterviewModal from "./AddStudentsToInterviewModal";
import { EventKey } from "react-bootstrap/esm/types";

enum TabKeys {
  RELATIONSHIP = "RELATIONSHIP",
  BEHAVIOR = "BEHAVIOR",
  CONTEXT = "CONTEXT",
}

type StateProps = {
  isLoading: {
    createInterview: boolean;
    updateInterview?: boolean;
    createInterviewStudent: boolean;
    updateInterviewStudent?: boolean;
  };
};

type OwnProps = {
  interviewId?: number;
  attachments: Array<AttachedResource>;
  selectedTeacher: UserInfo;
  selectedStudents: Array<Student>;
  interviewDetailsList: Array<Partial<InterviewStudent>>;
  onSaveStudentInterview: (
    attachments: Array<AttachedResource>,
    interviewDetailsList: Array<Partial<InterviewStudent>>,
    completed?: boolean
  ) => void;
};

type Props = OwnProps & StateProps;

const InterviewDetailsModalBody = ({
  interviewId,
  attachments: pAttachments,
  selectedTeacher,
  selectedStudents: pSelectedStudents,
  interviewDetailsList: pInterviewDetailsList,
  onSaveStudentInterview,
  isLoading,
}: Props) => {
  const [activeKey, setActiveKey] = useState<string | null>(
    TabKeys.RELATIONSHIP
  );
  const [attachments, setAttachments] = useState<Array<AttachedResource>>([]);
  const [interviewDetailsList, setInterviewDetailsList] = useState<
    Array<Partial<InterviewStudent>>
  >([]);
  const [selectedStudents, setSelectedStudents] = useState<Array<Student>>([]);
  const [selectedStudent, setSelectedStudent] = useState<Student | undefined>(
    undefined
  );

  const [examples, setExamples] = useState({
    tip1: false,
    tip2: false,
  });

  useEffect(() => {
    setAttachments(pAttachments);
  }, [pAttachments]);

  useEffect(() => {
    setInterviewDetailsList(pInterviewDetailsList);
  }, [pInterviewDetailsList]);

  useEffect(() => {
    setSelectedStudents(pSelectedStudents);
    setSelectedStudent(
      pSelectedStudents.length ? pSelectedStudents[0] : undefined
    );
  }, [pSelectedStudents]);

  const [completed, setCompleted] = useState(false);

  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false);

  const selectedStudentInterviewDetails =
    selectedStudent &&
    interviewDetailsList.find(
      (intDetails) => intDetails.student!.id === selectedStudent.id
    );

  const handleSaveStudentInterview = (
    attachments: Array<AttachedResource>,
    interviewDetailsList: Array<Partial<InterviewStudent>>,
    completed?: boolean
  ) => () => {
    onSaveStudentInterview(attachments, interviewDetailsList, completed);
    setCompleted(!!completed);
  };

  const handleAddStudentToInterview = (students: Array<Student>) => {
    setSelectedStudents(students);
    setInterviewDetailsList((list) =>
      students.map((s) => {
        const studentInterviewDetails = list.find(
          (x) => x.student!.id === s.id
        );
        return studentInterviewDetails
          ? studentInterviewDetails
          : { student: s };
      })
    );
    setSelectedStudent((student) =>
      students.some((s) => s.id === student!.id) ? student : students[0]
    );
  };

  const getDetailsEntryStatus = (student: Student) => {
    if (selectedStudent && selectedStudent.id === student.id) {
      return "In progress";
    }
    const interviewDetail = interviewDetailsList.find(
      (x) => x.student!.id === student.id
    );
    if (
      interviewDetail &&
      interviewDetail.think_relationship &&
      interviewDetail.think_behavior &&
      interviewDetail.think_context
    ) {
      return "Completed"; //todo change
    }
    return "No details entered";
  };

  const fileInputRef = useRef<HTMLInputElement>(null!);

  const onExamplesCollapse = (exampleName: "tip1" | "tip2") => () => {
    setExamples((prevState) => ({
      ...prevState,
      [exampleName]: !prevState[exampleName],
    }));
  };

  const onSelectedStudentChange = (student: Student) => () => {
    setSelectedStudent(student);
  };

  const onSelectedStudentInterviewDetailsChange = (e: React.FormEvent<any>) => {
    const { name, value } = e.target as HTMLInputElement;
    setInterviewDetailsList((interviewDetailsList) =>
      interviewDetailsList.map((intDetails) => {
        if (selectedStudent && intDetails.student!.id === selectedStudent.id) {
          return { ...intDetails, [name]: value };
        }
        return intDetails;
      })
    );
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList) {
      const files: Array<File> = Array.from(fileList);
      const attachedResources: Array<AttachedResource> = files.map((file) => ({
        attached_to_type: AttachedToType.CHECKLIST_ITEM,
        resource_type: AttachedResourceType.FILE,
        attachment: file,
        title: file.name,
        mime_type: file.type,
      }));
      setAttachments((attachments) => [...attachments, ...attachedResources]);
    }
  };

  const onAttachmentRemove = (index: number) => () => {
    setAttachments((attachments) =>
      attachments.filter((a, idx) => idx !== index)
    );
  };

  const onInterviewRelationshipGotIt = () => {
    setInterviewDetailsList((interviewDetailsList) =>
      interviewDetailsList.map((intDetails) => {
        if (selectedStudent && intDetails.student!.id === selectedStudent.id) {
          return { ...intDetails, think_relationship: true };
        }
        return intDetails;
      })
    );
    setActiveKey(TabKeys.BEHAVIOR);
  };

  return (
    <>
      <table className="interviewStudentsTable">
        <thead>
          <tr>
            <th>CLICK A STUDENT NAME TO ENTER DETAILS</th>
            <th>
              <span onClick={() => setShowAddStudentsModal(true)}>
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  size="lg"
                  style={{
                    cursor: "pointer",
                    color: "#ffffff",
                  }}
                />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedStudents.map((student) => {
            const isActive =
              selectedStudent && selectedStudent.id === student.id;
            return (
              <tr
                key={student.id}
                className={isActive ? "activeStudentRow" : undefined}
                onClick={onSelectedStudentChange(student)}
              >
                <td>
                  {student.first_name} {student.last_name}
                </td>
                <td>{getDetailsEntryStatus(student)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {selectedStudent && selectedStudentInterviewDetails && (
        <>
          <div className="my-3">
            <div className="mb-3">
              <h4>Interview details for </h4>
              <h3 className="font-weight-bolder">
                {selectedStudent.first_name} {selectedStudent.last_name}
              </h3>
              <h4>
                Key Questions to Establish the Goal with a Target Behavior to be
                Changed
              </h4>
            </div>
            <Tab.Container
              activeKey={activeKey as EventKey}
              onSelect={(k) => setActiveKey(k)}
            >
              <Row>
                <Col sm={4}>
                  <ListGroup className="interviewListGroup">
                    {
                      //@ts-ignore
                      <ListGroup.Item action eventKey={TabKeys.RELATIONSHIP}>
                        <div className="thinkLabel">
                          <FontAwesomeIcon
                            icon={
                              selectedStudentInterviewDetails.think_relationship
                                ? faCheckCircle
                                : faCircle
                            }
                          />
                          <h4>Think Relationship</h4>
                        </div>
                        <p>
                          How can you communicate positive regard How can you
                          emphasize that you want to be helpful?
                        </p>
                      </ListGroup.Item>
                    }
                    {
                      //@ts-ignore
                      <ListGroup.Item action eventKey={TabKeys.BEHAVIOR}>
                        <div className="thinkLabel">
                          <FontAwesomeIcon
                            icon={
                              selectedStudentInterviewDetails.think_behavior
                                ? faCheckCircle
                                : faCircle
                            }
                          />
                          <h4>Think Behavior</h4>
                        </div>
                        <p>
                          What type of concern/undesirable behavior does the
                          student exhibit? Can you describe the
                          concern/undesirable behavior in objective terms so I
                          can "see" it?
                        </p>
                      </ListGroup.Item>
                    }
                    {
                      //@ts-ignore
                      <ListGroup.Item action eventKey={TabKeys.CONTEXT}>
                        <div className="thinkLabel">
                          <FontAwesomeIcon
                            icon={
                              selectedStudentInterviewDetails.think_context
                                ? faCheckCircle
                                : faCircle
                            }
                          />
                          <h4>Think Context</h4>
                        </div>
                        <p>
                          Can you describe a recent situation where the
                          concern/undesired behavior occurred?
                        </p>
                      </ListGroup.Item>
                    }
                  </ListGroup>
                </Col>
                <Col sm={8}>
                  <Tab.Content>
                    <Tab.Pane eventKey={TabKeys.RELATIONSHIP}>
                      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                        <div>
                          <h3 className="font-weight-bold">
                            Tip #1: Communicate Positive Regard
                          </h3>
                          <p className="mb-0">
                            Show your teacher that you consider them an equal
                            by...
                          </p>
                          <ol>
                            <li>Exhibiting open body language</li>
                            <li>Getting to know them</li>
                            <li>
                              Expressing genuine interest in their problems{" "}
                            </li>
                            <li>Respecting their time</li>
                            <li>Being an active listener</li>
                          </ol>
                          <div className="exampleCollapseContainer">
                            <div
                              className="d-flex align-items-center"
                              aria-controls="examples-collapse-tip1"
                              aria-expanded={examples.tip1}
                              onClick={onExamplesCollapse("tip1")}
                            >
                              <div className="mr-3">
                                <FontAwesomeIcon
                                  size={"2x"}
                                  icon={
                                    examples.tip1 ? faCaretDown : faCaretRight
                                  }
                                />
                              </div>
                              <h3 className="font-weight-bold mb-0">
                                View Examples
                              </h3>
                            </div>
                            <Collapse in={examples.tip1}>
                              <div
                                id="examples-collapse-tip1"
                                className="thinkTabExampleContainer"
                              >
                                <p className="mb-0 font-weight-bold">
                                  What does a relationship with positive regard
                                  look like?
                                </p>
                                <ol>
                                  <li>
                                    Exhibit open body language (e.g., sit next
                                    to teacher, open arms, eye contact, smile)
                                  </li>
                                  <li>
                                    Express genuine interest in working and
                                    learning from teachers (e.g., make authentic
                                    statements about teacher expertise, ask
                                    questions)
                                    <ul>
                                      <li>
                                        “I'm excited to work with you and learn
                                        about your approach to teaching and
                                        student needs"
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    Honor teacher time (e.g., in planning and
                                    implementing and monitoring interventions/
                                    strategies)
                                    <ul>
                                      <li>
                                        "We need about 30 minutes to work on
                                        goals. Is this still a good time for
                                        you?"
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    Get to know the person (e.g., ask questions
                                    about teachers' interests, inquire about
                                    what they find most rewarding)
                                  </li>
                                  <li>
                                    Be an active listener by listening more than
                                    talking and restating (e.g., confirm your
                                    understanding)
                                    <ul>
                                      <li>
                                        "What I heard you say is that you are
                                        concerned about Clyde's off task
                                        behavior during math?
                                      </li>
                                    </ul>
                                  </li>
                                </ol>
                              </div>
                            </Collapse>
                          </div>
                        </div>
                        <div>
                          <h3 className="font-weight-bold">
                            Tip #2: Provide Supportive Actions
                          </h3>
                          <p className="mb-0">
                            Show your teacher that you're on the same team by...
                          </p>
                          <ol>
                            <li>
                              Using "We" statements instead of "You" or "I"
                            </li>
                            <li>Involving them in finding solutions</li>
                            <li>
                              Connecting them with colleagues who have dealt
                              with similar issues
                            </li>
                            <li>Providing specific praise and encouragement</li>
                          </ol>
                          <div className="exampleCollapseContainer">
                            <div
                              className="d-flex align-items-center"
                              aria-controls="examples-collapse-tip2"
                              aria-expanded={examples.tip2}
                              onClick={onExamplesCollapse("tip2")}
                            >
                              <div className="mr-3">
                                <FontAwesomeIcon
                                  size={"2x"}
                                  icon={
                                    examples.tip2 ? faCaretDown : faCaretRight
                                  }
                                />
                              </div>
                              <h3 className="font-weight-bold mb-0">
                                View Examples
                              </h3>
                            </div>
                            <Collapse in={examples.tip2}>
                              <div id="examples-collapse-tip2">
                                <h3
                                  className="font-weight-bold font-italic"
                                  style={{ color: "red" }}
                                >
                                  RC TEAM: COPY REQUIRED
                                </h3>
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-3">
                        <button
                          className="blueBtnMd"
                          onClick={onInterviewRelationshipGotIt}
                        >
                          Got it
                        </button>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={TabKeys.BEHAVIOR}>
                      <h3 className="font-weight-bold">Think Behavior</h3>
                      <p>
                        What type of concern/undesirable behavior does the
                        student exhibit? Can you describe the
                        concern/undesirable behavior in objective terms so I can
                        "see" it?
                      </p>
                      <Form>
                        <Form.Control
                          as="textarea"
                          name="think_behavior"
                          value={
                            selectedStudentInterviewDetails.think_behavior || ""
                          }
                          onChange={onSelectedStudentInterviewDetailsChange}
                          placeholder={`${selectedStudent.first_name} is always picking on other kids.`}
                        />
                      </Form>
                      <p>
                        <FontAwesomeIcon icon={faInfoCircle} /> Your interview
                        notes are visible to you and relevant educators.
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey={TabKeys.CONTEXT}>
                      <h3 className="font-weight-bold">Think Context</h3>
                      <p>
                        Can you describe a recent situation where the
                        concern/undesired behavior occurred?
                      </p>
                      <Form>
                        <Form.Control
                          as="textarea"
                          name="think_context"
                          value={
                            selectedStudentInterviewDetails.think_context || ""
                          }
                          onChange={onSelectedStudentInterviewDetailsChange}
                          placeholder={`${selectedTeacher.first_name} had him/her back turned to the class when ${selectedStudent.first_name} started making trouble.`}
                        />
                      </Form>
                      <p>
                        <FontAwesomeIcon icon={faInfoCircle} /> Your interview
                        notes are visible to you and relevant educators.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
          <div>
            <h3 className="font-weight-bolder">Attach files</h3>
            <div>
              {attachments.map((attachment, index) => (
                <div className="fileCell" key={attachment.id || index}>
                  <div className="fileCellName">{attachment.title}</div>
                  <div
                    className="fileCellRemove"
                    onClick={onAttachmentRemove(index)}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      size="1x"
                      style={{ color: "#ffffff" }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <hr />
            <button
              className="whiteBtnMd2"
              onClick={() => fileInputRef.current.click()}
            >
              <FontAwesomeIcon icon={faUpload} size="lg" /> Pick a file
            </button>
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={onFileChange}
            />
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="blueBtnMd mr-3"
              onClick={handleSaveStudentInterview(
                attachments,
                interviewDetailsList
              )}
            >
              {interviewId ? "Update" : "Save"} & continue later{" "}
              {(isLoading.createInterview ||
                isLoading.updateInterview ||
                isLoading.updateInterviewStudent ||
                isLoading.createInterviewStudent) &&
                !completed && <Spinner animation="border" size="sm" />}
            </button>
            <button
              disabled={interviewDetailsList.some(
                (intDetails) =>
                  !intDetails.think_relationship ||
                  !intDetails.think_behavior ||
                  !intDetails.think_context
              )}
              className="blueBtnMd"
              onClick={handleSaveStudentInterview(
                attachments,
                interviewDetailsList,
                true
              )}
            >
              Complete interview{" "}
              {(isLoading.createInterview ||
                isLoading.updateInterview ||
                isLoading.updateInterviewStudent ||
                isLoading.createInterviewStudent) &&
                completed && <Spinner animation="border" size="sm" />}
            </button>
          </div>
        </>
      )}
      {showAddStudentsModal && (
        <AddStudentsToInterviewModal
          selectedStudents={selectedStudents}
          selectedTeacher={selectedTeacher}
          onModalHide={() => setShowAddStudentsModal(false)}
          onSelectedStudentsConfirm={handleAddStudentToInterview}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    isLoading: {
      createInterview: onboarding.isLoading.createInterview,
      updateInterview: onboarding.isLoading.updateInterview,
      createInterviewStudent: onboarding.isLoading.createInterview,
      updateInterviewStudent: onboarding.isLoading.updateInterviewStudent,
    },
  };
};

export default connect(mapStateToProps)(InterviewDetailsModalBody);
