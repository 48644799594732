import React, { FunctionComponent, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  InterventionGroup,
  StudentGoal,
} from "../../../../../store/onboarding/cases/types";
import { Overlay, Popover, Spinner } from "react-bootstrap";
import Select from "react-select";
import { Student, StudentInfo } from "../../../../../store/onboarding/types";
import { getFullName } from "../../../../../utils/NamesUtils";
import { ValueType } from "react-select/src/types";

type OwnProps = {
  group: InterventionGroup;
  onGoalEdit: (goal?: StudentGoal) => any;
};

type Props = OwnProps;

const SelectStudentGoalsPopover: FunctionComponent<Props> = (props) => {
  const { group, onGoalEdit } = props;

  const [show, setShow] = useState<boolean>(false);

  const target = useRef<any>();

  const [selectedStudent, setSelectedStudent] = useState<Student | undefined>(
    undefined
  );

  const handleSelectStudent = (value: ValueType<Student, false>) => {
    setSelectedStudent(value as Student);
  };

  const handleStudentGoalEdit = () => {
    if (selectedStudent) {
      setShow(false);
      onGoalEdit(
        group.student_goals.find(
          (goal) => goal.student.id === selectedStudent.id
        )
      );
    }
  };

  return (
    <>
      {show && <div className="customDarkPopoverBackdrop" />}

      <Overlay
        target={target.current}
        show={show}
        rootClose
        rootCloseEvent={"click"}
        onHide={() => {
          setShow(false);
        }}
      >
        <Popover id="selectStudentsPopover">
          <Popover.Title>Pick which student to edit the goal for</Popover.Title>
          <Popover.Content>
            <Select
              isClearable
              value={selectedStudent}
              onChange={handleSelectStudent}
              placeholder="Search Students..."
              getOptionLabel={(student: Student) => getFullName(student)}
              getOptionValue={(student: Student) => student.id!.toString()}
              options={group.students}
            />
            <div className="d-flex align-items-center justify-content-center">
              <button
                disabled={!selectedStudent}
                className="mt-2 blueBtnSm"
                onClick={handleStudentGoalEdit}
              >
                Edit
              </button>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>

      <span ref={target} onClick={() => setShow(true)} className="pointer">
        <FontAwesomeIcon icon={faEdit} />
      </span>
    </>
  );
};

export default SelectStudentGoalsPopover;
