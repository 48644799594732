import React, { FunctionComponent, useMemo } from "react";
import {
  BehaviorTally,
  FbaBehaviorDisplayed,
} from "../../../../../../store/onboarding/cases/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { getBehaviorTallyName } from "../abc-utils";

type Props = {
  activeTally?: BehaviorTally;
  bTally: BehaviorTally;
  onSelect: (bTally: BehaviorTally) => void;
};

const BehaviorItem: FunctionComponent<Props> = (props) => {
  const { activeTally, bTally, onSelect } = props;

  const text = useMemo(() => {
    return `${getBehaviorTallyName(bTally)} - ${bTally.count} ${
      bTally.count === 1 ? "time" : "times"
    }`;
  }, [bTally]);

  const isActive = useMemo(
    () =>
      activeTally &&
      activeTally.behavior === bTally.behavior &&
      activeTally.other_behavior === bTally.other_behavior,
    [activeTally, bTally]
  );

  const handleClick = () => {
    onSelect(bTally);
  };

  return (
    <div
      className={`behavior-item ${isActive ? "active" : ""}`}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={isActive ? faDotCircle : faCircle} />
      <p className="mb-0">{text}</p>
    </div>
  );
};

export default BehaviorItem;
