import React, { FunctionComponent, useMemo, useRef } from "react";
import { Line } from "react-chartjs-2";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import moment from "moment";
import { bindActionCreators, Dispatch } from "redux";
import {
  openGoalProgressModal,
  openInterventionFidelityModal,
} from "../../../../../../store/onboarding/meeting-module/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  changeSelectedInterventionGroup,
  showInterventionPlanModal,
} from "../../../../../../store/onboarding/cases/actions";
import { getTitleByMeasurement } from "../../../../../../utils/NamesUtils";
import {
  interventionGroupDataCount,
  interventionGroupHasEnoughData,
} from "../../../../../utils";
import { useIntl } from "react-intl";
import { ApplicationState } from "../../../../../../store";
import useUserAssignedToGroup from "./hooks/useUserAssignedToGroup";
import useInterventionPlan from "../implementation-check/hooks/useInterventionPlan";
import useUserRole from "../../../../../../utils/hooks/useUserRole";
// import { formatDate } from "../../../../../utils/DateTimeUtils";

type OwnProps = {
  interventionGroup: InterventionGroup;
};

type DispatchProps = {
  changeSelectedInterventionGroup: (group: InterventionGroup) => any;
  openInterventionFidelityModal: () => any;
  openGoalProgressModal: () => void;
};

type Props = OwnProps & DispatchProps;

const FidelityChart: FunctionComponent<Props> = ({
  interventionGroup,
  changeSelectedInterventionGroup,
  openInterventionFidelityModal,
  openGoalProgressModal,
}) => {
  const chartRef = useRef<any>(null);
  const intl = useIntl();

  const dispatch = useDispatch();
  const { isDistrictOwner } = useUserRole(); 

  const {
    showOnlyActiveInterventions,
  } = useSelector(
    (s: ApplicationState) => s.cases.modalsState.interventionPlan
  );

  const {
    implementationChecks: filteredImplementationChecks,
  } = useInterventionPlan(
    interventionGroup,
    showOnlyActiveInterventions
  );
  
  const { isUserAssignedToGroup, isStudentDetail} = useUserAssignedToGroup(interventionGroup);

  const activeView = useSelector<ApplicationState, "list" | "grid">(
    (s) => s.cases.activeGroupsView
  );

  const handleGraphClick = () => {
    if (activeView === "list") {
      changeSelectedInterventionGroup(interventionGroup);
      openInterventionFidelityModal();
    } else {
      dispatch(showInterventionPlanModal(interventionGroup));
    }
  };

  const options = {
    plugins: {
      datalabels: {
        display: false,
      },
    },
    onClick: handleGraphClick,
    layout: {
      padding: {
        top: 8,
        left: 4,
        right: 4,
        bottom: 4,
      },
    },
    tooltips: {
      enabled: false,
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const measurement =
            interventionGroup.progress_overview?.student_goal_progress[
              tooltipItem.datasetIndex
            ]?.measurement;
          return `${
            data.datasets[tooltipItem.datasetIndex].label
          } : ${getTitleByMeasurement(tooltipItem.value, measurement)}${
            data.datasets[tooltipItem.datasetIndex].yAxisID === "fidelity"
              ? "%"
              : ""
          }`;
        },
      },
      custom: (tooltip: any) => {
        let tooltipEl = document.getElementById("chartjs-tooltip");

        if (!tooltipEl) {
          tooltipEl = document.createElement("div");
          tooltipEl.id = "chartjs-tooltip";
          tooltipEl.innerHTML = "<table></table>";
          chartRef.current.chartInstance.ctx.canvas.parentNode.appendChild(
            tooltipEl
          );
        }

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = "0";
          tooltipEl.remove();
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove("above", "below", "no-transform");
        if (tooltip.yAlign) {
          tooltipEl.classList.add(tooltip.yAlign);
        } else {
          tooltipEl.classList.add("no-transform");
        }

        // Set Text
        if (tooltip.body) {
          const titleLines = tooltip.title || [];
          const bodyLines = tooltip.body.map((bodyItem: any) =>
            bodyItem.lines.map((l: string) => l + bodyItem.after)
          );

          let innerHtml = "<thead>";

          titleLines.forEach(function(title: any) {
            innerHtml += "<tr><th>" + title + "</th></tr>";
          });
          innerHtml += "</thead><tbody>";
          bodyLines.forEach((body: any, i: number) => {
            const words = body[0].split(' ');
            words.splice(0, 1); // Removes student unique id
            body[0] = words.join(' ');
            const colors = tooltip.labelColors[i];
            let style = "background:" + colors.backgroundColor;
            style += "; border-color:" + colors.borderColor;
            style += "; border-width: 2px";
            const span =
              '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
            innerHtml += "<tr><td>" + span + body + "</td></tr>";
          });
          innerHtml += "</tbody>";

          const tableRoot = tooltipEl.querySelector("table");
          if (tableRoot) {
            tableRoot.innerHTML = innerHtml;
          }
        }

        const positionY = chartRef.current.chartInstance.ctx.canvas.offsetTop;
        const positionX = chartRef.current.chartInstance.ctx.canvas.offsetLeft;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = "1";
        tooltipEl.style.left = positionX + tooltip.caretX + "px";
        tooltipEl.style.top = positionY + tooltip.caretY + "px";
        tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
        tooltipEl.style.fontSize = tooltip.bodyFontSize + "px";
        tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
        tooltipEl.style.padding =
          tooltip.yPadding + "px " + tooltip.xPadding + "px";
      },
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: false,
        },
      ],
      yAxes: [
        {
          id: "goal",
          display: false,
          type: "linear",
          gridLines: {
            display: false,
          },
          position: "right",
        },
        {
          id: "fidelity",
          ticks: {
            min: 0,
            max: 100,
          },
          gridLines: {
            display: false,
          },
          display: false,
          type: "linear",
          position: "left",
        },
      ],
    },
  };

  const observedDates: Array<string> = useMemo(() => {
    const dates: Set<any> = new Set();
    if (interventionGroup.progress_overview) {
      filteredImplementationChecks.forEach(
        (ic) => {
          dates.add(moment.utc(ic.observed_date).format("MM/DD/YYYY"));
        }
      );

      interventionGroup.progress_overview.student_goal_progress.forEach(
        (sgp) => {
          sgp.logged_progress.forEach((lp) => {
            dates.add(moment.utc(lp.date).format("MM/DD/YYYY"));
          });
        }
      );
    }

    const sortDates = (dateA?: string, dateB?: string) => {
      if (!dateA) return 1;
      if (!dateB) return -1;

      const closestADate = moment(dateA);
      const closestBDate = moment(dateB);

      return closestADate.isBefore(closestBDate)
        ? -1
        : closestADate.isAfter(closestBDate)
        ? 1
        : 0;
    };

    return Array.from(dates).sort(sortDates);
  }, [interventionGroup]);

  const fidelityDataSet = useMemo(
    () => ({
      yAxisID: "fidelity",
      label: "Intervention Fidelity",
      lineTension: 0.1,
      backgroundColor: "rgb(203,220,240)",
      borderColor: "rgb(77,145,226)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBackgroundColor: "rgb(77,145,226)",
      pointBorderColor: "rgb(77,145,226)",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgb(203,220,240)",
      pointHoverBorderColor: "rgb(77,145,226)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      type: "bar",
      data: observedDates.map((tick) => {
        const fidelityCheckOnDateTick = filteredImplementationChecks.find(
          (ic) => moment.utc(ic.observed_date).format("MM/DD/YYYY") === tick
        );
        return fidelityCheckOnDateTick
          ? fidelityCheckOnDateTick.fidelity.toFixed(0)
          : null;
      }),
    }),
    [interventionGroup]
  );

  const studentGoalData: any[] = useMemo(() => {
    const studentGoalData:
      | Array<any>
      | undefined = interventionGroup.progress_overview?.student_goal_progress
      .filter((sgp) => sgp.is_general_outcome)
      .map(
      (sgp) => {
        const data = observedDates.map((tick) => {
          const goalCheckOnDateTick = sgp.logged_progress.find((lp) => {
            return moment.utc(lp.date).format("MM/DD/YYYY") === tick;
          });
          return goalCheckOnDateTick ? goalCheckOnDateTick.value : null;
        });
        return {
          type: "line",
          yAxisID: "goal",
          spanGaps: true,
          label: `${sgp.student.unique_id} ${sgp.student.first_name} (${sgp.measurement.display_name})`,
          data: data,
          fill: false,
          lineTension: 0.1,
          borderColor: "rgb(77,145,226)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          // pointBackgroundColor: "rgb(77,145,226)",
          pointBorderColor: "rgb(77,145,226)",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(203,220,240)",
          pointHoverBorderColor: "rgb(77,145,226)",
          pointHoverBorderWidth: 2,
          // pointRadius: 1,
          pointRadius: 3,
          pointBackgroundColor: "#ffffff",
          pointHitRadius: 10,
        };
      }
    );
    return studentGoalData ? studentGoalData : [];
  }, [interventionGroup]);

  const handleAddBaselineData = (event: React.MouseEvent) => {
    event.stopPropagation();
    if(!isStudentDetail) {
      changeSelectedInterventionGroup(interventionGroup);
      openGoalProgressModal();
    }
  };

  const hasEnoughData = useMemo(
    () => interventionGroupHasEnoughData(interventionGroup),
    [interventionGroup]
  );

  const dataCount = useMemo(
    () => interventionGroupDataCount(interventionGroup),
    [interventionGroup]
  );

  if (hasEnoughData) {
    return (
      <div className="fidelityLoggedDataChart fidelityLoggedDataChartMini">
        <Line
          ref={chartRef}
          data={{
            labels: observedDates,
            datasets: isStudentDetail && !isDistrictOwner ? 
              [
                ...studentGoalData,
                (isUserAssignedToGroup ? fidelityDataSet : []),
              ]
            : [
              ...studentGoalData,
              (!isDistrictOwner ? fidelityDataSet : []),
            ]
          }}
          options={options}
          height={70}
        />
      </div>
    );
  } else {
    return (
      <div className="fidelityLoggedDataChart fidelityLoggedDataChartMini flex-column">
        <em>
          {intl.formatMessage({
            id:
              "app.dashboard.yourAssigmentTab.studentGroupItem.monitoring.notEnoughData",
          })}
        </em>
        {!isDistrictOwner ? <button className="my-2 blueBtnSm" onClick={handleAddBaselineData}>
          {dataCount >= 1 ? "Log More Progress Data" : "Add Baseline Data"}
        </button> : ''}
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openInterventionFidelityModal: openInterventionFidelityModal,
      changeSelectedInterventionGroup: changeSelectedInterventionGroup,
      openGoalProgressModal: openGoalProgressModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(FidelityChart);
