import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

type Props = { names: Array<string> };

const TeacherNamesInline = (props: Props) => {
  return (
    <p style={{ fontSize: "0.75rem", maxWidth:'75px' }} className="mb-0 text-left">
      {props.names.length > 1 ? (
        <>
          {props.names[0]} and{" "}
          <OverlayTrigger
            overlay={
              <Popover id={"teachersPopover"}>
                <Popover.Content>
                  {props.names.slice(1).join(", ")}
                </Popover.Content>
              </Popover>
            }
          >
            <strong>{props.names.length - 1}+</strong>
          </OverlayTrigger>
        </>
      ) : (
        props.names.join(", ")
      )}
    </p>
  );
};

export default TeacherNamesInline;
