import React, { FunctionComponent } from "react";

type OwnProps = {
  selectedValue: any;
  value: any;
  label: string;
  setValue: (newValue: any) => void;
};

type Props = OwnProps;

const PillButton: FunctionComponent<Props> = ({
  value,
  label,
  selectedValue,
  setValue,
}) => {
  const active = selectedValue === value;

  return (
    <div
      onClick={() => setValue(value)}
      className={active ? "purplePillBtnSelected" : "purplePillBtn"}
    >
      {label}
    </div>
  );
};

export default PillButton;
