import React, {
  FunctionComponent,
  RefObject,
  useEffect,
  useMemo,
  useState,
} from "react";
import BehaviorTable from "../../../../second-step/evidence-tab/student-data-table/behavior-dropdown/BehaviorTable";
import {
  mapStaticDataCategoryDomains,
  TargetBehaviorGroup,
} from "../../../../second-step/evidence-tab/select-target/utils";
import { ApplicationState } from "../../../../../../../store";
import { connect } from "react-redux";
import { CategoryBehavior } from "../../../../../../../store/onboarding/types";
import LoadingIndicator from "../../../../LoadingIndicator";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

type StateProps = {
  staticDataCategoryDomains: Array<CategoryBehavior>;
  customBehaviors: Array<CategoryBehavior>;
  isLoading: {
    getStaticDataCategoryDomains: boolean;
    getCustomBehaviors?: boolean;
  };
  errors: {
    getStaticDataCategoryDomains?: string;
  };
};

type OwnProps = {
  newlyCreatedCustomBehaviorsIds?: number[];
  selectedBehaviors?: Array<CategoryBehavior>;
  containerStyle?: React.CSSProperties;
  displayNegative?: boolean;
  displayPositive?: boolean;
  displayCustom?: boolean;
  onBehaviorClick: (target: CategoryBehavior) => any;
};

type Props = OwnProps & StateProps;

const BehaviorTableContainer: FunctionComponent<Props> = (props) => {
  const {
    newlyCreatedCustomBehaviorsIds,
    selectedBehaviors,
    displayNegative,
    displayPositive,
    displayCustom,
    containerStyle,
    isLoading,
    staticDataCategoryDomains,
    customBehaviors,
    onBehaviorClick,
  } = props;

  const [behaviorFilter, setBehaviorFilter] = useState("");

  const reduceDomainBehaviors = (
    pV: Array<TargetBehaviorGroup>,
    cV: TargetBehaviorGroup
  ) => {
    if (cV.domain.name.toLowerCase().includes(behaviorFilter.toLowerCase())) {
      return [...pV, cV];
    }
    const categoryBehaviors = cV.behaviors.filter((b) =>
      b.full_display_name.toLowerCase().includes(behaviorFilter.toLowerCase())
    );
    if (categoryBehaviors.length) {
      return [...pV, { ...cV, behaviors: categoryBehaviors }];
    }
    return pV;
  };

  const customCategoryDomains = useMemo(() => {
    if (customBehaviors) {
      const dataMap = mapStaticDataCategoryDomains(customBehaviors);
      if (behaviorFilter !== "") {
        return dataMap.custom.reduce(reduceDomainBehaviors, []);
      }
      return dataMap.custom;
    }
    return [];
  }, [customBehaviors, behaviorFilter]);

  const customBehaviorsRefs: { [key: number]: RefObject<any> } = useMemo(
    () =>
      (customBehaviors || []).reduce(
        (acc: { [key: number]: RefObject<any> }, value) => {
          acc[value.id!] = React.createRef();
          return acc;
        },
        {}
      ),
    [customBehaviors]
  );

  useEffect(() => {
    if (newlyCreatedCustomBehaviorsIds?.length) {
      customBehaviorsRefs[
        newlyCreatedCustomBehaviorsIds[
          newlyCreatedCustomBehaviorsIds.length - 1
        ]
      ].current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [newlyCreatedCustomBehaviorsIds, customBehaviorsRefs]);

  const dataCategoryDomains = useMemo(() => {
    const a = mapStaticDataCategoryDomains(staticDataCategoryDomains);
    if (behaviorFilter !== "") {
      return {
        positive: a.positive.reduce(reduceDomainBehaviors, []),
        negative: a.negative.reduce(reduceDomainBehaviors, []),
      };
    }
    return a;
  }, [staticDataCategoryDomains, behaviorFilter]);

  const handleBehaviorFilterChange = (event: React.FormEvent<any>) => {
    event.preventDefault();
    const { value } = event.target as HTMLInputElement;
    setBehaviorFilter(value);
  };

  const isEmpty =
    !dataCategoryDomains.negative.length &&
    !dataCategoryDomains.positive.length;

  const handleBehaviorClick = (target: CategoryBehavior) => {
    onBehaviorClick(target);
  };

  return (
    <div>
      <InputGroup className="filterSearchDesktop">
        <InputGroup.Prepend>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          type="text"
          placeholder="Filter behaviors"
          value={behaviorFilter}
          onChange={handleBehaviorFilterChange}
          className="filterSearchHeight"
        />
      </InputGroup>
      <div style={containerStyle}>
        {isLoading.getStaticDataCategoryDomains ||
        isLoading.getCustomBehaviors ? (
          <LoadingIndicator />
        ) : isEmpty ? (
          <h3>No found</h3>
        ) : (
          <div>
            <div className="behaviorsListTable mt-1">
              {displayNegative && (
                <BehaviorTable
                  isNegative
                  selectedBehaviors={selectedBehaviors}
                  targetBehaviorGroups={dataCategoryDomains.negative}
                  onTargetBehaviorSelect={handleBehaviorClick}
                />
              )}
              {displayPositive && (
                <BehaviorTable
                  selectedBehaviors={selectedBehaviors}
                  targetBehaviorGroups={dataCategoryDomains.positive}
                  onTargetBehaviorSelect={handleBehaviorClick}
                />
              )}
              {displayCustom && (
                <BehaviorTable
                  isCustom
                  highlightedBehaviorsIds={newlyCreatedCustomBehaviorsIds}
                  refs={customBehaviorsRefs}
                  selectedBehaviors={selectedBehaviors}
                  targetBehaviorGroups={customCategoryDomains}
                  onTargetBehaviorSelect={handleBehaviorClick}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    staticDataCategoryDomains: onboarding.staticDataCategoryDomains,
    customBehaviors: onboarding.customBehaviors,
    isLoading: {
      getStaticDataCategoryDomains:
        onboarding.isLoading.getStaticDataCategoryDomains,
      getCustomBehaviors: onboarding.isLoading.getCustomBehaviors,
    },
    errors: {
      getStaticDataCategoryDomains:
        onboarding.errors.getStaticDataCategoryDomains,
    },
  };
};

export default connect(mapStateToProps)(BehaviorTableContainer);
