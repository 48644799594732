import { UserInfo } from "../auth/types";
import { Grade } from "../groups/types";
import { Student } from "../onboarding/types";

type SpellingTestsAction =
  // spelling test group
  | "getSpellingTestGroups"
  | "getSpellingTestGroup"
  | "createSpellingTestGroup"
  | "changeStudentsInSpellingTestGroup"
  | "updateSpellingTestGroup"
  | "deleteSpellingTestGroup"
  //spelling tests
  | "getSpellingTest"
  | "upsertSpellingTest"
  | "deleteSpellingTest"
  // spelling test question
  | "getSpellingTestQuestions"
  // students by grade
  | "getStudentsByGrade"
  // diagnostics info
  | "generateStudentReadingDiagnostics";

export type SpellingTestsState = {
  groups: SpellingTestGroup[];
  studentsByGrade: Student[];
  selectedGroup?: SpellingTestGroup;
  selectedTest?: SpellingTest;

  tests: SpellingTest[];
  wordGroups: SpellingTestWordGroup[];

  selectedStudent?: Student;
  prevQuestionWord?: string;
  spellingTestAnswers: SpellingTestAnswer[];

  isLoading: {
    [key in SpellingTestsAction]?: boolean;
  };
  errors: {
    [key in SpellingTestsAction]?: string;
  };
  showSpellingTestGroupUpsertModal?: boolean;
  showSpellingTestSummaryModal?: boolean;
};

export type SpellingTestGroup = {
  id: number;
  teacher: UserInfo;
  notes: string;
  grade: Grade;
  tests: SpellingTest[];
  students: Student[];
};

export type SpellingTest = {
  id: number;
  name: string;
  group: SpellingTestGroup;
  student: Student;
  answers: SpellingTestAnswer[];
};

export type SpellingTestWordGroup = {
  header_label_prefix: string;
  header_label_text: string;
  words: SpellingTestQuestion[];
};

export type SpellingTestQuestion = {
  word: string;
  order: number;
  grammar_parts: SpellingTestQuestionGrammarPart[];
};

export type SpellingTestQuestionGrammarPart = {
  type: GrammarType;
  correct_part: string;
};

export enum GrammarType {
  ConsonantsInitial = "consonants_initial",
  ConsonantsFinal = "consonants_final",
  VowelsShort = "vowels_short",
  VowelsLong = "vowels_long",
  DigraphsTrigraphs = "digraphs_trigraphs",
  InitialBlends = "blends_initial",
  FinalBlends = "blends_final",
  RimeChunks = "rime_chunks",
  VcePattern = "vce_pattern",
  VowelTeams = "vowel_teams",
  RControlledVowels = "r_controlled_vowels",
  Syllables = "syllables",
  InflectionalEndings = "inflectional_endings",
  Affixes = "affixes",
  CommonSpellings = "common_spellings",
}

export const GrammarTypeDisplayValues: { [key in GrammarType]: string } = {
  [GrammarType.ConsonantsInitial]: "Initial Constants",
  [GrammarType.ConsonantsFinal]: "Final Constants",
  [GrammarType.VowelsShort]: "Short Vowels",
  [GrammarType.VowelsLong]: "Long Vowels",
  [GrammarType.DigraphsTrigraphs]: "Digraphs / Trigraphs",
  [GrammarType.InitialBlends]: "Initial Blends",
  [GrammarType.FinalBlends]: "Final Blends",
  [GrammarType.RimeChunks]: "Rime Chunk",
  [GrammarType.VcePattern]: "VCe Pattern",
  [GrammarType.VowelTeams]: "Long Vowel Sound (Vowel Teams)",
  [GrammarType.RControlledVowels]: "r-Controlled Vowels",
  [GrammarType.Syllables]: "Syllables",
  [GrammarType.InflectionalEndings]: "Inflectional Endings",
  [GrammarType.Affixes]: "Affixes",
  [GrammarType.CommonSpellings]: "Common Spellings",
};

export type SpellingTestAnswer = {
  id?: number;
  test?: number; // FK: SpellingTest
  word: string; // The original word, so the original test can be reconstructed in case it changes
  student_answer?: string; // The whole word the student wrote down
  order: number;
  grammar_parts: SpellingTestAnswerGrammarPart[];
};

export type SpellingTestAnswerGrammarPart = {
  id?: number;
  answer?: number; // FK: SpellingTestAnswer
  grammar_type: GrammarType;
  correct_part: string; // The original correct part, so the original test can be reconstructed in case it changes
  student_answer?: string; // What the student wrote down
  correct: boolean;
};

// REQUESTS TYPES

export type UpsertSpellingTestGroupActionRequest = {
  teacher: number;
  grade: Grade;
  notes: string;
};
