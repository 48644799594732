import React, { FunctionComponent } from "react";
import { EvidencePeriodData } from "../../../../../store/onboarding/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCrosshairs,
  faKeyboard,
  faStickyNote,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  addBehaviorScreenerEcgToDataPeriod,
  addExistingBehaviorEcgToDataPeriod,
  openConductInterviewModal,
  openEnterDataManuallyModal,
  openUploadStudentDataModal,
} from "../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import { GoalTypes } from "../../../../../store/onboarding/cases/types";
import useTargetBehaviorECG from "../../../../../utils/hooks/useTargetBehaviorECG";
import { openPermissionSettingModal } from "../../../../../store/help/actions";
import useBehaviorScreenerECG from "../../../../../utils/hooks/useBehaviorScreenerECG";
import useUserRole from "../../../../../utils/hooks/useUserRole";

type PropsFromState = {
  evidencePeriodData?: EvidencePeriodData;
};

type DispatchProps = {
  openUploadStudentDataModal: () => any;
  openPermissionSettingModal: (isGlobal: boolean) => any;
  openEnterDataManuallyModal: (type?: GoalTypes) => any;
  addExistingBehaviorEcgToDataPeriod: (dataPeriodId?: number) => any;
  addBehaviorScreenerEcgToDataPeriod: (dataPeriodId?: number) => any;
  openConductInterviewModal: () => any;
};

type Props = PropsFromState & DispatchProps;

const BehaviorDataTooltipContent: FunctionComponent<Props> = (props) => {
  const { onAdd: handleTargetSelect } = useTargetBehaviorECG();
  const { onAdd: handleScreenerSelect } = useBehaviorScreenerECG();
  const { isDistrictOwner } = useUserRole();

  return (
    <div>
      <div className="mx-4 my-2">
        <h3 className="font-weight-bold">Add Behavior Data to your table</h3>
        Each column represents evidence for grouping your students
      </div>

      <div className="mt-2">
        <table className="addMoreDataTooltipTable">
          <thead>
            <tr>
              <th colSpan={2}>Here are some common ways to add data</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Upload a screening data or behavior rating scale data file (CSV
                or Excel formats)
              </td>

              <td>
                <div
                  className="addColumnBtn"
                  onClick={() => {
                    isDistrictOwner ? 
                      props.openUploadStudentDataModal()
                    :
                      props.openPermissionSettingModal(false);

                    document.body.click();
                  }}
                >
                  <span className="customCircleSpan">
                    <FontAwesomeIcon icon={faUpload} size="lg" />
                  </span>
                  <div className="d-flex flex-column">
                    <strong>Upload a File</strong>
                    <small>
                      We support many reading and behavior assessments
                    </small>
                  </div>
                </div>
              </td>
            </tr>
          {!isDistrictOwner ? 
            <>
              <tr>
                <td>
                  Get started by following a guideded interview of teacher's
                  you're coaching
                </td>
                <td>
                  <div
                    className="addColumnBtn"
                    onClick={() => {
                      props.openConductInterviewModal();
                      document.body.click();
                    }}
                  >
                    <span className="customCircleSpan">
                      <FontAwesomeIcon icon={faStickyNote} size="lg" />
                    </span>
                    <div className="d-flex flex-column">
                      <strong>Interview Teachers</strong>
                      <small>
                        Talk to teachers about student issues and record your
                        notes
                      </small>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  Find your rating scale and type in the results directly into the
                  data table
                </td>
                <td>
                  <div
                    className="addColumnBtn"
                    onClick={() => {
                      props.openEnterDataManuallyModal(GoalTypes.BEHAVIOR);
                      document.body.click();
                    }}
                  >
                    <span className="customCircleSpan">
                      <FontAwesomeIcon icon={faKeyboard} size="lg" />
                    </span>
                    <div className="d-flex flex-column">
                      <strong>Behavior Rating Scales</strong>
                      <small>Pick an assessment and type in student scores</small>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  Select specific behaviors to focus on from our list or describe
                  it manually
                </td>
                <td>
                  <div
                    className="addColumnBtn"
                    onClick={() => {
                      handleTargetSelect();
                      document.body.click();
                    }}
                  >
                    <span className="customCircleSpan">
                      <FontAwesomeIcon icon={faCrosshairs} size="lg" />
                    </span>
                    <div className="d-flex flex-column">
                      <strong>Select Target Behaviors</strong>
                      <small>
                        Choose student behaviors from a list or type in a custom
                        one
                      </small>
                    </div>
                  </div>
                </td>
              </tr>
            </>        
          : '' }
            <tr>
              <td>
                Select individual students to screen for externalizing and internalizing behaviors
              </td>
              <td>
                <div
                  className="addColumnBtn"
                  onClick={() => {
                    handleScreenerSelect();
                    document.body.click();
                  }}
                >
                  <span className="customCircleSpan">
                    <FontAwesomeIcon icon={faCrosshairs} size="lg" />
                  </span>
                  <div className="d-flex flex-column">
                    <strong>Add Behavior Screener</strong>
                    <small>
                      Allow behavior screening via the Student Risk Screen Scale (SRSS)
                    </small>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    evidencePeriodData: onboarding.evidencePeriodData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openUploadStudentDataModal: openUploadStudentDataModal,
      openPermissionSettingModal: openPermissionSettingModal,
      openEnterDataManuallyModal: openEnterDataManuallyModal,
      openConductInterviewModal: openConductInterviewModal,
      addExistingBehaviorEcgToDataPeriod: addExistingBehaviorEcgToDataPeriod,
      addBehaviorScreenerEcgToDataPeriod: addBehaviorScreenerEcgToDataPeriod,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BehaviorDataTooltipContent);
