import React, { FunctionComponent, useMemo } from "react";
import { ChatThread } from "../../../../store/chat/types";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { UserInfo } from "../../../../store/auth/types";
import { getFullName } from "../../../../utils/NamesUtils";
import { changeSelectedChatThread } from "../../../../store/chat/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { InterventionGroup } from "../../../../store/onboarding/cases/types";

type OwnProps = {
  chatThread: ChatThread;
};

type Props = OwnProps;

const ChatHeader: FunctionComponent<Props> = (props) => {
  const { chatThread } = props;

  const dispatch = useDispatch();

  const currentUser = useSelector<ApplicationState, UserInfo | undefined>(
    (s) => s.auth.userInfo
  );

  const interventionGroups = useSelector<ApplicationState, InterventionGroup[]>(
    (s) => s.cases.interventionGroups
  );

  const interventionGroup = useMemo(() => {
    if (!chatThread.intervention_group) {
      return null;
    }

    return interventionGroups.find(
      (interventionGroup) =>
        interventionGroup.id === chatThread.intervention_group
    );
  }, [chatThread.intervention_group, interventionGroups]);

  const title = useMemo(() => {
    const names = chatThread.participants
      .filter((user) => user.id !== currentUser?.id)
      .map(getFullName)
      .join(", ");

    return interventionGroup ? `${interventionGroup.name}: ${names}` : names;
  }, [
    chatThread.intervention_group,
    chatThread.participants,
    currentUser,
    interventionGroup?.name,
  ]);

  return (
    <div className="spaceBetween">
      <span className="font-weight-bold">{title}</span>
      <span
        className="pointer"
        onClick={() => dispatch(changeSelectedChatThread(undefined))}
      >
        <FontAwesomeIcon
          style={{ color: "#5e7179" }}
          icon={faTimes}
          size="lg"
        />
      </span>
    </div>
  );
};

export default ChatHeader;
