import React, { FunctionComponent, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { InterventionGroup } from "../../../../store/onboarding/cases/types";
import { ChatThread } from "../../../../store/chat/types";
import { UserInfo } from "../../../../store/auth/types";

type OwnProps = {
  chatThread: ChatThread;
  onCloseChatThread: Function;
};

type Props = OwnProps;

const ChatGroupThreadName: FunctionComponent<Props> = (props) => {
  const { onCloseChatThread, chatThread } = props;

  const intl = useIntl();

  const interventionGroups = useSelector<ApplicationState, InterventionGroup[]>(
    (s) => s.cases.interventionGroups
  );

  const interventionGroup = useMemo(
    () =>
      interventionGroups.find(
        (interventionGroup) =>
          interventionGroup.id === chatThread.intervention_group
      ),
    [chatThread.intervention_group, interventionGroups]
  );

  const currentUser = useSelector<ApplicationState, UserInfo | undefined>(
    (s) => s.auth.userInfo
  );

  const interlocutors = useMemo(() => {
    return chatThread.participants.filter(
      (user) => user.id !== currentUser?.id
    );
  }, [chatThread.participants, currentUser?.id]);

  if (!interventionGroup) {
    return null;
  }

  return (
    <>
      <div className="spaceBetween">
        <span className="chatThreadTypeLabel">
          {intl.formatMessage({
            id: "app.chats.thread.type.intervention.label",
          })}
        </span>
        <div
          className="chatThreadDeleteButton"
          onClick={(e) => {
            e.stopPropagation();
            onCloseChatThread();
          }}
        >
          x
        </div>
      </div>
      <div className="pointer">
        <div>{interventionGroup.name}</div>
        {interlocutors.map((interlocutor) => (
          <div className="d-flex" key={interlocutor.id}>
            <div className="displayCentered">
              <FontAwesomeIcon
                icon={faUser}
                size={"sm"}
                className="mr-2"
                style={{ color: "#b0bec5" }}
              />
            </div>
            <div style={{ fontSize: "14px" }}>
              {interlocutor.first_name + " " + interlocutor.last_name}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ChatGroupThreadName;
