import React, { FunctionComponent } from "react";
import { changeCollapsedAreas } from "../../../../../../store/onboarding/actions";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";

type OwnProps = {
  title: string;
  sectionKey: string;
};

type Props = OwnProps;

const SectionToggleButton: FunctionComponent<Props> = (props) => {
  const { title, sectionKey } = props;

  const dispatch = useDispatch();

  const collapsedAreas = useSelector(
    (s: ApplicationState) => s.onboarding.districtSummaryOptions.collapsedAreas
  );

  const handleCollapseArea = (key: string) => (e: React.MouseEvent) => {
    dispatch(
      changeCollapsedAreas({
        key: key,
        value: !collapsedAreas[key],
      })
    );
  };

  return (
    <button
      onClick={handleCollapseArea(sectionKey)}
      className={collapsedAreas[sectionKey] ? "blueBtnMd" : "whiteBtnMd2"}
    >
      {title}
    </button>
  );
};

export default SectionToggleButton;
