import React, { FunctionComponent, useMemo, useState } from "react";
import {
  BehaviorTally,
  Fba,
} from "../../../../../../store/onboarding/cases/types";
import FocusPanel from "./FocusPanel";
import { Table } from "react-bootstrap";
import _ from "lodash";
import AbcItemRow from "./AbcItemRow";
import SelectedAntecedentAndConsequence from "./SelectedAntecedentAndConsequence";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faSortAlphaDown,
} from "@fortawesome/free-solid-svg-icons";
import SortIcon from "./SortIcon";
import {
  getAntecedentNaming,
  getBehaviorNaming,
  getConsequenceNaming,
} from "../hooks";

type Props = {
  fba: Fba;
  selectedBehTally: BehaviorTally;
  selectedAntecedent:
    | { abcId: number; antecedents: number[]; other_antecedents?: string[] }
    | undefined;
  setSelectedAntecedent: React.Dispatch<
    React.SetStateAction<
      | { abcId: number; antecedents: number[]; other_antecedents?: string[] }
      | undefined
    >
  >;
  selectedConsequence:
    | { abcId: number; consequence: number; other_sequence?: string }
    | undefined;
  setSelectedConsequence: React.Dispatch<
    React.SetStateAction<
      | { abcId: number; consequence: number; other_sequence?: string }
      | undefined
    >
  >;
  onContinue: Function;
};

export enum AbcField {
  Antecedent,
  Behavior,
  ConsequenceAction,
  ConsequenceFunction,
}

export type AbcSorting = {
  field: AbcField;
  order: "asc" | "desc";
};

const AbcTableBody: FunctionComponent<Props> = (props) => {
  const {
    selectedBehTally,
    fba,
    selectedAntecedent,
    setSelectedAntecedent,
    selectedConsequence,
    setSelectedConsequence,
    onContinue,
  } = props;

  const abcList = useMemo(() => {
    return _.chain(fba.observations)
      .flatMap((obs) => obs.observationabc)
      .map((obsAbc) => {
        const consequence = getConsequenceNaming(
          obsAbc.consequence,
          obsAbc.other_sequence
        );
        return {
          abc: obsAbc,
          [AbcField.Antecedent]: getAntecedentNaming(
            obsAbc.antecedents,
            obsAbc.other_antecedents,
            true
          ),
          [AbcField.Behavior]: getBehaviorNaming(
            obsAbc.behaviors,
            obsAbc.other_behavior
          ),
          [AbcField.ConsequenceAction]: consequence
            ?.map((c) => c.consequence)
            .join(", "),
          [AbcField.ConsequenceFunction]: consequence
            ?.map((c) => c.function)
            .join(", "),
        };
      })
      .sortBy(({ abc }) => new Date(abc.created))
      .value();
  }, [fba.observations]);

  const [sorting, setSorting] = useState<AbcSorting | undefined>(undefined);

  const abcSortedList = useMemo(() => {
    if (sorting) {
      return _.orderBy(abcList, [sorting.field], [sorting.order]);
    } else {
      return _.sortBy(abcList, ({ abc }) => new Date(abc.created));
    }
  }, [abcList, sorting]);

  const handleAbcSort = (field: AbcField) => () => {
    setSorting((s) =>
      s && s.field === field
        ? s.order === "asc"
          ? { ...s, order: "desc" }
          : undefined
        : { field: field, order: "asc" }
    );
  };

  return (
    <div>
      <FocusPanel bTally={selectedBehTally!} />
      <p className="text-center my-3">
        Now, investigate which antecedents or behaviors are influencing the
        behavior. Sort the table by each column to find a pattern and click
        which antecedent and/or consequence you think is the primary cause of
        the behavior.
      </p>

      <SelectedAntecedentAndConsequence
        selectedAntecedent={selectedAntecedent}
        setSelectedAntecedent={setSelectedAntecedent}
        selectedConsequence={selectedConsequence}
        setSelectedConsequence={setSelectedConsequence}
      />

      <Table
        striped
        bordered
        hover
        className="review-abc-data-table text-center mt-3"
      >
        <thead>
          <tr>
            <th
              colSpan={2}
              rowSpan={2}
              onClick={handleAbcSort(AbcField.Antecedent)}
            >
              Antecedent{" "}
              <SortIcon originalField={AbcField.Antecedent} sorting={sorting} />
            </th>
            <th rowSpan={2} onClick={handleAbcSort(AbcField.Behavior)}>
              Behavior{" "}
              <SortIcon originalField={AbcField.Behavior} sorting={sorting} />
            </th>
            <th colSpan={3} className="non-interactive">
              Consequence
            </th>
          </tr>
          <tr>
            <th colSpan={2} onClick={handleAbcSort(AbcField.ConsequenceAction)}>
              Action{" "}
              <SortIcon
                originalField={AbcField.ConsequenceAction}
                sorting={sorting}
              />
            </th>
            <th onClick={handleAbcSort(AbcField.ConsequenceFunction)}>
              Function{" "}
              <SortIcon
                originalField={AbcField.ConsequenceFunction}
                sorting={sorting}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {abcSortedList.map(({ abc }) => (
            <AbcItemRow
              key={abc.id}
              abc={abc}
              selectedAntecedent={selectedAntecedent}
              setSelectedAntecedent={setSelectedAntecedent}
              selectedConsequence={selectedConsequence}
              setSelectedConsequence={setSelectedConsequence}
            />
          ))}
        </tbody>
      </Table>

      <div className="text-center">
        <button
          className="blueBtnSm"
          onClick={() => onContinue()}
          disabled={!selectedAntecedent && !selectedConsequence}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default AbcTableBody;
