import React, { FunctionComponent } from "react";
import { behaviorTypes } from "./types";
import { Col, Row } from "react-bootstrap";
import FbaListItem from "./FbaListItem";
import { FbaABC } from "../../../../../../store/onboarding/cases/types";
import { usePrevCustomRecords } from "../hooks";

type Props = {
  observationabc: FbaABC[];

  behaviorDescriptions: {
    [behaviorId: number]: string | undefined;
  };
  setBehaviorDescriptions: React.Dispatch<
    React.SetStateAction<{
      [behaviorId: number]: string | undefined;
    }>
  >;

  behaviorsCustom: string | undefined;
  setBehaviorsCustom: React.Dispatch<React.SetStateAction<string | undefined>>;

  behaviors: number[];
  setBehaviors: React.Dispatch<React.SetStateAction<number[]>>;
};

const Behaviors: FunctionComponent<Props> = (props) => {
  const {
    observationabc,
    behaviors,
    setBehaviors,
    behaviorsCustom,
    setBehaviorsCustom,
    behaviorDescriptions,
    setBehaviorDescriptions,
  } = props;

  const handleCustomOptionChange = (value?: string) => {
    setBehaviorsCustom(value);
  };

  const handleBehaviorsChange = (fbaBehavior: number) => () => {
    const isUncheck = behaviors.includes(fbaBehavior);

    setBehaviors((behaviors) =>
      isUncheck
        ? behaviors.filter((fb) => fb !== fbaBehavior)
        : [...behaviors, fbaBehavior]
    );
    setBehaviorDescriptions((behaviorDescriptions) =>
      isUncheck
        ? { ...behaviorDescriptions, [fbaBehavior]: undefined }
        : behaviorDescriptions
    );
  };

  const handleBehaviorDescriptionChange = (
    behaviorId: number,
    value?: string
  ) => {
    setBehaviorDescriptions((behaviorDescriptions) => ({
      ...behaviorDescriptions,
      [behaviorId]: value,
    }));
  };

  const allCustomRecords: string[] = usePrevCustomRecords(
    observationabc,
    "other_behavior",
    behaviorsCustom
  );

  return (
    <div>
      <h4 className="text-center font-weight-bold text-underline mb-3">
        Select one or more options from the column below
      </h4>
      <Row>
        {behaviorTypes.map((at, index) => (
          <Col
            key={`header_column_${index}`}
            sm={12}
            className="font-weight-bold"
          >
            {at.title}
          </Col>
        ))}
        {behaviorTypes.map((at, index) => (
          <Col key={`options_column_${index}`} sm={12}>
            {at.items.map((item) => (
              <FbaListItem
                key={item.value}
                index={index}
                onChange={handleBehaviorsChange}
                fbaItem={item}
                activeItem={behaviors}
                customOption={behaviorsCustom}
                onCustomOptionChange={handleCustomOptionChange}
                previousCustomRecords={allCustomRecords}
                behaviorDescription={
                  behaviorDescriptions && behaviorDescriptions[item.value]
                }
                onBehaviorDescriptionChange={handleBehaviorDescriptionChange}
              />
            ))}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Behaviors;
