import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import UserAvatarSmall from "../../components/common/UserAvatarSmall";
import { UserInfo } from "../../store/auth/types";

type PropsFromState = {
  userInfo?: UserInfo;
};

const mapStateToProps = ({ auth }: ApplicationState): PropsFromState => {
  return {
    userInfo: auth.userInfo,
  };
};

export default connect(mapStateToProps)(UserAvatarSmall);
