import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import {
  FilledInvite,
  InviteInfo,
  InviteToken,
  UserCredential,
} from "../../store/auth/types";
import { ApplicationState } from "../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  acceptInvite,
  getInviteInfo,
  loginUser,
} from "../../store/auth/actions";
import { connect } from "react-redux";
import LoadingIndicator from "../common/onboarding/LoadingIndicator";
import { push } from "connected-react-router";
import { toastr } from "react-redux-toastr";
import { IS_READY_COACH } from "../../constants";
import { TermsAndConditionsModal } from "./terms-and-conditions/TermsAndConditionsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

type PropsFromState = {
  isLoading: {
    getInviteInfo: boolean;
    acceptInvite: boolean;
  };
  errors: {
    getInviteInfo?: string;
    acceptInvite?: string;
  };
  inviteInfo?: InviteInfo;
};

type DispatchProps = {
  loginUser: (user: UserCredential) => any;
  push: (location: string) => any;
  getInviteInfo: (inviteToken: InviteToken) => any;
  acceptInvite: (filledInvite: FilledInvite) => any;
};

type MatchParams = {
  code: string;
};

type Props = PropsFromState & DispatchProps & RouteComponentProps<MatchParams>;

type State = {
  first_name: string;
  last_name: string;
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
  passwordsMatch: boolean;
  showTermsAndConditionsModal: boolean;
  attributeType: string;
  attributeConfirmType: string;
};

class InvitePage extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      code: "",
      password: "",
      confirmPassword: "",
      passwordsMatch: true,
      showTermsAndConditionsModal: false,
      attributeType: "password",
      attributeConfirmType: "password",
    };
  }

  componentDidMount() {
    try {
      const token: InviteToken = JSON.parse(atob(this.props.match.params.code));
      this.setState({ code: token.code, email: token.email });
      this.props.getInviteInfo(token);
    } catch (e) {
      console.error(e);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (
      prevProps.isLoading.getInviteInfo &&
      !this.props.isLoading.getInviteInfo &&
      this.props.inviteInfo
    ) {
      this.setState({
        first_name: this.props.inviteInfo.receiver.first_name,
        last_name: this.props.inviteInfo.receiver.last_name,
      });
    }
    if (
      prevProps.isLoading.acceptInvite &&
      !this.props.isLoading.acceptInvite
    ) {
      if (!this.props.errors.acceptInvite) {
        this.props.loginUser({
          username: this.state.email,
          password: this.state.password,
        });
      } else {
        toastr.error("Error", this.props.errors.acceptInvite);
      }
    }
  }

  handleShowTACModal = () => {
    this.setState({ showTermsAndConditionsModal: true });
  };

  handleHideTACModal = () => {
    this.setState({ showTermsAndConditionsModal: false });
  };

  handleChange = (event: React.FormEvent<any>) => {
    const { name, value } = event.target as HTMLInputElement;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      passwordsMatch: true,
    }));
  };

  handleSubmit = (event: React.FormEvent<any>) => {
    event.preventDefault();
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ passwordsMatch: false });
      return;
    }
    this.handleShowTACModal();
  };

  acceptInvite = () => {
    const filledInvite: FilledInvite = {
      code: this.state.code,
      email: this.state.email,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      password: this.state.password,
    };
    this.props.acceptInvite(filledInvite);
  };

  passwordShow = () => {
    this.setState({
      ...this.state,
      attributeType: this.state.attributeType === "password" ? "text": "password",
    })
  };

  confirmPasswordShow = () => {
    this.setState({
      ...this.state,
      attributeConfirmType: this.state.attributeConfirmType === "password" ? "text": "password",
    })
  };

  render() {
    const {
      email,
      password,
      confirmPassword,
      first_name,
      last_name,
      showTermsAndConditionsModal,
      attributeType,
      attributeConfirmType
    } = this.state;
    const { isLoading, inviteInfo } = this.props;
    if (isLoading.getInviteInfo) return <LoadingIndicator />;

    const appName = IS_READY_COACH ? "ReadyCoach" : "NJTSS Early Reading";
    return (
      <div>
        {inviteInfo ? (
          <>
            <Container className="loginContainer">
              <Row className="align-items-center">
                <Col sm>
                  <h1 className="hMax">
                    Sign up for <strong>{appName}</strong>
                  </h1>
                  <br />
                  <p className="text-white text-center">
                    You can sign up your district for <strong>{appName}</strong>
                  </p>
                  <p className="text-white text-center">
                    Once you have an account you'll be able to invite your staff
                    and begin coaching.
                  </p>
                </Col>
                <Col sm>
                  <div className="welcomeInputContainer">
                    <div>
                      <h3 className="font-weight-bold">
                        Hi there, {inviteInfo.receiver.first_name}!
                      </h3>
                      <h3>
                        {`${inviteInfo.sent_by.first_name} ${inviteInfo.sent_by.last_name}`}{" "}
                        has invited you to create an account to manage your
                        classes. Choose a password and let's get you started!
                      </h3>
                      <br />
                    </div>
                    <Form onSubmit={this.handleSubmit}>
                      <FormGroup controlId="validation_email">
                        <FormLabel>
                          <h4 className="font-weight-bold mb-1">Email</h4>
                        </FormLabel>
                        <FormControl
                          required
                          autoFocus
                          name={"email"}
                          type="email"
                          value={email}
                          onChange={this.handleChange}
                          className="fullWidthInput"
                        />
                      </FormGroup>
                      <FormGroup controlId="validation_first_name">
                        <FormLabel>
                          <h4 className="font-weight-bold mb-1">First Name</h4>
                        </FormLabel>
                        <FormControl
                          required
                          name={"first_name"}
                          type="text"
                          value={first_name}
                          onChange={this.handleChange}
                          className="fullWidthInput"
                        />
                      </FormGroup>
                      <FormGroup controlId="validation_last_name">
                        <FormLabel>
                          <h4 className="font-weight-bold mb-1">Last Name</h4>
                        </FormLabel>
                        <FormControl
                          required
                          name={"last_name"}
                          type="text"
                          value={last_name}
                          onChange={this.handleChange}
                          className="fullWidthInput"
                        />
                      </FormGroup>
                      <FormGroup controlId="validation_password">
                        <FormLabel>
                          <h4 className="font-weight-bold mb-1">Password</h4>
                        </FormLabel>
                        <div className="input-container">
                          <FormControl
                            required
                            minLength={8}
                            maxLength={20}
                            name={"password"}
                            value={password}
                            onChange={this.handleChange}
                            type={attributeType}
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                          />
                          <FontAwesomeIcon  
                              onClick={this.passwordShow}  
                              icon={attributeType == 'password' ? faEyeSlash : faEye} 
                              className="icon-eye"
                            />
                        </div>
                        <small>Passwords must be 8 to 20 characters long and include at least one uppercase letter, 
                    number and special character. eg. Example@1</small>
                      </FormGroup>
                      <FormGroup controlId="validation_confirmPassword">
                        <FormLabel>
                          <h4 className="font-weight-bold mb-1">
                            Confirm Password
                          </h4>
                        </FormLabel>
                        <div className="input-container">
                          <FormControl
                            required
                            minLength={8}
                            maxLength={20}
                            name={"confirmPassword"}
                            value={confirmPassword}
                            onChange={this.handleChange}
                            type={attributeConfirmType}
                            isInvalid={!this.state.passwordsMatch}
                          />
                          <FontAwesomeIcon  
                              onClick={this.confirmPasswordShow}  
                              icon={attributeConfirmType == 'password' ? faEyeSlash : faEye} 
                              className="icon-eye"
                            />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          Passwords do not match.
                        </Form.Control.Feedback>
                      </FormGroup>
                      <div className="text-center">
                        <label className="blueBtnMd">
                          <Button type="submit">Sign Up</Button>
                        </label>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
            <TermsAndConditionsModal
              showModal={showTermsAndConditionsModal}
              handleHideModal={this.handleHideTACModal}
              isLoading={isLoading.acceptInvite}
              onAccept={this.acceptInvite}
            />
          </>
        ) : (
          <Container>
            <Alert variant="danger">
              <Alert.Heading>
                Sorry, the invite you clicked is no longer valid
              </Alert.Heading>
            </Alert>
          </Container>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }: ApplicationState): PropsFromState => {
  return {
    isLoading: {
      getInviteInfo: auth.isLoading.getInviteInfo,
      acceptInvite: auth.isLoading.acceptInvite,
    },
    errors: {
      getInviteInfo: auth.errors.getInviteInfo,
      acceptInvite: auth.errors.acceptInvite,
    },
    inviteInfo: auth.inviteInfo,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      loginUser: loginUser,
      push: push,
      getInviteInfo: getInviteInfo,
      acceptInvite: acceptInvite,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InvitePage);
