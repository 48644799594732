import React from "react";
import PrivacyPolicy from "../terms-and-conditions/PrivacyPolicy";

export default function RCPrivacyPolicy() {
  return (
    <div className="privacyContainer">
      <div className="WordSection1">
        <PrivacyPolicy />
        {/* <p className="MsoNormal">
          <b>ONLINE PRIVACY POLICY AGREEMENT </b>
        </p>

        <p className="MsoNormal">
          Please contact Alexander Kurz for more information.
        </p> */}
      </div>
    </div>
  );
}
