export const createErrorMessage = (obj: any): string => {
  if (typeof obj === "string" || obj instanceof String) {
    return obj as string;
  }

  return Object.entries(obj).reduce((resMessage, [key, value]) => {
    let nextMessage = key + ": ";
    if (Array.isArray(value)) {
      nextMessage += value.map((el) => el).join(", ");
    } else if (typeof value === "string" || value instanceof String) {
      nextMessage += value;
    } else {
      nextMessage += createErrorMessage(value);
    }
    return resMessage + nextMessage + "\n";
  }, "");
};
