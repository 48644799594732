import React, { FunctionComponent, useMemo } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import Joyride, { CallBackProps, Step } from "react-joyride";
import { ApplicationState } from "../../../../store";
import { markFirstUploadTourAsShown } from "../../../../store/tour/actions";
import {
  DataPeriod,
  EvidencePeriodData,
} from "../../../../store/onboarding/types";
import usePrevProps from "../../../../utils/hooks/usePrevProps";
import useUserRole from "../../../../utils/hooks/useUserRole";

type StateProps = {
  currentDataPeriod?: DataPeriod;
  firstUploadTourShown?: boolean;
  evidencePeriodData?: EvidencePeriodData;
};
type DispatchProps = {
  markFirstUploadTourAsShown: () => any;
};

type Props = StateProps & DispatchProps;

const FirstDataUploadTour: FunctionComponent<Props> = (props) => {
  const prevProps = usePrevProps({
    studentRowsLength: props.evidencePeriodData?.student_rows.length,
  });

  const show: boolean = useMemo(() => {
    return !!(
      !props.firstUploadTourShown &&
      prevProps?.studentRowsLength === 0 &&
      props.evidencePeriodData?.student_rows.length
    );
  }, [props.evidencePeriodData?.student_rows.length]);

  // console.log(show, props.firstUploadTourShown);

  const steps: Step[] = useMemo(
    () => [
      {
        disableBeacon: true,
        target: "#data-period-panel",
        content: (
          <>
            You are viewing data for <b>{props.currentDataPeriod?.name}</b>. You
            can view other data by clicking on this dropdown. You can also
            create data periods with the edit button.
          </>
        ),
      },
      {
        disableBeacon: true,
        target: ".dataTooltipBody > button:first-child",
        content: <>You can also add more data by clicking here.</>,
      },
      {
        disableBeacon: true,
        target: ".studentGroupDropContainer > p",
        content: (
          <>
            Using your data, you can group students by dragging their names
            here.
          </>
        ),
      },
      {
        disableBeacon: true,
        target: "#changeMenuBarPositionBtn",
        content: (
          <>
            And if you want more space, click here to make the table wider and
            move this menu to the top of this screen. You can always move it
            back later.
          </>
        ),
      },
    ],
    [props.currentDataPeriod?.id]
  );

  const handleTourChange = (data: CallBackProps) => {
    if (["skip", "reset"].includes(data.action)) {
      props.markFirstUploadTourAsShown();
    }
  };

  const { isTeacher, isSupervisor } = useUserRole();

  if (props.firstUploadTourShown || isTeacher || isSupervisor) {
    return null;
  }

  return (
    <Joyride
      steps={steps}
      disableScrolling
      floaterProps={{ disableAnimation: true }}
      showSkipButton
      continuous
      callback={handleTourChange}
      run={show}
      styles={{
        options: {
          primaryColor: "#00265B",
        },
      }}
    />
  );
};

const mapStateToProps = ({
  tour,
  onboarding,
}: ApplicationState): StateProps => {
  return {
    currentDataPeriod: onboarding.currentDataPeriod,
    firstUploadTourShown: tour.firstUploadTourShown,
    evidencePeriodData: onboarding.evidencePeriodData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      markFirstUploadTourAsShown: markFirstUploadTourAsShown,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirstDataUploadTour);
