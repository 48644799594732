import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import BRSInput from "../subpages/brs/BRSInput";
import {
  EvidenceAssessment,
  Measurement,
} from "../../../../../../store/onboarding/types";
import {
  ConcernArea,
  GoalTypes,
  GroupRecommendation,
  InterventionGroup,
  InterventionGroupRequest,
  StudentGoal,
  StudentGoalForGroup,
} from "../../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../../store";
import { connect, useDispatch, useSelector } from "react-redux";
import SetDeadLine from "../subpages/SetDeadLine";
import { dateToFormatString } from "../../../../../utils/DateTimeUtils";
import { bindActionCreators, Dispatch } from "redux";
import useRecommendationGroupCards from "../../../../reading-interventions/intervention-tools/recommend-groups/hooks/useRecommendationGroupCards";
import {
  addInterventionImmediately,
  changeSelectedInterventionGroup,
  createStudentGoalForInterventionGroup,
  createStudentGoalForStudent,
  discontinueIntGroupInt,
  hideGroupingStudentsModal,
  hideInterventionPlanModal,
  hideSetGoalChooseConcernModal,
  openInterventionLibraryModal,
  resetGoalChooseConcernModel,
  showInterventionPlanModal,
  unsaveInterventionGroup,
} from "../../../../../../store/onboarding/cases/actions";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import useHighlightGroupCard from "../../../../reading-interventions/intervention-tools/recommend-groups/hooks/useHighlightGroupCard";
import useUnsavedGroupCards from "../../../../reading-interventions/intervention-tools/recommend-groups/hooks/useUnsavedGroupCards";

type StateProps = {
  evidenceAssessments: Array<EvidenceAssessment>;
  interventionGroup: InterventionGroup;
  studentGoal?: StudentGoal;
  setMissedGoal?: boolean;
};

type DispatchProps = {
  createStudentGoalForInterventionGroup: (
    interventionGroupId: number,
    goal: StudentGoalForGroup
  ) => any;
  createStudentGoalForStudent: (
    interventionGroupId: number,
    studentId: number,
    goal: StudentGoalForGroup,
    isMultiAPICallsForSetGoal?: boolean
  ) => any;
  hideSetGoalChooseConcernModal: () => any;
  hideGroupingStudentsModal: () => any;
  hideInterventionPlanModal: () => any;
  discontinueIntGroupInt: (
    interventionGroupId: number,
    // interventionId: number,
    students: Array<number>,
    reason: string,
    allStudentSelected: boolean,
  ) => any;
};

type OwnProps = {
  isDiffMeasurement?: any;
  setIsDiffMeasurement?: (isDiffMeasurement: boolean) => any;
  isStudentGoalExist?: boolean;
  isGeneralOutcomeMeasure?: boolean;
  setIsGeneralOutcomeMeasure?: (isGeneralOutcomeMeasure?: boolean) => any;
};

type Props = OwnProps & StateProps & DispatchProps;

enum BrsSteps {
  INTRO,
  // SET_OUTCOME,
  SET_DEADLINE,
}

const BrsTab: FunctionComponent<Props> = ({
  evidenceAssessments,
  studentGoal: pStudentGoal,
  interventionGroup,
  createStudentGoalForStudent,
  createStudentGoalForInterventionGroup,
  hideSetGoalChooseConcernModal,
  hideGroupingStudentsModal,
  hideInterventionPlanModal,
  discontinueIntGroupInt,
  isDiffMeasurement,
  setIsDiffMeasurement,
  isStudentGoalExist,
  setMissedGoal,
}) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [targetValue, setTargetValue] = useState<string>("");
  const [targetDate, setTargetDate] = useState<Date | undefined>(undefined);
  const [selectedBrsMeasurement, setSelectedBrsMeasurement] = useState<
    Measurement | undefined
  >();
 

  const studentGoalOptions = useSelector(
    (s: ApplicationState) => s.cases.modalsState.studentGoalOptions
  );

  const { onSaveGroup } = useRecommendationGroupCards();
  const { highlightGroup } = useHighlightGroupCard();

  const notFinishedGroups = useSelector<ApplicationState, InterventionGroup[]>(
    (s) => s.cases.notFinishedGroups
  );

  const tabName = useSelector(
    (s: ApplicationState) => s.cases.tabName
  );

  const measurements: Array<Measurement> = useMemo(
    () =>
      evidenceAssessments.reduce(
        (pV, cV) => [...pV, ...cV.measurements],
        [] as Array<Measurement>
      ),
    [evidenceAssessments]
  );

  useEffect(() => {
    if (pStudentGoal?.id) {
      setSelectedBrsMeasurement(
        measurements.find((m) => m.id === pStudentGoal.measurement.id)
      );
      setTargetValue(pStudentGoal.target_value ?? "");
      setTargetDate(
        pStudentGoal.target_date
          ? moment(pStudentGoal.target_date, "YYYY-MM-DD").toDate()
          : undefined
      );
    }
  }, [pStudentGoal]);

  // const hasBehavioralGoalType: boolean = useMemo( //original
  //   () => interventionGroup?.goal_type === GoalTypes.BEHAVIOR,
  //   [interventionGroup]
  // );
  const hasBehavioralGoalType: boolean = useMemo(
    () => { return false;},
    [interventionGroup]
  );

  const [isSameGoalForAllStudents, setIsSameGoalForAllStudents] = useState<
    boolean
  >(false);

  const [customTargetOutcome, setCustomTargetOutcome] = useState<{
    [studentId: number]: string;
  }>({});

  const [ isGeneralOutcomeMeasure, setIsGeneralOutcomeMeasure ] 
  = useState<boolean | undefined>(false);

  const selectedStudentGoal = useSelector<
  ApplicationState,
  StudentGoal | undefined
  >((s) => s.cases.selectedStudentGoal);

  useEffect(() => {
  setIsGeneralOutcomeMeasure!(selectedStudentGoal?.is_general_outcome)
  },[])

  const setGeneralOutcome = (isGeneralOutcome?: boolean) => {
    setIsGeneralOutcomeMeasure!(isGeneralOutcome)
  }
  

  //const [isRedirectToSetDeadline, setIsRedirectToSetDeadline] = useState(true)

  const content = useMemo(() => {
    switch (step) {
      case BrsSteps.INTRO:
        return (
          <BRSInput
            hasBehavioralGoalType={hasBehavioralGoalType}
            onNextClick={(selectedBrsMeasurement: Measurement) => {
              setStep(BrsSteps.SET_DEADLINE);
              setSelectedBrsMeasurement(selectedBrsMeasurement);
            }}
            onBackClick={() => null}
            setIsDiffMeasurement={setIsDiffMeasurement}
            isStudentGoalExist={isStudentGoalExist}
            selectedBrsMeasurement={selectedBrsMeasurement}
            // isRedirectToSetDeadline = {isRedirectToSetDeadline}
            // setIsRedirectToSetDeadline = {setIsRedirectToSetDeadline}
          />
        );
      // case BrsSteps.SET_OUTCOME:
      //   return (
      //     <BrsSetOutcome
      //       targetValue={targetValue}
      //       setTargetValue={setTargetValue}
      //       selectedBrsMeasurement={selectedBrsMeasurement!}
      //       onBackClick={() => setStep(BrsSteps.INTRO)}
      //       onNextClick={() => setStep(BrsSteps.SET_DEADLINE)}
      //     />
      //   );
      case BrsSteps.SET_DEADLINE: {
        const setGoal = (currentBaseline?: string) => {
          let studentGoal: StudentGoalForGroup = {
            concern_area: (tabName == 'groups/behavior') ? ConcernArea.BEHAVIOR : ConcernArea.ACADEMIC,
            measurement_id: selectedBrsMeasurement!.id!,
            target_date: dateToFormatString(targetDate!),
            target_value: +targetValue!,
            goal_statement: studentGoalOptions?.goalStatement,
            current_baseline: currentBaseline,
            is_primary_goal: pStudentGoal?.is_primary_goal,
            is_general_outcome: pStudentGoal?.is_primary_goal ? true : isGeneralOutcomeMeasure,
            ...(setMissedGoal ? {set_missed_goal: setMissedGoal} : '') //create the multiple group on missed goal
          };

          if(pStudentGoal?.id) {
            studentGoal = {
              ...studentGoal,
              goal_id: pStudentGoal?.id,
            }
          } else {
            studentGoal = {
              ...studentGoal,
              goal_id: undefined,
              create_new_goal: true
            }
          }

          if(isDiffMeasurement) {
            createNewGroupWithUpdatedGoal(interventionGroup, studentGoal);
          } else if(pStudentGoal) {
            createStudentGoalForStudent(
              interventionGroup.id!,
              pStudentGoal.student.id!,
              studentGoal
            ).then(
              () => hideSetGoalChooseConcernModal(),
              (err: any) => toastr.error("Error", err)
            );
          } else {
            if (isSameGoalForAllStudents) {
              createStudentGoalForInterventionGroup(
                interventionGroup.id!,
                studentGoal
              ).then(
                (updatedStudentGroup: InterventionGroup) => {
                  if (studentGoalOptions) {
                    dispatch(addInterventionImmediately(updatedStudentGroup));
                  } else {
                    hideSetGoalChooseConcernModal();
                  }
                },
                (err: any) => toastr.error("Error", err)
              );
            } else {
              Promise.all(
                interventionGroup.students
                .filter((std) => !interventionGroup.student_goals.some((sg) => sg.student.id == std.id))
                .map((student) =>
                  createStudentGoalForStudent(
                    interventionGroup.id!,
                    student.id!,
                    {
                      ...studentGoal,
                      ...(!isNaN(+customTargetOutcome[student.id!]) ? { target_value: +customTargetOutcome[student.id!] } : ''),
                    },
                    true
                  )
                )
              ).then(
                (data) => {
                  if (data?.length) {
                    if (studentGoalOptions) {
                      dispatch(
                        addInterventionImmediately(
                          data[data.length].intervention_group
                        )
                      );
                    } else {
                      hideSetGoalChooseConcernModal();
                    }
                  }
                },
                (err: any) => toastr.error("Error", err)
              );
            }
          }
        };
        const createNewGroupWithUpdatedGoal = (
          interventionGroup:InterventionGroup, 
          studentGoal: StudentGoalForGroup
          ) => { 
          let oldInterventionGroup = JSON.parse(JSON.stringify(interventionGroup));
          let group: GroupRecommendation = {
            ...interventionGroup,
            group_copied_from: interventionGroup.id,
            focuses: interventionGroup.focuses || [],
            name: `COPY: ${interventionGroup.name}`,
            default_interventionist_id: interventionGroup.teacher_assignment!.user.id,
            default_coach_id: interventionGroup.coach_assignment!.id,
          }
          onSaveGroup(group,true,(isCreateNewGroupWithUpdateGoal:boolean, newInterventionGroup:InterventionGroup) => {
            if(isCreateNewGroupWithUpdateGoal) {
              hideGroupingStudentsModal();
              createStudentGoalForInterventionGroup(
                newInterventionGroup.id!,
                studentGoal
              ).then(
                (updatedStudentGroup: InterventionGroup) => {
                  if (studentGoalOptions) {
                    dispatch(addInterventionImmediately(updatedStudentGroup));
                  } else {
                    hideSetGoalChooseConcernModal();
                  }
                  hideInterventionPlanModal();
                  if(interventionGroup.finished) { //blue group
                    discontinueIntGroupInt(
                      interventionGroup.id!,
                      interventionGroup.students.map((s) => s.id!),
                      'Changed the measure for goal and archive goals and group',
                      true
                    )
                  } else if(!interventionGroup.finished) { // green group
                    let oldGroup: GroupRecommendation = {
                      ...oldInterventionGroup,
                      focuses: oldInterventionGroup.focuses || [],
                      group_copied_from: null,
                      default_interventionist_id: oldInterventionGroup.teacher_assignment!.user.id,
                      default_coach_id: oldInterventionGroup.coach_assignment!.id,
                    }
                    const notFinishedInterGroup = notFinishedGroups.find(
                      (ig) => ig.id === oldGroup.id 
                    );
                    if (notFinishedInterGroup) {
                      dispatch<any>(
                        unsaveInterventionGroup(notFinishedInterGroup, oldGroup)
                      );
                    }
                  }
                },
                (err: any) => toastr.error("Error", err)
              ).then(() => {
                  highlightGroup(
                    `employee_assignment_${newInterventionGroup.teacher_assignment?.id}`,
                    newInterventionGroup.id
                  );
              });
            }
          }
          )
        }
        return (
          <SetDeadLine
            targetDate={targetDate}
            setTargetDate={setTargetDate}
            existingGoal={!!pStudentGoal?.id}
            selectedMeasurement={selectedBrsMeasurement!}
            targetValue={targetValue}
            setTargetValue={setTargetValue}
            onBackClick={(selectedBrsMeasurement: Measurement) => {
              setStep(BrsSteps.INTRO); 
              setIsDiffMeasurement!(false);
              setSelectedBrsMeasurement(selectedBrsMeasurement);
            }}
            setGoal={setGoal}
            isSameGoalForAllStudents={isSameGoalForAllStudents}
            setIsSameGoalForAllStudents={setIsSameGoalForAllStudents}
            customTargetOutcome={customTargetOutcome}
            setCustomTargetOutcome={setCustomTargetOutcome}
            isGeneralOutcomeMeasure={isGeneralOutcomeMeasure}
            setGeneralOutcome = {setGeneralOutcome}
            isDiffMeasurement={isDiffMeasurement}
          />
        );
      }
    }
  }, [
    step,
    selectedBrsMeasurement,
    targetValue,
    targetDate,
    pStudentGoal,
    studentGoalOptions,
    customTargetOutcome,
    isSameGoalForAllStudents,
    isGeneralOutcomeMeasure
  ]);
  
  return <div>{content}</div>;
};


const mapStateToProps = ({
  cases,
  onboarding,
}: ApplicationState): StateProps => {
  return {
    evidenceAssessments: onboarding.evidenceAssessments,
    interventionGroup: cases.selectedInterventionGroup!,
    studentGoal: cases.selectedStudentGoal,
    setMissedGoal: cases.modalsState.setMissedGoal
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      createStudentGoalForInterventionGroup: createStudentGoalForInterventionGroup,
      createStudentGoalForStudent: createStudentGoalForStudent,
      hideSetGoalChooseConcernModal: hideSetGoalChooseConcernModal,
      hideGroupingStudentsModal: hideGroupingStudentsModal,
      hideInterventionPlanModal: hideInterventionPlanModal,
      discontinueIntGroupInt: discontinueIntGroupInt
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BrsTab);
