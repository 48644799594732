import React, { FunctionComponent } from "react";
import {
  Measurement,
  MeasurementType,
} from "../../../../../../store/onboarding/types";
import { FormControl, InputGroup } from "react-bootstrap";

type OwnProps = {
  measurement: Measurement;
  value?: string;
  required?: boolean;
  onChange: (e: React.ChangeEvent<any>) => void;
};

type Props = OwnProps;

const GoalInputGroup: FunctionComponent<Props> = ({
  onChange,
  measurement,
  value,
  required,
}) => {
  if (measurement.type === MeasurementType.CATEGORY) {
    return (
      <InputGroup>
        <FormControl
          required={required}
          as="select"
          name="value"
          className="p-0 px-2"
          value={value}
          onChange={onChange}
        >
          <option value={undefined} />
          {measurement.possible_values!.map((item, index) => (
            <option key={index} value={item.order}>
              {item.display_name}
            </option>
          ))}
        </FormControl>
      </InputGroup>
    );
  } else {
    const isPercentageType = measurement.type === MeasurementType.PERCENTAGE;

    return (
      <InputGroup>
        <FormControl
          required={required}
          type="number"
          value={value || ""}
          min={isPercentageType ? 0 : measurement.range_min}
          max={isPercentageType ? 100 : measurement.range_max}
          name="value"
          onKeyDown={ (evt:any) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
          onChange={onChange}
          aria-describedby="measurement-type"
          style={{ fontSize: "1rem" }}
        />
        {isPercentageType && (
          <InputGroup.Append>
            <InputGroup.Text id="measurement-type">%</InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
    );
  }
};

export default GoalInputGroup;
