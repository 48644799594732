import { action, createAction } from "typesafe-actions";
import { HelpActionTypes, LOCALE } from "./types";
import { Dispatch } from "redux";
import axios, { AxiosError } from "axios";
import { API_CONTACT_SUPPORT, API_GET_LATEST_RELEASE } from "../../constants";
import { Release_Notes } from "../superuser/types";

export const openHelpVideoModal = () =>
  action(HelpActionTypes.OPEN_HELP_VIDEO_MODAL);
export const hideHelpVideoModal = () =>
  action(HelpActionTypes.HIDE_HELP_VIDEO_MODAL);

export const openContactSupportModal = () =>
  action(HelpActionTypes.OPEN_CONTACT_SUPPORT_MODAL);
export const hideContactSupportModal = () =>
  action(HelpActionTypes.HIDE_CONTACT_SUPPORT_MODAL);

export const getContactSupportRequest = () =>
  action(HelpActionTypes.GET_CONTACT_SUPPORT_START);
export const getContactSupportSuccess = () =>
  action(HelpActionTypes.GET_CONTACT_SUPPORT_SUCCESS);
export const getContactSupportFailure = (message: string) =>
  action(HelpActionTypes.GET_CONTACT_SUPPORT_FAILURE, { error: message });

export const submitSupportRequest = (
  role: string,
  phone_number: string,
  content: string
) => {
  return (dispatch: Dispatch) => {
    dispatch(getContactSupportRequest());
    return axios
      .post(API_CONTACT_SUPPORT, { role, phone_number, content })
      .then(() => {
        dispatch(getContactSupportSuccess());
      })
      .catch((err: AxiosError) => {
        dispatch(getContactSupportFailure(err.message));
      });
  };
};

export const changeDisplayedBar = () =>
  action(HelpActionTypes.CHANGE_DISPLAYED_BAR);

export const changeViewOfDataTable = () =>
  action(HelpActionTypes.CHANGE_VIEW_OF_DATA_TABLE);

export const changeLocale = createAction("CHANGE_LOCALE")<LOCALE>();

export const openSelectLanguageModal = createAction(
  "OPEN_SELECT_LANGUAGE_MODAL"
)();
export const hideSelectLanguageModal = createAction(
  "HIDE_SELECT_LANGUAGE_MODAL"
)();

export const openPageHelperModal = createAction(
  "@help/OPEN_PAGE_HELPER_MODAL"
)();
export const hidePageHelperModal = createAction(
  "@help/HIDE_PAGE_HELPER_MODAL"
)();

export const openPermissionSettingModal = (isGlobal: boolean) =>
  action(HelpActionTypes.OPEN_PERMISSION_SETTING_MODAL, {isGlobal});
export const hidePermissionSettingModal = () => 
  action(HelpActionTypes.HIDE_PERMISSION_SETTING_MODAL);

export const getLatestReleaseRequest = () =>
  action(HelpActionTypes.GET_LATEST_RELEASE_REQUEST);

export const getLatestReleaseSuccess = (latestRelease:Release_Notes) =>
  action(HelpActionTypes.GET_LATEST_RELEASE_SUCCESS, {
    latestRelease
  });

export const getLatestReleaseFailure = (message: string) =>
  action(HelpActionTypes.GET_LATEST_RELEASE_FAILURE, {
    error: message,
  });

export const getLatestRelease = () => {
    return (dispatch: Dispatch) => {
      dispatch(getLatestReleaseRequest());
      return axios
        .get(API_GET_LATEST_RELEASE)
        .then((res) => {
          const latestRelease: Release_Notes = res.data;
          dispatch(getLatestReleaseSuccess(latestRelease));
          dispatch(openReleaseModal());
        })
        .catch((err: AxiosError) => {
          dispatch(getLatestReleaseFailure(err.message));
        });
    };
  };
export const openReleaseModal = () =>
  action(HelpActionTypes.OPEN_RELEASE_MODAL);
export const hideReleaseModal = () => 
  action(HelpActionTypes.HIDE_RELEASE_MODAL);
