import React from "react";
import { Modal } from "react-bootstrap";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { changeIntGroupAssigmentCollapsedSettings, hideConfirmRecommendModal } from "../../../../../../store/onboarding/actions";
import { DataPeriod, EvidenceData, EvidencePeriodData } from "../../../../../../store/onboarding/types";
import { IS_READY_COACH } from "../../../../../../constants";
import { activeTabUrl, openGroupRecommendationFromData } from "../../../../../../store/onboarding/cases/actions";
import { CoachDashboardTabKeys } from "../../../../../pages/coach-dashboard/CoachDashboard";
import useActiveTab from "../../../../../pages/parent-dashboard/useActiveTab";

type PropsFromState = {
  showModal: boolean;
  currentDataPeriod?: DataPeriod;
  hasAcademic?: boolean;
  hasBehavior?: boolean;
  evidencePeriodData?: EvidencePeriodData
};

type DispatchProps = {
  hideConfirmRecommendModal: () => any;
  push: (location: string) => any;
  activeTabUrl: (tabName:string) =>any;
  openGroupRecommendationFromData: () => void;
  changeIntGroupAssigmentCollapsedSettings: (collapseSettting: any) => void;
};

const useActiveTabWrapper = (Component:any) => (props:Props) => {
  const {changeActiveTab} = useActiveTab()
  return <Component {...props} changeActiveTab={changeActiveTab} />;
};
type Props = PropsFromState & DispatchProps;

const RECOMMENDATIONS_ASSIGMENT_ID = -999; // just some uniq value to store in locale storage
class ConfirmRecommendModal extends React.Component<any, any> {
  constructor(props:Props) {
    super(props);
  }
  
  handleYes = (queryStr:string = '') => {
   
    if (IS_READY_COACH) {       
        this.props,activeTabUrl(queryStr);
        if(queryStr == 'groups/behavior') {
          this.props.push('/cases/'+ CoachDashboardTabKeys.YOUR_BEHAVIOUR_ASSIGNMENTS);
          this.props.changeActiveTab(CoachDashboardTabKeys.YOUR_BEHAVIOUR_ASSIGNMENTS)
        } else {
          this.props.push('/cases/'+ CoachDashboardTabKeys.YOUR_READING_ASSIGNMENTS);
          this.props.changeActiveTab(CoachDashboardTabKeys.YOUR_READING_ASSIGNMENTS)

        }
        this.props.openGroupRecommendationFromData();
    } else {
      const { currentDataPeriod } = this.props;
      this.props.push(
        currentDataPeriod
          ? "/group_recommendations/" + currentDataPeriod.id
          : ""
      );
    }
    this.props.hideConfirmRecommendModal();
  };

  render() {
    const { showModal } = this.props;
    return (
      <Modal
        show={showModal}
        onHide={this.props.hideConfirmRecommendModal}
        animation={false}
        backdropClassName="customDarkModalBackdrop in"
        size="sm"
      >
        <Modal.Header
          closeButton
          className={`${
            IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"
          } font-weight-bold`}
        >
          Confirm
        </Modal.Header>
        <Modal.Body>
          {this.props.hasBehavior && this.props.hasAcademic ?
          <>
          <div className="text-center mb-3">
            <h4>
              Your Data-period contains both type of data ( Behavior and Academic ). Which Groups you want to see ? 
            </h4>
          </div>
            <div className="d-flex">
              <button className="blueBtnSm w-100 mr-1" onClick={() => this.handleYes('groups/behavior')}>
                Behavior
              </button>
              <button className="blueBtnSm w-100" onClick={() => this.handleYes('groups/reading')}>
                Academic
              </button>
            </div> 
              <hr/>
              <button
                className="blueBtnSm w-100 mr-3"
                onClick={this.props.hideConfirmRecommendModal}
              >
                Cancel
              </button>
          </>
          :
          <>
            <div className="text-center mb-3">
              <h4>
                Are you sure? Some students identified as needing intervention do
                not have diagnostic data.
              </h4>
            </div>
            <div className="d-flex">
              <button
                className="blueBtnSm w-100 mr-3"
                onClick={this.props.hideConfirmRecommendModal}
              >
                No
              </button>
              <button className="blueBtnSm w-100" onClick={() => this.handleYes(this.props.hasBehavior ? 'groups/behavior' : 'groups/reading')}>
                Yes
              </button>
            </div>
          </>
          }
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    showModal: onboarding.modalsState.confirmRecommendModal,
    currentDataPeriod: onboarding.currentDataPeriod,
    hasBehavior: onboarding.modalsState.hasBehavior,
    hasAcademic: onboarding.modalsState.hasAcademic,
    evidencePeriodData: onboarding.evidencePeriodData
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideConfirmRecommendModal: hideConfirmRecommendModal,
      push: push,
      activeTabUrl: activeTabUrl,
      openGroupRecommendationFromData: openGroupRecommendationFromData,
      changeIntGroupAssigmentCollapsedSettings: changeIntGroupAssigmentCollapsedSettings
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(useActiveTabWrapper(ConfirmRecommendModal));
