import React, { FunctionComponent } from "react";
import Welcome from "./learn-more/Welcome";
import LearnMoreModal from "./learn-more/LearnMoreModal";
import EvidenceInfoTab from "../../../../common/onboarding/second-step/evidence-tab/EvidenceInfoTab";
import GroupingStudentInfo from "./learn-more/GroupingStudentInfo";
import useUserRole from "../../../../../utils/hooks/useUserRole";

type OwnProps = {
  tableOnly?: boolean;
};

type Props = OwnProps;

const YourDataAndInterviewsTab: FunctionComponent<Props> = (props) => {
  const { tableOnly } = props;
  const { isCoach } = useUserRole();

  return (
    <>
      {isCoach && (
        <>
          <Welcome />
          <GroupingStudentInfo />
          <LearnMoreModal />
        </>
      )}

      <EvidenceInfoTab tableOnly={tableOnly} />
    </>
  );
};

export default YourDataAndInterviewsTab;
