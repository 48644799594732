import React, { FunctionComponent, useEffect, useMemo } from "react";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Select from "react-select";
import {
  Grade,
  TimePeriod,
  TimePeriodShortOptions,
} from "../../../../../../store/groups/types";
import {
  ColorCriteria,
  EvidencePeriodData,
  GradeLevels,
} from "../../../../../../store/onboarding/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { ValueType } from "react-select/src/types";

type OwnProps = {
  selectedGrade: Grade | undefined;
  setSelectedGrade: React.Dispatch<React.SetStateAction<Grade | undefined>>;
  selectedTimePeriod: TimePeriod | undefined;
  setSelectedTimePeriod: React.Dispatch<
    React.SetStateAction<TimePeriod | undefined>
  >;
  evidenceColumnGroupId: number | undefined;
  filterDisabled: boolean;
  setFilterDisabled: React.Dispatch<React.SetStateAction<any | undefined>>;

};

type Props = OwnProps;

const BenchmarkFilters: FunctionComponent<Props> = (props) => {
  let {
    selectedGrade,
    setSelectedGrade,
    selectedTimePeriod,
    setSelectedTimePeriod,
    evidenceColumnGroupId,
    filterDisabled,
    setFilterDisabled
  } = props;
 const customColorCriteria = useSelector<ApplicationState, ColorCriteria[]>(
    (s) => s.onboarding.measurementCustomColorCriteria
  );

  const evidencePeriodData = useSelector<ApplicationState,EvidencePeriodData | undefined >(
    (s) => s.onboarding.evidencePeriodData);
  
  useEffect(() => {
    
    const withReadAlgoCheck = evidencePeriodData?.evidence_column_groups.find(
      (evidenceData)=> evidenceData.assessment !== null && evidenceData.id == evidenceColumnGroupId);
      
      if((withReadAlgoCheck != null) && 
      (withReadAlgoCheck?.assessment.is_system_assessment == false) 
      && (withReadAlgoCheck?.assessment.is_behavior == false))
      {
        if(withReadAlgoCheck?.assessment.use_grade_specific_color_criteria == false){
          setSelectedGrade(undefined);
          setSelectedTimePeriod(undefined);
          setFilterDisabled(true);
        } else {
          setFilterDisabled(false);
        }
      } 
  }, [evidencePeriodData])
    
  const customColorCriteriaOptions = useMemo(
    () =>
      customColorCriteria.length ? [{ label: "All", value: undefined }] : [],
    [customColorCriteria.length]
  );

  const gradeOptions = useMemo(
    () => [
      ...customColorCriteriaOptions,
      ...GradeLevels.map((gl) => ({
        label: gl.value,
        value: gl.value,
      })),
    ],
    [customColorCriteriaOptions]
  );

  const timePeriodOptions = useMemo(
    () => [...customColorCriteriaOptions, ...TimePeriodShortOptions],
    [customColorCriteriaOptions]
  );

  const timePeriodDisplayLabels: { [key: string]: string } = useMemo(
    () => ({
      boy: "Beginning",
      moy: "Middle",
      eoy: "End",
    }),
    []
  );

  const handleGradeLevelChange = (newValue?: ValueType<any, false>) => {
    const grade = newValue.value as Grade;
    setSelectedGrade(grade);
  };

  const handleTimePeriodChange = (newValue?: ValueType<any, false>) => {
    const timePeriod = newValue.value as TimePeriod;
    setSelectedTimePeriod(timePeriod);
  };
  return (
    <Row>
      <Col>
        <h4 className="font-weight-bold m-0">GRADE LEVEL</h4>
        <Select
          className="dataFiltersSelect"
          value={
            {
              value: selectedGrade,
              label: selectedGrade || "All",
            } as any
          }
          onChange={handleGradeLevelChange}
          options={gradeOptions}
          isDisabled={filterDisabled}
          />
      </Col>
      <Col>
        <h4 className="font-weight-bold m-0">TIME PERIOD</h4>
        <Select
          value={
            {
              value: selectedTimePeriod,
              label: selectedTimePeriod
              ? timePeriodDisplayLabels[selectedTimePeriod]
              : "All",
            } as any
          }
          onChange={handleTimePeriodChange}
          options={timePeriodOptions}
          isDisabled={filterDisabled}
        />
      </Col>
    </Row>
  );
};

export default BenchmarkFilters;
