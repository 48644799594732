export type BadgeStatus = {
  progress?: any;
  completed: boolean;
};

export type Badge = {
  id: number;
  name: string;
  description: string;
  icon?: string;
  status?: BadgeStatus;
};

export type BadgesState = {
  loadingStates: {
    getBadges: boolean;
  };
  badges?: Badge[];
  showNewAchievementsModal?: boolean;
  newAchievements: Badge[];
};

export enum BadgesActionTypes {
  OPEN_NEW_ACHIEVEMENTS_MODAL = "OPEN_NEW_ACHIEVEMENTS_MODAL",
  HIDE_NEW_ACHIEVEMENTS_MODAL = "HIDE_NEW_ACHIEVEMENTS_MODAL",
}
