import { ActionType } from "typesafe-actions";
import * as groupsActions from "./actions";
import { Reducer } from "redux";
import { GroupsActionTypes, GroupsState } from "./types";
import { actions } from "react-redux-toastr";

export type GroupsAction = ActionType<typeof groupsActions>;

const initialState: GroupsState = {
  skillFocusInfo: [],
  identifiersOrderList: [],
  identifiersInfo: {},
  overviewModelType:'', //Rakesh Bhargava 6 april 2022
  modalsState: {
    mergeDataModal: false,
    skillFocusesModal: false,
    behaviorScreenerModal: false,
    confirmDiagnosisModal: false,
  },
};

const reducer: Reducer<GroupsState> = (
  state: GroupsState = initialState,
  action: GroupsAction
): GroupsState => {
  switch (action.type) {
    case GroupsActionTypes.GET_SKILL_FOCUSES_SUCCESS:
      return {
        ...state,
        skillFocusInfo: action.payload.skillFocusesInfo,
        identifiersInfo: action.payload.identifiersInfo,
        grouping: action.payload.grouping,
        identifiersOrderList: action.payload.skillFocusesInfo.flatMap(
          (area) => [
            area.identifier,
            ...(area.skill_focuses?.flatMap((sf) => [
              sf.identifier,
              ...(sf.specifics?.map((spec) => spec.identifier) || []),
            ]) || []),
          ]
        ),
      };

    case GroupsActionTypes.OPEN_MERGE_DATA_MODAL:
      return {
        ...state,
        selectedAssessment: action.payload.assessment,
        modalsState: { ...state.modalsState, mergeDataModal: true },
      };
    case GroupsActionTypes.HIDE_MERGE_DATA_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          mergeDataModal: false,
        },
      };

    case GroupsActionTypes.OPEN_SKILL_FOCUSES_MODAL:
      return {
        ...state,
        selectedStudentEntry: action.payload.studentEntry,
        modalsState: { ...state.modalsState, skillFocusesModal: true },
      };
    case GroupsActionTypes.HIDE_SKILL_FOCUSES_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          skillFocusesModal: false,
        },
      };

    case GroupsActionTypes.OPEN_BEHAVIOR_SCREENER_MODAL: 
      return {
        ...state,
        //selectedStudentEntry: action.payload.studentEntry,
        evidenceColumnGroupId: action.payload.evidenceColumnGroupId,
        evidenceColumn: action.payload.evidenceColumn,
        student: action.payload.student,
        modalsState: { ...state.modalsState, behaviorScreenerModal: true },
      };
    case GroupsActionTypes.HIDE_BEHAVIOR_SCREENER_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          behaviorScreenerModal: false,
        },
      };

    case GroupsActionTypes.OPEN_CONFIRM_DIAGNOSIS_MODAL:
      return {
        ...state,
        selectedStudentEntry: action.payload.studentEntry,
        modalsState: { ...state.modalsState, confirmDiagnosisModal: true },
      };
    case GroupsActionTypes.HIDE_CONFIRM_DIAGNOSIS_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, confirmDiagnosisModal: false },
      };
    case GroupsActionTypes.ACCEPT_CONFIRM_DIAGNOSIS_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          confirmDiagnosisModal: false,
          skillFocusesModal: true,
        },
      };

    case GroupsActionTypes.CLEAR_SELECTED_ASSESSMENT:
      return {
        ...state,
        selectedAssessment: undefined,
      };
//Rakesh Bhargava 6 april 2022
    case GroupsActionTypes.OPEN_OVERVIEW_MODAL:
      
      return {
        ...state,
        overviewModelType: action.payload,
      };

    default:
      return state;
  }
};

export { reducer as groupsReducer };
