import React, { FunctionComponent, useCallback, useMemo } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import {
  completeFbaObservation,
  getFbaObservation,
  hideFbaObservationModal,
} from "../../../../../../store/onboarding/cases/actions";
import { getFullName } from "../../../../../../utils/NamesUtils";
import ObservedBehaviorsPanel from "./ObservedBehaviorsPanel";
import AbcCollapsedPanels from "./AbcCollapsedPanels";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";
import { dateToFormatString } from "../../../../../utils/DateTimeUtils";
import { useLoading } from "../../../../../../utils/hooks/useLoading";

type Props = {};

const FbaObservationModal: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const show = useSelector(
    (s: ApplicationState) => s.cases.modalsState.fbaObservationModal
  );

  const isSingleAbc = useSelector((s: ApplicationState) => s.cases.isSingleAbc);
  const selectedFba = useSelector((s: ApplicationState) => s.cases.selectedFba);

  const selectedFbaObservation = useSelector(
    (s: ApplicationState) => s.cases.selectedFbaObservation
  );

  const title = useMemo(
    () =>
      isSingleAbc
        ? `Log Single ABC for ${getFullName(selectedFba?.student)}`
        : "Observation", //todo change title
    [isSingleAbc, selectedFba]
  );

  const onConfirm = useCallback(() => {
    if (!isSingleAbc && selectedFbaObservation?.observationabc.length) {
      dispatch(getFbaObservation(selectedFbaObservation.id));
    }
    dispatch(hideFbaObservationModal());
  }, [isSingleAbc, selectedFbaObservation?.observationabc.length]);

  const handleModalHide = useCallback(() => {
    dispatch(
      showConfirmDialog({
        onConfirm: onConfirm,
        centered: true,
        title: "Confirm",
        text: "Close without saving?",
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
      })
    );
  }, [onConfirm]);

  const onClickObservationFinish = useCallback(() => {
    if (selectedFba?.id && selectedFbaObservation?.id) {
      dispatch(
        completeFbaObservation(
          selectedFba.id,
          selectedFbaObservation.id,
          dateToFormatString(new Date(), "YYYY/MM/DD hh:mm")
        )
      );
    }
  }, [selectedFba?.id, selectedFbaObservation?.id]);

  const isLoading = useSelector(
    (s: ApplicationState) => s.cases.isLoading.completeFbaObservation
  );
  const error = useSelector(
    (s: ApplicationState) => s.cases.errors.completeFbaObservation
  );
  const onSuccess = useCallback(() => {
    dispatch(hideFbaObservationModal());
  }, []);
  useLoading(isLoading, error, onSuccess);

  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleModalHide}
      animation={false}
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isSingleAbc && (
          <div className="d-flex align-items-start justify-content-between mb-3">
            <h3>{`Observing ${getFullName(selectedFba?.student)}`}</h3>
            <button
              className="blueBtnSm"
              disabled={!selectedFbaObservation?.observationabc?.length}
              onClick={onClickObservationFinish}
            >
              Finish Observation
              {isLoading && (
                <Spinner animation="border" size="sm" className="ml-1" />
              )}
            </button>
          </div>
        )}
        <div className="fba-observation-dialog-content">
          {!isSingleAbc && <ObservedBehaviorsPanel />}
          <AbcCollapsedPanels
            fbaId={selectedFba?.id!}
            isSingleAbc={isSingleAbc}
            observation={selectedFbaObservation}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FbaObservationModal;
