import React from "react";
import GroupCountIcon from "../../group-students/common/GroupCountIcon";
import UserAvatarSmall from "../../../../UserAvatarSmall";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { getFullNameForStudent } from "../../../../../../utils/StudentMapUtils";

type Props = {
  interventionGroup: InterventionGroup;
};
type State = {
  showInterventionGroup: boolean;
};

class InterventionGroupTooltip extends React.Component<Props, State> {
  state: Readonly<State> = {
    showInterventionGroup: false,
  };

  handleMouseLeave = () => {
    this.setState({ showInterventionGroup: false });
  };

  handleMouseEnter = () => {
    this.setState({ showInterventionGroup: true });
  };

  render() {
    const { interventionGroup } = this.props;
    const { showInterventionGroup } = this.state;
    return (
      <>
        <div
          className="whiteGroupCountIcon"
          onMouseEnter={this.handleMouseEnter}
        >
          <GroupCountIcon
            svgSize={60}
            studentsNum={interventionGroup.students.length}
          />
        </div>
        <div
          className="interventionGroupTooltip"
          hidden={!showInterventionGroup}
        >
          <div>
            <div
              className={"d-flex flex-row align-items-center mb-3"}
              onMouseLeave={this.handleMouseLeave}
            >
              <div className="whiteGroupCountIcon">
                <GroupCountIcon
                  svgSize={60}
                  studentsNum={interventionGroup.students.length}
                />
              </div>
              <div style={{ color: "#ffffff" }}>
                <UserAvatarSmall
                  userInfo={
                    interventionGroup.teacher_assignment &&
                    interventionGroup.teacher_assignment.user
                  }
                />{" "}
                {interventionGroup.teacher_assignment &&
                  `${interventionGroup.teacher_assignment.user.first_name} ${interventionGroup.teacher_assignment.user.last_name}`}
              </div>
            </div>
            <div className="newGroupModalNames mb-0">
              <div>
                {interventionGroup.students.map((student) => (
                  <div key={student.id}>{getFullNameForStudent(student)}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default InterventionGroupTooltip;
