import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  changeGroupSearch,
  changeGroupSort,
  changeShowArchivedGroups,
  changeExitedStudentsGroup,
} from "../../../../../../store/dataFilter/actions";
import {
  GroupSorting,
  InterventionGroupSortBy,
} from "../../../../../../store/dataFilter/types";
import { ApplicationState } from "../../../../../../store";
import Select, { ValueType } from "react-select";
import {
  ReactSelectOption,
  StudentInfo,
} from "../../../../../../store/onboarding/types";
import { RouteComponentProps, withRouter } from "react-router";
import { push } from "connected-react-router";
import { getInterventionGroups, displayGroupChange, callFromStudentDetail, callFromGroupDetail } from "../../../../../../store/onboarding/cases/actions";
import ToolBarButton from "./ToolBarButton";
import ShowRecommendationsCheckBox from "./ShowRecommendationsCheckBox";
import ShowInterventionStatisticsPerTeachersButton from "./ShowInterventionStatisticsPerTeachersButton";
import useUserRole from "../../../../../../utils/hooks/useUserRole";
import { hideConfirmDialog, showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";
import { isUrlBlocked } from "../../../../../../store/onboarding/actions";
import ListingOrderSwitcher from "./ListingOrderSwitcher";

type StateProps = {
  groupSort?: GroupSorting;
  showArchivedGroups?: boolean;
  exitedStudentsGroup?: boolean;
  tabName: string;
};

type DispatchProps = {
  changeGroupSearch: (str: string) => any;
  changeGroupSort: (groupSort?: GroupSorting) => any;
  changeShowArchivedGroups: (show?: boolean) => any;
  changeExitedStudentsGroup: (show?: boolean) => any;
  push: (location: string) => any;
  displayGroupChange: (displayGroupName: string) => any;
  getInterventionGroups: (archived?: boolean, tabName?: string, studentId?: number ) => any;
};
type MatchParams = {
  groupDisplayed?: string;
};

type OwnProps = {
  isYourAssignments?: boolean;
  student?: StudentInfo;
  activeTab?: string;
};

type Props = OwnProps &
  StateProps &
  DispatchProps &
  RouteComponentProps<MatchParams>;

enum DisplayedGroupsFilterValues {
  ACTIVE_GROUPS = "active",
  ARCHIVED_GROUPS = "archived",
  EXITED_GROUPS = "exited_groups",
}

const ToolBar: FunctionComponent<Props> = ({
  showArchivedGroups,
  exitedStudentsGroup,
  groupSort,
  changeGroupSort,
  changeGroupSearch,
  match: {
    params: { groupDisplayed },
  },
  changeShowArchivedGroups,
  changeExitedStudentsGroup,
  getInterventionGroups,
  push,
  isYourAssignments,
  student,
  tabName,
  activeTab
}) => {
  const { isCoach, isDistrictOwner } = useUserRole();
  const dispatch = useDispatch();
  const [selectDisplayGroup, setSelectDisplayGroup] = useState<any>(null);
  const [isStudentDetail, setIsStudentDetail] = useState<boolean>(false);
  const [isGroupDetail, setIsGroupDetail] = useState<boolean>(false);

  const isURLBlocked = useSelector((s: ApplicationState) => s.onboarding.isUrlBlocked);
  const showInterventionPlanModal = useSelector<ApplicationState>(
    (s) => s.cases.modalsState.interventionPlan?.show
  ); 

  let timeout: any = null;

  useEffect(() => {
    if (!showArchivedGroups) {
      changeShowArchivedGroups(!!groupDisplayed);
      if (groupDisplayed && !isYourAssignments) {
        getInterventionGroups(showArchivedGroups);
      }
    }
  }, [groupDisplayed, showArchivedGroups, exitedStudentsGroup]);

  const displayGroup = useSelector((s: ApplicationState) => s.cases.displayGroupName);

  useEffect(() => {
    if (isYourAssignments) {
      if (showArchivedGroups) {
        !isCoach ? getInterventionGroups(showArchivedGroups, tabName, student?.id) : '';
      } else {
        !isCoach ? getInterventionGroups(false, tabName, student?.id) : '';
      }
    } else if (exitedStudentsGroup) {
      getInterventionGroups(showArchivedGroups, tabName, student?.id);
    } else {
      getInterventionGroups(showArchivedGroups, tabName, student?.id);
    }

    if (displayGroup == DisplayedGroupsFilterValues.ARCHIVED_GROUPS) {
      setSelectDisplayGroup({
        label: "Archived Groups",
        value: DisplayedGroupsFilterValues.ARCHIVED_GROUPS,
      });
      handleDisplayedGroupsChange({
        label: "Archived Groups",
        value: DisplayedGroupsFilterValues.ARCHIVED_GROUPS,
      })
    } else if (displayGroup == DisplayedGroupsFilterValues.EXITED_GROUPS) {
      setSelectDisplayGroup({
        label: "Exited Groups",
        value: DisplayedGroupsFilterValues.EXITED_GROUPS,
      });
      handleDisplayedGroupsChange({
        label: "Exited Groups",
        value: DisplayedGroupsFilterValues.EXITED_GROUPS,
      })
    }
  }, [])

  useEffect(() => {
    if (isYourAssignments) {
      if (showArchivedGroups) {
        setIsGroupDetail(true);
        dispatch(callFromGroupDetail(showArchivedGroups));
        dispatch(callFromStudentDetail(false));
      } else {
        setIsGroupDetail(false);
        changeExitedStudentsGroup(false);
        dispatch(callFromGroupDetail(false));
        dispatch(callFromStudentDetail(false));
      }
    } else if (exitedStudentsGroup) {
      changeExitedStudentsGroup(true);
      dispatch(callFromStudentDetail(true));
      dispatch(callFromGroupDetail(false));
    } else {
      showArchivedGroups ? setIsStudentDetail(true) : setIsStudentDetail(false)
      dispatch(callFromStudentDetail(true));
      dispatch(callFromGroupDetail(false));
    }
  }, [showArchivedGroups])

  useEffect(() => {
    changeExitedStudentsGroup(false);
  }, []);

  const handleGroupSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (timeout) clearTimeout(timeout);
    const { value } = event.target;
    timeout = setTimeout(() => {
      changeGroupSearch(value);
    }, 500);
  };

  const options: Array<ReactSelectOption<string>> = useMemo(
    () => [
      {
        label: "Active Groups",
        value: DisplayedGroupsFilterValues.ACTIVE_GROUPS,
      },
      {
        label: "Archived Groups",
        value: DisplayedGroupsFilterValues.ARCHIVED_GROUPS,
      },
      {
        label: "Exited Groups",
        value: DisplayedGroupsFilterValues.EXITED_GROUPS,
      },
    ],
    []
  );

  if (!student) {
    delete options[2];
  }

  const handleDisplayedGroupsChange = (
    option: any
  ) => {
    if (isURLBlocked && (window.location.pathname.includes('groups'))) {
      dispatch(
        showConfirmDialog({
          title: "Confirm Navigation",
          text:
            "If you leave/refresh this page, any actions taken with the recommended groups will not be retained. Are you sure you want to leave? ",
          onConfirm: () => {
            dispatch(isUrlBlocked(false));
            displayGroups(option)
            dispatch(hideConfirmDialog());
          },
          confirmButtonText: "Leave this Page",
          cancelButtonText: "Stay on this page",
          modalClassName: "navigation-confirm"
        })
      );
    } else {
      displayGroups(option)
    }
  };

  const displayGroups = (option: ValueType<ReactSelectOption<string>, false>) => {
    dispatch(displayGroupChange(option!.value))
    setSelectDisplayGroup(option);
    const isArchivedGroup =
      (option as ReactSelectOption<string>).value ===
      DisplayedGroupsFilterValues.ARCHIVED_GROUPS;
    if (isYourAssignments) {
      if (isArchivedGroup) {
        setIsGroupDetail(true);
        dispatch(callFromGroupDetail(isArchivedGroup));
        dispatch(callFromStudentDetail(false));
        push(`/cases/${tabName}/archived`);
        getInterventionGroups(isArchivedGroup, tabName, student?.id);
      } else {
        setIsGroupDetail(false);
        changeExitedStudentsGroup(false);
        dispatch(callFromGroupDetail(false));
        dispatch(callFromStudentDetail(false));
        push(`/cases/${tabName}`);
        getInterventionGroups(false, tabName, student?.id);
      }
    }

    changeShowArchivedGroups(isArchivedGroup);

    if (
      (option as ReactSelectOption<string>).value ===
      DisplayedGroupsFilterValues.EXITED_GROUPS
    ) {
      getInterventionGroups(isArchivedGroup, tabName, student?.id);
      changeExitedStudentsGroup(true);
      dispatch(callFromStudentDetail(true));
      dispatch(callFromGroupDetail(false));
      setIsStudentDetail(false);
    } else if (!isYourAssignments) {
      getInterventionGroups(isArchivedGroup, tabName, student?.id);
      changeExitedStudentsGroup(false);
      isArchivedGroup ? setIsStudentDetail(true) : setIsStudentDetail(false)
      dispatch(callFromStudentDetail(true));
      dispatch(callFromGroupDetail(false));
    }
  }

  useEffect(() => {
    const option = isYourAssignments ? (showArchivedGroups ? options[1] : options[0]) : (selectDisplayGroup ? selectDisplayGroup : options[0])
    if(showInterventionPlanModal 
      && isYourAssignments 
      && (option?.value == 'active')
      && (activeTab == 'groups/reading' || activeTab == 'groups/behavior') 
      && (displayGroup == 'archived' || displayGroup == 'exited_groups')) {
      displayGroups(option);
    }
  },[selectDisplayGroup, showInterventionPlanModal])

  const InterventionScheduleSortButton = () => {
    const isActive =
      groupSort?.sortBy === InterventionGroupSortBy.INTERVENTION_SCHEDULE;

    const handleChange = () => {
      if (isActive) {
        changeGroupSort(undefined);
      } else {
        changeGroupSort({
          sortBy: InterventionGroupSortBy.INTERVENTION_SCHEDULE,
          direction: "asc",
        });
      }
    };

    return (
      <button className={isActive ? "active" : ""} onClick={handleChange}>
        Intervention Schedule
      </button>
    );
  };

  return (
    <div className="groups-tool-bar" style={{fontSize: '14px'}}>
      <div className="d-flex align-items-center">
          <div role="group" className="sortingOptionsGroup">
            <ToolBarButton
              title={"Goal Date"}
              itemSort={InterventionGroupSortBy.GOAL_DATE}
              groupSort={groupSort}
              changeGroupSort={changeGroupSort}
            />
            {/* <ToolBarButton
              title={"Progress Trend"}
              itemSort={InterventionGroupSortBy.PROGRESS_TREND}
              groupSort={groupSort}
              changeGroupSort={changeGroupSort}
            /> */}
            <ToolBarButton
              title={"Activity"}
              itemSort={InterventionGroupSortBy.ACTIVITY}
              groupSort={groupSort}
              changeGroupSort={changeGroupSort}
            />
            {/* <InterventionScheduleSortButton /> */}
          </div>

        {/*<ListGridSwitcher />*/}

        <div style={{ minWidth: 175 }} className="ml-2">
          <h4 className="font-weight-bold">Displayed Groups</h4>
          <Select
            options={options}
            value={isYourAssignments ? (showArchivedGroups ? options[1] : options[0]) : (selectDisplayGroup ? selectDisplayGroup : options[0])}
            onChange={handleDisplayedGroupsChange}
          />
        </div>
      </div>
      {isYourAssignments &&
        <ShowRecommendationsCheckBox />
      }


      {/*<input*/}
      {/*  type="text"*/}
      {/*  onChange={handleGroupSearchChange}*/}
      {/*  placeholder="Search Intervention Tables..."*/}
      {/*  className="stdInput group-search"*/}
      {/*  // style={{ width: "25%" }}*/}
      {/*/>*/}
      {/* Hide OVERVIEW OF ALL GROUPS button as of now for need to discuss, it single button, groups data will 
        be included of shared and not shared section */}
      <ShowInterventionStatisticsPerTeachersButton />
      {/* {isYourAssignments &&  <ShowInterventionStatisticsPerTeachersButton />} */}
      {isDistrictOwner ? <div className="row col-12 pl-0"><ListingOrderSwitcher/></div> : ''}
    </div>
  );
};

const mapStateToProps = ({ dataFilter, cases }: ApplicationState): StateProps => {
  return {
    groupSort: dataFilter.groupSort,
    showArchivedGroups: dataFilter.showArchivedGroups,
    exitedStudentsGroup: dataFilter.exitedStudentsGroup,
    tabName: cases.tabName
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      changeGroupSearch: changeGroupSearch,
      changeGroupSort: changeGroupSort,
      changeShowArchivedGroups: changeShowArchivedGroups,
      changeExitedStudentsGroup: changeExitedStudentsGroup,
      getInterventionGroups: getInterventionGroups,
      displayGroupChange: displayGroupChange,
      push: push,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ToolBar));
