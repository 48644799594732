import React, { useEffect, useMemo, useState } from "react";
import {
  AlertReason,
  InterventionGroup,
  InterventionGroupStage,
} from "../../../../../../../store/onboarding/cases/types";
import PreparationStageInfo from "../PreparationStageInfo";
import ImplementationStageInfo from "../ImplementationStageInfo";
import MonitoringStageInfo from "../MonitoringStageInfo";
import useUserRole from "../../../../../../../utils/hooks/useUserRole";
import { useDispatch } from "react-redux";
import {
  changeSelectedInterventionGroup,
  patchInterventionGroup,
  showInterventionPlanModal,
} from "../../../../../../../store/onboarding/cases/actions";
import { MeetingType } from "../../../../../../../store/onboarding/meeting-module/types";
import { useHistory } from "react-router";
import { linkToMeetingTab } from "../../../../../../../store/onboarding/meeting-module/actions";

const useInterventionGroupItem = ({
  interventionGroup,
  showArchivedGroups,
  isReadonly,
  hideChartActions,
  isIntGrpIDsWithReadAccess,
  progressOverviewLoader
}: {
  interventionGroup: InterventionGroup;
  showArchivedGroups?: boolean;
  isReadonly?: boolean;
  hideChartActions?: boolean;
  isIntGrpIDsWithReadAccess?: boolean;
  progressOverviewLoader?: boolean;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [stage, setStage] = useState<InterventionGroupStage>(
    interventionGroup.stage || InterventionGroupStage.STAGE_PREPARATION
  );

  useEffect(() => {
    if (showArchivedGroups || isReadonly || isIntGrpIDsWithReadAccess) {
      setStage(InterventionGroupStage.STAGE_MONITORING);
    } else {
      if (interventionGroup.skipped_meeting) {
        setStage(InterventionGroupStage.STAGE_MONITORING);
        return;
      }
      if (interventionGroup.skipped_prep) {
        setStage(InterventionGroupStage.STAGE_IMPLEMENTATION);
        return;
      }
      if (interventionGroup.latest_meeting) {
        if (
          interventionGroup.latest_meeting.planning_notes &&
          interventionGroup.latest_meeting.meeting_notes
        ) {
          setStage(InterventionGroupStage.STAGE_MONITORING);
        } else if (interventionGroup.latest_meeting.planning_notes) {
          setStage(InterventionGroupStage.STAGE_IMPLEMENTATION);
        } else {
          setStage(InterventionGroupStage.STAGE_PREPARATION);
        }
      }
    }
  }, [interventionGroup, showArchivedGroups, isReadonly, isIntGrpIDsWithReadAccess]);

  const hasAlert = useMemo(
    () =>
      !isReadonly &&
      interventionGroup.alert_reason &&
      interventionGroup.alert_reason != AlertReason.None,
    [isReadonly, interventionGroup.alert_reason]
  );

  const hasTime = useMemo(() => {
    return !!(
      interventionGroup.time_monday_start ||
      interventionGroup.time_tuesday_start ||
      interventionGroup.time_wednesday_start ||
      interventionGroup.time_thursday_start ||
      interventionGroup.time_friday_start
    );
  }, [interventionGroup]);

  const [
    showInterventionTimeOfDayPanel,
    setShowInterventionTimeOfDayPanel,
  ] = useState<boolean>(hasTime);

  const { role: userRole, isTeacher, isSupervisor } = useUserRole();

  const infoByStage = useMemo(() => {
    switch (stage) {
      case InterventionGroupStage.STAGE_PREPARATION:
        return (
          <PreparationStageInfo
            userRole={userRole}
            interventionGroup={interventionGroup}
            onInterventionSkip={() =>
              dispatch(
                patchInterventionGroup(interventionGroup.id!, {
                  skipped_prep: true,
                })
              )
            }
          />
        );
      case InterventionGroupStage.STAGE_IMPLEMENTATION:
        return (
          <ImplementationStageInfo
            userRole={userRole}
            interventionGroup={interventionGroup}
            onInterventionSkip={() =>
              dispatch(
                patchInterventionGroup(interventionGroup.id!, {
                  skipped_meeting: true,
                })
              )
            }
          />
        );
      case InterventionGroupStage.STAGE_MONITORING:
        return (
          <MonitoringStageInfo
            hideActions={
              hideChartActions ||
              isTeacher ||
              isSupervisor ||
              showArchivedGroups ||
              isReadonly ||
              isIntGrpIDsWithReadAccess
            }
            interventionGroup={interventionGroup}
            progressOverviewLoader={progressOverviewLoader}
          />
        );
      default:
        return null;
    }
  }, [stage, interventionGroup, userRole, isReadonly]);

  const onSupportImplFeedbackModalOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(changeSelectedInterventionGroup(interventionGroup));
    dispatch(
      linkToMeetingTab(interventionGroup!.id!, MeetingType.SUPPORT_INTERVENTION)
    );
  };

  const onGroupDetailsShow = () => {
    if (!showArchivedGroups && !isReadonly) {
      dispatch(showInterventionPlanModal(interventionGroup));
      // dispatch(changeSelectedInterventionGroup(interventionGroup));
      // history.push(`/cases/groups/${interventionGroup.id}/student-goals`);
    }
  };

  return {
    stage: stage,
    hasAlert: hasAlert,
    hasTime: hasTime,
    showInterventionTimeOfDayPanel,
    setShowInterventionTimeOfDayPanel,
    infoByStage,
    onSupportImplFeedbackModalOpen,
    onGroupDetailsShow,
  };
};

export default useInterventionGroupItem;
