import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import {
  ColorCriteria,
  ColorCriteriaBenchmarkNames,
  ColorCriteriaGrade,
  EvidencePeriodData,
  GradeLevels,
  Measurement,
  MeasurementType,
} from "../../../store/onboarding/types";
import SketchColorPicker from "./SketchColorPicker";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColorResult, GithubPicker } from "react-color";
import { Form, Spinner } from "react-bootstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IS_READY_COACH } from "../../../constants";
import {
  Grade,
  TimePeriod,
  TimePeriodShortOptions,
} from "../../../store/groups/types";
import Select from "react-select";
import { connect, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { GoalTypes } from "../../../store/onboarding/cases/types";

type PropsFromState = {
  enterDataManuallyType?: GoalTypes;
  searchFilter?: any;
};

type Props = PropsFromState & {
  showModal: boolean;
  withGrades?: boolean;
  withReadingAlgorithm?: boolean;
  useGradeSpecificColor?: boolean;
  selectedGrade?: Grade;
  selectedTimePeriod?: TimePeriod;
  benchmark_status?: string;
  measurement?: Measurement;
  onHide: () => any;
  onApplyChanges: (measurement: Measurement) => any;
  isLoading?: boolean;
  evidenceColumnGroupId?: number | undefined;
  filterDisabled?: boolean;
  setFilterDisabled?: React.Dispatch<React.SetStateAction<any | undefined>>;
  searchFilter?: any;
  onHandleSearchFilter?: (searchFilter: any) => any;
  editMeasureColorIndex?: number;
};

const EditMeasureColorCriteriaModal: React.FC<Props> = (props) => {
  let [measurement, setMeasurement] = useState<Measurement | undefined>();
  const [originalMeasurement, setOriginalMeasurement] = useState<Measurement | undefined>();
  const [grade, setGrade] = useState<string | undefined>();
  const [isAllowedToEdit, setIsAllowedToEdit] = useState(false);
  const [timePeriodGlobal, setTimePeriodGlobal] = useState<any[]>([]);
  const [colorPicker, setColorPicker] = useState<{
    target?: number;
    display: boolean;
  }>({
    display: false,
    target: undefined,
  });

  let initialSearchObj = {
    filterIndex: 0,
    filterGrade: [],
    filterAdditionalBanchmark: true,
    selectForK: [],
    selectForG1: [],
    selectForG2: [],
    selectForG3: [],
  }

  const [selectedSearchFilter, setSelectedSearchFilter] = useState<any>(initialSearchObj);

  const [searchFilter, setSearchFilter] = useState<any[]>([initialSearchObj]);

  const currentUser: any = useSelector(
    (s: ApplicationState) => s.auth.userInfo
  );

  useEffect(() => {
      if (measurement) {
        const userEditPermission = currentUser.allowed_edit_benchmarks;
        const isCustomColorCriteriaAvailable =
          measurement.custom_color_criteria &&
            measurement.custom_color_criteria.length
            ? true
            : false;
        const isGradeSpecificColorCriteriaAvailable = measurement.grade_specific_color_criteria
          ? true
          : false;
        const isCreator = measurement?.custom_color_criteria
          ? !!measurement.custom_color_criteria.find((et) =>
            et && et.user_id ? et.user_id == currentUser.id : false
          )
          : false;
        const isAllowedToEdit =
          userEditPermission ||
          isCreator ||
          (!isCustomColorCriteriaAvailable &&
            !isGradeSpecificColorCriteriaAvailable);
        setIsAllowedToEdit(isAllowedToEdit);

      }
  }, [measurement]);

  const evidencePeriodData = useSelector<ApplicationState,EvidencePeriodData | undefined >(
    (s: any) => s.onboarding.evidencePeriodData);
  
  useEffect(() => {
    
    const withReadAlgoCheck = evidencePeriodData?.evidence_column_groups.find(
      (evidenceData)=> evidenceData.assessment !== null && evidenceData.id == props.evidenceColumnGroupId);
      
      if((withReadAlgoCheck != null) && 
      (withReadAlgoCheck?.assessment.is_system_assessment == false) 
      && (withReadAlgoCheck?.assessment.is_behavior == false))
      {
        if(withReadAlgoCheck?.assessment.use_grade_specific_color_criteria == false){
          props.setFilterDisabled!(true);
        }
      }
     
  }, [evidencePeriodData])

  useEffect(() => {
    console.log(searchFilter[props.editMeasureColorIndex!])
     filterGrid();
  },[searchFilter[props.editMeasureColorIndex!]]);

  const handleSearchFilter = (selectedSearchFilter:any) => {
    if(searchFilter.some((search:any)=> search.filterIndex === selectedSearchFilter.filterIndex)) {
      let copyObj = {...selectedSearchFilter};
      searchFilter[copyObj.filterIndex] = copyObj;
      setSearchFilter(searchFilter);
      setSelectedSearchFilter(searchFilter[copyObj.filterIndex]);
    } 
  }

  const handleShowModal = async () => {
    if (!props.measurement) {
      return;
    }
    const hasColorCriteria =
      props.measurement.color_criteria &&
      props.measurement.color_criteria.length > 0;

    if (IS_READY_COACH) {
      // set initial search filter
      if(!searchFilter.some((search:any)=> search.filterIndex === props.editMeasureColorIndex)) {
        if(selectedSearchFilter.filterIndex !== props.editMeasureColorIndex) {
          initialSearchObj['filterIndex'] = props.editMeasureColorIndex!;
          searchFilter.push(initialSearchObj);
          setSearchFilter(searchFilter)
        }
      } 
  
      setSelectedSearchFilter(searchFilter[props.editMeasureColorIndex!]);



     await setMeasurement(
        hasColorCriteria
          ? !props.useGradeSpecificColor &&
            props.withGrades &&
            props.measurement.color_criteria?.some(
              (cc) =>
                cc.grade === props.selectedGrade &&
                cc.time_period === props.selectedTimePeriod
            )
            ? {
              ...props.measurement,
              color_criteria: [
                ...(props.measurement.color_criteria ?? []),
                getDefaultColorCriteria(props.measurement!),
              ],
            }
            : props.measurement
          : {
            ...props.measurement,
            color_criteria: [getDefaultColorCriteria(props.measurement)],
          }
      );

      setOriginalMeasurement(
        hasColorCriteria
          ? !props.useGradeSpecificColor &&
            props.withGrades &&
            props.measurement.color_criteria?.some(
              (cc) =>
                cc.grade === props.selectedGrade &&
                cc.time_period === props.selectedTimePeriod
            )
            ? {
              ...props.measurement,
              color_criteria: [
                ...(props.measurement.color_criteria ?? []),
                getDefaultColorCriteria(props.measurement!),
              ],
            }
            : props.measurement
          : {
            ...props.measurement,
            color_criteria: [getDefaultColorCriteria(props.measurement)],
          }
      )

      const filterGrade = (searchFilter[props.editMeasureColorIndex!] != undefined) ? searchFilter[props.editMeasureColorIndex!].filterGrade : null;
      if (filterGrade !== null && filterGrade.length > 0) {
        handleFilterGradeChange(filterGrade, props.measurement);
        setTimeout(() => { filterGrid();}, 100);
      }

    } else {
      setMeasurement(
        hasColorCriteria
          ? props.measurement
          : {
            ...props.measurement,
            color_criteria: [
              ...getDefaultBenchmarkCriteria(props.measurement),
            ],
          }
      );
    }
  };

  const getDefaultBenchmarkCriteria = (
    measurement: Measurement
  ): Array<ColorCriteria> => {
    if (
      measurement.type === MeasurementType.CATEGORY &&
      measurement.possible_values
    ) {
      return [getDefaultColorCriteria(measurement)];
    } else {
      return ColorCriteriaBenchmarkNames.map((benchmarkName) => {
        const criteria = getDefaultColorCriteria(measurement);
        criteria.display_name = benchmarkName;
        return criteria;
      });
    }
  };

  const getValueWithMinOrder = (m: Measurement) => {
    if (
      measurement &&
      measurement.color_criteria &&
      measurement.color_criteria.length > 0
    ) {
      return m
        .possible_values!.filter(
          (pValue) =>
            !measurement?.color_criteria!.some(
              (cc) => cc.selected_value_order === pValue.order
            )
        )
        .reduce((prev, curr) => (prev.order > curr.order ? prev : curr));
    } else {
      return m.possible_values!.reduce((prev, curr) =>
        prev.order < curr.order ? prev : curr
      );
    }
  };

  const getDefaultColorCriteria = (measurement: Measurement): ColorCriteria => {
    if (
      measurement.type === MeasurementType.CATEGORY &&
      measurement.possible_values
    ) {
      const valueWithMinOrder = getValueWithMinOrder(measurement);
      return {
        grade: props.selectedGrade,
        time_period: props.selectedTimePeriod,
        benchmark_status: "below",
        display_name: valueWithMinOrder.display_name,
        range_min: undefined,
        range_max: undefined,
        order: valueWithMinOrder.order,
        selected_value_order: valueWithMinOrder.order,
        color: "#ffffff",
        user_id: currentUser.id,
      };
    } else {
      return {
        grade: props.selectedGrade,
        time_period: props.selectedTimePeriod,
        benchmark_status: "below",
        display_name: "",
        range_min: undefined,
        range_max: undefined,
        order:
          measurement.color_criteria && measurement.color_criteria.length > 0
            ? measurement.color_criteria.reduce((prev, curr) =>
              prev.order! > curr.order! ? prev : curr
            ).order! + 1
            : 1,
        color: "#ffffff",
        user_id: currentUser.id,
      };
    }
  };

  const getBenchmarkCriteriaByName = (benchmarkName: string): any => {
    if (measurement && measurement.color_criteria) {
      const criteria = measurement.color_criteria.find(
        (colorCriteria) => colorCriteria.display_name === benchmarkName
      );
      return criteria ? criteria : getDefaultColorCriteria(measurement);
    }
  };

  const handleColorCriteriaChange = (selectedColorCriteria: ColorCriteria) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    setMeasurement((measurement) =>
      measurement
        ? {
          ...measurement,
          color_criteria: measurement.color_criteria!.map(
            (colorCriteria: ColorCriteria) => {
              if (colorCriteria === selectedColorCriteria) {
                if (name === "selected_value_order") {
                  const order = value ? +value : undefined;
                  const dName = order
                    ? measurement.possible_values!.find(
                      (pValue) => pValue.order === order
                    )!.display_name
                    : "";
                  return {
                    ...colorCriteria,
                    order: order,
                    selected_value_order: order,
                    display_name: dName,
                  };
                } else if (name === "grade" || name === "time_period" || name === "display_name") {
                  return {
                    ...colorCriteria,
                    [name]: value ? value : undefined,
                  };
                }
                return {
                  ...colorCriteria,
                  [name]:
                    name === "range_max" || name === "range_min"
                      ? value
                        ? +value
                        : undefined
                      : value,
                };
              }
              return colorCriteria;
            }
          ),
        }
        : undefined
    );
  };

  const handleBenchmarkCriteriaChange = (benchmarkName: string) => (
    event: React.FormEvent<any>
  ) => {
    const { name, value } = event.target as HTMLInputElement;

    setMeasurement((measurement) =>
      measurement
        ? {
          ...measurement,
          color_criteria: measurement.color_criteria!.map(
            (colorCriteria: ColorCriteria) => {
              if (colorCriteria.display_name === benchmarkName) {
                if (name === "selected_value_order") {
                  const order = value ? +value : undefined;
                  const dName = order
                    ? measurement.possible_values!.find(
                      (pValue) => pValue.order === order
                    )!.display_name
                    : "";
                  return {
                    ...colorCriteria,
                    order: order,
                    selected_value_order: order,
                    display_name: dName,
                  };
                }
                return {
                  ...colorCriteria,
                  [name]:
                    name === "range_max" || name === "range_min"
                      ? value
                        ? +value
                        : undefined
                      : value,
                };
              }
              return colorCriteria;
            }
          ),
        }
        : undefined
    );
  };

  const handleColorCriteriaRemove = (
    selectedColorCriteria: ColorCriteria
  ) => () => {
    setMeasurement((measurement) =>
      measurement
        ? {
          ...measurement,
          color_criteria:
            measurement.color_criteria?.filter((colorCriteria) =>
              props.useGradeSpecificColor
                ? !(
                  colorCriteria.grade === selectedColorCriteria.grade &&
                  colorCriteria.time_period ===
                  selectedColorCriteria.time_period
                )
                : colorCriteria !== selectedColorCriteria
            ) ?? [],
        }
        : undefined
    );
  };

  const handleColorPickerClick = (index: number) => () => {
    setColorPicker((colorPicker) => ({
      target: index,
      display: !colorPicker.display,
    }));
  };

  const handleColorPickerChange = (selectedColorCriteria: ColorCriteria) => (
    color: ColorResult
  ) => {
    setMeasurement((measurement) =>
      measurement
        ? {
          ...measurement,
          color_criteria: measurement.color_criteria!.map(
            (colorCriteria: ColorCriteria) => {
              if (colorCriteria === selectedColorCriteria) {
                return {
                  ...colorCriteria,
                  color: color.hex,
                };
              }
              return colorCriteria;
            }
          ),
        }
        : undefined
    );
  };

  const handleColorCriteriaAdd = (event: React.FormEvent) => {
    event.preventDefault();
    setMeasurement((measurement) =>
      measurement
        ? {
          ...measurement,
          color_criteria: [
            ...(measurement.color_criteria ?? []),
            getDefaultColorCriteria(measurement),
          ],
        }
        : undefined
    );
  };

  const handleColorPickerClose = () => {
    setColorPicker({ target: undefined, display: false });
  };

  const handleSelectedGradeChange = (event: React.FormEvent) => {
    event.preventDefault();
    const { value } = event.target as HTMLInputElement;
    setGrade(value);
  };

  const handleFilterGradeChange =  (selectedOption: any, measurementProps: any) => {
    if (selectedOption != null) {
      searchFilter[props.editMeasureColorIndex!].filterGrade = [...selectedOption];

      !(selectedOption.some((data: any) => data.value == 'K')) ? searchFilter[props.editMeasureColorIndex!].selectForK = [] : null;
      !(selectedOption.some((data: any) => data.value == 'G1')) ? searchFilter[props.editMeasureColorIndex!].selectForG1 = [] : null;
      !(selectedOption.some((data: any) => data.value == 'G2')) ? searchFilter[props.editMeasureColorIndex!].selectForG2 = [] : null;
      !(selectedOption.some((data: any) => data.value == 'G3')) ? searchFilter[props.editMeasureColorIndex!].selectForG3 = [] : null;
       handleSearchFilter!(searchFilter[props.editMeasureColorIndex!]);
      
      let measure = measurementProps.color_criteria?.filter(
        (colorCriteria: ColorCriteria, index: number) => (selectedOption.find(
          (data: any) => data.value == colorCriteria.grade!)
        )
      ) ?? [];
      setMeasurement((measurement) =>
        measurement
          ? {
            ...measurement,
            color_criteria: Array.from(new Set(measure))
          }
          : undefined
      );
    } else {
      searchFilter[props.editMeasureColorIndex!].filterGrade = [];
      searchFilter[props.editMeasureColorIndex!].selectForK = [];
      searchFilter[props.editMeasureColorIndex!].selectForG1 = [];
      searchFilter[props.editMeasureColorIndex!].selectForG2 = [];
      searchFilter[props.editMeasureColorIndex!].selectForG3 = [];
      setMeasurement(measurementProps);
      handleSearchFilter!(searchFilter[props.editMeasureColorIndex!]);
    }
   
  };


  const str2bool = (value: any) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  }

  const handleAdditionalBanchmarkChange = async (event: React.FormEvent) => {
    const { value } = event.target as HTMLInputElement;
    // handleSearchFilter!((searchFilter: any) =>
    //   searchFilter.filter((search: any)=> search.filterIndex == measurement?.column_index).map((s: any) => {
    //     return {
    //       ...s,
    //       filterAdditionalBanchmark: str2bool(value)
    //     }
    //   })
    // );
    searchFilter[props.editMeasureColorIndex!].filterAdditionalBanchmark = str2bool(value);
    handleSearchFilter!(searchFilter[props.editMeasureColorIndex!])
    setMeasurement(measurement);
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (measurement) {
      const filterGrade = (searchFilter[props.editMeasureColorIndex!]!= undefined) ?  searchFilter[props.editMeasureColorIndex!].filterGrade : [];
      let modifiedMeasurement = {...measurement};
      if(props.useGradeSpecificColor && (props.enterDataManuallyType == 'academic')) {
        if (searchFilter[props.editMeasureColorIndex!].filterAdditionalBanchmark == false) {
          modifiedMeasurement.color_criteria = modifiedMeasurement.color_criteria?.filter(
            (colorCriteria) => colorCriteria.benchmark_status !== 'average')
          if (filterGrade.length > 0) {
            modifiedMeasurement.color_criteria = modifiedMeasurement?.color_criteria?.filter(
              (colorCriteria: ColorCriteria, index: number) => (filterGrade.find(
                (data: any) => data.value === colorCriteria.grade!)
              )
            ) ?? [];
            let filteredObj = modifiedMeasurement.color_criteria;
            if (timePeriodGlobal.length > 0) {
              let filteredMeasure = modifiedMeasurement.color_criteria!.filter((colorCriteria: ColorCriteria) => 
                                      !(timePeriodGlobal.find((data: any) => (data.grade !== colorCriteria.grade) && (data.value !== colorCriteria.time_period))));
              let filterWithoutSelectedGrade = filteredObj!.filter((filterData: any) => !(filteredMeasure.find((colorCriteria: ColorCriteria) => (filterData.grade == colorCriteria.grade))));
              modifiedMeasurement.color_criteria = [...filteredMeasure, ...filterWithoutSelectedGrade];
            }
          }

          await (modifiedMeasurement.color_criteria!.length > 0) && modifiedMeasurement.color_criteria?.map((data, index) => {
            data.display_name = modifiedMeasurement.color_criteria?.[index].display_name!;
            data.range_max = modifiedMeasurement.color_criteria?.[index].range_max!;
            data.range_min = modifiedMeasurement.color_criteria?.[index].range_min!;
            data.color = modifiedMeasurement.color_criteria?.[index].color!;
            return data;
          })
          //// //log(' 3 level modifiedMeasurement', modifiedMeasurement);

        } else {
          modifiedMeasurement = {...measurement};
          if (filterGrade.length > 0) {
            modifiedMeasurement.color_criteria = modifiedMeasurement?.color_criteria?.filter(
              (colorCriteria: ColorCriteria, index: number) => (filterGrade.find(
                (data: any) => data.value === colorCriteria.grade!)
              )
            ) ?? [];
          }
          let filteredObj = modifiedMeasurement.color_criteria;
          if (timePeriodGlobal.length > 0) {
            let filteredMeasure = [...modifiedMeasurement.color_criteria!.filter((colorCriteria: ColorCriteria) => 
                                    !(timePeriodGlobal.find((data: any) => (data.grade !== colorCriteria.grade) && (data.value !== colorCriteria.time_period))))];
            let filterWithoutSelectedGrade = filteredObj!.filter((filterData: any) => !(filteredMeasure.find((colorCriteria: ColorCriteria) => (filterData.grade == colorCriteria.grade))));
            modifiedMeasurement.color_criteria = [...filteredMeasure, ...filterWithoutSelectedGrade];
          }

          await (modifiedMeasurement.color_criteria!.length > 0) && modifiedMeasurement.color_criteria?.map((data, index) => {
            if (index < measurement!.color_criteria!.length) {
              data.display_name = measurement?.color_criteria?.[index].display_name!;
              data.range_max = measurement?.color_criteria?.[index].range_max!;
              data.range_min = measurement?.color_criteria?.[index].range_min!;
              data.color = measurement?.color_criteria?.[index].color!;
              return data;
            }
          })

          //// //log(' 4 level modifiedMeasurement', modifiedMeasurement);
        }
      }
      modifiedMeasurement.color_criteria = modifiedMeasurement.color_criteria!
        .filter((data)=> (data.range_max !== undefined) && (data.range_min !== undefined))
        .map((modifiy, index) => {modifiy.order = index+1; return modifiy});
      
      if(searchFilter[props.editMeasureColorIndex!]!= undefined) handleSearchFilter!(searchFilter[props.editMeasureColorIndex!]);

      props.onApplyChanges(modifiedMeasurement);
    }
  };

  const refCallback = (colorCriteria: ColorCriteria) => (element: any) => {
    if (element) {
      if (!colorCriteria.range_min || !colorCriteria.range_max) {
        (element as HTMLInputElement).setCustomValidity("");
        return;
      }
      if (colorCriteria.range_min >= colorCriteria.range_max) {
        (element as HTMLInputElement).setCustomValidity(
          "The minimum range must be less than the maximum range"
        );
        return;
      }
      if (!measurement) return;
      const overlappingColorCriteria =
        colorCriteria.range_min &&
        measurement.color_criteria!.find(
          (cc) =>
            cc.grade == colorCriteria.grade &&
            cc.time_period == colorCriteria.time_period &&
            cc.order !== colorCriteria.order &&
            cc.range_min &&
            cc.range_max &&
            colorCriteria.range_min! >= cc.range_min &&
            colorCriteria.range_min! <= cc.range_max
        );
      if (overlappingColorCriteria) {
        (element as HTMLInputElement).setCustomValidity(
          `The benchmark values overlap with the following color criteria: ${overlappingColorCriteria.display_name} Score Range: ${overlappingColorCriteria.range_min}-${overlappingColorCriteria.range_max}`
        );
      } else {
        (element as HTMLInputElement).setCustomValidity("");
      }
    }
  };

  const isCategoryType = useMemo(() => {
    return measurement?.type === MeasurementType.CATEGORY;
  }, [measurement?.type]);


  const options = GradeLevels.filter((gl, index) => (index < 4) && gl).filter((grade) => currentUser.allowed_grades.length ? currentUser.allowed_grades.includes(grade.value) : true).map((data) => {
    return { 'label': data.value, 'value': data.value }
  });

  const handleCloseModal = () => {
    props.onHide();
  }

  const filterGrid = () => {
    const searchFilterState = searchFilter[props.editMeasureColorIndex!];
    if(measurement && (searchFilterState!= undefined)) {
      
      if (((searchFilterState.selectForK != null) && (searchFilterState.selectForK.length > 0))
        || ((searchFilterState.selectForG1 != null) && (searchFilterState.selectForG1.length > 0))
        || ((searchFilterState.selectForG2 != null) && (searchFilterState.selectForG2.length > 0))
        || ((searchFilterState.selectForG3 != null) && (searchFilterState.selectForG3.length > 0))
      ) {
  
        let stateForK = JSON.parse(JSON.stringify(searchFilterState.selectForK));
        let stateForG1 = JSON.parse(JSON.stringify(searchFilterState.selectForG1));
        let stateForG2 = JSON.parse(JSON.stringify(searchFilterState.selectForG2));
        let stateForG3 = JSON.parse(JSON.stringify(searchFilterState.selectForG3));
        stateForK = stateForK != null ? stateForK.map((k: any) => { k.grade = 'K'; return k; }) : [];
        stateForG1 = stateForG1 != null ? stateForG1.map((g1: any) => { g1.grade = 'G1'; return g1; }) : [];
        stateForG2 = stateForG2 != null ? stateForG2.map((g2: any) => { g2.grade = 'G2'; return g2; }) : [];
        stateForG3 = stateForG3 != null ? stateForG3.map((g3: any) => { g3.grade = 'G3'; return g3; }) : [];
        let globalFilterArray = [...stateForK, ...stateForG1, ...stateForG2, ...stateForG3];
        setTimePeriodGlobal(globalFilterArray);
        setGridData([...stateForK, ...stateForG1, ...stateForG2, ...stateForG3]);
      } else {
        setGridData([]);
      }

    }
  }

  const setGridData = (stateData: any) => {
    const filterGrade = searchFilter[props.editMeasureColorIndex!].filterGrade;

    let measure =  (props.measurement && (props.measurement!.color_criteria?.filter(
      (colorCriteria: ColorCriteria, index: number) => (filterGrade !== null)  && (filterGrade.length > 0)  && filterGrade.find((data: any) => (data.value == colorCriteria.grade!))
    ))) ?? [];

    let copyObject = [...measure];
    let filteredObj = [...measure];
    let mergedArray: any[] = [];
    if ((filterGrade != null) && (filterGrade.length == 1) && (stateData.length > 0)) {
      
      let filteredMeasure = copyObject.filter((colorCriteria: ColorCriteria) => 
      stateData.find((data: any) => (data.grade == colorCriteria.grade) && (data.value == colorCriteria.time_period)));
      //console.log(filteredMeasure)
       mergedArray = [...filteredMeasure];
    } else if ((filterGrade != null) && (filterGrade.length > 1) && (stateData.length > 0)) {
      //console.log(copyObject);
      //console.log(stateData);

      let filteredMeasure = copyObject.filter((colorCriteria: ColorCriteria) => 
                              (stateData.find((data: any) => (data.grade == colorCriteria.grade) && (data.value == colorCriteria.time_period))));
      //console.log(filteredMeasure)
      let filterWithoutSelectedGrade = filteredObj.filter((filterData: any) => !(filteredMeasure.find((colorCriteria: ColorCriteria) => (filterData.grade == colorCriteria.grade))));
      //console.log(filterWithoutSelectedGrade)
      mergedArray = [...filteredMeasure, ...filterWithoutSelectedGrade];
      
    } else {
      mergedArray = (filteredObj.length > 0) ? filteredObj : (props.measurement && props.measurement!.color_criteria) as any;
    }
    
    //console.log(mergedArray)
   

    setMeasurement((measurement: any) =>
      measurement
        ? {
          ...measurement,
          color_criteria: Array.from(new Set(mergedArray))

        }
        : undefined
    );
  }

  const checkSystemAssessment = evidencePeriodData?.evidence_column_groups.find(
    (evidenceData)=> evidenceData.assessment !== null && evidenceData.id == props.evidenceColumnGroupId);
  
  let messageSetBenchmarks:any = '';
    if((checkSystemAssessment != null) && 
    (checkSystemAssessment?.assessment.is_system_assessment == true))  {
      messageSetBenchmarks = <div className="col-12 pl-0">
        <div className="alert alert-warning py-1" role="alert">
          Setting district benchmarks for some grades and time periods will remove all benchmarks from other grades and time periods. This action cannot be undone. Are you sure you want to continue? 
        </div>
    </div>;
    }

   

  return (
    <Modal
      size="lg"
      show={props.showModal}
      centered
      style={{ backgroundColor: "rgb(255,255,255,0.8)" }}
      className="colorPickerModal"
      dialogClassName="colorPickerDialog"
      onHide={handleCloseModal}
      onShow={handleShowModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <div className="float-right">
          <Button onClick={handleCloseModal}>
            <FontAwesomeIcon icon={faTimes} className="pointer" />
          </Button>
        </div>

        {isAllowedToEdit && (
          <>
            {IS_READY_COACH ? (
              <h6>Edit Color Criteria for</h6>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <h5 style={{ marginRight: "15px" }}>View Benchmarks for</h5>
                <Form.Control
                  style={{ width: "auto", height: "auto", padding: "0" }}
                  required
                  name="grade"
                  as="select"
                  value={grade}
                  onChange={handleSelectedGradeChange}
                >
                  {ColorCriteriaGrade.map((grade: string, index: number) => (
                    <option key={index}>{grade}</option>
                  ))}
                </Form.Control>
              </div>
            )}
            <h3 className="font-weight-bold">{measurement?.display_name}</h3>
            {messageSetBenchmarks}
            <Form id="colorCriteriaForm" onSubmit={handleFormSubmit}>
              {/* (props.enterDataManuallyType == 'academic') && */}
              {props.useGradeSpecificColor && 
                <div className="row py-2 my-2 border-bottom border-top">
                  <div className="col-2 align-self-center">Filter By:</div>
                  <div className="col-3 bg-gray">
                    <div className="col-12 pr-0 py-2">
                      <Select
                        placeholder="Select Grades"
                        options={options}
                        isMulti={true}
                        onChange={(event) => handleFilterGradeChange(event, props.measurement)}
                        value={searchFilter[props.editMeasureColorIndex!].filterGrade}
                      />

                    </div>
                    <div className="col-12 pr-0 py-3 align-self-center">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          defaultChecked={props.measurement?.column_index != undefined ? searchFilter[props.editMeasureColorIndex!].filterAdditionalBanchmark === str2bool("false"): false}
                          onClick={(event) => handleAdditionalBanchmarkChange(event)}
                          value="false"
                        />
                        <label className="form-check-label" htmlFor="inlineRadio2">3 Benchmark Level</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          defaultChecked={props.measurement?.column_index != undefined ? searchFilter[props.editMeasureColorIndex!].filterAdditionalBanchmark === str2bool("true"): true}
                          onClick={(event) => handleAdditionalBanchmarkChange(event)}
                          value="true"
                        />
                        <label className="form-check-label" htmlFor="inlineRadio1">4 Benchmark Level </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-7 bg-gray">
                    <div className="row my-2">
                      <div className="col-6">
                        <Select
                          isMulti={true}
                          isDisabled={(searchFilter[props.editMeasureColorIndex!].filterGrade !== null) && (searchFilter[props.editMeasureColorIndex!].filterGrade.length > 0) ? !(searchFilter[props.editMeasureColorIndex!].filterGrade.some((data: any) => data.value == 'K')) : true}
                          placeholder="Select Time Period For K"
                          options={TimePeriodShortOptions}
                          onChange={(selectedOption:any) => {
                            if(selectedOption != null) {
                              searchFilter[props.editMeasureColorIndex!].selectForK = [...selectedOption];
                            } else {
                              searchFilter[props.editMeasureColorIndex!].selectForK = [];
                            }
                            handleSearchFilter!(searchFilter[props.editMeasureColorIndex!]);
                            }
                          }
                          value={searchFilter[props.editMeasureColorIndex!].selectForK}
                        />
                      </div>
                      <div className="col-6">
                        <Select
                          isMulti={true}
                          isDisabled={(searchFilter[props.editMeasureColorIndex!].filterGrade !== null) && (searchFilter[props.editMeasureColorIndex!].filterGrade !== undefined) && (searchFilter[props.editMeasureColorIndex!].filterGrade.length > 0) ? !(searchFilter[props.editMeasureColorIndex!].filterGrade.some((data: any) => data.value == 'G1')) : true}
                          placeholder="Select Time Period For G1"
                          options={TimePeriodShortOptions}
                          onChange={(selectedOption:any) => {
                            if(selectedOption != null) {
                              searchFilter[props.editMeasureColorIndex!].selectForG1 = [...selectedOption];
                            } else {
                              searchFilter[props.editMeasureColorIndex!].selectForG1 = [];
                            }
                            handleSearchFilter!(searchFilter[props.editMeasureColorIndex!]);
                            }
                          }
                          value={searchFilter[props.editMeasureColorIndex!].selectForG1}
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-6">
                        <Select
                          isMulti={true}
                          isDisabled={(searchFilter[props.editMeasureColorIndex!].filterGrade !== null) && (searchFilter[props.editMeasureColorIndex!].filterGrade !== undefined) && (searchFilter[props.editMeasureColorIndex!].filterGrade.length > 0) ? !(searchFilter[props.editMeasureColorIndex!].filterGrade.some((data: any) => data.value == 'G2')) : true}
                          placeholder="Select Time Period For G2"
                          options={TimePeriodShortOptions}
                          onChange={(selectedOption:any) => {
                            if(selectedOption != null) {
                              searchFilter[props.editMeasureColorIndex!].selectForG2 = [...selectedOption];
                            } else {
                              searchFilter[props.editMeasureColorIndex!].selectForG2 = [];
                            }
                            handleSearchFilter!(searchFilter[props.editMeasureColorIndex!]);
                            }
                          }
                          value={searchFilter[props.editMeasureColorIndex!].selectForG2}
                        />
                      </div>
                      <div className="col-6">
                        <Select
                          isDisabled={(searchFilter[props.editMeasureColorIndex!].filterGrade !== null) && (searchFilter[props.editMeasureColorIndex!].filterGrade !== undefined) && (searchFilter[props.editMeasureColorIndex!].filterGrade.length > 0) ? !(searchFilter[props.editMeasureColorIndex!].filterGrade.some((data: any) => data.value == 'G3')) : true}
                          placeholder="Select Time Period For G3"
                          options={[...TimePeriodShortOptions]}
                          isMulti={true}
                          onChange={(selectedOption:any) => {
                            if(selectedOption != null) {
                              searchFilter[props.editMeasureColorIndex!].selectForG3 = [...selectedOption];
                            } else {
                              searchFilter[props.editMeasureColorIndex!].selectForG3 = [];
                            }
                            handleSearchFilter!(searchFilter[props.editMeasureColorIndex!]);
                            }
                          }
                          value={searchFilter[props.editMeasureColorIndex!].selectForG3}
                        />

                      </div>

                    </div>
                  </div>
                </div>

              }
              <Table className="colorPickerTable">
                <thead>
                  <tr>
                    {props.withGrades && !isCategoryType && (
                      <>
                        <th>Grade</th>
                        <th>Time Period</th>
                      </>
                    )}
                    <th>Benchmark Status</th>
                    {IS_READY_COACH ? <th>Descriptor</th> : <th>Benchmark</th>}
                    {!isCategoryType && <th>Score Range</th>}
                    <th>Color Criteria</th>
                  </tr>
                </thead>
                {IS_READY_COACH ? (
                  <tbody>
                    {measurement?.color_criteria &&
                      measurement?.color_criteria.map(
                        (colorCriteria: ColorCriteria, index: number) => (
                          <React.Fragment key={index}>
                            {(searchFilter[props.editMeasureColorIndex!] != undefined) && searchFilter[props.editMeasureColorIndex!].filterAdditionalBanchmark ?

                              <tr>
                                {!isCategoryType ? (
                                  <>
                                    {props.withGrades && (
                                      <>
                                        <td>
                                          <Form.Control
                                            className="removeArrow"
                                            disabled={props.filterDisabled || props.useGradeSpecificColor}
                                            name={"grade"}
                                            as="select"
                                            value={props.filterDisabled ? "" : colorCriteria.grade}
                                            onChange={handleColorCriteriaChange(
                                              colorCriteria
                                            )}
                                           >
                                            <option value={""}>All</option>
                                            {GradeLevels.filter((grade) => currentUser.allowed_grades.length ? currentUser.allowed_grades.includes(grade.value) : true).map((gl) => (
                                              <option key={gl.value} value={gl.value}>
                                                {gl.value}
                                              </option>
                                            ))}
                                          </Form.Control>
                                        </td>

                                        <td>
                                          <Form.Control
                                            className="removeArrow"
                                            disabled={props.filterDisabled || props.useGradeSpecificColor}
                                            name={"time_period"}
                                            as="select"
                                            value={props.filterDisabled ? "" : colorCriteria.time_period}
                                            onChange={handleColorCriteriaChange(
                                              colorCriteria
                                            )}
                                          >
                                            <option value={""}>All</option>
                                            {TimePeriodShortOptions.map((tp) => (
                                              <option key={tp.value} value={tp.value}>
                                                {tp.label}
                                              </option>
                                            ))}
                                          </Form.Control>
                                        </td>
                                      </>
                                    )}
                                    <td>
                                      <Form.Control
                                        className="removeArrow"
                                        //disabled={props.filterDisabled || props.withReadingAlgorithm}
                                        name={"benchmark_status"}
                                        as="select"
                                        value={colorCriteria.benchmark_status}
                                        onChange={handleColorCriteriaChange(
                                          colorCriteria
                                        )}
                                      >
                                        <option value="below">
                                          Not Meeting Benchmarks
                                        </option>
                                        <option value="at_or_above">
                                          Meeting/Exceeding Benchmarks
                                        </option>
                                      </Form.Control>
                                    </td>
                                    <td className="colorCriteriaDesc">
                                      <Form.Control
                                        //disabled={props.withReadingAlgorithm}
                                        required
                                        name={"display_name"}
                                        type="text"
                                        className="stdInput"
                                        onChange={handleColorCriteriaChange(
                                          colorCriteria
                                        )}
                                        value={colorCriteria.display_name || ""}
                                      />
                                    </td>
                                    <td>
                                      <Form.Control
                                        required
                                        placeholder="Min"
                                        name={"range_min"}
                                        type="number"
                                        className="inputSm"
                                        min={measurement?.range_min}
                                        pattern="[0-9]+"
                                        ref={refCallback(colorCriteria)}
                                        value={
                                          colorCriteria.range_min === 0
                                            ? "0"
                                            : ((colorCriteria.range_min as unknown) as string) ||
                                            ""
                                        }
                                        onChange={handleColorCriteriaChange(
                                          colorCriteria
                                        )}
                                      />
                                      <strong>&nbsp;-&nbsp;</strong>
                                      <Form.Control
                                        required
                                        placeholder="Max"
                                        name={"range_max"}
                                        type="number"
                                        className="inputSm"
                                        pattern="[0-9]+"
                                        max={measurement?.range_max}
                                        value={
                                          colorCriteria.range_max === 0
                                            ? "0"
                                            : ((colorCriteria.range_max as unknown) as string) ||
                                            ""
                                        }
                                        onChange={handleColorCriteriaChange(
                                          colorCriteria
                                        )}
                                      />
                                    </td>
                                  </>
                                ) : (
                                  <Form.Control
                                    required
                                    name={"selected_value_order"}
                                    as="select"
                                    value={
                                      (colorCriteria.selected_value_order as unknown) as string
                                    }
                                    onChange={handleColorCriteriaChange(
                                      colorCriteria
                                    )}
                                    disabled={props.useGradeSpecificColor}
                                  >
                                    <option key={-1} value={""} />
                                    {measurement?.possible_values
                                      ?.filter(
                                        (possibleValue) =>
                                          !measurement?.color_criteria!.find(
                                            (cc) =>
                                              cc.selected_value_order ===
                                              possibleValue.order &&
                                              cc.selected_value_order !==
                                              colorCriteria.selected_value_order
                                          )
                                      )
                                      .map((possibleValue) => (
                                        <option
                                          key={possibleValue.order}
                                          value={possibleValue.order}
                                        >
                                          {possibleValue.display_name}
                                        </option>
                                      ))}
                                  </Form.Control>
                                )}
                                <td>
                                  <GithubPicker
                                    color={colorCriteria.color}
                                    onChange={handleColorPickerChange(
                                      colorCriteria
                                    )}
                                    width="112px"
                                    triangle="hide"
                                    colors={['#ffd1da', '#ffefc1', '#bff9dd', '#deeaf6']}
                                  />
                                </td>
                                <td>

                                  <SketchColorPicker
                                    readonly={props.useGradeSpecificColor}
                                    color={colorCriteria.color}
                                    handleColorPickerClose={handleColorPickerClose}
                                    handleColorPickerChange={handleColorPickerChange(
                                      colorCriteria
                                    )}
                                    handleColorPickerClick={handleColorPickerClick(
                                      index
                                    )}
                                    displayColorPicker={
                                      colorPicker.display &&
                                      colorPicker.target === index
                                    }
                                  />
                                </td>
                                <td>
                                  {props.useGradeSpecificColor ? null : (
                                    <Button
                                      onClick={handleColorCriteriaRemove(
                                        colorCriteria
                                      )}
                                    >
                                      <FontAwesomeIcon
                                        icon="times-circle"
                                        style={{
                                          color: "#9B9B9B",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </Button>
                                  )}
                                </td>
                              </tr>
                              :
                              (colorCriteria.benchmark_status !== 'average') &&
                              <tr>
                                {!isCategoryType ? (
                                  <>
                                    {props.withGrades && (
                                      <>
                                        <td>
                                          <Form.Control
                                            disabled={props.useGradeSpecificColor}
                                            name={"grade"}
                                            as="select"
                                            value={colorCriteria.grade}
                                            onChange={handleColorCriteriaChange(
                                              colorCriteria
                                            )}
                                          >
                                            <option value={""}>All</option>
                                            {GradeLevels.filter((grade) => currentUser.allowed_grades.length ? currentUser.allowed_grades.includes(grade.value) : true).map((gl) => (
                                              <option key={gl.value} value={gl.value}>
                                                {gl.value}
                                              </option>
                                            ))}
                                          </Form.Control>
                                        </td>

                                        <td>
                                          <Form.Control
                                            disabled={props.useGradeSpecificColor}
                                            name={"time_period"}
                                            as="select"
                                            value={colorCriteria.time_period}
                                            onChange={handleColorCriteriaChange(
                                              colorCriteria
                                            )}
                                          >
                                            <option value={""}>All</option>
                                            {TimePeriodShortOptions.map((tp) => (
                                              <option key={tp.value} value={tp.value}>
                                                {tp.label}
                                              </option>
                                            ))}
                                          </Form.Control>
                                        </td>
                                      </>
                                    )}
                                    <td>
                                      <Form.Control
                                        disabled={props.useGradeSpecificColor}
                                        name={"benchmark_status"}
                                        as="select"
                                        value={colorCriteria.benchmark_status}
                                        onChange={handleColorCriteriaChange(
                                          colorCriteria
                                        )}
                                      >
                                        <option value="below">
                                          Not Meeting Benchmarks
                                        </option>
                                        <option value="at_or_above">
                                          Meeting/Exceeding Benchmarks
                                        </option>
                                      </Form.Control>
                                    </td>
                                    <td className="colorCriteriaDesc">
                                      <Form.Control
                                        //disabled={props.withReadingAlgorithm}
                                        required
                                        name={"display_name"}
                                        type="text"
                                        className="stdInput"
                                        onChange={handleColorCriteriaChange(
                                          colorCriteria
                                        )}
                                        value={colorCriteria.display_name || ""}
                                      />
                                    </td>
                                    <td>
                                      <Form.Control
                                        required
                                        placeholder="Min"
                                        name={"range_min"}
                                        type="number"
                                        className="inputSm"
                                        min={measurement?.range_min}
                                        pattern="[0-9]+"
                                        ref={refCallback(colorCriteria)}
                                        value={
                                          colorCriteria.range_min === 0
                                            ? "0"
                                            : ((colorCriteria.range_min as unknown) as string) ||
                                            ""
                                        }
                                        onChange={handleColorCriteriaChange(
                                          colorCriteria
                                        )}
                                      />
                                      <strong>&nbsp;-&nbsp;</strong>
                                      <Form.Control
                                        required
                                        placeholder="Max"
                                        name={"range_max"}
                                        type="number"
                                        className="inputSm"
                                        pattern="[0-9]+"
                                        max={measurement?.range_max}
                                        value={
                                          colorCriteria.range_max === 0
                                            ? "0"
                                            : ((colorCriteria.range_max as unknown) as string) ||
                                            ""
                                        }
                                        onChange={handleColorCriteriaChange(
                                          colorCriteria
                                        )}
                                      />
                                    </td>
                                  </>
                                ) : (
                                  <Form.Control
                                    required
                                    name={"selected_value_order"}
                                    as="select"
                                    value={
                                      (colorCriteria.selected_value_order as unknown) as string
                                    }
                                    onChange={handleColorCriteriaChange(
                                      colorCriteria
                                    )}
                                    disabled={props.useGradeSpecificColor}
                                  >
                                    <option key={-1} value={""} />
                                    {measurement?.possible_values
                                      ?.filter(
                                        (possibleValue) =>
                                          !measurement?.color_criteria!.find(
                                            (cc) =>
                                              cc.selected_value_order ===
                                              possibleValue.order &&
                                              cc.selected_value_order !==
                                              colorCriteria.selected_value_order
                                          )
                                      )
                                      .map((possibleValue) => (
                                        <option
                                          key={possibleValue.order}
                                          value={possibleValue.order}
                                        >
                                          {possibleValue.display_name}
                                        </option>
                                      ))}
                                  </Form.Control>
                                )}
                                <td>
                                  <GithubPicker
                                    color={colorCriteria.color}
                                    onChange={handleColorPickerChange(colorCriteria)}
                                    width="112px"
                                    triangle="hide"
                                    colors={[
                                      "#ffd1da",
                                      "#ffefc1",
                                      "#bff9dd",
                                      "#deeaf6",
                                    ]}
                                  />
                                </td>
                                <td>
                                  <SketchColorPicker
                                    readonly={props.useGradeSpecificColor}
                                    color={colorCriteria.color}
                                    handleColorPickerClose={handleColorPickerClose}
                                    handleColorPickerChange={handleColorPickerChange(
                                      colorCriteria
                                    )}
                                    handleColorPickerClick={handleColorPickerClick(
                                      index
                                    )}
                                    displayColorPicker={
                                      colorPicker.display &&
                                      colorPicker.target === index
                                    }
                                  />
                                </td>
                                <td>
                                  {props.useGradeSpecificColor ? null : (
                                    <Button
                                      onClick={handleColorCriteriaRemove(
                                        colorCriteria
                                      )}
                                    >
                                      <FontAwesomeIcon
                                        icon="times-circle"
                                        style={{
                                          color: "#9B9B9B",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </Button>
                                  )}
                                </td>
                              </tr>
                            }
                          </React.Fragment>

                        )
                      )}
                    {!props.useGradeSpecificColor &&
                      (!isCategoryType ||
                        (isCategoryType &&
                          measurement?.color_criteria?.length !==
                          measurement?.possible_values?.length)) && (
                        <tr>
                          <td
                            colSpan={
                              isCategoryType ? 2 : props.withGrades ? 5 : 3
                            }
                          />
                          <td>
                            <Button onClick={handleColorCriteriaAdd}>
                              {" "}
                              <FontAwesomeIcon
                                icon="plus-circle"
                                style={{ color: "#2A74D1", cursor: "pointer" }}
                              />
                            </Button>
                          </td>
                        </tr>
                      )}
                  </tbody>
                ) : (
                  <tbody>
                    {!isCategoryType ? (
                      measurement?.color_criteria &&
                      ColorCriteriaBenchmarkNames.map(
                        (benchmarkName: string, index: number) => {
                          const benchmarkCriteria = getBenchmarkCriteriaByName(
                            benchmarkName
                          );
                          return (
                            <tr key={benchmarkName}>
                              <td className="colorCriteriaDesc font-weight-bold">
                                <Form.Label column={false}>
                                  {benchmarkName}
                                </Form.Label>
                              </td>
                              <td
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Form.Control
                                  required
                                  placeholder="Min"
                                  name={"range_min"}
                                  type="number"
                                  className="inputSm"
                                  min={measurement?.range_min}
                                  value={
                                    benchmarkCriteria.range_min === 0
                                      ? "0"
                                      : ((benchmarkCriteria.range_min as unknown) as string) ||
                                      ""
                                  }
                                  onChange={handleBenchmarkCriteriaChange(
                                    benchmarkName
                                  )}
                                />
                                <strong>&nbsp;-&nbsp;</strong>
                                <Form.Control
                                  required
                                  placeholder="Max"
                                  name={"range_max"}
                                  type="number"
                                  className="inputSm"
                                  max={measurement?.range_max}
                                  value={
                                    benchmarkCriteria.range_max === 0
                                      ? "0"
                                      : ((benchmarkCriteria.range_max as unknown) as string) ||
                                      ""
                                  }
                                  onChange={handleBenchmarkCriteriaChange(
                                    benchmarkName
                                  )}
                                />
                              </td>
                              <td>
                                <SketchColorPicker
                                  color={benchmarkCriteria.color}
                                  handleColorPickerClose={handleColorPickerClose}
                                  handleColorPickerChange={handleColorPickerChange(
                                    benchmarkCriteria
                                  )}
                                  handleColorPickerClick={handleColorPickerClick(
                                    index
                                  )}
                                  displayColorPicker={
                                    colorPicker.display &&
                                    colorPicker.target === index
                                  }
                                />
                              </td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <>
                        {measurement?.color_criteria &&
                          measurement?.color_criteria.map(
                            (benchmarkCriteria: ColorCriteria, index: number) => (
                              <tr key={index}>
                                <Form.Control
                                  required
                                  name={"selected_value_order"}
                                  as="select"
                                  value={
                                    (benchmarkCriteria.selected_value_order as unknown) as string
                                  }
                                  onChange={handleColorCriteriaChange(
                                    benchmarkCriteria
                                  )}
                                >
                                  <option key={-1} value={""} />
                                  {measurement
                                    ?.possible_values!.filter(
                                      (possibleValue) =>
                                        !measurement?.color_criteria!.find(
                                          (cc) =>
                                            cc.selected_value_order ===
                                            possibleValue.order &&
                                            cc.selected_value_order !==
                                            benchmarkCriteria.selected_value_order
                                        )
                                    )
                                    .map((possibleValue) => (
                                      <option
                                        key={possibleValue.order}
                                        value={possibleValue.order}
                                      >
                                        {possibleValue.display_name}
                                      </option>
                                    ))}
                                </Form.Control>
                                <td>
                                  <SketchColorPicker
                                    color={benchmarkCriteria.color}
                                    handleColorPickerClose={
                                      handleColorPickerClose
                                    }
                                    handleColorPickerChange={handleColorPickerChange(
                                      benchmarkCriteria
                                    )}
                                    handleColorPickerClick={handleColorPickerClick(
                                      index
                                    )}
                                    displayColorPicker={
                                      colorPicker.display &&
                                      colorPicker.target === index
                                    }
                                  />
                                </td>
                                <td>
                                  <Button
                                    onClick={handleColorCriteriaRemove(
                                      benchmarkCriteria
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon="times-circle"
                                      style={{
                                        color: "#9B9B9B",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Button>
                                </td>
                              </tr>
                            )
                          )}
                        {(!isCategoryType ||
                          (isCategoryType &&
                            measurement?.color_criteria!.length !==
                            measurement?.possible_values!.length)) && (
                            <tr>
                              <td colSpan={isCategoryType ? 2 : 3} />
                              <td>
                                <Button onClick={handleColorCriteriaAdd}>
                                  {" "}
                                  <FontAwesomeIcon
                                    icon="plus-circle"
                                    style={{ color: "#2A74D1", cursor: "pointer" }}
                                  />
                                </Button>
                              </td>
                            </tr>
                          )}
                      </>
                    )}
                  </tbody>
                )}
              </Table>
            </Form>
            <div className="col-12 pl-0">
                <div className="alert alert-warning py-1" role="alert">
                Once you click on Apply, the Areas of Need will be updated based on district benchmarks you have entered. 
                </div>
            </div>                 
            <label className="blueBtnSm colorPickerApplyButton">
              <Button type="submit" form="colorCriteriaForm">
                Apply{" "}
                {props.isLoading && <Spinner animation="border" size="sm" />}
              </Button>
            </label>
          </>
        )}

        {!isAllowedToEdit && (
          <>
            <h6>Insufficient Permission</h6>
            <h3 className="font-weight-bold my-3">
              {
                "Please contact your district administrator for permission to edit."
              }
            </h3>
            <label className="blueBtnSm colorPickerApplyButton">
              <Button
                type="submit"
                form="colorCriteriaForm"
                onClick={handleCloseModal}
              >
                Close
              </Button>
            </label>
          </>
        )}

      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ onboarding, groups }: ApplicationState): PropsFromState => {
  return {
    enterDataManuallyType: onboarding.modalsState.enterDataManuallyType,
    //searchFilter: groups.searchFilter
  };
};


export default connect(
  mapStateToProps,
  null
)(EditMeasureColorCriteriaModal);
