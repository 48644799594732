import React, { useEffect, useMemo, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Form, Modal, Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  createReleaseNote,
  getAllReleaseNotes,
  setShowReleaseModal,
  updateReleaseNote,
} from "../../../../../../store/superuser/actions";
import {
  Release_Notes,
  SuperuserState
} from "../../../../../../store/superuser/types";
import _ from "lodash";
import { useLoading } from "../../../../../../utils/hooks/useLoading";
import { toastr } from "react-redux-toastr";
import { htmlDecoding, htmlEncoding } from "../../../../../utils";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";

const defaultValue: Release_Notes = {
  id: 0,
  title: "What's new",
  description: "",
  is_published: false
};

type Props = {};


const UpsertReleaseModal: React.FC<Props> = () => {
  const {
    selectedReleaseNote,
    modalsState: {
      upsertReleaseModal: {
        show,
        onSuccess: onSuccessAdd,
      },
    }
  } = useSelector<ApplicationState, SuperuserState>((s) => s.superuser);

  const dispatch = useDispatch();

  const [releaseNote, setReleaseNote] = useState<Release_Notes>(defaultValue);


  const handleShow = () => {
    if (selectedReleaseNote) {
      setReleaseNote({
        ...releaseNote,
        id: selectedReleaseNote.id,
        title: selectedReleaseNote.title,
        description: htmlDecoding(selectedReleaseNote.description),
      });
      
    } else {
      setReleaseNote({
        ...defaultValue
      });
    }
  };

  const handleClose = () => {
      dispatch(setShowReleaseModal({ show: false, releaseNote: defaultValue }));
      setReleaseNote(defaultValue);
  };


  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if(!releaseNote.description) {
      toastr.error('Alert!!', 'Please enter the description.');
      return;
    }
    if (releaseNote.id) {
      dispatch<any>(
        updateReleaseNote({
          ...releaseNote,
          description: htmlEncoding(releaseNote.description)
        })
      ).then(
        (releaseNote: Release_Notes) => {
          toastr.success('Update: ', 'Note updated successfully');
          setReleaseNote({
            ...defaultValue
          });
          handleClose();
        }
      );
    } else {
       dispatch<any>(
        createReleaseNote({
          ...releaseNote,
          description: htmlEncoding(releaseNote.description)
        })
      ).then(
        (releaseNote: Release_Notes) => {
          toastr.success('Success: ', 'Note saved successfully')
          handleClose();
        }
      );
    }
  };


  const loading = useSelector<ApplicationState, boolean>((s) =>
    selectedReleaseNote
      ? s.superuser.isLoading.updateReleaseNote
      : s.superuser.isLoading.createReleaseNote
  );
  const error = useSelector<ApplicationState, string | undefined>((s) =>
    selectedReleaseNote ? s.superuser.errors.updateReleaseNote : s.superuser.errors.createReleaseNote
  );
  const onSuccess = () => {
    dispatch(setShowReleaseModal({ show: false, releaseNote: null }));
  };
  useLoading(loading, error, onSuccess);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setReleaseNote((prevState) => ({ ...prevState, ['title']: value }));
  };

  const handleDescriptionChange = (description: any) => {
    setReleaseNote((prevState) => ({ ...prevState, ['description']: description }));
  };

  const handleIsPublished = (checked: boolean) => {
    setReleaseNote((prevState) => ({ ...prevState, ['is_published']: checked }));
  };

  return (
    
    <Modal
      show={show}
      onShow={handleShow}
      onHide={handleClose}
      animation={false}
      backdropClassName="customDarkModalBackdrop in"
      size="lg"
    >
      <Modal.Header closeButton className="purpleModalHeader font-weight-bold">
        <Modal.Title>{releaseNote.id ? "Edit Release Note" : "Create Release Note"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="releaseNoteModalForm" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label className="font-weight-bold" column={false}>
              Title
            </Form.Label>
            <Form.Control
              required
              placeholder="Enter title..."
              name="title"
              onChange={handleChange}
              value={releaseNote.title}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="font-weight-bold" column={false}>
              Description
            </Form.Label>
            <CKEditor
              editor={ ClassicEditor}
              config={ {
                toolbar: ['heading', 
                '|', 'bold', 'italic',
                '|', 'numberedList', 'bulletedList', 
                '|', 'outdent', 'indent',
                '|', 'undo', 'redo']
              } }
              onChange={ ( event, editor ) => {
                const data = editor.getData();
                 handleDescriptionChange(data);
              } }
              data={releaseNote.description}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="blueBtnSm"
          type="submit"
          form="releaseNoteModalForm"
          disabled={loading}
        >
          Save {loading && <Spinner animation="border" size="sm" />}
        </button>
        <button
          className="whiteBtnSm"
          type="reset"
          onClick={handleClose}
          disabled={loading}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpsertReleaseModal;
