import React from "react";
import Manual from "./Manual";
import Button from "react-bootstrap/Button";
import { IS_READY_COACH } from "../../../../../constants";
import { toastr } from "react-redux-toastr";
import useUserRole from "../../../../../utils/hooks/useUserRole";

type Props = {
  openSearchForYourAssessmentModal: () => void;
  handleAcceptFile: () => void;
  onHide?: () => void;
};

const FileUnrecognizedContent = (props: Props) => {
  const { openSearchForYourAssessmentModal, handleAcceptFile, onHide } = props;
  const { isDistrictOwner } = useUserRole();
  return (
    <>
      <div className="text-center">
        <h4 className="font-weight-bold m-0">
          Unfortunately, we didn't recognize the contents of the file you
          uploaded. To ensure that the file can be recognized properly,
          please verify the format of the file and the spelling of the headers is correct.
        </h4>
        {!isDistrictOwner ?
          <>
            <p>
              You'll need to tell us what the columns mean. It's easy, don't worry.
            </p>
            <div className="px-5">
              <div className="px-5">
                <hr className="narrowMargin" />
              </div>
            </div>
          </>
          : ''
        }
      </div>
      {!isDistrictOwner ?
        <>
          <Manual />
          <div className="btnActions">
            <label
              className="clickableLabel"
              onClick={openSearchForYourAssessmentModal}
            >
              <Button>I want to search for my assessment</Button>
            </label>
            <label className="blueBtnLg">
              <Button onClick={handleAcceptFile}>Got it, let's do this!</Button>
            </label>
          </div>
        </>
        :
        <div className="btnActions mt-2">
          <label className="clickableLabel"></label>
          <label className="blueBtnSm">
            <Button onClick={onHide}>Close</Button>
          </label>
        </div>
      }
    </>
  );
};

export default FileUnrecognizedContent;
