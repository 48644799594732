import { Button, Col, Modal, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import React, { Component } from "react";
import {
  AddUserToDistrictRequest,
  BasicUserInfo,
  EmailCheckResponse,
  InvitesResponse,
  ReactSelectOption,
  SuccessfulInvite,
  TeacherInfo
} from "../../../../store/onboarding/types";
import FailedInvitationsAlert from "./upload-educators/FailedInvitationsAlert";
import { IS_READY_COACH } from "../../../../constants";
import { ApplicationState } from "../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  checkEmailExist,
  createUserInDistrict,
  getTeachers,
  hideTeacherInviteIndividuallyModal,
  sendInviteToTeachers,
} from "../../../../store/onboarding/actions";
import { connect } from "react-redux";
import { UserAssignmentRole, UserInfo } from "../../../../store/auth/types";
import { districtOwnerAssignableRoles } from "../../../../store/onboarding/users-management/types";
import Select, { ValueType } from "react-select";
import CreatableSelect from "react-select/creatable";
import { districtRoles } from "../../../../store/auth/constants";
import { toastr } from "react-redux-toastr";
import { showConfirmDialog } from "../../../../store/confirm-dialog/actions";
import { faCircle, faExclamationCircle, faTimesCircle, faUndo, faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type State = {
  invites: Array<AddUserToDistrictRequest>;
  isConfirm: boolean;
  teacherOptions?: ReactSelectOption<BasicUserInfo>[];
};

type PropsFromState = {
  hasDistrictPermission?: boolean;
  isLoading: {
    sendInviteToTeachers: boolean;
    addUserToDistrict: boolean;
  };
  errors?: {
    sendInviteToTeachers?: string;
  };
  invites: InvitesResponse;
  newTeacherForCreatable?: BasicUserInfo;
  modalShow: boolean;
  callback?: Function;
  districtUserName?: string;
  showFailedInvitationsAlert: boolean;
  currentRole?: string;
  teachersRoster?: TeacherInfo[];
};

type DispatchProps = {
  createUserInDistrict: (invites: AddUserToDistrictRequest[]) => any;
  checkEmailExist: (inviteEmails:Array<any>) => any;
  sendInviteToTeachers: (invites: Array<BasicUserInfo>) => any;
  getTeachers: () => any;
  onModalHide: () => void;
  showConfirmDialog: (confirmObj: any) => any;
};

type Props = PropsFromState & DispatchProps;

class TeacherInviteIndividuallyModal extends Component<Props, State> {
  emptyInvite: AddUserToDistrictRequest = {
    id: 0,
    first_name: "",
    last_name: "",
    email: "",
    role: undefined,
    isSetValue: false
  };

  state: Readonly<State> = {
    invites: [],
    isConfirm: false,
    teacherOptions: [],
  };
  

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    const {
      newTeacherForCreatable,
      invites,
      getTeachers,
      onModalHide,
      teachersRoster
    } = this.props;

    if (
      prevProps.isLoading.sendInviteToTeachers &&
      !this.props.isLoading.sendInviteToTeachers
    ) {
      const { failed_invites, successful_invites } = invites;
      if (failed_invites.length > 0) {
        const newRawData = this.state.invites.filter(
          (invite) =>
            !successful_invites.find((successfulInvite: SuccessfulInvite) => {
              return successfulInvite.user.email.trim() === invite.email.trim();
            })
        );
        this.setState({ invites: newRawData });
      } else {
        getTeachers();

        if (this.props.callback) {
          this.props.callback(successful_invites);
        }

        onModalHide();
      }
    }
    if (!prevProps.modalShow && this.props.modalShow) {
      if (newTeacherForCreatable) {
        this.setState({
          invites: [
            {
              first_name: newTeacherForCreatable.first_name,
              last_name: newTeacherForCreatable.last_name,
              email: newTeacherForCreatable.email,
            },
          ],
        });
      }

      this.setState({
        teacherOptions: 
          teachersRoster!
          .filter((teacherRoster) => teacherRoster.user.profile.current_role == UserAssignmentRole.NJTSS_IMPORTED_USER)
          .map((st) => ({
            label: st.user.first_name + " " + st.user.last_name,
            value: st.user,
          })) as any
      });
    }
  }

  handleOnEnter = () => {
    this.setState({
      invites: [this.emptyInvite],
    });
  };

  handleAddInvite = () => {
    this.setState((prevState) => ({
      ...prevState,
      invites: [...prevState.invites, this.emptyInvite],
    }));
  };

  handleRemove = (recIndex:number) => {
    const invites = this.state.invites;
    this.setState((prevState) => ({
      ...prevState,
      invites: [...invites.filter((invite, index) => recIndex !== index)],
    }));
  };

  handleReset = (recIndex:number) => {
    this.setState((state) => ({
      ...state,
      invites: state.invites.map((invite: BasicUserInfo, index) => {
        if (index === recIndex) {
          return {
            ...invite,
            id: 0,
            first_name: "",
            last_name: "",
            email: "",
            role: undefined,
            isSetValue: false
            
          };
        }
        return invite;
      }),
      isConfirm: false
    }));
  };

  handleInviteSelectChange = (selectedIndex: number) => (
    selectValue: ValueType<ReactSelectOption<BasicUserInfo>, false>
  ) => {
    const splitInput = (selectValue as ReactSelectOption<BasicUserInfo>)?.label.trim().split(" ");
    const extraUserInfo = (selectValue as ReactSelectOption<UserInfo>)?.value;
    console.log(extraUserInfo)
    this.setState((state) => ({
      ...state,
      invites: state.invites.map((invite: BasicUserInfo, index) => {
        if (index === selectedIndex) {
          return {
            ...invite,
            id: extraUserInfo.id || 0,
            first_name: splitInput![0],
            last_name: splitInput![1] || "",
            email: extraUserInfo.email || "",
            role: extraUserInfo.profile.current_role,
            isSetValue: true
            
          };
        }
        return invite;
      }),
      isConfirm: false
    }), () => {
      // this.setState({
      //   teacherOptions: this.state.teacherOptions?.filter((teacher) => 
      //                     !this.state.invites.some((invite) => invite.id == teacher.value.id))
      // })
    });
  };

  handleInviteChange = (selectedIndex: number) => (
    event: React.FormEvent<any>
  ) => {
    console.log('index', selectedIndex)
    const { name, value } = event.target as HTMLInputElement;
    this.setState((state) => ({
      ...state,
      invites: state.invites.map((invite: BasicUserInfo, index) => {
        if (index === selectedIndex) {
          return {
            ...invite,
            [name]: value,
            isSetValue: true
            
          };
        }
        return invite;
      }),
      isConfirm: false
    }));
  };

  handleCreateInvite = (selectedIndex: number) => (input: string) => {
    const splitInput = input.split(" ");
    this.setState((state) => ({
      ...state,
      invites: state.invites.map((invite: BasicUserInfo, index) => {
        if (index === selectedIndex) {
          return {
            ...invite,
            id: 0,
            first_name: splitInput![0],
            last_name: splitInput![1] || "",
            email: splitInput![2] || "",
            isSetValue: true
            
          };
        }
        return invite;
      }),
      isConfirm: false
    }));
  };

  createUserByDistrict = () => {
    this.props.createUserInDistrict(this.state.invites).then(
      () => {
        this.setState({isConfirm: false})
        this.handleModalClose();
        toastr.success(
          "Success",
          `The invitation has been sent successfully.`
          );
      },
      (err: any) =>  toastr.error("Failed to send the invite", err)
    //     //  todo handle failed invites
     );
  }

  handleInviteSend = (event: React.FormEvent<any>) => {
    event.preventDefault();
    const invities = this.state.invites;
    let isRequiredFlag = false;
    let isRequiredSelectFlag = false;
    for (let i = 0; i < invities.length; i++) {
      const element = invities[i];
      if(!element.first_name) {
        isRequiredSelectFlag = true;
        break;
      }
    }
    if(isRequiredSelectFlag) {
      toastr.error('Alert!!', 'Please enter user name.');
      this.setState({isConfirm: false});
      return;
    }
    if (this.props.hasDistrictPermission) {
      for (let i = 0; i < invities.length; i++) {
        const element = invities[i];
        if(element.role == undefined) {
          isRequiredFlag = true;
          break;
        }  
      }
      if(isRequiredFlag) {
        toastr.error('Alert!!', 'Please select account type.');
        this.setState({isConfirm: false});
        return;
      }
      const onConfirm = () => {
        this.setState({isConfirm: true});
      };
      if(!this.state.isConfirm) {
        this.props.showConfirmDialog({
          onConfirm,
          centered: true,
          size:'',
          title: "Confirm Account Type",
          timerElipseLeft: true,
          countDownTimer: 3,
          text: (
            <>
              The account type you have assigned the user is permanent and cannot be changed. Are you sure you want to continue?
            </>
          ),
          confirmButtonText: "Yes, Assign Account Type",
        });
      }
   
      if(this.state.isConfirm) {
        const inviteEmails = this.state.invites.map((invite) => invite.email);
        
        this.props.checkEmailExist(inviteEmails)
        .then((result:EmailCheckResponse) => {
          if(!result.emails_already_exist.length) {
            if(this.state.invites.some((invite) => 
                [UserAssignmentRole.COACH, UserAssignmentRole.TEACHER].includes(invite?.role!))
            ) {
              this.props.showConfirmDialog({
                onConfirm: () => {
                  this.createUserByDistrict();
                },
                centered: true,
                size:'',
                title: "Confirmation",
                timerElipseLeft: true,
                countDownTimer: 3,
                text: (
                  <>
                    In order for coach and teacher users to share data and collaborate on intervention cases, teachers must be assigned to a coach by accessing the actions menu in Manage users to assign teachers to coaches.
                  </>
                ),
                confirmButtonText: "Continue",
              });
            } else {
              this.createUserByDistrict();
            }
          } else {
  
            toastr.error("Alert!!", 'The highlighted email(s) below already exist in the system. Please use a different email to invite this user(s).')
            invities.map((invite,index) => {
              if(result.emails_already_exist.some((existingEmail:string) => existingEmail == invite.email)){
                document.getElementById(`email_${index}`)!.style.border="1px solid red";
              }
              if(result.new_emails.some((newEmail:string) => newEmail == invite.email)) {
                document.getElementById(`email_${index}`)!.style.border="";
              }
            })
  
          } 
        },
        (err: any) =>  toastr.error("Failed to send the invite", err)
      )
        
      }
    } else {
      this.props.sendInviteToTeachers(this.state.invites).then(
        () => {
        this.setState({isConfirm: false})
        if(!this.props.showFailedInvitationsAlert && !this.props.errors?.sendInviteToTeachers){
          toastr.success(
            "Success",
            `You have invited new user(s) to ReadyCoach. Your district admin (${this.props.districtUserName}) has been contacted to establish data accessing settings for the invited user to access shared data.`
          );
        }
        },
        (err: string) => toastr.error("Failed to send the invite", err)
      );
    }
    
    
  };

  handleModalClose = () => {
    const { getTeachers, onModalHide, isLoading } = this.props;
    if (!isLoading.sendInviteToTeachers) {
      getTeachers(); //todo need to optimize
      onModalHide();
      this.setState({
        invites: [this.emptyInvite],
      });
    }
  };

  handleRoleChange = (selectedIndex: number) => (
    role: ValueType<ReactSelectOption<string>, false>
  ) => {
    this.setState((state) => ({
      ...state,
      invites: state.invites.map((invite: BasicUserInfo, index) => {
        if (index === selectedIndex) {
          return {
            ...invite,
            role: (role as ReactSelectOption<string>)
              ?.value as UserAssignmentRole,
          };
        }
        return invite;
      }),
    }));
  };

  render() {
    const { invites } = this.state;
    const { hasDistrictPermission } = this.props;
    return (
      //@ts-ignore
      <Modal
        show={this.props.modalShow}
        animation={false}
        backdropClassName="customDarkModalBackdrop in"
        //@ts-ignore
        onShow={this.handleOnEnter}
        onHide={this.handleModalClose}
        size="lg"
      >
        <Modal.Header
          closeButton
          className="purpleModalHeader orangeModalHeader"
        >
          <Modal.Title>Invite Individually</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {hasDistrictPermission ?
             <div className="alert alert-warning p-1">Data access settings need to be established for invited users to view data within the system. Please see MANAGE PERMISSIONS to establish data access settings.</div>
             : ''
          }

          <h3>
            Enter their names and emails then hit <strong>Send</strong> and
            we'll send them an email with instructions to join{" "}
            {IS_READY_COACH ? (
              <strong>ReadyCoach</strong>
            ) : (
              <strong>NJTSS Early Reading</strong>
            )}
            .
          </h3>
          <br />
          <FailedInvitationsAlert />
          <div className="inviteContainer">
            <form id="newInviteForm12" onSubmit={this.handleInviteSend}>
              {invites.map((invite, index) => (
                <Form.Row key={`invite_${index}`} className="my-2">
                  <div className="d-flex align-items-center w-25px"> 
                    {invite.id! > 0 ? <FontAwesomeIcon
                        icon={faExclamationCircle}
                        size="lg"
                        className="purpleText"
                        title="This user is imported from file. The invitation will be sent to this existing user."
                      /> : '' }
                  </div>
                  <Col>
                  {!invite.isSetValue 
                  ?
                    <CreatableSelect
                      createOptionPosition="first"
                      onChange={this.handleInviteSelectChange(index)}
                      onCreateOption={this.handleCreateInvite(index)}
                      formatCreateLabel={(input: string) =>
                        `Add new '${input}'`
                      }
                      allowCreateWhileLoading
                      options={this.state.teacherOptions as any}
                      placeholder="Enter user name"
                      name="first_name"
                    />
                  :
                    <Form.Control
                      required
                      name="first_name"
                      //isLoading={props.isLoading.getStudents}
                      onChange={this.handleInviteChange(index)}
                      value={invite.first_name}
                    />
                  }
                  </Col>
                  <Col>
                    <Form.Control
                      required
                      placeholder="Enter last name"
                      name="last_name"
                      onChange={this.handleInviteChange(index)}
                      value={invite.last_name}
                      readOnly={!invite.first_name}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      required
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      onChange={this.handleInviteChange(index)}
                      value={invite.email}
                      id={`email_${index}`}
                      readOnly={!invite.first_name}
                    />
                  </Col>
                  {hasDistrictPermission && (
                    <Col>
                      <Select
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            height: "35px",
                            "min-height": "35px",
                            "width": "255px"
                          }),
                        }}
                        isClearable
                        name="roles"
                        placeholder="Select Account Type"
                        options={districtOwnerAssignableRoles.filter((dist)=> ['District Admin','Coach','Teacher'].includes(dist.label))}
                        value={invite.role != undefined ? districtOwnerAssignableRoles.find(
                          (role) => role.value === invite.role
                        ): null}
                        onChange={this.handleRoleChange(index)}
                        isDisabled={!invite.first_name || !!invite.id}
                      />
                    </Col>
                  )}
                  {(index > 0) ? <Col md={"1"} className="mt-2 d-flex justify-content-start">
                  <>
                    <span className={invite.first_name || invite.last_name || invite.email ? `circle` : `circle-transparent`} onClick={() => this.handleReset(index)}>
                  {invite.first_name || invite.last_name || invite.email ? 
                      <FontAwesomeIcon
                        icon={faUndoAlt}
                        size="lg"
                        title="Reset"
                        />
                    : ''}
                    </span> 
                    <span className="mx-1 removeIconBtn" onClick={() => this.handleRemove(index)}>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        color="red"
                        size="lg"
                        title="Remove"
                        /></span>
                  </>
                    </Col> 
                  : <Col md={"1"} className="d-flex align-items-center">
                      {invite.first_name || invite.last_name || invite.email ? 
                      <span className="circle" onClick={() => this.handleReset(index)}>
                        <FontAwesomeIcon
                          icon={faUndoAlt}
                          size="lg"
                          title="Reset"
                          />
                    </span>
                      : ''}
                    </Col>}
                </Form.Row>
              ))}
            </form>
          </div>

          <div className="btnActions">
            <div />
            <Button
              bsPrefix="b"
              className="blueBtnSm"
              onClick={this.handleAddInvite}
            >
              Add another
            </Button>
          </div>

          <hr className="narrowMargin" />
          <div className="modalActions" style={{ marginTop: "20px" }}>
            <div className="leftActions">
              <Button
                disabled={this.props.isLoading.sendInviteToTeachers}
                bsPrefix="b"
                onClick={this.props.onModalHide}
                className="blueBtnSm"
              >
                Cancel
              </Button>
            </div>
            <div className="rightActions">
              <button
                form="newInviteForm12"
                type="submit"
                className="blueBtnSm"
                disabled={(this.props.isLoading.sendInviteToTeachers ||
                  this.props.isLoading.addUserToDistrict)}
              >
                Send Invites and Close{" "}
                {(this.props.isLoading.sendInviteToTeachers ||
                  this.props.isLoading.addUserToDistrict) && (
                  <Spinner animation="border" size="sm" />
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  auth,
  onboarding,
}: ApplicationState): PropsFromState => ({
  hasDistrictPermission:
    auth.userInfo?.profile.current_assignment?.role &&
    districtRoles.includes(auth.userInfo.profile.current_assignment.role),
  districtUserName: auth.userInfo?.profile.district?.owner.first_name + " " + auth.userInfo?.profile.district?.owner.last_name,
  currentRole: auth.userInfo?.profile.current_assignment?.role,
  modalShow: onboarding.modalsState.teachersIndividuallyInviteModal,
  callback: onboarding.modalsState.teachersIndividuallyInviteModalCallback,
  invites: onboarding.invites,
  newTeacherForCreatable: onboarding.newTeacherForCreatable,
  errors: { sendInviteToTeachers: onboarding.errors.sendInviteToTeachers },
  isLoading: {
    sendInviteToTeachers: onboarding.isLoading.sendInviteToTeachers,
    addUserToDistrict: onboarding.isLoading.addUserToDistrict,
  },
  showFailedInvitationsAlert: onboarding.modalsState.showFailedInvitationsAlert,
  teachersRoster: onboarding.teachersRoster
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      createUserInDistrict: createUserInDistrict,
      checkEmailExist: checkEmailExist,
      sendInviteToTeachers: sendInviteToTeachers,
      onModalHide: hideTeacherInviteIndividuallyModal,
      getTeachers: getTeachers,
      showConfirmDialog: showConfirmDialog
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeacherInviteIndividuallyModal);
