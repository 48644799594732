import React, { Component } from "react";
import { Cell } from "fixed-data-table-2";
import Popover from "react-bootstrap/Popover";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { OverlayTrigger } from "react-bootstrap";
import {
  DataPeriod,
  EvidencePeriodData,
  EvidenceType,
  StudentEntry,
} from "../../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../../store";
import {
  openConfirmDiagnosisModal,
  openSkillFocusesModal,
} from "../../../../../../../store/groups/actions";
import { toastr } from "react-redux-toastr";

type OwnProps = {
  studentEntry?: StudentEntry;
  showHint?: boolean;
  studentId: number;
  dataPeriod?: DataPeriod;
};

type PropsFromState = {
  evidencePeriodData?: EvidencePeriodData;
  isEnterSpecificSkillActive: boolean;
};

type DispatchProps = {
  openSkillFocusesModal: (studentEntry?: StudentEntry) => any;
  openConfirmDiagnosisModal: (studentEntry?: StudentEntry) => any;
};

type Props = OwnProps & PropsFromState & DispatchProps;

class DiagnosticSkillDataEntryCell extends Component<Props> {
  overlay: any;
  handleSkillFocusesModalOpen = () => {
    if (this.overlay) this.overlay.hide();
    const { studentEntry, studentId, evidencePeriodData } = this.props;
    if (evidencePeriodData) {
      let needsAreaEntry: StudentEntry | undefined;
      evidencePeriodData.evidence_column_groups.some((ecg) =>
        ecg.evidence_columns.some(
          (ec) =>
            ec.evidence_type === EvidenceType.NeedAreas &&
            ec.student_entries.some((se) => {
              if (se.student_id === studentId) {
                needsAreaEntry = se;
                return true;
              }
              return false;
            })
        )
      );
      if(needsAreaEntry) {
        if(needsAreaEntry.grade == 'K' && this.props.dataPeriod?.time_period == 'boy') {
          toastr.error('Alert!!','Currently, ReadyCoach does not recommend intervention groups at the beginning of the year for Kindergarten based on diagnostic skills. Students who have Content Areas of Need should be provided tier 1 instruction in those areas at the beginning of the year.',{timeOut:7000});
          //return;
        }
      }
      if (
        needsAreaEntry &&
        needsAreaEntry.needs_area_tags &&
        needsAreaEntry.needs_area_tags.some((t) => t.tag === "rr_core_support")
      ) {
        this.props.openConfirmDiagnosisModal(
          studentEntry ? studentEntry : { student_id: studentId, entry_id: -1 }
        );
      } else {
        this.props.openSkillFocusesModal(
          studentEntry ? studentEntry : { student_id: studentId, entry_id: -1 }
        );
      }
    }
  };

  render() {
    return (
      <Cell
        className="dataTableCellEmpty"
        onClick={this.handleSkillFocusesModalOpen}
      >
        <div>
          {this.props.showHint ? (
            <OverlayTrigger
              ref={(ref: any) => (this.overlay = ref)}
              placement="bottom"
              defaultShow
              //@ts-ignore
              trigger={null}
              overlay={
                <Popover id="diagSkillCellPopover" className="text-center">
                  <Popover.Content>
                    <p>
                      To produce better group recommendations, you can select
                      specific skills which students are struggling with.
                    </p>
                    <button
                      className="blueBtnMd"
                      onClick={() => this.overlay.hide()}
                    >
                      GOT IT
                    </button>
                  </Popover.Content>
                </Popover>
              }
            >
              <button className="blueBtnSm">SELECT SKILLS</button>
            </OverlayTrigger>
          ) : (
            <button className="blueBtnSm">SELECT SKILLS</button>
          )}
        </div>
      </Cell>
    );
  }
}

const mapStateToProps = ({
  cases,
  onboarding,
}: ApplicationState): PropsFromState => {
  return {
    isEnterSpecificSkillActive: cases.isEnterSpecificSkillActive,
    evidencePeriodData: onboarding.evidencePeriodData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openSkillFocusesModal: openSkillFocusesModal,
      openConfirmDiagnosisModal: openConfirmDiagnosisModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiagnosticSkillDataEntryCell);
