import React, { FunctionComponent, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import Table from "react-bootstrap/Table";
import { getInterventionGroupLogging } from "../../../../../../store/onboarding/actions";
import { ApplicationState } from "../../../../../../store";
import moment from "moment";
import { getFullName } from "../../../../../../utils/NamesUtils";

type OwnProps = {
  interventionGroup: InterventionGroup;
  studentId?: number;
};

type StateProps = {
  interventionGroupLogging: Array<any>;
};

type DispatchProps = {
  getInterventionGroupLogging: (interventionGroupId: number, studentId: number) => any;
};

type Props = OwnProps & DispatchProps & StateProps;

const InterventionGroupLoggingTable: FunctionComponent<Props> = ({
  interventionGroupLogging,
  interventionGroup,
  studentId,
  getInterventionGroupLogging,
}) => {
  useMemo(() => {
    getInterventionGroupLogging(interventionGroup.id, studentId!);
  }, [interventionGroup]);

  return (
    <Table
      className="focuses-matrix-table table table-striped table-bordered"
      responsive
    >
      <thead>
        <tr>
          <th>Date</th>
          <th>Action Taken</th>
          <th>Action</th>
          <th>Action Taken By</th>
        </tr>
      </thead>
      <tbody>
        {interventionGroupLogging.length ? (
          interventionGroupLogging.map((igl, index) => {
            return (
              <tr key={index}>
                <td>{moment.utc(igl.created).format("MM/DD/YYYY")}</td>
                <td>{igl.activity_display}</td>
                {igl.activity === "intervention_added" && (
                  <td>{igl.intervention}</td>
                )}
                {igl.activity === "intervention_modified" && (
                  <td>{igl.intervention}</td>
                )}
                {igl.activity === "student_added" && <td>{igl.student}</td>}
                {igl.activity === "schedule_change" && (
                  <td>{igl.schedule_result}</td>
                )}
                {igl.activity === "student_exited" && <td>{igl.student}</td>}
                <td>{getFullName(igl.user)}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={4}>No data</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    interventionGroupLogging: onboarding.interventionGroupLogging,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      getInterventionGroupLogging: getInterventionGroupLogging,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterventionGroupLoggingTable);
