import React from "react";
import { Cell } from "fixed-data-table-2";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FontAwesomeIconDropDown from "../drop-down/FontAwesomeIconDropDown";
import {
  faLongArrowAltDown,
  faLongArrowAltUp,
  faSortAlphaDown,
  faSortAlphaUp,
} from "@fortawesome/free-solid-svg-icons";
import { ColumnNames, Sorting } from "../../EvidenceTable";
import { Portal } from "react-portal";

type Props = {
  onSort: (columnName: ColumnNames) => any;
  sorting: Sorting;
};

const StudentHeaderCell = (props: Props) => {
  return (
    <Cell className="w-100">
      <div className="d-flex align-items-center justify-content-between">
        <Dropdown className="w-40 lh-15">
          <Dropdown.Toggle
            as={FontAwesomeIconDropDown(
              <span  className="text-left">
                Student
                {props.sorting.column === ColumnNames.STUDENT && (
                  <>
                  <FontAwesomeIcon
                    className="ml-1"
                    style={{ color: "black" }}
                    icon={
                      props.sorting.direction === "asc"
                        ? faSortAlphaDown
                        : faSortAlphaUp
                    }
                  />
                  <span className="font-weight-bold font-size-teacher">(Sorted By Student)</span>
                  </>
                )}
                {props.sorting.column === ColumnNames.TEACHER && (
                  <>
                   
                   <FontAwesomeIcon
                    className="ml-1"
                    style={{ color: "black" }}
                    icon={
                      props.sorting.direction === "asc"
                        ? faSortAlphaDown
                        : faSortAlphaUp
                    }
                  />
                   <span className="font-weight-bold font-size-teacher">(Sorted By Teacher)</span>
                  </>
                )}
              </span>
            )}
            id={"dropdownToggleStudent"}
          />
          <Portal>
            <Dropdown.Menu>
              <Dropdown.Item onClick={props.onSort(ColumnNames.STUDENT)}>
                Sort by Student
              </Dropdown.Item>  
              <Dropdown.Item onClick={props.onSort(ColumnNames.TEACHER)}>
                Sort by Teacher
              </Dropdown.Item>        
            </Dropdown.Menu>
          </Portal>
        </Dropdown>
        <span className="pl-1" onClick={props.onSort(ColumnNames.CLASS)}>
          {props.sorting.column === ColumnNames.CLASS && (
            <FontAwesomeIcon
              className="mr-1"
              style={{ color: "black" }}
              icon={
                props.sorting.direction === "asc"
                  ? faLongArrowAltDown
                  : faLongArrowAltUp
              }
            />
          )}
          Class
        </span>
        <span className="mr-1" onClick={props.onSort(ColumnNames.GRADE)}>
          {props.sorting.column === ColumnNames.GRADE && (
            <FontAwesomeIcon
              className="mr-1"
              style={{ color: "black" }}
              icon={
                props.sorting.direction === "asc"
                  ? faLongArrowAltDown
                  : faLongArrowAltUp
              }
            />
          )}
          Grade
        </span>
      </div>
    </Cell>
  );
};

export default StudentHeaderCell;
