import { ActionType } from "typesafe-actions";
import * as notificationsActions from "./actions";
import { Reducer } from "redux";
import { NotificationsActionTypes, NotificationsState } from "./types";

export type NotificationsAction = ActionType<typeof notificationsActions>;

const initialState: NotificationsState = {
  notifications: [],
  notificationSettings: [],
  modalsState: {},
  isLoading: {
    getNotifications: false,
    markAsReadNotification: false,
    getNotificationSettings: false,
    updateNotificationSettings: false,
  },
  errors: {},
};

const reducer: Reducer<NotificationsState> = (
  state: NotificationsState = initialState,
  action: NotificationsAction
): NotificationsState => {
  switch (action.type) {
    case NotificationsActionTypes.GET_NOTIFICATIONS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotifications: true },
      };
    case NotificationsActionTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload.notifications,
        isLoading: { ...state.isLoading, getNotifications: false },
        errors: { ...state.errors, getNotifications: undefined },
      };
    case NotificationsActionTypes.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotifications: false },
        errors: { ...state.errors, getNotifications: action.payload.error },
      };

    case NotificationsActionTypes.MARK_AS_READ_NOTIFICATION_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, markAsReadNotification: true },
      };
    case NotificationsActionTypes.MARK_AS_READ_NOTIFICATION_SUCCESS: {
      const { notifications } = action.payload;
      let updatedNotification;
      const updatedNotifications = state.notifications.map((notification) => {
        updatedNotification = notifications.find(
          (n) => notification.id === n.id
        );
        return updatedNotification ?? notification;
      });
      return {
        ...state,
        notifications: updatedNotifications,
        isLoading: { ...state.isLoading, markAsReadNotification: false },
        errors: { ...state.errors, markAsReadNotification: undefined },
      };
    }
    case NotificationsActionTypes.MARK_AS_READ_NOTIFICATION_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, markAsReadNotification: false },
        errors: {
          ...state.errors,
          markAsReadNotification: action.payload.error,
        },
      };

    case NotificationsActionTypes.GET_NOTIFICATION_SETTINGS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotificationSettings: true },
      };
    case NotificationsActionTypes.GET_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        notificationSettings: action.payload.notificationSettings,
        isLoading: { ...state.isLoading, getNotificationSettings: false },
        errors: { ...state.errors, getNotificationSettings: undefined },
      };
    case NotificationsActionTypes.GET_NOTIFICATION_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotificationSettings: false },
        errors: {
          ...state.errors,
          getNotificationSettings: action.payload.error,
        },
      };

    case NotificationsActionTypes.UPDATE_NOTIFICATION_SETTINGS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateNotificationSettings: true },
      };
    case NotificationsActionTypes.UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        notificationSettings: action.payload.notificationSettings,
        isLoading: { ...state.isLoading, updateNotificationSettings: false },
        errors: { ...state.errors, updateNotificationSettings: undefined },
      };
    case NotificationsActionTypes.UPDATE_NOTIFICATION_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateNotificationSettings: false },
        errors: {
          ...state.errors,
          updateNotificationSettings: action.payload.error,
        },
      };

    default:
      return state;
  }
};

export { reducer as notificationsReducer };
