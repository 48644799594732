import React from "react";
import { Overlay, Popover } from "react-bootstrap";
import RcProgressIntro from "../../../third-step/set-goal-modal/subpages/rc-progress/RcProgressIntro";

type Props = {
  objectRef: React.MutableRefObject<any>;
  showDropdown: boolean;
  setShowDropdown: (show: boolean) => void;
  onSubmitPopover: () => void;
};

const RCIndicatorsPopover = ({
  objectRef,
  showDropdown,
  setShowDropdown,
  onSubmitPopover,
}: Props) => {
  return (
    <Overlay
      placement="auto"
      flip
      rootClose={true}
      onHide={() => setShowDropdown(false)}
      show={showDropdown}
      target={objectRef.current}
    >
      <Popover
        id="popover-rc-indicators"
        className="w-100 rc-indicators-popover"
      >
        <Popover.Title>RC Indicators</Popover.Title>
        <Popover.Content>
          <RcProgressIntro onClickContinue={onSubmitPopover} />
        </Popover.Content>
      </Popover>
    </Overlay>
  );
};

export default RCIndicatorsPopover;
