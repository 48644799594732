import React, { FunctionComponent } from "react";
import {
  ConcernType,
  InterventionTarget,
} from "../../../../../../../../store/onboarding/cases/types";
import PillButton from "./PillButton";

type OwnProps = {
  interventionTarget?: InterventionTarget;
  interventionConcernType?: ConcernType;
  setInterventionTarget: React.Dispatch<
    React.SetStateAction<InterventionTarget | undefined>
  >;
  setInterventionConcernType: React.Dispatch<
    React.SetStateAction<ConcernType | undefined>
  >;
  onClickNext: () => void;
};

type Props = OwnProps;

const QuestionsForm: FunctionComponent<Props> = ({
  interventionConcernType,
  setInterventionConcernType,
  interventionTarget,
  setInterventionTarget,
  onClickNext,
}) => {
  return (
    <div className="interventionModalContentNoPad">
      <h3 className="purpleText">
        To help locate your intervention more easily in the library, answer a
        few questions about it.
      </h3>
      <div className="interventionDetailsPurpleLabel">
        <p>
          1. Is the intervention strategy designed to address a new skill or
          behavior?
        </p>
      </div>

      <PillButton
        value={ConcernType.NEW}
        label={"Yes"}
        selectedValue={interventionConcernType}
        setValue={setInterventionConcernType}
      />
      <PillButton
        value={ConcernType.EXISTING}
        label={"No"}
        selectedValue={interventionConcernType}
        setValue={setInterventionConcernType}
      />
      <div className="interventionDetailsPurpleLabel">
        <p>2. Is it for an individual student, groups of students or both?</p>
      </div>
      <PillButton
        value={InterventionTarget.INDIVIDUALS}
        label={"Individuals"}
        selectedValue={interventionTarget}
        setValue={setInterventionTarget}
      />
      <PillButton
        value={InterventionTarget.SMALL_GROUP}
        label={"Groups"}
        selectedValue={interventionTarget}
        setValue={setInterventionTarget}
      />
      <PillButton
        value={InterventionTarget.WHOLE_CLASS}
        label={"Both"}
        selectedValue={interventionTarget}
        setValue={setInterventionTarget}
      />
      <hr />
      <div className="modalActions">
        <div />
        <button className="blueBtnSm" onClick={onClickNext}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default QuestionsForm;
