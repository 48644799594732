import React from "react";
import Table from "react-bootstrap/Table";
type Props = {
  raceVariations: any[];
}

const RaceDemographicsExample = ({raceVariations}: Props) => {
  return (
      <div className="overflow-auto">
        <Table className="uploadExampleTable">
        <thead>
          <tr>
            <th>Demographic</th>
            <th>Current Name Variations</th>
          </tr>
        </thead>
        <tbody>
            <tr>
              <td>Male</td>
              <td>M, Male</td>
            </tr>
            <tr>
              <td>Female</td>
              <td>F, Female</td>
            </tr>
            <tr>
              <td>Non Binary</td>
              <td>X</td>
            </tr>
            {raceVariations.sort((a: any,b: any) => a.displayName! > b.displayName! ? 1 : -1).map((races: any) => 
              <tr>
                <td>{races.displayName}</td>
                <td><div className="text-wrap">{races.variations}</div></td>
              </tr>
            )}
            {/* <tr>
              <td>Other</td>
              <td>other</td>
            </tr> */}
          
          </tbody>
        </Table>
      </div>
  );
};
export default RaceDemographicsExample;
