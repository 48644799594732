import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward, faStar } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "../../../store/badges/types";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import {
  getBadgesRequest,
  openNewAchievementsModal,
} from "../../../store/badges/actions";
import usePrevProps from "../../../utils/hooks/usePrevProps";
import { useIntl } from "react-intl";

type StateProps = {
  badges?: Array<Badge>;
};

type DispatchProps = {
  openNewAchievementsModal: (newAchievements: Array<Badge>) => any;
  getBadgesRequest: () => any;
};

type MatchParams = {
  tabName: string;
};

type Props = StateProps & RouteComponentProps<MatchParams> & DispatchProps;

const BadgesNavLink: FunctionComponent<Props> = ({
  badges,
  match,
  openNewAchievementsModal,
  getBadgesRequest,
}) => {
  const [newAchievements, setNewAchievements] = useState<Array<Badge>>([]);
  // const [test, setTest] = useState<Array<Badge>>([]);

  useEffect(() => {
    getBadgesRequest();
  }, []);

  const completedBadges = useMemo(
    () => badges?.filter((badge) => badge.status?.completed),
    [badges]
  );

  useEffect(() => {
    if (match.params.tabName === "badges") {
      setNewAchievements([]);
    }
  }, [match.params.tabName]);

  const prevProps = usePrevProps({ completedBadges });

  const intl = useIntl();

  useEffect(() => {
    if (
      !newAchievements.length &&
      completedBadges?.length &&
      prevProps?.completedBadges &&
      completedBadges.length != prevProps.completedBadges.length
    ) {
      const newAchievements = completedBadges.filter(
        (b) => !prevProps?.completedBadges?.some((cb) => cb.id === b.id)
      );
      setNewAchievements(newAchievements);
      openNewAchievementsModal(newAchievements);
      // openNewAchievementsModal(completedBadges.slice(0, 2));
    }
  }, [completedBadges]);

  return (
    <>
      <div className="d-flex align-items-center">
        <FontAwesomeIcon icon={faAward} />
        &nbsp;
        {intl.formatMessage({
          id: "app.navigation.dashboardMenuPanel.badgesLabel",
        })}
        &nbsp;
        {!!newAchievements.length && (
          <span className="ml-auto">
            <FontAwesomeIcon icon={faStar} />
          </span>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ badges }: ApplicationState): StateProps => {
  return {
    badges: badges.badges,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openNewAchievementsModal: openNewAchievementsModal,
      getBadgesRequest: getBadgesRequest,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BadgesNavLink));
