import React, { FunctionComponent, useMemo } from "react";
import { InterventionSummary } from "../../../../../../store/onboarding/types";
import {
  generateInterventionAbbrev,
  getFullName,
} from "../../../../../../utils/NamesUtils";
import VerticalBarChart from "./VerticalBarChart";
import _ from "lodash";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar, HorizontalBar } from "react-chartjs-2";

type OwnProps = {
  interventions: InterventionSummary[];
};

type Props = OwnProps;

const InterventionUsageChart: FunctionComponent<Props> = (props) => {
  const { interventions } = props;

  const xAxis = useMemo(
    () =>
      _.chain(interventions)
        .flatMap((interventionSummary) =>
          interventionSummary.interventions.map((int) => ({
            teachers: int.teachers,
            coach: interventionSummary.coach,
            intervention: int.intervention,
          }))
        )
        .groupBy((intGroup) => intGroup.intervention.id)
        .map((intGroups) => ({
          intervention: intGroups[0].intervention,
          coaches: _.chain(intGroups)
            .map((intGroup) => intGroup.coach)
            .uniqBy((c) => c.id)
            .value(),
          teachers: _.chain(intGroups)
            .flatMap((intGroup) => intGroup.teachers)
            .uniqBy((t) => t.id)
            .value(),
        }))
        .value(),
    [interventions]
  );

  const labels = useMemo(() => {
    return xAxis.map(
      (x) =>
        x.intervention.abbreviation ??
        generateInterventionAbbrev(x.intervention.name)
    );
  }, [xAxis]);
  const data = useMemo(
    () =>
      xAxis.map((intGroup) => {
        return intGroup.teachers.length + intGroup.coaches.length;
      }),
    [xAxis]
  );

  if (!data.length) {
    return null;
  }

  const chartData = useMemo(() => {
    return {
      labels: labels,
      datasets: [
        {
          backgroundColor: "rgb(203,220,240)",
          borderColor: "rgb(77,145,226)",
          borderWidth: 1,
          hoverBackgroundColor: "rgb(203,220,240,0.4)",
          hoverBorderColor: "rgb(77,145,226)",
          data: data,
          datalabels: {
            color: "rgb(30,136,229)",
            formatter: (value: number) => {
              if (!value) {
                return "";
              }
            },
            font: {
              weight: "bold",
            },
          },
        },
      ],
    };
  }, [data]);

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          title: (tooltipItem: any, data: any) => {
            return xAxis[tooltipItem[0].index].intervention.name;
          },
          label: (tooltipItem: any, data: any) => {
            return `Total count of usage: ${tooltipItem.value}`;
          },
          footer: (tooltipItem: any, data: any) => {
            return xAxis[tooltipItem[0].index].coaches
              .map(getFullName)
              .join("\n");
          },
        },
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
            },
            display: true,
          },
        ],
        xAxes: [
          {
            ticks: {
              autoSkip: false,
              maxRotation: 75,
              minRotation: 75,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          anchor: (context: any) => {
            const value = context.dataset.data[context.dataIndex];
            return value < 10 ? "end" : "center";
          },
          align: (context: any) => {
            const value = context.dataset.data[context.dataIndex];
            return value < 10 ? "top" : "center";
          },
          offset: 2,
          textAlign: "center",
          display: "auto",
          clamp: true,
        },
      },
    }),
    []
  );

  return (
    <div className="district-summary-chart-container">
      <HorizontalBar
        height={250}
        data={chartData}
        options={options}
        plugins={[ChartDataLabels]}
      />
    </div>
  );
};

export default InterventionUsageChart;
