import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { markEmptySpellingTestStudentAnswerPartAsCorrect } from "../../../../../../store/spelling-tests/actions";

const useSpellingTestCellOnFocus = (questionWord: string) => {
  const dispatch = useDispatch();
  const prevQuestionWord = useSelector(
    (s: ApplicationState) => s.spellingTests.prevQuestionWord
  );

  // useEffect(() => {
  //   if (prevQuestionWord !== questionWord) {
  //     dispatch(markEmptySpellingTestStudentAnswerPartAsCorrect(questionWord));
  //   }
  // }, [prevQuestionWord]);

  return () => {
    if (prevQuestionWord !== questionWord) {
      dispatch(markEmptySpellingTestStudentAnswerPartAsCorrect(questionWord));
    }
  };
};
export default useSpellingTestCellOnFocus;
