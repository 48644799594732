import {
  BasicUserInfo,
  EvidencePeriodData,
  Student,
  StudentRow,
} from "../store/onboarding/types";
import { IS_READY_COACH } from "../constants";

export const addStudentMapToLocalStore = (
  studentData: Map<string, Student>
) => {
  const studentJson = localStorage.getItem("studentMap");
  if (studentJson) {
    const existedStudentData = new Map<string, Student>(
      Object.entries(JSON.parse(studentJson))
    );
    studentData.forEach((value, key) => existedStudentData.set(key, value));
    localStorage.setItem(
      "studentMap",
      JSON.stringify(Object.fromEntries(existedStudentData))
    );
  } else {
    localStorage.setItem(
      "studentMap",
      JSON.stringify(Object.fromEntries(studentData))
    );
  }
};

export const getStudentDataById = (unique_id: string): Student | undefined => {
  const studentJson = localStorage.getItem("studentMap");
  if (studentJson) {
    const studentData = JSON.parse(studentJson);
    return studentData[unique_id];
  }
  return undefined;
};

export const getAPIAndLSMatchedStudentRows = (
  evidenceData: EvidencePeriodData
): Array<StudentRow> | undefined => {
  const studentJson = localStorage.getItem("studentMap");
  if (studentJson) {
    const studentData = JSON.parse(studentJson);
    return evidenceData.student_rows.filter(
      (row) => studentData[row.student.unique_id!] !== undefined
    );
  }
  return undefined;
};

export const getUnknownStudentsLength = (
  evidenceData: EvidencePeriodData
): number => {
  const matchedStudentRows = getAPIAndLSMatchedStudentRows(evidenceData);
  if (matchedStudentRows) {
    return evidenceData.student_rows.length - matchedStudentRows.length;
  }
  return evidenceData.student_rows.length;
};

export const getFullNameForStudent = (student: Student): string => {
  if (IS_READY_COACH) {
    return `${student.first_name || ""} ${student.last_name || ""}`;
  }

  const unknownStudentDisplayName = "Unknown Student";

  if (student.unique_id) {
    const data = getStudentDataById(student.unique_id);
    if (data) {
      return `${data.first_name || ""} ${data.last_name || ""}`;
    } else {
      return unknownStudentDisplayName;
    }
  } else {
    return unknownStudentDisplayName;
  }
};

export const getBasicUserInfoFromString = (input: string): BasicUserInfo => {
  const teacherNames = input.split(" ");
  const last_name = teacherNames.pop();
  const first_name = teacherNames.pop();
  return {
    first_name: first_name ? first_name : "",
    last_name: last_name ? last_name : "",
    email: "",
  };
};
