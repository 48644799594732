import React, { Component } from "react";
import { Alert, Col, Modal, Nav, Row, Spinner, Tab } from "react-bootstrap";
import {
  faCircle,
  faDotCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  getSkillFocuses,
  hideSkillFocusesModal,
} from "../../../../../../store/groups/actions";
import {
  Grade,
  GroupingStructure,
  Identifier,
  SkillFocus,
  SkillFocusArea,
  Specific,
} from "../../../../../../store/groups/types";
import { ApplicationState } from "../../../../../../store";
import {
  DataPeriod,
  DiagnosticTag,
  DiagnosticTagType,
  EvidenceColumnGroup,
  EvidenceType,
  NeedsAreaTag,
  Student,
  StudentEntry,
} from "../../../../../../store/onboarding/types";
import { toastr } from "react-redux-toastr";
import {
  addStudentSkills,
  deleteDataStudentEntry,
} from "../../../../../../store/onboarding/actions";
import { getFullNameForStudent } from "../../../../../../utils/StudentMapUtils";
import { IS_READY_COACH } from "../../../../../../constants";
import { EventKey } from "react-bootstrap/esm/types";

type PropsFromState = {
  skillFocusInfo: Array<SkillFocusArea>;
  evidenceColumnGroups?: Array<EvidenceColumnGroup>;
  identifiersInfo: { [identifier: string]: Identifier };
  grouping?: GroupingStructure;
  studentEntry?: StudentEntry;
  student?: Student;
  grade?: Grade;
  dataPeriod?: DataPeriod;
  showModal: boolean;
  isLoading: {
    addStudentSkills: boolean;
    deleteDataStudentEntry: boolean;
  };
  errors: {
    addStudentSkills?: string;
    deleteDataStudentEntry?: string;
  };
};

type DispatchProps = {
  getSkillFocuses: () => any;
  hideSkillFocusesModal: () => any;
  deleteStudentEntry: (studentEntryId: number) => any;
  addStudentSkills: (
    skill_identifiers: Array<string>,
    studentId: number,
    dataPeriodId?: number,
    grade?: Grade
  ) => any;
};

type State = {
  activeKeyName1: string;
  activeKeyName2: string;
  skills: Array<Specific>;
  needsAreaTags?: Array<NeedsAreaTag>;
};

type Props = PropsFromState & DispatchProps;

class SkillsFocusModal extends Component<Props, State> {
  defaultState: Readonly<State> = {
    activeKeyName1: "background",
    activeKeyName2: "background",
    skills: [],
    needsAreaTags: undefined,
  };

  state: Readonly<State> = this.defaultState;

  componentDidUpdate(
    prevProps: Readonly<PropsFromState & DispatchProps>,
    prevState: Readonly<State>
  ): void {
    if (
      prevProps.isLoading.addStudentSkills &&
      !this.props.isLoading.addStudentSkills
    ) {
      if (!this.props.errors.addStudentSkills) {
        this.handleModalHide();
      } else {
        toastr.error("Error", this.props.errors.addStudentSkills);
      }
    }
    if (
      prevProps.isLoading.deleteDataStudentEntry &&
      !this.props.isLoading.deleteDataStudentEntry
    ) {
      if (!this.props.errors.deleteDataStudentEntry) {
        this.handleModalHide();
      } else {
        toastr.error("Error", this.props.errors.deleteDataStudentEntry);
      }
    }
  }

  quickFind = (event: React.ChangeEvent<any>): void => {
    const searchObject = {
      column1: {
        founded: false,
        keyName1: "background",
        keyName2: "background",
      },
      column2: {
        founded: false,
        keyName1: "background",
        keyName2: "background",
      },
      column3: {
        founded: false,
        keyName1: "background",
        keyName2: "background",
      },
    };
    const { column1, column2, column3 } = searchObject;
    const searchString = event.target.value;
    if (searchString.length > 1) {
      this.props.skillFocusInfo.map((area) => {
        if (
          area.display_name.toLowerCase().startsWith(searchString.toLowerCase())
        ) {
          column1.founded = true;
          column1.keyName1 = area.display_name;
        }
        (area.skill_focuses || []).map((sf) => {
          if (
            sf.display_name.toLowerCase().startsWith(searchString.toLowerCase())
          ) {
            column2.founded = true;
            column2.keyName1 = area.display_name;
            column2.keyName2 = sf.display_name;
          }
          (sf.specifics || []).map((specific) => {
            if (
              specific.display_name
                .toLowerCase()
                .startsWith(searchString.toLowerCase())
            ) {
              column3.founded = true;
              column3.keyName1 = area.display_name;
              column3.keyName2 = sf.display_name;
            }
          });
        });
      });
    }

    if (searchObject.column3.founded) {
      this.setState({
        activeKeyName1: column3.keyName1,
        activeKeyName2: column3.keyName2,
      });
    } else if (searchObject.column2.founded) {
      this.setState({
        activeKeyName1: column2.keyName1,
        activeKeyName2: column2.keyName2,
      });
    } else {
      this.setState({
        activeKeyName1: column1.keyName1,
        activeKeyName2: column1.keyName2,
      });
    }
  };

  changeDefaultActiveKey = (keyName: string, firstKeyName: boolean): void => {
    if (firstKeyName) {
      this.setState({ activeKeyName1: keyName, activeKeyName2: "background" });
    } else {
      this.setState({ activeKeyName2: keyName });
    }
  };

  handleClickSkillFocusArea = (skillFocusArea: SkillFocusArea) => {
    if (skillFocusArea.skill_focuses) {
      this.changeDefaultActiveKey(skillFocusArea.display_name, true);
    } else {
      this.addRemoveSkill(skillFocusArea);
      this.setState({
        activeKeyName1: "background",
        activeKeyName2: "background",
      });
    }
  };

  handleClickSkillFocus = (skillFocus: SkillFocus) => {
    if (skillFocus.specifics) {
      this.changeDefaultActiveKey(skillFocus.display_name, false);
    } else {
      this.addRemoveSkill(skillFocus);
    }
  };

  handleClickSpecific = (specific: Specific) => {
    this.addRemoveSkill(specific);
  };

  addRemoveSkill = (specific: Specific) => {
    if (
      this.state.skills.some(
        (skill) => specific.identifier === skill.identifier
      )
    ) {
      const skills = this.state.skills.filter(
        (skill) => specific.identifier !== skill.identifier
      );
      this.setState({
        skills: [...skills],
      });
    } else {
      this.setState((prevState) => ({
        skills: [...prevState.skills, specific],
      }));
    }
  };

  handleSaveSkillFocus = () => {
    const { studentEntry, dataPeriod, grade } = this.props;
    const studentSkills = this.state.skills.map((skill) => skill.identifier);
    if (studentEntry) {
      if (studentSkills.length > 0) {
        this.props.addStudentSkills(studentSkills, studentEntry.student_id, dataPeriod?.id ,grade);
      } else if (studentEntry.entry_id !== -1) {
        this.props.deleteStudentEntry(studentEntry.entry_id);
      } else {
        this.handleModalHide();
      }
    }
  };

  handleModalHide = () => {
    this.props.hideSkillFocusesModal();
  };

  getTagIdentifier = (tag: DiagnosticTag) => {
    switch (tag.tag_type) {
      case DiagnosticTagType.Area:
        return tag.area;
      case DiagnosticTagType.SkillFocus:
        return tag.skill_focus;
      case DiagnosticTagType.Specific:
        return tag.specific;
    }
  };

  handleModalShow = () => {
    // console.log(this.props.studentEntry);

    this.props.getSkillFocuses();
    if (this.props.studentEntry) {
      const needAreasEntity = this.props.evidenceColumnGroups
        ?.find(
          (ecg) =>
            !ecg.assessment &&
            ecg.evidence_columns.some(
              (ec) => ec.evidence_type === EvidenceType.NeedAreas
            )
        )
        ?.evidence_columns.find(
          (ec) => ec.evidence_type === EvidenceType.NeedAreas
        )
        ?.student_entries.find(
          (se) => se.student_id === this.props.studentEntry?.student_id
        );

      this.setState({
        needsAreaTags: needAreasEntity?.needs_area_tags ?? [],
        skills: (this.props.studentEntry.diagnostic_tags || []).map((tag) => ({
          identifier: this.getTagIdentifier(tag) || "",
          display_name: tag.display_name,
        })),
        activeKeyName1: "background",
        activeKeyName2: "background",
      });
    } else {
      this.setState(this.defaultState);
    }
  };

  isCheckedArea = (item: SkillFocusArea) => {
    const { skills } = this.state;
    if (item.skill_focuses) {
      for (const sk of item.skill_focuses) {
        if (sk.specifics) {
          for (const spec of sk.specifics) {
            if (skills.some((s) => s.identifier === spec.identifier))
              return true;
          }
        }
        if (skills.some((s) => s.identifier === sk.identifier)) return true;
      }
    }
    if (skills.some((s) => s.identifier === item.identifier)) return true;
  };

  isCheckedSkillFocus = (item: SkillFocus) => {
    const { skills } = this.state;
    if (item.specifics) {
      for (const spec of item.specifics) {
        if (skills.some((s) => s.identifier === spec.identifier)) return true;
      }
    }
    return skills.some((s) => s.identifier === item.identifier);
  };

  isCheckedSpecific = (item: Specific) => {
    const { skills } = this.state;
    return skills.some((s) => s.identifier === item.identifier);
  };

  skillsForDisplay = () => {
    const { skillFocusInfo } = this.props;
    const { skills } = this.state;

    return skillFocusInfo.map((area) => {
      let areaSfs = "";
      if (area.skill_focuses) {
        area.skill_focuses.map((sf) => {
          let sfSpecifics = "";
          if (sf.specifics) {
            sf.specifics.map((specific) => {
              if (skills.some((s) => s.identifier === specific.identifier)) {
                if (sfSpecifics) sfSpecifics += ", ";
                sfSpecifics += specific.display_name;
              }
            });
            if (sfSpecifics) {
              if (areaSfs) areaSfs += "; ";
              areaSfs += sf.display_name + "(" + sfSpecifics + ")";
            }
          } else {
            if (skills.some((s) => s.identifier === sf.identifier)) {
              if (areaSfs) areaSfs += ", ";
              areaSfs += sf.display_name;
            }
          }
        });
        if (areaSfs) {
          return (
            <div className="specificButton" key={area.identifier}>
              {area.display_name}: {areaSfs}
            </div>
          );
        }
      } else {
        if (skills.some((s) => s.identifier === area.identifier)) {
          return (
            <div className="specificButton" key={area.identifier}>
              {area.display_name}
            </div>
          );
        }
      }
    });
  };

  render() {
    const {
      skillFocusInfo,
      identifiersInfo,
      grouping,
      student,
      grade,
      dataPeriod,
    } = this.props;
    const {
      activeKeyName1,
      activeKeyName2,
      skills,
      needsAreaTags,
    } = this.state;

    let showGroupingStatus = false;
    let willBeGrouped = false;
    if (skills.length > 0 && grade && grouping && dataPeriod) {
      const gradeAsGrade = grade as Grade;
      const timePeriods = grouping?.[gradeAsGrade]?.[dataPeriod.time_period];
      if (timePeriods) {
        const consideredKeys = new Set(
          timePeriods
            .flatMap((tp) => [tp.group_key, tp.sub_group_key])
            .filter((key) => key != null)
        );

        const keys = new Set(
          skills.flatMap((skill) => [
            identifiersInfo[skill.identifier].identifier_info[2],
            identifiersInfo[skill.identifier].identifier_info[3],
          ])
        );

        keys.forEach((key) => {
          if (consideredKeys.has(key)) {
            willBeGrouped = true;
            return false;
          }
        });
      }
      showGroupingStatus = !willBeGrouped;
    }

    const studentName = student ? getFullNameForStudent(student) : "Student";
    return (
      <Modal
        show={this.props.showModal}
        onShow={this.handleModalShow}
        onHide={this.handleModalHide}
        animation={false}
        size="lg"
        backdropClassName="customDarkModalBackdrop in"
      >
        <Modal.Header
          closeButton
          className={IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"}
        >
          <Modal.Title>{studentName}'s Skill Focus</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!needsAreaTags?.length && (
            <div>
              <h3 className="font-weight-bold mb-2">
                {studentName}'s Areas of Need
              </h3>
              <div>
                {needsAreaTags.map((at) => (
                  <div key={at.id} className="needsAreaItemSkills">
                    {at.display_name}
                  </div>
                ))}
              </div>
              <hr />
            </div>
          )}

          <div className="skillFocusArea">
            {skills.length === 0 ? (
              <span className="font-weight-bold">
                To add skills to{" "}
                {student ? getFullNameForStudent(student) : "Student"}
                's Focus - select an Area option, Skill Focus option, and 1 or
                more Specifics option(s)
              </span>
            ) : (
              <>{this.skillsForDisplay()}</>
            )}
          </div>
          {showGroupingStatus && grade && (["K","G1","G2","G3"].includes(grade!)) && (
            <Alert style={{ marginTop: 5 }} variant="warning">
              The selected skills will not result in grouping for{" "}
              {getFullNameForStudent(student!)} because they are not considered
              for this grade and time period.
            </Alert>
          )}
          <div style={{ marginTop: "20px" }}>
            <FontAwesomeIcon
              icon={faSearch}
              size="1x"
              style={{
                position: "absolute",
                marginLeft: "10px",
                marginTop: "12px",
                color: "#767676",
              }}
            />
            <input
              className="fullWidthInput"
              style={{ paddingLeft: "30px" }}
              type="text"
              placeholder="Quick Find..."
              onChange={this.quickFind}
            />
          </div>
          <div className="skillFocusTabArea">
            <Tab.Container activeKey={activeKeyName1 as EventKey} onSelect={() => null}>
              <Row className="m-0 mb-1">
                <Col sm={3}>
                  <span className="font-weight-bold">AREA</span>
                </Col>
                <Col sm={3}>
                  <span className="font-weight-bold">SKILL FOCUS</span>
                </Col>
                <Col sm={6}>
                  <span className="font-weight-bold">SPECIFICS</span>
                </Col>
              </Row>
              <Row className="m-0">
                <Col sm={3}>
                  <Nav variant="pills" className="skillFocusTab">
                    {skillFocusInfo.map((area) => (
                      <Nav.Item
                        className="skillFocusItem"
                        key={area.display_name}
                      >
                        <Nav.Link eventKey={area.display_name}>
                          <div
                            className={
                              this.isCheckedArea(area)
                                ? "skillFocusActive font-weight-bold"
                                : "skillFocus"
                            }
                            onClick={() => this.handleClickSkillFocusArea(area)}
                          >
                            <FontAwesomeIcon
                              icon={
                                this.isCheckedArea(area)
                                  ? faDotCircle
                                  : faCircle
                              }
                              size="1x"
                              style={{ marginRight: "10px" }}
                            />
                            {area.display_name}
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    {skillFocusInfo.map((area) => (
                      <Tab.Pane
                        key={area.display_name}
                        eventKey={area.display_name}
                      >
                        <Tab.Container
                          activeKey={activeKeyName2 as EventKey}
                          onSelect={() => null}
                        >
                          <Row>
                            <Col sm={4}>
                              <Nav variant="pills" className="skillFocusTab">
                                {(area.skill_focuses || []).map((sf) => (
                                  <Nav.Item
                                    className="skillFocusItem"
                                    key={sf.display_name}
                                  >
                                    <Nav.Link eventKey={sf.display_name}>
                                      <div
                                        className={
                                          this.isCheckedSkillFocus(sf)
                                            ? "skillFocusActive font-weight-bold"
                                            : "skillFocus"
                                        }
                                        onClick={() =>
                                          this.handleClickSkillFocus(sf)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            this.isCheckedSkillFocus(sf)
                                              ? faDotCircle
                                              : faCircle
                                          }
                                          size="1x"
                                          style={{ marginRight: "10px" }}
                                        />
                                        {sf.display_name}
                                      </div>
                                    </Nav.Link>
                                  </Nav.Item>
                                ))}
                              </Nav>
                            </Col>
                            <Col sm={8}>
                              <Tab.Content>
                                {(area.skill_focuses || []).map((sf) => (
                                  <Tab.Pane
                                    eventKey={sf.display_name}
                                    key={sf.display_name}
                                  >
                                    <div className="skillFocusTab">
                                      {(sf.specifics || []).map((specific) => (
                                        <div
                                          key={specific.identifier}
                                          className={
                                            this.isCheckedSpecific(specific)
                                              ? "skillFocusSpecificActive font-weight-bold"
                                              : "skillFocusSpecific"
                                          }
                                          onClick={() =>
                                            this.handleClickSpecific(specific)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={
                                              this.isCheckedSpecific(specific)
                                                ? faDotCircle
                                                : faCircle
                                            }
                                            size="1x"
                                            style={{ marginRight: "10px" }}
                                          />
                                          {specific.display_name}
                                        </div>
                                      ))}
                                    </div>
                                  </Tab.Pane>
                                ))}
                                <Tab.Pane
                                  eventKey="background"
                                  style={{ minHeight: "100px" }}
                                >
                                  <div className="emptyPane font-weight-bold">
                                    Click a Skill Focus to see Specifics
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                      </Tab.Pane>
                    ))}
                    <Tab.Pane eventKey="background">
                      <div className="emptyPane font-weight-bold">
                        Click a Skill Area to see Skill Focus and Specifics
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
          <div className="skillFocusButton">
            <button className="blueBtnLg" onClick={this.handleSaveSkillFocus}>
              Save skills{""}
              {(this.props.isLoading.addStudentSkills ||
                this.props.isLoading.deleteDataStudentEntry) && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  groups,
  onboarding,
}: ApplicationState): PropsFromState => {
  const studentRow =
    onboarding.evidencePeriodData &&
    groups.selectedStudentEntry &&
    onboarding.evidencePeriodData.student_rows.find(
      (st) => st.student.id === groups.selectedStudentEntry!.student_id
    );

  let grade: Grade | undefined;
  const student = studentRow && studentRow.student;

  if (student) {
    const { evidencePeriodData } = onboarding;
    if (evidencePeriodData) {
      for (const ecg of evidencePeriodData.evidence_column_groups) {
        if (grade) {
          break;
        }
        for (const ec of ecg.evidence_columns) {
          if (grade) {
            break;
          }
          for (const se of ec.student_entries) {
            if (grade) {
              break;
            }
            if (se.student_id === student.id) {
              grade = se.grade;
            }
          }
        }
      }
    }
  }

  return {
    skillFocusInfo: groups.skillFocusInfo,
    identifiersInfo: groups.identifiersInfo,
    grouping: groups.grouping,
    showModal: groups.modalsState.skillFocusesModal,
    evidenceColumnGroups: onboarding.evidencePeriodData?.evidence_column_groups,
    studentEntry: groups.selectedStudentEntry,
    student: student,
    grade: grade,
    dataPeriod: onboarding.currentDataPeriod,
    isLoading: {
      addStudentSkills: onboarding.isLoading.addStudentSkills,
      deleteDataStudentEntry: onboarding.isLoading.deleteDataStudentEntry,
    },
    errors: {
      addStudentSkills: onboarding.errors.addStudentSkills,
      deleteDataStudentEntry: onboarding.errors.deleteDataStudentEntry,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getSkillFocuses: getSkillFocuses,
      hideSkillFocusesModal: hideSkillFocusesModal,
      addStudentSkills: addStudentSkills,
      deleteStudentEntry: deleteDataStudentEntry,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SkillsFocusModal);
