import React, { FunctionComponent, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { GoalAttainment } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";

type OwnProps = {
  goalAttainment: Array<GoalAttainment>;
};

type Props = OwnProps;

const GoalAttainmentTable: FunctionComponent<Props> = ({ goalAttainment }) => {
  const columns = useMemo(
    () => [
      {
        dataField: "coachId",
        text: "Coach Id",
        hidden: true,
      },
      {
        dataField: "coach",
        text: "Coach Name",
        headerStyle: { width: "50%" },
        sort: true,
      },
      {
        dataField: "avg_attainment",
        text: "Attainment Average",
        sort: true,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      goalAttainment.map((ga) => ({
        coachId: ga.coach.id,
        coach: getFullName(ga.coach),
        avg_attainment:
          (ga.avg_attainment * 100).toFixed(2).replace(".00", "") + "%",
      })),
    [goalAttainment]
  );

  return (
    <BootstrapTable
      classes="rosterTable"
      keyField="id"
      bordered={false}
      bootstrap4
      data={data}
      columns={columns}
      noDataIndication={"No data"}
    />
  );
};

export default GoalAttainmentTable;
