import { Measurement, Student } from "../types";
import { AttachedResource, StudentGoal } from "../cases/types";
import { UserInfo } from "../../auth/types";

export type MeetingModuleState = {
  preselectedStudentGoal?: StudentGoal;
  progressOverview?: ProgressOverview;
  selectedPraise?: string;
  prepMeetings: Array<PrepMeeting>;
  meetings: Array<Meeting>;
  selectedMeeting?: Meeting;
  supportMeetings: Array<SupportMeeting>;
  feedbackMeetings: Array<FeedbackMeeting>;
  selectedMeetingOld?: PrepMeeting | FeedbackMeeting | SupportMeeting;
  predefinedMeetingType?: MeetingType;
  praises: Praise[];
  modalsState: {
    prepareInterventionModal: boolean;
    raiseEducatorModal: boolean;
    interventionsBriefModal: boolean;
    showOnlyMaterials?: boolean;
    supportInterventionModal: boolean;
    providingFeedbackModal: boolean;
    meetingPromptModal: boolean;
    supportImplFeedbackModal: boolean;
    implementationCheckModal: boolean;
    implementationCheckModalCallbacks?: {
      onClose?: Function;
    };
    goalProgressModal: boolean;
    goalProgressModalCallbacks?: {
      onClose?: Function;
    };
    endInterventionModal: boolean;
    modifyInterventionModal: boolean;
    interventionFidelityModal?: boolean;
  };
  isLoading: {
    getMeetings: boolean;
    createMeeting: boolean;
    updateMeeting: boolean;

    getPrepMeetings: boolean;
    createPrepMeeting: boolean;
    updatePrepMeeting: boolean;

    getFeedbackMeetings: boolean;
    createFeedbackMeeting: boolean;
    updateFeedbackMeeting: boolean;

    getSupportMeetings: boolean;
    createSupportMeeting: boolean;
    updateSupportMeeting: boolean;

    getProgressOverviewData: boolean;
    logProgressTowardsGoal: boolean;
    logIntervention: boolean;

    getPrises?: boolean;
  };
  errors: {
    getMeetings?: string;
    createMeeting?: string;
    updateMeeting?: string;

    getPrepMeetings?: string;
    createPrepMeeting?: string;
    updatePrepMeeting?: string;

    getFeedbackMeetings?: string;
    createFeedbackMeeting?: string;
    updateFeedbackMeeting?: string;

    getSupportMeetings?: string;
    createSupportMeeting?: string;
    updateSupportMeeting?: string;

    getProgressOverviewData?: string;
    logProgressTowardsGoal?: string;
    logIntervention?: string;
  };
};

export enum MeetingModuleActionTypes {
  OPEN_PREPARE_INTERVENTION_MODAL = "@@groups/OPEN_PREPARE_INTERVENTION_MODAL",
  HIDE_PREPARE_INTERVENTION_MODAL = "@@groups/HIDE_PREPARE_INTERVENTION_MODAL",

  OPEN_PRAISE_EDUCATOR_MODAL = "@@groups/OPEN_PRAISE_EDUCATOR_MODAL",
  HIDE_PRAISE_EDUCATOR_MODAL = "@@groups/HIDE_PRAISE_EDUCATOR_MODAL",

  OPEN_INTERVENTIONS_RESOURCES_MODAL = "@@groups/OPEN_INTERVENTIONS_RESOURCES_MODAL",
  HIDE_INTERVENTIONS_RESOURCES_MODAL = "@@groups/HIDE_INTERVENTIONS_RESOURCES_MODAL",

  OPEN_SUPPORT_INTERVENTION_MODAL = "@@groups/OPEN_SUPPORT_INTERVENTION_MODAL",
  HIDE_SUPPORT_INTERVENTION_MODAL = "@@groups/HIDE_SUPPORT_INTERVENTION_MODAL",

  OPEN_PROVIDING_FEEDBACK_MODAL = "@@groups/OPEN_PROVIDING_FEEDBACK_MODAL",
  HIDE_PROVIDING_FEEDBACK_MODAL = "@@groups/HIDE_PROVIDING_FEEDBACK_MODAL",

  OPEN_MEETING_PROMPT_MODAL = "@@groups/OPEN_MEETING_PROMPT_MODAL",
  HIDE_MEETING_PROMPT_MODAL = "@@groups/HIDE_MEETING_PROMPT_MODAL",

  OPEN_SUPPORT_IMPL_FEEDBACK_MODAL = "@@groups/OPEN_SUPPORT_IMPL_FEEDBACK_MODAL",
  HIDE_SUPPORT_IMPL_FEEDBACK_MODAL_OPEN = "@@groups/HIDE_SUPPORT_IMPL_FEEDBACK_MODAL_OPEN",

  OPEN_IMPLEMENTATION_CHECK_MODAL = "@@groups/OPEN_IMPLEMENTATION_CHECK_MODAL",
  HIDE_IMPLEMENTATION_CHECK_MODAL = "@@groups/HIDE_IMPLEMENTATION_CHECK_MODAL",

  OPEN_GOAL_PROGRESS_MODAL = "@@groups/OPEN_GOAL_PROGRESS_MODAL",
  HIDE_GOAL_PROGRESS_MODAL = "@@groups/HIDE_GOAL_PROGRESS_MODAL",

  OPEN_MODIFY_INTERVENTION_MODAL = "@@groups/OPEN_MODIFY_INTERVENTION_MODAL",
  HIDE_MODIFY_INTERVENTION_MODAL = "@@groups/HIDE_MODIFY_INTERVENTION_MODAL",

  OPEN_END_INTERVENTION_MODAL = "@@groups/OPEN_END_INTERVENTION_MODAL",
  HIDE_END_INTERVENTION_MODAL = "@@groups/HIDE_END_INTERVENTION_MODAL",

  OPEN_INTERVENTION_FIDELITY_MODAL = "@@groups/OPEN_INTERVENTION_FIDELITY_MODAL",
  HIDE_INTERVENTION_FIDELITY_MODAL = "@@groups/HIDE_INTERVENTION_FIDELITY_MODAL",

  GET_MEETINGS_START = "@@groups/GET_MEETINGS_START",
  GET_MEETINGS_SUCCESS = "@@groups/GET_MEETINGS_SUCCESS",
  GET_MEETINGS_FAILURE = "@@groups/GET_MEETINGS_FAILURE",

  CREATE_MEETING_START = "@@groups/CREATE_MEETING_START",
  CREATE_MEETING_SUCCESS = "@@groups/CREATE_MEETING_SUCCESS",
  CREATE_MEETING_FAILURE = "@@groups/CREATE_MEETING_FAILURE",

  UPDATE_MEETING_START = "@@groups/UPDATE_MEETING_START",
  UPDATE_MEETING_SUCCESS = "@@groups/UPDATE_MEETING_SUCCESS",
  UPDATE_MEETING_FAILURE = "@@groups/UPDATE_MEETING_FAILURE",

  CHANGE_SELECTED_MEETING = "@@groups/CHANGE_SELECTED_MEETING",

  //todo remove
  GET_PREP_MEETINGS_START = "@@groups/GET_PREP_MEETINGS_START",
  GET_PREP_MEETINGS_SUCCESS = "@@groups/GET_PREP_MEETINGS_SUCCESS",
  GET_PREP_MEETINGS_FAILURE = "@@groups/GET_PREP_MEETINGS_FAILURE",

  CREATE_PREP_MEETING_START = "@@groups/CREATE_PREP_MEETING_START",
  CREATE_PREP_MEETING_SUCCESS = "@@groups/CREATE_PREP_MEETING_SUCCESS",
  CREATE_PREP_MEETING_FAILURE = "@@groups/CREATE_PREP_MEETING_FAILURE",

  UPDATE_PREP_MEETING_START = "@@groups/UPDATE_PREP_MEETING_START",
  UPDATE_PREP_MEETING_SUCCESS = "@@groups/UPDATE_PREP_MEETING_SUCCESS",
  UPDATE_PREP_MEETING_FAILURE = "@@groups/UPDATE_PREP_MEETING_FAILURE",

  GET_FEEDBACK_MEETINGS_START = "@@groups/GET_FEEDBACK_MEETINGS_START",
  GET_FEEDBACK_MEETINGS_SUCCESS = "@@groups/GET_FEEDBACK_MEETINGS_SUCCESS",
  GET_FEEDBACK_MEETINGS_FAILURE = "@@groups/GET_FEEDBACK_MEETINGS_FAILURE",

  CREATE_FEEDBACK_MEETING_START = "@@groups/CREATE_FEEDBACK_MEETING_START",
  CREATE_FEEDBACK_MEETING_SUCCESS = "@@groups/CREATE_FEEDBACK_MEETING_SUCCESS",
  CREATE_FEEDBACK_MEETING_FAILURE = "@@groups/CREATE_FEEDBACK_MEETING_FAILURE",

  UPDATE_FEEDBACK_MEETING_START = "@@groups/UPDATE_FEEDBACK_MEETING_START",
  UPDATE_FEEDBACK_MEETING_SUCCESS = "@@groups/UPDATE_FEEDBACK_MEETING_SUCCESS",
  UPDATE_FEEDBACK_MEETING_FAILURE = "@@groups/UPDATE_FEEDBACK_MEETING_FAILURE",

  GET_SUPPORT_MEETINGS_START = "@@groups/GET_SUPPORT_MEETINGS_START",
  GET_SUPPORT_MEETINGS_SUCCESS = "@@groups/GET_SUPPORT_MEETINGS_SUCCESS",
  GET_SUPPORT_MEETINGS_FAILURE = "@@groups/GET_SUPPORT_MEETINGS_FAILURE",

  CREATE_SUPPORT_MEETING_START = "@@groups/CREATE_SUPPORT_MEETING_START",
  CREATE_SUPPORT_MEETING_SUCCESS = "@@groups/CREATE_SUPPORT_MEETING_SUCCESS",
  CREATE_SUPPORT_MEETING_FAILURE = "@@groups/CREATE_SUPPORT_MEETING_FAILURE",

  UPDATE_SUPPORT_MEETING_START = "@@groups/UPDATE_SUPPORT_MEETING_START",
  UPDATE_SUPPORT_MEETING_SUCCESS = "@@groups/UPDATE_SUPPORT_MEETING_SUCCESS",
  UPDATE_SUPPORT_MEETING_FAILURE = "@@groups/UPDATE_SUPPORT_MEETING_FAILURE",

  CHANGE_SELECTED_PRAISE = "@@groups/CHANGE_SELECTED_PRAISE",

  LOG_PROGRESS_TOWARDS_GOAL_START = "@@groups/LOG_PROGRESS_TOWARDS_GOAL_START",
  LOG_PROGRESS_TOWARDS_GOAL_SUCCESS = "@@groups/LOG_PROGRESS_TOWARDS_GOAL_SUCCESS",
  LOG_PROGRESS_TOWARDS_GOAL_FAILURE = "@@groups/LOG_PROGRESS_TOWARDS_GOAL_FAILURE",

  UPDATE_PROGRESS_TOWARDS_GOAL_START = "@@groups/UPDATE_PROGRESS_TOWARDS_GOAL_START",
  UPDATE_PROGRESS_TOWARDS_GOAL_SUCCESS = "@@groups/UPDATE_PROGRESS_TOWARDS_GOAL_SUCCESS",
  UPDATE_PROGRESS_TOWARDS_GOAL_FAILURE = "@@groups/UPDATE_PROGRESS_TOWARDS_GOAL_FAILURE",

  LOG_INTERVENTION_START = "@@groups/LOG_INTERVENTION_START",
  LOG_INTERVENTION_SUCCESS = "@@groups/LOG_INTERVENTION_SUCCESS",
  LOG_INTERVENTION_FAILURE = "@@groups/LOG_INTERVENTION_FAILURE",

  UPDATE_IMPLEMENTATION_CHECK_START = "@@groups/UPDATE_IMPLEMENTATION_CHECK_START",
  UPDATE_IMPLEMENTATION_CHECK_SUCCESS = "@@groups/UPDATE_IMPLEMENTATION_CHECK_SUCCESS",
  UPDATE_IMPLEMENTATION_CHECK_FAILURE = "@@groups/UPDATE_IMPLEMENTATION_CHECK_FAILURE",

  GET_PROGRESS_OVERVIEW_DATA_START = "@@groups/GET_PROGRESS_OVERVIEW_DATA_START",
  GET_PROGRESS_OVERVIEW_DATA_SUCCESS = "@@groups/GET_PROGRESS_OVERVIEW_DATA_SUCCESS",
  GET_PROGRESS_OVERVIEW_DATA_FAILURE = "@@groups/GET_PROGRESS_OVERVIEW_DATA_FAILURE",

  CHANGE_PREDEFINED_MEETING_TYPE = "@@groups/meeting/CHANGE_PREDEFINED_MEETING_TYPE",
}

export type ProgressOverview = {
  student_goal_progress: Array<StudentGoalProgress>;
  implementation_checks: Array<ImplementationCheck>;
};

export type StudentGoalProgress = {
  student_goal_id: number;
  student: Student;
  measurement: Measurement;
  logged_progress: Array<LoggedProgress>;
  goal_line_progress?: Array<LoggedProgress>;
  trendline_progress?: Array<LoggedProgress>;
  is_general_outcome?: boolean;
  is_primary_goal?: boolean;
};

export type LoggedProgress = {
  id?: number;
  goal_id: number;
  value: any; //todo
  date: any;
  student?: any;
  goal?: number;
};

export type ImplementationCheck = {
  id: number;
  is_active: true;
  fidelity: number;
  intervention: number;
  intervention_group: number;
  steps_completed: number;
  steps_count: number;
  observed_date: string;
  logged_by_user: number | UserInfo;
  logged_items?: Array<LoggedItem>;
  not_applicable_items?: Array<any>
  not_planned_reason?: string;
  fidelity_notes?: string;
};

export type InterventionCount = {
  id:IntervationObj;
};

export type IntervationObj = {
  groups: Array<any>;
  teachers: Array<any>;
}

export type LoggedItem = {
  id: number;
  created: string;
  updated: string;
  is_active: boolean;
  item: number;
  logged_interventions: number;
};

export type Meeting = {
  id: number;
  created: string;
  updated: string;
  is_active: boolean;
  completed: boolean;
  meeting_type: MeetingType;
  intervention_group: number;
  planning_notes?: string;
  meeting_notes?: string;
  other_notes?: string;
  educator_praises?: string[];
  modeled: boolean;
  practiced: boolean;
  needs_modeling: boolean;
  needs_practice: boolean;
  attachments: Array<AttachedResource>;
};

export enum MeetingType {
  PREPARE_FOR_INTERVENTION = "preparation",
  FEEDBACK_INTERVENTION = "feedback",
  SUPPORT_INTERVENTION = "support_intervention",
}

export const MeetingTypeDisplayed = {
  [MeetingType.PREPARE_FOR_INTERVENTION]: "Prepare for Intervention",
  [MeetingType.FEEDBACK_INTERVENTION]: "Implementation Feedback",
  [MeetingType.SUPPORT_INTERVENTION]: "Support Intervention",
};

export type MeetingOld = {
  id: number;
  created: string;
  updated: string;
  is_active: boolean;
  completed: boolean;
  meeting_type: 0 | 1;
  intervention_group: number;
  praise: string | null;
  next_steps: string | null;
};

export type PrepMeeting = MeetingOld & {
  modeling_notes: string;
  practice_notes: string;
  planning_meeting: number | PrepMeeting | null;
};

export type FeedbackMeeting = MeetingOld & {
  strengths: string;
  improvements: string;
  additional_modeling: string;
  additional_practice: string;
  planning_meeting: number | FeedbackMeeting | null;
};

export type SupportMeeting = MeetingOld & {
  strengths: string;
  improvements: string;
  barriers: string;
  additional_modeling: string;
  additional_practice: string;
  no_modeling: boolean;
  no_practice: boolean;
  planning_meeting: number | SupportMeeting | null;
};

export type Praise = {
  id: number;
  name: string;
};

export const praiseList: Praise[] = [
  {
    id: 1,
    name: "Your efforts are outstanding and are making a difference.",
  },
  {
    id: 2,
    name:
      "Your willingness to go the extra mile with this student is remarkable.",
  },
  {
    id: 3,
    name:
      "I think your contribution to this student's improvement is exceptional.",
  },
  {
    id: 4,
    name: "Your commitment to helping this students is admirable.",
  },
  {
    id: 5,
    name: "You're a terrific person to work with!",
  },
  {
    id: 6,
    name: "You're one of the most energetic people I have ever worked with.",
  },
  {
    id: 7,
    name:
      "If I were a student, I would want you as my teacher given your dedication.",
  },
];

export enum InterventionGroupTabs {
  MEETING = "meeting",
  STUDENTS_GOALS = "student-goals",
  MEETINGS = "meetings",
  EVIDENCE = "evidence",
  PROGRESS_MONITORING = "progress-monitoring",
}
