import React, { FunctionComponent, useCallback, useMemo } from "react";
import {
  Intervention,
  InterventionGroup,
  LessonNotes,
} from "../../../../../../../../../store/onboarding/cases/types";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTimesCircle,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { deleteLessonNotes } from "../../../../../../../../../store/onboarding/cases/actions";
import { showConfirmDialog } from "../../../../../../../../../store/confirm-dialog/actions";
import { getFullName } from "../../../../../../../../../utils/NamesUtils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ApplicationState } from "../../../../../../../../../store";
import { UserInfo } from "../../../../../../../../../store/auth/types";
import AttachedResourceItem from "../../../../../explore-and-learn/intervention-library/new-intervention-modal/intervention-steps-form/AttachedResource";
import useInterventionGroupAccess from "../../../../../../../../../utils/hooks/useInterventionGroupAccess";

type Props = {
  notes: LessonNotes;
  group: InterventionGroup;
  interventions: Intervention[];
  archivedInterventions: Intervention[];
  onEdit: () => void;
  isStudentDetail?: boolean;
  isGroupDetail?: boolean;
};

const LessonNotesItem: FunctionComponent<Props> = (props) => {
  const { notes, group, interventions, onEdit, isStudentDetail, isGroupDetail, archivedInterventions } = props;

  const dispatch = useDispatch();

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(group.id);

  const details = useMemo(() => {
    const interventionNames = notes.interventions.map(
      (intId) => {
        const interventionName = interventions.find((int) => int.id === intId)?.name;
        if(interventionName) {
          return interventionName;
        } else {
          return archivedInterventions.find((int) => int.id === intId)?.name;
        }
      }
    );

    return (notes.is_general
      ? ["General Notes", ...interventionNames]
      : interventionNames
    ).join(", ");
  }, [notes.is_general, notes.interventions, interventions]);

  const onRemove = useCallback(() => {
    if (group.id && notes.id) {
      dispatch(deleteLessonNotes(group.id, notes.id));
    }
  }, [group.id, notes.id]);

  const handleDelete = useCallback(() => {
    dispatch(
      showConfirmDialog({
        onConfirm: onRemove,
        centered: true,
      })
    );
  }, [onRemove]);

  const myId = useSelector((s: ApplicationState) => s.auth.userInfo?.id);

  const sharedWith: string | undefined = useMemo(() => {
    const users: UserInfo[] = [
      group.coach_assignment?.user,
      group.teacher_assignment?.user,
      ...group.associated_assignments.map((aa) => aa.user),
    ].filter<UserInfo>((user): user is UserInfo => {
      return !!user && notes.viewable_by.includes(user.id);
    });

    if (!users.length) {
      return undefined;
    }

    if (users.length === 1 && users[0].id === myId) {
      return "Just Me";
    } else {
      const getNameOrMe = (user: UserInfo) => {
        if (user.id === myId) {
          return "Me";
        } else {
          return getFullName(user);
        }
      };
      return users.map(getNameOrMe).join(", ");
    }
  }, [notes.viewable_by, myId]);

  return (
    <div className="lesson-notes-item">
      <div className="d-flex justify-content-between font-weight-bold">
        {moment(notes.date).format("MM/DD/YYYY")}
        {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess ?
          <div className="entry-actions my-0">
            <span className="edit">
              <FontAwesomeIcon icon={faEdit} onClick={onEdit} />
            </span>
            <span className="delete" onClick={handleDelete}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </span>
          </div>
          : ''
        }
      </div>
      <div>
        {sharedWith && !isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess && (
          <OverlayTrigger
            overlay={
              <Tooltip id={`tooltip-${notes.id}`}>
                <strong>Shared with: </strong>
                {sharedWith}
              </Tooltip>
            }
          >
            <span className="float-right ml-1">
              <FontAwesomeIcon icon={faUserAlt} />
            </span>
          </OverlayTrigger>
        )}
        <p className="mb-0">
          <strong>{getFullName(notes.user)}: </strong>
          {notes.notes}
        </p>
      </div>
      {!!details && (
        <>
          <hr className="my-2" />
          <i>{details}</i>
        </>
      )}

      {!!notes.record_tags?.length && (
        <>
          <hr className="my-2" />
          <div className="tagsContainer" style={{ margin: "-5px" }}>
            {notes.record_tags
              .sort((a, b) => a.order - b.order)
              .map((tag, index) => (
                <div key={`tag_${index}`} className="tagCell">
                  {tag.text}
                </div>
              ))}
          </div>
        </>
      )}
      {!!notes.attachments?.length && (
        <>
          <hr className="my-2" />
          <div className="interventionStepFileContainer">
            {notes.attachments.map((attachment) => (
              <AttachedResourceItem
                attachment={attachment}
                key={attachment.id}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default LessonNotesItem;
