import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  createDistrictLicense,
  getDistrict,
  getDistrictLicenses,
  setShowDistrictLicenseModal,
  updateDistrictLicense,
} from "../../../../../../store/superuser/actions";
import {
  ValueLabel,
  License_Request,
  LicenseTypes,
  LicenseStatuses,
  SuperuserState,
} from "../../../../../../store/superuser/types";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import CustomDateInput from "../../../../../common/onboarding/third-step/set-goal-modal/common/CustomDateInput";
import DatePicker from "react-datepicker";
import moment from "moment";

const defaultValue: License_Request = {
  license_type: "",
  started_at: "",
  expires_at: "",
  status: "",
};

const DistrictLicenseModal = () => {
  const {
    selectedDistrict,
    selectedDistrictLicense,
    modalsState: { showDistrictLicenseModal },
  } = useSelector<ApplicationState, SuperuserState>((s) => s.superuser);
  const isLoading = useSelector<ApplicationState, boolean>(
    (s) =>
      s.superuser.isLoading.createDistrictLicense ||
      s.superuser.isLoading.updateDistrictLicense ||
      s.superuser.isLoading.getDistrict ||
      s.superuser.isLoading.getDistrictLicenses
  );

  const dispatch = useDispatch();

  const [checkError, setCheckError] = useState<boolean>(false);
  const [license, setLicense] = useState<License_Request>(defaultValue);

  useEffect(() => {
    if (showDistrictLicenseModal) {
      setLicense(selectedDistrictLicense ?? defaultValue);
      setCheckError(false);
    }
  }, [showDistrictLicenseModal, selectedDistrictLicense]);

  const handleClose = () => {
    if (!isLoading) {
      dispatch(setShowDistrictLicenseModal({ show: false, license: null }));
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (
      selectedDistrict &&
      license.license_type &&
      license.started_at &&
      license.expires_at &&
      license.status
    ) {
      if (selectedDistrictLicense == null) {
        await dispatch(createDistrictLicense(selectedDistrict.id, license));
      } else {
        await dispatch(updateDistrictLicense(selectedDistrict.id, license));
      }
      await dispatch(getDistrict(selectedDistrict.id));
      await dispatch(getDistrictLicenses(selectedDistrict.id));
      dispatch(setShowDistrictLicenseModal({ show: false, license: null }));
    } else {
      setCheckError(true);
    }
  };

  const handleChangeType = (value: ValueType<ValueLabel, false>) => {
    const typedValue = value as ValueLabel;
    setLicense((prevState) => ({
      ...prevState,
      license_type: typedValue.value,
    }));
  };

  const handleChangeStatus = (value: ValueType<ValueLabel, false>) => {
    const typedValue = value as ValueLabel;
    setLicense((prevState) => ({ ...prevState, status: typedValue.value }));
  };

  return (
    <Modal
      show={showDistrictLicenseModal}
      onHide={handleClose}
      animation={false}
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="purpleModalHeader font-weight-bold">
        <Modal.Title>
          {selectedDistrictLicense == null ? "Create License" : "Edit License"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="districtLicenseModalForm" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label className="font-weight-bold" column={false}>
              License Type
            </Form.Label>
            <Select
              required
              placeholder="Select Type..."
              name="license_type"
              value={
                LicenseTypes.find(
                  (type) => type.value === license.license_type
                ) ?? null
              }
              options={LicenseTypes}
              getOptionLabel={(item: ValueLabel) => item.display_name}
              getOptionValue={(item: ValueLabel) => item.value}
              onChange={handleChangeType}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="font-weight-bold" column={false}>
              License Status
            </Form.Label>
            <Select
              required
              placeholder="Select Status..."
              name="status"
              value={
                LicenseStatuses.find(
                  (status) => status.value === license.status
                ) ?? null
              }
              options={LicenseStatuses}
              getOptionLabel={(item: ValueLabel) => item.display_name}
              getOptionValue={(item: ValueLabel) => item.value}
              onChange={handleChangeStatus}
            />
          </Form.Group>
          <div className="mb-3">
            <label className="font-weight-bold mr-3">License Start</label>
            <DatePicker
              required
              customInput={<CustomDateInput />}
              selected={
                license.started_at
                  ? moment(license.started_at).toDate()
                  : undefined
              }
              onChange={(date: Date) =>
                setLicense((prevState) => ({
                  ...prevState,
                  started_at: moment(date).format("YYYY-MM-DD"),
                }))
              }
            />
          </div>
          <div>
            <label className="font-weight-bold mr-3">License Expires</label>
            <DatePicker
              required
              customInput={<CustomDateInput />}
              selected={
                license.expires_at
                  ? moment(license.expires_at).toDate()
                  : undefined
              }
              onChange={(date: Date) =>
                setLicense((prevState) => ({
                  ...prevState,
                  expires_at: moment(date).format("YYYY-MM-DD"),
                }))
              }
            />
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {checkError && (
          <span style={{ color: "red" }}>
            Please fill all fields and try again.
          </span>
        )}
        <button
          className="blueBtnSm"
          type="submit"
          form="districtLicenseModalForm"
          disabled={isLoading}
        >
          {selectedDistrictLicense == null ? "Create" : "Update"}{" "}
          {isLoading && <Spinner animation="border" size="sm" />}
        </button>
        <button
          className="whiteBtnSm"
          type="reset"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DistrictLicenseModal;
