import React, { FunctionComponent } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { openManageDataPeriodsModal } from "../../../../../store/onboarding/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

type DispatchProps = {
  openManageDataPeriodsModal: () => any;
};

type Props = DispatchProps;

const DataPeriodsPanel: FunctionComponent<Props> = ({
  openManageDataPeriodsModal,
}) => {
  return (
    <>
      <span className="pointer" onClick={() => openManageDataPeriodsModal()}>
        <FontAwesomeIcon icon={faEdit} />
      </span>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      openManageDataPeriodsModal: openManageDataPeriodsModal,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(DataPeriodsPanel);
