//@ts-nocheck
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  AbcTypes,
  CasesState,
  FbaBehaviorDisplayed,
} from "../../../../../store/onboarding/cases/types";
import FbaChart from "./FbaChart";
import { getOverallABC } from "./utils";
import LoadingIndicator from "../../../../common/onboarding/LoadingIndicator";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import Select, { ValueType } from "react-select";
import { ReactSelectOption } from "../../../../../store/onboarding/types";
import useTopAbc from "./fba-item/useTopAbc";

enum FbaChartTabs {
  CHARTS = "charts",
  BEHAVIORS = "behaviors",
}

const FbaChartTab = () => {
  const { selectedFba, isLoading } = useSelector<ApplicationState, CasesState>(
    (s) => s.cases
  );

  const [filterBehavior, setFilterBehavior] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string | null>(
    FbaChartTabs.CHARTS
  );

  const columns = [
    {
      dataField: "antecedent_display",
      text: "Antecedent",
      sort: true,
    },
    {
      dataField: "behaviors_display",
      text: "Behaviors",
      sort: true,
    },
    {
      dataField: "consequence_display",
      text: "Consequence",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "behaviors_display",
      order: "asc",
    },
  ];

  const options = {
    sizePerPageList: [
      {
        text: "20",
        value: 20,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  };

  const handleClickMostFrequencyBehavior = (behavior: number) => {
    setFilterBehavior(FbaBehaviorDisplayed[behavior] ?? null);
    if (activeTab !== FbaChartTabs.BEHAVIORS) {
      setActiveTab(FbaChartTabs.BEHAVIORS);
    }
  };

  const handleSelectFilterBehavior = (
    behavior: ValueType<ReactSelectOption<string>>
  ) => {
    const item = behavior as ReactSelectOption<string>;
    setFilterBehavior(item?.value ?? null);
  };

  const maxAbcs = getOverallABC(selectedFba);

  const allBehaviors = selectedFba.observations
    .flatMap((obs) => obs.observationabc)
    .map((obs) => obs.behaviors_display);

  const behaviorsForFilter = allBehaviors
    .filter((beh, index) => allBehaviors.indexOf(beh) === index)
    .map((item) => ({
      label: item,
      value: item,
    }));

  const allObservations = selectedFba.observations
    .flatMap((obs) => obs.observationabc)
    .filter(
      (obs) =>
        filterBehavior == null ||
        obs.behaviors_display.toUpperCase() === filterBehavior.toUpperCase()
    );

  const { topAntecedent, topConsequence } = useTopAbc(
    selectedFba?.observations ?? []
  );

  const isEmpty = allObservations.length === 0 && !isLoading.updateFba;

  if (!selectedFba) return null;

  return (
    <div>
      <table className="fbaChartTable">
        <thead>
          <tr>
            <th style={{ width: "33%" }}>Most Frequency Antecedent</th>
            <th style={{ width: "33%" }}>Most Frequency Behavior</th>
            <th style={{ width: "33%" }}>Most Frequency Consequence</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{topAntecedent}</td>
            <td>
              {maxAbcs.b.map((x) => (
                <>
                  <button
                    key={x.behavior as number}
                    className="purplePillBtnSelected my-1"
                    onClick={() =>
                      handleClickMostFrequencyBehavior(x.behavior as number)
                    }
                  >
                    {FbaBehaviorDisplayed[x.behavior as number]}
                  </button>
                  <br />
                </>
              ))}
            </td>
            <td>{topConsequence?.map((c) => c.consequence).join(", ")}</td>
          </tr>
        </tbody>
      </table>
      <Tabs
        id="tabs"
        style={{ marginTop: 20 }}
        defaultActiveKey={FbaChartTabs.CHARTS}
        activeKey={activeTab as EventKey}
        onSelect={(k: string) => setActiveTab(k)}
      >
        <Tab eventKey={FbaChartTabs.CHARTS} title="Charts">
          <h3
            className="font-weight-semibold mt-3"
            style={{ marginLeft: "40px" }}
          >
            Antecedents per Behavior
          </h3>
          <FbaChart fba={selectedFba} type={AbcTypes.antecedents} />
          <h3
            className="font-weight-semibold mt-3"
            style={{ marginLeft: "40px" }}
          >
            Consequences per Behavior
          </h3>
          <FbaChart fba={selectedFba} type={AbcTypes.consequence} />
        </Tab>
        <Tab eventKey={FbaChartTabs.BEHAVIORS} title="Behaviors Table">
          <Select
            className="mt-2"
            isClearable
            options={behaviorsForFilter}
            value={
              behaviorsForFilter.find(
                (item) =>
                  item.value.toLowerCase() === filterBehavior?.toLowerCase()
              ) ?? null
            }
            placeholder="Select Behavior To Filter..."
            onChange={handleSelectFilterBehavior}
          />
          <BootstrapTable
            classes="rosterTable"
            // wrapperClasses={parents.length === 0 ? "rosterTableEmpty" : ""}
            keyField="id"
            bordered={false}
            bootstrap4
            data={allObservations}
            columns={columns}
            defaultSorted={defaultSorted}
            pagination={paginationFactory(options)}
            noDataIndication={
              isEmpty ? "No matching behaviors" : LoadingIndicator
            }
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default FbaChartTab;
