import React, { FunctionComponent, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { InterventionSummary } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";

type OwnProps = {
  interventions: Array<InterventionSummary>;
};

type Props = OwnProps;

const InterventionsTable: FunctionComponent<Props> = ({ interventions }) => {
  const columns = useMemo(
    () => [
      {
        dataField: "coach",
        text: "Coach Name",
        headerStyle: { width: "20%" },
        sort: true,
      },
      {
        dataField: "intervention",
        text: "Interventions",
        sort: true,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      interventions.map((is) => ({
        coach: getFullName(is.coach),
        intervention: (
          <div>
            {is.interventions.map((i, index) => (
              <div key={index}>
                <h4 className="font-weight-bold">{i.intervention.name}</h4>
                {!!i.teachers.length &&
                  `Used by ${i.teachers.map(getFullName).join(", ")}`}
                {index !== is.interventions.length - 1 && <hr />}
              </div>
            ))}
          </div>
        ),
      })),
    [interventions]
  );

  return (
    <BootstrapTable
      classes="rosterTable"
      keyField="id"
      bordered={false}
      bootstrap4
      data={data}
      columns={columns}
      noDataIndication={"No data"}
    />
  );
};

export default InterventionsTable;
