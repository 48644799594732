import React, { FunctionComponent } from "react";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { hideProvidingFeedbackModal } from "../../../../../../store/onboarding/meeting-module/actions";
import { connect } from "react-redux";

type StateProps = {
  showModal: boolean;
};

type DispatchProps = {
  hideProvidingFeedbackModal: () => any;
};

type Props = StateProps & DispatchProps;

const MeetingPromptModal: FunctionComponent<Props> = ({
  showModal,
  hideProvidingFeedbackModal,
}) => {
  //todo add a reminder option
  if (!showModal) return null;
  return (
    <div className="innerAlertModal">
      <div>
        <h3 className="font-weight-bold">
          Your meeting notes have been saved!
        </h3>
        <h4 className="mb-3 mt-2">
          Would you like to set a reminder for your next meeting?
        </h4>
        <div className="modalActions">
          <button className="blueBtnMd">Yes, set a reminder</button>
          <button className="blueBtnMd" onClick={hideProvidingFeedbackModal}>
            No, just save and exit
          </button>
        </div>
        <hr />
        <div className="modalActions">
          <button className="whiteBtnSm" onClick={hideProvidingFeedbackModal}>
            Cancel
          </button>
          <div />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  meetingModuleReducer,
}: ApplicationState): StateProps => {
  return {
    showModal: meetingModuleReducer.modalsState.meetingPromptModal,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hideProvidingFeedbackModal: hideProvidingFeedbackModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingPromptModal);
