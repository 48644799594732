import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ChecklistItem,
  Intervention,
  InterventionGroup,
  InterventionRequest,
} from "../../../../../store/onboarding/cases/types";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ImplementStepModal from "./ImplementStepModal";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  addInterventionsToGroup,
  createIntervention,
  hideInterventionLibraryModal,
  openImplementStepModal,
} from "../../../../../store/onboarding/cases/actions";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Spinner } from "react-bootstrap";

type StateProps = {
  selectedInterventionGroup?: InterventionGroup;
  isLoading: {
    createIntervention?: boolean;
    addInterventionsToGroup?: boolean;
  };
  districtId: number;
};
type DispatchProps = {
  openImplementStepModal: () => any;
  hideInterventionLibraryModal: () => any;
  createIntervention: (interventionRequest: InterventionRequest) => any;
  addInterventionsToGroup: (
    interventionGroupId: number,
    interventionIds: Array<number>
  ) => any;
};

type OwnProps = {
  selectedIntervention: Intervention;
  shareByDistrict?: boolean;
};

type Props = OwnProps & StateProps & DispatchProps;
type State = {
  modifiedIntervention?: Intervention;
  selectedCheckListItem?: ChecklistItem & { index?: number };
};

class ModifyingInterventionBody extends React.Component<Props, State> {
  state: Readonly<State> = {
    modifiedIntervention: undefined,
    selectedCheckListItem: undefined,
  };

  componentDidMount(): void {
    this.setState({ modifiedIntervention: this.props.selectedIntervention });
  }

  handleDragStart = (checkListItem: ChecklistItem) => (
    e: React.DragEvent<HTMLDivElement>
  ) => {
    e.dataTransfer.setData("checkListItem", JSON.stringify(checkListItem));
  };

  handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  handleCheckListItemEdit = (
    checkListItem: ChecklistItem,
    index: number
  ) => () => {
    this.setState({ selectedCheckListItem: { ...checkListItem, index } });
    this.props.openImplementStepModal();
  };

  handleStepAdd = () => {
    this.setState((prevState) => ({
      selectedCheckListItem: {
        index: prevState.modifiedIntervention!.checklist_items.length,
        is_active: true,
        title: "",
        details: "",
        common_step_name: undefined,
        order:
          prevState.modifiedIntervention!.checklist_items.reduce(
            (pV, cV) => (pV < cV.order ? cV.order : pV),
            0
          ) + 1,
        attachments: [],
      },
    }));
    this.props.openImplementStepModal();
  };

  handleStepDelete = () => {
    this.setState((prevState) => ({
      ...prevState,
      modifiedIntervention: {
        ...prevState.modifiedIntervention!,
        checklist_items: prevState.modifiedIntervention!.checklist_items.filter(
          (x) => x.order !== prevState.selectedCheckListItem!.order
        ),
      },
      selectedCheckListItem: undefined,
    }));
  };

  handleStepChange = (checkListItem: ChecklistItem & { index?: number }) => {
    this.setState((prevState) => ({
      ...prevState,
      modifiedIntervention: {
        ...prevState.modifiedIntervention!,
        checklist_items:
          checkListItem.index! <
          prevState.modifiedIntervention!.checklist_items.length
            ? prevState.modifiedIntervention!.checklist_items.map(
                (c, index) => {
                  if (index === checkListItem.index) {
                    //to set id to undefined for the modified checklist item
                    return { ...checkListItem, id: undefined };
                  }
                  return c;
                }
              )
            : [
                ...prevState.modifiedIntervention!.checklist_items,
                checkListItem,
              ],
      },
      selectedCheckListItem: undefined,
    }));
  };

  handleDrop = (checkListOverItem: ChecklistItem) => (
    event: React.DragEvent<HTMLDivElement>
  ) => {
    const data = event.dataTransfer.getData("checkListItem");
    if (data) {
      const checkListItem: ChecklistItem = JSON.parse(data);
      if (checkListOverItem.order === checkListItem.order) return;
      this.setState((prevState) => {
        const newCheckLists: Array<ChecklistItem> = prevState
          .modifiedIntervention!.checklist_items.sort(this.checklistItemsSort)
          .map((x) => {
            if (x.order === checkListItem.order) {
              return { ...x, order: checkListOverItem.order };
            }
            if (
              (x.order > checkListItem.order &&
                x.order > checkListOverItem.order) ||
              (x.order < checkListItem.order &&
                x.order < checkListOverItem.order)
            ) {
              return x;
            } else if (
              x.order < checkListItem.order &&
              x.order >= checkListOverItem.order
            ) {
              return { ...x, order: x.order + 1 };
            } else {
              return { ...x, order: x.order - 1 };
            }
          });
        return {
          modifiedIntervention: {
            ...prevState.modifiedIntervention!,
            checklist_items: newCheckLists,
          },
        };
      });
    }
  };

  handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {};

  handleChangesDiscard = () => {
    this.setState({ modifiedIntervention: this.props.selectedIntervention });
  };

  checklistItemsSort = (a: ChecklistItem, b: ChecklistItem) => {
    return a.order - b.order;
  };

  handleSaveAndUseIntervention = () => {
    if (this.state.modifiedIntervention) {
      const newIntervention: InterventionRequest = {
        source_intervention_id: this.state.modifiedIntervention.id,
        name: "COPY_" + this.state.modifiedIntervention.name,
        description: this.state.modifiedIntervention.description,
        concern_area: this.state.modifiedIntervention.concern_area,
        expected_change: this.state.modifiedIntervention.expected_change,
        purpose: this.state.modifiedIntervention.purpose,
        considerations: this.state.modifiedIntervention.considerations,
        target: this.state.modifiedIntervention.target,
        concern_type: this.state.modifiedIntervention.concern_type,
        checklist_items: this.state.modifiedIntervention.checklist_items
          .sort((a: ChecklistItem, b: ChecklistItem) => a.order - b.order)
          .map((ci, index) => ({
            title: ci.title,
            details: ci.details,
            order: index + 1, // value optimization
            common_step_name: ci.common_step_name,
            attachments: ci.attachments.map((a) => a.id!),
          })),
        ...(this.props.shareByDistrict ? {district: this.props.districtId} : '')
      };

      this.props.createIntervention(newIntervention).then(
        (intervention: Intervention) => {
          this.props
            .addInterventionsToGroup(
              this.props.selectedInterventionGroup?.id!,
              [
                ...(this.props.selectedInterventionGroup?.interventions?.map(
                  (i) => i.id
                ) || []),
                intervention.id,
              ]
            )
            .then(
              () => this.props.hideInterventionLibraryModal(),
              (err: string) =>
                toastr.error(
                  "Failed to assign the intervention to the group",
                  err
                )
            );
        },
        (err: string) => toastr.error("Failed to create the intervention", err)
      );
    }
  };

  render() {
    const { modifiedIntervention } = this.state;
    if (!modifiedIntervention) return null;
    return (
      <>
        <div className="d-flex justify-content-start">
          <h2 className="purpleText">
            Modify intervention steps to fit your needs.
          </h2>
          <button
            className="blueBtnLg ml-auto"
            onClick={this.handleSaveAndUseIntervention}
          >
            Save & use intervention
            {(this.props.isLoading.createIntervention ||
              this.props.isLoading.addInterventionsToGroup) && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        </div>
        <u
          className="font-weight-semibold pointer"
          onClick={this.handleChangesDiscard}
        >
          Discard changes
        </u>
        <hr />
        <div className="interventionStepsContainer">
          <div className="interventionStepsContainerHeader">
            <div>
              <p className="purpleText">
                Steps to implement{" "}
                <span className="font-weight-bold">
                  {modifiedIntervention.name}
                </span>
              </p>
              <p>You can drag and drop the steps to reorder them.</p>
            </div>
            <div onClick={this.handleStepAdd}>
              <FontAwesomeIcon icon="plus-circle" size="2x" />
            </div>
          </div>
          <div className="interventionStepsContainerBody">
            {modifiedIntervention.checklist_items
              .sort(this.checklistItemsSort)
              .map((checkListItem, index) => (
                <div
                  className="interventionStepItem"
                  key={index}
                  draggable
                  onDragStart={this.handleDragStart(checkListItem)}
                  onDragOver={this.handleDragOver}
                  onDragEnd={this.handleDragEnd}
                  onDrop={this.handleDrop(checkListItem)}
                >
                  <div>
                    <h3>{index + 1}</h3>
                  </div>
                  <div>
                    <h3 className="font-weight-bold">{checkListItem.title}</h3>
                    <p>{checkListItem.details}</p>
                    <div>
                      {checkListItem.attachments.map((attachment, index) => (
                        <span className="interventionStepFile" key={index}>
                          <FontAwesomeIcon icon="file" size="1x" />
                          &nbsp; {attachment.title}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div>
                    <span
                      onClick={this.handleCheckListItemEdit(
                        checkListItem,
                        index
                      )}
                    >
                      <FontAwesomeIcon icon={faEdit} size="lg" />
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <hr />
        <div className="modalActions">
          <div />
          <button
            className="blueBtnLg ml-auto"
            onClick={this.handleSaveAndUseIntervention}
          >
            Save & use intervention
            {(this.props.isLoading.createIntervention ||
              this.props.isLoading.addInterventionsToGroup) && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        </div>
        <ImplementStepModal
          selectedCheckListItem={this.state.selectedCheckListItem}
          selectedIntervention={this.props.selectedIntervention}
          onDeleteStep={this.handleStepDelete}
          onSaveChanges={this.handleStepChange}
        />
      </>
    );
  }
}

const mapStateToProps = ({ cases, auth }: ApplicationState): StateProps => {
  return {
    selectedInterventionGroup: cases.selectedInterventionGroup,
    isLoading: {
      createIntervention: cases.isLoading.createIntervention,
      addInterventionsToGroup: cases.isLoading.addInterventionsToGroup,
    },
    districtId: auth.userInfo?.profile.district?.id!
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openImplementStepModal: openImplementStepModal,
      createIntervention: createIntervention,
      addInterventionsToGroup: addInterventionsToGroup,
      hideInterventionLibraryModal: hideInterventionLibraryModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifyingInterventionBody);
