import React, { FunctionComponent, useMemo } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import {
  GrammarType,
  SpellingTestAnswer,
  SpellingTestAnswerGrammarPart,
  SpellingTestQuestion,
} from "../../../../../store/spelling-tests/types";
import { useDispatch } from "react-redux";
import { changeSpellingTestAnswerGrammarPart } from "../../../../../store/spelling-tests/actions";
import { useMinInputWidth } from "./hooks/useMinInputWidth";
import useSpellingTestCellOnFocus from "./hooks/useSpellingTestCellOnFocus";

type Props = {
  correctPart: string;
  grammarType: GrammarType;
  question: SpellingTestQuestion;
  answer?: SpellingTestAnswer;
};

const CommonCell: FunctionComponent<Props> = (props) => {
  const { correctPart, grammarType, answer, question } = props;
  const dispatch = useDispatch();

  const grammarPart: SpellingTestAnswerGrammarPart | undefined = useMemo(() => {
    return answer?.grammar_parts.find((gp) => gp.grammar_type === grammarType);
  }, [answer?.grammar_parts]);

  const handleCommonGrammarPartChange = (
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    const correct = value === correctPart;
    if (value !== answer?.word && answer && grammarPart) {
      dispatch(
        changeSpellingTestAnswerGrammarPart({
          word: question.word,
          type: grammarType,
          student_answer: value,
          correct: correct,
        })
      );
    }
  };

  const inputMinWidth = useMinInputWidth(correctPart);

  const onFocus = useSpellingTestCellOnFocus(question.word);

  return (
    <>
      <td>
        <InputGroup>
          <FormControl
            readOnly
            className="source-part"
            value={correctPart}
            style={{ minWidth: inputMinWidth }}
            tabIndex={-1}
          />
        </InputGroup>
      </td>
      <td>
        <InputGroup>
          <FormControl
            defaultValue={grammarPart?.student_answer || ""}
            onFocus={onFocus}
            onBlur={handleCommonGrammarPartChange}
            style={{ minWidth: inputMinWidth }}
            className={`${
              grammarPart?.student_answer !== undefined
                ? grammarPart.correct
                  ? "correct-part"
                  : "wrong-part"
                : "empty-part"
            }`}
          />
        </InputGroup>
      </td>
    </>
  );
};

export default CommonCell;
