import React from "react";
import ColumnHeaderSelector from "./ColumnHeaderSelector";
import { Col, Form } from "react-bootstrap";
import { IS_READY_COACH } from "../../../../../constants";

type Props = {
  assessmentName: string;
  studentIdColumnHeader: string;
  studentFNameColumnHeader: string;
  studentLNameColumnHeader: string;
  studentTeacherColumnHeader: string;
  studentGradeColumnHeader: string;
  studentRaceColumnHeader: string;
  onChange: (event: React.FormEvent<any>) => void;
  columnNames: Array<string>;
  selectedColumnNames: Array<string>;
};

const ColumnHeadersSelectorPanel = (props: Props) => {
  return (
    <>
      <Form.Group>
        <Form.Label className="font-weight-bold">Assessment Name *</Form.Label>
        <Form.Control
          required
          value={props.assessmentName}
          onChange={props.onChange}
          name={"assessmentName"}
          type="text"
          placeholder="Name your assessment (e.g. SAT, Behavior Score)"
        />
      </Form.Group>
      <Form.Row>
        <Col>
          <ColumnHeaderSelector
            required
            name={"selectedStudentIdColumn"}
            title={"Student ID Column *"}
            value={props.studentIdColumnHeader}
            onChange={props.onChange}
            columnNames={props.columnNames}
            selectedColumnNames={props.selectedColumnNames}
          />
        </Col>
        <Col>
          <ColumnHeaderSelector
            required
            name={"selectedStudentFirstNameColumn"}
            title={"Student First Name Column *"}
            value={props.studentFNameColumnHeader}
            onChange={props.onChange}
            columnNames={props.columnNames}
            selectedColumnNames={props.selectedColumnNames}
          />
        </Col>
        <Col>
          <ColumnHeaderSelector
            required
            name={"selectedStudentLastNameColumn"}
            title={"Student Last Name Column *"}
            value={props.studentLNameColumnHeader}
            onChange={props.onChange}
            columnNames={props.columnNames}
            selectedColumnNames={props.selectedColumnNames}
          />
        </Col>
      </Form.Row>
      {IS_READY_COACH && (
        <Form.Row>
          <Col>
            <ColumnHeaderSelector
              name={"selectedStudentTeacherColumn"}
              title={"Teacher Column"}
              value={props.studentTeacherColumnHeader}
              onChange={props.onChange}
              columnNames={props.columnNames}
              selectedColumnNames={props.selectedColumnNames}
            />
          </Col>
          <Col>
            <ColumnHeaderSelector
              name={"selectedStudentGradeColumn"}
              title={"Grade Column"}
              value={props.studentGradeColumnHeader}
              onChange={props.onChange}
              columnNames={props.columnNames}
              selectedColumnNames={props.selectedColumnNames}
            />
          </Col>
          <Col>
            <ColumnHeaderSelector
              name={"selectedStudentRaceColumn"}
              title={"Race Column"}
              value={props.studentRaceColumnHeader}
              onChange={props.onChange}
              columnNames={props.columnNames}
              selectedColumnNames={props.selectedColumnNames}
            />
          </Col>
          
        </Form.Row>
      )}
    </>
  );
};

export default ColumnHeadersSelectorPanel;
