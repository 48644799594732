import { ActionType } from "typesafe-actions";
import * as notificationsActions from "./actions";
import { TourActionTypes, TourState } from "./types";
import { Reducer } from "redux";
import {
  getBooleanFromLocalStorage,
  LocalStorageKeys,
} from "../../utils/LocalStorageUtils";

export type TourAction = ActionType<typeof notificationsActions>;

const initialState: TourState = {
  firstUploadTourShown: getBooleanFromLocalStorage(
    LocalStorageKeys.FirstUploadTour
  ),
  sidebarPositionTourShown: getBooleanFromLocalStorage(
    LocalStorageKeys.SidebarPositionTour
  ),
  prepareMeetingTourShown: getBooleanFromLocalStorage(
    LocalStorageKeys.PrepareMeetingTour
  ),
  implementMeetingTourShown: getBooleanFromLocalStorage(
    LocalStorageKeys.ImplementMeetingTour
  ),
  interventionFidelityTourShown: getBooleanFromLocalStorage(
    LocalStorageKeys.InterventionFidelityTour
  ),
  fidelityChartTourShown: getBooleanFromLocalStorage(
    LocalStorageKeys.FidelityChartTour
  ),
  emptyDataPeriodViewTourShown: getBooleanFromLocalStorage(
    LocalStorageKeys.EmptyDataPeriodViewTour
  ),
  addSecondaryGoalPositionTourShown: false,
};

const reducer: Reducer<TourState> = (
  state: TourState = initialState,
  action: TourAction
): TourState => {
  switch (action.type) {
    case TourActionTypes.MARK_FIRST_UPLOAD_TOUR_AS_SHOWN: {
      localStorage.setItem(
        LocalStorageKeys.FirstUploadTour,
        JSON.stringify(true)
      );
      return {
        ...state,
        firstUploadTourShown: true,
      };
    }
    case TourActionTypes.MARK_SIDEBAR_POSITION_TOUR_AS_SHOWN: {
      localStorage.setItem(
        LocalStorageKeys.SidebarPositionTour,
        JSON.stringify(true)
      );
      return {
        ...state,
        sidebarPositionTourShown: true,
      };
    }
    case TourActionTypes.MARK_PREPARE_MEETING_TOUR_AS_SHOWN: {
      localStorage.setItem(
        LocalStorageKeys.PrepareMeetingTour,
        JSON.stringify(true)
      );
      return {
        ...state,
        prepareMeetingTourShown: true,
      };
    }
    case TourActionTypes.MARK_IMPLEMENT_MEETING_TOUR_AS_SHOWN: {
      localStorage.setItem(
        LocalStorageKeys.ImplementMeetingTour,
        JSON.stringify(true)
      );
      return {
        ...state,
        implementMeetingTourShown: true,
      };
    }
    case TourActionTypes.MARK_INTERVENTION_FIDELITY_TOUR_AS_SHOWN: {
      localStorage.setItem(
        LocalStorageKeys.InterventionFidelityTour,
        JSON.stringify(true)
      );
      return {
        ...state,
        interventionFidelityTourShown: true,
      };
    }
    case TourActionTypes.MARK_FIDELITY_CHART_TOUR_AS_SHOWN: {
      localStorage.setItem(
        LocalStorageKeys.FidelityChartTour,
        JSON.stringify(true)
      );
      return {
        ...state,
        fidelityChartTourShown: true,
      };
    }
    case TourActionTypes.MARK_EMPTY_DATA_PERIOD_VIEW_TOUR_AS_SHOWN: {
      localStorage.setItem(
        LocalStorageKeys.EmptyDataPeriodViewTour,
        JSON.stringify(true)
      );
      return {
        ...state,
        emptyDataPeriodViewTourShown: true,
      };
    }
    case TourActionTypes.CHANGE_SELECTED_INTERVENTION_GROUP_ID: {
      return {
        ...state,
        selectedInterventionGroupId: action.payload,
      };
    }
    case TourActionTypes.MARK_ADD_SECONDARY_GOAL_TOUR_AS_SHOWN: {
      
      return {
        ...state,
        addSecondaryGoalPositionTourShown: action.payload,
      };
    }
    default:
      return state;
  }
};

export { reducer as tourReducer };
