import moment from "moment";

export const formatDate = (
  dateString: string,
  format: string = "MMM D, YYYY"
) => {
  return moment
    .utc(dateString)
    .local()
    .format(format);
};

export const formatDateWithoutLocal = (
  dateString: string,
  format: string = "MMM D, YYYY"
) => {
  return moment.utc(dateString).format(format);
};

export const dateToFormatString = (
  date: Date,
  format: string = "YYYY-MM-DD"
) => {
  return moment(date).format(format);
};

export const dateToFormatStringISO8601 = (date: Date) => {
  return moment(date).format();
};

export const getDifferenceWithCurrentDate = (dateString: string) => {
  return moment(dateString).fromNow();
};

export const getDifferenceWithTime = (dateString?: string) => {
  const date = moment(dateString);
  return `${date.fromNow()} ${date.format("h:mm a")}`;
};

export const sortDates = (dateA?: string, dateB?: string) => {
  if (!dateA) return 1;
  if (!dateB) return -1;

  const closestADate = moment(dateA);
  const closestBDate = moment(dateB);

  return closestADate.isBefore(closestBDate)
    ? -1
    : closestADate.isAfter(closestBDate)
    ? 1
    : 0;
};

export const secondsToHms = (d:number) => {     
  let h = Math.floor(d / 3600);     
  let m = Math.floor(d % 3600 / 60);     
  let s = Math.floor(d % 3600 % 60);      
  let hDisplay = h > 0 ? h + (h == 1 ? " hr" : " hrs") + (m > 0 || s > 0 ? ", ":"") : "";     
  let mDisplay = m > 0 ? m + (m == 1 ? " min" : " mins") + (s > 0 ? ", ":"") : "";     
  let sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";       
  return hDisplay + mDisplay + sDisplay; 
}
