import React, { FunctionComponent, useMemo } from "react";
import Joyride, { CallBackProps, Step } from "react-joyride";
import { ApplicationState } from "../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { markSidebarPositionTourAsShown } from "../../../../store/tour/actions";
import { connect } from "react-redux";
import usePrevProps from "../../../../utils/hooks/usePrevProps";

type StateProps = {
  sidebarPositionTourShown?: boolean;
  showTopBar?: boolean;
};
type DispatchProps = {
  markSidebarPositionTourAsShown: () => any;
};

type Props = StateProps & DispatchProps;

const SidebarPositionTour: FunctionComponent<Props> = (props) => {
  const steps: Step[] = [
    {
      disableBeacon: true,
      target: ".pageBtn",
      content: (
        <>
          Your Main Menu is now up here. You switch pages by clicking this menu
          and switch data periods to the right.
        </>
      ),
    },
  ];

  const prevProps = usePrevProps({
    showTopBar: props.showTopBar,
  });

  const handleTourChange = (data: CallBackProps) => {
    if (["skip", "reset"].includes(data.action)) {
      props.markSidebarPositionTourAsShown();
    }
  };

  const show = useMemo(() => {
    return !!(
      !props.sidebarPositionTourShown &&
      prevProps &&
      !prevProps.showTopBar &&
      props.showTopBar
    );
  }, [props.showTopBar, prevProps?.showTopBar, props.sidebarPositionTourShown]);

  return (
    <Joyride
      disableScrolling
      steps={steps}
      floaterProps={{ disableAnimation: true }}
      showSkipButton
      callback={handleTourChange}
      run={show}
      styles={{
        options: {
          primaryColor: "#00265B",
        },
      }}
    />
  );
};

const mapStateToProps = ({ tour, help }: ApplicationState): StateProps => {
  return {
    sidebarPositionTourShown: tour.sidebarPositionTourShown,
    showTopBar: help.showTopBar,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      markSidebarPositionTourAsShown: markSidebarPositionTourAsShown,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarPositionTour);
