import React, { FunctionComponent, useMemo } from "react";

import {
  ChecklistItem,
  CommonSteps,
  Intervention,
  InterventionGroup,
} from "../../../../../../../../../store/onboarding/cases/types";
import { OverlayTrigger, Popover, Table } from "react-bootstrap";
import FidelityValueSpan from "./FidelityValueSpan";
import InterventionStepRow from "./InterventionStepRow";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../../../store";
import { UserInfo } from "../../../../../../../../../store/auth/types";
import { getFirstName, getFullName } from "../../../../../../../../../utils/NamesUtils";
import { DataFilterState } from "../../../../../../../../../store/dataFilter/types";


type OwnProps = {
  stats: {
    intervention: Intervention;
    loggedTimes: number;
    teachersIds: number[];
    groupsIds: number[];
    totalFidelity: number | string;
    checklist_items: (ChecklistItem & {
      loggedTimes: number;
      notApplicableTimes?: number;
      usedTimes?: number;
    })[];
    implChecks?: number;
  }
  selectedInterventionGroup?: InterventionGroup;
};

type Props = OwnProps;

const InterventionStatsTable: FunctionComponent<Props> = (props) => {
  const { stats, selectedInterventionGroup } = props;

  const educator = useSelector<ApplicationState, UserInfo | undefined>(
    (s) => s.onboarding.selectedEducator
  );

  const overviewModelType = useSelector<ApplicationState, String | undefined>(
    (s) => s.groups.overviewModelType
  );

  const selectedGroup = useSelector(
    (s: ApplicationState) => s.cases.selectedInterventionGroup
  );
  
  // let interventionGroups = useSelector<ApplicationState, InterventionGroup[]>(
  //   (s) => s.cases.interventionGroups
  // );

//   const { showArchivedGroups } = useSelector<
//   ApplicationState,
//   DataFilterState
// >((s) => s.dataFilter);
// if(educator) interventionGroups = interventionGroups.filter((data, index) => data.teacher_assignment?.user.id == educator?.id);

// let uniqueIntervention: any[] = [];
// for (let i = 0; i < interventionGroups.length; i++) {
//   if(interventionGroups[i].finished && interventionGroups[i].interventions.length > 0){
//     interventionGroups[i].interventions.forEach((data, index)=> {
//       if(uniqueIntervention.indexOf(data.id) == -1){
//         uniqueIntervention.push(data);
//       }
//     })
//   }
// }


// let interventions:any = {};
// for (let ui = 0; ui < uniqueIntervention.length; ui++) {
//   let groupData = [];
//   let teachersData = [];
//   for (let j = 0; j < interventionGroups.length; j++) {
//     if(interventionGroups[j].finished && interventionGroups[j].interventions.length > 0){
//       let groups = interventionGroups[j].interventions.filter((int)=> int.id == uniqueIntervention[ui].id);
//       if(groups.length > 0) {
//         groupData.push(groups[0]);
//         teachersData.push(interventionGroups[j].teacher_assignment?.user)
//       } 
//     }
//   }
//   interventions[uniqueIntervention[ui].id] = {
//     'groups':groupData,
//     'teachers': Array.from(new Map(teachersData.map((item:any) => [item["id"], item])).values())
//   }
// }
// let interventionCount = stats.intervention !== undefined ?  interventions[stats.intervention!.id] : {};

// if((overviewModelType == 'all_group') || (overviewModelType == 'one_educator')) {
//   stats.groupsIds = (interventionCount != undefined) && Object.keys(interventionCount).length > 0 ? interventionCount.groups : 0;
//   stats.teachersIds = (interventionCount != undefined) && Object.keys(interventionCount).length > 0 ? interventionCount.teachers : 0;
// } 

 const groupsCountTitle = useMemo(() => {
   if(overviewModelType) {
     const gCount = stats.groupsIds.length;
      return gCount !== undefined
        ? `${gCount} group${(gCount > 1 ? "s": "")} ${(overviewModelType == 'all_group' ? " across " : "")}`
        : "";
   }
  }, [stats.groupsIds]);

  const teachersCountTitle = useMemo(() => {
    if(overviewModelType) {
    const tCount = stats.teachersIds.length;
    return tCount !== undefined && overviewModelType !== 'one_educator'
      ? `${tCount} teacher${tCount !== 1 ? "s" : ""}`
      : "";
    }
  }, [stats.teachersIds]);

  const params = useMemo(() => {
    if (stats.intervention) {
      return {
        title: stats.intervention.name,
        groupName: selectedGroup?.name,
        fidelity: stats.totalFidelity,
        loggedTimes: `Logged ${stats.loggedTimes} time${
          stats.loggedTimes === 1 ? "" : "s"
        }${
          ((overviewModelType !== 'one_group')) && stats.loggedTimes
            ? ` (${groupsCountTitle}${
                groupsCountTitle ? " " : ""
              }${teachersCountTitle})`
            : ""
        }`,
      };
    }

    return {
      title: "Common Research-Based Instructional Components",
      fidelity: null,
      loggedTimes: `Logged ${stats.loggedTimes} time${
        stats.loggedTimes === 1 ? "" : "s"
      }${
        stats.loggedTimes
          ? ` (${groupsCountTitle}${
              groupsCountTitle ? " " : ""
            }${teachersCountTitle})`
          : ""
      }`,
    };
  }, [stats, groupsCountTitle, teachersCountTitle]);

  

  let popOverMsg = '';

  if(overviewModelType === 'all_group') {
    popOverMsg = `These statistics are for ${params.title}. You are viewing an
    overview across teachers and groups.`;
  
  } else if(overviewModelType === 'one_educator') {
    if(params.title !== 'Common Research-Based Instructional Components') {
      popOverMsg = `These statistics are for ${params.title.toLocaleLowerCase()}. 
      You are viewing an overview of all ${getFullName(educator)+"'s"} groups.`;
    } else {
      popOverMsg = `These statistics are for ${params.title.toLocaleLowerCase()}. 
      You are viewing an overview across interventions and groups for this teacher.`;
    }
  
  } else if(overviewModelType === 'one_group') {
    popOverMsg = `These statistics are for ${params.title}. 
    You are viewing fidelity statistics for ${params.groupName}.`;
  }


  return (
    <div>
      <div className="d-flex justify-content-between align-items-start mb-2">
        <OverlayTrigger
          overlay={
            <Popover id="overall-fidelity-popover">
              <Popover.Content>
                {popOverMsg}
              </Popover.Content>
            </Popover>
          }
        >
          <div>
            <h3>{params.title}</h3>
            {(params.title !== 'Common Research-Based Instructional Components') && <p className="mb-0">{params.loggedTimes}</p>}
          </div>
        </OverlayTrigger>
        <FidelityValueSpan
          fidelity={params.fidelity}
          style={{ fontSize: "x-large" }}
        />
      </div>

      <Table bordered responsive className="goalTable">
        <thead>
          <tr>
            <th>Intervention Step</th>
            <th className="text-center">Fidelity</th>
          </tr>
        </thead>
        <tbody>
          {stats.checklist_items.length ? (
            (stats.intervention !== undefined) ? 
              stats.checklist_items.map((ci, index) => (
                <InterventionStepRow
                  key={index}
                  checklistItem={ci}
                  index={index}
                  loggedTimes={stats.implChecks}
                />
              ))
            :
            CommonSteps.map((step) => 
              stats.checklist_items.map((ci, index) => (
                (step.value == ci.title) && <InterventionStepRow
                  key={index}
                  checklistItem={ci}
                  index={index}
                  loggedTimes={stats.implChecks}
                  selectedInterventionGroup = {selectedInterventionGroup}
                />
              ))
            )
          ) : (
            <tr>
              <td colSpan={2}>No Data</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default InterventionStatsTable;
