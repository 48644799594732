import React, { FunctionComponent, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import { ProgressOverview } from "../../../../../../store/onboarding/meeting-module/types";

type OwnProps = {
  progressOverview: ProgressOverview | undefined;
  averageFidelity?: number;

};

type Props = OwnProps;

const AverageFidelity: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const { progressOverview, averageFidelity } = props;

  const formatDecimal = (num?: number) => {
    if (num) {
      return num.toFixed(0).replace(".00", "");
    } else {
      return "0";
    }
  };
  const {
    showFidelityInfo,
    minFidelity,
    maxFidelity,
    avgFidelity,
  } = useMemo(() => {
    let showFidelityInfo = false;
    let minFidelity: number | undefined = undefined;
    let maxFidelity: number | undefined = undefined;
    let avgFidelity: number = 0;

    if (progressOverview) {
      const loggedInterventions = progressOverview.implementation_checks;
      if (loggedInterventions.length > 0) {
        let fidelityTotal = 0;

        loggedInterventions.forEach((li) => {
          minFidelity = Math.min(minFidelity || li.fidelity, li.fidelity);
          maxFidelity = Math.max(maxFidelity || li.fidelity, li.fidelity);
          fidelityTotal += li.fidelity;
        });

        avgFidelity = fidelityTotal / loggedInterventions.length;
        showFidelityInfo = true;
      }
    }

    return {
      showFidelityInfo,
      minFidelity,
      maxFidelity,
      avgFidelity,
    };
  }, [progressOverview]);

  if (!showFidelityInfo) {
    return null;
  }

  return (
    <div className="fidelityContainer">
      <div>{formatDecimal(averageFidelity ?? avgFidelity)}%</div>
      <div>
        {intl.formatMessage({
          id:
            "app.dashboard.yourAssigmentTab.studentGroupItem.averageFidelityTitle",
        })}
      </div>
      {minFidelity !== maxFidelity && (
        <div>
          <FontAwesomeIcon icon={faLongArrowAltLeft} />
          <FontAwesomeIcon icon={faLongArrowAltRight} />
          {formatDecimal(minFidelity)}% - {formatDecimal(maxFidelity)}%
        </div>
      )}
    </div>
  );
};

export default AverageFidelity;
