import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { School } from "../../../../../store/onboarding/types";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  hideMergeSchoolsModal,
  mergeSchools,
} from "../../../../../store/onboarding/actions";
import { toastr } from "react-redux-toastr";
import { Spinner } from "react-bootstrap";
import { USState } from "../../../States";

type PropsFromState = {
  showModal: boolean;
  schoolToMerge?: School;
  schools: School[];
  isLoading: boolean;
  errors?: string;
};

type DispatchProps = {
  mergeSchools: (keep: number, merge: number) => any;
  hideMergeSchoolsModal: () => any;
};

type Props = PropsFromState & DispatchProps;

const MergeSchoolsModal = (props: Props) => {
  const { schools, schoolToMerge, isLoading, errors } = props;
  const [schoolToKeep, setSchool] = useState<number>();

  useEffect(() => {
    if (!isLoading) {
      if (errors) {
        toastr.error("Error", errors);
      } else {
        props.hideMergeSchoolsModal();
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (props.showModal) {
      setSchool(undefined);
    }
  }, [props.showModal]);

  const handleSubmitMerge = () => {
    if (schoolToKeep && schoolToMerge && !isLoading) {
      props.mergeSchools(schoolToKeep, schoolToMerge.id);
    }
  };

  return (
    <Modal
      show={props.showModal}
      onHide={props.hideMergeSchoolsModal}
      animation={false}
      backdropClassName={"customDarkModalBackdrop in"}
    >
      <Modal.Header closeButton className="orangeModalHeader">
        <Modal.Title>
          Merge{" "}
          <span className="font-weight-bold">
            {schoolToMerge ? schoolToMerge.name : ""}
          </span>{" "}
          School
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <h3 className="mb-2">
            Select the school with which you want to merge the selected school.
          </h3>
          <Select
            key={schoolToKeep}
            isClearable
            className="dataFiltersSelect"
            value={schools.find((x) => schoolToKeep === x.id)}
            options={schools}
            getOptionLabel={(school: School) => school.name}
            getOptionValue={(school: School) => school.id.toString()}
            onChange={(school: ValueType<School, false>) =>
              setSchool(school ? (school as School).id : undefined)
            }
          />
        </div>
        <div className="d-flex justify-content-center">
          <button
            disabled={isLoading}
            className="blueBtnMd mr-2"
            onClick={props.hideMergeSchoolsModal}
          >
            Cancel
          </button>
          <button
            disabled={!schoolToKeep}
            className="blueBtnMd"
            onClick={handleSubmitMerge}
          >
            Merge {isLoading && <Spinner animation="border" size="sm" />}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    showModal: onboarding.modalsState.mergeSchoolsModal,
    schoolToMerge: onboarding.selectedSchool,
    schools: onboarding.schools,
    isLoading: onboarding.isLoading.mergeSchools,
    errors: onboarding.errors.mergeSchools,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      mergeSchools: mergeSchools,
      hideMergeSchoolsModal: hideMergeSchoolsModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MergeSchoolsModal);
