import React, { FunctionComponent, useMemo } from "react";
import Select, { ValueType } from "react-select";
import {
  EvidencePeriodData,
  GradeLevels,
  ReactSelectOption,
} from "../../../../../../../store/onboarding/types";
import { Grade } from "../../../../../../../store/groups/types";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { changeDataGradesFilter } from "../../../../../../../store/dataFilter/actions";
import { connect, useSelector } from "react-redux";
import { USState } from "../../../../../States";

type StateProps = {
  dataGradesFilter: Grade[];
};

type DispatchProps = {
  changeDataGradesFilter: (grade?: Grade[]) => any;
};

type Props = StateProps & DispatchProps;

const GradeFilter: FunctionComponent<Props> = ({
  dataGradesFilter,
  changeDataGradesFilter,
}) => {
  const evidencePeriodData = useSelector<
    ApplicationState,
    EvidencePeriodData
  >((s) => s.onboarding.evidencePeriodData!);

  const gradesByCurrentDataPeriod: any[] = useMemo(() => 
    evidencePeriodData?.student_rows.map((std) => std?.grade || [])
  ,[evidencePeriodData])

  const gradeOptions = useMemo(() => {
    return GradeLevels.filter((grade) => gradesByCurrentDataPeriod.some((dataPeriodGrade) => grade.value == dataPeriodGrade))
  }, [gradesByCurrentDataPeriod, GradeLevels])

  const handleGradeLevelChange = (
    values: ValueType<ReactSelectOption<Grade>, true>
  ) => {
    if (values && (values as ReactSelectOption<Grade>[]).length > 0) {
      changeDataGradesFilter(
        (values as ReactSelectOption<Grade>[]).map((v) => v.value)
      );
    } else {
      changeDataGradesFilter();
    }
  };

  return (
    <>
      <h4 className="font-weight-bold m-0">Grade Level(s)</h4>
      <Select
        isMulti
        isClearable
        styles={{ menu: (styles) => ({ ...styles, zIndex: 100 }) }}
        value={gradeOptions.filter(
          (gl) => dataGradesFilter && dataGradesFilter.includes(gl.value)
        )}
        options={gradeOptions}
        onChange={handleGradeLevelChange}
      />
    </>
  );
};

const mapStateToProps = ({ dataFilter }: ApplicationState): StateProps => {
  return {
    dataGradesFilter: dataFilter.dataGradesFilter,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      changeDataGradesFilter: changeDataGradesFilter,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GradeFilter);
