import React, { FunctionComponent, useMemo } from "react";
import { Modal, Table } from "react-bootstrap";
import { useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { hidePermissionSettingModal } from "../../../store/help/actions";
import { ApplicationState } from "../../../store";
import { CoachDashboardTabKeys } from "../../pages/coach-dashboard/CoachDashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { openUploadStudentDataModal } from "../../../store/onboarding/actions";

type OwnProps = {};

type Props = OwnProps;

const PermissionSettingModal: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const show = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.help.modalsState.permissionSettingModal
  );

  const isGlobal =  useSelector<ApplicationState, boolean | undefined>(
    (s) => s.help.isGlobal
  );

  const permissionSetting = useSelector(
    (s:ApplicationState) => s.auth.userInfo
  );

  const onClose = () => {
    dispatch(hidePermissionSettingModal());
    if(!isGlobal) {
      dispatch(openUploadStudentDataModal());
    }
  };

  const permissionMessage = useMemo(()=>{
    let permissionMsg;
    if(!permissionSetting?.settings_present) {
      permissionMsg = 'You do not have any permissions set. Please contact your district administrator.';
    } else if(permissionSetting?.block_access_to_all_data) {
      permissionMsg = 'You do not have access to data. Please contact your district administrator.';
    }
    return permissionMsg;
  },[permissionSetting])

  return (
    <Modal
      show={show}
      onHide={onClose}
      animation={false}
      size="lg"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header className="purpleModalHeader">
        <Modal.Title>Permission Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2>{!isGlobal ? 'Please upload your data as per your permissions below' : ''}</h2>
        <Table bordered striped>
          {permissionMessage ?
            <tr>
              <td colSpan={2} className="font-weight-bold">{permissionMessage}</td>
            </tr>
          :
          <>
            <tr>
              <td>You can edit benchmarks</td>
              <td width={450}>{(permissionSetting!.allowed_edit_benchmarks !== undefined) && permissionSetting!.allowed_edit_benchmarks ? <FontAwesomeIcon icon={faCheckCircle} style={{color: "green"}}/> : <FontAwesomeIcon icon={faTimesCircle} style={{color: 'red'}} />}</td>
            </tr>
            {(permissionSetting!.share_all_data !== undefined) && permissionSetting!.share_all_data ?
            <tr>
              <td>You can access all data</td>
              <td width={450}>{(permissionSetting!.share_all_data !== undefined) && permissionSetting!.share_all_data ? <FontAwesomeIcon icon={faCheckCircle} style={{color: "green"}}/> : <FontAwesomeIcon icon={faTimesCircle} style={{color: 'red'}} />}</td>
            </tr>
            :
              <>
                <tr>
                  <td>You have access of these grades</td>
                  <td width={450}>{(permissionSetting?.allowed_grades !== undefined) && permissionSetting!.allowed_grades.length ? permissionSetting!.allowed_grades.join(', ') :  'ALL'}</td>
                </tr>
                <tr>
                  <td>You can see the students that don't have an assigned grade</td>
                  <td width={450}>{(permissionSetting!.allowed_no_grade !== undefined) && permissionSetting!.allowed_no_grade ? <FontAwesomeIcon icon={faCheckCircle} style={{color: "green"}}/> : <FontAwesomeIcon icon={faTimesCircle} style={{color: 'red'}} />}</td>
                </tr>
                <tr>
                  <td>You have access of these teachers</td>
                  <td width={450}>{(permissionSetting!.allowed_assigned_teachers !== undefined) && permissionSetting!.allowed_assigned_teachers.length ? permissionSetting!.allowed_assigned_teachers.join(', ') : 'ALL'}</td>
                </tr>
                <tr>
                  <td>You can see the students that don't have an assigned teachers</td>
                  <td width={450}>{(permissionSetting!.allowed_no_teacher !== undefined) && permissionSetting!.allowed_no_teacher ? <FontAwesomeIcon icon={faCheckCircle} style={{color: "green"}}/> : <FontAwesomeIcon icon={faTimesCircle} style={{color: 'red'}} />}</td>
                </tr>
                <tr>
                  <td>You have access of these schools</td>
                  <td width={450}>{(permissionSetting!.allowed_schools !== undefined) && permissionSetting!.allowed_schools.length ? permissionSetting!.allowed_schools.join(', ') : 'ALL'}</td>
                </tr>
              </>
            }
          </>
          }
        </Table>
        <div className="modalActions">
          <div />
          <button
            className="blueBtnSm"
            type="button"
            onClick={onClose}
          >
            {isGlobal ? 'close' : 'I understand'}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PermissionSettingModal;
