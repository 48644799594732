import React from "react";
import { Button } from "react-bootstrap";
import { bindActionCreators, Dispatch } from "redux";
import {
  openStudentInviteIndividuallyModal,
  openUploadStudentsRosterModal,
} from "../../../../../store/onboarding/actions";
import { connect } from "react-redux";

type DispatchProps = {
  openUploadStudentsRosterModal: () => any;
  openStudentInviteIndividuallyModal: () => any;
};

type Props = DispatchProps;

export const StudentsInvitePanel = (props: Props) => {
  return (
    <div className="onboardingSecondStepEmptyContainer">
      <h2 className="font-weight-bold">Would you like to add students?</h2>
      <br />
      <h3>
        Once you have students rostered, you can start uploading data for them.
      </h3>
      <br />
      <h3>
        You can add them directly to a teacher and/or class, or upload a roster
        and add them to classes later.
      </h3>
      <br />
      <Button
        className="blueBtnXL"
        bsPrefix="b"
        onClick={props.openStudentInviteIndividuallyModal}
      >
        Add Individually
      </Button>{" "}
      &nbsp;
      <Button
        className="blueBtnXL"
        bsPrefix="b"
        onClick={props.openUploadStudentsRosterModal}
      >
        Upload Roster
      </Button>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openUploadStudentsRosterModal: openUploadStudentsRosterModal,
      openStudentInviteIndividuallyModal: openStudentInviteIndividuallyModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(StudentsInvitePanel);
