import {
  UserAssignmentRoleDisplayedNames,
  UserInfo,
} from "../../../store/auth/types";
import { Prompt, RouteComponentProps } from "react-router";
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { Container, Nav, Tab } from "react-bootstrap";
import { ApplicationState } from "../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { push } from "connected-react-router";
import { connect, useDispatch, useSelector } from "react-redux";
import UserAvatarSmall from "../../common/UserAvatarSmall";
import YourAssigmentTab from "./tabs/your-assigments/YourAssigmentsTab";
import LoadingIndicator from "../../common/onboarding/LoadingIndicator";
import FbaDashboard from "./tabs/fba/FbaDashboard";
import InterventionLibraryTab from "./tabs/explore-and-learn/intervention-library/InterventionLibraryTab";
import YourDataAndInterviewsTab from "./tabs/your-data-and-interviews/YourDataAndInterviewsTab";
import RostersTab, {
  SecondStepTabKeys,
} from "../../common/onboarding/second-step/rosters-tab/RostersTab";
import TeacherDetailsModal from "../../common/onboarding/second-step/teacher-details/TeacherDetailsModal";
import BadgesTab from "../badges/BadgesTab";
import ProfileTab from "../../common/reading-interventions/management/profile-tab/ProfileTab";
import NotificationsTab from "../../common/reading-interventions/management/notifications-tab/NotificationsTab";
import EditProfileModal from "../../common/reading-interventions/management/profile-tab/modals/EditProfileModal";
import ChangePasswordModal from "../../common/reading-interventions/management/profile-tab/modals/ChangePasswordModal";
import ConfirmRecommendModal from "../../common/reading-interventions/intervention-tools/data-tab/modals/ConfirmRecommendModal";
import { DataPeriod } from "../../../store/onboarding/types";
import ShareDataModal from "../../common/reading-interventions/educators-and-sharing/modals/ShareDataModal";
import { getNotFinishedGroups } from "../../../store/onboarding/cases/actions";
import ConfirmDiagnosisModal from "../../common/reading-interventions/intervention-tools/data-tab/modals/ConfirmDiagnosisModal";
import DashboardMenuPanel from "./DashboardMenuPanel";
import TopBar from "../../common/top-bar/TopBar";
import ThreeStepGuide from "../../common/onboarding/third-step/group-students/three-step-guide/ThreeStepGuide";
import SidebarPositionTour from "./tours/SidebarPositionTour";
import ParentsTab from "../../common/onboarding/second-step/parents-tab/ParentsTab";
import TutorialVideosPage from "../tutorial_videos/TutorialVideosPage";
import useActiveTab from "../parent-dashboard/useActiveTab";
import NewAchievementsModal from "../badges/NewAchievementsModal";
import useUserRole from "../../../utils/hooks/useUserRole";
import ManageDataPeriodsModal from "./tabs/your-data-and-interviews/manage-data-periods/ManageDataPeriodsModal";
import CreateNewInterventionModal from "./tabs/explore-and-learn/intervention-library/new-intervention-modal/NewInterventionModal";
import NewSetGoalsModal from "../../common/onboarding/new-set-goals-modal/NewSetGoalsModal";
import InterventionsBriefModal from "./tabs/your-assigments/InterventionsResourcesModal";
import SupportImplFeedbackModal from "./tabs/your-assigments/support/SupportImplFeedbackModal";
import ProvidingFeedbackModal from "./tabs/your-assigments/feedback/ProvidingFeedbackModal";
import ImplementationCheckModal from "./tabs/your-assigments/implementation-check/ImplementationCheckModal";
import GoalProgressModal from "./tabs/your-assigments/goal-progress/GoalProgressModal";
import ModifyInterventionModal from "./tabs/your-assigments/ModifyInterventionModal";
import EndInterventionModal from "./tabs/your-assigments/end-intervention/EndInterventionModal";
import GroupInterventionsModal from "./tabs/your-assigments/group-interventions/GroupInterventionsModal";
import InterventionFidelityCheckModal from "./tabs/your-assigments/implementation-check/InterventionFidelityCheckModal";
import FindInterventionModal from "../../common/onboarding/third-step/find-intervention/FindInterventionModal";
import InterventionLibraryModal from "../../common/onboarding/third-step/find-intervention/InterventionLibraryModal";
import SetGoalChooseConcernModal from "../../common/onboarding/third-step/set-goal-modal/SetGoalChooseConcernModal";
import ConductInterviewModal from "../../common/onboarding/second-step/evidence-tab/conduct-interview/ConductInterviewModal";
import SpellingTestGroupsTab from "../spelling-test/SpellingTestGroupsTab";
import SpellingTestGroupUpsertModal from "../spelling-test/spelling-test-group-upsert-modal/SpellingTestGroupUpsertModal";
import SpellingTestSummaryModal from "../spelling-test/spelling-test-summary-modal/SpellingTestSummaryModal";
import ConfirmDialog from "../../common/confirm-dialog/ConfirmDialog";
import ClassWideNeedsModal from "./tabs/your-data-and-interviews/class-wide-needs-modal/ClassWideNeedsModal";
import PageHelperModal from "../../common/page-help/PageHelperModal";
import EquityBenchmarksModal from "./tabs/your-data-and-interviews/equity-benchmarks-modal/EquityBenchmarksModal";
import PermissionSettingModal from "../../common/permission-setting/PermissionSettingModal";
import CoachTeacherRelationModal from "../../common/reading-interventions/educators-and-sharing/modals/CoachTeacherRelationModal";
import LatestReleaseModal from "../../common/latest-release/LatestReleaseModal";
import { GroupRecommendation } from "../../../store/onboarding/cases/types";
import useRecommendationGroupCards from "../../common/reading-interventions/intervention-tools/recommend-groups/hooks/useRecommendationGroupCards";
import { isUrlBlocked } from "../../../store/onboarding/actions";
import ConfirmSecondaryGoalCreateModal from "../../common/reading-interventions/intervention-tools/groups-tab/modals/ConfirmSecondaryGoalCreateModal";
import ReleaseNotesTab from "../release-notes/ReleaseNotesTab";
import { EventKey } from "react-bootstrap/esm/types";

export enum CoachDashboardTabKeys {
  YOUR_ASSIGNMENTS = "groups",
  YOUR_BEHAVIOUR_ASSIGNMENTS = "groups/behavior",
  YOUR_READING_ASSIGNMENTS = "groups/reading",
  GROUPS_IN_PROGRESS = "groups/in-progress",
  YOUR_DATA_AND_INTERVIEWS = "data",
  ROSTERS_STUDENTS = "students",
  ROSTERS_TEACHERS = "teachers",
  FBAs = "fba",
  BADGES = "badges",
  INTERVENTION_LIBRARY = "interventions",
  TUTORIAL_VIDEOS = "tutorials/videos",
  RELEASE_NOTES = "release-notes",
  //RELEASE_NOTES_DETAIL = "release-notes-detail",
  YOUR_PROFILE = "profile",
  NOTIFICATIONS = "settings/notifications",
  PARENTS = "parents",
  SPELLING_TEST = "spelling-test",
}

type DispatchProps = {
  push: (location: string) => any;
};

type PropsFromState = {
  userInfo?: UserInfo;
  currentDataPeriod?: DataPeriod;
  showTopBar?: boolean;
  showClassWideNeedsModal?: boolean;
};

type MatchParams = {
  tabName: string;
  id?: string;
  groupDisplayed?:string;
  isBehavior?: string;
  releaseNoteId?: string;
};

type Props = PropsFromState &
  RouteComponentProps<MatchParams> &
  DispatchProps & {
    getUserInfo: () => any;
  };

const CoachDashboard: FunctionComponent<Props> = ({
  userInfo,
  showTopBar,
  showClassWideNeedsModal,
  match: {
    params: { tabName: activeTab, id, groupDisplayed },
  },
  currentDataPeriod,
  push,
}) => {
  const dispatch = useDispatch();
  const tabNameFromReducer = useSelector<
    ApplicationState
  >((s) => s.cases.tabName);

  const tabName = useMemo(() => {
   if((activeTab.includes('groups/behavior')) 
    || (activeTab.includes('groups/reading'))
    || (activeTab.includes('release-notes'))
  ) {
      return activeTab
    }
    return tabNameFromReducer;
  },[activeTab])

//   const displayGroupChange = useSelector<
//   ApplicationState
// >((s) => s.cases.displayGroupName);

  const { changeActiveTab } = useActiveTab();
  const { isCoach, isTeacher } = useUserRole();

 

  // useEffect(() => {
  //   //to display a count in the side menu //TODO maybe not only for coach
  //   if (isCoach) {
  //     dispatch(getNotFinishedGroups());
  //   }
  // }, [isCoach]);

  //todo

  const firstDataPeriodUpdate = useRef(true);
  useEffect(() => {
    if (firstDataPeriodUpdate.current && currentDataPeriod !== undefined) {
      firstDataPeriodUpdate.current = false;
      return;
    }
    if (currentDataPeriod) {
      push(`/data/${currentDataPeriod.id}`);
    }
  }, [currentDataPeriod]);

  const {
    recommendedGroups
  } = useRecommendationGroupCards();


  const recommendedGroupsFromAPI = useSelector<
  ApplicationState,
  GroupRecommendation[]
  >((s) => s.cases.recommendedGroupsFromAPI);


   useEffect(() => {
    let originalStdArray: any[] = [];
    let modifiedStdArray: any[] = [];
    for (let i = 0; i < recommendedGroupsFromAPI.length; i++) {
      const originalGrp:any = recommendedGroupsFromAPI[i];
      let originalObj = {}
      for (const key in originalGrp) {
        if(key !== 'focuses') {
          originalObj = {
            ...originalObj,
            [key] : originalGrp[key]
          }
        }
      }
      originalStdArray.push(originalObj)
      
    }

    for (let j = 0; j < recommendedGroups.length; j++) {
      const modifiedGrp:any = recommendedGroups[j];
      let modifiedObj = {}
      for (const key in modifiedGrp) {
        if(key !== 'focuses') {
          modifiedObj = {
            ...modifiedObj,
            [key] : modifiedGrp[key]
          }
        }
      }
      modifiedStdArray.push(modifiedObj)
    }

    let groupModified = JSON.stringify(originalStdArray) !== JSON.stringify(modifiedStdArray);
    
    groupModified ? dispatch(isUrlBlocked(true)) : dispatch(isUrlBlocked(false));
  },[recommendedGroupsFromAPI, recommendedGroups]);
  

  return (
    <>
      {showTopBar && <TopBar />}
      <Container fluid>
        <Tab.Container
          defaultActiveKey={CoachDashboardTabKeys.YOUR_DATA_AND_INTERVIEWS}
          activeKey={activeTab as EventKey}
          onSelect={changeActiveTab}
        >
          <div className="d-flex" id="user-dashboard">
            {!showTopBar && (
              <div
                className="coachDashboardSidebar mr-3"
                id="user-dashboard-sidebar"
              >
                {userInfo ? (
                  <div className="coachDashboardUserInfo">
                    <UserAvatarSmall size={60} userInfo={userInfo} />
                    <h3>{`${userInfo.first_name} ${userInfo.last_name}`}</h3>
                    <h4 className="p-0">
                      {userInfo.profile.custom_role_title ??
                        UserAssignmentRoleDisplayedNames[
                          userInfo.profile.current_role
                        ]}
                    </h4>
                  </div>
                ) : (
                  <LoadingIndicator />
                )}
                <hr />
                <Nav className="flex-column justify-content-start">
                  <DashboardMenuPanel activeTab={activeTab} />
                </Nav>
              </div>
            )}

            <div className="coachDashboardContentContainer">
              {isCoach && <ThreeStepGuide />}

              <Tab.Content>
                <Tab.Pane eventKey={CoachDashboardTabKeys.YOUR_BEHAVIOUR_ASSIGNMENTS}>
                  {tabName == 'groups/behavior' ? 
                  <>
                    <YourAssigmentTab />
                    <ConfirmSecondaryGoalCreateModal />
                  </>
                 : ''}
                </Tab.Pane>
                <Tab.Pane eventKey={CoachDashboardTabKeys.YOUR_READING_ASSIGNMENTS}>
                  {tabName == 'groups/reading' ? 
                  <>
                    <YourAssigmentTab />
                    <ConfirmSecondaryGoalCreateModal />
                  </> : ''}
                </Tab.Pane>     
                <Tab.Pane
                  eventKey={CoachDashboardTabKeys.YOUR_DATA_AND_INTERVIEWS}
                >
                  <YourDataAndInterviewsTab />
                  {showClassWideNeedsModal && 
                  <ClassWideNeedsModal /> } 
                  <ConfirmRecommendModal />
                  <ConfirmDiagnosisModal />
                </Tab.Pane>

                <Tab.Pane
                  mountOnEnter
                  unmountOnExit
                  eventKey={CoachDashboardTabKeys.INTERVENTION_LIBRARY}
                >
                  <InterventionLibraryTab />
                </Tab.Pane>

                <Tab.Pane
                  mountOnEnter
                  unmountOnExit
                  eventKey={CoachDashboardTabKeys.TUTORIAL_VIDEOS}
                >
                  <TutorialVideosPage />
                </Tab.Pane>
                <Tab.Pane
                  mountOnEnter
                  unmountOnExit
                  eventKey={CoachDashboardTabKeys.RELEASE_NOTES}
                >
                  <ReleaseNotesTab />
                  
                </Tab.Pane>
                {isCoach && (
                  <>
                    <Tab.Pane
                      mountOnEnter
                      unmountOnExit
                      eventKey={CoachDashboardTabKeys.FBAs}
                    >
                      <FbaDashboard />
                    </Tab.Pane>
                    <Tab.Pane
                      mountOnEnter
                      unmountOnExit
                      eventKey={CoachDashboardTabKeys.BADGES}
                    >
                      <BadgesTab />
                    </Tab.Pane>
                    <Tab.Pane
                      active={
                        activeTab === CoachDashboardTabKeys.ROSTERS_STUDENTS ||
                        activeTab === CoachDashboardTabKeys.ROSTERS_TEACHERS
                      }
                    >
                      <RostersTab
                        activeKey={activeTab as SecondStepTabKeys}
                        id={id}
                      />
                      <TeacherDetailsModal />
                      <ShareDataModal />
                      <CoachTeacherRelationModal />
                    </Tab.Pane>
                    <Tab.Pane
                      active={activeTab === CoachDashboardTabKeys.PARENTS}
                    >
                      <ParentsTab />
                    </Tab.Pane>
                  </>
                )}
                {isTeacher && (
                  <>
                    <Tab.Pane
                      active={
                        activeTab === CoachDashboardTabKeys.ROSTERS_STUDENTS
                      }
                    >
                      <RostersTab
                        activeKey={activeTab as SecondStepTabKeys}
                        id={id}
                      />
                      <TeacherDetailsModal />
                      <ShareDataModal />
                    </Tab.Pane>
                  </>
                )}
                <Tab.Pane eventKey={CoachDashboardTabKeys.YOUR_PROFILE}>
                  <ProfileTab />

                  <ChangePasswordModal />
                  <EditProfileModal />
                </Tab.Pane>

                <Tab.Pane
                  mountOnEnter
                  unmountOnExit
                  eventKey={CoachDashboardTabKeys.NOTIFICATIONS}
                >
                  <NotificationsTab />
                </Tab.Pane>

                <Tab.Pane
                  mountOnEnter
                  unmountOnExit
                  eventKey={CoachDashboardTabKeys.SPELLING_TEST}
                >
                  <SpellingTestGroupsTab />
                </Tab.Pane>

                {/* <Tab.Pane eventKey={CoachDashboardTabKeys.SETTINGS}>
                <InterventionLibraryTab />
              </Tab.Pane> */}
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </Container>

      <InterventionFidelityCheckModal />
      <InterventionsBriefModal />
      <SupportImplFeedbackModal />
      <ProvidingFeedbackModal />
      <ImplementationCheckModal />
      <GoalProgressModal />
      <ModifyInterventionModal />
      <EndInterventionModal />
      <GroupInterventionsModal />

      <FindInterventionModal />
      <InterventionLibraryModal />
      <SetGoalChooseConcernModal />
      <ConductInterviewModal />

      <SidebarPositionTour />
      <ManageDataPeriodsModal />
      <CreateNewInterventionModal />
      <NewAchievementsModal />
      <NewSetGoalsModal />

      <SpellingTestGroupUpsertModal />
      <SpellingTestSummaryModal />

      <EquityBenchmarksModal />

      <ConfirmDialog />

      <PageHelperModal />
      
      <LatestReleaseModal />
      
      <PermissionSettingModal />
    </>
  );
};

const mapStateToProps = ({
  auth,
  onboarding,
  help,
}: ApplicationState): PropsFromState => {
  return {
    userInfo: auth.userInfo,
    currentDataPeriod: onboarding.currentDataPeriod,
    showTopBar: help.showTopBar,
    showClassWideNeedsModal: onboarding.modalsState.showClassWideNeedsModal
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      push: push,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CoachDashboard);
