import React, { FunctionComponent } from "react";
import { useAntecedentNaming, useConsequenceNaming } from "../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

type Props = {
  selectedAntecedent:
    | { abcId: number; antecedents: number[]; other_antecedents?: string[] }
    | undefined;
  setSelectedAntecedent: React.Dispatch<
    React.SetStateAction<
      | { abcId: number; antecedents: number[]; other_antecedents?: string[] }
      | undefined
    >
  >;
  selectedConsequence:
    | { abcId: number; consequence: number; other_sequence?: string }
    | undefined;
  setSelectedConsequence: React.Dispatch<
    React.SetStateAction<
      | { abcId: number; consequence: number; other_sequence?: string }
      | undefined
    >
  >;
};

const SelectedAntecedentAndConsequence: FunctionComponent<Props> = (props) => {
  const {
    selectedAntecedent,
    setSelectedAntecedent,
    selectedConsequence,
    setSelectedConsequence,
  } = props;

  const antecedent = useAntecedentNaming(
    selectedAntecedent?.antecedents,
    selectedAntecedent?.other_antecedents
  );

  const consequence = useConsequenceNaming(
    selectedConsequence?.consequence,
    selectedConsequence?.other_sequence
  );

  const handleAntecedentReset = () => {
    setSelectedAntecedent(undefined);
  };

  const handleConsequenceReset = () => {
    setSelectedConsequence(undefined);
  };

  return (
    <div className="selected-ac-container">
      <div className="abc-focus-container">
        Selected Antecedent:{" "}
        {antecedent ? (
          <>
            {antecedent}
            <span className="removeIcon ml-1" onClick={handleAntecedentReset}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </span>
          </>
        ) : (
          <i>None</i>
        )}
      </div>
      <div className="abc-focus-container">
        Selected Consequence:{" "}
        {consequence && consequence.length > 0 ? (
          <>
            {consequence[0].consequence}
            <span className="removeIcon ml-1" onClick={handleConsequenceReset}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </span>
          </>
        ) : (
          <i>None</i>
        )}
      </div>
    </div>
  );
};

export default SelectedAntecedentAndConsequence;
