import {
  FbaAntecedent,
  FbaBehavior,
  FbaBehaviorDisplayed,
  FbaConsequence,
} from "../../../../../../store/onboarding/cases/types";

export type FbaColumnGroup = {
  title?: string;
  items: Array<FbaItem>;
};

export type FbaItem = {
  value: number;
  title: string;
  info?: string;
  isOther?: boolean;
};

export const behaviorTypes: Array<FbaColumnGroup> = [
  {
    title: "Behavior Exhibited",
    items: [
      {
        value: FbaBehavior.BEHAVIOR_OFF_TASK,
        title: FbaBehaviorDisplayed[FbaBehavior.BEHAVIOR_OFF_TASK],
      },
      {
        value: FbaBehavior.BEHAVIOR_NON_COMPLIANCE,
        title: FbaBehaviorDisplayed[FbaBehavior.BEHAVIOR_NON_COMPLIANCE],
      },
      {
        value: FbaBehavior.BEHAVIOR_INAPPROPRIATE_LANGUAGE,
        title:
          FbaBehaviorDisplayed[FbaBehavior.BEHAVIOR_INAPPROPRIATE_LANGUAGE],
      },
      {
        value: FbaBehavior.BEHAVIOR_YELLING,
        title: FbaBehaviorDisplayed[FbaBehavior.BEHAVIOR_YELLING],
      },
      {
        value: FbaBehavior.BEHAVIOR_TANTRUM,
        title: FbaBehaviorDisplayed[FbaBehavior.BEHAVIOR_TANTRUM],
      },
      {
        value: FbaBehavior.BEHAVIOR_VERBAL_AGGRESSION,
        title: FbaBehaviorDisplayed[FbaBehavior.BEHAVIOR_VERBAL_AGGRESSION],
      },
      {
        value: FbaBehavior.BEHAVIOR_PHYSICAL_AGGRESSION,
        title: FbaBehaviorDisplayed[FbaBehavior.BEHAVIOR_PHYSICAL_AGGRESSION],
      },
      {
        value: FbaBehavior.BEHAVIOR_PROPERTY_DESTRUCTION,
        title: FbaBehaviorDisplayed[FbaBehavior.BEHAVIOR_PROPERTY_DESTRUCTION],
      },
      {
        value: FbaBehavior.BEHAVIOR_SELF_INJURIOUS,
        title: FbaBehaviorDisplayed[FbaBehavior.BEHAVIOR_SELF_INJURIOUS],
      },
      {
        isOther: true,
        value: FbaBehavior.BEHAVIOR_OTHER,
        title: FbaBehaviorDisplayed[FbaBehavior.BEHAVIOR_OTHER],
      },
    ],
  },
];

export const antecedentTypes: FbaColumnGroup[] = [
  {
    title: "Who was the student interacting with?",
    items: [
      {
        value: FbaAntecedent.TargetTeacher,
        title: "Teacher",
      },
      {
        value: FbaAntecedent.TargetParaprofessional,
        title: "Paraprofessional",
      },
      {
        value: FbaAntecedent.TargetOtherSchoolStaff,
        title: "Other School Staff",
      },
      {
        value: FbaAntecedent.TargetPeers,
        title: "Peers",
      },
      {
        value: FbaAntecedent.TargetAlone,
        title: "Alone",
      },
      {
        value: FbaAntecedent.TargetOther,
        title: "Other",
        isOther: true,
      },
    ],
  },
  {
    title: "Where was the student?",
    items: [
      {
        value: FbaAntecedent.PlaceClassroomWholeClass,
        title: "Classroom: Whole Class",
      },
      {
        value: FbaAntecedent.PlaceClassroomSmallGroup,
        title: "Classroom: Small Group",
      },
      {
        value: FbaAntecedent.PlaceClassroomIndividual,
        title: "Classroom: Individual",
      },
      {
        value: FbaAntecedent.PlaceClassroomTransition,
        title: "Classroom: Transition",
      },
      {
        value: FbaAntecedent.PlaceHallway,
        title: "Hallway",
      },
      {
        value: FbaAntecedent.PlaceRecess,
        title: "Recess",
      },
      {
        value: FbaAntecedent.PlaceLunch,
        title: "Lunch",
      },
      {
        value: FbaAntecedent.PlaceRestroom,
        title: "Restroom",
      },
      {
        value: FbaAntecedent.PlaceOther,
        title: "Other",
        isOther: true,
      },
    ],
  },
  {
    title: "What was the student doing?",
    items: [
      {
        value: FbaAntecedent.ActionEngagedInActivity,
        title: "Engaged in an Activity",
      },
      {
        value: FbaAntecedent.ActionEngagedInInstruction,
        title: "Given instruction or prompt",
      },
      {
        value: FbaAntecedent.ActionAskedToStopActivity,
        title: "Asked/required to stop an activity",
      },
      {
        value: FbaAntecedent.ActionWasNotGivenActivity,
        title: "Was not given requested activity / item",
      },
      {
        value: FbaAntecedent.ActionWorkedOnAssigment,
        title: "Working on an assignment",
      },
      {
        value: FbaAntecedent.ActionWasPlaying,
        title: "Playing",
      },
      {
        value: FbaAntecedent.ActionWasWaiting,
        title: "Waiting",
      },
      {
        value: FbaAntecedent.ActionWasProvoked,
        title: "Provoked",
      },
      {
        value: FbaAntecedent.ActionEngagedInUnstructuredTime,
        title: "Engaged in unstructured time",
      },
      {
        value: FbaAntecedent.ActionOther,
        title: "Other",
        isOther: true,
      },
    ],
  },
];

export const consequenceTypes: Array<FbaColumnGroup> = [
  // {
  //   title: "Student received",
  //   items: [
  //     {
  //       value: FbaConsequence.CONSEQUENCE_RECEIVED_ATTENTION,
  //       title: "Attention",
  //       info: "(ex. reprimand, redirect)",
  //     },
  //     {
  //       value: FbaConsequence.CONSEQUENCE_RECEIVED_UNDESIRED_ACTIVITY,
  //       title: "Undesired item or activity",
  //     },
  //     {
  //       value: FbaConsequence.CONSEQUENCE_RECEIVED_SENSORY_STIMULATION,
  //       title: "Sensory stimulation",
  //     },
  //     {
  //       value: FbaConsequence.CONSEQUENCE_RECEIVED_OTHER,
  //       title: "Other",
  //       isOther: true,
  //     },
  //   ],
  // },
  // {
  //   title: "Student avoided",
  //   items: [
  //     {
  //       value: FbaConsequence.CONSEQUENCE_AVOIDED_ATTENTION,
  //       title: "Attention",
  //       info: "(ex. reprimand, redirect)",
  //     },
  //     {
  //       value: FbaConsequence.CONSEQUENCE_AVOIDED_UNDESIRED_ACTIVITY,
  //       title: "Undesired item or activity",
  //     },
  //     {
  //       value: FbaConsequence.CONSEQUENCE_AVOIDED_SENSORY_STIMULATION,
  //       title: "Sensory stimulation",
  //     },
  //     {
  //       value: FbaConsequence.CONSEQUENCE_AVOIDED_OTHER,
  //       title: "Other",
  //       isOther: true,
  //     },
  //   ],
  // },
  {
    title: undefined,
    items: [
      {
        value: FbaConsequence.N1,
        title: "Removed from situation or setting",
      },
      {
        value: FbaConsequence.N2,
        title: "Denied access to item or activity",
      },
      {
        value: FbaConsequence.N3,
        title: "Verbally re-directed or corrected",
        info: "(e.g., told “no”)",
      },
      {
        value: FbaConsequence.N4,
        title: "Given access to desired object or activity",
      },
      {
        value: FbaConsequence.N5,
        title: "Verbal reminder",
        info: "(e.g., rules, appropriate behavior)",
      },
      {
        value: FbaConsequence.N6,
        title: "Interaction with peer",
      },
      {
        value: FbaConsequence.N7,
        title: "Demand withdrawn",
      },
      {
        value: FbaConsequence.N8,
        title: "Positive praise",
      },
    ],
  },
  {
    items: [
      {
        value: FbaConsequence.N9,
        title: "Received the “look”",
        info: "(i.e., teacher stare)",
      },
      {
        value: FbaConsequence.N10,
        title: "Adult interaction explaining or reasoning with student",
      },
      {
        value: FbaConsequence.N11,
        title: "Asked to change seating",
      },
      {
        value: FbaConsequence.N12,
        title: "Announced consequence/loss of privilege",
        info: "(e.g., recess)",
      },
      {
        value: FbaConsequence.N13,
        title: "Moved to time-out",
      },
      {
        value: FbaConsequence.N14,
        title: "Ignored",
      },
      {
        value: FbaConsequence.CONSEQUENCE_RECEIVED_OTHER,
        title: "Other",
        isOther: true,
      },
    ],
  },
];
