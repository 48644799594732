import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { ApplicationState } from "../../../store";
import {
  deleteDistrict,
  getDistrictLicenses,
  selectDistrict,
  setShowDistrictLicenseModal,
} from "../../../store/superuser/actions";
import { push } from "connected-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPen,
  faPlusCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Release_Notes, SuperuserState } from "../../../store/superuser/types";

import LoadingIndicator from "../../common/onboarding/LoadingIndicator";
import { formatDate } from "../../utils/DateTimeUtils";
import useUserRole from "../../../utils/hooks/useUserRole";

type MatchParams = {
  releaseNoteId?: string;
};

type Props = RouteComponentProps<MatchParams>;

const HtmlToText = ({ htmlString }: any) => {
  // Create a new DOM element and set its innerHTML to the provided HTML string
  const div = document.createElement('div');
  div.innerHTML = htmlString;

  // Get the text content, which automatically strips out HTML tags
  const plainText = div.textContent || div.innerText || "";

  return (
    <div dangerouslySetInnerHTML={{ __html: plainText }} />
  );
};

const ReleaseNotesDetail: FunctionComponent<Props> = ({
  match: {
    params: { releaseNoteId },
  },
}) => {

  const dispatch = useDispatch();
  const { isDistrictOwner } = useUserRole();
  const releaseNotes = useSelector((s: ApplicationState) => s.superuser.releaseNotes);
  
  const handleBack = () => {
    dispatch(push(`${isDistrictOwner ? '/district' : ''}/release-notes`));
  };

  const releaseNote  = useMemo(() => {
    if(releaseNotes.length) {
      const publishedReleaseNotes: Release_Notes[] = releaseNotes.filter((release) => release.is_published);
      if(publishedReleaseNotes.length) {
        return publishedReleaseNotes.find((prn) => prn.id == releaseNoteId);
      }
    }
    return undefined;
  },[releaseNoteId])

  return (
      <div className="onboardingContainer">
        <div className="w-100 d-flex justify-content-between">
          <button
            className="blueBtnSm"
            onClick={handleBack}
          >
            <FontAwesomeIcon icon={faArrowLeft} style={{ color: "white" }} />{" "}
            Back
          </button>
        </div>
        {releaseNote ? 
          <div className="jumbotron">
          <h1>{releaseNote.title}</h1>
          <hr className="my-4"/>
          <p><HtmlToText htmlString={releaseNote.description} /></p>
        </div>
        : ''}
      </div>
    )
};

export default withRouter(ReleaseNotesDetail);
