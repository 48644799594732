import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { ChecklistItem, InterventionGroup } from "../../../../../../../../../store/onboarding/cases/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../../../store";
import useInterventionPlan from "../../../../implementation-check/hooks/useInterventionPlan";
import _ from "lodash";
import { ImplementationCheck } from "../../../../../../../../../store/onboarding/meeting-module/types";
import InterventionStatsTable from "./InterventionStatsTable";
import { makeStatisticObject } from "./utils";
import { ListGroup, OverlayTrigger, Popover } from "react-bootstrap";
import FidelityValueSpan from "./FidelityValueSpan";

type OwnProps = {
  interventionGroup: InterventionGroup;
};

type Props = OwnProps;

const InterventionStatsTab: FunctionComponent<Props> = (props) => {
  const { interventionGroup } = props;

  const { showOnlyActiveInterventions } = useSelector(
    (s: ApplicationState) => s.cases.modalsState.interventionPlan
  );

  const { implementationChecks } = useInterventionPlan(
    interventionGroup,
    showOnlyActiveInterventions
  );

  const stats: any = useMemo(() => {
     return _.chain(implementationChecks)
      .groupBy((ic) => ic.intervention)
      .toPairs()
      .map(([interventionId, implChecks]: [any, ImplementationCheck[]]) => {
        const intervention = interventionGroup.interventions.find(
          (int) => int.id == interventionId
        )!;

        const checklistItems = intervention ?
         _.chain(intervention.checklist_items)
          .map((ci) => {
            return {
              ...ci,
              loggedTimes: _.reduce(
                implementationChecks,
                (pV, cV) => {
                  if (cV.logged_items?.some((li) => li.item === ci.id)) {
                    return pV + 1;
                  }
                  return pV;
                },
                0
              ),
              notApplicableTimes: _.reduce(
                implementationChecks,
                (pV, cV) => {
                  if (cV.not_applicable_items?.some((li) => li.item === ci.id)) {
                    return pV + 1;
                  }
                  return pV;
                },
                0
              ),
            };
          }).sortBy('order')
          .value() : [];
        return makeStatisticObject({
          implChecks: implChecks,
          intervention: intervention,
          checklistItems: checklistItems,
        });
      })
      .value();
  }, []);

  const [selectedStats, setSelectedStats] = useState<any>(undefined);

  useEffect(() => {
    if(stats.length) {
      setSelectedStats(stats[0]);
    }
  },[])

  const commonChecklistItems = useMemo(
    () => {
    return  _.chain(stats)
        .flatMap((stats) => (stats !== undefined ) && stats.checklist_items)
        .filter((item) => !!item.common_step_name)
        .groupBy((item) => item.common_step_name)
        .toPairs()
        .map(([key, items]) => {
          return {
            title: key,
            usedTimes: //calculateUsedTime,
            _.chain(stats)
              .filter((stats) =>
              (stats !== undefined ) && stats.checklist_items.some(
                (ci: any) => ci.common_step_name && (ci.common_step_name != null) && (ci.common_step_name == key)
              )
              )
              .reduce((pV, cV) => pV + cV.loggedTimes, 0)
              .value(),
            loggedTimes: _.reduce(
              items,
              (pV, cV) => (cV.loggedTimes ? pV + cV.loggedTimes : pV),
              0
            ),
          };
        })
        .value()
    }
        ,
    [stats]
  );

  const handleCommonInterventionStepStatsView = () => {
     setSelectedStats({
      loggedTimes:
        _.chain(stats)
          .filter((s) =>
            (s !== undefined) && s.intervention?.checklist_items?.some(
              (ci: ChecklistItem) => !!ci.common_step_name
            )
          )
          .value().length || 0,
      checklist_items: commonChecklistItems,
      groupsIds: _.chain(stats)
        .flatMap((s) => (s !== undefined) && s.groupsIds)
        .uniq()
        .value(),
      teachersIds: _.chain(stats)
        .flatMap((s) => (s !== undefined) && s.teachersIds)
        .uniq()
        .value(),
    });
  };

  return (
    <div className="mt-2">
      <div className="alert alert-warning">Intervention stats are displayed for active interventions with logged fidelity data</div>
      {stats.length 
      ?
      <div className="intervention-stats-container">
      <div>
      <h3 className="mb-2">Interventions</h3>
            <ListGroup className="mb-2">
              {stats.map((stats: any) => ( (stats !== undefined ) &&
                <ListGroup.Item
                  key={stats.intervention.id}
                  action
                  className="m-0 d-flex align-items-center justify-content-between"
                  onClick={() => {
                    setSelectedStats(stats);
                  }}
                  style={{
                    backgroundColor:
                      selectedStats &&
                      selectedStats.intervention &&
                      selectedStats.intervention.id === stats.intervention.id
                        ? "#cbdcf0"
                        : undefined,
                  }}
                >
                   <>{(stats !== undefined ) && stats.intervention.name}</>
                  <FidelityValueSpan fidelity={(stats !== undefined ) && stats.totalFidelity} />
                </ListGroup.Item>
              ))}
            </ListGroup>
        <h3 className="mb-2">Other Stats</h3>
        <ListGroup>
          <ListGroup.Item
            action
            className="m-0 d-flex align-items-center justify-content-between"
            onClick={handleCommonInterventionStepStatsView}
            style={{
              backgroundColor:
                selectedStats && !selectedStats.intervention
                  ? "#cbdcf0"
                  : undefined,
            }}
          >
            <OverlayTrigger
              overlay={
                <Popover id="common-steps-popover">
                  <Popover.Content>
                    Some interventions share common steps. These statistics 
                    represent fidelity across multiple 
                    interventions where the steps were common.
                  </Popover.Content>
                </Popover>
              }
            >
              <div>Common Research-Based Instructional Components</div>
            </OverlayTrigger>
            <FidelityValueSpan fidelity={null} />
          </ListGroup.Item>
        </ListGroup>
      </div>

     {/* { stats.filter((stat: any) => stat.intervention != undefined).map((stats: any) => (
        <InterventionStatsTable key={stats.intervention.id} stats={stats} />
        )) 
    } */}
      {selectedStats ? (
        <InterventionStatsTable stats={selectedStats} selectedInterventionGroup={interventionGroup}/>
      ) : (
        <i className="text-center m-auto">
          Click on an intervention to show detailed statistics
        </i>
      )}
      </div>
      : ""}
      </div>
  );
}
export default InterventionStatsTab;
