import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { Modal, Tab, Tabs } from "react-bootstrap";
import InterventionStatsModalContent from "./InterventionStatsModalContent";
import { hideInterventionStatsModal } from "../../../../../../store/onboarding/actions";
import { UserInfo } from "../../../../../../store/auth/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { openOverviewModel } from "../../../../../../store/groups/actions";

type OwnProps = {};

type Props = OwnProps;

const InterventionStatsModal: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const show = useSelector(
    (s: ApplicationState) => s.onboarding.modalsState.showInterventionStatsModal
  );

  const handleHideModal = useCallback(() => {
    dispatch(hideInterventionStatsModal());
  }, []);

  const educator = useSelector<ApplicationState, UserInfo | undefined>(
    (s) => s.onboarding.selectedEducator
  );

  const title = useMemo(() => {
    //Rakesh Bhargava 6 april 2022
    if (!educator) {
      //dispatch(openOverviewModel('all_group'));
      return "Overview of all Groups";
    }
    //dispatch(openOverviewModel('one_educator'));
    return `${getFullName(educator)}'s Overview`;
  }, [educator]);

  return (
    <Modal
      show={show}
      onHide={handleHideModal}
      animation={false}
      size="xl"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="intervention-stats">
          <Tab eventKey="intervention-stats" title="Intervention Stats">
            <div className="mt-2">
              <InterventionStatsModalContent />
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default InterventionStatsModal;
