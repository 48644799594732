import { createAction, createAsyncAction } from "typesafe-actions";
import { Badge, BadgesActionTypes } from "./types";
import { Dispatch } from "redux";
import axios from "axios";
import { API_BADGES_GET } from "../../constants";
import { getUserInfo } from "../auth/actions";


export const getBadges = createAsyncAction(
  "GET_BADGES_REQUEST",
  "GET_BADGES_SUCCESS",
  "GET_BADGES_FAILURE"
)<void, Badge[], Error>();

export const getBadgesRequest = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(getBadges.request());
    try {
      const response = await axios.get(API_BADGES_GET());
      const badges = response.data as Badge[];

      dispatch(getBadges.success(badges));
      dispatch(getUserInfo());
    } catch (error) {
      dispatch(getBadges.failure(error));
    }
  };
};

export const openNewAchievementsModal = createAction(
  BadgesActionTypes.OPEN_NEW_ACHIEVEMENTS_MODAL
)<Array<Badge>>();

export const hideNewAchievementsModal = createAction(
  BadgesActionTypes.HIDE_NEW_ACHIEVEMENTS_MODAL
)();
