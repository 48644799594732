import React, { FunctionComponent } from "react";
import CreatableSelect from "react-select/creatable";
import { ValueType } from "react-select/src/types";
import {
  AuthState,
  UserAssignmentRole,
  UserInfo,
} from "../../../../../store/auth/types";
import { setShowUserModal } from "../../../../../store/superuser/actions";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { District_Request } from "../../../../../store/superuser/types";

type OwnProps = {
  district: District_Request;
  setDistrict: React.Dispatch<React.SetStateAction<District_Request>>;
};

type Props = OwnProps;

const DistrictOwnerSelector: FunctionComponent<Props> = (props) => {
  const { district, setDistrict } = props;

  const dispatch = useDispatch();

  const { userInfo } = useSelector<ApplicationState, AuthState>((s) => s.auth);

  const users = useSelector<ApplicationState, UserInfo[]>(
    (s) => s.superuser.users
  );

  const handleChangeOwner = (value: ValueType<UserInfo, false>) => {
    const typedValue = value as UserInfo;
    setDistrict((prevState) => ({ ...prevState, owner: typedValue.id }));
  };

  const usersList = userInfo ? [userInfo, ...users] : users; //todo remove when admin/users api is ready

  const handleCustomOptionCreate = (value: string) => {
    dispatch(
      setShowUserModal({
        show: true,
        user: null,
        name: value,
        role: UserAssignmentRole.DISTRICT_OWNER,
        district: district.id,
        onSuccess: (ownerId: number) => {
          setDistrict((s) => ({ ...s, owner: ownerId }));
        },
      })
    );
  };

  return (
    <CreatableSelect
      placeholder="Select Owner..."
      name="owner"
      value={usersList.find((s) => s.id === district.owner) ?? null}
      options={usersList}
      getOptionValue={(a: any) => a.value ?? a.id.toString()}
      getOptionLabel={(a: any) =>
        a.label ?? `${a.last_name} ${a.first_name} (${a.email})`
      }
      onChange={handleChangeOwner}
      getNewOptionData={(inputValue, optionLabel) =>
        ({
          label: optionLabel,
          value: inputValue,
        } as any)
      }
      formatCreateLabel={(input: string) => `Invite '${input}'`}
      onCreateOption={handleCustomOptionCreate}
    />
  );
};

export default DistrictOwnerSelector;
