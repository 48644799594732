import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
  InterventionGroup,
  StudentGoal,
} from "../../../../../../store/onboarding/cases/types";
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import GroupInfoModalTitle from "../common/GroupInfoModalTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  getProgressOverviewData,
  hideGoalProgressModal,
  logProgressTowardsGoal,
} from "../../../../../../store/onboarding/meeting-module/actions";
import { connect, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import CustomDateBoxInput from "../common/CustomDateBoxInput";
import ModalCloseButton from "../../../../../common/onboarding/third-step/group-students/common/ModalCloseButton";
import InterventionFidelityCollapse from "../InterventionFidelityCollapse";
import { LoggedProgress } from "../../../../../../store/onboarding/meeting-module/types";
import { dateToFormatStringISO8601 } from "../../../../../utils/DateTimeUtils";
import { toastr } from "react-redux-toastr";
import GoalInputGroup from "./GoalInputGroup";
import useInterventionGroupAccess from "../../../../../../utils/hooks/useInterventionGroupAccess";
import Select, { ValueType, components } from "react-select";
import { EvidenceAssessment, EvidencePeriodData, Measurement, ReactSelectOption } from "../../../../../../store/onboarding/types";

type StateProps = {
  showModal: boolean;
  interventionGroup?: InterventionGroup;
  isLoading: {
    logProgressTowardsGoal: boolean;
  };
};
type DispatchProps = {
  hideGoalProgressModal: () => any;
  logProgressTowardsGoal: (studentGoals: Array<LoggedProgress>) => any;
  getProgressOverviewData: (interventionGroupId: number) => any;
};

type OwnProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const GoalProgressModal: FunctionComponent<Props> = ({
  showModal,
  isLoading,
  interventionGroup,
  logProgressTowardsGoal,
  hideGoalProgressModal,
  getProgressOverviewData,
}) => {
  const [loggedData, setLoggedData] = useState<
    Array<StudentGoal & LoggedProgress>
  >([]);
  const [goalProgressDropdown, setGoalProgressDropdown] = useState<
    Array<StudentGoal & LoggedProgress>
  >([]);
  const [preSelectedMeasurement, setPreSelectedMeasurement] = useState<number | undefined>()
  const [isImplCheckDisable, setImplCheckDisable] = useState(false);

  const measurements = useMemo(() => {
    let uniqueMeasurementArray: Measurement[] = [];
    if(goalProgressDropdown.length) {
      let measurementsArray: Measurement[] = 
        [...goalProgressDropdown
          .sort((a,b) => a.is_primary_goal ? -1: 1)
          .sort((a,b) => a.student.first_name.localeCompare(b.student.first_name))
          .map((ld)=> ld.measurement)];
      uniqueMeasurementArray = Array.from(new Map(measurementsArray.map((x:any) => [x['id'], x])).values())
      return uniqueMeasurementArray;
    } 
  },[goalProgressDropdown]);

  const callbacks = useSelector(
    (s: ApplicationState) =>
      s.meetingModuleReducer.modalsState.goalProgressModalCallbacks
  );

  const onImpCheckAlert = (implCheck: boolean) => {
    setImplCheckDisable(implCheck)
  }

  const onModalHide = () => {
    hideGoalProgressModal();
    onImpCheckAlert(false);
    callbacks?.onClose && callbacks.onClose();
  };

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(interventionGroup?.id);

  const onModalShow = () => {
    if (interventionGroup) {
      getProgressOverviewData(interventionGroup!.id!);

      // Need to implement when multiple active goals of single student.

      // let onlyactiveGoals = (interventionGroup.student_goals || [])
      //                       .filter((studentGoal) => !studentGoal.archived);
      // let finalArrayId:any[] = [];
      // let finalArray = 
      //   onlyactiveGoals.filter((stug) => {
      //       if (!finalArrayId.includes(stug.student.id)) {
      //           let singleStudntGoals = onlyactiveGoals.filter((s) => s.student.id === stug.student.id )
      //           let latestgoal = singleStudntGoals.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())[0]; // find the latest based on created
      //           finalArrayId.push(latestgoal.student.id)
      //           return true
      //       }
      //       else return false
      //   })

      let logData = (interventionGroup.student_goals || []).filter((studentGoal) =>  !studentGoal.archived)
      .map((sg) => ({
        ...sg,
        goal_id: sg.id,
        value: undefined,
        date: undefined,
      }))

      setLoggedData(logData);
      setGoalProgressDropdown(logData);
      let preSelected = logData.find((logged) => logged.is_primary_goal);
      setPreSelectedMeasurement(preSelected?.measurement.id)
    }
  };

  const filterStudentsIds = (
    selectedInterventionGroup: InterventionGroup | undefined
  ) => {
    const students = selectedInterventionGroup?.students.filter(
      (student, index) => {
        let studentGoal: StudentGoal | undefined;
        if (selectedInterventionGroup?.student_goals) {
          studentGoal = selectedInterventionGroup?.student_goals.find(
            (x) => x.student.id === student.id && !x.archived
          );
        }
        return !!studentGoal;
      }
    );
    return students?.map((et) => et.id);
  };

  const currentStudentsIds = useMemo(() => {
    return filterStudentsIds(interventionGroup);
  }, [loggedData]);

  // const [currentStudents, setCurrentStudents] = useState(
  //   intervention ? filterStudents(intervention) : []
  // );

  // const allStudentsIds = useMemo(() => {
  //   return currentStudents ? currentStudents?.map((st) => st.id!) : [];
  // }, [currentStudents]);

  const handleProgressTowardsGoalLog = (e: React.FormEvent<any>) => {
    e.preventDefault();
    const studentGoals = loggedData
      .filter(
        (ld) => ld.goal_id !== undefined && ld.value !== undefined && ld.date
      )
      .map((ld) => ({
        goal_id: ld.goal_id,
        value: ld.value,
        date: dateToFormatStringISO8601(ld.date),
      }));
    if (studentGoals.length) {
      logProgressTowardsGoal(studentGoals).then(onModalHide, (err: string) => {
        toastr.error("Error", err);
      });
    }
  };

  const handleGoalLogChange = (studentGoalId: number) => (
    event: React.FormEvent<any>
  ) => {
    let { name, value } = event.target as HTMLInputElement;
    setLoggedData((ld) =>
      ld.map((l) => {
        if (l.goal_id === studentGoalId) {
          return { ...l, [name]: value};
        }
        return l;
      })
    );
  };

  const isLogBtnDisabled = useMemo(() => {
    return !loggedData.some((ld) => ld.value !== undefined && ld.date);
  }, [loggedData]);

  if (!interventionGroup) return null;

  const handleMeasurementChange = (
    value: ValueType<Measurement, false>
  ) => {
      setPreSelectedMeasurement(undefined)
      setLoggedData(
        (interventionGroup.student_goals || [])
        .filter((studentGoal) => 
          value != null ? 
            !studentGoal.archived && studentGoal.measurement.id == value?.id 
          : !studentGoal.archived)
        .map((sg) => ({
          ...sg,
          goal_id: sg.id,
          value: undefined,
          date: undefined,
        }))
      );
  };

  return (
    <Modal
      show={showModal}
      onHide={onModalHide}
      onShow={onModalShow}
      animation={false}
      size="lg"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header className="purpleModalHeader centerModalHeader">
        <GroupInfoModalTitle />
        <Modal.Title>
          <div className="">
              <Select
                isClearable
                menuPlacement="auto"
                placeholder="Select measurement..."
                menuPortalTarget={document.body}
                value={measurements?.find((measure) => measure.id == preSelectedMeasurement)}
                options={measurements}
                getOptionLabel={(measurement: Measurement) => measurement.display_name}
                getOptionValue={(measurement: Measurement) => measurement.id!.toString() }
                onChange={handleMeasurementChange}
                styles={{
                  menuPortal: (styles) => ({ ...styles, zIndex: 1051 }),
                  indicatorsContainer: (provided) => ({
                    ...provided,
                    "svg": {
                      fill: "black"
                    }
                  }),
                  // control: (base, props) => ({
                  //   ...base, 
                  //   background: 'none',
                  //   border: 'none',
                  //   "&:hover": {
                  //     // Overwrittes the different states of border
                  //     border: 'none'
                  //   }
                  // }),
                  // singleValue:(provided:any) => ({
                  //   ...provided,
                  //   color:'#fff',
                  // }),
                }}
                isDisabled={isImplCheckDisable}
              />
            {/* <span className="pointer">
              <FontAwesomeIcon icon={faCaretDown} size="2x" />
            </span> */}
          </div>
        </Modal.Title>
        <ModalCloseButton onClose={onModalHide} />
      </Modal.Header>
      <Modal.Body>
        <h3 className="mb-3">
          <span className="font-weight-bold">Group: </span>
          {interventionGroup.name}
        </h3>
        <div className="mt-3 text-center">
          <h3 className="font-weight-bold  mb-3">
            Record data for each student
          </h3>
          <div style={{ margin: "0 10%" }}>
            <form onSubmit={handleProgressTowardsGoalLog} id="recordDataForm">
              <table className="recordGoalProgressTable">
                <thead>
                  <tr>
                    <th>Student</th>
                    <th>DATE</th>
                    <th>Goal Progress</th>
                  </tr>
                </thead>
                <tbody>
                  {loggedData.length ?
                    loggedData.filter((logged) => preSelectedMeasurement 
                                        ? logged.measurement.id == preSelectedMeasurement
                                        : true
                    ).sort((a,b) => a.student.first_name < b.student.first_name ? -1 : 1).map(
                      (studentGoal) =>
                        currentStudentsIds?.includes(
                          studentGoal?.student?.id
                        ) && !isIntGrpIDsWithReadAccess && (
                          <tr key={studentGoal.goal_id}>
                            <td>
                              {studentGoal.student.first_name} 
                              {/* -{" "} */}
                              {/* {studentGoal.goal_id} */}
                            </td>
                            <td>
                              <DatePicker
                                maxDate={new Date()}
                                customInput={
                                  <CustomDateBoxInput
                                    style={{ margin: "-10px 0" }}
                                  />
                                }
                                selected={studentGoal.date}
                                onChange={(date: Date) =>
                                  setLoggedData((ld) =>
                                    ld.map((l) => {
                                      if (
                                        l.goal_id === studentGoal.goal_id ||
                                        !l.date
                                      ) {
                                        return { ...l, date: date };
                                      }
                                      return l;
                                    })
                                  )
                                }
                              />
                            </td>
                            <td>
                              <div className="d-flex align-items-center justify-content-between">
                                <div>
                                  {studentGoal.measurement.display_name +
                                    (studentGoal.observation_unit_description
                                      ? ` of ${studentGoal.observation_unit_description}`
                                      : "")}
                                </div>
                                <div className="mx-2">
                                  <GoalInputGroup
                                    measurement={studentGoal.measurement}
                                    value={studentGoal.value}
                                    onChange={handleGoalLogChange(
                                      studentGoal.goal_id
                                    )}
                                  />
                                </div>
                                {loggedData.length > 1 && (
                                  <span
                                    onClick={() =>
                                      setLoggedData((ld) =>
                                        ld.filter(
                                          (l) => l.goal_id !== studentGoal.goal_id
                                        )
                                      )
                                    }
                                  >
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="deleteMsg" className="customInfoTooltip">
                                        <div>
                                        {`This will temporarily remove access to record progress monitoring for this student.`}
                                        </div>
                                        <div>
                                        {`To permanently remove a student, access the Manage tab from the Group Workspace.`}
                                        </div>
                                      </Tooltip>
                                    }
                                    placement="right"
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      size="lg"
                                      className="pointer"
                                    />
                                  </OverlayTrigger>
                                  </span>
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                    )
                  : 
                  <tr><td colSpan={3} className="text-center">No record found</td></tr>
                  }
                </tbody>
              </table>
            </form>
            <div className="btnActions my-2">
              <div />
              <button
                form="recordDataForm"
                className="blueBtnSm"
                type="submit"
                disabled={isLogBtnDisabled}
              >
                Log{" "}
                {isLoading.logProgressTowardsGoal && (
                  <Spinner animation="border" size="sm" />
                )}
              </button>
            </div>
          </div>
        </div>

        <InterventionFidelityCollapse onImpCheckDisabled={onImpCheckAlert}/>

        <hr />
        <div className="modalActions">
          <button className="blueBtnSm" onClick={onModalHide}>
            Back
          </button>
          {/* <button
            className="blueBtnSm"
            onClick={handleProgressTowardsGoalLog}
            disabled={isLogBtnDisabled}
          >
            Log{" "}
            {isLoading.logProgressTowardsGoal && (
              <Spinner animation="border" size="sm" />
            )}
          </button> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({
  meetingModuleReducer,
  cases,
}: ApplicationState): StateProps => {
  return {
    interventionGroup: cases.selectedInterventionGroup,
    showModal: meetingModuleReducer.modalsState.goalProgressModal,
    isLoading: {
      logProgressTowardsGoal:
        meetingModuleReducer.isLoading.logProgressTowardsGoal,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hideGoalProgressModal: hideGoalProgressModal,
      logProgressTowardsGoal: logProgressTowardsGoal,
      getProgressOverviewData: getProgressOverviewData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalProgressModal);
