import React, { useRef, useState } from "react";
import AddStudentsToTableOverlay from "./student-data-table/AddStudentToTableOverlay";
import { Overlay } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { openUploadStudentsRosterModal } from "../../../../../store/onboarding/actions";

type DispatchProps = {
  openUploadStudentsRosterModal?: () => any;
};

type Props = DispatchProps;
const EmptyStudentsRows = (props: Props) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [isNewStudent, setIsNewStudent] = useState<boolean>(false);
  //const [isExistingStudent, setIsExistingStudent] = useState<boolean>(false);
  const [activeRef, setActiveRef] = useState<any>();

  const newStudentRef = useRef(null);
  const existingStudentRef = useRef(null);
  const dispatch = useDispatch();

  const clickNewStudent = () => {
    setActiveRef(newStudentRef);
    setIsNewStudent(true);
    //setIsExistingStudent(false);
    setShowOverlay((prevState) => !prevState);
  };

  const clickExistingStudent = () => {
    setActiveRef(existingStudentRef);
    setIsNewStudent(false);
    //setIsExistingStudent(true)
    setShowOverlay((prevState) => !prevState);
  };

  const onHideOverlay = () => {
    setShowOverlay(false);
  };

  const handleUploadStudentRoster = () => {
    dispatch(openUploadStudentsRosterModal());
  };

  return (
    <div className="interiorDataContainer">
      <h1 className="text-center font-weight-semibold mb-4">
        There are no students in your table!
      </h1>
      <div className="d-flex justify-content-center">
        <button
          className="blueBtnMd mr-4"
          onClick={handleUploadStudentRoster}
        >
          UPLOAD A STUDENT ROSTER
        </button>
        <button
          ref={newStudentRef}
          className="blueBtnMd mr-4"
          onClick={clickNewStudent}
        >
          Add New Student
        </button>
        <button
          ref={existingStudentRef}
          className="blueBtnMd"
          onClick={clickExistingStudent}
        >
          Add Existing Student
        </button>
      </div>
      <AddStudentsToTableOverlay
        placement={"right"}
        showOverlay={showOverlay}
        buttonRef={activeRef}
        onHide={onHideOverlay}
        isNewStudent={isNewStudent}
        //isExistingStudent={isExistingStudent}

      />
    </div>
  );
};

export default EmptyStudentsRows;
