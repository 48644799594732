import React, { FunctionComponent, useMemo } from "react";
import {
  Measurement,
  MeasurementType,
} from "../../../../../../store/onboarding/types";
import { Form, FormControl, InputGroup } from "react-bootstrap";

type OwnProps = {
  measurement: Measurement;
  targetValue: string;
  setTargetValue: (value: string) => void;
  optional?: boolean;
  withoutStyles?: boolean;
};

type Props = OwnProps;

const OutcomeCriteriaInput: FunctionComponent<Props> = ({
  targetValue,
  measurement,
  setTargetValue,
  optional,
  withoutStyles,
}) => {
  const isPercentageType = useMemo(
    () => measurement.type === MeasurementType.PERCENTAGE,
    []
  );

  const handleTargetValueChange = (event: React.FormEvent<any>) => {
    const { value } = event.target as HTMLInputElement;
    setTargetValue(value);
  };

  if (measurement.type === MeasurementType.CATEGORY) {
    return (
      <InputGroup
        style={
          withoutStyles
            ? undefined
            : { width: "120px", textAlign: "center", margin: "auto" }
        }
      >
        <Form.Control
          as="select"
          name="value"
          className="p-0 px-2"
          value={targetValue}
          onChange={handleTargetValueChange}
        >
          <option value={undefined} />
          {(measurement.possible_values || []).map((value) => (
            <option value={value.order} key={value.order}>
              {value.display_name}
            </option>
          ))}
        </Form.Control>
      </InputGroup>
    );
  }
  return (
    <InputGroup
      style={
        withoutStyles
          ? undefined
          : { width: "120px", textAlign: "center", margin: "auto" }
      }
    >
      <FormControl
        id={"outcome-criteria-input"}
        required={!optional}
        type="number"
        value={targetValue || ""}
        min={isPercentageType ? 0 : measurement.range_min}
        max={isPercentageType ? 100 : measurement.range_max}
        name="value"
        onChange={handleTargetValueChange}
        aria-describedby="measurement-type"
        style={{ fontSize: "1rem" }}
      />
      {isPercentageType && (
        <InputGroup.Append>
          <InputGroup.Text id="measurement-type">%</InputGroup.Text>
        </InputGroup.Append>
      )}
    </InputGroup>
  );
};

export default OutcomeCriteriaInput;
