import React, { useMemo } from "react";
import InterventionRow from "./InterventionRow";
import {
  Intervention,
  InterventionPlan,
} from "../../../../../../store/onboarding/cases/types";
import { Droppable } from "react-beautiful-dnd";
import { sortInterventionsByInterventionPlan } from "../../../../../utils";

type Props = {
  showIfNoData?: boolean;
  interventionGroupId: number;
  interventions: Intervention[];
  interventionPlan: InterventionPlan | null;
  isLoading?: boolean;
  onRemoveIntervention?: (intervention: Intervention) => void;
};

const InterventionsList = ({
  showIfNoData,
  interventionGroupId,
  interventions,
  interventionPlan,
  isLoading,
  onRemoveIntervention,
}: Props) => {
  const sortedInterventions = useMemo(
    () => sortInterventionsByInterventionPlan(interventions, interventionPlan),
    [interventionPlan, interventions]
  );

  if (showIfNoData && !sortedInterventions.length) {
    return <i>No Intervention Selected Yet</i>;
  }

  return (
    <Droppable
      droppableId={interventionGroupId.toString()}
      isDropDisabled={isLoading}
    >
      {(provided) => (
        <div
          className="w-100 d-flex flex-column m-2"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {sortedInterventions.map((inter, index) => (
            <InterventionRow
              key={inter.id}
              intervention={inter}
              index={index}
              isLoading={isLoading}
              onRemove={onRemoveIntervention}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default InterventionsList;
