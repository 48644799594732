import React, { FunctionComponent } from "react";
import { Modal } from "react-bootstrap";

type OwnProps = {
  showModal?: boolean;
  onHide: () => any;
};

type Props = OwnProps;

const GatherEvidenceModal: FunctionComponent<Props> = (props) => {
  return (
    <Modal
      id="gather-evidence-modal"
      show={props.showModal}
      animation={false}
      size="lg"
      backdropClassName="customDarkModalBackdrop in"
      onHide={props.onHide}
    >
      <Modal.Header closeButton className={"purpleModalHeader"}>
        <Modal.Title className="modalTitle">Gather Evidence</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>
            The first step of Intervention Planning in ReadyCoach is to gather
            evidence to support your grouping choices.
          </strong>
        </p>
        <p>
          Evidence can be data collected from assessments, descriptions of
          target behaviors, screening data, teacher interviews, and much more.
        </p>
        <p>
          There are many ways to add evidence. You can upload files, type in
          data manually, conduct interviews in the app, or select target
          behaviors from a predefined list.
        </p>
        <p>
          <strong>
            The Your Data &amp; Interviews page collects data into a table like
            Excel. Select an option on this page to get started.
          </strong>
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default GatherEvidenceModal;
