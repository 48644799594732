import React, { FunctionComponent } from "react";
import { Portal } from "react-portal";

type Props = {
  usePortal?: boolean;
};

const InterventionElementContainer: FunctionComponent<Props> = (props) => {
  return props.usePortal ? (
    <Portal>{props.children}</Portal>
  ) : (
    <>{props.children}</>
  );
};

export default InterventionElementContainer;
