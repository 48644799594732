import React, { FormEvent, FunctionComponent, useEffect, useState } from "react";
import {
  Alert,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Spinner,
} from "react-bootstrap";
import { APP_NAME } from "../../constants";
import { ApplicationState } from "../../store";
import { bindActionCreators, Dispatch } from "redux";
import { changePasswordByToken, isTokenValid, resetPassword } from "../../store/auth/actions";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";
import { AxiosError } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

type StateProps = {
  isLoading: {
    resetPassword?: boolean;
    changePassword?: boolean;
    isTokenValid?: boolean;
  };
  resetPasswordResponse?: any;
};
type DispatchProps = {
  resetPassword: (email: string) => any;
  changePasswordByToken: (
    token: string,
    email: string,
    password: string
  ) => any;
  push: (location: string) => any;
  isTokenValid: (
    token: string,
    email: string,
  ) => any;
};

type MatchParams = {
  token?: string;
  resetEmail?: string;
};

type Props = RouteComponentProps<MatchParams> & StateProps & DispatchProps;

const RestorePassword: FunctionComponent<Props> = ({
  match: {
    params: { token, resetEmail },
  },
  isLoading,
  resetPassword,
  changePasswordByToken,
  isTokenValid,
  push,
  resetPasswordResponse
}) => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [attributeType, setAttributeType] = useState("password");
  const [attributeConfirmType, setAttributeConfirmType] = useState("password");

  useEffect(() => {
    if(token && resetEmail) {
      isTokenValid(token!, resetEmail!).then(
        () => {
        },
        (err: string) => {
          toastr.error("Error", err);
          push("/login")
        }
      );
    }
  },[token, resetEmail])

  const handleEmailChange = (e: FormEvent<any>) => {
    e.preventDefault();
    const { value } = e.target as HTMLInputElement;
    setEmail(value);
  };

  const handleNewPasswordChange = (e: FormEvent<any>) => {
    e.preventDefault();
    const { value } = e.target as HTMLInputElement;
    setNewPassword(value);
    setPasswordsMatch(true);
  };

  const handlePasswordConfirmChange = (e: FormEvent<any>) => {
    e.preventDefault();
    const { value } = e.target as HTMLInputElement;
    setNewPasswordConfirm(value);
    setPasswordsMatch(true);
  };

  const handleReset = (e: FormEvent<any>) => {
    e.preventDefault();
    resetPassword(email).then(
      () => setIsSent(true),
      //(err: string) => toastr.error("Error", err)
    );
  };

  const handlePasswordChange = (e: FormEvent<any>) => {
    e.preventDefault();
    if (newPassword !== newPasswordConfirm) {
      setPasswordsMatch(false);
      return;
    }
    changePasswordByToken(token!, resetEmail!, newPassword).then(
      () => push("/login"),
      (err: string) => toastr.error("Error", err)
    );
  };

  const passwordShow = () => {
    let attrType: string = attributeType === "password" ? "text": "password";
    setAttributeType(attrType)
  };

  const confirmPasswordShow = () => {
    let attrType: string = attributeConfirmType === "password" ? "text": "password";
    setAttributeConfirmType(attrType)
  };

  return (
    <div>
      <Container className="loginContainer">
        <Row className="align-items-center">
          <Col sm>
            <h1 className="hMax">
              Sign up for <strong>{APP_NAME}</strong>
            </h1>
            <br />
            <p className="text-white text-center">
              You can sign up your district for <strong>{APP_NAME}</strong>
            </p>
            <p className="text-white text-center">
              Once you have an account you'll be able to invite your staff and
              begin coaching.
            </p>
          </Col>
          <Col sm>
            <div className="welcomeInputContainer">
              {isSent ? (
                 <Alert data-cy="forgot-password-success" variant="success">
                  {resetPasswordResponse ? resetPasswordResponse?.status : `An email with your confirmation code has been sent to you.
                  Please check the email and follow the attached link.`}
                </Alert>
              ) : token ? (
                <Form onSubmit={handlePasswordChange}>
                  <h2 className="mb-3 font-weight-bold">Set New Password</h2>
                  <FormGroup>
                    <FormLabel column={false} className="font-weight-bold">
                      New Password
                    </FormLabel>
                    <div className="input-container">
                      <FormControl
                        required
                        minLength={8}
                        maxLength={20}
                        type={attributeType}
                        placeholder="New Password"
                        aria-label="New Password"
                        value={newPassword}
                        name="newPassword"
                        onChange={handleNewPasswordChange}
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                      />
                      <FontAwesomeIcon  
                        onClick={passwordShow}  
                        icon={attributeType == 'password' ? faEyeSlash : faEye} 
                        className="icon-eye"
                      />
                    </div>
                    <small>Passwords must be 8 to 20 characters long and include at least one uppercase letter, 
                    number and special character. eg. Example@1</small>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel column={false} className="font-weight-bold">
                      Confirm New Password
                    </FormLabel>
                    <div className="input-container">
                      <FormControl
                        required
                        minLength={8}
                        maxLength={20}
                        type={attributeConfirmType}
                        placeholder="Confirm New Password"
                        aria-label="Confirm New Password"
                        value={newPasswordConfirm}
                        name="newPasswordConfirm"
                        onChange={handlePasswordConfirmChange}
                        onKeyPress={(e: any) =>
                          e.key === "Enter" ? handlePasswordChange(e) : undefined
                        }
                        isInvalid={!passwordsMatch}
                      />
                      <FontAwesomeIcon  
                        onClick={confirmPasswordShow}  
                        icon={attributeConfirmType == 'password' ? faEyeSlash : faEye} 
                        className="icon-eye"
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Passwords do not match.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <div className="d-flex justify-content-between">
                    <button
                      className="whiteBtnSm"
                      onClick={() => push("/login")}
                    >
                      Cancel
                    </button>
                    <button className="blueBtnSm" type="submit">
                      Change Password
                      {isLoading.changePassword && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="ml-1"
                        />
                      )}
                    </button>
                  </div>
                </Form>
              ) : (
                <div>
                  <h2 className="mb-3 font-weight-bold">Forgot Password</h2>
                  <p>
                    Give us your email address and instructions to reset your
                    password will be emailed to you.
                  </p>
                  <form id="resetPasswordForm" onSubmit={handleReset}>
                    <Form.Control
                      required
                      type="email"
                      placeholder="Enter email"
                      onChange={handleEmailChange}
                    />
                  </form>
                  <div className="d-flex justify-content-between mt-3">
                    <button
                      className="whiteBtnSm"
                      onClick={() => push("/login")}
                    >
                      Cancel
                    </button>
                    <button
                      className="blueBtnSm"
                      form="resetPasswordForm"
                      data-cy="reset-password-submit"
                    >
                      Reset my password
                      {isLoading.resetPassword && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="ml-1"
                        />
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ auth }: ApplicationState): StateProps => {
  return {
    isLoading: {
      resetPassword: auth.isLoading.resetPassword,
      changePassword: auth.isLoading.changePassword,
      isTokenValid: auth.isLoading.isTokenValid
    },
    resetPasswordResponse: auth.resetPasswordResponse
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      resetPassword: resetPassword,
      changePasswordByToken: changePasswordByToken,
      isTokenValid: isTokenValid,
      push: push,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RestorePassword);
