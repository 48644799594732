import React, { FunctionComponent, useMemo } from "react";
import Select, { ValueType } from "react-select";
import {
  BasicUserInfo,
  EvidencePeriodData,
  School,
  TeacherClass,
} from "../../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  changeClassIdFilter,
  changeDataSchoolsFilter,
} from "../../../../../../../store/dataFilter/actions";
import { connect, useSelector } from "react-redux";
import {
  changeSelectedClass,
  changeTeachersFilter,
  clearClassesAssignedToTeacher,
} from "../../../../../../../store/onboarding/actions";

type StateProps = {
  schools: Array<School>;
  dataSchoolsFilter?: Array<number>;
};

type DispatchProps = {
  changeDataSchoolsFilter: (id?: number[]) => any;
  // changeTeachersFilter: (teacherIdFilter?: number) => any;
  // changeSelectedClass: (selectedClass?: TeacherClass) => any;
  // changeClassFilter: (id?: number) => any;
  // clearClassesAssignedToTeacher: () => any;
};

type Props = StateProps & DispatchProps;

const SchoolsFilter: FunctionComponent<Props> = ({
  schools,
  dataSchoolsFilter,
  changeDataSchoolsFilter,
  // changeTeachersFilter,
  // changeSelectedClass,
  // changeClassFilter,
  // clearClassesAssignedToTeacher,
}) => {
  const evidencePeriodData = useSelector<
    ApplicationState,
    EvidencePeriodData
  >((s) => s.onboarding.evidencePeriodData!);

  const schoolsByCurrentDataPeriod: any[] = useMemo(() => 
    evidencePeriodData!.student_rows.flatMap((std) => std.student?.teachers?.flatMap((teacher) => teacher.schools) || []) || []
  ,[evidencePeriodData])

  const uniqueByKey = (array:School[], key:any ='id') => {
    return Array.from(new Map(array.map((x:any) => [x[key], x])).values());
  }

  const options: Array<School> = useMemo(() => 
    schools.filter((school) => (Array.from(new Set([...schoolsByCurrentDataPeriod])) || []).includes(school.id))
, [schools]);

  const handleSchoolChange = (value: ValueType<School, true>) => {
    if (value && (value as School[]).length > 0) {
      changeDataSchoolsFilter((value as School[]).map((t) => t.id!));
    } else {
      changeDataSchoolsFilter();
      // changeTeachersFilter();
      // changeSelectedClass();
      // changeClassFilter();
      // clearClassesAssignedToTeacher();
    }
  };

  return (
    <>
      <h4 className="font-weight-bold m-0">School(s)</h4>
      <Select
        isMulti
        isClearable
        styles={{ menu: (styles) => ({ ...styles, zIndex: 100 }) }}
        options={options}
        value={options.filter(
          (x) => dataSchoolsFilter && dataSchoolsFilter.includes(x.id!)
        )}
        getOptionLabel={(school: School) => `${school.name}`}
        getOptionValue={(school: School) => school.id.toString()}
        onChange={handleSchoolChange}
      />
    </>
  );
};

const mapStateToProps = ({
  dataFilter,
  onboarding,
}: ApplicationState): StateProps => {
  return {
    schools: onboarding.schools,
    dataSchoolsFilter: dataFilter.dataSchoolsFilter,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      changeDataSchoolsFilter: changeDataSchoolsFilter,
      // changeTeachersFilter: changeTeachersFilter,
      // changeSelectedClass: changeSelectedClass,
      // changeClassFilter: changeClassIdFilter,
      // clearClassesAssignedToTeacher: clearClassesAssignedToTeacher,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SchoolsFilter);
