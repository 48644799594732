import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import Select, { ValueType } from "react-select";
import {
    changeDataSort, closeSortModal,
    columnSortApplied
} from "../../../../../store/dataSort/actions";
import { EvidenceColumn } from '../../../../../store/onboarding/types';

export default function SortDataModal(props: any) {
    const dispatch = useDispatch();

    //const sortingArr = ['Composite','Maze-Adjusted' , 'ORF-Accuracy', 'ORF-WordsCorrect', 'ORF-Errors','WRF',  'NWF-WRC', 'NWF-CLS', 'PSF', 'LNF'];


    const evidencePeriodData: any = useSelector<any>(
        (s) => s?.onboarding?.evidencePeriodData
    );

    const dataSort: any = useSelector<any>(
        (s) => s?.dataSort.activeSort
    );


    const [options, setOptions] = useState<any[]>([]);
    const [displayOptions, setDisplayOptions] = useState<any[]>([]);

    const [sortTypes, setSortTypes] = useState([{
        value: 'Ascending', label: 'Ascending'
    }, {
        value: 'Descending', label: 'Descending'
    }]);

    const existingSort = [{ order: '1' }, { order: '2' }, { order: '3' }, { order: '4' }].map((ord: any, ind: any) => dataSort[ind] ? dataSort[ind] : ord);
    const [activeSort, setActiveSort] = useState<any[]>([...existingSort]);
    useEffect(() => {
        const availableLabel = activeSort.map(et => et && et.col && et.col.measurement ? et.col.measurement.column_name : null)
        if (evidencePeriodData && evidencePeriodData.evidence_column_groups)
            evidencePeriodData.evidence_column_groups.map((et: any) => {
                et.evidence_columns
                // .sort((a: EvidenceColumn, b: EvidenceColumn) => {
                //     return sortingArr.indexOf(a.measurement?.column_name!) - sortingArr.indexOf(b.measurement?.column_name!)
                // })
                .sort((a: EvidenceColumn, b: EvidenceColumn) => (a.id < b.id) ? -1 : 1)
                .map((col: any) => {

                    if (col && !!col.measurement) {
                        setOptions((data: any) => {
                            return [...data, { value: col, label: col?.measurement?.column_name }]
                        });
                        if(col && col.measurement && col.measurement.column_name && !availableLabel.includes(col.measurement.column_name))
                        setDisplayOptions((data: any) => {
                            return [...data, { value: col, label: col?.measurement?.column_name }]
                        });
                    }
                })
            })
    }, [evidencePeriodData])

    useEffect(() => {
        (() => {
            if (options.length != 0) {
                const availableLabel = activeSort.map(et => et && et.col && et.col.measurement ? et.col.measurement.column_name : null)
                const filteredData = options.filter(op => !availableLabel.includes(op.label));
                setDisplayOptions([...filteredData]);
            }
        })()
    }, [activeSort])

    const selectSortCol = (order: any, col: any) => {
        const newData = {
            order,
            col: !col ? undefined : col.value
        }
        setActiveSort((activeSortValue: any) => {
            const found = activeSortValue.find((et: any) => et?.order.toString() == order.toString())
            const activeSort = [...activeSortValue];
            for (let i in activeSort) {
                if (activeSort[i].order.toString() == order.toString())
                    activeSort[i] = { ...found, ...newData }
            }
            return [...activeSort]
        });
    }

    const selectSortType = (order: any, direction: any) => {
        const newData = {
            order,
            direction
        }
        setActiveSort((activeSortValue: any) => {
            const found = activeSortValue.find((et: any) => et?.order.toString() == order.toString())
            const activeSort = [...activeSortValue];
            for (let i in activeSort) {
                if (activeSort[i].order.toString() == order.toString())
                    activeSort[i] = { ...found, ...newData }
            }
            return [...activeSort]
        });
    }

    const handleSaveSortData = () => {
        const sortData = activeSort.filter(e => e.col && e.direction)
        dispatch(changeDataSort(sortData));
        props.setShow(false);
        dispatch(columnSortApplied(true))
    }

    return (
        <Modal
            className={'z-index-1075'}
            onHide={() => props.setShow(false)}
            animation={false}
            centered={true}
            backdropClassName={`customDarkModalBackdrop inner in ${false ??
                ""}`}
            show={props.show}
            size="lg"
        >
            <Modal.Header
                closeButton
                className="purpleModalHeader"
                style={{ borderBottom: 0 }}
            >
                <Modal.Title>{"Sort By Column with Order"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Add levels to sort by:</p>

                <table className="table table-light">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Column</th>
                            <th scope="col">Order</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            activeSort.map((sort: any, ind: any) => {
                                return <tr key={'row_sort_' + ind}>
                                    <th scope="row">Sort {(ind + 1).toString()}{(ind + 1) == 1 ? <sup>st</sup> : (ind + 1) == 2 ? <sup>nd</sup> : <sup>rd</sup>} by</th>
                                    <td><Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={dataSort[ind] ? { value: dataSort[ind]?.col, label: dataSort[ind]?.col?.measurement?.column_name } : undefined}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        onChange={(et) => { selectSortCol((ind + 1).toString(), et) }}
                                        // isRtl={isRtl}
                                        isSearchable={true}
                                        name="sortCol"
                                        options={displayOptions}
                                    /></td>
                                    <td><Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={dataSort[ind] ? { value: dataSort[ind]?.direction, label: dataSort[ind]?.direction } : undefined}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        onChange={(et) => { selectSortType((ind + 1).toString(), et?.label) }}
                                        // isRtl={isRtl}
                                        isSearchable={true}
                                        name="sortType"
                                        options={sortTypes}
                                    /></td>
                                </tr>
                            })
                        }

                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <div className="btnActions">
                    <button className="whiteBtnSm" onClick={() => props.setShow(false)}>
                        Cancel
                    </button>
                    <button className="blueBtnSm" onClick={() => { handleSaveSortData(); }}>
                        Apply
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
