import React from "react";
import Spinner from "react-spinkit";

type Props = {};

const LoadingIndicator = (props: Props) => {
  return (
    <div
      data-cy="loading-indicator"
      style={{ margin: "20px" }}
      className="d-flex justify-content-center align-items-center"
    >
      <Spinner name="circle" fadeIn="quarter" className="loadingIndicator" />
    </div>
  );
};

export default LoadingIndicator;
