import React, { Component } from "react";
import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  RenewedTeacherInfo,
  School,
  TeacherInfo,
} from "../../../../../store/onboarding/types";
import LoadingIndicator from "../../LoadingIndicator";
import { IS_READY_COACH } from "../../../../../constants";
import { getInviteStatusDisplay } from "../../../../../utils/NamesUtils";

type PropsFromState = {
  schools: Array<School>;
  teachersRoster: Array<TeacherInfo>;
  selectedTeacher?: TeacherInfo;
};

type DispatchProps = {
  getSchools: () => any;
  getTeachers: () => any;
  openTeacherDetailsModal: () => any;
  openMergeTeachersModal: () => any;
  updateTeacher: (id: number, updatedInfo: RenewedTeacherInfo) => any;
  setSelectedTeacher: (selectedTeacher?: TeacherInfo) => any;
};

type Props = PropsFromState & DispatchProps;

const defaultSorted: [{ dataField: string; order: SortOrder }] = [
  {
    dataField: "educator",
    order: "asc",
  },
];

const options = {
  sizePerPageList: [
    {
      text: "20",
      value: 20,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
  ],
};

type State = {
  showMergeModal: boolean;
  teacherToMerge?: number;
};

class TeachersTable extends Component<Props, State> {
  defState: Readonly<State> = {
    showMergeModal: false,
  };

  state: Readonly<State> = this.defState;

  private columnEvents = {
    onClick: (
      e: React.MouseEvent,
      column: any,
      columnIndex: number,
      row: any,
      rowIndex: number
    ) => {
      const selectedTeacher = this.props.teachersRoster.find(
        (teacher) => teacher.user.id === row.id
      );
      this.props.setSelectedTeacher(selectedTeacher!);
      this.props.openTeacherDetailsModal();
    },
  };

  columns = [
    {
      dataField: "educator",
      text: "Educator",
      sort: true,
      events: this.columnEvents,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      events: this.columnEvents,
    },
    {
      dataField: "school",
      text: "School",
      sort: true,
      events: this.columnEvents,
    },
    {
      dataField: "numClasses",
      text: "# of Classes",
      sort: true,
      events: this.columnEvents,
    },
    {
      dataField: "numStudents",
      text: "# of Students",
      sort: true,
      events: this.columnEvents,
    },
    {
      dataField: "inviteStatus",
      text: "Status",
      sort: true,
      events: this.columnEvents,
    },
    {
      dataField: "merge",
      text: "Merge",
      hidden: IS_READY_COACH,
    },
  ];

  componentDidMount() {
    if (IS_READY_COACH) {
      this.props.getSchools();
    }
  }

  showSchools = (teacher: TeacherInfo) => {
    if (teacher.user.profile.current_assignment) {
      const schoolsNames: Array<String> = teacher.user.profile.current_assignment!.schools.map(
        (school) => school.name
      );
      if (schoolsNames) {
        return schoolsNames.join(", ");
      }
    }
    return "";
  };

  handleOpenMergeModal = (teacher: TeacherInfo) => {
    this.props.setSelectedTeacher(teacher);
    this.props.openMergeTeachersModal();
  };

  render() {
    const { teachersRoster } = this.props;

    const getEmailDisplay = (email: string) => {
      if (email.indexOf("njtss_user_imported") >= 0) {
        return <em>Imported From File</em>;
      } else {
        return <span>{email}</span>;
      }
    };

    const data = teachersRoster.map((teacher) => ({
      id: teacher.user.id,
      educator: `${teacher.user.first_name} ${teacher.user.last_name}`,
      email: getEmailDisplay(teacher.user.email),
      school: this.showSchools(teacher),
      numClasses: teacher.info.classes,
      numStudents: teacher.info.students,
      inviteStatus: getInviteStatusDisplay(teacher),
      merge: !IS_READY_COACH ? (
        <button
          key={`teacher_merge_${teacher.user.id}`}
          className="blueBtnSm"
          onClick={() => this.handleOpenMergeModal(teacher)}
        >
          Merge
        </button>
      ) : null,
    }));

    return (
      <>
        <BootstrapTable
          classes="rosterTable"
          wrapperClasses={teachersRoster.length === 0 ? "rosterTableEmpty" : ""}
          keyField="id"
          bordered={false}
          bootstrap4
          data={data}
          columns={this.columns}
          defaultSorted={defaultSorted}
          pagination={paginationFactory(options)}
          noDataIndication={<LoadingIndicator />}
        />
      </>
    );
  }
}

export default TeachersTable;
