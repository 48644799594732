import React, { FunctionComponent, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { assignStudentsToTeacher } from "../../../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import { Student } from "../../../../../../../store/onboarding/types";
import { UserInfo } from "../../../../../../../store/auth/types";

type StateProps = {
  isLoading: {
    assignStudentsToTeacher: boolean;
  };
};

type DispatchProps = {
  assignStudentsToTeacher: (
    id: number,
    students: Array<Student>,
    teacherInfo?: UserInfo,
    isNewStudentAdd?: boolean,
  ) => any;
};

type OwnProps = {
  selectedTeacher: UserInfo;
  onClose: Function;
  isNewStudentAdd?: boolean
};

type Props = OwnProps & StateProps & DispatchProps;

const AddStudentTooltip: FunctionComponent<Props> = (props) => {
  const [selectedStudent, setSelectedStudent] = useState<Student>({
    first_name: "",
    last_name: "",
    unique_id: "",
  });

  const handleStudentChangesAccept = (event: React.FormEvent<any>) => {
    event.preventDefault();

    props
      .assignStudentsToTeacher(
        props.selectedTeacher.id,
        [selectedStudent],
        props.selectedTeacher,
        props.isNewStudentAdd
      )
      .then(() => props.onClose());
  };

  const handleSelectedStudentChange = (event: React.FormEvent<any>) => {
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;

    setSelectedStudent((s) => ({ ...s, [name]: value }));
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center m-0">
        <h4 className="font-weight-bold m-0">Add student</h4>
        <Button
          disabled={props.isLoading.assignStudentsToTeacher}
          onClick={() => props.onClose()}
        >
          <FontAwesomeIcon icon={faTimesCircle} className="pointer" />
        </Button>
      </div>
      <Form id="editStudentDetails" onSubmit={handleStudentChangesAccept}>
        <Form.Group>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            required
            type="text"
            name="first_name"
            placeholder="Enter first name"
            onChange={handleSelectedStudentChange}
            value={selectedStudent.first_name}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            required
            type="text"
            name="last_name"
            placeholder="Enter last name"
            onChange={handleSelectedStudentChange}
            value={selectedStudent.last_name}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Student ID Number</Form.Label>
          <Form.Control
            required
            type="text"
            name="unique_id"
            placeholder="Enter Student ID number"
            onChange={handleSelectedStudentChange}
            value={selectedStudent.unique_id}
          />
        </Form.Group>
      </Form>

      <button
        type="submit"
        form={"editStudentDetails"}
        className="blueBtnSmFull"
      >
        Add Student
        {props.isLoading.assignStudentsToTeacher && (
          <Spinner animation="border" size="sm" />
        )}
      </button>
    </div>
  );
};

const mapStateToProps = (
  { onboarding }: ApplicationState,
  props: OwnProps
): StateProps => {
  return {
    isLoading: {
      assignStudentsToTeacher: onboarding.isLoading.assignStudentsToTeacher.includes(
        props.selectedTeacher.id
      ),
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      assignStudentsToTeacher: assignStudentsToTeacher,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddStudentTooltip);
