import React, { FunctionComponent, useCallback, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { showFbaObservationModal } from "../../../../../../store/onboarding/cases/actions";
import { Fba } from "../../../../../../store/onboarding/cases/types";
import { useDispatch } from "react-redux";
import { Overlay, Popover } from "react-bootstrap";

type Props = {
  fba: Fba;
};

const AddAbcBtn: FunctionComponent<Props> = (props) => {
  const { fba } = props;
  const dispatch = useDispatch();

  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const overlayTarget = useRef(null);

  const handleSingleAbcAdd = () => {
    setShowOverlay(false);
    dispatch(
      showFbaObservationModal({
        fba: fba,
        isSingleAbc: true,
        observation: fba.quick_log_observation,
      })
    );
  };

  const handleOverlayShow = useCallback(() => {
    setShowOverlay((show) => !show);
  }, []);

  const handleObservationCreate = () => {
    setShowOverlay(false);
    dispatch(showFbaObservationModal({ fba: fba, observation: undefined }));
  };

  return (
    <>
      <button
        className="blueBtnMd my-2"
        style={{ width: "12rem" }}
        onClick={handleOverlayShow}
      >
        Add ABC
        <span className="ml-2" ref={overlayTarget}>
          <FontAwesomeIcon icon={faChevronDown} style={{ color: "white" }} />
        </span>
      </button>
      {showOverlay && <div className="customDarkPopoverBackdrop" />}
      <Overlay
        target={overlayTarget?.current as any}
        show={showOverlay}
        placement={"bottom"}
        rootClose
        rootCloseEvent={"click"}
        onHide={() => setShowOverlay(false)}
      >
        <Popover id="add-abc-overlay">
          <Popover.Content className="d-flex flex-column">
            <button className="blueBtnSm mb-2" onClick={handleSingleAbcAdd}>
              Log Single ABC Now
            </button>
            <button className="blueBtnSm" onClick={handleObservationCreate}>
              Start an Observation Session
            </button>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

export default AddAbcBtn;
