import React, { FunctionComponent, useState } from "react";
import ReportMessageAlert from "./ReportMessageAlert";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { ChatThread } from "../../../../store/chat/types";
import ChatHeader from "./ChatHeader";
import ChatMainWindow from "./ChatMainWindow";
import MessageInput from "./MessageInput";

type OwnProps = {};

type Props = OwnProps;

const ChatArea: FunctionComponent<Props> = (props) => {
  const [reportedMessage, setReportedMessage] = useState<
    | {
        id: number;
        userName: string;
      }
    | undefined
  >(undefined);

  const currentThread = useSelector<ApplicationState, ChatThread | undefined>(
    (s) => s.chat.selectedThread
  );

  if (!currentThread) {
    return (
      <div className="dataContainer w-100" style={{ visibility: "hidden" }} />
    );
  }

  return (
    <div className="dataContainer w-100">
      <ChatHeader chatThread={currentThread} />
      <hr />
      <ChatMainWindow
        chatThread={currentThread}
        onReportDialogOpen={setReportedMessage}
      />
      <hr />
      <MessageInput currentThreadId={currentThread.id!} />

      {reportedMessage && (
        <ReportMessageAlert
          onClose={() => setReportedMessage(undefined)}
          reportedMessage={reportedMessage}
        />
      )}
    </div>
  );
};

export default ChatArea;
