import React, { FunctionComponent, useMemo } from "react";
import CreatableSelect from "react-select/creatable";

type Props = {
  index?: number;
  onChange: (value?: string, index?: number) => any;
  value?: string;
  options: string[];
};

const OtherOptionSelect: FunctionComponent<Props> = (props) => {
  const { value, options, index, onChange } = props;

  const handleCustomOptionChange = (value: any) => {
    onChange(value?.label, index);
  };

  const handleCustomOptionCreate = (value: string) => {
    onChange(value, index);
  };

  const selectOptions = useMemo(() => {
    return options.map((text, index) => ({ label: text, value: index }));
  }, [options]);

  return (
    <CreatableSelect
      isClearable
      placeholder="Select or type custom notes..."
      name="fba-custom-option"
      options={selectOptions}
      value={selectOptions.find((so) => so.label === value)}
      onChange={handleCustomOptionChange}
      onCreateOption={handleCustomOptionCreate}
      getNewOptionData={(inputValue, optionLabel) =>
        ({
          label: optionLabel,
          value: inputValue,
        } as any)
      }
    />
  );
};

export default OtherOptionSelect;
