import React from "react";
import Table from "react-bootstrap/Table";
import { Measurement, ReactSelectOption } from "../../../../../store/onboarding/types";
import ColorCriteriaRow from "./ColorCriteriaRow";
import Button from "react-bootstrap/Button";
import { IS_READY_COACH } from "../../../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { SkillFocusArea } from "../../../../../store/groups/types";

type Props = {
  measurements: Array<Measurement>;
  columnsNames: Array<string>;
  selectedColumnNames: Array<string>;
  handleMeasurementsChange: (
    index: number,
    select?: boolean
  ) => (event: React.SyntheticEvent) => void;
  handlePossibleValuesChange: (measurement: Measurement) => (index: number) =>any;
  handleMeasurementAdd: () => any;
  handleMeasureEdit: (measurement: Measurement, index: number) => any;
  handleMeasurementRemove: (index: number) => any;
  basicColumnsHeadersNum: Readonly<number>;
  useGradeSpecificColor?: boolean;
  withReadingAlgorithm?: boolean;
  skill_areas?: ReactSelectOption<string>[];
  handleSkillAreaChange: (
    index: number,
    skill: string
  ) => any;
  newMeasurement?: Measurement;
};

const MeasurementsTable = (props: Props) => {
  const {
    measurements,
    columnsNames,
    selectedColumnNames,
    handleMeasurementsChange,
    handlePossibleValuesChange,
    handleMeasurementAdd,
    handleMeasureEdit,
    handleMeasurementRemove,
    basicColumnsHeadersNum,
    useGradeSpecificColor,
    withReadingAlgorithm,
    skill_areas,
    handleSkillAreaChange,
    newMeasurement
  } = props;
  const hasUnassignedColumns =
    measurements.length < columnsNames.length - basicColumnsHeadersNum;
  return (
    <div className="selectDataColumnTableContainer">
      <Table className="selectDataColumnTable">
        <thead>
          <tr>
            <th>#</th>
            <th>Column Name</th>
            <th>Display Name</th>
            {!IS_READY_COACH && (
              <th>
                <span className="mr-1">Skill Area</span>
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={
                    <Tooltip className="customTooltip" id="tooltip-top">
                      Skill Area is referred to as lorem ipsum solor sit amet,
                      consectetur adipiscing elit. Donec ac laculis dolor.
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </OverlayTrigger>
              </th>
            )}
            <th>Type</th>
            {withReadingAlgorithm && <th>Reading Areas</th>}
            <th>Range</th>
            <th>Color criteria</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {measurements.map((measurement: Measurement, index: number) => {
            if((measurement.color_criteria !== undefined) && (newMeasurement?.color_criteria !== undefined) && (measurement.color_criteria.length < newMeasurement.color_criteria!.length)) {
              newMeasurement?.color_criteria!.filter((element) => {
                
                if(!measurement.color_criteria!.find((data)=> data.grade == element.grade))  {
                  measurement.color_criteria!.push(element)
                }
                
              });
            }
            return <ColorCriteriaRow
              key={measurement.id}
              index={index}
              measurement={measurement}
              columnNames={columnsNames}
              selectedColumnNames={selectedColumnNames}
              onMeasureChange={handleMeasurementsChange}
              onPossibleValuesChange={handlePossibleValuesChange}
              onClick={handleMeasureEdit(measurement, index)}
              onMeasurementRemove={handleMeasurementRemove}
              withReadingAlgorithm={withReadingAlgorithm}
              useGradeSpecificColor={useGradeSpecificColor}
              skill_areas={skill_areas}
              onSkillAreaChange={handleSkillAreaChange}
            />
          })}
        </tbody>
      </Table>
      {hasUnassignedColumns && (
        <div className="text-right">
          <label className="blueBtnSm">
            <Button onClick={handleMeasurementAdd}>Add</Button>
          </label>
        </div>
      )}
      <hr />
    </div>
  );
};

export default MeasurementsTable;
