import { OverlayTrigger, Popover } from "react-bootstrap";
import React, { useMemo, useRef, useState } from "react";
import { getFullNameForStudent } from "../../../../../../utils/StudentMapUtils";
import { Student } from "../../../../../../store/onboarding/types";
import EditStudentDropdown from "./edit-student-dropdown/EditStudentDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import { Grade } from "../../../../../../store/groups/types";

type Props = { student: Student; canEdit?: boolean; grade?: Grade };

const NAME_DISPLAYED_CHARACTERS_NUM = 16;
const replacedString = "...";

const StudentName = (props: Props) => {
  const intl = useIntl();
  const { student, canEdit, grade } = props;
  const canEditReal = canEdit !== undefined ? canEdit : true;

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const editStudentRef = useRef(null);

  const studentName = useMemo(() => {
    const name = getFullNameForStudent(student);
    if (name.length > NAME_DISPLAYED_CHARACTERS_NUM)
      return (
        name.slice(0, NAME_DISPLAYED_CHARACTERS_NUM - replacedString.length) +
        replacedString
      );
    return name;
  }, [student]);

  return (
    <>
      <OverlayTrigger
        overlay={
          <Popover id={"studentUniqueIdPopover"}>
            <Popover.Content className="d-flex flex-column">
              <h4>{getFullNameForStudent(student)}</h4>
              <span>
                {intl.formatMessage(
                  {
                    id: "app.forms.uniqueId.label",
                  },
                  {
                    count: (
                      <strong key={student.id}>{student.unique_id}</strong>
                    ),
                  }
                )}
              </span>
            </Popover.Content>
          </Popover>
        }
      >
        <div className="d-flex" style={{ fontSize: "10pt" }}>
          <div className="d-inline-block text-truncate">{studentName}</div>
          {canEditReal && (
            <div
              ref={editStudentRef}
              className="ml-1"
              onClick={() => setShowDropdown(true)}
            >
              <FontAwesomeIcon
                icon={faCaretDown}
                className="pointer"
                size="sm"
                style={{ color: "#333333" }}
              />
            </div>
          )}
        </div>
      </OverlayTrigger>
      {canEditReal && (
        <EditStudentDropdown
          editStudentRef={editStudentRef}
          student={student}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          grade={grade}
        />
      )}
    </>
  );
};

export default StudentName;
