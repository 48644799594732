import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

type OwnProps = {};

type Props = OwnProps;

const PrivacyPolicy: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  return (
    <div className="terms-and-conditions-body">
      <h3 className="text-center text-uppercase my-3">
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.title",
        })}
      </h3>
      <p>
        {intl.formatMessage(
          {
            id: "app.terms-and-conditions.privacy-policy.intro",
          },
          { linebreak: <br /> }
        )}
      </p>
      <p>
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.inform-of",
        })}
      </p>
      <ol className="normal">
        <li>
          {intl.formatMessage({
            id: "app.terms-and-conditions.privacy-policy.inform-of.1",
          })}
        </li>
        <li>
          {intl.formatMessage({
            id: "app.terms-and-conditions.privacy-policy.inform-of.2",
          })}
        </li>
        <li>
          {intl.formatMessage({
            id: "app.terms-and-conditions.privacy-policy.inform-of.3",
          })}
        </li>
        <li>
          {intl.formatMessage({
            id: "app.terms-and-conditions.privacy-policy.inform-of.4",
          })}
        </li>
        <li>
          {intl.formatMessage({
            id: "app.terms-and-conditions.privacy-policy.inform-of.5",
          })}
        </li>
      </ol>
      <p className="bold-underline">
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.information-you-input",
        })}
      </p>
      <p>
        {intl.formatMessage({
          id:
            "app.terms-and-conditions.privacy-policy.information-you-input.content",
        })}
      </p>
      <p className="bold-underline">
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.information-we-collect",
        })}
      </p>
      <p>
        {intl.formatMessage(
          {
            id:
              "app.terms-and-conditions.privacy-policy.information-we-collect.content",
          },
          { linebreak: <br /> }
        )}
      </p>
      <p className="bold-underline">
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.security",
        })}
      </p>
      <p>
        {intl.formatMessage(
          {
            id: "app.terms-and-conditions.privacy-policy.security.content",
          },
          { linebreak: <br /> }
        )}
      </p>
      <p className="bold-underline">
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.why-we-collect",
        })}
      </p>
      <p>
        {intl.formatMessage(
          {
            id:
              "app.terms-and-conditions.privacy-policy.why-we-collect.content",
          },
          { linebreak: <br /> }
        )}
      </p>
      <p className="bold-underline">
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.limitations",
        })}
      </p>
      <p>
        {intl.formatMessage(
          {
            id: "app.terms-and-conditions.privacy-policy.limitations.content",
          },
          { linebreak: <br /> }
        )}
      </p>
      <p className="bold-underline">
        {intl.formatMessage({
          id:
            "app.terms-and-conditions.privacy-policy.disclosure-of-information",
        })}
      </p>
      <p>
        {intl.formatMessage(
          {
            id:
              "app.terms-and-conditions.privacy-policy.disclosure-of-information.content",
          },
          { linebreak: <br /> }
        )}
      </p>
      <ul>
        <li>
          {intl.formatMessage({
            id:
              "app.terms-and-conditions.privacy-policy.disclosure-of-information.option_1",
          })}
        </li>{" "}
        <li>
          {intl.formatMessage({
            id:
              "app.terms-and-conditions.privacy-policy.disclosure-of-information.option_2",
          })}
        </li>
        <li>
          {intl.formatMessage({
            id:
              "app.terms-and-conditions.privacy-policy.disclosure-of-information.option_3",
          })}
        </li>
        <li>
          {intl.formatMessage({
            id:
              "app.terms-and-conditions.privacy-policy.disclosure-of-information.option_4",
          })}
        </li>
        <li>
          {intl.formatMessage({
            id:
              "app.terms-and-conditions.privacy-policy.disclosure-of-information.option_5",
          })}
        </li>
        <li>
          {intl.formatMessage({
            id:
              "app.terms-and-conditions.privacy-policy.disclosure-of-information.option_6",
          })}
        </li>
        <li>
          {intl.formatMessage({
            id:
              "app.terms-and-conditions.privacy-policy.disclosure-of-information.option_7",
          })}
        </li>
      </ul>
      <p className="bold-underline">
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.non-marketing-purposes",
        })}
      </p>
      <p>
        {intl.formatMessage(
          {
            id:
              "app.terms-and-conditions.privacy-policy.non-marketing-purposes.content",
          },
          { linebreak: <br /> }
        )}
      </p>
      <p className="bold-underline">
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.students’-information",
        })}
      </p>
      <p>
        {intl.formatMessage(
          {
            id:
              "app.terms-and-conditions.privacy-policy.students’-information.content",
          },
          { linebreak: <br /> }
        )}
      </p>
      <p className="bold-underline">
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.unsubscribe-or-opt-out",
        })}
      </p>
      <p>
        {intl.formatMessage(
          {
            id:
              "app.terms-and-conditions.privacy-policy.unsubscribe-or-opt-out.content",
          },
          { linebreak: <br /> }
        )}
      </p>
      <p className="bold-underline">
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.links-to-other-websites",
        })}
      </p>
      <p>
        {intl.formatMessage(
          {
            id:
              "app.terms-and-conditions.privacy-policy.links-to-other-websites.content",
          },
          { linebreak: <br /> }
        )}
      </p>
      <p className="bold-underline">
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.european-union-users",
        })}
      </p>
      <p>
        {intl.formatMessage(
          {
            id:
              "app.terms-and-conditions.privacy-policy.european-union-users.content",
          },
          { linebreak: <br /> }
        )}
      </p>
      <p className="bold-underline">
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.acceptance-of-terms",
        })}
      </p>
      <p>
        {intl.formatMessage(
          {
            id:
              "app.terms-and-conditions.privacy-policy.acceptance-of-terms.content",
          },
          { linebreak: <br /> }
        )}
      </p>
      <p className="bold-underline">
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.how-to-contact-us",
        })}
      </p>
      <p>
        {intl.formatMessage(
          {
            id:
              "app.terms-and-conditions.privacy-policy.how-to-contact-us.content",
          },
          { linebreak: <br /> }
        )}
      </p>
      <p>
        <strong>
          {intl.formatMessage({
            id: "app.terms-and-conditions.privacy-policy.email",
          })}
        </strong>
        &nbsp;
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.email.content",
        })}
      </p>
      <p>
        <strong>
          {intl.formatMessage({
            id: "app.terms-and-conditions.privacy-policy.telephone",
          })}
        </strong>
        &nbsp;
        {intl.formatMessage({
          id: "app.terms-and-conditions.privacy-policy.telephone.content",
        })}
      </p>
      <p>
        <strong>
          {intl.formatMessage({
            id: "app.terms-and-conditions.privacy-policy.mailing-address",
          })}
        </strong>
        &nbsp;
        {intl.formatMessage(
          {
            id:
              "app.terms-and-conditions.privacy-policy.mailing-address.content",
          },
          { linebreak: <br /> }
        )}
      </p>
    </div>
  );
};

export default PrivacyPolicy;
