import React, { ChangeEvent, useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { IS_READY_COACH } from "../../../../../../constants";

type OwnProps = {
  showModal: boolean;
  isLoading?: boolean;
  time: string;
  onSave: (time: string | null) => any;
  onHide: () => any;
};

type Props = OwnProps;

const SetTimeModal = (props: Props) => {
  const [time, setTime] = useState<string>("");

  useEffect(() => {
    if (props.time !== time) {
      setTime(props.time);
    }
  }, [props.time]);

  useEffect(() => {
    if (props.showModal && !props.isLoading) {
      props.onHide();
    }
  }, [props.isLoading]);

  const handleModalShow = () => {
    setTime(props.time || "");
  };

  const handleChangeTime = (event: ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value);
  };

  const handleSave = () => {
    if (!props.isLoading) {
      props.onSave(time !== "" ? time : null);
    }
  };

  return (
    <Modal
      show={props.showModal}
      onShow={handleModalShow}
      onHide={props.onHide}
      animation={false}
      size="sm"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header
        closeButton
        className={`${
          IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"
        } font-weight-bold`}
      >
        Set Time
      </Modal.Header>
      <Modal.Body>
        <div className="text-center m-3">
          <input
            className="w-100"
            type="time"
            value={time}
            onChange={handleChangeTime}
          />
        </div>
        <div className="d-flex">
          <button
            className="blueBtnSm w-100 mr-2"
            disabled={props.isLoading}
            onClick={props.onHide}
          >
            Cancel
          </button>
          <button className="blueBtnSm w-100" onClick={handleSave}>
            Save {props.isLoading && <Spinner animation="border" size="sm" />}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SetTimeModal;
