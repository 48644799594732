import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { Form, FormControl, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import {
  getRaceInfo,
  hideStudentEditModal,
  updateTeacherStudentDetails,
} from "../../../../../store/onboarding/actions";
import {
  genderList,
  Race,
  raceList,
  ReactSelectOption,
  Student,
} from "../../../../../store/onboarding/types";
import Select from "react-select";
import { useLoading } from "../../../../../utils/hooks/useLoading";
import useUserRole from "../../../../../utils/hooks/useUserRole";

type OwnProps = {};

type Props = OwnProps;

const StudentEditModal: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const show = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.onboarding.modalsState.showStudentEditModal
  );

  const student = useSelector<ApplicationState, Student | undefined>(
    (s) => s.onboarding.selectedStudent
  );
  const hasUpdatedStudentID = useRef(false);

  const [isUpdateStudentID, setUpdateStudentID] = useState<boolean>();

  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [uniqueId, setUniqueId] = useState<string | undefined>();
  const [race, setRace] = useState<number | undefined>();
  const [raceOtherValue, setRaceOtherValue] = useState<string | undefined>();
  const [gender, setGender] = useState<string | undefined>();
  const [freeOrReducedLunch, setFreeOrReducedLunch] = useState<boolean | undefined>(false);
  const [specialEducation, setSpecialEducation] = useState<boolean | undefined>(false);
  const [ell, setELL] = useState<boolean | undefined>(false);
  const [sld, setSLD] = useState<boolean | undefined>(false);

  const currentDataPeriod = useSelector(
    (s: ApplicationState) => s.onboarding.currentDataPeriod
  );

  const { isCoach, isTeacher } = useUserRole();

  const raceInfo = useSelector(
    (s: ApplicationState) => s.onboarding.raceInfo
  );
  useEffect(() => {
    if(currentDataPeriod && !raceInfo.races.length){
      dispatch(getRaceInfo(currentDataPeriod.id))
    }
    setUpdateStudentID(false);
    hasUpdatedStudentID.current = false;
  },[show]);

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFirstName(value);
  };
  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLastName(value);
  };
  const handleUniqueIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUniqueId(value);
  };

  const handleModalShow = () => {
    if (student) {
      setFirstName(student.first_name);
      setLastName(student.last_name);
      setUniqueId(student.unique_id);
      setRace(student.race);
      setRaceOtherValue(student.race_other_value);
      setGender(student.gender);
      setSpecialEducation(student.special_education);
      setELL(student.ell);
      setSLD(student.sld);
      setFreeOrReducedLunch(student.free_or_reduced_lunch);
    }
  };
  const handleModalClose = () => {
    dispatch(hideStudentEditModal());
  };

  const isLoadingUpdateStudentDetails = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.onboarding.isLoading.updateStudentDetails);
  const errorUpdateStudentDetails = useSelector<
    ApplicationState,
    string | undefined
  >((s) => s.onboarding.errors.updateStudentDetails);
  useLoading(
    isLoadingUpdateStudentDetails,
    errorUpdateStudentDetails,
    handleModalClose
  );

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const updatedStudent: Student = {
      id: student?.id,
      unique_id: uniqueId,
      first_name: firstName!,
      last_name: lastName!,
      race: race,
      race_other_value: raceOtherValue,
      gender: gender,
      special_education: specialEducation!,
      ell: ell!,
      sld: sld!,
      free_or_reduced_lunch: freeOrReducedLunch
    };
    dispatch(updateTeacherStudentDetails(updatedStudent));
  };

  const options: ReactSelectOption<number>[] = useMemo(() => {
    if(raceInfo?.races.length) {
      return [...raceInfo.races.map((race, index) => ({
        value: index, label: race 
     })), {value: 7, label: 'Other'}]
    } else {
      return [];
    }
  },[]);

  const genderOptions: ReactSelectOption<string>[] = useMemo(() => genderList, []);

  useEffect(() => {
    if(!hasUpdatedStudentID.current) {
      if(((student?.unique_id != uniqueId) || (!uniqueId)) && !isUpdateStudentID) {
        setUpdateStudentID(true);
        hasUpdatedStudentID.current = true;
      }
    }
  },[uniqueId, student?.unique_id, isUpdateStudentID])
  return (
    <Modal
      animation={false}
      backdropClassName="customDarkModalBackdrop in"
      show={show}
      onShow={handleModalShow}
      onHide={handleModalClose}
    >
      <Modal.Header closeButton className="purpleModalHeader orangeModalHeader">
        <Modal.Title>Edit Student</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
        {isUpdateStudentID 
            ? 
            <>
              <p className="text-center">
                You are changing the ID of <span className="font-weight-bold">{firstName+' '+lastName}</span>, 
                and this update will be applied anywhere that <span className="font-weight-bold">{firstName+' '+lastName}</span> appears in the platform 
                (*e.g., data period, intervention groups). Are you sure you want to continue?
              </p>
              <div className="btnActions">
                <button className="whiteBtnSm" onClick={() => {
                  setUniqueId(student?.unique_id);
                  setUpdateStudentID(false);
                }}>Cancel</button>
                <button className="blueBtnSm" onClick={() => setUpdateStudentID(false)}>
                Continue</button>
              </div>
            </>

            :
            <>
              <Form.Group>
                <Form.Label className="font-weight-bold text-left">
                  First Name
                </Form.Label>
                <Form.Control
                  required
                  value={firstName}
                  onChange={handleFirstNameChange}
                  name={"firstName"}
                  placeholder="Enter first name..."
                  type="text"
                  disabled={isCoach || isTeacher}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="font-weight-bold text-left">
                  Last Name
                </Form.Label>
                <Form.Control
                  required
                  value={lastName}
                  onChange={handleLastNameChange}
                  name={"lastName"}
                  placeholder="Enter last name..."
                  type="text"
                  disabled={isCoach || isTeacher}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="font-weight-bold text-left">
                  Student ID
                </Form.Label>
                <Form.Control
                  required
                  value={uniqueId}
                  onChange={handleUniqueIdChange}
                  name={"uniqueId"}
                  placeholder="Enter Student ID number..."
                  disabled={isCoach || isTeacher}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-weight-bold text-left">Race</Form.Label>
                <Select
                  isClearable
                  className="flex-1"
                  placeholder="Not Set"
                  options={options}
                  value={options.find((r) => r.value === race)}
                  onChange={(value: any) => {
                    setRace(value ? value.value : undefined);
                    setRaceOtherValue(undefined);
                  }}
                />
                {race === Race.Other && (
                  <FormControl
                    className="mt-1"
                    placeholder="Enter Race Name..."
                    value={raceOtherValue ?? ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      setRaceOtherValue(value);
                    }}
                  />
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label className="font-weight-bold text-left">Gender</Form.Label>
                <Select
                  isClearable
                  className="flex-1"
                  placeholder="Not Set"
                  options={genderOptions}
                  value={genderOptions.find((g) => g.value === gender)}
                  onChange={(value: any) => {
                    setGender(value ? value.value : undefined);
                  }}
                />
              </Form.Group>
              <Form.Group>
                <div className="row mb-2">
                  <div className="col-6 pr-0">
                    <Form.Check 
                      type="switch"
                      id="special_education"
                      label="Special Education"
                      checked={specialEducation ?? false}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setSpecialEducation(checked);
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <Form.Check 
                      type="switch"
                      id="ell"
                      label="ELL"
                      checked={ell ?? false}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setELL(checked);
                      }}
                    />
                    
                  </div>
                  <div className="col-3">
                    <Form.Check 
                      type="switch"
                      id="sld"
                      label="SLD"
                      checked={sld ?? false}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setSLD(checked);
                      }}
                    />
                    
                  </div>
                  <div className="col-8">
                    <Form.Check 
                      type="switch"
                      id="freeOrReducedLunch"
                      label="Free or Reduced Lunch"
                      checked={freeOrReducedLunch ?? false}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setFreeOrReducedLunch(checked);
                      }}
                    />
                    
                  </div>
                </div>    
              </Form.Group>
              <div className="modalActions">
                <button
                  className="whiteBtnSm"
                  type="reset"
                  onClick={handleModalClose}
                >
                  Close
                </button>
                <button className="blueBtnSm" type="submit">
                  Save
                  {isLoadingUpdateStudentDetails && (
                    <Spinner animation="border" size="sm" className="ml-1" />
                  )}
                </button>
              </div>
            </>
          }
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default StudentEditModal;
