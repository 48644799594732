import React, { FormEvent, useEffect, useState } from "react";
import { Form, Overlay, Popover, Spinner } from "react-bootstrap";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  EvidencePeriodData,
  ReactSelectOption,
  Student,
  StudentInfo,
} from "../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../store";
import CreatableSelect from "react-select/creatable";
import {
  addStudents,
  addStudentsToDataPeriod,
  addStudentToParentRequest,
  getStudents,
  openUploadStudentsRosterModal,
} from "../../../../../../store/onboarding/actions";
import { ValueType } from "react-select/src/types";
import { toastr } from "react-redux-toastr";
import { Placement } from "react-bootstrap/Overlay";
import {
  InterventionGroup,
  InterventionGroupPatchRequest,
} from "../../../../../../store/onboarding/cases/types";
import { patchInterventionGroup } from "../../../../../../store/onboarding/cases/actions";
import { UserInfo } from "../../../../../../store/auth/types";
import { getFullName } from "../../../../../../utils/NamesUtils";

type PropsFromState = {
  evidencePeriodData?: EvidencePeriodData;
  interventionGroup?: InterventionGroup;
  studentsRoster: StudentInfo[];
  isLoading: {
    getStudents: boolean;
    addStudents: boolean;
    addStudentsToDataPeriod: boolean;
    patchInterventionGroup: boolean;
    addStudentToParent?: boolean;
  };
  errors: {
    getStudents?: string;
    addStudents?: string;
    addStudentsToDataPeriod?: string;
  };
};

type DispatchProps = {
  getStudents: (includeClasses?: boolean) => any;
  addStudents: (
    students: Array<Student>,
    addStudentsToEvidence?: boolean
  ) => any;
  addStudentsToDataPeriod: (studentsIds: number[]) => any;
  openUploadStudentsRosterModal: () => any;
  patchInterventionGroup: (
    groupId: number,
    request: InterventionGroupPatchRequest
  ) => any;
  addStudentToParent: (children: number[], parentId: number) => any;
};

type OwnProps = {
  placement?: Placement;
  selectedParent?: UserInfo;
  forStudentGroup?: boolean;
  showOverlay: boolean;
  buttonRef: React.MutableRefObject<any>;
  onHide: () => any;
  isNewStudent?: boolean;
 // isExistingStudent?: boolean;
};

type Props = PropsFromState & DispatchProps & OwnProps;

enum Content {
  SelectStudent,
  NewStudent,
  Children,
}

const AddStudentsToTableOverlay = (props: Props) => {
  const [content, setContent] = useState<Content>(Content.SelectStudent);
  const [studentOptions, setStudentOptions] = useState<
    ReactSelectOption<StudentInfo>[]
  >();
  const [selectedStudent, setSelectedStudent] = useState<StudentInfo>();
  const [selectedChildren, setSelectedChildren] = useState<
    ReactSelectOption<StudentInfo>[]
  >([]);
  const [studentsRoster, setStudentRoster] = useState<StudentInfo[]>([]);
  const [newStudent, setNewStudent] = useState<Student>({
    first_name: "",
    last_name: "",
    unique_id: "",
  });

  useEffect(() => {
    if (props.showOverlay) {
      props.getStudents();
      if (props.isNewStudent) {
        setContent(Content.NewStudent);
      } else if (props.selectedParent) {
        if (props.selectedParent.children) {
          setSelectedChildren(
            props.selectedParent.children.map((student) => {
              return { label: getFullName(student), value: student };
            })
          );
        } else {
          setSelectedChildren([]);
        }

        setContent(Content.Children);
      } else {
        setContent(Content.SelectStudent);
      }
    }
  }, [props.showOverlay]);

  useEffect(() => {
    if (props.forStudentGroup && props.interventionGroup) {
      setStudentRoster(
        props.studentsRoster.filter(
          (student) =>
            !props.interventionGroup!.students.some((s) => s.id === student.id)
        )
      );
    } else {
      setStudentRoster(
        props.evidencePeriodData
          ? props.studentsRoster.filter(
              (student) =>
                !props.evidencePeriodData!.student_rows.some(
                  (studentRow) => studentRow.student.id === student.id
                )
            )
          : props.studentsRoster
      );
    }
  }, [
    props.evidencePeriodData,
    props.studentsRoster,
    props.forStudentGroup,
    props.selectedParent,
  ]);

  useEffect(() => {
    if (props.showOverlay) {
      setStudentOptions(
        studentsRoster.map((st) => ({
          label: st.first_name + " " + st.last_name,
          value: st,
        }))
      );
    }
  }, [studentsRoster]);

  useEffect(() => {
    if (props.showOverlay) {
      if (props.errors.addStudentsToDataPeriod) {
        toastr.error("Error", props.errors.addStudentsToDataPeriod);
      } else {
        props.onHide();
      }
    }
  }, [props.isLoading.addStudentsToDataPeriod]);

  useEffect(() => {
    if (props.showOverlay) {
      if (props.errors.addStudents) {
        toastr.error("Error", props.errors.addStudents);
      }
    }
  }, [props.errors.addStudents]);

  const handleSelectStudent = (
    option: ValueType<ReactSelectOption<StudentInfo>, any>
  ) => {
    if (props.selectedParent) {
      setSelectedChildren((option as ReactSelectOption<StudentInfo>[]) ?? []);
    } else {
      setSelectedStudent((option as ReactSelectOption<StudentInfo>).value);
    }
  };

  const handleCreateStudent = (input: string) => {
    setContent(Content.NewStudent);
    const splitInput = input.split(" ");
    setNewStudent({
      first_name: splitInput[0],
      last_name: splitInput[1] || "",
      unique_id: splitInput[2] || "",
    });
  };

  const handleChange = (event: FormEvent<any>) => {
    const target = event.target as HTMLInputElement;
    setNewStudent((prevStudent) => ({
      ...prevStudent,
      [target.name]: target.value,
    }));
  };

  const handleAddStudent = () => {
    if (props.selectedParent && selectedChildren) {
      props.addStudentToParent(
        selectedChildren.map((sc) => sc.value.id!),
        props.selectedParent.id
      );
    } else if (
      props.forStudentGroup &&
      props.interventionGroup &&
      selectedStudent
    ) {
      props
        .patchInterventionGroup(props.interventionGroup.id!, {
          students: [...props.interventionGroup.students, selectedStudent]
            .filter((s) => s.id != null)
            .map((s) => s.id!),
        })
        .then(() => props.onHide());
    } else {
      if (selectedStudent && selectedStudent.id) {
        props.addStudentsToDataPeriod([selectedStudent.id]);
      }
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    props.addStudents([newStudent], true);
  };

  const handleUploadStudentRoster = () => {
    props.openUploadStudentsRosterModal();
    props.onHide();
  };

  const getPopover = () => {
    switch (content) {
      case Content.SelectStudent:
        return (
          <Popover id="selectStudentsPopover">
            <Popover.Title>Select student you want to add</Popover.Title>
            <Popover.Content>
              <CreatableSelect
                createOptionPosition="first"
                onChange={handleSelectStudent}
                onCreateOption={handleCreateStudent}
                placeholder="Search Students..."
                formatCreateLabel={(input: string) =>
                  `Add new student '${input}'`
                }
                allowCreateWhileLoading
                options={studentOptions}
                isLoading={props.isLoading.getStudents}
              />
              <button
                className="mt-2 blueBtnSm w-100"
                disabled={!selectedStudent}
                onClick={handleAddStudent}
              >
                ADD STUDENT{" "}
                {(props.isLoading.addStudentsToDataPeriod ||
                  (props.forStudentGroup &&
                    props.isLoading.patchInterventionGroup)) && (
                  <Spinner animation="border" size="sm" />
                )}
              </button>
              {//!props.isExistingStudent && 
              !props.forStudentGroup ?
              <>
                <div className="text-center font-weight-bold">OR</div>
                <button
                  className="blueBtnSm w-100"
                  onClick={handleUploadStudentRoster}
                >
                  UPLOAD A STUDENT ROSTER
                </button>
              </>
              : ''
              }
            </Popover.Content>
          </Popover>
        );
      case Content.Children:
        return (
          <Popover id="selectStudentsPopover">
            <Popover.Title>Select student you want to add</Popover.Title>
            <Popover.Content>
              <CreatableSelect
                isMulti
                value={selectedChildren}
                createOptionPosition="first"
                onChange={handleSelectStudent}
                onCreateOption={handleCreateStudent}
                placeholder="Search Students..."
                formatCreateLabel={(input: string) =>
                  `Add new student '${input}'`
                }
                allowCreateWhileLoading
                options={studentOptions}
                isLoading={props.isLoading.getStudents}
              />
              <button
                className="mt-2 blueBtnSm w-100"
                onClick={handleAddStudent}
              >
                {props.selectedParent?.children?.length ? "UPDATE" : "ADD"}
                &nbsp;STUDENTS
                {props.isLoading.addStudentToParent && (
                  <Spinner animation="border" size="sm" className={"ml-1"} />
                )}
              </button>
            </Popover.Content>
          </Popover>
        );
      case Content.NewStudent:
        return (
          <Popover id="addStudentsPopover">
            <Popover.Title>Add Student</Popover.Title>
            <Popover.Content>
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label column={false}>First Name</Form.Label>
                  <Form.Control
                    required
                    placeholder="Enter first name..."
                    name="first_name"
                    onChange={handleChange}
                    value={newStudent.first_name}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label column={false}>Last Name</Form.Label>
                  <Form.Control
                    required
                    placeholder="Enter last name..."
                    name="last_name"
                    onChange={handleChange}
                    value={newStudent.last_name}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label column={false}>Student ID</Form.Label>
                  <Form.Control
                    required
                    placeholder="Enter Student ID number..."
                    name="unique_id"
                    onChange={handleChange}
                    value={newStudent.unique_id}
                  />
                </Form.Group>
                <button className="blueBtnSm w-100" type="submit">
                  ADD STUDENT{" "}
                  {(props.isLoading.addStudents ||
                    props.isLoading.addStudentsToDataPeriod) && (
                    <Spinner animation="border" size="sm" />
                  )}
                </button>
                {/* <hr />
                <button
                  className="blueBtnSm w-100"
                  onClick={handleUploadStudentRoster}
                >
                  UPLOAD A STUDENT ROSTER
                </button> */}
              </Form>
            </Popover.Content>
          </Popover>
        );
    }
  };

  return (
    <Overlay
      target={props.buttonRef && props.buttonRef.current}
      show={props.showOverlay}
      placement={props.placement}
      rootClose
      rootCloseEvent={"click"}
      onHide={props.onHide}
      flip
    >
      {getPopover()}
    </Overlay>
  );
};

const mapStateToProps = ({
  onboarding,
  cases,
}: ApplicationState): PropsFromState => {
  return {
    errors: {
      getStudents: onboarding.errors.getStudents,
      addStudents: onboarding.errors.addStudents,
      addStudentsToDataPeriod: onboarding.errors.addStudentsToDataPeriod,
    },
    isLoading: {
      getStudents: onboarding.isLoading.getStudents,
      addStudents: onboarding.isLoading.addStudents,
      addStudentsToDataPeriod: onboarding.isLoading.addStudentsToDataPeriod,
      patchInterventionGroup: cases.isLoading.patchInterventionGroup,
      addStudentToParent: onboarding.isLoading.addStudentToParent,
    },
    evidencePeriodData: onboarding.evidencePeriodData,
    studentsRoster: onboarding.studentsRoster,
    interventionGroup: cases.selectedInterventionGroup,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getStudents: getStudents,
      addStudents: addStudents,
      addStudentsToDataPeriod: addStudentsToDataPeriod,
      openUploadStudentsRosterModal: openUploadStudentsRosterModal,
      patchInterventionGroup: patchInterventionGroup,
      addStudentToParent: addStudentToParentRequest,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddStudentsToTableOverlay);
