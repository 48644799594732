import React, { FunctionComponent, useMemo } from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router";
import { ApplicationState, history } from "../store";
import { connect } from "react-redux";
import LoginPage from "../components/pages/LoginPage";
import InvitePage from "../components/pages/InvitePage";
import { UserAssignmentRole, UserInfo } from "../store/auth/types";
import DistrictOwnerDashboard from "../components/pages/district-owner-dashboard/DistrictOwnerDashboard";
import CoachDashboard from "../components/pages/coach-dashboard/CoachDashboard";
import NewDistrictRegistrationPage from "../components/pages/NewDistrictRegistrationPage";
import { districtRoles } from "../store/auth/constants";
import RestorePassword from "../components/pages/RestorePassword";
import ParentDashboard from "../components/pages/parent-dashboard/ParentDashboard";
import SuperuserDashboard from "../components/pages/superuser-dashboard/SuperuserDashboard";
import NotActiveLicensePage from "../components/pages/NotActiveLicensePage";
import RCPrivacyPolicy from "../components/pages/privacy-policy/RCPrivacyPolicy";
import RCTermsAndConditions from "../components/pages/terms-and-conditions/RCTermsAndConditions";

type PropsFromState = {
  isAuthenticated: boolean;
  userInfo?: UserInfo;
};

type OwnProps = PropsFromState & {
  component: React.ComponentType<any>;
};

type PrivateRouteProps = OwnProps & RouteProps;
type PrivateRoleBasedRouteProps = PrivateRouteProps & {
  userInfo?: UserInfo;
  allowedRoles: UserAssignmentRole[];
};

const PrivateRoleBasedRoute = ({
  component: Node,
  userInfo,
  allowedRoles,
  isAuthenticated,
  ...rest
}: PrivateRoleBasedRouteProps) => {
  const role = userInfo?.profile.current_assignment?.role;
  const licenseStatus = userInfo?.current_license?.status;
  const isUserActive = sessionStorage.getItem('loginStatus')
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && isUserActive && role && allowedRoles.includes(role) ? (
          role === UserAssignmentRole.SUPERUSER ||
          licenseStatus === "active" ? (
            <Node {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/not-active-license",
                state: { from: history.location },
              }}
            />
          )
        ) : (
          <Redirect to={{ pathname: "/", state: { from: history.location } }} />
        )
      }
    />
  );
};

const PrivateRoute = ({
  component: Node,
  userInfo,
  isAuthenticated,
  ...rest
}: PrivateRouteProps) => {
    const isUserActive = sessionStorage.getItem('loginStatus')
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated && isUserActive &&
          userInfo?.profile.current_assignment?.role !==
            UserAssignmentRole.SUPERUSER &&
          userInfo?.current_license?.status !== "active" ? (
            <Node {...props} />
          ) : (
            <Redirect to={{ pathname: "/", state: { from: history.location } }} />
          )
        }
      />
    )
  };

const PublicRoute = ({
  component: Node,
  isAuthenticated,
  userInfo,
  ...rest
}: PrivateRouteProps) => (
  <Route
    {...rest}
    render={(props) => {
      const isUserActive = sessionStorage.getItem('loginStatus');

      if (isAuthenticated && isUserActive && userInfo) {
        //let pathname = "/cases/groups/reading";
        let pathname = "/data";

        if (
          userInfo?.profile.current_assignment?.role &&
          districtRoles.includes(userInfo.profile.current_assignment.role)
        ) {
          pathname = "/district";
        }

        if (userInfo?.current_license?.status !== "active") {
          pathname = "/not-active-license";
        }

        if (
          UserAssignmentRole.SUPERUSER ===
          userInfo?.profile.current_assignment?.role
        ) {
          pathname = "/superuser/districts";
        }

        if (
          UserAssignmentRole.SUPERUSER ===
          userInfo?.profile.current_assignment?.role
        ) {
          pathname = "/superuser/admin-release-notes";
        }

        if (
          UserAssignmentRole.SUPERUSER ===
          userInfo?.profile.current_assignment?.role
        ) {
          pathname = "/superuser/data-extract";
        }

        if (
          UserAssignmentRole.SUPERUSER ===
          userInfo?.profile.current_assignment?.role
        ) {
          pathname = "/superuser/master-data";
        }
        return (
          <Redirect
            to={{
              pathname: pathname,
            }}
          />
        );
      } else {
        return <Node {...props} />;
      }
    }}
  />
);

const RCRoutes: FunctionComponent<PropsFromState> = ({
  isAuthenticated,
  userInfo,
}: PropsFromState) => {
  const role = userInfo?.profile.current_assignment?.role;
  const routesByRole = useMemo(() => {
    switch (role) {
      case UserAssignmentRole.PARENT:
        return [];
      case UserAssignmentRole.COACH:
        return [
          "/cases/:tabName(groups)/:groupId(\\d+)?/:subPage(meeting|student-goals|meetings|evidence|progress-monitoring)?",
          "/cases/:tabName(groups)/:groupDisplayed(archived)?",
          "/cases/:tabName(groups/behavior)/:groupDisplayed(archived)?",
          "/cases/:tabName(groups/reading)/:groupDisplayed(archived)?",
          "/cases/:tabName(interventions)",
          "/:tabName(tutorials/videos)",
          "/:tabName(release-notes)/:releaseNoteId(\\d+)?",
          "/cases/:tabName(groups/in-progress)",
          "/:tabName(parents|fba|data|badges)",
          "/:tabName(data)/:dataPeriodId(\\d+)",
          "/rosters/:tabName(teachers|students)/:id([^/]+)?",
          "/:tabName(profile)",
          "/:tabName(settings/notifications)",
          "/:tabName(spelling-test)/groups/:testGroupId(\\d+)?",
          "/:tabName(spelling-test)/groups/:testGroupId(\\d+)/:subPage(new-test)?",
          "/:tabName(spelling-test)/groups/:testGroupId(\\d+)/:subPage(tests)/:testId(\\d+)",
        ];
      case UserAssignmentRole.SUPERVISOR:
      case UserAssignmentRole.TEACHER:
        return [
          "/cases/:tabName(groups)/:groupId(\\d+)?/:subPage(meeting|student-goals|meetings|evidence)?",
          "/cases/:tabName(groups)/:groupDisplayed(archived)?",
          "/cases/:tabName(groups/behavior)/:groupDisplayed(archived)?",
          "/cases/:tabName(groups/reading)/:groupDisplayed(archived)?",
          "/cases/:tabName(interventions)",
          "/:tabName(tutorials/videos)",
          "/:tabName(release-notes)/:releaseNoteId(\\d+)?",
          "/parents",
          "/:tabName(data)",
          "/:tabName(data)/:dataPeriodId(\\d+)",
          "/rosters/:tabName(students)/:id([^/]+)?",
          "/:tabName(profile)",
          "/:tabName(settings/notifications)",
          "/:tabName(spelling-test)/groups/:testGroupId(\\d+)?",
          "/:tabName(spelling-test)/groups/:testGroupId(\\d+)/:subPage(new-test)?",
          "/:tabName(spelling-test)/groups/:testGroupId(\\d+)/:subPage(tests)/:testId(\\d+)",
        ];
      default:
        return [
          "/cases/:tabName(groups)/:groupId(\\d+)?/:subPage(meeting|student-goals|meetings|evidence)?",
          "/cases/:tabName(groups)/:groupDisplayed(archived)?",
          "/cases/:tabName(groups/behavior)/:groupDisplayed(archived)?",
          "/cases/:tabName(groups/reading)/:groupDisplayed(archived)?",
          "/cases/:tabName(interventions)",
          "/:tabName(tutorials/videos)",
          "/:tabName(release-notes)/:releaseNoteId(\\d+)?",
          "/parents",
          "/:tabName(data)",
          "/:tabName(data)/:dataPeriodId(\\d+)",
          "/:tabName(profile)",
          "/:tabName(settings/notifications)",
          "/:tabName(spelling-test)/groups/:testGroupId(\\d+)?",
          "/:tabName(spelling-test)/groups/:testGroupId(\\d+)/:subPage(new-test)?",
          "/:tabName(spelling-test)/groups/:testGroupId(\\d+)/:subPage(tests)/:testId(\\d+)",
        ];
    }
  }, [userInfo]);

  // console.log(routesByRole)

  return (
    <Switch>
      <Route exact path={"/privacy"} component={RCPrivacyPolicy} />
      <Route
        exact
        path={"/user-agreement"}
        component={RCTermsAndConditions}
      />

      <PublicRoute
        exact
        userInfo={userInfo}
        path={["/", "/login"]}
        component={LoginPage}
        isAuthenticated={isAuthenticated}
      />
      <PublicRoute
        exact
        userInfo={userInfo}
        path={["/registration"]}
        component={NewDistrictRegistrationPage}
        isAuthenticated={isAuthenticated}
      />

      <PublicRoute
        exact
        userInfo={userInfo}
        path={["/password-reset/(change-password)?/:token?/:resetEmail?"]}
        component={RestorePassword}
        isAuthenticated={isAuthenticated}
      />

      <PublicRoute
        exact
        userInfo={userInfo}
        path="/invite/:code/accept"
        component={InvitePage}
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoleBasedRoute
        exact
        path={[
          "/district", 
          "/district/:tabName(settings/notifications)", 
          "/district/:tabName([\\w-]+)",
          "/district/rosters/:tabName(students)/:id([^/]+)?",
          "/district/:tabName(tutorials/videos)",
          "/district/:tabName(release-notes)/:releaseNoteId(\\d+)?",
        ]}
        userInfo={userInfo}
        allowedRoles={[
          UserAssignmentRole.DATA_MANAGER,
          UserAssignmentRole.DISTRICT_OWNER,
          UserAssignmentRole.DISTRICT_LEADERSHIP,
        ]}
        component={DistrictOwnerDashboard}
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoleBasedRoute
        exact
        userInfo={userInfo}
        //todo
        path={routesByRole}
        allowedRoles={[
          UserAssignmentRole.TEACHER,
          UserAssignmentRole.COACH,
          UserAssignmentRole.SUPERVISOR,
        ]}
        component={CoachDashboard}
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoleBasedRoute
        exact
        userInfo={userInfo}
        path={[
          "/cases/:tabName(groups)/:groupId(\\d+)?/:subPage(meeting|student-goals|meetings|evidence|progress-monitoring)?",
          "/cases/:tabName(groups)/:groupDisplayed(archived)?",
          "/cases/:tabName(groups/behavior)/:groupDisplayed(archived)?",
          "/cases/:tabName(groups/reading)/:groupDisplayed(archived)?",
          "/cases/:tabName(interventions)",
          "/:tabName(tutorials/videos)",
          "/:tabName(release-notes)/:releaseNoteId(\\d+)?",
          "/cases/:tabName(groups/in-progress|groups/recommendations)",
          "/:tabName(fba|data|badges)",
          "/:tabName(data)/:dataPeriodId(\\d+)",
          "/rosters/:tabName(teachers|students|sharing)",
          "/:tabName(profile)",
          "/:tabName(settings)",
          "/:tabName(settings/notifications)",
        ]}
        allowedRoles={[UserAssignmentRole.PARENT]}
        component={ParentDashboard}
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoleBasedRoute
        exact
        userInfo={userInfo}
        path={[
          "/superuser/:tabName(districts)/:districtId(\\d+)?/:isEdit(edit)?",
          "/superuser/districts/:tabName(summary)",
          "/superuser/:tabName(users)/:userId(\\d+)?/:isEdit(edit)?",
          "/superuser/:tabName(admin-release-notes)/:releaseNoteId(\\d+)?/:isEdit(edit)?",
          "/superuser/:tabName(data-extract)",
          "/superuser/:tabName(master-data)",
        ]}
        allowedRoles={[UserAssignmentRole.SUPERUSER]}
        component={SuperuserDashboard}
        isAuthenticated={isAuthenticated}
      />

      <PrivateRoute
        exact
        userInfo={userInfo}
        path={["/not-active-license"]}
        component={NotActiveLicensePage}
        isAuthenticated={isAuthenticated}
      />

      <Redirect to={{ pathname: "/", state: { from: history.location } }} />
    </Switch>
  );
};

const mapStateToProps = ({ auth }: ApplicationState): PropsFromState => {
  return {
    isAuthenticated: auth.isAuthenticated,
    userInfo: auth.userInfo,
  };
};

export default connect(mapStateToProps)(RCRoutes);
