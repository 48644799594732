import React, { FunctionComponent, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { hideInterventionFidelityModal } from "../../../../../../store/onboarding/meeting-module/actions";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import StudentGoalTable from "../../../../intervention-group-page/StudentGoalTable";
import CollapsedContainer from "../common/CollapsedContainer";
import InterventionFidelityTabContainer from "./InterventionFidelityTabContainer";

type StateProps = {
  showArchivedGroups?: boolean;
  showModal?: boolean;
  showSetGoalModal?: boolean;
  showEndInterventionModal?: boolean;
  interventionGroup?: InterventionGroup;
};
type DispatchProps = {
  hideInterventionFidelityModal: () => any;
};

type OwnProps = {
  isReadonly?: boolean;
};

type Props = StateProps & DispatchProps & OwnProps;

const InterventionFidelityCheckModal: FunctionComponent<Props> = ({
  isReadonly,
  showModal,
  showArchivedGroups,
  showSetGoalModal,
  showEndInterventionModal,
  interventionGroup,
  hideInterventionFidelityModal,
}) => {
  useEffect(() => {
    if (showModal && showEndInterventionModal) {
      hideInterventionFidelityModal();
    }
  }, [showModal, showEndInterventionModal]);

  return (
    <Modal
      show={showModal && !showSetGoalModal}
      onHide={hideInterventionFidelityModal}
      animation={false}
      size="lg"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header className="purpleModalHeader" closeButton>
        <Modal.Title>Intervention Fidelity And Goal Progress</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3 className="mb-3">
          <span className="font-weight-bold">Group: </span>
          {interventionGroup?.name}
        </h3>

        <InterventionFidelityTabContainer
          hideActions={showArchivedGroups || isReadonly}
          interventionGroup={interventionGroup}
        />
        <hr />
        <CollapsedContainer label="Student Goals">
          <StudentGoalTable hideActions={showArchivedGroups || isReadonly} />
        </CollapsedContainer>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({
  cases,
  meetingModuleReducer,
  dataFilter,
}: ApplicationState): StateProps => {
  return {
    showArchivedGroups: dataFilter.showArchivedGroups,
    interventionGroup: cases.selectedInterventionGroup,
    showModal: meetingModuleReducer.modalsState.interventionFidelityModal,
    showEndInterventionModal:
      meetingModuleReducer.modalsState.endInterventionModal,
    showSetGoalModal: cases.modalsState.setGoalModal,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideInterventionFidelityModal: hideInterventionFidelityModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterventionFidelityCheckModal);
