import React, { FunctionComponent, useMemo } from "react";
import { DistrictSummary } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import VerticalBarChart from "./VerticalBarChart";

type OwnProps = {
  label?: string;
  interventionWithLoggedFedility: DistrictSummary;
};

type Props = OwnProps;

const InterventionLoggedFidelityBySchoolChart: FunctionComponent<Props> = (props) => {
  const { label, interventionWithLoggedFedility } = props;

  // denumerator code

  let uniqueInterventionSchools:any[] = [];

  for (let i = 0; i < interventionWithLoggedFedility.interventions.length; i++) {
    const element = interventionWithLoggedFedility.interventions[i];
    if(element!.coach!.profile!.current_assignment!.schools.length > 0) {
        for (let j = 0; j < element!.coach!.profile!.current_assignment!.schools.length; j++) {
            const ele = element!.coach!.profile!.current_assignment!.schools[j];
            if(!uniqueInterventionSchools.includes(ele.id)) {
                uniqueInterventionSchools.push(ele.id)
            }  
        }
    }
  }
  //console.log('uniqueInterventionSchools',uniqueInterventionSchools)

  let interventionCountsBySchool:any = {};
  for (let k = 0; k < uniqueInterventionSchools.length; k++) {
      let interventionCount:number = 0;
      for (let i = 0; i < interventionWithLoggedFedility.interventions.length; i++) {
        const element = interventionWithLoggedFedility.interventions[i];
        if(element!.coach!.profile!.current_assignment!.schools.length > 0) {
            for (let j = 0; j < element!.coach!.profile!.current_assignment!.schools.length; j++) {
                const ele = element!.coach!.profile!.current_assignment!.schools[j];
                if(uniqueInterventionSchools[k] == ele.id) {
                    interventionCount = (interventionCount == undefined ? 0 : interventionCount) + element.interventions.length
                    interventionCountsBySchool[ele.name] = interventionCount;
                }  
            }
        }
      }
  }

  //console.log('numerator', interventionCountsBySchool)

// numertor
let uniqueFedilitySchools:any[] = [];

  for (let i = 0; i < interventionWithLoggedFedility.fidelity_by_intervention.length; i++) {
    const element = interventionWithLoggedFedility.fidelity_by_intervention[i];
    if(element!.coach!.profile!.current_assignment!.schools.length > 0) {
        for (let j = 0; j < element!.coach!.profile!.current_assignment!.schools.length; j++) {
            const ele = element!.coach!.profile!.current_assignment!.schools[j];
            if(!uniqueFedilitySchools.includes(ele.id)) {
                uniqueFedilitySchools.push(ele.id)
            }  
        }
    }
  }
  //console.log('uniqueFedilitySchools',uniqueFedilitySchools)

  let fedilityCountsBySchool:any = {};
  for (let k = 0; k < uniqueFedilitySchools.length; k++) {
      let fediltyByInterventionCount = 0;
      for (let i = 0; i < interventionWithLoggedFedility.fidelity_by_intervention.length; i++) {
        const element = interventionWithLoggedFedility.fidelity_by_intervention[i];
        if(element!.coach!.profile!.current_assignment!.schools.length > 0) {
            for (let j = 0; j < element!.coach!.profile!.current_assignment!.schools.length; j++) {
                const ele = element!.coach!.profile!.current_assignment!.schools[j];
                if(uniqueFedilitySchools[k] == ele.id) {
                    fediltyByInterventionCount = fediltyByInterventionCount + 1
                    fedilityCountsBySchool[ele.name] = fediltyByInterventionCount;
                }  
            }
        }
      }
  }
  //console.log('denumerator', fedilityCountsBySchool)

  for (const iKey in interventionCountsBySchool) {
    if (Object.prototype.hasOwnProperty.call(interventionCountsBySchool, iKey)) {
      for (const fKey in fedilityCountsBySchool) {
        if(fKey == iKey){
          interventionCountsBySchool[iKey] = ((fedilityCountsBySchool[fKey]/interventionCountsBySchool[iKey]) * 100).toFixed(2).replace(/^0+|0+$/g, "").replace(/\.$/, "")
          
        }
      }
      
    }
  }
  //console.log('interventionCountsBySchool',interventionCountsBySchool)
  let labels:any = [];
  let data:any = [];
  
  for (const key in interventionCountsBySchool) {
    labels.push(key);
    data.push(interventionCountsBySchool[key]);
  }

  if (!data.length) {
    return null;
  }

  return (
    <div className="district-summary-chart-container">
      {label && <h3 className="text-center">{label}</h3>}
      <div>
        <VerticalBarChart labels={labels} data={data} />
      </div>
    </div>
  );
};

export default InterventionLoggedFidelityBySchoolChart;
