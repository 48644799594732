import React, { Component } from "react";
import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { School } from "../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  getStudents,
  openMergeSchoolsModal,
  openSchoolModal,
} from "../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import LoadingIndicator from "../../LoadingIndicator";
import MergeSchoolsModal from "./MergeSchoolsModal";

type PropsFromState = {
  schools: Array<School>;
  isDataManagerUser: boolean;
  isLoading: {
    getSchoolsList: boolean;
  };
  errors: {
    getSchoolsList?: string;
  };
};

type DispatchProps = {
  getStudents: () => any;
  openSchoolModal: (selectedSchool?: School) => any;
  openMergeSchoolsModal: (selectedSchool: School) => any;
};

type Props = PropsFromState & DispatchProps;

const defaultSorted: [{ dataField: string; order: SortOrder }] = [
  {
    dataField: "id",
    order: "asc",
  },
];

const options = {
  sizePerPageList: [
    {
      text: "20",
      value: 20,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
  ],
};

class SchoolsTable extends Component<Props> {
  private columnEvents = {
    onClick: (
      e: React.MouseEvent,
      column: any,
      columnIndex: number,
      row: School
    ) => {
      if (this.props.isDataManagerUser) {
        const selectedSchool = this.props.schools.find(
          (school) => school.id === row.id
        );
        this.props.openSchoolModal(selectedSchool);
      }
    },
  };

  columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "name",
      text: "School Name",
      sort: true,
      events: this.columnEvents,
    },
    {
      dataField: "district",
      text: "District",
      sort: true,
      events: this.columnEvents,
    },
    {
      dataField: "merge",
      text: "Merge",
      headerStyle: { width: "15%" },
    },
  ];

  render() {
    const { schools } = this.props;
    return (
      <>
        <BootstrapTable
          classes="rosterTable"
          wrapperClasses={schools.length === 0 ? "rosterTableEmpty" : ""}
          keyField="id"
          bordered={false}
          bootstrap4
          data={schools.map((school) => ({
            id: school.id,
            name: school.name,
            district: school.district,
            merge: (
              <button
                key={school.id}
                className="blueBtnSm"
                onClick={() => this.props.openMergeSchoolsModal(school)}
              >
                Merge
              </button>
            ),
          }))}
          columns={this.columns}
          pagination={paginationFactory(options)}
          defaultSorted={defaultSorted}
          noDataIndication={<LoadingIndicator />}
        />
        <MergeSchoolsModal />
      </>
    );
  }
}

const mapStateToProps = ({
  auth,
  onboarding,
}: ApplicationState): PropsFromState => {
  return {
    schools: onboarding.schools,
    isDataManagerUser: auth.userInfo
      ? auth.userInfo.profile.current_role === "coach"
      : false, //TODO "coach" using only for testing, change "coach" to "data_manager"
    errors: {
      getSchoolsList: onboarding.errors.getSchoolsList,
    },
    isLoading: {
      getSchoolsList: onboarding.isLoading.getSchoolsList,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getStudents: getStudents,
      openSchoolModal: openSchoolModal,
      openMergeSchoolsModal: openMergeSchoolsModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SchoolsTable);
