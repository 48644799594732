import React, { FunctionComponent, useMemo } from "react";
import { DistrictSummary } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import VerticalBarChart from "./VerticalBarChart";

type OwnProps = {
  label?: string;
  interventionWithLoggedFedility: DistrictSummary;
};

type Props = OwnProps;

const InterventionLoggedFidelityChart: FunctionComponent<Props> = (props) => {
  const { label, interventionWithLoggedFedility } = props;
  let groupProgressArray: any[] = [];

  //deneumerator
  interventionWithLoggedFedility.interventions.map((element) => {
      if(element.coach.profile.current_role == 'coach') {
        groupProgressArray[element.coach.id] = {
          coach: element.coach,
          interventions: element.interventions.length,
          loggedFidelity: 0,
          fidelityPersentage:0
        }
        return groupProgressArray;
      }
    })

    //neumerator
    if(groupProgressArray.length > 0) {
      Object.keys(groupProgressArray).forEach((coachID:any)=> {
        let interventionCount = 0;
        interventionWithLoggedFedility.fidelity_by_intervention.map((fedilityElement) => {
          if(fedilityElement.coach.id == Number(coachID)) {
            groupProgressArray[coachID].loggedFidelity = 
            ++interventionCount;
            groupProgressArray[coachID].fidelityPersentage = 
            (groupProgressArray[coachID].loggedFidelity / groupProgressArray[coachID].interventions);
          } 
        });
      })
    }
  
  let groupArray:any[] = []
  groupProgressArray.map((data) => {
    let dataObj = {
      coach: data.coach,
      logged_fidelity: data.fidelityPersentage
    }
    groupArray.push(dataObj);
  })

  const labels = useMemo(
    () => groupArray.map((group) => getFullName(group.coach)),
    [groupArray]
  );
  const data = useMemo(
    () => groupArray.map((group) => +(group.logged_fidelity * 100).toFixed(2)),
    [groupArray]
  );

  if (!data.length) {
    return null;
  }

  return (
    <div className="district-summary-chart-container">
      {label && <h3 className="text-center">{label}</h3>}
      <div>
        <VerticalBarChart labels={labels} data={data} />
      </div>
    </div>
  );
};

export default InterventionLoggedFidelityChart;
