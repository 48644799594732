import React, { FunctionComponent, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import {
  ConcernArea,
  InterventionGroup,
  PurposeTypes,
  StudentGoal,
  StudentGoalForGroup,
} from "../../../../../../../store/onboarding/cases/types";
import { Spinner } from "react-bootstrap";
import {
  ManuallyAssessment,
  Measurement,
} from "../../../../../../../store/onboarding/types";
import { toastr } from "react-redux-toastr";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { addAssessmentToDataPeriod } from "../../../../../../../store/onboarding/actions";
import {
  createStudentGoalForInterventionGroup,
  createStudentGoalForStudent,
  hideSetGoalChooseConcernModal,
} from "../../../../../../../store/onboarding/cases/actions";

type StateProps = {
  interventionGroup: InterventionGroup;
  studentGoal?: StudentGoal;
  isLoading: {
    addAssessmentToDataPeriod: boolean;
    createStudentGoalForInterventionGroup: boolean;
    createStudentGoalForStudent: boolean;
  };
  errors: {
    addAssessmentToDataPeriod?: string;
    createStudentGoalForInterventionGroup?: string;
    createStudentGoalForStudent?: string;
  };
};

type DispatchProps = {
  createStudentGoalForInterventionGroup: (
    interventionGroupId: number,
    goal: StudentGoalForGroup
  ) => any;
  createStudentGoalForStudent: (
    interventionGroupId: number,
    studentId: number,
    goal: StudentGoalForGroup
  ) => any;
  addAssessmentToDataPeriod: (assessment: ManuallyAssessment) => any;
  hideSetGoalChooseConcernModal: () => any;
};

type OwnProps = {
  // targetDescriptions: Array<TargetDescription>;
  selectedMeasurement: Measurement;
  onClickBack: () => any;
  onClickNext: () => any;
};

type Props = OwnProps & StateProps & DispatchProps;

const ObservationSetOutcome: FunctionComponent<Props> = ({
  interventionGroup,
  studentGoal: pStudentGoal,
  // targetDescriptions,
  errors: { addAssessmentToDataPeriod: addAssessmentToDataPeriodError },
  isLoading: { addAssessmentToDataPeriod: loadingAddAssessmentToDataPeriod },
  selectedMeasurement,
  onClickBack,
  onClickNext,
  createStudentGoalForInterventionGroup,
  createStudentGoalForStudent,
  addAssessmentToDataPeriod,
  hideSetGoalChooseConcernModal,
}) => {
  const [selectedPurpose, setSelectedPurpose] = useState<
    PurposeTypes | undefined
  >();
  const [showSaveProgressModal, setShowSaveProgressModal] = useState(false);

  const handleGoalProceed = async () => {
    if (selectedMeasurement) {
      const assessment: ManuallyAssessment = {
        assessment_id: selectedMeasurement.assessment!.id,
        measurement_ids: [selectedMeasurement.id!],
      };
      await addAssessmentToDataPeriod(assessment);
      if (!addAssessmentToDataPeriodError) {
        const studentGoal: StudentGoalForGroup = {
          concern_area: ConcernArea.BEHAVIOR,
          measurement_id: selectedMeasurement.id!,
        };
        if (pStudentGoal) {
          createStudentGoalForStudent(
            interventionGroup.id!,
            pStudentGoal.student.id!,
            studentGoal
          );
        } else {
          createStudentGoalForInterventionGroup(
            interventionGroup.id!,
            studentGoal
          );
        }
        hideSetGoalChooseConcernModal();
      } else {
        toastr.error("Error", addAssessmentToDataPeriodError);
      }
    }
  };

  return (
    <>
      <h2 className="mb-0 font-weight-semibold">
        Establish an Outcome Criterion for the Target Behavior
      </h2>
      <h3 className="mb-4 font-weight-normal">
        The outcome criterion communicates the desired level of the target
        behavior.
      </h3>
      <p className="d-inline">
        Sources of information to develop an outcome criterion include:
      </p>
      <ul>
        <li>Current baseline data on their target behavior assessment.</li>
        <li>
          Proficiency indicators like a benchmark score or performance level on
          their target behavior assessment.
        </li>
        <li>Local standards for the behavior domain or specific behavior.</li>
      </ul>

      <p>
        It is helpful to establish a baseline of the behavior prior to setting
        an outcome criterion. You may use the template below to conduct a
        baseline observation or go ahead and set the outcome criterion.
      </p>

      <p>
        Download &quot;Observation&quot; or &quot;Assessment&quot; Template:
      </p>
      <button className="whitePillBtn">
        <FontAwesomeIcon icon={faFile} /> DirectObservationFreq.pdf
      </button>

      {/*{this.getTargetBehaviorEvidenceDescriptionPanel()} todo */}
      <br />
      <hr />
      <div>
        <h3 className="font-weight-bold text-center">
          What would you like to do?
        </h3>
        <div className="d-flex flex-column align-items-center justify-content-between">
          <button
            className={
              selectedPurpose ===
              PurposeTypes.DO_OBSERVATION_TO_ESTABLISH_BASELINE
                ? "purpleSolidBtnMd"
                : "purpleRegularBtnMd"
            }
            onClick={() =>
              setSelectedPurpose(
                PurposeTypes.DO_OBSERVATION_TO_ESTABLISH_BASELINE
              )
            }
          >
            Do Observation to Establish Baseline
          </button>
          <button
            className={
              selectedPurpose === PurposeTypes.SET_OUTCOME_CRITERION_NOW
                ? "purpleSolidBtnMd"
                : "purpleRegularBtnMd"
            }
            onClick={() =>
              setSelectedPurpose(PurposeTypes.SET_OUTCOME_CRITERION_NOW)
            }
          >
            Set Outcome Criterion now
          </button>
        </div>
      </div>
      <hr />
      <div className="btnActions">
        <button className="blueBtnSm" onClick={onClickBack}>
          Back
        </button>
        <button
          className="blueBtnSm"
          onClick={() => {
            selectedPurpose ===
            PurposeTypes.DO_OBSERVATION_TO_ESTABLISH_BASELINE
              ? setShowSaveProgressModal(true)
              : onClickNext();
          }}
          disabled={!selectedPurpose}
        >
          Next
        </button>
      </div>
      {showSaveProgressModal ? (
        <div className="smallAlertModal">
          <div>
            <div className={"pb-2"}>
              This will save your progress and take you back to the data matrix,
              where you can start an observation to establish a baseline.
            </div>
            <div className="spaceBetween">
              <button
                className="blueBtnSm"
                onClick={() => setShowSaveProgressModal(false)}
              >
                Cancel
              </button>
              <button className="blueBtnSm" onClick={handleGoalProceed}>
                Proceed{" "}
                {loadingAddAssessmentToDataPeriod && (
                  <Spinner animation="border" size="sm" />
                )}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = ({
  onboarding,
  cases,
}: ApplicationState): StateProps => {
  return {
    interventionGroup: cases.selectedInterventionGroup!,
    studentGoal: cases.selectedStudentGoal,
    isLoading: {
      addAssessmentToDataPeriod: onboarding.isLoading.addAssessmentToDataPeriod,
      createStudentGoalForInterventionGroup:
        cases.isLoading.createStudentGoalForInterventionGroup,
      createStudentGoalForStudent: cases.isLoading.createStudentGoalForStudent,
    },
    errors: {
      addAssessmentToDataPeriod: onboarding.errors.addAssessmentToDataPeriod,
      createStudentGoalForInterventionGroup:
        cases.errors.createStudentGoalForInterventionGroup,
      createStudentGoalForStudent: cases.errors.createStudentGoalForStudent,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      addAssessmentToDataPeriod: addAssessmentToDataPeriod,
      createStudentGoalForStudent: createStudentGoalForStudent,
      createStudentGoalForInterventionGroup: createStudentGoalForInterventionGroup,
      hideSetGoalChooseConcernModal: hideSetGoalChooseConcernModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObservationSetOutcome);
