import Modal from "react-bootstrap/Modal";
import React, { FunctionComponent, useEffect, useState } from "react";
import { ApplicationState } from "../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { hideChatModal } from "../../../store/chat/actions";
import { ModalSteps } from "../../../store/chat/types";
import NewChat from "./NewChat";
import Chat from "./Chat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

type OwnProps = {};

type PropsFromState = {
  showModal: boolean;
};

type DispatchProps = {
  hideChatModal: () => any;
};

type Props = OwnProps & PropsFromState & DispatchProps;

const ChatModal: FunctionComponent<Props> = ({ showModal, hideChatModal }) => {
  const [step, setStep] = useState(ModalSteps.CHAT);

  // const currentUser = useSelector<ApplicationState, UserInfo | undefined>(
  //   (s) => s.auth.userInfo
  // );
  //
  // const socketUrl = useMemo(() => {
  //   if (!currentUser?.id) {
  //     return null;
  //   }
  //   return `wss://${location.hostname}/ws/chat/user/${currentUser.id}`;
  // }, [currentUser?.id]);
  //
  // const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
  //   // retryOnError: true,
  //   onMessage: (
  //     e: MessageEvent<{
  //       timestamp: string;
  //       payload: {
  //         chat_thread: ChatThread;
  //         message: ChatMessage;
  //       };
  //     }>
  //   ) => {
  //     if (e.data) {
  //       //todo
  //     }
  //   },
  //   onError: (e) => {
  //     toastr.error("Unable to connect to socket", socketUrl!);
  //   },
  // });

  useEffect(() => {
    if (showModal) {
      setStep(ModalSteps.CHAT);
    }
  }, [showModal]);

  const modalBody = () => {
    switch (step) {
      case ModalSteps.CHAT:
        return <Chat setStep={setStep} />;
      case ModalSteps.NEW_CHAT:
        return <NewChat setStep={setStep} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={hideChatModal}
      animation={false}
      size="lg"
      backdropClassName={"customDarkModalBackdrop in"}
    >
      <Modal.Body className="chatModalBody">
        <div className="chatModalCloseBtn">
          <span className="pointer" onClick={() => hideChatModal()}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </span>
        </div>
        {modalBody()}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ chat }: ApplicationState): PropsFromState => {
  return {
    showModal: chat.modalsState.chatModal,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideChatModal: hideChatModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChatModal);
