import React, { FunctionComponent, useMemo } from "react";
import { getFullName } from "../../../../../../utils/NamesUtils";
import VerticalBarChart from "./VerticalBarChart";
import { GroupInfo } from "../../../../../../store/onboarding/types";

type OwnProps = {
  label: string;
  groupInfo: GroupInfo[];
};

type Props = OwnProps;

const PlansPerSchoolChart: FunctionComponent<Props> = (props) => {
  const { label, groupInfo = [] } = props;

  let uniqueSchools:any[] = [];

  for (let i = 0; i < groupInfo.length; i++) {
    const element = groupInfo[i];
    if(element!.coach!.profile!.current_assignment!.schools.length > 0) {
        for (let j = 0; j < element!.coach!.profile!.current_assignment!.schools.length; j++) {
            const ele = element!.coach!.profile!.current_assignment!.schools[j];
            if(!uniqueSchools.includes(ele.id)) {
                uniqueSchools.push(ele.id)
            }  
        }
    }
  }

  let planCountsBySchool:any = {};
  for (let k = 0; k < uniqueSchools.length; k++) {
      let dataArray = [];
      for (let i = 0; i < groupInfo.length; i++) {
        const element = groupInfo[i];
        if(element!.coach!.profile!.current_assignment!.schools.length > 0) {
            for (let j = 0; j < element!.coach!.profile!.current_assignment!.schools.length; j++) {
                const ele = element!.coach!.profile!.current_assignment!.schools[j];
                if(uniqueSchools[k] == ele.id) {
                    dataArray.push(element.planned_count)
                    planCountsBySchool[ele.name] = dataArray;
                }  
            }
        }
      }
  }

  //console.log('planCountsBySchool', planCountsBySchool)
  // const labels = useMemo(() => groupInfo.map((gi) => getFullName(gi.coach)), [
  //   groupInfo,
  // ]);
  // const data = useMemo(() => {
  //   return groupInfo.map((gi) => gi.planned_count);
  // }, [groupInfo]);

  let labels:any = [];
  let dataArr:any = [];
  
  for (const key in planCountsBySchool) {
    labels.push(key);
    dataArr.push(planCountsBySchool[key]);
  }

  const data = dataArr.map((data:any)=> data.reduce((sum:any, x:any) => sum + x))

  if (!data.length) {
    return null;
  }

  return (
    <div className="district-summary-chart-container">
      <h3 className="text-center">{label}</h3>
      <div>
        <VerticalBarChart labels={labels} data={data} isNumeric />
      </div>
    </div>
  );
};

export default PlansPerSchoolChart;
