import React from "react";
import { Cell } from "fixed-data-table-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKeyboard, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Interview } from "../../../../../../../store/onboarding/types";
import { openConductInterviewModal } from "../../../../../../../store/onboarding/actions";
import { Dropdown } from "react-bootstrap";
import { Portal } from "react-portal";
import { faEye } from "@fortawesome/free-regular-svg-icons";

type OwnProps = {
  studentId: number;
};

type StateProps = {
  interviews: Array<Interview>;
};

type DispatchProps = {
  openConductInterviewModal: typeof openConductInterviewModal;
};

type Props = OwnProps & StateProps & DispatchProps;

const InterviewEntryCell = ({
  studentId,
  interviews,
  openConductInterviewModal,
}: Props) => {
  const studentInterviews = interviews.filter((x) =>
    x.students.some((s) => s.student.id === studentId)
  );

  return studentInterviews.length ? (
    <Cell className="dataTableCell">
      <Dropdown title="Enter Details">
        <Dropdown.Toggle
          id="interview-toggle"
          as={"button"}
          bsPrefix="blueBtnSm"
        >
          <FontAwesomeIcon icon={faEye} size="lg" /> View
        </Dropdown.Toggle>

        <Portal>
          <Dropdown.Menu>
            {studentInterviews.map((x) => (
              <Dropdown.Item
                key={x.id}
                onClick={() => openConductInterviewModal(x)}
              >
                <FontAwesomeIcon icon={faKeyboard} size="1x" />{" "}
                {x.teacher.first_name} {x.teacher.last_name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Portal>
      </Dropdown>
    </Cell>
  ) : (
    <Cell className="dataTableCellEmpty">
      <FontAwesomeIcon icon={faPlusCircle} />
    </Cell>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    interviews: onboarding.interviews,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openConductInterviewModal: openConductInterviewModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InterviewEntryCell);
