import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMasterDataDisplayNames, getMasterDataVariations } from "../../../../../store/superuser/actions";
import { ApplicationState } from "../../../../../store";
import { Master_Data, SuperuserState } from "../../../../../store/superuser/types";
import LoadingIndicator from "../../../../common/onboarding/LoadingIndicator";
import AccordianContainer from "../../../coach-dashboard/tabs/your-assigments/common/AccordianContainer";
import MasterDataVariations from "./MasterDataVariations";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

type ownProps = {
  masterDataType: string 
}

type Props = ownProps;

const MasterDataDetails = (props: Props) => {
  const { masterDataType } = props;
  const [openId, setOpenId] = useState<string | null>(null);
  const [variationList, setVariationList] = useState<Master_Data[]>([]);
  const [totalRows, setTotalRows] = useState<number>();

  const dispatch = useDispatch();

  const toggleHandler = (targetValue: string) => () => {
    dispatch(getMasterDataVariations(targetValue))
    setOpenId(openId => openId === targetValue ? null : targetValue);
  };

  const {isLoading, masterDataDisplay, masterDataVariation} = 
    useSelector<ApplicationState, SuperuserState>((s) => s.superuser);

  useEffect(() => {
    dispatch(getMasterDataDisplayNames(masterDataType))
  },[masterDataType]);
  
  useEffect(() => {
    for (let i = 0; i < masterDataDisplay.length; i++) {
      for (let j = 0; j < masterDataVariation.length; j++) {
        if(masterDataDisplay[i].target_value == masterDataVariation[j].target_value) {
          masterDataVariation[j] = {
            ...masterDataVariation[j],
            masterDataId: `${i}_${j}`,
            target_value: masterDataVariation[j].target_value,
            isEditble: false
          };
        }
      }
    }
    setVariationList(masterDataVariation)
    setTotalRows(masterDataVariation.length - 1)
  },[masterDataVariation])

  const addNewRow = (dIndex: number, vIndex: number, target_value: string) => {
    setVariationList([
      ...variationList,
      {
        variation: '', 
        masterDataId: `${dIndex}_${vIndex}`,
        target_value: target_value, 
        isEditble: false,
        isAddNew: true
      },
    ]);
    setTotalRows(variationList.length + 1)
  }

  const editableRow = (masterDataId: string, editAction: boolean) => {
    setVariationList([
      ...variationList.map((varList) => {
        if(varList.masterDataId == masterDataId) {
          varList =  {
            ...varList,
            isEditble: editAction,
            isAddNew: false
          }
        }
        return varList;
      }
     ),
    ]);
  }

  const removeRow = (masterDataId: string) => {
    setVariationList([
      ...variationList.filter((varList) => varList.masterDataId !== masterDataId),
    ]);
    setTotalRows(variationList.length - 2)
  }

  return (
    <div className="onboardingContainer">
      {isLoading.getMasterDisplayNames ? 
        <LoadingIndicator/>
      : masterDataDisplay.length ?
        <div className="collapse-container">
          {masterDataDisplay
          .sort((a,b) => a.display_name! > b.display_name! ? 1 : -1)
          .map((display, displayIndex) => 
            <AccordianContainer 
              label={display.display_name}
              open={openId === display.target_value}
              onClick={toggleHandler(display.target_value!)}
            >
              {isLoading.getMasterVariations ?
                <LoadingIndicator/>
              : variationList.length ? 
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Variations</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {variationList
                    .map((variationObj, variationIndex:number) =>
                      <MasterDataVariations
                        key={`${displayIndex}_${variationIndex}`}
                        variationObj={variationObj}
                        masterDataType={masterDataType}
                        variationIndex={variationIndex}
                        displayIndex = {displayIndex}
                        totalRows = {totalRows}
                        addNewRow = {addNewRow}
                        editableRow = {editableRow}
                        removeRow = {removeRow}
                      />
                  )}
                </tbody>
                </Table>
              : ''}  
            </AccordianContainer>
          )}
        </div>
      : 'No Data Found'
      } 
    </div>
  );
};

export default MasterDataDetails;
