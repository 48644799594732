import { Student, TeacherInfo } from "../../../../../store/onboarding/types";
import React, { Component } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  assignStudentsToTeacher,
  hideStudentEditForm,
  openUploadStudentsToTeachersModal,
  updateTeacherStudentDetails,
} from "../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { UserAssignmentRole } from "../../../../../store/auth/types";

type PropsFromState = {
  show: boolean;
  selectedStudent?: Student;
  selectedTeacher?: TeacherInfo;
  isLoading: boolean;
  error?: string;
  currentRole?: string;
};

type DispatchProps = {
  onUploadRoster: () => void;
  assignStudentsToTeacher: (id: number, students: Array<Student>) => any;
  updateStudentDetails: (student: Student) => any;
  hideStudentEditForm: () => any;
};

type Props = PropsFromState & DispatchProps;

type State = {
  selectedStudent: Student;
};

class StudentEditForm extends Component<Props, State> {
  emptyStudent: Student = {
    first_name: "",
    last_name: "",
    unique_id: "",
  };

  state: Readonly<State> = {
    selectedStudent: this.emptyStudent,
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (this.props.show && !prevProps.show) {
      this.setState({
        selectedStudent: this.props.selectedStudent
          ? this.props.selectedStudent
          : this.emptyStudent,
      });
    }
    // console.log(prevProps.isLoading, !this.props.isLoading);
    if (prevProps.isLoading && !this.props.isLoading) {
      if (!this.props.error) {
        this.props.hideStudentEditForm();
      } else {
        toastr.error("Error", this.props.error);
      }
    }
  }

  handleSelectedStudentChange = (event: React.FormEvent<any>) => {
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;
    this.setState((state) => ({
      ...state,
      selectedStudent: {
        ...state.selectedStudent,
        [name]: value,
      },
    }));
  };

  handleStudentChangesAccept = (event: React.FormEvent<any>) => {
    event.preventDefault();
    const {
      selectedTeacher,
      assignStudentsToTeacher,
      updateStudentDetails,
    } = this.props;
    const { selectedStudent } = this.state;
    if (selectedTeacher) {
      if (selectedStudent.id) {
        updateStudentDetails(selectedStudent);
      } else {
        assignStudentsToTeacher(selectedTeacher.user.id, [selectedStudent]);
      }
    }
  };

  render() {
    const { show } = this.props;
    const { selectedStudent } = this.state;
    const isCoach = this.props.currentRole === UserAssignmentRole.COACH;
    const isTeacher = this.props.currentRole ===  UserAssignmentRole.TEACHER;

    return selectedStudent ? (
      <div hidden={!show}>
        <div className="addStudentWidget">
          <div className="d-flex justify-content-between align-items-center m-0">
            <h4 className="font-weight-bold m-0">
              {selectedStudent.id ? "Edit" : "Add"} Student{" "}
            </h4>
            <Button
              onClick={this.props.hideStudentEditForm}
              disabled={this.props.isLoading}
            >
              <FontAwesomeIcon icon={faTimesCircle} className="pointer" />
            </Button>
          </div>
          <Form
            id="editStudentDetails"
            onSubmit={this.handleStudentChangesAccept}
          >
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="first_name"
                placeholder="Enter first name"
                onChange={this.handleSelectedStudentChange}
                value={selectedStudent.first_name}
                disabled={isCoach || isTeacher}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="last_name"
                placeholder="Enter last name"
                onChange={this.handleSelectedStudentChange}
                value={selectedStudent.last_name}
                disabled={isCoach || isTeacher}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Student ID Number</Form.Label>
              <Form.Control
                required
                type="text"
                name="unique_id"
                placeholder="Enter Student ID number"
                onChange={this.handleSelectedStudentChange}
                value={selectedStudent.unique_id}
                disabled={isCoach || isTeacher}
              />
            </Form.Group>
          </Form>

          <button
            type="submit"
            form={"editStudentDetails"}
            className="blueBtnSmFull"
          >
            {selectedStudent.id ? "Save" : "Add Student"}{" "}
            {this.props.isLoading && <Spinner animation="border" size="sm" />}
          </button>

          {!selectedStudent.id && (
            <>
              <hr className="margin10" />
              <button
                className="blueBtnSmFull"
                onClick={this.props.onUploadRoster}
              >
                Upload a student roster
              </button>
            </>
          )}
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = ({ onboarding, auth }: ApplicationState): PropsFromState => {
  const selectedTeacher = onboarding.selectedTeacher;
  let assignStudentsToTeacherError;
  if (selectedTeacher) {
    const error = onboarding.errors.assignStudentsToTeacher.find(
      (x) => x.teacherId === selectedTeacher.user.id
    );
    if (error) {
      assignStudentsToTeacherError = error.message;
    }
  }
  return {
    show: onboarding.modalsState.editStudentForm,
    selectedStudent: onboarding.selectedStudent,
    currentRole: auth.userInfo?.profile.current_role,
    selectedTeacher: selectedTeacher,
    isLoading:
      (selectedTeacher
        ? onboarding.isLoading.assignStudentsToTeacher.includes(
            selectedTeacher.user.id
          )
        : false) || onboarding.isLoading.updateStudentDetails,
    error:
      assignStudentsToTeacherError || onboarding.errors.updateStudentDetails,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      onUploadRoster: openUploadStudentsToTeachersModal,
      assignStudentsToTeacher: assignStudentsToTeacher,
      updateStudentDetails: updateTeacherStudentDetails,
      hideStudentEditForm: hideStudentEditForm,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StudentEditForm);
