import React, { FunctionComponent, useState } from "react";
import InterventionPlanWorkspace from "./InterventionPlanWorkspace";
import ChartWorkspace from "./ChartWorkspace";
import LessonNotesWorkspace from "./lesson-notes/LessonNotesWorkspace";
import LoggedDataWorkspace from "./LoggedDataWorkspace";
import { InterventionGroup } from "../../../../../../../../store/onboarding/cases/types";
import useUserRole from "../../../../../../../../utils/hooks/useUserRole";

type Props = {
  interventionGroup: InterventionGroup;
  studentId?: number;
};

export enum GroupWorkSpace {
  InterventionPlan,
  Chart,
  LessonNotes,
  LoggedData,
}

const WorkspacesContainer: FunctionComponent<Props> = (props) => {
  const { interventionGroup, studentId } = props;

  const [expandedWorkspace, setExpandedWorkSpace] = useState<
    GroupWorkSpace | undefined
  >();

  const { isDistrictOwner } = useUserRole();
  return (
    <>
      {(expandedWorkspace === undefined ||
        expandedWorkspace === GroupWorkSpace.InterventionPlan) && (
        <InterventionPlanWorkspace
          expanded={expandedWorkspace === GroupWorkSpace.InterventionPlan}
          workspace={GroupWorkSpace.InterventionPlan}
          setExpandedWorkSpace={setExpandedWorkSpace}
          interventionGroup={interventionGroup}
        />
      )}
      {(expandedWorkspace === undefined ||
        expandedWorkspace === GroupWorkSpace.Chart) && (
        <ChartWorkspace
          expanded={expandedWorkspace === GroupWorkSpace.Chart}
          workspace={GroupWorkSpace.Chart}
          setExpandedWorkSpace={setExpandedWorkSpace}
        />
      )}
      {(expandedWorkspace === undefined ||
        expandedWorkspace === GroupWorkSpace.LessonNotes) && !isDistrictOwner && (
        <LessonNotesWorkspace
          expanded={expandedWorkspace === GroupWorkSpace.LessonNotes}
          workspace={GroupWorkSpace.LessonNotes}
          setExpandedWorkSpace={setExpandedWorkSpace}
          interventionGroup={interventionGroup}
        />
      )}
      {(expandedWorkspace === undefined ||
        expandedWorkspace === GroupWorkSpace.LoggedData) && (
        <LoggedDataWorkspace
          interventionGroup={interventionGroup}
          expanded={expandedWorkspace === GroupWorkSpace.LoggedData}
          workspace={GroupWorkSpace.LoggedData}
          setExpandedWorkSpace={setExpandedWorkSpace}
          studentId={studentId}
        />
      )}
    </>
  );
};

export default WorkspacesContainer;
