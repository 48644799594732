import { FailedInvite } from "../../../../../store/onboarding/types";
import React from "react";
import { Alert } from "react-bootstrap";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { hideFailedInvitationsAlert } from "../../../../../store/onboarding/actions";
import { connect } from "react-redux";

type PropsFromState = {
  failedInvites: Array<FailedInvite>;
  showFailedInvitationsAlert: boolean;
};

type DispatchProps = {
  hideFailedInvitationsAlert: () => any;
};

type Props = PropsFromState & DispatchProps;

const FailedInvitationsAlert = (props: Props) => {
  return (
    <Alert
      show={props.showFailedInvitationsAlert}
      variant="danger"
      onClose={props.hideFailedInvitationsAlert}
      dismissible
    >
      <Alert.Heading>Some invitations have failed.</Alert.Heading>
      {props.failedInvites.map((failedInvite, index) => (
        <h3 key={index}>
          <b className="font-weight-bold">{failedInvite.email}</b>
          <br />
          {Object.entries(failedInvite.errors).map((error) => (
            <>
              {" "}
              <strong>{error[0]}: </strong> {error[1]} <br />
            </>
          ))}
        </h3>
      ))}
    </Alert>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => ({
  failedInvites: onboarding.invites.failed_invites,
  showFailedInvitationsAlert: onboarding.modalsState.showFailedInvitationsAlert,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideFailedInvitationsAlert: hideFailedInvitationsAlert,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FailedInvitationsAlert);
