import GroupCountIcon from "../../common/onboarding/third-step/group-students/common/GroupCountIcon";
import { Image, Spinner } from "react-bootstrap";
import React, { FunctionComponent, useEffect, useState } from "react";
import { TeacherInfo } from "../../../store/onboarding/types";
import { InterventionGroup } from "../../../store/onboarding/cases/types";
import {
  ChatThread,
  ChatThreadRequest,
  ModalSteps,
} from "../../../store/chat/types";
import { UserInfo } from "../../../store/auth/types";
import { ApplicationState } from "../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  changeSelectedChatThread,
  startChatThread,
} from "../../../store/chat/actions";
import { connect, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useIntl } from "react-intl";

type OwnProps = {
  setStep: (step: ModalSteps) => any;
};

type PropsFromState = {
  userInfo?: UserInfo;
  teachersRoster: Array<TeacherInfo>;
  chatThreads: Array<ChatThread>;
  interventionGroups: Array<InterventionGroup>;
  isLoading: {
    startChatThread: boolean;
  };
  errors: {
    startChatThread?: string;
  };
};

type DispatchProps = {
  startChatThread: (chatThread: ChatThreadRequest) => any;
};

type Props = OwnProps & PropsFromState & DispatchProps;

const NewChat: FunctionComponent<Props> = ({
  userInfo,
  chatThreads,
  interventionGroups,
  teachersRoster,
  isLoading,
  setStep,
  startChatThread,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [chosenTeacherChat, setChosenTeacherChat] = useState<
    TeacherInfo | undefined
  >(undefined);
  const [chosenCaseChat, setChosenCaseChat] = useState<
    InterventionGroup | undefined
  >(undefined);

  useEffect(() => {
    if (chosenCaseChat && chosenTeacherChat) {
      setChosenCaseChat(undefined);
    }
  }, [chosenTeacherChat]);

  useEffect(() => {
    if (chosenTeacherChat && chosenCaseChat) {
      setChosenTeacherChat(undefined);
    }
  }, [chosenCaseChat]);

  const startChat = () => {
    if (!chosenTeacherChat && !chosenCaseChat) return;
    if (isLoading.startChatThread) return;
    let newChatThread: ChatThreadRequest = {
      created_by_user: userInfo!,
      participants: [userInfo!.id],
      is_active: true,
      is_archived: false,
      quarantined: false,
    };
    if (chosenCaseChat) {
      const participantIds = getUsersFromInterventionGroup(chosenCaseChat).map(
        (user) => user.id
      );
      newChatThread = {
        ...newChatThread,
        participants: [...newChatThread.participants, ...participantIds],
        intervention_group: chosenCaseChat.id,
      };
    } else {
      newChatThread = {
        ...newChatThread,
        participants: [
          ...newChatThread.participants,
          chosenTeacherChat!.user.id,
        ],
      };
    }

    startChatThread(newChatThread).then((chatThread: ChatThread | string) => {
      if (typeof chatThread === "string") {
        toastr.error("Error", chatThread);
      } else {
        dispatch(changeSelectedChatThread(chatThread));
        setStep(ModalSteps.CHAT);
      }
    });
  };

  const getFilteredInterventionGroups = () => {
    const chatThreadsInterventionGroups = chatThreads.map(
      (chatThread) => chatThread.intervention_group
    );
    return interventionGroups.filter(
      (interventionGroup) =>
        !chatThreadsInterventionGroups.includes(interventionGroup.id)
    );
  };

  const getFilteredTeachersRoster = () => {
    const individualChatUserIds = new Set();
    chatThreads
      .filter((chatThread) => !chatThread.intervention_group)
      .forEach((chatThread) =>
        chatThread.participants.forEach((participant) =>
          individualChatUserIds.add(participant.id)
        )
      );
    return teachersRoster.filter(
      (teacher) => !individualChatUserIds.has(teacher.user.id)
    );
  };

  const getUsersFromInterventionGroup = (
    interventionGroup: InterventionGroup
  ) => {
    const users: Array<UserInfo> = [];
    if (interventionGroup.associated_assignments) {
      interventionGroup.associated_assignments.forEach(
        (associatedAssignment) => {
          users.push(associatedAssignment.user);
        }
      );
    }
    if (interventionGroup.coach_assignment) {
      if (
        !users.some(
          (user) => user.id === interventionGroup.coach_assignment!.user.id
        )
      )
        users.push(interventionGroup.coach_assignment.user);
    }
    if (interventionGroup.teacher_assignment) {
      if (
        !users.some(
          (user) => user.id === interventionGroup.teacher_assignment!.user.id
        )
      )
        users.push(interventionGroup.teacher_assignment.user);
    }
    return users;
  };

  return (
    <div className="dataContainer">
      <h2 className="font-weight-bold">
        {intl.formatMessage({
          id: "app.chats.newChat.title",
        })}
      </h2>
      {intl.formatMessage({
        id: "app.chats.newChat.info",
      })}
      <div className="d-flex my-4">
        <div className="w-100 mr-4">
          <span className="font-weight-bold">
            {intl.formatMessage({
              id: "app.forms.cases.label",
            })}
          </span>
          <div className="chatSimpleContainer">
            {getFilteredInterventionGroups().map((interventionGroup) => (
              <div key={interventionGroup.id}>
                <div
                  className={
                    chosenCaseChat === interventionGroup
                      ? "selectedNewChatItem"
                      : "newChatItem"
                  }
                  onClick={() => setChosenCaseChat(interventionGroup)}
                >
                  <div className="chatCasesGroupName">
                    <div>
                      <GroupCountIcon
                        svgSize={60}
                        studentsNum={interventionGroup.students.length}
                      />
                    </div>
                    <div>
                      <span className="font-weight-bold">
                        {interventionGroup.name}
                        {": "}
                      </span>
                      <span>
                        {interventionGroup.interventions &&
                          interventionGroup.interventions
                            .map((v) => v.name)
                            .join(", ")}
                      </span>
                    </div>
                  </div>
                  <div>
                    {getUsersFromInterventionGroup(interventionGroup).map(
                      (user, index) => (
                        <Image
                          key={user.id}
                          style={
                            index !== 0 ? { marginLeft: "-18px" } : undefined
                          }
                          src={user.profile.profile_image_url}
                          width={36}
                          height={36}
                          roundedCircle
                          thumbnail
                        />
                      )
                    )}
                    <span className="ml-1">
                      {getUsersFromInterventionGroup(interventionGroup)
                        .map((user) => user.first_name + " " + user.last_name)
                        .join(", ")}
                    </span>
                  </div>
                </div>
                <hr className="m-0" />
              </div>
            ))}
          </div>
        </div>
        <div className="w-100">
          <span className="font-weight-bold">
            {intl.formatMessage({
              id: "app.forms.educators.label",
            })}
          </span>
          <div className="chatSimpleContainer">
            {getFilteredTeachersRoster().map((teacher) => (
              <div key={teacher.user.id}>
                <div
                  className={
                    chosenTeacherChat === teacher
                      ? "selectedNewChatItem"
                      : "newChatItem"
                  }
                  onClick={() => setChosenTeacherChat(teacher)}
                >
                  <Image
                    style={{
                      objectFit: "cover",
                      objectPosition: "top center",
                      margin: "0 15px",
                    }}
                    src={teacher.user.profile.profile_image_url}
                    width={35}
                    height={35}
                    roundedCircle
                  />
                  {teacher.user.first_name} {teacher.user.last_name}
                </div>
                <hr className="m-0" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="spaceBetween">
        <div className="whiteBtnMd2" onClick={() => setStep(ModalSteps.CHAT)}>
          {intl.formatMessage({
            id: "app.forms.buttons.cancel",
          })}
        </div>
        <div className="blueBtnMd" onClick={startChat}>
          {intl.formatMessage({
            id: "app.chats.newChat.startChatBtn.label",
          })}{" "}
          {isLoading.startChatThread && (
            <Spinner animation="border" size="sm" />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  onboarding,
  chat,
  cases,
  auth,
}: ApplicationState): PropsFromState => {
  return {
    userInfo: auth.userInfo,
    chatThreads: chat.chatThreads,
    teachersRoster: onboarding.teachersRoster,
    interventionGroups: cases.interventionGroups,
    isLoading: {
      startChatThread: chat.isLoading.startChatThread,
    },
    errors: {
      startChatThread: chat.errors.startChatThread,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      startChatThread: startChatThread,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NewChat);
