import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";
import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { push } from "connected-react-router";
import DistrictsTableActions from "./DistrictsTableActions";
import { USStateNamesValues } from "../../../../../common/States";
import {
  LicenseStatuses,
  LicenseTypes,
  SuperuserState,
} from "../../../../../../store/superuser/types";
import { getAllDistricts } from "../../../../../../store/superuser/actions";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";


const columns = [
  {
    dataField: "id",
    text: "District ID",
    sort: true,
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
  },
  {
    dataField: "state",
    text: "State",
    sort: true,
  },
  {
    dataField: "owner",
    text: "Owner",
    sort: true,
  },
  {
    dataField: "license_type",
    text: "License type",
    sort: true,
  },
  {
    dataField: "license_status",
    text: "License status",
    sort: true,
  },
  {
    dataField: "license_start",
    text: "License start",
    sort: true,
  },
  {
    dataField: "license_expires",
    text: "License expires",
    sort: true,
  },
  {
    dataField: "can_use_commercial_tools",
    text: "Can Use Commercial Tools",
    sort: true,
  },
];

const defaultSorted: [{ dataField: string; order: SortOrder }] = [
  {
    dataField: "id",
    order: "asc",
  },
];

const options = {
  sizePerPageList: [
    {
      text: "20",
      value: 20,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
  ],
};

type TableRow = {
  id: number;
  name: string;
  owner: string;
  license_type?: string;
  license_status?: string;
  license_start?: string;
  license_expires?: string;
  can_use_commercial_tools?: any;
};

const DistrictsTable = () => {
  const { districts, isLoading } = useSelector<
    ApplicationState,
    SuperuserState
  >((s) => s.superuser);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllDistricts());
  }, []);

  const tableData: TableRow[] = useMemo(
    () =>
      districts.map((district) => ({
        id: district.id,
        name: district.name,
        state:
          USStateNamesValues?.find((st) => st.value === district.state)
            ?.display_name ?? district.state,
        owner: getFullName(district.owner),
        license_type:
          LicenseTypes.find(
            (lic) => lic.value === district.current_license?.license_type
          )?.display_name ?? district.current_license?.license_type,
        license_status:
          LicenseStatuses.find(
            (status) => status.value === district.current_license?.status
          )?.display_name ?? district.current_license?.status,
        license_start: district.current_license?.started_at,
        license_expires: district.current_license?.expires_at,
        can_use_commercial_tools: (
          <FontAwesomeIcon
            size="lg"
            icon={district?.can_use_commercial_tools ? faCheckCircle : faTimes}
          />
        ) 
      })),
    [districts]
  );

  const tableRowEvents = {
    onClick: (e: React.FormEvent, row: TableRow) =>
      dispatch(push(`/superuser/districts/${row.id}`)),
  };

  const isEmpty = districts.length === 0 && !isLoading.getAllDistricts;

  return (
    <div className="onboardingContainer">
      <DistrictsTableActions />
      <BootstrapTable
        classes="rosterTable"
        keyField="id"
        bordered={false}
        bootstrap4
        data={tableData}
        columns={columns}
        pagination={paginationFactory(options)}
        defaultSorted={defaultSorted}
        noDataIndication={
          isEmpty ? "No matching districts" : <LoadingIndicator />
        }
        rowEvents={tableRowEvents}
      />
    </div>
  );
};

export default DistrictsTable;
