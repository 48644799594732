import React, { FunctionComponent, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ApplicationState } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { getPrises } from "../../../../../store/onboarding/meeting-module/actions";
import {
  Praise,
  praiseList,
} from "../../../../../store/onboarding/meeting-module/types";
import { getFullName } from "../../../../../utils/NamesUtils";
import { UserInfo } from "../../../../../store/auth/types";
import { CustomCheckbox } from "../../../../common/onboarding/second-step/evidence-tab/conduct-interview/CustomCheckbox";
import LoadingIndicator from "../../../../common/onboarding/LoadingIndicator";
import { Alert } from "react-bootstrap";
import { toastr } from "react-redux-toastr";

type OwnProps = {
  educator?: UserInfo;
  selectedPraises: string[];
  showModal: boolean;
  onModalHide: () => any;
  onSave: (praises: string[]) => any;
};

type Props = OwnProps;

const PraiseTeacherModal: FunctionComponent<Props> = ({
  selectedPraises: selectedPraisesDef,
  educator,
  showModal,
  onModalHide,
  onSave,
}) => {
  const [selectedPraises, setSelectedPraises] = useState<string[]>([]);
  const [withOtherPraises, setWithOtherPraises] = useState(false);
  const [otherPraisesNote, setOtherPraisesNote] = useState("");

  const dispatch = useDispatch<any>();
  const staticPraises = useSelector<ApplicationState, Praise[]>(
    (s) => s.meetingModuleReducer.praises
  );
  const loading = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.meetingModuleReducer.isLoading.getPrises
  );

  const onModalShow = () => {
    dispatch(getPrises()).then(
      () => {
        if (staticPraises.length) {
          if (selectedPraisesDef.length) {
            const { praises, otherPraisesNote } = selectedPraisesDef.reduce(
              (pV, cV: string) => {
                if (staticPraises.some((p) => p.name === cV)) {
                  return { ...pV, praises: [...pV.praises, cV] };
                } else {
                  return {
                    ...pV,
                    otherPraisesNote: pV.otherPraisesNote + cV + "\n",
                  };
                }
              },
              { praises: [] as string[], otherPraisesNote: "" as string }
            );
            setSelectedPraises(praises);
            setWithOtherPraises(!!otherPraisesNote);
            setOtherPraisesNote(otherPraisesNote);
          } else {
            setSelectedPraises([]);
            setOtherPraisesNote("");
          }
        }
      },
      (err: string) => toastr.error("Failed to get predefined praises", err)
    );
  };

  const handleChange = (index: number) => {
    setSelectedPraises((prevState) => {
      const target = staticPraises[index];
      return prevState.includes(target.name)
        ? prevState.filter((s) => s !== target.name)
        : [...prevState, target.name];
    });
  };

  const onPraiseSave = () => {
    onSave(
      withOtherPraises
        ? [...selectedPraises, otherPraisesNote]
        : selectedPraises
    );
    onModalHide();
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      animation={false}
      centered
      dialogClassName="praisePickerDialog"
      backdropClassName="customWhiteModalBackdrop in"
      onShow={onModalShow}
      onHide={onModalHide}
    >
      <Modal.Body
        style={{ backgroundColor: "#f7f4fc", borderRadius: "inherit" }}
      >
        <h3 className="font-weight-bold text-left mb-3">
          Praise {getFullName(educator)}
        </h3>
        {loading ? (
          <LoadingIndicator />
        ) : staticPraises.length ? (
          staticPraises.map((praise, index) => {
            const active = selectedPraises.includes(praise.name);
            return (
              <div
                key={`praise_${index}`}
                className="praiseItemContainer"
                onClick={() => handleChange(index)}
              >
                <CustomCheckbox checked={active} fixed />
                {praise.name}
              </div>
            );
          })
        ) : (
          <Alert variant="warning">No predefined praises</Alert>
        )}
        <div className="praiseItemContainer">
          <CustomCheckbox
            checked={withOtherPraises}
            onClick={() => {
              setWithOtherPraises(!withOtherPraises);
            }}
            fixed
          />
          Other:
          <input
            className="fullWidthInput ml-1"
            value={otherPraisesNote}
            onChange={(e) => setOtherPraisesNote(e.target.value)}
            disabled={!withOtherPraises}
          />
        </div>

        <div className="btnActions">
          <button className="whiteBtnMd2" onClick={() => onModalHide()}>
            Cancel
          </button>
          <button
            className="blueBtnMd"
            disabled={loading}
            onClick={onPraiseSave}
          >
            Select Praise
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PraiseTeacherModal;
