import React, { FunctionComponent, useMemo } from "react";
import { FidelityByIntervention } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import BootstrapTable from "react-bootstrap-table-next";

type OwnProps = {
  fidelityByIntervention: FidelityByIntervention[];
};

type Props = OwnProps;

const FidelityInterventionTable: FunctionComponent<Props> = (props) => {
  const { fidelityByIntervention = [] } = props;

  const columns = useMemo(
    () => [
      {
        dataField: "key",
        text: "key",
        hidden: true,
      },
      {
        dataField: "coach",
        text: "Coach Name",
        sort: true,
      },
      {
        dataField: "intervention",
        text: "Intervention",
        sort: true,
      },
      {
        dataField: "fidelity",
        text: "Fidelity",
        sort: true,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      fidelityByIntervention.map((item) => ({
        key: `${item.coach.id}_${item.intervention.id}`,
        coach: getFullName(item.coach),
        intervention: item.intervention.name,
        fidelity: (item.fidelity * 100).toFixed(2).replace(".00", "") + "%",
      })),
    [fidelityByIntervention]
  );

  return (
    <BootstrapTable
      classes="rosterTable"
      keyField="key"
      bordered={false}
      bootstrap4
      data={data}
      columns={columns}
      noDataIndication={"No data"}
    />
  );
};

export default FidelityInterventionTable;
