import React, { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import Table from "react-bootstrap/Table";
import InterventionFidelityRow from "./InterventionFidelityRow";
import useInterventionPlan from "./hooks/useInterventionPlan";
import useInterventionGroupAccess from "../../../../../../utils/hooks/useInterventionGroupAccess";
import { hideInterventionPlanModal, showInterventionPlanModal } from "../../../../../../store/onboarding/cases/actions";
import { openImplementationCheckModal } from "../../../../../../store/onboarding/meeting-module/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";

type OwnProps = {
  interventionGroup: InterventionGroup;
  hideActions?: boolean;
  onlyActive?: boolean;
  isStudentDetail?: boolean;
  isGroupDetail?: boolean;
};

type Props = OwnProps;

const LoggedInterventionsTable: FunctionComponent<Props> = ({
  interventionGroup,
  hideActions,
  onlyActive,
  isStudentDetail,
  isGroupDetail
}) => {
  const {
    implementationChecks: filteredImplementationChecks,
  } = useInterventionPlan(interventionGroup, onlyActive);

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(interventionGroup.id);

  const dispatch = useDispatch();

  const handleLogIntervention = useCallback(() => {
    dispatch(hideInterventionPlanModal());
    dispatch(
      openImplementationCheckModal({
        onClose: () => dispatch(showInterventionPlanModal()),
      })
    );
  }, []);

  const logGoalBtn = useMemo(() => {
    return (
      <button className="blueBtnSm " onClick={handleLogIntervention}>
        <FontAwesomeIcon
          icon={faList}
          size={"lg"}
          style={{ color: "white", marginRight: "0.5rem" }}
        />{" "}
        log intervention
      </button>
    );
  }, [handleLogIntervention]);
  
  return (
    <Table className="goalTable mb-0" responsive>
      <thead>
        <tr>
          <th>Date</th>
          <th>Intervention</th>
          <th>Fidelity</th>
          <th>Logged By</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {filteredImplementationChecks.length ? (
          filteredImplementationChecks.map((ic, index) => {
            const intervention = interventionGroup.interventions?.find(
              (intervention) => intervention.id === ic.intervention
            ) ??  interventionGroup.archived_interventions?.find(
              (intervention) => intervention.id === ic.intervention
            );
            return (
              <InterventionFidelityRow
                hideActions={hideActions}
                key={index}
                interventionGroup={interventionGroup}
                implementationCheck={ic}
                intervention={intervention}
                isStudentDetail={isStudentDetail}
                isGroupDetail={isGroupDetail}
              />
            );
          })
        ) : (
          <>
            <tr>
              <td colSpan={6} className="text-center">
                <p>No Intervention Logged.</p>
              </td>
            </tr>
            {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess ? 
              <tr style={{ backgroundColor:'#fff'}}>
                <td colSpan={6} className="text-center border-top-0 pt-1">{logGoalBtn}</td> 
              </tr>
            : ''}
          </>
        )}
      </tbody>
    </Table>
  );
};

export default LoggedInterventionsTable;
