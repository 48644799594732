import React, { FunctionComponent } from "react";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { changeSelectedInterventionGroup } from "../../../../../../store/onboarding/cases/actions";
import { push } from "connected-react-router";
import { Meeting } from "../../../../../../store/onboarding/meeting-module/types";
import { changeSelectedMeeting } from "../../../../../../store/onboarding/meeting-module/actions";
import InterventionGroupTeacher from "../common/InterventionGroupTeacher";
import { UserAssignmentRole } from "../../../../../../store/auth/types";
import { getFullName } from "../../../../../../utils/NamesUtils";

type DispatchProps = {
  changeSelectedInterventionGroup: (
    interventionGroupInfo?: InterventionGroup
  ) => any;
  changeSelectedMeeting: (meeting?: Meeting) => any;
  push: (location: string) => any;
};

type OwnProps = {
  interventionGroup?: InterventionGroup;
  onInterventionSkip: () => void;
  userRole?: UserAssignmentRole;
};

type Props = OwnProps & DispatchProps;

const PreparationStageInfo: FunctionComponent<Props> = ({
  changeSelectedInterventionGroup,
  interventionGroup,
  userRole,
  push,
  changeSelectedMeeting,
  onInterventionSkip,
}) => {
  if (!interventionGroup) return null;

  const onInterventionPrep = () => {
    changeSelectedInterventionGroup(interventionGroup);
    changeSelectedMeeting();
    push(`/cases/groups/${interventionGroup.id}/meeting`);
  };

  if (
    userRole === UserAssignmentRole.TEACHER ||
    userRole === UserAssignmentRole.SUPERVISOR
  ) {
    return (
      <div
        className="interventionInfoContainer"
        onClick={(e) => e.stopPropagation()}
      >
        <h4 className="font-weight-bold text-center">
          Waiting for{" "}
          {getFullName(interventionGroup.coach_assignment?.user) ?? "coach"} to
          prepare
          {userRole === UserAssignmentRole.SUPERVISOR
            ? ` a case for ${getFullName(
                interventionGroup.teacher_assignment?.user
              ) ?? "teacher"}`
            : " your case"}
        </h4>
      </div>
    );
  }

  return (
    <div
      className="interventionInfoContainer"
      id="meeting-preparation"
      onClick={(e) => e.stopPropagation()}
    >
      <h5 className="section">
        First, prepare to meet with&nbsp;
        <InterventionGroupTeacher interventionGroup={interventionGroup} />
      </h5>
      <div className="d-flex justify-content-around mt-2">
        <button className="blueBtnSm mx-3" onClick={onInterventionPrep}>
          Prepare
        </button>
        <button className="blueBtnSm mx-3" onClick={onInterventionSkip}>
          Skip
        </button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      changeSelectedInterventionGroup: changeSelectedInterventionGroup,
      changeSelectedMeeting: changeSelectedMeeting,
      push: push,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(PreparationStageInfo);
