import {
  Student,
  TeacherClass,
  TeacherInfo,
} from "../../../../../store/onboarding/types";
import React, { Component } from "react";
import { InputGroup, Modal, Spinner } from "react-bootstrap";
import DataFileReader from "../../../../../containers/onboarding/FileReader";
import ColumnHeaderSelector from "../upload-student-data/ColumnHeaderSelector";
import Table from "react-bootstrap/Table";
import UploadStudentsRosterManual from "./UploadStudentsRosterManual";
import Form from "react-bootstrap/Form";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import UserAvatarSmall from "../../../UserAvatarSmall";
import { toastr } from "react-redux-toastr";

library.add(faFile, faPencilAlt);

type PropsFromState = {
  originalFileName: string;
  columnsNames: Array<string>;
  rawData: Array<any>;
  isLoading: boolean;
  error?: string;
  selectedTeacher?: TeacherInfo;
};

type DispatchProps = {
  onHide: () => any;
  assignClassToTeacher?: (
    id: number,
    className: string,
    students: Array<Student>
  ) => any;
  updateTeacherClass?: (teacherId: number, updatedClass: TeacherClass) => any;
  assignStudentsToTeacher?: (id: number, students: Array<Student>) => any;
  addStudents?: (students: Array<Student>, addStudentsToEvidence?: boolean, isStudentRosterUploaded?: boolean) => any;
};

type OwnProps = {
  onEnter?: () => any;
  isClass?: boolean;
  className?: string;
  selectedClass?: TeacherClass;
  show: boolean;
};

type Props = OwnProps & PropsFromState & DispatchProps;

type State = {
  className: string;
  selectedStudentIdColumn: string;
  selectedFirstNameColumn: string;
  selectedLastNameColumn: string;
  selectedColumnNames: Array<string>;
};

class UploadStudentsRosterModal extends Component<Props, State> {
  emptyState: State = {
    className: "",
    selectedStudentIdColumn: "",
    selectedFirstNameColumn: "",
    selectedLastNameColumn: "",
    selectedColumnNames: [],
  };

  state: Readonly<State> = this.emptyState;

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (prevProps.isLoading && !this.props.isLoading) {
      if (!this.props.error) {
        this.props.onHide();
      }
    }
  }

  handleStudentsAdd = () => {
    const { selectedTeacher, rawData, isClass } = this.props;
    const {
      selectedStudentIdColumn,
      selectedFirstNameColumn,
      selectedLastNameColumn,
      className,
    } = this.state;
    const students: Array<Student> = rawData.map((row) => ({
      first_name: row[selectedFirstNameColumn],
      last_name: row[selectedLastNameColumn],
      unique_id: row[selectedStudentIdColumn],
    }));
    if (selectedTeacher) {
      if (isClass) {
        if (this.props.selectedClass) {
          this.props.updateTeacherClass!(selectedTeacher.user.id, {
            ...this.props.selectedClass,
            students: [...this.props.selectedClass!.students, ...students],
          });
        } else {
          this.props.assignClassToTeacher!(
            selectedTeacher.user.id,
            className,
            students
          );
        }
      } else {
        this.props.assignStudentsToTeacher!(selectedTeacher.user.id, students);
      }
    } else {
      this.props.addStudents!(students, true, true)
      .then(()=> toastr.success('Success', 'Student roster uploads successfully.'));
    }
  };

  handleChange = () => (event: React.SyntheticEvent) => {
    const { name, value } = event.target as HTMLInputElement;
    this.setState((prevState: State) => {
      const selectedColumnNames = [
        ...prevState.selectedColumnNames.filter(
          (columnName: string) => (prevState as any)[name] !== columnName
        ),
        value,
      ];
      return {
        ...prevState,
        [name]: value,
        selectedColumnNames: selectedColumnNames,
      };
    });
  };

  handleModalOnEnter = () => {
    if (this.props.onEnter) this.props.onEnter();
    this.setState({
      className: this.props.className || "",
      selectedStudentIdColumn: "",
      selectedFirstNameColumn: "",
      selectedLastNameColumn: "",
      selectedColumnNames: [],
    });
  };

  handleClassNameChange = (event: React.FormEvent<any>) => {
    const { name, value } = event.target as HTMLInputElement;
    this.setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  handleModalClose = () => {
    const { onHide, isLoading } = this.props;
    if (!isLoading) {
      onHide();
    }
  };

  render() {
    const {
      originalFileName,
      rawData,
      show,
      isClass,
      columnsNames,
    } = this.props;
    const {
      selectedStudentIdColumn,
      selectedFirstNameColumn,
      selectedLastNameColumn,
      selectedColumnNames,
      className,
    } = this.state;
    const columnsIsSelected =
      selectedStudentIdColumn &&
      selectedFirstNameColumn &&
      selectedLastNameColumn;
    return (this.props.selectedTeacher && isClass) || !isClass ? (
      //@ts-ignore
      <Modal
        animation={false}
        show={show}
        size="lg"
        backdropClassName="customDarkModalBackdrop in"
        onHide={this.handleModalClose}
        //@ts-ignore
        onShow={this.handleModalOnEnter}
      >
        <Modal.Header
          closeButton
          className="purpleModalHeader orangeModalHeader"
        >
          <Modal.Title>
            {isClass
              ? this.props.selectedClass
                ? `Add Students to ${this.props.selectedClass.name}`
                : `Create Class For ${
                    this.props.selectedTeacher!.user.first_name
                  } ${this.props.selectedTeacher!.user.last_name}`
              : "Upload Roster"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!originalFileName ? (
            <UploadStudentsRosterManual />
          ) : (
            <>
              <div className="text-center">
                {isClass && this.props.selectedTeacher && (
                  <>
                    <UserAvatarSmall
                      userInfo={this.props.selectedTeacher.user}
                    />
                    {/*<Image*/}
                    {/*  src={*/}
                    {/*    this.props.selectedTeacher.user.profile*/}
                    {/*      .profile_image_url*/}

                    {/*  }*/}
                    {/*  // src="https://rc-staging.codecygnus.com/media/default_profile_image.jpg"*/}
                    {/*  className="thumbnailIcon"*/}
                    {/*/>*/}
                    <h3 className="d-inline">
                      &nbsp;
                      {`${this.props.selectedTeacher.user.first_name} ${this.props.selectedTeacher.user.last_name}`}
                    </h3>
                  </>
                )}

                <h2>Great, you uploaded:</h2>

                <div>
                  <FontAwesomeIcon icon="file" />
                  <h4 className="font-weight-bold d-inline">
                    &nbsp;{originalFileName}
                  </h4>
                  <h5>
                    It has {rawData.length} rows. &nbsp;
                    <label className="clickableLabel">
                      Click here to pick a different file.
                      <DataFileReader />
                    </label>
                  </h5>
                </div>
                <br />

                {/*todo change*/}
                {isClass && !this.props.selectedClass && (
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>Class Name</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name="className"
                      value={className}
                      onChange={this.handleClassNameChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                )}

                <p className="text-left font-weight-bold">
                  Tell us the column header (label) you want to use for each
                  field below.
                </p>

                <div className="text-left">
                  <div className="form-group row">
                    <div className="col-sm-6">
                      <ColumnHeaderSelector
                        required
                        name={"selectedFirstNameColumn"}
                        title={"Student First Name Column"}
                        value={selectedFirstNameColumn}
                        onChange={this.handleChange()}
                        columnNames={columnsNames}
                        selectedColumnNames={selectedColumnNames}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-6">
                      <ColumnHeaderSelector
                        required
                        name={"selectedLastNameColumn"}
                        title={"Student Last Name Column"}
                        value={selectedLastNameColumn}
                        onChange={this.handleChange()}
                        columnNames={columnsNames}
                        selectedColumnNames={selectedColumnNames}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-6">
                      <ColumnHeaderSelector
                        required
                        name={"selectedStudentIdColumn"}
                        title={"Student ID Column"}
                        value={selectedStudentIdColumn}
                        onChange={this.handleChange()}
                        columnNames={columnsNames}
                        selectedColumnNames={selectedColumnNames}
                      />
                    </div>
                  </div>
                </div>
                <hr className="wideMargin" />
                {columnsIsSelected && (
                  <>
                    <h6 className="font-weight-bold text-left">
                      {" "}
                      Roster preview
                    </h6>
                    <Table className="stdTable">
                      <thead>
                        <tr>
                          <th>NAME</th>
                          <th>STUDENT ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rawData.map((element: any, index) => (
                          <tr key={index}>
                            <td>{`${element[selectedFirstNameColumn]} ${element[selectedLastNameColumn]}`}</td>
                            <td>{element[selectedStudentIdColumn]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                )}
              </div>

              <div className={"text-right"}>
                <button className="blueBtnMd" onClick={this.handleStudentsAdd}>
                  Save{" "}
                  {this.props.isLoading && (
                    <Spinner animation="border" size="sm" />
                  )}
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    ) : null;
  }
}

export default UploadStudentsRosterModal;
