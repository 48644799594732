import React, { FunctionComponent, useCallback } from "react";
import { SpellingTestGroup } from "../../../../store/spelling-tests/types";
import { getFullName } from "../../../../utils/NamesUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import {
  deleteSpellingTest,
  deleteSpellingTestGroup,
  showSpellingTestGroupUpsertModal,
  showSpellingTestSummaryModal,
} from "../../../../store/spelling-tests/actions";
import SpellingTestGroupStudentRow from "./SpellingTestGroupStudentRow";
import { showConfirmDialog } from "../../../../store/confirm-dialog/actions";

type Props = {
  group: SpellingTestGroup;
};

const SpellingTestGroupItem: FunctionComponent<Props> = (props) => {
  const { group } = props;

  const dispatch = useDispatch();

  const handleShowSpellingTestGroupSummary = useCallback(() => {
    dispatch(showSpellingTestSummaryModal(group));
  }, []);

  const handleSpellingTestGroupEdit = useCallback(() => {
    dispatch(showSpellingTestGroupUpsertModal(group));
  }, [group]);

  const handleSpellingTestGroupDelete = useCallback(() => {
    const onConfirm = () => dispatch(deleteSpellingTestGroup(group.id));
    dispatch(showConfirmDialog({ onConfirm: onConfirm }));
  }, [group.id]);

  return (
    <div className="spelling-test-group-item">
      <div className="d-flex align-items-center">
        <div className="grade">{group.grade}</div>
        <div className="d-flex flex-grow-1 justify-content-between align-items-center">
          <div>
            <h2 className="mb-0 font-weight-bold">{group.notes}</h2>
            <h3 className="font-weight-bold">{getFullName(group.teacher)}</h3>
          </div>
          <div className="d-flex align-items-center">
            <button
              disabled={!group.tests.length}
              className="blueBtnMd"
              onClick={handleShowSpellingTestGroupSummary}
            >
              View Summary
            </button>
            <div className="entry-actions d-flex flex-column ml-3">
              <span onClick={handleSpellingTestGroupEdit} className="edit">
                <FontAwesomeIcon size={"lg"} icon={faEdit} />
              </span>
              <span onClick={handleSpellingTestGroupDelete} className="delete">
                <FontAwesomeIcon size={"lg"} icon={faTrashAlt} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        {group.students.length ? (
          group.students.map((student, index) => (
            <React.Fragment key={`student_${student.id}`}>
              {!!index && <hr className="my-2" />}
              <SpellingTestGroupStudentRow
                key={`group_${group.id}_student_${student.id}`}
                student={student}
                group={group}
              />
            </React.Fragment>
          ))
        ) : (
          <div>No students in this group yet</div>
        )}
      </div>
    </div>
  );
};

export default SpellingTestGroupItem;
