import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Navbar } from "react-bootstrap";
import { ApplicationState } from "../../store";
import { connect, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBell,
  faComment,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  UserAssignmentRoleDisplayedNames,
  UserInfo,
} from "../../store/auth/types";
import { bindActionCreators, Dispatch } from "redux";
import { getUserInfo, logoutUser } from "../../store/auth/actions";
import UserAvatarSmall from "../../containers/onboarding/UserAvatarSmall";
import { IS_READY_COACH } from "../../constants";
import ChatModal from "../pages/chat/ChatModal";
import { openChatModal } from "../../store/chat/actions";
import HeaderNavLinks from "./HeaderNavLinks";
import NotificationsDropDown from "./NotificationsDropDown";
import UserActionsOverlay from "./UserActionsOverlay";
import { push } from "connected-react-router";
import HelpButton from "./page-help/HelpButton";
import RankSpan from "../pages/badges/RankSpan";
import SettingButton from "./permission-setting/SettingButton";
import ReleaseButton from "./latest-release/ReleaseButton";
import UserActivity from "../utils/UserActivity";

type PropsFromState = {
  isAuthenticated: boolean;
  userInfo?: UserInfo;
};

type DispatchProps = {
  push: (path: string) => any;
  logoutUser: () => any;
  getUserInfo: () => any;
  openChatModal: () => any;
};

type Props = PropsFromState & DispatchProps;

const INTERVAL = 300000;

const Header: FunctionComponent<Props> = ({
  isAuthenticated,
  userInfo,
  push,
  logoutUser,
  getUserInfo,
  openChatModal,
}) => {
  const node = useRef<HTMLDivElement | null>(null);

  const [showMobileDropDown, setShowMobileDropDown] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (node && node.current?.contains(e.target)) {
        setShowMobileDropDown((show) => !show);
      } else {
        setShowMobileDropDown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick, false);

    return () =>
      document.removeEventListener("click", handleOutsideClick, false);
  }, []);

  // useEffect(() => {
  //   if (isAuthenticated && !userInfo) {
  //     getUserInfo();
  //   }
  // }, [isAuthenticated, userInfo]);

  useEffect(() => {
    if (isAuthenticated) {
      getUserInfo();
    }
    const interval = setInterval(() => {
      if (isAuthenticated) {
        getUserInfo();
      }
    }, INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const role = useMemo(() => {
    return userInfo?.profile.custom_role_title ?? userInfo?.profile.current_role
    ? UserAssignmentRoleDisplayedNames[userInfo?.profile.current_role]
    : undefined;
  }, [userInfo]);
  
  return (
    <>
      {isAuthenticated ? <UserActivity /> : ''}
      <Navbar className="mainHeader">
        <Navbar.Brand>
          {IS_READY_COACH ? (
            <div className="d-flex">
              <a href="" onClick={() => push("/")}>
                <img
                  style={{
                    width: 200,
                    height: "auto",
                    marginTop: -10,
                    marginLeft: 20,
                  }}
                  src="/media/readycoach-logo.png"
                  alt="ReadyCoach"
                />
              </a>
              {role && (
                <div className="role-label" title={role == 'Superuser' ? "Super Admin" : role}>
                  &nbsp;|&nbsp;<em>{role == 'Superuser' ? "Super Admin" : role}</em>
                </div>
              )}
            </div>
          ) : (
            <span>
              <strong>NJTSS</strong>&nbsp;Early Reading
            </span>
          )}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="readycoach-header" />
        {isAuthenticated && sessionStorage.getItem('loginStatus') && (
          <>
            <Navbar.Collapse id="readycoach-header">
              <HeaderNavLinks />
            </Navbar.Collapse>
            <div className="mainHeaderUserOptions">
              {IS_READY_COACH && (
                <>
                  {/* <ReleaseButton /> */}
                  <SettingButton />
                  <RankSpan />
                  <HelpButton />
                </>
              )}

              <UserActionsOverlay userInfo={userInfo} />
              {IS_READY_COACH && (
                <div
                  className="pointer position-relative ml-4"
                  onClick={() => openChatModal()}
                >
                  <FontAwesomeIcon icon={faComment} />
                  {/*{true && (*/}
                  {/*  <Badge*/}
                  {/*    className="position-absolute"*/}
                  {/*    style={{*/}
                  {/*      top: "-0.25rem",*/}
                  {/*      right: "-0.75rem",*/}
                  {/*    }}*/}
                  {/*    variant="danger"*/}
                  {/*    // pill*/}
                  {/*  >*/}
                  {/*    {3}*/}
                  {/*  </Badge>*/}
                  {/*)}*/}
                </div>
              )}
              <NotificationsDropDown />
            </div>

            <div ref={node}>
              <div className="mainHeaderMobileNav">
                <FontAwesomeIcon icon={faBars} />
              </div>
              <div
                className="mobileHeaderDropdown"
                hidden={!showMobileDropDown}
              >
                <HeaderNavLinks withIcons />
                <hr />
                <div className="mobileUserOptions">
                  <div>
                    <UserAvatarSmall />
                    <span>{userInfo?.first_name || ""}</span>
                  </div>
                  <div>
                    <div className="mobileNavIcon">
                      <FontAwesomeIcon icon={faBell} />
                    </div>
                    Notifications
                  </div>
                  <hr />
                  <div onClick={logoutUser}>
                    <div className="mobileNavIcon">
                      <FontAwesomeIcon icon={faSignOutAlt} />
                    </div>
                    Log Out
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Navbar>
      {IS_READY_COACH && <ChatModal />}
    </>
  );
};

const mapStateToProps = ({ auth }: ApplicationState) => {
  return {
    isAuthenticated: auth.isAuthenticated,
    userInfo: auth.userInfo,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      push: push,
      logoutUser: logoutUser,
      getUserInfo: getUserInfo,
      openChatModal: openChatModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
