import React, { FunctionComponent, useMemo } from "react";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import FidelityChart from "./FidelityChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faComments,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Popover, Spinner, Tooltip } from "react-bootstrap";
import { MeetingType } from "../../../../../../store/onboarding/meeting-module/types";
import { bindActionCreators, Dispatch } from "redux";
import {
  linkToMeetingTab,
  openGoalProgressModal,
  openImplementationCheckModal,
  openProvidingFeedbackModal,
} from "../../../../../../store/onboarding/meeting-module/actions";
import { connect, useSelector } from "react-redux";
import { changeSelectedInterventionGroup } from "../../../../../../store/onboarding/cases/actions";
import { useIntl } from "react-intl";
import LatestActivityComp from "./LatestActivityComp";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";
import useUserRole from "../../../../../../utils/hooks/useUserRole";
import { ApplicationState } from "../../../../../../store";

type DispatchProps = {
  openProvidingFeedbackModal: () => any;
  openImplementationCheckModal: () => any;
  openGoalProgressModal: () => any;
  changeSelectedInterventionGroup: (
    interventionGroupInfo?: InterventionGroup
  ) => any;
  linkToMeetingTab: (id: number, meetingType: MeetingType) => any;
};

type OwnProps = {
  interventionGroup?: InterventionGroup;
  hideActions?: boolean;
  progressOverviewLoader?: boolean;
};

type Props = OwnProps & DispatchProps;

const MonitoringStageInfo: FunctionComponent<Props> = ({
  hideActions,
  interventionGroup,
  openImplementationCheckModal,
  changeSelectedInterventionGroup,
  openGoalProgressModal,
  linkToMeetingTab,
  progressOverviewLoader
}) => {
  const intl = useIntl();
  const { isDistrictOwner } = useUserRole();
  
  const displayGroupChange = useSelector<
  ApplicationState
>((s) => s.cases.displayGroupName);

  return (
    <>
      <LatestActivityComp latestActivity={interventionGroup?.latest_activity} />
      <div
        className="d-flex align-items-center"
        id="monitoring-panel"
        onClick={(e) => e.stopPropagation()}
        style={{ cursor: "default" }}
      >
        {interventionGroup && (
          <div className="text-center">
            {interventionGroup?.progress_overview?.student_goal_progress.length ? (
                displayGroupChange === "active" ?
                  <OverlayTrigger
                    trigger={"hover"}
                    placement="top"
                    overlay={
                      <Tooltip className="customTooltip" id="tooltip-top-column">
                        {intl.formatMessage({ id: "app.dashboard.yourAssigmentTab.studentGroupItem.monitoring.start.label.hover" })}
                      </Tooltip>
                    }
                  >
                    <h4 className="font-weight-bold">
                      {!isDistrictOwner ? `Student Progress & Intervention Fidelity` : `Student Progress`}
                    </h4>
                  </OverlayTrigger>
                :
                  <h4 className="font-weight-bold">
                  {!isDistrictOwner ? `Student Progress & Intervention Fidelity` : `Student Progress`}
                  </h4>
            ) : (
              <h4 className="text-center font-weight-bold">
                {intl.formatMessage({
                  id:
                    "app.dashboard.yourAssigmentTab.studentGroupItem.monitoring.start.label",
                })}
              </h4>

            )}
            {progressOverviewLoader ? (
                <LoadingIndicator/>
              ) : 
              <FidelityChart interventionGroup={interventionGroup} />}
            

            {interventionGroup?.latest_activity && (
              <small className="font-weight-bold details-link">
                {intl.formatMessage({
                  id:
                    "app.dashboard.yourAssigmentTab.studentGroupItem.monitoring.seeDetailsBtn.label",
                })}
              </small>
            )}
          </div>
        )}
        {!hideActions && (
          <div className="d-flex flex-column">
            <OverlayTrigger
              overlay={
                <Popover id="tooltip">
                  <Popover.Title>
                    Check Intervention Implementation
                  </Popover.Title>
                  <Popover.Content>
                    Document intervention implementation using a checklist.
                  </Popover.Content>
                </Popover>
              }
            >
              <span
                className="my-2 pointer"
                onClick={() => {
                  changeSelectedInterventionGroup(interventionGroup);
                  openImplementationCheckModal();
                }}
              >
                <FontAwesomeIcon icon={faList} size={"lg"} />
              </span>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={
                <Popover id="tooltip">
                  <Popover.Title>Provide Implementation Feedback</Popover.Title>
                  <Popover.Content>
                    Share comments and discuss next steps for intervention
                    implementation.
                  </Popover.Content>
                </Popover>
              }
            >
              <span
                className="my-2 pointer"
                onClick={() =>
                  linkToMeetingTab(
                    interventionGroup!.id!,
                    MeetingType.FEEDBACK_INTERVENTION
                  )
                }
              >
                <FontAwesomeIcon icon={faComments} size={"lg"} />
              </span>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={
                <Popover id="tooltip">
                  <Popover.Title>
                    Record Students&apos; Progress Towards Goals
                  </Popover.Title>
                  <Popover.Content>
                    Record student data over time (scores, ratings,
                    observations, etc.).
                  </Popover.Content>
                </Popover>
              }
            >
              <span
                className="my-2 pointer"
                onClick={() => {
                  changeSelectedInterventionGroup(interventionGroup);
                  openGoalProgressModal();
                }}
              >
                <FontAwesomeIcon icon={faChartLine} size={"lg"} />
              </span>
            </OverlayTrigger>
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      openProvidingFeedbackModal: openProvidingFeedbackModal,
      openImplementationCheckModal: openImplementationCheckModal,
      changeSelectedInterventionGroup: changeSelectedInterventionGroup,
      openGoalProgressModal: openGoalProgressModal,
      linkToMeetingTab: linkToMeetingTab,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(MonitoringStageInfo);
