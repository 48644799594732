import React from "react";
import { useDispatch } from "react-redux";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setShowUserModal } from "../../../../../../store/superuser/actions";

const UsersTableActions = () => {
  const dispatch = useDispatch();

  const onCreateUser = () => {
    dispatch(setShowUserModal({ show: true, user: null }));
  };

  return (
    <div>
      <button className="blueBtnSm mr-3" onClick={onCreateUser}>
        <FontAwesomeIcon icon={faPlusCircle} style={{ color: "white" }} />{" "}
        Create User
      </button>
    </div>
  );
};

export default UsersTableActions;
