import React, { FunctionComponent, useMemo } from "react";
import FullWordCell from "./cells/FullWordCell";
import GrammarPart from "./GrammarPart";
import {
  GrammarType,
  SpellingTestQuestion,
} from "../../../../store/spelling-tests/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";

type Props = {
  selectedGrammarTypes: GrammarType[];
  question: SpellingTestQuestion;
};

const WordRow: FunctionComponent<Props> = (props) => {
  const { selectedGrammarTypes, question } = props;

  const spellingTestAnswers = useSelector(
    (s: ApplicationState) => s.spellingTests.spellingTestAnswers
  );

  const answer = useMemo(
    () => spellingTestAnswers.find((answer) => answer.word === question.word),
    [spellingTestAnswers, question.word]
  );

  return (
    <tr>
      <td>{question.order + 1}</td>
      <FullWordCell question={question} answer={answer} />
      {selectedGrammarTypes.map((gt) => (
        <GrammarPart
          key={`question_${question.order}_${gt}`}
          grammarType={gt}
          question={question}
          answer={answer}
        />
      ))}
    </tr>
  );
};

export default WordRow;
