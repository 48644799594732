import React, { FunctionComponent, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import GeneralSpellingTestGroupStatisticsTable from "../spelling-test-general-statistics/GeneralSpellingTestGroupStatisticsTable";
import { hideSpellingTestSummaryModal } from "../../../../store/spelling-tests/actions";

type Props = {};

const SpellingTestSummaryModal: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const selectedGroup = useSelector(
    (s: ApplicationState) => s.spellingTests.selectedGroup
  );

  const show = useSelector(
    (s: ApplicationState) => s.spellingTests.showSpellingTestSummaryModal
  );

  const handleHideModal = useCallback(() => {
    dispatch(hideSpellingTestSummaryModal());
  }, []);

  return (
    <Modal
      show={show}
      dialogClassName="max-w-90"
      onHide={handleHideModal}
      animation={false}
      size="xl"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {selectedGroup && (
            <GeneralSpellingTestGroupStatisticsTable group={selectedGroup} />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SpellingTestSummaryModal;
