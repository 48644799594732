import React, { FunctionComponent, useState } from "react";
import { Nav, TabContainer, TabContent, TabPane } from "react-bootstrap";
import InterventionFidelityCollapse from "../InterventionFidelityCollapse";
import LoggedProgressTable from "./LoggedProgressTable";
import LoggedInterventionsTable from "./LoggedInterventionsTable";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import useUserAssignedToGroup from "../assigment/hooks/useUserAssignedToGroup";
import { EventKey } from "react-bootstrap/esm/types";

type OwnProps = {
  interventionGroup?: InterventionGroup;
  hideActions?: boolean;
};

type Props = OwnProps;

enum TabKeys {
  FIDELITY_CHART = "FIDELITY_CHART",
  LOGGED_PROGRESS = "LOGGED_PROGRESS",
  LOGGED_INTERVENTIONS = "LOGGED_INTERVENTIONS",
}

const InterventionFidelityTabContainer: FunctionComponent<Props> = ({
  interventionGroup,
  hideActions,
}) => {
  const [activeTab, setActiveTab] = useState<string | null>(
    TabKeys.FIDELITY_CHART
  );

  const { isUserAssignedToGroup, isStudentDetail } = useUserAssignedToGroup(interventionGroup!);

  return (
    <TabContainer activeKey={activeTab as EventKey} onSelect={setActiveTab}>
      <Nav variant="pills" className="justify-content-center text-center">
        <Nav.Item>
          <Nav.Link
            eventKey={TabKeys.FIDELITY_CHART}
            style={{ height: "unset" }}
          >
            <div>Fidelity Chart</div>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey={TabKeys.LOGGED_PROGRESS}
            style={{ height: "unset" }}
          >
            <div>Logged Progress</div>
          </Nav.Link>
        </Nav.Item>
        {isStudentDetail ? 
          (isUserAssignedToGroup ? 
          <Nav.Item>
            <Nav.Link
              eventKey={TabKeys.LOGGED_INTERVENTIONS}
              style={{ height: "unset" }}
            >
              <div>Logged Interventions</div>
            </Nav.Link>
          </Nav.Item>
          : '')
        :
        <Nav.Item>
          <Nav.Link
            eventKey={TabKeys.LOGGED_INTERVENTIONS}
            style={{ height: "unset" }}
          >
            <div>Logged Interventions</div>
          </Nav.Link>
        </Nav.Item>
        }
      </Nav>
      <TabContent>
        <TabPane eventKey={TabKeys.FIDELITY_CHART}>
          <InterventionFidelityCollapse />
        </TabPane>
        <TabPane eventKey={TabKeys.LOGGED_PROGRESS}>
          <LoggedProgressTable
            interventionGroup={interventionGroup!}
            hideActions={hideActions}
          />
        </TabPane>
        <TabPane eventKey={TabKeys.LOGGED_INTERVENTIONS}>
          <LoggedInterventionsTable
            interventionGroup={interventionGroup!}
            hideActions={hideActions}
          />
        </TabPane>
      </TabContent>
    </TabContainer>
  );
};

export default InterventionFidelityTabContainer;
