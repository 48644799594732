import React, { useRef, useState } from "react";
import { Cell } from "fixed-data-table-2";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { IS_READY_COACH } from "../../../../../../../constants";
import AddStudentsToTableOverlay from "../AddStudentToTableOverlay";

type Props = {};

const AddStudentsHeaderCell: React.FunctionComponent<Props> = (props) => {
  const [showAddStudentsOverlay, setShowAddStudentsOverlay] = useState<boolean>(
    false
  );

  const addRef = useRef(null);

  const handleAddClick = () => {
    setShowAddStudentsOverlay((prevState) => !prevState);
  };

  const onHideAddOverlay = () => {
    setShowAddStudentsOverlay(false);
  };

  return IS_READY_COACH ? (
    <Cell>
      <OverlayTrigger
        overlay={
          showAddStudentsOverlay ? (
            <div />
          ) : (
            <Popover id="addStudentsToTable">
              <Popover.Title>Add Students to Table</Popover.Title>
              <Popover.Content>
                <p>
                  You can add other students you're working with to the table to
                  enter their data manually.
                </p>
              </Popover.Content>
            </Popover>
          )
        }
      >
        <span
          onClick={handleAddClick}
          ref={addRef}
          className="showFiltersButton"
        >
          <FontAwesomeIcon icon={faPlusCircle} size="lg" color={"black"} />
        </span>
      </OverlayTrigger>
      <AddStudentsToTableOverlay
        placement={"right"}
        showOverlay={showAddStudentsOverlay}
        buttonRef={addRef}
        onHide={onHideAddOverlay}
      />
    </Cell>
  ) : (
    <Cell />
  );
};

export default AddStudentsHeaderCell;
