import React, { FunctionComponent, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { GoalSummary } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { getGoalTitle } from "../../../../../utils";
import { ConcernAreaDisplayedNames } from "../../../../../../store/onboarding/cases/types";

type OwnProps = {
  goals: Array<GoalSummary>;
};

type Props = OwnProps;

const GoalsTable: FunctionComponent<Props> = ({ goals }) => {
  const columns = useMemo(
    () => [
      {
        dataField: "goalName",
        text: "Goal Name",
        sort: true,
      },
      {
        dataField: "coach",
        text: "Coach Name",
        sort: true,
      },
      {
        dataField: "case",
        text: "Case",
        sort: true,
      },
      {
        dataField: "concern_area",
        text: "Areas of focus or concern",
        sort: true,
      },
      {
        dataField: "target",
        text: "Target",
        sort: true,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      goals.map((g) => ({
        goalName: getGoalTitle(g.goal),
        coach: getFullName(g.coach),
        case: `${getFullName(g.teacher)} / ${getFullName(g.goal.student)}`,
        concern_area: ConcernAreaDisplayedNames[g.goal.concern_area],
        target: getGoalTitle(g.goal),
      })),
    [goals]
  );

  const pagination = useMemo(() => {
    if (goals.length > 10) {
      return {
        sizePerPageList: [
          {
            text: "10",
            value: 10,
          },
          {
            text: "20",
            value: 20,
          },
          {
            text: "50",
            value: 50,
          },
        ],
      };
    }
  }, [goals]);

  return (
    <BootstrapTable
      classes="rosterTable"
      keyField="id"
      bordered={false}
      bootstrap4
      data={data}
      columns={columns}
      noDataIndication={"No data"}
      pagination={pagination && paginationFactory(pagination)}
    />
  );
};

export default GoalsTable;
