import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { Student, StudentInfo } from "../../../../../store/onboarding/types";
import { getFullName } from "../../../../../utils/NamesUtils";
import { getStudents } from "../../../../../store/onboarding/actions";

type OwnProps = {
  selectedStudents: Student[];
  onStudentAdd: (student: Student) => void;
};

type Props = OwnProps;

const DistrictStudentsList: FunctionComponent<Props> = (props) => {
  const { selectedStudents, onStudentAdd } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStudents());
  }, []);

  const studentRoaster = useSelector<ApplicationState, StudentInfo[]>(
    (s) => s.onboarding.studentsRoster
  );

  const students = useMemo(() => {
    return studentRoaster
      .map<Student>(({ id, first_name, last_name, unique_id }) => ({
        id,
        first_name,
        last_name,
        unique_id,
      }))
      .filter((s) => !selectedStudents.some((ss) => ss.id == s.id));
  }, [studentRoaster, selectedStudents]);

  const handleSelectStudent = (s: Student) => () => {
    onStudentAdd(s);
  };

  return (
    <div className="mt-1">
      {students.length ? (
        <div className="existing-students-container">
          {students.map((s) => (
            <div key={s.id} onClick={handleSelectStudent(s)}>
              {getFullName(s)}
            </div>
          ))}
        </div>
      ) : (
        <i>No students</i>
      )}
    </div>
  );
};

export default DistrictStudentsList;
