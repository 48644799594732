import React, { FunctionComponent, useEffect, useMemo } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getDistrictSummaryReport,
  getRaceInfo,
  getTeachers,
} from "../../../../../../store/onboarding/actions";
import { ApplicationState } from "../../../../../../store";
import {
  GradeLevels,
  raceList,
  ReactSelectOption,
  TeacherInfo,
} from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import {
  UserAssignmentRole,
  UserInfo,
} from "../../../../../../store/auth/types";
import { Grade } from "../../../../../../store/groups/types";
import {
  ConcernArea,
  ConcernAreaDisplayedNames,
} from "../../../../../../store/onboarding/cases/types";
import _ from "lodash";
import DatePicker from "react-datepicker";
import CustomDateInput from "../../../../../common/onboarding/third-step/set-goal-modal/common/CustomDateInput";
import { useLoading } from "../../../../../../utils/hooks/useLoading";
import { Spinner } from "react-bootstrap";

type OwnProps = {
  districtId: number | undefined;
  raceFilter: number | undefined;
  setRaceFilter: React.Dispatch<React.SetStateAction<number | undefined>>;
  gradesFilter: Grade[];
  setGradesFilter: React.Dispatch<React.SetStateAction<Grade[]>>;
  selectedEducators: UserInfo[];
  setSelectedEducators: React.Dispatch<React.SetStateAction<UserInfo[]>>;
  concernAreaFilter: ConcernArea;
  setConcernAreaFilter: React.Dispatch<React.SetStateAction<ConcernArea>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
};

type Props = OwnProps;

const FiltersPanel: FunctionComponent<Props> = (props) => {
  const {
    districtId,
    selectedEducators,
    setSelectedEducators,
    raceFilter,
    setRaceFilter,
    gradesFilter,
    setGradesFilter,
    concernAreaFilter,
    setConcernAreaFilter,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = props;

  const dispatch = useDispatch();

  const currentDataPeriod = useSelector(
    (s: ApplicationState) => s.onboarding.currentDataPeriod
  );

  const raceInfo = useSelector(
    (s: ApplicationState) => s.onboarding.raceInfo
  );

  useEffect(() => {
    dispatch(getTeachers());
    if(currentDataPeriod && !raceInfo.races.length){
      dispatch(getRaceInfo(currentDataPeriod.id))
    }
  }, []);

  const teachers = useSelector<ApplicationState, TeacherInfo[]>(
    (s) => s.onboarding.teachersRoster
  );

  const isLoading = useSelector<ApplicationState, boolean>(
    (s) => s.onboarding.isLoading.getTeachers
  );

  const options = useMemo(() => {
    return teachers.map((t) => t.user);
  }, [teachers]);

  const handleSelectedTeacherChange = (values: any) => {
    setSelectedEducators(values ? values : []);
  };

  const raceListOptions: ReactSelectOption<number>[] =  useMemo(() => {
    if(raceInfo?.races.length) {
      return [...raceInfo.races.map((race, index) => ({
        value: index, label: race 
     })), {value: 7, label: 'Other'}]
    } else {
      return [];
    }
  },[]);

  const concernAreaOptions: ReactSelectOption<number>[] = useMemo(() => {
    return _.chain(ConcernArea)
      .values()
      .filter(Number.isInteger)
      .map((key) => ({
        label: key == 2 ? "All Domains" : ConcernAreaDisplayedNames[key],
        value: key,
      }))
      .value();
  }, []);

  const reportPending = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.onboarding.isLoading.getDistrictSummaryReport
  );
  const reportError = useSelector<ApplicationState, string | undefined>(
    (s) => s.onboarding.errors.getDistrictSummaryReport
  );
  useLoading(reportPending, reportError);

  const displayCharts = useSelector<ApplicationState, boolean>(
    (s) => s.onboarding.districtSummaryOptions.displayCharts
  );

  const collapsedAreas = useSelector(
    (s: ApplicationState) => s.onboarding.districtSummaryOptions.collapsedAreas
  );

  const handleReportPrint = () => {
    const groupedByRole = _.chain(selectedEducators)
      .groupBy(
        (e) => e.profile.current_assignment?.role ?? e.profile.current_role
      )
      .value();

    const teachersIds =
      groupedByRole[UserAssignmentRole.TEACHER]?.map((u) => u.id) ?? [];
    const coachesIds =
      groupedByRole[UserAssignmentRole.COACH]?.map((u) => u.id) ?? [];

    const displayedSections = _.chain(collapsedAreas)
      .toPairs()
      .filter(([, value]) => value)
      .map(([key]) => key)
      .value();

    dispatch(
      getDistrictSummaryReport({
        districtId: districtId,
        teachersIds: teachersIds,
        coachesIds: coachesIds,
        race: raceFilter,
        grades: gradesFilter,
        concernArea: concernAreaFilter,
        startDate: startDate ? startDate.toISOString() : undefined,
        endDate: endDate ? endDate.toISOString() : undefined,
        displayCharts: displayCharts,
        displayedSections: displayedSections,
      })
    );
  };

  let raceListOption:any;
  if(!(raceListOptions.some((data)=> data.label == 'All Races/Ethnicities'))){
    let obj = {value: undefined, label: 'All Races/Ethnicities'};
    raceListOption = [obj, ...raceListOptions];
  }
  return (
    <div className="district-summary-filters mb-2">
      <div className="row">
        <div className="col-6 px-0">
          <Select
            isMulti
            isClearable
            placeholder="All Grades"
            className="dataFiltersSelect"
            value={GradeLevels.filter((gl) => gradesFilter.includes(gl.value))}
            options={GradeLevels}
            onChange={(value: any) => {
              setGradesFilter(
                value ? value.map((option: any) => option.value) : []
              );
            }}
          />
        </div>
        <div className="col-6 pr-0">
          <Select
            isClearable
            isMulti
            isLoading={isLoading}
            options={options}
            getOptionLabel={(teacher) => getFullName(teacher)}
            getOptionValue={(teacher) => teacher.id! as any}
            placeholder="All Teachers/Coaches"
            value={selectedEducators}
            onChange={handleSelectedTeacherChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6 pl-0">
          <Select
            placeholder="All Domains"
            options={concernAreaOptions}
            value={concernAreaOptions.find((r) => r.value === concernAreaFilter)}
            onChange={(value: any) => {
              setConcernAreaFilter(value ? value.value : undefined);
            }}
          />

        </div>
        <div className="col-6 px-0">
          <Select
            isClearable
            placeholder="All Races/Ethnicities"
            options={raceListOption}
            value={raceListOption.find((r:any) => r.value === raceFilter)}
            onChange={(value: any) => {
              setRaceFilter(value ? value.value : undefined);
            }}
          />

        </div>
      </div>




      <div className="d-flex" style={{ minWidth: "auto", flexBasis: "auto" }}>
        <DatePicker
          isClearable
          withPortal
          customInput={<CustomDateInput isClearable position="right" />}
          maxDate={endDate ?? null}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
        />
        <DatePicker
          isClearable
          customInput={<CustomDateInput isClearable position="left" />}
          minDate={startDate ?? null}
          withPortal
          selected={endDate}
          onChange={(date) => setEndDate(date)}
        />
      </div>

      <button className="whiteBtnSm" onClick={handleReportPrint}>
        Generate PDF {reportPending && <Spinner animation="border" size="sm" />}
      </button>
    </div>
  );
};

export default FiltersPanel;
