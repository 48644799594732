import React, { FunctionComponent, useMemo } from "react";
import { InterventionFidelity } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import VerticalBarChart from "./VerticalBarChart";

type OwnProps = {
  label?: string;
  interventionFidelity: Array<InterventionFidelity>;
};

type Props = OwnProps;

const AverageInterventionFidelityChart: FunctionComponent<Props> = (props) => {
  const { label, interventionFidelity } = props;

  const labels = useMemo(
    () =>
      interventionFidelity.map((intFidelity) => getFullName(intFidelity.coach)),
    [interventionFidelity]
  );
  const data = useMemo(
    () =>
      interventionFidelity.map(
        (intFidelity) => +(intFidelity.avg_fidelity * 100).toFixed(0)
      ),
    [interventionFidelity]
  );

  if (!data.length) {
    return null;
  }

  return (
    <div className="district-summary-chart-container">
      {label && <h3 className="text-center">{label}</h3>}
      <div>
        <VerticalBarChart labels={labels} data={data} />
      </div>
    </div>
  );
};

export default AverageInterventionFidelityChart;
