import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { useMemo } from "react";
import { UserInfo } from "../../store/auth/types";
import { getFullName } from "../NamesUtils";
import { useIntl } from "react-intl";
import { IS_READY_COACH } from "../../constants";

const useCreatedBy = (user?: UserInfo) => {
  const userInfo = useSelector((s: ApplicationState) => s.auth.userInfo);
  const intl = useIntl();

  const platformName = useMemo(
    () => (IS_READY_COACH ? "ReadyCoach" : "NJTSS Early Reading"),
    []
  );

  const createdBy = useMemo(() => {
    if (!user) return platformName;
    if (user.id === (userInfo && userInfo.id)) {
      return intl.formatMessage({
        id: "app.dashboard.interventions.filters.createdBy.you.label",
      });
    } else {
      return getFullName(user);
    }
  }, [user, userInfo, platformName]);

  return { platformName, createdBy };
};

export default useCreatedBy;
