import React, { ChangeEvent, Component } from "react";
import Modal from "react-bootstrap/Modal";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  hideFailedInvitationsAlert,
  hideInviteTeachersModal,
  sendInviteToTeachers,
} from "../../../../../store/onboarding/actions";
import {
  DataSharingTableData,
  GradeLevels,
  InvitesResponse,
  ReactSelectOption,
  School,
  SendInviteRequest,
  TeacherInfo,
  UserSpecification,
  UserSpecifications,
} from "../../../../../store/onboarding/types";
import Select from "react-select";
import { Grade } from "../../../../../store/groups/types";
import { Form, FormControl, FormGroup, Spinner } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { ValueType } from "react-select/src/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FailedInvitationsAlert from "../../../onboarding/second-step/upload-educators/FailedInvitationsAlert";
import { IS_READY_COACH } from "../../../../../constants";
import { getFullName } from "../../../../../utils/NamesUtils";

type OwnProps = {
  selectedUser?: DataSharingTableData;
};

type PropsFromState = {
  tableData: Array<DataSharingTableData>;
  invites: InvitesResponse;
  isLoading: {
    sendInviteToTeachers: boolean;
  };
  errors: {
    sendInviteToTeachers?: string;
  };
  teachers: TeacherInfo[];
  schools: School[];
  showModal: boolean;
  districtUserName?: string;
  showFailedInvitationsAlert: boolean;
};

type DispatchProps = {
  hideInviteTeachersModal: () => any;
  hideFailedInvitationsAlert: () => any;
  sendInviteToTeachers: (invites: SendInviteRequest[]) => any;
};

type Props = OwnProps & PropsFromState & DispatchProps;

type State = {
  invites: SendInviteRequest[];
};

class InviteTeachersModal extends Component<Props, State> {
  defaultState: Readonly<State> = {
    invites: [],
  };

  state: Readonly<State> = this.defaultState;

  componentDidUpdate(
    prevProps: Readonly<OwnProps & PropsFromState & DispatchProps>,
    prevState: Readonly<{}>
  ): void {
    if (!prevProps.showModal && this.props.showModal) {
      this.setState({
        invites: this.filteredTableData().map((row) => {
          return {
            id: row.id,
            first_name: row.user.first_name,
            last_name: row.user.last_name,
            email: "",
            grades: row.grades,
            teachers: [
              ...row.teachers.map((teacher) => teacher.id),
              row.user.id,
            ],
            all_data: IS_READY_COACH ? true : row.all_data,
            role: IS_READY_COACH ? "teacher" : undefined,
            existing_user_id: row.user.id,
            originalUser: row.user,
          };
        }),
      });
    }
    if (
      prevProps.isLoading.sendInviteToTeachers &&
      !this.props.isLoading.sendInviteToTeachers
    ) {
      if (this.props.errors.sendInviteToTeachers) {
        toastr.error("Error!", this.props.errors.sendInviteToTeachers);
      } else {
        const { failed_invites, successful_invites } = this.props.invites;
        if (failed_invites.length > 0) {
          // debugger;
          const invites = this.state.invites.filter(
            (invite) =>
              !successful_invites.some(
                (successfulInvite) =>
                  successfulInvite.user.email === invite.email
              )
          );
          this.setState({
            invites: invites,
          });
        } else {
          this.handleHideModal();
        }
      }
    }
  }

  filteredTableData = () => {
    return this.props.tableData.filter((row) => row.relationship_status === 4);
  };

  handleGradeLevelChange = (invite: SendInviteRequest) => (
    options: ValueType<ReactSelectOption<Grade>, true>
  ) => {
    if (options && (options as ReactSelectOption<Grade>[]).length > 0) {
      this.setState((prevState) => ({
        ...prevState,
        invites: [
          ...prevState.invites.map((inv) =>
            inv === invite
              ? {
                  ...inv,
                  grades: (options as ReactSelectOption<Grade>[]).map(
                    (v) => v.value
                  ),
                }
              : inv
          ),
        ],
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        invites: [
          ...prevState.invites.map((inv) =>
            inv === invite
              ? {
                  ...inv,
                  grades: [],
                }
              : inv
          ),
        ],
      }));
    }
  };

  handleTeacherChange = (invite: SendInviteRequest) => (
    options: ValueType<TeacherInfo, true>
  ) => {
    if (options && (options as TeacherInfo[]).length > 0) {
      this.setState((prevState) => ({
        ...prevState,
        invites: [
          ...prevState.invites.map((inv) =>
            inv === invite
              ? {
                  ...inv,
                  teachers: (options as TeacherInfo[]).map((t) => t.user.id),
                }
              : inv
          ),
        ],
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        invites: [
          ...prevState.invites.map((inv) =>
            inv === invite
              ? {
                  ...inv,
                  teachers: [],
                }
              : inv
          ),
        ],
      }));
    }
  };

  handleSchoolChange = (invite: SendInviteRequest) => (
    options: ValueType<School, true>
  ) => {
    if (options && (options as School[]).length > 0) {
      this.setState((prevState) => ({
        ...prevState,
        invites: [
          ...prevState.invites.map((inv) =>
            inv === invite
              ? {
                  ...inv,
                  schools: (options as School[]).map((sc) => sc.id),
                }
              : inv
          ),
        ],
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        invites: [
          ...prevState.invites.map((inv) =>
            inv === invite
              ? {
                  ...inv,
                  schools: [],
                }
              : inv
          ),
        ],
      }));
    }
  };

  handleUserSpecificationChange = (invite: SendInviteRequest) => (
    options: ValueType<ReactSelectOption<UserSpecification>, false>
  ) => {
    if (options && (options as ReactSelectOption<UserSpecification>)) {
      this.setState((prevState) => ({
        ...prevState,
        invites: [
          ...prevState.invites.map((inv) =>
            inv === invite
              ? {
                  ...inv,
                  role: (options as ReactSelectOption<UserSpecification>).value,
                }
              : inv
          ),
        ],
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        invites: [
          ...prevState.invites.map((inv) =>
            inv === invite
              ? {
                  ...inv,
                  role: undefined,
                }
              : inv
          ),
        ],
      }));
    }
  };

  handleChangeAllData = (invite: SendInviteRequest) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      invites: [
        ...prevState.invites.map((inv) =>
          invite === inv
            ? {
                ...inv,
                all_data: checked,
              }
            : inv
        ),
      ],
    }));
  };

  removeInvite = (invite: SendInviteRequest) => () => {
    this.setState((prevState) => ({
      ...prevState,
      invites: prevState.invites.filter((inv) => inv !== invite),
    }));
  };

  onSend = (event: React.FormEvent) => {
    event.preventDefault();
    const target = event.target;
    const { invites } = this.state;
    const { isLoading } = this.props;
    if (!isLoading.sendInviteToTeachers) {
      const request = invites.map(
        (inv, index): SendInviteRequest => ({
          ...inv,
          // @ts-ignore
          first_name: target[index + "-first_name"].value,
          // @ts-ignore
          last_name: target[index + "-last_name"].value,
          // @ts-ignore
          email: target[index + "-email"].value,
          grades: inv.all_data ? undefined : inv.grades,
          teachers: inv.all_data ? undefined : inv.teachers,
        })
      );
      this.setState({ invites: request });
      this.props.sendInviteToTeachers(request).then(
        () => {
        if(!this.props.showFailedInvitationsAlert && !this.props.errors?.sendInviteToTeachers){
          toastr.success(
            "Success",
            `You have invited new user(s) to ReadyCoach. Your district admin (${this.props.districtUserName}) has been contacted to establish data accessing settings for the invited user to access shared data.`
          );
        }
        },
        (err: string) => toastr.error("Failed to send the invite", err)
      );
    }
  };

  handleHideModal = () => {
    this.props.hideInviteTeachersModal();
    this.props.hideFailedInvitationsAlert();
  };

  render() {
    const { invites } = this.state;
    const { showModal, isLoading } = this.props;
    const getTeacherOptions = (invite: SendInviteRequest) => {
      if (!invite.grades || invite.grades.length === 0) {
        return this.props.teachers;
      } else {
        return this.props.teachers.filter(
          (t) =>
            !t.user.available_grades ||
            (invite.grades &&
              t.user.available_grades.some((ag) => invite.grades!.includes(ag)))
        );
      }
    };
    return (
      <Modal
        show={showModal}
        onHide={this.handleHideModal}
        animation={false}
        size="xl"
        centered
        backdropClassName={"customDarkModalBackdrop in"}
      >
        <Modal.Header
          closeButton
          className={IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"}
        >
          <Modal.Title>Invite Teachers Imported From Your Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-1">
            <FailedInvitationsAlert />
          </div>
          <div className="d-flex mb-3">
            <h4 className="font-weight-bold" style={{ width: "35%" }}>
              With whom do you want to share your data?
            </h4>
            <h4 className="font-weight-bold" style={{ width: "65%" }}>
              Which data do you want to share?
            </h4>
          </div>

          <div className="flexEqualWidth mb-1">
            <h4 className="font-weight-bold">From Data File</h4>
            <h4 className="font-weight-bold">First Name</h4>
            <h4 className="font-weight-bold">Last Name</h4>
            <h4 className="font-weight-bold">Email</h4>
            {/*<h4*/}
            {/*  className="font-weight-bold mr-2"*/}
            {/*  style={{ minWidth: "15%", maxWidth: "15%" }}*/}
            {/*>*/}
            {/*  Grade-level data*/}
            {/*</h4>*/}
            {/*<h4*/}
            {/*  className="font-weight-bold mr-2"*/}
            {/*  style={{ minWidth: "15%", maxWidth: "15%" }}*/}
            {/*>*/}
            {/*  Specific teachers' student data*/}
            {/*</h4>*/}
            {/*<h4*/}
            {/*  className="font-weight-bold mr-2"*/}
            {/*  style={{ minWidth: "13%", maxWidth: "13%" }}*/}
            {/*>*/}
            {/*  Specific schools*/}
            {/*</h4>*/}
            {/*<h4*/}
            {/*  className="font-weight-bold mr-1"*/}
            {/*  style={{ minWidth: "13%", maxWidth: "13%" }}*/}
            {/*>*/}
            {/*  Kind of user*/}
            {/*</h4>*/}
            {/*<h4*/}
            {/*  className="font-weight-bold mr-2"*/}
            {/*  style={{ minWidth: "5%", maxWidth: "5%" }}*/}
            {/*>*/}
            {/*  All Data*/}
            {/*</h4>*/}
          </div>
          <Form
            className="inviteTeachersModalTable"
            id="inviteTeachersForm"
            onSubmit={this.onSend}
          >
            {invites.map((invite, index) => (
              <div key={invite.id ? invite.id : index}>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="d-flex flex-column" style={{ flex: 1 }}>
                    <div className="flexEqualWidth">
                      <h3 className="mr-2 mb-3">
                        {getFullName(invite.originalUser)}
                      </h3>
                      <FormGroup
                        className=" mr-2"
                        controlId={index + "-first_name"}
                      >
                        <FormControl
                          required
                          name={"first_name"}
                          type="string"
                          defaultValue={invite.first_name}
                        />
                      </FormGroup>
                      <FormGroup
                        className=" mr-2"
                        controlId={index + "-last_name"}
                      >
                        <FormControl
                          required
                          name={"last_name"}
                          type="string"
                          defaultValue={invite.last_name}
                        />
                      </FormGroup>
                      <FormGroup className=" mr-2" controlId={index + "-email"}>
                        <FormControl
                          required
                          name={"email"}
                          type="email"
                          defaultValue={invite.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          Incorrect email.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </div>
                    {!IS_READY_COACH && (
                      <div className="flexEqualWidth">
                        <>
                          <FormGroup className="mr-2">
                            <Select
                              isMulti
                              isClearable
                              isDisabled={invite.all_data}
                              placeholder="Select Grade-level..."
                              className="dataFiltersSelect"
                              value={GradeLevels.filter(
                                (gl) =>
                                  invite.grades &&
                                  invite.grades.includes(gl.value)
                              )}
                              options={GradeLevels}
                              onChange={this.handleGradeLevelChange(invite)}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (styles) => ({
                                  ...styles,
                                  zIndex: 2000,
                                }),
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="mr-2">
                            <Select
                              isMulti
                              isClearable
                              isDisabled={invite.all_data}
                              className="dataFiltersSelect"
                              value={this.props.teachers.filter(
                                (x) =>
                                  invite.teachers &&
                                  invite.teachers.includes(x.user.id)
                              )}
                              options={getTeacherOptions(invite)}
                              getOptionLabel={({ user }: TeacherInfo) =>
                                `${user.first_name} ${user.last_name}`
                              }
                              getOptionValue={({ user }: TeacherInfo) =>
                                user.id!.toString()
                              }
                              onChange={this.handleTeacherChange(invite)}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (styles) => ({
                                  ...styles,
                                  zIndex: 2000,
                                }),
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="mr-2">
                            <Select
                              isMulti
                              isClearable
                              isDisabled={invite.all_data}
                              className="dataFiltersSelect"
                              placeholder="Select Schools..."
                              value={this.props.schools.filter(
                                (x) =>
                                  invite.schools &&
                                  invite.schools.includes(x.id)
                              )}
                              options={this.props.schools}
                              getOptionLabel={(school: School) => school.name}
                              getOptionValue={(school: School) =>
                                school.id.toString()
                              }
                              onChange={this.handleSchoolChange(invite)}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (styles) => ({
                                  ...styles,
                                  zIndex: 2000,
                                }),
                              }}
                            />
                          </FormGroup>
                        </>
                        <FormGroup className="mr-2">
                          <Select
                            isClearable
                            className="dataFiltersSelect"
                            placeholder="Select User Role..."
                            value={UserSpecifications.find(
                              (us) => invite.role === us.value
                            )}
                            options={UserSpecifications}
                            onChange={this.handleUserSpecificationChange(
                              invite
                            )}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (styles) => ({
                                ...styles,
                                zIndex: 2000,
                              }),
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="mr-2">
                          <Form.Check
                            custom
                            type="checkbox"
                            name="isAllData"
                            id={`isAllDataCheckBox-${index}`}
                            label="All data"
                            checked={invite.all_data}
                            onChange={this.handleChangeAllData(invite)}
                          />
                        </FormGroup>
                      </div>
                    )}
                  </div>
                  <div>
                    <div onClick={this.removeInvite(invite)}>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="redIcon pointer"
                        size="2x"
                      />
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </Form>
          <div className="d-flex justify-content-end">
            <button
              className="blueBtnMd"
              form="inviteTeachersForm"
              type="submit"
            >
              Send{" "}
              {isLoading.sendInviteToTeachers && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth, onboarding }: ApplicationState): PropsFromState => {
  return {
    tableData: onboarding.dataSharingTableData,
    invites: onboarding.invites,
    isLoading: {
      sendInviteToTeachers: onboarding.isLoading.sendInviteToTeachers,
    },
    errors: {
      sendInviteToTeachers: onboarding.errors.sendInviteToTeachers,
    },
    teachers: onboarding.teachersRoster,
    schools: onboarding.schools,
    showModal: onboarding.modalsState.inviteTeachersModal,
    districtUserName: auth.userInfo?.profile.district?.owner.first_name + " " + auth.userInfo?.profile.district?.owner.last_name,
    showFailedInvitationsAlert: onboarding.modalsState.showFailedInvitationsAlert,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideInviteTeachersModal: hideInviteTeachersModal,
      sendInviteToTeachers: sendInviteToTeachers,
      hideFailedInvitationsAlert: hideFailedInvitationsAlert,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteTeachersModal);
