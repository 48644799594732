import React from "react";
import { Image } from "react-bootstrap";
import { UserAssignmentInfo, UserInfo } from "../../store/auth/types";

type Props = {
  userInfo?: UserAssignmentInfo | UserInfo;
  size?: number;
  className?: string;
};

const UserAvatarSmall = ({ userInfo, size = 30, className }: Props) => {
  return (
    <Image
      style={{
        objectFit: "cover",
        objectPosition: "top center",
        border: "1px solid #616161",
      }}
      src={userInfo && userInfo.profile.profile_image_url}
      width={size}
      height={size}
      className={className}
      roundedCircle
    />
  );
};

export default UserAvatarSmall;
