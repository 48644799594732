import React from "react";
import UserAvatarSmall from "../../../../../UserAvatarSmall";
import { Modal } from "react-bootstrap";
import { UserInfo } from "../../../../../../../store/auth/types";

type Props = {
  selectedTeacher: UserInfo;
};

const InterviewModalTitle = ({ selectedTeacher }: Props) => {
  return (
    <>
      <div className="userInfoModalTitle">
        <UserAvatarSmall userInfo={selectedTeacher} />
        {selectedTeacher.first_name} {selectedTeacher.last_name}
      </div>
      <Modal.Title>
        Interview with {selectedTeacher.first_name} {selectedTeacher.last_name}
      </Modal.Title>
    </>
  );
};

export default InterviewModalTitle;
