import React, { FunctionComponent, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  createIntervention,
  hideCreateNewInterventionModal,
  updateIntervention,
} from "../../../../../../../store/onboarding/cases/actions";
import { connect, useDispatch } from "react-redux";
import {
  ChecklistItem,
  ConcernArea,
  ConcernAreaDisplayedNames,
  ConcernType,
  ExpectedChange,
  Intervention,
  InterventionDuration,
  InterventionDurationDisplayedNames,
  InterventionRequest,
  InterventionTarget,
} from "../../../../../../../store/onboarding/cases/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ModalCloseButton from "../../../../../../common/onboarding/third-step/group-students/common/ModalCloseButton";
import InterventionSteps from "./intervention-steps-form/InterventionSteps";
import InterventionStepDetails from "./intervention-steps-form/InterventionStepDetails";
import SetDurationForm from "./set-duration/SetDurationForm";
import SelectBehaviorForm from "./select-behavior/SelectBehaviorForm";
import InterventionContext from "./intervention-context/InterventionContext";
import QuestionsForm from "./questions/QuestionsForm";
import InterventionReview from "./intervention-review/InterventionReview";
import { toastr } from "react-redux-toastr";
import InterventionStrategyModalBody from "./intervention-strategy/InterventionStrategyModalBody";
import ModifyInterventionInfo from "./modify-intervention/ModifyInterventionInfo";
import ReadingSkillsForm from "./reading-skills/ReadingSkillsForm";
import InterventionShared from "./intervention-shared/InterventionShared";

type StateProps = {
  createNewInterventionModalConfig?: {
    onBack?: Function;
    editing?: boolean;
  };
  selectedIntervention?: Intervention;
  interventionGroupId: number | undefined;
  showModal: boolean;
  districtId: number;
};
type DispatchProps = {
  createIntervention: (interventionRequest: InterventionRequest) => any;
  hideCreateNewInterventionModal: () => any;
};
type OwnProps = {};

type Props = OwnProps & StateProps & DispatchProps;

enum NewInterventionSteps {
  SHARE_BY_DISTRICT,
  INPUT_TYPE_AND_NAME,
  SELECT_BEHAVIOR,
  SET_DURATION,
  INTERVENTION_STEPS,
  STEP_DETAILS,
  WHAT_WHY_CONTEXT,
  QUESTIONS,
  REVIEW,
  MODIFY_INTERVENTION_INFO,
  READING_SKILLS,
}

const NewInterventionModal: FunctionComponent<Props> = ({
  showModal,
  selectedIntervention,
  createIntervention,
  hideCreateNewInterventionModal,
  createNewInterventionModalConfig,
  interventionGroupId,
  districtId
}) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);

  const [interventionName, setInterventionName] = useState("");
  const [interventionDescription, setInterventionDescription] = useState("");
  const [interventionPurpose, setInterventionPurpose] = useState("");
  const [interventionConsiderations, setInterventionConsiderations] = useState(
    ""
  );
  const [concernArea, setConcernArea] = useState<ConcernArea>(
    ConcernArea.BEHAVIOR
  );
  const [expectedChange, setExpectedChange] = useState<
    ExpectedChange | undefined
  >(ExpectedChange.INCREASE);
  const [duration, setDuration] = useState<InterventionDuration>(
    InterventionDuration.SHORT_TERM
  );
  const [interventionTarget, setInterventionTarget] = useState<
    InterventionTarget | undefined
  >(undefined);

  const [interventionConcernType, setInterventionConcernType] = useState<
    ConcernType | undefined
  >(undefined);

  const [checkListItems, setCheckListItems] = useState<Array<ChecklistItem>>(
    []
  );
  const [selectedCheckListItem, setSelectedCheckListItem] = useState<
    (Partial<ChecklistItem> & { index?: number }) | undefined
  >(undefined);
  const [readingSkillTypes, setReadingSkillTypes] = useState<string[]>([]);
  const [readingSkills, setReadingSkills] = useState<string[]>([]);

  const [shareByDistrict, setShareByDistrict] = useState<boolean>(false);

  const handleModalOpen = () => {
    if (selectedIntervention) {
      if (createNewInterventionModalConfig?.editing) {
        setStep(NewInterventionSteps.SHARE_BY_DISTRICT);
        //setStep(NewInterventionSteps.INPUT_TYPE_AND_NAME);
        setInterventionName(selectedIntervention.name);
        setInterventionDescription(selectedIntervention.description);
        setInterventionPurpose(selectedIntervention.purpose);
        setInterventionConsiderations(selectedIntervention.considerations);
        setShareByDistrict(!!selectedIntervention.district)
      } else {
        setStep(NewInterventionSteps.MODIFY_INTERVENTION_INFO);
        setInterventionName("");
        setInterventionDescription("");
        setInterventionPurpose("");
        setInterventionConsiderations("");
        setShareByDistrict(false)
      }

      setConcernArea(selectedIntervention.concern_area);
      setInterventionTarget(selectedIntervention.target);
      setInterventionConcernType(selectedIntervention.concern_type);
      setDuration(InterventionDuration.SHORT_TERM); //todo
      setExpectedChange(selectedIntervention.expected_change);
      setCheckListItems(selectedIntervention.checklist_items);
      setReadingSkillTypes(selectedIntervention.skill_areas ?? []);
      setReadingSkills(selectedIntervention.focuses ?? []);
    } else {
      setStep(NewInterventionSteps.SHARE_BY_DISTRICT);
      // setStep(NewInterventionSteps.INPUT_TYPE_AND_NAME);
      setConcernArea(ConcernArea.BEHAVIOR);
      setInterventionTarget(undefined);
      setInterventionConcernType(undefined);
      setDuration(InterventionDuration.SHORT_TERM);
      setExpectedChange(ExpectedChange.INCREASE);
      setCheckListItems([]);
      setReadingSkillTypes([]);
      setReadingSkills([]);
      setInterventionName("");
      setInterventionDescription("");
      setInterventionPurpose("");
      setInterventionConsiderations("");
      setShareByDistrict(false)
    }

    setSelectedCheckListItem(undefined);
  };

  const handleModalClose = () => {
    createNewInterventionModalConfig?.onBack &&
      createNewInterventionModalConfig.onBack();
    hideCreateNewInterventionModal();
  };

  const handleBackClick = () => {
    switch (step) {
      case NewInterventionSteps.MODIFY_INTERVENTION_INFO: {
        handleModalClose();
        break;
      }
      case NewInterventionSteps.SHARE_BY_DISTRICT: {
        selectedIntervention && !createNewInterventionModalConfig?.editing
          ? setStep(NewInterventionSteps.MODIFY_INTERVENTION_INFO)
          : handleModalClose();
        break;
      }
      case NewInterventionSteps.INPUT_TYPE_AND_NAME: {
        setStep(
          selectedIntervention
            ? NewInterventionSteps.SHARE_BY_DISTRICT
            : step - 1
        );
        break;
      }
      case NewInterventionSteps.QUESTIONS: {
        setStep(
          selectedIntervention
            ? NewInterventionSteps.SHARE_BY_DISTRICT
            : step - 1
        );
        break;
      }
      case NewInterventionSteps.INTERVENTION_STEPS: {
        setStep(
          selectedIntervention
            ? NewInterventionSteps.SHARE_BY_DISTRICT
            : step - 1
        );
        break;
      }
      case NewInterventionSteps.SET_DURATION: {
        setStep(
          concernArea === ConcernArea.ACADEMIC
            ? NewInterventionSteps.SHARE_BY_DISTRICT
            : step - 1
        );
        break;
      }

      case NewInterventionSteps.READING_SKILLS: {
        setStep(NewInterventionSteps.SET_DURATION);
        break;
      }

      default:
        setStep(step - 1);
    }
  };

  const getColumn = () => {
    switch (step) {
      case NewInterventionSteps.SHARE_BY_DISTRICT:
        return (
          <InterventionShared 
            shareByDistrict={shareByDistrict} 
            setShareByDistrict={setShareByDistrict}
            onClickNext={() => setStep(NewInterventionSteps.SELECT_BEHAVIOR)}
          />
        );
      case NewInterventionSteps.SELECT_BEHAVIOR:
        return (
          <SelectBehaviorForm
            concernArea={concernArea}
            expectedChange={expectedChange}
            setExpectedChange={setExpectedChange}
            onClickNext={() => setStep(NewInterventionSteps.SET_DURATION)}
          />
        );
      case NewInterventionSteps.SET_DURATION:
        return (
          <SetDurationForm
            duration={duration}
            setDuration={setDuration}
            onClickNext={() =>
              setStep(
                concernArea === ConcernArea.BEHAVIOR
                  ? NewInterventionSteps.INTERVENTION_STEPS
                  : NewInterventionSteps.READING_SKILLS
              )
            }
          />
        );
      case NewInterventionSteps.INTERVENTION_STEPS: {
        return (
          <InterventionSteps
            interventionName={interventionName}
            checkListItems={checkListItems}
            setCheckListItems={setCheckListItems}
            setSelectedCheckListItem={setSelectedCheckListItem}
            onOpenEditStepForm={() =>
              setStep(NewInterventionSteps.STEP_DETAILS)
            }
            onClickNext={() => setStep(NewInterventionSteps.WHAT_WHY_CONTEXT)}
          />
        );
      }
      case NewInterventionSteps.STEP_DETAILS: {
        return (
          <InterventionStepDetails
            interventionName={interventionName}
            onOpenHideStepForm={() =>
              setStep(NewInterventionSteps.INTERVENTION_STEPS)
            }
            selectedCheckListItem={selectedCheckListItem}
            setCheckListItems={setCheckListItems}
            setSelectedCheckListItem={setSelectedCheckListItem}
          />
        );
      }
      case NewInterventionSteps.WHAT_WHY_CONTEXT:
        return (
          <InterventionContext
            interventionConsiderations={interventionConsiderations}
            interventionName={interventionName}
            interventionPurpose={interventionPurpose}
            onClickNext={() => setStep(NewInterventionSteps.QUESTIONS)}
            setInterventionConsiderations={setInterventionConsiderations}
            setInterventionPurpose={setInterventionPurpose}
          />
        );
      case NewInterventionSteps.QUESTIONS: {
        return (
          <QuestionsForm
            interventionTarget={interventionTarget}
            setInterventionTarget={setInterventionTarget}
            interventionConcernType={interventionConcernType}
            setInterventionConcernType={setInterventionConcernType}
            onClickNext={() => setStep(NewInterventionSteps.REVIEW)}
          />
        );
      }
      case NewInterventionSteps.REVIEW: {
        const handleInterventionSave = () => {
          const newIntervention: InterventionRequest = {
            name: interventionName,
            description: interventionDescription,
            concern_area: concernArea!,
            expected_change: expectedChange,
            purpose: interventionPurpose,
            considerations: interventionConsiderations,
            target: interventionTarget,
            concern_type: interventionConcernType,
            checklist_items: checkListItems
              .sort((a: ChecklistItem, b: ChecklistItem) => a.order - b.order)
              .map((ci, index) => ({
                title: ci.title,
                details: ci.details,
                order: index + 1, // value optimization
                common_step_name: ci.common_step_name,
                attachments: ci.attachments.map((a) => a.id!),
              })),
            skill_areas: readingSkillTypes,
            focuses: readingSkills,
            source_group_id:interventionGroupId
          };

          if (createNewInterventionModalConfig?.editing) {
            dispatch<any>(
              updateIntervention({
                ...newIntervention,
                id: selectedIntervention?.id
              })
            ).then(
              () => handleModalClose(),
              (err: string) => toastr.error("Error:", err)
            );
          } else {
            createIntervention({
              ...newIntervention,
              source_intervention_id: selectedIntervention?.id,
              ...(shareByDistrict ? {district: districtId} : '')
            }).then(
              () => handleModalClose(),
              (err: string) =>
                toastr.error("Assessment creation has failed", err)
            );
          }
        };
        return (
          <InterventionReview
            editing={createNewInterventionModalConfig?.editing}
            selectedIntervention={selectedIntervention}
            interventionName={interventionName}
            onSave={handleInterventionSave}
          />
        );
      }

      case NewInterventionSteps.READING_SKILLS: {
        return (
          <ReadingSkillsForm
            readingSkillTypes={readingSkillTypes}
            setReadingSkillTypes={setReadingSkillTypes}
            readingSkills={readingSkills}
            setReadingSkills={setReadingSkills}
            onClickNext={() => {
              setStep(NewInterventionSteps.INTERVENTION_STEPS);
            }}
          />
        );
      }
    }
  };

  const getModalBodyByStep = () => {
    switch (step) {
      case NewInterventionSteps.SHARE_BY_DISTRICT:
        return (
          <InterventionShared 
            shareByDistrict={shareByDistrict} 
            setShareByDistrict={setShareByDistrict}
            onClickNext={() => setStep(NewInterventionSteps.INPUT_TYPE_AND_NAME)}
          />
        );
      case NewInterventionSteps.INPUT_TYPE_AND_NAME:
        return (
          <InterventionStrategyModalBody
            editing={createNewInterventionModalConfig?.editing}
            selectedIntervention={selectedIntervention}
            interventionName={interventionName}
            interventionDescription={interventionDescription}
            concernArea={concernArea}
            setConcernArea={(ca) => {
              setConcernArea(ca);
              if (ca === ConcernArea.ACADEMIC) {
                setExpectedChange(undefined);
              }
            }}
            setInterventionName={setInterventionName}
            setInterventionDescription={setInterventionDescription}
            onClickNext={() =>
              setStep(
                selectedIntervention &&
                  !createNewInterventionModalConfig?.editing
                  ? NewInterventionSteps.INTERVENTION_STEPS
                  : concernArea === ConcernArea.ACADEMIC
                    ? NewInterventionSteps.SET_DURATION
                    : NewInterventionSteps.SELECT_BEHAVIOR
              )
            }
          />
        );
      case NewInterventionSteps.MODIFY_INTERVENTION_INFO:
        return (
          <ModifyInterventionInfo
            selectedIntervention={selectedIntervention}
            onClickNext={() =>
              setStep(NewInterventionSteps.SHARE_BY_DISTRICT)
            }
          />
        );
      default:
        return (
          <Modal.Body className="p-0">
            <Row className="m-0">
              <Col sm={3} className="p-0">
                <div className="interventionModalSidebar">
                  <h4
                    className="m-0 font-weight-bold pointer"
                    onClick={() =>
                      !selectedIntervention &&
                      setStep(NewInterventionSteps.SHARE_BY_DISTRICT)
                    }
                  >
                    SHARE BY DISTRICT
                  </h4>
                  <p>{shareByDistrict ? 'Yes' : 'No'}</p>
                  <h4
                    className="m-0 font-weight-bold pointer"
                    onClick={() =>
                      !selectedIntervention &&
                      setStep(NewInterventionSteps.INPUT_TYPE_AND_NAME)
                    }
                  >
                    AREA OF CONCERN
                  </h4>
                  <p>{ConcernAreaDisplayedNames[concernArea!]}</p>
                  <h4
                    className="m-0 font-weight-bold pointer"
                    onClick={() =>
                      setStep(NewInterventionSteps.INPUT_TYPE_AND_NAME)
                    }
                  >
                    NAME
                  </h4>
                  <p>{interventionName}</p>
                  {
                    <>
                      <h4
                        className="m-0 font-weight-bold pointer"
                        onClick={() =>
                          !selectedIntervention &&
                          setStep(NewInterventionSteps.SET_DURATION)
                        }
                      >
                        DURATION
                      </h4>
                      <p>{InterventionDurationDisplayedNames[duration]}</p>
                    </>
                  }
                  {expectedChange !== undefined && (
                    <>
                      <h4
                        className="m-0 font-weight-bold pointer"
                        onClick={() =>
                          !selectedIntervention &&
                          setStep(NewInterventionSteps.SELECT_BEHAVIOR)
                        }
                      >
                        EXPECTED CHANGE
                      </h4>
                      <p>
                        {expectedChange === ExpectedChange.INCREASE
                          ? "Increase Positive Behavior"
                          : "Decrease Problematic Behavior"}
                      </p>
                    </>
                  )}
                  {!!checkListItems.length && (
                    <>
                      <h4
                        className="m-0 font-weight-bold pointer"
                        onClick={() =>
                          !selectedIntervention &&
                          setStep(NewInterventionSteps.INTERVENTION_STEPS)
                        }
                      >
                        NUMBER OF STEPS
                      </h4>
                      <p>{checkListItems.length}</p>
                    </>
                  )}
                  {!!checkListItems.length &&
                    step > NewInterventionSteps.WHAT_WHY_CONTEXT && (
                      <>
                        <h4
                          className="m-0 font-weight-bold pointer"
                          onClick={() =>
                            setStep(NewInterventionSteps.WHAT_WHY_CONTEXT)
                          }
                        >
                          WHAT, WHY &amp; CONTEXT
                        </h4>
                        <a
                          onClick={() =>
                            setStep(NewInterventionSteps.WHAT_WHY_CONTEXT)
                          }
                        >
                          Edit
                        </a>
                      </>
                    )}
                </div>
              </Col>
              <Col sm={9} className="p-0">
                {getColumn()}
              </Col>
            </Row>
          </Modal.Body>
        );
    
      }
  };

  return (
    <Modal
      show={showModal}
      animation={false}
      size="lg"
      backdropClassName="customDarkModalBackdrop in"
      onShow={handleModalOpen}
      onHide={handleModalClose}
    >
      <Modal.Header className="purpleModalHeader centerModalHeader">
        <div>
          <button className={"btnModalBack"} onClick={handleBackClick}>
            <FontAwesomeIcon icon={faChevronLeft} size={"lg"} />
            Back
          </button>
        </div>
        <Modal.Title>
          {selectedIntervention
            ? createNewInterventionModalConfig?.editing
              ? "Edit Intervention"
              : "Modify Existing Intervention"
            : "Create a New Intervention"}
        </Modal.Title>
        <ModalCloseButton onClose={handleModalClose} />
      </Modal.Header>
      {getModalBodyByStep()}
    </Modal>
  );
};

const mapStateToProps = ({ cases, auth }: ApplicationState): StateProps => {
  return {
    selectedIntervention: cases.selectedIntervention,
    createNewInterventionModalConfig:
      cases.modalsState.createNewInterventionModalConfig,
    interventionGroupId: cases.modalsState.interventionGroupId,
    showModal: cases.modalsState.createNewInterventionModal,
    districtId: auth.userInfo?.profile.district?.id!
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideCreateNewInterventionModal: hideCreateNewInterventionModal,
      createIntervention: createIntervention,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewInterventionModal);
