import React, {
  FunctionComponent,
  RefObject,
  useEffect,
  useState,
} from "react";
import { Collapse } from "react-bootstrap";
import { TargetBehaviorGroup } from "../../select-target/utils";
import { CategoryBehavior } from "../../../../../../../store/onboarding/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { bindActionCreators, Dispatch } from "redux";
import { changeCustomBehaviorName } from "../../../../../../../store/onboarding/actions";
import { connect } from "react-redux";

type OwnProps = {
  isCustom?: boolean;
  selectedBehaviors?: Array<CategoryBehavior>;
  domainGroup: TargetBehaviorGroup;
  onTargetBehaviorSelect: (target: CategoryBehavior) => any;
  refs?: { [key: number]: RefObject<any> };
  highlightedBehaviorsIds?: number[];
};

type DispatchProps = {
  changeCustomBehaviorName: (name?: string) => any;
};

type Props = OwnProps & DispatchProps;

const BehaviorDomainGroup: FunctionComponent<Props> = ({
  isCustom,
  selectedBehaviors,
  domainGroup,
  refs,
  onTargetBehaviorSelect,
  changeCustomBehaviorName,
  highlightedBehaviorsIds,
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const handleCollapse = () => {
    setCollapsed((prevState) => !prevState);
  };

  const selectedBehaviorsIds = selectedBehaviors
    ? selectedBehaviors.map((b) => b.id!)
    : [];

  const handleCreateCopy = (targetName: string) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    changeCustomBehaviorName(targetName);
  };

  useEffect(() => {
    setCollapsed(
      domainGroup.behaviors.some((b) => selectedBehaviorsIds.includes(b.id!))
    );
  }, [selectedBehaviors]);

  return (
    <>
      {!isCustom && (
        <div
          className="domainHeader"
          aria-controls="target-behaviors"
          aria-expanded={collapsed}
          onClick={handleCollapse}
        >
          <FontAwesomeIcon
            icon={collapsed ? faCaretDown : faCaretRight}
            size="1x"
          />
          {domainGroup.domain.name}
        </div>
      )}
      <Collapse in={collapsed || isCustom}>
        <div id="target-behaviors" className="behaviorsList">
          {domainGroup.behaviors.map((target) => {
            const className = `${
              selectedBehaviorsIds?.includes(target.id!) ? "active" : ""
            } ${
              highlightedBehaviorsIds?.includes(target.id!) ? "highlighted" : ""
            }`;

            return (
              <div
                key={target.id}
                ref={refs ? refs[target.id!] : undefined}
                className={className}
                onClick={() => onTargetBehaviorSelect(target)}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  className="mx-2 pointer"
                  onClick={handleCreateCopy(target.name)}
                />

                {target.name}
              </div>
            );
          })}
        </div>
      </Collapse>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      changeCustomBehaviorName: changeCustomBehaviorName,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(BehaviorDomainGroup);
