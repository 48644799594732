import React, { FunctionComponent } from "react";

type OwnProps = {
  interventionName: string;
  interventionPurpose: string;
  interventionConsiderations: string;
  onClickNext: () => void;
  setInterventionPurpose: React.Dispatch<React.SetStateAction<string>>;
  setInterventionConsiderations: React.Dispatch<React.SetStateAction<string>>;
};

type Props = OwnProps;

const InterventionContext: FunctionComponent<Props> = ({
  interventionName,
  interventionPurpose,
  interventionConsiderations,
  setInterventionPurpose,
  setInterventionConsiderations,
  onClickNext,
}) => {
  const handleInterventionPurposeChange = (event: React.ChangeEvent<any>) => {
    const { value } = event.target as HTMLInputElement;
    setInterventionPurpose(value);
  };

  const handleInterventionConsiderationChange = (
    event: React.ChangeEvent<any>
  ) => {
    const { value } = event.target as HTMLInputElement;
    setInterventionConsiderations(value);
  };

  return (
    <div className="interventionModalContent">
      <h3 className="font-weight-bold my-3">
        What is the purpose of {interventionName}?
      </h3>
      <textarea
        className="fullWidthTextarea"
        placeholder="Step details..."
        value={interventionPurpose}
        onChange={handleInterventionPurposeChange}
      />
      <h3 className="font-weight-bold my-3">
        Describe any additional considerations.
      </h3>
      <textarea
        className="fullWidthTextarea"
        placeholder="Considerations..."
        value={interventionConsiderations}
        onChange={handleInterventionConsiderationChange}
      />
      <hr />
      <div className="modalActions">
        <div />
        <button className="blueBtnSm" onClick={onClickNext}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default InterventionContext;
