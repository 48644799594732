import React from "react";
import { Modal } from "react-bootstrap";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  acceptConfirmDiagnosisModal,
  hideConfirmDiagnosisModal,
} from "../../../../../../store/groups/actions";
import { StudentEntry } from "../../../../../../store/onboarding/types";
import { IS_READY_COACH } from "../../../../../../constants";

type PropsFromState = {
  showModal: boolean;
  selectedStudentEntry?: StudentEntry;
};

type DispatchProps = {
  hideConfirmDiagnosisModal: () => any;
  acceptConfirmDiagnosisModal: () => any;
};

type Props = PropsFromState & DispatchProps;

class ConfirmDiagnosisModal extends React.Component<Props> {
  render() {
    const { showModal } = this.props;
    return (
      <Modal
        show={showModal}
        onHide={this.props.hideConfirmDiagnosisModal}
        animation={false}
        backdropClassName="customDarkModalBackdrop in"
        size="sm"
      >
        <Modal.Header
          closeButton
          className={`${
            IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"
          } font-weight-bold`}
        >
          Confirm
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mb-3">
            <h4>
              This student has not been identified as needing intervention. Are
              you sure you want to add diagnostic data?
            </h4>
          </div>
          <div className="text-center">
            <button
              className="blueBtnSm mr-3"
              onClick={this.props.acceptConfirmDiagnosisModal}
            >
              Yes
            </button>
            <button
              className="blueBtnSm"
              onClick={this.props.hideConfirmDiagnosisModal}
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({ groups }: ApplicationState): PropsFromState => {
  return {
    showModal: groups.modalsState.confirmDiagnosisModal,
    selectedStudentEntry: groups.selectedStudentEntry,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideConfirmDiagnosisModal: hideConfirmDiagnosisModal,
      acceptConfirmDiagnosisModal: acceptConfirmDiagnosisModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmDiagnosisModal);
