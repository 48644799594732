import React, { FunctionComponent, useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import {
  addExistingBehaviorEcgToDataPeriod,
  getDataPeriods,
  getEvidenceInDataPeriod,
  openConductInterviewModal,
  openPickDataSourceModal,
  patchDataPeriod,
} from "../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../../store";
import {
  DataPeriod,
  EvidencePeriodData,
} from "../../../../../store/onboarding/types";
import TeacherInviteIndividuallyModal from "../TeacherInviteIndividuallyModal";
import usePrevProps from "../../../../../utils/hooks/usePrevProps";
import StartWorkingContainer from "../../third-step/start-working/StartWorkingContainer";
import useUserRole from "../../../../../utils/hooks/useUserRole";

type PropsFromState = {
  evidencePeriodData?: EvidencePeriodData;
  isLoading: {
    addExistingBehaviorEcgToDataPeriod: boolean;
    uploadEvidenceData: boolean;
    addAssessmentToDataPeriod: boolean;
    uploadAssessmentData: boolean;
    uploadUndefinedAssessmentData: boolean;
  };
  hasEmptyData: boolean;
};

type DispatchProps = {
  patchDataPeriod: (
    dataPeriodId: number,
    dataPeriod: Partial<DataPeriod>
  ) => any;
  getEvidenceInDataPeriod: (dataPeriodId?: number) => any;
  getDataPeriods: () => any;
  openPickDataSourceModal: () => any;
  addExistingBehaviorEcgToDataPeriod: (dataPeriodId?: number) => any;
  openConductInterviewModal: typeof openConductInterviewModal;
};

type Props = PropsFromState & DispatchProps;

const FormsEvidence: FunctionComponent<Props> = (props: Props) => {
  const { isDistrictOwner } = useUserRole();
  const prevProps = usePrevProps({
    uploadEvidenceData: props.isLoading.uploadEvidenceData,
    addAssessmentToDataPeriod: props.isLoading.addAssessmentToDataPeriod,
    uploadAssessmentData: props.isLoading.uploadAssessmentData,
    uploadUndefinedAssessmentData:
      props.isLoading.uploadUndefinedAssessmentData,
  });

  useEffect(() => {
    if (
      (prevProps?.uploadEvidenceData && !props.isLoading.uploadEvidenceData) ||
      (prevProps?.uploadUndefinedAssessmentData &&
        !props.isLoading.uploadUndefinedAssessmentData) ||
      (prevProps?.addAssessmentToDataPeriod &&
        !props.isLoading.addAssessmentToDataPeriod)
    ) {
      props.getDataPeriods().then((dataPeriods: Array<DataPeriod>) => {
        if (dataPeriods.length && !dataPeriods.some((dp) => dp.is_selected)) {
          props.patchDataPeriod(dataPeriods[0].id, { is_selected: true, all_data_period_ids: dataPeriods.map((dp) => dp?.id) || [] });
          // .then(() => props.getEvidenceInDataPeriod(dataPeriods[0].id));
        }
      });
    }
    if((prevProps?.uploadAssessmentData &&
      !props.isLoading.uploadAssessmentData)
      && !isDistrictOwner) { // RC-1247 stop the calls
        props.getDataPeriods().then((dataPeriods: Array<DataPeriod>) => {
          if (dataPeriods.length && !dataPeriods.some((dp) => dp.is_selected)) {
            props.patchDataPeriod(dataPeriods[0].id, { is_selected: true, all_data_period_ids: dataPeriods.map((dp) => dp?.id) || [] });
            // .then(() => props.getEvidenceInDataPeriod(dataPeriods[0].id));
          }
        });
      }
  }, [prevProps, props.isLoading]);

  return props.hasEmptyData ? (
    <div className="dataTooltipContainer">
      <StartWorkingContainer />
      <TeacherInviteIndividuallyModal />
    </div>
  ) : null;
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    isLoading: {
      addExistingBehaviorEcgToDataPeriod:
        onboarding.isLoading.addExistingBehaviorEcgToDataPeriod,
      uploadEvidenceData: onboarding.isLoading.uploadEvidenceData,
      addAssessmentToDataPeriod: onboarding.isLoading.addAssessmentToDataPeriod,
      uploadAssessmentData: onboarding.isLoading.uploadAssessmentData,
      uploadUndefinedAssessmentData:
        onboarding.isLoading.uploadUndefinedAssessmentData,
    },
    evidencePeriodData: onboarding.evidencePeriodData,
    hasEmptyData: !(
      onboarding.evidencePeriodData &&
      onboarding.evidencePeriodData.evidence_column_groups.length > 0
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getEvidenceInDataPeriod: getEvidenceInDataPeriod,
      patchDataPeriod: patchDataPeriod,
      getDataPeriods: getDataPeriods,
      openPickDataSourceModal: openPickDataSourceModal,
      addExistingBehaviorEcgToDataPeriod: addExistingBehaviorEcgToDataPeriod,
      openConductInterviewModal: openConductInterviewModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FormsEvidence);
