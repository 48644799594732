import Table from "react-bootstrap/Table";
import { Card } from "react-bootstrap";
import DataFileReader from "../../../../../containers/onboarding/FileReader";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UploadStudentsRosterManual = () => {
  return (
    <>
      <div className="m-4">
        <p className="text-center">
          To upload students from a roster, it needs to be in a{" "}
          <strong>CSV format</strong>, and include a header row. It must include
          at least three columns; one for First Name, Last Name, and Student ID.
        </p>
        <div>
          <h4>
            <strong>Example format</strong>
          </h4>
          <Table className="uploadExampleTable">
            <thead>
              <tr>
                <th>student_id</th>
                <th>last_name</th>
                <th>first_name</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1001</td>
                <td>Appleseed</td>
                <td>John</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <Card>
          <Card.Header>
            <FontAwesomeIcon icon="info-circle" /> What is a CSV file?{" "}
          </Card.Header>
          <Card.Body>
            <Card.Text>
              A CSV file is a structured data file that organizes information
              with rows, and columns separated by a comma. Opening a CSV file in
              Excel would display the data in a format like shown above.
            </Card.Text>
          </Card.Body>
        </Card>
      </div>

      <div className="text-center">
        <label className="blueBtnLg">
          Upload roster csv file
          <DataFileReader />
        </label>
      </div>
    </>
  );
};

export default UploadStudentsRosterManual;
