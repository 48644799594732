import React, { Component } from "react";

type OwnProps = {
  studentsNum: number;
  svgSize: number;
};

type Props = OwnProps;

class GroupCountIcon extends Component<Props> {
  createNodes = (
    numNodes: number,
    radius: number = this.props.svgSize / 2.67
  ): Array<any> => {
    const nodes = [];
    for (let i = 0, angle, x, y; i < numNodes; i++) {
      angle = (i / (numNodes / 2) + 0.33) * Math.PI;
      x = radius * Math.cos(angle) + this.props.svgSize / 2;
      y = radius * Math.sin(angle) + this.props.svgSize / 2;
      nodes.push({ id: i, x: x, y: y });
    }
    return nodes;
  };

  render() {
    const { svgSize, studentsNum } = this.props;
    return (
      <svg width={svgSize} height={svgSize} className="groupCountSvg">
        <g>
          <circle cx={svgSize / 2} cy={svgSize / 2} r={svgSize / 4} />
          <text
            x={svgSize / 2}
            y={svgSize / 2}
            // fontSize="120"
            fill="white"
            textAnchor="middle"
            dominantBaseline="central"
          >
            {studentsNum}
          </text>
        </g>

        {this.createNodes(studentsNum).map((x) => (
          <circle key={x.id} cx={x.x} cy={x.y} r={svgSize / 12} />
        ))}
      </svg>
    );
  }
}

export default GroupCountIcon;
