import React from "react";
import { Modal } from "react-bootstrap";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { hideConfirmSecondaryGoalCreateModal } from "../../../../../../store/onboarding/actions";
import { IS_READY_COACH } from "../../../../../../constants";
import { activeTabUrl, showInterventionPlanModal } from "../../../../../../store/onboarding/cases/actions";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { markAddSecondaryGoalAsShown } from "../../../../../../store/tour/actions";

type PropsFromState = {
  showModal: boolean;
  finishedInterventionGroup?: InterventionGroup;
};

type State = { 
  isDeclineToAddSecondaryGoal: boolean;
};

type DispatchProps = {
  hideConfirmSecondaryGoalCreateModal: () => any;
  showInterventionPlanModal: (finishedInterventionGroup: InterventionGroup) => any;
  markAddSecondaryGoalAsShown: (isShown?: boolean) => any;
};

type Props = PropsFromState & DispatchProps;


class ConfirmSecondaryGoalCreateModal extends React.Component<Props, State> {
  constructor(props:Props) {
    super(props);
  }

  state: State = {
    isDeclineToAddSecondaryGoal: false
  }
  
  handleYes = () => {

    this.props.showInterventionPlanModal(this.props.finishedInterventionGroup!);
    //this.props.markAddSecondaryGoalAsShown(true);
    setTimeout(() => {
      document.getElementById('group-workspace-tabs-tab-manage')?.click()
    }, 100);
    setTimeout(() => {
      this.props.markAddSecondaryGoalAsShown(true)
    }, 150);
    this.props.hideConfirmSecondaryGoalCreateModal();
  };

  render() {
    const { showModal } = this.props;
    return (
      <Modal
        show={showModal}
        onHide={this.props.hideConfirmSecondaryGoalCreateModal}
        animation={false}
        backdropClassName="customDarkModalBackdrop in"
        size="sm"
      >
        <Modal.Header
          closeButton
          className={`${
            IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"
          } font-weight-bold`}
        >
          Confirm
        </Modal.Header>
        <Modal.Body>
          {this.state.isDeclineToAddSecondaryGoal ? 
          <>
            <div className="text-center mb-3">
              <h4>
              If you want to add secondary goals in the future, click on the manage tab in the group workspace followed by the + icon under Action.
              </h4>
            </div>
            <div className="d-flex">
            <button className="blueBtnSm w-100" onClick={() => this.setState({
                isDeclineToAddSecondaryGoal: false
              })}>
                Back
              </button>
              <button className="blueBtnSm w-100 ml-1" onClick={() => this.setState({
                isDeclineToAddSecondaryGoal: false
              }, () => this.props.hideConfirmSecondaryGoalCreateModal())}>
                Ok
              </button>
            </div>
          </>
          : 
          <>
            <div className="text-center mb-3">
              <h4>
              Would you like to add one or more secondary goals at this time? 
              </h4>
            </div>
            <div className="d-flex">
              <button
                className="blueBtnSm w-100 mr-3"
                onClick={() => this.setState({
                  isDeclineToAddSecondaryGoal: true
                })}
              >
                No
              </button>
              <button className="blueBtnSm w-100" onClick={() => this.handleYes()}>
                Yes
              </button>
            </div>
          </>
          }
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    showModal: onboarding.modalsState.confirmSecondaryGoalCreateModal,
    finishedInterventionGroup: onboarding.finishedInterventionGroup,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideConfirmSecondaryGoalCreateModal: hideConfirmSecondaryGoalCreateModal,
      showInterventionPlanModal: showInterventionPlanModal,
      markAddSecondaryGoalAsShown: markAddSecondaryGoalAsShown
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmSecondaryGoalCreateModal);
