import React from "react";
import { Overlay, Popover } from "react-bootstrap";
import { ObservationTypes } from "../../../../../../store/onboarding/cases/types";
import BoxButton from "../../../third-step/set-goal-modal/common/BoxButton";

type Props = {
  objectRef: React.MutableRefObject<any>;
  showDropdown: boolean;
  setShowDropdown: (show: boolean) => void;
  onSubmitPopover: (observationType: ObservationTypes) => void;
};

const ObservationTypePopover = ({
  objectRef,
  showDropdown,
  setShowDropdown,
  onSubmitPopover,
}: Props) => {
  const handleObservationTypeChange = (
    observationType: ObservationTypes
  ) => () => {
    onSubmitPopover(observationType);
  };

  return (
    <Overlay
      placement="auto"
      flip
      rootClose={true}
      onHide={() => setShowDropdown(false)}
      show={showDropdown}
      target={objectRef.current}
    >
      <Popover
        id="popover-observation-type"
        className="w-100 observations-popover"
      >
        <Popover.Title>Which type of Observation?</Popover.Title>
        <Popover.Content>
          <div className="d-flex flex-column">
            <h4 className="mb-3">
              Select how you would like to observe and record target behaviors.
              You can choose frequency/event, interval, duration, or latency
              recording. Each option comes with a specialized observation
              template you can customize.
            </h4>
            <div className="w-100">
              <BoxButton
                className="mb-1"
                isNotRadio
                value={ObservationTypes.FREQUENCY_OR_EVENT}
                title={"Frequency / Event"}
                description={
                  "Record the number of occurrences of the target behavior as a total count (6 occurrences) or rate (e.g., 2 times per minute)."
                }
                selectedValue={null}
                onSelect={handleObservationTypeChange}
              />
              <BoxButton
                className="mb-1"
                isNotRadio
                value={ObservationTypes.INTERVAL}
                title={"Interval"}
                description={
                  "Record the percentage of intervals (e.g., series of 1-minute observations) during which a target behavior occurs."
                }
                selectedValue={null}
                onSelect={handleObservationTypeChange}
              />
              <BoxButton
                className="mb-1"
                isNotRadio
                value={ObservationTypes.DURATION}
                title={"Duration"}
                description={
                  "Record the total amount of time that a student exhibits the target behavior."
                }
                selectedValue={null}
                onSelect={handleObservationTypeChange}
              />
              <BoxButton
                value={ObservationTypes.LATENCY}
                isNotRadio
                title={"Latency"}
                description={
                  "Record the amount of time between when a student is directed and when they exhibit the target behavior."
                }
                selectedValue={null}
                onSelect={handleObservationTypeChange}
              />
            </div>
          </div>
        </Popover.Content>
      </Popover>
    </Overlay>
  );
};

export default ObservationTypePopover;
