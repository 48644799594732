import React from "react";
import { Cell } from "fixed-data-table-2";
import { Student } from "../../../../../../../../../store/onboarding/types";

type Props = {
  student: Student;
};

const MatrixStudentHeader = ({ student }: Props) => (
  <Cell className="second-column-header">
    {student.first_name} {student.last_name}
  </Cell>
);

export default MatrixStudentHeader;
