import {
  InterventionGroup,
  InterventionGroupAssignment,
} from "../store/onboarding/cases/types";
import { UserAssignmentRole, UserInfo } from "../store/auth/types";
import { IS_READY_COACH } from "../constants";

export type InterventionGroupMap = {
  employeeAssignment: InterventionGroupAssignment;
  interventionGroups: Array<InterventionGroup>;
  unfinishedGroupsIds?: number[];
};

export const groupInterventionGroupsByTeacher = (
  interventionGroups: Array<InterventionGroup>,
  userInfo?: UserInfo,
  sorted?: boolean,
  listOrderSwitch?: boolean,
): Array<InterventionGroupMap> => {
  const isCoach =
    IS_READY_COACH &&
    userInfo?.profile.current_assignment?.role === UserAssignmentRole.COACH;
  const isDistrictOwner =
    IS_READY_COACH &&
    userInfo?.profile.current_assignment?.role === UserAssignmentRole.DISTRICT_OWNER;

  if (sorted) {
    return interventionGroups.map((ig) => ({
      employeeAssignment: isDistrictOwner 
                            ? !listOrderSwitch
                                ? ig.teacher_assignment!
                                : ig.coach_assignment!
                            : isCoach
                                ? ig.teacher_assignment!
                                : ig.coach_assignment!,
      interventionGroups: [ig],
    }));
  }

  const equal = isDistrictOwner
                  ? !listOrderSwitch
                      ? (a: InterventionGroupMap, b: InterventionGroup) =>
                          a.employeeAssignment?.id === b.teacher_assignment?.id
                      : (a: InterventionGroupMap, b: InterventionGroup) =>
                          a.employeeAssignment?.id === b.coach_assignment?.id
                  : isCoach
                      ? (a: InterventionGroupMap, b: InterventionGroup) =>
                          a.employeeAssignment?.id === b.teacher_assignment?.id
                      : (a: InterventionGroupMap, b: InterventionGroup) =>
                          a.employeeAssignment?.id === b.coach_assignment?.id;

  return interventionGroups.reduce(
    (pV: Array<InterventionGroupMap>, cV: InterventionGroup) => {
      if (pV.some((x) => equal(x, cV))) {
        return pV.map((x) => {
          if (equal(x, cV)) {
            return {
              ...x,
              interventionGroups: [...x.interventionGroups, cV],
            };
          }
          return x;
        });
      }
      return [
        ...pV,
        {
          employeeAssignment: isDistrictOwner 
                                ? !listOrderSwitch
                                    ? cV.teacher_assignment!
                                    : cV.coach_assignment!
                                : isCoach
                                    ? cV.teacher_assignment!
                                    : cV.coach_assignment!,
          interventionGroups: [cV],
        },
      ];
    },
    []
  );
};
