import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import UploadTeachersRosterManual from "./UploadTeachersRosterManual";
import DataFileReader from "../../../../../containers/onboarding/FileReader";
import ColumnHeaderSelector from "../upload-student-data/ColumnHeaderSelector";
import {
  AddUserToDistrictRequest,
  BasicUserInfo,
  EmailCheckResponse,
  InvitesResponse,
  ReactSelectOption,
  SuccessfulInvite,
} from "../../../../../store/onboarding/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import FailedInvitationsAlert from "./FailedInvitationsAlert";
import Select, { ValueType } from "react-select";
import { TeacherRoles, UserRole } from "../../../../../store/help/types";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  checkEmailExist,
  createUserInDistrict,
  getTeachers,
  hideUploadTeachersRosterModal,
  sendInviteToTeachers,
  updateCsvFileRawData,
} from "../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import { districtRoles } from "../../../../../store/auth/constants";
import { toastr } from "react-redux-toastr";
import { showConfirmDialog } from "../../../../../store/confirm-dialog/actions";

type PropsFromState = {
  originalFileName: string;
  columnsNames: Array<string>;
  rawData: Array<any>;
  isLoading: {
    sendInviteToTeachers: boolean;
    addUserToDistrict: boolean;
  };
  errors?: {
    sendInviteToTeachers?: string;
    addUserToDistrict?: string;
  };
  invites: InvitesResponse;
  show: boolean;
  hasDistrictPermission?: boolean;
  districtUserName?: string;
  showFailedInvitationsAlert: boolean;
};

type DispatchProps = {
  createUserInDistrict: (invites: AddUserToDistrictRequest[]) => any;
  checkEmailExist: (inviteEmails:Array<any>) => any;
  sendInviteToTeachers: (invites: Array<BasicUserInfo>) => any;
  updateCsvFileRawData: (rawData: Array<any>) => any;
  getTeachers: () => any;
  onHide: () => any;
  showConfirmDialog: (confirmObj:any) => any;
};

type Props = PropsFromState & DispatchProps;

type State = {
  numPreviewRows: number;
  selectedEmailColumn: string;
  selectedFirstNameColumn: string;
  selectedLastNameColumn: string;
  selectedRoleColumn?: string;
  selectedColumnNames: Array<string>;
  isConfirm: boolean;
};

class UploadTeachersRosterModal extends Component<Props, State> {
  emptyState: State = {
    numPreviewRows: 4,
    selectedEmailColumn: "",
    selectedFirstNameColumn: "",
    selectedLastNameColumn: "",
    selectedRoleColumn: undefined,
    selectedColumnNames: [],
    isConfirm: false
  };
  

  state: Readonly<State> = this.emptyState;

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (
      prevProps.isLoading.sendInviteToTeachers &&
      !this.props.isLoading.sendInviteToTeachers
    ) {
      const { failed_invites, successful_invites } = this.props.invites;
      if (failed_invites.length > 0) {
        const newRawData = this.props.rawData.filter(
          (rawRow) =>
            !successful_invites.find((successfulInvite: SuccessfulInvite) => {
              return (
                successfulInvite.user.email.trim() ===
                rawRow[this.state.selectedEmailColumn].trim()
              );
            })
        );
        this.props.updateCsvFileRawData(newRawData);
      } else {
        this.props.getTeachers();
        this.props.onHide();
      }
    }
  }

  handleInviteSend = (event: React.FormEvent<any>) => {
    event.preventDefault();
    const { rawData, sendInviteToTeachers, hasDistrictPermission } = this.props;
    const {
      selectedRoleColumn,
      selectedEmailColumn,
      selectedFirstNameColumn,
      selectedLastNameColumn,
      isConfirm,
    } = this.state;
    if(hasDistrictPermission) {

      let roleArray = rawData.reduce((a, row) => {
        let role = row[selectedRoleColumn!];
        if(role) {
           role = row[selectedRoleColumn!].toLowerCase();
          }
          a.push(role);
        return a;
      }, []);
  
      
      let invalidFlag = false;
      for (let i = 0; i < roleArray.length; i++) {
        if(!['coach', 'teacher'].includes(roleArray[i])) {
          invalidFlag = true;
        } 
      }
      
      if(invalidFlag) {
        toastr.error('Alert!!', 'Please select role as Coach or Teacher');
        this.setState({isConfirm: false})
        return;
      }


      const onConfirm = () => {
        this.setState({isConfirm: true});
      }
      if(!isConfirm) {
        this.props.showConfirmDialog({
          onConfirm,
          centered: true,
          size:'',
          title: "Confirm Account Type",
          timerElipseLeft: true,
          countDownTimer: 3,
          text: (
            <>
              The account type (role) you have assigned the user is permanent and cannot be changed. Are you sure you want to continue?
            </>
          ),
          confirmButtonText: "Yes, Assign Account Type",
        });
      }

      if(isConfirm) {
        const invites: Array<BasicUserInfo> = rawData.map((row) => ({
          first_name: row[selectedFirstNameColumn],
          last_name: row[selectedLastNameColumn],
          email: row[selectedEmailColumn],
          role: selectedRoleColumn && row[selectedRoleColumn] ? row[selectedRoleColumn].toLowerCase() : selectedRoleColumn && row[selectedRoleColumn],
        }));

        const inviteEmails = invites.map((invite) => invite.email);
        this.props.checkEmailExist(inviteEmails)
          .then(async (result:EmailCheckResponse) => {
            if(!result.emails_already_exist.length) {
              this.props.createUserInDistrict(invites).then(
                () => {
                  this.setState({isConfirm: false})
                  this.handleModalClose();
                  toastr.success(
                    "Success",
                    `The invitation has been sent successfully.`
                    );
                },
                (err: any) =>  toastr.error("Failed to send the invite", err)
                  //  todo handle failed invites
                );
            } else {
              await this.handleDisplayAllRows();
              toastr.error("Alert!!", 'Below highlighted email id already exists in the system. Please use a different email id to invite')
              invites.map((invite,index) => {
                if(result.emails_already_exist.some((existingEmail:string) => existingEmail == invite.email)){
                  document.getElementById(`roster_email_${index}`)!.style.border="1px solid red";
                }
                if(result.new_emails.some((newEmail:string) => newEmail == invite.email)) {
                  document.getElementById(`roster_email_${index}`)!.style.border="";
                }
              })
    
            } 
          },
          (err: any) =>  toastr.error("Failed to send the invite", err)
        )
      }
    } else {
      const invites: Array<BasicUserInfo> = rawData.map((row) => ({
        first_name: row[selectedFirstNameColumn],
        last_name: row[selectedLastNameColumn],
        email: row[selectedEmailColumn],
      }));
      sendInviteToTeachers(invites).then(
        () => {
        this.setState({isConfirm: false});
        if(!this.props.showFailedInvitationsAlert){
          toastr.success(
            "Success",
            `You have invited new user(s) to ReadyCoach. Your district admin (${this.props.districtUserName}) has been contacted to establish data accessing settings for the invited user to access shared data.`
          );
        }
        },
        (err: string) => toastr.error("Failed to send the invite", err)
      );
    }
  };

  handleChange = () => (event: React.SyntheticEvent) => {
    const { name, value } = event.target as HTMLInputElement;
    this.setState((prevState: State) => {
      const selectedColumnNames = [
        ...prevState.selectedColumnNames.filter(
          (columnName: string) => (prevState as any)[name] !== columnName
        ),
        value,
      ];
      return {
        ...prevState,
        [name]: value,
        selectedColumnNames: selectedColumnNames,
        isConfirm: false
      };
    });
  };

  handleDisplayAllRows = () => {
    this.setState({
      numPreviewRows: this.props.rawData.length,
    });
  };

  handleOnEnter = () => {
    this.setState({ ...this.emptyState });
  };

  handleModalClose = () => {
    const { getTeachers, onHide, isLoading } = this.props;
    if (!isLoading.sendInviteToTeachers) {
      getTeachers();
      onHide();
    }
  };

  handleTeacherRoleChange = (element: any) => (
    value: ValueType<ReactSelectOption<UserRole>, false>
  ) => {
    const res = value as ReactSelectOption<UserRole>;
    const { selectedEmailColumn, selectedRoleColumn } = this.state;
    if (res && selectedRoleColumn) {
      const { rawData } = this.props;
      this.props.updateCsvFileRawData(
        rawData.map((row) => {
          if (row[selectedEmailColumn] === element[selectedEmailColumn]) {
            return { ...row, [selectedRoleColumn]: res.value };
          }
          return row;
        })
      );
    }
  };

  getRoleCell = (element: any) => {
    const { selectedRoleColumn } = this.state;
    if (selectedRoleColumn) {
      const rawRole = element[selectedRoleColumn]?.toLowerCase();
      return (
        <td>
          <Select
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            key={"role"}
            placeholder="Select Role..."
            value={TeacherRoles.find(
              (tr) => tr.value.toLowerCase() === rawRole
            )}
            options={TeacherRoles}
            onChange={this.handleTeacherRoleChange(element)}
          />
        </td>
      );
    }
    return null;
  };

  render() {
    const {
      originalFileName,
      rawData,
      show,
      columnsNames,
      hasDistrictPermission,
    } = this.props;
    const {
      selectedEmailColumn,
      selectedFirstNameColumn,
      selectedLastNameColumn,
      selectedColumnNames,
      selectedRoleColumn,
      numPreviewRows,
    } = this.state;
    const columnsIsSelected =
      selectedEmailColumn && selectedFirstNameColumn && selectedLastNameColumn;

    return (
      //@ts-ignore
      <Modal
        show={show}
        size="lg"
        onHide={this.handleModalClose}
        animation={false}
        backdropClassName="customDarkModalBackdrop in"
        //@ts-ignore
        onShow={this.handleOnEnter}
      >
        <Modal.Header
          closeButton
          className="purpleModalHeader orangeModalHeader"
        >
          <Modal.Title>Upload User Roster</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!originalFileName ? (
            <UploadTeachersRosterManual hasDistrictPermission = {hasDistrictPermission} />
          ) : (
            <>
              <div>
                <div className="text-center">
                  <h2 className="font-weight-bold">
                    Great, you uploaded the file
                  </h2>
                  <div>
                    <FontAwesomeIcon icon={faFile} />
                    <h4 className="font-weight-bold d-inline">
                      &nbsp;{originalFileName}
                    </h4>
                    <h5>
                      It has {rawData.length} rows.&nbsp;
                      <label className="clickableLabel">
                        Click here to pick a different file.
                        <DataFileReader />
                      </label>
                    </h5>
                  </div>
                </div>
                <br />
                <p className="text-left font-weight-bold">
                  Tell us the column header (label) you want to use for each
                  field below.
                </p>
                {hasDistrictPermission && (
                  <p className="mb-0">
                    We took our best guess already but we aren't always right.
                    We’ll also show you a preview of the content in your
                    selected fields so you know you're picking the correct ones.
                  </p>
                )}
                <br />
                <form id="newInviteForm13" onSubmit={this.handleInviteSend}>
                  <div className="text-left">
                    <div className="form-group row mb-0">
                      <div className="col-sm-6">
                        <ColumnHeaderSelector
                          required
                          name={"selectedEmailColumn"}
                          title={"Email Column"}
                          value={selectedEmailColumn}
                          onChange={this.handleChange()}
                          columnNames={columnsNames}
                          selectedColumnNames={selectedColumnNames}
                        />
                      </div>

                      {hasDistrictPermission && (
                        <div className="col-sm-6">
                          <ColumnHeaderSelector
                            required
                            name={"selectedRoleColumn"}
                            title={"Account Type (Role)"}
                            value={selectedRoleColumn || ""}
                            onChange={this.handleChange()}
                            columnNames={columnsNames}
                            selectedColumnNames={selectedColumnNames}
                          />
                        </div>
                      )}
                    </div>

                    <div className="form-group row mb-0">
                      <div className="col-sm-6">
                        <ColumnHeaderSelector
                          required
                          name={"selectedFirstNameColumn"}
                          title={"First Name Column"}
                          value={selectedFirstNameColumn}
                          onChange={this.handleChange()}
                          columnNames={columnsNames}
                          selectedColumnNames={selectedColumnNames}
                        />
                      </div>
                    </div>

                    <div className="form-group row mb-0">
                      <div className="col-sm-6">
                        <ColumnHeaderSelector
                          required
                          name={"selectedLastNameColumn"}
                          title={"Last Name Column"}
                          value={selectedLastNameColumn}
                          onChange={this.handleChange()}
                          columnNames={columnsNames}
                          selectedColumnNames={selectedColumnNames}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <FailedInvitationsAlert />
                <hr />
                {columnsIsSelected && (
                  <>
                    <h6 className="font-weight-bold text-left">
                      {" "}
                      Roster preview
                    </h6>
                    <table className="scrollableTable rosterScrollableTable">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          {hasDistrictPermission && selectedRoleColumn && (
                            <th>Account Type (Role)</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {rawData
                          .slice(0, numPreviewRows)
                          .map((element: any, index) => (
                            <tr key={index}>
                              <td>{`${element[selectedFirstNameColumn]} ${element[selectedLastNameColumn]}`}</td>
                              <td id={`roster_email_${index}`}>{element[selectedEmailColumn]}</td>
                              {hasDistrictPermission &&
                                selectedRoleColumn &&
                                this.getRoleCell(element)}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {rawData.length > numPreviewRows && (
                      <h6
                        className="font-weight-bold"
                        onClick={this.handleDisplayAllRows}
                      >
                        And {rawData.length - numPreviewRows} more...
                      </h6>
                    )}
                  </>
                )}
              </div>

              <div className="text-right mt-3">
                <button
                  form="newInviteForm13"
                  type="submit"
                  className="blueBtnSm"
                >
                Invite and Save{" "}
                  {(this.props.isLoading.sendInviteToTeachers 
                  || this.props.isLoading.addUserToDistrict) && (
                    <Spinner animation="border" size="sm" />
                  )}
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  onboarding,
  auth,
}: ApplicationState): PropsFromState => {
  const { loadableFile } = onboarding;
  return {
    hasDistrictPermission:
      auth.userInfo?.profile.current_assignment?.role &&
      districtRoles.includes(auth.userInfo.profile.current_assignment.role),
    originalFileName: loadableFile ? loadableFile.originalFileName! : "",
    rawData: loadableFile ? loadableFile.rawData : [],
    columnsNames: loadableFile ? loadableFile.columnsNames : [],
    errors: { sendInviteToTeachers: onboarding.errors.sendInviteToTeachers },
    isLoading: {
      sendInviteToTeachers: onboarding.isLoading.sendInviteToTeachers,
      addUserToDistrict: onboarding.isLoading.addUserToDistrict,
    },
    invites: onboarding.invites,
    show: onboarding.modalsState.uploadTeachersRosterModal,
    districtUserName: auth.userInfo?.profile.district?.owner.first_name + " " + auth.userInfo?.profile.district?.owner.last_name,
    showFailedInvitationsAlert: onboarding.modalsState.showFailedInvitationsAlert,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      createUserInDistrict: createUserInDistrict,
      checkEmailExist: checkEmailExist,
      sendInviteToTeachers: sendInviteToTeachers,
      updateCsvFileRawData: updateCsvFileRawData,
      getTeachers: getTeachers,
      onHide: hideUploadTeachersRosterModal,
      showConfirmDialog: showConfirmDialog
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadTeachersRosterModal);
