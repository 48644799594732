import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Modal, Nav, Row, Spinner, Table } from "react-bootstrap";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useDispatch } from "react-redux";
import {
  hideBehaviorScreenerModal,
} from "../../../../../../store/groups/actions";
import { ApplicationState } from "../../../../../../store";
import {
  BehaviorScreenState,
  DataStudentEntry,
  ElementaryGrade,
  EvidenceColumn,
  EvidenceColumnGroup,
  EvidenceType,
  MiddleHighGrade,
  Student,
  StudentEntry,
} from "../../../../../../store/onboarding/types";
import { toastr } from "react-redux-toastr";
import {
  setValueOfDSEtoDataPeriod,
  updateValueOfDSEtoDataPeriod,
} from "../../../../../../store/onboarding/actions";
import { getFullNameForStudent } from "../../../../../../utils/StudentMapUtils";
import { IS_READY_COACH } from "../../../../../../constants";

type PropsFromState = {
  evidenceColumnGroups?: Array<EvidenceColumnGroup>;
  studentEntry?: StudentEntry;
  student?: Student;
  showModal: boolean;
  evidenceColumnGroupId?: number,
  evidenceColumn?: EvidenceColumn[],
  studentId?: number,
  isLoading: {
    updateValueOfDSEtoDataPeriod: boolean;
    setValueOfDSEtoDataPeriod: boolean;
  };
  errors: {
    updateValueOfDSEtoDataPeriod?: string; 
    setValueOfDSEtoDataPeriod?: string;
  };
};

type DispatchProps = {
  hideBehaviorScreenerModal: () => any;
  setValueOfDSEtoDataPeriod: (
    evidenceColumnGroupId: number,
    dse: DataStudentEntry
  ) => any;
  updateValueOfDSEtoDataPeriod: (
    evidenceColumnGroupId: number,
    dseId: number,
    dse: DataStudentEntry
  ) => any;
};

type Props = PropsFromState & DispatchProps;

const BehaviorScreenerModal: React.FunctionComponent<Props> = (props) => {
  const { 
    student,  
    evidenceColumnGroupId,
    evidenceColumn,
    studentId,
    evidenceColumnGroups
  } = props;
 
  const dispatch = useDispatch();

  const symptoms = {
    "Steal": undefined,
    "Lie, Cheat, Sneak": undefined,
    "Behavior Problem": undefined,
    "Peer Rejection": undefined,
    "Low Academic Achievement": undefined,
    "Negative Attitude": undefined,
    "Aggressive Behavior": undefined,
    "Emotionally Flat": undefined,
    "Shy; Withdrawn": undefined,
    "Sad; Depressed": undefined,
    "Anxious": undefined,
    "Lonely": undefined,
  }

  const externalElementary = [
    "Steal",
    "Lie, Cheat, Sneak",
    "Behavior Problem",
    "Peer Rejection",
    "Low Academic Achievement",
    "Negative Attitude",
    "Aggressive Behavior"
  ];

  const internalElementary = [
    "Emotionally Flat",
    "Shy; Withdrawn",
    "Sad; Depressed",
    "Anxious",
    "Lonely",
  ]

  const externalMiddle = [
    "Steal",
    "Lie, Cheat, Sneak",
    "Behavior Problem",
    "Peer Rejection",
    "Low Academic Achievement",
    "Negative Attitude",
    "Aggressive Behavior"
  ];

  const internalMiddle = [
    "Peer Rejection",
    "Emotionally Flat",
    "Shy; Withdrawn",
    "Sad; Depressed",
    "Anxious",
    "Lonely",
  ]
  
  const [behaviorSymptoms, setBehaviorSymptoms] = useState<any>([]);
  let [internalScreenerSum, setInternalScreenerSum] = useState(0);
  let [externalScreenerSum, setExternalScreenerSum] = useState(0);

  useEffect(() => {
    let screenerExtraInfo: any; 
    let behaviorColumn:EvidenceColumn | undefined = 
        evidenceColumn?.find((ec) => ec.evidence_type == EvidenceType.BehaviorScreener);
    if(behaviorColumn?.student_entries.length) {
      let studentEntry: StudentEntry | undefined = 
        behaviorColumn?.student_entries.find((entry) => entry.student_id == student?.id);
      if(studentEntry?.extra_info && Object.keys(studentEntry?.extra_info).length) {
        screenerExtraInfo = studentEntry?.extra_info.srss_data
      }
    }
    if(screenerExtraInfo)
      setBehaviorSymptoms(screenerExtraInfo)
    else 
      setBehaviorSymptoms(symptoms)
  },[props.showModal])

  useEffect(() => {
    let internalSum: number = 0;
    let externalSum: number = 0;
    for (const key in behaviorSymptoms) {
      if (Object.prototype.hasOwnProperty.call(behaviorSymptoms, key)) {
        if(behaviorSymptoms[key] != undefined) {
          if(ElementaryGrade.indexOf(student!.grade!) > -1) {
            if(internalElementary.includes(key)) {
              internalSum += behaviorSymptoms[key];
            } 
            if(externalElementary.includes(key)) {
              externalSum += behaviorSymptoms[key];
            }
          } else if(MiddleHighGrade.indexOf(student!.grade!) > -1) {
            if(internalMiddle.includes(key)) {
              internalSum += behaviorSymptoms[key];
            } 
            if(externalMiddle.includes(key)) {
              externalSum += behaviorSymptoms[key];
            }
          }
        }
      }
    }
    setInternalScreenerSum(internalSum)
    setExternalScreenerSum(externalSum)

  }, [behaviorSymptoms])
  
  const studentName = useMemo(() => {
    return student ? getFullNameForStudent(student) : "Student";
  },[student])

  const findDataStudentEntry = (
    evidenceColumnGroupId: number,
    evidenceColumnId: number,
    studentId?: number
  ) => {
    let dataStudentEntry: StudentEntry | undefined;

    const curEvidenceColumnGroup:
      | EvidenceColumnGroup
      | undefined = evidenceColumnGroups?.find(
        (evidenceColumnGroup) => evidenceColumnGroup.id === evidenceColumnGroupId
      );
    if (curEvidenceColumnGroup) {
      const curEvidenceColumn:
        | EvidenceColumn
        | undefined = curEvidenceColumnGroup.evidence_columns.find(
          (evidenceColumn) => evidenceColumn.id === evidenceColumnId
        );
      if (curEvidenceColumn) {
        const studentEntry:
          | StudentEntry
          | undefined = curEvidenceColumn.student_entries.find(
            (studentEntry) => studentEntry.student_id === studentId
          );
        if (studentEntry) {
          if (studentEntry) {
            dataStudentEntry = studentEntry;
          }
        }
      }
    }
    return dataStudentEntry;
  };
   
  const handleSaveScreener = () => {
    let isAnySymptomsEmpty:boolean = false;
    for (const key in behaviorSymptoms) {
      if (!(key in symptoms) || behaviorSymptoms[key] == undefined) {
        isAnySymptomsEmpty = true;
        break;
      }
    }
    if(isAnySymptomsEmpty) {
      toastr.error('Alert!!','Please select your observation for each symptom');
      return;
    }

    let screenerColumnId: number | undefined =
      evidenceColumn?.length 
        ? evidenceColumn?.find((ec) => ec.evidence_type == EvidenceType.BehaviorScreener)?.id
        : 0;
    let externalSRSSColumnId: number | undefined =
        evidenceColumn?.length 
          ? evidenceColumn?.find((ec) => ec.measurement?.column_name == 'SRSS External')?.id
          : 0;
    let internalSRSSColumnId: number | undefined =
        evidenceColumn?.length 
          ? evidenceColumn?.find((ec) => ec.measurement?.column_name == 'SRSS Internal')?.id
          : 0;
    let isUpdateDSE: boolean = false;
    const dataStudentEntryForScreener = findDataStudentEntry(
      evidenceColumnGroupId!,
      screenerColumnId!,
      student?.id
    );
    
    if (dataStudentEntryForScreener) {
      const stdId = dataStudentEntryForScreener.entry_id;
      if (stdId) {
        //updated STE
        dispatch(updateValueOfDSEtoDataPeriod(evidenceColumnGroupId!, stdId, {
          student_id: student?.id,
          evidence_column_id: screenerColumnId,
          value: 11111,
          extra_info: {
            srss_data: behaviorSymptoms
          }
        }));
        isUpdateDSE = true
      }
    }
    const dataStudentEntryForExternal = findDataStudentEntry(
      evidenceColumnGroupId!,
      externalSRSSColumnId!,
      student?.id
    );
    
    if (dataStudentEntryForExternal) {
      const stdId = dataStudentEntryForExternal.entry_id;
      if (stdId) {
        //updated STE
        dispatch(updateValueOfDSEtoDataPeriod(evidenceColumnGroupId!, stdId, {
          student_id: student?.id,
          evidence_column_id: externalSRSSColumnId,
          value: externalScreenerSum
        }));
        isUpdateDSE = true
      }
    }

    const dataStudentEntryForInternal = findDataStudentEntry(
      evidenceColumnGroupId!,
      internalSRSSColumnId!,
      student?.id
    );
    if (dataStudentEntryForInternal) {
      const stdId = dataStudentEntryForInternal.entry_id;
      if (stdId) {
        //updated STE
        dispatch(updateValueOfDSEtoDataPeriod(evidenceColumnGroupId!, stdId, {
            student_id: student?.id,
            evidence_column_id: internalSRSSColumnId,
            value: internalScreenerSum
        }));
        isUpdateDSE = true;
      }
    }

    if(isUpdateDSE) {
      handleModalHide();
      return;
    }

    //Behavior screener data
    dispatch(setValueOfDSEtoDataPeriod(evidenceColumnGroupId!, {
      student_id: student?.id,
      evidence_column_id: screenerColumnId,
      value: 11111,
      extra_info: {
        srss_data: behaviorSymptoms
      }
    }));
    //External screener data
    dispatch(setValueOfDSEtoDataPeriod(evidenceColumnGroupId!, {
      student_id: student?.id,
      evidence_column_id: externalSRSSColumnId,
      value: externalScreenerSum
    }));
    //Internal screener data
    dispatch(setValueOfDSEtoDataPeriod(evidenceColumnGroupId!, {
      student_id: student?.id,
      evidence_column_id: internalSRSSColumnId,
      value: internalScreenerSum
    }));


     handleModalHide();
  };

  const handleModalHide = () => {
    dispatch(hideBehaviorScreenerModal());
  };

  const handleScreenerChange = (screenState: number, key: string) => {
    setBehaviorSymptoms((sbs: any) => {
      return {
        ...sbs,
        [key]: screenState
      }
    });
    let element = document.getElementById(key);
        element?.classList.add("table-primary");
  }

  const getScreenerRow = () => {
    let screenerRow = [];
    for (const key in symptoms) {
      if (Object.prototype.hasOwnProperty.call(behaviorSymptoms, key)) {
       const behaviorSymptomRow = 
        <tr id={key}>
          <td>{key}</td>
          <td 
            className="text-center" 
            onClick={() => handleScreenerChange(BehaviorScreenState.NEVER, key)}
          >
            <Form.Check
              id={`symptom_${key}`}
              name={`symptom_${key}`}
              onChange={() => handleScreenerChange(BehaviorScreenState.NEVER, key)}
              type="radio"
              label
              checked={behaviorSymptoms[key] == BehaviorScreenState.NEVER}
              value={behaviorSymptoms[key]}
            />
          </td>
          <td 
            className="text-center" 
            onClick={() => handleScreenerChange(BehaviorScreenState.OCCASIONALLY, key)}
          >
            <Form.Check
              id={`symptom_${key}`}
              name={`symptom_${key}`}
              
              onChange={() => handleScreenerChange(BehaviorScreenState.OCCASIONALLY, key)}
              type="radio"
              label
              checked={behaviorSymptoms[key] == BehaviorScreenState.OCCASIONALLY}
              value={behaviorSymptoms[key]}
            />
          </td>
          <td 
            className="text-center" 
            onClick={() => handleScreenerChange(BehaviorScreenState.SOMETIMES, key)}
          >
            <Form.Check
              id={`symptom_${key}`}
              name={`symptom_${key}`}
              
              onChange={() => handleScreenerChange(BehaviorScreenState.SOMETIMES, key)}
              type="radio"
              label
              checked={behaviorSymptoms[key] == BehaviorScreenState.SOMETIMES}
              value={behaviorSymptoms[key]}
            />
          </td>
          <td 
            className="text-center" 
            onClick={() => handleScreenerChange(BehaviorScreenState.FREQUENTLY, key)}
          >
            <Form.Check
              id={`symptom_${key}`}
              name={`symptom_${key}`}
              onChange={() => handleScreenerChange(BehaviorScreenState.FREQUENTLY, key)}
              type="radio"
              label
              checked={behaviorSymptoms[key] == BehaviorScreenState.FREQUENTLY}
              value={behaviorSymptoms[key]}
            />
          </td>
        </tr>
       screenerRow.push(behaviorSymptomRow);
      }
    }

    return screenerRow;
  }

  return (
    <Modal
      show={props.showModal}
      onHide={handleModalHide}
      animation={false}
      size="lg"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header
        closeButton
        className={IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"}
      >
        <Modal.Title>Screener for {studentName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <div className="col-12 mb-3">
            Please rate how frequently you observe the following for <span className="font-weight-bold">{studentName}</span>:
          </div>
          <div className="col-12">
            <Table className="m-0 mb-1 table-bordered screenerTable">
              <tr className="table-secondary">
                <th className="font-weight-bold" style={{width:"40%"}}>Symptom</th>
                <th className="font-weight-bold text-center" style={{width:"10%"}}>Never</th>
                <th className="font-weight-bold text-center" style={{width:"10%"}}>Occasionally</th>
                <th className="font-weight-bold text-center" style={{width:"10%"}}>Sometimes</th>
                <th className="font-weight-bold text-center" style={{width:"10%"}}>Frequently</th>
              </tr>
              <tbody>
                {getScreenerRow()}
              </tbody>
            </Table>
          </div>
          {/* <span>External: {externalScreenerSum}</span>
          <span>Internal: {internalScreenerSum}</span> */}
        </div>
        <div className="skillFocusButton">
          <button className="blueBtnLg" onClick={handleSaveScreener}>
            Save Screener{""}
            {(props.isLoading.updateValueOfDSEtoDataPeriod ||
              props.isLoading.setValueOfDSEtoDataPeriod) && (
              <Spinner animation="border" size="sm" />
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = ({
  groups,
  onboarding,
}: ApplicationState): PropsFromState => {

  const evidenceColumnGroup: EvidenceColumnGroup | undefined = 
  onboarding.evidencePeriodData && 
  onboarding.evidencePeriodData?.evidence_column_groups.find(
    (ecg) => ecg.id === groups.evidenceColumnGroupId
  );

  return {
    showModal: groups.modalsState.behaviorScreenerModal,
    student: groups.student,
    evidenceColumnGroupId: groups.evidenceColumnGroupId,
    evidenceColumn: evidenceColumnGroup?.evidence_columns,
    evidenceColumnGroups: onboarding.evidencePeriodData?.evidence_column_groups,
    isLoading: {
      updateValueOfDSEtoDataPeriod: onboarding.isLoading.updateValueOfDSEtoDataPeriod,
      setValueOfDSEtoDataPeriod: onboarding.isLoading.setValueOfDSEtoDataPeriod,
    },
    errors: {
      setValueOfDSEtoDataPeriod: onboarding.errors.setValueOfDSEtoDataPeriod,
      updateValueOfDSEtoDataPeriod: onboarding.errors.updateValueOfDSEtoDataPeriod,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideBehaviorScreenerModal: hideBehaviorScreenerModal,
      setValueOfDSEtoDataPeriod: setValueOfDSEtoDataPeriod,
      updateValueOfDSEtoDataPeriod: updateValueOfDSEtoDataPeriod
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BehaviorScreenerModal);
