import {
  groupInterventionGroupsByTeacher,
  InterventionGroupMap,
} from "../../../../../../../utils/Mappers";
import { useMemo } from "react";
import { UserInfo } from "../../../../../../../store/auth/types";
import { getFullName } from "../../../../../../../utils/NamesUtils";
import { Student } from "../../../../../../../store/onboarding/types";
import {
  Intervention,
  InterventionGroup,
  ProgressTrendSortValues,
  StudentGoal,
} from "../../../../../../../store/onboarding/cases/types";
import moment, { Moment } from "moment";
import {
  DataFilterState,
  InterventionGroupSortBy,
} from "../../../../../../../store/dataFilter/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../store";
import _ from "lodash";
import useUserRole from "../../../../../../../utils/hooks/useUserRole";

const useInterventionGroupsMap = ({ student }: { student?: Student }) => {
  const {
    groupSearch,
    groupSort,
    showArchivedGroups,
    exitedStudentsGroup,
  } = useSelector<ApplicationState, DataFilterState>((s) => s.dataFilter);

  const { isCoach, isTeacher, isDistrictOwner } = useUserRole();

  const listOrderSwitch = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.cases.listOrderSwitch);

  const interventionGroups = useSelector<ApplicationState, InterventionGroup[]>(
    (s) =>
      showArchivedGroups
        ? s.cases.archivedInterventionGroups
        : s.cases.interventionGroups
  );

  const userInfo = useSelector<ApplicationState, UserInfo | undefined>(
    (s) => s.auth.userInfo
  );

  const isStudentDetail = useSelector<
  ApplicationState
  >((s) => s.cases.isStudentDetail);

  const searchStringLowerCase = groupSearch.toLowerCase();

  const groupNameIncludeSearchString = (groupName: string) => {
    return groupName?.toLowerCase().includes(searchStringLowerCase);
  };

  const teacherNameIncludeSearchString = (user?: UserInfo) => {
    return (
      user &&
      getFullName(user)
        .toLowerCase()
        .includes(searchStringLowerCase)
    );
  };

  const studentNamesIncludeSearchString = (students: Array<Student>) => {
    return students
      .map((s) => getFullName(s).toLowerCase())
      .some((sn) => sn.includes(searchStringLowerCase));
  };

  const coachNameIncludeSearchString = (user?: UserInfo) => {
    return (
      user &&
      getFullName(user)
        .toLowerCase()
        .includes(searchStringLowerCase)
    );
  };

  const interventionNamesIncludeSearchString = (
    interventions?: Array<Intervention>
  ) => {
    return interventions
      ?.map((i) => i.name.toLowerCase())
      .some((iName) => iName.includes(searchStringLowerCase));
  };

  const goalMeasurementsNamesIncludeSearchString = (
    student_goals?: Array<StudentGoal>
  ) => {
    return student_goals
      ?.map((sg) => sg.measurement?.display_name.toLowerCase())
      .some((mn) => mn.includes(searchStringLowerCase));
  };

  let allActiveIntervetionGroup: number[] = [];
  let uniqueActiveIntervenstionGroup: number[] = [];
  let allArchivedIntervetionGroup: number[] = [];
  let uniqueArchivedIntervenstionGroup: number[] = [];

  //if (exitedStudentsGroup && student) {
  interventionGroups.map((ig) => {
    ig.student_goals.map((goal) => {
      if (!goal.archived && goal.student.id == student?.id) {
        allActiveIntervetionGroup.push(ig.id);
      }
    });
  });
  uniqueActiveIntervenstionGroup = allActiveIntervetionGroup.filter(
    (v, i, a) => a.indexOf(v) === i
  );

  interventionGroups.map((ig) => {
    ig.student_goals.map((goal) => {
      if (
        goal.archived &&
        goal.student.id == student?.id &&
        !uniqueActiveIntervenstionGroup.includes(ig.id)
      ) {
        allArchivedIntervetionGroup.push(ig.id);
      }
    });
  });
  uniqueArchivedIntervenstionGroup = allArchivedIntervetionGroup.filter(
    (v, i, a) => a.indexOf(v) === i
  );
  //}

  const interventionGroupsMap: Array<InterventionGroupMap> = useMemo(() => {
    let filteredGroups;
    if (exitedStudentsGroup && student) {
      filteredGroups = interventionGroups
        .filter((ig) => ig.finished)
        .filter((ig) =>
           student ? ig.student_goals.every((item) => item.archived) : true
        )
        .filter((ig) => uniqueArchivedIntervenstionGroup.includes(ig.id))
        .filter(
          (ig) => {
          let teacherOrCoachCondition;
          if(isDistrictOwner && isStudentDetail) {
            if(listOrderSwitch) {
              teacherOrCoachCondition = teacherNameIncludeSearchString(ig.teacher_assignment?.user)
            } else {
              teacherOrCoachCondition = coachNameIncludeSearchString(ig.coach_assignment?.user)
            }
          } else {
            teacherOrCoachCondition = teacherNameIncludeSearchString(ig.teacher_assignment?.user) ||
            coachNameIncludeSearchString(ig.coach_assignment?.user)
          }

          return groupNameIncludeSearchString(ig.name) ||
            teacherOrCoachCondition ||
            studentNamesIncludeSearchString(ig.students) ||
            interventionNamesIncludeSearchString(ig.interventions) ||
            goalMeasurementsNamesIncludeSearchString(ig.student_goals)
          }
        );
    } else if (!exitedStudentsGroup && student) {
      filteredGroups = interventionGroups
        .filter((ig) => ig.finished)
        .filter((ig) =>
          student && !showArchivedGroups ? ig.students.some((item) => item.id === student.id) : true
        )
        .filter((ig) => isStudentDetail && !showArchivedGroups ? uniqueActiveIntervenstionGroup.includes(ig.id) : true)
        .filter(
          (ig) => {
            let teacherOrCoachCondition;
            if(isDistrictOwner && isStudentDetail) {
              if(listOrderSwitch) {
                teacherOrCoachCondition = teacherNameIncludeSearchString(ig.teacher_assignment?.user)
              } else {
                teacherOrCoachCondition = coachNameIncludeSearchString(ig.coach_assignment?.user)
              }
            } else {
              teacherOrCoachCondition = teacherNameIncludeSearchString(ig.teacher_assignment?.user) ||
              coachNameIncludeSearchString(ig.coach_assignment?.user)
            }

            return groupNameIncludeSearchString(ig.name) ||
            teacherOrCoachCondition ||
            studentNamesIncludeSearchString(ig.students) ||
            interventionNamesIncludeSearchString(ig.interventions) ||
            goalMeasurementsNamesIncludeSearchString(ig.student_goals)
          }
        );
    } else {
      filteredGroups = interventionGroups
        .filter((ig) => ig.finished)
        .filter((ig) =>
          student ? ig.students.some((item) => item.id === student.id) : true
        )
        .filter(
          (ig) => {

            let teacherOrCoachCondition;
            if(isDistrictOwner && isStudentDetail) {
              if(listOrderSwitch) {
                teacherOrCoachCondition = teacherNameIncludeSearchString(ig.teacher_assignment?.user)
              } else {
                teacherOrCoachCondition = coachNameIncludeSearchString(ig.coach_assignment?.user)
              }
            } else {
              teacherOrCoachCondition = teacherNameIncludeSearchString(ig.teacher_assignment?.user) ||
              coachNameIncludeSearchString(ig.coach_assignment?.user)
            }

            return groupNameIncludeSearchString(ig.name) ||
            teacherOrCoachCondition ||
            studentNamesIncludeSearchString(ig.students) ||
            interventionNamesIncludeSearchString(ig.interventions) ||
            goalMeasurementsNamesIncludeSearchString(ig.student_goals)
          }
        );
    }

    const correlationCoeff = groupSort?.direction === "desc" ? -1 : 1;

    const sortDates = (dateA?: string, dateB?: string) => {
      if (!dateA) return 1;
      if (!dateB) return -1;

      const closestADate = moment(dateA);
      const closestBDate = moment(dateB);

      return closestADate.isBefore(closestBDate)
        ? -correlationCoeff
        : closestADate.isAfter(closestBDate)
        ? correlationCoeff
        : 0;
    };

    const getClosestDate = (student_goals?: Array<StudentGoal>) => {
      return student_goals
        ?.map((sg) => sg.target_date)
        .reduce((pV: string | undefined, cV) => {
          if (!pV) return cV;
          if (
            moment(cV).diff(moment()) >= 0 &&
            moment(cV).diff(moment(pV)) < 0
          ) {
            return cV;
          }
          return pV;
        }, undefined);
    };

    const sortByGoalDate = (a: InterventionGroup, b: InterventionGroup) => {
      const closestA = getClosestDate(a.student_goals);
      const closestB = getClosestDate(b.student_goals);

      return sortDates(closestA, closestB);
    };

    const sortByProgressTrend = (
      a: InterventionGroup,
      b: InterventionGroup
    ) => {
      if (!a.trend_status) return 1;
      if (!b.trend_status) return -1;

      return (
        correlationCoeff *
        (ProgressTrendSortValues[a.trend_status] -
          ProgressTrendSortValues[b.trend_status])
      );
    };

    const sortByLatestActivity = (
      a: InterventionGroup,
      b: InterventionGroup
    ) => {
      const dateA = a?.latest_activity?.date;
      const dateB = b?.latest_activity?.date;
      return sortDates(dateA, dateB);
    };

    const getGroupScheduleDayStartByWeekday = (
      weekday: number
    ):
      | "time_monday_start"
      | "time_tuesday_start"
      | "time_wednesday_start"
      | "time_thursday_start"
      | "time_friday_start" => {
      switch (weekday) {
        case 1:
          return "time_monday_start";
        case 2:
          return "time_tuesday_start";
        case 3:
          return "time_wednesday_start";
        case 4:
          return "time_thursday_start";
        case 5:
          return "time_friday_start";
        default:
          return "time_monday_start";
      }
    };

    const getGroupClosestScheduleDateTime = (
      group: InterventionGroup
    ): Moment | null => {
      if (
        group.time_monday_start ||
        group.time_tuesday_start ||
        group.time_wednesday_start ||
        group.time_thursday_start ||
        group.time_friday_start
      ) {
        const todayWeekday = moment().weekday();

        const todayStartKey = getGroupScheduleDayStartByWeekday(todayWeekday);

        const todayTime = group[todayStartKey];
        //check if todayTime is  not null and after current time
        if (todayTime) {
          const timeDateTime = moment(todayTime, "hh:mm A");
          if (timeDateTime.isAfter(moment())) {
            return timeDateTime;
          }
        }
        //in other cases find next available time in schedule
        let weekday = todayWeekday;
        let foundedTime = null;
        for (let i = 0; i < 5; i++) {
          if (weekday >= 5) {
            weekday = 1;
          } else {
            weekday++;
          }
          const startKey = getGroupScheduleDayStartByWeekday(weekday);
          foundedTime = group[startKey];
          if (foundedTime) {
            return moment(foundedTime, "hh:mm A")
              .day(weekday)
              .add(1, "weeks");
          }
        }
      }
      return null;
    };

    const sortByInterventionSchedule = (
      a: InterventionGroup,
      b: InterventionGroup
    ) => {
      const aDateTime = getGroupClosestScheduleDateTime(a);
      const bDateTime = getGroupClosestScheduleDateTime(b);

      if (!aDateTime && !bDateTime) return 0;
      if (!aDateTime) return 1;
      if (!bDateTime) return -1;

      return aDateTime.unix() - bDateTime.unix();
    };

    const getSortFunction = () => {
      switch (groupSort?.sortBy) {
        case InterventionGroupSortBy.GOAL_DATE:
          return sortByGoalDate;
        case InterventionGroupSortBy.PROGRESS_TREND:
          return sortByProgressTrend;
        case InterventionGroupSortBy.ACTIVITY:
          return sortByLatestActivity;
        case InterventionGroupSortBy.INTERVENTION_SCHEDULE:
          return sortByInterventionSchedule;
        default:
          return undefined;
      }
    };

    let sortedGroups: InterventionGroup[];
    if (groupSort) {
      const sortFunction = getSortFunction();
      sortedGroups = filteredGroups.sort(sortFunction);
    } else {
      sortedGroups = filteredGroups;
    }

    return groupInterventionGroupsByTeacher(
      sortedGroups,
      userInfo,
      !!groupSort,
      listOrderSwitch
    );
  }, [
    interventionGroups,
    groupSearch,
    groupSort,
    userInfo,
    exitedStudentsGroup,
    listOrderSwitch
  ]);

  const notFinishedGroups = useSelector<ApplicationState, InterventionGroup[]>(
    (s) => s.cases.notFinishedGroups
  );

  const filteredNotFinishedGroups = useMemo(() => {
    return notFinishedGroups.filter(
      (ig) =>
        groupNameIncludeSearchString(ig.name) ||
        teacherNameIncludeSearchString(ig.teacher_assignment?.user) ||
        studentNamesIncludeSearchString(ig.students) ||
        coachNameIncludeSearchString(ig.coach_assignment?.user) ||
        interventionNamesIncludeSearchString(ig.interventions) ||
        goalMeasurementsNamesIncludeSearchString(ig.student_goals)
    );
  }, [notFinishedGroups, groupSearch]);


  const interventionGroupsMapWithNotFinished = useMemo(() => {
    if ((!isCoach && !isTeacher) || showArchivedGroups) {
      return interventionGroupsMap;
    }

    const equal = isDistrictOwner
          ? !listOrderSwitch
              ? (a: InterventionGroupMap, b: InterventionGroup) =>
                  a.employeeAssignment?.id === b.teacher_assignment?.id
              : (a: InterventionGroupMap, b: InterventionGroup) =>
                  a.employeeAssignment?.id === b.coach_assignment?.id
          : isCoach
              ? (a: InterventionGroupMap, b: InterventionGroup) =>
                  a.employeeAssignment?.id === b.teacher_assignment?.id
              : (a: InterventionGroupMap, b: InterventionGroup) =>
                  a.employeeAssignment?.id === b.coach_assignment?.id;

    return _.reduce(
      filteredNotFinishedGroups,
      (pV, cV) => {
        if (pV.some((g) => equal(g, cV))) {
          return pV.map((g) =>
            equal(g, cV)
              ? {
                  ...g,
                  unfinishedGroupsIds: [
                    ...(g.unfinishedGroupsIds ?? []),
                    cV.id,
                  ],
                }
              : g
          );
        }

        return [
          ...pV,
          {
            employeeAssignment: isDistrictOwner 
                                  ? !listOrderSwitch
                                      ? cV.teacher_assignment!
                                      : cV.coach_assignment!
                                  : isCoach
                                      ? cV.teacher_assignment!
                                      : cV.coach_assignment!,
            interventionGroups: [],
            unfinishedGroupsIds: [cV.id],
          },
        ];
      },
      interventionGroupsMap
    );
  }, [
    interventionGroupsMap,
    filteredNotFinishedGroups,
    isCoach,
    showArchivedGroups,
    listOrderSwitch
  ]);

  return {
    interventionGroupsMap,
    interventionGroupsMapWithNotFinished,
  };
};

export default useInterventionGroupsMap;
