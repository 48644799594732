import React, { FunctionComponent, useMemo } from "react";
import { FidelityByIntervention } from "../../../../../../store/onboarding/types";
import {
  generateInterventionAbbrev,
  getFullName,
} from "../../../../../../utils/NamesUtils";
import VerticalBarChart from "./VerticalBarChart";

type OwnProps = {
  fidelityByIntervention: FidelityByIntervention[];
};

type Props = OwnProps;

const FidelityInterventionChart: FunctionComponent<Props> = (props) => {
  const { fidelityByIntervention = [] } = props;

  const labels = useMemo(
    () =>
      fidelityByIntervention.map(
        (item) =>
          `${getFullName(item.coach)} / ${generateInterventionAbbrev(
            item.intervention.name
          )}`
      ),
    [fidelityByIntervention]
  );
  const data = useMemo(
    () =>
      fidelityByIntervention.map((item) => +(item.fidelity * 100).toFixed(0)),
    [fidelityByIntervention]
  );

  if (!data.length) {
    return null;
  }

  return (
    <div className="district-summary-chart-container">
      <div>
        <VerticalBarChart labels={labels} data={data} />
      </div>
    </div>
  );
};

export default FidelityInterventionChart;
