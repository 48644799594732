import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from "react";
import CollapsedContainer from "../../your-assigments/common/CollapsedContainer";
import AntecedentEvents from "./AntecedentEvents";
import { antecedentTypes } from "./types";
import Behaviors from "./Behaviors";
import ConsequenceEvents from "./ConsequenceEvents";
import { useDispatch, useSelector } from "react-redux";
import {
  createFbaABC,
  createFbaObservation,
  getFbaObservation,
  hideFbaObservationModal,
} from "../../../../../../store/onboarding/cases/actions";
import { FbaObservation } from "../../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../../store";
import { Spinner } from "react-bootstrap";
import { useLoading } from "../../../../../../utils/hooks/useLoading";
import { toastr } from "react-redux-toastr";

type Props = {
  fbaId: number;
  isSingleAbc?: boolean;
  observation?: FbaObservation;
};

const AbcCollapsedPanels: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const { fbaId, observation, isSingleAbc } = props;

  const [antecedents, setAntecedents] = useState<(number | undefined)[]>([]);
  const [customAntecedents, setCustomAntecedents] = useState<
    (string | undefined)[]
  >([]);

  const antecedentPartCompleted = useMemo(() => {
    return (
      antecedents.length === antecedentTypes.length &&
      antecedents.every((id) => id !== undefined)
    );
  }, [antecedents, antecedentTypes.length]);

  const [behaviors, setBehaviors] = useState<number[]>([]);
  const [customBehaviors, setCustomBehaviors] = useState<string | undefined>();
  const [behaviorDescriptions, setBehaviorDescriptions] = useState<{
    [behaviorId: number]: string | undefined;
  }>({});
  const behaviorsPartCompleted = useMemo(() => {
    return !!behaviors.length;
  }, [behaviors.length]);

  const [consequence, setConsequence] = useState<number | undefined>();
  const [customConsequence, setCustomConsequence] = useState<
    string | undefined
  >();
  const consequencePartCompleted = useMemo(() => {
    return consequence !== undefined;
  }, [consequence]);

  const [showAntecedents, setShowAntecedents] = useState<boolean>(true);
  const [showBehaviors, setShowBehaviors] = useState<boolean>(false);
  const [showConsequence, setShowConsequence] = useState<boolean>(false);

  const handleBehaviorsPanelOpen = useCallback(() => {
    setShowAntecedents(false);
    setShowBehaviors(true);
    setShowConsequence(false);
  }, []);

  const handleConsequencePanelOpen = useCallback(() => {
    setShowAntecedents(false);
    setShowBehaviors(false);
    setShowConsequence(true);
  }, []);

  const logABC = (observationId: number) => {
    dispatch(
      createFbaABC({
        antecedents: antecedents as number[],
        behaviors: behaviors,
        consequence: consequence!,
        customAntecedents: customAntecedents,
        customBehavior: customBehaviors,
        customConsequence: customConsequence,
        behaviorDescriptions: behaviorDescriptions,
        observation: observationId,
        isSingleAbc: isSingleAbc,
      })
    );
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleBehaviorLog = () => {
    if (observation) {
      logABC(observation.id);
    } else {
      setIsLoading(true);
      (dispatch(createFbaObservation(fbaId)) as any)?.then(
        (observation: FbaObservation) => {
          setIsLoading(false);
          logABC(observation.id);
        },
        (errMessage: string) => {
          setIsLoading(false);
          toastr.error("Error", errMessage);
        }
      );
    }
  };

  const isCreateFbaAbcLoading = useSelector(
    (s: ApplicationState) => s.cases.isLoading.createFbaABC
  );
  const createFbaAbcError = useSelector(
    (s: ApplicationState) => s.cases.errors.createFbaABC
  );
  const onSuccess = useCallback(() => {
    toastr.success("Success!", "The ABC has been successfully added!");
    if (isSingleAbc && observation?.id) {
      dispatch(hideFbaObservationModal());
      dispatch(getFbaObservation(observation.id));
    } else {
      setAntecedents([]);
      setCustomAntecedents([]);

      setBehaviors([]);
      setCustomBehaviors(undefined);
      setBehaviorDescriptions({});

      setConsequence(undefined);
      setCustomConsequence(undefined);

      setShowAntecedents(true);
      setShowBehaviors(false);
      setShowConsequence(false);
    }
  }, [isSingleAbc, observation?.id]);
  useLoading(isCreateFbaAbcLoading, createFbaAbcError, onSuccess);

  return (
    <div className="flex-grow-1" style={{ flexBasis: "75%" }}>
      <CollapsedContainer
        openByParentControl={showAntecedents}
        setOpenByParentControl={setShowAntecedents}
        useChevronIcons
        header={
          <div className="fba-header">
            <h3>Antecedent Events</h3>
            <p className="mb-0">
              What happened right before the behavior occurred?
            </p>
          </div>
        }
        className="fba-observation-container antecedent mb-3"
      >
        <AntecedentEvents
          observationabc={observation?.observationabc ?? []}
          antecedents={antecedents}
          setAntecedents={setAntecedents}
          customNotes={customAntecedents}
          setCustomNotes={setCustomAntecedents}
        />

        <div className="d-flex align-items-center justify-content-center mt-3">
          <button
            className="blueBtnMd"
            disabled={!antecedentPartCompleted}
            onClick={handleBehaviorsPanelOpen}
          >
            Next: Behavior
          </button>
        </div>
      </CollapsedContainer>

      <CollapsedContainer
        openByParentControl={showBehaviors}
        setOpenByParentControl={setShowBehaviors}
        useChevronIcons
        header={
          <>
            <div className="fba-header">
              <h3>Behavior(s)</h3>
              <p className="mb-0">What did you observe?</p>
            </div>
            {/*<span className="float-right">*/}
            {/*  <FontAwesomeIcon*/}
            {/*    icon={faCheckCircle}*/}
            {/*    size="2x"*/}
            {/*    style={{ color: titleCircleColor }}*/}
            {/*  />*/}
            {/*</span>*/}
          </>
        }
        className="fba-observation-container behavior mb-3"
      >
        <Behaviors
          observationabc={observation?.observationabc ?? []}
          behaviors={behaviors}
          setBehaviors={setBehaviors}
          behaviorsCustom={customBehaviors}
          setBehaviorsCustom={setCustomBehaviors}
          behaviorDescriptions={behaviorDescriptions}
          setBehaviorDescriptions={setBehaviorDescriptions}
        />

        <div className="d-flex align-items-center justify-content-center mt-3">
          <button
            className="blueBtnMd"
            disabled={!behaviorsPartCompleted}
            onClick={handleConsequencePanelOpen}
          >
            Next: Consequence
          </button>
        </div>
      </CollapsedContainer>

      <CollapsedContainer
        openByParentControl={showConsequence}
        setOpenByParentControl={setShowConsequence}
        useChevronIcons
        header={
          <div className="fba-header">
            <h3>Consequence Events</h3>
            <p className="mb-0">
              What happened right after the behavior(s) occurred?
            </p>
          </div>
        }
        className="fba-observation-container consequence mb-3"
      >
        <ConsequenceEvents
          observationabc={observation?.observationabc ?? []}
          consequence={consequence}
          setConsequence={setConsequence}
          customConsequence={customConsequence}
          setCustomConsequence={setCustomConsequence}
        />
      </CollapsedContainer>

      <div className="d-flex align-items-center justify-content-center mt-3">
        <button
          className="blueBtnMd"
          onClick={handleBehaviorLog}
          disabled={
            isCreateFbaAbcLoading ||
            isLoading ||
            !antecedentPartCompleted ||
            !behaviorsPartCompleted ||
            !consequencePartCompleted
          }
        >
          Save ABC{" "}
          {(isCreateFbaAbcLoading || isLoading) && (
            <Spinner animation="border" size="sm" />
          )}
        </button>
      </div>
    </div>
  );
};

export default AbcCollapsedPanels;
