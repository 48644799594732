import React, { FunctionComponent, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import _ from "lodash";
import {
  DataPeriod,
  EvidenceColumnGroup,
  EvidencePeriodData,
  EvidenceType,
  MeasurementType,
} from "../../../../../../store/onboarding/types";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { jsPDF } from "jspdf";
// @ts-ignore
import html2pdf from "html2pdf.js";
import { ClassWideNeedsStats } from "./useClassWideNeeds";
import usePDFGenerate from "../../../../../common/pdf-generate/usePDFGenerate";
import useBehaviourClassWideNeeds from "./useBehaviourClassWideNeeds";
import { hidePreClassWideNeedsModal } from "../../../../../../store/onboarding/actions";


const ClassWideNeedNames: { [key: string]: string } = {
  personal_responsibility: "Personal Responsibility",
  optimistic_thinking: "Optimistic Thinking",
  goal_directed_behavior: "Goal-Directed Behavior",
  decision_making: "Decision Making",
  relationship_skills: "Relationship Skills",
  self_awareness: "Self-Awareness",
  self_management: "Self-Management",
  //dessa_sect_score: "Social-Emotional Composite",
  social_awareness: "Social-Awareness",
};

const BehaviourContent: FunctionComponent = () => {

  const { behaviorStats } = useBehaviourClassWideNeeds();
  const dispatch = useDispatch();
  const selectedDataPeriod = useSelector<
    ApplicationState,
    DataPeriod | undefined
  >((s) => s.onboarding.currentDataPeriod);

  const evidencePeriodData = useSelector<
    ApplicationState,
    EvidencePeriodData | undefined
  >((s) => s.onboarding.evidencePeriodData);

  let assessmentName = useMemo(() => {
    let assessmentName = '';
    if(evidencePeriodData) {
      for (let i = 0; i < evidencePeriodData?.evidence_column_groups.length; i++) {
        const evdenceColumnGroup = evidencePeriodData?.evidence_column_groups[i];
        if(!evdenceColumnGroup.is_primary_screening && (evdenceColumnGroup.assessment !== null)) {
          assessmentName = evdenceColumnGroup.assessment.name;
        }
      }
    }
    return assessmentName;
  },[evidencePeriodData])


  useEffect(()=>{
    if(behaviorStats.length) {
      dispatch(hidePreClassWideNeedsModal()); //hide the loader on confirm dialog RC-1205

    }
  },[behaviorStats])
  
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { reportGenerate } = usePDFGenerate();

  const handleReportGenerate = () => {
    const fileName:string = `${selectedDataPeriod?.name}: Class-Wide Needs.pdf`;
    return reportGenerate(contentRef, fileName);
  };

  let statObj:any = {};
  //let studentsArray: any[] = [];
  behaviorStats.map((stat: any) => {
    //studentsArray.push(...stat.students.map((std) => std.unique_id))
    statObj =  {
      ...statObj,
      [stat.teacher.first_name+' '+stat.teacher.last_name] : {
        ...statObj[stat.teacher.first_name+' '+stat.teacher.last_name],
        [(stat.grade) as string]: {
          ...(statObj[stat.teacher.first_name+' '+stat.teacher.last_name] ?
            statObj[stat.teacher.first_name+' '+stat.teacher.last_name][stat.grade as string] :
            statObj[stat.grade as string]),
          [(stat.classInfo) as string]: {
            classWideNeed: stat.classWideNeed,
            students: stat.students
          }
        }
      }
    }
  })

  //console.log(Array.from(new Set(studentsArray)).length);

//  console.log('stats', stats)
  

  return (
    <div>
      {behaviorStats.length ? (
        <>
          <button
            className="float-right whiteBtnSm"
            onClick={handleReportGenerate}
          >
            Generate PDF
          </button>
          <div className="class-wide-need-container">
            <div ref={contentRef}>
              <div
                className="only-for-pdf"
                style={{
                  background: "#00265b",
                  padding: "0.5rem 1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "1rem",
                  borderRadius: "0.5rem",
                }}
              >
                <img
                  src="/media/readycoach-logo.png"
                  alt="ReadyCoach"
                  style={{ height: "2rem" }}
                />
              </div>
              <h2 className="only-for-pdf pdfFontFamily">Class-wide Needs</h2>
              {selectedDataPeriod && (
                <h2 className="mb-3 pdfFontFamily">Data Period: {selectedDataPeriod.name}{assessmentName !== '' ? ', '+assessmentName : ''}</h2>
              )}
              
              {Object.keys(statObj).map((teacher, teacherIndex) => {
                return  (
                  <React.Fragment key={teacherIndex}>
                    {!!teacherIndex && <hr />}
                    <p>
                      {teacher} has a class-wide need for:
                      <> {
                        Object.keys(statObj[teacher]).map((grade, gradeIndex) => 
                        {
                          return (<React.Fragment key={gradeIndex}>
                            <p className="mb-0 pl-2 font-weight-bold">{"Grade - "}{grade}</p>
                              {
                                Object.keys(statObj[teacher][grade]).map((classInfo, classInfoIndex) => {
                                  return(<React.Fragment key={classInfoIndex}>
                                    <p className="mb-0 pl-4 font-weight-bold">{"Class - "}{classInfo.startsWith('Unassigned') ? 'Unassigned' : classInfo}</p>
                                    <ul className="class-wide-need-list">
                                      {Object.keys(statObj[teacher][grade][classInfo].classWideNeed)
                                        .map((classWideNeedKey, classInfoIndex) => {
                                          if((classWideNeedKey != undefined) && ClassWideNeedNames[classWideNeedKey]) {
                                            return (
                                              <li key={classInfoIndex}>
                                                <strong>{ClassWideNeedNames[classWideNeedKey]}</strong>{" "}
                                                intervention
                                                {`(${statObj[teacher][grade][classInfo].classWideNeed[classWideNeedKey].studentsIds.length || 0} of ${statObj[teacher][grade][classInfo].students != undefined ? statObj[teacher][grade][classInfo].students.length : 0} students)`}
                                                {statObj[teacher][grade][classInfo].classWideNeed[classWideNeedKey].skills != undefined && !!statObj[teacher][grade][classInfo].classWideNeed[classWideNeedKey].skills.length && (
                                                  <ul>
                                                    {statObj[teacher][grade][classInfo].classWideNeed[classWideNeedKey].skills.map((s:any, index:number) => (
                                                      <li key={index}>
                                                        {s.display_name} ({s.studentIds.length} of{" "}
                                                        {
                                                          _.chain(statObj[teacher][grade][classInfo].classWideNeed)
                                                            .flatMap(
                                                              ([teacher, values]) => values.studentsIds
                                                            )
                                                            .uniq()
                                                            .value().length
                                                        }{" "}
                                                        diagnosed students out of&nbsp;
                                                        {statObj[teacher][grade][classInfo].students.length})
                                                      </li>
                                                    ))}
                                                  </ul>
                                                )}
                                              </li>
                                            )
                                          }
                                      }
                                        )}
                                    </ul>
                                  </React.Fragment>);
                                })
                              }

                          </React.Fragment>)}
                        )
                      }
                      </>
                    </p>
                  </React.Fragment>
                )
              } 
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faThumbsUp}
            style={{ color: "rgb(139, 195, 74)" }}
          />{" "}
          No class-wide needs
        </div>
      )}
    </div>
  );
};

export default BehaviourContent;
