import { Student } from "../../../../store/onboarding/types";
import { ApplicationState } from "../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  addStudents,
  getStudents,
  hideStudentInviteIndividuallyModal,
} from "../../../../store/onboarding/actions";
import { connect } from "react-redux";
import StudentsInviteIndividuallyModal from "../../../../components/common/onboarding/second-step/students/StudentsInviteIndividuallyModal";

type PropsFromState = {
  isLoading: {
    addStudents: boolean;
  };
  error?: {
    addStudents?: string;
  };
  modalShow: boolean;
};

type DispatchProps = {
  addStudents: (students: Array<Student>) => any;
  onModalHide: () => void;
  getStudents: () => any;
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    modalShow: onboarding.modalsState.studentsIndividuallyInviteModal,
    error: { addStudents: onboarding.errors.addStudents },
    isLoading: {
      addStudents: onboarding.isLoading.addStudents,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      onModalHide: hideStudentInviteIndividuallyModal,
      addStudents: addStudents,
      getStudents: getStudents,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentsInviteIndividuallyModal);
