import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Badge,
  Button,
  Form,
  FormControl,
  OverlayTrigger,
  Popover,
  Spinner,
} from "react-bootstrap";
import { ApplicationState } from "../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  AttachedResource,
  AttachedResourceType,
  AttachedToType,
  InterventionGroup,
} from "../../../store/onboarding/cases/types";
import { push } from "connected-react-router";
import {
  createMeeting,
  updateMeeting,
} from "../../../store/onboarding/meeting-module/actions";
import {
  Meeting,
  MeetingType,
} from "../../../store/onboarding/meeting-module/types";
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faPlusCircle,
  faTimes,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import GroupCountIcon from "../../common/onboarding/third-step/group-students/common/GroupCountIcon";
import { uploadDocument } from "../../../store/onboarding/actions";
import { RouteComponentProps, withRouter } from "react-router";
import InterventionGroupCoachAndTeacher from "../coach-dashboard/tabs/your-assigments/common/InterventionGroupCoachAndTeacher";
import PraiseTeacherModal from "../coach-dashboard/tabs/your-assigments/PraiseTeacherModal";
import useUserRole from "../../../utils/hooks/useUserRole";

type StateProps = {
  interventionGroup?: InterventionGroup;
  // predefinedMeetingType?: MeetingType;
  selectedMeeting?: Meeting;
  tabName:string;
  isLoading: {
    createMeeting: boolean;
    updateMeeting: boolean;
  };
};
type DispatchProps = {
  uploadDocument: (attachedResource: AttachedResource) => any;
  createMeeting: (meeting: Partial<Meeting>) => any;
  updateMeeting: (meeting: Partial<Meeting>) => any;
  push: (location: string) => any;
};
type OwnProps = {};

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;

const MeetingEntryPage: FunctionComponent<Props> = (props, {
  interventionGroup,
  // predefinedMeetingType,
  selectedMeeting,
  isLoading: {
    createMeeting: loadingCreateMeeting,
    updateMeeting: loadingUpdateMeeting,
  },
  uploadDocument,
  createMeeting,
  updateMeeting,
  push,
  history,
}) => {
  /*const options: Array<ReactSelectOption<string>> = useMemo(
    () => [
      {
        label: MeetingTypeDisplayed[MeetingType.PREPARE_FOR_INTERVENTION],
        value: MeetingType.PREPARE_FOR_INTERVENTION,
      },
      {
        label: MeetingTypeDisplayed[MeetingType.FEEDBACK_INTERVENTION],
        value: MeetingType.FEEDBACK_INTERVENTION,
      },
      {
        label: MeetingTypeDisplayed[MeetingType.SUPPORT_INTERVENTION],
        value: MeetingType.SUPPORT_INTERVENTION,
      },
    ],
    [],
  );*/

  // const [meetingType, setMeetingType] = useState<any>(options[0]);
  const [planningNotes, setPlanningNotes] = useState<string | undefined>(
    undefined
  );
  const [meetingNotes, setMeetingNotes] = useState<string | undefined>(
    undefined
  );

  const [modeled, setModeled] = useState<boolean>(false);
  const [practiced, setPracticed] = useState<boolean>(false);

  const [needModeling, setNeedModeling] = useState<boolean>(false);
  const [needPractice, setNeedPractice] = useState<boolean>(false);

  const [otherCheck, setOtherCheck] = useState<boolean>(false);
  const [otherNodes, setOtherNodes] = useState<string | undefined>(undefined);

  const [isCompletingMeeting, setIsCompletingMeeting] = useState<boolean>(
    false
  );

  const [attachments, setAttachments] = useState<Array<AttachedResource>>([]);
  const [documentUploading, setDocumentUploading] = useState<boolean>(false);
  const [showMeetingNotes, setShowMeetingNotes] = useState<boolean>(false);

  const [showPraiseTeacherModal, setShowPraiseTeacherModal] = useState(false);
  const [selectedPraises, setSelectedPraises] = useState<string[]>([]);

  const { isCoach } = useUserRole();

  const fileInputRef = useRef<HTMLInputElement>(null!);

  const handlePlanningNotesChange = (e: React.FormEvent) => {
    const { value } = e.target as HTMLInputElement;
    setPlanningNotes(value);
  };

  const handleMeetingNotesChange = (e: React.FormEvent) => {
    const { value } = e.target as HTMLInputElement;
    setMeetingNotes(value);
  };

  const handleOtherNotesChange = (e: React.FormEvent) => {
    const { value } = e.target as HTMLInputElement;
    setOtherNodes(value);
  };

  /*useEffect(() => {
    if (predefinedMeetingType !== undefined) {
      setMeetingType(options.find(o => o.value === predefinedMeetingType));
    }
  }, [predefinedMeetingType]);*/

  useEffect(() => {
    if (selectedMeeting) {
      /*setMeetingType(
        options.find(o => o.value === selectedMeeting.meeting_type),
      );*/
      setPlanningNotes(selectedMeeting.planning_notes);
      setMeetingNotes(selectedMeeting.meeting_notes);
      setModeled(selectedMeeting.modeled);
      setPracticed(selectedMeeting.practiced);
      setNeedModeling(selectedMeeting.needs_modeling);
      setNeedPractice(selectedMeeting.needs_practice);
      setAttachments(selectedMeeting.attachments || []);
      setShowMeetingNotes(true);
      setSelectedPraises(selectedMeeting.educator_praises || []);
    } else {
      // setMeetingType(options[0]);
      setPlanningNotes(undefined);
      setMeetingNotes(undefined);
      setModeled(false);
      setPracticed(false);
      setNeedModeling(false);
      setNeedPractice(false);
      setAttachments([]);
      setShowMeetingNotes(false);
      setSelectedPraises([]);
    }
  }, [selectedMeeting]);

  const handleBack = () => {
    history.goBack();
    // push(`/cases/groups/${interventionGroup!.id}/meetings`);
  };

  const handleSave = async (completed?: boolean) => {
    setIsCompletingMeeting(!!completed);
    const meeting: Partial<Meeting> = {
      // meeting_type: meetingType.value,
      meeting_type: MeetingType.FEEDBACK_INTERVENTION,
      intervention_group: interventionGroup!.id,
      planning_notes: planningNotes,
      meeting_notes: meetingNotes,
      other_notes: otherCheck ? otherNodes : undefined,
      modeled: modeled,
      practiced: practiced,
      needs_modeling: needModeling,
      needs_practice: needPractice,
      attachments: attachments.filter((a) => a.id).map((a) => a.id) as any,
      educator_praises: selectedPraises,
    };
    const newAttachments = attachments.filter((a) => !a.id);
    if (newAttachments.length) {
      setDocumentUploading(true);
      await Promise.all(newAttachments.map((a) => uploadDocument(a)))
        .then(
          (a) =>
            (meeting.attachments = [
              ...(meeting.attachments || []),
              ...a.map((a) => a.id),
            ] as any),
          (err: string) =>
            toastr.error("Failed to upload the attached files", err)
        )
        .then(() => {
          setDocumentUploading(false);
          setIsCompletingMeeting(false);
        });
    }

    if (selectedMeeting) {
      updateMeeting({ ...selectedMeeting, ...meeting }).then(
        handleBack,
        (err: string) => toastr.error("Error", err)
      );
    } else {
      createMeeting(meeting).then(handleBack, (err: string) =>
        toastr.error("Error", err)
      );
    }
  };

  const onAttachmentRemove = (index: number) => () => {
    setAttachments((attachments) =>
      attachments.filter((a, idx) => idx !== index)
    );
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList) {
      const files: Array<File> = Array.from(fileList);
      const attachedResources: Array<AttachedResource> = files.map((file) => ({
        attached_to_type: AttachedToType.INTERVIEW,
        resource_type: AttachedResourceType.FILE,
        attachment: file,
        title: file.name,
        mime_type: file.type,
      }));
      setAttachments((attachments) => [...attachments, ...attachedResources]);
    }
  };

  const interventionGroupSummaryInfo = useMemo(() => {
    if (!interventionGroup) return null;
    if (
      !interventionGroup.student_goals ||
      !interventionGroup.student_goals.length
    ) {
      return (
        <div
          className="d-flex align-items-center w-100"
          style={{ marginLeft: -20 }}
        >
          <GroupCountIcon
            svgSize={80}
            studentsNum={interventionGroup.students.length}
          />
          <div className="align-items-center">
            <h3 className="font-weight-bold d-inline">
              This group doesn't have any goals yet
            </h3>
            <h4 className="mb-0">Let's set goals for the students.</h4>
          </div>
        </div>
      );
    }

    if (
      !interventionGroup.interventions ||
      !interventionGroup.interventions.length
    ) {
      return (
        <div className="d-flex align-items-center w-100">
          <GroupCountIcon
            svgSize={80}
            studentsNum={interventionGroup.students.length}
          />
          <div className="align-items-center">
            <h3 className="font-weight-bold d-inline">
              This group doesn't have an Intervention yet
            </h3>
            <h4 className="mb-0">
              Let's set goals for the students. ReadyCoach will help you
              identify and select interventions.
            </h4>
          </div>
          <button className="blueBtnSm" style={{ margin: "0 10px 0 auto" }}>
            Find an intervention
          </button>
        </div>
      );
    }

    return (
      <div className="d-flex align-items-center w-100">
        <GroupCountIcon
          svgSize={80}
          studentsNum={interventionGroup.students.length}
        />
        <div className="align-items-center">
          <div>
            {interventionGroup.interventions.map((intervention:any) => (
              <h3
                className="font-weight-bold d-inline-block mr-3"
                key={intervention.id}
              >
                {intervention.name}{" "}
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Popover id={"interventionDescription"}>
                      <Popover.Content>
                        {intervention.description}
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="pointer" />
                </OverlayTrigger>
              </h3>
            ))}
          </div>
          <h4 className="mb-0">
            You can go to the Dashboard to prepare for implementation.
          </h4>
          {/* <small>
            <i>This intervention modified from a ReadyCoach intervention.</i>
          </small> */}
        </div>
      </div>
    );
  }, [interventionGroup]);

  if (!interventionGroup) {
    push("/cases/"+props.tabName);
    return null;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 20,
          borderRadius: 8,
          overflow: "hidden",
        }}
      >
        <div style={{ flex: 1 }}>
          <div className="onboardingContainer">
            <div className="d-flex align-items-center justify-content-between">
              <InterventionGroupCoachAndTeacher
                interventionGroup={interventionGroup}
              />

              <button className="blueBtnLg mr-3" onClick={handleBack}>
                Back to Dashboard
              </button>
            </div>
            <div className="padding10">{interventionGroupSummaryInfo}</div>
          </div>
        </div>
      </div>
      <div className="onboardingContainer">
        <div>
          {/*<div style={{ width: "300px" }}>
            <Select
              value={meetingType}
              options={options}
              onChange={setMeetingType}
            />
          </div>
          <hr />*/}
          {isCoach && (
            <div className="d-flex flex-column align-items-start">
              <button
                className="blueBtnSm"
                onClick={() => setShowPraiseTeacherModal(true)}
              >
                Praise educator
              </button>
              {selectedPraises.map((praise) => (
                <Badge pill key={praise} variant="secondary" className="my-1">
                  {praise}
                </Badge>
              ))}
            </div>
          )}
          <hr />
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label className="font-weight-bold">Planning Notes</Form.Label>
            <Form.Control
              as="textarea"
              value={planningNotes || ""}
              onChange={handlePlanningNotesChange}
            />
          </Form.Group>

          {showMeetingNotes ? (
            <div>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label className="font-weight-bold">
                  Meeting Notes
                </Form.Label>
                <Form.Control
                  as="textarea"
                  value={meetingNotes || ""}
                  onChange={handleMeetingNotesChange}
                />
              </Form.Group>
              <div className="m-3">
                <Form.Check
                  id="isModelingThis"
                  checked={modeled}
                  onChange={() => setModeled((b) => !b)}
                  label="We modeled during this meeting"
                  custom
                  type="checkbox"
                />
                <Form.Check
                  id="isPracticeThis"
                  checked={practiced}
                  onChange={() => setPracticed((b) => !b)}
                  label="We practiced during this meeting"
                  custom
                  type="checkbox"
                />
                <Form.Check
                  id="isModelingNext"
                  checked={needModeling}
                  onChange={() => setNeedModeling((b) => !b)}
                  label="We need to model during our next meeting"
                  custom
                  type="checkbox"
                />
                <Form.Check
                  id="isPracticeNext"
                  checked={needPractice}
                  onChange={() => setNeedPractice((b) => !b)}
                  label="We need to practice during our next meeting"
                  custom
                  type="checkbox"
                />
                <div className="d-flex text-center">
                  <Form.Check
                    className="mr-2"
                    id="other"
                    checked={otherCheck}
                    onChange={() => {
                      setOtherCheck((b) => !b);
                    }}
                    label="Other"
                    custom
                    type="checkbox"
                  />
                  <FormControl
                    as="textarea"
                    value={otherNodes || ""}
                    onChange={handleOtherNotesChange}
                    disabled={!otherCheck}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <h4 className="font-weight-bold">Meeting Notes</h4>
              <p className="mb-0">
                Click the plus icon to log meeting notes now. Or save the
                meeting and log them later.
              </p>
              <Button
                onClick={() => setShowMeetingNotes(true)}
                title="Add Meeting Notes"
                className="mb-2"
              >
                <FontAwesomeIcon icon={faPlusCircle} className="pointer" />
              </Button>
            </>
          )}

          <div>
            <h4 className="font-weight-bolder">Attach files</h4>
            <div>
              {attachments.map((attachment, index) => (
                <div className="fileCell" key={attachment.id || index}>
                  <div className="fileCellName">{attachment.title}</div>
                  <div
                    className="fileCellRemove"
                    onClick={onAttachmentRemove(index)}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      size="1x"
                      style={{ color: "#ffffff" }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <hr />
            <button
              className="whiteBtnMd2"
              onClick={() => fileInputRef.current.click()}
            >
              <FontAwesomeIcon icon={faUpload} size="lg" /> Pick a file
            </button>
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={onFileChange}
            />
          </div>

          <div className="d-flex justify-content-end">
            <button className="blueBtnSm mr-5" onClick={() => handleSave()}>
              {`${selectedMeeting ? "Update" : "Save"} & Continue later`}&nbsp;
              {!isCompletingMeeting &&
                (loadingCreateMeeting ||
                  loadingUpdateMeeting ||
                  documentUploading) && (
                  <Spinner animation="border" size="sm" />
                )}
            </button>
            <button className="blueBtnSm" onClick={() => handleSave(true)}>
              Complete Meeting&nbsp;
              {isCompletingMeeting &&
                (loadingCreateMeeting ||
                  loadingUpdateMeeting ||
                  documentUploading) && (
                  <Spinner animation="border" size="sm" />
                )}
            </button>
          </div>
        </div>
      </div>

      <PraiseTeacherModal
        educator={interventionGroup.teacher_assignment?.user}
        showModal={showPraiseTeacherModal}
        selectedPraises={selectedPraises}
        onModalHide={() => setShowPraiseTeacherModal(false)}
        onSave={(praises) => setSelectedPraises(praises)}
      />
    </>
  );
};

const mapStateToProps = ({
  cases,
  meetingModuleReducer,
}: ApplicationState): StateProps => {
  return {
    interventionGroup: cases.selectedInterventionGroup,
    selectedMeeting: meetingModuleReducer.selectedMeeting,
    tabName: cases.tabName,
    // predefinedMeetingType: meetingModuleReducer.predefinedMeetingType,
    isLoading: {
      createMeeting: meetingModuleReducer.isLoading.createMeeting,
      updateMeeting: meetingModuleReducer.isLoading.updateMeeting,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      createMeeting: createMeeting,
      updateMeeting: updateMeeting,
      uploadDocument: uploadDocument,
      push: push,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MeetingEntryPage));
