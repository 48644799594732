import React, { FunctionComponent, useMemo } from "react";
import {
  ColorCriteria,
  EvidenceColumn,
  MeasurementType,
} from "../../../../../../store/onboarding/types";
import ColorColorCriteriaRow from "./ColorColorCriteriaRow";
import Table from "react-bootstrap/Table";
import { getStudentsNum } from "./helpers";
import { Grade } from "../../../../../../store/groups/types";

type Props = {
  measurementType?: MeasurementType;
  allColorCriteria: any[];
  evidenceColumn?: EvidenceColumn;
  selectedGrade?: Grade;
  withoutStats?: boolean;
};

const ColorCriteriaTable: FunctionComponent<Props> = (props) => {
  const {
    measurementType,
    allColorCriteria,
    evidenceColumn,
    selectedGrade,
    withoutStats,
  } = props;

  const getStudentsNumByColorCriteria = evidenceColumn
    ? (colorCriteria: ColorCriteria) =>
        getStudentsNum(evidenceColumn, colorCriteria, selectedGrade)
    : null;

  const totalStudents = useMemo(() => {
    if (!getStudentsNumByColorCriteria) {
      return null;
    }

    return allColorCriteria.reduce<number>(
      (pV, cV) => pV + getStudentsNumByColorCriteria(cV),
      0
    );
  }, [allColorCriteria]);

  let isCustomColorExist = false;
  allColorCriteria.map((ac) => {
    if (ac?.user_id) {
      isCustomColorExist = true;
      return;
    }
  });

  return (
    <Table className="viewBenchmarksTable mb-0">
      <thead>
        <tr>
          <th>Label</th>
          {measurementType !== MeasurementType.CATEGORY && <th>Range</th>}
          {!withoutStats && (
            <>
              <th># of Students</th>
              <th>% of Students</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {allColorCriteria.length ? (
          allColorCriteria.map((colorCriteria: ColorCriteria, index) => (
            <>
              {isCustomColorExist && colorCriteria?.user_id && (
                <ColorColorCriteriaRow
                  key={index}
                  measureType={measurementType}
                  colorCriteria={colorCriteria}
                  totalStudents={totalStudents}
                  getStudentsNumByColorCriteria={getStudentsNumByColorCriteria}
                  withoutStats={withoutStats}
                />
              )}
              {!isCustomColorExist && !colorCriteria?.user_id && (
                <ColorColorCriteriaRow
                  key={index}
                  measureType={measurementType}
                  colorCriteria={colorCriteria}
                  totalStudents={totalStudents}
                  getStudentsNumByColorCriteria={getStudentsNumByColorCriteria}
                  withoutStats={withoutStats}
                />
              )}
            </>
          ))
        ) : (
          <tr>
            <td colSpan={withoutStats ? 2 : 4}>No Data</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default ColorCriteriaTable;
