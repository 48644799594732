import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Student } from "../../../../../store/onboarding/types";
import React, { Component } from "react";
import { Button, Col, Modal, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { toastr } from "react-redux-toastr";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

type PropsFromState = {
  isLoading: {
    addStudents: boolean;
  };
  error?: {
    addStudents?: string;
  };
  modalShow: boolean;
};

type DispatchProps = {
  addStudents: (students: Array<Student>) => any;
  onModalHide: () => void;
  getStudents: () => any;
};

type Props = PropsFromState & DispatchProps;

type State = {
  students: Array<Student>;
};

class StudentsInviteIndividuallyModal extends Component<Props, State> {
  emptyStudent: Student = {
    first_name: "",
    last_name: "",
    unique_id: "",
  };

  state: Readonly<State> = {
    students: [],
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (prevProps.isLoading.addStudents && !this.props.isLoading.addStudents) {
      if (this.props.error!.addStudents) {
        toastr.error("Error", this.props.error!.addStudents);
      } else {
        this.props.onModalHide();
      }
    }
  }

  handleOnEnter = () => {
    this.setState({
      students: [this.emptyStudent],
    });
  };

  handleAddStudent = () => {
    this.setState((prevState) => ({
      ...prevState,
      students: [...prevState.students, this.emptyStudent],
    }));
  };

  handleStudentChange = (selectedIndex: number) => (
    event: React.FormEvent<any>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    this.setState((state) => ({
      ...state,
      students: state.students.map((student: Student, index) => {
        if (index === selectedIndex) {
          return {
            ...student,
            [name]: value,
          };
        }
        return student;
      }),
    }));
  };

  handleStudentsAdd = (event: React.FormEvent<any>) => {
    event.preventDefault();
    this.props.addStudents(this.state.students);
  };

  handleModalClose = () => {
    const { getStudents, onModalHide, isLoading } = this.props;
    if (!isLoading.addStudents) {
      getStudents();
      onModalHide();
    }
  };

  handleRemove = (recIndex:number) => {
    const students = this.state.students;
    this.setState((prevState) => ({
      ...prevState,
      students: [...students.filter((student, index) => recIndex !== index)],
    }));
  };

  render() {
    const { students } = this.state;
    return (
      //@ts-ignore
      <Modal
        animation={false}
        backdropClassName="customDarkModalBackdrop in"
        show={this.props.modalShow}
        //@ts-ignore
        onShow={this.handleOnEnter}
        onHide={this.handleModalClose}
        size="lg"
      >
        <Modal.Header
          closeButton
          className="purpleModalHeader orangeModalHeader"
        >
          <Modal.Title>Add Individually</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h3>
            These students will be added to your district but not assigned to
            any educators. You can assign them to educators by viewing the
            educator’s details in the Educators tab.
          </h3>
          <br />
          <div className="inviteContainer">
            <Form id="newInviteForm" onSubmit={this.handleStudentsAdd}>
              {students.map((student, index) => (
                <Form.Row key={index.toString()}>
                  <Col>
                    <Form.Control
                      required
                      placeholder="Enter first name..."
                      name="first_name"
                      onChange={this.handleStudentChange(index)}
                      value={student.first_name}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      required
                      placeholder="Enter last name..."
                      name="last_name"
                      onChange={this.handleStudentChange(index)}
                      value={student.last_name}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      required
                      placeholder="Enter Student ID number..."
                      name="unique_id"
                      onChange={this.handleStudentChange(index)}
                      value={student.unique_id}
                    />
                  </Col>
                  {(index > 0) ? <Col md={"1"} className="mt-2 d-flex w-20">
                    <span className="mx-1 removeIconBtn" onClick={() => this.handleRemove(index)}>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        color="red"
                        size="lg"
                        title="Remove"
                        /></span>
                    </Col> 
                  : <Col md={"1"}></Col>}
                </Form.Row>
              ))}
            </Form>
          </div>

          <div className="btnActions">
            <div />
            <Button
              bsPrefix="b"
              className="blueBtnSm"
              onClick={this.handleAddStudent}
            >
              Add another
            </Button>
          </div>

          <hr className="narrowMargin" />
          <div className="modalActions" style={{ marginTop: "20px" }}>
            <div className="leftActions">
              <Button
                bsPrefix="b"
                disabled={this.props.isLoading.addStudents}
                onClick={this.props.onModalHide}
                className="blueBtnSm"
              >
                Cancel
              </Button>
            </div>
            <div className="rightActions">
              <button form="newInviteForm" type="submit" className="blueBtnSm">
                Add students and Close{" "}
                {this.props.isLoading.addStudents && (
                  <Spinner animation="border" size="sm" />
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default StudentsInviteIndividuallyModal;
