import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import Joyride, { CallBackProps, Step } from "react-joyride";
import { ApplicationState } from "../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  changeSelectedInterventionGroupId,
  markFidelityChartTourAsShown,
} from "../../../../store/tour/actions";
import { connect } from "react-redux";
import { InterventionGroup } from "../../../../store/onboarding/cases/types";
import { interventionGroupHasEnoughData } from "../../../utils";

type StateProps = {
  fidelityChartTourShown?: boolean;
  interventionFidelityTourShown?: boolean;
  selectedInterventionGroupId?: number;
  interventionGroups: Array<InterventionGroup>;
};
type DispatchProps = {
  markFidelityChartTourAsShown: () => any;
  changeSelectedInterventionGroupId: (intGroupId?: number) => any;
};

type Props = StateProps & DispatchProps;

const FidelityChartTour: FunctionComponent<Props> = (props) => {
  const groupWithMonitoringStageWithData:
    | InterventionGroup
    | undefined = useMemo(() => {
    return props.interventionGroups.find(interventionGroupHasEnoughData);
  }, [props.interventionGroups]);

  const steps: Step[] = useMemo(
    () => [
      {
        disableBeacon: true,
        target: `${
          props.selectedInterventionGroupId
            ? `#intervention_group_${props.selectedInterventionGroupId}`
            : ""
        } .fidelityLoggedDataChartMini`,
        content: (
          <>
            Your data will show inline here, but you can click this graph for a
            larger view and more details about the fidelity and goal progress.
          </>
        ),
      },
    ],
    [props.selectedInterventionGroupId]
  );

  const [runTour, setRunTour] = useState(false);

  useEffect(() => {
    if (
      !!props.interventionFidelityTourShown &&
      !props.fidelityChartTourShown &&
      groupWithMonitoringStageWithData
    ) {
      props.changeSelectedInterventionGroupId(
        groupWithMonitoringStageWithData.id
      );
    } else {
      setRunTour(false);
    }
  }, [
    props.interventionFidelityTourShown,
    props.fidelityChartTourShown,
    groupWithMonitoringStageWithData,
  ]);

  useEffect(() => {
    if (
      !!props.interventionFidelityTourShown &&
      !props.fidelityChartTourShown &&
      groupWithMonitoringStageWithData &&
      props.selectedInterventionGroupId
    ) {
      setTimeout(() => setRunTour(true), 500);
    }
  }, [props.selectedInterventionGroupId]);

  const handleTourChange = (data: CallBackProps) => {
    if (["skip", "reset"].includes(data.action)) {
      props.markFidelityChartTourAsShown();
    }
  };

  return (
    <Joyride
      steps={steps}
      callback={handleTourChange}
      floaterProps={{ disableAnimation: true }}
      disableScrolling
      showSkipButton
      run={runTour}
      styles={{
        options: {
          primaryColor: "#00265B",
        },
      }}
    />
  );
};

const mapStateToProps = ({ tour, cases }: ApplicationState): StateProps => {
  return {
    fidelityChartTourShown: tour.fidelityChartTourShown,
    interventionFidelityTourShown: tour.interventionFidelityTourShown,
    interventionGroups: cases.interventionGroups,
    selectedInterventionGroupId: tour.selectedInterventionGroupId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      markFidelityChartTourAsShown: markFidelityChartTourAsShown,
      changeSelectedInterventionGroupId: changeSelectedInterventionGroupId,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FidelityChartTour);
