import React, { FormEvent, useMemo, useState } from "react";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { UserInfo } from "../../../../../store/auth/types";
import { Form, Modal, Spinner } from "react-bootstrap";
import {
  addStudentToParentRequest,
  changeSelectedStudent,
  getStudents,
  hideInviteParentModal,
  inviteParentRequest,
  setSelectedParent,
} from "../../../../../store/onboarding/actions";
import ModalCloseButton from "../../third-step/group-students/common/ModalCloseButton";
import {
  InviteParentRequest,
  Student,
  StudentInfo,
} from "../../../../../store/onboarding/types";
import Select from "react-select";
import { toastr } from "react-redux-toastr";

type PropsFromState = {
  showModal: boolean;
  existingUser?: UserInfo;
  studentsRoster: StudentInfo[];
  selectedStudent?: Student;
  isLoading: {
    getStudents: boolean;
    inviteParent?: boolean;
    addStudentToParent?: boolean;
  };
};

type DispatchProps = {
  hide: () => any;
  sendInvite: (req: InviteParentRequest) => any;
  setSelectedParent: (parent: UserInfo | undefined) => any;
  getStudents: () => any;
  changeSelectedStudent: (selectedStudent?: Student) => any;
  addStudentToParent: (children: number[], parentId: number) => any;
};

type OwnProps = {};

type Props = OwnProps & PropsFromState & DispatchProps;

const ParentModalForm: React.FC<Props> = ({
  existingUser,
  showModal,
  studentsRoster,
  selectedStudent,
  hide,
  sendInvite,
  setSelectedParent,
  getStudents,
  isLoading,
  changeSelectedStudent,
  addStudentToParent,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [childrenIds, setChildrenIds] = useState<Array<number>>([]);

  const handleOpen = () => {
    if (existingUser) {
      setFirstName(existingUser.first_name);
      setLastName(existingUser.last_name);
      setEmail(existingUser.email);
      setChildrenIds(
        existingUser?.children?.map((student) => student.id!) ?? []
      );
    } else {
      setFirstName("");
      setLastName("");
      setEmail("");
      setChildrenIds(selectedStudent ? [selectedStudent.id!] : []);
    }

    getStudents();
  };

  const handleHide = () => {
    setSelectedParent(undefined);
    changeSelectedStudent();
    hide();
  };

  const handleSendInvite = () => {
    sendInvite({
      first_name: firstName,
      last_name: lastName,
      email: email,
      children: childrenIds,
    }).then(
      () => {
        handleHide();
      },
      (err: string) =>
        toastr.error(`Failed to invite ${firstName} ${lastName}`, err)
    );
  };

  const handleUpdateChildrenList = () => {
    if (existingUser) {
      addStudentToParent(childrenIds, existingUser.id).then(
        () => {
          handleHide();
        },
        (err: string) => toastr.error(`Failed to update children list`, err)
      );
    }
  };

  const isDisabled = existingUser !== undefined;

  const studentOptions = useMemo(
    () =>
      studentsRoster.map((student) => {
        return {
          value: student.id!,
          label: `${student.first_name} ${student.last_name}`,
        };
      }),
    [studentsRoster]
  );

  return (
    <Modal
      onShow={handleOpen}
      onHide={handleHide}
      animation={false}
      backdropClassName="customDarkModalBackdrop in"
      show={showModal}
      size="lg"
    >
      <Modal.Header
        className="purpleModalHeader centerModalHeader"
        style={{ borderBottom: 0 }}
      >
        <div />
        {existingUser === undefined ? (
          <Modal.Title>Invite a Parent to ReadyCoach</Modal.Title>
        ) : (
          <Modal.Title>Parent Details</Modal.Title>
        )}
        <ModalCloseButton onClose={handleHide} />
      </Modal.Header>
      <Modal.Body>
        <Form className="d-flex" id="inviteParentForm">
          {existingUser === undefined ? (
            <h3>
              Inviting a Parent to ReadyCoach allows them to view intervention
              plans for their children.
            </h3>
          ) : (
            <h3>
              {existingUser.first_name} was invited to ReadyCoach. You can add
              or remove children whose data they have access to below.
            </h3>
          )}
          <div className="parentInviteFormContainer">
            <Form.Label>
              <strong>First Name</strong>
              <Form.Control
                style={{ minWidth: "250px" }}
                value={firstName}
                disabled={isDisabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFirstName(e.currentTarget.value)
                }
                required
              />
            </Form.Label>

            <Form.Label>
              <strong>Last Name</strong>
              <Form.Control
                style={{ minWidth: "250px" }}
                value={lastName}
                disabled={isDisabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLastName(e.currentTarget.value)
                }
                required
              />
            </Form.Label>

            <Form.Label>
              <strong>Email</strong>
              <Form.Control
                type="email"
                style={{ minWidth: "250px" }}
                value={email}
                disabled={isDisabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.currentTarget.value)
                }
                required
              />
            </Form.Label>
          </div>
          <h3>Select children</h3>
          <Select
            isMulti
            onChange={(options: any) =>
              setChildrenIds(options ? options.map((op: any) => op.value) : [])
            }
            options={studentOptions}
            value={studentOptions.filter((option) =>
              childrenIds.includes(option.value)
            )}
            isLoading={isLoading.getStudents}
            styles={{
              container: (base) => {
                return { ...base, marginBottom: 20 };
              },
            }}
          />

          {!isDisabled ? (
            <button
              className="blueBtnLg"
              onClick={(e) => {
                e.preventDefault();
                handleSendInvite();
              }}
            >
              Send Invite
              {isLoading.inviteParent && (
                <Spinner animation="border" size="sm" className="ml-1" />
              )}
            </button>
          ) : (
            <button
              className="blueBtnLg"
              onClick={(e) => {
                e.preventDefault();
                handleUpdateChildrenList();
              }}
            >
              Save
              {isLoading.addStudentToParent && (
                <Spinner animation="border" size="sm" className="ml-1" />
              )}
            </button>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    showModal: onboarding.modalsState.inviteParentModal,
    existingUser: onboarding.selectedParent,
    isLoading: {
      getStudents: onboarding.isLoading.getStudents,
      addStudentToParent: onboarding.isLoading.addStudentToParent,
      inviteParent: onboarding.isLoading.inviteParent,
    },
    studentsRoster: onboarding.studentsRoster,
    selectedStudent: onboarding.selectedStudent,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hide: hideInviteParentModal,
      sendInvite: inviteParentRequest,
      setSelectedParent: setSelectedParent,
      getStudents: getStudents,
      changeSelectedStudent: changeSelectedStudent,
      addStudentToParent: addStudentToParentRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParentModalForm);
