import React, { ReactNode } from "react";

const FontAwesomeIconDropDown = (component: ReactNode, isUsed?:boolean) =>
  React.forwardRef((props: any, ref: any) => (
    <div
    ref={ref}
    onClick={(e: any) => {
      e.preventDefault();
      props.onClick(e);
    }}
    >
      {component}
      {isUsed !== false && 
      <div className="dataColOptions">
        <div />
      </div>
      }
      {props.children}
    </div>
  ));

export default FontAwesomeIconDropDown;
