import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
  Overlay,
  OverlayTrigger,
  Popover,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { getStudents } from "../../../../../../store/onboarding/actions";
import Select from "react-select";
import {
  Student,
  StudentClass,
  StudentInfo,
} from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { ValueType } from "react-select/src/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";
import { UserInfo } from "../../../../../../store/auth/types";
import { GroupRecommendation } from "../../../../../../store/onboarding/cases/types";
import { changeRecommendedGroup } from "../../../../../../store/onboarding/cases/actions";

type OwnProps = {
  target: any;
  selectedStudents: Student[];
  onAdd: (student: Student, teacher?: UserInfo) => void;
  btnText: string;
  title: string;
  show: boolean;
  setShow: (flag: boolean) => any;
  placement?: any;
  loading?: boolean;
  withTeacher?: boolean;
  group?: GroupRecommendation;
  fromRecommendation?: boolean;
};

type Props = OwnProps;

const AddStudentOverlay: FunctionComponent<Props> = (props) => {
  const {
    show,
    setShow,
    target,
    selectedStudents,
    onAdd,
    btnText,
    title,
    placement,
    loading,
    withTeacher,
    group,
    fromRecommendation
  } = props;
  const dispatch = useDispatch();

  const [selectedRecommendedStudent, setSelectedRecommendedStudent] = useState<
    StudentInfo[]
  >([]);
  const [selectedStudent, setSelectedStudent] = useState<
    StudentInfo | undefined
  >(undefined);

  const students = useSelector(
    (s: ApplicationState) => s.onboarding.studentsRoster
  );

  const isLoading = useSelector(
    (s: ApplicationState) => s.onboarding.isLoading.getStudents
  );

  const studentOptions = useMemo(() => {
    return students.filter(
      (s) => !selectedStudents.some((ss) => ss.id === s.id)
    );
  }, [students, selectedStudents]);

  const handleSelectStudent = (value: ValueType<StudentInfo, false>) => {
    setSelectedStudent(value as StudentInfo);
    setSelectedTeacher(undefined);
  };

  const handleSelectRecommendedStudent = (value: ValueType<StudentInfo, any>) => {
    setSelectedRecommendedStudent((value as StudentInfo[]) ?? []);
    setSelectedTeacher(undefined);
  };

  const [selectedTeacher, setSelectedTeacher] = useState<any>(undefined);

  const handleSelectTeacher = (value: ValueType<UserInfo, false>) => {
    setSelectedTeacher(value as UserInfo);
  };

  const handleAddStudent = () => {
    if (selectedStudent) {
      const { classes, ...student } = selectedStudent;
      onAdd(student, selectedTeacher);
      // setSelectedStudent(undefined);
    }
    if(selectedRecommendedStudent && !withTeacher) {
      dispatch(
        changeRecommendedGroup(group!, {
          ...group!,
          students: [...selectedRecommendedStudent, ...group!.students],
        })
      );
      setShow(false);
      setSelectedRecommendedStudent([]);
    }
  };

  const formatOptionLabel = (student: StudentInfo) => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div>{getFullName(student)}</div>
        {(!!student.classes?.length || !!student.teachers?.length) && (
          <OverlayTrigger
            container={document.body}
            placement="top"
            overlay={
              <Tooltip id="fullName" className="customInfoTooltip text-left">
                <>
                  <strong>Teachers: </strong>
                  {student.teachers
                    ?.map((teacher) => getFullName(teacher))
                    .join(", ") || <i>None</i>}
                  <br />
                </>
                <>
                  <strong>Classes: </strong>
                  {student.classes?.map((c: any) => c.name).join(", ") || (
                    <i>None</i>
                  )}
                </>
              </Tooltip>
            }
          >
            <FontAwesomeIcon className="ml-1" icon={faChalkboardTeacher} />
          </OverlayTrigger>
        )}
      </div>
    );
  };

  const filterOption = (
    { label, value, data }: { label: string; value: string; data: StudentInfo },
    query: string
  ): boolean => {
    const queryInLowerCase = query.toLowerCase();
    return (
      !queryInLowerCase.length ||
      label.toLowerCase().includes(queryInLowerCase) ||
      data.teachers.some((t) =>
        getFullName(t)
          .toLowerCase()
          .includes(queryInLowerCase)
      ) ||
      !!data.classes?.some((c: StudentClass) =>
        c.name.toLowerCase().includes(queryInLowerCase)
      )
    );
  };

  return (
    <Overlay
      target={target.current}
      show={show}
      placement={placement}
      rootClose
      rootCloseEvent={"click"}
      onEnter={() => {
        dispatch(getStudents(true));
      }}
      onHide={() => {
        setShow(false);
        setSelectedStudent(undefined)
        fromRecommendation ? setSelectedRecommendedStudent([]) : '';
        setSelectedTeacher(undefined);
      }}
    >
      <Popover id="selectStudentsPopover">
        <Popover.Title>{title}</Popover.Title>
        <Popover.Content>
          {fromRecommendation ? 
            <Select
              isMulti
              value={selectedRecommendedStudent}
              onChange={handleSelectRecommendedStudent}
              placeholder="Search Students..."
              getOptionLabel={(student: any) => getFullName(student)}
              getOptionValue={(student: any) => student.id!.toString()}
              options={studentOptions as any}
              formatOptionLabel={formatOptionLabel}
              filterOption={filterOption}
              isLoading={isLoading}
            />
            : 
            <Select
              value={selectedStudent}
              onChange={handleSelectStudent}
              placeholder="Search Students..."
              getOptionLabel={(student: StudentInfo) => getFullName(student)}
              getOptionValue={(student: StudentInfo) => student.id!.toString()}
              options={studentOptions}
              formatOptionLabel={formatOptionLabel}
              filterOption={filterOption}
              isLoading={isLoading}
            />
          }

          {selectedStudent && withTeacher && (
            <div className="mt-2">
              {selectedStudent?.teachers.length ? (
                <Select
                  value={selectedTeacher}
                  onChange={handleSelectTeacher}
                  placeholder="Select Teacher..."
                  getOptionLabel={(teacher: UserInfo) => getFullName(teacher)}
                  getOptionValue={(teacher: UserInfo) => teacher.id!.toString()}
                  options={selectedStudent?.teachers || []}
                />
              ) : (
                <i>This student has no assigned teachers</i>
              )}
            </div>
          )}

          <button
            className="mt-2 blueBtnSm w-100"
            disabled={!selectedRecommendedStudent || (withTeacher && !selectedTeacher)}
            onClick={handleAddStudent}
          >
            {btnText} {loading && <Spinner animation="border" size="sm" />}
          </button>
        </Popover.Content>
      </Popover>
    </Overlay>
  );
};

export default AddStudentOverlay;
