import React, { FunctionComponent, useMemo } from "react";
import { ChatThread } from "../../../../store/chat/types";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedChatThread,
  deleteChatThread,
} from "../../../../store/chat/actions";
import ChatGroupThreadName from "./ChatGroupThreadName";
import ChatIndividualThreadName from "./ChatIndividualThreadName";
import { ApplicationState } from "../../../../store";

type OwnProps = {
  chatThread: ChatThread;
};

type Props = OwnProps;

const ChatThreadName: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const { chatThread } = props;

  const selectedThread = useSelector<ApplicationState, ChatThread | undefined>(
    (s) => s.chat.selectedThread
  );

  const isActive = useMemo(() => {
    return selectedThread && selectedThread.id === chatThread.id;
  }, [selectedThread?.id, chatThread.id]);

  const openChatThreadChat = (chatThread: ChatThread) => {
    dispatch(changeSelectedChatThread(chatThread));
  };

  const handleDeleteChatThread = () => {
    dispatch(deleteChatThread(chatThread.id!));
    if (selectedThread && chatThread.id === selectedThread?.id) {
      dispatch(changeSelectedChatThread(undefined));
    }
  };

  return (
    <div onClick={() => openChatThreadChat(chatThread)} className="pointer">
      <div className={`chat-thread-name ${isActive ? "active" : ""}`}>
        {chatThread.intervention_group ? (
          <ChatGroupThreadName
            chatThread={chatThread}
            onCloseChatThread={handleDeleteChatThread}
          />
        ) : (
          <ChatIndividualThreadName
            chatThread={chatThread}
            onCloseChatThread={handleDeleteChatThread}
          />
        )}
      </div>
      <hr className="m-0" />
    </div>
  );
};

export default ChatThreadName;
