import React, { FunctionComponent, useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { SkillFocusArea } from "../../../../../../../../store/groups/types";
import { ValueType } from "react-select/src/types";
import { ReactSelectOption } from "../../../../../../../../store/onboarding/types";
import { useDispatch, useSelector } from "react-redux";
import { getSkillFocuses } from "../../../../../../../../store/groups/actions";
import { ApplicationState } from "../../../../../../../../store";
import _ from "lodash";

type OwnProps = {
  readingSkillTypes: string[];
  setReadingSkillTypes: React.Dispatch<React.SetStateAction<string[]>>;
  readingSkills: string[];
  setReadingSkills: React.Dispatch<React.SetStateAction<string[]>>;
  onClickNext: () => void;
};

type Props = OwnProps;

const ReadingSkillsForm: FunctionComponent<Props> = (props) => {
  const {
    readingSkillTypes,
    setReadingSkillTypes,
    readingSkills,
    setReadingSkills,
    onClickNext,
  } = props;

  const dispatch = useDispatch();

  const areas = useSelector<ApplicationState, SkillFocusArea[]>(
    (s) => s.groups.skillFocusInfo
  );

  useEffect(() => {
    dispatch(getSkillFocuses());
  }, []);

  const skillTypeOptions = useMemo(() => {
    return areas.map((area) => ({
      label: area.display_name,
      value: area.identifier,
    }));
  }, [areas]);
  const selectedReadingSkillTypes = useMemo(() => {
    return readingSkillTypes.length
      ? readingSkillTypes.map(
          (rsType) => skillTypeOptions.find((t) => t.value === rsType)!
        )
      : [];
  }, [readingSkillTypes, skillTypeOptions]);
  const handleReadingSkillTypeChange = (
    value: ValueType<ReactSelectOption<string>, true>
  ) => {
    setReadingSkillTypes(value?.map((v) => v.value) ?? []);
    setReadingSkills([]);
  };

  const skillsOptions = useMemo(() => {
    return _.chain(areas)
      .filter((area) => readingSkillTypes.includes(area.identifier))
      .flatMap(
        (area) =>
          area.skill_focuses?.flatMap((sf) => [
            {
              label: sf.display_name,
              value: sf.identifier,
            },
            ...(sf.specifics?.map((s) => ({
              label: s.display_name,
              value: s.identifier,
            })) ?? []),
          ]) ?? []
      )
      .value();
  }, [areas, readingSkillTypes]);
  const selectedSkills = useMemo(() => {
    return readingSkills.map(
      (skill) => skillsOptions.find((so) => so.value === skill)!
    );
  }, [readingSkills, skillsOptions]);
  const handleReadingSkillsChange = (
    values: ValueType<ReactSelectOption<string>, true>
  ) => {
    setReadingSkills(values?.map((v) => v.value) ?? []);
  };

  return (
    <div className="interventionModalContent">
      <div className="text-center">
        <h3 className="font-weight-bold">
          You can select the "Reading Skills" and "Reading Skills" type to
          filter interventions in the intervention library.
        </h3>
      </div>
      <hr />
      <div className="mx-4 px-4">
        <Form.Group>
          <Form.Label>Reading Skill Type</Form.Label>
          <Select
            isMulti
            isClearable
            options={skillTypeOptions}
            value={selectedReadingSkillTypes}
            onChange={handleReadingSkillTypeChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Reading Skills</Form.Label>
          <Select
            isMulti
            isDisabled={!readingSkillTypes.length}
            options={skillsOptions}
            value={selectedSkills}
            onChange={handleReadingSkillsChange}
          />
        </Form.Group>
      </div>
      <div className="modalActions">
        <div />
        <button className="blueBtnSm" onClick={onClickNext}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default ReadingSkillsForm;
