import React from "react";
import { Cell } from "fixed-data-table-2";

const MatrixFocusHeader = () => (
  <Cell className="first-column-header">
    Click Skills for Intervention Suggestions
  </Cell>
);

export default MatrixFocusHeader;
