import React, { FunctionComponent, useMemo } from "react";
import { FidelityByTeacher } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import BootstrapTable from "react-bootstrap-table-next";

type OwnProps = {
  fidelityByTeacher: FidelityByTeacher[];
};

type Props = OwnProps;

const FidelityByCoachingRelationshipTable: FunctionComponent<Props> = (
  props
) => {
  const { fidelityByTeacher = [] } = props;

  const columns = useMemo(
    () => [
      {
        dataField: "key",
        text: "key",
        hidden: true,
      },
      {
        dataField: "coach",
        text: "Coach Name",
        headerStyle: { width: "45%" },
        sort: true,
      },
      {
        dataField: "teacher",
        text: "Teacher Name",
        headerStyle: { width: "45%" },
        sort: true,
      },
      {
        dataField: "fidelity",
        text: "Fidelity",
        sort: true,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      fidelityByTeacher.map((item) => ({
        key: `${item.coach.id}_${item.teacher.id}`,
        coach: getFullName(item.coach),
        teacher: getFullName(item.teacher),
        fidelity: (item.fidelity * 100).toFixed(2).replace(".00", "") + "%",
      })),
    [fidelityByTeacher]
  );

  return (
    <BootstrapTable
      classes="rosterTable"
      keyField="key"
      bordered={false}
      bootstrap4
      data={data}
      columns={columns}
      noDataIndication={"No data"}
    />
  );
};

export default FidelityByCoachingRelationshipTable;
