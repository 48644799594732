import { ChatThread, ModalSteps } from "../../../store/chat/types";
import React, { FunctionComponent, useEffect } from "react";
import { ApplicationState } from "../../../store";
import { getChatMessages, getChatThreads } from "../../../store/chat/actions";
import { connect, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import ChatThreads from "./ChatThreads";
import ChatArea from "./chat-area/ChatArea";

type OwnProps = {
  setStep: (step: ModalSteps) => any;
};

type PropsFromState = {};

type DispatchProps = {
  getChatMessages: (threadId: number) => any;
  getChatThreads: () => any;
};

type Props = OwnProps & PropsFromState & DispatchProps;

const Chat: FunctionComponent<Props> = ({
  getChatMessages,
  setStep,
  getChatThreads,
}) => {
  const currentThread = useSelector<ApplicationState, ChatThread | undefined>(
    (s) => s.chat.selectedThread
  );

  useEffect(() => {
    if (currentThread) {
      getChatMessages(currentThread.id!);
    } else {
      getChatThreads();
    }
  }, [currentThread]);

  return (
    <div className="spaceBetween">
      <ChatThreads onNewChat={() => setStep(ModalSteps.NEW_CHAT)} />
      <ChatArea />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getChatMessages: getChatMessages,
      getChatThreads: getChatThreads,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Chat);
