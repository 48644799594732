import React, { FunctionComponent } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import useSpellingTestCellOnFocus from "./hooks/useSpellingTestCellOnFocus";

type Props = {
  questionWord: string;
  minWidths: number[];
  correctParts: string[];
  studentAnswerParts: string[];
  onCommonGrammarPartChange: (value: string, index: number) => any;
};

const SyllablesGrammarPartInput: FunctionComponent<Props> = (props) => {
  const {
    questionWord,
    minWidths,
    correctParts,
    studentAnswerParts,
    onCommonGrammarPartChange,
  } = props;

  const handleVceGrammarPartChange = (index: number) => (
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    onCommonGrammarPartChange(value, index);
  };

  const onFocus = useSpellingTestCellOnFocus(questionWord);

  return (
    <InputGroup>
      {correctParts.map((part, index) => (
        <React.Fragment key={index}>
          <FormControl
            defaultValue={studentAnswerParts[index] || ""}
            onFocus={onFocus}
            onBlur={handleVceGrammarPartChange(index)}
            style={{ minWidth: minWidths[index] }}
            className={`${
              studentAnswerParts[index] !== undefined
                ? part === studentAnswerParts[index]
                  ? "correct-part"
                  : "wrong-part"
                : "empty-part"
            }`}
          />
        </React.Fragment>
      ))}
    </InputGroup>
  );
};

export default SyllablesGrammarPartInput;
