import React, { FunctionComponent } from "react";
import { Specific } from "../../../../../../../store/groups/types";
import MatrixTab from "./MatrixTab";
import { Student } from "../../../../../../../store/onboarding/types";
import { InterventionGroup } from "../../../../../../../store/onboarding/cases/types";

type OwnProps = {
  focuses: Specific[];
  students: Student[];
  interventionGroup?: InterventionGroup
};

type Props = OwnProps;

const FocusSection: FunctionComponent<Props> = (props) => {
  const { focuses, students, interventionGroup } = props;
  return (
    <>
      <h5 className="font-weight-bold">FOCUS</h5>
      <MatrixTab specifics={focuses} students={students} interventionGroup={interventionGroup}/>
    </>
  );
};

export default FocusSection;
