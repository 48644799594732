import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import GroupCountIcon from "../../../../../common/onboarding/third-step/group-students/common/GroupCountIcon";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { Student } from "../../../../../../store/onboarding/types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import GroupStudentName from "./GroupStudentName";
import { IS_READY_COACH } from "../../../../../../constants";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  GroupRecommendation,
  InterventionGroup,
  StudentGoal,
} from "../../../../../../store/onboarding/cases/types";
import { intersection } from "lodash";
import { deleteInterventionGroup } from "../../../../../../store/onboarding/cases/actions";
import { ApplicationState } from "../../../../../../store";
import useInterventionGroupAccess from "../../../../../../utils/hooks/useInterventionGroupAccess";

type OwnProps = {
  isReadonly?: boolean;
  students: Array<Student>;
  interventionGroup: InterventionGroup;
  exitedStudentsGroup?: boolean;
  onDragDropStudent: (student: Student, group: GroupRecommendation) => void;
  isShowStudentCount?: boolean;
};

type Props = OwnProps;

const MAX_DISPLAYED_NAMES_NUM = 3;

const InterventionGroupStudents: FunctionComponent<Props> = ({
  interventionGroup,
  isReadonly,
  students,
  exitedStudentsGroup,
  onDragDropStudent,
  isShowStudentCount
}) => {
  const dispatch = useDispatch();

  const [showAll, setStateShowAll] = useState<boolean>(false);

  const displayGroup = useSelector((s: ApplicationState) => s.cases.displayGroupName);

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(interventionGroup.id);

  //const [prevGroupState, setPrevGroupState] = useState<any>();

//   const bothStudentGroupUpdated = useSelector<
//   ApplicationState,
//   boolean | undefined
// >((s) => s.cases.bothStudentGroupUpdated);

  // const firstUpdate = useRef(true);

  // useEffect(() => {
  //   if(prevGroupState) {
  //     let showStudentWarnArray:any[] = 
  //           prevGroupState?.finished ? 
  //             prevGroupState.students.filter(
  //               (student:any) => prevGroupState.student_goals.find(
  //                 (sg:any) => (student.id == sg.student.id) && !sg.archived))
  //           : prevGroupState.students;
  //     if(!prevGroupState?.finished && bothStudentGroupUpdated && (showStudentWarnArray.length <= 1) ) {
  //       if(firstUpdate.current){
  //       dispatch(deleteInterventionGroup(prevGroupState.id!));
  //       firstUpdate.current = false;
  //         return;
  //       }

  //     }
  //   }
  // }, [bothStudentGroupUpdated])

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const uniqueByKey = (array:Student[], key:any ='id') => {
    return Array.from(new Map(array.map((x:any) => [x[key], x])).values());
  }

  const filterStudents = (
    selectedInterventionGroup: InterventionGroup | undefined
  ) => {
    let studentsList = selectedInterventionGroup?.students.filter(
      (student, index) => {
        let studentGoal: StudentGoal | undefined;
        if (selectedInterventionGroup?.student_goals) {
          if(isShowStudentCount) {
            studentGoal = selectedInterventionGroup?.student_goals.find(
             (x) => x.student.id === student.id && ((displayGroup == 'archived' || isReadonly) ? x.archived : !x.archived)
           );
          } else {
            studentGoal = selectedInterventionGroup?.student_goals.find(
              (x) => x.student.id !== student.id && ((displayGroup == 'archived' || isReadonly) ? x.archived : !x.archived)
            );
          }
        }
        return !!studentGoal;
      }
    );

    studentsList = exitedStudentsGroup
      ? students
      : 
        (displayGroup == 'archived' || isReadonly) 
        ? uniqueByKey([...studentsList!, ...students])
        : intersection(studentsList!,students)//if first array empty, intersection will return empty array

    return studentsList;
  };

  const currentStudents = useMemo(() => {
    const result = filterStudents(interventionGroup);
    return result != undefined ? result : [];
  }, [students, interventionGroup]);

  const handleDrop = (event: React.DragEvent) => {
    const studentData = event.dataTransfer.getData("student");
    const prevGroupData = event.dataTransfer.getData("prevGroup");
    
    if (studentData && prevGroupData) {
      const student = JSON.parse(studentData);
      const prevGroup = JSON.parse(prevGroupData);

      if(interventionGroup.id == prevGroup.id) {
        //unable to drag and drop student in same group
        return;
      }

      if (IS_READY_COACH) {
        
        let showStudentWarnArray:any[] = 
            prevGroup?.finished ? 
              prevGroup.students.filter(
                (student:any) => prevGroup.student_goals.find(
                  (sg:any) => (student.id == sg.student.id) && !sg.archived))
            : prevGroup.students;

        let isStudentWithGoal: boolean = prevGroup.student_goals && prevGroup.student_goals.some(
          (sg:any) => (student.id == sg.student.id) && !sg.archived);  

        if(isStudentWithGoal) {

          dispatch(
            showConfirmDialog({
              title: "Confirmation",
              text: !prevGroup.finished
               ? "Are you sure you want to move this student? Their primary goal will not move to the new group with them."
               : "Are you sure you want to move this student? Primary goals will be archived but do not move to the new group. Secondary goals will be deleted, not archived and do not move to the new group.",
              showLastStudentWarning:  (showStudentWarnArray.length > 1) ? false : true,
              isFinished: prevGroup?.finished,
              countDownTimer: (showStudentWarnArray.length > 1) ? 0 : 3,  
              cancelButtonText: "No",
              confirmButtonText: "Yes",
              onConfirm: () => {
                 onDragDropStudent(student, prevGroup);
              },
            })
          );
        } else {
          onDragDropStudent(student, prevGroup);
        }
      } else {
        onDragDropStudent(student, prevGroup);
      }
    }
  };

  return (
    <>
    {isShowStudentCount 
    ? 
      <div className="d-flex flex-column mr-3">
        <GroupCountIcon svgSize={80} studentsNum={currentStudents.length} />
        {!isReadonly && !isIntGrpIDsWithReadAccess && (
          <small
            className="font-weight-bold details-link"
            style={{ marginBottom: "-10px" }}
          >
            Click for details
          </small>
        )}
      </div>
    : ''}
    {currentStudents.length
    ? 
      <div
        className="interventionStudentsContainer flex-1"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {(showAll
          ? currentStudents
          : currentStudents.slice(0, MAX_DISPLAYED_NAMES_NUM)
        ).map((student) => (
          <GroupStudentName
            key={student.id}
            student={student}
            interventionGroup={interventionGroup}
            isReadonly={isReadonly}
            isIntGrpIDsWithReadAccess={isIntGrpIDsWithReadAccess}
          />
        ))}
        {!showAll && currentStudents.length > MAX_DISPLAYED_NAMES_NUM && (
          <OverlayTrigger
            overlay={
              <Popover id={"tooltip"}>
                <Popover.Content>
                  {currentStudents
                    .slice(MAX_DISPLAYED_NAMES_NUM)
                    .map(getFullName)
                    .join(", ")}
                </Popover.Content>
              </Popover>
            }
          >
            <div
              className="moreStudentsContainer"
              onClick={(e) => {
                e.stopPropagation();
                setStateShowAll(true);
              }}
            >
              +{currentStudents.length - MAX_DISPLAYED_NAMES_NUM} more
            </div>
          </OverlayTrigger>
        )}
      </div>
    : 
    (<i>No students</i>)}
    </>
  );
};

export default InterventionGroupStudents;
