import React, { FunctionComponent, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import GroupInfoModalTitle from "../common/GroupInfoModalTitle";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  changeSelectedPraise,
  getFeedbackMeetings,
  hideProvidingFeedbackModal,
  openPraiseEducatorModal,
  openSupportInterventionModal,
} from "../../../../../../store/onboarding/meeting-module/actions";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import InterventionsBar from "../common/InterventionsBar";
import PlanningModalBody from "./PlanningModalBody";
import MeetingModalBody from "./MeetingModalBody";
import ModalCloseButton from "../../../../../common/onboarding/third-step/group-students/common/ModalCloseButton";

type StateProps = {
  showModal: boolean;
  showResourcesModal: boolean;
  interventionGroup?: InterventionGroup;
};

type DispatchProps = {
  hideProvidingFeedbackModal: () => any;
  openSupportInterventionModal: () => any;
  changeSelectedPraise: () => any;
  getFeedbackMeetings: (
    feedbackMeetingId?: number,
    interventionGroupId?: number
  ) => any;
};

type OwnProps = {};

type Props = OwnProps & StateProps & DispatchProps;

enum ModalSteps {
  PLANNING,
  MEETING,
}

const ProvidingFeedbackModal: FunctionComponent<Props> = ({
  showModal,
  showResourcesModal,
  interventionGroup,

  getFeedbackMeetings,
  changeSelectedPraise,
  hideProvidingFeedbackModal,
  openSupportInterventionModal,
}) => {
  const [step, setStep] = useState(ModalSteps.PLANNING);

  // useEffect(() => {
  //   //MODAL OVERLAY CLASS HACKS
  //   if (showModal && !showResourcesModal) {
  //     document.body.style.overflow = "hidden";
  //     document.body.classList.add("modal-open");
  //   }
  // }, [showResourcesModal]);

  useEffect(() => {
    changeSelectedPraise();
  }, [step]);

  if (!interventionGroup) return null;

  const onModalHide = () => {
    hideProvidingFeedbackModal();
    openSupportInterventionModal();
  };

  const onModalShow = () => {
    getFeedbackMeetings(undefined, interventionGroup.id);
    setStep(ModalSteps.PLANNING);
  };

  return (
    //@ts-ignore
    <Modal
      show={showModal}
      onHide={onModalHide}
      //@ts-ignore
      onShow={onModalShow}
      animation={false}
      size="lg"
      backdropClassName={`customDarkModalBackdrop in ${
        showResourcesModal ? "d-none" : ""
      }`}
      dialogClassName={showResourcesModal ? "d-none" : ""}
    >
      <Modal.Header className="purpleModalHeader centerModalHeader">
        <GroupInfoModalTitle />
        <Modal.Title>
          <div className="interventionSelectContainer">
            Providing Feedback
            <span className="pointer">
              <FontAwesomeIcon icon={faCaretDown} size="2x" />
            </span>
          </div>
        </Modal.Title>
        <ModalCloseButton onClose={onModalHide} />
      </Modal.Header>
      <Modal.Body>
        <h3 className="mb-3">
          <span className="font-weight-bold">Group: </span>
          {interventionGroup.name}
        </h3>
        <div>
          <InterventionsBar>
            <div>
              <h3 className="font-weight-bold">
                When providing feedback, it is important to:
              </h3>
              <ul style={{ paddingInlineStart: "20px" }}>
                <li>be positive, sincere, and identify strengths</li>
                <li>use data to inform improvement plans</li>
                <li>consider additional modeling and practice needs</li>
              </ul>
            </div>
          </InterventionsBar>
          {step === ModalSteps.PLANNING ? (
            <PlanningModalBody
              onReadyToMeetMow={() => setStep(ModalSteps.MEETING)}
            />
          ) : (
            <MeetingModalBody />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({
  cases,
  meetingModuleReducer,
}: ApplicationState): StateProps => {
  return {
    showModal: meetingModuleReducer.modalsState.providingFeedbackModal,
    showResourcesModal:
      meetingModuleReducer.modalsState.interventionsBriefModal,
    interventionGroup: cases.selectedInterventionGroup,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hideProvidingFeedbackModal: hideProvidingFeedbackModal,
      openSupportInterventionModal: openSupportInterventionModal,
      openPraiseEducatorModal: openPraiseEducatorModal,
      changeSelectedPraise: changeSelectedPraise,
      getFeedbackMeetings: getFeedbackMeetings,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProvidingFeedbackModal);
