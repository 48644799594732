import React, { FunctionComponent, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { CoachTeacherTotalSummary } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";

type OwnProps = {
  goalProgress: Array<CoachTeacherTotalSummary>;
};

type Props = OwnProps;

const GoalProgressTable: FunctionComponent<Props> = ({ goalProgress }) => {
  const columns = useMemo(
    () => [
      {
        dataField: "coach",
        text: "Coach Name",
        sort: true,
      },
      {
        dataField: "decision_count",
        text: "# Times Goal Progress Logged",
        sort: true,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      goalProgress.map((gp) => ({
        coach: getFullName(gp.coach),
        decision_count: gp.total,
      })),
    [goalProgress]
  );

  return (
    <BootstrapTable
      classes="rosterTable"
      keyField="id"
      bordered={false}
      bootstrap4
      data={data}
      columns={columns}
      noDataIndication={"No data"}
    />
  );
};

export default GoalProgressTable;
