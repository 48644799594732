import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  ChecklistItem,
  CommonSteps,
  Intervention,
} from "../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { hideImplementStepModal } from "../../../../../store/onboarding/cases/actions";
import { connect } from "react-redux";
import AddResourcesForm from "../../../../pages/coach-dashboard/tabs/explore-and-learn/intervention-library/new-intervention-modal/intervention-steps-form/AddResourcesForm";
import Select, { ValueType } from "react-select";
import { ReactSelectOption } from "../../../../../store/onboarding/types";

type DispatchProps = {
  hideImplementStepModal: () => any;
};

type PropsFromState = {
  showModal: boolean;
};

type OwnProps = {
  selectedCheckListItem?: ChecklistItem & { index?: number };
  selectedIntervention: Intervention;
  onDeleteStep: () => any;
  onSaveChanges: (checklistItem: ChecklistItem & { index?: number }) => any;
};
type Props = OwnProps & DispatchProps & PropsFromState;

// type State = {
//   selectedCheckListItem: ChecklistItem & { index?: number };
//   fileDragging: boolean;
// };

const ImplementStepModal: React.FunctionComponent<Props> = (props) => {
  const [selectedCheckListItem, setSelectedCheckListItem] = useState<
    (Partial<ChecklistItem> & { index?: number }) | undefined
  >(undefined);
  const { selectedIntervention, showModal } = props;

  const options = CommonSteps.map((data) => {
    return { 'label': data.label, 'value': data.value }
  });
  //
  // state: = {
  //   selectedCheckListItem: {
  //     is_active: true,
  //     title: "",
  //     details: "",
  //     order: 0,
  //     attachments: [],
  //   }
  // };

  // dragCounter: number = 0;

  const handleModalOpen = () => {
    setSelectedCheckListItem(props.selectedCheckListItem);
  };

  const handleModalHide = () => {
    props.hideImplementStepModal();
  };

  // handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };
  //
  // handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   this.dragCounter++;
  //   if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
  //     this.setState({ fileDragging: true });
  //   }
  // };
  //
  // handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   this.dragCounter--;
  //   if (this.dragCounter > 0) return;
  //   this.setState({ fileDragging: false });
  // };
  //
  // handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   this.setState({ fileDragging: false });
  //   if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
  //     const files: Array<File> = Array.from(e.dataTransfer.files);
  //     const attachments: Array<AttachedResource> = files.map((file) => ({
  //       attached_to_type: AttachedToType.CHECKLIST_ITEM,
  //       resource_type: AttachedResourceType.FILE,
  //       attachment: file,
  //       title: file.name,
  //       mime_type: file.type,
  //     }));
  //     this.setState((prevState) => ({
  //       ...prevState,
  //       selectedCheckListItem: {
  //         ...prevState.selectedCheckListItem,
  //         attachments: [
  //           ...prevState.selectedCheckListItem.attachments,
  //           ...attachments,
  //         ],
  //       },
  //     }));
  //     e.dataTransfer.clearData();
  //     this.dragCounter = 0;
  //   }
  // };

  const handleStepDelete = () => {
    handleModalHide();
    props.onDeleteStep();
  };

  const handleStepChange = () => {
    handleModalHide();
    if (selectedCheckListItem) {
      props.onSaveChanges(selectedCheckListItem as any);
    }
  };

  // handleAttachmentRemove = (index: number) => () => {
  //   this.setState((prevState) => ({
  //     selectedCheckListItem: {
  //       ...prevState.selectedCheckListItem,
  //       attachments: prevState.selectedCheckListItem.attachments.filter(
  //         (a, idx) => idx !== index
  //       ),
  //     },
  //   }));
  // };

  const handleCheckListItemChange = (event: React.FormEvent<any>) => {
    const { name, value } = event.target as HTMLInputElement;

    setSelectedCheckListItem((pS) => ({
      ...pS,
      [name]: value,
    }));
  };

  const handleCommonStepChange = (
    event: ValueType<ReactSelectOption<string>, false>
  ) => {
    setSelectedCheckListItem((item) => ({ ...item, 'common_step_name': event?.value }));
  };

  return (
    //@ts-ignore
    <Modal
      size="lg"
      show={showModal}
      style={{ backgroundColor: "rgb(255,255,255,0.8)" }}
      className="colorPickerModal"
      dialogClassName="colorPickerDialog"
      onHide={handleModalHide}
      onShow={handleModalOpen}
    >
      <Modal.Body className="implementationStepModal">
        <div className="implementationStepModalTopContainer">
          <div>
            <h3>
              Implementation Step{" "}
              <strong>#{selectedCheckListItem?.index! + 1}</strong> for
              <br />
              <strong>{selectedIntervention.name}</strong>
            </h3>
          </div>
          <button className="redBtnSm" onClick={handleStepDelete}>
            Delete this step
          </button>
        </div>
        <Form>
          <Form.Group>
            <Form.Label className="font-weight-bold">Common Research-Based Instructional Components</Form.Label>
            <Select
              placeholder="Select Step"
              options={options}
              onChange={(event) => handleCommonStepChange(event)}
              value={selectedCheckListItem?.common_step_name != null ? options.find((option) => option.value == selectedCheckListItem?.common_step_name) : null}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="font-weight-bold">Step Title</Form.Label>
            <Form.Text className="mt-0">
              This should be a short description of the step, without the
              details
            </Form.Text>
            <Form.Control
              type="text"
              placeholder="Enter Step Title"
              value={selectedCheckListItem?.title}
              name="title"
              onChange={handleCheckListItemChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label className="font-weight-bold">Step Details</Form.Label>
            <Form.Text className="mt-0">
              Here you can get into the nitty-gritty of how to implement this
              step
            </Form.Text>
            <Form.Control
              as="textarea"
              type="text"
              placeholder="Enter Step Details"
              value={selectedCheckListItem?.details}
              name="details"
              onChange={handleCheckListItemChange}
            />
          </Form.Group>
        </Form>

        <AddResourcesForm
          attachments={selectedCheckListItem?.attachments}
          onAttachmentsAdd={(resources) =>
            setSelectedCheckListItem((ci) => ({
              ...ci!,
              attachments: [...(ci?.attachments || []), ...resources],
            }))
          }
        />

        {/*<div*/}
        {/*  className="dropFileArea"*/}
        {/*  onDragOver={this.handleDrag}*/}
        {/*  onDragEnter={this.handleDragIn}*/}
        {/*  onDragLeave={this.handleDragOut}*/}
        {/*  onDrop={this.handleDrop}*/}
        {/*>*/}
        {/* */}
        {/*  {selectedCheckListItem.attachments.map((attachment, index) => (*/}
        {/*    <div className="fileCell" key={attachment.id}>*/}
        {/*      <div className="fileCellName">{attachment.title}</div>*/}
        {/*      <div*/}
        {/*        className="fileCellRemove"*/}
        {/*        onClick={this.handleAttachmentRemove(index)}*/}
        {/*      >*/}
        {/*        <FontAwesomeIcon*/}
        {/*          icon={faTimes}*/}
        {/*          size="1x"*/}
        {/*          style={{ color: "#ffffff" }}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</div>*/}

        <div className="d-flex justify-content-around align-items-center">
          <button
            className="whiteBtnMd2"
            style={{ width: "40%" }}
            onClick={handleModalHide}
          >
            Cancel
          </button>
          <button
            className="blueBtnMd"
            style={{ width: "40%" }}
            onClick={handleStepChange}
            disabled={
              selectedCheckListItem?.title?.trim() == ''
              || selectedCheckListItem?.common_step_name?.trim() == undefined
            } 
          > 
            Save changes
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ cases }: ApplicationState): PropsFromState => {
  return { showModal: cases.modalsState.implementStepModal };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideImplementStepModal: hideImplementStepModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ImplementStepModal);
