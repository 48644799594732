import React, { FunctionComponent, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { getDataPeriodSchools, hideEquityBenchmarksModal } from "../../../../../../store/onboarding/actions";
import { Modal, ModalProps } from "react-bootstrap";
import EquityBenchmarksModalContent from "./EquityBenchmarksModalContent";

type OwnProps = {};

type Props = OwnProps;

const EquityBenchmarksModal: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const showModal = useSelector(
    (s: ApplicationState) => s.onboarding.modalsState.showEquityBenchmarksModal
  );
  const currentDataPeriod = useSelector((s: ApplicationState) => s.onboarding.currentDataPeriod);

  useEffect(() => {
    if(showModal) {
      dispatch(getDataPeriodSchools(currentDataPeriod?.id))
    }
  },[showModal])

  const handleHide = () => {
    dispatch(hideEquityBenchmarksModal());
  };

  useEffect(() => {
    if(showModal) {
      let modalElement:HTMLElement = document.getElementById('benchmark-modal')!;
      modalElement?.parentElement!.classList?.add('benchmark-modal-pdf-class');
    } else {
      let modalElement:HTMLElement = document.getElementById('benchmark-modal')!;
      modalElement?.parentElement!.classList?.remove('benchmark-modal-pdf-class');
    }
  },[showModal])

  return (
    <Modal
      show={showModal}
      onHide={handleHide}
      animation={false}
      size="xl"
      backdropClassName="customDarkModalBackdrop in"
      id="benchmark-modal"
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>Equity Benchmarks ({currentDataPeriod?.name})</Modal.Title>
      </Modal.Header>
      <Modal.Body>{showModal && <EquityBenchmarksModalContent dataPeriod={currentDataPeriod}/>}</Modal.Body>
    </Modal>
  );
};

export default EquityBenchmarksModal;
