import React, { FunctionComponent, useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  deleteEvidenceColumnsGroup,
  hideEvidenceConfirmDeletionModal,
} from "../../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import { EvidenceColumnGroup } from "../../../../../../store/onboarding/types";
import { evidenceColumnGroupDisplayTitle } from "./Helpers";
import { toastr } from "react-redux-toastr";

type StateProps = {
  evidenceColumnGroup?: EvidenceColumnGroup;
  showModal?: boolean;
  isLoading: {
    deleteEvidenceColumnsGroup?: boolean;
  };
  timeElipse?: boolean;
};

type DispatchProps = {
  deleteEvidenceColumnsGroup: (evidenceColumnGroupId: number) => any;
  onHide: () => any;
};

type OwnProps = {};

type Props = StateProps & DispatchProps & OwnProps;

const RemoveEvidenceConfirmModal: FunctionComponent<Props> = (props) => {
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if(props.timeElipse) {
      setTimeLeft(3)
    }
  }, [props.timeElipse])

  useEffect(() => {
     if(props.timeElipse) {
      // exit early when we reach 0
      if (!timeLeft) return;
  
      // save intervalId to clear the interval when the
      // component re-renders
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
  
      // clear interval on re-render to avoid memory leaks
      return () => {
        clearInterval(intervalId);
      };
      // add timeLeft as a dependency to re-rerun the effect
      // when we update it
    }
  }, [timeLeft]);

  const handleConfirm = () => {
    if (props.evidenceColumnGroup) {
      props.deleteEvidenceColumnsGroup(props.evidenceColumnGroup.id).then(
        () => {
          props.onHide();
        },
        (err: string) =>
          toastr.error("Failed to remove the evidence column group!", err)
      );
    }
  };

  if (!props.evidenceColumnGroup) {
    return null;
  }

  return (
    <>
    {props.timeElipse ? 
      <Modal
        show={props.showModal}
        onHide={props.onHide}
        animation={false}
        size="sm"
        backdropClassName="customDarkModalBackdrop in"
      >
        <Modal.Header closeButton className="purpleModalHeader">
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p>
              This Assessment data table may be shared with other users who may have already made decisions using the data. 
            All users with whom this data may be shared will no longer have access to the data. Are you sure you want to continue?
              {/* {" "}<b>{evidenceColumnGroupDisplayTitle(props.evidenceColumnGroup)}</b>? */}
            </p>
          </div>
          <hr/>
          <div className="btnActions">
            <button className="whiteBtnSm mr-2" onClick={props.onHide}>
              Cancel
            </button>
            <button className="blueBtnSm" disabled={props.timeElipse ? !!timeLeft : false} onClick={handleConfirm}>
              {props.timeElipse ? !timeLeft ? "Yes, delete it "
              : timeLeft : ''}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    : ''
    }
    </>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    evidenceColumnGroup: onboarding.selectedEvidenceColumnGroup,
    showModal: onboarding.modalsState.evidenceConfirmDeletionModal,
    timeElipse: onboarding.timeElipse,
    isLoading: {
      deleteEvidenceColumnsGroup:
        onboarding.isLoading.deleteEvidenceColumnsGroup,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      onHide: hideEvidenceConfirmDeletionModal,
      deleteEvidenceColumnsGroup: deleteEvidenceColumnsGroup,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveEvidenceConfirmModal);
