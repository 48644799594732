import {
  Measurement,
  measurementOptionsList,
  MeasurementType,
  MeasurementTypeDisplayedNames,
  ReactSelectOption,
  skillAreaDisplayedNames,
} from "../../../../../store/onboarding/types";
import React, { Component } from "react";
import ColumnHeaderSelector from "./ColumnHeaderSelector";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { IS_READY_COACH } from "../../../../../constants";
import ReadingSkillSelector from "../evidence-tab/ReadingSkillSelector";
import { SkillFocusArea } from "../../../../../store/groups/types";

type Props = {
  index: number;
  measurement: Measurement;
  onMeasureChange: (
    index: number,
    select?: boolean
  ) => (event: React.SyntheticEvent) => void;
  onPossibleValuesChange: (measurement: Measurement) => (index: number) => any;
  columnNames: Array<string>;
  selectedColumnNames: Array<string>;
  onClick: (event: React.SyntheticEvent) => void;
  onMeasurementRemove: (index: number) => any;
  withReadingAlgorithm?: boolean;
  skill_areas?: ReactSelectOption<string>[];
  onSkillAreaChange: (
    index: number,
    skill: string
  ) => any;
  useGradeSpecificColor?: boolean;
};

class ColorCriteriaRow extends Component<Props> {
  createRangeTd = (measurement: Measurement, index: number) => {
    switch (measurement.type) {
      case MeasurementType.NUMBER:
        return (
          <div className="form-inline">
            <div>
              <input
                required
                type="number"
                name="range_min"
                placeholder="Min"
                className="inputSm"
                style={{ minWidth: 50 }}
                value={measurement.range_min}
                onChange={this.props.onMeasureChange(index)}
              />
            </div>
            <strong>&nbsp;-&nbsp;</strong>
            <div>
              <input
                required
                type="number"
                name="range_max"
                placeholder="Max"
                className="inputSm"
                style={{ minWidth: 50 }}
                value={measurement.range_max}
                onChange={this.props.onMeasureChange(index)}
              />
            </div>
          </div>
        );
      case MeasurementType.PERCENTAGE:
        return <div className="rangeDiv"> 0 - 100 </div>;
      case MeasurementType.CATEGORY:
      default:
        return (
          <Button
            variant="primary"
            size="sm"
            //disabled={!measurement.column_name}
            disabled={this.props.useGradeSpecificColor}
            onClick={this.props.onPossibleValuesChange(measurement)(index)}
          >
            <div className="rangeDiv" style={{ cursor: "pointer" }}>
              Select
            </div>
          </Button>
        );
    }
  };

  render() {
    const { index, measurement } = this.props;
    const buttonDisabled = !(
      measurement.display_name &&
      measurement.column_name &&
      measurement.type !== undefined &&
      ((measurement.range_min !== undefined &&
        measurement.range_max !== undefined) ||
        (measurement.possible_values &&
          measurement.possible_values.length > 0)) &&
      !(IS_READY_COACH && measurement.skill_area !== undefined)
    );
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <ColumnHeaderSelector
            required
            name={"column_name"}
            value={measurement.column_name!}
            onChange={this.props.onMeasureChange(index, true)}
            columnNames={this.props.columnNames}
            selectedColumnNames={this.props.selectedColumnNames}
          />
        </td>
        <td>
          <input
            required
            name={"display_name"}
            value={measurement.display_name}
            type="text"
            className="stdInput"
            id="displayedName"
            onChange={this.props.onMeasureChange(index)}
          />
        </td>
        {!IS_READY_COACH && (
          <td>
            <Form.Control
              required
              style={{ width: "140px" }}
              name="skill_area"
              as="select"
              value={(measurement.skill_area as unknown) as string}
              onChange={this.props.onMeasureChange(index)}
            >
              <option key={-1} value={""} />
              {skillAreaDisplayedNames.map(
                (skillArea: { value: string; label: string }, index) => (
                  <option value={skillArea.value} key={index}>
                    {skillArea.label}
                  </option>
                )
              )}
            </Form.Control>
          </td>
        )}
        <td>
          <Form.Control
            required
            name="type"
            as="select"
            value={(measurement.type as unknown) as string}
            onChange={this.props.onMeasureChange(index)}
          >
            {measurementOptionsList.map((measureType: string, index) => (
              <option value={+measureType} key={index}>
                {
                  MeasurementTypeDisplayedNames[
                    (measureType as unknown) as MeasurementType
                  ]
                }
              </option>
            ))}
          </Form.Control>
        </td>
        {this.props.withReadingAlgorithm && (
            <td style={{ width: "190px" }}>
            <ReadingSkillSelector
              value={measurement.reading_skill}
              skills={this.props.skill_areas!}
              onChange={(skill) =>this.props.onSkillAreaChange(index, skill!)}
            />
          </td>                       
          )}
        <td>{this.createRangeTd(measurement, index)}</td>
        <td>
          <Button
            variant="link"
            size="sm"
            disabled={buttonDisabled}
            onClick={this.props.onClick}
          >
            Edit
          </Button>
        </td>
        <td>
          <Button onClick={this.props.onMeasurementRemove(index)}>
            <FontAwesomeIcon
              icon={faTimesCircle}
              className={`pointer ${IS_READY_COACH ? "" : "orangeText"}`}
            />
          </Button>
        </td>
      </tr>
    );
  }
}

export default ColorCriteriaRow;
