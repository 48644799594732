import React, { FunctionComponent, useCallback, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import BehaviorTableContainer from "../../../../../common/onboarding/third-step/set-goal-modal/subpages/do/BehaviorTableContainer";
import { CategoryBehavior } from "../../../../../../store/onboarding/types";

type Props = {
  behavior: string;
  studentName: string;
  hypothesis: string;
  onInterventionPrepare: (withoutReplacement?: boolean) => void;
  replacementBehavior: CategoryBehavior | undefined;
  setReplacementBehavior: React.Dispatch<
    React.SetStateAction<CategoryBehavior | undefined>
  >;
};

const ReplacementBehaviorTab: FunctionComponent<Props> = (props) => {
  const {
    hypothesis,
    behavior,
    studentName,
    onInterventionPrepare,
    replacementBehavior,
    setReplacementBehavior,
  } = props;

  const handleReplacementBehaviorChange = (
    replacementBehavior: CategoryBehavior | undefined
  ) => {
    setReplacementBehavior((prevBeh) =>
      prevBeh?.id === replacementBehavior?.id ? undefined : replacementBehavior
    );
    if (replacementBehavior) {
      setShowDropdown(false);
    }
  };

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const dropdownRef = useRef<any>(null);

  const handleInterventionPrepare = useCallback(() => {
    onInterventionPrepare();
  }, []);

  return (
    <>
      <div>
        <h3 className="text-center mb-3">
          Find a behavior for {studentName} that will replace <u>{behavior}</u>{" "}
          and satisfy the same function in your hypothesis.
        </h3>

        <div className="mb-3">
          <h4 className="text-center font-weight-bold">
            Here's your hypothesis:
          </h4>
          <p className="text-center">{hypothesis}</p>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <span ref={dropdownRef}>
            {replacementBehavior ? (
              <div
                className="abc-focus-container pointer"
                onClick={() => setShowDropdown(true)}
              >
                Replacement: {replacementBehavior.name}
              </div>
            ) : (
              <button
                className="blueBtnSm"
                onClick={() => setShowDropdown(true)}
              >
                Select Behavior
              </button>
            )}
          </span>
        </div>

        {replacementBehavior && (
          <div className="text-center mt-3">
            <button className="blueBtnSm" onClick={handleInterventionPrepare}>
              Prepare for Intervention
            </button>
          </div>
        )}
      </div>

      <Overlay
        placement="right"
        rootClose={true}
        onHide={() => setShowDropdown(false)}
        show={showDropdown}
        target={dropdownRef.current}
      >
        <Popover id="replacement-behavior">
          <Popover.Content>
            <BehaviorTableContainer
              displayPositive
              containerStyle={{ maxHeight: 250, overflowY: "auto" }}
              selectedBehaviors={
                replacementBehavior ? [replacementBehavior] : undefined
              }
              onBehaviorClick={handleReplacementBehaviorChange}
            />
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

export default ReplacementBehaviorTab;
