import React from "react";
import { Overlay, Popover } from "react-bootstrap";
import BRSInput from "../../../third-step/set-goal-modal/subpages/brs/BRSInput";
import { Measurement } from "../../../../../../store/onboarding/types";

type Props = {
  objectRef: React.MutableRefObject<any>;
  showDropdown: boolean;
  setShowDropdown: (show: boolean) => void;
  onSubmitPopover: (selectedBrsMeasurement: Measurement) => void;
};

const BRSPopover = ({
  objectRef,
  showDropdown,
  setShowDropdown,
  onSubmitPopover,
}: Props) => {
  return (
    <Overlay
      placement="auto"
      flip
      rootClose={true}
      onHide={() => setShowDropdown(false)}
      show={showDropdown}
      target={objectRef.current}
    >
      <Popover id="popover-brs" className="w-100 brs-popover">
        <Popover.Title>Behavior Rating Scale</Popover.Title>
        <Popover.Content>
          <BRSInput
            hasBehavioralGoalType
            onNextClick={onSubmitPopover}
            onBackClick={() => null}
          />
        </Popover.Content>
      </Popover>
    </Overlay>
  );
};

export default BRSPopover;
