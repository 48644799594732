import React, { Component } from "react";
import { Intervention } from "../../../../../store/onboarding/cases/types";
import { Spinner } from "react-bootstrap";

type OwnProps = {
  selectedIntervention: Intervention | undefined;
  handleUseAsIs: () => void;
  handleInterventionModify: () => void;
  handleClose: () => void;
  loading: { addInterventionsToGroup: boolean };
};

type Props = OwnProps;

class ModifyInterventionPopup extends Component<Props> {
  private popupRef?: HTMLDivElement | null;

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }

  handleClickOutside = (event: any) => {
    if (event.target === this.popupRef) {
      this.props.handleClose();
    }
  };

  render() {
    return (
      <div
        data-cy="ask-to-modify-alert"
        className="smallAlertModal"
        ref={(ref) => (this.popupRef = ref)}
      >
        <div>
          <div className={"pb-2"}>
            Would you like to use{" "}
            <strong>{this.props.selectedIntervention!.name}</strong> as is, or
            would you like to edit/remove some steps?
          </div>
          <div className="spaceBetween">
            <button
              data-cy="use-as-is-btn"
              className="blueBtnSm"
              onClick={this.props.handleUseAsIs}
            >
              Use as is{" "}
              {this.props.loading.addInterventionsToGroup && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
            <button
              className="blueBtnSm"
              onClick={this.props.handleInterventionModify}
            >
              Modify steps
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ModifyInterventionPopup;
