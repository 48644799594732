import React, { FunctionComponent, useMemo } from "react";
import { Tab, Tabs } from "react-bootstrap";
import FocusPanel from "./FocusPanel";
import { BehaviorTally } from "../../../../../../store/onboarding/cases/types";
import ReplacementBehaviorTab from "./ReplacementBehaviorTab";
import { useHypothesis } from "../hooks";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { getFullName } from "../../../../../../utils/NamesUtils";
import DecreaseBehaviorTab from "./DecreaseBehaviorTab";
import { CategoryBehavior } from "../../../../../../store/onboarding/types";

type Props = {
  selectedBehTally: BehaviorTally;
  selectedAntecedent:
    | { abcId: number; antecedents: number[]; other_antecedents?: string[] }
    | undefined;
  selectedConsequence:
    | { abcId: number; consequence: number; other_sequence?: string }
    | undefined;
  replacementBehavior: CategoryBehavior | undefined;
  setReplacementBehavior: React.Dispatch<
    React.SetStateAction<CategoryBehavior | undefined>
  >;
  onContinue: Function;
};

const DecisionBody: FunctionComponent<Props> = (props) => {
  const {
    selectedBehTally,
    selectedAntecedent,
    selectedConsequence,
    replacementBehavior,
    setReplacementBehavior,
    onContinue,
  } = props;

  const fba = useSelector((s: ApplicationState) => s.cases.selectedFba);

  const studentName = useMemo(() => {
    return getFullName(fba?.student);
  }, [fba?.student]);

  const { hypothesis, behavior } = useHypothesis({
    studentName,
    behTally: selectedBehTally,
    selectedAntecedent,
    selectedConsequence,
  });

  const handleInterventionPrepare = (isReplacement?: boolean) => {
    if (isReplacement) {
      setReplacementBehavior(undefined);
    }
    onContinue();
  };

  return (
    <div>
      <FocusPanel bTally={selectedBehTally!} />

      <h3 className="text-center my-3">
        How do you want to address this issue?
      </h3>

      <div className="decision-tabs">
        <Tabs defaultActiveKey={"replace"} id="decision-tab">
          <Tab eventKey="replace" title="Find a Replacement Behavior">
            <ReplacementBehaviorTab
              hypothesis={hypothesis}
              studentName={studentName}
              behavior={behavior}
              replacementBehavior={replacementBehavior}
              setReplacementBehavior={setReplacementBehavior}
              onInterventionPrepare={handleInterventionPrepare}
            />
          </Tab>
          <Tab eventKey="decrease" title={`Decrease ${behavior}`}>
            <DecreaseBehaviorTab
              hypothesis={hypothesis}
              behavior={behavior}
              onInterventionPrepare={handleInterventionPrepare}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default DecisionBody;
