import React, { FunctionComponent, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { SpellingTestGroup } from "../../../../store/spelling-tests/types";
import {
  getSpellingTestGroups,
  showSpellingTestGroupUpsertModal,
} from "../../../../store/spelling-tests/actions";
import LoadingIndicator from "../../../common/onboarding/LoadingIndicator";
import SpellingTestGroupItem from "./SpellingTestGroupItem";
import { useLoading } from "../../../../utils/hooks/useLoading";

type Props = {};

const SpellingTestGroupsPage: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    (s: ApplicationState) => s.spellingTests.isLoading.getSpellingTestGroups
  );

  const groups: SpellingTestGroup[] = useSelector(
    (s: ApplicationState) => s.spellingTests.groups
  );

  useEffect(() => {
    dispatch(getSpellingTestGroups());
  }, []);

  const showGroupUpsertModal = useCallback(() => {
    dispatch(showSpellingTestGroupUpsertModal(undefined));
  }, []);

  const deleteSpellingTestErr = useSelector(
    (s: ApplicationState) => s.spellingTests.errors.deleteSpellingTest
  );
  const deleteSpellingTestLoading = useSelector(
    (s: ApplicationState) => s.spellingTests.isLoading.deleteSpellingTest
  );
  useLoading(deleteSpellingTestLoading, deleteSpellingTestErr);

  const deleteSpellingTestGroupErr = useSelector(
    (s: ApplicationState) => s.spellingTests.errors.deleteSpellingTestGroup
  );
  const deleteSpellingTestGroupLoading = useSelector(
    (s: ApplicationState) => s.spellingTests.isLoading.deleteSpellingTestGroup
  );
  useLoading(deleteSpellingTestGroupLoading, deleteSpellingTestGroupErr);

  return (
    <div className="onboardingContainer">
      <h2 className="font-weight-bold">Spelling Test - Groups</h2>

      {isLoading ? (
        <LoadingIndicator />
      ) : groups.length ? (
        <div>
          <button className="blueBtnSm mb-3" onClick={showGroupUpsertModal}>
            Create New Group
          </button>

          {groups.map((group, index) => (
            <React.Fragment key={`spelling_test_group_${group.id}`}>
              {!!index && <hr />}
              <SpellingTestGroupItem group={group} />
            </React.Fragment>
          ))}
        </div>
      ) : (
        <div>
          <p>
            You don't have any spelling test group yet. Get started by adding
            new one!
          </p>
          <button className="blueBtnSm" onClick={showGroupUpsertModal}>
            Create New Group
          </button>
        </div>
      )}
    </div>
  );
};

export default SpellingTestGroupsPage;
