import React, { FunctionComponent, useEffect, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import usePrevProps from "../../../../../utils/hooks/usePrevProps";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { getFullName } from "../../../../../utils/NamesUtils";
import { changeSelectedFba } from "../../../../../store/onboarding/cases/actions";
import { Fba } from "../../../../../store/onboarding/cases/types";

type OwnProps = {};

type Props = OwnProps;

const NiceWorkOverlay: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const [showOverlay, setShowOverlay] = useState(false);

  const showAnalyzeFbaResultsModal = useSelector<ApplicationState>(
    (s) => s.cases.modalsState.showAnalyzeFbaResultsModal
  );

  const showSetGoalModal = useSelector<ApplicationState>(
    (s) => s.cases.modalsState.setGoalModal
  );
  const showInterventionLibraryModal = useSelector<ApplicationState>(
    (s) => s.cases.modalsState.interventionLibraryModal
  );
  const showInterventionPlanModal = useSelector<ApplicationState>(
    (s) => s.cases.modalsState.interventionPlan?.show
  );

  const selectedFba = useSelector<ApplicationState, Fba | undefined>(
    (s) => s.cases.selectedFba
  );

  const prevProps = usePrevProps({
    showInterventionLibraryModal,
    showAnalyzeFbaResultsModal,
    showSetGoalModal,
  });
  useEffect(() => {
    if (
      selectedFba &&
      ((prevProps?.showAnalyzeFbaResultsModal &&
        !showAnalyzeFbaResultsModal &&
        !showSetGoalModal) ||
        (prevProps?.showSetGoalModal &&
          !showSetGoalModal &&
          !showAnalyzeFbaResultsModal &&
          !showInterventionLibraryModal) ||
        (prevProps?.showInterventionLibraryModal &&
          !showInterventionLibraryModal &&
          !showInterventionPlanModal))
    ) {
      setShowOverlay(true);
    }
  }, [
    selectedFba,
    showAnalyzeFbaResultsModal,
    showSetGoalModal,
    showInterventionLibraryModal,
    showInterventionPlanModal,
    prevProps,
  ]);

  return (
    <>
      {showOverlay && <div className="customDarkPopoverBackdrop" />}
      <Overlay
        onExit={() => dispatch(changeSelectedFba(undefined))}
        placement="bottom"
        rootClose={true}
        onHide={() => setShowOverlay(false)}
        show={showOverlay}
        target={document.getElementById("fba-tabs-tab-active-intervention")}
      >
        <Popover id="popover-nice-work-abc">
          <Popover.Title>Nice work!</Popover.Title>
          <Popover.Content>
            You created an intervention group for{" "}
            {getFullName(selectedFba?.student)}. To track intervention and goal progress, 
            please find {getFullName(selectedFba?.student)} 
            under “Behavior Intervention Groups” in your caseload. 
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

export default NiceWorkOverlay;
