import React, { FunctionComponent, useEffect, useMemo } from "react";
import {
  GroupSorting,
  InterventionGroupSortBy,
} from "../../../../../../store/dataFilter/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltDown,
  faLongArrowAltUp,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";
import { ReactSelectOption } from "../../../../../../store/onboarding/types";
import Select from "react-select";
import { InputGroup } from "react-bootstrap";

type OwnProps = {
  groupSort?: GroupSorting;
  itemSort: InterventionGroupSortBy;
  title: string;
  changeGroupSort: (groupSort?: GroupSorting) => any;
};

type Props = OwnProps;

const ToolBarButton: FunctionComponent<Props> = ({
  title,
  itemSort,
  groupSort,
  changeGroupSort,
}) => {
  const isActive = groupSort?.sortBy === itemSort;

  const options: Array<ReactSelectOption<string>> = useMemo(
    () => [
      {
        label: "Ascending",
        value: 'asc',
      },
      {
        label: "Descending",
        value: 'desc',
      }
    ],
    []
  );

  const handleChangeGroupSort = (event:any) => {
    if(event == null){
      changeGroupSort(undefined);
    } else {
      if (isActive) {
        if (groupSort?.direction == 'desc') {
          changeGroupSort({ sortBy: itemSort, direction: 'asc' });
        } else if (groupSort?.direction == 'asc') {
          changeGroupSort({ sortBy: itemSort, direction: 'desc' });
        } else {
          changeGroupSort(undefined);
        }
      } else {
        toastr.error("Message:", "Sorting applies to only groups you are working on. To see all groups, remove the sort. ");
        changeGroupSort({ sortBy: itemSort, direction: event.value });
      }
    }
  };

  return (
    <div style={{minWidth:'177px'}}>
      <h4 className="font-weight-bold">Sort by {title}</h4>
      <Select
        options={options}
        value={options.find((direction:any) => isActive && direction.value == groupSort?.direction)}
        onChange={handleChangeGroupSort}
        className="mr-2"
        isClearable
      />
    </div>
  );
};

export default ToolBarButton;
