import React, { FunctionComponent, useState } from "react";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Col, Container, Modal, Row, Tab } from "react-bootstrap";
import NavPanel from "../../../../../common/onboarding/first-step/NavPanel";
import FirstTab from "../../../../../common/onboarding/first-step/FirstTab";
import SecondTab from "../../../../../common/onboarding/first-step/SecondTab";
import ThirdTab from "../../../../../common/onboarding/first-step/ThirdTab";
import { hideLearnMoreModal } from "../../../../../../store/onboarding/cases/actions";
import { EventKey } from "react-bootstrap/esm/types";

type StateProps = {
  showModal: boolean;
};

type DispatchProps = {
  hideLearnMoreModal: () => any;
};

type OwnProps = {};

type Props = StateProps & DispatchProps & OwnProps;

enum TabKeys {
  FIRST = "first",
  SECOND = "second",
  THIRD = "third",
}

const LearnMoreModal: FunctionComponent<Props> = ({
  showModal,
  hideLearnMoreModal,
}) => {
  const [activeTab, setActiveTab] = useState<string | null>(TabKeys.FIRST);

  return (
    <Modal
      show={showModal}
      size={"lg"}
      onHide={hideLearnMoreModal}
      id="learn-more-modal"
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>Learn how we support you</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="onboardingContainer">
          <Tab.Container
            defaultActiveKey={TabKeys.FIRST}
            activeKey={activeTab as EventKey}
            onSelect={(tab) => setActiveTab(tab)}
          >
            <NavPanel />
            <Container>
              <Row>
                <Col>
                  <Tab.Content>
                    <Tab.Pane eventKey={TabKeys.FIRST}>
                      <FirstTab />
                    </Tab.Pane>
                    <Tab.Pane eventKey={TabKeys.SECOND}>
                      <SecondTab />
                    </Tab.Pane>
                    <Tab.Pane eventKey={TabKeys.THIRD}>
                      <ThirdTab />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Container>
          </Tab.Container>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ cases }: ApplicationState): StateProps => {
  return {
    showModal: cases.modalsState.learnMoreModal,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hideLearnMoreModal: hideLearnMoreModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LearnMoreModal);
