import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

type Props = {
  onClickBack?: () => void;
};

const ConductInterviewModalTitle = ({ onClickBack }: Props) => {
  return (
    <>
      {onClickBack && (
        <Button style={{ position: "absolute" }} onClick={onClickBack}>
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          <h5 className="d-inline font-weight-semibold">Back</h5>
        </Button>
      )}
      <Modal.Title>Conduct an Interview</Modal.Title>
    </>
  );
};

export default ConductInterviewModalTitle;
