import React, { FunctionComponent } from "react";
import { Col, Image } from "react-bootstrap";
import { getDifferenceWithTime } from "../../../utils/DateTimeUtils";
import { UserInfo } from "../../../../store/auth/types";
import { ChatMessage } from "../../../../store/chat/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";

type OwnProps = {
  userInfo: UserInfo;
  message: ChatMessage;
};

type Props = OwnProps;

const MyChatMessage: FunctionComponent<Props> = ({ userInfo, message }) => {
  return (
    <>
      <Col className="mb-1" lg={1} />
      <Col className="mb-1 px-0" lg={10}>
        <div className="chatMessageDate">
          {getDifferenceWithTime(message.created)}
        </div>
        <div className="myChatRow">
          <div className="myChatMessage">
            {message.text}
            <div>
              {message.attachments?.map((att) => (
                <a
                  className="blueTitle mr-3"
                  href={att.attachment_url}
                  key={att.id}
                >
                  <FontAwesomeIcon icon={faFile} className="mr-1" />
                  {att.title}
                </a>
              ))}
            </div>
          </div>
        </div>
      </Col>
      <Col className="mb-1 pl-1" lg={1}>
        <Image
          className="mt-2"
          src={userInfo.profile.profile_image_url}
          width={30}
          height={30}
          roundedCircle
        />
      </Col>
    </>
  );
};

export default MyChatMessage;
