import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faFile,
  faPlusCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { ChecklistItem } from "../../../../../../../../store/onboarding/cases/types";

type OwnProps = {
  interventionName: string;
  checkListItems: Array<ChecklistItem>;
  setCheckListItems: React.Dispatch<React.SetStateAction<Array<ChecklistItem>>>;
  setSelectedCheckListItem: (
    checkListItem: ChecklistItem & { index?: number }
  ) => void;
  onOpenEditStepForm: () => void;
  onClickNext: () => void;
};

type Props = OwnProps;

const InterventionSteps: FunctionComponent<Props> = ({
  interventionName,
  checkListItems,
  setCheckListItems,
  setSelectedCheckListItem,
  onOpenEditStepForm,
  onClickNext,
}) => {
  const initChecklistItem = {
    index: 0,
    order: 1,
    is_active: true,
    title: "",
    details: "",
    attachments: [],
  };

  const handleStepAdd = () => {
    setSelectedCheckListItem({
      ...initChecklistItem,
      index: checkListItems.length,
      order:
        checkListItems.reduce((pV, cV) => (pV < cV.order ? cV.order : pV), 0) +
        1,
    });
    onOpenEditStepForm();
  };

  const handleStepEdit = (step: ChecklistItem, index: number) => {
    setSelectedCheckListItem({ ...step, index });
    onOpenEditStepForm();
  };

  const handleStepDelete = (step: ChecklistItem) => {
    setCheckListItems((checkList: Array<ChecklistItem>) =>
      checkList.filter((x) => x.order !== step.order)
    );
  };

  const checkListItemsSort = (a: ChecklistItem, b: ChecklistItem) => {
    return a.order - b.order;
  };

  const handleDragStart = (checkListItem: ChecklistItem) => (
    e: React.DragEvent<HTMLDivElement>
  ) => {
    e.dataTransfer.setData("checkListItem", JSON.stringify(checkListItem));
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (checkListOverItem: ChecklistItem) => (
    event: React.DragEvent<HTMLDivElement>
  ) => {
    const data = event.dataTransfer.getData("checkListItem");
    if (data) {
      const checkListItem: ChecklistItem = JSON.parse(data);
      if (checkListOverItem.order === checkListItem.order) return;
      setCheckListItems((checkList: Array<ChecklistItem>) => {
        return checkList.sort(checkListItemsSort).map((ci) => {
          if (ci.order === checkListItem.order) {
            return { ...ci, order: checkListOverItem.order };
          }
          if (
            (ci.order > checkListItem.order &&
              ci.order > checkListOverItem.order) ||
            (ci.order < checkListItem.order &&
              ci.order < checkListOverItem.order)
          ) {
            return ci;
          } else if (
            ci.order < checkListItem.order &&
            ci.order >= checkListOverItem.order
          ) {
            return { ...ci, order: ci.order + 1 };
          } else {
            return { ...ci, order: ci.order - 1 };
          }
        });
      });
    }
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {};

  return (
    <div className="interventionModalContent">
      {!!checkListItems.length && (
        <h1 className="purpleText text-center">You can edit this steps.</h1>
      )}
      <h2 className="text-center">
        Tell me the steps to your intervention strategy.
      </h2>
      <div className="interventionStepsContainer">
        <div className="interventionStepsContainerHeader">
          <div>
            <p className="purpleText">
              Steps to implement{" "}
              <span className="font-weight-bold">{interventionName}</span>
            </p>
            <p>You can drag and drop the steps to reorder them.</p>
          </div>
          {!!checkListItems.length && (
            <div onClick={handleStepAdd}>
              <FontAwesomeIcon icon={faPlusCircle} size="2x" />
            </div>
          )}
        </div>
        {checkListItems.length ? (
          <div className="interventionStepsContainerBody">
            {checkListItems.sort(checkListItemsSort).map((item, index) => (
              <div
                className="interventionStepItem"
                key={index}
                draggable
                onDragStart={handleDragStart(item)}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                onDrop={handleDrop(item)}
              >
                <div>
                  <h3>{index + 1}</h3>
                </div>
                <div>
                  <h3 className="font-weight-bold">{item.title!}</h3>
                  <p>{item.details!}</p>
                  <div className="interventionStepFileContainer">
                    {item.attachments.map((attachment) => (
                      <span
                        className="interventionStepFile"
                        key={attachment.id}
                      >
                        <FontAwesomeIcon icon={faFile} size="1x" />
                        &nbsp; {attachment.title}
                      </span>
                    ))}
                  </div>
                </div>
                <div>
                  <span onClick={() => handleStepEdit(item, index)}>
                    <FontAwesomeIcon icon={faEdit} size="1x" />
                  </span>
                  <span>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      size="1x"
                      onClick={() => handleStepDelete(item)}
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="interventionStepsContainerBodyEmpty">
            <h3 className="font-weight-bold">
              You haven't added any steps yet.
            </h3>
            <p>Let's get started.</p>
            <button
              className="whiteBtnXL"
              onClick={() => {
                setSelectedCheckListItem(initChecklistItem);
                onOpenEditStepForm();
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} size="3x" />
              <h3>Add Step 1</h3>
              <p className="m-0">
                We’ll ask you to give the step a title, describe it, and provide
                any supplemental materials
              </p>
            </button>
          </div>
        )}
      </div>
      <hr />
      {checkListItems.length < 2 ? (
        <p className="text-right mt-4">Add at least 2 steps to continue</p>
      ) : (
        <div className="modalActions">
          <div />
          <button className="blueBtnSm" onClick={onClickNext}>
            Continue
          </button>
        </div>
      )}
    </div>
  );
};

export default InterventionSteps;
