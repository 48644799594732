import { Container, Nav } from "react-bootstrap";
import React from "react";

enum TabKeys {
  FIRST = "first",
  SECOND = "second",
  THIRD = "third",
}

const NavPanel = () => {
  return (
    <Container>
      <Nav
        data-cy="learn-more-tabs-navigation"
        variant="pills"
        className="justify-content-center text-center"
      >
        <Nav.Item>
          <Nav.Link eventKey={TabKeys.FIRST}>
            <div>What You Get</div>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={TabKeys.SECOND}>
            <div>How It Works</div>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={TabKeys.THIRD}>
            <div>The Heart &amp; Science Behind It</div>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Container>
  );
};

export default NavPanel;
