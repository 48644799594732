import React, { FunctionComponent } from "react";
import { useParams } from "react-router";
import SpellingTestGroup from "./SpellingTestGroup";
import SpellingTestGroupsPage from "./spelling-test-groups/SpellingTestGroupsPage";

type Props = {};

const SpellingTestGroupsTab: FunctionComponent<Props> = (props) => {
  const { testGroupId } = useParams<{ testGroupId?: string }>();

  if (!testGroupId) {
    return <SpellingTestGroupsPage />;
  } else {
    return <SpellingTestGroup />;
  }
};

export default SpellingTestGroupsTab;
