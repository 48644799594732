import React, { FunctionComponent, useMemo } from "react";
import Select from "react-select";
import { ReactSelectOption } from "../../../../../store/onboarding/types";
import { GoalTypes } from "../../../../../store/onboarding/cases/types";

type OwnProps = {
  value?: string;
  onChange: (value: GoalTypes) => void;
};

type Props = OwnProps;

const GoalTypeSelect: FunctionComponent<Props> = ({ value, onChange }) => {
  const options: Array<ReactSelectOption<GoalTypes>> = useMemo(
    () => [
      { value: GoalTypes.BEHAVIOR, label: "Behavior" },
      { value: GoalTypes.ACADEMIC, label: "Academic" },
    ],
    []
  );

  return (
    <Select
      id={"goal-type-selector"}
      placeholder="Goal type..."
      styles={{
        container: (styles) => ({
          ...styles,
          minWidth: 120,
          margin: "5px",
        }),
        menu: (styles) => ({ ...styles, zIndex: 100 }),
      }}
      value={options.find((o) => o.value === value)}
      onChange={(v) =>
        v ? onChange((v as ReactSelectOption<GoalTypes>).value) : undefined
      }
      options={options}
    />
  );
};

export default GoalTypeSelect;
