import React from "react";
import { ColorResult, SketchPicker } from "react-color";

type Props = {
  readonly?: boolean;
  displayColorPicker: boolean;
  color?: string;
  handleColorPickerClick: () => any;
  handleColorPickerClose: () => any;
  handleColorPickerChange: (color: ColorResult) => any;
};

const SketchColorPicker = (props: Props) => {
  return (
    <div>
      <div
        style={{ cursor: props.readonly ? "default" : undefined }}
        className="sketchColorPickerSwatch"
        onClick={props.handleColorPickerClick}
      >
        <div
          style={{
            background: props.color,
            cursor: "inherit",
          }}
          className="sketchColorPickerColor"
        />
      </div>
      {!props.readonly && props.displayColorPicker ? (
        <div className="sketchColorPickerPopover">
          <div
            className="sketchColorPickerCover"
            onClick={props.handleColorPickerClose}
          />
          <SketchPicker
            color={props.color}
            onChange={props.handleColorPickerChange}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SketchColorPicker;
