import React, { useMemo } from "react";
import {
  BehaviorTally,
  FbaABC,
  FbaAntecedent,
  FbaAntecedentDisplayed,
  FbaBehavior,
  FbaBehaviorDisplayed,
  FbaConsequence,
  FbaConsequenceDisplayed,
  FbaConsequenceFunctions,
} from "../../../../../store/onboarding/cases/types";
import _ from "lodash";

const ANTECEDENTS_PART_COUNT = 3;

export const useAbcNaming = (abc: FbaABC) => {
  const antecedent: React.ReactNode = useAntecedentNaming(
    abc.antecedents,
    abc.other_antecedents
  );

  const behaviors = useBehaviorsNaming(abc.behaviors, abc.other_behavior);

  const consequence = useConsequenceNaming(abc.consequence, abc.other_sequence);

  return {
    antecedent,
    behaviors,
    consequence,
  };
};

export const getAntecedentNaming = (
  antecedents: number[] | undefined,
  other_antecedents?: string[],
  asSting?: boolean
) => {
  if (antecedents?.length === ANTECEDENTS_PART_COUNT) {
    const target =
      antecedents[0] === FbaAntecedent.TargetOther && other_antecedents
        ? other_antecedents[0]
        : FbaAntecedentDisplayed[antecedents[0]];
    const phraseStart =
      antecedents[0] === FbaAntecedent.TargetAlone
        ? "Being"
        : "Interacted with";
    const place =
      antecedents[1] === FbaAntecedent.PlaceOther && other_antecedents
        ? other_antecedents[1]
        : FbaAntecedentDisplayed[antecedents[1]];
    const partWhile =
      antecedents[2] === FbaAntecedent.ActionOther && other_antecedents
        ? other_antecedents[2]
        : FbaAntecedentDisplayed[antecedents[2]];

    if (asSting) {
      return `${phraseStart} ${target} while ${partWhile} in the ${place}`;
    }

    return (
      <>
        {phraseStart} <strong>{target}</strong> while{" "}
        <strong>{partWhile}</strong> in the <strong>{place}</strong>
      </>
    );
  }

  return null;
};

export const useAntecedentNaming = (
  antecedents: number[] | undefined,
  other_antecedents?: string[],
  asSting?: boolean
) => {
  return useMemo(() => {
    return getAntecedentNaming(antecedents, other_antecedents, asSting);
  }, [antecedents, other_antecedents]);
};

export const getBehaviorNaming = (
  behaviors: number[] | number,
  other_behavior?: string
) => {
  return Array.isArray(behaviors)
    ? behaviors
        .map((b) =>
          b === FbaBehavior.BEHAVIOR_OTHER && other_behavior
            ? other_behavior
            : FbaBehaviorDisplayed[b]
        )
        .join(", ")
    : behaviors === FbaBehavior.BEHAVIOR_OTHER && other_behavior
    ? other_behavior
    : FbaBehaviorDisplayed[behaviors];
};

export const useBehaviorsNaming = (
  behaviors: number[] | number,
  other_behavior?: string
) => {
  return useMemo(() => {
    return getBehaviorNaming(behaviors, other_behavior);
  }, [other_behavior, behaviors]);
};

export const getConsequenceNaming = (
  consequences?: number[] | number,
  other_consequence?: string
) => {
  if (consequences === undefined) {
    return null;
  }

  return Array.isArray(consequences)
    ? consequences.map((consequence) =>
        (consequence === FbaConsequence.CONSEQUENCE_AVOIDED_OTHER ||
          consequence === FbaConsequence.CONSEQUENCE_RECEIVED_OTHER) &&
        other_consequence
          ? { consequence: other_consequence, function: null }
          : {
              consequence: FbaConsequenceDisplayed[consequence],
              function: FbaConsequenceFunctions[consequence],
            }
      )
    : (consequences === FbaConsequence.CONSEQUENCE_AVOIDED_OTHER ||
        consequences === FbaConsequence.CONSEQUENCE_RECEIVED_OTHER) &&
      other_consequence
    ? [{ consequence: other_consequence, function: null }]
    : [
        {
          consequence: FbaConsequenceDisplayed[consequences],
          function: FbaConsequenceFunctions[consequences],
        },
      ];
};

export const useConsequenceNaming = (
  consequences?: number[] | number,
  other_consequence?: string
) => {
  return useMemo(() => {
    return getConsequenceNaming(consequences, other_consequence);
  }, [consequences, other_consequence]);
};

export const usePrevCustomRecords = (
  observationabc: FbaABC[],
  abcField: keyof FbaABC,
  customValue?: string
) => {
  const previousCustomRecords: string[] = useMemo(() => {
    return _.chain(observationabc)
      .reduce<string[]>((pV, cV) => {
        return cV[abcField] ? [...pV, cV[abcField] as string] : pV;
      }, [])
      .sortedUniq()
      .value();
  }, []);

  return useMemo(() => {
    return customValue
      ? [customValue, ...previousCustomRecords]
      : [...previousCustomRecords];
  }, [customValue, previousCustomRecords]);
};

export const useHypothesis = ({
  studentName,
  behTally,
  selectedAntecedent,
  selectedConsequence,
}: {
  studentName: string;
  behTally: BehaviorTally;
  selectedAntecedent:
    | { abcId: number; antecedents: number[]; other_antecedents?: string[] }
    | undefined;
  selectedConsequence:
    | { abcId: number; consequence: number; other_sequence?: string }
    | undefined;
}) => {
  const antecedent = useAntecedentNaming(
    selectedAntecedent?.antecedents,
    selectedAntecedent?.other_antecedents,
    true
  );

  const behavior = useBehaviorsNaming(
    behTally.behavior,
    behTally.other_behavior
  );

  const consequence = useConsequenceNaming(
    selectedConsequence?.consequence,
    selectedConsequence?.other_sequence
  );

  const hypothesis = useMemo(() => {
    if (antecedent && consequence) {
      const firstConsequence = consequence[0];
      let description = firstConsequence.consequence;
      if (description && description.length > 0) {
        description =
          description.charAt(0).toLowerCase() + description.slice(1);
      }
      return `When ${antecedent}, ${studentName} exhibits ${behavior} in order to be ${description} (${firstConsequence.function})`;
    } else if (antecedent) {
      return `When ${antecedent}, ${studentName} exhibits ${behavior}`;
    } else if (consequence) {
      const firstConsequence = consequence[0];
      let description = firstConsequence.consequence;
      if (description && description.length > 0) {
        description =
          description.charAt(0).toLowerCase() + description.slice(1);
      }
      return `When ${studentName} exhibits ${behavior}, he/she does so in order to be ${description} (${firstConsequence.function})`;
    } else {
      return `Unknown Hypothesis`;
    }
  }, [antecedent, behavior, consequence]);

  return {
    hypothesis,
    antecedent,
    behavior,
    consequence,
  };
};
