import React, { FunctionComponent, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import ConfirmPopup from "./ConfirmPopup";
import {
  addInterventionsToGroup,
  openInterventionLibraryModal,
} from "../../../../../../store/onboarding/cases/actions";

type StateProps = {
  isLoading: {
    addInterventionsToGroup: boolean;
  };
};
type DispatchProps = {
  addInterventionsToGroup: (
    interventionGroupId: number,
    interventionIds: Array<number>
  ) => any;
  openInterventionLibraryModal: () => any;
};

type OwnProps = {
  interventionGroup?: InterventionGroup;
};

type Props = OwnProps & StateProps & DispatchProps;

const GroupInterventionsTable: FunctionComponent<Props> = ({
  isLoading,
  interventionGroup,
  addInterventionsToGroup,
  openInterventionLibraryModal,
}) => {
  const [selectedInterventionId, setSelectedInterventionId] = useState<
    number | undefined
  >(undefined);

  const columns = useMemo(
    () => [
      {
        dataField: "intervention",
        text: "Intervention",
        sort: true,
      },
      {
        headerStyle: { width: "10%" },
        dataField: "actions",
        text: "",
        // eslint-disable-next-line react/display-name
        headerFormatter: () => {
          return (
            <span
              className="pointer"
              onClick={(e) => {
                e.preventDefault();
                openInterventionLibraryModal();
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </span>
          );
        },
      },
    ],
    []
  );

  const handleInterventionRemove = (interventionId: number) => {
    if (
      interventionGroup?.interventions &&
      interventionGroup.interventions.length > 1
    ) {
      setSelectedInterventionId(interventionId);
    }
  };

  const handleInterventionRemoveConfirm = () => {
    addInterventionsToGroup(
      interventionGroup?.id!,
      (interventionGroup?.interventions || [])
        .map((i) => i.id)
        .filter((i) => i !== selectedInterventionId)
    ).then(() => setSelectedInterventionId(undefined));
  };

  const data = useMemo(
    () =>
      interventionGroup?.interventions?.map((intervention) => ({
        id: intervention.id,
        intervention: intervention.name,
        actions: (
          <div>
            <span onClick={() => handleInterventionRemove(intervention.id)}>
              <FontAwesomeIcon icon={faTrashAlt} size="lg" />
            </span>
          </div>
        ),
      })) ?? [],
    [interventionGroup]
  );

  return (
    <>
      <BootstrapTable
        classes="rosterTable"
        keyField="id"
        bordered={false}
        bootstrap4
        data={data}
        columns={columns}
      />
      {selectedInterventionId && (
        <ConfirmPopup
          title="Are you sure you want to remove this intervention from the list?"
          isLoading={isLoading.addInterventionsToGroup}
          onHide={() => setSelectedInterventionId(undefined)}
          onConfirm={handleInterventionRemoveConfirm}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ cases }: ApplicationState): StateProps => {
  return {
    isLoading: {
      addInterventionsToGroup: cases.isLoading.addInterventionsToGroup,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      addInterventionsToGroup: addInterventionsToGroup,
      openInterventionLibraryModal: openInterventionLibraryModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupInterventionsTable);
