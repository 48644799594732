import { Dispatch } from "redux";
import axios, { AxiosError } from "axios";
import { API_SKILL_FOCUSES } from "../../constants";
import {
  GroupsActionTypes,
  SkillFocusArea,
  GroupingStructure,
  Identifier,
} from "./types";
import { action } from "typesafe-actions";
import { Assessment, EvidenceColumn, Student, StudentEntry } from "../onboarding/types";

export const openMergeDataModal = (assessment: Assessment) =>
  action(GroupsActionTypes.OPEN_MERGE_DATA_MODAL, { assessment });
export const hideMergeDataModal = () =>
  action(GroupsActionTypes.HIDE_MERGE_DATA_MODAL);

export const openSkillFocusesModal = (studentEntry?: StudentEntry) =>
  action(GroupsActionTypes.OPEN_SKILL_FOCUSES_MODAL, { studentEntry });
export const hideSkillFocusesModal = () =>
  action(GroupsActionTypes.HIDE_SKILL_FOCUSES_MODAL);

export const openBehaviorScreenerModal = (
    evidenceColumnGroupId: number,
    evidenceColumn: EvidenceColumn,
    student: Student,
    //studentEntry?: StudentEntry
  ) =>
  action(GroupsActionTypes.OPEN_BEHAVIOR_SCREENER_MODAL, { 
    evidenceColumnGroupId,
    evidenceColumn,
    student,
    //studentEntry 
  });
export const hideBehaviorScreenerModal = () =>
  action(GroupsActionTypes.HIDE_BEHAVIOR_SCREENER_MODAL);

export const openConfirmDiagnosisModal = (studentEntry?: StudentEntry) =>
  action(GroupsActionTypes.OPEN_CONFIRM_DIAGNOSIS_MODAL, { studentEntry });
export const hideConfirmDiagnosisModal = () =>
  action(GroupsActionTypes.HIDE_CONFIRM_DIAGNOSIS_MODAL);
export const acceptConfirmDiagnosisModal = () =>
  action(GroupsActionTypes.ACCEPT_CONFIRM_DIAGNOSIS_MODAL);

export const clearSelectedAssessment = () =>
  action(GroupsActionTypes.CLEAR_SELECTED_ASSESSMENT);

export const getSkillFocusesRequest = () =>
  action(GroupsActionTypes.GET_SKILL_FOCUSES_START);
export const getSkillFocusesSuccess = (
  skillFocusesInfo: Array<SkillFocusArea>,
  identifiersInfo: { [identifier: string]: Identifier },
  grouping: GroupingStructure
) =>
  action(GroupsActionTypes.GET_SKILL_FOCUSES_SUCCESS, {
    skillFocusesInfo,
    identifiersInfo,
    grouping,
  });
export const getSkillFocusesFailure = (message: string) =>
  action(GroupsActionTypes.GET_SKILL_FOCUSES_FAILURE, { error: message });

export const getSkillFocuses = () => {
  return (dispatch: Dispatch) => {
    dispatch(getSkillFocusesRequest());
    return axios
      .get(API_SKILL_FOCUSES)
      .then((res) => {
        const skillFocusesInfo: Array<SkillFocusArea> =
          res.data.skills_structure.areas;
        const identifiersInfo: { [identifier: string]: Identifier } =
          res.data.identifiers;
        const grouping: GroupingStructure = res.data.grouping;

        dispatch(
          getSkillFocusesSuccess(skillFocusesInfo, identifiersInfo, grouping)
        );
      })
      .catch((err: AxiosError) => {
        dispatch(getSkillFocusesFailure(err.message));
      });
  };
};

export const openOverviewModel = (overviewModelType: string) => {
  return action(GroupsActionTypes.OPEN_OVERVIEW_MODAL, overviewModelType);
}

