import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  createDistrict,
  setShowDistrictModal,
  setShowUserModal,
} from "../../../../../../store/superuser/actions";
import {
  AuthState,
  UserAssignmentRole,
  UserInfo,
} from "../../../../../../store/auth/types";
import {
  District_Request,
  DistrictInfo,
  SuperuserState,
} from "../../../../../../store/superuser/types";
import { USState, USStateNamesValues } from "../../../../../common/States";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import CreatableSelect from "react-select/creatable";
import DistrictOwnerSelector from "../DistrictOwnerSelector";

const defaultValue: District_Request = {
  name: "",
  state: "",
};

const CreateDistrictModal = () => {
  const {
    modalsState: { showDistrictModal },
    isLoading: { createDistrict: isLoading },
  } = useSelector<ApplicationState, SuperuserState>((s) => s.superuser);

  const dispatch = useDispatch();

  const [checkError, setCheckError] = useState<boolean>(false);
  const [district, setDistrict] = useState<District_Request>(defaultValue);

  useEffect(() => {
    if (showDistrictModal) {
      setDistrict(defaultValue);
      setCheckError(false);
    }
  }, [showDistrictModal]);

  const handleClose = () => {
    if (!isLoading) {
      dispatch(setShowDistrictModal({ show: false, district: null }));
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (district.name && district.state && district.owner != null) {
      await dispatch(createDistrict(district));
      dispatch(setShowDistrictModal({ show: false, district: null }));
    } else {
      setCheckError(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const key = event.currentTarget.name as keyof DistrictInfo;
    setDistrict((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleChangeState = (value: ValueType<USState, false>) => {
    const typedValue = value as USState;
    setDistrict((prevState) => ({ ...prevState, state: typedValue.value }));
  };

  return (
    <Modal
      show={showDistrictModal}
      onHide={handleClose}
      animation={false}
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="purpleModalHeader font-weight-bold">
        <Modal.Title>Create District</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="districtModalForm" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label className="font-weight-bold" column={false}>
              Name
            </Form.Label>
            <Form.Control
              required
              placeholder="Enter name..."
              name="name"
              onChange={handleChange}
              value={district.name}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="font-weight-bold" column={false}>
              State
            </Form.Label>
            <Select
              required
              placeholder="Select State..."
              name="state"
              value={
                USStateNamesValues?.find((s) => s.value === district.state) ??
                null
              }
              options={USStateNamesValues}
              getOptionLabel={({ display_name }: USState) => display_name}
              getOptionValue={({ value }: USState) => value}
              onChange={handleChangeState}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="font-weight-bold" column={false}>
              Owner
            </Form.Label>
            <DistrictOwnerSelector
              district={district}
              setDistrict={setDistrict}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {checkError && (
          <span style={{ color: "red" }}>
            Please fill all fields and try again.
          </span>
        )}
        <button
          className="blueBtnSm"
          type="submit"
          form="districtModalForm"
          disabled={isLoading}
        >
          Create {isLoading && <Spinner animation="border" size="sm" />}
        </button>
        <button
          className="whiteBtnSm"
          type="reset"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateDistrictModal;
