import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { ApplicationState } from "../../../../../../store";
import { EvidencePeriodData } from "../../../../../../store/onboarding/types";
import { connect, useSelector } from "react-redux";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { push } from "connected-react-router";
import { bindActionCreators, Dispatch } from "redux";
import GatherEvidenceModal from "./GatherEvidenceModal";
import GroupStudentsModal from "./GroupStudentsModal";
import TrackInterventionModal from "./TrackInterventionModal";
import {
  faFolderPlus,
  faUsers,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";
import { hideThreeStepProgress } from "../../../../../../store/onboarding/cases/actions";
import StepItem from "./StepItem";

type StateProps = {
  showThreeStepProgress?: boolean;
  evidencePeriodData?: EvidencePeriodData;
  interventionGroups: Array<InterventionGroup>;
  isLoading: {
    getEvidenceData?: boolean;
    getDataPeriods?: boolean;
    getInterventionGroups?: boolean;
  };
};

type DispatchProps = {
  hideThreeStepProgress: () => any;
  push: (location: string) => any;
};

export enum Steps {
  GatherEvidence,
  GroupStudents,
  TrackIntervention,
  Completed,
}

type Props = StateProps & DispatchProps;

const ThreeStepGuide: FunctionComponent<Props> = (props) => {
  const tabName = useSelector<
          ApplicationState
        >((s) => s.cases.tabName);

  const hasData: boolean = useMemo(() => {
    if (props.evidencePeriodData) {
      return !!props.evidencePeriodData?.student_rows.length;
    }
    return false;
  }, [props.evidencePeriodData]);

  const hasGroups: boolean = useMemo(() => {
    return !!props.interventionGroups.length;
  }, [props.interventionGroups]);

  const hasLoggedData: boolean = useMemo(() => {
    return props.interventionGroups.some((ig) => ig.latest_activity);
  }, [props.interventionGroups]);

  const [showGatherEvidenceModal, setShowGatherEvidenceModal] = useState(false);
  const [showGroupStudentsModal, setShowGroupStudentsModal] = useState(false);
  const [showTrackInterventionModal, setShowTrackInterventionModal] = useState(
    false
  );

  const currentStep = useMemo(() => {
    if (!hasData) return Steps.GatherEvidence;
    if (!hasGroups) return Steps.GroupStudents;
    if (!hasLoggedData) return Steps.TrackIntervention;
    return Steps.Completed;
  }, [hasData, hasGroups, hasLoggedData]);

  const handleGatherEvidenceClick = () => {
    props.push("/data");
    setShowGatherEvidenceModal(true);
  };

  const handleGroupStudentsClick = () => {
    props.push("/data");
    setShowGroupStudentsModal(true);
  };

  const handleTrackInterventionClick = () => {

    if(tabName == 'groups/behavior')
      props.push(`/cases/groups/behavior`);

  if(tabName == 'groups/reading')
      props.push(`/cases/groups/reading`);

    //props.push("/cases/groups");
    setShowTrackInterventionModal(true);
  };

  const steps: any[] = useMemo(() => {
    return [
      {
        step: Steps.GatherEvidence,
        icon: faFolderPlus,
        title: "Gather Evidence",
        details: "Student Data or observations are used to support grouping",
        completed: hasData,
        onClick: handleGatherEvidenceClick,
      },
      {
        step: Steps.GroupStudents,
        icon: faUsers,
        title: "Group Students",
        details:
          "Manually group students or use reading data to get recommendations",
        completed: hasGroups,
        onClick: handleGroupStudentsClick,
      },
      {
        step: Steps.TrackIntervention,
        icon: faChartBar,
        title: "Track Intervention",
        details:
          "Meet with your teachers, track their intervention fidelity, make decisions",
        completed: hasLoggedData,
        onClick: handleTrackInterventionClick,
      },
    ];
  }, [hasData, hasGroups, hasLoggedData]);

  useEffect(() => {
    if (props.showThreeStepProgress && hasData && hasGroups && hasLoggedData) {
      props.hideThreeStepProgress();
    }
  }, [props.showThreeStepProgress, hasData, hasGroups, hasLoggedData]);

  if (
    !props.showThreeStepProgress ||
    (!hasData && props.isLoading.getEvidenceData) ||
    (!hasGroups && props.isLoading.getInterventionGroups) ||
    props.isLoading.getDataPeriods
  ) {
    return null;
  }

  return (
    <>
      <div data-cy="three-step-guide" className="threeStepGuideContainer mb-3">
        {steps.map((step, index) => (
          <StepItem
            key={`guide_step_${index}`}
            index={index}
            step={step.step}
            icon={step.icon}
            title={step.title}
            details={step.details}
            completed={step.completed}
            onClick={step.onClick}
            currentStep={currentStep}
          />
        ))}
      </div>
      <GatherEvidenceModal
        showModal={showGatherEvidenceModal}
        onHide={() => setShowGatherEvidenceModal(false)}
      />
      <GroupStudentsModal
        showModal={showGroupStudentsModal}
        onHide={() => setShowGroupStudentsModal(false)}
      />
      <TrackInterventionModal
        showModal={showTrackInterventionModal}
        onHide={() => setShowTrackInterventionModal(false)}
      />
    </>
  );
};

const mapStateToProps = ({
  onboarding,
  cases,
}: ApplicationState): StateProps => {
  return {
    showThreeStepProgress: cases.showThreeStepProgress,
    evidencePeriodData: onboarding.evidencePeriodData,
    interventionGroups: cases.interventionGroups,
    isLoading: {
      getDataPeriods: onboarding.isLoading.getDataPeriods,
      getEvidenceData: onboarding.isLoading.getEvidenceInDatePeriod,
      getInterventionGroups: cases.isLoading.getInterventionGroups,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hideThreeStepProgress: hideThreeStepProgress,
      push: push,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ThreeStepGuide);
