import React, { ChangeEvent, Component } from "react";
import Modal from "react-bootstrap/Modal";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  hideDataSharingEditModal,
  updateDataSharingTableData,
} from "../../../../../store/onboarding/actions";
import {
  DataSharingTableData,
  GradeLevels,
  ReactSelectOption,
  School,
  TeacherInfo,
} from "../../../../../store/onboarding/types";
import Select from "react-select";
import { Grade } from "../../../../../store/groups/types";
import { ValueType } from "react-select/src/types";
import { Spinner } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { IS_READY_COACH } from "../../../../../constants";

type OwnProps = {
  selectedUser?: DataSharingTableData;
};

type PropsFromState = {
  isLoading: boolean;
  errors?: string;
  teachers: TeacherInfo[];
  schools: School[];
  showModal: boolean;
};

type DispatchProps = {
  hideDataSharingEditModal: () => any;
  updateDataSharingTableData: (
    id: number,
    data: {
      grades: Grade[];
      teachers: number[];
      schools: number[];
      all_data: boolean;
    }
  ) => any;
};

type Props = OwnProps & PropsFromState & DispatchProps;

type State = {
  user?: DataSharingTableData;
  gradesFilter: Grade[];
  teachersFilter: number[];
  schoolsFilter: number[];
  isAllData: boolean;
};

class DataSharingEditModal extends Component<Props> {
  state: Readonly<State> = {
    user: undefined,
    gradesFilter: [],
    teachersFilter: [],
    schoolsFilter: [],
    isAllData: false,
  };

  componentDidMount() {
    //console.log('this.props.selectedUser', this.props.selectedUser)
    if (this.props.selectedUser ) {
      const { selectedUser } = this.props;
      this.setState({
        user: selectedUser,
        gradesFilter: selectedUser.grades,
        schoolsFilter:
          selectedUser && selectedUser.schools
            ? selectedUser.schools.map((school) => school.id)
            : [],
        teachersFilter: selectedUser.teachers.map((teacher) => teacher.id),
        isAllData: selectedUser.all_data,
      });
    }
    // if (!this.props.isLoading) {
    //   if (this.props.errors) {
    //     toastr.error("Error!", this.props.errors);
    //   } else {
    //     this.props.hideDataSharingEditModal();
    //   }
    // }
  }

  componentDidUpdate(
    prevProps: Readonly<OwnProps & PropsFromState & DispatchProps>,
    prevState: Readonly<{}>
  ): void {
    // console.log('selectedUser', this.props.selectedUser)
    // console.log('prevProps', prevProps.selectedUser)
    // if (
    //   this.props.selectedUser &&
    //   prevProps.selectedUser !== this.props.selectedUser
    // ) {
    //   const { selectedUser } = this.props;
    //   this.setState({
    //     user: selectedUser,
    //     gradesFilter: selectedUser.grades,
    //     schoolsFilter:
    //       selectedUser && selectedUser.schools
    //         ? selectedUser.schools.map((school) => school.id)
    //         : [],
    //     teachersFilter: selectedUser.teachers.map((teacher) => teacher.id),
    //     isAllData: selectedUser.all_data,
    //   });
    // }
    if (prevProps.isLoading && !this.props.isLoading) {
      if (this.props.errors) {
        toastr.error("Error!", this.props.errors);
      } else {
        this.props.hideDataSharingEditModal();
      }
    }
  }

  handleGradeLevelChange = (
    options: ValueType<ReactSelectOption<Grade>, true>
  ) => {
    if (options && (options as ReactSelectOption<Grade>[]).length > 0) {
      this.setState({
        gradesFilter: (options as ReactSelectOption<Grade>[]).map(
          (v) => v.value
        ),
      });
    } else {
      this.setState({ gradesFilter: [] });
    }
  };

  handleTeacherChange = (options: ValueType<TeacherInfo, true>) => {
    if (options && (options as TeacherInfo[]).length > 0) {
      this.setState({
        teachersFilter: (options as TeacherInfo[]).map((t) => t.user.id),
      });
    } else {
      this.setState({ teachersFilter: [] });
    }
  };

  handleSchoolChange = (options: ValueType<School, true>) => {
    if (options && (options as School[]).length > 0) {
      this.setState({
        schoolsFilter: (options as School[]).map((sc) => sc.id),
      });
    } else {
      this.setState({ schoolsFilter: [] });
    }
  };

  handleChangeAllData = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      isAllData: checked,
    }));
  };

  handleHideModal = () => {
    this.props.hideDataSharingEditModal();
  };

  handleUpdateData = () => {
    const {
      user,
      gradesFilter,
      teachersFilter,
      schoolsFilter,
      isAllData,
    } = this.state;
    if (user) {
      const data = {
        grades: gradesFilter,
        teachers: teachersFilter,
        schools: schoolsFilter,
        all_data: isAllData,
      };
      this.props.updateDataSharingTableData(user.id, data);
      
    }
  };

  render() {
    const { user } = this.state;
    return (
      <div>
        <Modal
          show={this.props.showModal}
          onHide={this.handleHideModal}
          animation={false}
          size="lg"
          backdropClassName={"customDarkModalBackdrop in"}
        >
          <Modal.Header
            closeButton
            className={
              IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"
            }
          >
            <Modal.Title>
              Edit data sharing access for{" "}
              {user ? (
                <span className="font-weight-bold">
                  {user.user.first_name + " " + user.user.last_name}
                </span>
              ) : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {user ? (
              <div>
                <h2>
                  Edit which teachers&apos; data and grade levels{" "}
                  {user.user.first_name +
                    " " +
                    user.user.last_name}{" "}
                  has access to
                </h2>
                <div className="d-flex flex-column m-3">
                  <div>
                    <span className="font-weight-bold">
                      ACCESS TO THESE TEACHERS&apos; DATA
                    </span>
                    <Select
                      isMulti
                      isClearable
                      isDisabled={this.state.isAllData}
                      className="dataFiltersSelect"
                      value={this.props.teachers.filter((x) =>
                        this.state.teachersFilter.includes(x.user.id)
                      )}
                      options={this.props.teachers}
                      getOptionLabel={({ user }: TeacherInfo) =>
                        `${user.first_name} ${user.last_name}`
                      }
                      getOptionValue={({ user }: TeacherInfo) =>
                        user.id!.toString()
                      }
                      onChange={this.handleTeacherChange}
                    />
                  </div>
                  <div className="w-100 mb-2">
                    <span className="font-weight-bold">
                      ACCESS TO THESE GRADE LEVELS
                    </span>
                    <Select
                      isMulti
                      isClearable
                      isDisabled={this.state.isAllData}
                      className="dataFiltersSelect"
                      value={GradeLevels.filter((gl) =>
                        this.state.gradesFilter.includes(gl.value)
                      )}
                      options={GradeLevels}
                      onChange={this.handleGradeLevelChange}
                    />
                  </div>
                  <div className="w-100 mb-2">
                    <span className="font-weight-bold">
                      ACCESS TO THESE SCHOOLS
                    </span>
                    <Select
                      isMulti
                      isClearable
                      isDisabled={this.state.isAllData}
                      className="dataFiltersSelect"
                      value={this.props.schools.filter((x) =>
                        this.state.schoolsFilter.includes(x.id)
                      )}
                      options={this.props.schools}
                      getOptionLabel={(school: School) => school.name}
                      getOptionValue={(school: School) => school.id.toString()}
                      onChange={this.handleSchoolChange}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center m-3">
                  <div>
                    <input
                      readOnly
                      type="checkbox"
                      name="isAllData"
                      checked={this.state.isAllData}
                      onChange={this.handleChangeAllData}
                    />{" "}
                    <span className="font-weight-bold mr-2">
                      SHARE ALL DATA
                    </span>
                  </div>
                  <div>
                    <div
                      className="blueBtnMd mr-2"
                      onClick={this.handleHideModal}
                    >
                      Cancel
                    </div>
                    <div className="blueBtnMd" onClick={this.handleUpdateData}>
                      Submit
                      {this.props.isLoading && (
                        <Spinner animation="border" size="sm" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    isLoading: onboarding.isLoading.updateDataSharingTableData,
    errors: onboarding.errors.updateDataSharingTableData,
    teachers: onboarding.teachersRoster,
    schools: onboarding.schools,
    showModal: onboarding.modalsState.dataSharingEditModal,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideDataSharingEditModal: hideDataSharingEditModal,
      updateDataSharingTableData: updateDataSharingTableData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataSharingEditModal);
