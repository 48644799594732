import React, { FunctionComponent, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { consequenceTypes } from "./types";
import FbaListItem from "./FbaListItem";
import _ from "lodash";
import { FbaABC } from "../../../../../../store/onboarding/cases/types";
import { usePrevCustomRecords } from "../hooks";

type Props = {
  observationabc: FbaABC[];
  consequence: number | undefined;
  setConsequence: React.Dispatch<React.SetStateAction<number | undefined>>;

  customConsequence: string | undefined;
  setCustomConsequence: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
};

const ConsequenceEvents: FunctionComponent<Props> = (props) => {
  const {
    observationabc,
    consequence,
    setConsequence,
    customConsequence,
    setCustomConsequence,
  } = props;

  const handleConsequenceChange = (fbaConsequence: number) => () => {
    setConsequence(fbaConsequence);
    setCustomConsequence(undefined);
  };

  const handleCustomConsequenceChange = (value?: string) => {
    setCustomConsequence(value);
  };

  const allCustomRecords: string[] = usePrevCustomRecords(
    observationabc,
    "other_sequence",
    customConsequence
  );

  return (
    <div>
      <h4 className="text-center font-weight-bold text-underline mb-3">
        Select one option from the columns below
      </h4>
      <Row>
        {consequenceTypes.map((fbaCg, index) => (
          <Col
            key={`header_column_${index}`}
            sm={12 / consequenceTypes.length}
            className="font-weight-bold"
          >
            {fbaCg.title}
          </Col>
        ))}
        {consequenceTypes.map((fbaCg, index) => (
          <Col
            key={`options_column_${index}`}
            sm={12 / consequenceTypes.length}
          >
            {fbaCg.items.map((item) => (
              <FbaListItem
                key={item.value}
                index={index}
                onChange={handleConsequenceChange}
                fbaItem={item}
                activeItem={consequence}
                customOption={customConsequence}
                onCustomOptionChange={handleCustomConsequenceChange}
                previousCustomRecords={allCustomRecords}
              />
            ))}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ConsequenceEvents;
