import React, { FunctionComponent, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faEdit,
  faGripVertical,
  faPen,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { Badge, Dropdown, Form, FormLabel, InputGroup } from "react-bootstrap";
import {
  Intervention,
  InterventionGroup,
  InterventionPlan,
  InterventionPlanItem,
  InterventionPlanStates,
  InterventionPlanStatesLabels,
} from "../../../../../../../store/onboarding/cases/types";
import { DraggableProvided } from "react-beautiful-dnd";
import {
  addInterventionsToGroup,
  hideInterventionPlanModal,
  openCreateNewInterventionModal,
  openInterventionLibraryModal,
  patchInterventionGroup,
  showInterventionPlanModal,
} from "../../../../../../../store/onboarding/cases/actions";
import { useDispatch, useSelector } from "react-redux";
import { removeFromInterventionPlan } from "../../../../../../utils";
import { showConfirmDialog } from "../../../../../../../store/confirm-dialog/actions";
import { Portal } from "react-portal";
import { initial } from "lodash";
import { count } from "console";
import { toastr } from "react-redux-toastr";
import { ApplicationState } from "../../../../../../../store";
import useInterventionGroupAccess from "../../../../../../../utils/hooks/useInterventionGroupAccess";

type Props = {
  provided: DraggableProvided;
  index: number;
  statePlan: InterventionPlan;
  intervention: Intervention;
  interventionGroup: InterventionGroup;
  isLoading?: boolean;
  setStatePlan: React.Dispatch<React.SetStateAction<InterventionPlan>>;
  hideDelete: boolean;
  isStudentDetail: boolean;
  isGroupDetail: boolean;
};

const CustomToggle = (interventionState?: InterventionPlanStates) =>
  React.forwardRef((props: any, ref: any) => (
    <Badge
      ref={ref}
      onClick={props.onClick}
      pill
      variant={
        interventionState === InterventionPlanStates.ACTIVE
          ? "primary"
          : interventionState === InterventionPlanStates.COMPLETED
          ? "success"
          : interventionState === InterventionPlanStates.DISCONTINUED
          ? "warning"
          : "secondary"
      }
      className="d-flex align-items-center justify-content-center pointer"
    >
      <h4 className="text-white mr-1">
        {interventionState
          ? InterventionPlanStatesLabels[interventionState]
          : "Not selected"}
      </h4>
      <FontAwesomeIcon
        style={{ color: "white" }}
        icon={props["aria-expanded"] ? faChevronUp : faChevronDown}
      />
    </Badge>
  ));

const InterventionElement: FunctionComponent<Props> = (props) => {
  const {
    provided,
    index,
    isLoading,
    statePlan,
    setStatePlan,
    intervention,
    interventionGroup,
    hideDelete,
    isStudentDetail,
    isGroupDetail
  } = props;
  const { interventions } = interventionGroup;

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(interventionGroup.id);

  const dispatch = useDispatch();

  const planItem = useMemo(
    () =>
      statePlan.plan_items?.find(
        (pi) => pi.intervention_id === intervention.id
      ),
    [statePlan.plan_items, intervention.id]
  );

  const handleDelete = (intervention: Intervention) => {
    if (interventions.length > 1) {
      dispatch(
        showConfirmDialog({
          onConfirm: () => onRemove(intervention),
          centered: true,
        })
      );
    } else {
      toastr.error(
        "Error",
        "This is the only intervention in the group. It cannot be deleted."
      );
    }
  };

  const onRemove = (intervention: Intervention) => {
    if (interventionGroup.id) {
      const changedPlan = removeFromInterventionPlan(
        intervention,
        interventions,
        statePlan
      );

      const changedInterventionsList = interventions.filter(
        (int) => int !== intervention
      );

      addInterventionsToGroup(
        interventionGroup.id,
        changedInterventionsList.map((int) => int.id)
      )(dispatch).then(() => {
        if (interventionGroup.id) {
          dispatch(
            patchInterventionGroup(interventionGroup.id, {
              intervention_plan: changedPlan,
            }, true)
          );
          setStatePlan(changedPlan);
          // if (changedInterventionsList.length === 0) {
          //   dispatch(hideInterventionPlanModal());
          // }
        }
      });
    }
  };

  const [discontinueReason, setDiscontinueReason] = useState<string>();
  const [showDiscontinueForm, setShowDiscontinueForm] = useState<boolean>(
    false
  );

  const onChangeInterventionState = (planItem?: InterventionPlanItem) => (
    eventKey: string | null
  ) => {
    if (interventionGroup.id && planItem) {
      const isDiscontinue = eventKey === InterventionPlanStates.DISCONTINUED;

      if (isDiscontinue) {
        setShowDiscontinueForm(true);
      } else {
        const changedPlan: InterventionPlan = {
          plan_items: statePlan.plan_items?.map((pi) =>
            pi.intervention_id === planItem.intervention_id
              ? {
                  ...pi,
                  state: eventKey as InterventionPlanStates,
                  discontinue_reason: isDiscontinue
                    ? pi.discontinue_reason ?? ""
                    : undefined,
                }
              : pi
          ),
        };

        setStatePlan(changedPlan);
        setShowDiscontinueForm(false);
        dispatch(
          patchInterventionGroup(interventionGroup.id, {
            intervention_plan: changedPlan,
          })
        );
      }
    }
  };

  document.body.blur();

  const onChangeDiscontinueReason = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value;
    setDiscontinueReason(value);
  };

  const onKeydownDiscontinueReason = (event: React.KeyboardEvent) => {
    if (
      event.key === "Enter" &&
      interventionGroup.intervention_plan !== statePlan
    ) {
      handleInterventionDiscontinue();
    }
  };

  const handleInterventionDiscontinue = () => {
    const changedPlan: InterventionPlan = {
      plan_items: statePlan.plan_items?.map((pi) =>
        pi.intervention_id === planItem?.intervention_id
          ? {
              ...pi,
              state: InterventionPlanStates.DISCONTINUED,
              discontinue_reason: discontinueReason,
            }
          : pi
      ),
    };

    setStatePlan(changedPlan);

    (dispatch(
      patchInterventionGroup(interventionGroup.id, {
        intervention_plan: changedPlan,
      })
    ) as any).then(() => setShowDiscontinueForm(false));
  };

  const handleModifyIntervention = () => {
    dispatch(
      showConfirmDialog({
        title: "Confirmation",
        text:
          "If you modify this intervention, a new copy of the intervention will be created and added. Are you sure want to continue?",
        onConfirm: () => {
          dispatch(openCreateNewInterventionModal(
            intervention,
            interventionGroup?.id
          ));
        },
        confirmButtonText: "Continue",
      })
    );
  }

  return (
    <div
      onClick={() => {
        if(!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess) {
          dispatch(hideInterventionPlanModal());
          dispatch(
            openInterventionLibraryModal(undefined, intervention, {
              onBack: () => {
                dispatch(showInterventionPlanModal());
              },
            })
          );
        }
      }}
      className="intervention-plan-item"
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div className="w-100 d-flex flex-column">
        <div className="flex-grow-1 d-flex justify-content-between">
        <div className="row">
            <div className="col-12 mb-1">
              <h4 className="sequence-list-row d-flex flex-grow-1 mr-2 font-weight-bold">
              {index + 1}.<span className="ml-1">{intervention.name}</span>
              </h4>
            </div>
            <div className="col-auto">
              {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess &&
              <div className="ml-3">
                <Badge
                  variant="secondary"
                  className="d-flex align-items-center justify-content-center pointer p-2"
                  pill
                  style={{fontSize: '10px'}}
                  onClick={(e: any) => {
                  e.stopPropagation();
                  handleModifyIntervention();
                }}
                >MODIFY INTERVENTION</Badge>
              </div>
              }
            </div>
          </div>
          <div className="d-flex align-items-start">
            {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess ? 
              <Dropdown
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
                className="m-1 recommendationsSelectInterventions"
                onSelect={onChangeInterventionState(planItem)}
                >
                <Dropdown.Toggle
                  id="dropdown-basic"
                  as={CustomToggle(planItem?.state)}
                />
                <Portal>
                  <Dropdown.Menu style={{ zIndex: 9999 }}>
                    <Dropdown.Item
                      active={planItem?.state === InterventionPlanStates.ACTIVE}
                      eventKey={InterventionPlanStates.ACTIVE}
                      as="button"
                    
                    >
                      Active
                    </Dropdown.Item>
                    <Dropdown.Item
                      active={planItem?.state === InterventionPlanStates.COMPLETED}
                      eventKey={InterventionPlanStates.COMPLETED}
                      as="button"
                    >
                      Completed
                    </Dropdown.Item>
                    <Dropdown.Item
                      active={
                        planItem?.state === InterventionPlanStates.DISCONTINUED
                      }
                      eventKey={InterventionPlanStates.DISCONTINUED}
                      as="button"
                    >
                      Discontinued
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Portal>
              </Dropdown>
            : 
              <FormLabel>{planItem!.state!.charAt(0).toUpperCase() + planItem!.state!.slice(1)}</FormLabel>
            }
            {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess && <div
              className="m-1 mt-2"
              onClick={(e: any) => {
                e.stopPropagation();
                !isLoading && handleDelete(intervention);
                //!hideDelete && !isLoading && handleDelete(intervention);
              }}
              //style={{ visibility: hideDelete ? "hidden" : "visible" }}
            >
              <FontAwesomeIcon
                icon={faTimesCircle}
                size="lg"
                className="pointer"
              />
            </div>
            }
          </div>
        </div>
        <div
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          {(planItem?.state === InterventionPlanStates.DISCONTINUED ||
            showDiscontinueForm) &&
            (showDiscontinueForm ? (
              <>
                <hr className="w-100 mx-0 my-1" />
                <h5 className="mb-1 font-weight-bold">
                  Why are you discontinuing this intervention?
                </h5>
                <div className="d-flex">
                  <InputGroup size="sm">
                    <Form.Control
                      value={discontinueReason ?? ""}
                      onChange={onChangeDiscontinueReason}
                      onKeyDown={onKeydownDiscontinueReason}
                    />
                  </InputGroup>
                  <button
                    className="blueBtnSm ml-3"
                    onClick={handleInterventionDiscontinue}
                  >
                    Save
                  </button>
                  <button
                    className="whiteBtnSm ml-3"
                    onClick={() => setShowDiscontinueForm(false)}
                  >
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <hr className="w-100 mx-0 my-1" />
                <div className="ml-3">
                  <h5 className="mb-1 font-weight-bold">
                    Discontinue reason{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowDiscontinueForm(true)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </span>{" "}
                    :
                  </h5>
                  <p
                    className="mb-0"
                    style={{ fontSize: "small", lineHeight: "normal" }}
                  >
                    {planItem?.discontinue_reason ? (
                      planItem?.discontinue_reason
                    ) : (
                      <i>No reason was given</i>
                    )}
                  </p>
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default InterventionElement;
