import React, { FunctionComponent } from "react";

type OwnProps = {
  onClickContinue: () => any;
};

type Props = OwnProps;

const RcProgressIntro: FunctionComponent<Props> = ({ onClickContinue }) => {
  return (
    <div>
      <h3 className="font-weight-bold">
        Measure your goal using simple indicators about your progress.
      </h3>

      <div className="my-3">
        <h3>ReadyCoach Indicators</h3>
        <ul>
          <li>No improvement</li>
          <li>Minimal improvement</li>
          <li>Moderate improvement</li>
          <li>Significant improvement</li>
          <li>Goal met</li>
        </ul>
      </div>

      <div className="d-flex align-items-center justify-content-center">
        <button className="blueBtnMd" onClick={onClickContinue}>
          Use ReadyCoach Indicators
        </button>
      </div>
    </div>
  );
};

export default RcProgressIntro;
