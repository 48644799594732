import * as XLSX from "xlsx";
import Papa, { ParseResult } from "papaparse";

const CSV_FILE_TYPES = [
  "text/comma-separated-values",
  "text/csv",
  "application/csv",
  "application/vnd.ms-excel",
  "application/vnd.msexcel",
  "csv",
];

const EXCEL_FILE_TYPES = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "xlsx",
];

export const parseCsvFile = ({
  file,
  onComplete,
  onShowError,
}: {
  file: File;
  onComplete: (results: ParseResult, file?: File) => void;
  onShowError: Function;
}) => {
  const fileType = file
    ? file.type
      ? file.type
      : file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2)
    : "";

  const reader = new FileReader();
  const readAsArrayBuffer = EXCEL_FILE_TYPES.includes(fileType);

  reader.onload = (e) => {
    if (reader.result) {
      if (CSV_FILE_TYPES.includes(fileType)) {
        handleCSVData(reader.result as string, fileType, onComplete);
      } else if (EXCEL_FILE_TYPES.includes(fileType)) {
        const workbook = XLSX.read(reader.result, { type: "array" });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];

        const asCSV = XLSX.utils.sheet_to_csv(sheet);
        handleCSVData(asCSV, "csv", onComplete);
      } else {
        onShowError();
      }
    } else {
      onShowError();
    }
  };

  if (readAsArrayBuffer) {
    reader.readAsArrayBuffer(file);
  } else {
    reader.readAsText(file);
  }
};

export const handleCSVData = (
  csvData: string,
  fileType: string,
  onComplete?: (results: ParseResult, file?: File) => void
) => {
  Papa.parse(csvData, {
    complete: onComplete,
    header: true,
    dynamicTyping: true,
    trimHeaders: true,
    skipEmptyLines: true,
    transform(value: string, field: string | number): any {
      return value.trim();
    },
    transformHeader(header: string): any {
      return header.trim();
    },
  });
};
