import React, { FunctionComponent } from "react";
import { bindActionCreators, Dispatch } from "redux";
import {
  hideWelcomePanel,
  openLearnMoreModal,
} from "../../../../../../store/onboarding/cases/actions";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../../../store";

type StateProps = {
  showPanel: boolean;
};

type DispatchProps = {
  openLearnMoreModal: () => any;
  hideWelcomePanel: () => any;
};

type OwnProps = {};

type Props = StateProps & DispatchProps & OwnProps;

const Welcome: FunctionComponent<Props> = ({
  showPanel,
  openLearnMoreModal,
  hideWelcomePanel,
}) => {
  if (!showPanel) return null;

  const handleStartCoaching = () => {
    hideWelcomePanel();
  };

  return (
    <div className="welcomeContainer mb-3" data-cy="data-welcome-container">
      <h3 style={{ fontSize: "10pt" }}>Welcome to</h3>
      <h1 style={{ fontSize: "16pt" }}>ReadyCoach</h1>
      <h3 className="font-weight-semibold" style={{ fontSize: "12pt" }}>
        The best platform for academic coaching.
      </h3>
      <div className="d-flex align-items-center justify-content-start mt-3">
        <button
          className="whiteBtnXL2 mr-3"
          onClick={() => openLearnMoreModal()}
          data-cy="learn-more"
        >
          Learn More
        </button>
        <button
          className="marigoldBtnXL"
          onClick={handleStartCoaching}
          data-cy="start"
        >
          Start Coaching!
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  cases,
  onboarding,
}: ApplicationState): StateProps => {
  return {
    showPanel:
      cases.showWelcomePanel &&
      !!cases.showGroupingStudentInfo &&
      !onboarding.evidencePeriodData?.evidence_column_groups.length,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      openLearnMoreModal: openLearnMoreModal,
      hideWelcomePanel: hideWelcomePanel,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
