import { ApplicationState } from "../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  assignClassToTeacher,
  updateTeacherClass,
} from "../../../../store/onboarding/actions";
import { connect } from "react-redux";
import UploadStudentsRosterModal from "../../../../components/common/onboarding/second-step/upload-students/UploadStudentsRosterModal";
import {
  Student,
  TeacherClass,
  TeacherInfo,
} from "../../../../store/onboarding/types";

type PropsFromState = {
  originalFileName: string;
  columnsNames: Array<string>;
  rawData: Array<any>;
  selectedTeacher?: TeacherInfo;
  isLoading: boolean;
  error?: string;
};

type DispatchProps = {
  assignClassToTeacher: (
    id: number,
    className: string,
    students: Array<Student>
  ) => any;
  updateTeacherClass: (teacherId: number, updatedClass: TeacherClass) => any;
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  const loadableFile = onboarding.loadableFile;
  return {
    originalFileName: loadableFile ? loadableFile.originalFileName! : "",
    rawData: loadableFile ? loadableFile.rawData : [],
    columnsNames: loadableFile ? loadableFile.columnsNames : [],
    selectedTeacher: onboarding.selectedTeacher,
    isLoading:
      onboarding.isLoading.assignClassToTeacher ||
      onboarding.isLoading.updateTeacherClass,
    error:
      onboarding.errors.assignClassToTeacher ||
      onboarding.errors.updateTeacherClass,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      assignClassToTeacher: assignClassToTeacher,
      updateTeacherClass: updateTeacherClass,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadStudentsRosterModal);
