import {
  getFbas,
  getInterventionGroups,
  activeTabUrl,
  callFromStudentDetail,
  callFromGroupDetail,
  sendSharedWithData,
  sendNotSharedWithData
} from "../../../store/onboarding/cases/actions";

import {
  getCustomBehaviors,
  getDataPeriods,
  getStaticDataCategoryDomains,
  isUrlBlocked,
} from "../../../store/onboarding/actions";
import { getBadgesRequest } from "../../../store/badges/actions";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { push } from "connected-react-router";
import { CoachDashboardTabKeys } from "../coach-dashboard/CoachDashboard";
import { UserAssignmentRole } from "../../../store/auth/types";
import { SuperuserDashboardTabKeys } from "../superuser-dashboard/SuperuserDashboard";
import { showConfirmDialog, hideConfirmDialog } from "../../../store/confirm-dialog/actions";
import { useEffect, useMemo, useState } from "react";
import { changeGroupSort } from "../../../store/dataFilter/actions";

export default function useActiveTab() {
  const dispatch = useDispatch();
  const showArchivedGroups = useSelector(
    (s: ApplicationState) => s.dataFilter.showArchivedGroups
  );

  const tabName = useSelector(
    (s: ApplicationState) => s.cases.tabName
  );

  const isParent = useSelector(
    (s: ApplicationState) =>
      s.auth.userInfo?.profile.current_assignment?.role ===
      UserAssignmentRole.PARENT
  );

  const isURLBlocked = useSelector((s:ApplicationState) => s.onboarding.isUrlBlocked)

  const changeActiveTab = (activeTab: string | null) => {
    dispatch(changeGroupSort(undefined))
    if(isURLBlocked && (window.location.pathname.includes('groups'))) {
        dispatch(
          showConfirmDialog({
            title: "Confirm Navigation",
            text:
              "If you leave/refresh this page, any actions taken with the recommended groups will not be retained. Are you sure you want to leave? ",
            onConfirm: () => {
              dispatch(isUrlBlocked(false));
              switchCaseFunction(activeTab)
              dispatch(hideConfirmDialog());
            },
            confirmButtonText: "Leave this Page",
            cancelButtonText: "Stay on this page",
            modalClassName: "navigation-confirm"
          })
        );
    } else {

      switchCaseFunction(activeTab)
    }
      
  };

  const switchCaseFunction = (activeTab: string | null) => {
    switch (activeTab) {
      // case CoachDashboardTabKeys.YOUR_ASSIGNMENTS: {
      //   if (!isParent) {
      //     dispatch(getInterventionGroups(showArchivedGroups));
      //   }
      //   dispatch(
      //     push(`/cases/${activeTab}${showArchivedGroups ? "/archived" : ""}`)
      //   );
      //   break;
      // }
      case CoachDashboardTabKeys.YOUR_BEHAVIOUR_ASSIGNMENTS: {
        dispatch(activeTabUrl(activeTab))
        if (!isParent) {
          dispatch(getInterventionGroups(showArchivedGroups, activeTab));
        }

        //if(!showArchivedGroups) {
          dispatch(callFromStudentDetail(false));
          dispatch(sendSharedWithData(false))
          dispatch(sendNotSharedWithData(false))
        //}

        if(showArchivedGroups) {
          dispatch(callFromGroupDetail(true));
        }
            
        dispatch(
          push(`/cases/${activeTab}${showArchivedGroups ? "/archived" : ""}`)
        );
        break;
      }
      case CoachDashboardTabKeys.YOUR_READING_ASSIGNMENTS: {
        dispatch(activeTabUrl(activeTab))
        if (!isParent) {
          dispatch(getInterventionGroups(showArchivedGroups, activeTab));
        }
        //if(!showArchivedGroups) {
          dispatch(callFromStudentDetail(false));
          dispatch(sendSharedWithData(false))
          dispatch(sendNotSharedWithData(false))
        //}

        if(showArchivedGroups) {
          dispatch(callFromGroupDetail(true));
        }
        
        dispatch(
          push(`/cases/${activeTab}${showArchivedGroups ? "/archived" : ""}`)
        );
        break;
      }
      case CoachDashboardTabKeys.INTERVENTION_LIBRARY:
        dispatch(push(`/cases/${activeTab}`));
        break;
      case CoachDashboardTabKeys.TUTORIAL_VIDEOS:
        dispatch(push(`/${activeTab}`));
        break;
      case CoachDashboardTabKeys.RELEASE_NOTES:
        dispatch(push(`/${activeTab}`));
        break;
      // case CoachDashboardTabKeys.RELEASE_NOTES_DETAIL:
      //   dispatch(push(`/${activeTab}`));
      //   break;
      case CoachDashboardTabKeys.GROUPS_IN_PROGRESS:
        dispatch(push(`/cases/${activeTab}`));
        break;
      case CoachDashboardTabKeys.FBAs:
        // dispatch(getFbas());
        dispatch(push(`/${activeTab}`));
        break;
      case CoachDashboardTabKeys.YOUR_DATA_AND_INTERVIEWS: {
        if (!isParent) {
          dispatch(getDataPeriods());
          dispatch(getStaticDataCategoryDomains());
          dispatch(getCustomBehaviors());
        }

        dispatch(push(`/${activeTab}`));
        break;
      }
      case CoachDashboardTabKeys.PARENTS:
        dispatch(push(`/${activeTab}`));
        break;
      case CoachDashboardTabKeys.BADGES:
        dispatch(getBadgesRequest());
        dispatch(push(`/${activeTab}`));
        break;
      case CoachDashboardTabKeys.ROSTERS_STUDENTS:
      case CoachDashboardTabKeys.ROSTERS_TEACHERS:
        dispatch(push(`/rosters/${activeTab}`));
        break;
      case CoachDashboardTabKeys.YOUR_PROFILE:
        dispatch(push(`/profile`));
        break;
      case CoachDashboardTabKeys.NOTIFICATIONS:
        dispatch(push(`/settings/notifications`));
        break;

      case SuperuserDashboardTabKeys.DISTRICTS:
        dispatch(push(`/superuser/districts`));
        break;
      case SuperuserDashboardTabKeys.DISTRICT_SUMMARY:
        dispatch(push(`/superuser/districts/summary`));
        break;
      case SuperuserDashboardTabKeys.USERS:
        dispatch(push(`/superuser/users`));
        break;
      case SuperuserDashboardTabKeys.ADMIN_RELEASE_NOTES:
        dispatch(push(`/superuser/admin-release-notes`));
        break;
      case SuperuserDashboardTabKeys.DATA_EXTRACT:
        dispatch(push(`/superuser/data-extract`));
        break;
      case SuperuserDashboardTabKeys.MASTER_DATA:
        dispatch(push(`/superuser/master-data`));
        break;
      case CoachDashboardTabKeys.SPELLING_TEST:
        dispatch(push(`/${activeTab}/groups`));
        break;
      default:
        break;
    }
  }

  return {
    changeActiveTab,
  };
}
