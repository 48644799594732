import React, { FunctionComponent, useMemo } from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import { LatestActivity } from "../../../../../../store/onboarding/cases/types";

type OwnProps = {
  latestActivity: LatestActivity | undefined;
  isStudentDetail?: boolean;
  isUserAssignedToGroup?: boolean;
};

type Props = OwnProps;

const LatestActivityComp: FunctionComponent<Props> = (props) => {
  const { latestActivity } = props;

  const intl = useIntl();

  const getDateDifference = (startDate: any) =>  {
    const start = new Date(startDate);
    const end = new Date();

    // Calculate the difference in milliseconds
    const difference = end.getTime() - start.getTime();

    // Convert difference from milliseconds to days
    const daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24));

    if (daysDifference >= 365) {
        // More than a year difference
        const years = Math.floor(daysDifference / 365);
        return `${years} year${years > 1 ? 's' : ''} ago`;
    } else if (daysDifference >= 30) {
        // More than a month difference
        const months = Math.floor(daysDifference / 30);
        return `${months} month${months > 1 ? 's' : ''} ago`;
    } else if(daysDifference > 0) {
        // Less than a day difference
        return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
    } else {
        // Less than a day difference
        return `Today`;
    }
}
  

  return useMemo(() => {
    if (latestActivity) {
      const { display_label, date } = latestActivity;
      const timeDiff = getDateDifference(date)


      return (
        <div className="last-activity">
          {intl.formatMessage({
            id:
              "app.dashboard.yourAssigmentTab.studentGroupItem.activity.title",
          })}
          {props.isStudentDetail ? 
            (props.isUserAssignedToGroup ? 
              <>
                <br />
                <strong>{" "}{display_label}</strong>
              </>  
            : '')
          :
          <strong>{" "}{display_label}</strong>
          }
          <br />
          {timeDiff}
        </div>
      );
    }
    return null;
  }, [latestActivity]);
};

export default LatestActivityComp;
