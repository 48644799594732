import React, { useRef, useState } from "react";
import BehaviorsDropDown from "../../second-step/evidence-tab/student-data-table/behavior-dropdown/BehaviorsDropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { StudentEntry } from "../../../../../store/onboarding/types";
import { GoalsData } from "../NewSetGoalsModal";

type Props = {
  goalsData: GoalsData;
  studentEntries: StudentEntry[];
  onChangeBehaviorsTag: (studentEntry?: StudentEntry) => void;
};

const BehaviorsStudentCell = ({
  goalsData,
  studentEntries,
  onChangeBehaviorsTag,
}: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const selectBehaviorBtnRef = useRef(null);

  const student = goalsData.student;
  const studentEntry = studentEntries?.find(
    (se) => se.student_id === student.id
  );
  const behavior_tags = studentEntry?.behavior_tags ?? [];
  return (
    <td>
      <div className="d-inline-flex mb-1">
        <span>
          {student.first_name} {student.last_name}
        </span>
        <div
          onClick={() => {
            onChangeBehaviorsTag(studentEntry);
            setShowDropdown(true);
          }}
          ref={selectBehaviorBtnRef}
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            size="lg"
            className="ml-3 pointer"
          />
        </div>
      </div>
      <BehaviorsDropDown
        refObject={selectBehaviorBtnRef}
        setShowDropdown={setShowDropdown}
        showDropdown={showDropdown}
      />

      {behavior_tags.length === 0 ? (
        <button
          className="w-100 whiteBtnSm text-nowrap"
          type="button"
          onClick={() => {
            onChangeBehaviorsTag(studentEntry);
            setShowDropdown(true);
          }}
        >
          Select Behavior
        </button>
      ) : (
        <div className="behaviorsArea">
          {behavior_tags.map((behavior) => (
            <div className="fileCell" key={behavior.id}>
              <div className="fileCellName"> {behavior.name}</div>
            </div>
          ))}
        </div>
      )}
    </td>
  );
};

export default BehaviorsStudentCell;
