import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { listingOrderSwitch } from "../../../../../../store/onboarding/cases/actions";

type OwnProps = {};

type Props = OwnProps;

const ListingOrderSwitcher: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const listOrderSwitch = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.cases.listOrderSwitch);


  const handleListOrderSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    dispatch(listingOrderSwitch(checked as any))
  };
  
  return (
    <div className="list-order-swither-checkbox d-flex">
      <label className="font-weight-bold">Organize by Teacher</label>  
      <Form.Check
        type="switch"
        id="list-order-switch"
        label="Organize by Coach"
        className="font-weight-bold ml-2"
        checked={listOrderSwitch}
        onChange={handleListOrderSwitch}
      />
    </div>
  );
};

export default ListingOrderSwitcher;
