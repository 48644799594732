import React, { FunctionComponent, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";

type OwnProps = {
  shareByDistrict: boolean;
  setShareByDistrict: React.Dispatch< React.SetStateAction<boolean>>;
  onClickNext?: () => void;
};

type Props = OwnProps;

const InterventionShared: FunctionComponent<Props> = ({
  shareByDistrict, 
  setShareByDistrict,
  onClickNext,
}) => {

    return (
      <Modal.Body>
        <div className="interventionModalContent d-flex">
          <div className="modalActions justify-content-center">
            <Form.Check 
              type="checkbox"
              id="is_visible_to_all_district_user"
              label="Is accessible to all district users"
              className="font-weight-bold"
              checked={shareByDistrict ?? false}
              onChange={(e) => {
                const checked = e.target.checked;
                setShareByDistrict(checked);
              }}
            />
          </div>
        </div>
        <hr />
        <div className="modalActions">
          <div />
          <button
            className="blueBtnSm"
            onClick={onClickNext}
          >
            Continue
          </button>
        </div>
      </Modal.Body>
    );
};

export default InterventionShared;
