import React, { FunctionComponent } from "react";
import { ApplicationState } from "../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { UserInfo } from "../../../store/auth/types";
import { RouteComponentProps } from "react-router";
import { Container, Nav, Tab } from "react-bootstrap";
import YourAssigmentTab from "../coach-dashboard/tabs/your-assigments/YourAssigmentsTab";
import InterventionLibraryTab from "../coach-dashboard/tabs/explore-and-learn/intervention-library/InterventionLibraryTab";
import ProfileTab from "../../common/reading-interventions/management/profile-tab/ProfileTab";
import ChangePasswordModal from "../../common/reading-interventions/management/profile-tab/modals/ChangePasswordModal";
import EditProfileModal from "../../common/reading-interventions/management/profile-tab/modals/EditProfileModal";
import NotificationsTab from "../../common/reading-interventions/management/notifications-tab/NotificationsTab";
import UserAvatarSmall from "../../common/UserAvatarSmall";
import LoadingIndicator from "../../common/onboarding/LoadingIndicator";
import DashboardMenuPanel from "../coach-dashboard/DashboardMenuPanel";
import { DataPeriod } from "../../../store/onboarding/types";
import TopBar from "../../common/top-bar/TopBar";
import useActiveTab from "./useActiveTab";
import { CoachDashboardTabKeys } from "../coach-dashboard/CoachDashboard";
import ParentEvidenceTab from "../../common/onboarding/second-step/evidence-tab/ParentEvidenceTab";
import TutorialVideosPage from "../tutorial_videos/TutorialVideosPage";
import InterventionsBriefModal from "../coach-dashboard/tabs/your-assigments/InterventionsResourcesModal";
import SupportImplFeedbackModal from "../coach-dashboard/tabs/your-assigments/support/SupportImplFeedbackModal";
import ProvidingFeedbackModal from "../coach-dashboard/tabs/your-assigments/feedback/ProvidingFeedbackModal";
import ImplementationCheckModal from "../coach-dashboard/tabs/your-assigments/implementation-check/ImplementationCheckModal";
import GoalProgressModal from "../coach-dashboard/tabs/your-assigments/goal-progress/GoalProgressModal";
import ModifyInterventionModal from "../coach-dashboard/tabs/your-assigments/ModifyInterventionModal";
import EndInterventionModal from "../coach-dashboard/tabs/your-assigments/end-intervention/EndInterventionModal";
import InterventionLibraryModal from "../../common/onboarding/third-step/find-intervention/InterventionLibraryModal";
import GroupInterventionsModal from "../coach-dashboard/tabs/your-assigments/group-interventions/GroupInterventionsModal";
import InterventionFidelityCheckModal from "../coach-dashboard/tabs/your-assigments/implementation-check/InterventionFidelityCheckModal";
import FindInterventionModal from "../../common/onboarding/third-step/find-intervention/FindInterventionModal";
import SetGoalChooseConcernModal from "../../common/onboarding/third-step/set-goal-modal/SetGoalChooseConcernModal";
import ConductInterviewModal from "../../common/onboarding/second-step/evidence-tab/conduct-interview/ConductInterviewModal";
import { EventKey } from "react-bootstrap/esm/types";

type DispatchProps = {
  push: (location: string) => any;
};

type PropsFromState = {
  userInfo?: UserInfo;
  currentDataPeriod?: DataPeriod;
  showTopBar?: boolean;
};

type MatchParams = {
  tabName: string;
};

type Props = PropsFromState &
  RouteComponentProps<MatchParams> &
  DispatchProps & {
    getUserInfo: () => any;
  };

const ParentDashboard: FunctionComponent<Props> = ({
  userInfo,
  showTopBar,
  match: {
    params: { tabName: activeTab },
  },
  // currentDataPeriod,
  // push
}) => {
  const { changeActiveTab } = useActiveTab();

  return (
    <>
      {showTopBar && <TopBar />}
      <Container fluid>
        <Tab.Container
          defaultActiveKey={CoachDashboardTabKeys.YOUR_DATA_AND_INTERVIEWS}
          activeKey={activeTab as EventKey}
          onSelect={changeActiveTab}
        >
          <div className="d-flex" id="user-dashboard">
            {!showTopBar && (
              <div
                className="coachDashboardSidebar mr-3"
                id="user-dashboard-sidebar"
              >
                {userInfo ? (
                  <>
                    <div className="coachDashboardUserInfo">
                      <UserAvatarSmall size={60} userInfo={userInfo} />
                      <h3>{`${userInfo.first_name} ${userInfo.last_name}`}</h3>
                    </div>
                  </>
                ) : (
                  <LoadingIndicator />
                )}
                <hr />
                <Nav className="flex-column justify-content-start">
                  <DashboardMenuPanel activeTab={activeTab} />
                </Nav>
              </div>
            )}

            <div className="coachDashboardContentContainer">
              <Tab.Content>
                <Tab.Pane
                  mountOnEnter
                  unmountOnExit
                  eventKey={CoachDashboardTabKeys.YOUR_DATA_AND_INTERVIEWS}
                >
                  <ParentEvidenceTab />
                </Tab.Pane>
                <Tab.Pane
                  mountOnEnter
                  unmountOnExit
                  eventKey={CoachDashboardTabKeys.YOUR_READING_ASSIGNMENTS}
                >
                  <YourAssigmentTab />
                </Tab.Pane>

                <Tab.Pane
                  mountOnEnter
                  unmountOnExit
                  eventKey={CoachDashboardTabKeys.INTERVENTION_LIBRARY}
                >
                  <InterventionLibraryTab isReadonly />
                </Tab.Pane>

                <Tab.Pane
                  mountOnEnter
                  unmountOnExit
                  eventKey={CoachDashboardTabKeys.TUTORIAL_VIDEOS}
                >
                  <TutorialVideosPage />
                </Tab.Pane>
                
                <Tab.Pane eventKey={CoachDashboardTabKeys.YOUR_PROFILE}>
                  <ProfileTab />

                  <ChangePasswordModal />
                  <EditProfileModal />
                </Tab.Pane>

                <Tab.Pane
                  mountOnEnter
                  unmountOnExit
                  eventKey={CoachDashboardTabKeys.NOTIFICATIONS}
                >
                  <NotificationsTab />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>

        <InterventionFidelityCheckModal isReadonly />
        <InterventionsBriefModal />
        <SupportImplFeedbackModal />
        <ProvidingFeedbackModal />
        <ImplementationCheckModal />
        <GoalProgressModal />
        <ModifyInterventionModal />
        <EndInterventionModal />
        <GroupInterventionsModal />

        <FindInterventionModal />
        <InterventionLibraryModal />
        <SetGoalChooseConcernModal />
        <ConductInterviewModal />
      </Container>
    </>
  );
};

const mapStateToProps = ({
  auth,
  onboarding,
  help,
}: ApplicationState): PropsFromState => {
  return {
    userInfo: auth.userInfo,
    currentDataPeriod: onboarding.currentDataPeriod,
    showTopBar: help.showTopBar,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      push: push,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParentDashboard);
