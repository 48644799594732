import React, { FunctionComponent, useMemo } from "react";
import VerticalBarChart from "./VerticalBarChart";
import { CoachTeacherTotalSummary } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";

type OwnProps = {
  label?: string;
  goalProgress: CoachTeacherTotalSummary[];
};

type Props = OwnProps;

const GoalsProgressMonitoredInDistrictChart: FunctionComponent<Props> = (
  props
) => {
  const { label, goalProgress } = props;
  const labels = useMemo(
    () => goalProgress.map((gProgress) => getFullName(gProgress.coach)),
    [goalProgress]
  );
  const data = useMemo(() => goalProgress.map((gProgress) => gProgress.total), [
    goalProgress,
  ]);

  if (!data.length) {
    return null;
  }

  return (
    <div className="district-summary-chart-container">
      {label && <h3 className="text-center">{label}</h3>}
      <div>
        <VerticalBarChart labels={labels} data={data} isNumeric />
      </div>
    </div>
  );
};

export default GoalsProgressMonitoredInDistrictChart;
