import React, { FunctionComponent, useEffect, useState } from "react";
import NoStaffForm from "./NoStaffForm";
import ManageUsersPage from "./manage-users-page/ManageUsersPage";
import { ApplicationState } from "../../../../../store";
import { connect, useDispatch, useSelector } from "react-redux";
import { TeacherInfo } from "../../../../../store/onboarding/types";
import LoadingIndicator from "../../../../common/onboarding/LoadingIndicator";
import { bindActionCreators, Dispatch } from "redux";
import {
  getSchools,
  getTeachers,
  setShowDisabledUsers,
} from "../../../../../store/onboarding/actions";
import TeacherInviteIndividuallyModal from "../../../../common/onboarding/second-step/TeacherInviteIndividuallyModal";
import UploadTeachersRosterModal from "../../../../common/onboarding/second-step/upload-educators/UploadTeachersRosterModal";

type StateProps = {
  staffRoster: Array<TeacherInfo>;
  isLoading: {
    getStaffRoster: boolean;
  };
};

type DispatchProps = {
  getSchools: () => any;
  getTeachers: (withDisabled?: boolean) => any;
};

type Props = StateProps & DispatchProps;

const YourStaffTab: FunctionComponent<Props> = ({
  staffRoster,
  isLoading,
  getTeachers,
  getSchools,
}) => {
  const dispatch = useDispatch();
  const [activeStaffRoster, setActiveStaffRoster] = useState<TeacherInfo[]>([]);
  const [disabledStaffRoster, setDisableStaffRoster] = useState<TeacherInfo[]>([]);
  const showDisabledUsers = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.onboarding.showDisabledUsers
  );
  
  useEffect(() => {
    getSchools();
    getTeachers().then((activeStaffRoster: TeacherInfo[]) => {
      setActiveStaffRoster(activeStaffRoster);
      dispatch(setShowDisabledUsers(false));
      if(!activeStaffRoster.length) {
        getTeachers(true).then((disabledStaffRoster: TeacherInfo[]) => {
          setDisableStaffRoster(disabledStaffRoster)
          dispatch(setShowDisabledUsers(true))
        });
      }
    })
  }, []);

  return (
    <>
      {isLoading.getStaffRoster && !staffRoster.length ? (
        <LoadingIndicator />
      ) : !activeStaffRoster.length && !disabledStaffRoster.length ? (
        <NoStaffForm />
      ) : (
        <ManageUsersPage />
      )}

      <UploadTeachersRosterModal />
      <TeacherInviteIndividuallyModal />
    </>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    staffRoster: onboarding.teachersRoster,
    isLoading: {
      getStaffRoster: onboarding.isLoading.getTeachers,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getTeachers: getTeachers,
      getSchools: getSchools,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(YourStaffTab);
