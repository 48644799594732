import React, { FunctionComponent, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import GroupInfoModalTitle from "../common/GroupInfoModalTitle";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  getSupportMeetings,
  hideSupportImplFeedbackModal,
} from "../../../../../../store/onboarding/meeting-module/actions";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import InterventionsBar from "../common/InterventionsBar";
import PlanningModalBody from "./PlanningModalBody";
import MeetingModalBody from "./MeetingModalBody";
import ModalCloseButton from "../../../../../common/onboarding/third-step/group-students/common/ModalCloseButton";

type StateProps = {
  showModal: boolean;
  showResourcesModal: boolean;
  interventionGroup?: InterventionGroup;
};

type DispatchProps = {
  getSupportMeetings: (
    supportMeetingId?: number,
    interventionGroupId?: number
  ) => any;
  hideSupportImplFeedbackModal: () => any;
};

type Props = StateProps & DispatchProps;

enum ModalSteps {
  PLANNING,
  MEETING,
}

const SupportImplFeedbackModal: FunctionComponent<Props> = ({
  showModal,
  showResourcesModal,
  interventionGroup,

  getSupportMeetings,
  hideSupportImplFeedbackModal,
}) => {
  const [step, setStep] = useState(ModalSteps.PLANNING);

  // useEffect(() => {
  //   //MODAL OVERLAY CLASS HACKS
  //   if (showModal && !showResourcesModal) {
  //     document.body.style.overflow = "hidden";
  //     document.body.classList.add("modal-open");
  //   }
  // }, [showResourcesModal]);

  if (!interventionGroup) return null;

  const onModalHide = () => {
    hideSupportImplFeedbackModal();
  };

  const onModalShow = () => {
    getSupportMeetings();
    setStep(ModalSteps.PLANNING);
  };

  return (
    //@ts-ignore
    <Modal
      show={showModal}
      onHide={onModalHide}
      //@ts-ignore
      onShow={onModalShow}
      animation={false}
      size="lg"
      backdropClassName={`customDarkModalBackdrop in ${
        showResourcesModal ? "d-none" : ""
      }`}
      dialogClassName={showResourcesModal ? "d-none" : ""}
    >
      <Modal.Header className="purpleModalHeader centerModalHeader">
        <GroupInfoModalTitle />
        <Modal.Title>
          <div className="interventionSelectContainer">
            Support Implementation
            <span className="pointer">
              <FontAwesomeIcon icon={faCaretDown} size="2x" />
            </span>
          </div>
        </Modal.Title>
        <ModalCloseButton onClose={onModalHide} />
      </Modal.Header>
      <Modal.Body>
        <h3 className="mb-3">
          <span className="font-weight-bold">Group: </span>
          {interventionGroup.name}
        </h3>
        <div>
          <InterventionsBar>
            <h2 className="font-weight-bold">
              {(interventionGroup.interventions || [])
                .map((i) => i.name)
                .join(", ")}
            </h2>
          </InterventionsBar>
          <h3 className="font-weight-bold">Provide Implementation Feedback</h3>
          <p>
            Because implementation fidelity needs improvement, it is important
            to identify potential barriers and/or provide additional modeling
            and practice opportunities while recognizing teacher strengths and
            referencing student data.
          </p>
          {step === ModalSteps.PLANNING ? (
            <PlanningModalBody
              onReadyToMeetMow={() => setStep(ModalSteps.MEETING)}
            />
          ) : (
            <MeetingModalBody />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({
  cases,
  meetingModuleReducer,
}: ApplicationState): StateProps => {
  return {
    showModal: meetingModuleReducer.modalsState.supportImplFeedbackModal,
    showResourcesModal:
      meetingModuleReducer.modalsState.interventionsBriefModal,
    interventionGroup: cases.selectedInterventionGroup,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      getSupportMeetings: getSupportMeetings,
      hideSupportImplFeedbackModal: hideSupportImplFeedbackModal,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportImplFeedbackModal);
