import React from "react";
import Table from "react-bootstrap/Table";

const ISteepExample = () => {
  return (
    <>
      <h4 className="font-weight-bold mb-2">Example Format</h4>
      <div className="overflow-auto mb-3">
        <Table className="uploadExampleTable table-mb">
          <thead>
            <tr>
              <th>School District</th>
              <th>School</th>
              <th>Student ID</th>
              <th>First Name</th>
              <th>Middle Name</th>
              <th>Last Name</th>
              <th>Student Secondary ID</th>
              <th>Student DOB</th>
              <th>Test Date</th>
              <th>Score</th>
              <th>CDWD Score</th>
              <th>Skill</th>
              <th>Teacher Name</th>
              <th>Class</th>
              <th>Grade</th>
              <th>Secure ID</th>
              <th>National Percentile</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sample District</td>
              <td>Sample School</td>
              <td>555555</td>
              <td>John</td>
              <td>Doe</td>
              <td>Sample</td>
              <td>66666666</td>
              <td>19/05/2014</td>
              <td>16/08/2020</td>
              <td>18</td>
              <td></td>
              <td>Online Scoring Assessments (1 to 1) CVC Sounds-Phonemic Awareness Grade 1</td>
              <td>Sample Teacher</td>
              <td>LANGUAGE ARTS 1 009097</td>
              <td>1</td>
              <td></td>
              <td>2</td>
              <td>Below Averag</td>
            </tr>
          </tbody>
        </Table>
      </div>
  </>
  );
};
export default ISteepExample;
