import React, { FunctionComponent, useMemo } from "react";
import {
  GrammarType,
  GrammarTypeDisplayValues,
} from "../../../../store/spelling-tests/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import {
  getFullWordsAnswersStatistics,
  getGrammarPartsAnswersStatistics,
} from "../utils";

type Props = {
  grammarTypes: GrammarType[];
};

const StatsFooter: FunctionComponent<Props> = (props) => {
  const { grammarTypes } = props;

  const studentTestAnswers = useSelector(
    (s: ApplicationState) => s.spellingTests.spellingTestAnswers
  );

  const wholeWordsStats = useMemo(() => {
    return getFullWordsAnswersStatistics(studentTestAnswers);
  }, [studentTestAnswers]);

  const stats = useMemo(() => {
    return getGrammarPartsAnswersStatistics(studentTestAnswers);
  }, [studentTestAnswers]);

  return (
    <tfoot>
      <tr>
        <td rowSpan={2}>Total Correct</td>
        <td colSpan={2}>Whole Words</td>
        {grammarTypes.map((gt) => (
          <td key={gt} colSpan={2}>
            {GrammarTypeDisplayValues[gt]}
          </td>
        ))}
      </tr>
      <tr>
        <td colSpan={2}>
          {wholeWordsStats.correct}/{wholeWordsStats.total}
        </td>
        {grammarTypes.map((gt) => (
          <td key={gt} colSpan={2}>
            {stats[gt] && (
              <>
                {stats[gt].correct}/{stats[gt].total}
              </>
            )}
          </td>
        ))}
      </tr>
      <tr>
        <td>% Correct</td>
        <td colSpan={2}>{`${(
          100 *
          (wholeWordsStats.correct / wholeWordsStats.total)
        )
          .toFixed(2)
          .replace(".00", "")}%`}</td>
        {grammarTypes.map((gt) => (
          <td key={gt} colSpan={2}>
            {stats[gt] && (
              <>
                {`${(100 * (stats[gt].correct / stats[gt].total))
                  .toFixed(2)
                  .replace(".00", "")}%`}
              </>
            )}
          </td>
        ))}
      </tr>
    </tfoot>
  );
};

export default StatsFooter;
