import React, { FunctionComponent, useMemo } from "react";
import LoadingIndicator from "../../common/onboarding/LoadingIndicator";
import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { formatDate } from "../../utils/DateTimeUtils";
import {
  Meeting,
  MeetingTypeDisplayed,
} from "../../../store/onboarding/meeting-module/types";
import { InterventionGroup } from "../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { hideModifyInterventionModal } from "../../../store/onboarding/meeting-module/actions";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { Badge } from "react-bootstrap";

type StateProps = {
  meetings: Array<Meeting>;
  interventionGroup?: InterventionGroup;
  isLoading: {
    getMeetings: boolean;
  };
};

type DispatchProps = {
  push: (location: string) => any;
  hideModifyInterventionModal: () => any;
};

type OwnProps = {
  isTeacher?: boolean;
};

type Props = OwnProps & StateProps & DispatchProps;

const MeetingsTable: FunctionComponent<Props> = ({
  isTeacher,
  interventionGroup,
  meetings,
  isLoading: { getMeetings: loadingGetMeetings },
  push,
  hideModifyInterventionModal,
}) => {
  const options = useMemo(
    () => ({
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },
      ],
    }),
    []
  );

  const defaultSorted: [{ dataField: string; order: SortOrder }] = useMemo(
    () => [
      {
        dataField: "created",
        order: "asc",
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "created",
        text: "Date",
        sort: true,
        style: { whiteSpace: "nowrap" } as any,
        headerStyle: { width: "15%" },
      },
      {
        dataField: "meeting_details",
        text: "Meeting Details",
        sort: false,
      },
    ],
    []
  );

  const meetingsWithEmptyNotes = useMemo(() => {
    return meetings.filter((m) => !m.planning_notes || !m.meeting_notes);
  }, [meetings]);

  const rowClasses = (row: any, rowIndex: number) => {
    let classes = "";
    if (meetingsWithEmptyNotes.some((m) => m.id === row.id)) {
      classes = "meetingNoteActive";
    }
    return classes;
  };

  const handleContinue = () => {
    hideModifyInterventionModal();
    push(`/cases/groups/${interventionGroup!.id}/meeting`);
  };

  const data = useMemo(
    () =>
      meetings.map((meeting) => ({
        id: meeting.id,
        created: formatDate(meeting.created),
        meeting_details: (
          <div className="meetingDetails">
            <div>
              <h3 className="font-weight-bold">
                {MeetingTypeDisplayed[meeting.meeting_type]}
              </h3>
              <h3>Preparation Notes:</h3>
              <p>{meeting.planning_notes}</p>
              {meeting.meeting_notes && (
                <>
                  <h3>Meeting Notes:</h3>
                  <p>{meeting.meeting_notes}</p>{" "}
                </>
              )}
              <div className="tagsContainer">
                {meeting.modeled && <div className="tagCell">We Modeled</div>}
                {meeting.practiced && (
                  <div className="tagCell">We Practiced</div>
                )}
                {meeting.needs_modeling && (
                  <div className="tagCell">We need to Model</div>
                )}
                {meeting.needs_practice && (
                  <div className="tagCell">We need to Practice</div>
                )}
              </div>
            </div>
            <div>
              {meeting.educator_praises?.map((praise) => (
                <Badge pill variant="light" key={praise} className="mx-1">
                  {praise}
                </Badge>
              ))}
            </div>
          </div>
        ),
      })),
    [meetings]
  );

  return (
    <BootstrapTable
      classes="stdTable"
      rowClasses={rowClasses}
      wrapperClasses={meetings.length === 0 ? "rosterTableEmpty" : ""}
      keyField="id"
      bordered={false}
      bootstrap4
      data={data}
      columns={columns}
      pagination={paginationFactory(options)}
      defaultSorted={defaultSorted}
      noDataIndication={
        loadingGetMeetings ? (
          <LoadingIndicator />
        ) : (
          <div className="d-flex flex-column align-items-center">
            <h4 className="font-weight-bold text-center">
              You have not had any meetings yet
            </h4>
            {!isTeacher && (
              <button
                style={{ marginTop: 10 }}
                className="blueBtnMd"
                onClick={handleContinue}
              >
                Start a new meeting
              </button>
            )}
          </div>
        )
      }
    />
  );
};

const mapStateToProps = ({
  cases,
  meetingModuleReducer,
}: ApplicationState): StateProps => {
  return {
    meetings: meetingModuleReducer.meetings,
    interventionGroup: cases.selectedInterventionGroup,
    isLoading: {
      getMeetings: meetingModuleReducer.isLoading.getMeetings,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hideModifyInterventionModal: hideModifyInterventionModal,
      push: push,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingsTable);
