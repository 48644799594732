import React, { FocusEvent } from "react";
import { useDispatch } from "react-redux";
import { Student, StudentEntry } from "../../../../../store/onboarding/types";
import CustomDateInput from "../../third-step/set-goal-modal/common/CustomDateInput";
import DatePicker from "react-datepicker";
import {
  changeSelectedStudent,
  changeSelectedStudentEntry,
} from "../../../../../store/onboarding/actions";
import { GoalsData } from "../NewSetGoalsModal";
import BehaviorsStudentCell from "./BehaviorsStudentCell";
import { Form, FormControl, InputGroup } from "react-bootstrap";

type Props = {
  goalsDataList: GoalsData[];
  studentEntries: StudentEntry[];
  setGoalsDataList: React.Dispatch<React.SetStateAction<GoalsData[]>>;
  onSubmit: (e: React.FormEvent) => void;
};

const SetGoalsBehaviors = ({
  goalsDataList,
  studentEntries,
  setGoalsDataList,
  onSubmit,
}: Props) => {
  const dispatch = useDispatch();

  const handleChangeDateForAll = (date: Date | null) => {
    setGoalsDataList(goalsDataList.map((d) => ({ ...d, targetDate: date })));
  };

  const handleChangeDate = (data: GoalsData) => (date: Date | null) => {
    setGoalsDataList(
      goalsDataList.map((d) => (d === data ? { ...d, targetDate: date } : d))
    );
  };

  const onChangeBehaviorsTag = (student: Student) => (
    studentEntry?: StudentEntry
  ) => {
    dispatch(changeSelectedStudent(student));
    dispatch(changeSelectedStudentEntry(studentEntry));
  };

  const handleChangeGoalStatement = (data: GoalsData) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value;
    setGoalsDataList(
      goalsDataList.map((d) =>
        d === data ? { ...d, goal_statement: value } : d
      )
    );
  };

  const handleAutoPopulateCheck = (studentId: number) => (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    setGoalsDataList((goalsDataList) => {
      const restEmpty = goalsDataList.every(
        (goal) => !goal.goal_statement?.length || goal.student.id === studentId
      );

      if (restEmpty) {
        return goalsDataList.map((g) => ({ ...g, goal_statement: value }));
      }

      return goalsDataList;
    });
  };

  return (
    <div>
      <div className="text-center mb-3">
        <h3>
          <strong>The right goal can make a huge difference</strong>
          <br />
          Describe the desired behavior & target date for each student below.
          <br />
          Afterwards, you&apos;ll choose how to measure progress.
        </h3>
      </div>
      <Form id="set-goals-behaviors-form" onSubmit={onSubmit}>
        <table className="stdTableWide">
          <thead>
            <tr>
              <th className="w-25 align-middle">
                Students & Problem Behaviors
              </th>
              <th style={{ height: "70px", verticalAlign: "middle" }}>
                Describe the desired behavior
                <DatePicker
                  wrapperClassName={"float-right"}
                  customInput={
                    <button className="blueBtnSm" type="button">
                      Set Date For All
                    </button>
                  }
                  minDate={new Date()}
                  onChange={handleChangeDateForAll}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {goalsDataList.length ? (
              goalsDataList.map((data) => (
                <tr key={data.student.id}>
                  <BehaviorsStudentCell
                    goalsData={data}
                    studentEntries={studentEntries}
                    onChangeBehaviorsTag={onChangeBehaviorsTag(data.student)}
                  />
                  <td>
                    <div>{data.student.first_name} will...</div>
                    <div className="d-flex align-items-center">
                      <input
                        required
                        value={data.goal_statement ?? ""}
                        className="stdInput flex-grow-1"
                        type="text"
                        placeholder="Describe the desired behavior..."
                        onChange={handleChangeGoalStatement(data)}
                        onBlur={handleAutoPopulateCheck(data.student.id!)}
                      />
                      <span className="mx-3">by</span>
                      <DatePicker
                        required
                        customInput={<CustomDateInput />}
                        minDate={new Date()}
                        selected={data.targetDate}
                        onChange={handleChangeDate(data)}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2}>No students</td>
              </tr>
            )}
          </tbody>
        </table>
      </Form>
    </div>
  );
};

export default SetGoalsBehaviors;
