import React, { FunctionComponent, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { TeacherInfo } from "../../../../../store/onboarding/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { ValueType } from "react-select/src/types";

type OwnProps = {
  searchString: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  selectedTeacherId: number | undefined;
  setSelectedTeacherId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
};

type Props = OwnProps;

const FbaFilters: FunctionComponent<Props> = (props) => {
  const {
    searchString,
    setSearchString,
    selectedTeacherId,
    setSelectedTeacherId,
  } = props;

  const teachersRoster = useSelector(
    (s: ApplicationState) => s.onboarding.teachersRoster
  );
  const isLoadingGetTeachers = useSelector(
    (s: ApplicationState) => s.onboarding.isLoading.getTeachers
  );

  const handleSelectedTeacherChange = (
    value: ValueType<TeacherInfo, false>
  ) => {
    setSelectedTeacherId(value ? (value as TeacherInfo).user.id : undefined);
  };

  let timeout: any = null;

  const handleGroupSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (timeout) clearTimeout(timeout);
    const { value } = event.target;
    timeout = setTimeout(() => {
      setSearchString(value);
    }, 500);
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <Form.Group style={{ minWidth: 250 }}>
        <h4 className="font-weight-bold">Teacher</h4>
        <Select
          isClearable
          isLoading={isLoadingGetTeachers}
          placeholder="Select Educator..."
          value={
            selectedTeacherId !== undefined
              ? teachersRoster.find((t) => t.user.id === selectedTeacherId)
              : undefined
          }
          options={teachersRoster}
          getOptionLabel={({ user }: TeacherInfo) =>
            `${user.first_name} ${user.last_name}`
          }
          getOptionValue={({ user }: TeacherInfo) => user.id.toString()}
          onChange={handleSelectedTeacherChange}
        />
      </Form.Group>

      <input
        type="text"
        defaultValue={searchString}
        onChange={handleGroupSearchChange}
        placeholder="Search ABC Data Collections..."
        className="stdInput"
        style={{ width: "25%", minWidth: 250 }}
      />
    </div>
  );
};

export default FbaFilters;
