import React, { FunctionComponent, useMemo, useState } from "react";
import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  DistrictInfo,
  License,
  LicenseStatuses,
  LicenseTypes,
} from "../../../../../../../store/superuser/types";
import { faPen, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDistrictLicense,
  setShowDistrictLicenseModal,
} from "../../../../../../../store/superuser/actions";
import ConfirmModal from "../../../../../../common/reading-interventions/ConfirmModal";
import { ApplicationState } from "../../../../../../../store";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import LoadingIndicator from "../../../../../../common/onboarding/LoadingIndicator";

const columns = [
  {
    dataField: "id",
    text: "License ID",
    sort: true,
  },
  {
    dataField: "license_type",
    text: "Type",
    sort: true,
  },
  {
    dataField: "status",
    text: "Current Status",
    sort: true,
  },
  {
    dataField: "started_at",
    text: "Start",
    sort: true,
  },
  {
    dataField: "expires_at",
    text: "Expires",
    sort: true,
  },
  {
    dataField: "actions",
    text: "Actions",
    sort: false,
  },
];

const defaultSorted: [{ dataField: string; order: SortOrder }] = [
  {
    dataField: "expires_at",
    order: "desc",
  },
];

const options = {
  sizePerPageList: [
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "50",
      value: 50,
    },
  ],
};

type TableRow = {
  id: number;
  license_type: string;
  status: string;
  started_at: string;
  expires_at: string;
  actions: React.ReactElement;
};

type Props = {
  district: DistrictInfo;
};

const DistrictLicensesTable: FunctionComponent<Props> = ({ district }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [license, setLicense] = useState<License | undefined>();

  const dispatch = useDispatch();

  const isLoadingGet = useSelector<ApplicationState, boolean>(
    (s) => s.superuser.isLoading.getDistrictLicenses
  );
  const isLoadingDelete = useSelector<ApplicationState, boolean>(
    (s) => s.superuser.isLoading.deleteDistrictLicense
  );

  const onEdit = (license: License) => {
    dispatch(setShowDistrictLicenseModal({ show: true, license: license }));
  };

  const onDelete = (license: License) => {
    setLicense(license);
    setShowDeleteModal(true);
  };

  const onConfirmDeleteModal = () => {
    if (license) {
      dispatch(deleteDistrictLicense(district.id, license.id));
    }
  };

  const onHideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const tableData: TableRow[] = useMemo(
    () =>
      district.licenses?.map((license) => ({
        id: license.id,
        license_type:
          LicenseTypes.find((lic) => lic.value === license.license_type)
            ?.display_name ?? license.license_type,
        status:
          LicenseStatuses.find((status) => status.value === license.status)
            ?.display_name ?? license.status,
        started_at: license.started_at,
        expires_at: license.expires_at,
        actions: (
          <div>
            <OverlayTrigger
              overlay={
                <Tooltip id="editLicense" className="customInfoTooltip">
                  Edit License
                </Tooltip>
              }
            >
              <Button className="mr-3" onClick={() => onEdit(license)}>
                <FontAwesomeIcon icon={faPen} size="1x" />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="deleteLicense" className="customInfoTooltip">
                  Delete License
                </Tooltip>
              }
            >
              <Button onClick={() => onDelete(license)}>
                <FontAwesomeIcon icon={faTimes} size="1x" />
              </Button>
            </OverlayTrigger>
          </div>
        ),
      })) || [],
    [district]
  );

  const isEmpty =
    district.licenses?.length === 0 && !isLoadingGet && !isLoadingDelete;
  return (
    <>
      <BootstrapTable
        classes="rosterTable"
        keyField="id"
        bordered={false}
        bootstrap4
        data={tableData}
        columns={columns}
        pagination={paginationFactory(options)}
        defaultSorted={defaultSorted}
        noDataIndication={isEmpty ? "No licenses" : <LoadingIndicator />}
      />
      <ConfirmModal
        showModal={showDeleteModal}
        onHide={onHideDeleteModal}
        onConfirm={onConfirmDeleteModal}
        isLoading={isLoadingDelete}
        deleteModal={true}
      />
    </>
  );
};

export default DistrictLicensesTable;
