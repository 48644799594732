import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFbas, resetGoalChooseConcernModel } from "../../../../../store/onboarding/cases/actions";
import InterpretFbaModal from "./interpretation/InterpretFbaModal";
import FbaDashboardContainer from "./FbaDashboardContainer";
import FbaModal from "./FbaModal";
import FbaObservationModal from "./observation/FbaObservationModal";
import AnalyzeFbaResultsModal from "./analyze-results-modal/AnalyzeFbaResultsModal";
import NewDataCollectionPopover from "./NewDataCollectionPopover";
import { ApplicationState } from "../../../../../store";
import { Fba } from "../../../../../store/onboarding/cases/types";

type Props = {};

const FbaDashboard: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFbas());
    dispatch(resetGoalChooseConcernModel(true));
  }, []);
  
  const fbas = useSelector<ApplicationState, Fba[]>((s) => s.cases.fbas);
  const groupedFbas = useMemo(
    () =>
      fbas.reduce(
        (pV, cV) => {
          if (cV.intervention_group) {
            return { ...pV, active: [...pV.active, cV] };
          } else {
            return { ...pV, observing: [...pV.observing, cV] };
          }
        },
        {
          observing: [] as Fba[],
          active: [] as Fba[],
        }
      ),
    [fbas]
  );
  return (
    <div className="onboardingContainer">
      <div className="fbaInfoContainer">
        <div>
          <h1>ABC Data Collection</h1>
          <p>
            Conduct an ABC Data Collection to determine the typical antecedents
            and consequences surrounding a problem behavior. Knowing what comes
            before and after the behavior will help you identify important
            environmental triggers, what consequences the behavior typically
            receives, and subsequently find an appropriate replacement behavior.
          </p>
        </div>
        <div>
          {groupedFbas.observing.length && groupedFbas.active.length ? <NewDataCollectionPopover /> : ''}
          
        </div>
      </div>

      <FbaDashboardContainer />

      <FbaObservationModal />
      <AnalyzeFbaResultsModal />
      <InterpretFbaModal />
      <FbaModal />
    </div>
  );
};

export default FbaDashboard;
