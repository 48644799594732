import React, { ChangeEvent, Component } from "react";
import Modal from "react-bootstrap/Modal";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  hideCoachTeacherRelationModal,
  shareCoachTeacherRelationData,
} from "../../../../../store/onboarding/actions";
import {
  GradeLevels,
  ReactSelectOption,
  School,
  TeacherInfo,
} from "../../../../../store/onboarding/types";
import Select from "react-select";
import { Grade } from "../../../../../store/groups/types";
import { ValueType } from "react-select/src/types";
import { Form, Spinner } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { IS_READY_COACH } from "../../../../../constants";

type OwnProps = {
  
};

type PropsFromState = {
  isLoading: boolean;
  errors?: string;
  teachers: TeacherInfo[];
  schools: School[];
  showModal: boolean;
};

type DispatchProps = {
  hideCoachTeacherRelationModal: () => any;
  shareCoachTeacherRelationData: (
    data: {
      grades: Grade[];
      teachers: number[];
      schools: number[];
      email: string;
    }
  ) => any;
};

type Props = OwnProps & PropsFromState & DispatchProps;

type State = {
  gradesFilter: Grade[];
  teachersFilter: number[];
  schoolsFilter: number[];
  email: string;
};

class CoachTeacherRelationModal extends Component<Props> {
  initialState = {
    gradesFilter: [],
    teachersFilter: [],
    schoolsFilter: [],
    email: '',
  }
  state: Readonly<State> = this.initialState;

  componentDidMount() {
    this.setState(this.initialState);
  }

  // componentDidUpdate(
  //   prevProps: Readonly<OwnProps & PropsFromState & DispatchProps>,
  //   prevState: Readonly<{}>
  // ): void {
  //   if (
  //     this.props.selectedUser &&
  //     prevProps.selectedUser !== this.props.selectedUser
  //   ) {
  //     const { selectedUser } = this.props;
  //     this.setState({
  //       gradesFilter: selectedUser.grades,
  //       schoolsFilter:
  //         selectedUser && selectedUser.schools
  //           ? selectedUser.schools.map((school) => school.id)
  //           : [],
  //       teachersFilter: selectedUser.teachers.map((teacher) => teacher.id),
  //       email: selectedUser.all_data,
  //     });
  //   }
  //   if (prevProps.isLoading && !this.props.isLoading) {
  //     if (this.props.errors) {
  //       toastr.error("Error!", this.props.errors);
  //     } else {
  //       this.props.hideCoachTeacherRelationModal();
  //     }
  //   }
  // }

  handleGradeLevelChange = (
    options: ValueType<ReactSelectOption<Grade>, true>
  ) => {
    if (options && (options as ReactSelectOption<Grade>[]).length > 0) {
      this.setState({
        gradesFilter: (options as ReactSelectOption<Grade>[]).map(
          (v) => v.value
        ),
      });
    } else {
      this.setState({ gradesFilter: [] });
    }
  };

  handleTeacherChange = (options: ValueType<TeacherInfo, true>) => {
    if (options && (options as TeacherInfo[]).length > 0) {
      this.setState({
        teachersFilter: (options as TeacherInfo[]).map((t) => t.user.id),
      });
    } else {
      this.setState({ teachersFilter: [] });
    }
  };

  handleSchoolChange = (options: ValueType<School, true>) => {
    if (options && (options as School[]).length > 0) {
      this.setState({
        schoolsFilter: (options as School[]).map((sc) => sc.id),
      });
    } else {
      this.setState({ schoolsFilter: [] });
    }
  };


  handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  handleHideModal = () => {
    this.setState(this.initialState);
    this.props.hideCoachTeacherRelationModal();
  };


  handleUpdateData = (event: React.FormEvent) => {
    event.preventDefault();
    
    const {
      gradesFilter,
      teachersFilter,
      schoolsFilter,
      email,
    } = this.state;
      const data = {
        grades: gradesFilter,
        teachers: teachersFilter,
        schools: schoolsFilter,
        email: email,
      };
      this.props.shareCoachTeacherRelationData(data)
      .then(() => {
        if(!this.props.errors) {
          this.handleHideModal();
          toastr.success('Success', "Coach Teacher Data Access Relationship successfully saved.")
        } else {
          toastr.error('Error', this.props.errors)
        }
      })
  
  };

  render() {
    return (
      <div>
        <Modal
          show={this.props.showModal}
          onHide={this.handleHideModal}
          animation={false}
          size="lg"
          backdropClassName={"customDarkModalBackdrop in"}
        >
          <Modal.Header
            closeButton
            className={
              IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"
            }
          >
            <Modal.Title>
              Coach Teacher Data Access Relationship
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div>
                <h2>Please enter the email of the teacher you want to share data with and also select the appropriate data access settings you wish to set for that teacher. </h2>
                <Form id="coachTeacherRelationForm" onSubmit={this.handleUpdateData}>
                  <div className="d-flex flex-column m-3">
                    <div>
                    <span className="font-weight-bold">
                        TEACHERS&apos; EMAIL
                    </span>
                    <Form.Control
                      required
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleEmailChange}
                    />
                    </div>
                    <div>
                      <span className="font-weight-bold">
                        ACCESS TO THESE TEACHERS&apos; DATA
                      </span>
                      <Select
                        isMulti
                        isClearable
                        className="dataFiltersSelect"
                        value={this.props.teachers.filter((x) =>
                          this.state.teachersFilter.includes(x.user.id)
                        )}
                        options={this.props.teachers}
                        getOptionLabel={({ user }: TeacherInfo) =>
                          `${user.first_name} ${user.last_name}`
                        }
                        getOptionValue={({ user }: TeacherInfo) =>
                          user.id!.toString()
                        }
                        onChange={this.handleTeacherChange}
                      />
                    </div>
                    <div className="w-100 mb-2">
                      <span className="font-weight-bold">
                        ACCESS TO THESE GRADE LEVELS
                      </span>
                      <Select
                        isMulti
                        isClearable
                        className="dataFiltersSelect"
                        value={GradeLevels.filter((gl) =>
                          this.state.gradesFilter.includes(gl.value)
                        )}
                        options={GradeLevels}
                        onChange={this.handleGradeLevelChange}
                      />
                    </div>
                    <div className="w-100 mb-2">
                      <span className="font-weight-bold">
                        ACCESS TO THESE SCHOOLS
                      </span>
                      <Select
                        isMulti
                        isClearable
                        className="dataFiltersSelect"
                        value={this.props.schools.filter((x) =>
                          this.state.schoolsFilter.includes(x.id)
                        )}
                        options={this.props.schools}
                        getOptionLabel={(school: School) => school.name}
                        getOptionValue={(school: School) => school.id.toString()}
                        onChange={this.handleSchoolChange}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row justify-content-between float-right m-3">
                    <div>
                      <div
                        className="blueBtnMd mr-2"
                        onClick={this.handleHideModal}
                      >
                        Cancel
                      </div>
                      <button type="submit" className="blueBtnMd">
                        Submit
                        {this.props.isLoading && (
                          <Spinner animation="border" size="sm" />
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    isLoading: onboarding.isLoading.shareCoachTeacherRelationData,
    errors: onboarding.errors.shareCoachTeacherRelationData,
    teachers: onboarding.teachersRoster,
    schools: onboarding.schools,
    showModal: onboarding.modalsState.coachTeacherRelationModal,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideCoachTeacherRelationModal: hideCoachTeacherRelationModal,
      shareCoachTeacherRelationData: shareCoachTeacherRelationData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachTeacherRelationModal);
