import React, { FunctionComponent, useEffect, useState } from "react";
import Joyride, { CallBackProps, Step } from "react-joyride";
import { ApplicationState } from "../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { markAddSecondaryGoalAsShown } from "../../../../store/tour/actions";
import { connect } from "react-redux";

type StateProps = {
  addSecondaryGoalPositionTourShown?: boolean;
};
type DispatchProps = {
  markAddSecondaryGoalAsShown: (isShown?: boolean) => any;
};

type Props = StateProps & DispatchProps;

const AddSecondaryGoalPositionTour: FunctionComponent<Props> = (props) => {
  const [show, setShow] = useState(false);
  const steps: Step[] = [
    {
      disableBeacon: true,
      target: ".addSecondaryGoal",
      content: (
        <>
          Click on the + icon under Action to add secondary goals.
        </>
      ),
    },
  ];

  useEffect(() => {
    if(props.addSecondaryGoalPositionTourShown) {
      setShow(true);
    }
  }, [props.addSecondaryGoalPositionTourShown]);

  const handleTourChange = (data: CallBackProps) => {
    if (["skip", "reset"].includes(data.action)) {
      props.markAddSecondaryGoalAsShown(false);
    }
  };

  return (
    <Joyride
      //disableScrolling
      steps={steps}
      floaterProps={{ disableAnimation: true }}
      showSkipButton
      callback={handleTourChange}
      run={show}
      styles={{
        options: {
          primaryColor: "#00265B",
          zIndex: 1060
        },
      }}
    />
  );
};

const mapStateToProps = ({ tour }: ApplicationState): StateProps => {
  return {
    addSecondaryGoalPositionTourShown: tour.addSecondaryGoalPositionTourShown,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      markAddSecondaryGoalAsShown: markAddSecondaryGoalAsShown,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSecondaryGoalPositionTour);
