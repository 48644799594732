import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { Bar, ChartData } from "react-chartjs-2";
import { ApplicationState } from "../../../../../../store";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  ImplementationCheck,
  LoggedProgress,
  ProgressOverview,
  StudentGoalProgress,
} from "../../../../../../store/onboarding/meeting-module/types";
import moment from "moment";
import {
  Measurement,
  MeasurementType,
  ReactSelectOption,
  Student,
  measurementOptionsList,
} from "../../../../../../store/onboarding/types";
import { InterventionGroup, StudentGoal } from "../../../../../../store/onboarding/cases/types";
import {
  generateInterventionAbbrev,
  getFullName,
  getLoggedBy,
  getTitleByMeasurement,
} from "../../../../../../utils/NamesUtils";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Form, Table } from "react-bootstrap";
import Select, { ValueType } from "react-select";
import { sortDates } from "../../../../../utils/DateTimeUtils";
import { changeInterventionPlanFilters } from "../../../../../../store/onboarding/cases/actions";
import useInterventionPlan from "./hooks/useInterventionPlan";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";
import ImplementationCheckAlert from "./ImplementationCheckAlert";
import useUserRole from "../../../../../../utils/hooks/useUserRole";
import useUserAssignedToGroup from "../assigment/hooks/useUserAssignedToGroup";
import LoggedProgressTab from "../assigment/intervention-plan-modal/workspaces/LoggedProgressTab";
import LoggedProgressTable from "./LoggedProgressTable";

type StateProps = {
  selectedInterventionGroup?: InterventionGroup;
  progressOverview?: ProgressOverview;
  isLoading: {
    getProgressOverviewData: boolean;
  };
  loggedGoalProgressData: any;
  notShareDataWith?: boolean;
};

type OwnProps = {
  observations?: { date: string; interventions: number[] }[];
  onImpCheckDisabled?: (implCheck: boolean) => void;
};

type Props = OwnProps & StateProps;

const GOAL_LINE_PROGRESS_TICK = 5;

const InterventionFidelityChart: FunctionComponent<Props> = (props) => {
  const { observations, selectedInterventionGroup, progressOverview, loggedGoalProgressData } = props;
  const [showExitStudents, setShowExitStudents] = useState<boolean>(false);
  const [measurementOptions, setMeasurementOptions] = useState<any>([]);
  const [studentOptions, setStudentOptions] = useState<any>([]);


  const showExitStudentsRef = useRef<HTMLInputElement>(null);
  const showOnlyActiveInterventionsRef = useRef<HTMLInputElement>(null);

  const displayGroup = useSelector((s: ApplicationState) => s.cases.displayGroupName);

  const isGroupDetail =  useSelector(
    (s: ApplicationState) => s.cases.isGroupDetail
  );

  const { isDistrictOwner } = useUserRole()
  const dispatch = useDispatch();
  const chartRef = useRef<any>(null);


  let {
    selectedStudents,
    selectedStudentGoals,
    selectedStudentGoalMeasure,
    showOnlyActiveInterventions,
    showGoalLines,
    showStudentTrendsLines,
  } = useSelector(
    (s: ApplicationState) => s.cases.modalsState.interventionPlan
  );

  const everyoneHasSameGoalsPrimary = useMemo(() => {
    return (
      selectedInterventionGroup!.student_goals!.filter((sg) => (showExitStudents ? sg.archived : !sg.archived)).length > 1 &&
      selectedInterventionGroup?.student_goals.filter((sg) => (showExitStudents ? sg.archived : !sg.archived)).every(
        (sg, index, arr) => {
          
          return  sg.target_date == arr[0].target_date &&
            sg.target_value == arr[0].target_value &&
            sg.measurement.id == arr[0].measurement.id
          }
      )
    );
  }, [selectedInterventionGroup?.student_goals, selectedInterventionGroup?.students]);

  const everyoneHasSameGoalsSecondary = useMemo(() => {
    return (
      selectedInterventionGroup!.student_goals!.filter((sg) => (showExitStudents ? sg.archived : !sg.archived)).length > 1 &&
      selectedInterventionGroup?.student_goals.filter((sg) => (showExitStudents ? sg.archived : !sg.archived)).every(
        (sg, index, arr) => {
        return  sg.target_date == arr[0].target_date &&
          sg.target_value == arr[0].target_value &&
          sg.measurement.id == arr[0].measurement.id
        }
      )
    );
  }, [selectedInterventionGroup?.student_goals, selectedInterventionGroup?.students]);

  const { isUserAssignedToGroup, isStudentDetail } = useUserAssignedToGroup(selectedInterventionGroup!);

  useEffect(() => {
    //console.log(displayGroup)
    if(displayGroup == "archived" || displayGroup == "exited_groups") {
      showExitStudentsRef.current?.click();
      showOnlyActiveInterventionsRef.current?.click();
    } else if(showExitStudents && (displayGroup == 'active')) {
      showExitStudentsRef.current?.click();
      showOnlyActiveInterventionsRef.current?.click();
    }
  },[displayGroup])

  let allStudentIdsOfActiveGoalsArray = useMemo(() => {
    let allStudentIdsOfGoals = new Set();
    let allStudentIdsOfActiveGoals = new Set();
  
    selectedInterventionGroup?.student_goals.map((goal, index) => {
      allStudentIdsOfGoals.add(goal.student.id);
      if (!goal.archived) {
        allStudentIdsOfActiveGoals.add(goal.student.id);
      }
    });
    return  Array.from(allStudentIdsOfActiveGoals);
  },[]);

  let allStudentIdsOfArchivedGoalsArray = useMemo(() => {
    let allStudentIdsOfGoals = new Set();
    let allStudentIdsOfArchivedGoals = new Set();
  
    selectedInterventionGroup?.student_goals.map((goal, index) => {
      allStudentIdsOfGoals.add(goal.student.id);
      if (goal.archived) {
        allStudentIdsOfArchivedGoals.add(goal.student.id);
      }
    });
    return  Array.from(allStudentIdsOfArchivedGoals);
  },[]);

  const filteredStudentData = useMemo(() => {
    let result: StudentGoalProgress[] = 
     progressOverview?.student_goal_progress.filter((element) =>
                  selectedInterventionGroup?.student_goals.some((sg) => sg.id == element.student_goal_id && !sg.archived)
                  && allStudentIdsOfActiveGoalsArray.includes(element.student.id)
                ) || [];
    if(showExitStudents) {
      const archivedResult = progressOverview?.student_goal_progress?.filter((element) =>
        selectedInterventionGroup?.student_goals.some((sg) => sg.id == element.student_goal_id && sg.archived)
        && allStudentIdsOfArchivedGoalsArray.includes(element.student.id)
      ) || [];
      result = result.concat(archivedResult)
    }
    return result;
  }, [
      showExitStudents, 
      progressOverview
    ]);

  const uniqueByKey = (array:any[], key:any ='id') => {
    return Array.from(new Map(array.map((x:any) => [x[key], x])).values());
  }

  const measurementOptionsList = (filterByStudentIds?: any) => {
    let studentGoalsByInterventionGroup: StudentGoal[] = [];
    if(!showExitStudents) {
      studentGoalsByInterventionGroup = selectedInterventionGroup?.student_goals
      .filter((sg) => !sg.archived)!
    } else {
      studentGoalsByInterventionGroup = selectedInterventionGroup?.student_goals!
    }
    const measurements =  
      studentGoalsByInterventionGroup.filter((sg) => filterByStudentIds ? filterByStudentIds.includes(sg.student.id!)  : true)
      .sort((sg) => sg.is_primary_goal ? -1 : 1)
      .sort((a,b) => a.student.last_name.localeCompare(b.student.last_name))
      .reduce((pV: any, cV: any) => 
        !pV.some((measure: any) => measure.value == cV.measurement.id)
        ? [
            ...pV, 
            {
              label: cV.measurement.display_name,
              value: cV.measurement.id
            }
          ]
        : pV
      ,[]);
    setMeasurementOptions(uniqueByKey(measurements, 'value') || []); 
    return Promise.resolve();
  }


  const studentOptionsList = (filterByMeasurement?: any) => {
    let filterByMeasurementStudentIds: any[] = [];

      let studentsByInterventionGroup: Student[] = [];
      if(!showExitStudents) {
        studentsByInterventionGroup = selectedInterventionGroup?.student_goals
        .filter((sg) => !sg.archived).map((std) => std.student)!
      } else {
        studentsByInterventionGroup = selectedInterventionGroup?.student_goals!.map((std) => std.student)!;
      }
     
      let studentGoalsByInterventionGroup: StudentGoal[] = [];
      if(!showExitStudents) {
        studentGoalsByInterventionGroup = selectedInterventionGroup?.student_goals
        .filter((sg) => !sg.archived)!
      } else {
        studentGoalsByInterventionGroup = selectedInterventionGroup?.student_goals!;
      }


    if(filterByMeasurement) {
      filterByMeasurementStudentIds = studentsByInterventionGroup
        .filter((std) => studentGoalsByInterventionGroup.some((sg) => (sg.measurement.id == filterByMeasurement.value) && (sg.student.id == std.id)))
        .map((std) => std.id)!
    }
    let studentOptions = 
    studentsByInterventionGroup
    .filter((std) => studentGoalsByInterventionGroup
            .some((sg) => sg.student.id == std.id)
    )
    .reduce((pV: any, cV: any) => 
    !pV.some((student: any) => student.value == cV.id)
    ? [
      ...pV, 
      {
        label: getFullName(cV),
        value: cV.id.toString()
      }
    ]
    : pV
    ,[]);

    
    if(filterByMeasurementStudentIds.length) {
      studentOptions = [...studentOptions.filter((studentOption:any) => filterByMeasurementStudentIds.includes(+studentOption.value))]
    
      
    }
    
    setStudentOptions(studentOptions);

  }
  
  useEffect(() => {
    measurementOptionsList();    
    studentOptionsList();
  },[])

  useEffect(() => {
    measurementOptionsList(selectedStudents.length ? selectedStudents.map((std:any) => +std.value) : undefined);    
    studentOptionsList(selectedStudentGoalMeasure != null ? selectedStudentGoalMeasure : 0);
  },[showExitStudents])

  useEffect(() => {

    let studentGoalIdsFromMeasurement: number[] = [];
    let studentGoalIdsFromStudent: number[] = [];

    if(selectedStudentGoalMeasure) {
      studentGoalIdsFromMeasurement = progressOverview?.student_goal_progress
      .filter((sgp) => sgp.measurement.id == selectedStudentGoalMeasure.value)
      .map((sgp) => sgp.student_goal_id)!;
    }

    if(selectedStudents.length) {
      
      studentGoalIdsFromMeasurement = progressOverview?.student_goal_progress
      .filter((sgp) => selectedStudents.some((std: any) => std.value == sgp.student.id))
      .map((sgp) => sgp.student_goal_id)! || [];
    }

    let studentGoalIds: number[] = [];
    if(!studentGoalIdsFromMeasurement?.length || !studentGoalIdsFromStudent?.length) {
      studentGoalIds = studentGoalIdsFromMeasurement?.concat(studentGoalIdsFromStudent);
    } else {
      studentGoalIds = studentGoalIdsFromMeasurement?.filter(value => studentGoalIdsFromStudent.includes(value)); 
    }
    let studentGoals: StudentGoalProgress[] | undefined = 
        progressOverview?.student_goal_progress
        .filter((sgp) => sgp.logged_progress.some((lp) => studentGoalIds?.includes(lp.goal!)));

    dispatch(
      changeInterventionPlanFilters({ 
        selectedStudentGoals: studentGoals
      })
    );        
  },[selectedStudentGoalMeasure, selectedStudents])


  const handleStudentGoalMeasureChange = (
    measurement: ValueType<ReactSelectOption<any>, false>
  ) => {
    
    if(measurement == null) {
      dispatch(
        changeInterventionPlanFilters({
            selectedStudents: selectedStudents.length ? selectedStudents : [], 
            selectedStudentGoals: selectedStudentGoals?.length ? selectedStudentGoals : [], 
            selectedStudentGoalMeasure: undefined,
        })
        );
        measurementOptionsList(selectedStudents.length ? selectedStudents.map((std:any) => +std.value) : undefined);
        studentOptionsList(0)
      } else {
        studentOptionsList(measurement); //filter student dropdown
        
      

        dispatch(
          changeInterventionPlanFilters({ 
            selectedStudentGoalMeasure: measurement
          })
        );
       
    }
  }

 
  
  const handleSelectedStudentChange = (student: any, selectedOption: any) => {
    
    if(student == null) {
    
      if(selectedOption.action == 'remove-value') {
        selectedStudentGoals = [...selectedStudentGoals
                                .filter((ssg) => +selectedOption.removedValue.value !== ssg.student.id)]
      }
        dispatch(
          changeInterventionPlanFilters({ 
            selectedStudents: [], 
            selectedStudentGoals: selectedStudentGoals?.length ? selectedStudentGoals : [], 
            selectedStudentGoalMeasure: selectedStudentGoalMeasure || undefined,
          })
        );
        measurementOptionsList(undefined);
        if(selectedStudentGoalMeasure != null) {
          studentOptionsList(selectedStudentGoalMeasure)
        } 
        
        if(selectedStudentGoalMeasure == null){
          studentOptionsList(0)
        }
      } else {
        measurementOptionsList(student.map((std:any) => +std.value)); //filter student by measurement

      
        dispatch(
          changeInterventionPlanFilters({ 
            selectedStudents: student,
          })
        );
    }
  };

  const handleShowOnlyActiveInterventionsChange = () => {
    dispatch(
      changeInterventionPlanFilters({
        showOnlyActiveInterventions: !showOnlyActiveInterventions,
      })
    );
  };

  const {
    implementationChecks: filteredImplementationChecks,
  } = useInterventionPlan(
    selectedInterventionGroup,
    showOnlyActiveInterventions
  );

  const studentGoalProgressFiltered = useMemo(() => {
    if (selectedStudentGoalMeasure == undefined && !selectedStudents.length) {
      return filteredStudentData?.filter((fsd) => fsd.is_general_outcome && fsd.is_primary_goal) ?? [];
    } else if(selectedStudentGoalMeasure && !selectedStudents.length) {
      return (
        filteredStudentData?.filter((fsd) => fsd.is_general_outcome &&
          (selectedStudentGoalMeasure.value == fsd.measurement.id)
        ) ?? []
      );
    } else if((selectedStudentGoalMeasure == undefined) && selectedStudents.length) {
      return (
        filteredStudentData?.filter((fsd) => fsd.is_general_outcome 
          && selectedStudents.some((s: any) =>(s.value == fsd.student.id))
        ) ?? []
      );
    } else if(selectedStudentGoalMeasure && selectedStudents.length) {
      return (
        filteredStudentData?.filter((fsd) => fsd.is_general_outcome &&
          (selectedStudentGoalMeasure.value == fsd.measurement.id)
          && selectedStudents.some((s: any) =>(s.value == fsd.student.id))
        ) ?? []
      );
    }

    // if(selectedStudentGoals?.length) {
    //   return (
    //     filteredStudentData?.filter((fsd) =>
    //       selectedStudentGoals?.some((ssg) => ssg.is_general_outcome && (ssg.student_goal_id === fsd.student_goal_id))
    //     ) ?? []
    //     );
    // } else if(selectedStudents.length) {
    //   return (
    //     filteredStudentData?.filter((fsd) =>
    //       selectedStudents.some((s) => fsd.is_general_outcome &&  (s.id === fsd.student.id))
    //     ) ?? []
    //   );
    // } 
    return [];
  }, [selectedStudents, selectedStudentGoalMeasure, selectedStudentGoals, filteredStudentData]);

  const sameMeasurement: StudentGoal | StudentGoalProgress | Measurement | undefined = useMemo(() => {
    const sameMeasurement = studentGoalProgressFiltered?.every(
      (value, index, array) => value.measurement.id === array[0].measurement.id
    );
    let studentGoalProgress;
    if(selectedStudentGoalMeasure != undefined) {
      studentGoalProgress = studentGoalProgressFiltered?.find((sgpf) => sgpf.measurement.id == selectedStudentGoalMeasure.value)!
    }
    return sameMeasurement
      ? studentGoalProgress?.measurement
      : undefined;
  }, [studentGoalProgressFiltered]);

  const colors = useMemo(
    () => [
      "#003f5c",
      "#2f4b7c",
      "#665191",
      "#a05195",
      "#d45087",
      "#f95d6a",
      "#ff7c43",
      "#ffa600",
      "#fcc267",
      "#ffff9d",
    ],
    []
  );

  const goalColors = useMemo(
    () => [
      "#143c00",
      "#144600",
      "#145000",
      "#145a00",
      "#146400",
      "#146e00",
      "#147800",
      "#148200",
      "#148c00",
      "#149600",
    ],
    []
  );

  const trendColors = useMemo(
    () => [
      "#003c3c",
      "#004646",
      "#005050",
      "#005a5a",
      "#006464",
      "#006e6e",
      "#007878",
      "#008282",
      "#008c8c",
      "#009696",
    ],
    []
  );

  const xAxisLabels = useMemo(() => {
    const allDates = new Set<string>();

    filteredImplementationChecks.forEach((ic) =>
      allDates.add(moment.utc(ic.observed_date).format("MM/DD/YYYY"))
    );

    const datesGroups: {
      loggedProgress: string[];
      goalLineProgress: string[];
      trendlineProgress: string[];
    } = studentGoalProgressFiltered?.reduce(
      (pV, cV) => {
        return {
          loggedProgress: [
            ...pV.loggedProgress,
            ...cV.logged_progress.map((lp) =>
              moment.utc(lp.date).format("MM/DD/YYYY")
            ),
          ],
          goalLineProgress: [
            ...pV.goalLineProgress,
            ...(cV.goal_line_progress ?? []).map((lp) =>
              moment.utc(lp.date).format("MM/DD/YYYY")
            ),
          ],
          trendlineProgress: [
            ...pV.trendlineProgress,
            ...(cV.trendline_progress ?? []).map((lp) =>
              moment.utc(lp.date).format("MM/DD/YYYY")
            ),
          ],
        };
      },
      {
        loggedProgress: [] as string[],
        goalLineProgress: [] as string[],
        trendlineProgress: [] as string[],
      }
    );

    datesGroups.loggedProgress.forEach(allDates.add, allDates);

    if (showGoalLines) {
      datesGroups.goalLineProgress.forEach(allDates.add, allDates);
    }

    if (showStudentTrendsLines) {
      datesGroups.trendlineProgress.forEach(allDates.add, allDates);
    }

    if (
      (showGoalLines || showStudentTrendsLines) &&
      (datesGroups.goalLineProgress.length ||
        datesGroups.trendlineProgress.length) &&
      datesGroups.loggedProgress.length
    ) {
      const lastLpDate = datesGroups.loggedProgress.sort(sortDates)[
        datesGroups.loggedProgress.length - 1
      ];
      const lastGlpDate = datesGroups.goalLineProgress.sort(sortDates)[
        datesGroups.goalLineProgress.length - 1
      ];
      const lastTlDate = datesGroups.trendlineProgress.sort(sortDates)[
        datesGroups.trendlineProgress.length - 1
      ];

      const lpDate = moment(lastLpDate);
      const lastDate =
        lastGlpDate && lastTlDate
          ? moment.max(moment(lastGlpDate), moment(lastTlDate))
          : lastGlpDate
          ? moment(lastGlpDate)
          : moment(lastTlDate);

      const diff = lastDate.diff(lpDate, "days");

      const ticksForNormalize: string[] = [];
      if (diff > GOAL_LINE_PROGRESS_TICK) {
        const tickCount = Math.round(diff / GOAL_LINE_PROGRESS_TICK);
        for (let i = 1; i <= tickCount; i++) {
          ticksForNormalize.push(
            lpDate.add(GOAL_LINE_PROGRESS_TICK, "days").format("MM/DD/YYYY")
          );
        }
      }

      ticksForNormalize.forEach(allDates.add, allDates);
    }

    // if (showWithoutFidelity && observations) {
    //   observations.forEach(
    //     (obs) => allDates.add(moment(obs.date).format("MM/DD/YYYY")),
    //     allDates
    //   );
    // }

    return Array.from(allDates).sort(sortDates);
  }, [
    filteredImplementationChecks,
    studentGoalProgressFiltered,
    showGoalLines,
    showStudentTrendsLines,
    // showWithoutFidelity,
    observations,
  ]);

  const interventionsData = useMemo(
    () =>
      filteredImplementationChecks
        .reduce(
          (
            pV: { intervention: number; checks: any[] }[],
            cV: ImplementationCheck
          ) => {
            const exist = pV.some((el) => el.intervention === cV.intervention);
            if (exist) {
              return pV.map((el) =>
                el.intervention === cV.intervention
                  ? { ...el, checks: [...el.checks, cV] }
                  : el
              );
            }

            return [...pV, { intervention: cV.intervention, checks: [cV] }];
          },
          []
        )
        .map((el) => ({
          intervention: el.intervention,
          fidelityData: xAxisLabels.map((tick) =>
            el.checks.find(
              (ic) => moment.utc(ic.observed_date).format("MM/DD/YYYY") === tick
            )
          ),
        })),
    [filteredImplementationChecks, xAxisLabels]
  );

  const data = useMemo(() => {
    if (progressOverview) {
      const fidelityDataSets = interventionsData.map((intData) => {
        const data = intData.fidelityData.map((fidelityCheckOnDateTick) =>
          fidelityCheckOnDateTick
            ? fidelityCheckOnDateTick.fidelity.toFixed(0)
            : null
        );
         return {
          yAxisID: `fidelity`,
          type: "bar",
          label: `Intervention Fidelity - Intervention #${intData.intervention}`,
          interventionId: intData.intervention,
          fidelityData: intData.fidelityData,
          data: data,
          fill: false,
          maxBarThickness: 50,
          backgroundColor: "#b8e986",
          borderColor: "#b8b8b8",
          hoverBackgroundColor: "#b8e986",
          hoverBorderColor: "#b8b8b8",
          borderWidth: 1,
          pointRadius: 0,
          order: 1,
        };
      });

      let studentsProgressDataSetsPrimary: ChartData<any>[] = [];
      let studentsProgressDataSetsSecondary: ChartData<any>[] = [];
      let uniqueStdGoalProgressFilterdWithPrimary = uniqueByKey(studentGoalProgressFiltered.filter((sgp) => sgp.is_primary_goal), 'student_goal_id');
      if(uniqueStdGoalProgressFilterdWithPrimary.length) {

        for (let index = 0; index < uniqueStdGoalProgressFilterdWithPrimary.length; index++) {
          const sgp: StudentGoalProgress = uniqueStdGoalProgressFilterdWithPrimary[index];
            const fillColor = colors[index % colors.length];
            const goalColor = goalColors[index % goalColors.length];
            const trendColor = trendColors[index % trendColors.length];
            const hoverColor = "#0c4a91";
  
            studentsProgressDataSetsPrimary.push({
              yAxisID: "goal",
              type: "line",
              label: `${sgp.student.unique_id} ${sgp.student.first_name} (${sgp.measurement.display_name})`,
              data: xAxisLabels.map(
                (tick) =>{
                  return sgp.logged_progress.find(
                    (lp: any) => moment.utc(lp.date).format("MM/DD/YYYY") === tick
                  )?.value
                }
              ),
              fill: false,
              spanGaps: true,
              pointRadius: 4,
              pointHitRadius: 10,
              lineTension: 0,
              backgroundColor: fillColor,
              borderColor: fillColor,
              pointBackgroundColor: "#ffffff",
              pointBorderColor: fillColor,
              pointHoverBackgroundColor: hoverColor,
              pointHoverBorderColor: fillColor,
            });
            if (showStudentTrendsLines) {
              studentsProgressDataSetsPrimary.push({
                yAxisID: "goal",
                type: "line",
                borderDash: [6, 8],
                label: `${sgp.student.unique_id} ${sgp.student.first_name} (${sgp.measurement.display_name}) Trend`,
                data: xAxisLabels.map(
                  (tick) =>
                    sgp.trendline_progress?.find(
                      (lp: any) => moment.utc(lp.date).format("MM/DD/YYYY") === tick
                    )?.value
                ),
                fill: false,
                spanGaps: true,
                pointRadius: 4,
                pointHitRadius: 10,
                lineTension: 0,
                backgroundColor: trendColor,
                borderColor: trendColor,
                pointBackgroundColor: "#ffffff",
                pointBorderColor: trendColor,
                pointHoverBackgroundColor: trendColor,
                pointHoverBorderColor: trendColor,
              });
            }
    
            if (showGoalLines) { 
              
              studentsProgressDataSetsPrimary.push({
                yAxisID: "goal",
                type: "line",
                borderDash: [6, 8],
                label: `${sgp.student.unique_id} ${sgp.student.first_name} (${sgp.measurement.display_name}) Goal`,
                data:  xAxisLabels.map(
                  (tick, index) => {
                 return  sgp.goal_line_progress?.find(
                      (lp: any) => {
                    
                        return moment.utc(lp.date).format("MM/DD/YYYY") == tick
                      }
                    )?.value
                  }
                ),
                fill: false,
                spanGaps: true,
                pointRadius: 4,
                pointHitRadius: 10,
                lineTension: 0,
                backgroundColor: goalColor,
                borderColor: goalColor,
                pointBackgroundColor: "#ffffff",
                pointBorderColor: goalColor,
                pointHoverBackgroundColor: goalColor,
                pointHoverBorderColor: goalColor,
              });
            }
        }
      }

      let uniqueStdGoalProgressFilterdWithSecondary = 
      uniqueByKey(studentGoalProgressFiltered
        .filter((sgp) => !sgp.is_primary_goal && sgp.is_general_outcome), 'student_goal_id');
  
      if(uniqueStdGoalProgressFilterdWithSecondary.length) {
   
        uniqueStdGoalProgressFilterdWithSecondary?.forEach((sgp: StudentGoalProgress, index) => {
  
          const fillColor = colors[index % colors.length];
          const goalColor = goalColors[index % goalColors.length]; 
          const trendColor = trendColors[index % trendColors.length];
          const hoverColor = "#0c4a91";
          studentsProgressDataSetsSecondary.push({
            yAxisID: "goal",
            type: "line",
            label: `${sgp.student.unique_id} ${sgp.student.first_name} (${sgp.measurement.display_name})`,
            data: xAxisLabels.map(
              (tick) => {
                const loggedValue = sgp.logged_progress.find(
                  (lp: any) => moment.utc(lp.date).format("MM/DD/YYYY") === tick
                )?.value;
              
                return loggedValue;
              }
               
            ),
            fill: false,
            spanGaps: true,
            pointRadius: 4,
            pointHitRadius: 10,
            lineTension: 0,
            backgroundColor: fillColor,
            borderColor: fillColor,
            pointBackgroundColor: "#ffffff",
            pointBorderColor: fillColor,
            pointHoverBackgroundColor: hoverColor,
            pointHoverBorderColor: fillColor,
          });
          if (showStudentTrendsLines) {
            studentsProgressDataSetsSecondary.push({
              yAxisID: "goal",
              type: "line",
              borderDash: [6, 8],
              label: `${sgp.student.unique_id} ${sgp.student.first_name} (${sgp.measurement.display_name}) Trend`,
              data: xAxisLabels.map(
                (tick) =>
                  sgp.trendline_progress?.find(
                    (lp: any) => moment.utc(lp.date).format("MM/DD/YYYY") === tick
                  )?.value
              ),
              fill: false,
              spanGaps: true,
              pointRadius: 4,
              pointHitRadius: 10,
              lineTension: 0,
              backgroundColor: trendColor,
              borderColor: trendColor,
              pointBackgroundColor: "#ffffff",
              pointBorderColor: trendColor,
              pointHoverBackgroundColor: trendColor,
              pointHoverBorderColor: trendColor,
            });
          }
  
          if (showGoalLines) { 
            
            if(!studentsProgressDataSetsSecondary.some((stdProgDataSet) => stdProgDataSet.label.includes('All Students'))) {
              studentsProgressDataSetsSecondary.push({
                yAxisID: "goal",
                type: "line",
                borderDash: [6, 8],
                label: `${sgp.student.unique_id} ${sgp.student.first_name} (${sgp.measurement.display_name}) Goal`,
                data: xAxisLabels.map(
                  (tick) =>
                    sgp.goal_line_progress?.find(
                      (lp: any) => moment.utc(lp.date).format("MM/DD/YYYY") === tick
                    )?.value
                ),
                fill: false,
                spanGaps: true,
                pointRadius: 4,
                pointHitRadius: 10,
                lineTension: 0,
                backgroundColor: goalColor,
                borderColor: goalColor,
                pointBackgroundColor: "#ffffff",
                pointBorderColor: goalColor,
                pointHoverBackgroundColor: goalColor,
                pointHoverBorderColor: goalColor,
              });
            }
          }
        });
      }

      // let withoutFidelityDataSets: any[] = [];
      // if (showWithoutFidelity && observations) {
      //   withoutFidelityDataSets = [
      //     {
      //       yAxisID: `without-fidelity`,
      //       type: "bar",
      //       label: "",
      //       data: xAxisLabels.map((tick) =>
      //         observations.find(
      //           (obs) => moment.utc(obs.date).format("MM/DD/YYYY") === tick
      //         )
      //           ? "100"
      //           : null
      //       ),
      //       fill: false,
      //       maxBarThickness: 50,
      //       backgroundColor: "#86aae9",
      //       borderColor: "#b8b8b8",
      //       hoverBackgroundColor: "#86aae9",
      //       hoverBorderColor: "#b8b8b8",
      //       borderWidth: 1,
      //       pointRadius: 0,
      //     },
      //   ];
      // }

    

      return {
        datasets: isStudentDetail && !isDistrictOwner ? 
          [
            ...(isUserAssignedToGroup ? fidelityDataSets : []),
            ...studentsProgressDataSetsPrimary,
            ...(selectedStudents.length || selectedStudentGoalMeasure ? studentsProgressDataSetsSecondary : []),
          ]
        : [
          ...(!isDistrictOwner ? fidelityDataSets : []),
          ...studentsProgressDataSetsPrimary,
          ...(selectedStudents.length || selectedStudentGoalMeasure ? studentsProgressDataSetsSecondary : []),
          // ...withoutFidelityDataSets,
        ],
        labels: xAxisLabels,
      };
    }
    return undefined;
  }, [
    interventionsData,
    xAxisLabels,
    progressOverview,
    showStudentTrendsLines,
    // showWithoutFidelity,
    observations,
    showExitStudents
  ]);

  const myId = useSelector((s: ApplicationState) => s.auth.userInfo?.id);

  const maxY = useMemo(() => {
    return data?.datasets
      .filter((ds) => ds.yAxisID === "goal")
      .flatMap((ds) => ds.data)
      .reduce((pV, cV) => {
        const value = Number(cV);
        if (Number.isNaN(value)) {
          return pV;
        } else {
          if (pV !== undefined) {
            return Math.max(value, pV);
          } else {
            return value;
          }
        }
      }, undefined);
  }, [data?.datasets]);

  const options = useMemo(
    () => ({
      responsive: true,
      layout: {
        padding: {
          top: 30,
        },
      },
      tooltips: {
        enabled: false,
        callbacks: {
          label: (tooltipItem: any, data: any) => {
            const isFidelityDataSet =
              data.datasets[tooltipItem.datasetIndex].yAxisID === "fidelity";
            const isWithoutFidelityDataSet =
              data.datasets[tooltipItem.datasetIndex].yAxisID ===
              "without-fidelity";

            if (isWithoutFidelityDataSet && observations) {
              return observations
                .find(
                  (obs) =>
                    moment.utc(obs.date).format("MM/DD/YYYY") ===
                    tooltipItem.label
                )
                ?.interventions.map(
                  (intId) =>
                    selectedInterventionGroup?.interventions.find(
                      (int) => int.id === intId
                    )?.name
                )
                .join(", ");
            }

            const measurement =
              studentGoalProgressFiltered[tooltipItem.datasetIndex]
                ?.measurement;

            let intName;
            let archIntName;

            if (isFidelityDataSet) {
              const interventionId =
                data.datasets[tooltipItem.datasetIndex].interventionId;

              intName = selectedInterventionGroup?.interventions.find(
                (int) => int.id === interventionId
              )?.name;
              archIntName = selectedInterventionGroup?.archived_interventions.find(
                (int) => int.id === interventionId
              )?.name;
            }

            return `${
              isFidelityDataSet
                ? "Intervention Fidelity"
                : data.datasets[tooltipItem.datasetIndex].label
            }${intName ? ` (${intName})` : archIntName ? `(${archIntName})` : ""}: ${getTitleByMeasurement(
              tooltipItem.value,
              measurement
            )}${isFidelityDataSet ? "%" : ""}`;
          },
          footer: (tooltipItem: any, data: any) => {
            const isFidelityDataSet =
              data.datasets[tooltipItem[0]?.datasetIndex ?? 0]?.yAxisID ===
              "fidelity";

            if (!isFidelityDataSet) {
              return;
            }
            const createdBy = getLoggedBy(
              interventionsData.find(
                (intData) =>
                  intData.intervention ===
                  data.datasets[tooltipItem[0].datasetIndex].interventionId
              )?.fidelityData[tooltipItem[0].index]?.logged_by_user,
              myId
            );

            return `Logged by ${createdBy}`;
          },
        },
        custom: (tooltip: any) => {
          let tooltipEl = document.getElementById("chartjs-tooltip");
  
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";
            chartRef.current.chartInstance.ctx.canvas.parentNode.appendChild(
              tooltipEl
            );
          }
  
          // Hide if no tooltip
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = "0";
            tooltipEl.remove();
            return;
          }
  
          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltip.yAlign) {
            tooltipEl.classList.add(tooltip.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }
          // Set Text
          if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map((bodyItem: any) =>
              bodyItem.lines.map((l: string) => l + bodyItem.after)
            );
            let innerHtml = "<thead>";
  
            titleLines.forEach(function(title: any) {
              innerHtml += "<tr><th>" + title + "</th></tr>";
            });
            innerHtml += "</thead><tbody>";
            
            if((showGoalLines || showStudentTrendsLines) && !JSON.stringify(tooltip.body).includes('Intervention Fidelity')) {
              
              if(tooltip?.dataPoints) {
                let uniqueStdGoalProgressFilterdWithPrimary: StudentGoalProgress[] =
                     uniqueByKey(
                        studentGoalProgressFiltered
                        .filter((sgp) => 
                          sgp.is_primary_goal), 'student_goal_id');
                
                let sortedStdGoalProgressWithPrimary: StudentGoalProgress[] = uniqueStdGoalProgressFilterdWithPrimary.map((uniquesgpf) =>({
                  ...uniquesgpf,
                  logged_progress: [...uniquesgpf.logged_progress.sort((a,b) => a.date < b.date ? -1 : 1)]
                }))

                
                let uniqueStdGoalProgressFilterdWithSecondary: StudentGoalProgress[] = 
                    uniqueByKey(studentGoalProgressFiltered
                      .filter((sgp) => !sgp.is_primary_goal), 'student_goal_id');
                let sortedStdGoalProgressWithSecondary: StudentGoalProgress[] = uniqueStdGoalProgressFilterdWithSecondary.map((uniquesgpf) =>({
                  ...uniquesgpf,
                  logged_progress: [...uniquesgpf.logged_progress.sort((a,b) => a.date < b.date ? -1 : 1)]
                }))

                const bodyLinesPrimary = tooltip.body.filter((body:any) => sortedStdGoalProgressWithPrimary.some((primary) => body.lines[0].includes(primary.measurement.display_name)))
                .map((bodyItem: any) =>
                  bodyItem.lines.map((l: string) => l + bodyItem.after)
                );
                const bodyLinesSecondary = tooltip.body
                .filter((body:any) => 
                  sortedStdGoalProgressWithSecondary
                    .some((secondary) => body.lines[0].includes(secondary.measurement.display_name))
                ).map((bodyItem: any) =>
                    bodyItem.lines.map((l: string) => l + bodyItem.after)
                );

                

                let primaryGoalExistInTooltipDataPoint =
                  sortedStdGoalProgressWithPrimary.filter((ssgp) => ssgp.logged_progress
                  .some((lp) => lp.date.includes(moment(tooltip?.dataPoints[0].xLabel).format('YYYY-MM-DD')))).length;
                
                let secondaryGoalExistInTooltipDataPoint =
                  sortedStdGoalProgressWithSecondary.filter((ssgp) => ssgp.logged_progress
                  .some((lp) => lp.date.includes(moment(tooltip?.dataPoints[0].xLabel).format('YYYY-MM-DD')))).length
                
                if(primaryGoalExistInTooltipDataPoint) {
                  bodyLinesPrimary.forEach((body: any, i: number) => {
                    if(!body[0].includes("Goal") && !body[0].includes("Trend")) {
                      const words = body[0].split(' ');
                      words.splice(0, 1); // Removes student unique id
                      body[0] = words.join(' ');
                      const colors = tooltip.labelColors[0];
                      let style = "background:" + colors.backgroundColor;
                      style += "; border-color:" + colors.borderColor;
                      style += "; border-width: 2px";
                      const span =
                      '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
                      innerHtml += "<tr><td>" + span + body[0] + "</td></tr>";
                      }
                  });
                } 
                if(secondaryGoalExistInTooltipDataPoint) {

                  // Remove duplicates
                  const uniqueBodyLineSecondary = Array.from(
                    new Set(bodyLinesSecondary.map((item: any) => JSON.stringify(item)))
                ).map((item: any) => JSON.parse(item));

                  
                uniqueBodyLineSecondary.forEach((body: any, i: number) => {
                    if(!body[0].includes("Goal") && !body[0].includes("Trend")) {
                      const words = body[0].split(' ');
                      words.splice(0, 1); // Removes student unique id
                      body[0] = words.join(' ');
                      const colors = tooltip.labelColors[0];
                      let style = "background:" + colors.backgroundColor;
                      style += "; border-color:" + colors.borderColor;
                      style += "; border-width: 2px";
                      const span =
                        '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
                      innerHtml += "<tr><td>" + span + body[0] + "</td></tr>";
                    }
                  });
                } 
                if(!primaryGoalExistInTooltipDataPoint && !secondaryGoalExistInTooltipDataPoint) {
                  let primaryGoalBody = '';
                  bodyLinesPrimary.forEach((body: any, i: number) => {
                    if(body[0].includes("Goal")) {
                      primaryGoalBody = body[0]
                    }
                  });
                  if(primaryGoalBody) {
                    let primaryGoalLineProgressObj: any = {};
                    let primaryTargetScoreObj: any = {};
                    let primaryObjCount: number = 0;

                    for (let index = 0; index < uniqueStdGoalProgressFilterdWithPrimary.length; index++) {
                      const sgp: StudentGoalProgress = uniqueStdGoalProgressFilterdWithPrimary[index];

                      let studentGoal = selectedInterventionGroup!.student_goals!.filter((sg) => (showExitStudents ? sg.archived : !sg.archived) && sg.is_primary_goal && sg.id == sgp.student_goal_id)
                      const everyoneHasSameGoalsPrimary = selectedInterventionGroup!.student_goals!.filter((sg) => (showExitStudents ? sg.archived : !sg.archived) && sg.is_primary_goal).length > 1 &&
                            selectedInterventionGroup?.student_goals.filter((sg) => (showExitStudents ? sg.archived : !sg.archived) && sg.is_primary_goal).every(
                              (sg, index, arr) => {
                                
                                return  sg.target_date == studentGoal[0]?.target_date &&
                                  sg.target_value == studentGoal[0]?.target_value &&
                                  sg.measurement.id == studentGoal[0]?.measurement.id
                                }
                            );

                        primaryGoalLineProgressObj = sgp.goal_line_progress!
                        .filter(
                          (gpl: any) => gpl.id == sgp.student_goal_id)
                        .reduce(function(rv: any, x: any) {
                          (rv[x['value']] = rv[x['value']] || []).push(x);
                          return rv;
                        }, {});
                        for (const key in primaryGoalLineProgressObj) {
                          if (Object.prototype.hasOwnProperty.call(primaryGoalLineProgressObj, key)) {
                            let element = primaryTargetScoreObj[key] || [];
                            element.push(key);
                            primaryTargetScoreObj[key] = element; 
                          //  break;
                          }
                        }

                        for (const key in primaryTargetScoreObj) {
                          if (Object.prototype.hasOwnProperty.call(primaryTargetScoreObj, key)) {
                            let glpValue = sgp.goal_line_progress?.find(
                              (lp: any) => lp.value === key
                            )?.value 
                            if(glpValue != undefined) {
                              primaryObjCount = Object.keys(primaryTargetScoreObj[glpValue]).length - 1
                            }        
                          }
                        } 
                        if(everyoneHasSameGoalsPrimary && !selectedStudents.length) {
                          primaryGoalBody = `All Students (${sgp.measurement.display_name}) Goal: ${sgp.goal_line_progress![1].value}`
                        } else {
                          primaryGoalBody = primaryObjCount ? sgp.student.unique_id + ' '+ sgp.student.first_name + ' +'+ primaryObjCount +' of add’l students '+`(${sgp.measurement.display_name}) Goal: ${sgp.goal_line_progress![1].value}` : primaryGoalBody
                          const words = primaryGoalBody.split(' ');
                          words.splice(0, 1); // Removes student unique id
                          primaryGoalBody = words.join(' ');
                        }


                    }
                    const colors = tooltip.labelColors[0];
                    let style = "background:" + colors.backgroundColor;
                    style += "; border-color:" + colors.borderColor;
                    style += "; border-width: 2px";
                    const span =
                    '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
                    innerHtml += "<tr><td>" + span + primaryGoalBody + "</td></tr>";
                  }
                  let secondaryGoalBody = '';
                  bodyLinesSecondary.forEach((body: any, i: number) => {
                    if(body[0].includes("Goal")) {
                      secondaryGoalBody = body[0]
                    }
                  });
                  if(secondaryGoalBody) {
                    let secondaryGoalLineProgressObj: any = {};
                    let secondaryTargetScoreObj: any = {};
                    let secondaryObjCount: number = 0;

                    for (let index = 0; index < uniqueStdGoalProgressFilterdWithSecondary.length; index++) {
                      const sgp: StudentGoalProgress = uniqueStdGoalProgressFilterdWithSecondary[index];

                      let studentGoal = selectedInterventionGroup!.student_goals!.filter((sg) => (showExitStudents ? sg.archived : !sg.archived) && !sg.is_primary_goal && sg.id == sgp.student_goal_id)
                      const everyoneHasSameGoalsSecondary = selectedInterventionGroup!.student_goals!.filter((sg) => (showExitStudents ? sg.archived : !sg.archived) && !sg.is_primary_goal).length > 1 &&
                      selectedInterventionGroup?.student_goals.filter((sg) => (showExitStudents ? sg.archived : !sg.archived) && !sg.is_primary_goal).every(
                        (sg, index, arr) => {
                          
                          return  sg.target_date == studentGoal[0]?.target_date &&
                            sg.target_value == studentGoal[0]?.target_value &&
                            sg.measurement.id == studentGoal[0]?.measurement.id
                          }
                      );

                        secondaryGoalLineProgressObj = sgp.goal_line_progress!
                        .filter(
                          (gpl: any) => gpl.id == sgp.student_goal_id)
                        .reduce(function(rv: any, x: any) {
                          (rv[x['value']] = rv[x['value']] || []).push(x);
                          return rv;
                        }, {});
                        for (const key in secondaryGoalLineProgressObj) {
                          if (Object.prototype.hasOwnProperty.call(secondaryGoalLineProgressObj, key)) {
                            let element = secondaryTargetScoreObj[key] || [];
                            element.push(key);
                            secondaryTargetScoreObj[key] = element; 
                          //  break;
                          }
                        }
                        for (const key in secondaryTargetScoreObj) {
                          if (Object.prototype.hasOwnProperty.call(secondaryTargetScoreObj, key)) {
                            let glpValue = sgp.goal_line_progress?.find(
                              (lp: any) => lp.value === key
                            )?.value 
                            if(glpValue != undefined) {
                              secondaryObjCount = Object.keys(secondaryTargetScoreObj[glpValue]).length - 1
                            }        
                          }
                        }  
                        if(everyoneHasSameGoalsSecondary && !selectedStudents.length) {
                          secondaryGoalBody = `All Students (${sgp.measurement.display_name}) Goal: ${sgp.goal_line_progress![1].value}`
                        } else {
                          secondaryGoalBody = secondaryObjCount ? sgp.student.unique_id + ' '+ sgp.student.first_name + ' +'+ secondaryObjCount +' of add’l students '+`(${sgp.measurement.display_name}) Goal: ${sgp.goal_line_progress![1].value}` : secondaryGoalBody
                          const words = secondaryGoalBody.split(' ');
                          words.splice(0, 1); // Removes student unique id
                          secondaryGoalBody = words.join(' ');}
                    }
                    const colors = tooltip.labelColors[0];
                    let style = "background:" + colors.backgroundColor;
                    style += "; border-color:" + colors.borderColor;
                    style += "; border-width: 2px";
                    const span =
                      '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
                    innerHtml += "<tr><td>" + span + secondaryGoalBody +"</td></tr>";
                  }

                  let goalBody = '';
                  bodyLines.forEach((body: any, i: number) => {
                    if(body[0].includes('Trend')){
                      goalBody = body[0]
                    }
                  });

                  if(goalBody) {
                    const words = goalBody.split(' ');
                    words.splice(0, 1); // Removes student unique id
                    goalBody = words.join(' ');
                    const colors = tooltip.labelColors[0];
                    let style = "background:" + colors.backgroundColor;
                    style += "; border-color:" + colors.borderColor;
                    style += "; border-width: 2px";
                    const span =
                      '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
                    innerHtml += "<tr><td>" + span + goalBody +"</td></tr>";
                  }
                
                }
                
              }
            } else {
            const uniqueBodyLine = Array.from(
              new Set(bodyLines.map((item: any) => JSON.stringify(item)))
            ).map((item: any) => JSON.parse(item));
            uniqueBodyLine.forEach((body: any, i: number) => {
                if(!JSON.stringify(tooltip.body).includes('Intervention Fidelity')) {
                  const words = body[0].split(' ');
                  words.splice(0, 1); // Removes student unique id
                  body[0] = words.join(' ');
                }
                  const colors = tooltip.labelColors[i];
                  let style = "background:" + colors.backgroundColor;
                  style += "; border-color:" + colors.borderColor;
                  style += "; border-width: 2px";
                  const span =
                    '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
                  innerHtml += "<tr><td>" + span + body + (JSON.stringify(tooltip.body).includes('Intervention Fidelity') ? "<br/>" + '(Click to see full details)' : '' )+ "</td></tr>";
              });
            }
            innerHtml += "</tbody>";
  
            const tableRoot = tooltipEl.querySelector("table");
            if (tableRoot) {
              tableRoot.innerHTML = innerHtml;
            }
          }
  
          const positionY = chartRef.current.chartInstance.ctx.canvas.offsetTop;
          const positionX = chartRef.current.chartInstance.ctx.canvas.offsetLeft;
  
          // Display, position, and set styles for font
          tooltipEl.style.opacity = "1";
          tooltipEl.style.left = positionX + tooltip.caretX + "px";
          tooltipEl.style.top = positionY + tooltip.caretY + "px";
          tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
          tooltipEl.style.fontSize = tooltip.bodyFontSize + "px";
          tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
          tooltipEl.style.padding =
            tooltip.yPadding + "px " + tooltip.xPadding + "px";
          tooltipEl.style.zIndex= "1";    
          tooltipEl.style.width= "max-content";   
        },
      },
      legend: false,
      scales: {
        xAxes: [
          {
            ticks: {
              max: 10,
              autoSkip : true,
            },
            stacked: true,
          },
          {
            id: "x-axis-2",
            type: "linear",
            position: "bottom",
            display: false,
          },
        ],
        yAxes: [
          {
            id: "goal",
            display: !!sameMeasurement,
            scaleLabel: {
              display: !!sameMeasurement,
              labelString: sameMeasurement?.display_name,
            },
            ticks: {
              max: maxY ? Math.trunc(maxY * 1.5) : undefined,
              callback: (value: number, index: number, values: Array<any>) => {
                if (sameMeasurement) {
                  if (sameMeasurement.type === MeasurementType.CATEGORY) {
                    return sameMeasurement.possible_values?.find(
                      (pv) => pv.order == value
                    )?.display_name;
                  }
                  return value;
                }
                return null;
              },
            },
          },
          {
            id: `fidelity`,
            display: !sameMeasurement,
            ticks: {
              min: 0,
              max: 100,
              display: false,
            },
          },
          {
            id: `without-fidelity`,
            display: false,
            ticks: {
              min: 0,
              max: 100,
              display: false,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          align: "top",
          textAlign: "center",
          offset: 0,
          display: "auto",
          clamp: true,
          textStrokeColor: "black",
          textStrokeWidth: 1,
          textShadowColor: "black",
          textShadowBlur: 4,
          color: "white",
          formatter: (value: any, data: any) => {
            if (value === null || value === undefined) {
              return null;
            }
            const measurement =
              studentGoalProgressFiltered[data.datasetIndex]?.measurement;
            const isFidelityDataSet = data.dataset.yAxisID === "fidelity";
            const isWithoutFidelityDataSet =
              data.dataset.yAxisID === "without-fidelity";

            let label: string;
            if (isFidelityDataSet) {
              const interventionId = data.dataset.interventionId;
              
              const intervention = selectedInterventionGroup?.interventions.find(
                (int) => int.id === interventionId
                );
                
                const archivedIntervention = selectedInterventionGroup?.archived_interventions.find(
                  (int) => int.id === interventionId
                  );
                  
                  const abbrev = intervention
                  ? intervention.abbreviation ??
                  generateInterventionAbbrev(intervention.name)
                  : archivedIntervention ? generateInterventionAbbrev(archivedIntervention.name) :  "unknown";
                  
                  label = `${abbrev}\n${value}%`;
            } else if (isWithoutFidelityDataSet) {
              return null;
            } else {
              label = getTitleByMeasurement(value, measurement).toString();
            }
            return label.length > 15 ? label.split(" ") : label;
          },
        },
      },
    }),
    [
      myId,
      maxY,
      interventionsData,
      sameMeasurement,
      studentGoalProgressFiltered,
      selectedInterventionGroup?.interventions,
      observations,
    ]
  );

  const customStyles: any = useMemo(
    () => ({
      container: (base: any) => ({
        ...base,
        minWidth: 200,
      }),
      control: (base: any) => ({
        ...base,
        minHeight: 30,
      }),
      dropdownIndicator: (base: any) => ({
        ...base,
        padding: 4,
      }),
      clearIndicator: (base: any) => ({
        ...base,
        padding: 4,
      }),
      valueContainer: (base: any) => ({
        ...base,
        padding: "0px 6px",
      }),
      input: (base: any) => ({
        ...base,
        margin: 0,
        padding: 0,
      }),
    }),
    []
  );

  const loading = useSelector(
    (s: ApplicationState) =>
      s.meetingModuleReducer.isLoading.getProgressOverviewData
  );

  // const generalOutcomeDataExist = useMemo(() => {
  //   return selectedStudentGoals?.length ? filteredStudentData?.filter((fsd) =>
  //    selectedStudentGoals?.some((ssg) =>(ssg.student_goal_id === fsd.student_goal_id && ssg.is_general_outcome))
  //  ) : filteredStudentData?.filter((fsd) => fsd.is_general_outcome);
  // },[selectedStudentGoalMeasure, selectedStudentGoals, selectedStudents]);

  const generalOutcomeDataNotExist = useMemo(() => {
    const studentGoalIds = selectedStudentGoals?.length 
    ? filteredStudentData?.filter((fsd) =>
          selectedStudentGoals?.filter((ssg) => !ssg.is_general_outcome )
                            .some((ssg) =>(ssg.student_goal_id === fsd.student_goal_id))
        ).map((ssg) => ssg.student_goal_id) 
    : selectedStudentGoalMeasure == undefined && !selectedStudents.length
        ? filteredStudentData?.filter((fsd) => !fsd.is_general_outcome).map((fsd) => fsd.student_goal_id)
        : [];
   return selectedInterventionGroup?.student_goals.filter((sg) => !sg.is_general_outcome && studentGoalIds?.includes(sg.id))
    //setGeneralOutcomeDataNotExist(generalOutcomeDataNotExist)
  },[selectedStudentGoalMeasure, selectedStudentGoals, selectedStudents ])

  const [implCheck, setImplCheck] = useState<ImplementationCheck | undefined>(
    undefined
  );

  const checkboxDisable = useMemo(() => {
    setTimeout(() => {
      return (displayGroup == "archived") || (displayGroup == "exited_groups")

    },1000)
  },[])

  const handleShowExitStudents = () => {
    setShowExitStudents((currentValue) => !currentValue);
  };

  if (!data && loading) {
    return <LoadingIndicator />;
  }
  if (!data) return <i>Not Logged Data.</i>;
  return (
    <>
        <div
          className="position-relative"
          style={{
            background: "linear-gradient(#e7e7e7, #ffffff)",
            border: "1px solid #979797",
            minHeight: "400px",
          }}
        >
          <div className="mt-3">
            <h3 className="text-center font-weight-bold mb-2">
              Graph Parameters:
            </h3>
              <div className="d-flex flex-wrap flex-column p-2" style={{fontSize: '1rem'}}>
                  <Form.Check
                    ref={showExitStudentsRef}
                    type="switch"
                    id="show-exited-students-switch"
                    label="Show Exited Students"
                    checked={showExitStudents}
                    onClick={handleShowExitStudents}
                    disabled={!!implCheck || (showExitStudents ? ((displayGroup == "archived") || (displayGroup == "exited_groups")) : false) }
                    className="text-left mb-2"
                  />
                <label className="text-left">Measurements:</label>
                <Select
                  isClearable
                  placeholder="Select..."
                  styles={customStyles}
                  value={measurementOptions?.find((measure: any) => measure.value == selectedStudentGoalMeasure?.value) || undefined}
                  options={measurementOptions}
                  onChange={handleStudentGoalMeasureChange}
                />
                <label className="text-left">Student(s): </label>
                <Select
                  isClearable
                  isMulti
                  placeholder="All Students"
                  styles={customStyles}
                  value={selectedStudents}
                        
                  options={studentOptions}
                  // getOptionLabel={getFullName}
                  // getOptionValue={({ id }: Student) => id!.toString()}
                  onChange={(e, selectedOption) => handleSelectedStudentChange(e, selectedOption)}
                />
                
              </div>
              {(data?.datasets.length || displayGroup == "archived" || displayGroup == "exited_groups") && studentGoalProgressFiltered?.length ?
                <div className="fidelity-chat-switches-panel" style={{fontSize: '1rem'}}>
                  <Form.Check
                    type="switch"
                    id="goal-lines-switch"
                    label="Show Goal Lines"
                    checked={showGoalLines}
                    onChange={() => {
                      dispatch(
                        changeInterventionPlanFilters({
                          showGoalLines: !showGoalLines,
                        })
                      );
                    }}
                    disabled={!!implCheck}
                  />
                  <Form.Check
                    type="switch"
                    id="student-trends-lines-switch"
                    label="Show Student Trends Lines"
                    checked={showStudentTrendsLines}
                    onChange={() => {
                      dispatch(
                        changeInterventionPlanFilters({
                          showStudentTrendsLines: !showStudentTrendsLines,
                        })
                      );
                    }}
                    disabled={!!implCheck}
                  />
                  <Form.Check
                    ref={showOnlyActiveInterventionsRef}
                    type="switch"
                    id="only-active-interventions-switch"
                    label="Show Only Active Interventions"
                    checked={showOnlyActiveInterventions}
                    onClick={handleShowOnlyActiveInterventionsChange}
                    disabled={!!implCheck}
                  />
                 
                </div>
               : ''}
            </div>
           {(data?.datasets.length || displayGroup == "archived" || displayGroup == "exited_groups") 
             && ((selectedStudentGoalMeasure || selectedStudents.length) 
             ? studentGoalProgressFiltered?.length 
             : (displayGroup == "archived" || displayGroup == "exited_groups") && !data?.datasets.length && showExitStudents ? false : true ) 
           ?
            <div className="d-flex flex-wrap flex-column p-2">
              <Bar
                ref={chartRef}
                data={data}
                getElementAtEvent={(chartElements: any[]) => {
                  const element = chartElements?.[0];
                  if (!element) {
                    return;
                  }
                  const _datasetIndex = element._datasetIndex;
                  const _index = element._index;
                  const dataSet = element._chart.data.datasets[_datasetIndex];
                  if (!dataSet.fidelityData) {
                    return;
                  }
                  const implCheck: ImplementationCheck = dataSet.fidelityData[_index];
                  if (implCheck) {
                    setImplCheck(implCheck);
                    props.onImpCheckDisabled?.(true)
                  }
                }}
                options={options}
                plugins={[ChartDataLabels]}
                height={150}
              />
              </div>
            : ''}
            {generalOutcomeDataNotExist?.length ? 
            <>
              <div className="d-flex flex-wrap flex-column p-2">
                <LoggedProgressTable
                  studentsFilter={selectedStudents}
                  selectedMeasurement={selectedStudentGoalMeasure}
                  interventionGroup={selectedInterventionGroup!}
                  isStudentDetail={isStudentDetail}
                  isGroupDetail={isGroupDetail}
                  showExitStudents={showExitStudents}
                  fromInterventionFidelityChart
                />
              </div>
            </> : ''
            }
               {
               ((selectedStudentGoalMeasure || selectedStudents.length) 
                  ? !studentGoalProgressFiltered?.length 
                  : false
                ) && 
                (selectedStudentGoalMeasure?.value ? 
                !generalOutcomeDataNotExist?.filter((gom) => (gom.measurement.id == selectedStudentGoalMeasure?.value)).length 
                : selectedStudents.length 
                  ? !generalOutcomeDataNotExist?.filter((gom) => selectedStudents.some((student:any) => +student?.value == gom.student.id)).length
                : false) || !data.datasets.length
                ?
                <>
                  <hr/>
                  <div className="text-center">No Logged Data.</div>
                </>: ''}
            
          </div>
        {implCheck && selectedInterventionGroup && (
            <ImplementationCheckAlert
              interventionGroup={selectedInterventionGroup}
              implCheck={implCheck}
              onClose={() => {
                setImplCheck(undefined);
                props.onImpCheckDisabled?.(false)
              }}
            />
          )}

        
    </>
  );
};

const mapStateToProps = ({
  meetingModuleReducer,
  cases,
}: ApplicationState): StateProps => {
  return {
    selectedInterventionGroup: cases.selectedInterventionGroup,
    progressOverview: cases.selectedInterventionGroup?.progress_overview,
    loggedGoalProgressData: cases.loggedProgressData,
    isLoading: {
      getProgressOverviewData:
        meetingModuleReducer.isLoading.getProgressOverviewData,
    },
    notShareDataWith: cases.notShareDataWith
  };
};

export default connect(mapStateToProps)(InterventionFidelityChart);
