import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from "react";
import LoadingIndicator from "../../../../common/onboarding/LoadingIndicator";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { Fba } from "../../../../../store/onboarding/cases/types";
import FbaItem from "./fba-item/FbaItem";
import { Tab, Tabs } from "react-bootstrap";
import _ from "lodash";
import FbaTab from "./FbaTab";
import Select from "react-select";
import FbaFilters from "./FbaFilters";
import { getFullName } from "../../../../../utils/NamesUtils";
import NiceWorkOverlay from "./NiceWorkOverlay";
import { EventKey } from "react-bootstrap/esm/types";

type OwnProps = {
  onQuestionClick: () => any;
};

type Props = OwnProps;

const FbaTable: FunctionComponent<Props> = (props) => {
  const isLoading = useSelector<ApplicationState>(
    (s) => s.cases.isLoading.getFbas
  );

  const fbas = useSelector<ApplicationState, Fba[]>((s) => s.cases.fbas);

  const groupedFbas = useMemo(
    () =>
      fbas.reduce(
        (pV, cV) => {
          if (cV.intervention_group) {
            return { ...pV, active: [...pV.active, cV] };
          } else {
            return { ...pV, observing: [...pV.observing, cV] };
          }
        },
        {
          observing: [] as Fba[],
          active: [] as Fba[],
        }
      ),
    [fbas]
  );

  const [selectedTeacherId, setSelectedTeacherId] = useState<
    number | undefined
  >(undefined);

  const [searchString, setSearchString] = useState<string>("");

  const filterFun = useCallback(
    (fba: Fba) => {
      return (
        fba.teacher.id === selectedTeacherId ||
        (!!searchString.length &&
          (getFullName(fba.teacher)
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
            getFullName(fba.student)
              .toLowerCase()
              .includes(searchString.toLowerCase())))
      );
    },
    [selectedTeacherId, searchString]
  );

  const filteredFbas = useMemo(() => {
    if (selectedTeacherId || !!searchString.length) {
      return {
        observing: groupedFbas.observing.filter(filterFun),
        active: groupedFbas.active.filter(filterFun),
      };
    }
    return groupedFbas;
  }, [groupedFbas, selectedTeacherId, searchString]);

  const usingFilters = useMemo(() => {
    return selectedTeacherId !== undefined && !!searchString.length;
  }, [selectedTeacherId, searchString]);

  const [activeTab, setActiveTab] = useState<string | null>("observing");

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <FbaFilters
        searchString={searchString}
        setSearchString={setSearchString}
        selectedTeacherId={selectedTeacherId}
        setSelectedTeacherId={setSelectedTeacherId}
      />
      <Tabs
        defaultActiveKey={"observing"}
        id="fba-tabs"
        activeKey={activeTab as EventKey}
        onSelect={(k) => setActiveTab(k)}
      >
        <Tab eventKey="observing" title="Observing">
          <FbaTab
            fbas={filteredFbas.observing}
            usingFilters={usingFilters && !!groupedFbas.observing.length}
          />
        </Tab>
        <Tab eventKey="active-intervention" title="Active Intervention">
          <FbaTab
            forActive
            activeCallback={() => setActiveTab("observing")}
            fbas={filteredFbas.active}
            usingFilters={usingFilters && !!groupedFbas.active.length}
          />
        </Tab>
      </Tabs>

      <NiceWorkOverlay />
    </div>
  );
};

export default FbaTable;
