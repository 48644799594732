import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { getBadgesRequest } from "../../../store/badges/actions";
import { bindActionCreators, Dispatch } from "redux";
import { Badge } from "../../../store/badges/types";
import LoadingIndicator from "../../common/onboarding/LoadingIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import YourRank from "./YourRank";

type StateProps = {
  badges: Badge[];
  isLoading: boolean;
  error?: Error;
};

type DispatchProps = {
  getBadgesRequest: () => any;
};

type OwnProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const BadgesTab: FunctionComponent<Props> = ({
  badges,
  getBadgesRequest,
  isLoading,
}) => {
  useEffect(() => {
    getBadgesRequest();
  }, []);

  const getBadgeView = (badge: Badge) => {
    let imageClassName;
    if (badge.status && badge.status.completed) {
      imageClassName = "badgeIcon";
    } else {
      imageClassName = "badgeIcon badgeIconIncomplete";
    }

    return (
      <div key={`badge_${badge.id}`} className="badgeCard">
        <img className={imageClassName} src={badge.icon} />
        <span className="badgeName">{badge.name}</span>
        <p>{badge.description}</p>
      </div>
    );
  };

  return (
    <>
      <div className="badgesHeader">
        <FontAwesomeIcon icon={faTrophy} />
        &nbsp;Your Achievements
      </div>
      <div className="onboardingContainer badgesContainer">
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <YourRank />
            {badges.map((badge) => getBadgeView(badge))}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ badges }: ApplicationState): StateProps => {
  return {
    badges: badges.badges || [],
    isLoading: badges.loadingStates.getBadges,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getBadgesRequest: getBadgesRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BadgesTab);
