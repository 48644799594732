import React, { FunctionComponent, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Cell } from "fixed-data-table-2";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  changeSelectedStudent,
  changeSelectedStudentEntry,
} from "../../../../../../../store/onboarding/actions";
import {
  Student,
  StudentEntry,
} from "../../../../../../../store/onboarding/types";
import BehaviorsDropDown from "../behavior-dropdown/BehaviorsDropDown";

type DispatchProps = {
  changeSelectedStudent: (student?: Student) => any;
  changeSelectedStudentEntry: (studentEntry?: StudentEntry) => any;
};

type OwnProps = {
  student: Student;
};

type Props = DispatchProps & OwnProps;

const TargetBehaviorEmptyCell: FunctionComponent<Props> = ({
  student,
  changeSelectedStudent,
  changeSelectedStudentEntry,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const refObject = useRef(null);

  const handleClick = () => {
    changeSelectedStudent(student);
    changeSelectedStudentEntry();
    setShowDropdown(true);
  };

  return (
    <div className="dataTableCellEmpty" ref={refObject}>
      <Cell className="dataTableCellEmpty" onClick={handleClick}>
        <FontAwesomeIcon icon={faPlusCircle} />
      </Cell>
      <BehaviorsDropDown
        refObject={refObject}
        setShowDropdown={setShowDropdown}
        showDropdown={showDropdown}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      changeSelectedStudent: changeSelectedStudent,
      changeSelectedStudentEntry: changeSelectedStudentEntry,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(TargetBehaviorEmptyCell);
