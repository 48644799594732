import React, { useEffect, useState } from "react";
import {
  ObservationTypes,
  StaticMeasurementTypes,
} from "../../../../../../store/onboarding/cases/types";
import ObservationTypePopover from "./ObservationTypePopover";
import MeasurementTypePopover from "./MeasurementTypePopover";

enum Content {
  OBSERVATION_TYPE,
  MEASUREMENT_TYPE,
}

type Props = {
  objectRef: React.MutableRefObject<any>;
  showDropdown: boolean;
  goalObservation: ObservationTypes | null;
  goalMeasurement: StaticMeasurementTypes | null;
  setShowDropdown: (show: boolean) => void;
  onSubmitPopover: (
    measurementType: StaticMeasurementTypes,
    observationType: ObservationTypes
  ) => void;
};

const ObservationsPopover = ({
  objectRef,
  showDropdown,
  goalObservation,
  goalMeasurement,
  setShowDropdown,
  onSubmitPopover,
}: Props) => {
  const [activeContent, setActiveContent] = useState<Content>(
    Content.OBSERVATION_TYPE
  );
  const [
    selectedObservationType,
    setSelectedObservationType,
  ] = useState<ObservationTypes | null>(null);

  useEffect(() => {
    if (showDropdown) {
      setActiveContent(Content.OBSERVATION_TYPE);
    }
  }, [showDropdown, goalObservation, goalMeasurement]);

  const onSubmitObservationTypePopover = (
    observationType: ObservationTypes
  ) => {
    setSelectedObservationType(observationType);
    setActiveContent(Content.MEASUREMENT_TYPE);
  };

  const handleMeasurementPopoverBack = () => {
    setActiveContent(Content.OBSERVATION_TYPE);
  };

  const onSubmitMeasurementTypePopover = (
    measurementType: StaticMeasurementTypes
  ) => {
    if (measurementType != null && selectedObservationType != null) {
      onSubmitPopover(measurementType, selectedObservationType);
    }
  };

  switch (activeContent) {
    case Content.OBSERVATION_TYPE:
      return (
        <ObservationTypePopover
          objectRef={objectRef}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          onSubmitPopover={onSubmitObservationTypePopover}
        />
      );
    case Content.MEASUREMENT_TYPE:
      return (
        <MeasurementTypePopover
          objectRef={objectRef}
          showDropdown={showDropdown}
          observationType={selectedObservationType}
          goalMeasurement={goalMeasurement}
          setShowDropdown={setShowDropdown}
          onPopoverBack={handleMeasurementPopoverBack}
          onSubmitPopover={onSubmitMeasurementTypePopover}
        />
      );
    default:
      return null;
  }
};

export default ObservationsPopover;
