import React, { useRef } from "react";
import { Cell } from "fixed-data-table-2";
import { Student } from "../../../../../../../store/onboarding/types";
import { IS_READY_COACH } from "../../../../../../../constants";

type OwnProps = {
  onClick: () => any;
  selectedStudents: Array<Student>;
};

type Props = OwnProps;

const CheckTeacherClassHeaderCell: React.FunctionComponent<Props> = (props) => {
  //const hasSelectedStudents = props.selectedStudents.length > 0;
  let uniqueGrade = Array.from(new Set(props.selectedStudents.map((student)=> student.grade)));
  let hasSelectedStudentsCommonTeacher:boolean = false;
  let uniqueTeachers: any = Array.from( new Set(props.selectedStudents
  .map(item => item.teachers!)
  .reduce((prev, curr) => prev.concat(curr), [])
  .filter((item, i, arr) => arr.indexOf(item) === i)
  .map((data:any) => data.id )));
  
  let uniqueTeacherCountArray:any[] = [];
  for (let i = 0; i < uniqueTeachers.length; i++) {
    const element = {
      id: uniqueTeachers[i],
      count: 0
    };
    uniqueTeacherCountArray.push(element)
  }
  
  
  for (let i = 0; i < props.selectedStudents.length; i++) {
    const student = props.selectedStudents[i];
    for (let j = 0; j < uniqueTeacherCountArray.length; j++) {
      if(student.teachers?.filter((teacher) => teacher.id == uniqueTeacherCountArray[j].id).length){
        uniqueTeacherCountArray[j].count = uniqueTeacherCountArray[j].count +1
        
      }
    }
  }

  for (let k = 0; k < uniqueTeacherCountArray.length; k++) {
    if((uniqueTeacherCountArray[k]['count'] == props.selectedStudents.length) && (uniqueGrade.length == 1)){
      hasSelectedStudentsCommonTeacher = true;
    }   
  }
  
  return (
          <button
            title="Click to add to a teacher class"
            className="blueBtnSm"
            onClick={props.onClick}
            style={{ margin: "auto" }}
            disabled={!hasSelectedStudentsCommonTeacher}
          >
            {IS_READY_COACH
              ? "Assign Teacher Class"
              : hasSelectedStudentsCommonTeacher
              ? `Add ${props.selectedStudents.length} students to group`
              : "Click to add to a teacher class"}
          </button>
  );
};

export default CheckTeacherClassHeaderCell;
