import React, { FunctionComponent, useMemo } from "react";
import { AssignmentSummary } from "../../../../../../store/onboarding/types";
import { Bar, HorizontalBar } from "react-chartjs-2";
import { getFullName } from "../../../../../../utils/NamesUtils";
import _ from "lodash";
import { colors } from "./index";

type OwnProps = {
  assignments: AssignmentSummary[];
};

type Props = OwnProps;

const AssignmentsInDistrictChart: FunctionComponent<Props> = (props) => {
  const { assignments } = props;

  const labels = useMemo(() => {
    return assignments.map((a) => getFullName(a.coach));
  }, [assignments]);

  const allTeachers = useMemo(() => {
    return _.chain(assignments)
      .flatMap((assignment) => assignment.teachers)
      .map((teacherSummary) => teacherSummary.teacher)
      .uniqBy((t) => t.id)
      .value();
  }, [assignments]);

  const chartData = useMemo(() => {
    return {
      labels: labels,
      datasets: allTeachers.map((teacher, index) => ({
        label: `teacher_${teacher.id}_dataset`,
        backgroundColor: colors[index % colors.length],
        borderWidth: 1,
        data: assignments.map(
          (a) =>
            a.teachers.find((t) => t.teacher.id === teacher.id)
              ?.student_count ?? 0
        ),
        datalabels: {
          display: false,
        },
      })),
    };
  }, [labels, assignments]);

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: (tooltipItem: any, data: any) => {
            return getFullName(allTeachers[tooltipItem.datasetIndex]);
          },
          footer: (tooltipItem: any, data: any) => {
            return `Group count: ${tooltipItem[0].value}`;
          },
        },
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            min: 0,
          },
        ],
        xAxes: [
          {
            stacked: true,
          },
        ],
      },
    }),
    [allTeachers]
  );

  return (
    <div className="position-relative district-summary-chart-container">
      <HorizontalBar height={250} data={chartData} options={options} />
    </div>
  );
};

export default AssignmentsInDistrictChart;
