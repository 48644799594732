import React, { Component, useCallback, useState } from "react";
import { Button, Col, Modal, Spinner } from "react-bootstrap";
import {
  ColorCriteria,
  DataPeriod,
  EvidenceColumn,
  GradeLevels,
  Measurement,
} from "../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  getCustomColorCriteria,
  hideEditMeasureColorCriteriaModal,
  openEditMeasureColorCriteriaModal,
  restoreDefaultBenchmarks,
  updateCustomColorCriteria,
  setNewCustomColorCriteria,
  getUpdatedAreaOfNeed,
} from "../../../../../../store/onboarding/actions";
import { connect, useSelector } from "react-redux";
import LoadingIndicator from "../../../LoadingIndicator";
import EditMeasureColorCriteriaModal from "../../../../../../containers/color-picker/EditMeasureColorCriteriaModal";
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Row from "react-bootstrap/Row";
import {
  Grade,
  TimePeriod,
  TimePeriodShortOptions,
} from "../../../../../../store/groups/types";
import { ValueType } from "react-select/src/types";
import { IS_READY_COACH } from "../../../../../../constants";
import ColorCriteriaTable from "./ColorCriteriaTable";
import { USState } from "../../../../States";
import { useLoading } from "../../../../../../utils/hooks/useLoading";
import BenchmarkFilters from "./BenchmarkFilters";
import useBenchmarkFilters from "./hooks/useBenchmarkFilters";
import useColorCriteria from "./hooks/useColorCriteria";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";
import { useDispatch } from "react-redux";

type PropsFromState = {
  showModal: boolean;
  isLoading: {
    getCustomColorCriteria: boolean;
    restoreDefaultBenchmarks: boolean;
    updateCustomColorCriteria: boolean;
  };
  errors: {
    getCustomColorCriteria?: string;
    restoreDefaultBenchmarks?: string;
    updateCustomColorCriteria?: string;
  };
  dataPeriod: DataPeriod;
};

type DispatchProps = {
  openEditMeasureColorCriteriaModal: () => any;
  hideEditMeasureColorCriteriaModal: () => any;
  getCustomColorCriteria: (measurementId: number) => any;
  updateCustomColorCriteria: (
    measurementId: number,
    colorCriteria: Array<ColorCriteria>
  ) => any;
  setNewCustomColorCriteria: (
    measurementId: number,
    colorCriteria: Array<ColorCriteria>,
    user_id: number
  ) => any;
  restoreDefaultBenchmarks: (measurementId: number) => any;
  getUpdatedAreaOfNeed: (dataPeriodId: number) => any;
};

type OwnProps = {
  onHide: (measurement?: Measurement) => any;
  selectedEvidence: {
    assessmentName: string;
    evidenceColumnGroupId: number;
    evidenceColumn: EvidenceColumn;
  };
  lowestGradeInData: string;
};

type Props = PropsFromState & DispatchProps & OwnProps;

const ViewBenchmarksModal: React.FC<Props> = (props) => {
  const currentUser: any = useSelector(
    (s: ApplicationState) => s.auth.userInfo
  );

  const { isLoading, errors, selectedEvidence, lowestGradeInData } = props;
  const measurement: Measurement = selectedEvidence.evidenceColumn.measurement;
  let isCloseModel:boolean = false;
  const onSuccess = useCallback(async () => {
    await props.getUpdatedAreaOfNeed(props.dataPeriod.id);
    props.hideEditMeasureColorCriteriaModal();
  }, []);
  useLoading(
    isLoading.updateCustomColorCriteria,
    errors.updateCustomColorCriteria,
    onSuccess
  );

  const onRestoreDefaultBenchmarksSuccess = useCallback(() => {
    getCustomColorCriteria();
  }, []);

  useLoading(
    isLoading.restoreDefaultBenchmarks,
    errors.restoreDefaultBenchmarks,
    onRestoreDefaultBenchmarksSuccess
  );

  const {
    customColorCriteria,
  } = useColorCriteria({
    measurement,
  });

  const {
    filters,
    selectedGrade,
    setSelectedGrade,
    selectedTimePeriod,
    setSelectedTimePeriod,
    filterDisabled,
    setFilterDisabled
  } = useBenchmarkFilters({
    show:
      !!measurement.grade_specific_color_criteria ||
      !!measurement.custom_color_criteria?.length || 
      !!customColorCriteria,
    evidenceColumnGroupId: selectedEvidence.evidenceColumnGroupId,
    isCloseModel,
  });

  const getCustomColorCriteria = () => {
    const { measurement } = selectedEvidence.evidenceColumn;
    if (measurement.id) {
      props.getCustomColorCriteria(measurement.id);
    }
  };

  const handleApplyColorCriteriaChanges = (measurement: Measurement) => {
    if (measurement.id && measurement.color_criteria) {
      props.updateCustomColorCriteria(
        measurement.id,
        measurement.color_criteria
      );
    }
  };

  const handleApplyNewDistrictBenchmarkCriteriaChanges = (
    measurement: Measurement
  ) => {
    if (measurement.id && measurement.color_criteria) {
      props.setNewCustomColorCriteria(
        measurement.id,
        measurement.color_criteria,
        currentUser.id
      );
    }
  };

  const {
    mainColorCriteria,
    allColorCriteria,
  } = useColorCriteria({
    selectedGrade,
    selectedTimePeriod,
    measurement,
  });

  /*const getGrade = (grades: Array<string>) => {
    let lowestGradeIndex = grades.indexOf(lowestGradeInData)
        if(lowestGradeIndex!=-1) return grades[lowestGradeIndex]
        else return grades[0] as Grade;
  }*/

  const handleModalOpen = () => {
    let grade: any;
    let timePeriod: any;

    const measurement: Measurement =
      selectedEvidence.evidenceColumn.measurement;
    let grades: Array<string> = [];

    /*if (measurement.grade_specific_color_criteria) {

      grades = Object.keys(measurement.grade_specific_color_criteria);
    }

    if (grades.length && measurement.grade_specific_color_criteria) {
      grade = getGrade(grades);
      const criteria = measurement.grade_specific_color_criteria!;
      timePeriod = props.dataPeriod.time_period;
      if (!criteria[grade][timePeriod]) {
        const keys = Object.keys(criteria[grade]);
        if (keys.length > 0) {
          timePeriod = keys[0] as TimePeriod;
        }
      }
    }

    if (measurement.custom_color_criteria) {
      measurement.custom_color_criteria.forEach(ccc=>{
        if(ccc.grade) grades.push(ccc.grade);
      })
    }

    if (grades.length && measurement.custom_color_criteria && measurement.custom_color_criteria.length>0) {
      grade = getGrade(grades);
      const criteria = measurement.custom_color_criteria!;
      timePeriod = props.dataPeriod.time_period;
      if (!timePeriod || criteria.findIndex(x=>x.grade==grade && x.time_period==timePeriod)==-1) {
        timePeriod = criteria[criteria.findIndex(x=>x.grade==grade)].time_period
      }*/

    grade = lowestGradeInData;
    timePeriod = props.dataPeriod.time_period;
    isCloseModel = false;
    setSelectedGrade(grade);
    setSelectedTimePeriod(timePeriod);
    getCustomColorCriteria();
  };

  const handleModalHide = () => {
    const {
      getCustomColorCriteria,
      updateCustomColorCriteria,
      restoreDefaultBenchmarks,
    } = isLoading;
    if (
      !getCustomColorCriteria &&
      !updateCustomColorCriteria &&
      !restoreDefaultBenchmarks
    ) {
      if (customColorCriteria.length) {
        const measurement: Measurement = {
          ...selectedEvidence.evidenceColumn.measurement,
          custom_color_criteria: customColorCriteria,
        };
        props.onHide(measurement);
      } else {
        props.onHide();
      }

      setFilterDisabled(false);
    }
  };

  const handleDefaultBenchmarksRestore = () => {
    const { measurement } = selectedEvidence.evidenceColumn;
    if (measurement.id) {
      props.restoreDefaultBenchmarks(measurement.id);
    }
  };

  const dispatch = useDispatch();

  const handleShow = (id: any) => {
    dispatch(
      showConfirmDialog({
        title: "Confirm",
        text:
          "District benchmarks will be applied to all district users and may effect grouping decisions that were made using previous benchmarks. Do you want to continue?",
        onConfirm: () => {
          props.openEditMeasureColorCriteriaModal();
        },
        confirmButtonText: "Continue",
      })
    );
  };

  return (
    <>
      {
        <Modal
          animation={false}
          backdropClassName="customDarkModalBackdrop in"
          className="viewBenchmarksModalContainer"
          show={props.showModal}
          centered
          onShow={handleModalOpen}
          onHide={handleModalHide}
        >
          <Modal.Body className="viewBenchmarksModal">
            <div className="d-flex justify-content-between align-items-center m-0">
              <p className="m-0">Benchmarks</p>
              <Button onClick={handleModalHide}>
                <FontAwesomeIcon icon={faTimes} className="pointer" />
              </Button>
            </div>
            <h3 className="m-0 font-weight-bold">{`${selectedEvidence.assessmentName}: ${measurement.display_name}`}</h3>
            {isLoading.getCustomColorCriteria ? (
              <LoadingIndicator />
            ) : (
              <>
                <>
                  <p className="m-0">
                    Below you can see how many of the students meet each
                    benchmark for
                    <strong>
                      {` ${selectedEvidence.assessmentName}: ${measurement.display_name}`}
                    </strong>
                    . Filters applied to the data table also apply to the counts
                    in the table shown here.
                  </p>

                  {filters}

                  <ColorCriteriaTable
                    allColorCriteria={allColorCriteria}
                    measurementType={measurement.type}
                    selectedGrade={selectedGrade}
                    evidenceColumn={selectedEvidence.evidenceColumn}
                  />
                </>
                {IS_READY_COACH && (
                  <div className="d-flex justify-content-around flex-wrap">
                    <Button
                      bsPrefix="b"
                      className="blueBtnSm text-nowrap mt-3"
                      //onClick={props.openEditMeasureColorCriteriaModal}
                      onClick={handleShow}
                    >
                      {customColorCriteria.length > 0
                        ? "Edit District Benchmarks"
                        : mainColorCriteria.length
                        ? "Set District Benchmarks"
                        : "Create District Benchmarks"}
                    </Button>
                    {/* {customColorCriteria.length > 0 && (
                      <Button
                        bsPrefix="b"
                        className="blueBtnSm text-nowrap mt-3"
                        onClick={handleDefaultBenchmarksRestore}
                      >
                        USE DEFAULT BENCHMARKS{" "}
                        {isLoading.restoreDefaultBenchmarks && (
                          <Spinner animation="border" size="sm" />
                        )}
                      </Button>
                    )} */}
                  </div>
                )}
              </>
            )}
          </Modal.Body>
        </Modal>
      }
      <EditMeasureColorCriteriaModal
        withGrades={IS_READY_COACH}
        selectedGrade={selectedGrade}
        selectedTimePeriod={selectedTimePeriod}
        measurement={{
          ...measurement,
          color_criteria: customColorCriteria,
        }}
        onApplyChanges={customColorCriteria.length > 0
          ? handleApplyColorCriteriaChanges
          : handleApplyNewDistrictBenchmarkCriteriaChanges}
        isLoading={isLoading.updateCustomColorCriteria}
        evidenceColumnGroupId={selectedEvidence.evidenceColumnGroupId} 
        filterDisabled={filterDisabled} 
        setFilterDisabled={setFilterDisabled }      
        />
    </>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    showModal: onboarding.modalsState.viewBenchmarksModal,
    dataPeriod: onboarding.currentDataPeriod!,
    isLoading: {
      getCustomColorCriteria: onboarding.isLoading.getCustomColorCriteria,
      updateCustomColorCriteria: onboarding.isLoading.updateCustomColorCriteria,
      restoreDefaultBenchmarks: onboarding.isLoading.restoreDefaultBenchmarks,
    },
    errors: {
      getCustomColorCriteria: onboarding.errors.getCustomColorCriteria,
      updateCustomColorCriteria: onboarding.errors.updateCustomColorCriteria,
      restoreDefaultBenchmarks: onboarding.errors.restoreDefaultBenchmarks,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openEditMeasureColorCriteriaModal: openEditMeasureColorCriteriaModal,
      hideEditMeasureColorCriteriaModal: hideEditMeasureColorCriteriaModal,
      getCustomColorCriteria: getCustomColorCriteria,
      updateCustomColorCriteria: updateCustomColorCriteria,
      setNewCustomColorCriteria: setNewCustomColorCriteria,
      restoreDefaultBenchmarks: restoreDefaultBenchmarks,
      getUpdatedAreaOfNeed: getUpdatedAreaOfNeed,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewBenchmarksModal);
