import { action } from "typesafe-actions";
import { DataSortActionTypes, GroupSorting } from "./types";
import { Grade } from "../groups/types";

export const changeDataSort = (activeSort: any[]) =>{
  console.log(activeSort,'< activeSort from actions ')
  return action(DataSortActionTypes.CHANGE_SORTING_DATA, {
    activeSort
  });
}

export const resetColumnSort = (isResetColumnSort: boolean) =>{
  return action(DataSortActionTypes.RESET_COLUMN_SORT, {
    isResetColumnSort
  });
}

export const columnSortApplied = (isColumnSortApplied: boolean) =>{
  return action(DataSortActionTypes.COLUMN_SORT_APPLIED, {
    isColumnSortApplied
  });
}

export const closeSortModal = () =>
  action(DataSortActionTypes.CLOSE_MODAL);

export const openSortModal = () =>
  action(DataSortActionTypes.SHOW_MODAL);

export const setColoumnsForSorting = (allColoumnFields:any[]) =>
action(DataSortActionTypes.CHANGE_ALL_FIELDS, {
  allColoumnFields
});

