import React, { FunctionComponent, useMemo } from "react";
import { GroupProgress } from "../../../../../../store/onboarding/types";
import VerticalBarChart from "./VerticalBarChart";
import { Bar, HorizontalBar } from "react-chartjs-2";
import { getFullName } from "../../../../../../utils/NamesUtils";

type OwnProps = {
  groupProgress: GroupProgress[];
};

type Props = OwnProps;

const InterventionGroupProgressChart: FunctionComponent<Props> = (props) => {
  const { groupProgress = [] } = props;

  const labels = useMemo(
    () =>
      groupProgress.map(
        (gp) => `${getFullName(gp.coach)} / ${gp.intervention_group.name}`
      ),
    [groupProgress]
  );
  const data = useMemo(
    () => groupProgress.map((gp) => +(gp.progress_percentage * 100).toFixed(0)),
    [groupProgress]
  );

  if (!data.length) {
    return null;
  }

  const chartData = useMemo(() => {
    return {
      labels: labels,
      datasets: [
        {
          backgroundColor: "rgb(203,220,240)",
          borderColor: "rgb(77,145,226)",
          borderWidth: 1,
          hoverBackgroundColor: "rgb(203,220,240,0.4)",
          hoverBorderColor: "rgb(77,145,226)",
          data: data,
          datalabels: {
            color: "rgb(30,136,229)",
            formatter: (value: number) => {
              if (!value) {
                return "";
              }
              return value + "%";
            },
            font: {
              weight: "bold",
            },
          },
        },
      ],
    };
  }, [data, labels]);

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            display: true,
          },
        ],
        xAxes: [
          {
            ticks: {
              max: 100,
              min: 0,
              stepSize: 10,
            },
            display: true,
          },
        ],
      },
    }),
    []
  );

  return (
    <div className="district-summary-chart-container">
      <div style={{ height: "unset" }}>
        <HorizontalBar
          height={labels.length * 25}
          data={chartData}
          options={options}
        />
      </div>
    </div>
  );
};

export default InterventionGroupProgressChart;
