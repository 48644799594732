import React, { FunctionComponent, useEffect, useState } from "react";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { SupportMeeting } from "../../../../../../store/onboarding/meeting-module/types";
import {
  Col,
  Form,
  ListGroup,
  Nav,
  Row,
  Spinner,
  TabContainer,
  TabContent,
  TabPane,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  createSupportMeeting,
  hideSupportImplFeedbackModal,
  updateSupportMeeting,
} from "../../../../../../store/onboarding/meeting-module/actions";
import { connect } from "react-redux";
import { formatDate } from "../../../../../utils/DateTimeUtils";
import { EventKey } from "react-bootstrap/esm/types";

type StateProps = {
  isLoading: {
    createSupportMeeting: boolean;
    updateSupportMeeting: boolean;
  };
  interventionGroup?: InterventionGroup;
  selectedPraise?: string;
  selectedSupportMeeting?: SupportMeeting;
  supportMeetings: Array<SupportMeeting>;
};

type DispatchProps = {
  hideSupportImplFeedbackModal: () => any;
  createSupportMeeting: (supportMeeting: Partial<SupportMeeting>) => any;
  updateSupportMeeting: (supportMeeting: Partial<SupportMeeting>) => any;
};

type Props = StateProps & DispatchProps;

enum TabKeys {
  PLANNING_NOTES = "PLANNING_NOTES",
  PREVIOUS_MEETINGS = "PREVIOUS_MEETINGS",
}

const MeetingModalBody: FunctionComponent<Props> = ({
  interventionGroup,
  isLoading: {
    createSupportMeeting: loadingCreateSupportMeeting,
    updateSupportMeeting: loadingUpdateSupportMeeting,
  },
  supportMeetings,
  selectedSupportMeeting,

  hideSupportImplFeedbackModal,
  createSupportMeeting,
  updateSupportMeeting,
}) => {
  const [strengths, setStrengths] = useState("");
  const [barriers, setBarriers] = useState("");
  const [improvements, setImprovements] = useState("");
  const [additionalModeling, setAdditionalModeling] = useState("");
  const [noModeling, setNoModeling] = useState(false);
  const [additionalPractice, setAdditionalPractice] = useState("");
  const [noPractice, setNoPractice] = useState(false);
  const [isDone, setDone] = useState(false);
  const [isFirstRender, setFirstRender] = useState(true);
  const [editing, setEditing] = useState(false);
  const [selectedPrevMeeting, selectPrevMeeting] = useState<
    SupportMeeting | undefined
  >(undefined);
  const [activeTab, setActiveTab] = useState<string | null>(
    TabKeys.PLANNING_NOTES
  );

  useEffect(() => {
    if (!isFirstRender) {
      hideSupportImplFeedbackModal();
      return;
    }
    setFirstRender(false);
  }, [selectedSupportMeeting]);

  if (!selectedSupportMeeting) return null;

  const handleEditBtnClick = (meeting?: SupportMeeting) => (
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    selectPrevMeeting(meeting);
    setEditing(!!meeting);
    if (meeting) {
      setStrengths(meeting.strengths);
      setBarriers(meeting.barriers);
      setImprovements(meeting.improvements);
      setAdditionalModeling(meeting.additional_modeling);
      setAdditionalPractice(meeting.additional_practice);
      setNoModeling(meeting.no_modeling);
      setNoPractice(meeting.no_practice);
    } else {
      setStrengths("");
      setBarriers("");
      setImprovements("");
      setAdditionalModeling("");
      setAdditionalPractice("");
      setNoModeling(false);
      setNoPractice(false);
    }
  };

  const saveBtnDisabled =
    !strengths ||
    !barriers ||
    !improvements ||
    !additionalModeling ||
    !additionalPractice;

  const onSupportMeetingSave = (readyToMeet: boolean) => () => {
    setDone(readyToMeet);
    const supportMeeting: Partial<SupportMeeting> = {
      meeting_type: 1,
      strengths: strengths,
      improvements: improvements,
      barriers: barriers,
      additional_modeling: additionalModeling,
      additional_practice: additionalPractice,
      no_modeling: noModeling,
      no_practice: noPractice,
      intervention_group: interventionGroup!.id,
      planning_meeting: selectedSupportMeeting!.id,
    };
    createSupportMeeting(supportMeeting);
  };

  const onSupportMeetingUpdate = () => {
    if (selectedPrevMeeting) {
      const supportMeeting: Partial<SupportMeeting> = {
        ...selectedPrevMeeting,
        strengths: strengths,
        improvements: improvements,
        barriers: barriers,
        additional_modeling: additionalModeling,
        additional_practice: additionalPractice,
        no_modeling: noModeling,
        no_practice: noPractice,
        planning_meeting: selectedPrevMeeting.planning_meeting
          ? (selectedPrevMeeting.planning_meeting as SupportMeeting).id
          : selectedPrevMeeting.planning_meeting,
      };
      updateSupportMeeting(supportMeeting);
    }
  };

  return (
    <>
      <Row>
        <Col sm={5}>
          <TabContainer activeKey={activeTab as EventKey} onSelect={setActiveTab}>
            <Nav variant="pills" className="justify-content-center text-center">
              <Nav.Item>
                <Nav.Link
                  eventKey={TabKeys.PLANNING_NOTES}
                  onSelect={() => {
                    selectPrevMeeting(undefined);
                    setEditing(false);
                  }}
                  style={{ height: "unset" }}
                >
                  <div>Planning Notes</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey={TabKeys.PREVIOUS_MEETINGS}
                  style={{ height: "unset" }}
                >
                  <div>Previous Meetings</div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <TabContent>
              <TabPane eventKey={TabKeys.PLANNING_NOTES}>
                <div className="planningNotesContainer">
                  <h4 className="font-weight-bold">Strengths</h4>
                  <p>{selectedSupportMeeting.strengths}</p>
                  <h4 className="font-weight-bold">Potential Barriers</h4>
                  <p>{selectedSupportMeeting.barriers}</p>
                  <h4 className="font-weight-bold">
                    Implementation Improvement
                  </h4>
                  <p>{selectedSupportMeeting.improvements}</p>
                  <h4 className="font-weight-bold">Modeling Needed</h4>
                  <p>{selectedSupportMeeting.additional_modeling}</p>
                  <h4 className="font-weight-bold">Practice Needed</h4>
                  <p>{selectedSupportMeeting.additional_practice}</p>
                </div>
              </TabPane>
              <TabPane eventKey={TabKeys.PREVIOUS_MEETINGS}>
                <div className="notesListContainer">
                  <ListGroup>
                    {supportMeetings.map((meeting) => (
                      <ListGroup.Item
                        key={meeting.id}
                        action
                        active={
                          selectedPrevMeeting &&
                          selectedPrevMeeting.id === meeting.id
                        }
                        onClick={() => {
                          selectPrevMeeting(meeting);
                          setEditing(false);
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h4>
                              {formatDate(meeting.created, "YYYY-MM-DD hh:mm")}
                            </h4>
                            <h3 className="font-weight-bold">
                              Meeting details
                            </h3>
                          </div>
                          <span onClick={handleEditBtnClick(meeting)}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </span>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </TabPane>
            </TabContent>
          </TabContainer>
        </Col>

        <Col>
          <h3 className="font-weight-bold mb-2">
            Meeting Notes with{" "}
            {getFullName(interventionGroup!.teacher_assignment!.user)}
          </h3>
          <Form>
            <Form.Group>
              <Form.Label>Strength</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.strengths}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={strengths}
                  onChange={(e) =>
                    setStrengths((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Potential Barriers</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.barriers}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={barriers}
                  onChange={(e) =>
                    setBarriers((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Implementation Improvement</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.improvements}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={improvements}
                  onChange={(e) =>
                    setImprovements((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
            <Form.Group>
              <div className="d-flex justify-content-between">
                <Form.Label>Modeling Needed</Form.Label>
                <Form.Check
                  id="noModeling"
                  custom
                  disabled={selectedPrevMeeting && !editing}
                  onChange={() => setNoModeling((checked) => !checked)}
                  className="customMeetingCheckbox"
                  type="checkbox"
                  label="No need to model"
                  checked={
                    selectedPrevMeeting
                      ? selectedPrevMeeting.no_modeling
                      : noModeling
                  }
                />
              </div>

              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.additional_modeling}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={additionalModeling}
                  onChange={(e) =>
                    setAdditionalModeling((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
            <Form.Group>
              <div className="d-flex justify-content-between">
                <Form.Label className="d-flex justify-content-between">
                  Practice Needed
                </Form.Label>
                <Form.Check
                  id="noPractice"
                  custom
                  disabled={selectedPrevMeeting && !editing}
                  onChange={() => setNoPractice((checked) => !checked)}
                  className="customMeetingCheckbox"
                  type="checkbox"
                  label="No need to practice"
                  checked={noPractice}
                />
              </div>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.additional_practice}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={additionalPractice}
                  onChange={(e) =>
                    setAdditionalPractice((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
          </Form>
          <p>
            <FontAwesomeIcon icon={faInfoCircle} /> Only you and your educators
            will see these notes.
          </p>
        </Col>
      </Row>

      <hr />
      <div className="btnActions mt-3">
        <button className="whiteBtnSm" onClick={hideSupportImplFeedbackModal}>
          Cancel
        </button>
        {!selectedPrevMeeting ? (
          <div>
            <button
              className="blueBtnSm mr-3"
              onClick={onSupportMeetingSave(false)}
              disabled={saveBtnDisabled}
            >
              Save{" "}
              {!isDone && loadingCreateSupportMeeting && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
            <button
              className="blueBtnSm"
              onClick={onSupportMeetingSave(true)}
              disabled={saveBtnDisabled}
            >
              Done{" "}
              {isDone && loadingCreateSupportMeeting && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          </div>
        ) : (
          editing && (
            <button
              className="blueBtnSm"
              onClick={onSupportMeetingUpdate}
              disabled={saveBtnDisabled}
            >
              Update{" "}
              {loadingUpdateSupportMeeting && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          )
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  cases,
  meetingModuleReducer,
}: ApplicationState): StateProps => {
  return {
    interventionGroup: cases.selectedInterventionGroup,
    isLoading: {
      createSupportMeeting: meetingModuleReducer.isLoading.createSupportMeeting,
      updateSupportMeeting: meetingModuleReducer.isLoading.updateSupportMeeting,
    },
    selectedPraise: meetingModuleReducer.selectedPraise,
    selectedSupportMeeting: meetingModuleReducer.selectedMeetingOld as SupportMeeting,
    supportMeetings: meetingModuleReducer.supportMeetings.filter(
      (meeting) => meeting.meeting_type
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hideSupportImplFeedbackModal: hideSupportImplFeedbackModal,
      createSupportMeeting: createSupportMeeting,
      updateSupportMeeting: updateSupportMeeting,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingModalBody);
