import React, { useEffect, useMemo, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  createDataExtract,
  getAllDistricts
} from "../../../../../store/superuser/actions";
import {
  SuperuserState,
  Data_Extract_Request,
} from "../../../../../store/superuser/types";
import {
  FileTypeOptions, TimePeriodOptions,
} from "../../../../../store/groups/types";
import Select from "react-select";
import { ReactSelectOption } from "../../../../../store/onboarding/types";
import { toastr } from "react-redux-toastr";

const defaultValue: Data_Extract_Request = {
  district: 0,
  file_type: "",
  time_period: [],
  year: [],
};

const DataExtractFrom = () => {
  const {
    districts
  } = useSelector<ApplicationState, SuperuserState>((s) => s.superuser);

  const dispatch = useDispatch();

  const [dataExtract, setDataExtract] = useState<Data_Extract_Request>(defaultValue);

  useEffect(() => {
    dispatch(getAllDistricts());
  }, []);

  const date = new Date();
  const currentYear = date.getFullYear();

  const possibleYears: ReactSelectOption<string>[] = [];
  for (let i = -5; i < 5; ++i) {
    possibleYears.push({
      value: (currentYear + i).toString(),
      label: (currentYear + i).toString(),
    });
  }

  let districtsArray = useMemo(() => {
    let districtArr: ReactSelectOption<any>[] = [];
    districtArr = districts.map((district) => {
      return {
        value: district.id,
        label: district.name,
      }
    })
    return districtArr;
  },[districts])

  useEffect(() => {
    console.log(dataExtract)
  },[dataExtract])

  // const handleClose = () => {
  //   setDataExtract((prevState) => ({ ...prevState, ...defaultValue }));
  // };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (dataExtract.district && dataExtract.file_type && dataExtract.time_period && dataExtract.year != null) {
       dispatch<any>(createDataExtract({
        ...dataExtract,
        time_period: dataExtract.time_period.length ? dataExtract.time_period.flatMap((tp:any) => tp.value) : [],
        year: dataExtract.year.length ? dataExtract.year.flatMap((y:any) => y.value) : []
      })).then(
        (dataExtract: Data_Extract_Request) => {
          toastr.success('Success ', 'Data Extraction request sent successfully.');
        }
      );
    }
  };

  const handleChangeDistrict = (selectedOption:any) => {
    setDataExtract((prevState) => ({ ...prevState, district: selectedOption.value }));
  };

  const handleChangeFileType = (selectedOption:any) => {
    setDataExtract((prevState) => ({ ...prevState, file_type: selectedOption.value }));
  };

  const handleChangeTimePeriod = (selectedTPOption:any) => {
    setDataExtract((prevState) => ({ ...prevState, time_period: selectedTPOption ?? [] }));
  };


  const handleChangeYear = (selectedYearOption:any) => {
    setDataExtract((prevState) => ({ ...prevState, year: selectedYearOption ?? [] }));
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="col-6">
        <h2 className="p-2 mb-2 purpleModalHeader rounded">Data Extract</h2>
          <Form id="dataExtractForm" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className="font-weight-bold" column={false}>
                District
              </Form.Label>
              <Select
                value={districtsArray.find((d) => d.value === dataExtract.district)}
                menuPlacement="auto"
                options={districtsArray}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
                }}
                openMenuOnFocus
                onChange={handleChangeDistrict}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="font-weight-bold" column={false}>
                File Type
              </Form.Label>
              <Select
                value={FileTypeOptions.find((s) => s.value === dataExtract.file_type)}
                menuPlacement="auto"
                options={FileTypeOptions}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
                }}
                openMenuOnFocus
                onChange={handleChangeFileType}
              />
            </Form.Group>
            <Form.Group>
            <Form.Label className="font-weight-bold m-0">Time Period</Form.Label>
                <Select
                  isMulti
                  value={dataExtract.time_period}
                  menuPlacement="auto"
                  options={TimePeriodOptions}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
                  }}
                  openMenuOnFocus
                  onChange={handleChangeTimePeriod}
                />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="font-weight-bold" column={false}>
                Year
              </Form.Label>
              <Select
                isMulti
                value={dataExtract.year}
                menuPlacement="auto"
                options={possibleYears}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
                }}
                openMenuOnFocus
                onChange={handleChangeYear}
              />
            </Form.Group>
              <button
                className="blueBtnSm mr-2"
                type="submit"
                disabled={!dataExtract.district || (dataExtract.file_type == '') || (dataExtract.time_period == '') || (dataExtract.year == '')}
              >
                Send 
              </button>
              {/* <button
                className="whiteBtnSm"
                type="reset"
                onClick={handleClose}
              >
                Cancel
              </button> */}
          </Form>
      </div>
    </div>
  );
};

export default DataExtractFrom;
