import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { UserInfo } from "../../../store/auth/types";


type OwnProps = {};

type Props = OwnProps;

const YourRank: FunctionComponent<Props> = (props) => {
  const me = useSelector<ApplicationState, UserInfo | undefined>(
    (s) => s.auth.userInfo
  );

  if (!me?.profile.xp) {
    return null;
  }

  return (
    <div style={{ flex: 1, flexBasis: "100%" }} className="text-center">
      <h4>Your Rank</h4>
      <h1 className="my-0">{me.profile.xp.rank_display_name} </h1>
      <h3 className="font-weight-bold">XP: {me.profile.xp.rank_points}</h3>
    </div>
  );
};

export default YourRank;
