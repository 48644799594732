import { ApplicationState } from "../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  assignStudentsToTeacher,
  hideTeacherDetailsModal,
  openTeacherDetailsModal,
} from "../../../../store/onboarding/actions";
import { connect } from "react-redux";
import UploadStudentsRosterModal from "../../../../components/common/onboarding/second-step/upload-students/UploadStudentsRosterModal";
import { Student, TeacherInfo } from "../../../../store/onboarding/types";

type PropsFromState = {
  originalFileName: string;
  columnsNames: Array<string>;
  rawData: Array<any>;
  selectedTeacher?: TeacherInfo;
  isLoading: boolean;
  error?: string;
  show: boolean;
};

type DispatchProps = {
  assignStudentsToTeacher: (id: number, students: Array<Student>) => any;
  onHide: () => any;
  onEnter: () => any;
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  const loadableFile = onboarding.loadableFile;
  const selectedTeacher = onboarding.selectedTeacher;
  const assignStudentsToTeacherLoading = selectedTeacher
    ? onboarding.isLoading.assignStudentsToTeacher.includes(
        selectedTeacher.user.id
      )
    : false;
  let assignStudentsToTeacherError;
  if (selectedTeacher) {
    const error = onboarding.errors.assignStudentsToTeacher.find(
      (x) => x.teacherId === selectedTeacher.user.id
    );
    if (error) {
      assignStudentsToTeacherError = error.message;
    }
  }
  return {
    show: onboarding.modalsState.uploadStudentsToTeachersModal,
    originalFileName: loadableFile ? loadableFile.originalFileName! : "",
    rawData: loadableFile ? loadableFile.rawData : [],
    columnsNames: loadableFile ? loadableFile.columnsNames : [],
    selectedTeacher: onboarding.selectedTeacher,
    isLoading:
      assignStudentsToTeacherLoading ||
      onboarding.isLoading.updateStudentDetails,
    error: assignStudentsToTeacherError,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      assignStudentsToTeacher: assignStudentsToTeacher,
      onHide: openTeacherDetailsModal,
      onEnter: hideTeacherDetailsModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadStudentsRosterModal);
