import React, { FunctionComponent } from "react";
import {
  ConcernArea,
  ConcernAreaFullDisplayedNames,
  ExpectedChange,
} from "../../../../../../../../store/onboarding/cases/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

type OwnProps = {
  concernArea: ConcernArea;
  expectedChange?: ExpectedChange;
  setExpectedChange: React.Dispatch<
    React.SetStateAction<ExpectedChange | undefined>
  >;
  onClickNext: () => void;
};

type Props = OwnProps;

const SelectBehaviorForm: FunctionComponent<Props> = ({
  concernArea,
  expectedChange,
  setExpectedChange,
  onClickNext,
}) => {
  return (
    <div className="interventionModalContent">
      <div className="interventionModalInnerContent">
        <div className="text-center">
          <h1 className="purpleText">
            You indicated this intervention is for{" "}
            {ConcernAreaFullDisplayedNames[concernArea]} issues.
          </h1>
          <br />
          <h3 className="font-weight-bold">
            Is it intended increase a desired behavior or decrease a problematic
            behavior?
          </h3>
          <p className="mb-0">
            This lets us try to to recommend interventions that don’t rely on
            punishment when possible.
          </p>
          <br />
          <div
            className={
              "widePill" +
              (expectedChange === ExpectedChange.INCREASE ? " active" : "")
            }
            onClick={() => setExpectedChange(ExpectedChange.INCREASE)}
          >
            {expectedChange === ExpectedChange.INCREASE ? (
              <FontAwesomeIcon
                size="lg"
                icon={faDotCircle}
                style={{ color: "#673ab7" }}
                inverse={true}
              />
            ) : (
              <FontAwesomeIcon size="lg" icon={faCircle} />
            )}
            <div>
              <h4 className="font-weight-bold">Increase Positive Behavior</h4>
              <p className="m-0">
                ex. Rewarding handraising to address issues with calling out and
                disrupting the class
              </p>
            </div>
          </div>
          <div
            className={
              "widePill" +
              (expectedChange === ExpectedChange.DECREASE ? " active" : "")
            }
            onClick={() => setExpectedChange(ExpectedChange.DECREASE)}
          >
            {expectedChange === ExpectedChange.DECREASE ? (
              <FontAwesomeIcon
                size="lg"
                icon={faDotCircle}
                style={{ color: "#673ab7" }}
                inverse={true}
              />
            ) : (
              <FontAwesomeIcon size="lg" icon={faCircle} />
            )}
            <div>
              <h4 className="font-weight-bold">
                Decrease Problematic Behavior
              </h4>
              <p className="m-0">
                ex. Putting a child on timeout when they misbehave to
                immediately stop the bad behavior
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="modalActions">
        <div />
        <button className="blueBtnSm" onClick={onClickNext}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default SelectBehaviorForm;
