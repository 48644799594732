import React, { FunctionComponent, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretRight,
  faChevronCircleDown,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "react-bootstrap";

type OwnProps = {
  openByParentControl?: boolean;
  setOpenByParentControl?: React.Dispatch<React.SetStateAction<boolean>>;
  useChevronIcons?: boolean;
  header?: React.ReactNode;
  label?: string;
  className?: string;
  open?: boolean;
  onClick?: () => void;
};

type Props = OwnProps;

const AccordianContainer: FunctionComponent<Props> = (props) => {
  const {  
    header,
    useChevronIcons,
    className,
    label,
    children,
    open, onClick }  = props;
  return (
    <div className={className ?? "exampleCollapseContainer my-3"}>
      <div
        className={`d-flex align-items-center ${open ? 'mb-2' : ''}`}
        aria-controls="common-collapse"
        aria-expanded={open}
      >
        <div className="mr-3" onClick={onClick}>
          <FontAwesomeIcon
            size={"2x"}
            icon={
              open
                ? useChevronIcons
                  ? faChevronCircleDown
                  : faCaretDown
                : useChevronIcons
                ? faChevronCircleRight
                : faCaretRight
            }
          />
        </div>
        {header ?? <h3 className="font-weight-bold mb-0">{label}</h3>}
      </div>
      <Collapse in={open}>
        <div id="common-collapse">{children}</div>
      </Collapse>
    </div>
  );
};

export default AccordianContainer;
