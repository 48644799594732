import React, { FunctionComponent, useMemo } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import {
  SpellingTestAnswer,
  SpellingTestQuestion,
} from "../../../../../store/spelling-tests/types";
import { useDispatch } from "react-redux";
import {
  changeSpellingTestStudentAnswer,
  markSpellingTestStudentAnswer,
} from "../../../../../store/spelling-tests/actions";
import { useMinInputWidth } from "./hooks/useMinInputWidth";
import {
  faCheck,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  question: SpellingTestQuestion;
  answer?: SpellingTestAnswer;
};

const FullWordCell: FunctionComponent<Props> = (props) => {
  const { question, answer } = props;
  const dispatch = useDispatch();

  const handleAnswerWordChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value !== answer?.student_answer) {
      dispatch(
        changeSpellingTestStudentAnswer({
          word: question.word,
          student_answer: value,
        })
      );
    }
  };

  const handleMarkAnswer = (correct?: boolean) => (
    e: React.MouseEvent<HTMLSpanElement>
  ) => {
    dispatch(
      markSpellingTestStudentAnswer({ word: question.word, correct: !!correct })
    );
  };

  const inputMinWidth = useMinInputWidth(question.word);

  return (
    <>
      <td>
        <InputGroup>
          <FormControl
            tabIndex={-1}
            value={question.word}
            style={{ minWidth: inputMinWidth }}
            className="source-part font-weight-bold"
            readOnly
          />
        </InputGroup>
      </td>
      <td className="spelling-test-full-word-actions">
        <span
          className={`correct ${
            answer?.student_answer === question.word ? "active" : ""
          }`}
          onClick={handleMarkAnswer(true)}
        >
          <FontAwesomeIcon icon={faCheckCircle} />
        </span>
        <span
          className={`wrong ${
            answer?.student_answer !== undefined &&
            answer.student_answer !== question.word
              ? "active"
              : ""
          }`}
          onClick={handleMarkAnswer()}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
        {/*<InputGroup>*/}
        {/*  <FormControl*/}
        {/*    style={{ minWidth: inputMinWidth }}*/}
        {/*    defaultValue={answer?.student_answer}*/}
        {/*    onBlur={handleAnswerWordChange}*/}
        {/*    className={`${*/}
        {/*      answer?.student_answer !== undefined*/}
        {/*        ? answer.student_answer === question.word*/}
        {/*          ? "correct-part"*/}
        {/*          : "wrong-part"*/}
        {/*        : "empty-part"*/}
        {/*    }`}*/}
        {/*  />*/}
        {/*</InputGroup>*/}
      </td>
    </>
  );
};

export default FullWordCell;
