import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import {
  connectRouter,
  routerMiddleware,
  RouterState,
} from "connected-react-router";
import { reducer as toastrReducer, ToastrState } from "react-redux-toastr";
import { onboardingReducer } from "./onboarding/reducer";
import { AuthState } from "./auth/types";
import { OnboardingState } from "./onboarding/types";
import { authReducer } from "./auth/reducer";
import thunk from "redux-thunk";
import { CasesState } from "./onboarding/cases/types";
import { casesReducer } from "./onboarding/cases/reducer";
import { groupsReducer } from "./groups/reducer";
import { notificationsReducer } from "./notifications/reducer";
import { GroupsState } from "./groups/types";
import { DataFilterState } from "./dataFilter/types";
import { dataFilterReducer } from "./dataFilter/reducer";
import { dataSortReducer} from "./dataSort/reducer";

import { NotificationsState } from "./notifications/types";
import { HelpState } from "./help/types";
import { helpReducer } from "./help/reducer";
import { meetingModuleReducer } from "./onboarding/meeting-module/reducer";
import { MeetingModuleState } from "./onboarding/meeting-module/types";
import { ChatState } from "./chat/types";
import { chatReducer } from "./chat/reducer";
import { UsersManagementState } from "./onboarding/users-management/types";
import { usersManagementReducer } from "./onboarding/users-management/reducer";
import { badgesReducer } from "./badges/reducer";
import { BadgesState } from "./badges/types";
import { TourState } from "./tour/types";
import { tourReducer } from "./tour/reducer";
import { superuserReducer } from "./superuser/reducer";
import { SuperuserState } from "./superuser/types";
import { spellingTestReducer } from "./spelling-tests/reducer";
import { SpellingTestsState } from "./spelling-tests/types";
import {
  confirmDialogReducer,
  ConfirmDialogState,
} from "./confirm-dialog/reducers";
import { DataSortState } from "./dataSort/types";

export const history = createBrowserHistory({
  basename: "/app",
});

export type ApplicationState = {
  auth: AuthState;
  superuser: SuperuserState;
  onboarding: OnboardingState;
  dataFilter: DataFilterState;
  dataSort: DataSortState;
  groups: GroupsState;
  help: HelpState;
  notifications: NotificationsState;
  cases: CasesState;
  chat: ChatState;
  badges: BadgesState;
  meetingModuleReducer: MeetingModuleState;
  usersManagement: UsersManagementState;
  spellingTests: SpellingTestsState;
  confirmDialog: ConfirmDialogState;
  tour: TourState;
  router: RouterState & { from?: string };
  toastr: ToastrState;
};

const appReducer: any = (state: any, action: any) => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }
  return rootReducer(state, action);
};

const rootReducer = combineReducers<ApplicationState>({
  auth: authReducer,
  superuser: superuserReducer,
  onboarding: onboardingReducer,
  dataFilter: dataFilterReducer,
  dataSort: dataSortReducer,
  groups: groupsReducer,
  help: helpReducer,
  notifications: notificationsReducer,
  cases: casesReducer,
  chat: chatReducer,
  badges: badgesReducer,
  meetingModuleReducer: meetingModuleReducer,
  usersManagement: usersManagementReducer,
  spellingTests: spellingTestReducer,
  confirmDialog: confirmDialogReducer,
  tour: tourReducer,
  toastr: toastrReducer,
  router: connectRouter(history),
});

const configureStore = (preloadedState?: any) => {
  const composeEnhancer: typeof compose =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    appReducer,
    preloadedState,
    composeEnhancer(applyMiddleware(routerMiddleware(history), thunk))
  );
  return store;
};

export default configureStore;
