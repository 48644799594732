import { Grade } from "../groups/types";

export type DataFilterState = {
  dataGradesFilter: Grade[];
  dataSchoolsFilter: number[];
  dataTeachersFilter: number[];
  showDiagnosedStudentFilter: boolean;
  isDataFilterApplied: boolean;
  raceFilter: string[];
  raceOtherFilter: string[];
  genderFilter: string[];
  specialEducationFilter: boolean | undefined;
  ellFilter: boolean | undefined;
  sldFilter: boolean | undefined;
  freeOrReducedLunchFilter: boolean | undefined;
  classIdFilter?: number[];
  teacherAssignmentIdsFilter: number[];
  dayOfWeekFilter?: { value: number; label: string; short: string };
  maxSizeTierTwo?: number;
  maxSizeTierThree?: number;
  focusIdsFilter: string[];
  groupSearch: string;
  groupSort?: GroupSorting;
  recommendGradesFilter: Grade[];
  recommendTeachersFilter: number[];
  recommendClassFilter: number[];
  recommendSchoolsFilter: number[];
  showArchivedGroups?: boolean;
  exitedStudentsGroup?: boolean;
  groupByGrade?: boolean;
  groupByClass?: boolean;
};

export type GroupSorting = {
  sortBy: InterventionGroupSortBy;
  direction: "asc" | "desc";
};

export enum InterventionGroupSortBy {
  GOAL_DATE,
  PROGRESS_TREND,
  ACTIVITY,
  INTERVENTION_SCHEDULE,
}

export enum DataFilterActionTypes {
  CHANGE_DATA_GRADES_FILTER = "@@datafilters/CHANGE_DATA_GRADES_FILTER",
  CHANGE_DATA_SCHOOLS_FILTER = "@@datafilters/CHANGE_DATA_SCHOOLS_FILTER",
  CHANGE_DATA_TEACHERS_FILTER = "@@datafilters/CHANGE_DATA_TEACHERS_FILTER",
  CHANGE_CLASS_FILTER = "@@datafilters/CHANGE_CLASS_FILTER",
  CHANGE_ASSIGNMENT_TEACHER_FILTER = "@@datafilters/CHANGE_ASSIGNMENT_TEACHER_FILTER",
  CHANGE_DAY_OF_WEEK_FILTER = "@@datafilters/CHANGE_DAY_OF_WEEK_FILTER",
  CHANGE_MAX_SIZE_TIER_TWO_FILTER = "@@datafilters/CHANGE_MAX_SIZE_TIER_TWO_FILTER",
  CHANGE_MAX_SIZE_TIER_THREE_FILTER = "@@datafilters/CHANGE_MAX_SIZE_TIER_THREE_FILTER",
  CHANGE_GROUP_BY_GRADE_FILTER = "@@datafilters/CHANGE_GROUP_BY_GRADE_FILTER",
  CHANGE_GROUP_BY_CLASS_FILTER = "@@datafilters/CHANGE_GROUP_BY_CLASS_FILTER",
  CHANGE_FOCUS_FILTER = "@@datafilters/CHANGE_FOCUS_FILTER",
  CHANGE_GROUP_SEARCH = "@@datafilters/CHANGE_GROUP_SEARCH",
  CHANGE_GROUP_SORT = "@@datafilters/CHANGE_GROUP_SORT",
  CHANGE_RECOMMEND_GRADES_FILTER = "@@datafilters/CHANGE_RECOMMEND_GRADES_FILTER",
  CHANGE_RECOMMEND_TEACHERS_FILTER = "@@datafilters/CHANGE_RECOMMEND_TEACHERS_FILTER",
  CHANGE_RECOMMEND_CLASS_FILTER = "@@datafilters/CHANGE_RECOMMEND_CLASS_FILTER",
  CHANGE_RECOMMEND_SCHOOLS_FILTER = "@@datafilters/CHANGE_RECOMMEND_SCHOOLS_FILTER",
  SET_RECOMMEND_FILTERS_FROM_DATA_FILTERS = "@@datafilters/SET_RECOMMEND_FILTERS_FROM_DATA_FILTERS",
  CHANGE_SHOW_ARCHIVED_GROUPS = "@@datafilters/CHANGE_SHOW_ARCHIVED_GROUPS",
  CHANGE_EXITED_STUDENTS_GROUPS = "@@datafilters/CHANGE_EXITED_STUDENTS_GROUPS",
  CHANGE_RACE_FILTER = "@@datafilters/CHANGE_RACE_FILTER",
  CHANGE_RACE_OTHER_FILTER= "@@datafilters/CHANGE_RACE_OTHER_FILTER",
  CHANGE_GENDER_FILTER = "@@datafilters/CHANGE_GENDER_FILTER",
  CHANGE_SPACIAL_EDUCATION_FILTER = "@@datafilters/CHANGE_SPACIAL_EDUCATION_FILTER",
  CHANGE_SLD_FILTER = "@@datafilters/CHANGE_SLD_FILTER",
  CHANGE_ELL_FILTER = "@@datafilters/CHANGE_ELL_FILTER",
  CHANGE_FREE_REDUCED_LUNCH_FILTER = "@@datafilters/CHANGE_FREE_REDUCED_LUNCH_FILTER",
  CHANGE_SHOW_DIAGNOSED_STUDENT_FILTER = "@@datafilters/CHANGE_SHOW_DIAGNOSED_STUDENT_FILTER",
  DATA_FILTER_APPLIED = "@@datafilters/DATA_FILTER_APPLIED"
}
