import React, { FunctionComponent, useState } from "react";

type Props = {
  onClick: Function;
};

const CellGroup: FunctionComponent<Props> = (props) => {
  const { children, onClick } = props;

  const [className, setClassName] = useState<string | undefined>();

  const handleMouseOver = () => {
    setClassName("hovered");
  };

  const onMouseLeave = () => {
    setClassName(undefined);
  };

  return (
    <>
      {React.Children.map(children, (child: any) =>
        React.cloneElement(child, {
          onMouseOver: handleMouseOver,
          onMouseLeave: onMouseLeave,
          onClick: onClick,
          className: className,
        })
      )}
    </>
  );
};

export default CellGroup;
