import React, { FunctionComponent, useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { hideConfirmDialog } from "../../../store/confirm-dialog/actions";

type Props = {};

const ConfirmDialog: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState(0);
  const {
    show,
    onConfirm,
    onCancel,
    intent,
    title,
    centered,
    text,
    showLastStudentWarning,
    timerElipseLeft,
    isFinished,
    countDownTimer,
    cancelButtonText,
    confirmButtonText,
    size,
    backdropClassName,
    modalClassName,
    additionalButtonText,
    isAdditionalButton
  } = useSelector((s: ApplicationState) => s.confirmDialog.confirmDialog);


  const handleDialogHide = () => {
    dispatch(hideConfirmDialog());
    onCancel && onCancel();
  };

  const handleConfirm = (buttonType?: boolean) => {
     dispatch(hideConfirmDialog());
    onConfirm && onConfirm(buttonType);
  };

  useEffect(() => {
    if(countDownTimer) {
      setTimeLeft(countDownTimer!)
    }
  }, [countDownTimer, showLastStudentWarning, timerElipseLeft])

  useEffect(() => {
     if(showLastStudentWarning || timerElipseLeft) {
      // exit early when we reach 0
      if (!timeLeft) return;
  
      // save intervalId to clear the interval when the
      // component re-renders
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
  
      // clear interval on re-render to avoid memory leaks
      return () => {
        clearInterval(intervalId);
      };
      // add timeLeft as a dependency to re-rerun the effect
      // when we update it
    }
  }, [timeLeft]);

   return (
    <Modal
      className={modalClassName}
      onHide={handleDialogHide}
      animation={false}
      centered={centered}
      backdropClassName={`customDarkModalBackdrop inner in ${backdropClassName ??
        ""}`}
      show={show}
      size={size ?? "sm"}
    >
      <Modal.Header
        closeButton
        className="purpleModalHeader"
        style={{ borderBottom: 0 }}
      >
        <Modal.Title>{title ?? "Confirm Deletion"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showLastStudentWarning ? 
          <div className="alert alert-warning">
            You are removing the last student in the group, once moved the group will be 
            {isFinished ? <span className="font-weight-bold"> archived</span>: <span className="font-weight-bold"> discarded</span>}. Are you sure you want to move this student? 
          </div> : ''}
        <p className="text-center">
          {text ?? "Are you sure? This operation cannot be undone."}
        </p>
        <hr />
        <div className="btnActions">
          <button className="whiteBtnSm" onClick={handleDialogHide}>
            {cancelButtonText ?? "Cancel"}
          </button>
          <button className="blueBtnSm" disabled={showLastStudentWarning || timerElipseLeft ? !!timeLeft : false} onClick={() => handleConfirm(false)}>
            {showLastStudentWarning || timerElipseLeft ? !timeLeft ? confirmButtonText ?? "Yes, delete it" : timeLeft : confirmButtonText ?? "Yes, delete it"}
          </button>
          {isAdditionalButton ? <button className="blueBtnSm" disabled={showLastStudentWarning || timerElipseLeft ? !!timeLeft : false} onClick={() => handleConfirm(true)}>
            {showLastStudentWarning || timerElipseLeft ? !timeLeft ? additionalButtonText ?? "Yes, delete it" : timeLeft : additionalButtonText ?? "Yes, delete it"}
          </button> : ''}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmDialog;
