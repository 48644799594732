import React, { FunctionComponent } from "react";
import { IS_READY_COACH } from "../../constants";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faHome } from "@fortawesome/free-solid-svg-icons";
import { UserAssignmentRole, UserInfo } from "../../store/auth/types";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { useHistory } from "react-router";

type StateProps = {
  userInfo?: UserInfo;
};

type OwnProps = {
  withIcons?: boolean;
};

type Props = StateProps & OwnProps;

const HeaderNavLinks: FunctionComponent<Props> = ({ userInfo, withIcons }) => {
  const history = useHistory();
  if (!userInfo) return null;

  const handleNavLinkClick = (location: string) => () => {
    history.push(location);
  };

  if (!IS_READY_COACH) {
    return (
      <Nav.Link onClick={handleNavLinkClick("/groups")}>
        {withIcons && (
          <div className="mobileNavIcon">
            <FontAwesomeIcon icon={faHome} />
          </div>
        )}
        Home
      </Nav.Link>
    );
  }

  if (!userInfo?.profile.current_assignment?.role) return null;

  switch (userInfo.profile.current_assignment.role) {
    case UserAssignmentRole.DISTRICT_OWNER:
      return (
        <>
          <Nav.Link onClick={handleNavLinkClick("/district")}>
            {withIcons && (
              <div className="mobileNavIcon">
                <FontAwesomeIcon icon={faHome} />
              </div>
            )}
            Home
          </Nav.Link>
          {/* <div className="menuSeparator">|</div>
          <Nav.Link onClick={handleNavLinkClick("/district/staff")}>
            {withIcons && (
              <div className="mobileNavIcon">
                <FontAwesomeIcon icon={faBook} />
              </div>
            )}
            Coaching
          </Nav.Link> */}
          {/* <div className="menuSeparator">|</div>
          <Nav.Link onClick={handleNavLinkClick("/district/assessments")}>
            {withIcons && (
              <div className="mobileNavIcon">
                <FontAwesomeIcon icon={faBook} />
              </div>
            )}
            Assessments
          </Nav.Link> */}
        </>
      );
    case UserAssignmentRole.COACH:
    case UserAssignmentRole.TEACHER:
    case UserAssignmentRole.SUPERVISOR:
    default:
      return (
        <>
          {/* <Nav.Link onClick={handleNavLinkClick("/cases/groups")}>
            {withIcons && (
              <div className="mobileNavIcon">
                <FontAwesomeIcon icon={faHome} />
              </div>
            )}
            Coaching
          </Nav.Link> */}
          {/* <div className="menuSeparator">|</div>
          <Nav.Link onClick={handleNavLinkClick("/cases/groups")}>
            {withIcons && (
              <div className="mobileNavIcon">
                <FontAwesomeIcon icon={faBook} />
              </div>
            )}
            Assessments
          </Nav.Link> */}
        </>
      );
  }
};

const mapStateToProps = ({ auth }: ApplicationState) => {
  return {
    userInfo: auth.userInfo,
  };
};

export default connect(mapStateToProps)(HeaderNavLinks);
