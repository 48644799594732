import React, { FunctionComponent, MouseEventHandler } from "react";

type OwnProps = {
  onClick: MouseEventHandler<any>;
};

type Props = OwnProps;

const IntroPanel: FunctionComponent<Props> = (props) => {
  //todo update layout
  return (
    <div className="dataContainer text-center">
      <div>
        <h2 className="font-weight-bold">
          Welcome. You don't have any ABC collection yet.
        </h2>
        <h3 className="font-weight-bold">Get started by adding some data!</h3>
      </div>

      <button className="blueBtnLg mt-3" onClick={props.onClick}>
        Get Started
      </button>
    </div>
  );
};

export default IntroPanel;
