export type USState = {
  value: string;
  display_name: string;
};

export const USStateNamesValues: USState[] | undefined = [
  { value: "AL", display_name: "Alabama" },
  { value: "AZ", display_name: "Arizona" },
  { value: "AR", display_name: "Arkansas" },
  { value: "CA", display_name: "California" },
  { value: "CO", display_name: "Colorado" },
  { value: "CT", display_name: "Connecticut" },
  { value: "DE", display_name: "Delaware" },
  { value: "DC", display_name: "District of Columbia" },
  { value: "FL", display_name: "Florida" },
  { value: "GA", display_name: "Georgia" },
  { value: "ID", display_name: "Idaho" },
  { value: "IL", display_name: "Illinois" },
  { value: "IN", display_name: "Indiana" },
  { value: "IA", display_name: "Iowa" },
  { value: "KS", display_name: "Kansas" },
  { value: "KY", display_name: "Kentucky" },
  { value: "LA", display_name: "Louisiana" },
  { value: "ME", display_name: "Maine" },
  { value: "MD", display_name: "Maryland" },
  { value: "MA", display_name: "Massachusetts" },
  { value: "MI", display_name: "Michigan" },
  { value: "MN", display_name: "Minnesota" },
  { value: "MS", display_name: "Mississippi" },
  { value: "MO", display_name: "Missouri" },
  { value: "MT", display_name: "Montana" },
  { value: "NE", display_name: "Nebraska" },
  { value: "NV", display_name: "Nevada" },
  { value: "NH", display_name: "New Hampshire" },
  { value: "NJ", display_name: "New Jersey" },
  { value: "NM", display_name: "New Mexico" },
  { value: "NY", display_name: "New York" },
  { value: "NC", display_name: "North Carolina" },
  { value: "ND", display_name: "North Dakota" },
  { value: "OH", display_name: "Ohio" },
  { value: "OK", display_name: "Oklahoma" },
  { value: "OR", display_name: "Oregon" },
  { value: "PA", display_name: "Pennsylvania" },
  { value: "RI", display_name: "Rhode Island" },
  { value: "SC", display_name: "South Carolina" },
  { value: "SD", display_name: "South Dakota" },
  { value: "TN", display_name: "Tennessee" },
  { value: "TX", display_name: "Texas" },
  { value: "UT", display_name: "Utah" },
  { value: "VT", display_name: "Vermont" },
  { value: "VA", display_name: "Virginia" },
  { value: "WA", display_name: "Washington" },
  { value: "WV", display_name: "West Virginia" },
  { value: "WI", display_name: "Wisconsin" },
  { value: "WY", display_name: "Wyoming" },
  { value: "AK", display_name: "Alaska" },
  { value: "HI", display_name: "Hawaii" },
];
