import React, { FunctionComponent } from "react";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import Modal from "react-bootstrap/Modal";
import GroupInfoModalTitle from "../common/GroupInfoModalTitle";
import ModalCloseButton from "../../../../../common/onboarding/third-step/group-students/common/ModalCloseButton";
import {
  hideGroupInterventionsModal,
  openInterventionLibraryModal,
} from "../../../../../../store/onboarding/cases/actions";
import GroupInterventionsTable from "./GroupInterventionsTable";

type StateProps = {
  showModal?: boolean;
  showLibraryModal?: boolean;
  interventionGroup?: InterventionGroup;
};

type DispatchProps = {
  hideGroupInterventionsModal: () => any;
};

type Props = StateProps & DispatchProps;

const GroupInterventionsModal: FunctionComponent<Props> = ({
  showModal,
  showLibraryModal,
  interventionGroup,
  hideGroupInterventionsModal,
}) => {
  const onModalHide = () => {
    hideGroupInterventionsModal();
  };

  const onModalShow = () => {};

  return (
    <Modal
      show={showModal && !showLibraryModal}
      onHide={onModalHide}
      onShow={onModalShow}
      animation={false}
      size="lg"
      backdropClassName={"customDarkModalBackdrop in"}
    >
      <Modal.Header className="purpleModalHeader centerModalHeader">
        <GroupInfoModalTitle />
        <Modal.Title>Your current interventions</Modal.Title>
        <ModalCloseButton onClose={onModalHide} />
      </Modal.Header>
      <Modal.Body>
        <h3 className="mb-3">
          <span className="font-weight-bold">Group: </span>
          {interventionGroup?.name}
        </h3>
        <div>
          <GroupInterventionsTable interventionGroup={interventionGroup} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ cases }: ApplicationState): StateProps => {
  return {
    showModal: cases.modalsState.groupInterventionsModal,
    showLibraryModal: cases.modalsState.interventionLibraryModal,
    interventionGroup: cases.selectedInterventionGroup,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hideGroupInterventionsModal: hideGroupInterventionsModal,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupInterventionsModal);
