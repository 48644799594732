import React, { FunctionComponent, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAlphaDown,
  faSortAlphaUp,
} from "@fortawesome/free-solid-svg-icons";
import { AbcField, AbcSorting } from "./AbcTableBody";

type OwnProps = {
  originalField: AbcField;
  sorting?: AbcSorting;
};

type Props = OwnProps;

const SortIcon: FunctionComponent<Props> = (props) => {
  const { originalField, sorting } = props;

  const show = useMemo(() => {
    return sorting && sorting.field === originalField;
  }, [sorting?.field, originalField]);

  if (!show) {
    return null;
  }

  return (
    <span>
      <FontAwesomeIcon
        icon={sorting?.order === "asc" ? faSortAlphaDown : faSortAlphaUp}
      />
    </span>
  );
};

export default SortIcon;
