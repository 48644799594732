import React, { FunctionComponent, useMemo } from "react";

import { OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../../../store";
import { UserInfo } from "../../../../../../../../../store/auth/types";
import { CommonSteps, Intervention, InterventionGroup } from "../../../../../../../../../store/onboarding/cases/types";
import { ImplementationCheck } from "../../../../../../../../../store/onboarding/meeting-module/types";


type OwnProps = {
  checklistItem: any;
  index: number;
  loggedTimes?: number;
  selectedInterventionGroup?: InterventionGroup;
};

type Props = OwnProps;

enum DisplayedGroupsFilterValues {
  ACTIVE_GROUPS = "active",
  ARCHIVED_GROUPS = "archived",
  EXITED_GROUPS = "exited_groups",
}

const InterventionStepRow: FunctionComponent<Props> = (props) => {
  const { checklistItem, loggedTimes, index, selectedInterventionGroup } = props;
  // custom code

  const displayGroup = useSelector((s: ApplicationState) => s.cases.displayGroupName);  

  const interventionGroups = useSelector<ApplicationState, InterventionGroup[]>(
    (s) => displayGroup == DisplayedGroupsFilterValues.ARCHIVED_GROUPS 
          ?
            s.cases.archivedInterventionGroups
            .filter((interventionGroup) => 
              selectedInterventionGroup 
              ? (interventionGroup.id == selectedInterventionGroup.id) 
              : true)
          : s.cases.interventionGroups
            .filter((interventionGroup) => 
            selectedInterventionGroup 
            ? (interventionGroup.id == selectedInterventionGroup.id) 
            : true)
  );

  const educator = useSelector<ApplicationState, UserInfo | undefined>(
    (s) => s.onboarding.selectedEducator
  );

  const currentUser = useSelector<ApplicationState, UserInfo | undefined>(
    (s) => s.auth.userInfo
  );
  
  let interventionArray:any[] = [];
  let filterdGroups:InterventionGroup[] = [];
  let uniqueInterventions: any[] = [];
  
  for (let i = 0; i < interventionGroups.length; i++) {
    if(educator){
      if(interventionGroups[i].teacher_assignment?.user.id == educator.id) {
        interventionArray.push(interventionGroups[i].interventions);
        filterdGroups.push(interventionGroups[i])
      }
      if(interventionGroups[i].coach_assignment?.user.id == educator.id) {
        interventionArray.push(interventionGroups[i].interventions);
        filterdGroups.push(interventionGroups[i])
      }
    } else {
      interventionArray.push(interventionGroups[i].interventions);
      filterdGroups.push(interventionGroups[i])
    }
  }

  for (let j = 0; j < interventionArray.length; j++) {
    for (let k = 0; k < interventionArray[j].length; k++) {
      const element = interventionArray[j][k];
      if(!uniqueInterventions.some((data)=> data.id == element.id)) {
        uniqueInterventions.push(element);
      }
    }
  }
  
  let interventionTimesCount: any[] = [];
  
  for (let i = 0; i < uniqueInterventions.length; i++) {
    const uniqueIntervention: Intervention = uniqueInterventions[i];
    let interventionimpl:number = 0;
    let notApplicableCIObj = {};
    let notPlannedStepsChecked:number = 0;
    for (let j = 0; j < filterdGroups.length; j++) {
      const filterElement = filterdGroups[j];
      const found = filterElement.interventions.some((intervention:any) => uniqueIntervention.id == intervention.id)
      if(found) {
        filterElement.progress_overview?.implementation_checks.forEach((implChecks:ImplementationCheck) => {
          if (implChecks.intervention == uniqueIntervention.id){
              interventionimpl += 1;
              if(implChecks.not_applicable_items?.length){
                uniqueIntervention.checklist_items.forEach((ci) => {
                  implChecks.not_applicable_items?.forEach((na) => {
                    if(na.item == ci.id){
                      notApplicableCIObj = {
                        ...notApplicableCIObj,
                        [ci.common_step_name as any]: notPlannedStepsChecked + 1
                       }
                    }
                  })
                })
              }
          }
        })
      }
    }
    //console.log(notApplicableCIObj)
    interventionTimesCount.push({
      intervention: uniqueIntervention,
      loggedInterventionCount: interventionimpl,
      notApplicableCIObj: notApplicableCIObj
    })
  }


  let commonstepcount:any = {};
  for (let i = 0; i < CommonSteps.length; i++) {
    const commonStep = CommonSteps[i];
    let all_common_count = 0;

    for (let j = 0; j < interventionTimesCount.length; j++) {
      const data = interventionTimesCount[j];
      let common_step_count_by_checklist_items = data.intervention.checklist_items.filter((checks:any) => {
        return checks.common_step_name == commonStep.value
      }).length;
      if(common_step_count_by_checklist_items > 0) {
        all_common_count += (common_step_count_by_checklist_items) * data.loggedInterventionCount
      }
      for (const commonStepKey in data.notApplicableCIObj) {
        if (Object.prototype.hasOwnProperty.call(data.notApplicableCIObj, commonStepKey)) {
          const notPlannedItemsCount = data.notApplicableCIObj[commonStepKey];
          if(commonStepKey == commonStep.value){
            all_common_count = all_common_count -(notPlannedItemsCount || 0)
          }
          
        }
      }
    }

    commonstepcount[commonStep.value] = all_common_count
  }
  // custom code end

  const fidelityMsg = useMemo(() => {
    if(currentUser?.profile.current_role !== 'superuser') {
      for (const key in commonstepcount) {
        if(checklistItem.title == key) {
          const total = commonstepcount[key] || 0;
          const fidelity = ((checklistItem.loggedTimes / total) * 100).toFixed();
          return (fidelity == 'NaN' ? '0' : fidelity) + `% (${checklistItem.loggedTimes} of ${total})`;
        } 

      }
    }
    const notPlanned = checklistItem.notApplicableTimes || 0;
    const total =  checklistItem.usedTimes || loggedTimes || 0;
    const fidelity = ((checklistItem.loggedTimes / (total - notPlanned) ) * 100).toFixed();
    return (fidelity == 'NaN' ? '0' : fidelity) + `% (${checklistItem.loggedTimes} of ${total - notPlanned})`;
  }, [checklistItem, loggedTimes, commonstepcount]);

  const overviewModelType = useSelector<ApplicationState, String | undefined>(
    (s) => s.groups.overviewModelType
  );

  return (
    <tr key={checklistItem.id}>
      <td className={"font-weight-bold"}>
        {checklistItem.id
          ? `Step ${index + 1}: ${checklistItem.title}`
          : checklistItem.title}
          {/* <br/>{"============"}<br/>{checklistItem.common_step_name} */}
      </td>
      <td>
        <div>
          <OverlayTrigger
            overlay={
              <Popover id="particular-step-fidelity-popover">
                <Popover.Content>
                  {overviewModelType !== 'one_educator' ? 
                    `This is the fidelity for this common step as well as how 
                    many times it was implemented out of how many times 
                    it occurred across all interventions and groups.`
                  :
                    `This is the fidelity for this common step as well as how 
                    many times it was implemented out of how many times 
                    it occurred across all interventions used by this teacher.`
                  }

                </Popover.Content>
              </Popover>
            }
          >
            <div className="text-center">{fidelityMsg}</div>
          </OverlayTrigger>
        </div>
      </td>
    </tr>
  );
};

export default InterventionStepRow;
