import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { UserInfo } from "../../../../store/auth/types";
import { Image, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  openEditProfileModal,
  uploadUserProfileImage,
} from "../../../../store/auth/actions";
import PictureReader from "../../file-reader/PictureReader";
import LoadingIndicator from "../../onboarding/LoadingIndicator";
import { IS_READY_COACH } from "../../../../constants";
import { useIntl } from "react-intl";

type PropsFromState = {
  userInfo?: UserInfo;
  isLoading: {
    uploadUserProfileImage: boolean;
    loadUserInfo: boolean;
  };
  errors: {
    uploadUserProfileImage?: String;
  };
};

type DispatchProps = {
  openEditProfileModal: () => any;
  uploadUserProfileImage: (file: File) => any;
};

type OwnProps = {
  isNotificationsTab?: boolean;
};

type Props = PropsFromState & DispatchProps & OwnProps;

const ProfileHeader = (props: Props) => {
  const { userInfo, isNotificationsTab, isLoading } = props;
  const intl = useIntl();
  if (isLoading.loadUserInfo) return <LoadingIndicator />;
  if (!userInfo) return null;

  return (
    <div className="d-flex justify-content-between flex-wrap align-items-center pb-4 border-bottom">
      <div>
        <div className="d-flex align-items-center">
          <div className="mr-3">
            <div className="d-flex ">
              <Image
                style={{
                  objectFit: "cover",
                  objectPosition: "top center",
                  border: "1px solid rgb(97, 97, 97)",
                }}
                src={userInfo && userInfo.profile.profile_image_url}
                width={100}
                height={100}
                roundedCircle
              />
              {isLoading.uploadUserProfileImage && (
                <div
                  style={{
                    width: 100,
                    height: 100,
                  }}
                  className="loadImageSpinnerContainer rounded-circle"
                >
                  <Spinner animation="border" />
                </div>
              )}
            </div>
          </div>

          <div>
            <h1 className="font-weight-bold">
              {userInfo.first_name} {userInfo.last_name}
            </h1>
            <h2>{userInfo.email}</h2>
          </div>
        </div>

        {!isNotificationsTab && (
          <label className="blueBtnSm mt-2">
            Change Profile Picture
            <PictureReader />
          </label>
        )}
      </div>
      <div>
        {isNotificationsTab ? (
          <Link to={window.location.pathname.includes('district') ? `/district/profile` : '/profile'}>
            <button className={IS_READY_COACH ? "whiteBtnMd2" : "whiteBtnMd"}>
              {intl.formatMessage({
                id: "app.dashboard.profile.returnToProfileBtn.label",
              })}
            </button>
          </Link>
        ) : (
          <button
            className="blueBtnMd"
            style={{ margin: "10px" }}
            onClick={props.openEditProfileModal}
          >
            {intl.formatMessage({
              id: "app.dashboard.profile.editProfileBtn.label",
            })}
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }: ApplicationState): PropsFromState => {
  return {
    userInfo: auth.userInfo,
    isLoading: {
      uploadUserProfileImage: auth.isLoading.uploadUserProfileImage,
      loadUserInfo: auth.isLoading.loadUserInfo,
    },
    errors: {
      uploadUserProfileImage: auth.errors.uploadUserProfileImage,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openEditProfileModal: openEditProfileModal,
      uploadUserProfileImage: uploadUserProfileImage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHeader);
