import { action } from "typesafe-actions";
import { DataFilterActionTypes, GroupSorting } from "./types";
import { Grade } from "../groups/types";

export const changeDataGradesFilter = (dataGradesFilter?: Grade[]) =>
  action(DataFilterActionTypes.CHANGE_DATA_GRADES_FILTER, {
    dataGradesFilter,
  });

export const changeDataSchoolsFilter = (dataSchoolsFilter?: number[]) =>
  action(DataFilterActionTypes.CHANGE_DATA_SCHOOLS_FILTER, {
    dataSchoolsFilter,
  });

export const changeDataTeachersFilter = (dataTeachersFilter?: number[]) =>
  action(DataFilterActionTypes.CHANGE_DATA_TEACHERS_FILTER, {
    dataTeachersFilter,
  });

export const changeClassIdFilter = (classIdFilter?: number[]) =>
  action(DataFilterActionTypes.CHANGE_CLASS_FILTER, {
    classIdFilter,
  });

export const changeInterventionGroupTeacherFilter = (
  teacherAssignmentIdsFilter?: number[]
) =>
  action(DataFilterActionTypes.CHANGE_ASSIGNMENT_TEACHER_FILTER, {
    teacherAssignmentIdsFilter,
  });

export const changeDayOfWeekFilter = (dayOfWeekFilter?: {
  value: number;
  label: string;
  short: string;
}) =>
  action(DataFilterActionTypes.CHANGE_DAY_OF_WEEK_FILTER, {
    dayOfWeekFilter,
  });

export const changeMaxSizeTierTwoFilter = (maxSizeTierTwo?: number) =>
  action(DataFilterActionTypes.CHANGE_MAX_SIZE_TIER_TWO_FILTER, {
    maxSizeTierTwo,
  });

export const changeMaxSizeTierThreeFilter = (maxSizeTierThree?: number) =>
  action(DataFilterActionTypes.CHANGE_MAX_SIZE_TIER_THREE_FILTER, {
    maxSizeTierThree,
  });

export const changeGroupByGradeFilter = (groupByGrade?: boolean) =>
  action(DataFilterActionTypes.CHANGE_GROUP_BY_GRADE_FILTER, {
    groupByGrade,
  });

export const changeGroupByClassFilter = (groupByClass?: boolean) =>
  action(DataFilterActionTypes.CHANGE_GROUP_BY_CLASS_FILTER, {
    groupByClass,
  });

export const changeFocusFilter = (focusIdsFilter?: string[]) =>
  action(DataFilterActionTypes.CHANGE_FOCUS_FILTER, {
    focusIdsFilter,
  });

export const changeGroupSearch = (groupSearch: string) =>
  action(DataFilterActionTypes.CHANGE_GROUP_SEARCH, {
    groupSearch,
  });

export const changeGroupSort = (groupSort?: GroupSorting) =>
  action(DataFilterActionTypes.CHANGE_GROUP_SORT, {
    groupSort,
  });

export const changeRecommendGradesFilter = (gradesFilter?: Grade[]) =>
  action(DataFilterActionTypes.CHANGE_RECOMMEND_GRADES_FILTER, {
    gradesFilter,
  });

export const changeRecommendTeachersFilter = (teachersFilter?: number[]) =>
  action(DataFilterActionTypes.CHANGE_RECOMMEND_TEACHERS_FILTER, {
    teachersFilter,
  });

export const changeRecommendClassFilter = (classFilter?: number[]) =>
  action(DataFilterActionTypes.CHANGE_RECOMMEND_CLASS_FILTER, {
    classFilter,
  });

export const changeRecommendSchoolsFilter = (schoolsFilter?: number[]) =>
  action(DataFilterActionTypes.CHANGE_RECOMMEND_SCHOOLS_FILTER, {
    schoolsFilter,
  });

export const setRecommendFiltersFromDataFilters = () =>
  action(DataFilterActionTypes.SET_RECOMMEND_FILTERS_FROM_DATA_FILTERS);

export const changeShowArchivedGroups = (show?: boolean) =>
  action(DataFilterActionTypes.CHANGE_SHOW_ARCHIVED_GROUPS, { show });

export const changeExitedStudentsGroup = (show?: boolean) =>
  action(DataFilterActionTypes.CHANGE_EXITED_STUDENTS_GROUPS, { show });

export const changeRaceFilter = (raceFilter?: string[]) =>
  action(DataFilterActionTypes.CHANGE_RACE_FILTER, {
    raceFilter,
  });

export const changeRaceOtherFilter = (raceOtherFilter?: string[]) =>
  action(DataFilterActionTypes.CHANGE_RACE_OTHER_FILTER, {
    raceOtherFilter,
  });

export const changeGenderFilter = (genderFilter?: string[]) =>
  action(DataFilterActionTypes.CHANGE_GENDER_FILTER, {
    genderFilter,
  });

export const changeSpecialEducationFilter = (specialEducationFilter: boolean | undefined) =>
  action(DataFilterActionTypes.CHANGE_SPACIAL_EDUCATION_FILTER, {
    specialEducationFilter,
  });

export const changeSLDFilter = (sldFilter: boolean | undefined) =>
  action(DataFilterActionTypes.CHANGE_SLD_FILTER, {
    sldFilter,
  });

export const changeELLFilter = (ellFilter: boolean | undefined) =>
  action(DataFilterActionTypes.CHANGE_ELL_FILTER, {
    ellFilter,
  });

export const changeFreeOrReducedLunchFilter = (freeOrReducedLunchFilter: boolean | undefined) =>
  action(DataFilterActionTypes.CHANGE_FREE_REDUCED_LUNCH_FILTER, {
    freeOrReducedLunchFilter,
  });

export const changeShowDiagnosedStudentFilter = (showDiagnosedStudentFilter: boolean) =>
  action(DataFilterActionTypes.CHANGE_SHOW_DIAGNOSED_STUDENT_FILTER, {
    showDiagnosedStudentFilter,
  });

export const dataFilterApplied = (isDataFilterApplied: boolean) =>
  action(DataFilterActionTypes.DATA_FILTER_APPLIED, {
    isDataFilterApplied,
  });  