import React, { FunctionComponent } from "react";

type Props = {};

const EmptyCell: FunctionComponent<Props> = (props) => {
  return (
    <>
      <td />
      <td />
    </>
  );
};

export default EmptyCell;
