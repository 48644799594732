import {
  faAward,
  faBars,
  faBell,
  faDatabase,
  faGlasses,
  faRestroom,
  faSpellCheck,
  faStickyNote,
  faTable,
  faThumbsUp,
  faUniversalAccess,
  faUserCheck,
  faUsers,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent, useMemo } from "react";
import { Col, Nav, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { ApplicationState } from "../../../store";
import { UserAssignmentRole } from "../../../store/auth/types";
import { CoachDashboardTabKeys } from "../../pages/coach-dashboard/CoachDashboard";
import CoachDataPeriodSelector from "../../pages/coach-dashboard/CoachDataPeriodSelector";
import DashboardMenuPanel from "../../pages/coach-dashboard/DashboardMenuPanel";
import GroupsInProgressNavLink from "../../pages/coach-dashboard/tabs/groups-in-progress/GroupsInProgressNavLink";
import DataPeriodsPanel from "../../pages/coach-dashboard/tabs/your-data-and-interviews/DataPeriodsPanel";
import useActiveTab from "../../pages/parent-dashboard/useActiveTab";
import useUserRole from "../../../utils/hooks/useUserRole";
import { EventKey } from "react-bootstrap/esm/types";

type MatchParams = {
  tabName: string;
};

type Props = RouteComponentProps<MatchParams>;

const TopBar: FunctionComponent<Props> = ({
  match: {
    params: { tabName: activeTab },
  },
}) => {
  const { isTeacher } = useUserRole();
  const isParent = useSelector(
    (s: ApplicationState) =>
      s.auth.userInfo?.profile.current_assignment?.role ===
      UserAssignmentRole.PARENT
  );

  const activeItem = useMemo(() => {
    switch (activeTab) {
      case CoachDashboardTabKeys.YOUR_READING_ASSIGNMENTS:
        return (
          <>
            <FontAwesomeIcon icon={faUsers} />
            &nbsp;{isParent ? "Your Children" : "Reading Intervention Groups"}
          </>
        );
      case CoachDashboardTabKeys.YOUR_BEHAVIOUR_ASSIGNMENTS:
        return (
          <>
            <FontAwesomeIcon icon={faUsers} />
            &nbsp;{isParent ? "Your Children" : "Behavior Intervention Groups"}
          </>
        );
      // case CoachDashboardTabKeys.GROUPS_IN_PROGRESS:
      //   return <GroupsInProgressNavLink />;
      case CoachDashboardTabKeys.YOUR_DATA_AND_INTERVIEWS:
        return (
          <>
            <FontAwesomeIcon icon={faTable} />
            &nbsp;Your Data & Interviews
          </>
        );
      case CoachDashboardTabKeys.ROSTERS_STUDENTS:
      case CoachDashboardTabKeys.ROSTERS_TEACHERS:
        return (
          <>
            <FontAwesomeIcon icon={faRestroom} />
            &nbsp; {isTeacher ? `Student Data` : 'Educators & Student Data'}
          </>
        );
      // case CoachDashboardTabKeys.PARENTS:
      //   return (
      //     <>
      //       <FontAwesomeIcon icon={faUniversalAccess} />
      //       &nbsp;Parent/Guardian Access
      //     </>
      //   );
      case CoachDashboardTabKeys.FBAs:
        return (
          <>
            <FontAwesomeIcon icon={faGlasses} />
            &nbsp;ABC Data Collection
          </>
        );
      case CoachDashboardTabKeys.BADGES:
        return (
          <>
            <FontAwesomeIcon icon={faAward} />
            &nbsp;Badges
          </>
        );
      case CoachDashboardTabKeys.INTERVENTION_LIBRARY:
        return (
          <>
            <FontAwesomeIcon icon={faDatabase} />
            &nbsp;Intervention Library
          </>
        );
      case CoachDashboardTabKeys.TUTORIAL_VIDEOS:
        return (
          <>
            <FontAwesomeIcon icon={faVideo} />
            &nbsp;Video Tutorials
          </>
        );
      case CoachDashboardTabKeys.RELEASE_NOTES:
        return (
          <>
            <FontAwesomeIcon icon={faStickyNote} />
            &nbsp;Release Notes
          </>
        );
      case CoachDashboardTabKeys.YOUR_PROFILE:
        return (
          <>
            <FontAwesomeIcon icon={faUserCheck} />
            &nbsp;Your Profile
          </>
        );

      case CoachDashboardTabKeys.NOTIFICATIONS:
        return (
          <>
            <FontAwesomeIcon icon={faBell} />
            &nbsp;Notifications
          </>
        );

      case CoachDashboardTabKeys.SPELLING_TEST:
        return (
          <>
            <FontAwesomeIcon icon={faSpellCheck} />
            &nbsp;Spelling Test
          </>
        );
      default:
        return <i>Not selected</i>;
    }
  }, [activeTab]);

  const { changeActiveTab } = useActiveTab();

  const handleActiveTabSelect = (activeTab: string | null) => {
    document.body.click(); // causes the popover to close
    changeActiveTab(activeTab);
  };

  const popover = useMemo(
    () => (
      <Popover id="side-bar-page-popover">
        <Popover.Content>
          <Nav
            bsPrefix="coachDashboardSidebar"
            defaultActiveKey={CoachDashboardTabKeys.YOUR_READING_ASSIGNMENTS}
            activeKey={activeTab as EventKey}
            onSelect={handleActiveTabSelect}
          >
            <DashboardMenuPanel activeTab={activeTab} />
          </Nav>
        </Popover.Content>
      </Popover>
    ),
    [activeTab]
  );

  return (
    <div className="topBar" id="topBar">
      <OverlayTrigger
        trigger="click"
        rootClose
        overlay={popover}
        placement="bottom"
      >
        <div>
          <h4 className="text-center font-weight-bold">Main Menu</h4>
          <div className="pageBtn">
            <span className="flex-grow-1">{activeItem}</span>
            <span>
              <FontAwesomeIcon icon={faBars} size="lg" />
            </span>
          </div>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default withRouter(TopBar);
