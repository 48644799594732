import React, { FunctionComponent, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Decision } from "../../../../../../store/onboarding/types";

type OwnProps = {
  decisions: Array<Decision>;
};

type Props = OwnProps;

const DecisionsTable: FunctionComponent<Props> = ({ decisions }) => {
  const columns = useMemo(
    () => [
      {
        dataField: "decision_title",
        text: "Decision Title",
        sort: true,
      },
      {
        dataField: "decision_count",
        text: "Decision Count",
        sort: true,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      decisions.map((d) => ({
        decision_title: d.title,
        decision_count: d.count,
      })),
    [decisions]
  );

  return (
    <BootstrapTable
      classes="rosterTable"
      keyField="id"
      bordered={false}
      bootstrap4
      data={data}
      columns={columns}
      noDataIndication={"No data"}
    />
  );
};

export default DecisionsTable;
