import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import BootstrapTable, {
  SelectRowProps,
  SortOrder,
} from "react-bootstrap-table-next";
import { Button, Col, Modal, Spinner } from "react-bootstrap";
import {
  getEvidenceAssessments,
  getEvidenceInDataPeriod,
  hideSearchForYourAssessmentModal,
  updateCsvFileRawData,
  uploadUndefinedAssessmentData,
} from "../../../../../store/onboarding/actions";
import LoadingIndicator from "../../LoadingIndicator";
import {
  DataPeriod,
  EvidenceAssessment,
  EvidencePeriodData,
  Student,
} from "../../../../../store/onboarding/types";
import Form from "react-bootstrap/Form";
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { TimePeriod } from "../../../../../store/groups/types";
import { addStudentMapToLocalStore } from "../../../../../utils/StudentMapUtils";

const columns = [
  {
    dataField: "id",
    text: "Assessment Id",
    hidden: true,
  },
  {
    dataField: "name",
    text: "Assessment",
    sort: true,
  },
  {
    dataField: "dataColumns",
    text: "DATA COLUMNS/SUBSCALES",
    sort: true,
  },
];

const defaultSorted: [{ dataField: string; order: SortOrder }] = [
  {
    dataField: "name",
    order: "asc",
  },
];

type PropsFromState = {
  columnsNames: Array<string>;
  showModal: boolean;
  evidenceAssessments: Array<EvidenceAssessment>;
  evidencePeriodData?: EvidencePeriodData;
  currentDataPeriod?: DataPeriod;
  rawData: any;
  studentIdColumn?: string;
  isLoading: {
    uploadUndefinedAssessmentData: boolean;
    getEvidenceAssessments: boolean;
  };
  errors: {
    uploadUndefinedAssessmentData?: string;
    getEvidenceAssessments?: string;
  };
};

type DispatchProps = {
  getEvidenceInDataPeriod: (dataPeriodId?: number) => any;
  getEvidenceAssessments: () => any;
  hideSearchForYourAssessmentModal: () => any;
  uploadUndefinedAssessmentData: (
    assessmentId: number,
    column_name_mappings: any,
    gradeColumn: string,
    timePeriod: string,
    assessmentUniqueIdColumn: string,
    customUniqueIdColumn: string,
    uploadUndefinedAssessmentData?: number,
    year?: number
  ) => any;
  updateRawDataOfCsvFile: (rawData: any) => any;
};

type Props = PropsFromState & DispatchProps;

type State = {
  assessmentsFilter: string;
  selectedAssessment?: EvidenceAssessment;
  evidenceAssessments: Array<EvidenceAssessment>;
  column_name_mappings: any;
  gradeColumn?: string;
  timePeriod?: string;
  year: string;
};

class SearchForYourAssessmentModal extends Component<Props, State> {
  state: Readonly<State> = {
    evidenceAssessments: [],
    assessmentsFilter: "",
    selectedAssessment: undefined,
    column_name_mappings: {},
    year: "2019",
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (
      prevProps.isLoading.getEvidenceAssessments &&
      !this.props.isLoading.getEvidenceAssessments &&
      !this.props.errors.getEvidenceAssessments
    ) {
      this.setState({ evidenceAssessments: this.props.evidenceAssessments });
    }

    if (
      prevProps.isLoading.uploadUndefinedAssessmentData &&
      !this.props.isLoading.uploadUndefinedAssessmentData
    ) {
      if (!this.props.errors.uploadUndefinedAssessmentData) {
        const { rawData, studentIdColumn, currentDataPeriod } = this.props;
        const assessment = this.state.selectedAssessment;
        if (assessment && studentIdColumn) {
          const studentMap = new Map<string, Student>(
            rawData.map((row: any) => [
              row[studentIdColumn],
              {
                first_name:
                  row[
                    this.state.column_name_mappings[
                      assessment.first_name_column
                    ]
                  ],
                last_name:
                  row[
                    this.state.column_name_mappings[assessment.last_name_column]
                  ],
              },
            ])
          );
          addStudentMapToLocalStore(studentMap);
        }
        this.props.hideSearchForYourAssessmentModal();
        this.props.getEvidenceInDataPeriod(
          currentDataPeriod ? currentDataPeriod.id : undefined
        );
      } else {
        toastr.error("Error", this.props.errors.uploadUndefinedAssessmentData);
      }
    }

    //MODAL OVERLAY CLASS HACKS

    // if (!prevProps.showModal && this.props.showModal) {
    //   document.body.style.overflow = "hidden";
    //   document.body.classList.add("modal-open");
    // }
  }

  handleAssessmentSelect = (row: any, isSelect: boolean) => {
    if (isSelect) {
      this.setState((prevState: State, prevProps: Props) => {
        const selectedAssessment = prevProps.evidenceAssessments.find(
          (x) => x.id === row.id
        );
        if (selectedAssessment)
          return {
            ...prevState,
            column_name_mappings: {
              [selectedAssessment.unique_id_column]: undefined,
              [selectedAssessment.first_name_column]: undefined,
              [selectedAssessment.last_name_column]: undefined,
              ...selectedAssessment.measurements
                .map((measurement) => measurement.column_name)
                .reduce((obj: any, item: any) => {
                  obj[item] = undefined;
                  return obj;
                }, {}),
            },
            selectedAssessment: selectedAssessment,
          };
        return prevState;
      });
    }
  };

  handleAssessmentsFilterChange = (event: React.ChangeEvent<any>) => {
    event.preventDefault();
    const { value } = event.target as HTMLInputElement;
    this.setState({
      assessmentsFilter: value,
      evidenceAssessments: this.props.evidenceAssessments.filter(
        (evidenceAssessment) =>
          evidenceAssessment.name
            .toLowerCase()
            .includes(value.trim().toLowerCase())
      ),
    });
  };

  handleUploadStudentData = (event: React.FormEvent<any>) => {
    const { selectedAssessment } = this.state;

    let target_evidence_column_group_id;
    if (this.props.evidencePeriodData) {
      const ecg = this.props.evidencePeriodData.evidence_column_groups.find(
        (ecg) => ecg.assessment && ecg.assessment.id === selectedAssessment!.id
      );
      target_evidence_column_group_id = ecg ? ecg.id : undefined;
    }
    event.preventDefault();
    this.props.uploadUndefinedAssessmentData(
      this.state.selectedAssessment!.id,
      this.state.column_name_mappings,
      this.state.gradeColumn!,
      this.state.timePeriod!,
      selectedAssessment!.unique_id_column,
      this.state.column_name_mappings[selectedAssessment!.unique_id_column],
      target_evidence_column_group_id,
      parseInt(this.state.year)
    );
  };

  handleModalShow = () => {
    this.props.getEvidenceAssessments();
    this.setState({
      assessmentsFilter: "",
      selectedAssessment: undefined,
    });
  };

  handleModalHide = () => {
    if (
      !this.props.isLoading.getEvidenceAssessments &&
      !this.props.isLoading.uploadUndefinedAssessmentData
    ) {
      this.props.hideSearchForYourAssessmentModal();
    }
  };

  handleChange = (event: React.FormEvent<any>) => {
    const { name, value } = event.target as HTMLInputElement;
    this.setState((prevState) => ({
      column_name_mappings: {
        ...prevState.column_name_mappings,
        [name]: value,
      },
    }));
  };

  handleGradeChange = (event: React.FormEvent<any>) => {
    const { value } = event.target as HTMLInputElement;
    this.setState((prevState) => ({
      ...prevState,
      gradeColumn: value,
    }));
  };

  handleYearChange = (event: React.FormEvent<any>) => {
    const { value } = event.target as HTMLInputElement;
    this.setState((prevState) => ({
      ...prevState,
      year: value,
    }));
  };

  handleTimePeriodChange = (event: React.FormEvent<any>) => {
    const { value } = event.target as HTMLInputElement;
    this.setState((prevState) => ({
      ...prevState,
      timePeriod: value,
    }));
  };

  getOptions = (columnName?: string, showNoValueLabel: boolean = false) => {
    const selectedValues = Object.values(
      this.state.column_name_mappings
    ).filter((x) => x !== this.state.column_name_mappings[columnName as any]);

    if (this.state.gradeColumn !== undefined && columnName !== "grade") {
      selectedValues.push(this.state.gradeColumn);
    }

    const options = this.props.columnsNames
      .filter((columnName) => !selectedValues.includes(columnName))
      .map((columnName) => (
        <option key={columnName} value={columnName}>
          {columnName}
        </option>
      ));

    const label = showNoValueLabel ? `No Data for ${columnName}` : "";
    return (
      <>
        <option key={-1} value={label} />
        {options}
      </>
    );
  };

  getYearOptions = () => {
    const start = 2000;
    const years = 50;

    const yearOptions: { value: string; label: string }[] = [];
    for (let i = 0; i < years; ++i) {
      const str = (start + i).toString();
      yearOptions.push({
        value: str,
        label: str,
      });
    }

    const options = yearOptions.map((year) => (
      <option key={year.value} value={year.value}>
        {year.label}
      </option>
    ));

    return (
      <>
        <option key={-1} value={""} />
        {options}
      </>
    );
  };

  getTimePeriodOptions = () => {
    const timePeriods = [
      { value: TimePeriod.Beginning, label: "Beginning" },
      { value: TimePeriod.Middle, label: "Middle" },
      { value: TimePeriod.End, label: "End" },
    ];

    const options = timePeriods.map((tp) => (
      <option key={tp.value} value={tp.value}>
        {tp.label}
      </option>
    ));

    return (
      <>
        <option key={-1} value={""} />
        {options}
      </>
    );
  };

  render() {
    const { selectedAssessment, evidenceAssessments } = this.state;
    const isLoading =
      this.props.isLoading.getEvidenceAssessments ||
      this.props.isLoading.uploadUndefinedAssessmentData;
    const isEmpty =
      evidenceAssessments.length === 0 &&
      !this.props.isLoading.getEvidenceAssessments;
    const selectRow: SelectRowProps<any> = {
      mode: "radio",
      clickToSelect: true,
      selected: selectedAssessment ? [selectedAssessment.id] : undefined,
      onSelect: this.handleAssessmentSelect,
    };

    return (
      //@ts-ignore
      <Modal
        animation={false}
        size="lg"
        backdropClassName="customDarkModalBackdrop in"
        show={this.props.showModal}
        //@ts-ignore
        onShow={this.handleModalShow}
        onHide={this.handleModalHide}
      >
        <Modal.Header closeButton className="purpleModalHeader">
          {selectedAssessment && (
            <Button
              onClick={() => this.setState({ selectedAssessment: undefined })}
            >
              <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
              <h5 className="d-inline font-weight-semibold">
                Back to List of Assessment
              </h5>
            </Button>
          )}
          <Modal.Title>Search for your assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedAssessment ? (
            <>
              <div>
                <h4 className="font-weight-bold">
                  Match the columns of your file with the assessment.
                </h4>
              </div>
              <Form
                id={"assessmentMappingForm"}
                onSubmit={this.handleUploadStudentData}
              >
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label className="font-weight-bold text-left">
                        {selectedAssessment.unique_id_column}
                      </Form.Label>
                      <Form.Control
                        name={selectedAssessment.unique_id_column}
                        as="select"
                        value={
                          this.state.column_name_mappings[
                            selectedAssessment.unique_id_column as any
                          ] || ""
                        }
                        onChange={this.handleChange}
                      >
                        {this.getOptions(
                          selectedAssessment.unique_id_column,
                          true
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label className="font-weight-bold text-left">
                        {selectedAssessment.first_name_column}
                      </Form.Label>
                      <Form.Control
                        required
                        name={selectedAssessment.first_name_column}
                        as="select"
                        value={
                          this.state.column_name_mappings[
                            selectedAssessment.first_name_column as any
                          ] || ""
                        }
                        onChange={this.handleChange}
                      >
                        {this.getOptions(selectedAssessment.first_name_column)}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label className="font-weight-bold text-left">
                        {selectedAssessment.last_name_column}
                      </Form.Label>
                      <Form.Control
                        name={selectedAssessment.last_name_column}
                        as="select"
                        value={
                          this.state.column_name_mappings[
                            selectedAssessment.last_name_column as any
                          ] || ""
                        }
                        onChange={this.handleChange}
                      >
                        {this.getOptions(
                          selectedAssessment.last_name_column,
                          true
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label className="font-weight-bold text-left">
                        Year
                      </Form.Label>
                      <Form.Control
                        required
                        name={"year"}
                        as="select"
                        value={this.state.year}
                        onChange={this.handleYearChange}
                      >
                        {this.getYearOptions()}
                      </Form.Control>
                    </Form.Group>
                  </Col>{" "}
                  <Col>
                    <Form.Group>
                      <Form.Label className="font-weight-bold text-left">
                        Grade
                      </Form.Label>
                      <Form.Control
                        required
                        name={"grade"}
                        as="select"
                        value={this.state.gradeColumn}
                        onChange={this.handleGradeChange}
                      >
                        {this.getOptions("grade")}
                      </Form.Control>
                    </Form.Group>
                  </Col>{" "}
                  <Col>
                    <Form.Group>
                      <Form.Label className="font-weight-bold text-left">
                        Time Period
                      </Form.Label>
                      <Form.Control
                        required
                        name={"time_period"}
                        as="select"
                        value={this.state.timePeriod}
                        onChange={this.handleTimePeriodChange}
                      >
                        {this.getTimePeriodOptions()}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Form.Row>
                {selectedAssessment.measurements.map((measurement) => (
                  <Form.Group key={measurement.id}>
                    <Form.Label className="font-weight-bold text-left">
                      {measurement.column_name}
                    </Form.Label>
                    <Form.Control
                      name={measurement.column_name}
                      as="select"
                      value={
                        this.state.column_name_mappings[
                          measurement.column_name as any
                        ] || ""
                      }
                      onChange={this.handleChange}
                    >
                      {this.getOptions(measurement.column_name)}
                    </Form.Control>
                  </Form.Group>
                ))}
              </Form>
              <hr className="narrowMargin" />
              <div className="btnActions">
                <div>&nbsp;</div>
                <div>
                  <button
                    disabled={isLoading || !selectedAssessment}
                    type="submit"
                    form="assessmentMappingForm"
                    className="blueBtnSm"
                  >
                    Save{" "}
                    {this.props.isLoading.uploadUndefinedAssessmentData && (
                      <Spinner animation="border" size="sm" />
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <h3 className="font-weight-bold">Select an assessment.</h3>
              <h3 className="font-weight-bold">
                If you need suggestions on assessment please <u>click here</u>.
              </h3>
              <div className="mb-3">
                <input
                  type="text"
                  placeholder="Search for Assessments..."
                  value={this.state.assessmentsFilter}
                  onChange={this.handleAssessmentsFilterChange}
                  className="stdInput"
                />
                <br />
              </div>
              <BootstrapTable
                wrapperClasses={
                  evidenceAssessments.length === 0 ? "rosterTableEmpty" : ""
                }
                classes="stdTable"
                keyField="id"
                bordered={false}
                bootstrap4
                columns={columns}
                data={evidenceAssessments.map((evidenceAssessment) => ({
                  id: evidenceAssessment.id,
                  name: evidenceAssessment.name,
                  dataColumns: evidenceAssessment.measurements
                    .map((measurement) => measurement.display_name)
                    .join(", "),
                }))}
                defaultSorted={defaultSorted}
                noDataIndication={
                  isEmpty ? "No matching assessments" : <LoadingIndicator />
                }
                selectRow={selectRow}
              />
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  const { loadableFile } = onboarding;
  const rawData = loadableFile ? loadableFile.rawData : undefined;
  return {
    columnsNames: onboarding.loadableFile
      ? onboarding.loadableFile.columnsNames
      : [],
    currentDataPeriod: onboarding.currentDataPeriod,
    showModal: onboarding.modalsState.searchForYourAssessmentModal,
    evidenceAssessments: onboarding.evidenceAssessments,
    evidencePeriodData: onboarding.evidencePeriodData,
    rawData: rawData,
    studentIdColumn: loadableFile
      ? loadableFile.generatedStudentIdColumnName
      : undefined,
    isLoading: {
      uploadUndefinedAssessmentData:
        onboarding.isLoading.uploadUndefinedAssessmentData,
      getEvidenceAssessments: onboarding.isLoading.getEvidenceAssessments,
    },
    errors: {
      uploadUndefinedAssessmentData:
        onboarding.errors.uploadUndefinedAssessmentData,
      getEvidenceAssessments: onboarding.errors.getEvidenceAssessments,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getEvidenceAssessments: getEvidenceAssessments,
      hideSearchForYourAssessmentModal: hideSearchForYourAssessmentModal,
      uploadUndefinedAssessmentData: uploadUndefinedAssessmentData,
      updateRawDataOfCsvFile: updateCsvFileRawData,
      getEvidenceInDataPeriod: getEvidenceInDataPeriod,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchForYourAssessmentModal);
