import React, { FunctionComponent, useMemo } from "react";
import {
  Attendance,
  InterventionGroup,
} from "../../../../../../../../../store/onboarding/cases/types";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { getFullName } from "../../../../../../../../../utils/NamesUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { deleteAttendance } from "../../../../../../../../../store/onboarding/cases/actions";
import { showConfirmDialog } from "../../../../../../../../../store/confirm-dialog/actions";
import { Student } from "../../../../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../../../../store";
import useInterventionGroupAccess from "../../../../../../../../../utils/hooks/useInterventionGroupAccess";

type OwnProps = {
  group: InterventionGroup;
  attendances: Attendance[];
  onEdit: (attendance: Attendance) => void;
  isStudentDetail?:boolean;
  isGroupDetail?: boolean;
};

type Props = OwnProps;

const AttendanceTable: FunctionComponent<Props> = (props) => {
  const { group, attendances, onEdit, isStudentDetail, isGroupDetail } = props;

  const dispatch = useDispatch();

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(group?.id);

  const onRemove = (attendanceId: number) => {
    if (group.id && attendanceId) {
      dispatch(deleteAttendance(group.id, attendanceId));
    }
  };

  const handleDelete = (attendanceId: number) => () => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => onRemove(attendanceId),
        centered: true,
      })
    );
  };

  let allStudent: Student[] = [];

  group.student_goals.map((goal) => {
    allStudent.push(goal.student);
  });

  return (
    <Table className="goalTable mb-0" bordered responsive>
      <thead>
        <tr>
          <th>Date</th>
          <th>Present</th>
          <th>Absent</th>
          <th>Interventions</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {attendances.map((attendance) => (
          <tr key={`attendance_${attendance.id}`}>
            <td>{moment(attendance.date).format("MM/DD/YYYY")}</td>
            <td>
              {attendance.present_students
                .map((ps) => getFullName(allStudent.find((s) => s.id === ps)))
                .join(", ")}
            </td>
            <td>
              {attendance.absent_students
                .map((as) => getFullName(allStudent.find((s) => s.id === as)))
                .join(", ")}
            </td>
            <td>
              {attendance.interventions
                .map(
                  (intId) => {
                    let groupIntName =  group.interventions.find((int) => int.id === intId)?.name
                    if(groupIntName) {
                      return groupIntName;
                    } else {
                      return group.archived_interventions.find((int) => int.id === intId)?.name
                    }
                  }
                )
                .join(", ")}
            </td>
            <td>
              {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess &&
                <div className="entry-actions d-flex align-items-center">
                  <span className="edit">
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => onEdit(attendance)}
                    />
                  </span>
                  <span className="delete" onClick={handleDelete(attendance.id)}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </span>
                </div>
              }
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default AttendanceTable;
