import React, { FunctionComponent, useMemo, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { ApplicationState } from "../../../../../../../store";
import { connect } from "react-redux";
import {
  CategoryBehavior,
  StudentEntry,
} from "../../../../../../../store/onboarding/types";
import BehaviorTag from "./BehaviorTag";
import useWindowSize, {
  WindowSize,
} from "../../../../../../../utils/hooks/useWindowSize";

type StateProps = {
  staticDataCategoryDomains: Array<CategoryBehavior>;
  customBehaviors: Array<CategoryBehavior>;
};

type OwnProps = {
  isReadonly?: boolean;
  studentEntry?: StudentEntry;
};

type Props = OwnProps & StateProps;

const DISPLAYED_BEHAVIOR_TAGS_NUM = 1;
const TRIM_STRING = "...";

const BehaviorTags: FunctionComponent<Props> = ({
  isReadonly,
  staticDataCategoryDomains,
  customBehaviors,
  studentEntry,
}) => {
  const moreTagNode = useRef<any>(null);
  const [showMoreTagsPopup, setShowMoreTagsPopup] = useState(false);

  const behaviors = useMemo(() => {
    return (studentEntry!.behavior_tags || []).map((behaviorTag) => {
      const behavior = [...staticDataCategoryDomains, ...customBehaviors].find(
        (b) => b.id === behaviorTag.id
      );
      return behavior ?? { name: "Unrecognized id" };
    });
  }, [studentEntry, staticDataCategoryDomains, customBehaviors]);

  const windowSize: WindowSize | undefined = useWindowSize();

  const customDisplayedBehaviorName = useMemo(() => {
    const displayedChrNum =
      windowSize === WindowSize.XLG
        ? behaviors.length > DISPLAYED_BEHAVIOR_TAGS_NUM
          ? 40
          : 52
        : behaviors.length > DISPLAYED_BEHAVIOR_TAGS_NUM
        ? 45
        : 55;
    if (behaviors[0].name.length > displayedChrNum) {
      return (
        behaviors[0].name.slice(0, displayedChrNum - TRIM_STRING.length) +
        TRIM_STRING
      );
    }
  }, [behaviors, windowSize]);

  return (
    <div className="behaviorsTitle">
      {behaviors[0] && (
        <span title={behaviors[0].name} style={{ overflowWrap: "anywhere" }}>
          <BehaviorTag
            isReadonly={isReadonly}
            customName={customDisplayedBehaviorName}
            selectedBehavior={behaviors[0]}
            studentEntry={studentEntry}
          />
        </span>
      )}

      {behaviors.length > DISPLAYED_BEHAVIOR_TAGS_NUM && (
        <>
          <strong
            style={{ display: "inline-block" }}
            className="ml-1 pointer"
            ref={moreTagNode}
            onClick={(e: any) => {
              e.stopPropagation();
              setShowMoreTagsPopup((flag) => !flag);
            }}
            // onMouseEnter={() => setShowMoreTagsPopup(true)}
          >
            {` and ${behaviors.length - DISPLAYED_BEHAVIOR_TAGS_NUM} more`}
          </strong>

          <Overlay
            target={moreTagNode && moreTagNode.current}
            show={showMoreTagsPopup}
            // placement={"bottom"}
            // rootClose
            // rootCloseEvent={"mousedown"}
            onHide={() => setShowMoreTagsPopup(false)}
          >
            <Popover
              id="teachersPopover"
              onMouseLeave={() => setShowMoreTagsPopup(false)}
            >
              <Popover.Content>
                {behaviors
                  .slice(DISPLAYED_BEHAVIOR_TAGS_NUM)
                  .map((b, index) => (
                    <React.Fragment key={index}>
                      <BehaviorTag
                        isReadonly={isReadonly}
                        selectedBehavior={b}
                        studentEntry={studentEntry}
                      />
                      {index !==
                        behaviors.length - DISPLAYED_BEHAVIOR_TAGS_NUM - 1 && (
                        <>{", "}</>
                      )}
                    </React.Fragment>
                  ))}
              </Popover.Content>
            </Popover>
          </Overlay>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    staticDataCategoryDomains: onboarding.staticDataCategoryDomains,
    customBehaviors: onboarding.customBehaviors,
  };
};

export default connect(mapStateToProps)(BehaviorTags);
