import React, { FunctionComponent } from "react";

type OwnProps = {
  onClose: () => any;
};

type Props = OwnProps;

const ModalCloseButton: FunctionComponent<Props> = ({ onClose }) => {
  return (
    <div>
      <button type="button" className="close" onClick={onClose}>
        <span aria-hidden="true">×</span>
        <span className="sr-only">Close</span>
      </button>
    </div>
  );
};

export default ModalCloseButton;
