export type TourState = {
  firstUploadTourShown?: boolean;
  sidebarPositionTourShown?: boolean;
  prepareMeetingTourShown?: boolean;
  implementMeetingTourShown?: boolean;
  interventionFidelityTourShown?: boolean;
  fidelityChartTourShown?: boolean;
  emptyDataPeriodViewTourShown?: boolean;
  selectedInterventionGroupId?: number;
  addSecondaryGoalPositionTourShown?: boolean;
};

export enum TourActionTypes {
  MARK_FIRST_UPLOAD_TOUR_AS_SHOWN = "@@tour/MARK_FIRST_UPLOAD_TOUR_AS_SHOWN",
  MARK_SIDEBAR_POSITION_TOUR_AS_SHOWN = "@@tour/MARK_SIDEBAR_POSITION_TOUR_AS_SHOWN",
  MARK_PREPARE_MEETING_TOUR_AS_SHOWN = "@@tour/MARK_PREPARE_MEETING_TOUR_AS_SHOWN",
  MARK_IMPLEMENT_MEETING_TOUR_AS_SHOWN = "@@tour/MARK_IMPLEMENT_MEETING_TOUR_AS_SHOWN",
  MARK_INTERVENTION_FIDELITY_TOUR_AS_SHOWN = "@@tour/MARK_INTERVENTION_FIDELITY_TOUR_AS_SHOWN",
  MARK_FIDELITY_CHART_TOUR_AS_SHOWN = "@@tour/MARK_FIDELITY_CHART_TOUR_AS_SHOWN",
  MARK_EMPTY_DATA_PERIOD_VIEW_TOUR_AS_SHOWN = "@@tour/MARK_EMPTY_DATA_PERIOD_VIEW_TOUR_AS_SHOWN",
  CHANGE_SELECTED_INTERVENTION_GROUP_ID = "@@tour/CHANGE_SELECTED_INTERVENTION_GROUP_ID",
  MARK_ADD_SECONDARY_GOAL_TOUR_AS_SHOWN = "@@tour/MARK_ADD_SECONDARY_GOAL_TOUR_AS_SHOWN",
}
