import React, { FunctionComponent, useMemo } from "react";
import { GoalAttainment } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import VerticalBarChart from "./VerticalBarChart";

type OwnProps = {
  label?: string;
  goalAttainment: Array<GoalAttainment>;
};

type Props = OwnProps;

const AverageGoalAttainmentBySchoolChart: FunctionComponent<Props> = (props) => {
  const { label, goalAttainment } = props;

  let uniqueSchools:any[] = [];

  for (let i = 0; i < goalAttainment.length; i++) {
    const element = goalAttainment[i];
    if(element!.coach!.profile!.current_assignment!.schools.length > 0) {
        for (let j = 0; j < element!.coach!.profile!.current_assignment!.schools.length; j++) {
            const ele = element!.coach!.profile!.current_assignment!.schools[j];
            if(!uniqueSchools.includes(ele.id)) {
                uniqueSchools.push(ele.id)
            }  
        }
    }
  }

  let goalCountsBySchool:any = {};
  for (let k = 0; k < uniqueSchools.length; k++) {
      let dataArray = [];
      for (let i = 0; i < goalAttainment.length; i++) {
        const element = goalAttainment[i];
        if(element!.coach!.profile!.current_assignment!.schools.length > 0) {
            for (let j = 0; j < element!.coach!.profile!.current_assignment!.schools.length; j++) {
                const ele = element!.coach!.profile!.current_assignment!.schools[j];
                if(uniqueSchools[k] == ele.id) {
                    dataArray.push(element.avg_attainment)
                    goalCountsBySchool[ele.name] = dataArray;
                }  
            }
        }
      }
  }

  // const labels = useMemo(
  //   () =>
  //     goalAttainment.map((goalAttainment) => getFullName(goalAttainment.coach)),
  //   [goalAttainment]
  // );
  // const data = useMemo(
  //   () =>
  //     goalAttainment.map(
  //       (goalAttainment) => +(goalAttainment.avg_attainment * 100).toFixed(0)
  //     ),
  //   [goalAttainment]
  // );

  let labels:any = [];
  let dataArr:any = [];
  
  for (const key in goalCountsBySchool) {
    labels.push(key);
    dataArr.push(goalCountsBySchool[key]);
  }

  const data = dataArr.map((data:any)=> ((data.reduce((sum:any, x:any) => sum + x)/data.length) * 100).toFixed(2).replace(/^0+|0+$/g, "").replace(/\.$/, ""))

  if (!data.length) {
    return null;
  }

  return (
    <div className="district-summary-chart-container">
      {label && <h3 className="text-center">{label}</h3>}
      <div>
        <VerticalBarChart labels={labels} data={data} />
      </div>
    </div>
  );
};

export default AverageGoalAttainmentBySchoolChart;
