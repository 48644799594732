import React, { FunctionComponent } from "react";

type OwnProps = {
  svgSize: number;
  text: string | number;
  className?: string;
};

type Props = OwnProps;

const SvgCircle: FunctionComponent<Props> = ({ svgSize, text, className }) => {
  return (
    <svg width={svgSize} height={svgSize} className={className}>
      <g>
        <circle cx={svgSize / 2} cy={svgSize / 2} r={svgSize / 2} />
        <text
          x={svgSize / 2}
          y={svgSize / 2}
          textAnchor="middle"
          dominantBaseline="central"
        >
          {text}
        </text>
      </g>
    </svg>
  );
};

export default SvgCircle;
