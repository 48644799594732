import React, { ChangeEvent, FunctionComponent, useState } from "react";
import LoadingIndicator from "../../common/onboarding/LoadingIndicator";
import { ChatThread } from "../../../store/chat/types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import ChatThreadName from "./chat-thread-name/ChatThreadName";

type OwnProps = {
  onNewChat: () => void;
};

type Props = OwnProps;

const ChatThreads: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const { onNewChat } = props;

  const [showArchived, setShowArchived] = useState<boolean>(false);

  const isLoadingGetChatThreads = useSelector<ApplicationState, boolean>(
    (s) => s.chat.isLoading.chatThreads
  );

  const chatThreads = useSelector<ApplicationState, ChatThread[]>(
    (s) => s.chat.chatThreads
  );

  return (
    <div
      className="dataContainer pl-0"
      style={{ width: "45%", marginRight: "20px" }}
    >
      <div className="spaceBetween">
        <span className="font-weight-bold ml-3">
          {intl.formatMessage({
            id: "app.chats.title",
          })}
        </span>
        {/* <div className="d-flex align-items-center">
          <input
            className="mr-1"
            type="checkbox"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setShowArchived(event.target.checked)
            }
            checked={showArchived}
          />
          <span className="smallerText">
            {intl.formatMessage({
              id: "app.chats.showArchived.label",
            })}
          </span>
        </div> */}
      </div>
      <hr className="mb-0" />
      <div className="chatThreads">
        {isLoadingGetChatThreads ? (
          <LoadingIndicator />
        ) : (
          chatThreads
            .filter((chatThread) => showArchived || !chatThread.is_archived)
            .map((chatThread) => (
              <ChatThreadName key={chatThread.id} chatThread={chatThread} />
            ))
        )}
      </div>
      <div style={{ paddingLeft: "20px" }}>
        <div className="blueBtnMd w-100" onClick={onNewChat}>
          {intl.formatMessage({
            id: "app.chats.newChatBtn.label",
          })}
        </div>
      </div>
    </div>
  );
};

export default ChatThreads;
