import React, { FunctionComponent } from "react";
import { Cell } from "fixed-data-table-2";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../store";
import ViewToggleCell from "./ViewToggleCell";

type OwnProps = {};

type Props = OwnProps;

const DataLoadingCell: FunctionComponent<Props> = (props) => {
  const show = useSelector(
    (s: ApplicationState) => s.onboarding.isLoading.getEvidenceInDatePeriod
  );

  return show ? (
    <Cell>
      <Spinner animation="border" />
    </Cell>
  ) : (
    <ViewToggleCell />
  );
};

export default DataLoadingCell;
