import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faDotCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Popover } from "react-bootstrap";

type Props = {
  title: string;
  description: string;
  isNotRadio?: boolean;
  className?: string;
  info?: {
    advantage: string;
    disadvantage: string;
  };
  value: number | boolean;
  selectedValue: number | boolean | null;
  onSelect: (value: any) => any;
};

const BoxButton = (props: Props) => {
  const active = props.selectedValue === props.value;
  return (
    <div
      className={"boxButton " + props.className}
      onClick={props.onSelect(props.value)}
    >
      {!props.isNotRadio && (
        <FontAwesomeIcon
          size="lg"
          icon={active ? faDotCircle : faCircle}
          style={active ? { color: "#00265B" } : undefined}
          inverse={active}
        />
      )}
      <div>
        <h3 className="font-weight-semibold mb-0">{props.title}</h3>
        <p className={"mb-0"}>{props.description}</p>
      </div>
      {props.info && (
        <OverlayTrigger
          placement={"bottom"}
          overlay={
            <Popover id={"info"}>
              <Popover.Content>
                <strong>Advantage:</strong> {props.info.advantage} <br />
                <strong>Disadvantage:</strong>
                {props.info.disadvantage}
              </Popover.Content>
            </Popover>
          }
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            size="sm"
            style={{ alignSelf: "start", margin: "-10px", marginLeft: "auto" }}
          />
        </OverlayTrigger>
      )}
    </div>
  );
};

export default BoxButton;
