import React, { useEffect, useState } from "react";
import {
  CasesState,
  FbaModalTabs,
} from "../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Nav, Tab } from "react-bootstrap";
import { setFbaModal } from "../../../../../store/onboarding/cases/actions";
import FbaSummaryTab from "./FbaSummaryTab";
import FbaChartTab from "./FbaChartTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faEye } from "@fortawesome/free-solid-svg-icons";
import { EventKey } from "react-bootstrap/esm/types";

const FbaModal = () => {
  const {
    selectedFba,
    modalsState: { fbaModal: showModal, fbaModalTab: initialTab },
  } = useSelector<ApplicationState, CasesState>((s) => s.cases);

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState<string | null>(FbaModalTabs.CHART);

  useEffect(() => {
    if (showModal && initialTab != null) {
      setActiveTab(initialTab);
    }
  }, [showModal, initialTab]);

  const onModalHide = () => {
    dispatch(setFbaModal(false, null));
  };

  if (!selectedFba) return null;

  return (
    <Modal
      show={showModal}
      size="lg"
      onHide={onModalHide}
      animation={false}
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>{`${selectedFba.student.first_name} / ${selectedFba.teacher.first_name} ${selectedFba.teacher.last_name}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container
          defaultActiveKey={FbaModalTabs.CHART}
          activeKey={activeTab as EventKey}
          onSelect={setActiveTab}
          unmountOnExit
        >
          <Nav variant="pills" className="justify-content-center text-center">
            {/* <Nav.Item>
              <Nav.Link eventKey={FbaModalTabs.SUMMARY}>
                <div>
                  <FontAwesomeIcon
                    icon={faEye}
                    size="lg"
                    style={{ color: "#00265B" }}
                  />
                  &nbsp;Summary
                </div>
              </Nav.Link>
            </Nav.Item> */}
            <Nav.Item>
              <Nav.Link eventKey={FbaModalTabs.CHART}>
                <div>
                  <FontAwesomeIcon
                    icon={faChartBar}
                    size="lg"
                    style={{ color: "#00265B" }}
                  />
                  &nbsp;Chart/Table
                </div>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            {/* <Tab.Pane eventKey={FbaModalTabs.SUMMARY}>
              <FbaSummaryTab />
            </Tab.Pane> */}
            <Tab.Pane eventKey={FbaModalTabs.CHART}>
              <FbaChartTab />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

export default FbaModal;
