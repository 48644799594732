import React, { CSSProperties } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

type OwnProps = {
  onClick?: any;
  value?: string;
  style?: CSSProperties;
};

type Props = OwnProps;

const CustomDateBoxInput = React.forwardRef(
  ({ value, onClick, style }: Props, ref: any) => {
    return (
      <div
        className="datePickerBoxBtn"
        onClick={onClick}
        style={style}
        ref={ref}
      >
        {value || ""} <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
      </div>
    );
  }
);

export default CustomDateBoxInput;
