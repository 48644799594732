import React, { FunctionComponent, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  openCoachTeacherRelationModal,
  openInviteTeachersModal,
  openShareDataModal,
} from "../../../../../store/onboarding/actions";
import { ApplicationState } from "../../../../../store";
import { DataSharingTableData } from "../../../../../store/onboarding/types";

type OwnProps = {};

type Props = OwnProps;

const SharingNavigationActions: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const sharingData = useSelector<ApplicationState, DataSharingTableData[]>(
    (s) => s.onboarding.dataSharingTableData
  );

  const numberOfImportedTeachers = useMemo(() => {
    const importedTeachers = sharingData.filter(
      (row) => row.relationship_status === 4
    );
    return importedTeachers.length;
  }, [sharingData]);

  const handleShare = () => {
    dispatch(openCoachTeacherRelationModal());
    //dispatch(openShareDataModal());
  };

  const handleInviteTeachers = () => {
    dispatch(openInviteTeachersModal());
  };

  return (
    <div className="rosterTabShareBtn">
      {!!numberOfImportedTeachers && (
        <div
          className="tableRowBtn mr-1"
          style={{ width: "260px", fontSize: '.8rem' }}
          onClick={handleInviteTeachers}
        >
          {numberOfImportedTeachers} Teachers Imported. Invite them now!
        </div>
      )}
      {!!sharingData.length && (
        <button className="blueBtnSm" onClick={handleShare}>
          SHARE
        </button>
      )}
    </div>
  );
};

export default SharingNavigationActions;
