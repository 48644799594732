import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDistrictAccessDataSettings,
  getSchools,
  getTeachers,
  openDistrictAccessDataSettingUpsertModal,
  updateSelectedUserData,
} from "../../../../../store/onboarding/actions";
import LoadingIndicator from "../../../../common/onboarding/LoadingIndicator";
import { ApplicationState } from "../../../../../store";
import DataAccessTable from "./DataAccessTable";
import DistrictAccessDataSettingUpsertModal from "./DistrictAccessDataSettingUpsertModal";
import { UserAssignmentRole, UserInfo } from "../../../../../store/auth/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import { DistrictAccessDataSetting, TeacherInfo } from "../../../../../store/onboarding/types";
import FilterPanel from "../your-staff/manage-users-page/FilterPanel";
import { Form, InputGroup } from "react-bootstrap";
import { changeSearchFilter } from "../../../../../store/onboarding/users-management/actions";

type OwnProps = {};

type Props = OwnProps;

const DataAccessPage: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState<any>([]);

  useEffect(() => {
    dispatch(getSchools());
    dispatch(getTeachers());
    dispatch(getDistrictAccessDataSettings());
    dispatch(changeSearchFilter(''));
  }, []);

  useEffect(() => {
    dispatch(updateSelectedUserData(selectedUsers))
  },[selectedUsers])

  const teachersRoster = useSelector<ApplicationState, TeacherInfo[]>(
    (s) => s.onboarding.teachersRoster
  );
  const districtAccessDataSettings = useSelector<
    ApplicationState,
    DistrictAccessDataSetting[]
  >((s) => s.onboarding.districtAccessDataSettings);

  const educators = useMemo(() => {
    return teachersRoster
      .filter(
        (t) =>
          t.user.profile.current_role === UserAssignmentRole.TEACHER ||
          t.user.profile.current_role === UserAssignmentRole.COACH
      )
      .map((t) => t.user);
  }, [teachersRoster]);


  const settings = useMemo(() => {
    return !educators.every((educator) => districtAccessDataSettings.find((s) => s.user === educator.id));
  }, [educators, districtAccessDataSettings]);

  const searchFilter = useSelector<ApplicationState, string>(
    (s) => s.usersManagement.searchFilter!
  );

  const handleSearchFilterChange = (event: React.FormEvent<any>) => {
    event.preventDefault();
    const { value } = event.target as HTMLInputElement;
    dispatch(changeSearchFilter(value));
  };

  const handleEdit = (
    educator: UserInfo,
    setting?: any
  ) => () => {
    dispatch(
      openDistrictAccessDataSettingUpsertModal(
        setting ? { ...(setting as any), user: educator } : { user: educator }
      )
    );
  };

  const loadingGetTeachers = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.onboarding.isLoading.getTeachers
  );

  const loadingGetDistrictAccessDataSettings = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.onboarding.isLoading.getDistrictAccessDataSettings);
  return (
    <div className="manageDistrictContainer">
      
      <div className="row">
        <div className="col-6"><h2 className="font-weight-bold mb-3">Manage Data Access</h2></div>
        <div className="col-6"><button
            className="blueBtnSm float-right"
            type="button"
            disabled={!selectedUsers.length}
            onClick={handleEdit(selectedUsers)}
          >Create/Update Data Access Settings</button></div>
      </div>
      <div className="row col-12">
        <div className="alert alert-warning py-1">{settings ? `Some users don’t have permissions set yet, please set the permissions by selecting the user(s) and clicking on CREATE/UPDATE DATA ACCESS SETTINGS button.`: ''} </div>
      </div>
      
      {loadingGetTeachers || loadingGetDistrictAccessDataSettings ? (
        <LoadingIndicator />
      ) : (
      <>
          <div className="row">
            <div className="col-8" />
            <div className="col-4">
              <InputGroup className="filterSearchDesktop">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  placeholder="Search..."
                  value={searchFilter as any}
                  onChange={handleSearchFilterChange}
                  className="filterSearchHeight"
                />
              </InputGroup>
            </div>
          </div>
        <DataAccessTable selectedUsers = {selectedUsers} setSelectedUsers = {setSelectedUsers}/>
      </>
      )}
      <DistrictAccessDataSettingUpsertModal isShowDisabled={false} />
    </div>
  );
};

export default DataAccessPage;
