import React, { Component, useMemo, useState } from "react";
import {
  Button,
  Col,
  InputGroup,
  Modal,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Student,
  TeacherClass,
  TeacherInfo,
} from "../../../../../store/onboarding/types";
import UploadStudentsRosterToClassModal from "../../../../../containers/onboarding/second-step/students/UploadStudentsRosterToClassModal";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import ModalCloseButton from "../../third-step/group-students/common/ModalCloseButton";
import { useLoading } from "../../../../../utils/hooks/useLoading";
import DistrictStudentsList from "./DistrictStudentsList";

type PropsFromState = {
  showModal: boolean;
  selectedTeacher: TeacherInfo;
  selectedClass?: TeacherClass;
  isLoading: {
    assignClassToTeacher: boolean;
    updateTeacherClass: boolean;
  };
  errors?: {
    assignClassToTeacher?: string;
    updateTeacherClass?: string;
  };
};

type DispatchProps = {
  onModalHide: () => void;
  assignClassToTeacher: (
    id: number,
    className: string,
    students: Array<Student>
  ) => any;
  updateTeacherClass: (teacherId: number, updatedClass: TeacherClass) => any;
  clearStoredCsvFile: () => any;
};

type Props = PropsFromState & DispatchProps;

const CreateClassModal: React.FC<Props> = (props) => {
  const emptyStudent: Student = useMemo(
    () => ({
      unique_id: "",
      first_name: "",
      last_name: "",
    }),
    []
  );

  const [student, setStudent] = useState<Student>(emptyStudent);

  const [showUploadStudentModal, setShowUploadStudentModal] = useState<boolean>(
    false
  );
  const [className, setClassName] = useState<string>("");
  const [students, setStudents] = useState<Student[]>([]);

  useLoading(
    props.isLoading.assignClassToTeacher,
    props.errors?.assignClassToTeacher,
    props.onModalHide
  );
  useLoading(
    props.isLoading.updateTeacherClass,
    props.errors?.updateTeacherClass,
    props.onModalHide
  );

  const handleStudentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStudent((student) => ({ ...student, [name]: value }));
  };

  const handleClassNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setClassName(value);
  };

  const handleOnEnter = () => {
    if (props.selectedClass) {
      setClassName(props.selectedClass.name);
      setStudents(props.selectedClass.students);
    } else {
      setClassName("");
      setStudents([]);
    }
    setStudent(emptyStudent);
  };

  const handleAddClass = (event: React.FormEvent<any>) => {
    event.preventDefault();
    const { id } = props.selectedTeacher.user;
    if (props.selectedClass) {
      props.updateTeacherClass(id, {
        name: className,
        students: students,
        id: props.selectedClass.id,
      });
    } else {
      props.assignClassToTeacher(id, className, students);
    }
  };

  const handleUploadStudentModalOnClose = () => {
    setShowUploadStudentModal(false);
    props.onModalHide();
    props.clearStoredCsvFile();
  };

  const handleModalClose = () => {
    if (
      !props.isLoading.assignClassToTeacher ||
      !props.isLoading.updateTeacherClass
    ) {
      props.onModalHide();
    }
  };

  const handleSelectOptionChange = (
    value: ValueType<Student, true>,
    action: any
  ) => {
    switch (action.action) {
      case "remove-value":
        setStudents((students) =>
          students.filter((student) => student !== action.removedValue)
        );
        break;
      case "clear":
        setStudents((students) => students.filter((student) => !student.id));
        break;
      default:
        break;
    }
  };

  const handleAddStudentRow = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStudents((students) => [...students, student]);
    setStudent(emptyStudent);
  };

  const { showModal, isLoading, onModalHide, selectedClass } = props;

  const title = useMemo(() => {
    if(props.selectedTeacher) {
      return selectedClass
        ? `Edit Class For ${props.selectedTeacher.user.first_name} ${props.selectedTeacher.user.last_name}`
        : `Add A Class For ${props.selectedTeacher.user.first_name} ${props.selectedTeacher.user.last_name}`;
    }
  }, [selectedClass, props.selectedTeacher]);

  return !showUploadStudentModal && props.selectedTeacher ? (
    <Modal
      animation={false}
      backdropClassName="customDarkModalBackdrop in"
      show={showModal}
      onHide={onModalHide}
      onShow={handleOnEnter}
      size="lg"
    >
      <Modal.Header
        className="purpleModalHeader orangeModalHeader centerModalHeader"
        onHide={handleModalClose}
      >
        <span className="pointer" onClick={onModalHide}>
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          <h5 className="d-inline font-weight-semibold">Back to Details</h5>
        </span>
        <Modal.Title>{title}</Modal.Title>
        <ModalCloseButton onClose={handleModalClose} />
      </Modal.Header>

      <Modal.Body>
        {selectedClass ? (
          <h3 className="font-weight-normal mb-3">
            You can change the name and add/remove students.
          </h3>
        ) : (
          <>
            <h2 className="font-weight-bold">
              Name the class and tell us which students are in it.
            </h2>
            <h3 className="font-weight-normal mb-3">
              You can add students manually or upload a roster CSV file.
            </h3>
          </>
        )}
        <Form id="createClass" onSubmit={handleAddClass}>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>Class Name</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              required
              name="className"
              value={className}
              onChange={handleClassNameChange}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faPencilAlt} />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form>

        <hr />

        <Select
          isSearchable={false}
          isMulti
          name="students"
          value={students}
          getOptionLabel={({ first_name, last_name }: Student) =>
            `${first_name} ${last_name}`
          }
          onChange={handleSelectOptionChange}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          noOptionsMessage={() => null}
          placeholder=""
        />

        <hr />

        <Tabs>
          <Tab eventKey={"new-students"} title={"Add New Students"}>
            <Form
              className="inviteContainer mt-1"
              id="new-student-form"
              onSubmit={handleAddStudentRow}
            >
              <Form.Row>
                <Col>
                  <Form.Control
                    required
                    placeholder="Enter first name..."
                    name="first_name"
                    onChange={handleStudentChange}
                    value={student.first_name}
                  />
                </Col>
                <Col>
                  <Form.Control
                    required
                    placeholder="Enter last name..."
                    name="last_name"
                    onChange={handleStudentChange}
                    value={student.last_name}
                  />
                </Col>
                <Col>
                  <Form.Control
                    required
                    placeholder="Enter Student ID number..."
                    name="unique_id"
                    onChange={handleStudentChange}
                    value={student.unique_id}
                  />
                </Col>
                <button
                  className="blueBtnSm"
                  type="submit"
                  form="new-student-form"
                >
                  Add
                </button>
              </Form.Row>
            </Form>
          </Tab>
          <Tab
            eventKey={"existing-students"}
            title={"Select Existing Students"}
          >
            <DistrictStudentsList
              selectedStudents={students}
              onStudentAdd={(student) => {
                setStudents((students) => [...students, student]);
              }}
            />
          </Tab>
        </Tabs>
        <hr />
        <div className="modalActions" style={{ marginTop: "20px" }}>
          <div className="leftActions">
            <button
              disabled={
                isLoading.assignClassToTeacher || isLoading.updateTeacherClass
              }
              onClick={() => setShowUploadStudentModal(true)}
              className="blueBtnSm"
            >
              Upload student roster
            </button>
          </div>
          <div className="rightActions">
            <button type="submit" form="createClass" className="blueBtnSm">
              Save{" "}
              {isLoading.assignClassToTeacher && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  ) : (
    <UploadStudentsRosterToClassModal
      isClass
      className={className}
      selectedClass={selectedClass}
      onHide={handleUploadStudentModalOnClose}
      show={showUploadStudentModal}
    />
  );
};

export default CreateClassModal;
