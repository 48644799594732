import React, { FunctionComponent, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { changeUserStatus } from "../../../../../../store/onboarding/actions";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";

type OwnProps = {
  className?: string;
  isActive?: boolean;
  userId: number;
};

type Props = OwnProps;

const UserStatusButton: FunctionComponent<Props> = (props) => {
  const { isActive, userId, className } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  return isActive ? null : (
    <div className={className ?? "ml-2"}>
      {loading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <OverlayTrigger
          overlay={
            <Tooltip id="change-user-status-btn" className="customInfoTooltip">
              Change user status to Active
            </Tooltip>
          }
        >
          <FontAwesomeIcon
            icon={faUserCheck}
            size="lg"
            onClick={(e) => {
              e.stopPropagation();
              setLoading(true);
              dispatch<any>(changeUserStatus(userId, true)).then(
                () => {
                  setLoading(false);
                },
                (error: string) => {
                  toastr.error("Error", error);
                  setLoading(false);
                }
              );
            }}
          />
        </OverlayTrigger>
      )}
    </div>
  );
};

export default UserStatusButton;
