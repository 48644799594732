import React from "react";
import { Cell } from "fixed-data-table-2";
import { Student } from "../../../../../../../store/onboarding/types";
import { IS_READY_COACH } from "../../../../../../../constants";

type OwnProps = {
  onClick: () => any;
  selectedStudents: Array<Student>;
};

type Props = OwnProps;

const CheckStudentsHeaderCell: React.FunctionComponent<Props> = (props) => {
  const hasSelectedStudents = props.selectedStudents.length > 0;
  return (
    <Cell className="group-btn">
      <button
        title="Check to add to group"
        className="blueBtnSm"
        onClick={props.onClick}
        style={{ margin: "auto" }}
        disabled={!hasSelectedStudents}
      >
        {IS_READY_COACH
          ? "Group selected"
          : hasSelectedStudents
          ? `Add ${props.selectedStudents.length} students to group`
          : "Check to add to group"}
      </button>
    </Cell>
  );
};

export default CheckStudentsHeaderCell;
