import React, { FunctionComponent } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Intervention } from "../../../../../../../../store/onboarding/cases/types";
import { faListOl } from "@fortawesome/free-solid-svg-icons";

type OwnProps = {
  selectedIntervention?: Intervention;
  onClickNext: () => void;
};

type Props = OwnProps;

const ModifyInterventionInfo: FunctionComponent<Props> = ({
  selectedIntervention,
  onClickNext,
}) => {
  return (
    <Modal.Body>
      <div className="text-center">
        <FontAwesomeIcon icon={faListOl} size="5x" />
        <br />
        <br />
        <h2 className="purpleText">
          <strong>{selectedIntervention?.name}</strong>
        </h2>
        <h3 className="purpleText">
          We will guide you through the process of customizing this intervention
          as you see fit.
        </h3>
        <br />
        <h4>You can modify steps.</h4>
        <h4>
          You can include your tips, information and resources as Word docs or
          PDFs.
        </h4>
        <h4>We store it for your use later.</h4>
        <button className="blueBtnMd mt-3" onClick={onClickNext}>
          Let's get started
        </button>
      </div>
    </Modal.Body>
  );
};

export default ModifyInterventionInfo;
