import React, { FunctionComponent } from "react";
import BehaviorsTable from "./BehaviorsTable";
import SetGoalDeadlinePanel from "../../SetGoalDeadlinePanel";
import { Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../../../store";
import { connect } from "react-redux";
import { StudentGoal } from "../../../../../../../store/onboarding/cases/types";

type StateProps = {
  isLoading: {
    createStudentGoalForInterventionGroup: boolean;
    createStudentGoalForStudent: boolean;
  };
};

type OwnProps = {
  studentGoal?: StudentGoal;
  targetDate?: Date;
  setTargetDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  onBackClick: () => any;
  handleGoalSet: () => any;
};

type Props = OwnProps & StateProps;

const RcProgressSetGoal: FunctionComponent<Props> = ({
  studentGoal,
  targetDate,
  setTargetDate,
  onBackClick,
  handleGoalSet,
  isLoading: {
    createStudentGoalForInterventionGroup: loadingCreateStudentGoalForInterventionGroup,
    createStudentGoalForStudent: loadingCreateStudentGoalForStudent,
  },
}) => {
  return (
    <div>
      <h3 className="font-weight-bold mb-3">
        Here is what you plan to focus on. All we need to know is when you want
        to achieve your goal.
      </h3>

      <BehaviorsTable studentGoal={studentGoal} />

      <SetGoalDeadlinePanel
        targetDate={targetDate}
        setTargetDate={(d) => setTargetDate(d)}
      />

      <hr />
      <div className="btnActions">
        <button className="blueBtnSm" onClick={onBackClick}>
          Back
        </button>
        <button
          className="blueBtnSm"
          disabled={!targetDate}
          onClick={handleGoalSet}
        >
          {studentGoal?.id ? "Update" : "Set"} goal{" "}
          {(loadingCreateStudentGoalForInterventionGroup ||
            loadingCreateStudentGoalForStudent) && (
            <Spinner animation="border" size="sm" />
          )}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ cases }: ApplicationState): StateProps => {
  return {
    isLoading: {
      createStudentGoalForInterventionGroup:
        cases.isLoading.createStudentGoalForInterventionGroup,
      createStudentGoalForStudent: cases.isLoading.createStudentGoalForStudent,
    },
  };
};

export default connect(mapStateToProps)(RcProgressSetGoal);
