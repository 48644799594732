import React, { FunctionComponent } from "react";
import {
  openTeacherInviteIndividuallyModal,
  openUploadTeachersRosterModal,
} from "../../../../../store/onboarding/actions";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { BasicUserInfo } from "../../../../../store/onboarding/types";

type DispatchProps = {
  openUploadTeachersRosterModal: () => any;
  openTeacherInviteIndividuallyModal: (newTeacher?: BasicUserInfo) => any;
};
type OwnProps = {};

type Props = OwnProps & DispatchProps;

const NoStaffForm: FunctionComponent<Props> = ({
  openUploadTeachersRosterModal,
  openTeacherInviteIndividuallyModal,
}) => {
  return (
    <div className="noStaffContainer">
      <h3 className="font-weight-bold mb-4">Your Staff</h3>
      <div>
        <h1 className="font-weight-bold d-inline">
          You haven't invited any of your staff yet
        </h1>
        <h3 className="my-4">
          Invite your Coaches and Educators to get started. <br />
          You can invite a user one at time, or upload a roster with multiple
          users' information.
        </h3>
        <br />
        <button
          className="blueBtnXL"
          onClick={() => openTeacherInviteIndividuallyModal()}
        >
          Invite Individually
        </button>
        &nbsp;
        <button
          className="blueBtnXL"
          onClick={() => openUploadTeachersRosterModal()}
        >
          Upload roster
        </button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openUploadTeachersRosterModal: openUploadTeachersRosterModal,
      openTeacherInviteIndividuallyModal: openTeacherInviteIndividuallyModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(NoStaffForm);
