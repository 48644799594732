import React, { FunctionComponent, useMemo } from "react";
import { DistrictSummary } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import VerticalBarChart from "./VerticalBarChart";

type OwnProps = {
  label?: string;
  goalsWithLoggedProgress: DistrictSummary;
};

type Props = OwnProps;

const GoalsWithLoggedProgressChart: FunctionComponent<Props> = (props) => {
  const { label, goalsWithLoggedProgress } = props;
  let goalsArray: any[] = [];
  let uniqueCoachArray:any[] = [];
  goalsWithLoggedProgress.goals.map((element) => {
    if(element.coach.profile.current_role == 'coach') {
      if(!uniqueCoachArray.includes(element.coach.id)){
        uniqueCoachArray.push(element.coach.id)
      }
    }
  });
  for (let i = 0; i < uniqueCoachArray.length; i++) {
    let goalCount = 0;
    const uniqueElement = uniqueCoachArray[i];
    goalsWithLoggedProgress.goals.map((element) => {
        if((element.coach.profile.current_role == 'coach') && (uniqueElement == element.coach.id)) {
          goalsArray[element.coach.id] = {
            coach: element.coach,
            goals: ++goalCount,
            goalPersentage: 0,
          }
          return goalsArray;
        }
    })
    
  }

  
  if(goalsArray.length > 0) {
    Object.keys(goalsArray).forEach((coachID)=> {
      goalsWithLoggedProgress.goal_progress.map((goalProgress) => {
        if(goalProgress.coach.id == Number(coachID)) {
           goalsArray[goalProgress.coach.id].goalPersentage = 
          ((goalProgress.unique_goal_count / goalsArray[goalProgress.coach.id].goals) * 100).toFixed(2) ;
        } 
      });
    })
  }
  
  
  let groupArray:any[] = []
  goalsArray.map((data) => {
    let dataObj = {
      coach: data.coach,
      goal_persentage: data.goalPersentage
    }
    groupArray.push(dataObj);
  })

  const labels = useMemo(
    () => groupArray.map((group) => getFullName(group.coach)),
    [groupArray]
  );
  const data = useMemo(
    () => groupArray.map((group) => +(group.goal_persentage)),
    [groupArray]
  );

  if (!data.length) {
    return null;
  }

  return (
    <div className="district-summary-chart-container">
      {label && <h3 className="text-center">{label}</h3>}
      <div>
        <VerticalBarChart labels={labels} data={data} />
      </div>
    </div>
  );
};

export default GoalsWithLoggedProgressChart;
