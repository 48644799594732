import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import {
  ConcernType,
  InterventionGroup,
} from "../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  getInterventions,
  hideFindInterventionModal,
  openInterventionLibraryModal,
} from "../../../../../store/onboarding/cases/actions";
import { connect } from "react-redux";
import InterventionGroupTooltip from "./common/InterventionGroupTooltip";

type PropsFromState = {
  interventionGroup: InterventionGroup;
  showModal: boolean;
  isLoading: {
    getInterventions: boolean;
  };
  errors: {
    getInterventions?: string;
  };
};
type DispatchProps = {
  hideFindInterventionModal: () => any;
  getInterventions: (concernType?: ConcernType) => any;
  openInterventionLibraryModal: () => any;
};

type Props = PropsFromState & DispatchProps;

type State = {
  selectedConcernType?: ConcernType;
};

class FindInterventionModal extends React.Component<Props, State> {
  state: Readonly<State> = {
    selectedConcernType: undefined,
  };

  handleHide = () => {
    this.props.hideFindInterventionModal();
  };

  handleNext = () => {
    this.props.getInterventions(this.state.selectedConcernType);
    this.props.openInterventionLibraryModal();
  };

  handleSelectedBehaviorTypeChange = (concernType: ConcernType) => () => {
    this.setState({ selectedConcernType: concernType });
  };

  render() {
    const { showModal, interventionGroup } = this.props;
    const { selectedConcernType } = this.state;
    if (!interventionGroup) return null;
    return (
      <Modal
        onHide={this.handleHide}
        animation={false}
        backdropClassName="customDarkModalBackdrop in"
        show={showModal}
        size="lg"
      >
        <Modal.Header
          closeButton
          className="purpleModalHeader"
          style={{ borderBottom: 0 }}
        >
          <InterventionGroupTooltip interventionGroup={interventionGroup} />
          <Modal.Title>
            Find intervention for {interventionGroup.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className="mb-4 text-center purpleText">One last question.</h2>
          <hr />
          <h2 className="my-4 text-center font-weight-bold">
            Is this a behavior that still needs to be learned, or a behavior
            that just needs improvement?
          </h2>
          <div className="d-flex flex-column align-items-center justify-content-between">
            <button
              className={
                selectedConcernType === ConcernType.NEW
                  ? "purpleSolidBtnMd"
                  : "purpleRegularBtnMd"
              }
              onClick={this.handleSelectedBehaviorTypeChange(ConcernType.NEW)}
            >
              It needs to be learned
            </button>
            <button
              className={
                selectedConcernType === ConcernType.EXISTING
                  ? "purpleSolidBtnMd"
                  : "purpleRegularBtnMd"
              }
              onClick={this.handleSelectedBehaviorTypeChange(
                ConcernType.EXISTING
              )}
            >
              It needs improvement
            </button>
            <button
              className={
                selectedConcernType === ConcernType.BOTH
                  ? "purpleSolidBtnMd"
                  : "purpleRegularBtnMd"
              }
              onClick={this.handleSelectedBehaviorTypeChange(ConcernType.BOTH)}
            >
              I'm not really sure
            </button>
          </div>
          <hr />
          <div className="btnActions">
            <div className="blueBtnSm" onClick={this.handleHide}>
              Back
            </div>
            <button
              className="blueBtnSm"
              onClick={this.handleNext}
              disabled={selectedConcernType === undefined}
            >
              Next{" "}
              {this.props.isLoading.getInterventions && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({ cases }: ApplicationState): PropsFromState => {
  return {
    interventionGroup: cases.selectedInterventionGroup!,
    showModal: cases.modalsState.findInterventionModal,
    isLoading: {
      getInterventions: cases.isLoading.getInterventions,
    },
    errors: {
      getInterventions: cases.errors.getInterventions,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideFindInterventionModal: hideFindInterventionModal,
      getInterventions: getInterventions,
      openInterventionLibraryModal: openInterventionLibraryModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FindInterventionModal);
