import React, { FunctionComponent } from "react";
import UserAvatarSmall from "../../../../../common/UserAvatarSmall";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../../store";
import { connect } from "react-redux";

type StateProps = {
  interventionGroup?: InterventionGroup;
};

type Props = StateProps;

const GroupInfoModalTitle: FunctionComponent<Props> = ({
  interventionGroup,
}) => {
  if (!interventionGroup) return null;
  return (
    <div className="groupInfoModalTitle">
      <UserAvatarSmall userInfo={interventionGroup.teacher_assignment!.user} />
      <div>
        <h4 className="font-weight-semibold">
          {interventionGroup!.teacher_assignment!.user.first_name}
        </h4>
      </div>
    </div>
  );
};

const mapStateToProps = ({ cases }: ApplicationState): StateProps => {
  return {
    interventionGroup: cases.selectedInterventionGroup,
  };
};

export default React.memo(connect(mapStateToProps)(GroupInfoModalTitle));
