import {
  InterventionGroup,
  InterventionPlan,
  InterventionPlanStates,
} from "../../../../../../../store/onboarding/cases/types";
import { useMemo } from "react";
import { checkedInterventionPlan } from "../../../../../../utils";
import { ImplementationCheck } from "../../../../../../../store/onboarding/meeting-module/types";

const useInterventionPlan = (
  interventionGroup?: InterventionGroup,
  onlyActive?: boolean
) => {
  const interventionPlan: InterventionPlan = useMemo(() => {
    if (interventionGroup?.interventions) {
      return checkedInterventionPlan(
        interventionGroup.interventions,
        interventionGroup?.intervention_plan
      );
    } else {
      return {
        plan_items: [],
      };
    }
  }, [interventionGroup?.intervention_plan, interventionGroup?.interventions]);

  const activeInterventionIds: number[] = useMemo(() => {
    return (interventionPlan.plan_items || []).reduce(
      (pV, cV) =>
        cV.state === InterventionPlanStates.ACTIVE
          ? [cV.intervention_id, ...pV]
          : pV,
      [] as number[]
    );
  }, [interventionPlan]);

  const filteredImplementationChecks = useMemo(() => {
    if (!onlyActive) {
      return interventionGroup?.progress_overview?.implementation_checks ?? [];
    }

    return (
      interventionGroup?.progress_overview?.implementation_checks.filter(
        (ic: ImplementationCheck) =>
          activeInterventionIds.includes(ic.intervention)
      ) ?? []
    );
  }, [
    interventionGroup?.progress_overview?.implementation_checks,
    activeInterventionIds,
    onlyActive,
  ]);

  return {
    implementationChecks: filteredImplementationChecks,
  };
};

export default useInterventionPlan;
