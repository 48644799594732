import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ApplicationState } from "../../../../../../../store";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getEvidenceAssessments, openAddAssessmentModal, resetRedirectToSetGoal } from "../../../../../../../store/onboarding/actions";
import {
  EvidenceAssessment,
  EvidencePeriodData,
  EvidenceType,
  Measurement,
} from "../../../../../../../store/onboarding/types";
import LoadingIndicator from "../../../../LoadingIndicator";
import {
  InterventionGroup,
  StudentGoal,
} from "../../../../../../../store/onboarding/cases/types";
import EvidenceTable from "../../../../second-step/evidence-tab/EvidenceTable";
import App from "../../../../../../../App";
import _ from "lodash";
import { showConfirmDialog } from "../../../../../../../store/confirm-dialog/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { UserAssignmentRole } from "../../../../../../../store/auth/types";

type PropsFromState = {
  studentGoal?: StudentGoal;
  evidenceAssessments: Array<EvidenceAssessment>;
  evidenceAssessment?: EvidenceAssessment;
  isLoading: {
    getEvidenceAssessments: boolean;
  };
  isRedirectToSetGoal?: boolean;
  interventionGroup: InterventionGroup;
  setMissedGoal?: boolean;
};
type DispatchProps = {
  getEvidenceAssessments: () => any;
  openAddAssessmentModal: (isNewProgressMonitoring?: boolean) => any;
  resetRedirectToSetGoal: () => any;
};
type OwnProps = {
  hasBehavioralGoalType?: boolean;
  onBackClick: () => any;
  onNextClick: (selectedMeasurement: Measurement) => any;
  isDiffMeasurement?: boolean;
  setIsDiffMeasurement?: (isDiffMeasurement: boolean) => any
  isStudentGoalExist?: boolean;
  selectedBrsMeasurement?: Measurement;
};
type Props = OwnProps & PropsFromState & DispatchProps;

const BRSInput: FunctionComponent<Props> = (props) => {
  const { 
    evidenceAssessments, 
    hasBehavioralGoalType, 
    setIsDiffMeasurement, 
    isStudentGoalExist,
    isRedirectToSetGoal, 
    evidenceAssessment,
    selectedBrsMeasurement,
    interventionGroup,
    setMissedGoal
  } = props;
  
  const dispatch = useDispatch();
  const [assessmentsFilter, setAssessmentsFilter] = useState<string>("");

  const selectedInterventionGroup = useSelector<
    ApplicationState,
    InterventionGroup | undefined
  >((s) => s.cases.selectedInterventionGroup);

  const [selectedMeasurement, setSelectedMeasurement] = useState<
    Measurement | undefined
  >(undefined);

  useEffect(() => {
    props.getEvidenceAssessments();
  }, []);

  const measurements: Array<Measurement> = useMemo(
    () =>
      props.evidenceAssessments.reduce(
        (pV, cV) => [...pV, ...cV.measurements],
        [] as Array<Measurement>
      ),
    [props.evidenceAssessments]
  );

  const existingGoalofStudent = useMemo(() => {
    return interventionGroup.student_goals
    .filter((sg) => !sg.archived && (sg.student.id == props.studentGoal?.student.id))
    .map((sg) => sg.measurement.id);
  },[props.studentGoal])

  useEffect(() => {
    if(selectedBrsMeasurement) {
      setSelectedMeasurement(selectedBrsMeasurement);
    } else if (props.studentGoal?.id) {
      setSelectedMeasurement(
        measurements.find((m) => m.id === props.studentGoal!.measurement.id)
      );
    }
  }, [props.studentGoal, measurements, selectedBrsMeasurement]);


  useEffect(() => {
    if(isRedirectToSetGoal && evidenceAssessment?.measurements?.length) {
      props.onNextClick(evidenceAssessment?.measurements[0]);
      handleBarsAssessmentsSelect(evidenceAssessment?.measurements[0])
      props.resetRedirectToSetGoal();
    }
  },[isRedirectToSetGoal, evidenceAssessment])

  const handleBarsAssessmentsSelect = (measurement: Measurement) => () => {
     let isDifferMeasureExist:any = false;
     if(props.studentGoal && props.studentGoal!.measurement)  {
       isDifferMeasureExist = props.studentGoal!.measurement?.id !== measurement.id;
      } else {
        isDifferMeasureExist = selectedInterventionGroup?.student_goals
        .filter((stdGoal) => !stdGoal.archived)
        .filter((stdGoal) => stdGoal.is_primary_goal)
        .some((sg) => sg.measurement.id != measurement.id);
      }
      const singleStdWithGoal = 
        selectedInterventionGroup?.students
            .filter((std) => selectedInterventionGroup?.student_goals
                                .some((sg) => (std.id == sg.student.id) && !sg.archived));
  
      if(singleStdWithGoal!.length == 1) {
        if(!selectedInterventionGroup?.finished) {
          isDifferMeasureExist = false
          setIsDiffMeasurement!(false);
        }
      }


    if(isDifferMeasureExist && props.studentGoal?.is_primary_goal) {
      dispatch(
        showConfirmDialog({
          onConfirm: () => {
            setIsDiffMeasurement!(true);
            setSelectedMeasurement(measurement);
            props.onNextClick(measurement!)
          },
          centered: true,
          title: "Confirmation",
          text: (
            <div className="text-left">
            {props.studentGoal && props.studentGoal!.id ? 
              <p>{`Changing the measure for the primary goal will:`}</p>
              :
              <p>{`Adding a different measurement tool than other students in the group will:`}</p>
            }
             <ul>
              {selectedInterventionGroup?.finished ? <li>Archive the group</li> : ''}
              {!selectedInterventionGroup?.finished ? <li>Discard and move group to Recommendations.</li> : ''}
              <li>Create a new group with all students from this group. Student goals <strong>do not</strong> move to the new group</li>
            </ul>
              <p className="mb-0">Click CONFIRM to create new group and set goal(s). </p>
            </div>
          ),
          confirmButtonText: "Confirm",
          size: 'lg'
        })
      );
    } else {
      setSelectedMeasurement(measurement);
    }
  };

  const evidenceData = useSelector<
    ApplicationState,
    EvidencePeriodData | undefined
  >((s) => s.onboarding.evidencePeriodData);

  const assessmentsMeasurementsIdsForGroup = useMemo(() => {
    if (evidenceData && selectedInterventionGroup) {
      return evidenceData.evidence_column_groups
        .filter(
          (ecg) =>
            ecg.assessment &&
            ecg.evidence_columns.some((ec) =>
              ec.student_entries.some((se) =>
                selectedInterventionGroup.students.some(
                  (s) => s.id === se.student_id
                )
              )
            )
        )
        .map((ecg) => ({
          assessmentId: ecg.assessment.id,
          measurementsIds: ecg.evidence_columns
            .filter((ec) => ec.evidence_type != EvidenceType.BehaviorScreener)
            .reduce<number[]>((pV, ec) => {
            return ec.student_entries.some((se) =>
              selectedInterventionGroup.students.some(
                (s) => s.id === se.student_id
              )
            )
              ? [...pV, ec.measurement.id!]
              : pV;
          }, []),
        }))
        .reverse();
    }

    return [];
  }, [selectedInterventionGroup, evidenceData]);

  const compareAssessments = useCallback(
    (a: EvidenceAssessment, b: EvidenceAssessment) => {
      const aIndex = assessmentsMeasurementsIdsForGroup.findIndex(
        (predicate) => predicate.assessmentId === a.id
      );
      const bIndex = assessmentsMeasurementsIdsForGroup.findIndex(
        (predicate) => predicate.assessmentId === b.id
      );

      if (aIndex === -1 && bIndex === -1) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      } else {
        return bIndex - aIndex;
      }
    },
    [assessmentsMeasurementsIdsForGroup]
  );

  const compareMeasurement = useCallback(
    (ids: number[]) => (a: Measurement, b: Measurement) => {
      const aIndex = ids.indexOf(a.id!);
      const bIndex = ids.indexOf(b.id!);

      if (aIndex === -1 && bIndex === -1) {
        if (a.display_name > b.display_name) {
          return 1;
        }
        if (a.display_name < b.display_name) {
          return -1;
        }
        return 0;
      } else {
        return bIndex - aIndex;
      }
    },
    []
  );

  const handleAssessmentsFilterChange = (event: React.ChangeEvent<any>) => {
    event.preventDefault();
    const { value } = event.target as HTMLInputElement;
    setAssessmentsFilter(value.toLowerCase());
  };

  const sortedEvidenceAssessments = useMemo(() => {
    return evidenceAssessments
      .sort(compareAssessments)
      .filter((ea) => ea.measurements?.length)
      .map((ea) => {
        const ids =
          assessmentsMeasurementsIdsForGroup
            .find((a) => a.assessmentId === ea.id)
            ?.measurementsIds.slice()
            .reverse() ?? [];

        const sorted = ea.measurements.sort(compareMeasurement(ids));

        return {
          ...ea,
          measurements: sorted,
        };
      });
  }, [
    evidenceAssessments,
    assessmentsMeasurementsIdsForGroup,
    compareMeasurement,
  ]);


  return (
    <>
      <h2 className="mb-0 font-weight-bold">
        {hasBehavioralGoalType ? "Behavior Rating Scales" : "Assessments"}
      </h2>
      <h4 className="mb-2">
        Select a {hasBehavioralGoalType && "Behavior Rating Scale"} assessment
        from the list of recommended assessments below. Each of these
        assessments is backed by evidence that shows they are effective measures
        of your selected target behavior(s)/skill(s).
      </h4>

      {selectedInterventionGroup && (
        <div className="small-evidence-table-container mb-2">
          <EvidenceTable
            interventionGroup={selectedInterventionGroup}
            isReadonly
            minimal
            fromSetGoalModal
          />
        </div>
      )}
      <div className="row my-2">
        <div className="col-9">
          <h4 data-cy="selected-assessment">
            <strong>Selected Assessment:</strong>{" "}
            {selectedMeasurement ? (
              selectedMeasurement.display_name
            ) : (
              <i data-cy="none">None selected</i>
            )}
          </h4>
        </div>
        <div className="col-3">
          <input
            type="text"
            placeholder="Search for Assessments..."
            value={assessmentsFilter}
            onChange={handleAssessmentsFilterChange}
            className="stdInput"
          />
        </div>
      </div>
      <div style={{ height: 300, overflowY: "auto" }}>
        <table data-cy="assessments-table" className="BRSTempTable">
          <thead>
            <tr>
              <th>Assessment</th>
              <th>Measurement</th>
              <th>Created By</th>
            </tr>
          </thead>
          <tbody>
            {props.isLoading.getEvidenceAssessments ? (
              <tr>
                <td colSpan={3}>
                  <LoadingIndicator />
                </td>
              </tr>
            ) : (
              sortedEvidenceAssessments
              .filter(
                (evidenceAssessment) =>
                  evidenceAssessment.name.toLowerCase()
                  .includes(assessmentsFilter)
              )
              .map((ev) => (
                <React.Fragment key={ev.id}>
                  {ev.measurements
                    .filter((measure) => 
                      existingGoalofStudent.length 
                      ? !existingGoalofStudent.includes(measure.id)
                      : true
                    )
                    .filter((measurement) => setMissedGoal ? interventionGroup.student_goals.some((sg) => !sg.archived && sg.measurement.id == measurement.id) : true)
                    .map((measurement, index) => {
                      if(setMissedGoal) {
                        setTimeout(() => {
                          document.getElementById(`measurement_${measurement.id}`)?.click()
                        }, 1);
                      }
                      return <tr
                        className={
                          selectedMeasurement &&
                          measurement.id === selectedMeasurement.id
                            ? "selectedAssessment"
                            : ""
                        }
                        key={measurement.id}
                        onClick={handleBarsAssessmentsSelect(measurement)}
                        id={`measurement_${measurement.id}`}
                      >
                        <td style={{width:'37%'}} className="font-weight-bold" >{ev.name}</td>
                        <td style={{width:'37%'}} data-cy="assessment-measurement">
                          {measurement.display_name}
                        </td>
                        <td style={{width:'26%'}}>{ev.owner ? 
                                      <div className="d-flex">
                                        <span className="d-inline-block text-truncate" 
                                              title={`${ev.owner?.first_name} ${ev.owner?.last_name}`} 
                                              style={{maxWidth: "100px"}}
                                          >{`${ev.owner?.first_name} ${ev.owner?.last_name}`}</span> 
                                        <span>{ev.owner.current_role == UserAssignmentRole.DISTRICT_OWNER 
                                          ? `(District Admin)` : ''}</span>  
                                      </div>
                                          : ev.is_system_assessment ? 'ReadyCoach': ''}</td>
                      </tr>
                    }
                  )}
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>
      {!isStudentGoalExist ? <p className="my-3">
        <FontAwesomeIcon icon={faInfoCircle} /> You are setting the first goal for the student(s).
          To set additional goals, go to Manage in the Group Workspace.
      </p>: ''}
      <hr />
      <div className="modalActions">
        <OverlayTrigger
          overlay={
            <Popover id="progressMonitorAssessment">
              <Popover.Content>If you do not see your assessment listed above, click here to add.</Popover.Content>
            </Popover>
          }
        >
        <button
          data-cy="next-btn"
          className="blueBtnSm"
          onClick={() => props.openAddAssessmentModal(true)}
        >
          Add new progress monitoring assessment
        </button>
        </OverlayTrigger>
        <button
          data-cy="next-btn"
          className="blueBtnSm"
          onClick={() => props.onNextClick(selectedMeasurement!)}
          disabled={!selectedMeasurement}
        >
          Next
        </button>
      </div>
    </>
  );
};

const mapStateToProps = ({
  onboarding,
  cases,
}: ApplicationState): PropsFromState => {
  return {
    studentGoal: cases.selectedStudentGoal,
    interventionGroup: cases.selectedInterventionGroup!,
    evidenceAssessments: onboarding.evidenceAssessments,
    evidenceAssessment: onboarding.evidenceAssessment,
    isRedirectToSetGoal: onboarding.modalsState.isRedirectToSetGoal,
    setMissedGoal: cases.modalsState.setMissedGoal,
    isLoading: {
      getEvidenceAssessments: onboarding.isLoading.getEvidenceAssessments,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getEvidenceAssessments: getEvidenceAssessments,
      openAddAssessmentModal: openAddAssessmentModal,
      resetRedirectToSetGoal: resetRedirectToSetGoal
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BRSInput);
