import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { IS_READY_COACH } from "../../../constants";
import RCTermsAndConditions from "./RCTermsAndConditions";
import RITermsAndConditions from "./RITermsAndConditions";

type Props = {
  showModal: boolean;
  isLoggedUser?: boolean;
  isLoading: boolean;
  onAccept: () => void;
  handleHideModal?: () => void;
};

export const TermsAndConditionsModal: React.FC<Props> = ({
  showModal,
  isLoggedUser,
  isLoading,
  onAccept,
  handleHideModal,
}: Props) => {
  const [confirmed, setConfirmed] = useState<boolean>(false);

  const handleShowModal = () => {
    setConfirmed(false);
  };

  const handleConfirmedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setConfirmed(checked);
  };

  return (
    <Modal
      id={"terms-and-conditions-modal"}
      show={showModal}
      onShow={handleShowModal}
      onHide={handleHideModal}
      animation={false}
      size="lg"
      backdropClassName={"customDarkModalBackdrop in"}
    >
      <Modal.Header
        closeButton={!isLoggedUser}
        className={IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"}
      >
        <Modal.Title>Terms And Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {IS_READY_COACH ? <RCTermsAndConditions /> : <RITermsAndConditions />}

        {IS_READY_COACH && (
          <p className="mt-3 text-center" data-cy="confirm-user-agreement">
            <Form.Check
              custom
              type="checkbox"
              name="confirmed"
              id={`confirmed-checkbox`}
              label="Please Click Here to confirm that you agree with the terms of this User Agreement"
              checked={confirmed}
              onChange={handleConfirmedChange}
            />
          </p>
        )}

        <div className="btnActionRight">
          <button
            data-cy="submit"
            className="blueBtnMd"
            onClick={onAccept}
            disabled={IS_READY_COACH && !confirmed}
          >
            Accept {isLoading && <Spinner animation="border" size="sm" />}
          </button>
          {!isLoggedUser && (
            <div className="blueBtnMd" onClick={handleHideModal}>
              Cancel
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
