import React from "react";

type Props = {
  checked: boolean;
};

export const CustomRadioButton = ({ checked }: Props) => {
  return (
    <div className="customCheckboxContainer">
      <input type="checkbox" checked={checked} readOnly />
      <span className="customRadioCheckmark" />
    </div>
  );
};
