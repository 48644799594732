import React, { FunctionComponent, useEffect, useMemo } from "react";
import SpellingTestInputTable from "./SpellingTestInputForm";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import LoadingIndicator from "../../../common/onboarding/LoadingIndicator";
import {
  createSpellingTest,
  generateSpellingTestAnswers,
  getSpellingTestQuestions,
  updateSpellingTest,
} from "../../../../store/spelling-tests/actions";
import { GrammarType } from "../../../../store/spelling-tests/types";
import { getFullName } from "../../../../utils/NamesUtils";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router";
import { Spinner } from "react-bootstrap";

type Props = {};

const SpellingTestUpsertPage: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedTest = useSelector(
    (s: ApplicationState) => s.spellingTests.selectedTest
  );
  const selectedGroup = useSelector(
    (s: ApplicationState) => s.spellingTests.selectedGroup
  );
  const pStudent = useSelector(
    (s: ApplicationState) => s.spellingTests.selectedStudent
  );

  const selectedStudent = useMemo(() => {
    return selectedTest?.student ?? pStudent;
  }, []);

  useEffect(() => {
    if (selectedGroup?.grade) {
      (dispatch(getSpellingTestQuestions(selectedGroup.grade)) as any).then(
        null,
        (err: string) => {
          toastr.error("Failed to get spelling test questions by grade", err);
        }
      );
    }
  }, [selectedGroup?.grade]);

  const wordGroups = useSelector(
    (s: ApplicationState) => s.spellingTests.wordGroups
  );

  useEffect(() => {
    if (wordGroups.length) {
      dispatch(
        generateSpellingTestAnswers({
          questions: wordGroups.flatMap((wg) => wg.words),
          answers: selectedTest?.answers,
        })
      );
    }
  }, [wordGroups, selectedTest?.answers]);

  const selectedGrammarTypes = useMemo(() => {
    const grammarTypeOrder = Object.values(GrammarType);

    return Array.from(
      new Set(
        wordGroups
          .flatMap((wg) => wg.words)
          .flatMap((q) => q.grammar_parts)
          .map((gp) => gp.type)
      )
    ).sort((a, b) => grammarTypeOrder.indexOf(a) - grammarTypeOrder.indexOf(b));
  }, [wordGroups]);

  const isLoading = useSelector(
    (s: ApplicationState) =>
      s.spellingTests.isLoading.getSpellingTestGroup ||
      s.spellingTests.isLoading.getSpellingTestQuestions
  );

  const isLoadingUpsertSpellingTest = useSelector(
    (s: ApplicationState) => s.spellingTests.isLoading.upsertSpellingTest
  );

  const spellingTestAnswers = useSelector(
    (s: ApplicationState) => s.spellingTests.spellingTestAnswers
  );

  const handleSpellingTestSave = () => {
    if (selectedTest) {
      (dispatch(
        updateSpellingTest(
          selectedTest.id,
          selectedGroup?.id!,
          selectedStudent?.id!,
          spellingTestAnswers
        )
      ) as any).then(
        () => history.push("/spelling-test/groups"),
        (err: string) => {
          toastr.error("Failed to update spelling test", err);
        }
      );
    } else {
      (dispatch(
        createSpellingTest(
          selectedGroup?.id!,
          selectedStudent?.id!,
          spellingTestAnswers
        )
      ) as any).then(
        () => history.push("/spelling-test/groups"),
        (err: string) => {
          toastr.error("Failed to create spelling test", err);
        }
      );
    }
  };

  return (
    <div className="onboardingContainer">
      <h2 className="font-weight-bold">
        Spelling Test - {selectedTest ? "Update Test" : "New Test"}
      </h2>

      <div className="d-flex align-items-center justify-content-between mb-3">
        <div>
          <h3>Student: {getFullName(selectedStudent)}</h3>
          <h3>Group Name: {selectedGroup?.notes}</h3>
          <h3>Teacher: {getFullName(selectedGroup?.teacher)}</h3>
        </div>

        <button
          disabled={isLoading || isLoadingUpsertSpellingTest}
          className="blueBtnLg"
          type="submit"
          form="spelling_test_upsert_form"
        >
          Save{" "}
          {isLoadingUpsertSpellingTest && (
            <Spinner animation="border" size="sm" />
          )}
        </button>
      </div>

      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <SpellingTestInputTable
          wordGroups={wordGroups}
          selectedGrammarTypes={selectedGrammarTypes}
          onSpellingTestSave={handleSpellingTestSave}
        />
      )}
    </div>
  );
};

export default SpellingTestUpsertPage;
