import React, { FunctionComponent, useMemo, useState } from "react";
import {
  AttachedResource,
  AttachedResourceType,
  AttachedToType,
  ChecklistItem,
} from "../../../../../../../../store/onboarding/cases/types";
import { Form, Spinner } from "react-bootstrap";
import { bindActionCreators, Dispatch } from "redux";
import { uploadDocument } from "../../../../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

type OwnProps = {
  onClose: () => void;
  onAttachmentsAdd: (resources: AttachedResource[]) => any;
};

type DispatchProps = {
  uploadDocument: (attachedResource: AttachedResource) => any;
};

type Props = OwnProps & DispatchProps;

const UploadUrlModal: FunctionComponent<Props> = ({
  onClose,
  onAttachmentsAdd,
  uploadDocument,
}) => {
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const defaultUrlResource: AttachedResource = useMemo(
    () => ({
      attached_to_type: AttachedToType.CHECKLIST_ITEM,
      resource_type: AttachedResourceType.URL,
      title: "",
      url: "",
    }),
    []
  );

  const [resource, setResource] = useState(defaultUrlResource);

  const handleResourceChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target as HTMLInputElement;
    setResource((resource) => ({ ...resource, [name]: value }));
  };

  const handleAddResource = (event: React.FormEvent<any>) => {
    event.preventDefault();
    setFileUploading(true);
    uploadDocument(resource).then(
      (res: AttachedResource) => {
        onAttachmentsAdd([res]);
        setFileUploading(false);
        onClose();
      },
      (err: string) => {
        toastr.error("File Upload Error", defaultUrlResource.title + err);
        setFileUploading(false);
      }
    );
  };

  return (
    <div className="innerSmallModal">
      <div className="interventionStepDetailContainerResource">
        <Form id="urlResource" onSubmit={handleAddResource}>
          <h4>Resource URL</h4>
          <input
            className="fullWidthInput"
            name="url"
            type="url"
            placeholder="URL..."
            value={resource.url || ""}
            onChange={handleResourceChange}
            required
          />
          <br />
          <br />
          <h4>Resource Title</h4>
          <input
            className="fullWidthInput"
            placeholder="Title..."
            name="title"
            value={resource.title}
            onChange={handleResourceChange}
            required
          />
        </Form>
        <br />
        <br />
        <div className="modalActions">
          <button className="blueBtnSm" onClick={onClose}>
            CANCEL
          </button>
          <button className="blueBtnSm" form="urlResource" type="submit">
            ADD {fileUploading && <Spinner animation="border" size="sm" />}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      uploadDocument: uploadDocument,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(UploadUrlModal);
