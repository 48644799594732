const useHighlightGroupCard = () => {
  const highlightGroup = (rowId: string, groupId?: number) => {
    const recommendationsRow = document.getElementById(rowId);
    if (recommendationsRow?.getAttribute("aria-expanded") === "false") {
      recommendationsRow?.click();
    }
    const groupCard = document.getElementById(`group_card_${groupId}`);
    if (groupCard) {
      groupCard.scrollIntoView();
      groupCard.classList.add("highlighted");
      setTimeout(() => groupCard.classList.remove("highlighted"), 1500);
    }
  };

  return {
    highlightGroup,
  };
};

export default useHighlightGroupCard;
