import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import StudentGroupsTooltip from "./StudentGroupsTooltip";
import GroupCountIcon from "../../../third-step/group-students/common/GroupCountIcon";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { Student } from "../../../../../../store/onboarding/types";

type Props = {
  interventionGroups: Array<InterventionGroup>;
  student: Student;
};

const StudentGroupIconOverlay = ({ interventionGroups, student }: Props) => {
  const studentInterventionGroups = interventionGroups.filter((x) =>
    x.students.some((s) => s.id === student.id)
  );
  if (!studentInterventionGroups.length) return null;
  return (
    <OverlayTrigger
      placement="right-start"
      overlay={
        <StudentGroupsTooltip interventionGroups={studentInterventionGroups} />
      }
      trigger={['hover', 'focus']}
    >
      <div className="interventionGroupIcon">&nbsp;&nbsp;
        {/* <GroupCountIcon svgSize={10} studentsNum={0} /> */}
      </div>
    </OverlayTrigger>
  );
};
export default StudentGroupIconOverlay;
