import { BadgesState } from "./types";
import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";
import {
  getBadges,
  hideNewAchievementsModal,
  openNewAchievementsModal,
} from "./actions";

export type BadgesActions = ActionType<typeof actions>;

const initialState: BadgesState = {
  newAchievements: [],
  loadingStates: {
    getBadges: false,
  },
};

const reducer = createReducer<BadgesState, BadgesActions>(initialState)
  .handleAction(getBadges.request, (state, action) => {
    return {
      ...state,
      loadingStates: {
        ...state.loadingStates,
        getBadges: true,
      },
    };
  })
  .handleAction(getBadges.success, (state, action) => {
    return {
      ...state,
      badges: action.payload,
      loadingStates: {
        ...state.loadingStates,
        getBadges: false,
      },
    };
  })
  .handleAction(getBadges.failure, (state, action) => {
    return {
      ...state,
      loadingStates: {
        ...state.loadingStates,
        getBadges: false,
      },
    };
  })
  .handleAction(openNewAchievementsModal, (state, action) => {
    return {
      ...state,
      showNewAchievementsModal: true,
      newAchievements: action.payload,
    };
  })
  .handleAction(hideNewAchievementsModal, (state, action) => {
    return {
      ...state,
      showNewAchievementsModal: false,
      newAchievements: [],
    };
  });

export { reducer as badgesReducer };
