//@ts-nocheck
import React from "react";
import { Bar } from "react-chartjs-2";
import {
  AbcTypes,
  Fba,
  FbaABC,
  FbaBehaviorDisplayed,
  FbaConsequenceColors,
  FbaConsequenceDisplayed,
  FbaObservation,
} from "../../../../../store/onboarding/cases/types";
import { getAntecedentNaming } from "./hooks";

const options = {
  plugins: {
    datalabels: {
      display: false,
    },
  },
  barShowStroke: false,
  barValueSpacing: 20,
  legend: {
    textAlign: "left",
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
        scaleLabel: {
          display: true,
          labelString: "Number Of Occurrences",
        },
      },
    ],
  },
  tooltips: {
    custom: (tooltip: any) => {
      if (!tooltip) return;
      tooltip.displayColors = false;
    },
    callbacks: {
      title: () => null,
      beforeLabel: (tooltipItem: any, data: any) => {
        return tooltipItem.label;
      },
      label: (tooltipItem: any, data: any) => {
        return `followed ${data.datasets[tooltipItem.datasetIndex].label} `;
      },
      afterLabel: (tooltipItem: any, data: any) => {
        return `${tooltipItem.value} times.`;
      },
    },
  },
};

const getTitles = (fba: Fba) => {
  return fba.observations.reduce((pV: Array<number>, cV: FbaObservation) => {
    return [
      ...pV,
      ...cV.tally.behavior
        .flatMap((x: any) => x.behaviors)
        .filter(
          (x, index, arr) =>
            !pV.some((a) => a === x) && arr.indexOf(x) === index
        ),
    ];
  }, []);
};

const getDataSet = (
  fba: Fba,
  behaviorLabelIds: Array<number>,
  abcType: AbcTypes.antecedents | AbcTypes.consequence
) => {
  const observationsAbcs = fba.observations.reduce(
    (pV: Array<FbaABC>, cV: FbaObservation) => [...pV, ...cV.observationabc],
    []
  );
  return observationsAbcs.reduce(
    //@ts-ignore
    (pV: Array<DataEntry<abcType>>, cV: FbaABC, currentIndex: number) => {
      if (!pV.some((x) => x[abcType] === cV[abcType])) {
        return [
          ...pV,
          {
            color:
              abcType === AbcTypes.antecedents //todo
                ? FbaConsequenceColors[
                    currentIndex % Object.keys(FbaConsequenceColors).length
                  ]
                : FbaConsequenceColors[
                    cV[abcType] % Object.keys(FbaConsequenceColors).length
                  ],
            [abcType]: cV[abcType],
            data: behaviorLabelIds.map((behId) => ({
              behavior: behId,
              count: cV.behaviors.includes(behId) ? 1 : 0,
            })),
          },
        ];
      } else {
        return pV.map((dataEntry) => {
          if (dataEntry[abcType] === cV[abcType]) {
            if (
              dataEntry.data.some((data) =>
                cV.behaviors.some((b) => b === data.behavior)
              )
            ) {
              return {
                ...dataEntry,
                data: dataEntry.data.map((data) => {
                  if (cV.behaviors.some((b) => b === data.behavior)) {
                    return { ...data, count: data.count + 1 };
                  }
                  return data;
                }),
              };
            }
          }
          return dataEntry;
        });
      }
    },
    []
  );
};

type DataEntry<PropertyName extends string> = {
  color: string;
  data: Array<{
    behavior: number;
    count: number;
  }>;
} & { [P in PropertyName]: number };

type Props = {
  fba: Fba;
  type: AbcTypes.antecedents | AbcTypes.consequence;
};

const FbaChart = ({ fba, type }: Props) => {
  const behaviorLabelIds = getTitles(fba);
  const data = {
    labels: behaviorLabelIds.map((a) => FbaBehaviorDisplayed[a]),
    datasets: getDataSet(fba, behaviorLabelIds, type).map((abc: any) => ({
      label:
        type === AbcTypes.antecedents
          ? getAntecedentNaming(abc[type], [], true)
          : FbaConsequenceDisplayed[abc[type]],
      backgroundColor: abc.color,
      data: abc.data.map((d: any) => d.count),
    })),
  };
  return <Bar data={data} options={options} height={120} />;
};

export default FbaChart;
