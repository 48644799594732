import { faHandsHelping, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Table from "react-bootstrap/Table";

const LinkItExample = () => {
  const dibels_columns = {
    "LNF": ["LNF (LNF)", "LNF"],
    "NWF-CLS": [
        "NWF-CLS (NWF-CLS)",
        "NWF - CLS(NWF - CLS)",
        "NWF - CLS (NWF - CLS)",
        "NWF (CLS)",
        "NWF (NWF-CLS)",
        "NWF: CLS",
        "NWF - CLS",
        "NWF-CLS"
    ],
    "NWF-WRC": [
        "NWF-WRC (NWF-WRC)",
        "NWF - WRC (NWF-WRC)",
        "NWF - WRC (NWF - WRC)",
        "NWF (NWF-WRC)",
        "NWF (WRC)",
        "NWF: WRC",
        "NWF - WRC",
        "NWF-WRC"
    ],
    "ORF-Accuracy": [
        "ORF-ACC (ORF-ACC)",
        "ORF (Percent)",
        "ORF (Accuracy)",
        "ORF - Accuracy(ORF - Accuracy)",
        "ORF - Accuracy (ORF - Accuracy)",
        "ORF-Accuracy(ORF-Accuracy)",
        "ORF(ORF - Accuracy)",
        "ORF (ORF - Accuracy)",
        "ORF(ORF-Accuracy)",
        "ORF (ORF- Accuracy)",
        "ORF (ORF -Accuracy)",
        "ORF - Accuracy (ORF - Accuracy)"
    ],
    "ORF-WordsCorrect": [
        "ORF (ORF)",
        "ORF (ORF-Words Correct)",
        "ORF (ORF - Words Correct)",
        "ORF (Words Correct)",
        "ORF (ORF Words Correct)",
        "ORF - Words Correct (ORF - Words Correct)",
        "ORF-Words Correct (ORF-Words Correct)"
    ],
    "PSF": ["PSF (PSF)", "PSF"],
    "WRF": ["WRF (WRF)", "WRF"],
    "Maze-Adjusted": [
        "MAZE",
        "Maze (Maze Adjusted)",
        "MAZE (Maze Adjusted)",
        "Maze (MAZE Adjusted)",
        "MAZE (MAZE Adjusted)",
        "Maze",
        "Maze-Adj",
        "MAZE - Adj",
        "Adj",
        "Maze (Maze)",
        "Maze(Maze)",
        "Maze Adjusted",
        "Maze Adjusted (Maze Adjusted)",
        "Maze Adjusted(Maze Adjusted)"
    ],
    "Composite": [
        "Composite-Score",
        "Composite",
        "Composite - Score",
        "Composite Score"
    ]
}
  const variants = (type: string, variantArray:any) => {
    return <OverlayTrigger
      overlay={ (
          <Popover id={type}>
            <Popover.Content>
              <p className="font-weight-bold mb-1">Accepted header variation for {type}</p>
              <ul>
                {variantArray.length 
                  ? variantArray.map((element:string) => 
                      <li>{element}</li>
                    ) 
                  : ''}
              </ul>
            </Popover.Content>
          </Popover>
        )
      }
    >
      <FontAwesomeIcon
        icon={faQuestionCircle}
        size="1x"
        style={{ color: "#00265b" }}
        className="mx-1"
      />
    </OverlayTrigger>
  }
  return (
    <>
    <h4 className="font-weight-bold mb-2">Sample 1 Example Format</h4>
    <div className="overflow-auto mb-3">
      <Table className="uploadExampleTable table-mb">
        <thead>
            <tr><td>Sample Date</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
            <tr><td>Sample District</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
            <tr><td>Sample School</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
            <tr style={{height: '35px'}}><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
          <tr>
            <th>Student</th>
            <th>ID</th>
            <th>Grade</th>
            <th>Race</th>
            <th>Gender</th>
            <th>Free or Reduced Lunch</th>
            <th>Special Education</th>
            <th>SLD</th>
            <th>ELL</th>
            <th>Result Date</th>
            <th>School</th>
            <th>Teacher</th>
            <th>LNF (LNF) {variants('LNF (LNF)', dibels_columns.LNF)}</th>
            <th>PSF (PSF) {variants('PSF (PSF)', dibels_columns.PSF)}</th>
            <th>NWF (CLS) {variants('NWF (CLS)', dibels_columns["NWF-CLS"])}</th>
            <th>NWF (WRC) {variants('NWF (WRC)', dibels_columns["NWF-WRC"])}</th>
            <th>WRF (WRF) {variants('WRF (WRF)', dibels_columns.WRF)}</th>
            <th>ORF (Words Correct) {variants('ORF (Words Correct)', dibels_columns["ORF-WordsCorrect"])}</th>
            <th>ORF (Accuracy) {variants('ORF (Accuracy)', dibels_columns["ORF-Accuracy"])}</th>
            <th>MAZE {variants('MAZE', dibels_columns["Maze-Adjusted"])}</th>
            <th>Composite Score {variants('Composite Score', dibels_columns.Composite)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>John Doe</td>
            <td>22222</td>
            <td>1</td>
            <td>Black - African American</td>
            <td>Female</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>2021-09-17</td>
            <td>Sample School</td>
            <td>Sample Teacher</td>
            <td>At Benchmark|62</td>
            <td>Well Below Benchmark|15</td>
            <td>Below Benchmark|29</td>
            <td>At Benchmark|9</td>
            <td>Above Benchmark|20</td>
            <td>Above Benchmark|46</td>
            <td>At Benchmark|83</td>
            <td>2.0</td>
            <td>At Benchmark|338</td>
          </tr>
        </tbody>
      </Table>
    </div>
    <h4 className="font-weight-bold mb-2">Sample 2 Example Format</h4>
    <div className="overflow-auto mb-3">
      <Table className="uploadExampleTable table-mb">
        <thead>
            <tr><td>Sample Date</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
            <tr><td>Sample District</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
            <tr><td>Sample School</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
            <tr style={{height: '35px'}}><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
            <tr style={{height: '35px'}}><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
          <tr>
            <th>Student</th>
            <th>ID</th>
            <th>Grade</th>
            <th>Race</th>
            <th>Gender</th>
            <th>Free or Reduced Lunch</th>
            <th>Special Education</th>
            <th>SLD</th>
            <th>ELL</th>
            <th>Result Date</th>
            <th>School</th>
            <th>Teacher</th>
            <th>LNF (LNF) {variants('LNF (LNF)', dibels_columns.LNF)}</th>
            <th>PSF (PSF) {variants('PSF (PSF)', dibels_columns.PSF)}</th>
            <th>NWF (CLS) {variants('NWF (CLS)', dibels_columns["NWF-CLS"])}</th>
            <th>NWF (WRC) {variants('NWF (WRC)', dibels_columns["NWF-WRC"])}</th>
            <th>WRF (WRF) {variants('WRF (WRF)', dibels_columns.WRF)}</th>
            <th>ORF (Words Correct) {variants('ORF (Words Correct)', dibels_columns["ORF-WordsCorrect"])}</th>
            <th>ORF (Accuracy) {variants('ORF (Accuracy)', dibels_columns["ORF-Accuracy"])}</th>
            <th>MAZE {variants('MAZE', dibels_columns["Maze-Adjusted"])}</th>
            <th>Composite Score {variants('Composite Score', dibels_columns.Composite)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>John Doe</td>
            <td>22222</td>
            <td>1</td>
            <td>Black - African American</td>
            <td>Female</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>2021-09-17</td>
            <td>Sample School</td>
            <td>Sample Teacher</td>
            <td>At Benchmark|62</td>
            <td>Well Below Benchmark|15</td>
            <td>Below Benchmark|29</td>
            <td>At Benchmark|9</td>
            <td>Above Benchmark|20</td>
            <td>Above Benchmark|46</td>
            <td>At Benchmark|83</td>
            <td>2.0</td>
            <td>At Benchmark|338</td>
          </tr>
        </tbody>
      </Table>
    </div>
    <h4 className="font-weight-bold mb-2">Sample 3 Example Format</h4>
    <div className="overflow-auto mb-3">
      <Table className="uploadExampleTable table-mb">
        <thead>
          <tr><th>Export Date</th><td>Sample Date</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
          <tr><th>Selected Tests</th><td>22-23 G2 DIBELS 8 (b) MOY</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
          <tr><th>District</th><td>Sample District</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
          <tr><th>Total Students</th><td>11111</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
          <tr><th>Powered by LinkIt!</th><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
          <tr>
              <th rowSpan={2}>Student</th>
              <th rowSpan={2}>ID</th>
              <th rowSpan={2}>Grade</th>
              <th rowSpan={2}>Race</th>
              <th rowSpan={2}>Gender</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th className="text-center" colSpan={12}>22-23 G2 DIBELS 8 (b) MOY</th>
          </tr>
          <tr>
            <th>Free or Reduced Lunch</th>
            <th>Special Education</th>
            <th>SLD</th>
            <th>ELL</th>
            <th>Result Date</th>
            <th>School</th>
            <th>Teacher</th>
            <th>LNF (LNF) {variants('LNF (LNF)', dibels_columns.LNF)}</th>
            <th>PSF (PSF) {variants('PSF (PSF)', dibels_columns.PSF)}</th>
            <th>NWF (CLS) {variants('NWF (CLS)', dibels_columns["NWF-CLS"])}</th>
            <th>NWF (WRC) {variants('NWF (WRC)', dibels_columns["NWF-WRC"])}</th>
            <th>WRF (WRF) {variants('WRF (WRF)', dibels_columns.WRF)}</th>
            <th>ORF (Words Correct) {variants('ORF (Words Correct)', dibels_columns["ORF-WordsCorrect"])}</th>
            <th>ORF (Accuracy) {variants('ORF (Accuracy)', dibels_columns["ORF-Accuracy"])}</th>
            <th>MAZE {variants('MAZE', dibels_columns["Maze-Adjusted"])}</th>
            <th>Composite Score {variants('Composite Score', dibels_columns.Composite)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>John Doe</td>
            <td>22222</td>
            <td>1</td>
            <td>Black - African American</td>
            <td>Female</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>2021-09-17</td>
            <td>Sample School</td>
            <td>Sample Teacher</td>
            <td>At Benchmark|62</td>
            <td>Well Below Benchmark|15</td>
            <td>Below Benchmark|29</td>
            <td>At Benchmark|9</td>
            <td>Above Benchmark|20</td>
            <td>Above Benchmark|46</td>
            <td>At Benchmark|83</td>
            <td>2.0</td>
            <td>At Benchmark|338</td>
          </tr>
        </tbody>
      </Table>
    </div>
  </>
  );
};
export default LinkItExample;
