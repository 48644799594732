import React, { FunctionComponent } from "react";
import { Modal } from "react-bootstrap";

type OwnProps = {
  showModal?: boolean;
  onHide: () => any;
};

type Props = OwnProps;

const GroupStudentsModal: FunctionComponent<Props> = (props) => {
  return (
    <Modal
      show={props.showModal}
      animation={false}
      size="sm"
      backdropClassName="customDarkModalBackdrop in"
      onHide={props.onHide}
    >
      <Modal.Header closeButton className={"purpleModalHeader"}>
        <Modal.Title className="modalTitle">Group Students</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>
            Once you've gathered evidence, it's time to group students for
            intervention. An Intervention Group is a group of students or
            individual students that share the same intervention plan and whose
            progress will be tracked together.
          </strong>
        </p>
        <p>
          Consider using the evidence you've gathered to find students with
          common needs that can be supported together. You can also put single
          students in their own Intervention Groups if you are working with them
          individually.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default GroupStudentsModal;
