import React, { FunctionComponent } from "react";
import { Modal } from "react-bootstrap";
import { DataPeriod } from "../../../store/onboarding/types";

type OwnProps = {
  showModal?: boolean;
  onClose: () => any;
  onSelect: (dataPeriodId: number) => any;
  newDataPeriods: DataPeriod[];
};

type Props = OwnProps;

const NewDataPeriodsModal: FunctionComponent<Props> = (props) => {
  return (
    <Modal
      show={props.showModal}
      dialogClassName="modal-md"
      onHide={props.onClose}
      animation={false}
      size="sm"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>New Data Available</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Your file contained data for {props.newDataPeriods.length} new data
          periods. Which one would you like to view?
        </p>
        <div className="d-flex flex-column">
          {props.newDataPeriods.map((dp) => (
            <button
              key={dp.id}
              onClick={props.onSelect(dp.id)}
              className="whiteBtnMd2 mb-2"
            >
              {dp.name}
            </button>
          ))}
        </div>
        <div className="text-center">
          <button className="blueBtnSm mt-3" onClick={props.onClose}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NewDataPeriodsModal;
