import React, { FunctionComponent, useState } from "react";
import { Form, FormControl, Spinner } from "react-bootstrap";
import { patchInterventionGroup } from "../../../../../../store/onboarding/cases/actions";
import {
  InterventionGroup,
  InterventionGroupPatchRequest,
} from "../../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

type StateProps = {
  isLoading: {
    patchInterventionGroup?: boolean;
  };
};

type DispatchProps = {
  patchInterventionGroup: (
    groupId: number,
    request: InterventionGroupPatchRequest
  ) => any;
};

type OwnProps = {
  interventionGroup: InterventionGroup;
};

type Props = DispatchProps & StateProps & OwnProps;

const InterventionGroupName: FunctionComponent<Props> = (props) => {
  const [editing, setEditing] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");

  const handleEdit = () => {
    setNewGroupName(props.interventionGroup.name);
    setEditing(true);
  };

  const handleNewGroupNameChange = (event: React.FormEvent<any>) => {
    const { value } = event.target as HTMLInputElement;
    setNewGroupName(value);
  };

  const handleSave = (e: React.FormEvent<any>) => {
    e.preventDefault();
    props
      .patchInterventionGroup(props.interventionGroup.id!, {
        name: newGroupName,
      })
      .then(
        () => {
          setEditing(false);
          setNewGroupName("");
        },
        (err: string) =>
          toastr.error("Failed to update the intervention group", err)
      );
  };

  return editing ? (
    <span className="ml-1">
      <Form className="d-flex align-items-center" onSubmit={handleSave}>
        <FormControl
          required
          placeholder="Group Name"
          aria-label="Group Name"
          value={newGroupName}
          onChange={handleNewGroupNameChange}
        />
        <button className="blueBtnMd mx-3">
          Save
          {props.isLoading.patchInterventionGroup && (
            <Spinner animation="border" size="sm" className={"ml-1"} />
          )}
        </button>
        <button className="whiteBtnMd2" onClick={() => setEditing(false)}>
          Close
        </button>
      </Form>
    </span>
  ) : (
    <span
      className="pointer ml-1"
      onClick={handleEdit}
      title="Click to change group name"
    >
      {props.interventionGroup.name}
    </span>
  );
};

const mapStateToProps = ({ cases }: ApplicationState): StateProps => {
  return {
    isLoading: {
      patchInterventionGroup: cases.isLoading.patchInterventionGroup,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      patchInterventionGroup: patchInterventionGroup,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterventionGroupName);
