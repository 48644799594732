import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { TargetBehaviorGroup } from "../utils";
import { CategoryBehavior } from "../../../../../../../store/onboarding/types";

type OwnProps = {
  selectedBehavior?: TargetBehaviorGroup;
  selectedTarget?: CategoryBehavior;
  onTargetBehaviorSelect: (target: CategoryBehavior) => any;
  onCustomTargetSelect: () => any;
  onCustomTargetBehaviorNameChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => any;
};
type Props = OwnProps;

const BehaviorDomainBehaviorsTable = ({
  selectedBehavior,
  selectedTarget,
  onTargetBehaviorSelect,
  onCustomTargetSelect,
  onCustomTargetBehaviorNameChange,
}: Props) => {
  return selectedBehavior ? (
    <div className="findTargetDomainsTable">
      <div className="findTargetDomainsTableHeader">
        {selectedBehavior.domain.name}
      </div>
      <div className="findTargetDomainsTableBody">
        {selectedBehavior.behaviors.map((target) => {
          const isActive = selectedTarget && selectedTarget.id === target.id;
          return (
            <div
              key={target.id}
              onClick={onTargetBehaviorSelect(target)}
              className={isActive ? "findTargetDomainsTableActiveItem" : ""}
            >
              <FontAwesomeIcon icon={isActive ? faDotCircle : faCircle} />
              {target.full_display_name}
            </div>
          );
        })}
        <div
          className={
            selectedTarget && !selectedTarget.id
              ? "findTargetDomainsTableActiveItem"
              : ""
          }
          onClick={onCustomTargetSelect}
        >
          <FontAwesomeIcon
            icon={selectedTarget && !selectedTarget.id ? faDotCircle : faCircle}
          />
          Other:
          <input
            disabled={!(selectedTarget && !selectedTarget.id)}
            className="customBehaviorInput"
            onChange={onCustomTargetBehaviorNameChange}
            onClick={(event) => event.stopPropagation()}
            value={
              selectedTarget && !selectedTarget.id ? selectedTarget.name : ""
            }
          />
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
};

export default BehaviorDomainBehaviorsTable;
