import React, { useEffect, useMemo, useRef, useState } from "react";
import { Card, OverlayTrigger, Popover, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataFileReader from "../../../../../containers/student-data-upload/FileReader";
import { IS_READY_COACH } from "../../../../../constants";
import AccordianContainer from "../../../../pages/coach-dashboard/tabs/your-assigments/common/AccordianContainer";
import DDMSExample from "./DDMSExample";
import LinkItExample from "./LinkItExample";
import NJTSSExample from "./NJTSSExample";
import DESSAExample from "./DESSAExample";
import ISteepExample from "./ISteepExample";
import { faExclamationTriangle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import MClassExample from "./MClassExample";
import { useDispatch, useSelector } from "react-redux";
import { showConfirmDialog } from "../../../../../store/confirm-dialog/actions";
import RaceDemographicsExample from "./RaceDemographicsExample";
import { getMasterDataDisplayNames, getMasterDataVariations } from "../../../../../store/superuser/actions";
import { ApplicationState } from "../../../../../store";
import { Master_Data, SuperuserState } from "../../../../../store/superuser/types";
import LoadingIndicator from "../../LoadingIndicator";

const UploadingDataGuide = () => {
  const dispatch = useDispatch();
  const dataFileReaderRef = useRef<any>(null!);
  const [openId, setOpenId] = useState<number | null>(null);
  const [raceVariation, setRaceVariation] = useState<any>([]);
  const toggleHandler = (id: number) => () => {
    setOpenId(openId => openId === id ? null : id);
  };
  
  useEffect(() => {
      dispatch<any>(getMasterDataDisplayNames('races'))
      .then((displayNameResponse: Master_Data[]) => {
        let raceVariations: any[] = [];
        if(displayNameResponse.length) {
          for (let i = 0; i < displayNameResponse.length; i++) {
            const races = displayNameResponse[i];
            dispatch<any>(getMasterDataVariations(races.target_value!))
            .then((raceTypes: any) => {
              if(raceTypes.length) {
                let raceVariationsData = raceTypes.map((raceType: any) => raceType.variation).join('; ')
                raceVariations.push({
                  displayName: races.display_name,
                  variations: raceVariationsData
                })
                setRaceVariation(raceVariations);
              }
            });
          }
        }
      })
  },[])

  const commonDemoGraphicContent = (fileType: string) => {
    return <>
      {!['Other'].includes(fileType) 
        ?
        <>
          <p className="mb-2">
            To ensure your file is uploaded correctly, the contents of your file, including header rows 
            and file type-specific information, <strong>must match</strong> the format displayed below:
          </p>
          <>
            {fileType == 'DDMS' ? <DDMSExample /> :''}
            {fileType == 'LinkIt' ? <LinkItExample /> : ''}
            {fileType == 'NJTSS' ? <NJTSSExample /> : ''}
            {fileType == 'mClass' ? <MClassExample /> : ''}
            {fileType == 'DESSA' ? <DESSAExample /> : ''}
            {fileType == 'iSteep' ? <ISteepExample /> : ''}
          </>
          {fileType == 'RaceDemographics'
          ?
            <>
              <p className="mb-2">
                {/* If your file includes demographic information, columns in the file <strong>must</strong> be named exactly as listed 
                in the table below, and except for Race and Gender entries, contain a Y for yes. 
                If the demographic does not apply to the student, leave the cell empty. 
                To view the accepted Race and Gender name variations, please access the Race/Ethnicity and Gender 
                Demographics in this modal.   */}
                If your file includes demographic information, columns in the file must be named exactly as listed in the table below, and except for Race and Gender entries, contain a Y for yes. If the demographic does not apply to the student, leave the cell empty. For Race and Gender entries, please see specific information following this table.
              </p>
              <div className="overflow-auto">
                <Table className="uploadExampleTable">
                <thead>
                  <tr>
                    <th>Demographic</th>
                    <th>Column Name</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                      <td>Special Education</td>
                      <td>{`Special Education`}</td>
                    </tr>
                    <tr>
                      <td>English Language Learner</td>
                      <td>{`ELL`}</td>
                    </tr>
                    <tr>
                      <td>Specific Learning Disability</td>
                      <td>{`SLD`}</td>
                    </tr>
                    <tr>
                      <td>Race</td>
                      <td>{`Race`}</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>{`Gender`}</td>
                    </tr>
                    <tr>
                      <td>Free or Reduced Lunch</td>
                      <td>{`Free or Reduced Lunch`}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <p className="mb-2">
                To ensure race/ethnicity and gender demographics are uploaded correctly, the demographic name must exactly (e.g., spacing, casing) match one of the variations below. For example, in the gender column, Male can be entered as M or Male. To view the accepted demographic column names and entries for other demographic information (e.g., Special Education, SLD) check the guidance in each of the file type sections in this upload modal. 
              </p>
              {openId && !raceVariation.length ? <LoadingIndicator/> : '' }
              {openId && raceVariation.length ? <RaceDemographicsExample raceVariations={raceVariation}/> : ''}
            </>
          :
          <>
            <Card>
              <Card.Header>
                <FontAwesomeIcon icon="info-circle" /> Why do we need Student {fileType == 'mClass' ? 'Primary' : ''} ID?{" "}
              </Card.Header>
              <Card.Body>
                <Card.Text className="smallerText">
                  <>
                    The Student {fileType == 'mClass' ? 'Primary' : ''} ID should be unique for each student across in all
                    files uploaded to{" "}
                    {IS_READY_COACH ? "ReadyCoach" : "NJTSS Early Reading"}. This lets
                    us match up student data without relying on their names.
                  </>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <FontAwesomeIcon icon="info-circle" /> Student Demographics{" "}
              </Card.Header>
              <Card.Body>
                <Card.Text className="smallerText">
                  ReadyCoach allows a user to filter by select demographic information and generate an Equity Benchmark report for equity analysis. To access these features, demographics must be available for each student. If your assessment file includes demographic information, please carefully review the guidance in the Race/Ethnicity and Gender Demographics Variations section.
                </Card.Text>
              </Card.Body>
            </Card>
            {/* <p className="mb-2">
              If your file includes demographic information, columns in the file <strong>must</strong> be named exactly as listed 
              in the table below, and except for Race and Gender entries, contain a Y for yes. 
              If the demographic does not apply to the student, leave the cell empty.  
            </p>
            <div className="overflow-auto">
              <Table className="uploadExampleTable">
              <thead>
                <tr>
                  <th>Demographic</th>
                  <th>Column Name</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>Special Education</td>
                    <td>{fileType == 'DESSA' ? `SpecialEdSvc` :`Special Education`}</td>
                  </tr>
                  <tr>
                    <td>English Language Learner</td>
                    <td>{fileType == 'DESSA' ? `EngLangLearn` :`ELL`}</td>
                  </tr>
                  {fileType == 'DESSA' ? '' :
                    <tr>
                      <td>Specific Learning Disability</td>
                      <td>{`SLD`}</td>
                    </tr>
                  }
                  <tr>
                    <td>Race</td>
                    <td>{fileType == 'DESSA' 
                      ? 
                      <>
                        {`NativeAmerican, Asian, AfricanAmerican, Hispanic, NativeHawaiian, White, Other`} 
                          <OverlayTrigger
                          overlay={ (
                              <Popover id={fileType}>
                                <Popover.Content>
                                  <p>Multiple columns for each race type, indicated by Y if applicable 
                                    in the column for each student. Please see the example format above 
                                    for more clarity.</p>
                                </Popover.Content>
                              </Popover>
                            )
                          }
                        >
                        
                          <FontAwesomeIcon
                            icon={faQuestionCircle}
                            size="1x"
                            style={{ color: "#00265b" }}
                            className="mx-1"
                          />
                        
                        </OverlayTrigger>
                      </>
                      :`Race`}</td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td>{fileType == 'DESSA' ? `StudentGender` :`Gender`}</td>
                  </tr>
                  <tr>
                    <td>Free or Reduced Lunch</td>
                    <td>{fileType == 'DESSA' ? `FreeReduced` : `Free or Reduced Lunch`}</td>
                  </tr>
                </tbody>
              </Table>
            </div> */}
          </>
          }
        </>
        :
          ''
      }
    </>
  }

  const handleFileInputModal = () => {
    dispatch(showConfirmDialog({
      onConfirm: () => {
        const fileInput = dataFileReaderRef.current.querySelector('input[type="file"]');
        fileInput?.click();
      },
      centered: true,
      title: "Confirmation",
      timerElipseLeft: true,
      countDownTimer: 5,
      text: (
        <>
          <FontAwesomeIcon 
              icon={faExclamationTriangle}
              style={{color:'red'}}
            /> {" "}
           Before uploading your file, it is extremely important to review your file with the guidance outlined in this modal. 
           If your file structure is not in <span className="font-weight-bold">exact</span> alignment (e.g., column names, casing, spaces, etc),
           it may prevent you from uploading your file or result in an incomplete upload of your file.
        </>
      ),
      confirmButtonText: "Confirm"
    }))
  }
  return IS_READY_COACH ? (
    <>
      <h2 className="text-center font-weight-semibold mb-xl-3 mb-2">
        We can guide you in uploading data.
      </h2>
      {/*<br />*/}
      <h4 className="text-center mb-2">
        To upload data from a file, it needs to be in CSV or XLS format and include a
        header row. It must include at least two columns, Student ID and the
        data itself.
      </h4>
      <h4 className="text-center mb-xl-3 mb-2">
        To upload a DIBELS 8th, DESSA, or iSteep file, please select your file type below 
        and follow the guidance provided. 
        If uploading any other type of file (e.g. custom assessment), select Other from below and 
        follow the guidance provided.
      </h4>
      <h4 className="text-center mb-xl-3 mb-2 font-weight-bold">
        To ensure that the file can be recognized properly, 
        please verify the format of the file and the spelling of the headers is correct.
      </h4>
      {/* <div className="alert alert-primary"> 
        <FontAwesomeIcon
          icon={faHandPointRight}
          size="1x"
          className="text-primary Blink" />
          <span className="ml-2 font-weight-bold">
            If there is an existing user in the file to be uploaded, 
            please replace his name with their email address.
          </span>
      </div> */}
      <div className="collapse-container">
        <AccordianContainer 
          label="DIBELS 8th (DIBELS Data Management System)" 
          open={openId === 1}
          onClick={toggleHandler(1)}
        >
          {commonDemoGraphicContent('DDMS')}
        </AccordianContainer>
        <AccordianContainer 
          label="DIBELS 8th (LinkIt)"
          open={openId === 2}
          onClick={toggleHandler(2)}
        >
          {commonDemoGraphicContent('LinkIt')}
        </AccordianContainer>
        <AccordianContainer 
          label="DIBELS 8th (NJTSS-ER Google Planning Sheets)"
          open={openId === 3}
          onClick={toggleHandler(3)}
        >
          {commonDemoGraphicContent('NJTSS')}
        </AccordianContainer>
        <AccordianContainer 
          label="DIBELS 8th (mClass)"
          open={openId === 4}
          onClick={toggleHandler(4)}
        >
          {commonDemoGraphicContent('mClass')}
        </AccordianContainer>
        <AccordianContainer 
          label="DESSA"
          open={openId === 5}
          onClick={toggleHandler(5)}
        >
          {commonDemoGraphicContent('DESSA')}
        </AccordianContainer>
        <AccordianContainer 
          label="iSteep"
          open={openId === 6}
          onClick={toggleHandler(6)}
        >
          {commonDemoGraphicContent('iSteep')}
        </AccordianContainer>
        <AccordianContainer 
          label="Other"
          open={openId === 7}
          onClick={toggleHandler(7)}
        >
          {commonDemoGraphicContent('Other')}
          <p className="mb-xl-3 mb-2">
            Uploaded files can include more than one type of data. Multiple types of
            data can be grouped together under a common data source. For example,
            "off-task" and "interrupting class" could be grouped under "Disruptive
            Behavior".
          </p>
          <p className="mb-2">
            To upload, click on “Upload the student data CSV or XLS file” 
            and follow the prompts.
          </p>
        </AccordianContainer>
        <hr/>
        <AccordianContainer 
          label="Race/Ethnicity and Gender Demographics Variations"
          open={openId === 8}
          onClick={toggleHandler(8)}
        >
          {commonDemoGraphicContent('RaceDemographics')}
        </AccordianContainer>
      </div>
      <h5 className="text-center font-weight-normal m-3">
        Click the button below to choose a student data CSV or XLS file from your
        computer to upload
      </h5>
      <div className="text-center">
        <label className="blueBtnXL" data-cy="upload-data-csv-file" onClick={handleFileInputModal}>
          Upload the Student Data CSV or XLS File
        </label>
        <div ref={dataFileReaderRef}><DataFileReader/></div>
      </div>
    </>
  ) : (
    <>
      <h1 className="text-center font-weight-bold mb-5">
        We can guide you in uploading data.
      </h1>
      <h3 className="text-center mb-4">
        To upload data from a file, it needs to be in CSV format and include a
        header row. <br />
        It must include at least two columns, Student ID and the data for at
        least one assessment.
      </h3>
      <h3 className="text-center mb-5">
        Uploaded files can include more than one type of skill indicator.
        Multiple skill indicators can <br />
        be grouped together. For example,{" "}
        <span className="font-weight-bold">
          phoneme segmentation fluency (PSF){" "}
        </span>
        and{" "}
        <span className="font-weight-bold">
          nonsense <br />
          word fluency (NWF)
        </span>{" "}
        can be provided within the same file.
      </h3>
      <h3 className="text-center font-weight-bold mb-5">
        The Student ID should be unique for each student in all files uploaded
        to NJTSS Early Reading. <br />
        This lets us match up student data without relying on their names.
      </h3>
      <div className="overflow-auto" style={{ width: "80%", margin: "0 10%" }}>
        <h3 className="font-weight-bold mb-2">Example Format</h3>
        <Table className="uploadExampleTable">
          <thead>
            <tr>
              <th>student_id</th>
              <th>last_name</th>
              <th>first_name</th>
              <th>psf</th>
              <th>nwf</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>424242</td>
              <td>Roberts</td>
              <td>Wendy</td>
              <td>42</td>
              <td>65</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <h4 className="text-center mb-4">
        Click the button below to choose a CSV file from your computer to
        upload.
      </h4>
      <div className="text-center">
        <label className="blueBtnXL">
          Upload CSV file1
          <DataFileReader />
        </label>
      </div>
    </>
  );
};

export default UploadingDataGuide;
