import React, { Component, useEffect, useMemo, useState } from "react";
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/auth/actions";
import { Modal } from "react-bootstrap";
import { push } from "connected-react-router";

type Props = {};


const UserActivity: React.FunctionComponent<Props> = (props) => {
    const [state, setState] = useState<string>('Active')
    const [remaining, setRemaining] = useState<number>(0)
    const [open, setOpen] = useState<boolean>(false)

    const dispatch = useDispatch();

    const onIdle = () => {
      setState('Idle')
      setOpen(false)
    }
  
    const onActive = () => {
      setState('Active')
      setOpen(false)
    }
  
    const onPrompt = () => {
      setState('Prompted')
      setOpen(true)
    }
  
    const {
      getRemainingTime,
      activate,
      isLastActiveTab,
      isPrompted,
    } = useIdleTimer({
      onIdle,
      onActive,
      timeout: 1000 * 60 * 60 * 8, // 8 hours user idle
      crossTab: true,
      leaderElection: true,
      syncTimers: 200,
      onPrompt,
      promptBeforeIdle: 1000 * 60, // popup shown before 60 seconds of the time out
      throttle: 500
    })
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (isPrompted()) {
          setRemaining(Math.ceil(getRemainingTime() / 1000)); // show remaining time in seconds on the prompt
        }
      }, 500)
  
      return () => {
        clearInterval(interval)
      }
    })
  
    const handleStillHere = () => {
      activate()
    }

    const handleLogout = () => {
      sessionStorage.clear();
      localStorage.clear();
      dispatch(logoutUser() as any)
    }
  
    useMemo(() => {
     if(remaining == 1) {
        sessionStorage.clear();
        localStorage.clear();
        dispatch(logoutUser() as any)
      }
    },[remaining])
  
    const lastActiveTab = useMemo(() => {
      return isLastActiveTab() === null ? 'loading' : isLastActiveTab().toString()
    },[isLastActiveTab])
      
    
    useEffect(() => {
      if(lastActiveTab) {
        if(!sessionStorage.getItem('loginStatus')) {
          sessionStorage.clear();
          localStorage.clear();
          dispatch(logoutUser() as any);
        }
      }
    
    }, [lastActiveTab]);

    const handleDialogHide = () => {
     return;
    };
  
  // Inactive code

  return (
    <>
      <Modal
        id={"user_idle-modal"}
        show={open}
        animation={false}
        backdropClassName="customDarkModalBackdrop in"
        onHide={handleDialogHide}
      >
        <Modal.Header
            closeButton
            className={`purpleModalHeader font-weight-bold`}
        >
            <Modal.Title>Session Timeout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-left mb-3">
              <p className="mb-0">Your session has been inactive for an extended period of time and you will be logged out shortly.</p>
              <p>Press “STAY LOGGED IN” to continue the session, or “LOGOUT NOW” if you would like to log out now.</p>
              <p>Logging out in: {remaining} seconds</p>
              
          </div>
          <div className="d-flex">
          <button
              className="blueBtnSm w-100 mr-2"
              onClick={handleStillHere}
              data-cy="submit"
          >
              Stay Logged In
          </button>
          <button
              className="whiteBtnSm w-100"
              onClick={handleLogout}
          >
              Logout Now
          </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
};

export default UserActivity;