import React, { FunctionComponent } from "react";
import { Form } from "react-bootstrap";
import { getRecommendations, showDiagnosedStudents, showRecommendations } from "../../../../../../store/onboarding/cases/actions";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { changeShowDiagnosedStudentFilter } from "../../../../../../store/dataFilter/actions";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";

type OwnProps = {};

type Props = OwnProps;

const ShowDiagnosedStudentCheckBox: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  
  const showDiagnosedStudentCheckBox = (e:any) => {
    const { checked } = e.target; 
    if(checked) {
      dispatch(showConfirmDialog({
        title: "Confirmation",
        text:`Any student in a group without diagnostic skill information will be excluded from the group. 
              To include them, return to My Data and Interviews and enter Diagnostic Skill Info.
               Are you sure you want to continue?`,
        onConfirm: () => {
          dispatch(changeShowDiagnosedStudentFilter(checked))
          dispatch(getRecommendations());
        },
        confirmButtonText: "Continue",
      }))
    } else {
      dispatch(changeShowDiagnosedStudentFilter(checked))
      dispatch(getRecommendations());
    }
  }

  const showDiagnosedStudentCheck = useSelector<
    ApplicationState,
    boolean
  >((s) => s.dataFilter.showDiagnosedStudentFilter);
  return (
    <div className="recommendations-checkbox">
      <span className="list-order-swither-checkbox d-flex">
            <label>Content Areas of Need and Diagnostic Skill Information</label>  
            <Form.Check
              type="switch"
              id="active-status-switch"
              label='Only Diagnostic Skill Information'
              className="ml-2"
              checked={!!showDiagnosedStudentCheck}
              onChange={showDiagnosedStudentCheckBox}
            />
          </span>
    </div>
  );
};

export default ShowDiagnosedStudentCheckBox;
