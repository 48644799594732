import { action, createAsyncAction } from "typesafe-actions";
import { Dispatch } from "redux";
import axios, { AxiosError } from "axios";
import {
  API_FEEDBACK_MEETINGS,
  API_LOG_INTERVENTION,
  API_MEETINGS,
  API_PREP_MEETINGS,
  API_PROGRESS_OVERVIEW,
  API_PROGRESS_TOWARDS_GOAL,
  API_STATIC_DATA_PRAISES,
  API_SUPPORT_MEETINGS,
} from "../../../constants";
import {
  FeedbackMeeting,
  InterventionGroupTabs,
  LoggedProgress,
  Meeting,
  MeetingModuleActionTypes,
  MeetingType,
  Praise,
  PrepMeeting,
  ProgressOverview,
  SupportMeeting,
} from "./types";
import { push } from "connected-react-router";
import moment from "moment";
import {
  changeInterventionGroupLatestMeeting,
  changeSelectedInterventionGroup,
  getInterventionGroupsSuccess,
  updateProgressOverview,
} from "../cases/actions";
import { getBadgesRequest } from "../../badges/actions";
import { Student } from "../types";
import { InterventionGroup, StudentGoal } from "../cases/types";
import { ApplicationState } from "../../index";

export const openPrepareInterventionModal = () =>
  action(MeetingModuleActionTypes.OPEN_PREPARE_INTERVENTION_MODAL);
export const hidePrepareInterventionModal = () =>
  action(MeetingModuleActionTypes.HIDE_PREPARE_INTERVENTION_MODAL);

export const openPraiseEducatorModal = () =>
  action(MeetingModuleActionTypes.OPEN_PRAISE_EDUCATOR_MODAL);
export const hidePraiseEducatorModal = () =>
  action(MeetingModuleActionTypes.HIDE_PRAISE_EDUCATOR_MODAL);

export const openSupportInterventionModal = () =>
  action(MeetingModuleActionTypes.OPEN_SUPPORT_INTERVENTION_MODAL);
export const hideSupportInterventionModal = () =>
  action(MeetingModuleActionTypes.HIDE_SUPPORT_INTERVENTION_MODAL);

export const openProvidingFeedbackModal = () =>
  action(MeetingModuleActionTypes.OPEN_PROVIDING_FEEDBACK_MODAL);
export const hideProvidingFeedbackModal = () =>
  action(MeetingModuleActionTypes.HIDE_PROVIDING_FEEDBACK_MODAL);

export const openInterventionsResourcesModal = (showOnlyMaterials?: boolean) =>
  action(MeetingModuleActionTypes.OPEN_INTERVENTIONS_RESOURCES_MODAL, {
    showOnlyMaterials,
  });
export const hideInterventionsResourcesModal = () =>
  action(MeetingModuleActionTypes.HIDE_INTERVENTIONS_RESOURCES_MODAL);

export const openMeetingPromptModal = () =>
  action(MeetingModuleActionTypes.OPEN_MEETING_PROMPT_MODAL);
export const hideMeetingPromptModal = () =>
  action(MeetingModuleActionTypes.HIDE_MEETING_PROMPT_MODAL);

export const openSupportImplFeedbackModal = () =>
  action(MeetingModuleActionTypes.OPEN_SUPPORT_IMPL_FEEDBACK_MODAL);
export const hideSupportImplFeedbackModal = () =>
  action(MeetingModuleActionTypes.HIDE_SUPPORT_IMPL_FEEDBACK_MODAL_OPEN);

export const openImplementationCheckModal = (callbacks?: {
  onClose?: Function;
}) =>
  action(MeetingModuleActionTypes.OPEN_IMPLEMENTATION_CHECK_MODAL, callbacks);
export const hideImplementationCheckModal = () =>
  action(MeetingModuleActionTypes.HIDE_IMPLEMENTATION_CHECK_MODAL);

export const openGoalProgressModal = (callbacks?: { onClose?: Function }) =>
  action(MeetingModuleActionTypes.OPEN_GOAL_PROGRESS_MODAL, callbacks);
export const hideGoalProgressModal = () =>
  action(MeetingModuleActionTypes.HIDE_GOAL_PROGRESS_MODAL);

export const openModifyInterventionModal = () =>
  action(MeetingModuleActionTypes.OPEN_MODIFY_INTERVENTION_MODAL);
export const hideModifyInterventionModal = () =>
  action(MeetingModuleActionTypes.HIDE_MODIFY_INTERVENTION_MODAL);

export const openEndInterventionModal = (preselectedStudentGoal?: any) =>
  action(MeetingModuleActionTypes.OPEN_END_INTERVENTION_MODAL, {
    preselectedStudentGoal,
  });
export const hideEndInterventionModal = () =>
  action(MeetingModuleActionTypes.HIDE_END_INTERVENTION_MODAL);

export const openInterventionFidelityModal = () =>
  action(MeetingModuleActionTypes.OPEN_INTERVENTION_FIDELITY_MODAL);
export const hideInterventionFidelityModal = () =>
  action(MeetingModuleActionTypes.HIDE_INTERVENTION_FIDELITY_MODAL);

export const changeSelectedMeeting = (meeting?: Meeting) =>
  action(MeetingModuleActionTypes.CHANGE_SELECTED_MEETING, { meeting });

export const getMeetingsStart = () =>
  action(MeetingModuleActionTypes.GET_MEETINGS_START);
export const getMeetingsSuccess = (meetings: Array<Meeting>) =>
  action(MeetingModuleActionTypes.GET_MEETINGS_SUCCESS, {
    meetings,
  });
export const getMeetingsFailure = (message: string) =>
  action(MeetingModuleActionTypes.GET_MEETINGS_FAILURE, {
    error: message,
  });

export const getMeetings = (
  meetingId?: number,
  interventionGroupId?: number
) => {
  return (dispatch: Dispatch) => {
    dispatch(getMeetingsStart());
    return axios
      .get(API_MEETINGS(meetingId, interventionGroupId))
      .then((res) => {
        const meetings: Array<Meeting> = res.data;
        dispatch(getMeetingsSuccess(meetings));
      })
      .catch((err: AxiosError) => {
        dispatch(getMeetingsFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const createMeetingStart = () =>
  action(MeetingModuleActionTypes.CREATE_MEETING_START);
export const createMeetingSuccess = (meeting: Meeting) =>
  action(MeetingModuleActionTypes.CREATE_MEETING_SUCCESS, {
    meeting,
  });
export const createMeetingFailure = (message: string) =>
  action(MeetingModuleActionTypes.CREATE_MEETING_FAILURE, {
    error: message,
  });

export const createMeeting = (meeting: Partial<Meeting>) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(createMeetingStart());
    return axios
      .post(API_MEETINGS(), meeting)
      .then((res) => {
        const meeting: Meeting = res.data;
        dispatch(createMeetingSuccess(meeting));
        dispatch(changeInterventionGroupLatestMeeting(meeting));
        dispatch(getBadgesRequest());
      })
      .catch((err: AxiosError) => {
        dispatch(createMeetingFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const updateMeetingStart = () =>
  action(MeetingModuleActionTypes.UPDATE_MEETING_START);
export const updateMeetingSuccess = (meeting: Meeting) =>
  action(MeetingModuleActionTypes.UPDATE_MEETING_SUCCESS, {
    meeting,
  });
export const updateMeetingFailure = (message: string) =>
  action(MeetingModuleActionTypes.UPDATE_MEETING_FAILURE, {
    error: message,
  });

export const updateMeeting = (meeting: Partial<Meeting>) => {
  return (dispatch: Dispatch) => {
    dispatch(updateMeetingStart());
    return axios
      .put(API_MEETINGS(meeting.id), meeting)
      .then((res) => {
        const meeting: Meeting = res.data;
        dispatch(updateMeetingSuccess(meeting));
        dispatch(changeInterventionGroupLatestMeeting(meeting));
      })
      .catch((err: AxiosError) => {
        dispatch(updateMeetingFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const getPrepMeetingsStart = () =>
  action(MeetingModuleActionTypes.GET_PREP_MEETINGS_START);
export const getPrepMeetingsSuccess = (prepMeetings: Array<PrepMeeting>) =>
  action(MeetingModuleActionTypes.GET_PREP_MEETINGS_SUCCESS, {
    prepMeetings,
  });
export const getPrepMeetingsFailure = (message: string) =>
  action(MeetingModuleActionTypes.GET_PREP_MEETINGS_FAILURE, {
    error: message,
  });

export const getPrepMeetings = (
  prepMeetingId?: number,
  interventionGroupId?: number
) => {
  return (dispatch: Dispatch) => {
    dispatch(getPrepMeetingsStart());
    return axios
      .get(API_PREP_MEETINGS(prepMeetingId, interventionGroupId))
      .then((res) => {
        const prepMeetings: Array<PrepMeeting> = res.data;
        dispatch(getPrepMeetingsSuccess(prepMeetings));
      })
      .catch((err: AxiosError) => {
        dispatch(getPrepMeetingsFailure(err.message));
      });
  };
};

export const createPrepMeetingStart = () =>
  action(MeetingModuleActionTypes.CREATE_PREP_MEETING_START);
export const createPrepMeetingSuccess = (meeting: PrepMeeting) =>
  action(MeetingModuleActionTypes.CREATE_PREP_MEETING_SUCCESS, {
    meeting,
  });
export const createPrepMeetingFailure = (message: string) =>
  action(MeetingModuleActionTypes.CREATE_PREP_MEETING_FAILURE, {
    error: message,
  });

export const createPrepMeeting = (prepMeeting: Partial<PrepMeeting>) => {
  return (dispatch: Dispatch) => {
    dispatch(createPrepMeetingStart());
    return axios
      .post(API_PREP_MEETINGS(), prepMeeting)
      .then((res) => {
        const meeting: PrepMeeting = res.data;
        dispatch(createPrepMeetingSuccess(meeting));
      })
      .catch((err: AxiosError) => {
        dispatch(createPrepMeetingFailure(err.message));
      });
  };
};

export const updatePrepMeetingStart = () =>
  action(MeetingModuleActionTypes.UPDATE_PREP_MEETING_START);
export const updatePrepMeetingSuccess = (meeting: PrepMeeting) =>
  action(MeetingModuleActionTypes.UPDATE_PREP_MEETING_SUCCESS, {
    meeting,
  });
export const updatePrepMeetingFailure = (message: string) =>
  action(MeetingModuleActionTypes.UPDATE_PREP_MEETING_FAILURE, {
    error: message,
  });

export const updatePrepMeeting = (prepMeeting: Partial<PrepMeeting>) => {
  return (dispatch: Dispatch) => {
    dispatch(updatePrepMeetingStart());
    return axios
      .put(API_PREP_MEETINGS(prepMeeting.id), prepMeeting)
      .then((res) => {
        const meeting: PrepMeeting = res.data;
        dispatch(updatePrepMeetingSuccess(meeting));
      })
      .catch((err: AxiosError) => {
        dispatch(updatePrepMeetingFailure(err.message));
      });
  };
};

export const getFeedbackMeetingsStart = () =>
  action(MeetingModuleActionTypes.GET_FEEDBACK_MEETINGS_START);
export const getFeedbackMeetingsSuccess = (
  feedbackMeetings: Array<FeedbackMeeting>
) =>
  action(MeetingModuleActionTypes.GET_FEEDBACK_MEETINGS_SUCCESS, {
    feedbackMeetings,
  });
export const getFeedbackMeetingsFailure = (message: string) =>
  action(MeetingModuleActionTypes.GET_FEEDBACK_MEETINGS_FAILURE, {
    error: message,
  });
export const getFeedbackMeetings = (
  supportMeetingId?: number,
  interventionGroupId?: number
) => {
  return (dispatch: Dispatch) => {
    dispatch(getFeedbackMeetingsStart());
    return axios
      .get(API_FEEDBACK_MEETINGS(supportMeetingId, interventionGroupId))
      .then((res) => {
        const feedbackMeetings: Array<FeedbackMeeting> = res.data;
        dispatch(getFeedbackMeetingsSuccess(feedbackMeetings));
      })
      .catch((err: AxiosError) => {
        dispatch(getFeedbackMeetingsFailure(err.message));
      });
  };
};

export const createFeedbackMeetingStart = () =>
  action(MeetingModuleActionTypes.CREATE_FEEDBACK_MEETING_START);
export const createFeedbackMeetingSuccess = (meeting: FeedbackMeeting) =>
  action(MeetingModuleActionTypes.CREATE_FEEDBACK_MEETING_SUCCESS, {
    meeting,
  });
export const createFeedbackMeetingFailure = (message: string) =>
  action(MeetingModuleActionTypes.CREATE_FEEDBACK_MEETING_FAILURE, {
    error: message,
  });

export const createFeedbackMeeting = (
  feedbackMeeting: Partial<FeedbackMeeting>
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(createFeedbackMeetingStart());
    return axios
      .post(API_FEEDBACK_MEETINGS(), feedbackMeeting)
      .then((res) => {
        const meeting: FeedbackMeeting = res.data;
        dispatch(createFeedbackMeetingSuccess(meeting));
        dispatch(getBadgesRequest());
      })
      .catch((err: AxiosError) => {
        dispatch(createFeedbackMeetingFailure(err.message));
      });
  };
};

export const updateFeedbackMeetingStart = () =>
  action(MeetingModuleActionTypes.UPDATE_FEEDBACK_MEETING_START);
export const updateFeedbackMeetingSuccess = (meeting: FeedbackMeeting) =>
  action(MeetingModuleActionTypes.UPDATE_FEEDBACK_MEETING_SUCCESS, {
    meeting,
  });
export const updateFeedbackMeetingFailure = (message: string) =>
  action(MeetingModuleActionTypes.UPDATE_FEEDBACK_MEETING_FAILURE, {
    error: message,
  });

export const updateFeedbackMeeting = (
  supportMeeting: Partial<FeedbackMeeting>
) => {
  return (dispatch: Dispatch) => {
    dispatch(updateFeedbackMeetingStart());
    return axios
      .put(API_FEEDBACK_MEETINGS(supportMeeting.id), supportMeeting)
      .then((res) => {
        const meeting: FeedbackMeeting = res.data;
        dispatch(updateFeedbackMeetingSuccess(meeting));
      })
      .catch((err: AxiosError) => {
        dispatch(updateFeedbackMeetingFailure(err.message));
      });
  };
};

export const getSupportMeetingsStart = () =>
  action(MeetingModuleActionTypes.GET_SUPPORT_MEETINGS_START);
export const getSupportMeetingsSuccess = (
  supportMeetings: Array<SupportMeeting>
) =>
  action(MeetingModuleActionTypes.GET_SUPPORT_MEETINGS_SUCCESS, {
    supportMeetings,
  });
export const getSupportMeetingsFailure = (message: string) =>
  action(MeetingModuleActionTypes.GET_SUPPORT_MEETINGS_FAILURE, {
    error: message,
  });

export const getSupportMeetings = (
  supportMeetingId?: number,
  interventionGroupId?: number
) => {
  return (dispatch: Dispatch) => {
    dispatch(getSupportMeetingsStart());
    return axios
      .get(API_SUPPORT_MEETINGS(supportMeetingId, interventionGroupId))
      .then((res) => {
        const supportMeetings: Array<SupportMeeting> = res.data;
        dispatch(getSupportMeetingsSuccess(supportMeetings));
      })
      .catch((err: AxiosError) => {
        dispatch(getSupportMeetingsFailure(err.message));
      });
  };
};

export const createSupportMeetingStart = () =>
  action(MeetingModuleActionTypes.CREATE_SUPPORT_MEETING_START);
export const createSupportMeetingSuccess = (meeting: SupportMeeting) =>
  action(MeetingModuleActionTypes.CREATE_SUPPORT_MEETING_SUCCESS, {
    meeting,
  });
export const createSupportMeetingFailure = (message: string) =>
  action(MeetingModuleActionTypes.CREATE_SUPPORT_MEETING_FAILURE, {
    error: message,
  });

export const createSupportMeeting = (
  supportMeeting: Partial<SupportMeeting>
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(createSupportMeetingStart());
    return axios
      .post(API_SUPPORT_MEETINGS(), supportMeeting)
      .then((res) => {
        const meeting: SupportMeeting = res.data;
        dispatch(createSupportMeetingSuccess(meeting));
        dispatch(getBadgesRequest());
      })
      .catch((err: AxiosError) => {
        dispatch(createSupportMeetingFailure(err.message));
      });
  };
};

export const updateSupportMeetingStart = () =>
  action(MeetingModuleActionTypes.UPDATE_SUPPORT_MEETING_START);
export const updateSupportMeetingSuccess = (meeting: SupportMeeting) =>
  action(MeetingModuleActionTypes.UPDATE_SUPPORT_MEETING_SUCCESS, {
    meeting,
  });
export const updateSupportMeetingFailure = (message: string) =>
  action(MeetingModuleActionTypes.UPDATE_SUPPORT_MEETING_FAILURE, {
    error: message,
  });

export const updateSupportMeeting = (
  supportMeeting: Partial<SupportMeeting>
) => {
  return (dispatch: Dispatch) => {
    dispatch(updateSupportMeetingStart());
    return axios
      .put(API_SUPPORT_MEETINGS(supportMeeting.id), supportMeeting)
      .then((res) => {
        const meeting: SupportMeeting = res.data;
        dispatch(updateSupportMeetingSuccess(meeting));
      })
      .catch((err: AxiosError) => {
        dispatch(updateSupportMeetingFailure(err.message));
      });
  };
};

export const changeSelectedPraise = (selectedPraise?: string) =>
  action(MeetingModuleActionTypes.CHANGE_SELECTED_PRAISE, { selectedPraise });

export const getProgressOverviewDataStart = () =>
  action(MeetingModuleActionTypes.GET_PROGRESS_OVERVIEW_DATA_START);
export const getProgressOverviewDataSuccess = (
  progressOverview: ProgressOverview
) =>
  action(MeetingModuleActionTypes.GET_PROGRESS_OVERVIEW_DATA_SUCCESS, {
    progressOverview,
  });
export const getProgressOverviewDataFailure = (message: string) =>
  action(MeetingModuleActionTypes.GET_PROGRESS_OVERVIEW_DATA_FAILURE, {
    error: message,
  });
export const getProgressOverviewData = (interventionGroupId: number, studentId = 0 ) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(getProgressOverviewDataStart());
    return axios
      .get(API_PROGRESS_OVERVIEW(interventionGroupId, studentId))
      .then((res) => {
        const progressOverview: ProgressOverview = res.data;
        dispatch(getProgressOverviewDataSuccess(progressOverview));
        // intervention group list updated
        if(getState().cases.interventionGroups.length) {
          let showArchivedGroups = getState().dataFilter.showArchivedGroups;
          let interventionGroups = [...getState().cases.interventionGroups?.map((interventionGroup) => {
            if (interventionGroup?.id == interventionGroupId) {
              interventionGroup = {
                ...interventionGroup,
                progress_overview: progressOverview,
              }
            }
            return interventionGroup;
          })];
  
          if(interventionGroups.length) {
            dispatch(
              dispatch(getInterventionGroupsSuccess(interventionGroups, showArchivedGroups))
            );
          }
        }
        //single intervention group updated
        if (
          getState().cases.selectedInterventionGroup?.id === interventionGroupId
        ) {
          dispatch(
            changeSelectedInterventionGroup({
              ...getState().cases.selectedInterventionGroup!,
              progress_overview: progressOverview,
            })
          );
        }
      })
      .catch((err: AxiosError) => {
        dispatch(getProgressOverviewDataFailure(err.message));
      });
  };
};

export const logProgressTowardsGoalStart = () =>
  action(MeetingModuleActionTypes.LOG_PROGRESS_TOWARDS_GOAL_START);
export const logProgressTowardsGoalSuccess = () =>
  action(MeetingModuleActionTypes.LOG_PROGRESS_TOWARDS_GOAL_SUCCESS);
export const logProgressTowardsGoalFailure = (message: string) =>
  action(MeetingModuleActionTypes.LOG_PROGRESS_TOWARDS_GOAL_FAILURE, {
    error: message,
  });
export const logProgressTowardsGoal = (studentGoals: Array<LoggedProgress>) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(logProgressTowardsGoalStart());
    return axios
      .post(API_PROGRESS_TOWARDS_GOAL(), studentGoals)
      .then((res) => {
        const interventionGroup: InterventionGroup =
          res.data.intervention_group;

        dispatch(getBadgesRequest());
        dispatch(logProgressTowardsGoalSuccess());
        dispatch(updateProgressOverview(interventionGroup));
      })
      .catch((err: AxiosError) => {
        dispatch(logProgressTowardsGoalFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const updateProgressTowardsGoalStart = () =>
  action(MeetingModuleActionTypes.UPDATE_PROGRESS_TOWARDS_GOAL_START);
export const updateProgressTowardsGoalSuccess = () =>
  action(MeetingModuleActionTypes.UPDATE_PROGRESS_TOWARDS_GOAL_SUCCESS);
export const updateProgressTowardsGoalFailure = (message: string) =>
  action(MeetingModuleActionTypes.UPDATE_PROGRESS_TOWARDS_GOAL_FAILURE, {
    error: message,
  });
export const updateProgressTowardsGoal = (
  loggedProgressId: number,
  loggedProgress: Partial<LoggedProgress>
) => {
  return (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
    dispatch(updateProgressTowardsGoalStart());
    return axios
      .put(API_PROGRESS_TOWARDS_GOAL(loggedProgressId), { ...loggedProgress })
      .then((res) => {
        const loggedProgressData: any = res.data;
        const interGroupLoggedProgress: InterventionGroup = loggedProgressData.intervention_group

        dispatch(updateProgressTowardsGoalSuccess());

        const { selectedInterventionGroup } = getState().cases;
        if (selectedInterventionGroup) {
          const updatedInterventionGroup = {
            ...selectedInterventionGroup,
            alert_reason: interGroupLoggedProgress.alert_reason,
            alert_reason_text: interGroupLoggedProgress.alert_reason_text,
            latest_activity: interGroupLoggedProgress?.latest_activity,
            ...(selectedInterventionGroup.student_goals.length 
              ? {
                  student_goals:
                    selectedInterventionGroup.student_goals.map((sg) => {
                      for (let i = 0; i < interGroupLoggedProgress.student_goals.length; i++) {
                        let studentGoal = interGroupLoggedProgress.student_goals[i];
                        if(studentGoal.id == sg.id) {
                          sg = {
                            ...sg,
                            is_met_goal: studentGoal.is_met_goal,
                            insufficient_goal_progress: studentGoal.insufficient_goal_progress
                          }
                        }
                      }
                      return sg;
                    })
                } 
              : []),
            progress_overview: {
              ...selectedInterventionGroup.progress_overview!,
              student_goal_progress: selectedInterventionGroup.progress_overview!.student_goal_progress.map(
                (sgp) => {
                  for (let i = 0; i < interGroupLoggedProgress.progress_overview!.student_goal_progress.length; i++) {
                    const studentGoalProgress = interGroupLoggedProgress.progress_overview?.student_goal_progress[i];
                    if (sgp.student_goal_id === studentGoalProgress?.student_goal_id) {
                      sgp = {
                        ...sgp,
                        goal_line_progress: sgp.goal_line_progress?.map((glp) => {
                          for (let j = 0; j < studentGoalProgress.goal_line_progress!.length; j++) {
                            const goalLineProgress = studentGoalProgress.goal_line_progress![j];
                            if (glp.id === goalLineProgress.id) {
                              glp = { ...glp, ...goalLineProgress }
                              break;
                            }
                          }
                          return glp;
                        }),
                        logged_progress: sgp.logged_progress.map((lp) => {
                          for (let j = 0; j < studentGoalProgress.logged_progress.length; j++) {
                            const loggedProgress = studentGoalProgress.logged_progress[j];
                            if (lp.id === loggedProgress.id) {
                              lp = { ...lp, ...loggedProgress }
                              break;
                            }
                          }
                          return lp;
                        }),
                      };
                      break;
                    }
                  }
                  return sgp;
                }
              ),
            },
          };
          dispatch(updateProgressOverview(updatedInterventionGroup));
        }
      })
      .catch((err: AxiosError) => {
        dispatch(updateProgressTowardsGoalFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const logInterventionStart = () =>
  action(MeetingModuleActionTypes.LOG_INTERVENTION_START);
export const logInterventionSuccess = () =>
  action(MeetingModuleActionTypes.LOG_INTERVENTION_SUCCESS);
export const logInterventionFailure = (message: string) =>
  action(MeetingModuleActionTypes.LOG_INTERVENTION_FAILURE, {
    error: message,
  });
export const logIntervention = (
  interventionGroupId: number,
  interventionId: number,
  observedDate: Date,
  selectedChecklistItemIds: number[],
  selectedNotPlannedItemIds: number[],
  notPlannedReason?: string,
  notes?: string,
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(logInterventionStart());
    return axios
      .post(API_LOG_INTERVENTION(interventionGroupId), {
        intervention_id: interventionId,
        observed_date: moment(observedDate).format("YYYY-MM-DD"),
        checklist_items: selectedChecklistItemIds,
        not_applicable_steps_ids: selectedNotPlannedItemIds,
        not_planned_reason: notPlannedReason,
        fidelity_notes: notes
      })
      .then((res) => {
        const interventionGroup: InterventionGroup =
          res.data.intervention_group;
        dispatch(getBadgesRequest());
        dispatch(logInterventionSuccess());
        dispatch(updateProgressOverview(interventionGroup));
      })
      .catch((err: AxiosError) => {
        dispatch(logInterventionFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const updateImplementationCheckStart = () =>
  action(MeetingModuleActionTypes.UPDATE_IMPLEMENTATION_CHECK_START);
export const updateImplementationCheckSuccess = () =>
  action(MeetingModuleActionTypes.UPDATE_IMPLEMENTATION_CHECK_SUCCESS);
export const updateImplementationCheckFailure = (message: string) =>
  action(MeetingModuleActionTypes.UPDATE_IMPLEMENTATION_CHECK_FAILURE, {
    error: message,
  });
export const updateImplementationCheck = (
  interventionGroupId: number,
  implCheckId: number,
  checklistItems: number[],
  notPlannedItems: number[], 
  observedDate: Date,
  notPlannedReason?: string,
  notes?: string
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(updateImplementationCheckStart());
    return axios
      .put(API_LOG_INTERVENTION(interventionGroupId, implCheckId), {
        observed_date: moment(observedDate).format("YYYY-MM-DD"),
        checklist_items: checklistItems,
        not_applicable_steps_ids: notPlannedItems,
        not_planned_reason: notPlannedReason,
        fidelity_notes: notes
      })
      .then((res) => {
        const interventionGroup: InterventionGroup =
          res.data.intervention_group;
        dispatch(updateImplementationCheckSuccess());
        dispatch(updateProgressOverview(interventionGroup));
      })
      .catch((err: AxiosError) => {
        dispatch(updateImplementationCheckFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const changePredefinedMeetingType = (meetingType: MeetingType) =>
  action(MeetingModuleActionTypes.CHANGE_PREDEFINED_MEETING_TYPE, {
    meetingType,
  });

export const linkToMeetingTab = (id: number, meetingType: MeetingType) => (
  dispatch: Dispatch
) => {
  dispatch(push(`/cases/groups/${id}/${InterventionGroupTabs.MEETING}`));
  dispatch(changePredefinedMeetingType(meetingType));
};

export const getPrisesActions = createAsyncAction(
  "GET_PRISES_REQUEST",
  "GET_PRISES_SUCCESS",
  "GET_PRISES_FAILURE"
)<void, Praise[], Error>();

export const getPrises = () => {
  return (dispatch: Dispatch) => {
    dispatch(getPrisesActions.request());
    return axios
      .get(API_STATIC_DATA_PRAISES)
      .then((res) => {
        const praises: Praise[] = res.data;
        dispatch(getPrisesActions.success(praises));
      })
      .catch((err: AxiosError) => {
        dispatch(getPrisesActions.failure(err));
        return Promise.reject(err.message);
      });
  };
};
