import React, { FunctionComponent, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { InterventionFidelity } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";

type OwnProps = {
  interventionFidelity: Array<InterventionFidelity>;
};

type Props = OwnProps;

const InterventionFidelityTable: FunctionComponent<Props> = ({
  interventionFidelity,
}) => {
  const columns = useMemo(
    () => [
      {
        dataField: "coachId",
        text: "Coach Id",
        hidden: true,
      },
      {
        dataField: "coach",
        text: "Coach Name",
        headerStyle: { width: "50%" },
        sort: true,
      },
      {
        dataField: "avg_fidelity",
        text: "Fidelity Average",
        sort: true,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      interventionFidelity.map((intFidelity) => ({
        coachId: intFidelity.coach.id,
        coach: getFullName(intFidelity.coach),
        avg_fidelity:
          (intFidelity.avg_fidelity * 100).toFixed(2).replace(".00", "") + "%",
      })),
    [interventionFidelity]
  );

  return (
    <BootstrapTable
      classes="rosterTable"
      keyField="id"
      bordered={false}
      bootstrap4
      data={data}
      columns={columns}
      noDataIndication={"No data"}
    />
  );
};

export default InterventionFidelityTable;
