import React, { useEffect, useMemo, useState } from "react";
import LoadingIndicator from "../../../../LoadingIndicator";
import { CustomRadioButton } from "../CustomRadioButton";
import { UserInfo } from "../../../../../../../store/auth/types";
import {
  BasicUserInfo,
  TeacherInfo,
} from "../../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  getTeachers,
  openTeacherInviteIndividuallyModal,
} from "../../../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import { getFullName } from "../../../../../../../utils/NamesUtils";
import { toastr } from "react-redux-toastr";

type StateProps = {
  teachersRoster: Array<TeacherInfo>;
  errors: {
    getTeachers?: string;
  };
  isLoading: {
    getTeachers: boolean;
  };
};

type DispatchProps = {
  openTeacherInviteIndividuallyModal: (newTeacher?: BasicUserInfo) => any;
  getTeachers: () => any;
};

type OwnProps = {
  selectedTeacher?: UserInfo;
  onSelectedTeacherConfirm: (teacherInfo: UserInfo) => () => void;
};

type Props = StateProps & DispatchProps & OwnProps;

const SelectTeacherModalBody = ({
  selectedTeacher: teacher,
  teachersRoster,
  onSelectedTeacherConfirm,
  getTeachers,
  openTeacherInviteIndividuallyModal,
  isLoading: { getTeachers: isLoading },
  errors: { getTeachers: error },
}: Props) => {
  const [teachersFilter, setTeachersFilter] = useState("");
  const [filteredTeachersRoster, setFilteredTeachersRoster] = useState<
    Array<TeacherInfo>
  >([]);
  const [selectedTeacher, setSelectedTeacher] = useState(teacher);
  useEffect(() => {
    getTeachers();
  }, [getTeachers]);
  useEffect(() => {
    if (error) {
      toastr.error("Error", error);
    } else {
      setFilteredTeachersRoster(teachersRoster.filter(filterTeachers()));
    }
  }, [teachersRoster]);

  const filterTeachers = (value: string = teachersFilter || "") => (
    teacher: TeacherInfo
  ) => {
    return getFullName(teacher.user)
      .toLowerCase()
      .includes(value.toLowerCase());
  };

  const onTeacherFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTeachersFilter(value);
    setFilteredTeachersRoster(teachersRoster.filter(filterTeachers(value)));
  };

  const onSelectedTeacherChange = (teacher: TeacherInfo) => () => {
    setSelectedTeacher(teacher.user);
  };

  return (
    <>
      <h3 className="font-weight-bold">
        Select one of your educators below to conduct an Interview
      </h3>
      <p>
        We'll provide you with helpful material that you can print out conduct
        the interview offline and enter the information later.
      </p>

      <div>
        <div className="mb-3 d-flex justify-content-end">
          <button
            className="blueBtnSm mr-3"
            onClick={() => openTeacherInviteIndividuallyModal()}
          >
            Invite Teachers
          </button>

          <input
            type="text"
            placeholder="Search teachers..."
            value={teachersFilter || ""}
            onChange={onTeacherFilterChange}
            className="stdInput"
          />
        </div>
        <table className="scrollableTable">
          <thead>
            <tr>
              <th />
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={2}>
                  <LoadingIndicator />
                </td>
              </tr>
            ) : filteredTeachersRoster.length ? (
              filteredTeachersRoster.map((teacher) => (
                <tr
                  key={teacher.user.id}
                  onClick={onSelectedTeacherChange(teacher)}
                >
                  <td className="d-flex">
                    <CustomRadioButton
                      checked={
                        !!selectedTeacher &&
                        selectedTeacher.id === teacher.user.id
                      }
                    />
                  </td>
                  <td>
                    {teacher.user.first_name} {teacher.user.last_name}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2}>No matching teachers</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <hr />
      <div className="modalActions">
        <div />
        <button
          className="blueBtnSm"
          disabled={!selectedTeacher}
          onClick={onSelectedTeacherConfirm(selectedTeacher!)}
        >
          Continue
        </button>
      </div>
    </>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    errors: {
      getTeachers: onboarding.errors.getTeachers,
    },
    isLoading: {
      getTeachers: onboarding.isLoading.getTeachers,
    },
    teachersRoster: onboarding.teachersRoster,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getTeachers: getTeachers,
      openTeacherInviteIndividuallyModal: openTeacherInviteIndividuallyModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectTeacherModalBody);
