import DataFileReader from "../../../../../containers/student-data-upload/FileReader";
import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { Assessment } from "../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../store";
import { connect } from "react-redux";
import { IS_READY_COACH } from "../../../../../constants";

library.add(faFile);

type PropsFromState = {
  assessments: Array<Assessment>;
};

type OwnProps = {
  originalFileName: string;
  rawData: Array<any>;
};

type Props = OwnProps & PropsFromState;

const UploadInfo = (props: Props) => {
  return (
    <div className="text-center">
      {IS_READY_COACH ? (
        <h2 className="font-weight-semibold">Great, you uploaded:</h2>
      ) : null}
      <div>
        <FontAwesomeIcon icon="file" />
        <h4 className="font-weight-bold d-inline blackText">
          &nbsp;{props.originalFileName}
        </h4>
        <h5>
          It has {props.rawData.length} rows. &nbsp;
          <label className="clickableLabel">
            Click here to pick a different file.
            <DataFileReader />
          </label>
        </h5>
      </div>
    </div>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    assessments: onboarding.assessments,
  };
};

export default connect(mapStateToProps)(UploadInfo);
