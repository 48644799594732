import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import ProfileHeader from "../ProfileHeader";
import { Link } from "react-router-dom";
import { openChangePasswordModal } from "../../../../../store/onboarding/cases/actions";
import { getNotificationSettings } from "../../../../../store/notifications/actions";
import { IS_READY_COACH } from "../../../../../constants";
import { useIntl } from "react-intl";

type PropsFromState = {
  isLoading: {
    loadUserInfo: boolean;
  };
};

type DispatchProps = {
  openChangePasswordModal: () => any;
  getNotificationSettings: () => any;
};

type Props = PropsFromState & DispatchProps;

const ProfileTab = (props: Props) => {
  const handleChangePassword = () => {
    props.openChangePasswordModal();
  };

  const intl = useIntl();

  return (
    <div className="dataContainer">
      <ProfileHeader />
      <div className="mt-3">
        <h2 className="mb-2">
          {intl.formatMessage({
            id: "app.dashboard.profile.tab.links.title",
          })}
        </h2>
        <div className="profileHelpfulLinks">
          <div className="blueBtnMd w-100 mb-3" onClick={handleChangePassword}>
            {intl.formatMessage({
              id: "app.dashboard.profile.changePasswordBtn.label",
            })}
          </div>
          <Link
            to={IS_READY_COACH ? "settings/notifications" : "/notifications"}
            onClick={props.getNotificationSettings}
          >
            <div className="blueBtnMd w-100">
              {intl.formatMessage({
                id: "app.dashboard.profile.manageNotificationsBtn.label",
              })}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }: ApplicationState): PropsFromState => {
  return {
    isLoading: {
      loadUserInfo: auth.isLoading.loadUserInfo,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openChangePasswordModal: openChangePasswordModal,
      getNotificationSettings: getNotificationSettings,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProfileTab);
