import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  BenchmarkStatus,
  BenchmarkStatusDefaultColors,
  BenchmarkStatusDisplayedNames,
  ColorCriteria,
  EvidenceData,
  Measurement,
  measurementOptionsList,
  MeasurementType,
  MeasurementTypeDisplayedNames,
} from "../../../../../store/onboarding/types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import EditMeasureColorCriteriaModal from "../../../color-picker/EditMeasureColorCriteriaModal";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  createAssessment,
  getEvidenceAssessments,
  hideAddAssessmentModal,
  hideAddProgressMonitorModal,
  openAddAssessmentModal,

} from "../../../../../store/onboarding/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Col, Form, OverlayTrigger, Spinner, Table, Tooltip } from "react-bootstrap";
import EditPossibleValuesModal from "./EditPossibleValuesModal";
import { useLoading } from "../../../../../utils/hooks/useLoading";
import ReadingSkillSelector from "./ReadingSkillSelector";
import { getSkillFocuses } from "../../../../../store/groups/actions";
import { CasesState, GoalTypes } from "../../../../../store/onboarding/cases/types";
import {
  Grade,
  SkillFocusArea,
  TimePeriod,
} from "../../../../../store/groups/types";
import _ from "lodash";
import { toastr } from "react-redux-toastr";

type PropsFromState = {
  showModal: boolean;
  enterDataManuallyType?: GoalTypes;
  isNewProgressMonitoring?: boolean;
  isRedirectToSetGoal?: boolean;
  isLoading: {
    createAssessment: boolean;
  };
  errors: {
    createAssessment?: string;
  };
};

type DispatchProps = {
  hideAddAssessmentModal: () => any;
  hideAddProgressMonitorModal: () => any
  openAddAssessmentModal: () => any;
  createAssessment: (assessment: Partial<EvidenceData>, isGeneralOutcomeMeasure?: boolean) => any;
};

type Props = PropsFromState & DispatchProps;

const CreateAssessmentModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const currentUser: any = useSelector(
    (s: ApplicationState) => s.auth.userInfo
  );

  const {
    selectedStudentGoal
  } = useSelector<ApplicationState, CasesState>((s) => s.cases);

  const dataColumnFormEl = useRef<HTMLFormElement>(null);
  const [useGradeSpecificColor, setUseGradeSpecificColor] = useState<boolean>(
    false
  );
  const [withReadingAlgorithm, setWithReadingAlgorithm] = useState<boolean>(
    false
  );

  const tabName:any = useSelector<ApplicationState>((s)=> s.cases.tabName);

  const supportedGrades: Grade[] = useMemo(() => {
    return ["K", "G1", "G2", "G3"];
  }, []);
  const timePeriods = useMemo(() => {
    return Object.values(TimePeriod);
  }, []);
  const benchmarkStatuses = useMemo(() => {
    return Object.values(BenchmarkStatus);
  }, []);

  const raBenchmarkForNumericType = useMemo(() => {
    const colorCriteriaList: ColorCriteria[] = [];
    supportedGrades.forEach((grade) => {
      timePeriods.forEach((timePeriod) => {
        benchmarkStatuses.forEach((benchmarkStatus) => {
          const colorCriteria: ColorCriteria = {
            display_name: BenchmarkStatusDisplayedNames[benchmarkStatus],
            color: BenchmarkStatusDefaultColors[benchmarkStatus],
            grade: grade,
            time_period: timePeriod,
            benchmark_status: benchmarkStatus,
          };
          colorCriteriaList.push(colorCriteria);
        });
      });
    });

    return colorCriteriaList;
  }, [supportedGrades, timePeriods, benchmarkStatuses]);

  const raBenchmarkForCategoryType: Pick<
    Measurement,
    "possible_values" | "color_criteria" | "type"
  > = useMemo(() => {
    return {
      possible_values: benchmarkStatuses.map((benchmarkStatus, index) => ({
        display_name: BenchmarkStatusDisplayedNames[benchmarkStatus],
        order: index + 1,
      })),
      color_criteria: benchmarkStatuses.map((benchmarkStatus, index) => ({
        order: index + 1,
        selected_value_order: index + 1,
        display_name: BenchmarkStatusDisplayedNames[benchmarkStatus],
        color: BenchmarkStatusDefaultColors[benchmarkStatus],
      })),
    };
  }, [benchmarkStatuses]);

  const newMeasurement: Measurement = useMemo(
    () => ({
      display_name: "",
      column_index: 0,
      column_name: "",
      type: MeasurementType.NUMBER,
      reading_skill: undefined,
      color_criteria: useGradeSpecificColor
        ? raBenchmarkForNumericType
        : undefined,
    }),
    [useGradeSpecificColor]
  );

  const [assessmentName, setAssessmentName] = useState<string>("");
  const [studentIdColumnHeader, setStudentIdColumnHeader] = useState<string>(
    ""
  );
  const [studentFNameColumnHeader, setStudentFNameColumnHeader] = useState<
    string
  >("");
  const [studentLNameColumnHeader, setStudentLNameColumnHeader] = useState<
    string
  >("");
  const [studentTeacherColumnHeader, setStudentTeacherColumnHeader] = useState<
    string
  >("");
  const [studentGradeColumnHeader, setStudentGradeColumnHeader] = useState<
    string
  >("");
  const [studentRaceColumnHeader, setStudentRaceColumnHeader] = useState<
    string
  >("");
  const [measurements, setMeasurements] = useState<Measurement[]>([]);
  const [editMeasureColorIndex, setEditMeasureColorIndex] = useState<number>(0);
  const [
    showEditMeasureColorCriteriaModal,
    setShowEditMeasureColorCriteriaModal,
  ] = useState<boolean>(false);
  const [
    showEditPossibleValuesModal,
    setShowEditPossibleValuesModal,
  ] = useState<boolean>(false);
  const [selectedMeasurement, setSelectedMeasurement] = useState<
    Measurement | undefined
  >(undefined);

  // let initialSearchObj = {
  //   filterIndex: 0,
  //   filterGrade: [],
  //   filterAdditionalBanchmark: true,
  //   selectForK: [],
  //   selectForG1: [],
  //   selectForG2: [],
  //   selectForG3: [],
  // }

  // const [selectedSearchFilter, setSelectedSearchFilter] = useState<any>(initialSearchObj);

  // const [searchFilter, setSearchFilter] = useState<any[]>([initialSearchObj]);

  useLoading(
    props.isLoading.createAssessment,
    props.errors.createAssessment,
    () => props.hideAddAssessmentModal(),
    () => props.hideAddProgressMonitorModal()
  );

  const basicColumnsHeadersSelected = useMemo(() => {
    return studentIdColumnHeader &&
    studentFNameColumnHeader &&
    studentLNameColumnHeader;
  },[])

  const handleModalShow = () => {
    console.log(selectedStudentGoal)

    setAssessmentName("");
    setStudentIdColumnHeader("");
    setStudentFNameColumnHeader("");
    setStudentLNameColumnHeader("");
    setMeasurements([newMeasurement]);
    setShowEditMeasureColorCriteriaModal(false);
    setShowEditPossibleValuesModal(false);
    setSelectedMeasurement(undefined);
    (props.enterDataManuallyType == 'academic') && refreshColorCriteria(true);
    setUseGradeSpecificColor(props.enterDataManuallyType == 'academic' ? true : false);
    setWithReadingAlgorithm(false);
   // setSearchFilter([initialSearchObj]);
  };

  useEffect(() => {
    if (useGradeSpecificColor) {
      dispatch(getSkillFocuses());
    }
  }, [useGradeSpecificColor]);

  const handleAssessmentNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setAssessmentName(value);
  };

  const handleStudentIdColumnHeaderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setStudentIdColumnHeader(value);
  };

  const handleStudentFNameColumnHeaderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setStudentFNameColumnHeader(value);
  };

  const handleStudentLNameColumnHeaderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setStudentLNameColumnHeader(value);
  };

  // const handleStudentTeacherColumnHeaderChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { value } = event.target;
  //   setStudentTeacherColumnHeader(value);
  // };

  // const handleStudentGradeColumnHeaderChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { value } = event.target;
  //   setStudentGradeColumnHeader(value);
  // };

  // const handleStudentRaceColumnHeaderChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { value } = event.target;
  //   setStudentRaceColumnHeader(value);
  // };

  const handleMeasurementAdd = (event: React.FormEvent<any>) => {
    event.preventDefault();
    setMeasurements((measurements) => [...measurements, newMeasurement]);
  };

  const getDefaultTypeValues = (
    measureType: MeasurementType
  ): Partial<Measurement> => {
    let defaultValues: Partial<Measurement>;
    switch (measureType) {
      case MeasurementType.NUMBER:
        defaultValues = {
          range_min: 200,
          range_max: 800,
          possible_values: undefined,
          color_criteria: useGradeSpecificColor
            ? raBenchmarkForNumericType
            : undefined,
        };
        break;
      case MeasurementType.PERCENTAGE:
        defaultValues = {
          range_min: 0,
          range_max: 100,
          possible_values: undefined,
          color_criteria: useGradeSpecificColor
            ? raBenchmarkForNumericType
            : undefined,
        };
        break;
      case MeasurementType.CATEGORY:
        defaultValues = {
          range_min: undefined,
          range_max: undefined,
          ...raBenchmarkForCategoryType,
        };
        break;
      default:
        defaultValues = {};
        break;
    }
    return defaultValues;
  };

  const handleMeasurementChange = (changingIndex: number) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    console.log(name, value);

    setMeasurements((measurements) => {
      return measurements.map((measurement: Measurement, index: number) => {
        if (index === changingIndex) {
          if (name === "type") {
            const defTypeValues = getDefaultTypeValues(+value);
            return {
              ...measurement,
              ...defTypeValues,
              type: +value,
            };
          }
          return {
            ...measurement,
            [name]: value,
          }
        }
        return measurement;
      });
    });
  };

  const handleMeasurementRemove = (changingIndex: number) => () => {
    setMeasurements((measurements) => {
      return measurements.filter(
        (measurement: Measurement, index: number) => !(index === changingIndex)
      );
    });
  };

  const createRangeTd = (measurement: Measurement, index: number) => {
    switch (measurement.type) {
      case MeasurementType.NUMBER:
        return (
          <div className={"form-inline"}>
            <div>
              <input
                required
                type="number"
                name="range_min"
                placeholder="Min"
                className="inputSm"
                value={measurement.range_min || ""}
                onChange={handleMeasurementChange(index)}
              />
            </div>
            <strong>&nbsp;-&nbsp;</strong>
            <div>
              <input
                required
                type="number"
                name="range_max"
                placeholder="Max"
                className="inputSm"
                value={measurement.range_max || ""}
                onChange={handleMeasurementChange(index)}
              />
            </div>
          </div>
        );
      case MeasurementType.PERCENTAGE:
        return <div className="rangeDiv"> 0 - 100 </div>;
      case MeasurementType.CATEGORY:
      default:
        return (
          <Button
            disabled={useGradeSpecificColor}
            variant="primary"
            size="sm"
            onClick={() => {
              setSelectedMeasurement({...measurement, column_index: index});
              setShowEditPossibleValuesModal(true);
            }}
          >
            <div className="rangeDiv" style={{ cursor: "pointer" }}>
              Select
            </div>
          </Button>
        );
    }
  };

  const handleCreateAssessment = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const measurementArray = [...measurements.map((measurement)=> {
      return {
        ...measurement,
        color_criteria: measurement.color_criteria?.filter((data:any)=> (data.order != undefined))
      }
    })];
    //console.log('measurements', measurementArray);

    let isScoreRangeExist: boolean = true;
    for (let i = 0; i < measurementArray.length; i++) {
      const measurement = measurementArray[i];
      if(!measurement.color_criteria?.length){
        isScoreRangeExist = false;
        break;
      }
    }
    //console.log('isScoreRangeExist', isScoreRangeExist);

    if (dataColumnFormEl.current?.reportValidity() && isScoreRangeExist) {
     
      const assessment: Partial<EvidenceData> = {
        first_name_column: studentFNameColumnHeader,
        last_name_column: studentLNameColumnHeader,
        unique_id_column: studentIdColumnHeader,
        teacher_column: studentTeacherColumnHeader,
        grade_column: studentGradeColumnHeader,
        race_column: studentRaceColumnHeader,
        assessment: {
          with_reading_algorithm: withReadingAlgorithm,
          use_grade_specific_color_criteria: useGradeSpecificColor,
          is_behavior: props.enterDataManuallyType == "academic" ? false : true,
          name: assessmentName,
          data_columns: useGradeSpecificColor
            ? measurements.map((m) => ({
                ...m,
                color_criteria: [],
                grade_specific_color_criteria: _.chain(m.color_criteria || [])
                  .filter((data:any)=> (data.range_max !== undefined) || (data.range_min !== undefined))
                  .groupBy((cc) => cc.grade)
                  .mapValues((cList) =>
                    _.chain(cList)
                      .groupBy((cc) => cc.time_period)
                      .value()
                  )
                  .value(),
              }))
            : measurements,
        },
      };
      //console.log('assessment', assessment); return;
      props.createAssessment(assessment);
    } else {       
      toastr.error("Unable to create assessment", "Color criteria(s) missing for one of the Data Column(s)");   
      return; 
    }
  };

  const handleCreateProgressMonitoringAssessment = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const assessment: Partial<EvidenceData> = {
      first_name_column: 'first_name_column',
      last_name_column: 'last_name_column',
      unique_id_column: 'unique_id_column',
      // teacher_column: 'teacher_column',
      // grade_column: 'grade_column',
      // race_column: 'race_column',
      assessment: {
        with_reading_algorithm: false,
        use_grade_specific_color_criteria: false,
        is_behavior: tabName == 'groups/behavior' ? true : false,
        name: assessmentName,
        data_columns:  [
          {
              display_name: assessmentName,
              column_index: 0,
              column_name: assessmentName,
              type: 0,
              color_criteria: [
                  {
                      benchmark_status: "below",
                      display_name: assessmentName,
                      range_min: 1,
                      range_max: 100,
                      order: 1,
                      color: "#ffd1da",
                      user_id: currentUser!.id
                  }
              ],
              range_min: 1,
              range_max: 100
          }
      ],
      },
    };

    props.createAssessment(assessment);

  };

  const handleHideAddAssessmentModal = () => {
    if (!props.isLoading.createAssessment) {
      if(props.isNewProgressMonitoring) {
        if(props.isRedirectToSetGoal) {
          dispatch(getEvidenceAssessments());
        }
        props.hideAddProgressMonitorModal();
      } else {
        setTimeout(() => {
          props.hideAddAssessmentModal();
        }, 1);
      }
    }
  };

  const handleApplyColorCriteriaChanges = async (updatedMeasurement: Measurement) => {
    await setShowEditMeasureColorCriteriaModal(false);
    await setSelectedMeasurement(undefined);
    const measurementArray = [...measurements.map(
      (measurement: Measurement): Measurement => {
        if ((measurement.column_name === updatedMeasurement.column_name) && (measurement.column_index == updatedMeasurement.column_index)) {
          return {
            ...measurement,
            color_criteria: updatedMeasurement.color_criteria,
          };
        }
        return measurement;
      }
    )]
    await setMeasurements(measurementArray);
  };

  const handleApplyPossibleValuesChange = (updatedMeasurement: Measurement) => (
    event: React.FormEvent
  ) => {
    event.preventDefault();
    setShowEditPossibleValuesModal(false);
    setSelectedMeasurement(undefined);
    setMeasurements((measurements) =>
      measurements.map(
        (measurement: Measurement): Measurement => {
          if (measurement.column_name === updatedMeasurement!.column_name) {
            return updatedMeasurement;
          }
          return measurement;
        }
      )
    );
  };

  const handleEditColorCriteriaModalOpen = (measurement: Measurement, index: number) => () => {
    // if(!searchFilter.some((search:any)=> search.filterIndex === index)) {
    //   if(selectedSearchFilter.filterIndex !== index) {
    //     initialSearchObj['filterIndex'] = index;
    //     searchFilter.push(initialSearchObj);
    //     setSearchFilter(searchFilter)
    //   }
    // } 

    // setSelectedSearchFilter(searchFilter[index]);
    setEditMeasureColorIndex(index);
    setSelectedMeasurement(measurement);
    setShowEditMeasureColorCriteriaModal(true);
  };

  const handleEditColorCriteriaModalHide = () => {
    setShowEditMeasureColorCriteriaModal(false);
  };

  const handleApplyPossibleValuesModalHide = () => {
    setShowEditPossibleValuesModal(false);
  };

  const unfulfilledMeasurement = (measurement: Measurement): boolean => {
    if (!measurement.display_name || !measurement.column_name) return true;
    if (measurement.type === MeasurementType.CATEGORY) {
      return (
        !measurement.possible_values || measurement.possible_values.length === 0
      );
    } else {
      return (
        measurement.range_min === undefined ||
        measurement.range_max === undefined
      );
    }
  };

  const areas = useSelector<ApplicationState, SkillFocusArea[]>(
    (s) => s.groups.skillFocusInfo
  );
  const skills = useMemo(() => {
    return useGradeSpecificColor
      ? areas.flatMap(
          (a) =>
            a.skill_focuses?.map((sf) => ({
              label: sf.display_name,
              value: sf.identifier,
            })) ?? []
        )
      : [];
  }, [areas, useGradeSpecificColor]);

  const skill_areas = useMemo(() => {
    return useGradeSpecificColor
      ? areas?.map((sf) => ({
          label: sf.display_name,
          value: sf.identifier,
        })) ?? []
      : [];
  }, [areas, useGradeSpecificColor]);

  const refreshColorCriteria = (checked: boolean) => {
    setMeasurements((measurements) =>
      measurements.map((m) => {
        return {
          ...m,
          ...(m.type === MeasurementType.CATEGORY
            ? raBenchmarkForCategoryType
            : { color_criteria: checked ? raBenchmarkForNumericType : [] }),
        };
      })
    );
  };

  // const handleSearchFilter = (selectedSearchFilter:any) => {
  //   if(searchFilter.some((search:any)=> search.filterIndex === selectedSearchFilter.filterIndex)) {
  //     let copyObj = {...selectedSearchFilter};
  //     searchFilter[copyObj.filterIndex] = copyObj;
  //     setSearchFilter(searchFilter);
  //     setSelectedSearchFilter(searchFilter[copyObj.filterIndex]);
  //   } 
  // }

  return (
    <Modal
      animation={false}
      size="lg"
      style={{ backgroundColor: "rgb(255,255,255,0.8)" }}
      backdropClassName="customDarkModalBackdrop in"
      show={props.showModal}
      onShow={handleModalShow}
      onHide={() => handleHideAddAssessmentModal()}
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>
          {props.isNewProgressMonitoring 
            ? `Add Progress Monitoring Assessment​`
            :`Add Assessment`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h2 className="font-weight-semibold pb-4">
        {props.isNewProgressMonitoring 
            ? `Create your own assessment to monitor student goal progress​​`
            :`Create your own student assessments`}
        </h2>
        {!props.isNewProgressMonitoring ? <hr className="narrowMargin" /> : ''}
        <Form id={"assessmentBasicInfo"} onSubmit={
                                          props.isNewProgressMonitoring 
                                          ? handleCreateProgressMonitoringAssessment
                                          : handleCreateAssessment
                                          }>
        {props.isNewProgressMonitoring ? 
          <>
            <Form.Group controlId="assessmentName">
              <Form.Label className="font-weight-bold text-left">
                Assessment Name
              </Form.Label>
              <Form.Control
                required
                value={assessmentName}
                onChange={handleAssessmentNameChange}
                name={"assessmentName"}
                type="text"
                placeholder="Name your assessment (e.g. SAT, Behavior Score)"
              />
            </Form.Group>
          </>
        : 
          <>
          <Form.Group controlId="assessmentName">
              <Form.Label className="font-weight-bold text-left">
                Assessment Name
              </Form.Label>
              <Form.Control
                required
                value={assessmentName}
                onChange={handleAssessmentNameChange}
                name={"assessmentName"}
                type="text"
                placeholder="Name your assessment (e.g. SAT, Behavior Score)"
              />
            </Form.Group>
            <p className="assessmentCopyText">
            If you plan to upload this assessment in the future using CSV/Excel
            files, enter the column names ReadyCoach should look for. If not,
            you may enter: StudentID, FirstName, and LastName.
          </p>
          <Form.Row>
            <Col>
              <Form.Group controlId="studentIdColumn">
                <Form.Label className="font-weight-bold text-left">
                  Student ID Column
                </Form.Label>
                <Form.Control
                  required
                  value={studentIdColumnHeader}
                  onChange={handleStudentIdColumnHeaderChange}
                  name={"studentIdColumnHeader"}
                  placeholder="Enter Student ID Column Name"
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="studentFNameColumnHeader">
                <Form.Label className="font-weight-bold text-left">
                  Student First Name Column
                </Form.Label>
                <Form.Control
                  required
                  value={studentFNameColumnHeader}
                  onChange={handleStudentFNameColumnHeaderChange}
                  name={"studentFNameColumnHeader"}
                  placeholder="Enter Student First Name Column Name"
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="studentFNameColumnHeader">
                <Form.Label className="font-weight-bold text-left">
                  Student Last Name Column
                </Form.Label>
                <Form.Control
                  required
                  value={studentLNameColumnHeader}
                  onChange={handleStudentLNameColumnHeaderChange}
                  name={"studentLNameColumnHeader"}
                  placeholder="Enter Student Last Name Column Name"
                  type="text"
                />
              </Form.Group>
            </Col>
          </Form.Row>
          {/* <Form.Row>
            <Col>
              <Form.Group controlId="studentTeacherColumnHeader">
                <Form.Label className="font-weight-bold text-left">
                  Teacher Column Name
                </Form.Label>
                <Form.Control
                  value={studentTeacherColumnHeader}
                  onChange={handleStudentTeacherColumnHeaderChange}
                  name={"studentTeacherColumnHeader"}
                  placeholder="Enter Teacher Column Name"
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="studentGradeColumnHeader">
                <Form.Label className="font-weight-bold text-left">
                  Grade Column Name
                </Form.Label>
                <Form.Control
                  value={studentGradeColumnHeader}
                  onChange={handleStudentGradeColumnHeaderChange}
                  name={"studentGradeColumnHeader"}
                  placeholder="Enter Grade Column Name"
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="studentRaceColumnHeader">
                <Form.Label className="font-weight-bold text-left">
                  Race Column Name
                </Form.Label>
                <Form.Control
                  value={studentRaceColumnHeader}
                  onChange={handleStudentRaceColumnHeaderChange}
                  name={"studentRaceColumnHeader"}
                  placeholder="Enter Race Column Name"
                  type="text"
                />
              </Form.Group>
            </Col>
          </Form.Row> */}
          {props.enterDataManuallyType == "academic" ? (
            <>
              <p className="assessmentCopyText">
              Click the first checkbox below if you would like to use grade and time period specific color criteria.
              </p>
              <p className="assessmentCopyText">
                Click the second checkbox if you you would like to use this assessment with the ReadyCoach reading algorithms, 
                if checked, you will be asked to map your assessment’s Data Columns to reading skills in the table below.
              </p>
              <div className="row mt-3">
                <div className="col-5">
                  <Form.Check
                    id="use_grade_specific_color"
                    className="mb-3 font-weight-bold"
                    checked={useGradeSpecificColor}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      refreshColorCriteria(checked);
                      setUseGradeSpecificColor(checked);
                      if(!checked) {
                        setWithReadingAlgorithm(false);
                      }
                    }}
                    label="Use Grade and Time Period Specific Color Criteria"
                    custom
                    type="checkbox"
                  />
                </div>
                <div className="col-7 pl-0">
                  {(props.enterDataManuallyType == "academic") &&  useGradeSpecificColor == false && (
                    <div className="alert alert-warning py-1" role="alert">
                      Benchmarks will apply to all grades and time periods.
                    </div>
                  )}
                </div>
              </div>
              {useGradeSpecificColor ?
                <div className="row">
                  <div className="col-4">
                    <Form.Check
                      id="with_reading_algorithm"
                      className="mb-3 font-weight-bold"
                      checked={withReadingAlgorithm}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setWithReadingAlgorithm(checked);
                        if(!checked) {
                          setMeasurements((measurements) =>
                          measurements.map((measure) => {
                            return {
                              ...measure,
                              reading_skill: ""
                            }
                          }
                          )
                          );
                        }
                      }}
                      label="Use with Reading Algorithm"
                      custom
                      type="checkbox"
                    />
                  </div>
                </div>
              : ''
              }
            </>
          ) : (
            ""
          )}
          </>
        }
        </Form>
        {!props.isNewProgressMonitoring ? 
          <>    
            <h6 className="font-weight-bold">Data columns</h6>
            <hr className="m-1" />
            <div className="selectDataColumnTableContainer">
              <Form
                id="newMeasurementForm"
                onSubmit={handleMeasurementAdd}
                ref={dataColumnFormEl} // required to confirm the parental form
              >
                <Table className="selectDataColumnTable">
                  <thead>
                    <tr>
                      <th>#</th>
                        <OverlayTrigger
                            trigger={"hover"}
                            placement="top"
                            overlay={
                              <Tooltip className="customTooltip" id="tooltip-top-column">
                                Displayed on the column header in the data period.
                              </Tooltip>
                            }
                            >
                        <th>Column Name <br/><small>(Eg. PSF )</small></th>
                        </OverlayTrigger>
                        <OverlayTrigger
                            trigger={"hover"}
                            placement="top"
                            overlay={
                              <Tooltip className="customTooltip" id="tooltip-top-display">
                                Displayed on the hover of the column header in the data period.
                              </Tooltip>
                            }
                          >
                          <th>Display Name <br/><small>(Eg. Phonemic Segmentation Fluency)</small></th>
                        </OverlayTrigger>
                      <th>Type</th>
                      {withReadingAlgorithm && <th>Reading Areas</th>}
                      <th>Range</th>
                      <th>Color criteria</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {measurements.map((measurement, index) => {
                      if((measurement.color_criteria !== undefined) 
                      && (newMeasurement.color_criteria !== undefined) 
                      && (measurement.color_criteria.length < newMeasurement.color_criteria!.length)) {
                        newMeasurement.color_criteria!.filter((element) => {
                          
                          if(!measurement.color_criteria!.find((data)=> data.grade == element.grade))  {
                            measurement.color_criteria!.push(element)
                          }
                          
                        });
                      }
                      return (<tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Form.Control
                            required
                            name="column_name"
                            value={measurement.column_name}
                            onChange={handleMeasurementChange(index)}
                            placeholder="Column name"
                          />
                        </td>
                        <td>
                          <Form.Control
                            required
                            name="display_name"
                            value={measurement.display_name}
                            onChange={handleMeasurementChange(index)}
                            placeholder="Display name"
                          />
                        </td>
                        <td>
                          <Form.Control
                            required
                            name="type"
                            as="select"
                            value={(measurement.type as unknown) as string}
                            onChange={handleMeasurementChange(index)}
                          >
                            {measurementOptionsList.map(
                              (measureType: string, index) => (
                                <option value={+measureType} key={index}>
                                  {
                                    MeasurementTypeDisplayedNames[
                                      (measureType as unknown) as MeasurementType
                                    ]
                                  }
                                </option>
                              )
                            )}
                          </Form.Control>
                        </td>
                        {withReadingAlgorithm && (
                          <td style={{ width: "190px" }}>
                            <ReadingSkillSelector
                              value={measurement.reading_skill}
                              skills={skill_areas}
                              onChange={(skill) => {
                                setMeasurements((measurements) =>
                                  measurements.map((m, mIndex) =>
                                    index === mIndex
                                      ? { ...m, reading_skill: skill }
                                      : m
                                  )
                                );
                              }}
                            />
                          </td>
                        )}
                        <td>{createRangeTd(measurement, index)}</td>
                        <td>
                          <Button
                            variant="link"
                            size="sm"
                            disabled={unfulfilledMeasurement(measurement)}
                            onClick={handleEditColorCriteriaModalOpen(measurement, index)}
                          >
                            Edit
                          </Button>
                        </td>
                        <td>
                          <Button onClick={handleMeasurementRemove(index)}>
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="pointer"
                            />
                          </Button>
                        </td>
                      </tr>)
                    })}
                  </tbody>
                </Table>
              </Form>

              <div className="text-right mb-2">
                <Button
                  type="submit"
                  form="newMeasurementForm"
                  bsPrefix="b"
                  className="blueBtnSm"
                >
                  Add
                </Button>
              </div>
            </div>
            <hr className="narrowMargin" />
            {showEditMeasureColorCriteriaModal && <EditMeasureColorCriteriaModal
              withGrades={useGradeSpecificColor}
              useGradeSpecificColor={useGradeSpecificColor}
              withReadingAlgorithm = {withReadingAlgorithm}
              onApplyChanges={handleApplyColorCriteriaChanges}
              onHide={handleEditColorCriteriaModalHide}
              measurement={selectedMeasurement}
              showModal={showEditMeasureColorCriteriaModal}
              editMeasureColorIndex={editMeasureColorIndex}
              // searchFilter={selectedSearchFilter}
              // onHandleSearchFilter={handleSearchFilter}
            />}

            <EditPossibleValuesModal
              onApplyChanges={handleApplyPossibleValuesChange}
              onHide={handleApplyPossibleValuesModalHide}
              measurement={selectedMeasurement}
              showModal={showEditPossibleValuesModal}
            />
          </>
        : ''}
          

        <div className={"text-right"} style={{ marginTop: "20px" }}>
          <Button
            type="submit"
            form="assessmentBasicInfo"
            bsPrefix="b"
            className="blueBtnMd"
          >
            { props.isNewProgressMonitoring 
              ? `Create Assessment and set goal in next step`
              :`Create Assessment`
            }{" "}
            {props.isLoading.createAssessment && (
              <Spinner animation="border" size="sm" />
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    showModal: onboarding.modalsState.createAssessmentModal,
    enterDataManuallyType: onboarding.modalsState.enterDataManuallyType,
    isNewProgressMonitoring: onboarding.modalsState.isNewProgressMonitoring,
    isRedirectToSetGoal: onboarding.modalsState.isRedirectToSetGoal,
    isLoading: {
      createAssessment: onboarding.isLoading.createAssessment,
    },
    errors: {
      createAssessment: onboarding.errors.createAssessment,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideAddAssessmentModal: hideAddAssessmentModal,
      hideAddProgressMonitorModal: hideAddProgressMonitorModal,
      openAddAssessmentModal: openAddAssessmentModal,
      createAssessment: createAssessment,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAssessmentModal);
