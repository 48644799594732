import { Col, Image, Row } from "react-bootstrap";
import React from "react";
import InfoButton from "./InfoButton";
import howItWorksImage from "../../../../Images/howItWorks.png";

type Props = { onClick?: () => void };

const SecondTab = (props: Props) => {
  return (
    <div className="firstTabContainer">
      {/* <h2 className="font-weight-bold">How ReadyCoach works</h2> */}
      <h4 className="font-weight-bold">
        ReadyCoach is designed to help you bring evidence-based practices and
        strategies into the classroom through a systematic coaching process that
        features six coaching actions grounded in over ten years of research.
      </h4>
      <br />
      <Row>
        <Col sm>
          <Image src={howItWorksImage} className="img-fluid mb-4" />
        </Col>
        <Col sm>
          <h2 className="font-weight-semibold">
            Coaches work with educators to:
          </h2>
          <h3 className="onboardingFirstStepSecondTabListItem">
            1. Identify Needs and Resources
            <InfoButton
              id={"point1"}
              text={
                "Coaches communicate and work collaboratively with teachers to " +
                "gather information to identify needs and resources to improve " +
                "student performance and teacher practices. This information " +
                "may guide the formulation of goals for quality instruction, " +
                "behavior management and school-wide learning and professional " +
                "development."
              }
            />
          </h3>
          <h3 className="onboardingFirstStepSecondTabListItem">
            2. Set Goals
            <InfoButton
              id={"point2"}
              text={
                "Coaches collaborate with teachers to identify and write " +
                "measurable goals based on student functioning and teacher " +
                "practices relative to performance expectations. Measurable " +
                "goals are specific, reflect high expectations and are " +
                "essential for designing effective implementation plans."
              }
            />
          </h3>
          <h3 className="onboardingFirstStepSecondTabListItem">
            3. Design Implementation Plan
            <InfoButton
              id={"point3"}
              text={
                "Coaches collaborate with teachers to create specific steps " +
                "needed to achieve goals. Coaches consider teachers’ skills, " +
                "possible resources and barriers to ensure plans can be modeled " +
                "and delivered with integrity."
              }
            />
          </h3>
          <h3 className="onboardingFirstStepSecondTabListItem">
            4. Model Implementation Steps
            <InfoButton
              id={"point4"}
              text={
                "Coaches demonstrate steps specified in implementation plans " +
                "and support teacher practice of steps. Modeling and practice " +
                "help teachers to independently deliver a plan with integrity " +
                "and create opportunities for feedback."
              }
            />
          </h3>
          <h3 className="onboardingFirstStepSecondTabListItem">
            5. Provide Performance Feedback
            <InfoButton
              id={"point5"}
              text={
                "Coaches use data to provide specific, positive, and timely feedback " +
                "about plan implementation and goal attainment. Feedback helps refine " +
                "implementation of evidence-based practices that improve student and " +
                "teacher performance."
              }
            />
          </h3>
          <h3 className="onboardingFirstStepSecondTabListItem">
            6. Evaluate Implementation &amp; Goal Attainment
            <InfoButton
              id={"point6"}
              text={
                "Coaches use a comprehensive set of data to make summative judgments " +
                "about the integrity of plan implementation and progress toward goal " +
                "attainment. This information is used to continuously improve plans, " +
                "goal attainment, and coaching effectiveness."
              }
            />
          </h3>
        </Col>
      </Row>
      {/* <div className="text-center">
        <br />
        <Button bsPrefix="b" className="blueBtnLg" onClick={props.onClick}>
          Learn about the heart &amp; science behind it
        </Button>
      </div> */}
    </div>
  );
};

export default SecondTab;
