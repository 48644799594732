import { Grade, TimePeriod } from "../../../../../../../store/groups/types";
import {
  ColorCriteria,
  Measurement,
} from "../../../../../../../store/onboarding/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../store";

const useColorCriteria = ({
  selectedGrade,
  selectedTimePeriod,
  measurement,
}: {
  selectedGrade?: Grade;
  selectedTimePeriod?: TimePeriod;
  measurement: Measurement;
}) => {
  const customColorCriteria = useSelector<ApplicationState, ColorCriteria[]>(
    (s) => s.onboarding.measurementCustomColorCriteria
  );

  const getMainColorCriteria = (measurement: Measurement) => {
    if (measurement.grade_specific_color_criteria) {
      if (selectedGrade) {
        const gradeSpecificColorCriteria =
          measurement.grade_specific_color_criteria[selectedGrade];
        if (!gradeSpecificColorCriteria) {
          return [];
        }
        if (
          selectedTimePeriod &&
          gradeSpecificColorCriteria[selectedTimePeriod]
        ) {
          return gradeSpecificColorCriteria[selectedTimePeriod];
        }
        return [];
      } else {
        return [];
      }
    } else if (measurement.color_criteria) {
      return measurement.color_criteria;
    }
    return [];
  };

  const mainColorCriteria = getMainColorCriteria(measurement);

  const filteredCustomColorCriteria = customColorCriteria.filter(
    (ccc) =>
      ccc.grade === selectedGrade && ccc.time_period === selectedTimePeriod
  );

  const allColorCriteria = customColorCriteria.length ? filteredCustomColorCriteria :  mainColorCriteria.concat(
    filteredCustomColorCriteria
  );

  return {
    customColorCriteria,
    allColorCriteria,
    mainColorCriteria,
  };
};

export default useColorCriteria;
