import React, { FunctionComponent } from "react";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCity, faDatabase, faFileExport, faStickyNote, faTable, faUsers } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import { SuperuserDashboardTabKeys } from "./SuperuserDashboard";
import { DistrictOwnerDashboardTabKeys } from "../district-owner-dashboard/DistrictOwnerDashboard";

const SuperuserDashboardMenuPanel = () => {
  const intl = useIntl();
  return (
    <Nav className="flex-column justify-content-start">
      <Nav.Item>
        <Nav.Link eventKey={SuperuserDashboardTabKeys.DISTRICTS}>
          <FontAwesomeIcon icon={faCity} />
          &nbsp;
          {intl.formatMessage({
            id: "app.navigation.dashboardMenuPanel.districtsLabel",
          })}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey={SuperuserDashboardTabKeys.DISTRICT_SUMMARY}>
          <FontAwesomeIcon icon={faTable} />
          &nbsp;District Summary
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey={SuperuserDashboardTabKeys.USERS}>
          <FontAwesomeIcon icon={faUsers} />
          &nbsp;
          {intl.formatMessage({
            id: "app.navigation.dashboardMenuPanel.usersLabel",
          })}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey={SuperuserDashboardTabKeys.ADMIN_RELEASE_NOTES}>
          <FontAwesomeIcon icon={faStickyNote} />
          &nbsp;Release Notes
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey={SuperuserDashboardTabKeys.DATA_EXTRACT}>
          <FontAwesomeIcon icon={faFileExport} />
          &nbsp;Data Extract
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey={SuperuserDashboardTabKeys.MASTER_DATA}>
          <FontAwesomeIcon icon={faDatabase} />
          &nbsp;Master Data
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default SuperuserDashboardMenuPanel;
