import { action } from "typesafe-actions";
import { UsersManagementActionTypes } from "./types";
import { School } from "../types";
import { UserAssignmentRole } from "../../auth/types";

export const changeSchoolFilter = (school?: School) =>
  action(UsersManagementActionTypes.CHANGE_SCHOOL_FILTER, { school });

export const changeRoleFilter = (role?: UserAssignmentRole) =>
  action(UsersManagementActionTypes.CHANGE_ROLE_FILTER, { role });

export const changeSearchFilter = (searchFilter?: string) =>
  action(UsersManagementActionTypes.CHANGE_SEARCH_FILTER, { searchFilter });
