import React, { FunctionComponent } from "react";
import { Cell } from "fixed-data-table-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompressAlt, faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { changeViewOfDataTable } from "../../../../../../../store/help/actions";
import useWindowSize, {
  WindowSize,
} from "../../../../../../../utils/hooks/useWindowSize";
import { OverlayTrigger, Popover } from "react-bootstrap";

type StateProps = {
  isCompressedViewOfDataTable?: boolean;
};

type DispatchProps = {
  changeViewOfDataTable: () => any;
};

type Props = StateProps & DispatchProps;

const ViewToggleCell: FunctionComponent<Props> = (props) => {
  const windowsSize = useWindowSize();

  return windowsSize === WindowSize.XLG ? (
    <Cell>
      <OverlayTrigger
        placement={"top"}
        overlay={
          <Popover id={"info"}>
            <Popover.Title>Change table size</Popover.Title>
            <Popover.Content>Click to adjust table size</Popover.Content>
          </Popover>
        }
      >
        <span
          className="showFiltersButton"
          onClick={() => props.changeViewOfDataTable()}
        >
          <FontAwesomeIcon
            icon={
              props.isCompressedViewOfDataTable ? faExpandAlt : faCompressAlt
            }
            size="lg"
            color={"black"}
          />
        </span>
      </OverlayTrigger>
    </Cell>
  ) : null;
};

const mapStateToProps = ({ help }: ApplicationState): StateProps => {
  return {
    isCompressedViewOfDataTable: help.isCompressedViewOfDataTable,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      changeViewOfDataTable: changeViewOfDataTable,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ViewToggleCell);
