import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { Student } from "../../../../../../../store/onboarding/types";
import { Specific } from "../../../../../../../store/groups/types";
import StudentHeaderCell from "./StudentHeaderCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import FocusCell from "./FocusCell";
import _ from "lodash";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../store";
import { InterventionGroup } from "../../../../../../../store/onboarding/cases/types";

type OwnProps = {
  students: Student[];
  specifics: Specific[];
  showFullNames?: boolean;
  interventionGroup?: InterventionGroup;
};

type Props = OwnProps;

const MatrixTab: FunctionComponent<Props> = (props) => {
  const { students, specifics, interventionGroup, showFullNames } = props;
  const [showExStudents, setShowExStudents] = useState<boolean>(false);
  const identifiersInfo = useSelector(
    (s: ApplicationState) => s.groups.identifiersInfo
  );

  const studentsSpecifics = useMemo(() => {
    return students.flatMap(
      (s) =>
        s.focuses?.map(
          (focus) =>
            ({
              identifier: focus.identifier,
              display_name: focus.display_name,
            } as Specific)
        ) ?? []
    );
  }, [students]);

  const allUniqSpecifics = useMemo(() => {
    return _.uniqBy([...specifics, ...studentsSpecifics], (s) => s?.identifier);
  }, [specifics, studentsSpecifics]);

  const orderList = useSelector(
    (s: ApplicationState) => s.groups.identifiersOrderList
  );

  const filteredStudentData = useMemo(() => {
    let result = students?.sort((a,b) => a.first_name < b.first_name ? -1 : 1)
    .filter((et) => {
      const goalFound = interventionGroup?.student_goals?.find((goal) => {
        return et.id == goal.student.id;
      });
      if (goalFound?.archived && goalFound?.is_primary_goal && !showExStudents) {
        return false;
      }
      return true;
    });
    if(showExStudents) {
      const exitedStudent: Student[] | undefined = 
              interventionGroup?.student_goals?.filter((sg) => sg.archived && sg.is_primary_goal)
              .map((sg) => sg.student)?.sort((a,b) => a.first_name < b.first_name ? -1 : 1);
       result = _.uniqBy(result.concat(exitedStudent!), (s) => s.id);
    }
    return result;
  }, [showExStudents, interventionGroup]);

  const handleShowExStudents = () => {
    setShowExStudents((c) => !c);
  };

  const list: any[] = useMemo(() => {
    return _.chain(allUniqSpecifics)
      .map((ss) => {
        const ii = identifiersInfo[ss.identifier];
        if (ii) {
          return { identifier: ss.identifier, ...ii };
        }
        return null;
      })
      .map((ii) =>
        ii
          ? {
              identifier: ii.identifier,
              name: ii.name,
              area: identifiersInfo[ii.area_identifier]?.name,
              type: ii.type
            }
          : null
      )
      .filter((ii) => !!ii)
      .sortBy((ii: any) => orderList.indexOf(ii.identifier))
      .value();
  }, [identifiersInfo, allUniqSpecifics, orderList]);

  if (!allUniqSpecifics.length) {
    return <i>The group doesn't have any focuses</i>;
  }

  return (
    <div
      className="table-responsive"
      style={{ overflow: "scroll", maxHeight: "225px" }}
    >
      {interventionGroup?.finished && (
        <Form.Check
          type="switch"
          id="show-exited-students-switch-ex-std"
          label="Show Exited Students"
          checked={showExStudents}
          onChange={handleShowExStudents}
        />
      )}
      <Table striped bordered className="focuses-matrix-table">
        <thead>
          <tr>
            <th />
            {/* {console.log(filteredStudentData, "filteredStudentData")} */}
            {filteredStudentData.map((student) => (
              <StudentHeaderCell
                key={`student_head_${student.id}`}
                showFullNames={showFullNames}
                student={student}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {list.map((specific) => (
            <tr key={`focus-${specific.identifier}`}>
              <FocusCell
                showFullNames={showFullNames}
                name={specific.name}
                areaName={specific.area}
                focusType={specific.type}
              />
              {filteredStudentData.map((student) => (
                <td key={`student_cell_${student.id}`}>
                  {student.focuses?.some(
                    (f) => f.identifier === specific.identifier
                  ) && <FontAwesomeIcon icon={faCheckCircle} color="green" />}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default MatrixTab;
