import React, { CSSProperties, FunctionComponent } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

type OwnProps = {
  fidelity: string | number | null;
  style?: CSSProperties;
};

type Props = OwnProps;

const FidelityValueSpan: FunctionComponent<Props> = (props) => {
  const { fidelity, style } = props;

  if (fidelity === null) {
    return null;
  }

  return (
    <OverlayTrigger
      overlay={
        <Popover id="overall-fidelity-popover">
          <Popover.Content>
            This is the overall fidelity for this intervention.
          </Popover.Content>
        </Popover>
      }
    >
      <span className="fidelity-value-container" style={style}>
        {fidelity}%
      </span>
    </OverlayTrigger>
  );
};

export default FidelityValueSpan;
