import React, { FunctionComponent, useRef, useState } from "react";
import { Image, ListGroup, Overlay, Popover } from "react-bootstrap";
import { UserInfo } from "../../store/auth/types";
import { useDispatch } from "react-redux";
import { logoutUser, openEditProfileModal } from "../../store/auth/actions";
import { useIntl } from "react-intl";

type OwnProps = {
  userInfo?: UserInfo;
};

type Props = OwnProps;

const UserActionsOverlay: FunctionComponent<Props> = ({ userInfo }) => {
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  return (
    <>
      <Overlay
        target={buttonRef?.current as any}
        show={showOverlay}
        placement={"bottom"}
        rootClose
        rootCloseEvent={"click"}
        onHide={() => setShowOverlay(false)}
      >
        <Popover id="user-actions" className="user-actions-popover">
          <Popover.Content>
            <ListGroup>
              <ListGroup.Item
                action
                onClick={() => {
                  dispatch(openEditProfileModal());
                  setShowOverlay(false);
                }}
              >
                {intl.formatMessage({
                  id: "app.dashboard.profile.editProfileBtn.label",
                })}
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => {
                  dispatch(logoutUser());
                  setShowOverlay(false);
                }}
              >
                {intl.formatMessage({
                  id: "app.dashboard.logoutBtn.label",
                })}
              </ListGroup.Item>
            </ListGroup>
          </Popover.Content>
        </Popover>
      </Overlay>
      <div onClick={() => setShowOverlay(true)} className="user-menu-item">
        <div ref={buttonRef}>{userInfo ? userInfo.first_name : ""}</div>
        <Image
          style={{ objectFit: "cover", objectPosition: "top center" }}
          src={userInfo?.profile?.profile_image_url}
          width={30}
          height={30}
          roundedCircle
        />
      </div>
    </>
  );
};

export default UserActionsOverlay;
