import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { getFullName } from "../../../../../utils/NamesUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareSquare, faUserTimes } from "@fortawesome/free-solid-svg-icons";
import {
  CoachTeacherRelationshipRequest,
  DataSharingTableData,
  SupervisorEducatorRelationshipRequest,
  TeacherInfo,
} from "../../../../../store/onboarding/types";
import { bindActionCreators, Dispatch } from "redux";
import {
  //openDataSharingEditModal,
  removeEducatorsFromSupervisors,
  removeTeachersFromCoaches,
} from "../../../../../store/onboarding/actions";
import { connect, useDispatch } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { StaffRelationship, UserAssignmentRole, UserInfo } from "../../../../../store/auth/types";
import { showConfirmDialog } from "../../../../../store/confirm-dialog/actions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type OwnProps = {
  educator: UserInfo;
  eductorStaffRelation?: StaffRelationship;
  onHandleEditRow: (eductorStaffRelation:StaffRelationship) => any;
};

type StateProps = {
  selectedEmployee?: TeacherInfo;
};



type DispatchProps = {
  removeTeachersFromCoaches: (
    relationship: CoachTeacherRelationshipRequest
  ) => any;
  removeEducatorsFromSupervisors: (
    relationship: SupervisorEducatorRelationshipRequest
  ) => any;
};

type Props = OwnProps & StateProps & DispatchProps;

const EducatorListItem: FunctionComponent<Props> = ({
  selectedEmployee,
  educator,
  eductorStaffRelation,
  onHandleEditRow,
  removeTeachersFromCoaches,
  removeEducatorsFromSupervisors,
}) => {
  const dispatch = useDispatch();
  const targetEmployeeIsCoach = useMemo(
    () =>
      selectedEmployee?.user.profile.current_assignment?.role ===
      UserAssignmentRole.COACH,
    [selectedEmployee]
  );

  const handleTeacherRemove = (educator: UserInfo) => {
    if (targetEmployeeIsCoach) {
      removeTeachersFromCoaches({
        coach: selectedEmployee!.user.id,
        teacher: educator.id,
      });
    } else {
      removeEducatorsFromSupervisors({
        supervisor: selectedEmployee!.user.id,
        user: educator.id,
      });
    }
  };

  const handlePasswordResetConfirm = () => {
    const onConfirm = () => {
      if (selectedEmployee!.user.id) {
        handleTeacherRemove(educator);
      }
    };
    dispatch(
      showConfirmDialog({
        onConfirm,
        centered: true,
        title: "Remove Educator",
        text: (
          <>
            You are removing the current educator. It will then be displayed in the <span className="font-weight-bold">Previous Educators</span> tab. 
            Once this educator is removed from this coach, any shared data periods will no longer be accessible, and they will not be able to collaborate on intervention cases in ReadyCoach. 
            This operation cannot be undone. Are you sure you want to remove it?
          </>
        ),
        confirmButtonText: "Confirm",
      })
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <strong className="d-inline">{getFullName(educator)}</strong>
        <span onClick={() => handlePasswordResetConfirm()}>
          <FontAwesomeIcon icon={faUserTimes} className="pointer" />
        </span>
      </div>
      <hr className="narrowMargin" />
     
    </div>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    selectedEmployee: onboarding.selectedTeacher
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      removeTeachersFromCoaches: removeTeachersFromCoaches,
      removeEducatorsFromSupervisors: removeEducatorsFromSupervisors,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EducatorListItem);
