import React from "react";
import { Modal } from "react-bootstrap";
import {
  Interview,
  InterviewRequest,
  InterviewStudent,
  InterviewStudentRequest,
  Student,
} from "../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  createInterview,
  createInterviewStudent,
  getEvidenceInDataPeriod,
  getStudents,
  hideConductInterviewModal,
  updateInterview,
  updateInterviewStudent,
  uploadDocument,
} from "../../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { UserInfo } from "../../../../../../store/auth/types";
import ConductInterviewModalTitle from "./modal-parts/ConductInterviewModalTitle";
import InterviewModalTitle from "./modal-parts/InterviewModalTitle";
import SelectTeacherModalBody from "./modal-parts/SelectTeacherModalBody";
import SelectStudentsModalBody from "./modal-parts/SelectStudentsModalBody";
import InterviewDetailsModalBody from "./modal-parts/InterviewDetailsModalBody";
import { AttachedResource } from "../../../../../../store/onboarding/cases/types";

type DispatchProps = {
  hideConductInterviewModal: () => any;
  getEvidenceInDataPeriod: () => any;
  createInterview: (interview: Partial<InterviewRequest>) => any;
  updateInterview: (
    interview: Partial<InterviewRequest>,
    interviewId: number
  ) => any;
  createInterviewStudent: (
    interviewStudentRequest: InterviewStudentRequest
  ) => any;
  updateInterviewStudent: (
    interviewStudentRequest: InterviewStudentRequest,
    interviewStudentId: number
  ) => any;
  uploadDocument: (attachedResource: AttachedResource) => any;
};

type PropsFromState = {
  showModal: boolean;
  showSubModal?: boolean;
  selectedInterview?: Interview;
  errors: {
    createInterview?: string;
    createInterviewStudent?: string;
  };
  isLoading: {
    createInterview: boolean;
    createInterviewStudent: boolean;
  };
};

type Props = DispatchProps & PropsFromState;
type State = {
  interviewId?: number;
  step: ModalSteps;
  selectedTeacher?: UserInfo;
  selectedStudents: Array<Student>;
  interviewDetailsList: Array<Partial<InterviewStudent>>;
  attachments: Array<AttachedResource>;
};

enum ModalSteps {
  SELECT_TEACHER,
  SELECT_STUDENTS,
  INTERVIEW_DETAILS,
}

class ConductInterviewModal extends React.Component<Props, State> {
  defaultState: Readonly<State> = {
    interviewId: undefined,
    step: ModalSteps.SELECT_TEACHER,
    selectedTeacher: undefined,
    selectedStudents: [],
    interviewDetailsList: [],
    attachments: [],
  };

  state: Readonly<State> = this.defaultState;

  componentDidUpdate(
    prevProps: Readonly<DispatchProps & PropsFromState>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      prevProps.isLoading.createInterview &&
      !this.props.isLoading.createInterview
    ) {
      if (this.props.errors.createInterview) {
        toastr.error("Error", this.props.errors.createInterview);
      }
    }
  }

  handleModalClose = () => {
    this.props.hideConductInterviewModal();
    this.setState(this.defaultState);
  };

  handleModalShow = () => {
    const { selectedInterview } = this.props;
    if (
      selectedInterview &&
      selectedInterview.students &&
      selectedInterview.students.length
    ) {
      this.setState({
        ...this.defaultState,
        step: ModalSteps.INTERVIEW_DETAILS,
        selectedTeacher: selectedInterview.teacher,
        selectedStudents: selectedInterview.students.map((s) => s.student),
        interviewDetailsList: selectedInterview.students,
        attachments: selectedInterview.attachments,
        interviewId: selectedInterview.id,
      });
    } else {
      this.setState(this.defaultState);
    }
  };

  handleSelectedStudentsConfirm = (students: Array<Student>) => () => {
    this.setState((prevState) => ({
      step: prevState.step + 1,
      selectedStudents: students,
      interviewDetailsList: students.map((s) => ({
        student: s,
      })),
    }));
  };

  handleSelectedTeacherConfirm = (teacher: UserInfo) => () => {
    this.setState((prevState) => ({
      selectedTeacher: teacher,
      step: prevState.step + 1,
    }));
  };

  handleClickBack = () => {
    this.setState((prevState) => ({ step: prevState.step - 1 }));
  };

  handleSaveStudentInterview = async (
    attachments: Array<AttachedResource>,
    interviewDetailsList: Array<Partial<InterviewStudent>>,
    completed?: boolean
  ) => {
    const attachedResources: Array<AttachedResource> = attachments.filter(
      (a) => a.id
    );
    let hasAttachedResourcesError: boolean = false;
    for (const attachedResource of attachments.filter((a) => !a.id)) {
      await this.props.uploadDocument(attachedResource).then(
        (a: AttachedResource) => attachedResources.push(a),
        (err: string) => {
          toastr.error("Error", err);
          hasAttachedResourcesError = true;
        }
      );
    }
    if (!hasAttachedResourcesError) {
      if (this.state.interviewId) {
        await this.props
          .updateInterview(
            {
              attachments: attachedResources.map((a) => a.id!),
              teacher: this.state.selectedTeacher!.id,
              is_active: true,
              completed: !!completed,
            },
            this.state.interviewId
          )
          .then(null, (err: string) =>
            toastr.error("Failed to update the interview", err)
          );
      } else {
        await this.props
          .createInterview({
            attachments: attachedResources.map((a) => a.id!),
            teacher: this.state.selectedTeacher!.id,
            is_active: true,
            completed: !!completed,
          })
          .then(null, (err: string) =>
            toastr.error("Failed to create the interview", err)
          );
      }

      const { selectedInterview } = this.props;

      if (selectedInterview) {
        const mapStudentInterviewData = (
          interviewDetails: Partial<InterviewStudent>
        ) => {
          return {
            student: interviewDetails.student!.id!,
            interview: selectedInterview.id,
            think_relationship: !!interviewDetails.think_relationship,
            think_behavior: interviewDetails.think_behavior || "",
            think_context: interviewDetails.think_context || "",
          };
        };

        if (this.state.interviewId) {
          await Promise.all(
            interviewDetailsList.map((interviewDetails) =>
              interviewDetails.id
                ? this.props.updateInterviewStudent(
                    mapStudentInterviewData(interviewDetails),
                    interviewDetails.id
                  )
                : this.props.createInterviewStudent(
                    mapStudentInterviewData(interviewDetails)
                  )
            )
          ).then(
            () => {
              this.props.getEvidenceInDataPeriod();
              this.props.hideConductInterviewModal();
            },
            (err: string) =>
              toastr.error("Failed to update the student interview", err)
          );
        } else {
          await Promise.all(
            interviewDetailsList.map((interviewDetails) =>
              this.props.createInterviewStudent(
                mapStudentInterviewData(interviewDetails)
              )
            )
          ).then(
            () => {
              this.props.getEvidenceInDataPeriod();
              this.props.hideConductInterviewModal();
            },
            (err: string) =>
              toastr.error("Failed to create the student interview", err)
          );
        }
      }
    }
  };

  getModalTitleByStep = () => {
    switch (this.state.step) {
      case ModalSteps.SELECT_TEACHER:
        return <ConductInterviewModalTitle />;
      case ModalSteps.SELECT_STUDENTS:
        return (
          <ConductInterviewModalTitle onClickBack={this.handleClickBack} />
        );
      case ModalSteps.INTERVIEW_DETAILS:
        return (
          <InterviewModalTitle selectedTeacher={this.state.selectedTeacher!} />
        );
      default:
        throw new Error();
    }
  };

  getModalBodyByStep = () => {
    switch (this.state.step) {
      case ModalSteps.SELECT_TEACHER:
        return (
          <SelectTeacherModalBody
            selectedTeacher={this.state.selectedTeacher}
            onSelectedTeacherConfirm={this.handleSelectedTeacherConfirm}
          />
        );
      case ModalSteps.SELECT_STUDENTS:
        return (
          <SelectStudentsModalBody
            selectedTeacher={this.state.selectedTeacher!}
            selectedStudents={this.state.selectedStudents}
            onSelectedStudentsConfirm={this.handleSelectedStudentsConfirm}
            isNewStudentAdd
          />
        );
      case ModalSteps.INTERVIEW_DETAILS:
        return (
          <InterviewDetailsModalBody
            interviewId={this.state.interviewId}
            attachments={this.state.attachments}
            selectedTeacher={this.state.selectedTeacher!}
            selectedStudents={this.state.selectedStudents}
            interviewDetailsList={this.state.interviewDetailsList}
            onSaveStudentInterview={this.handleSaveStudentInterview}
          />
        );
      default:
        throw new Error();
    }
  };

  render() {
    return (
      //@ts-ignore
      <Modal
        enforceFocus={false}
        animation={false}
        size="lg"
        backdropClassName="customDarkModalBackdrop in"
        show={this.props.showModal && !this.props.showSubModal}
        onHide={this.handleModalClose}
        //@ts-ignore
        onShow={this.handleModalShow}
      >
        <Modal.Header closeButton className="purpleModalHeader">
          {this.getModalTitleByStep()}
        </Modal.Header>
        <Modal.Body>{this.getModalBodyByStep()}</Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    errors: {
      createInterview: onboarding.errors.createInterview,
      createInterviewStudent: onboarding.errors.createInterviewStudent,
    },
    isLoading: {
      createInterview: onboarding.isLoading.createInterview,
      createInterviewStudent: onboarding.isLoading.createInterview,
    },
    selectedInterview: onboarding.selectedInterview,
    showModal: onboarding.modalsState.conductInterviewModal,
    showSubModal: onboarding.modalsState.teachersIndividuallyInviteModal,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getStudents: getStudents,
      hideConductInterviewModal: hideConductInterviewModal,
      createInterview: createInterview,
      updateInterview: updateInterview,
      createInterviewStudent: createInterviewStudent,
      updateInterviewStudent: updateInterviewStudent,
      getEvidenceInDataPeriod: getEvidenceInDataPeriod,
      uploadDocument: uploadDocument,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConductInterviewModal);
