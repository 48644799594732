import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { AdditionalEducatorAssignment, InterventionGroup } from "../../../store/onboarding/cases/types";
import { getFullName } from "../../../utils/NamesUtils";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { ReactSelectOption, TeacherInfo } from "../../../store/onboarding/types";
import { UserAssignmentRole, UserInfo } from "../../../store/auth/types";
import Select from "react-select";
import { getTeachers } from "../../../store/onboarding/actions";
import { ValueType } from "react-select/src/types";
import { patchInterventionGroup } from "../../../store/onboarding/cases/actions";
import { Spinner } from "react-bootstrap";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toastr } from "react-redux-toastr";
import { showConfirmDialog } from "../../../store/confirm-dialog/actions";
import useUserRole from "../../../utils/hooks/useUserRole";
import AdditionalEducatorsSelector from "../../common/reading-interventions/intervention-tools/recommend-groups/group-card/AdditionalEducatorsSelector";

type OwnProps = {
  interventionGroup: InterventionGroup;
  isStudentDetail?: boolean;
  isGroupDetail?: boolean;
};

type Props = OwnProps;

const InterventionGroupAssignmentsTable: FunctionComponent<Props> = (props) => {
  const [localInterventionGroup, setLocalInterventionGroup] = useState<any>();
  const { interventionGroup, isStudentDetail, isGroupDetail } = props;
  let [additionalEducatorPlan, setAdditionalEducatorPlan] = useState<any>([]);
  
  const { isCoach, isTeacher } = useUserRole();
  
  const teachersRoaster = useSelector<ApplicationState, TeacherInfo[]>(
    (s) => s.onboarding.teachersRoster
  );

  const dispatch = useDispatch();
  const loadingGetTeachers = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.onboarding.isLoading.getTeachers
  );
  useEffect(() => {
    dispatch(getTeachers());
    setLocalInterventionGroup(interventionGroup);
    if(interventionGroup.additional_educator_plan != null) {
      setAdditionalEducatorPlan(interventionGroup.additional_educator_plan);
    }
  }, []);

  useEffect(() => {
    //console.log('additionalEducatorPlan', additionalEducatorPlan)
  },[additionalEducatorPlan]);

  const currentUser: any = useSelector(
    (s: ApplicationState) => s.auth.userInfo
  );

  const teachers = useMemo(() => {
    return teachersRoaster
      .filter((t) => t.user.profile.current_role === UserAssignmentRole.TEACHER)
      .map((t) => t.user);
  }, [teachersRoaster]);

  const [showTeacherSelector, setShowTeacherSelector] = useState<boolean>(
    false
  );

  const handleSelectedTeacherChange = (value: ValueType<UserInfo, false>) => {
    let isNotExistInAdditionalEducator = !interventionGroup.associated_assignments
    .some(((associateAssign:any) => interventionGroup.teacher_assignment?.user.id == associateAssign.user.id));

    if(isNotExistInAdditionalEducator || !interventionGroup.associated_assignments.length) {
      dispatch(
        showConfirmDialog({
          title: "Confirmation",
          text:
            isCoach ? "If you want this interventionist to retain access to this group, you must first assign this interventionist as an Additional Educator. Do you want to change the interventionist without assigning him/her as an Additional Educator?"
            : isTeacher ? "If you want to retain access to this group, you must first assign yourself as an Additional Educator. Do you want to change the interventionist without assigning yourself as an Additional Educator?" 
              : '',
          onConfirm: () => {
             dispatch(
              patchInterventionGroup(interventionGroup.id, {
                teacher_assignment: value?.profile.current_assignment?.id,
              })
            );
          },
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          timerElipseLeft: true,
          countDownTimer: 5,
        })
      );
    } else {
      dispatch(
        patchInterventionGroup(interventionGroup.id, {
          teacher_assignment: value?.profile.current_assignment?.id,
        })
      );
    }
  };


  const loadingPatchInterventionGroup = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.cases.isLoading.patchInterventionGroup);

  return (
    <Table className="goalTable">
      <thead>
        <tr>
          <th style={{ width: "30%" }}>Teacher</th>
          <th>Additional Educator(s)</th>
          <th>
            {loadingPatchInterventionGroup && (
              <Spinner className="float-right" animation="border" size="sm" />
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {showTeacherSelector ? (
              <Select
                autoFocus
                className="-m-2"
                isLoading={loadingGetTeachers}
                onMenuClose={() => setShowTeacherSelector(false)}
                defaultMenuIsOpen
                options={teachers.filter((teacher) => {
                  if(currentUser.allowed_assigned_teachers_ids !== undefined) {
                    if(currentUser.allowed_assigned_teachers_ids.length) {
                      return currentUser.allowed_assigned_teachers_ids.includes(teacher.id) 
                    } else {
                      return true;
                    }
                  } else {
                    return false;
                  }
                })}
                value={interventionGroup.teacher_assignment?.user}
                getOptionValue={(t) => t.id.toString()}
                getOptionLabel={(t) => getFullName(t)}
                onChange={handleSelectedTeacherChange}
              />
            ) : (
              <>
                {getFullName(interventionGroup.teacher_assignment?.user)}
                {!isStudentDetail && !isGroupDetail ? <span
                  className="pointer"
                  onClick={() => setShowTeacherSelector(true)}
                >
                  <FontAwesomeIcon icon={faEdit} className="ml-2" />
                </span> : ''}
              </>
            )}
          </td>
          <td colSpan={2}>
            <AdditionalEducatorsSelector 
              interventionGroup={interventionGroup}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default InterventionGroupAssignmentsTable;
