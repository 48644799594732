import React, { FunctionComponent } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import UploadStudentDemographicModal from "./UploadStudentDemographicModal";
import { openUploadStudentDemographicModal } from "../../../../../store/onboarding/actions";

type OwnProps = {};

type Props = OwnProps;

const UploadStudentDemographicsCsvButton: FunctionComponent<Props> = (
  props
) => {
  const dispatch = useDispatch();

  return (
    <>
      <Dropdown.Item
        as="button"
        onClick={() => {
          dispatch(openUploadStudentDemographicModal());
        }}
        className="text-wrap"
      >
        Upload Student Demographics CSV
      </Dropdown.Item>
      <UploadStudentDemographicModal />
    </>
  );
};

export default UploadStudentDemographicsCsvButton;
