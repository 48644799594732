import React from "react";

type Props = {
  fixed?: boolean;
  checked: boolean;
  onClick?: () => void;
};

export const CustomCheckbox = ({ fixed, checked, onClick }: Props) => {
  return (
    <div
      className={`customCheckboxContainer ${fixed ? "fixed" : ""}`}
      onClick={() => onClick && onClick()}
    >
      <input type="checkbox" checked={checked} readOnly />
      <span className="customCheckboxCheckmark" />
    </div>
  );
};
