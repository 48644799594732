import { ActionType, createReducer } from "typesafe-actions";
import * as spellingTestsActions from "./actions";
import { SpellingTestsState } from "./types";

export type SpellingTestsActions = ActionType<typeof spellingTestsActions>;

const initialState: SpellingTestsState = {
  tests: [],
  studentsByGrade: [],
  groups: [],
  wordGroups: [],
  spellingTestAnswers: [],
  isLoading: {},
  errors: {},
};

const reducer = createReducer<SpellingTestsState, SpellingTestsActions>(
  initialState
)
  //get spelling test groups
  .handleAction(
    spellingTestsActions.getSpellingTestGroupsAction.request,
    (state): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getSpellingTestGroups: true,
      },
      errors: {
        ...state.errors,
        getSpellingTestGroups: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.getSpellingTestGroupsAction.success,
    (state, action): SpellingTestsState => ({
      ...state,
      groups: action.payload,
      isLoading: {
        ...state.isLoading,
        getSpellingTestGroups: false,
      },
      errors: {
        ...state.errors,
        getSpellingTestGroups: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.getSpellingTestGroupsAction.failure,
    (state, action): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getSpellingTestGroups: false,
      },
      errors: {
        ...state.errors,
        getSpellingTestGroups: action.payload.message,
      },
    })
  )
  //get spelling test group
  .handleAction(
    spellingTestsActions.getSpellingTestGroupAction.request,
    (state): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getSpellingTestGroup: true,
      },
      errors: {
        ...state.errors,
        getSpellingTestGroup: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.getSpellingTestGroupAction.success,
    (state, action): SpellingTestsState => ({
      ...state,
      selectedGroup: action.payload,
      isLoading: {
        ...state.isLoading,
        getSpellingTestGroup: false,
      },
      errors: {
        ...state.errors,
        getSpellingTestGroup: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.getSpellingTestGroupAction.failure,
    (state, action): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getSpellingTestGroup: false,
      },
      errors: {
        ...state.errors,
        getSpellingTestGroup: action.payload.message,
      },
    })
  )
  //add spelling test group
  .handleAction(
    spellingTestsActions.createSpellingTestGroupAction.request,
    (state): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        createSpellingTestGroup: true,
      },
      errors: {
        ...state.errors,
        createSpellingTestGroup: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.createSpellingTestGroupAction.success,
    (state, action): SpellingTestsState => ({
      ...state,
      groups: [action.payload, ...state.groups],
      isLoading: {
        ...state.isLoading,
        createSpellingTestGroup: false,
      },
      errors: {
        ...state.errors,
        createSpellingTestGroup: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.createSpellingTestGroupAction.failure,
    (state, action): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        createSpellingTestGroup: false,
      },
      errors: {
        ...state.errors,
        createSpellingTestGroup: action.payload.message,
      },
    })
  )
  // add/remove student to spelling test group
  .handleAction(
    [
      spellingTestsActions.addStudentToSpellingTestGroupAction.request,
      spellingTestsActions.removeStudentFromSpellingTestGroupAction.request,
    ],
    (state): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        changeStudentsInSpellingTestGroup: true,
      },
      errors: {
        ...state.errors,
        changeStudentsInSpellingTestGroup: undefined,
      },
    })
  )
  .handleAction(
    [
      spellingTestsActions.addStudentToSpellingTestGroupAction.success,
      spellingTestsActions.removeStudentFromSpellingTestGroupAction.success,
    ],
    (state, action): SpellingTestsState => ({
      ...state,
      groups: state.groups.map((stg) =>
        stg.id === action.payload.id ? action.payload : stg
      ),
      isLoading: {
        ...state.isLoading,
        changeStudentsInSpellingTestGroup: false,
      },
      errors: {
        ...state.errors,
        changeStudentsInSpellingTestGroup: undefined,
      },
    })
  )
  .handleAction(
    [
      spellingTestsActions.addStudentToSpellingTestGroupAction.failure,
      spellingTestsActions.removeStudentFromSpellingTestGroupAction.failure,
    ],
    (state, action): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        changeStudentsInSpellingTestGroup: false,
      },
      errors: {
        ...state.errors,
        changeStudentsInSpellingTestGroup: action.payload.message,
      },
    })
  )
  //update spelling test group
  .handleAction(
    spellingTestsActions.updateSpellingTestGroupAction.request,
    (state): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        updateSpellingTestGroup: true,
      },
      errors: {
        ...state.errors,
        updateSpellingTestGroup: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.updateSpellingTestGroupAction.success,
    (state, action): SpellingTestsState => ({
      ...state,
      groups: state.groups.map((stg) =>
        stg.id === action.payload.id ? action.payload : stg
      ),
      isLoading: {
        ...state.isLoading,
        updateSpellingTestGroup: false,
      },
      errors: {
        ...state.errors,
        updateSpellingTestGroup: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.updateSpellingTestGroupAction.failure,
    (state, action): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        updateSpellingTestGroup: false,
      },
      errors: {
        ...state.errors,
        updateSpellingTestGroup: action.payload.message,
      },
    })
  )
  //delete spelling test group
  .handleAction(
    spellingTestsActions.deleteSpellingTestGroupAction.request,
    (state): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        deleteSpellingTestGroup: true,
      },
      errors: {
        ...state.errors,
        deleteSpellingTestGroup: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.deleteSpellingTestGroupAction.success,
    (state, action): SpellingTestsState => ({
      ...state,
      groups: state.groups.filter((stg) => stg.id !== action.payload),
      isLoading: {
        ...state.isLoading,
        deleteSpellingTestGroup: false,
      },
      errors: {
        ...state.errors,
        deleteSpellingTestGroup: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.deleteSpellingTestGroupAction.failure,
    (state, action): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        deleteSpellingTestGroup: false,
      },
      errors: {
        ...state.errors,
        deleteSpellingTestGroup: action.payload.message,
      },
    })
  )
  //get spelling test
  .handleAction(
    spellingTestsActions.getSpellingTestAction.request,
    (state): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getSpellingTest: true,
      },
      errors: {
        ...state.errors,
        getSpellingTest: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.getSpellingTestAction.success,
    (state, action): SpellingTestsState => ({
      ...state,
      selectedTest: action.payload,
      selectedGroup: action.payload.group,
      isLoading: {
        ...state.isLoading,
        getSpellingTest: false,
      },
      errors: {
        ...state.errors,
        getSpellingTest: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.getSpellingTestAction.failure,
    (state, action): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getSpellingTest: false,
      },
      errors: {
        ...state.errors,
        getSpellingTest: action.payload.message,
      },
    })
  )
  // show/hide spelling test group upsert modal
  .handleAction(
    spellingTestsActions.showSpellingTestGroupUpsertModal,
    (state, action): SpellingTestsState => ({
      ...state,
      showSpellingTestGroupUpsertModal: true,
      selectedGroup: action.payload,
    })
  )
  .handleAction(
    spellingTestsActions.hideSpellingTestGroupUpsertModal,
    (state, action): SpellingTestsState => ({
      ...state,
      showSpellingTestGroupUpsertModal: false,
    })
  )
  //get spelling test questions
  .handleAction(
    spellingTestsActions.getSpellingTestQuestionsAction.request,
    (state): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getSpellingTestQuestions: true,
      },
      errors: {
        ...state.errors,
        getSpellingTestQuestions: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.getSpellingTestQuestionsAction.success,
    (state, action): SpellingTestsState => ({
      ...state,
      wordGroups: action.payload,
      isLoading: {
        ...state.isLoading,
        getSpellingTestQuestions: false,
      },
      errors: {
        ...state.errors,
        getSpellingTestQuestions: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.getSpellingTestQuestionsAction.failure,
    (state, action): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getSpellingTestQuestions: false,
      },
      errors: {
        ...state.errors,
        getSpellingTestQuestions: action.payload.message,
      },
    })
  )
  //get students by grade
  .handleAction(
    spellingTestsActions.getStudentsByGradeAction.request,
    (state): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getStudentsByGrade: true,
      },
      errors: {
        ...state.errors,
        getStudentsByGrade: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.getStudentsByGradeAction.success,
    (state, action): SpellingTestsState => ({
      ...state,
      studentsByGrade: action.payload,
      isLoading: {
        ...state.isLoading,
        getStudentsByGrade: false,
      },
      errors: {
        ...state.errors,
        getStudentsByGrade: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.getStudentsByGradeAction.failure,

    (state, action): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getStudentsByGrade: false,
      },
      errors: {
        ...state.errors,
        getStudentsByGrade: action.payload.message,
      },
    })
  )
  //create/update spelling test
  .handleAction(
    [
      spellingTestsActions.createSpellingTestAction.request,
      spellingTestsActions.updateSpellingTestAction.request,
    ],
    (state): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        upsertSpellingTest: true,
      },
      errors: {
        ...state.errors,
        upsertSpellingTest: undefined,
      },
    })
  )
  .handleAction(
    [
      spellingTestsActions.createSpellingTestAction.success,
      spellingTestsActions.updateSpellingTestAction.success,
    ],
    (state, action): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        upsertSpellingTest: false,
      },
      errors: {
        ...state.errors,
        upsertSpellingTest: undefined,
      },
    })
  )
  .handleAction(
    [
      spellingTestsActions.createSpellingTestAction.failure,
      spellingTestsActions.updateSpellingTestAction.failure,
    ],
    (state, action): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        upsertSpellingTest: false,
      },
      errors: {
        ...state.errors,
        upsertSpellingTest: action.payload.message,
      },
    })
  )
  //delete spelling test
  .handleAction(
    spellingTestsActions.deleteSpellingTestAction.request,
    (state): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        deleteSpellingTest: true,
      },
      errors: {
        ...state.errors,
        deleteSpellingTest: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.deleteSpellingTestAction.success,
    (state, action): SpellingTestsState => ({
      ...state,
      groups: state.groups.map((g) =>
        g.id === action.payload.groupId
          ? {
              ...g,
              tests: g.tests.filter((t) => t.id !== action.payload.testId),
            }
          : g
      ),
      isLoading: {
        ...state.isLoading,
        deleteSpellingTest: false,
      },
      errors: {
        ...state.errors,
        deleteSpellingTest: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.deleteSpellingTestAction.failure,
    (state, action): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        deleteSpellingTest: false,
      },
      errors: {
        ...state.errors,
        deleteSpellingTest: action.payload.message,
      },
    })
  )
  //generateStudentReadingDiagnostics
  .handleAction(
    spellingTestsActions.generateStudentReadingDiagnosticsAction.request,
    (state): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        generateStudentReadingDiagnostics: true,
      },
      errors: {
        ...state.errors,
        generateStudentReadingDiagnostics: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.generateStudentReadingDiagnosticsAction.success,
    (state, action): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        generateStudentReadingDiagnostics: false,
      },
      errors: {
        ...state.errors,
        generateStudentReadingDiagnostics: undefined,
      },
    })
  )
  .handleAction(
    spellingTestsActions.generateStudentReadingDiagnosticsAction.failure,
    (state, action): SpellingTestsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        generateStudentReadingDiagnostics: false,
      },
      errors: {
        ...state.errors,
        generateStudentReadingDiagnostics: action.payload.message,
      },
    })
  )
  //generate empty spelling test answers
  .handleAction(
    spellingTestsActions.generateSpellingTestAnswers,
    (state, action): SpellingTestsState => ({
      ...state,
      prevQuestionWord: undefined,
      spellingTestAnswers:
        action.payload.answers &&
        action.payload.questions.length === action.payload.answers.length
          ? action.payload.answers
          : action.payload.questions.map((question) => ({
              word: question.word,
              student_answer: undefined,
              order: question.order,
              grammar_parts: question.grammar_parts.map((gp) => ({
                grammar_type: gp.type,
                correct_part: gp.correct_part,
                student_answer: undefined,
                correct: false,
              })),
            })),
    })
  )
  .handleAction(
    spellingTestsActions.changeSpellingTestStudentAnswer,
    (state, action): SpellingTestsState => ({
      ...state,
      spellingTestAnswers: state.spellingTestAnswers.map((answer) =>
        answer.word === action.payload.word
          ? { ...answer, student_answer: action.payload.student_answer }
          : answer
      ),
    })
  )
  .handleAction(
    spellingTestsActions.markSpellingTestStudentAnswer,
    (state, action): SpellingTestsState => ({
      ...state,
      prevQuestionWord: action.payload.word,
      spellingTestAnswers: state.spellingTestAnswers.map((answer) =>
        answer.word === action.payload.word
          ? {
              ...answer,
              student_answer: action.payload.correct ? answer.word : "", //todo maybe change
              grammar_parts: action.payload.correct
                ? answer.grammar_parts.map((gp) => ({
                    ...gp,
                    correct: true,
                    student_answer: gp.correct_part,
                  }))
                : answer.grammar_parts.map((gp) => ({
                    ...gp,
                    correct: false,
                  })),
            }
          : answer
      ),
    })
  )
  .handleAction(
    spellingTestsActions.changeSpellingTestAnswerGrammarPart,
    (state, action): SpellingTestsState => {
      return {
        ...state,
        prevQuestionWord: action.payload.word,
        spellingTestAnswers: state.spellingTestAnswers.map((answer) =>
          answer.word === action.payload.word
            ? {
                ...answer,
                grammar_parts: answer.grammar_parts.map((agp) =>
                  action.payload.type === agp.grammar_type
                    ? {
                        ...agp,
                        student_answer: action.payload.student_answer,
                        correct: action.payload.correct,
                      }
                    : agp
                ),
              }
            : answer
        ),
      };
    }
  )
  //mark empty spelling test student answer part as correct
  .handleAction(
    spellingTestsActions.markEmptySpellingTestStudentAnswerPartAsCorrect,
    (state, action): SpellingTestsState => {
      const selectedAnotherQuestionWord =
        state.prevQuestionWord !== undefined &&
        action.payload !== state.prevQuestionWord;

      return {
        ...state,
        prevQuestionWord: action.payload,
        spellingTestAnswers: state.spellingTestAnswers.map((answer) =>
          selectedAnotherQuestionWord &&
          answer.student_answer === "" && //todo maybe change
          answer.word === state.prevQuestionWord
            ? {
                ...answer,
                grammar_parts: answer.grammar_parts.map((agp) =>
                  agp.student_answer !== undefined
                    ? agp
                    : {
                        ...agp,
                        correct: true,
                        student_answer: agp.correct_part,
                      }
                ),
              }
            : answer
        ),
      };
    }
  )
  //change selected student
  .handleAction(
    spellingTestsActions.changeSelectedStudent,
    (state, action): SpellingTestsState => ({
      ...state,
      selectedStudent: action.payload,
    })
  )
  //change selected spelling test
  .handleAction(
    spellingTestsActions.changeSelectedSpellingTest,
    (state, action): SpellingTestsState => ({
      ...state,
      selectedTest: action.payload,
    })
  )
  // show/hide spelling test summary modal
  .handleAction(
    spellingTestsActions.showSpellingTestSummaryModal,
    (state, action): SpellingTestsState => ({
      ...state,
      showSpellingTestSummaryModal: true,
      selectedGroup: action.payload,
    })
  )
  .handleAction(
    spellingTestsActions.hideSpellingTestSummaryModal,
    (state, action): SpellingTestsState => ({
      ...state,
      showSpellingTestSummaryModal: false,
    })
  );

export { reducer as spellingTestReducer };
