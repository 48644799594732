import {
  BehaviorTally,
  FbaBehavior,
  FbaBehaviorDisplayed,
} from "../../../../../store/onboarding/cases/types";

export const getBehaviorTallyName = (bTally: BehaviorTally) => {
  return bTally.behavior === FbaBehavior.BEHAVIOR_OTHER && bTally.other_behavior
    ? bTally.other_behavior
    : FbaBehaviorDisplayed[bTally.behavior];
};
