import { Button } from "react-bootstrap";
import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { openInviteParentModal } from "../../../../../store/onboarding/actions";
import { connect } from "react-redux";

type DispatchProps = {
  openInviteParentModal: () => any;
};

type Props = DispatchProps;

const NoParentsView = (props: Props) => {
  return (
    <div className="noParentsViewContainer mt-3">
      <h2 className="font-weight-bold d-inline">
        Invite Parents to ReadyCoach
      </h2>
      <h3 className="mt-2" style={{ marginLeft: 60, marginRight: 60 }}>
        Keep Parents in the loop. Invite them to ReadyCoach and they will be
        able to see the interventions and progress for their children.
      </h3>
      <br />
      <Button
        className="blueBtnXL"
        bsPrefix="b"
        onClick={() => props.openInviteParentModal()}
      >
        Invite Parents
      </Button>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openInviteParentModal: openInviteParentModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(NoParentsView);
