import React from "react";
import {
  drawCell,
  pickColor,
  pickTextColorBasedOnBgColor,
} from "../../../../utils";
import {
  Measurement,
  MeasurementType,
} from "../../../../../store/onboarding/types";

type Props = {
  index: number;
  element: any;
  measurement: Measurement;
};

const PreviewDataCell = (props: Props) => {
  const { index, element, measurement } = props;
  const value =
    measurement.type !== MeasurementType.CATEGORY
      ? element[measurement.column_name!]
      : measurement.possible_values!.find(
          (x) => x.display_name === element[measurement.column_name!]
        );
  const bgColor = value
    ? measurement.type !== MeasurementType.CATEGORY
      ? pickColor(value, measurement)
      : pickColor(value.order, measurement)
    : undefined;
  const textColor = pickTextColorBasedOnBgColor(bgColor);
  return (
    <td
      key={index}
      style={{
        backgroundColor: bgColor,
        color: textColor,
        borderLeft: "3px #ffffff solid",
      }}
    >
      {drawCell(element[measurement.column_name!], measurement)}
    </td>
  );
};

export default PreviewDataCell;
