import { TargetBehaviorGroup } from "../../select-target/utils";
import React, {
  FunctionComponent,
  RefObject,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "react-bootstrap";
import { CategoryBehavior } from "../../../../../../../store/onboarding/types";
import BehaviorDomainGroup from "./BehaviorDomainGroup";

type OwnProps = {
  isNegative?: boolean;
  isCustom?: boolean;
  selectedBehaviors?: Array<CategoryBehavior>;
  targetBehaviorGroups: Array<TargetBehaviorGroup>;
  onTargetBehaviorSelect: (target: CategoryBehavior) => any;
  refs?: { [key: number]: RefObject<any> };
  highlightedBehaviorsIds?: number[];
};

type Props = OwnProps;

const BehaviorTable: FunctionComponent<Props> = ({
  isCustom,
  isNegative,
  targetBehaviorGroups,
  onTargetBehaviorSelect,
  selectedBehaviors,
  refs,
  highlightedBehaviorsIds,
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const title = useMemo(() => {
    if (isCustom) {
      return "My Behaviors";
    }
    if (isNegative) {
      return "Negative behaviors to decrease";
    } else {
      return "Positive behaviors to increase";
    }
  }, [isNegative, isCustom]);

  useEffect(() => {
    if (isCustom) {
      setCollapsed(true);
    }
  }, [selectedBehaviors]);

  if (!targetBehaviorGroups.length) return null;

  const handleCollapse = () => {
    setCollapsed((prevState) => !prevState);
  };

  return (
    <>
      <div
        className="behaviorsListTableHeader"
        aria-controls="target-behavior-groups"
        aria-expanded={collapsed}
        onClick={handleCollapse}
      >
        <FontAwesomeIcon
          icon={collapsed ? faCaretDown : faCaretRight}
          size="2x"
        />
        {title}
      </div>
      <Collapse in={collapsed}>
        <div id="target-behavior-groups" className="behaviorsListTableBody">
          {targetBehaviorGroups.map((domainGroup, index) => (
            <BehaviorDomainGroup
              key={`domain_${domainGroup.domain.id}`}
              refs={refs}
              highlightedBehaviorsIds={highlightedBehaviorsIds}
              isCustom={isCustom}
              domainGroup={domainGroup}
              selectedBehaviors={selectedBehaviors}
              onTargetBehaviorSelect={onTargetBehaviorSelect}
            />
          ))}
        </div>
      </Collapse>
    </>
  );
};

export default BehaviorTable;
