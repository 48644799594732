import React, { FunctionComponent, useMemo } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import SyllablesGrammarPartInput from "./SyllablesGrammarPartInput";
import { useDispatch } from "react-redux";
import {
  GrammarType,
  SpellingTestAnswer,
  SpellingTestAnswerGrammarPart,
  SpellingTestQuestion,
} from "../../../../../store/spelling-tests/types";
import { changeSpellingTestAnswerGrammarPart } from "../../../../../store/spelling-tests/actions";
import { useMinMultiInputWidth } from "./hooks/useMinMultiInputWidth";

type Props = {
  correctPart: string;
  question: SpellingTestQuestion;
  answer?: SpellingTestAnswer;
};

const SyllablesCell: FunctionComponent<Props> = (props) => {
  const { correctPart, answer, question } = props;
  const dispatch = useDispatch();

  const grammarPart: SpellingTestAnswerGrammarPart | undefined = useMemo(() => {
    return answer?.grammar_parts.find(
      (gp) => gp.grammar_type === GrammarType.Syllables
    );
  }, [answer?.grammar_parts]);

  const parts = useMemo(() => correctPart.split(","), [correctPart]);
  const studentAnswerParts = useMemo(
    () =>
      grammarPart?.student_answer ? grammarPart.student_answer.split(",") : [],
    [grammarPart?.student_answer]
  );

  const onCommonGrammarPartChange = (value: string, index: number) => {
    if (value !== studentAnswerParts[index]) {
      const student_answer = parts
        .map((p, i) => (index === i ? value : studentAnswerParts[i] ?? ""))
        .join(",");

      const correct = student_answer === correctPart;

      dispatch(
        changeSpellingTestAnswerGrammarPart({
          word: question.word,
          type: GrammarType.Syllables,
          student_answer: student_answer,
          correct: correct,
        })
      );
    }
  };

  const minWidths = useMinMultiInputWidth(parts);

  return (
    <>
      <td>
        <InputGroup>
          {parts.map((part, index) => (
            <FormControl
              tabIndex={-1}
              key={index}
              readOnly
              className="source-part"
              style={{ minWidth: minWidths[index] }}
              value={part}
            />
          ))}
        </InputGroup>
      </td>
      <td>
        <SyllablesGrammarPartInput
          questionWord={question.word}
          minWidths={minWidths}
          correctParts={parts}
          studentAnswerParts={studentAnswerParts}
          onCommonGrammarPartChange={onCommonGrammarPartChange}
        />
      </td>
    </>
  );
};

export default SyllablesCell;
