import React from "react";
import Table from "react-bootstrap/Table";

const DESSAExample = () => {
  return (
    <>
      <h4 className="font-weight-bold mb-2">Example Format</h4>
      <div className="overflow-auto mb-3">
        <Table className="uploadExampleTable table-mb">
          <thead>
            <tr>
              <th>ProgramId</th>
              <th>ProgramName</th>
              <th>SiteId</th>
              <th>RatingPeriodName</th>
              <th>RaterTypeId</th>
              <th>DESSAAssignmentName</th>
              <th>SiteName</th>
              <th>GroupId</th>
              <th>GroupName</th>
              <th>RecordedBy</th>
              <th>RaterName</th>
              <th>RaterDescription</th>
              <th>StudentStatus</th>
              <th>StudentId</th>
              <th>StudentLastName</th>
              <th>StudentFirstName</th>
              <th>StudentGender</th>
              <th>StudentDOB</th>
              <th>GradeAtRating</th>
              <th>AgeAtRating</th>
              <th>AgeAtRatingMonths</th>
              <th>RatingId</th>
              <th>RatingDate</th>
              <th>RecordForm</th>
              <th>PRScaleRawScore</th>
              <th>PRTScore</th>
              <th>PRPercentile</th>
              <th>PRDescription</th>
              <th>OTScaleRawScore</th>
              <th>OTTScore</th>
              <th>OTPercentile</th>
              <th>OTDescription</th>
              <th>GBScaleRawScore</th>
              <th>GBTScore</th>
              <th>GBPercentile</th>
              <th>GBDescription</th>
              <th>SOScaleRawScore</th>
              <th>SOTScore</th>
              <th>SOPercentile</th>
              <th>SODescription</th>
              <th>DMScaleRawScore</th>
              <th>DMTScore</th>
              <th>DMPercentile</th>
              <th>DMDescription</th>
              <th>RSScaleRawScore</th>
              <th>RSTScore</th>
              <th>RSPercentile</th>
              <th>RSDescription</th>
              <th>SAScaleRawScore</th>
              <th>SATScore</th>
              <th>SAPercentile</th>
              <th>SADescription</th>
              <th>SMScaleRawScore</th>
              <th>SMTScore</th>
              <th>SMPercentile</th>
              <th>SMDescription</th>
              <th>SECScaleRawScore</th>
              <th>SECTScore</th>
              <th>SECPercentile</th>
              <th>SECDescription</th>
              <th>NativeAmerican</th>
              <th>Asian</th>
              <th>AfricanAmerican</th>
              <th>Hispanic</th>
              <th>NativeHawaiian</th>
              <th>White</th>
              <th>Other</th>
              <th>SpecialEdSvc</th>
              <th>Section504</th>
              <th>EngLangLearn</th>
              <th>FreeReduced</th>
              <th>EconDisadv</th>
              <th>McKinney</th>
              <th>Migrant</th>
              <th>GiftedTalented</th>
              <th>ZipCode</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>4444</td>
              <td>Sample District</td>
              <td>11</td>
              <td>2021-2022 BOY Mini/Full</td>
              <td>0</td>
              <td>2021-2022 BOY Mini/Full Conditional</td>
              <td>G</td>
              <td>Sample </td>
              <td>Sample </td>
              <td>Sample </td>
              <td>Sample </td>
              <td>Educator</td>
              <td>A</td>
              <td>100</td>
              <td></td>
              <td>Dareon</td>
              <td>M</td>
              <td>9/10/2007</td>
              <td>8th Grade</td>
              <td>14</td>
              <td>170</td>
              <td>45454</td>
              <td>9/16/2021</td>
              <td>DESSA</td>
              <td>21</td>
              <td>44</td>
              <td>27</td>
              <td>T</td>
              <td>11</td>
              <td>37</td>
              <td>10</td>
              <td>N</td>
              <td>14</td>
              <td>36</td>
              <td>8</td>
              <td>N</td>
              <td>17</td>
              <td>40</td>
              <td>16</td>
              <td>N</td>
              <td>16</td>
              <td>42</td>
              <td>21</td>
              <td>T</td>
              <td>20</td>
              <td>42</td>
              <td>21</td>
              <td>T</td>
              <td>14</td>
              <td>42</td>
              <td>21</td>
              <td>T</td>
              <td>22</td>
              <td>42</td>
              <td>21</td>
              <td>T</td>
              <td>325</td>
              <td>40</td>
              <td>16</td>
              <td>N</td>
              <td>N</td>
              <td>N</td>
              <td>Y</td>
              <td>N</td>
              <td>N</td>
              <td>N</td>
              <td>N</td>
              <td>Y</td>
              <td></td>
              <td>N</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>N</td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};
export default DESSAExample;
