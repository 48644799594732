import React, { FunctionComponent } from "react";
import BehaviorsTable from "./rc-progress/BehaviorsTable";
import { StudentGoal } from "../../../../../../store/onboarding/cases/types";
import { getFullName } from "../../../../../../utils/NamesUtils";

type OwnProps = {
  studentGoal?: StudentGoal;
  onClickContinue: () => any;
};

type Props = OwnProps;

const FocusOn: FunctionComponent<Props> = ({
  studentGoal,
  onClickContinue,
}) => {
  return (
    <div>
      <h3 className="font-weight-bold text-center">
        Step 1: What are you going to focus on for{" "}
        {studentGoal ? getFullName(studentGoal.student) : "each Student"}?
      </h3>
      <br />
      <BehaviorsTable studentGoal={studentGoal} />

      <div className="d-flex flex-column justify-content-center align-items-center mt-3">
        <h3>
          Next, {studentGoal?.id ? "you can change" : "you'll pick"} how to
          measure your goal progress
        </h3>
        <button className="blueBtnMd mt-2" onClick={onClickContinue}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default FocusOn;
