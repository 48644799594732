import React, { Component } from "react";
import {
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Button,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import { bindActionCreators, Dispatch } from "redux";
import { APP_NAME } from "../../constants";
import { ApplicationState } from "../../store";
import {
  getRegistrationDistricts,
  registerNewDistrict,
} from "../../store/auth/actions";
import { NewDistrictRegistrationCredential } from "../../store/auth/types";
import { USState, USStateNamesValues } from "../common/States";
import { TermsAndConditionsModal } from "./terms-and-conditions/TermsAndConditionsModal";
import { ReactSelectOption } from "../../store/onboarding/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const trialSelectOptions: ReactSelectOption<"coach" | "district">[] = [
  { label: "Coach Trial", value: "coach" },
  { label: "Whole District Trial", value: "district" },
];

type PropsFromState = {
  isLoading: boolean;
  error?: string;
};

type Props = PropsFromState & {
  registerNewDistrict: (
    district: NewDistrictRegistrationCredential,
    trialType: "coach" | "district"
  ) => any;
};

type State = {
  user: NewDistrictRegistrationCredential;
  trial: "coach" | "district";
  passwordsMatch: boolean;
  showTermsAndConditionsModal: boolean;
  attributeType: string;
  attributeConfirmType: string;
};

class NewDistrictRegistrationPage extends Component<Props, State> {
  state: Readonly<State> = {
    user: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      district_name: "",
      state: undefined,
    },
    trial: "coach",
    passwordsMatch: true,
    showTermsAndConditionsModal: false,
    attributeType: "password",
    attributeConfirmType: "password",
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (prevProps.isLoading && !this.props.isLoading) {
      if (this.props.error) {
        if (!this.props.error.includes("Invalid email")) {
          toastr.error("Error!", this.props.error);
        }
        this.setState({
          showTermsAndConditionsModal: false,
        });
      }
    }
  }

  handleTrialChange = (
    trial: ValueType<ReactSelectOption<"coach" | "district">, false>
  ) => {
    const item = trial as ReactSelectOption<"coach" | "district">;
    this.setState({
      trial: item.value,
    });
  };

  handleChange = (event: React.FormEvent<any>) => {
    const { name, value } = event.target as HTMLInputElement;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
      passwordsMatch: true,
    }));
  };

  handleChangeState = (value: ValueType<USState, false>) => {
    const typedValue = value as USState;
    this.setState((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        state: typedValue.value,
      },
    }));
  };

  handleSubmit = (event: React.FormEvent<any>) => {
    event.preventDefault();
    if (this.state.user.password !== this.state.user.password_confirmation) {
      this.setState({ passwordsMatch: false });
      return;
    }
    this.handleShowTACModal();
  };

  registerUser = () => {
    const { user, trial } = this.state;
    this.props.registerNewDistrict(user, trial);
    this.setState({
      passwordsMatch: true,
    });
  };

  handleShowTACModal = () => {
    this.setState({ showTermsAndConditionsModal: true });
  };

  handleHideTACModal = () => {
    this.setState({ showTermsAndConditionsModal: false });
  };

  passwordShow = () => {
    this.setState({
      ...this.state,
      attributeType: this.state.attributeType === "password" ? "text": "password",
    })
  };

  confirmPasswordShow = () => {
    this.setState({
      ...this.state,
      attributeConfirmType: this.state.attributeConfirmType === "password" ? "text": "password",
    })
  };

  render() {
    const { isLoading, error } = this.props;
    const {
      user,
      trial,
      passwordsMatch,
      showTermsAndConditionsModal,
      attributeType,
      attributeConfirmType
    } = this.state;

    const stateValue = user.state;
    return (
      <div>
        <Container className="loginContainer">
          <Row className="align-items-center">
            <Col sm>
              <h1 className="hMax">
                Sign up for a Free Trial to
                <br />
                <strong>{APP_NAME}</strong>
              </h1>
              <br />
              <p className="text-white text-center">
                If you're a coach, signing up for a coach trial allows you to
                test out ReadyCoach's features for 30 days. Once, your trial is
                up, you can work with your district administrators to get full
                access and collaborate with your colleagues.
              </p>
              <hr />
              <p className="text-white text-center">
                If you're a District Administrator, you can sign up for a trial
                and invite staff members to try the app for 30 days.
              </p>
              <hr />
              <h2 className="text-white text-center">Have questions?</h2>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="blueBtnMd m-4">Contact Us Today</button>
              </div>
            </Col>
            <Col sm>
              <div className="welcomeInputContainer">
                <Form onSubmit={this.handleSubmit} data-cy="sign-up-form">
                  <h2 className="m-0 mb-3">
                    <strong>Sign up!</strong>
                  </h2>
                  <FormGroup data-cy={"trial-type-group"}>
                    <FormLabel>
                      <h4>Trial Type</h4>
                    </FormLabel>
                    <Select
                      options={trialSelectOptions}
                      value={
                        trialSelectOptions.find(
                          (item) => item.value === trial
                        ) ?? null
                      }
                      defaultValue={trialSelectOptions[0]}
                      onChange={this.handleTrialChange}
                    />
                  </FormGroup>
                  <FormGroup controlId="validation_first_name">
                    <FormLabel>
                      <h4>First Name</h4>
                    </FormLabel>
                    <FormControl
                      required
                      autoFocus
                      name={"first_name"}
                      type="string"
                      value={user.first_name}
                      onChange={this.handleChange}
                      className="fullWidthInput"
                    />
                  </FormGroup>
                  <FormGroup controlId="validation_last_name">
                    <FormLabel>
                      <h4>Last Name</h4>
                    </FormLabel>
                    <FormControl
                      required
                      name={"last_name"}
                      type="string"
                      value={user.last_name}
                      onChange={this.handleChange}
                      className="fullWidthInput"
                    />
                  </FormGroup>
                  <FormGroup controlId="validation_email">
                    <FormLabel>
                      <h4>Your Email</h4>
                    </FormLabel>
                    <FormControl
                      required
                      name={"email"}
                      type="email"
                      value={user.email}
                      onChange={this.handleChange}
                      className="fullWidthInput"
                      isInvalid={!!error && error.includes("Invalid email")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Email is invalid or already taken
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup controlId="validation_password">
                    <FormLabel>
                      <h4>Password</h4>
                    </FormLabel>
                    <div className="input-container">
                      <FormControl
                        minLength={8}
                        maxLength={20}
                        required
                        name={"password"}
                        value={user.password}
                        onChange={this.handleChange}
                        type={attributeType}
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                      />
                      <FontAwesomeIcon  
                        onClick={this.passwordShow}  
                        icon={attributeType == 'password' ? faEyeSlash : faEye} 
                        className="icon-eye"
                      />
                    </div>
                    <small>Passwords must be 8 to 20 characters long and include at least one uppercase letter, 
                    number and special character. eg. Example@1</small>
                  </FormGroup>
                  <FormGroup controlId="validation_password_confirm">
                    <FormLabel>
                      <h4>Password Confirmation</h4>
                    </FormLabel>
                    <div className="input-container">
                      <FormControl
                        minLength={8}
                        maxLength={20}
                        name={"password_confirmation"}
                        value={user.password_confirmation}
                        onChange={this.handleChange}
                        type={attributeConfirmType}
                        isInvalid={!passwordsMatch}
                      />
                      <FontAwesomeIcon  
                        onClick={this.confirmPasswordShow}  
                        icon={attributeConfirmType == 'password' ? faEyeSlash : faEye} 
                        className="icon-eye"
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Passwords do not match.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup controlId="validation_password_confirm">
                    <FormLabel>
                      <h4>District Name</h4>
                    </FormLabel>
                    <FormControl
                      minLength={8}
                      maxLength={20}
                      name={"district_name"}
                      value={user.district_name}
                      onChange={this.handleChange}
                      type="string"
                    />
                  </FormGroup>
                  <Select
                    id={"state-selector"}
                    className="mt-4"
                    placeholder="Select State..."
                    name={"state"}
                    value={USStateNamesValues?.find(
                      (s) => s.value === stateValue
                    )}
                    options={USStateNamesValues}
                    getOptionLabel={({ display_name }: USState) => display_name}
                    getOptionValue={({ value }: USState) => value}
                    onChange={this.handleChangeState}
                    required
                  />
                  <div className="text-center mt-4">
                    <div>
                      <button
                        data-cy="submit-registration"
                        className="blueBtnMd m-4"
                        disabled={!stateValue}
                        type="submit"
                      >
                        Register
                      </button>
                    </div>
                    <div>
                      <a href={"/app/login"} className="clickableLabel">
                        Login
                      </a>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <TermsAndConditionsModal
          showModal={showTermsAndConditionsModal}
          handleHideModal={this.handleHideTACModal}
          isLoading={isLoading}
          onAccept={this.registerUser}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }: ApplicationState): PropsFromState => {
  return {
    isLoading: auth.isLoading.registerUser,
    error: auth.errors.registerUser,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      registerNewDistrict: registerNewDistrict,
      getRegistrationDistricts: getRegistrationDistricts,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewDistrictRegistrationPage);
