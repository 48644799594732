import {
  InterventionGroup,
  StudentGoal,
} from "../../../../../../store/onboarding/cases/types";
import { Measurement } from "../../../../../../store/onboarding/types";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import SetGoalDeadlinePanel from "../SetGoalDeadlinePanel";
import { Form, FormGroup, Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../../store";
import { connect, useSelector } from "react-redux";
import OutcomeCriteriaInput from "../common/OutcomeCriteriaInput";
import EvidenceTable from "../../../second-step/evidence-tab/EvidenceTable";
import BenchmarkArea from "./BenchmarkArea";
import { getFullName } from "../../../../../../utils/NamesUtils";

type StateProps = {
  isLoading: {
    createStudentGoalForInterventionGroup: boolean;
    createStudentGoalForStudent: boolean;
    createInterventionGroup: boolean;
  };
  errors: {
    createStudentGoalForInterventionGroup?: string;
    createStudentGoalForStudent?: string;
    createInterventionGroup?: string;
  };
  studentGoal?: StudentGoal;
};

type OwnProps = {
  existingGoal?: boolean;
  targetDate?: Date;
  setTargetDate: React.Dispatch<React.SetStateAction<Date | any>>;
  selectedMeasurement: Measurement;
  targetValue: string;
  setTargetValue: React.Dispatch<React.SetStateAction<string>>;
  observationUnitDescription?: string;
  onBackClick: (selectedMeasurement: Measurement) => any;
  setGoal: (currentBaseline?: string) => any;

  isSameGoalForAllStudents: boolean;
  setIsSameGoalForAllStudents: React.Dispatch<React.SetStateAction<boolean>>;

  customTargetOutcome: {
    [studentId: number]: string;
  };
  setCustomTargetOutcome: React.Dispatch<
    React.SetStateAction<{
      [studentId: number]: string;
    }>
  >;
  isDiffMeasurement?: boolean;
 // setIsGeneralOutcomeMeasure?: React.Dispatch<React.SetStateAction<boolean | undefined>> ;
  isGeneralOutcomeMeasure?: boolean ;
  setGeneralOutcome?: (isGeneralOutcomeMeasure?: boolean) => any;
};

type Props = OwnProps & StateProps;

const SetDeadLine: FunctionComponent<Props> = ({
  existingGoal,
  targetDate,
  setTargetDate,
  selectedMeasurement,
  observationUnitDescription,
  targetValue,
  setTargetValue,
  isLoading: {
    createStudentGoalForInterventionGroup: loadingCreateStudentGoalForInterventionGroup,
    createStudentGoalForStudent: loadingCreateStudentGoalForStudent,
    createInterventionGroup: loadingCreateInterventionGroup
  },
  onBackClick,
  setGoal,
  customTargetOutcome,
  setCustomTargetOutcome,
  isSameGoalForAllStudents,
  setIsSameGoalForAllStudents,
  studentGoal: studentGoal,
  isDiffMeasurement,
  isGeneralOutcomeMeasure,
  setGeneralOutcome
}) => {
  const studentGoalOptions = useSelector(
    (s: ApplicationState) => s.cases.modalsState.studentGoalOptions
  );
  const [ isGeneralCheck, setIsGeneralCheck ] 
        = useState<boolean | undefined>(true);

const selectedStudentGoal = useSelector<
        ApplicationState,
        StudentGoal | undefined
      >((s) => s.cases.selectedStudentGoal);

  // useEffect(() => {
  //   console.log(selectedStudentGoal)
  //   setIsGeneralCheck(isGeneralOutcomeMeasure);
  //   //setIsGeneralOutcomeMeasure!(selectedStudentGoal?.is_general_outcome)
  // },[])

  useEffect(() => {
    setTargetDate(studentGoalOptions?.targetDate);
    setTargetValue(
      (studentGoalOptions?.measuredBy &&
        parseInt(studentGoalOptions.measuredBy).toString()) ??
        ""
    );
  }, [studentGoalOptions]);

  const [currentBaseline, setCurrentBaseline] = useState<string>("");

  const handleGoalSet = (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setGoal(currentBaseline);
  };

  const selectedInterventionGroup = useSelector<
    ApplicationState,
    InterventionGroup | undefined
  >((s) => s.cases.selectedInterventionGroup);

  const assessmentTitle = useMemo(() => {
    return `${selectedMeasurement.display_name}`;
    // return `${selectedMeasurement.display_name}${
    //   observationUnitDescription ? ` of ${observationUnitDescription}` : ""
    // }`;
  }, [selectedMeasurement.display_name, observationUnitDescription]);

    useEffect(() => {
      //comment for rc-922 if all student set the goals.
      let isAllStudentsSetGoal = selectedInterventionGroup?.students
      .filter((std) => selectedInterventionGroup.student_goals.some((sg) => sg.student.id == std.id));
      if(!isAllStudentsSetGoal?.length || !existingGoal || isDiffMeasurement) {
      return setIsSameGoalForAllStudents(!!selectedInterventionGroup);
      }
    }, [selectedInterventionGroup]);


  return (
    <>
      <h2 className="font-weight-semibold mb-3">
        Establish an Outcome Criterion for the Target Behavior
      </h2>

      {selectedInterventionGroup && (
        <>
          <div className="small-evidence-table-container mb-2">
            <EvidenceTable
              interventionGroup={selectedInterventionGroup}
              isReadonly
              minimal
              fromSetGoalModal
            />
          </div>
          <h2 className="my-2">{assessmentTitle}</h2>
          <BenchmarkArea
            minimal
            measurement={selectedMeasurement}
            interventionGroup={selectedInterventionGroup}
          />

          {((!existingGoal && !studentGoal) || isDiffMeasurement) && (
            <Form.Check
              id={"same-goal-for-all-students-checkbox"}
              custom
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const checked = e.target.checked;
                setIsSameGoalForAllStudents(checked);
              }}
              className="customDefaultCheckbox mb-2"
              type="checkbox"
              label="Same goal for all students"
              checked={isSameGoalForAllStudents}
              disabled={isDiffMeasurement}
            />
          )}
        </>
      )}

      <form id="set-outcome-form" onSubmit={handleGoalSet}>
        <table className="stdTableWide text-center">
          <thead>
            <tr>
              {(!existingGoal && !studentGoal) || isDiffMeasurement ? (
                <th>Student(s)</th>
              ) : (
                <th>Student</th>
              )}
              <th>Assessment</th>
              <th>Target Outcome</th>
              {selectedStudentGoal && !selectedStudentGoal?.is_primary_goal ? <th>General Outcome</th> : ''}
            </tr>
          </thead>
          <tbody>
            {(!existingGoal && !studentGoal) || isDiffMeasurement ? (
              <>
                {isSameGoalForAllStudents ? (
                  <tr>
                    <td>All</td>
                    <td>
                      <strong>{assessmentTitle}</strong>
                    </td>
                    <td>
                      <OutcomeCriteriaInput
                        targetValue={targetValue}
                        setTargetValue={setTargetValue}
                        measurement={selectedMeasurement}
                      />
                    </td>
                  </tr>
                ) : (
                  selectedInterventionGroup?.students
                  .filter((std) => !selectedInterventionGroup.student_goals.some((sg) => sg.student.id == std.id))
                  .map((student) => (
                    <tr key={`student_${student.id}`}>
                      <td>{getFullName(student)}</td>
                      <td>
                        <strong>{assessmentTitle}</strong>
                      </td>
                      <td>
                        <OutcomeCriteriaInput
                          targetValue={customTargetOutcome[student.id!] || ""}
                          setTargetValue={(value: string) => {
                            setCustomTargetOutcome((d) => ({
                              ...d,
                              [student.id!]: value,
                            }));
                          }}
                          measurement={selectedMeasurement}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </>
            ) : (
              <tr>
                <td>{getFullName(studentGoal?.student)}</td>
                <td>
                  <strong>{assessmentTitle}</strong>
                </td>
                <td>
                  <OutcomeCriteriaInput
                    targetValue={targetValue}
                    setTargetValue={setTargetValue}
                    measurement={selectedMeasurement}
                  />
                </td>
                {!selectedStudentGoal?.is_primary_goal ? <td>
                  <Form.Check
                    id="is_general_outcome"
                    className="mb-3 ml-2 font-weight-bold"
                    checked={isGeneralOutcomeMeasure}
                    onChange={(e) => setGeneralOutcome!(e.target.checked)}
                    custom
                    type="checkbox"
                  />
                </td> : ''}
              </tr>
            )}
          </tbody>
        </table>

        {studentGoalOptions && (
          <div className="goalDeadlineContainer mt-3">
            <h4 className="font-weight-bold mb-2">Current Baseline</h4>
            <FormGroup className="d-flex align-items-center">
              <h4 className="font-weight-bold text-nowrap mr-3">
                Outcome Criterion
              </h4>

              <OutcomeCriteriaInput
                optional
                withoutStyles
                targetValue={currentBaseline}
                setTargetValue={setCurrentBaseline}
                measurement={selectedMeasurement}
              />
            </FormGroup>
          </div>
        )}
      </form>

      <SetGoalDeadlinePanel
        targetDate={targetDate}
        setTargetDate={setTargetDate}
      />
      <hr />
      <div className="btnActions">
        <button 
          className="blueBtnSm" 
          onClick={() => onBackClick(selectedMeasurement)}
          disabled={(loadingCreateStudentGoalForInterventionGroup ||
            loadingCreateStudentGoalForStudent ||
            loadingCreateInterventionGroup )}>
          Back
        </button>
        <button
          data-cy="set-goal-btn"
          className="blueBtnSm"
          disabled={!targetDate || (loadingCreateStudentGoalForInterventionGroup ||
            loadingCreateStudentGoalForStudent ||
            loadingCreateInterventionGroup )}
          type="submit"
          form="set-outcome-form"
        >
          { isDiffMeasurement ? "Create New Group with Updated Goal" : existingGoal ? "Update Goal" : "Set goal"} {" "}
          {(loadingCreateStudentGoalForInterventionGroup ||
            loadingCreateStudentGoalForStudent ||
            loadingCreateInterventionGroup ) && (
            <Spinner animation="border" size="sm" />
          )}
        </button>
      </div>
    </>
  );
};

const mapStateToProps = ({ cases }: ApplicationState): StateProps => {
  return {
    isLoading: {
      createStudentGoalForInterventionGroup:
        cases.isLoading.createStudentGoalForInterventionGroup,
      createStudentGoalForStudent: cases.isLoading.createStudentGoalForStudent,
      createInterventionGroup: cases.isLoading.createInterventionGroup
    },
    errors: {
      createStudentGoalForInterventionGroup:
        cases.errors.createStudentGoalForInterventionGroup,
      createStudentGoalForStudent: cases.errors.createStudentGoalForStudent,
      createInterventionGroup: cases.errors.createInterventionGroup
    },
    studentGoal: cases.selectedStudentGoal,
  };
};

export default connect(mapStateToProps)(SetDeadLine);
