import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Assessment,
  Measurement,
} from "../../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  backToFba,
  getAssessmentStaticData,
  getMeasurementStaticData,
} from "../../../../../../../store/onboarding/cases/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import LoadingIndicator from "../../../../LoadingIndicator";
import { cleanUpAssessmentName } from "../../utils";
import MeasurementItem from "./MeasurementItem";

type StateProps = {
  staticData: {
    measurements: Array<Measurement>;
    assessments: Array<Assessment>;
  };
  isLoading: {
    getAssessmentStaticData: boolean;
    getMeasurementStaticData: boolean;
  };
  errors: {
    getAssessmentStaticData?: string;
    getMeasurementStaticData?: string;
  };
};

type DispatchProps = {
  getAssessmentStaticData: () => any;
  getMeasurementStaticData: () => any;
};

type OwnProps = {
  selectedMeasurement?: Measurement;
  setSelectedMeasurement: React.Dispatch<
    React.SetStateAction<Measurement | undefined>
  >;
  observationUnitDescription: string;
  setObservationUnitDescription: React.Dispatch<React.SetStateAction<string>>;
  onClickNext: () => void;
  findDifferentAssessment?: Function;
};

type Props = OwnProps & StateProps & DispatchProps;

const ObservationIntro: FunctionComponent<Props> = ({
  selectedMeasurement,
  setSelectedMeasurement,
  observationUnitDescription,
  setObservationUnitDescription,
  staticData,
  isLoading: {
    getAssessmentStaticData: loadingGetAssessmentStaticData,
    getMeasurementStaticData: loadingGetMeasurementStaticData,
  },
  getAssessmentStaticData,
  getMeasurementStaticData,

  onClickNext,
  findDifferentAssessment,
}) => {
  const dispatch = useDispatch();

  const [staticAssessments, setStaticAssessments] = useState<
    Array<Assessment & { measurements: Array<Measurement> }>
  >([]);

  useEffect(() => {
    getAssessmentStaticData();
    getMeasurementStaticData();
  }, []);

  useEffect(() => {
    if (staticData.assessments.length && staticData.measurements.length) {
      const staticAssessments: Array<Assessment & {
        measurements: Array<Measurement>;
      }> = staticData.measurements.reduce(
        (pV, cV) => {
          return pV.map((x: any) => {
            if (x.id === cV.assessment!.id) {
              return {
                ...x,
                measurements: x.measurements ? [...x.measurements, cV] : [cV],
              };
            }
            return x;
          });
        },
        staticData.assessments.map((x) => ({ ...x, measurements: [] }))
      );
      setStaticAssessments(staticAssessments);
    }
  }, [staticData]);

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onClickNext();
  };

  return (
    <>
      <h2 className="font-weight-semibold">Direct Observation</h2>
      <h3 className="mb-4 font-weight-normal">
        Select how you would like to observe and record target behaviors. You
        can choose frequency/event, interval, duration, or latency recording.
        Each option comes with a specialized observation template you can
        customize.
      </h3>

      <div>
        {loadingGetAssessmentStaticData && loadingGetMeasurementStaticData ? (
          <LoadingIndicator />
        ) : (
          <form id="direct-observation-form" onSubmit={handleFormSubmit}>
            {staticAssessments.map((sa, index) => (
              <div key={index}>
                <div className="staticAssessmentRow">
                  {cleanUpAssessmentName(sa.name)}
                </div>
                <div className="staticMeasurementBody">
                  {sa.measurements.map((m) => (
                    <MeasurementItem
                      key={m.id}
                      measurement={m}
                      observationUnitDescription={observationUnitDescription}
                      setObservationUnitDescription={
                        setObservationUnitDescription
                      }
                      selectedMeasurement={selectedMeasurement}
                      setSelectedMeasurement={setSelectedMeasurement}
                    />
                  ))}
                </div>
              </div>
            ))}
          </form>
        )}
      </div>

      {findDifferentAssessment && (
        <>
          <hr />
          <button
            className="blueBtnSm"
            onClick={() => findDifferentAssessment()}
          >
            Find a different assessment
          </button>
        </>
      )}

      <hr />
      <div className="btnActions">
        {findDifferentAssessment ? (
          <button
            className="blueBtnSm"
            onClick={() => {
              dispatch(backToFba());
            }}
          >
            Back
          </button>
        ) : (
          <div />
        )}
        <button
          className="blueBtnSm"
          form="direct-observation-form"
          type={"submit"}
          disabled={!selectedMeasurement}
        >
          Next
        </button>
      </div>
    </>
  );
};

const mapStateToProps = ({ cases }: ApplicationState): StateProps => {
  return {
    staticData: cases.staticData,
    isLoading: {
      getAssessmentStaticData: cases.isLoading.getAssessmentStaticData,
      getMeasurementStaticData: cases.isLoading.getMeasurementStaticData,
    },
    errors: {
      getAssessmentStaticData: cases.errors.getAssessmentStaticData,
      getMeasurementStaticData: cases.errors.getMeasurementStaticData,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getAssessmentStaticData: getAssessmentStaticData,
      getMeasurementStaticData: getMeasurementStaticData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ObservationIntro);
