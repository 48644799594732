import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlusCircle, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { Master_Data, Master_Data_Variation } from "../../../../../store/superuser/types";
import { showConfirmDialog } from "../../../../../store/confirm-dialog/actions";
import { createMasterDataVariation, deleteMasterDataVariation, updateMasterDataVariation } from "../../../../../store/superuser/actions";

type OwnProps = {
  variationObj: Master_Data;
  masterDataType: string;
  displayIndex?: number;
  variationIndex?: number;
  totalRows?: number;
  addNewRow: (dIndex: number, vIndex: number, target_value: string) => void;
  editableRow: (masterDataId: string, editAction: boolean) => void;
  removeRow: (masterDataId: string) => void;
};

type DispatchProps = {
  createMasterDataVariation: (
    variationData: Partial<Master_Data_Variation>
  ) => any;
  updateMasterDataVariation: (
    masterDataId: number,
    variationData: Partial<Master_Data_Variation>
  ) => any;
  deleteMasterDataVariation?: any;
};

type Props = OwnProps & DispatchProps;

const MasterDataVariations: FunctionComponent<Props> = ({
  variationObj,
  masterDataType,
  createMasterDataVariation,
  updateMasterDataVariation,
  deleteMasterDataVariation,
  displayIndex,
  variationIndex,
  totalRows,
  addNewRow,
  editableRow,
  removeRow
}) => {
  const [loading, setLoading] = useState(false);
  let [variationData, setVariationData] = useState<Master_Data_Variation | undefined>(undefined);

  const dispatch = useDispatch();

  const handleShow = (id: any) => {
    dispatch(
      showConfirmDialog({
        centered: true,
        onConfirm: () => {
          deleteMasterDataVariation(id).then(
            (responseData: string) => {
              toastr.success('Success!!', 'Variation deleted successfully.')
            },
            (err: string) => {
              toastr.error('Alert!!', err)
              setLoading(false);
            }
          );
        },
      })
    );
  };

  useEffect(() => {
    if (variationObj) {
      variationData = {
        id: variationObj.id,
        type: masterDataType,
        target_value: variationObj.target_value!,
        variation: variationObj.variation
      }
      setVariationData(variationData)
    }
  }, [variationObj]);

  const handleVariationChange = (event: React.ChangeEvent<any>) => {
    const { value } = event.target as HTMLInputElement;
    setVariationData((s) => (s ? { ...s, variation: value } : undefined));
  };

  const handleUpdateVariation = () => {
    if (variationData) {
      setLoading(true);
      if(variationData.id!) {
        updateMasterDataVariation(variationData.id!, {
          type: variationData.type,
          target_value: variationData.target_value,
          variation: variationData.variation
        }).then(
          (responseData: string) => {
            editableRow(variationObj?.masterDataId!, false)
            setLoading(false);
            toastr.success('Success!!', responseData)
          },
          (err: string) => {
            toastr.error('Alert!!', err)
            setLoading(false);
          }
        );
      } else {
        createMasterDataVariation({
          type: variationData.type,
          target_value: variationData.target_value,
          variation: variationData.variation
        }).then(
          (responseData: string) => {
            editableRow(variationObj?.masterDataId!, false)
            setLoading(false);
            toastr.success('Success!!', responseData)
          },
          (err: string) => {
            toastr.error("Failed to update the progress", err);
            setLoading(false);
          }
        );
      }
    }
  };

  return <>
    {variationObj.isEditble || variationObj.isAddNew ? (
      <tr>
        <td className="text-nowrap" colSpan={2}>
          <div className="flex-wrap-container">
          <InputGroup>
            <Form.Control
              type="text"
              required={true}
              placeholder="Variation"
              onChange={handleVariationChange}
              value={variationData?.variation}
            />
          </InputGroup>
            <div className="d-flex justify-content-center align-items-center">
            {variationObj.isAddNew
            ?
              <button
                className="redBtnSm"
                onClick={() => removeRow(variationObj?.masterDataId!)}
              >
                Dismiss
              </button>
            :
              <button
                className="whiteBtnSm"
                onClick={() => editableRow(variationObj?.masterDataId!, false)}
              >
                Cancel
              </button>
            }
            <button
              disabled={!variationData?.variation.trim()}
              className="blueBtnSm ml-3 d-flex align-items-center"
              onClick={handleUpdateVariation}
            >
              {variationObj.isAddNew ? `Save` : `Update`}
              {loading && (
                <Spinner animation="border" size="sm" className="ml-3" />
              )}
            </button>
            </div>
          </div>
        </td>
      </tr>
    ) : (
      <tr>
        <td style={{width: '90%'}} className="text-wrap ">{variationObj.variation}</td>
        <td style={{width: '10%'}}>
              <span className="pointer mr-1" onClick={() => editableRow(variationObj?.masterDataId!, true)}>
                <FontAwesomeIcon icon={faEdit} size="1x" className="purpleText mr-2" />
              </span>
              {variationData?.id ? <span className="pointer" onClick={() => handleShow(variationData?.id)}>
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  size="1x"
                  className="purpleText"
                /> 
              </span>: ''}
        </td>
      </tr>
    )}
    {variationIndex == totalRows ?
    <tr>
      <td colSpan={2}>
      <button className="blueBtnSm mr-3" onClick={() => addNewRow(displayIndex!, variationIndex!+1, variationObj?.target_value!)}>
        <FontAwesomeIcon icon={faPlusCircle} style={{ color: "white" }} />{" "}
        Add New
      </button>
      </td>
    </tr>
    : ''}
  </>
  
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      createMasterDataVariation: createMasterDataVariation,
      updateMasterDataVariation: updateMasterDataVariation,
      deleteMasterDataVariation: deleteMasterDataVariation,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(MasterDataVariations);
