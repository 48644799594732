import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";
import * as chatActions from "./actions";
import { ChatActionTypes, ChatState } from "./types";
import * as onboardingActions from "../onboarding/actions";

export type ChatAction = ActionType<typeof chatActions>;

const initialState: ChatState = {
  chatThreads: [],
  chatMessages: [],
  chatReports: [],
  modalsState: {
    chatModal: false,
  },
  isLoading: {
    chatThreads: false,
    chatMessages: false,
    startChatThread: false,
    deleteChatThread: false,
    reportChatMessage: false,
    getChatReports: false,
  },
  errors: {},
};

const reducer: Reducer<ChatState> = (
  state: ChatState = initialState,
  action: ChatAction
): ChatState => {
  switch (action.type) {
    case ChatActionTypes.OPEN_CHAT_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, chatModal: true },
      };
    case ChatActionTypes.HIDE_CHAT_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, chatModal: false },
      };

    case ChatActionTypes.GET_CHAT_THREADS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, chatThreads: true },
      };
    case ChatActionTypes.GET_CHAT_THREADS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, chatThreads: false },
        errors: { ...state.errors, chatThreads: undefined },
        chatThreads: action.payload.chatThreads.sort((a, b) => {
          if (a.updated! < b.updated!) return 1;
          if (a.updated! > b.updated!) return -1;
          return 0;
        }),
      };
    case ChatActionTypes.GET_CHAT_THREADS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, chatThreads: false },
        errors: { ...state.errors, chatThreads: action.payload.error },
      };

    case ChatActionTypes.GET_CHAT_MESSAGES_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, chatMessages: true },
      };
    case ChatActionTypes.GET_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, chatMessages: false },
        errors: { ...state.errors, chatMessages: undefined },
        chatMessages: action.payload.chatMessages,
      };
    case ChatActionTypes.GET_CHAT_MESSAGES_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, chatMessages: false },
        errors: { ...state.errors, chatMessages: action.payload.error },
      };

    case ChatActionTypes.START_CHAT_THREAD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, startChatThread: true },
      };
    case ChatActionTypes.START_CHAT_THREAD_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, startChatThread: false },
        errors: { ...state.errors, startChatThread: undefined },
        chatThreads: [action.payload.chatThread, ...state.chatThreads],
      };
    case ChatActionTypes.START_CHAT_THREAD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, startChatThread: false },
        errors: { ...state.errors, startChatThread: action.payload.error },
      };

    case ChatActionTypes.DELETE_CHAT_THREAD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteChatThread: true },
      };
    case ChatActionTypes.DELETE_CHAT_THREAD_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteChatThread: false },
        errors: { ...state.errors, deleteChatThread: undefined },
        chatThreads: [
          ...state.chatThreads.filter(
            (chatThread) => chatThread.id !== action.payload.chatThreadId
          ),
        ],
      };
    case ChatActionTypes.DELETE_CHAT_THREAD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteChatThread: false },
        errors: { ...state.errors, deleteChatThread: action.payload.error },
      };

    case ChatActionTypes.ADD_MESSAGE_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, chatThreads: true },
      };
    case ChatActionTypes.ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, chatThreads: false },
        errors: { ...state.errors, chatThreads: undefined },
        chatMessages: [...state.chatMessages, action.payload.chatMessage],
      };
    case ChatActionTypes.ADD_MESSAGE_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, chatThreads: false },
        errors: { ...state.errors, chatThreads: action.payload.error },
      };

    case ChatActionTypes.REPORT_CHAT_MESSAGE_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, reportChatMessage: true },
      };
    case ChatActionTypes.REPORT_CHAT_MESSAGE_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, reportChatMessage: false },
      };
    case ChatActionTypes.REPORT_CHAT_MESSAGE_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, reportChatMessage: false },
      };

    case ChatActionTypes.GET_CHAT_REPORTS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getChatReports: true },
      };
    case ChatActionTypes.GET_CHAT_REPORTS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getChatReports: false },
        chatReports: action.payload.chatReports,
      };
    case ChatActionTypes.GET_CHAT_REPORTS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getChatReports: false },
      };

    case ChatActionTypes.DELETE_CHAT_REPORT_SUCCESS:
      return {
        ...state,
        chatReports: state.chatReports.filter(
          (cr) => cr.id !== action.payload.chatReportId
        ),
      };

    case getType(chatActions.changeSelectedChatThread): {
      return {
        ...state,
        selectedThread: action.payload,
      };
    }

    default:
      return state;
  }
};

export { reducer as chatReducer };
