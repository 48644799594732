import React, { Component } from "react";
import {
  getParents,
  getStudents,
  openInviteParentModal,
} from "../../../../../store/onboarding/actions";
import NoParentsView from "./NoParentsView";
import ParentsTable from "./ParentsTable";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import ParentFormModal from "./ParentFormModal";

type PropsFromState = {
  hasParentsList: boolean;
  isLoading: {
    getParents?: boolean;
    getStudents?: boolean;
  };
};

type DispatchProps = {
  getStudents: () => any;
  getParents: () => any;
  openInviteParentModal: () => any;
};

type OwnProps = {};

type Props = OwnProps & PropsFromState & DispatchProps;

class ParentsTab extends Component<Props> {
  componentDidMount(): void {
    this.props.getStudents();
    this.props.getParents();
    // this.updateDataTables();
  }
  //
  // updateDataTables = () => {
  //   this.props.getParents();
  // };

  handleInviteParent = () => {
    this.props.openInviteParentModal();
  };

  render() {
    return (
      <>
        <div className="onboardingContainer parentContainer">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1>Parent/Guardian Access</h1>
              <h3>
                Inviting Parents to ReadyCoach allows them to see the
                interventions being done with their children.
              </h3>
            </div>
            <div className="toolsRowContainer">
              <button className="blueBtnLg" onClick={this.handleInviteParent}>
                Invite Parent
              </button>
            </div>
          </div>

          {this.props.hasParentsList || this.props.isLoading.getParents ? (
            <div className="horizontalScrollContainer">
              <ParentsTable />
            </div>
          ) : (
            <NoParentsView />
          )}
        </div>
        <ParentFormModal />
      </>
    );
  }
}

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    hasParentsList: !!onboarding.parents.length,
    isLoading: {
      getParents: onboarding.isLoading.getParentsList,
      getStudents: onboarding.isLoading.getStudents,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getParents: getParents,
      getStudents: getStudents,
      push: push,
      openInviteParentModal: openInviteParentModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParentsTab);
