import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { patchInterventionGroup } from "../../../../../../store/onboarding/cases/actions";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { ApplicationState } from "../../../../../../store";

type OwnProps = {
  isReadonly?: boolean;
  isEditName: boolean;
  setIsEditName: React.Dispatch<React.SetStateAction<boolean>>;
  interventionGroupId: number;
  interventionGroupName: string;
};

type Props = OwnProps;

const EditableGroupName: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const isStudentDetail =  useSelector(
    (s: ApplicationState) => s.cases.isStudentDetail
  );

  const {
    isReadonly,
    isEditName,
    setIsEditName,
    interventionGroupId,
    interventionGroupName,
  } = props;

  const [editName, setEditName] = useState<string>("");

  const onEditName = () => {
    setIsEditName(true);
    setEditName(interventionGroupName);
  };

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    setEditName(target.value);
  };

  const handleSaveName = () => {
    if (editName === interventionGroupName) {
      setIsEditName(false);
      return;
    }

    dispatch<any>(
      patchInterventionGroup(interventionGroupId, { name: editName })
    ).then(
      () => setIsEditName(false),
      (err: string) => {
        toastr.error("Failed to update the Intervention Group", err);
      }
    );
  };

  return (
    <div className="editable-name flex-1">
      {isEditName ? (
        <>
          <input
            className="stdInput flex-1"
            type="text"
            value={editName}
            onChange={handleChangeName}
          />

          <div onClick={handleSaveName} className="pointer">
            <FontAwesomeIcon
              icon={faCheck}
              size="1x"
              style={{ color: "#ffffff" }}
            />
          </div>
        </>
      ) : (
        <>
          <h3 title={interventionGroupName}>{interventionGroupName}</h3>
          {!isReadonly && !isStudentDetail && (
            <div onClick={onEditName} className="pointer">
              <FontAwesomeIcon
                icon={faPencilAlt}
                size="1x"
                style={{ color: "#ffffff" }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EditableGroupName;
