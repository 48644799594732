import { Button, Col, Image, Row } from "react-bootstrap";
import React, { Component } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBullseye,
  faList,
  faPlusCircle,
  faPuzzlePiece,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons";
import { UserInfo } from "../../../../store/auth/types";
import { ApplicationState } from "../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  getUserInfo,
  updateUserOnboardingStep,
} from "../../../../store/auth/actions";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { hideLearnMoreModal } from "../../../../store/onboarding/cases/actions";
import relationshipIcon from "../../../../Images/relationshipIcon.png";

library.add(faPlusCircle, faPuzzlePiece, faBullseye, faList, faUserClock);

type PropsFromState = {
  userInfo?: UserInfo;
};

type DispatchProps = {
  updateUserOnboardingStep: () => any;
  hideLearnMoreModal: () => any;
  getUserInfo: () => any;
  push: (location: string) => any;
};

type Props = PropsFromState & DispatchProps;

class ThirdTab extends Component<Props> {
  handleGetStartedClick = () => {
    // if (
    //   !this.props.userInfo ||
    //   this.props.userInfo.profile.onboarding_state !==
    //     OnboardingStates.COACH_ONBOARDING_STATE_INTRO
    // ) {
    //   this.props.push("/onboarding");
    // } else {
    //   this.props.updateUserOnboardingStep();
    // }
    this.props.hideLearnMoreModal();
  };

  render() {
    return (
      <>
        <div className="firstTabContainer">
          {/* <h2 className="font-weight-bold">The Heart &amp; Science Behind It</h2> */}
          <h4 className="font-weight-bold mb-3">
            <b>ReadyCoach</b> supports instructional coaching by facilitating:
          </h4>
          <ul>
            <li>
              Collaboration with educators to make important assessment and
              intervention decisions.
            </li>
            <li>
              Application of behavior change theory and supportive relationship
              practices.
            </li>
            <li>
              Generation of actionable insights for coaches and educators
              serving Pre K-12 grade students.
            </li>
          </ul>
          {/*<Row>*/}
          {/*  <Col xs="auto">*/}
          {/*    <Image src={require("../../../../Images/thinkIcon.png")} />*/}
          {/*  </Col>*/}
          {/*  <Col>*/}
          {/*    <p>*/}
          {/*      Application of 5 strategic <b>Think Rules</b> shape*/}
          {/*      communications and decisions for assessment and intervention*/}
          {/*      actions:*/}
          {/*    </p>*/}
          {/*    <FontAwesomeIcon icon="user-clock" />*/}
          {/*    <p className="d-inline">&nbsp; Think Behavior / Skill</p>*/}
          {/*    <br />*/}
          {/*    <FontAwesomeIcon icon="plus-circle" />*/}
          {/*    <p className="d-inline">&nbsp; Think Positive</p>*/}
          {/*    <br />*/}
          {/*    <FontAwesomeIcon icon="puzzle-piece" />*/}
          {/*    <p className="d-inline">&nbsp; Think Context</p>*/}
          {/*    <br />*/}
          {/*    <FontAwesomeIcon icon="bullseye" />*/}
          {/*    <p className="d-inline">&nbsp; Think Goal</p>*/}
          {/*    <br />*/}
          {/*    <FontAwesomeIcon icon="list" />*/}
          {/*    <p className="d-inline">&nbsp; Think Solution</p>*/}
          {/*    <br />*/}
          {/*    <br />*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <Row>
            <Col xs="auto">
              <Image src={relationshipIcon} />
            </Col>
            <Col>
              <p>
                <b>Positive Regard</b> and <b>Supportive Relationships</b> tips
                are offered throughout the coaching process to promote effective
                interactions and solution-focused changes with educators.
              </p>
            </Col>
          </Row>
          <div className="text-center">
            <br />
            <Button
              bsPrefix="b"
              className="blueBtnLg"
              onClick={this.handleGetStartedClick}
            >
              Get Started
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ auth }: ApplicationState): PropsFromState => {
  return {
    userInfo: auth.userInfo,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      updateUserOnboardingStep: updateUserOnboardingStep,
      hideLearnMoreModal: hideLearnMoreModal,
      getUserInfo: getUserInfo,
      push: push,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ThirdTab);
