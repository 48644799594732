import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  CoachTeacherRelationshipRequest,
  SupervisorEducatorRelationshipRequest,
  TeacherInfo,
} from "../../../../../store/onboarding/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ModalCloseButton from "../../third-step/group-students/common/ModalCloseButton";
import { getFullName } from "../../../../../utils/NamesUtils";
import LoadingIndicator from "../../LoadingIndicator";
import { CustomCheckbox } from "../evidence-tab/conduct-interview/CustomCheckbox";
import {
  addEducatorsToSupervisors,
  addTeachersToCoaches,
  hideAssignEducatorsToEmployeeModal,
  openTeacherDetailsModal,
} from "../../../../../store/onboarding/actions";
import { UserAssignmentRole } from "../../../../../store/auth/types";
import { toastr } from "react-redux-toastr";

type StateProps = {
  showModal: boolean;
  teachersRoster: Array<TeacherInfo>;
  selectedEmployee?: TeacherInfo;
  isLoading: {
    getTeachers: boolean;
    addTeachersToCoaches: boolean;
    addEducatorsToSupervisors: boolean;
  };
};

type DispatchProps = {
  openTeacherDetailsModal: () => any;
  hideAssignEducatorsToCoachModal: () => any;
  addTeachersToCoaches: (
    relationships: Array<CoachTeacherRelationshipRequest>
  ) => any;
  addEducatorsToSupervisors: (
    relationships: Array<SupervisorEducatorRelationshipRequest>
  ) => any;
};

type Props = StateProps & DispatchProps;

const AssignEducatorsToEmployeeModal: FunctionComponent<Props> = ({
  showModal,
  isLoading,
  teachersRoster,
  selectedEmployee,
  openTeacherDetailsModal,
  addTeachersToCoaches,
  addEducatorsToSupervisors,
  hideAssignEducatorsToCoachModal,
}) => {
  const [teachersFilter, setTeachersFilter] = useState("");
  const [filteredTeachersRoster, setFilteredTeachersRoster] = useState<
    Array<TeacherInfo>
  >([]);
  const [assignedTeacherIds, setAssignedTeacherIds] = useState<Array<number>>(
    []
  );

  useEffect(() => {
    setFilteredTeachersRoster(teachersRoster.filter(filterTeachers()));
  }, [teachersRoster]);

  const isCoach = useMemo(
    () =>
      selectedEmployee?.user.profile.current_assignment?.role ===
      UserAssignmentRole.COACH,
    [selectedEmployee]
  );

  const handleShowModal = () => {
    setTeachersFilter("");
    setAssignedTeacherIds([]);
  };

  const handleHideModal = () => {
    hideAssignEducatorsToCoachModal();
    openTeacherDetailsModal();
  };

  const filterTeachers = (value: string = teachersFilter || "") => (
    teacher: TeacherInfo
  ) => {
    return (
      teacher.user.profile.current_role === UserAssignmentRole.TEACHER &&
      (!value ||
        getFullName(teacher.user)
          .toLowerCase()
          .includes(value.toLowerCase()))
    );
  };

  const onTeacherFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTeachersFilter(value);
    setFilteredTeachersRoster(teachersRoster.filter(filterTeachers(value)));
  };

  const handleTeachersAssign = () => {
    const selectedEmployeeId = selectedEmployee!.user.id;

    if (isCoach) {
      const relationships: Array<CoachTeacherRelationshipRequest> = assignedTeacherIds.map(
        (id) => ({
          coach: selectedEmployeeId,
          teacher: id,
        })
      );

      addTeachersToCoaches(relationships).then(
        () => handleHideModal(),
        (error: string) => toastr.error("Failed to assign the educators", error)
      );
    } else {
      const relationships: Array<SupervisorEducatorRelationshipRequest> = assignedTeacherIds.map(
        (id) => ({
          supervisor: selectedEmployeeId,
          user: id,
        })
      );
      addEducatorsToSupervisors(relationships).then(
        () => handleHideModal(),
        (error: string) => toastr.error("Failed to assign the coaches", error)
      );
    }
  };

  const handleAssignTeacherChange = (teacherId: number) => {
    setAssignedTeacherIds((ids) =>
      ids.includes(teacherId)
        ? ids.filter((id) => id != teacherId)
        : [...ids, teacherId]
    );
  };

  return (
    <Modal
      animation={false}
      backdropClassName="customDarkModalBackdrop in"
      show={showModal}
      onShow={handleShowModal}
      onHide={handleHideModal}
      size="lg"
    >
      <Modal.Header className="purpleModalHeader centerModalHeader">
        <button className={"btnModalBack"} onClick={handleHideModal}>
          <FontAwesomeIcon icon={faChevronLeft} size={"lg"} />
        </button>
        <Modal.Title>{`Assign Educators to ${selectedEmployee?.user.first_name}`}</Modal.Title>
        <ModalCloseButton onClose={handleHideModal} />
      </Modal.Header>
      <Modal.Body>
        <h3 className="font-weight-bold">
          Select one or more educators to assign to{" "}
          {selectedEmployee && getFullName(selectedEmployee?.user)}
        </h3>
        <p>
          You can assign teachers to {selectedEmployee?.user.first_name} to help
          speed up the process but {selectedEmployee?.user.first_name} will also
          be able to invite educators to collaborate on cases.
        </p>

        <div>
          <div className="mb-3 d-flex justify-content-end">
            <input
              type="text"
              placeholder={`Search teachers...`}
              value={teachersFilter || ""}
              onChange={onTeacherFilterChange}
              className="stdInput"
            />
          </div>
          <table className="scrollableTable">
            <thead>
              <tr>
                <th />
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {isLoading.getTeachers ? (
                <tr>
                  <td colSpan={2}>
                    <LoadingIndicator />
                  </td>
                </tr>
              ) : filteredTeachersRoster.length ? (
                filteredTeachersRoster.map((teacher) => (
                  <tr
                    key={teacher.user.id}
                    onClick={() => handleAssignTeacherChange(teacher.user.id)}
                  >
                    <td className="d-flex">
                      <CustomCheckbox
                        checked={assignedTeacherIds.includes(teacher.user.id)}
                      />
                    </td>
                    <td>{getFullName(teacher.user)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={2}>No matching teachers</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <hr />
        <div className="modalActions">
          <div />
          <button
            className="blueBtnSm"
            disabled={!assignedTeacherIds.length}
            onClick={handleTeachersAssign}
          >
            Assign{" "}
            {(isLoading.addTeachersToCoaches ||
              isLoading.addEducatorsToSupervisors) && (
              <Spinner animation="border" size="sm" />
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  const alreadyAssignedUsers = onboarding.districtRelationships.map(
    (user) => user.child.user.id
  );
  return {
    showModal: onboarding.modalsState.assignEducatorsToEmployee,
    selectedEmployee: onboarding.selectedTeacher,
    teachersRoster: onboarding.teachersRoster.filter(
      (t) => !alreadyAssignedUsers.includes(t.user.id)
    ),
    isLoading: {
      getTeachers: onboarding.isLoading.getTeachers,
      addTeachersToCoaches: onboarding.isLoading.addTeachersToCoaches,
      addEducatorsToSupervisors: onboarding.isLoading.addEducatorsToSupervisors,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      openTeacherDetailsModal: openTeacherDetailsModal,
      hideAssignEducatorsToCoachModal: hideAssignEducatorsToEmployeeModal,
      addTeachersToCoaches: addTeachersToCoaches,
      addEducatorsToSupervisors: addEducatorsToSupervisors,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignEducatorsToEmployeeModal);
