import React, { FunctionComponent, useEffect, useState } from "react";
import {
  UserAssignmentRole,
  UserInfo,
} from "../../../../../../store/auth/types";
import { Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { USState, USStateNamesValues } from "../../../../../common/States";
import { ValueType } from "react-select/src/types";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import {
  District_Request,
  DistrictInfo,
  SuperuserState,
} from "../../../../../../store/superuser/types";
import { RouteComponentProps, withRouter } from "react-router";
import {
  selectDistrict,
  setShowUserModal,
  updateDistrict,
} from "../../../../../../store/superuser/actions";
import { push } from "connected-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";
import { toastr } from "react-redux-toastr";
import CreatableSelect from "react-select/creatable";
import TeacherInviteIndividuallyModal from "../../../../../common/onboarding/second-step/TeacherInviteIndividuallyModal";
import DistrictOwnerSelector from "../DistrictOwnerSelector";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";

const defaultValue: District_Request = {
  name: "",
  state: "",
};

const convert = ({
  id,
  name,
  state,
  owner,
  can_use_commercial_tools,
  individual_view_permission
}: DistrictInfo): District_Request => ({
  id,
  name,
  state,
  owner: owner.id,
  can_use_commercial_tools,
  individual_view_permission
});

type MatchParams = {
  districtId?: string;
};

type Props = RouteComponentProps<MatchParams>;

const DistrictDetailsEdit: FunctionComponent<Props> = ({
  match: {
    params: { districtId },
  },
}) => {
  const {
    selectedDistrict,
    districts,
    isLoading: { updateDistrict: isLoading },
    errors: { updateDistrict: updateDistrictError },
  } = useSelector<ApplicationState, SuperuserState>((s) => s.superuser);

  const dispatch = useDispatch();

  const [district, setDistrict] = useState<District_Request>(defaultValue);

  useEffect(() => {
    const foundedDistrict = districts.find(
      (item) => item.id.toString() === districtId
    );
    if (foundedDistrict) {
      dispatch(selectDistrict(foundedDistrict));
      setDistrict(convert(foundedDistrict));
    }    
  }, [districts, districtId]);

  const handleCancel = () => {
    if (selectedDistrict) {
      dispatch(push(`/superuser/districts/${selectedDistrict.id}`));
    }
  };

  const handleChangeState = (value: ValueType<USState, false>) => {
    const typedValue = value as USState;
    setDistrict((prevState) => ({ ...prevState, state: typedValue.value }));
  };

  const handleCanUserCommercialTools = (e: React.ChangeEvent<HTMLInputElement>) => {
    var tar =  e?.target?.checked    
    setDistrict((prevState) => (
      { ...prevState, can_use_commercial_tools: tar }
    ));
  }

  const handleIndividualViewPermission = (e: React.ChangeEvent<HTMLInputElement>) => {
    var tar =  e?.target?.checked    
    setDistrict((prevState) => (
      { ...prevState, individual_view_permission: tar }
    ));
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const key = event.currentTarget.name as keyof DistrictInfo;
    setDistrict((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleUpdate = () => {
    dispatch<any>(updateDistrict(district)).then(
      (district: DistrictInfo) => {
        dispatch(push(`/superuser/districts/${district.id}`));
      },
      (error: string) => {
        toastr.error("Error", error);
      }
    );
  };

  const handleSubmitChanges = (event: React.FormEvent) => {
    event.preventDefault();
    if (district.owner === selectedDistrict?.owner.id) {
      handleUpdate();
    } else {
      dispatch(
        showConfirmDialog({
          title: "Confirm",
          text:
            "You're going to change the owner of the district. Are you sure?",
          onConfirm: handleUpdate,
          confirmButtonText: "Continue",
        })
      );
    }
  };

  const loading = useSelector<ApplicationState, boolean>(
    (s) => s.superuser.isLoading.getDistrict
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    selectedDistrict && (
      <div className="onboardingContainer">
        <div className="w-100 d-flex justify-content-between">
          <button
            className="blueBtnSm"
            onClick={handleCancel}
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            className="blueBtnSm"
            type="submit"
            form="districtDetailsEditForm"
            disabled={isLoading}
          >
            <FontAwesomeIcon icon={faCheck} style={{ color: "white" }} /> Save
            Changes {isLoading && <Spinner animation="border" size="sm" />}
          </button>
        </div>
        <Form
          id="districtDetailsEditForm"
          className="mt-3"
          onSubmit={handleSubmitChanges}
        >
          <Form.Group className="mt-3">
            <Form.Label className="font-weight-bold" column={false}>
              District Name
            </Form.Label>
            <Form.Control
              required
              placeholder="Enter name..."
              name="name"
              onChange={handleChange}
              value={district.name}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label className="font-weight-bold" column={false}>
              District State
            </Form.Label>
            <Select
              required
              placeholder="Select State..."
              name="state"
              value={
                USStateNamesValues?.find((s) => s.value === district.state) ??
                null
              }
              options={USStateNamesValues}
              getOptionLabel={({ display_name }: USState) => display_name}
              getOptionValue={({ value }: USState) => value}
              onChange={handleChangeState}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label className="font-weight-bold" column={false}>
              District Owner
            </Form.Label>
            <DistrictOwnerSelector
              district={district}
              setDistrict={setDistrict}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="switch"
              id="can_use_commerical_tools_id"
              label="Can Use Commercial Tools"
              checked={district.can_use_commercial_tools}
              onChange={handleCanUserCommercialTools}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="switch"
              id="individual_view_permission"
              label="Individual View Permission"
              checked={district.individual_view_permission}
              onChange={handleIndividualViewPermission}
            />
          </Form.Group>
        </Form>
        <TeacherInviteIndividuallyModal />
      </div>
    )
  );
};

export default withRouter(DistrictDetailsEdit);
