import React, { FunctionComponent, useMemo, useState } from "react";
import { GroupWorkSpace } from "./WorkspacesContainer";
import WorkspaceHeader from "./WorkspaceHeader";
import { Nav, Tab, TabContainer, TabContent, TabPane } from "react-bootstrap";
import { InterventionGroup } from "../../../../../../../../store/onboarding/cases/types";
import AttendanceTab from "./attendance-tab/AttendanceTab";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../../store";
import LoggedProgressTab from "./LoggedProgressTab";
import LoggedInterventionsTab from "./LoggedInterventionsTab";
import MatrixTab from "../../../../../../../common/reading-interventions/intervention-tools/recommend-groups/group-card/focus-section/MatrixTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faList } from "@fortawesome/free-solid-svg-icons";
import {
  hideInterventionPlanModal,
  showInterventionPlanModal,
} from "../../../../../../../../store/onboarding/cases/actions";
import {
  openGoalProgressModal,
  openImplementationCheckModal,
} from "../../../../../../../../store/onboarding/meeting-module/actions";
import InterventionGroupLoggingTab from "./InterventionGroupLoggingTab";
import useUserAssignedToGroup from "../../hooks/useUserAssignedToGroup";
import useInterventionGroupAccess from "../../../../../../../../utils/hooks/useInterventionGroupAccess";
import { EventKey } from "react-bootstrap/esm/types";

type Props = {
  interventionGroup: InterventionGroup;
  expanded?: boolean;
  workspace: GroupWorkSpace;
  studentId?: number;
  setExpandedWorkSpace: React.Dispatch<
    React.SetStateAction<GroupWorkSpace | undefined>
  >;
};

enum TabKeys {
  LoggedGoalProgress = "LoggedGoalProgress",
  LoggedInterventions = "LoggedInterventions",
  Feedback = "Feedback",
  Attendance = "Attendance",
  Focuses = "Focuses",
  ActionsTaken = "ActionsTaken",
}

const LoggedDataWorkspace: FunctionComponent<Props> = (props) => {
  const {
    interventionGroup,
    expanded,
    workspace,
    studentId,
    setExpandedWorkSpace,
  } = props;

  const dispatch = useDispatch();

  const { isUserAssignedToGroup } = useUserAssignedToGroup(interventionGroup);

  const isStudentDetail =  useSelector(
    (s: ApplicationState) => s.cases.isStudentDetail
  );

  const isGroupDetail =  useSelector(
    (s: ApplicationState) => s.cases.isGroupDetail
  );

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(interventionGroup.id);

  const notShareDataWith =  useSelector(
    (s: ApplicationState) => s.cases.notShareDataWith
  );

  const [activeTab, setActiveTab] = useState<string | null>(TabKeys.Attendance);

  const { selectedStudents, showOnlyActiveInterventions } = useSelector(
    (s: ApplicationState) => s.cases.modalsState.interventionPlan
  );

  const [showUpsertForm, setShowUpsertForm] = useState<boolean>(false);

  const handleLogGoalProgress = () => {
    dispatch(hideInterventionPlanModal());
    dispatch(
      openGoalProgressModal({
        onClose: () => {
          dispatch(showInterventionPlanModal());
        },
      })
    );
  };

  const handleLogIntervention = () => {
    setActiveTab(TabKeys.LoggedInterventions);
    dispatch(hideInterventionPlanModal());
    dispatch(
      openImplementationCheckModal({
        onClose: () => dispatch(showInterventionPlanModal()),
      })
    );
  };

  return (
    <div>
      <WorkspaceHeader
        expanded={expanded}
        workspace={workspace}
        setExpandedWorkSpace={setExpandedWorkSpace}
        header={"Logged Data"}
      />
      <div className="workspace-body">
        {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess &&
          <div className="d-flex justify-content-between mb-2">
            <button
              className="blueBtnSm mr-1"
              onClick={() => {
                setActiveTab(TabKeys.Attendance);
                setShowUpsertForm(true);
              }}
            >
              Add New Attendance
            </button>

            <button className="blueBtnSm mx-1" onClick={handleLogGoalProgress}>
              <FontAwesomeIcon
                icon={faChartLine}
                size={"lg"}
                style={{ color: "white", marginRight: "0.5rem" }}
              />
              log goal progress
            </button>

            <button className="blueBtnSm ml-1" onClick={handleLogIntervention}>
              <FontAwesomeIcon
                icon={faList}
                size={"lg"}
                style={{ color: "white", marginRight: "0.5rem" }}
              />{" "}
              log intervention
            </button>
          </div>
        }

        <TabContainer activeKey={activeTab as EventKey} onSelect={setActiveTab}>
          <Nav variant="pills" className="justify-content-center text-center">
            <Nav.Item>
              <Nav.Link
                eventKey={TabKeys.Attendance}
                style={{ height: "unset" }}
              >
                <div>Attendance</div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={TabKeys.LoggedGoalProgress}
                style={{ height: "unset" }}
              >
                <div>Logged Goal Progress</div>
              </Nav.Link>
            </Nav.Item>
            {isStudentDetail ?
              (isUserAssignedToGroup ?
                <Nav.Item>
                  <Nav.Link
                    eventKey={TabKeys.LoggedInterventions}
                    style={{ height: "unset" }}
                  >
                    <div>Logged Interventions</div>
                  </Nav.Link>
              </Nav.Item>
                : '')
            :
            !notShareDataWith ? 
              <Nav.Item>
                <Nav.Link
                  eventKey={TabKeys.LoggedInterventions}
                  style={{ height: "unset" }}
                >
                  <div>Logged Interventions</div>
                </Nav.Link>
              </Nav.Item>
            : ''}
            <Nav.Item>
              <Nav.Link eventKey={TabKeys.Focuses} style={{ height: "unset" }}>
                <div>Focuses</div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={TabKeys.ActionsTaken}
                style={{ height: "unset" }}
              >
                <div>Actions Taken</div>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <TabContent className={"workspace-content-container"}>
            <Tab.Pane eventKey={TabKeys.Attendance}>
              <AttendanceTab
                showUpsertForm={showUpsertForm}
                setShowUpsertForm={setShowUpsertForm}
                interventionGroup={interventionGroup}
                onlyActive={showOnlyActiveInterventions}
                isStudentDetail={isStudentDetail}
                isGroupDetail={isGroupDetail}
                studentId={studentId}
              />
            </Tab.Pane>
            <TabPane eventKey={TabKeys.LoggedGoalProgress}>
              <LoggedProgressTab
                selectedStudents={selectedStudents}
                interventionGroup={interventionGroup}
                isStudentDetail={isStudentDetail}
                isGroupDetail={isGroupDetail}
              />
            </TabPane>
            <TabPane eventKey={TabKeys.LoggedInterventions}>
              <LoggedInterventionsTab
                interventionGroup={interventionGroup}
                onlyActive={showOnlyActiveInterventions}
                isStudentDetail={isStudentDetail}
                isGroupDetail={isGroupDetail}
              />
            </TabPane>
            <TabPane eventKey={TabKeys.Focuses}>
              <MatrixTab
                interventionGroup={interventionGroup}
                students={interventionGroup.students}
                specifics={interventionGroup.focuses ?? []}
                showFullNames
              />
            </TabPane>
            <TabPane eventKey={TabKeys.ActionsTaken}>
              {activeTab == "ActionsTaken" && (
                <InterventionGroupLoggingTab
                  interventionGroup={interventionGroup}
                  studentId={studentId}
                />
              )}
            </TabPane>
          </TabContent>
        </TabContainer>
      </div>
    </div>
  );
};

export default LoggedDataWorkspace;
