import React, { FunctionComponent, useMemo } from "react";
import { GoalSummary } from "../../../../../../store/onboarding/types";
import { colors } from "./index";
import { Doughnut } from "react-chartjs-2";
import _ from "lodash";
import { ConcernArea } from "../../../../../../store/onboarding/cases/types";

type OwnProps = {
  goals: GoalSummary[];
};

type Props = OwnProps;

const GroupNumberDonutChart: FunctionComponent<Props> = (props) => {
  const { goals } = props;

  const labels = useMemo(
    () => ["Number of Behavior Groups", "Number of Academic Groups"],
    []
  );
  const data = useMemo(() => {
    return _.chain(goals)
      .map((g) => g.goal)
      .groupBy((g) => g.concern_area)
      .toPairs()
      .reduce(
        (pV, [key, goals]) => {
          if (+key === ConcernArea.BEHAVIOR) {
            return [goals.length, pV[1]];
          } else if (+key === ConcernArea.ACADEMIC) {
            return [pV[0], goals.length];
          }
          return pV;
        },
        [0, 0]
      )
      .value();
  }, [goals]);

  const chartData = useMemo(() => {
    return {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: data.map(
            (d, index) => colors[index % colors.length]
          ),
          datalabels: {
            display: false,
          },
        },
      ],
    };
  }, [data, labels]);

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
    }),
    []
  );

  return (
    <div className="district-summary-chart-container">
      <Doughnut height={250} data={chartData} options={options} />
    </div>
  );
};

export default GroupNumberDonutChart;
