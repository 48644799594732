import React, { useRef, useState } from "react";
import { GoalsData } from "../NewSetGoalsModal";
import MeasureTableRow from "./MeasureTableRow";
import MeasureForAllPopover from "./MeasureForAllPopover";
import { Form } from "react-bootstrap";

type Props = {
  goalsDataList: GoalsData[];
  setGoalsDataList: (goalsData: GoalsData[]) => void;
  onSubmit: (e: React.FormEvent) => void;
};

const SetGoalsMeasure = ({
  goalsDataList,
  setGoalsDataList,
  onSubmit,
}: Props) => {
  const [showMeasureForAll, setShowMeasureForAll] = useState<boolean>(false);

  const measureAllBtnRef = useRef(null);

  const onChangeGoalsData = (changedData: GoalsData) => {
    setGoalsDataList(
      goalsDataList.map((oldData) =>
        oldData.student.id === changedData.student.id ? changedData : oldData
      )
    );
  };

  return (
    <div>
      <div className="text-center mb-3">
        <h3>
          <strong>Choose an appropriate way to measure your goal.</strong>
          <br />
          You can use ReadyCoach Indicators to measure without data, conduct
          observations, or use data from a behavior rating scale.
        </h3>
      </div>
      <Form id="set-goals-measurement-form" onSubmit={onSubmit}>
        <table className="stdTableWide">
          <thead>
            <tr>
              <th className="w-25 align-middle">Student Goals</th>
              <th className="d-flex align-items-center justify-content-between">
                How will you measure your progress?
                <button
                  ref={measureAllBtnRef}
                  className="blueBtnSm"
                  type="button"
                  onClick={() => setShowMeasureForAll(true)}
                >
                  Set For All
                </button>
                {showMeasureForAll && (
                  <div className="customDarkPopoverBackdrop" />
                )}
                <MeasureForAllPopover
                  objectRef={measureAllBtnRef}
                  showDropdown={showMeasureForAll}
                  setShowDropdown={setShowMeasureForAll}
                  goalsDataList={goalsDataList}
                  setGoalsDataList={setGoalsDataList}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {goalsDataList.length ? (
              goalsDataList.map((data) => (
                <MeasureTableRow
                  key={data.student.id}
                  goalsData={data}
                  onChangeGoalsData={onChangeGoalsData}
                />
              ))
            ) : (
              <tr>
                <td colSpan={2}>No students</td>
              </tr>
            )}
          </tbody>
        </table>
      </Form>
    </div>
  );
};

export default SetGoalsMeasure;
