import { bindActionCreators, Dispatch } from "redux";
import {
  assignClassToTeacher,
  clearStoredCsvFile,
  openTeacherDetailsModal,
  updateTeacherClass,
} from "../../../../store/onboarding/actions";
import { connect } from "react-redux";
import CreateClassModal from "../../../../components/common/onboarding/second-step/teacher-details/CreateClassModal";
import {
  Student,
  TeacherClass,
  TeacherInfo,
} from "../../../../store/onboarding/types";
import { ApplicationState } from "../../../../store";

type PropsFromState = {
  showModal: boolean;
  selectedTeacher: TeacherInfo;
  selectedClass?: TeacherClass;
  isLoading: {
    assignClassToTeacher: boolean;
    updateTeacherClass: boolean;
  };
  errors?: {
    assignClassToTeacher?: string;
    updateTeacherClass?: string;
  };
};

type DispatchProps = {
  onModalHide: () => any;
  assignClassToTeacher: (
    id: number,
    className: string,
    students: Array<Student>
  ) => any;
  updateTeacherClass: (teacherId: number, updatedClass: TeacherClass) => any;
  clearStoredCsvFile: () => any;
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    showModal: onboarding.modalsState.createClassModal,
    selectedTeacher: onboarding.selectedTeacher!,
    selectedClass: onboarding.selectedClass,
    isLoading: {
      assignClassToTeacher: onboarding.isLoading.assignClassToTeacher,
      updateTeacherClass: onboarding.isLoading.updateTeacherClass,
    },
    errors: {
      assignClassToTeacher: onboarding.errors.assignClassToTeacher,
      updateTeacherClass: onboarding.errors.updateTeacherClass,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      assignClassToTeacher: assignClassToTeacher,
      updateTeacherClass: updateTeacherClass,
      onModalHide: openTeacherDetailsModal,
      clearStoredCsvFile: clearStoredCsvFile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateClassModal);
