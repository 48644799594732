import React, { FunctionComponent } from "react";
import { Spinner } from "react-bootstrap";

type OwnProps = {
  title: string;
  onHide: () => void;
  onConfirm: () => void;
  isLoading: boolean;
};

type Props = OwnProps;

const ConfirmPopup: FunctionComponent<Props> = ({
  title,
  onHide,
  onConfirm,
  isLoading,
}) => {
  return (
    <div className="confirmDialog">
      <div>
        <h3 className="text-center font-weight-bold mb-3">{title}</h3>
        <div className="d-flex justify-content-between">
          <button onClick={onHide} className="whiteBtnSm">
            Cancel
          </button>
          <button onClick={onConfirm} className="blueBtnSm">
            Confirm {isLoading && <Spinner animation="border" size="sm" />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
