import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
  District,
  UserAssignmentRole,
  UserInfo,
} from "../../../../../../store/auth/types";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  DistrictSummary,
  School,
} from "../../../../../../store/onboarding/types";
import InterventionFidelityTable from "./InterventionFidelityTable";
import GoalAttainmentTable from "./GoalAttainmentTable";
import DecisionsTable from "./DecisionsTable";
import GoalProgressTable from "./GoalProgressTable";
import GoalsTable from "./GoalsTable";
import AssigmentTable from "../AssigmentTable";
import InterventionsTable from "./InterventionsTable";
import {
  getDistricts,
  getDistrictSummary,
} from "../../../../../../store/onboarding/actions";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";
import { Alert } from "react-bootstrap";
import Select, { ValueType } from "react-select";
import AverageInterventionFidelityChart from "../charts/AverageInterventionFidelityChart";
import AverageGoalAttainmentChart from "../charts/AverageGoalAttainmentChart";
import FiltersPanel from "./FiltersPanel";
import _ from "lodash";
import DecisionsMadeInDistrict from "../charts/DecisionsMadeInDistrict";
import GoalsProgressMonitoredInDistrictChart from "../charts/GoalsProgressMonitoredInDistrictChart";
import AssignmentsInDistrictChart from "../charts/AssignmentsInDistrictChart";
import SummarySection from "../SummarySection";
import GroupNumberDonutChart from "../charts/GroupNumberDonutChart";
import GoalsByWeekBarChart from "../charts/GoalsByWeekBarChart";
import InterventionUsageChart from "../charts/InterventionUsageChart";
import DistrictSectionsToggleButtons from "../district-sections-toggle-buttons/DistrictSectionsToggleButtons";
import { Grade } from "../../../../../../store/groups/types";
import FidelityByCoachingRelationshipTable from "./FidelityByCoachingRelationshipTable";
import FidelityByCoachingRelationshipChart from "../charts/FidelityByCoachingRelationshipChart";
import FidelityInterventionTable from "./FidelityInterventionTable";
import FidelityInterventionChart from "../charts/FidelityInterventionChart";
import InterventionGroupProgressChart from "../charts/InterventionGroupProgressChart";
import InterventionGroupProgressTable from "./InterventionGroupProgressTable";
import GoalsPerCoachChart from "../charts/GoalsPerCoachChart";
import PlansPerCoachChart from "../charts/PlansPerCoachChart";
import InterventionsImplementedChart from "../charts/InterventionsImplementedChart";
import AlertsPerCoachTable from "./AlertsPerCoachTable";
import AlertsPerCoachChart from "../charts/AlertsPerCoachChart";
import { ConcernArea } from "../../../../../../store/onboarding/cases/types";
import InterventionsStats from "../../interventions-stats/InterventionsStats";
import { USState, USStateNamesValues } from "../../../../../common/States";

import InterventionLoggedFidelityChart from "../charts/InterventionLoggedFidelityChart";
import GoalsWithLoggedProgressChart from "../charts/GoalsWithLoggedProgressChart";
import GoalsPerSchoolChart from "../charts/GoalsPerSchoolChart";
import PlansPerSchoolChart from "../charts/PlansPerSchoolChart";
import AverageGoalAttainmentBySchoolChart from "../charts/AverageGoalAttainmentBySchoolChart";
import InterventionLoggedFidelityBySchoolChart from "../charts/InterventionLoggedFidelityBySchoolChart";
import GoalsWithLoggedProgressBySchoolChart from "../charts/GoalsWithLoggedProgressBySchoolChart";
import { openOverviewModel } from "../../../../../../store/groups/actions";

type StateProps = {
  userInfo?: UserInfo;
  districtSummary?: DistrictSummary;
  districts: Array<District>;
  schools: Array<School>;
  isLoading: {
    getDistrictSummary?: boolean;
    getDistricts?: boolean;
    getDistrictSchool?: boolean;
  };
};

type DispatchProps = {
  getDistricts: () => any;
};

type Props = StateProps & DispatchProps;

const DistrictSummaryTab: FunctionComponent<Props> = ({
  getDistricts,
  isLoading,
  districts,
  schools,
  districtSummary,
  userInfo,
}) => {
  const [selectedEducators, setSelectedEducators] = useState<UserInfo[]>([]);

  const dispatch = useDispatch();

  const [selectedDistrict, setSelectedDistrict] = useState<
    District | null
  >(null);
  const [selectedSchool, setSelectedSchool] = useState<
    School | null
  >(null);
  const [raceFilter, setRaceFilter] = useState<number | undefined>();
  const [gradesFilter, setGradesFilter] = useState<Grade[]>([]);
  const [concernAreaFilter, setConcernAreaFilter] = useState<ConcernArea>(
    ConcernArea.BOTH
  );
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [state, setState] = useState<USState | null>(null);
  
  const isStaffOrSuperuser = useMemo(() => {
    return (
      userInfo?.is_staff ||
      userInfo?.profile.current_role === UserAssignmentRole.SUPERUSER
    );
  }, [userInfo?.is_staff, userInfo?.profile.current_role]);

  useEffect(() => {
    if (isStaffOrSuperuser) {
      getDistricts();
    }
  }, [isStaffOrSuperuser, state, selectedDistrict, selectedSchool]);

  useEffect(() => {
    dispatch(openOverviewModel('all_group'));
  },[])

  useEffect(() => {
    const groupedByRole = _.chain(selectedEducators)
      .groupBy(
        (e) => e.profile.current_assignment?.role ?? e.profile.current_role
      )
      .value();

    const teachersIds =
      groupedByRole[UserAssignmentRole.TEACHER]?.map((u) => u.id) ?? [];
    const coachesIds =
      groupedByRole[UserAssignmentRole.COACH]?.map((u) => u.id) ?? [];

    if (!isStaffOrSuperuser || selectedDistrict?.id) {
      dispatch(
        getDistrictSummary({
          districtId: selectedDistrict?.id,
          schoolId: selectedSchool?.id,
          teachersIds: teachersIds,
          coachesIds: coachesIds,
          race: raceFilter,
          grades: gradesFilter,
          concernArea: concernAreaFilter,
          startDate: startDate ? startDate.toISOString() : undefined,
          endDate: endDate ? endDate.toISOString() : undefined,
        })
      );
    }
  }, [
    isStaffOrSuperuser,
    selectedDistrict,
    selectedSchool,
    selectedEducators,
    raceFilter,
    gradesFilter,
    concernAreaFilter,
    startDate,
    endDate,
  ]);

  const attainmentPercentage = useMemo(() => {
    if (districtSummary?.goal_attainment) {
      return (
        (districtSummary.goal_attainment.reduce(
          (a, b) => a + b.avg_attainment,
          0
        ) /
          districtSummary.goal_attainment.length) *
        100
      )
        .toFixed(2)
        .replace(".00", "");
    }
    return "0";
  }, [districtSummary?.goal_attainment]);

  const fidelityAverage = useMemo(() => {
    if (districtSummary?.intervention_fidelity) {
      return (
        (districtSummary.intervention_fidelity.reduce(
          (a, b) => a + b.avg_fidelity,
          0
        ) /
          districtSummary.intervention_fidelity.length) *
        100
      )
        .toFixed(2)
        .replace(".00", "");
    }
    return "0";
  }, [districtSummary?.intervention_fidelity]);

  const handleSelectedDistrictChange = (value: ValueType<District, false>) => {
    setSelectedDistrict(value as District);
    setSelectedSchool(null)
  };

  const handleSelectedSchoolChange = (value: ValueType<School, false>) => {
    setSelectedSchool(value as School);
  };

  const handleChangeState = (value: ValueType<USState, false>) => {
    setState(value as USState);
    setSelectedDistrict(null)
  };

  let USStateNamesValue:any = USStateNamesValues;
  let stateObj:any = {'value': null, 'display_name': 'All States'};
  let USStateNamesOptions = [stateObj, ...USStateNamesValue];
 
  return (
    <div className="manageDistrictContainer">
      <div className="mb-3 row col-12">
          <h2 className="font-weight-bold text-nowrap">Filter Selection</h2>
      </div>  
        <div>
          {isStaffOrSuperuser ? (
            <div className="row mb-2">
              <div className="col-4">
                <Select
                  required
                  isClearable
                  placeholder="All States"
                  name="state"
                  value={USStateNamesOptions.find((data) => data.value == state)}
                  options={[stateObj, ...USStateNamesValue]}
                  getOptionLabel={({ display_name }: USState) => display_name}
                  getOptionValue={({ value }: USState) => value}
                  onChange={handleChangeState}
                />
              </div>
              <div className="col-4">
                <Select
                  isClearable
                  isLoading={isLoading.getDistricts}
                  options={districts.filter((data:District) => (state != null) &&  (data.state  == state!.value))}
                  getOptionLabel={(d) => d.name}
                  getOptionValue={(d) => d.id as any}
                  value={selectedDistrict}
                  placeholder="All Districts"
                  onChange={handleSelectedDistrictChange}
                  isDisabled={state == null}
                />
              </div>
              <div className="col-4">
                <Select
                  isClearable
                  options={selectedDistrict?.schools}
                  getOptionLabel={(d) => d.name}
                  getOptionValue={(d) => d.id as any}
                  value={selectedSchool}
                  placeholder="All Schools"
                  onChange={handleSelectedSchoolChange}
                  isDisabled={selectedDistrict == undefined}
                />
              </div>
            </div>
          ) : (
            <h3 className="blueTitle">{userInfo?.profile.district?.name}</h3>
          )}
          <FiltersPanel
            districtId={selectedDistrict?.id}
            raceFilter={raceFilter}
            setRaceFilter={setRaceFilter}
            gradesFilter={gradesFilter}
            setGradesFilter={setGradesFilter}
            selectedEducators={selectedEducators}
            setSelectedEducators={setSelectedEducators}
            concernAreaFilter={concernAreaFilter}
            setConcernAreaFilter={setConcernAreaFilter}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>
      

      {isLoading.getDistrictSummary ? (
        <LoadingIndicator />
      ) : districtSummary ? (
        <div>
          <div className="academic-stats">
            <div className={"academic-stats-btn"}>
              <h1>{districtSummary.academic_stats?.coaches ?? 0}</h1>
              {districtSummary.academic_stats?.coaches === 1
                ? "Active Coach"
                : "Active Coaches"}
            </div>
            <div className={"academic-stats-btn"}>
              <h1>{districtSummary.academic_stats?.teachers ?? 0}</h1>
              {districtSummary.academic_stats?.teachers === 1
                ? "Active Teacher"
                : "Active Teachers"}
            </div>
            <div className={"academic-stats-btn"}>
              <h1>{districtSummary.academic_stats?.students ?? 0}</h1>
              {districtSummary.academic_stats?.students === 1
                ? "Student"
                : "Students"}
            </div>
            {//(userInfo?.profile.current_role == "superuser") && 
            <>
              <div className={"academic-stats-btn"}>
                <h1>{districtSummary.goals.length ?? 0}</h1>
                {districtSummary.goals.length === 1
                  ? "Goal"
                  : "Goals"}
              </div>
              <div className={"academic-stats-btn"}>
                <h1>{districtSummary.academic_stats?.unique_interventions_count ?? 0}</h1>
                {districtSummary.academic_stats?.unique_interventions_count === 1
                  ? "Intervention"
                  : "Interventions"}
              </div>
            </>
            }
          </div>
          <div className="mb-3">
            <div className="district-summary-quadrant">
              <>
                <GoalsPerSchoolChart
                  label={"Active Goals per School"}
                  goals={districtSummary.goals}
                />
                <PlansPerSchoolChart
                  label={"Active intervention groups per school"}
                  groupInfo={districtSummary.group_info}
                />
                <AverageGoalAttainmentBySchoolChart
                  label={"% of Goals Attained per School"}
                  goalAttainment={districtSummary.goal_attainment}
                />
                <InterventionLoggedFidelityBySchoolChart
                  label={"% of Interventions with Logged Fidelity per School"}
                  interventionWithLoggedFedility={districtSummary}
                />
                <GoalsWithLoggedProgressBySchoolChart
                  label={"% of Goals with Logged Progress per School"}
                  goalsWithLoggedProgress={districtSummary}
                />
              </>
              </div>
          </div>
              {((userInfo?.profile.current_role == "superuser") || (userInfo?.profile.current_role == "district_owner" && userInfo.profile.district?.individual_view_permission)) &&
              <>
                <hr className="blueHr" />
                <div className="mb-3">
                  <div className="district-summary-quadrant">
                      <GoalsPerCoachChart
                        label={"Active Goals per Coach"}
                        goals={districtSummary.goals}
                      />
                      <PlansPerCoachChart
                        label={"Active intervention groups per coach"}
                        groupInfo={districtSummary.group_info}
                      />
                      <AverageGoalAttainmentChart
                        label={"% of Goals Attained per Coach"}
                        goalAttainment={districtSummary.goal_attainment}
                      />
                      <InterventionLoggedFidelityChart
                        label={"% of Interventions with Logged Fidelity per Coach"}
                        interventionWithLoggedFedility={districtSummary}
                      />
                      <GoalsWithLoggedProgressChart
                        label={"% of Goals with Logged Progress per Coach"}
                        goalsWithLoggedProgress={districtSummary}
                      />

                  </div>
                </div>
              </> 
              
              }
          {((userInfo?.profile.current_role == "superuser") || (userInfo?.profile.current_role == "district_owner" && userInfo.profile.district?.individual_view_permission))  &&
            <>
              <hr className="blueHr" />
              <DistrictSectionsToggleButtons />
              <SummarySection
                areaKey="assignments-in-district"
                title="Assignments in District"
              >
                <AssigmentTable assignments={districtSummary.assignments} />
                <AssignmentsInDistrictChart
                  assignments={districtSummary.assignments}
                />
              </SummarySection>
              <SummarySection areaKey="goals-in-district" title="Goals in District">
                <GoalsTable goals={districtSummary.goals} />
                <div className="d-flex flex-column">
                  <GroupNumberDonutChart goals={districtSummary.goals} />
                  <GoalsByWeekBarChart goals={districtSummary.goals} />
                </div>
              </SummarySection>
              <SummarySection
                areaKey="interventions-in-district"
                title="Interventions used in District"
              >
                <InterventionsTable interventions={districtSummary.interventions} />
                <InterventionUsageChart
                  interventions={districtSummary.interventions}
                />
              </SummarySection>
              <SummarySection
                areaKey="interventions-stats"
                title="Interventions Stats"
              >
                <InterventionsStats
                  interventions={districtSummary.interventions}
                  allLoggedInterventions={districtSummary.all_logged_interventions}
                />
              </SummarySection>
              <SummarySection
                areaKey="goal-progress-in-district"
                title="Goal Progress Monitored in District"
              >
                <GoalProgressTable goalProgress={districtSummary.goal_progress} />
                <GoalsProgressMonitoredInDistrictChart
                  goalProgress={districtSummary.goal_progress}
                />
              </SummarySection>
              <SummarySection
                areaKey="decisions-in-district"
                title="Decisions Made in District"
              >
                <DecisionsTable decisions={districtSummary.decisions} />
                <DecisionsMadeInDistrict decisions={districtSummary.decisions} />
              </SummarySection>
              <SummarySection
                areaKey="goal-attainment-in-district"
                title={
                  <>
                    Average Goal Attainment
                    <br />
                    <small>Attainment percentage: {attainmentPercentage}%</small>
                  </>
                }
              >
                <GoalAttainmentTable
                  goalAttainment={districtSummary.goal_attainment}
                />
                <AverageGoalAttainmentChart
                  goalAttainment={districtSummary.goal_attainment}
                />
              </SummarySection>
              <SummarySection
                areaKey="intervention-fidelity-in-district"
                title={
                  <>
                    Average Intervention Fidelity
                    <br />
                    <small>Fidelity average: {fidelityAverage}%</small>
                  </>
                }
              >
                <InterventionFidelityTable
                  interventionFidelity={districtSummary.intervention_fidelity}
                />
                <AverageInterventionFidelityChart
                  interventionFidelity={districtSummary.intervention_fidelity}
                />
              </SummarySection>
              <SummarySection
                areaKey="fidelity-by-teacher-in-district"
                title={"Fidelity by Coaching Relationship"}
              >
                <FidelityByCoachingRelationshipTable
                  fidelityByTeacher={districtSummary.fidelity_by_teacher}
                />
                <FidelityByCoachingRelationshipChart
                  fidelityByTeacher={districtSummary.fidelity_by_teacher}
                />
              </SummarySection>
              <SummarySection
                areaKey="fidelity-by-intervention-in-district"
                title={"Fidelity by Intervention"}
              >
                <FidelityInterventionTable
                  fidelityByIntervention={districtSummary.fidelity_by_intervention}
                />
                <FidelityInterventionChart
                  fidelityByIntervention={districtSummary.fidelity_by_intervention}
                />
              </SummarySection>
              <SummarySection
                areaKey="group-progress-in-district"
                title={"Intervention Group Progress Bars"}
              >
                <InterventionGroupProgressTable
                  groupProgress={districtSummary.group_progress}
                />
                <InterventionGroupProgressChart
                  groupProgress={districtSummary.group_progress}
                />
              </SummarySection>
              <SummarySection
                areaKey="alerts-per-coach-in-district"
                title={"Alerts per Coach"}
              >
                <AlertsPerCoachTable groupInfo={districtSummary.group_info} />
                <AlertsPerCoachChart groupInfo={districtSummary.group_info} />
              </SummarySection>
            </>
          }
        </div>
      ) : isStaffOrSuperuser && !selectedDistrict ? (
        <Alert variant="warning">No district selected</Alert>
      ) : (
        <Alert variant="warning">
          No data available. There is no data to show you right now.
        </Alert>
      )}
    </div>
  );
};

const mapStateToProps = ({
  auth,
  onboarding,
}: ApplicationState): StateProps => {
  return {
    userInfo: auth.userInfo,
    districtSummary: onboarding.district_summary,
    districts: onboarding.districts,
    schools: onboarding.schools,
    isLoading: {
      getDistrictSummary: onboarding.isLoading.getDistrictSummary,
      getDistricts: onboarding.isLoading.getDistricts
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getDistrictSummaryRequest: getDistrictSummary,
      getDistricts: getDistricts
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DistrictSummaryTab);
