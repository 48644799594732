import React, { FunctionComponent, useEffect, useMemo } from "react";
import useBenchmarkFilters from "../../../second-step/evidence-tab/color-criteria-modal/hooks/useBenchmarkFilters";
import ColorCriteriaTable from "../../../second-step/evidence-tab/color-criteria-modal/ColorCriteriaTable";
import {
  DataPeriod,
  Measurement,
} from "../../../../../../store/onboarding/types";
import useColorCriteria from "../../../second-step/evidence-tab/color-criteria-modal/hooks/useColorCriteria";
import { useDispatch, useSelector } from "react-redux";
import { getCustomColorCriteria } from "../../../../../../store/onboarding/actions";
import { Grade, TimePeriod } from "../../../../../../store/groups/types";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../../store";
import _ from "lodash";
import { getStudentGradeFromEcg } from "../../../second-step/evidence-tab/student-data-table/Helpers";
import { gradeLevels } from "../../../second-step/evidence-tab/EvidenceTable";

type OwnProps = {
  minimal?: boolean;
  measurement: Measurement;
  interventionGroup: InterventionGroup;
};

type Props = OwnProps;

const BenchmarkArea: FunctionComponent<Props> = (props) => {
  const { measurement, interventionGroup, minimal } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomColorCriteria(measurement.id!));
  }, [measurement.id]);

  const {
    filters,
    selectedGrade,
    setSelectedGrade,
    selectedTimePeriod,
    setSelectedTimePeriod,
  } = useBenchmarkFilters({
    show: true,
  });

  const evidencePeriodData = useSelector(
    (s: ApplicationState) => s.onboarding.evidencePeriodData
  );
  const lowestGradeInGroup = useMemo(() => {
    return _.chain(evidencePeriodData?.student_rows ?? [])
      .filter((sr) =>
        interventionGroup.students.some((s) => s.id === sr.student.id)
      )
      .map(
        (sr) =>
          sr.grade ??
          getStudentGradeFromEcg(
            evidencePeriodData?.evidence_column_groups ?? [],
            sr.student.id!
          )
      )
      .uniq()
      .filter((g) => !!g)
      .sort(
        (a, b) =>
          gradeLevels.indexOf(a as string) - gradeLevels.indexOf(b as string)
      )
      .head()
      .value();
  }, [interventionGroup.students, evidencePeriodData]);

  useEffect(() => {
    setSelectedGrade((lowestGradeInGroup as Grade) ?? "K");
  }, [lowestGradeInGroup]);

  const currentDataPeriod = useSelector<
    ApplicationState,
    DataPeriod | undefined
  >((s) => s.onboarding.currentDataPeriod);

  useEffect(() => {
    setSelectedTimePeriod(
      currentDataPeriod?.time_period ?? TimePeriod.Beginning
    );
  }, [currentDataPeriod?.time_period]);

  const {
    mainColorCriteria,
    allColorCriteria,
    customColorCriteria,
  } = useColorCriteria({
    selectedGrade,
    selectedTimePeriod,
    measurement,
  });

  return (
    <div className={`mb-4 ${minimal ? "benchmark-area-minimal" : ""}`}>
      {filters}

      <ColorCriteriaTable
        allColorCriteria={allColorCriteria}
        withoutStats
        selectedGrade={selectedGrade}
        measurementType={measurement.type}
      />
    </div>
  );
};

export default BenchmarkArea;
