import { ApplicationState } from "../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  addStudents,
  assignStudentsToTeacher,
  hideUploadStudentsRosterModal,
} from "../../../../store/onboarding/actions";
import { connect } from "react-redux";
import UploadStudentsRosterModal from "../../../../components/common/onboarding/second-step/upload-students/UploadStudentsRosterModal";
import { Student } from "../../../../store/onboarding/types";

type PropsFromState = {
  originalFileName: string;
  columnsNames: Array<string>;
  rawData: Array<any>;
  show: boolean;
  isLoading: boolean;
  error?: string;
};

type DispatchProps = {
  onHide: () => any;
  addStudents: (students: Array<Student>) => any;
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  const loadableFile = onboarding.loadableFile;
  return {
    originalFileName: loadableFile ? loadableFile.originalFileName! : "",
    rawData: loadableFile ? loadableFile.rawData : [],
    columnsNames: loadableFile ? loadableFile.columnsNames : [],
    show: onboarding.modalsState.uploadStudentsRosterModal,
    isLoading: onboarding.isLoading.addStudents,
    error: onboarding.errors.addStudents,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      assignStudentsToTeacher: assignStudentsToTeacher,
      onHide: hideUploadStudentsRosterModal,
      addStudents: addStudents,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadStudentsRosterModal);
