import React, { FunctionComponent, useEffect, useMemo } from "react";
import { ApplicationState } from "../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { InterventionGroup } from "../../../store/onboarding/cases/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Nav, OverlayTrigger, Popover, Tab } from "react-bootstrap";
import EvidenceTable from "../../common/onboarding/second-step/evidence-tab/EvidenceTable";
import StudentGoalTable from "./StudentGoalTable";
import GroupCountIcon from "../../common/onboarding/third-step/group-students/common/GroupCountIcon";
import {
  changeSelectedInterventionGroup,
  openFindInterventionModal,
} from "../../../store/onboarding/cases/actions";
import LoadingIndicator from "../../common/onboarding/LoadingIndicator";
import InterventionNotesTab from "./InterventionNotesTab";
import { push } from "connected-react-router";
import { InterventionGroupTabs } from "../../../store/onboarding/meeting-module/types";
import InterventionGroupCoachAndTeacher from "../coach-dashboard/tabs/your-assigments/common/InterventionGroupCoachAndTeacher";
import InterventionFidelityTabContainer from "../coach-dashboard/tabs/your-assigments/implementation-check/InterventionFidelityTabContainer";

type DispatchProps = {
  openFindInterventionModal: () => any;
  changeSelectedInterventionGroup: (
    interventionGroup?: InterventionGroup
  ) => any;
  push: (location: string) => any;
};

type StateProps = {
  interventionGroup?: InterventionGroup;
  interventionGroups: Array<InterventionGroup>;
  tabName: string;
};

type OwnProps = {
  groupId: number;
  subPage?: InterventionGroupTabs;
};

type Props = OwnProps & DispatchProps & StateProps;

const InterventionGroupPage: FunctionComponent<Props> = (props,{
  groupId,
  subPage,
  interventionGroup,
  interventionGroups,
  openFindInterventionModal,
  changeSelectedInterventionGroup,
  push,
}) => {
  useEffect(() => {
    if (!interventionGroup && groupId) {
      changeSelectedInterventionGroup(
        interventionGroups.find((g:any) => g.id === groupId)
      );
    }
  }, [interventionGroup, groupId, interventionGroups]);

  const handleFindInterventionModalOpen = () => {
    openFindInterventionModal();
  };

  const handleActiveTabSelect = (activeTab: string | null) => {
    push(`/cases/groups/${interventionGroup!.id}/${activeTab}`);
  };

  const interventionGroupSummaryInfo = useMemo(() => {
    if (!interventionGroup) return null;
    if (
      !interventionGroup.student_goals ||
      !interventionGroup.student_goals.length
    ) {
      return (
        <div
          className="d-flex align-items-center w-100"
          style={{ marginLeft: -20 }}
        >
          <GroupCountIcon
            svgSize={80}
            studentsNum={interventionGroup!.students.length}
          />
          <div className="align-items-center">
            <h3 className="font-weight-bold d-inline">
              This group doesn't have any goals yet
            </h3>
            <h4 className="mb-0">Let's set goals for the students.</h4>
          </div>
        </div>
      );
    }

    if (
      !interventionGroup.interventions ||
      !interventionGroup.interventions.length
    ) {
      return (
        <div className="d-flex align-items-center w-100">
          <GroupCountIcon
            svgSize={80}
            studentsNum={interventionGroup!.students.length}
          />
          <div className="align-items-center">
            <h3 className="font-weight-bold d-inline">
              This group doesn't have an Intervention yet
            </h3>
            <h4 className="mb-0">
              Let's set goals for the students. ReadyCoach will help you
              identify and select interventions.
            </h4>
          </div>
          <button
            className="blueBtnSm"
            onClick={handleFindInterventionModalOpen}
            style={{ margin: "0 10px 0 auto" }}
          >
            Find an intervention
          </button>
        </div>
      );
    }

    return (
      <div className="d-flex align-items-center w-100">
        <GroupCountIcon
          svgSize={80}
          studentsNum={interventionGroup!.students.length}
        />
        <div className="align-items-center">
          <div>
            {interventionGroup.interventions!.map((intervention:any) => (
              <h3
                className="font-weight-bold d-inline-block mr-3"
                key={intervention.id}
              >
                {intervention.name}{" "}
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Popover id={"interventionDescription"}>
                      <Popover.Content>
                        {intervention.description}
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="pointer" />
                </OverlayTrigger>
              </h3>
            ))}
          </div>
          <h4 className="mb-0">
            You can go to the Dashboard to prepare for implementation.
          </h4>
          {/* <small>
            <i>This intervention modified from a ReadyCoach intervention.</i>
          </small> */}
        </div>
      </div>
    );
  }, [interventionGroup]);

  if (!interventionGroup) return <LoadingIndicator />; //todo

  const handleBack = () => {
    push("/cases/"+props.tabName);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 20,
          borderRadius: 8,
          overflow: "hidden",
        }}
      >
        {/*<div*/}
        {/*  style={{*/}
        {/*    backgroundColor: "white",*/}
        {/*    display: "flex",*/}
        {/*    alignContent: "center",*/}
        {/*    alignItems: "center",*/}
        {/*    justifyContent: "center"*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <button className="blueBtnMd ml-3" onClick={handleBack}>*/}
        {/*    Back to*/}
        {/*    <br /> Dashboard*/}
        {/*  </button>*/}

        {/*  /!*<button*!/*/}
        {/*  /!*  onClick={handleBack}*!/*/}
        {/*  /!*  className="chevronBack"*!/*/}
        {/*  /!*  style={{*!/*/}
        {/*  /!*    backgroundColor: "#00265b",*!/*/}
        {/*  /!*    borderRadius: "50%",*!/*/}
        {/*  /!*    margin: 20,*!/*/}
        {/*  /!*    display: "flex",*!/*/}
        {/*  /!*    alignItems: "center",*!/*/}
        {/*  /!*    justifyContent: "center",*!/*/}
        {/*  /!*    width: 70,*!/*/}
        {/*  /!*    height: 70*!/*/}
        {/*  /!*  }}*!/*/}
        {/*  /!*  title="Return to Dashboard"*!/*/}
        {/*  /!*>*!/*/}
        {/*  /!*  <Image*!/*/}
        {/*  /!*    src={require("./back-chevron.png")}*!/*/}
        {/*  /!*    style={{ width: 35, height: "auto", marginLeft: -4 }}*!/*/}
        {/*  /!*    className="img-fluid"*!/*/}
        {/*  /!*  />*!/*/}
        {/*  /!*</button>*!/*/}
        {/*</div>*/}
        <div style={{ flex: 1 }}>
          <div className="onboardingContainer">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <InterventionGroupCoachAndTeacher
                interventionGroup={interventionGroup}
              />

              <button className="blueBtnLg mr-3" onClick={handleBack}>
                Back to Dashboard
              </button>
            </div>

            <div className="padding10">{interventionGroupSummaryInfo}</div>
          </div>
        </div>
      </div>
      <div className="onboardingContainer">
        <Tab.Container
          activeKey={subPage || InterventionGroupTabs.STUDENTS_GOALS}
          onSelect={handleActiveTabSelect}
        >
          <Nav style={{ marginBottom: "10px" }}>
            <div className="btnActions" style={{ margin: 8 }}>
              <div>
                <Nav.Item className="onboardingSecondStepTab">
                  <Nav.Link eventKey={InterventionGroupTabs.STUDENTS_GOALS}>
                    Student Goals
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="onboardingSecondStepTab">
                  <Nav.Link eventKey={InterventionGroupTabs.MEETINGS}>
                    Meetings
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="onboardingSecondStepTab">
                  <Nav.Link eventKey={InterventionGroupTabs.EVIDENCE}>
                    Evidence Used to Group Students
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="onboardingSecondStepTab">
                  <Nav.Link
                    eventKey={InterventionGroupTabs.PROGRESS_MONITORING}
                  >
                    Progress Monitoring
                  </Nav.Link>
                </Nav.Item>
              </div>
            </div>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey={InterventionGroupTabs.STUDENTS_GOALS}>
              <StudentGoalTable showAddStudentAction />
            </Tab.Pane>
            <Tab.Pane eventKey={InterventionGroupTabs.MEETINGS}>
              <InterventionNotesTab />
            </Tab.Pane>
            <Tab.Pane eventKey={InterventionGroupTabs.EVIDENCE}>
              <div>
                <EvidenceTable
                  interventionGroup={interventionGroup}
                  isReadonly
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey={InterventionGroupTabs.PROGRESS_MONITORING}>
              <InterventionFidelityTabContainer
                interventionGroup={interventionGroup}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

const mapStateToProps = ({ cases }: ApplicationState): StateProps => {
  return {
    interventionGroup: cases.selectedInterventionGroup,
    interventionGroups: cases.interventionGroups,
    tabName: cases.tabName
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      openFindInterventionModal: openFindInterventionModal,
      changeSelectedInterventionGroup: changeSelectedInterventionGroup,
      push: push,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterventionGroupPage);
