import React from "react";
import { Cell } from "fixed-data-table-2";
import { Specific } from "../../../../../../../../../store/groups/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { Student } from "../../../../../../../../../store/onboarding/types";

type Props = {
  focus: Specific;
  student: Student;
};

const MatrixStudentCell = ({ focus, student }: Props) => (
  <Cell className="second-column-cell">
    {student.focuses?.some(
      (studentFocus) => studentFocus.identifier === focus.identifier
    ) && <FontAwesomeIcon icon={faCheckCircle} color="green" />}
  </Cell>
);

export default MatrixStudentCell;
