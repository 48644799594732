import React, { FunctionComponent } from "react";
import FilterPanel from "./FilterPanel";
import StaffTable from "./StaffTable";
import TeacherDetailsModal from "../../../../../common/onboarding/second-step/teacher-details/TeacherDetailsModal";
import AssignEducatorsToCoachModal from "../../../../../common/onboarding/second-step/teacher-details/AssignEducatorsToEmployeeModal";

type OwnProps = {};

type Props = OwnProps;

const ManageUsersPage: FunctionComponent<Props> = () => {
  return (
    <div className="manageDistrictContainer">
      <h2 className="font-weight-bold mb-3">Manage Users</h2>
      <FilterPanel />
      <StaffTable />
      <TeacherDetailsModal fromManageUsers/>
      <AssignEducatorsToCoachModal />
    </div>
  );
};

export default ManageUsersPage;
