import React, {
  CSSProperties,
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useSelector } from "react-redux";
import {
  addDataPeriod,
  deleteDataPeriod,
  deleteDataPeriodRequest,
  getDataPeriods,
  hideManageDataPeriodsModal,
  updateDataPeriod,
} from "../../../../../../store/onboarding/actions";
import { DataPeriod, ReactSelectOption } from "../../../../../../store/onboarding/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlusCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";
import Select from "react-select";
import {
  getTimePeriodLabel,
  getTimePeriodWithoutLabel,
  TimePeriodOptions,
} from "../../../../../../store/groups/types";
import { toastr } from "react-redux-toastr";
import CoachDataPeriodSelector from "../../../CoachDataPeriodSelector";
import useUserRole from "../../../../../../utils/hooks/useUserRole";
import App from "../../../../../../App";
import { useDispatch } from "react-redux";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";

type StateProps = {
  dataPeriods: Array<DataPeriod>;
  isLoading: {
    getDataPeriods: boolean;
    addDataPeriod: boolean;
    updateDataPeriod: boolean;
    deleteDataPeriod: boolean;
  };
  showModal: boolean;
};

type DispatchProps = {
  hideManageDataPeriodsModal: () => any;
  addDataPeriod: (dataPeriod: Partial<DataPeriod>) => any;
  updateDataPeriod: (
    dataPeriodId: number,
    dataPeriod: Partial<DataPeriod>
  ) => any;
  deleteDataPeriod: (dataPeriodId: number) => any;
};

type Props = StateProps & DispatchProps;

const ManageDataPeriodsModal: FunctionComponent<Props> = ({
  dataPeriods,
  showModal,
  isLoading,
  addDataPeriod,
  updateDataPeriod,
  deleteDataPeriod,
  hideManageDataPeriodsModal
}) => {
  const { isDistrictOwner } = useUserRole();
  const defaultDataPeriod: Partial<DataPeriod> = useMemo(
    () => ({
      id: 0,
      year: "",
      time_period: undefined,
      name: "",
    }),
    []
  );

  const permissionSetting = useSelector(
    (s:ApplicationState) => s.auth.userInfo
  );

  const permissionMessage = useMemo(()=>{
    let permissionMsg;
    if(!permissionSetting?.settings_present) {
      permissionMsg = 'You do not have any permissions set. Please contact your district administrator.';
    } else if(permissionSetting?.block_access_to_all_data) {
      permissionMsg = 'You do not have access to data. Please contact your district administrator.';
    }
    return permissionMsg;
  },[permissionSetting])

  const [updatedDataPeriod, setUpdatedDataPeriod] = useState<any | undefined>(
    undefined
  );
  const [displayLoaderId, setDisplayLoader] = useState<any>(0);

  const date = new Date();
    const currentYear = date.getFullYear();

    const possibleYears: ReactSelectOption<string>[] = [];
    for (let i = -5; i < 5; ++i) {
      possibleYears.push({
        value: (currentYear + i).toString(),
        label: (currentYear + i).toString(),
      });
    }

  const hideModalShow = () => {
    setUpdatedDataPeriod(undefined);
  };

  const selectedDataPeriod = useSelector(
    (s: ApplicationState) => s.onboarding.currentDataPeriod
  );

  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        dataField: "name",
        text: "Name",
        sort: true,
      },
      {
        dataField: "time_period",
        text: "Time Period",
        sort: true,
      },
      {
        headerStyle: { width: "25%", "text-align": "center" },
        dataField: "actions",
        style: {
          textAlign: "center" as any,
        },
        text: (<button
            className="whiteBtnSm"
            onClick={ async () => {
              await setUpdatedDataPeriod(undefined); 
              setUpdatedDataPeriod(defaultDataPeriod)
            } }
            style={{ margin: "-0.75rem" }}
          >
            <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
            New Data Period
          </button>) as any
      },
    ],
    []
  );

  const options = useMemo(
    () => ({
      sizePerPageList: [
        {
          text: "20",
          value: 20,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
    }),
    []
  );

  const defaultSorted: [{ dataField: string; order: SortOrder }] = useMemo(
    () => [
      {
        dataField: "name",
        order: "asc",
      },
    ],
    []
  );

  const { isTeacher } = useUserRole();
  const { isCoach } = useUserRole();
  const myId = useSelector<ApplicationState, number | undefined>(
    (s) => s.auth.userInfo?.id
  );

  const data = useMemo(() => {
    const handleDeleteDataPeriod = (dataPeriodId: number) => {
      dispatch(
        showConfirmDialog({
          onConfirm: () => {
            setDisplayLoader(dataPeriodId);
            return deleteDataPeriod(dataPeriodId).then(
              () => {
                setUpdatedDataPeriod(undefined);
                toastr.success(
                  "Success",
                  "The data period has been successfully deleted!"
                );
                setDisplayLoader(0);
                dispatch(getDataPeriods());
              },
              (err: string) => {
                setDisplayLoader(0);
                toastr.error("Failed to delete the data period", err)
              }
            )

          },
          centered: true,
          text: `This data period may be shared with other users who may have already made decisions using the data.
           All users with whom this data may be shared will no longer have access to the data. Are you sure you want to continue?`
        })
      );
    };

    return dataPeriods.map((dataPeriod) => {
      const isMyDataPeriod = myId === dataPeriod.user_id;
      return {
        id: dataPeriod.id,
        name: `${dataPeriod.name}${isMyDataPeriod ? "" : ` (Shared by ${
          dataPeriod.user_name ? dataPeriod.user_name : dataPeriod.user_email
        })`}`,
        time_period: getTimePeriodLabel(dataPeriod.time_period),
        actions:
          !isMyDataPeriod && !isCoach ? null : (
            <div>
              {!isMyDataPeriod && isCoach ? (
                <span className="ml-3"></span>
              ) : (
                <span onClick={() => setUpdatedDataPeriod(dataPeriod)}>
                  <FontAwesomeIcon icon={faPen} size="lg" />
                </span>
              )}

              {dataPeriods.length > 1 ? (
                !isMyDataPeriod && isCoach ? (
                  <span className="ml-3"></span>
                ) :
                 (displayLoaderId != dataPeriod.id) ? <span
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleDeleteDataPeriod(dataPeriod.id);
                  }}
                  // style={{
                  //   visibility: isLoading.deleteDataPeriod ? "hidden" : "visible",
                  // }}
                  className="ml-4"
                >
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    size="lg"
                    style={{ color: "red" }}
                  />
                </span>
                :
                <span className="ml-1"><Spinner animation="border" size="sm" /></span>
              ) : (
                ""
              )}
            </div>
          ),
      };
    });
  }, [dataPeriods, isTeacher, isCoach, myId, isLoading]);

  const noDataIndicator = useMemo(() => {
    if (!data.length && isLoading.getDataPeriods) {
      return <LoadingIndicator />;
    }
    return "No data";
  }, [isLoading.getDataPeriods, data]);

  const handleDatePeriodSave = (e: any) => {
    e.preventDefault();
    if (updatedDataPeriod.id) {
      updateDataPeriod(updatedDataPeriod.id, updatedDataPeriod).then(
        () => {
          setUpdatedDataPeriod(undefined);
          toastr.success(
            "Success",
            "The data period has been successfully updated!"
          );
          dispatch(getDataPeriods());
        },
        (err: string) => toastr.error("Failed to update the data period", err)
      );
    } else {
      addDataPeriod(updatedDataPeriod).then(
        () => {
          setUpdatedDataPeriod(undefined);
          toastr.success(
            "Success",
            "The data period has been successfully added!"
          );
          dispatch(getDataPeriods());
        },
        (err: string) => toastr.error("Failed to create the data period", err)
      );
    }
  };

  const firstUpdate = useRef(true);

  useEffect(() => {
    if(updatedDataPeriod && updatedDataPeriod.year && updatedDataPeriod.time_period) {
      if(firstUpdate.current){
        setUpdatedDataPeriod((dataPeriod: any) => ({     
          ...dataPeriod,
          name: (updatedDataPeriod?.id == 0) ? `${updatedDataPeriod.year} - ${getTimePeriodWithoutLabel(updatedDataPeriod.time_period)}`: updatedDataPeriod.name,
        }))
        firstUpdate.current = false;
        return;
      }
    }
  },[updatedDataPeriod])

  const message = useMemo(() => {
    return selectedDataPeriod
        ? "You can create a new data period. If you need to, you can also update/delete your data periods."
          : dataPeriods.length
            ? "You do not have the selected data period. You can create a new data period or select an existing one."
              : "You have no data period. First create a new one.";
  }, [selectedDataPeriod, dataPeriods.length]);

  const isYearExist =  updatedDataPeriod && (updatedDataPeriod.year !== '') && possibleYears.some((y) => y.value === updatedDataPeriod.year)
  //console.log('isYearExist', isYearExist, (updatedDataPeriod.year == '') || (updatedDataPeriod.time_period == undefined) || (updatedDataPeriod.name == ''))
  return (
    <Modal
      show={showModal}
      size={"lg"}
      onShow={hideModalShow}
      onHide={hideManageDataPeriodsModal}
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>Manage Data Periods</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {permissionMessage ?
            <tr>
              <td colSpan={2} className="font-weight-bold">{permissionMessage}</td>
            </tr>
        :
        <>
          <h3 className="font-weight-bold mb-3">{message}</h3>

          {!selectedDataPeriod && !!dataPeriods.length && (
            <div className="mb-3">
              <CoachDataPeriodSelector small />
            </div>
          )}

          {updatedDataPeriod && (
            <Form>
              <div className="dataPeriodContainer">
                <Row>
                  <Col md={4}>
                    <h4 className="font-weight-bold m-0">Year</h4>
                    <Select
                      value={possibleYears.find((y) => y.value === updatedDataPeriod.year)}
                      menuPlacement="auto"
                      options={possibleYears}
                      // components={{
                      //   DropdownIndicator: () => null,
                      //   IndicatorSeparator: () => null,
                      // }}
                      styles={{
                        menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
                      }}
                      openMenuOnFocus
                      onChange={(year: any) => {
                        setUpdatedDataPeriod((dataPeriod: any) => ({
                          ...dataPeriod,
                          year: year.value,
                        }));
                        firstUpdate.current = true;
                      }}
                      isDisabled={updatedDataPeriod.id}
                    />
                  </Col>
                  <Col md={4}>
                    <h4>Time Period</h4>
                    <Select
                      placeholder={"Select..."}
                      //isClearable
                      value={TimePeriodOptions.find(
                        (tpo) => tpo.value === updatedDataPeriod.time_period
                      )}
                      options={TimePeriodOptions}
                      onChange={(timePeriodOption: any) => {
                        if(timePeriodOption) {
                          setUpdatedDataPeriod((dataPeriod: any) => ({
                            ...dataPeriod,
                            time_period: timePeriodOption.value,
                          }));
                        }
                        firstUpdate.current = true;
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    <FormGroup controlId="dataPeriodName">
                      <FormLabel>
                        <h4 className="font-weight-bold">Data Period Name</h4>
                      </FormLabel>
                      <FormControl
                        autoFocus
                        className="fullWidthInput"
                        name={"dataPeriodName"}
                        type="text"
                        value={updatedDataPeriod.name}
                        onChange={(e: React.FormEvent<any>) => {
                          e.preventDefault();
                          const { value } = e.target as HTMLInputElement;
                          setUpdatedDataPeriod((dataPeriod: any) => ({
                            ...dataPeriod,
                            name: value,
                          }));
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>
                  
                </Row>

                <div className="modalActions">
                  <div />
                  <button
                    className="blueBtnSm"
                    type="submit"
                    onClick={handleDatePeriodSave}
                    disabled={!isYearExist || (updatedDataPeriod.year == '') || (updatedDataPeriod.time_period == undefined) || (updatedDataPeriod.name == '')}
                  >
                    {updatedDataPeriod?.id
                      ? "Update Data Period"
                      : "Create Data Period"}
                    {(isLoading.addDataPeriod || isLoading.updateDataPeriod) && (
                      <Spinner animation="border" size="sm" className="ml-1" />
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}

          <BootstrapTable
            classes="rosterTable"
            keyField="id"
            bordered={false}
            bootstrap4
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            pagination={paginationFactory(options)}
            noDataIndication={noDataIndicator}
          />
        </>
      }
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
   return {
    dataPeriods: onboarding.dataPeriods,
    isLoading: {
      getDataPeriods: onboarding.isLoading.getDataPeriods,
      addDataPeriod: onboarding.isLoading.addDataPeriod,
      updateDataPeriod: onboarding.isLoading.updateDataPeriod,
      deleteDataPeriod: onboarding.isLoading.deleteDataPeriod,
    },
    showModal: onboarding.modalsState.manageDataPeriodsModal
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hideManageDataPeriodsModal: hideManageDataPeriodsModal,
      addDataPeriod: addDataPeriod,
      updateDataPeriod: updateDataPeriod,
      deleteDataPeriod: deleteDataPeriod,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageDataPeriodsModal);
