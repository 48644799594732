import React, { FunctionComponent } from "react";
import { Dropdown } from "react-bootstrap";
import { bindActionCreators, Dispatch } from "redux";
import {
  openTeacherInviteIndividuallyModal,
  openUploadTeachersRosterModal,
} from "../../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import { BasicUserInfo } from "../../../../../../store/onboarding/types";

type DispatchProps = {
  openUploadTeachersRosterModal: () => any;
  openTeacherInviteIndividuallyModal: (newTeacher?: BasicUserInfo) => any;
};
type OwnProps = {};

type Props = OwnProps & DispatchProps;

const InviteUsersDropDown: FunctionComponent<Props> = ({
  openTeacherInviteIndividuallyModal,
  openUploadTeachersRosterModal,
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-basic"
        variant="secondary"
        className="whiteDropdown"
      >
        INVITE USERS
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          as="button"
          onClick={() => openTeacherInviteIndividuallyModal()}
        >
          Invite Individually
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          onClick={() => openUploadTeachersRosterModal()}
        >
          Upload Roster
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openUploadTeachersRosterModal: openUploadTeachersRosterModal,
      openTeacherInviteIndividuallyModal: openTeacherInviteIndividuallyModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(InviteUsersDropDown);
