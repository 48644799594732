import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCrosshairs,
  faKeyboard,
  faQuestionCircle,
  faStar,
  faStickyNote,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { bindActionCreators, Dispatch } from "redux";
import {
  addExistingBehaviorEcgToDataPeriod,
  openConductInterviewModal,
  openEnterDataManuallyModal,
  openManageDataPeriodsModal,
  openUploadStudentDataModal,
} from "../../../../../store/onboarding/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { EvidencePeriodData } from "../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../store";
import { GoalTypes } from "../../../../../store/onboarding/cases/types";
import useDiagnoseSkillIssues from "../../../../../utils/hooks/useDiagnoseSkillIssues";
import useTargetBehaviorECG from "../../../../../utils/hooks/useTargetBehaviorECG";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { openPermissionSettingModal } from "../../../../../store/help/actions";
import useBehaviorScreenerECG from "../../../../../utils/hooks/useBehaviorScreenerECG";
import useUserRole from "../../../../../utils/hooks/useUserRole";

type PropsFromState = {
  evidencePeriodData?: EvidencePeriodData;
};

type DispatchProps = {
  openUploadStudentDataModal: () => any;
  openPermissionSettingModal: (isGlobal: boolean) => any;
  openEnterDataManuallyModal: (type?: GoalTypes) => any;
  addExistingBehaviorEcgToDataPeriod: (dataPeriodId?: number) => any;
  openConductInterviewModal: () => any;
};

type OwnProps = {
  onQuestionClick: () => any;
};

type Props = PropsFromState & DispatchProps & OwnProps;

const StartWorkingPanel: FunctionComponent<Props> = (props) => {
  const { onAdd: addDiagnosticSkillColumn } = useDiagnoseSkillIssues();
  const { onAdd: handleTargetSelect } = useTargetBehaviorECG();
  const { onAdd: handleScreenerSelect } = useBehaviorScreenerECG();

  const dispatch = useDispatch();
  const { isDistrictOwner } = useUserRole();

  const selectedDataPeriod = useSelector(
    (s: ApplicationState) => s.onboarding.currentDataPeriod
  );

  const handleUploadFileClick = () => {
    if (selectedDataPeriod) {
      isDistrictOwner 
      ? 
        props.openUploadStudentDataModal()
      :
        props.openPermissionSettingModal(false);
        
      document.body.click();
    } else {
      dispatch(openManageDataPeriodsModal());
    }
  };
  const handleInterviewTeachersClick = () => {
    if (selectedDataPeriod) {
      props.openConductInterviewModal();
      document.body.click();
    } else {
      dispatch(openManageDataPeriodsModal());
    }
  };
  const handleBrsClick = () => {
    if (selectedDataPeriod) {
      props.openEnterDataManuallyModal(GoalTypes.BEHAVIOR);
      document.body.click();
    } else {
      dispatch(openManageDataPeriodsModal());
    }
  };
  const handleTargetBehaviorClick = () => {
    if (selectedDataPeriod) {
      handleTargetSelect();
      document.body.click();
    } else {
      dispatch(openManageDataPeriodsModal());
    }
  };
  const handleCommonAssessmentsClick = () => {
    if (selectedDataPeriod) {
      props.openEnterDataManuallyModal(GoalTypes.ACADEMIC);
      document.body.click();
    } else {
      dispatch(openManageDataPeriodsModal());
    }
  };
  const handleDiagnoseSkillIssuesClick = () => {
    if (selectedDataPeriod) {
      addDiagnosticSkillColumn();
    } else {
      dispatch(openManageDataPeriodsModal());
    }
  };
  const handleBehaviorScreenClick = () => {
    if (selectedDataPeriod) {
      handleScreenerSelect();
      document.body.click();
    } else {
      dispatch(openManageDataPeriodsModal());
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <h3 className="font-weight-bold">Start Working on Your Data Table</h3>
          Your table will contain evidence for grouping your students
        </div>

        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="auto"
          flip
          overlay={
            <Tooltip id="question-btn-tooltip" color="#fff">
              Back
            </Tooltip>
          }
        >
          <FontAwesomeIcon
            icon={faQuestionCircle}
            size="2x"
            className="blueIconBtn"
            onClick={props.onQuestionClick}
          />
        </OverlayTrigger>
      </div>

      <div className="mt-2">
        <table className="addColumnTooltipTable" id="addColumnTooltip">
          <thead>
            <tr>
              <th />
              <th>Enter Initial Data</th>
              <th>
                Target Behaviors/Skills
                <br /> for Intervention
              </th>
              <th>Behavior Screener</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Upload Data or Interview</td>
              <td>
                <div
                  className="addColumnBtn headingFont"
                  onClick={handleUploadFileClick}
                  data-cy="upload-file-btn"
                >
                  <span className="customCircleSpan">
                    <FontAwesomeIcon icon={faUpload} size="lg" />
                  </span>
                  <div className="d-flex flex-column">
                    <strong>Upload a File</strong>
                    <small>
                      We support many reading and behavior assessments
                    </small>
                  </div>
                </div>
                {!isDistrictOwner 
                ? 
                <div
                  className="addColumnBtn headingFont"
                  onClick={handleInterviewTeachersClick}
                >
                  <span className="customCircleSpan">
                    <FontAwesomeIcon icon={faStickyNote} size="lg" />
                  </span>
                  <div className="d-flex flex-column">
                    <strong>Interview Teachers</strong>
                    <small>
                      Talk to teachers about student issues and record your
                      notes
                    </small>
                  </div>
                </div> : '' }
              </td>
              <td />
              <td/>
            </tr>
            <tr>
              <td>Manually Enter Behavior Data</td>
              <td>
                <div className="addColumnBtn headingFont" onClick={handleBrsClick}>
                  <span className="customCircleSpan">
                    <FontAwesomeIcon icon={faKeyboard} size="lg" />
                  </span>
                  <div className="d-flex flex-column">
                    <strong> Behavior Data</strong>
                    <small>Pick an assessment and type in student scores</small>
                  </div>
                </div>
              </td>
              <td>
                {!isDistrictOwner 
                ? 
                <div
                  className="addColumnBtn headingFont"
                  onClick={handleTargetBehaviorClick}
                >
                  <span className="customCircleSpan">
                    <FontAwesomeIcon icon={faCrosshairs} size="lg" />
                  </span>
                  <div className="d-flex flex-column">
                    <strong>Select Target Behaviors</strong>
                    <small>
                      Choose student behaviors from a list or type in a custom
                      one
                    </small>
                  </div>
                </div>
                : ''}
              </td>
              <td>
                <div
                  className="addColumnBtn headingFont"
                  onClick={handleBehaviorScreenClick}
                >
                  <span className="customCircleSpan">
                    <FontAwesomeIcon icon={faCrosshairs} size="lg" />
                  </span>
                  <div className="d-flex flex-column">
                    <strong>Add Behavior Screener</strong>
                    <small>
                      Allow behavior screening via the Student Risk Screen Scale (SRSS)
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Manually Enter Reading Data</td>
              <td>
                <div
                  className="addColumnBtn headingFont"
                  onClick={handleCommonAssessmentsClick}
                >
                  <span className="customCircleSpan">
                    <FontAwesomeIcon icon={faKeyboard} size="lg" />
                  </span>
                  <div className="d-flex flex-column">
                    <strong>Reading Data</strong>
                    <small>Pick an assessment and type in student scores</small>
                  </div>
                </div>
              </td>
              <td>
                <div
                  className="addColumnBtn headingFont"
                  onClick={handleDiagnoseSkillIssuesClick}
                >
                  <span className="customCircleSpan">
                    <FontAwesomeIcon
                      icon={faStar}
                      size="lg"
                      className="customStarIcon position-absolute"
                      transform={"left-12 up-8"}
                    />
                    <FontAwesomeIcon icon={faKeyboard} size="lg" />
                  </span>
                  <div className="d-flex flex-column">
                    <strong>Diagnose Skill Issues</strong>
                    <small>
                      Select which reading skills students are focusing from a
                      list
                    </small>
                  </div>
                </div>
              </td>
              <td/>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="mb-0">
        <FontAwesomeIcon icon={faStar} className="customStarIcon mr-1" />
        Starred options support automatic grouping for some grade levels and
        time periods
      </p>
    </div>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    evidencePeriodData: onboarding.evidencePeriodData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openUploadStudentDataModal: openUploadStudentDataModal,
      openPermissionSettingModal: openPermissionSettingModal,
      openEnterDataManuallyModal: openEnterDataManuallyModal,
      openConductInterviewModal: openConductInterviewModal,
      addExistingBehaviorEcgToDataPeriod: addExistingBehaviorEcgToDataPeriod,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StartWorkingPanel);
