import React, { FunctionComponent } from "react";
import { Badge } from "../../../store/badges/types";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { hideNewAchievementsModal } from "../../../store/badges/actions";
import { Carousel, Modal } from "react-bootstrap";

type StateProps = {
  showModal?: boolean;
  newAchievements: Array<Badge>;
};
type DispatchProps = {
  hideNewAchievementsModal: () => any;
};

type Props = StateProps & DispatchProps;

const NewAchievementsModal: FunctionComponent<Props> = ({
  showModal,
  newAchievements,
  hideNewAchievementsModal,
}) => {
  return (
    <Modal
      centered
      animation={false}
      size="lg"
      backdropClassName="customDarkModalBackdrop inner in"
      show={showModal}
      onHide={hideNewAchievementsModal}
    >
      <Modal.Body>
        <Carousel
          className={`customCarousel ${
            newAchievements.length === 1 ? "single-item" : ""
          }`}
        >
          {newAchievements.map((badge) => (
            <Carousel.Item key={badge.id}>
              <img
                className="d-block m-auto"
                src={badge.icon}
                alt="First slide"
              />
              <Carousel.Caption>
                <h1>You earned the {badge.name} badge!</h1>
                <h2>Nice Job. Keep it up!</h2>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="d-flex justify-content-center">
          <button
            className="blueBtnMd"
            onClick={() => hideNewAchievementsModal()}
          >
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ badges }: ApplicationState): StateProps => {
  return {
    showModal: badges.showNewAchievementsModal,
    newAchievements: badges.newAchievements,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideNewAchievementsModal: hideNewAchievementsModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewAchievementsModal);
