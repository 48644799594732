import React, { Component } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  hideInterpretFbaModal,
  updateFba,
} from "../../../../../../store/onboarding/cases/actions";
import {
  Fba,
  FbaBehavior,
  FbaBehaviorDisplayed,
} from "../../../../../../store/onboarding/cases/types";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import FbaTable from "./FbaTable";
import BehaviorDomainsTable from "../../../../../common/onboarding/second-step/evidence-tab/select-target/about-target-behaviors/BehaviorDomainsTable";
import {
  addCategoryBehavior,
  getStaticDataCategoryDomains,
} from "../../../../../../store/onboarding/actions";
import {
  mapStaticDataCategoryDomains,
  TargetBehaviorGroup,
} from "../../../../../common/onboarding/second-step/evidence-tab/select-target/utils";
import {
  CategoryBehavior,
  CategoryBehaviorRequest,
} from "../../../../../../store/onboarding/types";
import BehaviorDomainBehaviorsTable from "../../../../../common/onboarding/second-step/evidence-tab/select-target/about-target-behaviors/BehaviorDomainBehaviorsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";

type PropsFromState = {
  selectedFba?: Fba;
  showModal: boolean;
  positiveTargetBehaviorGroup: Array<TargetBehaviorGroup>;
  isLoading: {
    getStaticDataCategoryDomains: boolean;
    updateFba: boolean;
  };
  errors: {
    getStaticDataCategoryDomains?: string;
    updateFba?: string;
  };
};

type DispatchProps = {
  hideInterpretFbaModal: () => any;
  getStaticDataCategoryDomains: () => any;
  updateFba: (fba: Fba) => any;
  addCategoryBehavior: (categoryBehavior: CategoryBehaviorRequest) => any;
};

type State = {
  selectedFba?: Fba;
  step: number;
  selectedBehavior?: TargetBehaviorGroup;
  replacementTarget?: CategoryBehavior;
};

type Props = PropsFromState & DispatchProps;

const fbaBehaviorOptions = Object.values(FbaBehavior).map((beh) => ({
  label: FbaBehaviorDisplayed[beh],
  value: beh,
}));

class InterpretFbaModal extends Component<Props, State> {
  defaultState: Readonly<State> = {
    selectedFba: undefined,
    step: 0,
    selectedBehavior: undefined,
    replacementTarget: undefined,
  };

  state: Readonly<State> = this.defaultState;

  componentDidUpdate(
    prevProps: Readonly<PropsFromState & DispatchProps>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.isLoading.updateFba && !this.props.isLoading.updateFba) {
      if (!this.props.errors.updateFba) {
        this.props.hideInterpretFbaModal();
      } else {
        toastr.error("Error", this.props.errors.updateFba);
      }
    }
  }

  handleModalHide = () => {
    this.props.hideInterpretFbaModal();
  };

  handleModalShow = () => {
    this.setState({
      ...this.defaultState,
      selectedFba: this.props.selectedFba,
    });
    this.props.getStaticDataCategoryDomains();
  };

  handleSelectedFbaBehaviorChange = (option: ValueType<any, false> | null) => {
    this.setState((prevState) => ({
      selectedFba: {
        ...prevState.selectedFba!,
        target_behavior: option ? option.value : undefined,
        replacement_behavior_other: undefined,
      },
    }));
  };

  handleBehaviorSelect = (behavior: TargetBehaviorGroup) => () => {
    this.setState({
      selectedBehavior: behavior,
      replacementTarget: undefined,
    });
  };

  handleCustomTargetBehaviorNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      replacementTarget: {
        ...prevState.replacementTarget!,
        name: value,
        full_display_name: value,
      },
    }));
  };

  handleReplacementBehaviorOtherChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      selectedFba: {
        ...prevState.selectedFba!,
        replacement_behavior_other: value,
      },
    }));
  };

  handleCustomTargetSelect = () => {
    this.setState((prevState) => ({
      replacementTarget: {
        domain: prevState.selectedBehavior!.domain,
        created: new Date().toUTCString(),
        is_active: true,
        order:
          prevState.selectedBehavior!.behaviors.reduce(
            (pV: number, cV: CategoryBehavior) =>
              pV > cV.order ? pV : cV.order,
            0
          ) + 1,
        category_type: prevState.selectedBehavior!.domain.category_type,
        name: "",
        full_display_name: "",
      },
    }));
  };

  handleTargetBehaviorSelect = (target: CategoryBehavior) => () => {
    this.setState({
      replacementTarget: target,
    });
  };

  handleCanPerformReplacementChange = (value: boolean) => () => {
    this.setState((prevState) => ({
      selectedFba: {
        ...prevState.selectedFba!,
        can_perform_replacement: value,
      },
    }));
  };

  handleAntecedentsEffectiveChange = (value: boolean) => () => {
    this.setState((prevState) => ({
      selectedFba: {
        ...prevState.selectedFba!,
        antecedents_effective: value,
      },
    }));
  };

  handleFbaUpdate = async () => {
    const { selectedFba, replacementTarget } = this.state;
    if (selectedFba) {
      const isCustomReplacementTarget =
        replacementTarget && !replacementTarget.id;
      let replacementBehavior: any;
      if (isCustomReplacementTarget) {
        const categoryBehavior: CategoryBehaviorRequest = {
          is_active: true,
          order: replacementTarget!.order,
          name: replacementTarget!.name,
          full_display_name: replacementTarget!.full_display_name,
          definition: "definition",
          example: "example",
          category_type: replacementTarget!.category_type,
          domain: replacementTarget!.domain.id,
        };
        replacementBehavior = await this.props.addCategoryBehavior(
          categoryBehavior
        );
      }
      this.props.updateFba({
        ...selectedFba,
        replacement_behavior: isCustomReplacementTarget
          ? replacementBehavior?.id
          : replacementTarget?.id,
      });
    }
  };

  getModalBody = () => {
    switch (this.state.step) {
      case 0: {
        const { selectedFba } = this.state;
        if (!selectedFba) return null;

        const handleSubmit = (e: React.FormEvent<any>) => {
          e.preventDefault();
          this.setState((prevState) => ({ step: prevState.step + 1 }));
        };

        return (
          <>
            <p>
              To find a replacement behavior, you can you use the target
              behavior finder below or write your own. Be sure the target
              behavior and related contingencies still provide the student with
              the same function. For example, if the problem behavior previously
              provided the student with lots of attention, the new replacement
              behavior must do so as well.
            </p>
            <ol>
              <li>
                Select the problem behavior from the most frequent behavior
                list.
              </li>
              <li>
                Identify the most frequent consequence for the target behavior,
                which represents the function of the behavior (i.e., why the
                student engages in the behavior).
              </li>
              <li>
                Identify the replacement target behavior that is socially
                acceptable yet still meets the same function (i.e., functionally
                equivalent).
              </li>
            </ol>

            <FbaTable selectedFba={selectedFba} />

            <h3 className="font-weight-semibold mt-4">
              Select the target behavior for which you want to interpret the ABC
              Data Collection:
            </h3>

            <form onSubmit={handleSubmit}>
              <div style={{ maxWidth: "35%", margin: "10px 0px 10px 0px" }}>
                <Select
                  isClearable
                  menuPlacement="auto"
                  value={fbaBehaviorOptions.find(
                    (x) => x.value === selectedFba.target_behavior
                  )}
                  options={fbaBehaviorOptions}
                  onChange={this.handleSelectedFbaBehaviorChange}
                />
              </div>

              {selectedFba.target_behavior === FbaBehavior.BEHAVIOR_OTHER && (
                <Form.Group>
                  <Form.Label className="font-weight-bold">
                    Replacement Behavior
                  </Form.Label>
                  <Form.Control
                    required
                    value={selectedFba.replacement_behavior_other}
                    onChange={this.handleReplacementBehaviorOtherChange}
                  />
                </Form.Group>
              )}

              <hr className="mt-3" />
              <div className="modalActions">
                <div />
                <button
                  className="blueBtnSm"
                  disabled={selectedFba.target_behavior == undefined}
                  type={"submit"}
                  // onClick={() =>
                  //   this.setState(prevState => ({ step: prevState.step + 1 }))
                  // }
                >
                  Continue
                </button>
              </div>
            </form>
          </>
        );
      }
      case 1: {
        const { selectedFba } = this.state;
        if (!selectedFba) return null;

        const getRecommendationText = () => {
          if (selectedFba.can_perform_replacement) {
            if (selectedFba.antecedents_effective) {
              return "Excellent! We recommend finding an intervention that focuses on increasing a desired behavior.";
            } else {
              return "We recommend improving the classroom environment before finding an intervention that focuses on increasing a desired behavior.";
            }
          } else {
            if (selectedFba.antecedents_effective) {
              return "Good! We recommend focusing on teaching the replacement behavior.";
            } else {
              return "Okay. We recommend improving the classroom environment as well as teaching the replacement behavior.";
            }
          }
        };

        return (
          <>
            <p>
              To find a replacement behavior, you can use the target behavior
              finder below or write your own. Be sure the target behavior and
              related contingencies still provide the student with the same
              function. For example, if the problem behavior previously provided
              the student with lots of attention, the new replacement behavior
              must do so as well.
            </p>

            <div className="my-4">
              <FbaTable selectedFba={selectedFba} />
            </div>

            <h3>
              <strong>Selected Target Behavior: </strong>
              {selectedFba.target_behavior != undefined
                ? FbaBehaviorDisplayed[selectedFba.target_behavior!]
                : ""}
            </h3>

            {selectedFba.replacement_behavior_other ? (
              <>
                <h3 className="mt-4 mb-2">
                  <strong>Selected Replacement Behavior: </strong>
                  {selectedFba.replacement_behavior_other}
                </h3>
              </>
            ) : (
              <>
                <h3 className="font-weight-bold mt-4">
                  Select a replacement positive behavior:
                </h3>
                <div className="d-flex justify-content-between align-items-start my-1">
                  <div className="findTargetTable">
                    <BehaviorDomainsTable
                      targetBehaviorGroups={
                        this.props.positiveTargetBehaviorGroup
                      }
                      selectedBehavior={this.state.selectedBehavior}
                      handleBehaviorSelect={this.handleBehaviorSelect}
                    />
                  </div>
                  <div style={{ width: "48%" }}>
                    <BehaviorDomainBehaviorsTable
                      selectedBehavior={this.state.selectedBehavior}
                      selectedTarget={this.state.replacementTarget}
                      onCustomTargetBehaviorNameChange={
                        this.handleCustomTargetBehaviorNameChange
                      }
                      onCustomTargetSelect={this.handleCustomTargetSelect}
                      onTargetBehaviorSelect={this.handleTargetBehaviorSelect}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="d-flex align-items-center">
              <h3 style={{ flex: 1 }}>
                Can the student perform the replacement behavior?
              </h3>
              <div style={{ flex: 1 }}>
                <button
                  className={
                    selectedFba.can_perform_replacement
                      ? "purplePillBtnSelected"
                      : "purplePillBtn"
                  }
                  onClick={this.handleCanPerformReplacementChange(true)}
                >
                  Yes
                </button>
                <button
                  className={
                    !selectedFba.can_perform_replacement
                      ? "purplePillBtnSelected"
                      : "purplePillBtn"
                  }
                  onClick={this.handleCanPerformReplacementChange(false)}
                >
                  No
                </button>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <h3 style={{ flex: 1 }}>
                Review the environmental antecedents. Do they represent
                effective classroom practices?
              </h3>
              <div style={{ flex: 1 }}>
                <button
                  className={
                    selectedFba.antecedents_effective
                      ? "purplePillBtnSelected"
                      : "purplePillBtn"
                  }
                  onClick={this.handleAntecedentsEffectiveChange(true)}
                >
                  Yes
                </button>
                <button
                  className={
                    !selectedFba.antecedents_effective
                      ? "purplePillBtnSelected"
                      : "purplePillBtn"
                  }
                  onClick={this.handleAntecedentsEffectiveChange(false)}
                >
                  No
                </button>
              </div>
            </div>

            <div className="fbaExcellentMsgContainer my-3">
              <FontAwesomeIcon
                icon={faThumbsUp}
                size="2x"
                style={{
                  color: "#8bc34a",
                  marginBottom: "10px",
                }}
              />
              <h3 className="font-weight-bold">{getRecommendationText()}</h3>
            </div>

            <hr />
            <div className="modalActions">
              <button
                className="blueBtnSm"
                onClick={() =>
                  this.setState((prevState) => ({ step: prevState.step - 1 }))
                }
              >
                Back
              </button>
              <button className="blueBtnSm" onClick={this.handleFbaUpdate}>
                Complete ABC Data Collection{" "}
                {this.props.isLoading.updateFba && (
                  <Spinner animation="border" size="sm" />
                )}
              </button>
            </div>
          </>
        );
      }
      default:
        break;
    }
  };

  render() {
    const { showModal, selectedFba } = this.props;
    if (!selectedFba) return null;
    return (
      <Modal
        show={showModal}
        size="lg"
        onHide={this.handleModalHide}
        onShow={this.handleModalShow}
        animation={false}
        backdropClassName="customDarkModalBackdrop in"
      >
        <Modal.Header closeButton className="purpleModalHeader">
          <Modal.Title>Interpret ABC Data Collection</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.getModalBody()}</Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  cases,
  onboarding,
}: ApplicationState): PropsFromState => {
  return {
    selectedFba: cases.selectedFba,
    showModal: cases.modalsState.interpretFbaModal,
    positiveTargetBehaviorGroup: mapStaticDataCategoryDomains(
      onboarding.staticDataCategoryDomains
    ).positive,
    isLoading: {
      getStaticDataCategoryDomains:
        onboarding.isLoading.getStaticDataCategoryDomains,
      updateFba: cases.isLoading.updateFba,
    },
    errors: {
      getStaticDataCategoryDomains:
        onboarding.errors.getStaticDataCategoryDomains,
      updateFba: cases.errors.updateFba,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideInterpretFbaModal: hideInterpretFbaModal,
      getStaticDataCategoryDomains: getStaticDataCategoryDomains,
      updateFba: updateFba,
      addCategoryBehavior: addCategoryBehavior,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InterpretFbaModal);
