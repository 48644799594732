import React, { FunctionComponent } from "react";
import LoadingIndicator from "../../../../LoadingIndicator";
import { getFullName } from "../../../../../../../utils/NamesUtils";
import SelectBehaviorBtn from "../SelectBehaviorBtn";
import {
  InterventionGroup,
  StudentGoal,
} from "../../../../../../../store/onboarding/cases/types";
import {
  EvidenceColumnGroup,
  EvidenceType,
  StudentEntry,
} from "../../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import BehaviorTags from "./BehaviorTags";
import { changeSelectedStudentEntry } from "../../../../../../../store/onboarding/actions";

type StateProps = {
  interventionGroup: InterventionGroup;
  targetBehaviorStudentEntries?: Array<StudentEntry>;
  isLoading: {
    getEvidenceInDataPeriod: boolean;
  };
};

type DispatchProps = {
  changeSelectedStudentEntry: (studentEntry?: StudentEntry) => any;
};

type OwnProps = {
  studentGoal?: StudentGoal;
};

type Props = OwnProps & StateProps & DispatchProps;

const BehaviorsTable: FunctionComponent<Props> = ({
  studentGoal,
  interventionGroup,
  isLoading: { getEvidenceInDataPeriod: loadingGetEvidenceInDataPeriod },
  changeSelectedStudentEntry,
  targetBehaviorStudentEntries,
}) => {
  const getStudentEntry = (studentId: number): StudentEntry =>
    targetBehaviorStudentEntries?.find(
      (tgtBehSe) => tgtBehSe.student_id === studentId
    ) ?? {
      //HACK
      entry_id: -1 * studentId,
      student_id: studentId,
      behavior_tags: [],
    };

  const studentList = studentGoal
    ? [studentGoal.student]
    : interventionGroup.students;

  return (
    <table className="stdTableWide">
      <thead>
        <tr>
          <th>Student</th>
          <th>Target Behaviors</th>
          <th className="text-end">
            <SelectBehaviorBtn
              isForAll
              hasValues={studentList.some(
                (student) =>
                  student.id != null &&
                  getStudentEntry(student.id).behavior_tags?.length
              )}
              onClick={() => null}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {loadingGetEvidenceInDataPeriod ? (
          <tr>
            <td colSpan={3}>
              <LoadingIndicator />
            </td>
          </tr>
        ) : (
          studentList.map((student) => {
            const studentEntry = getStudentEntry(student.id!);
            return (
              <tr key={student.id}>
                <td>{getFullName(student)}</td>
                <td>
                  {studentEntry?.behavior_tags?.length ? (
                    <BehaviorTags studentEntry={studentEntry} />
                  ) : (
                    <i>Not selected</i>
                  )}
                </td>
                <td className="text-end">
                  <SelectBehaviorBtn
                    hasValues={
                      (studentEntry &&
                        studentEntry.behavior_tags &&
                        studentEntry.behavior_tags.length > 0) ||
                      false
                    }
                    onClick={() => changeSelectedStudentEntry(studentEntry)}
                  />
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};

const mapStateToProps = ({
  cases,
  onboarding,
}: ApplicationState): StateProps => {
  const targetBehaviorECG:
    | EvidenceColumnGroup
    | undefined = onboarding.evidencePeriodData
    ? onboarding.evidencePeriodData.evidence_column_groups.find(
        (ecg) =>
          !ecg.assessment &&
          ecg.evidence_columns.some(
            (ec) => ec.evidence_type === EvidenceType.ExistingBehavior
          )
      )
    : undefined;

  return {
    interventionGroup: cases.selectedInterventionGroup!,
    isLoading: {
      getEvidenceInDataPeriod: onboarding.isLoading.getEvidenceInDatePeriod,
    },
    targetBehaviorStudentEntries:
      targetBehaviorECG && cases.selectedInterventionGroup
        ? targetBehaviorECG.evidence_columns[0].student_entries.filter((se) =>
            cases.selectedInterventionGroup!.students.some(
              (s) => s.id === se.student_id
            )
          )
        : [],
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      changeSelectedStudentEntry: changeSelectedStudentEntry,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BehaviorsTable);
