import { Dispatch } from "redux";
import axios, { AxiosError } from "axios";
import { API_NOTIFICATION_SETTINGS, API_NOTIFICATIONS } from "../../constants";
import { action } from "typesafe-actions";
import {
  Notification,
  NotificationsActionTypes,
  NotificationSettings,
} from "./types";

export const getNotificationsRequest = () =>
  action(NotificationsActionTypes.GET_NOTIFICATIONS_START);
export const getNotificationsSuccess = (notifications: Array<Notification>) =>
  action(NotificationsActionTypes.GET_NOTIFICATIONS_SUCCESS, {
    notifications,
  });
export const getNotificationsFailure = (message: string) =>
  action(NotificationsActionTypes.GET_NOTIFICATIONS_FAILURE, {
    error: message,
  });

export const markAsReadNotificationRequest = () =>
  action(NotificationsActionTypes.MARK_AS_READ_NOTIFICATION_START);
export const markAsReadNotificationSuccess = (
  notifications: Array<Notification>
) =>
  action(NotificationsActionTypes.MARK_AS_READ_NOTIFICATION_SUCCESS, {
    notifications,
  });
export const markAsReadNotificationFailure = (message: string) =>
  action(NotificationsActionTypes.MARK_AS_READ_NOTIFICATION_FAILURE, {
    error: message,
  });

export const getNotifications = () => {
  return (dispatch: Dispatch) => {
    dispatch(getNotificationsRequest());
    return axios
      .get(API_NOTIFICATIONS())
      .then((res) => {
        const notifications: Array<Notification> = res.data;
        dispatch(getNotificationsSuccess(notifications));
      })
      .catch((err: AxiosError) => {
        dispatch(getNotificationsFailure(err.message));
      });
  };
};

export const markAsReadNotification = (notificationIds: Array<number>) => {
  return (dispatch: Dispatch) => {
    dispatch(markAsReadNotificationRequest());

    const requests = notificationIds.map((id) =>
      axios
        .patch(API_NOTIFICATIONS(id), {
          read: true,
        })
        .then((res) => {
          return res.data;
        })
    );

    return Promise.all(requests)
      .then((res: Array<Notification>) => {
        dispatch(markAsReadNotificationSuccess(res));
      })
      .catch((error: AxiosError) => {
        dispatch(markAsReadNotificationFailure(error.message));
        return Promise.reject(error.message);
      });
  };
};

export const getNotificationSettingsRequest = () =>
  action(NotificationsActionTypes.GET_NOTIFICATION_SETTINGS_START);
export const getNotificationSettingsSuccess = (
  notificationSettings: Array<NotificationSettings>
) =>
  action(NotificationsActionTypes.GET_NOTIFICATION_SETTINGS_SUCCESS, {
    notificationSettings,
  });
export const getNotificationSettingsFailure = (message: string) =>
  action(NotificationsActionTypes.GET_NOTIFICATION_SETTINGS_FAILURE, {
    error: message,
  });

export const getNotificationSettings = () => {
  return (dispatch: Dispatch) => {
    dispatch(getNotificationSettingsRequest());
    return axios
      .get(API_NOTIFICATION_SETTINGS)
      .then((res) => {
        const notificationSettings: Array<NotificationSettings> = res.data;
        dispatch(getNotificationSettingsSuccess(notificationSettings));
      })
      .catch((err: AxiosError) => {
        dispatch(getNotificationSettingsFailure(err.message));
      });
  };
};

export const updateNotificationSettingsRequest = () =>
  action(NotificationsActionTypes.UPDATE_NOTIFICATION_SETTINGS_START);
export const updateNotificationSettingsSuccess = (
  notificationSettings: NotificationSettings[]
) =>
  action(NotificationsActionTypes.UPDATE_NOTIFICATION_SETTINGS_SUCCESS, {
    notificationSettings,
  });
export const updateNotificationSettingsFailure = (message: string) =>
  action(NotificationsActionTypes.UPDATE_NOTIFICATION_SETTINGS_FAILURE, {
    error: message,
  });

export const updateNotificationSettings = (preferences: {
  [id: number]: {
    email: boolean;
    in_app: boolean;
  };
}) => {
  return (dispatch: Dispatch) => {
    dispatch(updateNotificationSettingsRequest());
    return axios
      .put(API_NOTIFICATION_SETTINGS, {
        preferences,
      })
      .then((res) => {
        const notificationSettings: NotificationSettings[] = res.data;
        dispatch(updateNotificationSettingsSuccess(notificationSettings));
      })
      .catch((err: AxiosError) => {
        dispatch(updateNotificationSettingsFailure(err.message));
      });
  };
};
