import React, { FunctionComponent, useMemo } from "react";
import { getFullName } from "../../../../../../../utils/NamesUtils";
import { Student } from "../../../../../../../store/onboarding/types";
import Tooltip from "react-bootstrap/Tooltip";
import { OverlayTrigger } from "react-bootstrap";

type OwnProps = {
  student: Student;
  showFullNames?: boolean;
};

type Props = OwnProps;

const StudentHeaderCell: FunctionComponent<Props> = (props) => {
  const { student, showFullNames } = props;

  const fullName = useMemo(() => getFullName(student), [student]);
  const nameInitials = useMemo(() => {
    return `${student.first_name.charAt(0)}.${student.last_name.charAt(
      0
    )}.`.toUpperCase();
  }, [student]);

  return (
    <th>
      <OverlayTrigger
        placement="bottom"
        trigger={showFullNames ? [] : ["hover", "focus"]}
        overlay={<Tooltip id={`fullName`}>{fullName}</Tooltip>}
      >
        <div>{showFullNames ? fullName : nameInitials}</div>
      </OverlayTrigger>
    </th>
  );
};

export default StudentHeaderCell;
