import React, { FunctionComponent, useMemo } from "react";
import { getFullName } from "../../../../../../utils/NamesUtils";
import VerticalBarChart from "./VerticalBarChart";
import { GroupInfo } from "../../../../../../store/onboarding/types";

type OwnProps = {
  label?: string;
  groupInfo: GroupInfo[];
};

type Props = OwnProps;

const AlertsPerCoachChart: FunctionComponent<Props> = (props) => {
  const { label, groupInfo = [] } = props;
  const labels = useMemo(() => groupInfo.map((gi) => getFullName(gi.coach)), [
    groupInfo,
  ]);
  const data = useMemo(() => {
    return groupInfo.map((gi) => gi.with_alerts_count);
  }, [groupInfo]);

  if (!data.length) {
    return null;
  }

  return (
    <div className="district-summary-chart-container">
      {label && <h3 className="text-center">{label}</h3>}
      <div>
        <VerticalBarChart labels={labels} data={data} isNumeric />
      </div>
    </div>
  );
};

export default AlertsPerCoachChart;
