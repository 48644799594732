import { Grade } from "../groups/types";

export type DataSortState = {
  allColoumnFields:any,
  activeSort:any,
  isResetColumnSort: boolean;
  isColumnSortApplied: boolean;
  showModal:boolean
};

export type GroupSorting = {
  sortBy: InterventionGroupSortBy;
  direction: "asc" | "desc";
};

export enum InterventionGroupSortBy {
  GOAL_DATE,
  PROGRESS_TREND,
  ACTIVITY,
  INTERVENTION_SCHEDULE,
}

export enum DataSortActionTypes {
  SHOW_MODAL = '@@datashort/SHOW_MODAL',
  CLOSE_MODAL = '@@datashort/CLOSE_MODAL',
  CHANGE_SORTING_DATA = '@@datashort/CHANGE_SORTING_DATA',
  RESET_COLUMN_SORT = '@@datashort/RESET_COLUMN_SORT',
  CHANGE_ALL_FIELDS = '@@datashort/CHANGE_ALL_FIELDS',
  COLUMN_SORT_APPLIED = '@@datashort/COLUMN_SORT_APPLIED'
}
