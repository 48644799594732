import {
  GrammarType,
  SpellingTestAnswer,
} from "../../../store/spelling-tests/types";

export const getFullWordsAnswersStatistics = (
  studentTestAnswers: SpellingTestAnswer[]
) => {
  return studentTestAnswers.reduce(
    (pV, cV) => {
      const isCorrectAnswer = cV.word === cV.student_answer;
      return {
        total: pV.total + 1,
        correct: isCorrectAnswer ? pV.correct + 1 : pV.correct,
      };
    },
    { total: 0, correct: 0 }
  );
};
export const getGrammarPartsAnswersStatistics = (
  studentTestAnswers: SpellingTestAnswer[]
) => {
  return studentTestAnswers
    .flatMap((a) => a.grammar_parts)
    .reduce((pV, cV) => {
      const prevValue = pV[cV.grammar_type] ?? { total: 0, correct: 0 };
      return {
        ...pV,
        [cV.grammar_type]: {
          total: prevValue.total + 1,
          correct: cV.correct ? prevValue.correct + 1 : prevValue.correct,
        },
      };
    }, {} as { [key in GrammarType]: { total: number; correct: number } });
};
