import React, { FunctionComponent, useEffect, useMemo } from "react";
import { ApplicationState } from "../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useSelector } from "react-redux";
import { InterventionGroup } from "../../../store/onboarding/cases/types";
import {
  changeSelectedMeeting,
  getMeetings,
} from "../../../store/onboarding/meeting-module/actions";
import { push } from "connected-react-router";
import { Meeting } from "../../../store/onboarding/meeting-module/types";
import LoadingIndicator from "../../common/onboarding/LoadingIndicator";
import { toastr } from "react-redux-toastr";
import MeetingsTable from "./MeetingsTable";
import InterventionGroupTeacher from "../coach-dashboard/tabs/your-assigments/common/InterventionGroupTeacher";
import { UserAssignmentRole } from "../../../store/auth/types";

type StateProps = {
  meetings: Array<Meeting>;
  interventionGroup?: InterventionGroup;
  isLoading: {
    getMeetings: boolean;
  };
};

type DispatchProps = {
  changeSelectedMeeting: (meeting?: Meeting) => any;
  getMeetings: (meetingId?: number, interventionGroupId?: number) => any;
  push: (location: string) => any;
};

type Props = StateProps & DispatchProps;

const InterventionNotesTab: FunctionComponent<Props> = ({
  interventionGroup,
  meetings,
  isLoading: { getMeetings: loadingGetMeetings },
  changeSelectedMeeting,
  getMeetings,
  push,
}) => {
  useEffect(() => {
    interventionGroup &&
      getMeetings(undefined, interventionGroup.id).then(null, (err: string) =>
        toastr.error("Error", err)
      );
    changeSelectedMeeting();
  }, [interventionGroup]);

  const handleContinue = () => {
    push(`/cases/groups/${interventionGroup!.id}/meeting`);
  };

  const userRole = useSelector(
    (s: ApplicationState) => s.auth.userInfo?.profile.current_assignment?.role
  );

  const isTeacher = useMemo(
    () =>
      userRole === UserAssignmentRole.TEACHER ||
      userRole === UserAssignmentRole.SUPERVISOR,
    [userRole]
  );

  const handleMeetingWithEmptyNode = () => {
    changeSelectedMeeting(
      meetingsWithEmptyNotes[meetingsWithEmptyNotes.length - 1]
    );
    handleContinue();
  };

  const meetingsWithEmptyNotes = useMemo(() => {
    return meetings.filter((m) => !m.planning_notes || !m.meeting_notes);
  }, [meetings]);

  if (loadingGetMeetings) return <LoadingIndicator />;

  return (
    <div style={{ margin: "10px" }}>
      {!isTeacher && (
        <div
          className="mb-3"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {meetingsWithEmptyNotes.length ? (
            <div className="d-inline-flex align-items-center ">
              <h3 className="font-weight-bold">
                You prepared to meet with{" "}
                <InterventionGroupTeacher
                  interventionGroup={interventionGroup}
                />
              </h3>
              <button
                className="blueBtnSm mx-3"
                onClick={handleMeetingWithEmptyNode}
              >
                Meet now!
              </button>
            </div>
          ) : (
            <button
              className="blueBtnSm btnActionRight"
              onClick={handleContinue}
            >
              Start a new meeting
            </button>
          )}
        </div>
      )}

      <MeetingsTable isTeacher={isTeacher} />
    </div>
  );
};

const mapStateToProps = ({
  cases,
  meetingModuleReducer,
}: ApplicationState): StateProps => {
  return {
    meetings: meetingModuleReducer.meetings,
    interventionGroup: cases.selectedInterventionGroup,
    isLoading: {
      getMeetings: meetingModuleReducer.isLoading.getMeetings,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      changeSelectedMeeting: changeSelectedMeeting,
      getMeetings: getMeetings,
      push: push,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterventionNotesTab);
