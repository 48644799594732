import { ActionType } from "typesafe-actions";
import * as dataFilterActions from "./actions";
import { DataFilterActionTypes, DataFilterState } from "./types";
import { LocalStorageKeys, getBooleanFromLocalStorage } from "../../utils/LocalStorageUtils";

export type DataFilterAction = ActionType<typeof dataFilterActions>;

const initialState: DataFilterState = {
  groupSearch: "",
  dataGradesFilter: [],
  dataSchoolsFilter: [],
  dataTeachersFilter: [],
  teacherAssignmentIdsFilter: [],
  recommendGradesFilter: [],
  recommendTeachersFilter: [],
  recommendClassFilter: [],
  focusIdsFilter: [],
  recommendSchoolsFilter: [],
  showDiagnosedStudentFilter: !getBooleanFromLocalStorage(
    LocalStorageKeys.ShowDiagnosedStudents
  ),
  raceFilter: [],
  raceOtherFilter: [],
  genderFilter: [],
  specialEducationFilter: undefined,
  ellFilter: undefined,
  sldFilter: undefined,
  freeOrReducedLunchFilter: undefined,
  isDataFilterApplied: false,
};

const reducer = (
  state: DataFilterState = initialState,
  action: DataFilterAction
): DataFilterState => {
  switch (action.type) {
    case DataFilterActionTypes.CHANGE_DATA_GRADES_FILTER:
      return {
        ...state,
        dataGradesFilter: action.payload.dataGradesFilter || [],
        recommendGradesFilter: action.payload.dataGradesFilter || [],
      };
    case DataFilterActionTypes.CHANGE_DATA_SCHOOLS_FILTER:
      return {
        ...state,
        dataSchoolsFilter: action.payload.dataSchoolsFilter || [],
      };
    case DataFilterActionTypes.CHANGE_DATA_TEACHERS_FILTER:
      return {
        ...state,
        dataTeachersFilter: action.payload.dataTeachersFilter || [],
        recommendTeachersFilter: action.payload.dataTeachersFilter || [],
      };
    case DataFilterActionTypes.CHANGE_CLASS_FILTER:
      return {
        ...state,
        classIdFilter: action.payload.classIdFilter,
      };
    case DataFilterActionTypes.CHANGE_ASSIGNMENT_TEACHER_FILTER:
      return {
        ...state,
        teacherAssignmentIdsFilter:
          action.payload.teacherAssignmentIdsFilter || [],
      };
    case DataFilterActionTypes.CHANGE_DAY_OF_WEEK_FILTER:
      return {
        ...state,
        dayOfWeekFilter: action.payload.dayOfWeekFilter,
      };
    case DataFilterActionTypes.CHANGE_MAX_SIZE_TIER_TWO_FILTER:
      return {
        ...state,
        maxSizeTierTwo: action.payload.maxSizeTierTwo,
      };
    case DataFilterActionTypes.CHANGE_MAX_SIZE_TIER_THREE_FILTER:
      return {
        ...state,
        maxSizeTierThree: action.payload.maxSizeTierThree,
      };
    case DataFilterActionTypes.CHANGE_GROUP_BY_GRADE_FILTER:
      return {
        ...state,
        groupByGrade: action.payload.groupByGrade,
      };
    case DataFilterActionTypes.CHANGE_GROUP_BY_CLASS_FILTER:
      return {
        ...state,
        groupByClass: action.payload.groupByClass,
      };
    case DataFilterActionTypes.CHANGE_FOCUS_FILTER:
      return {
        ...state,
        focusIdsFilter: action.payload.focusIdsFilter || [],
      };
    case DataFilterActionTypes.CHANGE_GROUP_SEARCH:
      return {
        ...state,
        groupSearch: action.payload.groupSearch,
      };
    case DataFilterActionTypes.CHANGE_GROUP_SORT:
      return {
        ...state,
        groupSort: action.payload.groupSort,
      };
    case DataFilterActionTypes.CHANGE_RECOMMEND_GRADES_FILTER:
      return {
        ...state,
        recommendGradesFilter: action.payload.gradesFilter || [],
      };
    case DataFilterActionTypes.CHANGE_RECOMMEND_TEACHERS_FILTER:
      return {
        ...state,
        recommendTeachersFilter: action.payload.teachersFilter || [],
      };
    case DataFilterActionTypes.CHANGE_RECOMMEND_CLASS_FILTER:
      return {
        ...state,
        recommendClassFilter: action.payload.classFilter || [],
      };
    case DataFilterActionTypes.CHANGE_RECOMMEND_SCHOOLS_FILTER:
      return {
        ...state,
        recommendSchoolsFilter: action.payload.schoolsFilter || [],
      };
    case DataFilterActionTypes.SET_RECOMMEND_FILTERS_FROM_DATA_FILTERS:
      return {
        ...state,
        recommendGradesFilter: [...state.dataGradesFilter],
        recommendTeachersFilter: [...state.dataTeachersFilter],
      };
    case DataFilterActionTypes.CHANGE_SHOW_ARCHIVED_GROUPS:
      return {
        ...state,
        showArchivedGroups: action.payload.show,
      };

    case DataFilterActionTypes.CHANGE_EXITED_STUDENTS_GROUPS:
      return {
        ...state,
        exitedStudentsGroup: action.payload.show,
      };
    
    case DataFilterActionTypes.CHANGE_RACE_FILTER:
      return {
        ...state,
        raceFilter: action.payload.raceFilter!,
      };

    case DataFilterActionTypes.CHANGE_RACE_OTHER_FILTER:
      return {
        ...state,
        raceOtherFilter: action.payload.raceOtherFilter!,
      };
      
    case DataFilterActionTypes.CHANGE_GENDER_FILTER:
      return {
        ...state,
        genderFilter: action.payload.genderFilter!,
      };

    case DataFilterActionTypes.CHANGE_SPACIAL_EDUCATION_FILTER:
      return {
        ...state,
        specialEducationFilter: action.payload.specialEducationFilter,
      };

      case DataFilterActionTypes.CHANGE_SLD_FILTER:
      return {
        ...state,
        sldFilter: action.payload.sldFilter,
      };

      case DataFilterActionTypes.CHANGE_ELL_FILTER:
      return {
        ...state,
        ellFilter: action.payload.ellFilter,
      };

      case DataFilterActionTypes.CHANGE_FREE_REDUCED_LUNCH_FILTER:
      return {
        ...state,
        freeOrReducedLunchFilter: action.payload.freeOrReducedLunchFilter,
      };
      case DataFilterActionTypes.CHANGE_SHOW_DIAGNOSED_STUDENT_FILTER: {
        localStorage.setItem(
          LocalStorageKeys.ShowDiagnosedStudents,
          JSON.stringify(!action.payload.showDiagnosedStudentFilter)
        );
        return {
          ...state,
          showDiagnosedStudentFilter: action.payload.showDiagnosedStudentFilter,
        };
      }
      case DataFilterActionTypes.DATA_FILTER_APPLIED:
      return {
        ...state,
        isDataFilterApplied: action.payload.isDataFilterApplied,
      };
    default:
      return state;
  }
};

export { reducer as dataFilterReducer };
