import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Content from "./Content";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import {
  hideClassWideNeedsModal,
  hidePreClassWideNeedsModal,
  setNoClassWideNeeds,
} from "../../../../../../store/onboarding/actions";

import BehaviourContent from "./BehaviourContent";

type OwnProps = {};

type Props = OwnProps;

const ClassWideNeedsModal: FunctionComponent<Props> = (props) => {
    const [isShowBehaviour, setIsShowBehaiour] = useState(false);
    const [isPrimaryScreening, setIsPrimaryScreening] = useState(false);
    const [isBehaviourScreening, setIsBehaviourScreening] = useState(false);
    

  const dispatch = useDispatch();
  const showModal = useSelector(
    (s: ApplicationState) => s.onboarding.modalsState.showClassWideNeedsModal
  );

  const evidencePeriodData = useSelector(
    (s: ApplicationState) => s.onboarding.evidencePeriodData
    );
    
    
    useMemo(() => {
      if(showModal) {
        if(evidencePeriodData) {
          for (let i = 0; i < evidencePeriodData!.evidence_column_groups!.length; i++) {
            const element = evidencePeriodData?.evidence_column_groups[i];
            if(element.is_primary_screening) {
              setIsPrimaryScreening(element.is_primary_screening);
            }
            if(element.is_behaviour_primary_screening) {
              setIsBehaviourScreening(element.is_behaviour_primary_screening); 
            }
          }
         
        }
      }
    },[evidencePeriodData, showModal, isShowBehaviour])

  useEffect(() => {
      if((isPrimaryScreening == false) && (isBehaviourScreening == false)) {
         dispatch(setNoClassWideNeeds(true));
      }
      if(isBehaviourScreening) {
        setIsShowBehaiour(isBehaviourScreening);
      } 
      
      if(isPrimaryScreening){
        setIsShowBehaiour(!isPrimaryScreening);
      }
  },[])

  const handleHide = () => {
    for (let i = 0; i < evidencePeriodData!.evidence_column_groups.length; i++) {
      const ecg = evidencePeriodData?.evidence_column_groups[i];
      for (let j = 0; j < ecg!.evidence_columns.length; j++) {
        const ec = ecg!.evidence_columns[j];
        for (let k = 0; k < ec.student_entries.length; k++) {
          for (let l = 0; l < evidencePeriodData!.student_rows.length; l++) {
            let studentRow = evidencePeriodData!.student_rows[l];
            if(studentRow.student.id === ec.student_entries[k].student_id) {
              for (let m = 0; m < studentRow.student.teachers!.length; m++) {
                let teacher = studentRow.student.teachers![m];
                if(teacher.classes?.some((classes) => classes.name.toLowerCase().includes('unassigned'))) {
                  let classes = teacher.classes?.filter((classes) => !classes.name.toLowerCase().includes('unassigned'));
                  if(!classes.length) {
                    teacher.classes = null;
                  }
                }
                
              }
            }
            
          }

        }
      }
    }
    dispatch(hideClassWideNeedsModal());
    dispatch(hidePreClassWideNeedsModal());
  };

  
  //console.log(isBehaviourScreening, isPrimaryScreening)
  return (
    <Modal
      show={showModal}
      onHide={handleHide}
      animation={false}
      size="lg"
      backdrop="static"
      keyboard={false}
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>View Class-Wide Needs for Teachers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isBehaviourScreening && isPrimaryScreening && 
        <div className="row col-12 text-right">
          <Form.Check 
            type="switch"
            id="behaviourContent"
            label="Is Show Behavior"
            checked={isShowBehaviour ?? false}
            onChange={(e) => {
              const checked = e.target.checked;
              if(checked){
                setIsShowBehaiour(true);
              } else {
                setIsShowBehaiour(false)
              }
              //console.log(isShowBehaviour)
              // setIsBehaviourScreening(checked);
              // setIsPrimaryScreening(!checked);
            }}
          />
        </div>
        }
        {showModal ? (isShowBehaviour ? <BehaviourContent /> : <Content />) : ''}
        
      </Modal.Body>
    </Modal>
  );
};

export default ClassWideNeedsModal;
