import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { hideGroupingStudentInfoPanel } from "../../../../../../store/onboarding/cases/actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

type StateProps = {
  showPanel?: boolean;
};

type DispatchProps = {
  hideGroupingStudentInfoPanel: () => any;
};

type Props = StateProps & DispatchProps;

const GroupingStudentInfo: FunctionComponent<Props> = (props) => {
  return props.showPanel ? (
    <div className="welcomeContainer mb-3">
      <div className="d-flex">
        <span className="mr-3">
          <FontAwesomeIcon
            className="marigoldCircleIcon"
            icon={faQuestionCircle}
            size="4x"
          />
        </span>
        <div>
          <h3>
            Now that you have uploaded student data, go ahead and group
            students.
          </h3>
          <h4>
            <strong>HINT</strong>: Try the benchmarking feature to quickly group
            students! (
            {
              //todo add functionality to open a video when it's ready.
            }
            <Link to="/tutorials/videos" className="marigoldLabel">
              Show me how
            </Link>
            )
          </h4>
        </div>
        <div className="ml-auto">
          <button
            className="marigoldBtnMd text-nowrap"
            onClick={() => props.hideGroupingStudentInfoPanel()}
          >
            Got it!
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

const mapStateToProps = ({
  cases,
  onboarding,
}: ApplicationState): StateProps => {
  return {
    showPanel:
      cases.showGroupingStudentInfo &&
      !!onboarding.evidencePeriodData?.evidence_column_groups.length,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hideGroupingStudentInfoPanel: hideGroupingStudentInfoPanel,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupingStudentInfo);
