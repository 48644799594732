import { ActionType } from "typesafe-actions";
import * as dataSortActions from "./actions";
import { DataSortActionTypes, DataSortState } from "./types";

export type DataFilterAction = ActionType<typeof dataSortActions>;

const initialState: DataSortState = {
  allColoumnFields: [],
  activeSort: [],
  isResetColumnSort: false,
  isColumnSortApplied: false,
  showModal: false
};

const reducer = (
  state: DataSortState = initialState,
  action: DataFilterAction
): DataSortState => {
  switch (action.type) {
    case DataSortActionTypes.SHOW_MODAL:
      return {
        ...state,
        showModal: true,
      };
    case DataSortActionTypes.CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
      };
    case DataSortActionTypes.CHANGE_SORTING_DATA:
      return {
        ...state,
        activeSort: [...action?.payload?.activeSort],
      };
    case DataSortActionTypes.RESET_COLUMN_SORT:
      return {
        ...state,
        isResetColumnSort: action?.payload?.isResetColumnSort,
      };
    case DataSortActionTypes.COLUMN_SORT_APPLIED:
      return {
        ...state,
        isColumnSortApplied: action?.payload?.isColumnSortApplied,
      };
    case DataSortActionTypes.CHANGE_ALL_FIELDS:
      return {
        ...state,
        allColoumnFields: [...action.payload?.allColoumnFields],
      };
    default:
      return state;
  }
};

export { reducer as dataSortReducer };
