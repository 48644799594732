import React, { FunctionComponent, useMemo, useState } from "react";
import { GroupWorkSpace } from "../WorkspacesContainer";
import WorkspaceHeader from "../WorkspaceHeader";
import LessonNotesContainer from "./LessonNotesContainer";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InterventionGroup, LessonNotes } from "../../../../../../../../../store/onboarding/cases/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../../../store";
import useInterventionGroupAccess from "../../../../../../../../../utils/hooks/useInterventionGroupAccess";

type Props = {
  interventionGroup: InterventionGroup;
  expanded?: boolean;
  workspace: GroupWorkSpace;
  setExpandedWorkSpace: React.Dispatch<
    React.SetStateAction<GroupWorkSpace | undefined>
  >;
};

const LessonNotesWorkspace: FunctionComponent<Props> = (props) => {
  const { expanded, workspace, setExpandedWorkSpace, interventionGroup } = props;

  const [showUpsertForm, setShowUpsertForm] = useState<boolean>(false);
  const [selectedLessonNotes, setSelectedLessonNotes] = useState<
    LessonNotes | undefined
  >();
  const [lessonNoteSearchStr, setLessonNoteSearchStr] = useState("");

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(interventionGroup.id);

  const isStudentDetail =  useSelector(
    (s: ApplicationState) => s.cases.isStudentDetail
  );

  const isGroupDetail =  useSelector(
    (s: ApplicationState) => s.cases.isGroupDetail
  );

  const onLessonNoteFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLessonNoteSearchStr(value);
  };

  return (
    <div>
      <WorkspaceHeader
        expanded={expanded}
        workspace={workspace}
        setExpandedWorkSpace={setExpandedWorkSpace}
        header={
          <div>
            My Notes & Collaboration{" "}
            {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess && !showUpsertForm ? 
              <span
                onClick={() => {
                  setSelectedLessonNotes(undefined);
                  setShowUpsertForm(true);
                }}
              >
                <FontAwesomeIcon title="Add New Notes" icon={faPlusCircle} />
              </span>
              :
              ''
            }  
            {!showUpsertForm ? <span className="ml-2">
              <input
                type="text"
                placeholder={`Search...`}
                value={lessonNoteSearchStr || ""}
                onChange={onLessonNoteFilterChange}
                className="stdInput"
              />       
            </span> : ''}
          </div>
        }
      />
      <div className="workspace-body">
        <LessonNotesContainer
          showUpsertForm={showUpsertForm}
          setShowUpsertForm={setShowUpsertForm}
          selectedLessonNotes={selectedLessonNotes}
          setSelectedLessonNotes={setSelectedLessonNotes}
          isStudentDetail={isStudentDetail}
          isGroupDetail={isGroupDetail}
          lessonNoteSearchStr={lessonNoteSearchStr}
        />
      </div>
    </div>
  );
};

export default LessonNotesWorkspace;
