import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Overlay, Popover, Spinner } from "react-bootstrap";
import { bindActionCreators, Dispatch } from "redux";
import { addStudentBehaviorsToDataPeriod } from "../../../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import {
  CategoryBehavior,
  Student,
  StudentEntry,
} from "../../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../../store";
import BehaviorTableContainer from "../../../../third-step/set-goal-modal/subpages/do/BehaviorTableContainer";
import CustomBehaviorInputForm from "./CustomBehaviorInputForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";
import { InterventionGroup } from "../../../../../../../store/onboarding/cases/types";

type StateProps = {
  staticDataCategoryDomains: Array<CategoryBehavior>;
  selectedStudentEntry?: StudentEntry;
  selectedStudent?: Student;
  interventionGroup?: InterventionGroup;
  isLoading: {
    getStaticDataCategoryDomains: boolean;
    addStudentBehaviorsToDataPeriod?: boolean;
    addCustomBehavior?: boolean;
  };
  errors: {
    getStaticDataCategoryDomains?: string;
  };
};

type DispatchProps = {
  addStudentBehaviorsToDataPeriod: (
    behaviors: Array<CategoryBehavior>,
    studentId: number,
    selectedDataPeriodId?: number
  ) => any;
};

type OwnProps = {
  isForAll?: boolean;
  showDropdown: boolean;
  refObject: React.MutableRefObject<any>;
  setShowDropdown: (show: boolean) => any;
};

type Props = OwnProps & StateProps & DispatchProps;

const HIGHLIGHT_FADE_OUT_MS = 1000;
const HIGHLIGHT_NEWLY_FADE_OUT_MS = 10000;

const BehaviorsDropDown: FunctionComponent<Props> = ({
  staticDataCategoryDomains,
  interventionGroup,
  selectedStudentEntry,
  selectedStudent,
  isForAll,
  showDropdown,
  refObject,
  setShowDropdown,
  addStudentBehaviorsToDataPeriod,
  isLoading,
}) => {
  const [selectedBehaviors, setSelectedBehaviors] = useState<
    Array<CategoryBehavior>
  >([]);

  const [
    newlyCreatedCustomBehaviorsIds,
    setNewlyCreatedCustomBehaviorsIds,
  ] = useState<number[]>([]);

  useEffect(() => {
    if (showDropdown && isForAll) {
      setSelectedBehaviors([]);
    }
  }, [showDropdown, isForAll]);

  useEffect(() => {
    if (!isForAll) {
      setSelectedBehaviors(selectedStudentEntry?.behavior_tags ?? []);
    }
  }, [isForAll, selectedStudentEntry, selectedStudent]);

  const studentId = useMemo(() => {
    return selectedStudentEntry
      ? selectedStudentEntry.student_id
      : selectedStudent?.id;
  }, [selectedStudentEntry, selectedStudent]);

  const [highlightedBehaviorsIds, setHighlightedBehaviorsIds] = useState<
    number[]
  >([]);

  const highlightBehavior = (targetId: number) => {
    setHighlightedBehaviorsIds((ids) => [...ids, targetId]);
    setTimeout(
      () =>
        setHighlightedBehaviorsIds((ids) =>
          ids.filter((id) => id !== targetId)
        ),
      HIGHLIGHT_FADE_OUT_MS
    );
  };

  const handleHighlightCustomBehavior = (targetId: number) => {
    highlightBehavior(targetId);
    setNewlyCreatedCustomBehaviorsIds((ids) => [...ids, targetId]);
    setTimeout(
      () =>
        setNewlyCreatedCustomBehaviorsIds((ids) =>
          ids.filter((id) => id !== targetId)
        ),
      HIGHLIGHT_NEWLY_FADE_OUT_MS
    );
  };

  const handleBehaviorClick = (target: CategoryBehavior) => {
    if (
      !isLoading.addStudentBehaviorsToDataPeriod &&
      !isLoading.addCustomBehavior
    ) {
      const selectedBehaviorIds = selectedBehaviors.map(
        (behavior) => behavior.id
      );

      let behaviors = selectedBehaviors;
      const isRemoving = selectedBehaviorIds.includes(target.id);
      if (isRemoving) {
        behaviors = behaviors.filter((b) => b.id !== target.id!);
      } else {
        behaviors.push(target);
      }
      const studentIds = isForAll
        ? interventionGroup?.students.map((st) => st.id) ?? []
        : [studentId];

      Promise.all(
        studentIds
          .filter((studentId) => studentId != null)
          .map((studentId) =>
            addStudentBehaviorsToDataPeriod(behaviors, studentId!)
          )
      ).then(
        () => {
          if (!isRemoving) {
            highlightBehavior(target.id!);
          }
          setSelectedBehaviors(behaviors);
          toastr.success("Saved!", "", { timeOut: 3000 });
        },
        (error: string) => toastr.error("Failed to update behaviors", error)
      );
    }
  };

  const behaviorsList = useMemo(() => {
    if (selectedBehaviors.length) {
      return (
        <div className="behaviorsArea">
          {selectedBehaviors.map((behavior, index) => (
            <div
              className={`fileCell ${
                highlightedBehaviorsIds.includes(behavior.id!) ? "active" : ""
              }`}
              key={index}
            >
              <div className="fileCellName"> {behavior.name}</div>
              <div
                className="fileCellRemove"
                onClick={() => handleBehaviorClick(behavior)}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  size="1x"
                  style={{ color: "#ffffff" }}
                />
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return <h3 className="font-italic">No behaviors Selected</h3>;
    }
  }, [selectedBehaviors, highlightedBehaviorsIds, staticDataCategoryDomains]);

  return (
    <Overlay
      placement="auto"
      flip
      rootClose={true}
      onHide={() => setShowDropdown(false)}
      show={showDropdown}
      target={refObject.current}
    >
      <Popover id="popover-behaviors" style={{ width: "100%" }}>
        <Popover.Content>
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <h3>Behaviors</h3>
              {(isLoading.addStudentBehaviorsToDataPeriod ||
                isLoading.addCustomBehavior) && (
                <Spinner animation="border" size="sm" />
              )}
            </div>

            <hr className="my-1" />
            {behaviorsList}
            <hr className="my-1" style={{ borderTop: "1px dashed #e5e5e5" }} />
            <BehaviorTableContainer
              newlyCreatedCustomBehaviorsIds={newlyCreatedCustomBehaviorsIds}
              containerStyle={{ maxHeight: 250, overflowY: "auto" }}
              selectedBehaviors={selectedBehaviors}
              displayNegative
              displayPositive
              displayCustom
              onBehaviorClick={handleBehaviorClick}
            />
            <hr className="my-1" style={{ borderTop: "1px dashed #e5e5e5" }} />
            <CustomBehaviorInputForm
              isForAll={isForAll}
              studentIds={
                isForAll
                  ? interventionGroup?.students
                      .filter((st) => st.id != null)
                      .map((st) => st.id!) ?? []
                  : []
              }
              selectedBehaviors={selectedBehaviors}
              studentId={studentId}
              onHighlightBehavior={handleHighlightCustomBehavior}
            />
          </div>
        </Popover.Content>
      </Popover>
    </Overlay>
  );
};

const mapStateToProps = ({
  cases,
  onboarding,
}: ApplicationState): StateProps => {
  return {
    staticDataCategoryDomains: onboarding.staticDataCategoryDomains,
    selectedStudentEntry: onboarding.selectedStudentEntry,
    selectedStudent: onboarding.selectedStudent,
    interventionGroup: cases.selectedInterventionGroup,
    isLoading: {
      getStaticDataCategoryDomains:
        onboarding.isLoading.getStaticDataCategoryDomains,
      addStudentBehaviorsToDataPeriod:
        onboarding.isLoading.addStudentBehaviorsToDataPeriod,
      addCustomBehavior: onboarding.isLoading.addCustomBehavior,
    },
    errors: {
      getStaticDataCategoryDomains:
        onboarding.errors.getStaticDataCategoryDomains,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      addStudentBehaviorsToDataPeriod: addStudentBehaviorsToDataPeriod,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BehaviorsDropDown);
