import { ApplicationState } from "../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  changeSelectedTeacher,
  getSchools,
  getTeachers,
  openMergeTeachersModal,
  openTeacherDetailsModal,
  updateTeacher,
} from "../../../../store/onboarding/actions";
import { connect } from "react-redux";
import TeachersTable from "../../../../components/common/onboarding/second-step/teacher-details/TeachersTable";
import {
  School,
  RenewedTeacherInfo,
  TeacherInfo,
} from "../../../../store/onboarding/types";

type PropsFromState = {
  schools: Array<School>;
  teachersRoster: Array<TeacherInfo>;
  selectedTeacher?: TeacherInfo;
};

type DispatchProps = {
  getSchools: () => any;
  getTeachers: () => any;
  updateTeacher: (id: number, updatedInfo: RenewedTeacherInfo) => any;
  setSelectedTeacher: (selectedTeacher?: TeacherInfo) => any;
  openTeacherDetailsModal: () => any;
  openMergeTeachersModal: () => any;
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    schools: onboarding.schools,
    teachersRoster: onboarding.teachersRoster,
    selectedTeacher: onboarding.selectedTeacher,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getTeachers: getTeachers,
      getSchools: getSchools,
      updateTeacher: updateTeacher,
      setSelectedTeacher: changeSelectedTeacher,
      openTeacherDetailsModal: openTeacherDetailsModal,
      openMergeTeachersModal: openMergeTeachersModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TeachersTable);
