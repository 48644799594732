import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { openOverviewModel } from "../../../../../../store/groups/actions";
import { openInterventionStatsModal } from "../../../../../../store/onboarding/actions";
import useUserRole from "../../../../../../utils/hooks/useUserRole";


type OwnProps = {};

type Props = OwnProps;

const ShowInterventionStatisticsPerTeachersButton: FunctionComponent<Props> = (
  props
) => {
  const dispatch = useDispatch();

  const { isCoach, isTeacher } = useUserRole();

  if (!(isCoach || isTeacher)) {
    return null;
  }

  return (
    <button
      className="blueBtnSm"
      onClick={() => {
        
        dispatch(openInterventionStatsModal(undefined));
        dispatch(openOverviewModel('all_group'));
      }}
    >
      Overview of all Groups
    </button>
  );
};

export default ShowInterventionStatisticsPerTeachersButton;
