import React from "react";
import Table from "react-bootstrap/Table";

const Manual = () => {
  return (
    <>
      <h4 className="text-center font-weight-bold">Here's how it works:</h4>
      <Table borderless>
        <tbody>
          <tr>
            <td>
              <div className="numberCircle">
                <h3>1</h3>
              </div>
            </td>
            <td>
              <p>First, name the data source.</p>
              <h4 className="font-weight-bold font-italic">
                e.g., "BehaviorAssessment System of Children"
              </h4>
            </td>
          </tr>
          <tr>
            <td>
              <div className="numberCircle">
                <h3>2</h3>
              </div>
            </td>
            <td>
              <p>
                Then tell us which columns includes the student IDs and names.
              </p>
              <h4 className="font-weight-bold font-italic">
                e.g., "student_id", "student_name"
              </h4>
            </td>
          </tr>
          <tr>
            <td>
              <div className="numberCircle">
                <h3>3</h3>
              </div>
            </td>
            <td>
              <p>
                Finally, specify which columns contain the data and indicate the
                data type.
              </p>
              <h4 className="font-weight-bold font-italic">
                e.g., "Adaptability is a number from 20-80", "Aggression is a
                number from 20-80"
              </h4>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
export default Manual;
