import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

type Props = {
  id: string;
  text: string;
};

const InfoButton = (props: Props) => {
  return (
    <OverlayTrigger
      overlay={
        <Popover id={props.id}>
          <Popover.Content>{props.text}</Popover.Content>
        </Popover>
      }
    >
      <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "5px" }} />
    </OverlayTrigger>
  );
};

export default InfoButton;
