import { Col, Row } from "react-bootstrap";
import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullseye,
  faChartLine,
  faTable,
  faTasks,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

library.add(faUsers, faBullseye, faTable, faTasks, faChartLine);

type Props = { onClick?: () => void };

const FirstTab = (props: Props) => {
  return (
    <div className="firstTabContainer">
      <Row className="mb-4">
        <Col>
          <h1 className="font-weight-bold text-center">
            ReadyCoach makes coaching more efficient and rewarding.
          </h1>
          <h3 className="font-weight-bold text-center">
            We provide you with powerful tools that improve the coaching
            process.
          </h3>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <div className="firstTabCircleItem">
            <FontAwesomeIcon icon="users" size="3x" />
            <h2>Build Relationships</h2>
            <p>Includes coach-educator relationship strategies</p>
          </div>
        </Col>
        <Col lg={4}>
          <div className="firstTabCircleItem">
            <FontAwesomeIcon icon="bullseye" size="3x" />
            <h2>Set Goals</h2>
            <p>Includes tools for targeting and assessing behaviors</p>
          </div>
        </Col>
        <Col lg={4}>
          <div className="firstTabCircleItem">
            <FontAwesomeIcon icon="table" size="3x" />
            <h2>Synthesize Data</h2>
            <p>
              Includes tools for data collection, progress monitoring, data
              visualization, and reporting
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 4, offset: 2 }}>
          <div className="firstTabCircleItem">
            <FontAwesomeIcon icon="tasks" size="3x" />
            <h2>Find Interventions</h2>
            <p>
              Includes evidence-based interventions matched to students’ needs
            </p>
          </div>
        </Col>
        <Col lg={4}>
          <div className="firstTabCircleItem">
            <FontAwesomeIcon icon="chart-line" size="3x" />
            <h2>Develop Professionally</h2>
            <p>Includes our reward program and assessments</p>
          </div>
        </Col>
      </Row>
      {/* <Row className="text-center mt-4">
        <Col>
          <Button bsPrefix="b" className="blueBtnLg" onClick={props.onClick}>
            Learn about how it works
          </Button>
        </Col>
      </Row> */}
    </div>
  );
};

export default FirstTab;
