import React, { FunctionComponent } from "react";
import { Modal } from "react-bootstrap";

type OwnProps = {
  showModal?: boolean;
  onHide: () => any;
};

type Props = OwnProps;

const TrackInterventionModal: FunctionComponent<Props> = (props) => {
  return (
    <Modal
      show={props.showModal}
      animation={false}
      size="sm"
      backdropClassName="customDarkModalBackdrop in"
      onHide={props.onHide}
    >
      <Modal.Header closeButton className={"purpleModalHeader"}>
        <Modal.Title className="modalTitle">Track Intervention</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>
            Once you have grouped students and set their goals, the next step is
            to start tracking your interventions.
          </strong>
        </p>
        <p>
          Tracking your intervention means providing feedback to teachers,
          logging student goal progress, and logging the fidelity of
          intervention implementation.
        </p>
        <p>
          Each of these actions is available on the{" "}
          <strong>Active Groups</strong> page.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default TrackInterventionModal;
