import React, { FunctionComponent, useMemo } from "react";
import {
  GrammarType,
  SpellingTestAnswer,
  SpellingTestQuestion,
} from "../../../../store/spelling-tests/types";
import CommonCell from "./cells/CommonCell";
import EmptyCell from "./cells/EmptyCell";
import VcePatternCell from "./cells/VcePatternCell";
import SyllablesCell from "./cells/SyllablesCell";

type Props = {
  grammarType: GrammarType;
  question: SpellingTestQuestion;
  answer?: SpellingTestAnswer;
};

const GrammarPart: FunctionComponent<Props> = (props) => {
  const { grammarType, question, answer } = props;

  const correctPart = useMemo(
    () =>
      question.grammar_parts.find((gp) => gp.type === grammarType)
        ?.correct_part,
    [question.grammar_parts, grammarType]
  );

  if (!correctPart) {
    return <EmptyCell />;
  }

  if (grammarType === GrammarType.VcePattern) {
    return (
      <VcePatternCell
        correctPart={correctPart}
        question={question}
        answer={answer}
      />
    );
  }

  if (grammarType === GrammarType.Syllables) {
    return (
      <SyllablesCell
        correctPart={correctPart}
        question={question}
        answer={answer}
      />
    );
  }

  return (
    <CommonCell
      correctPart={correctPart}
      grammarType={grammarType}
      question={question}
      answer={answer}
    />
  );
};

export default GrammarPart;
