import React, { FunctionComponent, useMemo } from "react";
import { Student } from "../../../../store/onboarding/types";
import { getFullName } from "../../../../utils/NamesUtils";
import {
  changeSelectedStudent,
  deleteSpellingTest,
} from "../../../../store/spelling-tests/actions";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { SpellingTestGroup } from "../../../../store/spelling-tests/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { showConfirmDialog } from "../../../../store/confirm-dialog/actions";

type Props = {
  student: Student;
  group: SpellingTestGroup;
};

const SpellingTestGroupStudentRow: FunctionComponent<Props> = (props) => {
  const { student, group } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const test = useMemo(() => {
    return group.tests.find((t) => t.student.id === student.id);
  }, [group.tests, student.id]);

  const handleSpellingTestShow = (student: Student) => () => {
    if (test) {
      history.push(`/spelling-test/groups/${group.id}/tests/${test.id}`);
    } else {
      dispatch(changeSelectedStudent(student));
      history.push(`/spelling-test/groups/${group.id}/new-test`);
    }
  };

  const btnTitle = useMemo(() => {
    if (!test) {
      return "Start";
    }
    if (
      !test.answers.length ||
      test.answers.some((a) => a.student_answer === undefined)
    ) {
      return "Continue";
    } else {
      return "View / Edit";
    }
  }, [test]);

  const handleSpellingTestDelete = () => {
    if (test) {
      const onConfirm = () => dispatch(deleteSpellingTest(test.id, group.id));
      dispatch(showConfirmDialog({ onConfirm: onConfirm }));
    }
  };

  return (
    <div
      key={`student_${student.id}`}
      className="d-flex justify-content-between align-items-center"
    >
      <h4>{getFullName(student)}</h4>
      <div>
        <button
          className="blueBtnSm"
          style={{ minWidth: "7rem" }}
          onClick={handleSpellingTestShow(student)}
        >
          {btnTitle}
        </button>

        {test && (
          <span
            className="ml-4 mr-1 removeIcon"
            onClick={handleSpellingTestDelete}
          >
            <FontAwesomeIcon icon={faTimesCircle} size="lg" />
          </span>
        )}
      </div>
    </div>
  );
};

export default SpellingTestGroupStudentRow;
