import React, { FunctionComponent, SyntheticEvent, useMemo } from "react";
import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  changeSelectedTeacher,
  openTeacherDetailsModal,
} from "../../../../../../store/onboarding/actions";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useDispatch } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { InviteStatuses, School, TeacherInfo } from "../../../../../../store/onboarding/types";
import {
  getFullName,
  getInviteStatusDisplay,
  getUserAssigmentRoleDisplayedName,
} from "../../../../../../utils/NamesUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { UserAssignmentRole } from "../../../../../../store/auth/types";
import UserStatusButton from "./UserStatusButton";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ResendInviteButton from "./ResendInviteButton";

type StateProps = {
  schoolFilter?: School;
  roleFilter?: UserAssignmentRole;
  searchFilter?: string;
  staffRoster: Array<TeacherInfo>;
  selectedTeacher?: TeacherInfo;
};
type DispatchProps = {
  setSelectedTeacher: (selectedTeacher?: TeacherInfo) => any;
  openTeacherDetailsModal: () => any;
};
type OwnProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const StaffTable: FunctionComponent<Props> = ({
  roleFilter,
  searchFilter,
  schoolFilter,
  staffRoster,
  setSelectedTeacher,
  openTeacherDetailsModal,
}) => {
  const columns = useMemo(
    () => [
      {
        dataField: "name",
        text: "Name",
        sort: true,
        headerStyle: { width: "20%" }
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
        headerStyle: { width: "20%" }
      },
      {
        dataField: "role",
        text: "Role",
        sort: true,
        headerStyle: { width: "16%" }
      },
      {
        dataField: "schools",
        text: "Schools",
        sort: true,
        headerStyle: { width: "22%" }
      },
      {
        dataField: "inviteStatus",
        text: "Status",
        sort: true,
        headerStyle: { width: "16%" }
      },
      {
        dataField: "actions",
        text: "Actions",
        headerStyle: { width: "6%" }
      },
    ],
    []
  );

  const options = useMemo(
    () => ({
      sizePerPageList: [
        {
          text: "20",
          value: 20,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
    }),
    []
  );

  const defaultSorted: [{ dataField: string; order: SortOrder }] = useMemo(
    () => [
      {
        dataField: "educator",
        order: "asc" as SortOrder,
      },
    ],
    []
  );

  const rowEvents = useMemo(
    () => ({
      onClick: (e: SyntheticEvent, row: any, rowIndex: number) => {
        const selectedTeacher = staffRoster.find(
          (teacher) => teacher.user.id === row.id
        );
        setSelectedTeacher(selectedTeacher!);
        openTeacherDetailsModal();
      },
    }),
    [staffRoster]
  );

  const filterStaffRosterBySchool = (employee: TeacherInfo) =>
    !schoolFilter ||
    employee.user.profile?.current_assignment?.schools?.some(
      (school) => school.id === schoolFilter?.id
    );

  const filterStaffRosterByRole = (employee: TeacherInfo) =>
    !roleFilter ||
    employee.user.profile?.current_assignment?.role === roleFilter;

  const filterStaffRosterBySearch = (employee: TeacherInfo) => 
    employee.user.first_name.toLowerCase().includes(searchFilter!.toLowerCase()) ||
    employee.user.last_name.toLowerCase().includes(searchFilter!.toLowerCase()) ||
    getFullName(employee.user).toLowerCase().includes(searchFilter!.toLowerCase()) ||
    getInviteStatusDisplay(employee).toLowerCase().includes(searchFilter!.toLowerCase()) ||
    employee.user.email.toLowerCase().includes(searchFilter!.toLowerCase());
  
  const data = useMemo(
    () =>
      staffRoster
        .filter(filterStaffRosterBySchool)
        .filter(filterStaffRosterByRole)
        .filter(filterStaffRosterBySearch)
        .map((employee) => ({
          id: employee.user.id,
          name: <h4 className="blueTitle"> {getFullName(employee.user)}</h4>,
          email: employee.user.email.endsWith("@njtss_user_imported") ? (
            <i>No email</i>
          ) : (
            <div className="multiline-break">{employee.user.email}</div>
          ),
          role: getUserAssigmentRoleDisplayedName(
            employee.user.profile.current_assignment?.role
          ),
          schools:
            employee.user.profile.current_assignment?.schools
              .map((school) => school.name)
              .join(", ") ?? "",
          inviteStatus: getInviteStatusDisplay(employee),
          actions: (
            <div className="row">
              <div className="col-2">
                <ResendInviteButton
                  isPending={getInviteStatusDisplay(employee).replace(' ','_').toLowerCase() == InviteStatuses.INVITE_SENT}
                  employee={employee}
                />
              </div>
              <div className="col-2">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="edit-user-btn" className="customInfoTooltip">
                      Edit User
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon icon={faPen} size="lg" />
                </OverlayTrigger>
              </div>
              {/* <div className="col-2"> //RC-1457 hide the icon when active to disable from modal
                <UserStatusButton
                  isActive={!employee.user.account_disabled}
                  userId={employee.user.id}
                />
              </div> */}
            </div>
          ),
        })),
    [staffRoster, schoolFilter, roleFilter, searchFilter]
  );

  return (
    <BootstrapTable
      classes="rosterTable"
      keyField="id"
      bordered={false}
      bootstrap4
      rowEvents={rowEvents}
      data={data}
      columns={columns}
      defaultSorted={defaultSorted}
      pagination={paginationFactory(options)}
      noDataIndication={() => "No Users found. Try changing the filters."}
    />
  );
};

const mapStateToProps = ({
  onboarding,
  usersManagement,
}: ApplicationState): StateProps => {
  return {
    schoolFilter: usersManagement.schoolFilter,
    roleFilter: usersManagement.roleFilter,
    searchFilter: usersManagement.searchFilter,
    staffRoster: onboarding.teachersRoster,
    selectedTeacher: onboarding.selectedTeacher,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      setSelectedTeacher: changeSelectedTeacher,
      openTeacherDetailsModal: openTeacherDetailsModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StaffTable);
