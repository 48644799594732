import React, { FunctionComponent, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { Overlay, OverlayTrigger, Popover } from "react-bootstrap";
import { bindActionCreators, Dispatch } from "redux";
import { changeSelectedInterventionGroup } from "../../../../../../store/onboarding/cases/actions";
import {
  openEndInterventionModal,
  openModifyInterventionModal,
} from "../../../../../../store/onboarding/meeting-module/actions";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";

type DispatchProps = {
  //openModifyInterventionModal: () => any;
  openEndInterventionModal: ({goal_id, is_primary_goal, student}: any) => any;
  changeSelectedInterventionGroup: (
    interventionGroup: InterventionGroup
  ) => any;
  push: (location: string) => any;
  showConfirmDialog: (confirmObj:any) => any;
};

type OwnProps = {
  interventionGroup: InterventionGroup;
  isCoachOrTeacharAssignedToGroup?: boolean;
};

type Props = OwnProps & DispatchProps;

const ChangeGroupOverlay: FunctionComponent<Props> = ({
  interventionGroup,
  // push,
  // openModifyInterventionModal,
  openEndInterventionModal,
  changeSelectedInterventionGroup,
  isCoachOrTeacharAssignedToGroup,
  showConfirmDialog
}) => {
  const targetRef = useRef<any>(null);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  // const handleGroupDetailsView = () => {
  //   setShowOverlay(false);
  //   changeSelectedInterventionGroup(interventionGroup);
  //   push(`/cases/groups/${interventionGroup.id}`);
  // };

  // const handleModifyIntervention = () => {
  //   setShowOverlay(false);
  //   changeSelectedInterventionGroup(interventionGroup);
  //   openModifyInterventionModal();
  // };

  const handleDiscontinueIntervention = () => {
    setShowOverlay(false);
    changeSelectedInterventionGroup(interventionGroup);
    showConfirmDialog({
        onConfirm: () => {
          openEndInterventionModal({goal_id: undefined, is_primary_goal: true, student: undefined});
        },
        centered: true,
        title: 'Confirmation',
        timerElipseLeft: true,
        countDownTimer: 3,
        text: (
          <div className="text-center">
              Exiting student(s) from the group will archive all primary and secondary goals. 
                Once exited, archived goals can be accessed from exited student section. 
                Are you sure you want to continue?
          </div>
        ),
        confirmButtonText: "Confirm",
      });
  };

  // const handleChangeStudent = () => {
  //   setShowOverlay(false);
  //   push(`/cases/groups/${interventionGroup.id}`);
  // };

  return (
    <>
      <span
        className="pointer"
        ref={targetRef}
        onClick={() => setShowOverlay(true)}
      >
        <FontAwesomeIcon icon={faCog} size={"lg"} />
      </span>
      <Overlay
        flip
        rootClose
        onHide={() => setShowOverlay(false)}
        show={showOverlay}
        target={targetRef.current}
      >
        <Popover id="changeGroupPopover">
          <Popover.Content>
            <div className="d-flex flex-column">
              {/* <button
                className="whiteBtnSm mb-2"
                onClick={handleModifyIntervention}
              >
                Modify Intervention
              </button> */}
              {isCoachOrTeacharAssignedToGroup ? <button
                className="whiteBtnSm mb-2"
                onClick={handleDiscontinueIntervention}
              >
                Exit Student(s) from Group
              </button> : ''}
              {/* <button
                className="whiteBtnSm mb-2"
                onClick={handleGroupDetailsView}
              >
                View Group Details
              </button> */}
              {/* <button className="whiteBtnSm" onClick={handleChangeStudent}>
                Add/Remove Students
              </button> */}
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      changeSelectedInterventionGroup: changeSelectedInterventionGroup,
     // openModifyInterventionModal: openModifyInterventionModal,
      openEndInterventionModal: openEndInterventionModal,
      showConfirmDialog: showConfirmDialog,
      push: push,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ChangeGroupOverlay);
