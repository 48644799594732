import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useLoading } from "../../../../../../../../../utils/hooks/useLoading";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../../../store";
import { getLessonNotes } from "../../../../../../../../../store/onboarding/cases/actions";
import LoadingIndicator from "../../../../../../../../common/onboarding/LoadingIndicator";
import LessonNotesUpsertForm from "./LessonNotesUpsertForm";
import LessonNotesItem from "./LessonNotesItem";
import { toastr } from "react-redux-toastr";
import { LessonNotes } from "../../../../../../../../../store/onboarding/cases/types";
import useInterventionGroupAccess from "../../../../../../../../../utils/hooks/useInterventionGroupAccess";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Student } from "../../../../../../../../../store/onboarding/types";

type Props = {
  showUpsertForm: boolean;
  setShowUpsertForm: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLessonNotes: LessonNotes | undefined;
  setSelectedLessonNotes: React.Dispatch<
    React.SetStateAction<LessonNotes | undefined>
  >;
  isStudentDetail?: boolean;
  isGroupDetail?: boolean;
  lessonNoteSearchStr?: string;
};

const LessonNotesContainer: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    showUpsertForm,
    setShowUpsertForm,
    selectedLessonNotes,
    setSelectedLessonNotes,
    isStudentDetail,
    isGroupDetail,
    lessonNoteSearchStr
  } = props;
  const selectedGroup = useSelector(
    (s: ApplicationState) => s.cases.selectedInterventionGroup
  );

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(selectedGroup?.id);
  

  const selectedStudent = useSelector(
    (s: ApplicationState) => s.cases.selectedStudent
  );

  const selectedInterventionGroup = useSelector(
    (s: ApplicationState) => s.cases.selectedInterventionGroup
  );

  const lessonNotes = useSelector((s: ApplicationState) => s.cases.lessonNotes);

  const getAllLoading = useSelector(
    (s: ApplicationState) => s.cases.isLoading.getLessonNotes
  );
  const getAllError = useSelector(
    (s: ApplicationState) => s.cases.errors.getLessonNotes
  );
  useLoading(getAllLoading, getAllError);

  const deleteLoading = useSelector(
    (s: ApplicationState) => s.cases.isLoading.deleteLessonNotes
  );
  const deleteError = useSelector(
    (s: ApplicationState) => s.cases.errors.deleteLessonNotes
  );
  const deletionSuccess = useCallback(() => {
    toastr.success(
      "Success",
      "The lesson notes has been successfully deleted!"
    );
  }, []);
  useLoading(deleteLoading, deleteError, deletionSuccess);

  useEffect(() => {
    if (selectedGroup?.id) {
      dispatch(getLessonNotes(selectedGroup.id));
    }
  }, [selectedGroup?.id]);

  const allEducators: any[] = useMemo(() => {
    let allUsers: any[] = [];
    if(Object.keys(selectedInterventionGroup?.teacher_assignment!).length) {
      allUsers.push({
        id: selectedInterventionGroup?.teacher_assignment?.user.id,
        first_name: selectedInterventionGroup?.teacher_assignment?.user.first_name,
        last_name: selectedInterventionGroup?.teacher_assignment?.user.last_name,
      })
    }
    if(Object.keys(selectedInterventionGroup?.coach_assignment!).length) {
      allUsers.push({
        id: selectedInterventionGroup?.coach_assignment?.user.id,
        first_name: selectedInterventionGroup?.coach_assignment?.user.first_name,
        last_name: selectedInterventionGroup?.coach_assignment?.user.last_name,
      })
    }
    if(selectedInterventionGroup?.associated_assignments.length) {
      selectedInterventionGroup?.associated_assignments.forEach((associate) => {
        return allUsers.push({
          id: associate.user.id,
          first_name: associate.user.first_name ,
          last_name: associate.user.last_name,
        })
      }
      )
    }
    return Object.values(
      allUsers.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
    )
  },[selectedInterventionGroup?.teacher_assignment, selectedInterventionGroup?.coach_assignment, selectedInterventionGroup?.additional_educator_plan])
  

  const handleShowUpsertForm = useCallback(() => {
    setSelectedLessonNotes(undefined);
    setShowUpsertForm(true);
  }, []);

  if (getAllLoading) {
    return <LoadingIndicator />;
  }

  const filterLessonNotes = (lessonNote: LessonNotes) => {
    let noteSearchStr = lessonNoteSearchStr?.toLowerCase();
    const interventionIds: number[] = selectedInterventionGroup?.interventions
    .filter((intervention) => intervention.name.toLowerCase().includes(noteSearchStr!))
    .map((intervention) => intervention.id)!;

    const pertainStudentIds: any[] = selectedInterventionGroup?.students!
    .filter((student) => student?.first_name.toLowerCase().includes(noteSearchStr!))
    .map((student) => student?.id)!;

    let lessonNotes = lessonNote.interventions
                        .find((interventionId) => interventionIds.includes(interventionId))
                    || lessonNote.record_tags
                        .find((rt) => rt.text.toLowerCase().includes(noteSearchStr!))
                    || lessonNote.notes.toLowerCase().includes(noteSearchStr!)
                    || lessonNote.pertaining_students!
                        .find((pertainStudentId) => pertainStudentIds.includes(pertainStudentId))
                    || lessonNote.viewable_by
                        .find((viewableBy) => allEducators
                                              .filter((edu) => 
                                                  edu.first_name.toLowerCase().includes(noteSearchStr)
                                                  || edu.last_name.toLowerCase().includes(noteSearchStr))
                                              .some((edu) => edu.id == viewableBy))
    return lessonNotes     
  };

  return (
    <>
      <LessonNotesUpsertForm
        show={showUpsertForm}
        onHideForm={() => {
          setShowUpsertForm(false);
          setSelectedLessonNotes(undefined);
        }}
        selectedGroup={selectedGroup!}
        selectedLessonNotes={selectedLessonNotes}
      />
      <div
        className={"workspace-content-container"}
        style={showUpsertForm ? { display: "none" } : undefined}
      >
        {lessonNotes.length ? (
          <div>
            {lessonNotes.filter(filterLessonNotes).map((notes) => (
              <LessonNotesItem
                key={notes.id}
                group={selectedGroup!}
                notes={notes}
                onEdit={() => {
                  setShowUpsertForm(true);
                  setSelectedLessonNotes(notes);
                }}
                interventions={selectedGroup?.interventions ?? []}
                archivedInterventions={selectedGroup?.archived_interventions ?? []}
                isStudentDetail={isStudentDetail}
                isGroupDetail={isGroupDetail}
              />
            ))}
          </div>
        ) : (
          <div className="text-center">
            {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess ?
              <>
                <p> No Lesson Notes.</p>
                <button className="blueBtnSm" onClick={handleShowUpsertForm}>
                  Add New Notes
                </button>
              </>
              :
              <p className="mb-0">No Lesson Notes.</p>
            }
          </div>
        )}
      </div>
    </>
  );
};

export default LessonNotesContainer;
