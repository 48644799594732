import { Button, Image } from "react-bootstrap";
import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import {
  openTeacherInviteIndividuallyModal,
  openUploadTeachersRosterModal,
} from "../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import { BasicUserInfo } from "../../../../../store/onboarding/types";
import relationshipIcon from "../../../../../Images/relationshipIcon.png";

type DispatchProps = {
  openUploadTeachersRosterModal: () => any;
  openTeacherInviteIndividuallyModal: (newTeacher?: BasicUserInfo) => any;
};

type Props = DispatchProps;

const TeachersInvitePanel = (props: Props) => {
  return (
    <div className="onboardingSecondStepEmptyContainer">
      <h2 className="font-weight-bold d-inline">
        It is time to invite educators or support staff. &nbsp;
      </h2>
      <Image src={relationshipIcon} />
      <h3 className="mt-2">
        Coaching is all about collaboration. Positive coach-educator
        relationships are important to achieve outcomes
      </h3>
      <br />
      <Button
        className="blueBtnXL"
        bsPrefix="b"
        onClick={() => props.openTeacherInviteIndividuallyModal()}
      >
        Invite Individually
      </Button>{" "}
      &nbsp;
      <Button
        className="blueBtnXL"
        bsPrefix="b"
        onClick={props.openUploadTeachersRosterModal}
      >
        Upload roster
      </Button>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openUploadTeachersRosterModal: openUploadTeachersRosterModal,
      openTeacherInviteIndividuallyModal: openTeacherInviteIndividuallyModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(TeachersInvitePanel);
