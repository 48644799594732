import React, { FunctionComponent, useMemo, useState } from "react";
import { TutorialTimeStamp, TutorialVideo } from "../../../store/onboarding/types";
import { Accordion, Badge, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import ReactPlayer from "react-player";

type OwnProps = {
  tutorial: TutorialVideo;
  activeKey?: string;
};

type Props = OwnProps;

const TutorialItem: FunctionComponent<Props> = ({ tutorial, activeKey }) => {
  const eventKey = useMemo(() => `tutorial_${tutorial.id}`, [tutorial]);
  const intl = useIntl();
  const collapsed = eventKey === activeKey;

  const tutorialTimeStamps = useMemo(() => {
    let tutorialTimeStampArray: any[] = [];
    if(tutorial.additional_details) {
      for (const key in tutorial.additional_details.time_staps) {
        if (Object.prototype.hasOwnProperty.call(tutorial.additional_details.time_staps, key)) {
          tutorialTimeStampArray.push(tutorial.additional_details.time_staps[key]);
        }
      }
    }
    return tutorialTimeStampArray;
  }, [tutorial])

  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={eventKey}
        className="d-flex align-items-center"
      >
        <span className="mr-3">
          <FontAwesomeIcon
            icon={collapsed ? faCaretDown : faCaretRight}
            size="2x"
          />
        </span>
        <div>
          <div> {tutorial.title}</div>
          <small className="mb-2">{tutorial.description}</small>
          {!!tutorial?.tags?.length && (
            <div>
              <small>
                {intl.formatMessage({
                  id: "app.dashboard.tutorials.videos.tags.title",
                })}
              </small>
              {tutorial.tags.map((tag) => (
                <Badge pill variant="warning" key={tag} className="mx-1">
                  {tag}
                </Badge>
              ))}
            </div>
          )}
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>
          <div className="row">
            <div className={`col-${tutorialTimeStamps.length ? 'auto':'12'}`}>
            {tutorial.video_file 
            ? <ReactPlayer
              url={tutorial.video_file ?? tutorial.external_video_url}
              controls
              width="100%"
              />
            : ''}
            {tutorial.external_video_url
            ? tutorial.external_video_url.includes('youtube') ?
              <ReactPlayer
              url={tutorial.external_video_url}
              controls
              width="100%"
              />
            :
            tutorial.thumbnail 
              ? <a href={tutorial.external_video_url} target="_blank">
                  <img
                    style={{
                      width: 500,
                      height: "300px",
                    }}
                    src={tutorial.thumbnail}
                    alt="ReadyCoach"
                  /> 
                </a>
              : ''
            : ''}

            </div>
            <div className="col-auto ml-3">
              {tutorialTimeStamps.length ? 
                <ul>
                  {tutorialTimeStamps.map((timeStamp: TutorialTimeStamp, index) => 
                    (index == 0)
                    ? <div className="row">
                        <h4 className="mb-2">
                          <u>
                            <em>{timeStamp.title}: <span className="font-weight-bold">{timeStamp.value}</span>
                            </em>
                          </u>
                        </h4>
                      </div>
                    : <li>{timeStamp.title}: <span className="font-weight-bold">{timeStamp.value}</span></li>
                  )}
                </ul>
              : ''}
            </div>
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default TutorialItem;
