import React, { FunctionComponent, useMemo } from "react";
import {
  GrammarType,
  GrammarTypeDisplayValues,
  SpellingTestWordGroup,
} from "../../../../store/spelling-tests/types";
import { Table } from "react-bootstrap";
import WordGroup from "./WordGroup";
import StatsFooter from "./StatsFooter";

type Props = {
  wordGroups: SpellingTestWordGroup[];
  selectedGrammarTypes: GrammarType[];
  onSpellingTestSave: () => void;
};

const DEFAULT_COL_SPAN = 3;

const SpellingTestInputTable: FunctionComponent<Props> = (props) => {
  const { wordGroups, selectedGrammarTypes, onSpellingTestSave } = props;

  const colSpan = useMemo(
    () => DEFAULT_COL_SPAN + selectedGrammarTypes.length * 2,
    [selectedGrammarTypes.length]
  );

  const handleSpellingTestSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSpellingTestSave();
  };

  return (
    <form id="spelling_test_upsert_form" onSubmit={handleSpellingTestSave}>
      <Table
        striped
        bordered
        responsive
        size="sm"
        className="spelling-test-upsert-test-form-table"
      >
        <thead>
          <tr>
            <th>#</th>
            <th colSpan={2}>Full Words</th>
            {selectedGrammarTypes.map((gt) => (
              <th key={gt} colSpan={2}>
                {GrammarTypeDisplayValues[gt]}
              </th>
            ))}
          </tr>
        </thead>
        {wordGroups.map((wg) => (
          <WordGroup
            key={wg.header_label_prefix}
            wordGroup={wg}
            selectedGrammarTypes={selectedGrammarTypes}
            colSpan={colSpan}
          />
        ))}
        <StatsFooter grammarTypes={selectedGrammarTypes} />
      </Table>
    </form>
  );
};

export default SpellingTestInputTable;
