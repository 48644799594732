import React, { FunctionComponent } from "react";
import IntroPanel from "./IntroPanel";
import EmptyGroupList from "./EmptyGroupList";
import { useDispatch, useSelector } from "react-redux";
import { setShowNoGroupsIntro } from "../../../../../store/onboarding/cases/actions";
import { ApplicationState } from "../../../../../store";
import { useHistory } from "react-router";

type OwnProps = {
  forParent?: boolean;
  fromRecommendation?: boolean;
};

type Props = OwnProps;

const NoGroupsContainer: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const messageParts = useSelector((s: ApplicationState) => s.onboarding.messageParts);

  const handleGotItClick = () => {
    history.push("/data");
  };

  return (
    <div className="mt-3">
      <IntroPanel onClick={handleGotItClick} fromRecommendation = {props.fromRecommendation} messageParts = {messageParts}/>
    </div>
  );
};

export default NoGroupsContainer;
