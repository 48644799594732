import React, { FunctionComponent, useMemo, useState } from "react";
import { ListGroup, OverlayTrigger, Popover } from "react-bootstrap";
import InterventionStatsTable from "../assigment/intervention-plan-modal/workspaces/inteventions-stats/InterventionStatsTable";
import _ from "lodash";
import FidelityValueSpan from "../assigment/intervention-plan-modal/workspaces/inteventions-stats/FidelityValueSpan";
import { ChecklistItem } from "../../../../../../store/onboarding/cases/types";

type OwnProps = {
  stats: any[];
};

type Props = OwnProps;

const InterventionStats: FunctionComponent<Props> = (props) => {
  const { stats } = props;

  const [selectedStats, setSelectedStats] = useState<any>(undefined);

  const commonChecklistItems = useMemo(
    () => {
    return  _.chain(stats)
        .flatMap((stats) => (stats !== undefined ) && stats.checklist_items)
        .filter((item) => !!item.common_step_name)
        .groupBy((item) => item.common_step_name)
        .toPairs()
        .map(([key, items]) => {
          return {
            title: key,
            usedTimes: //calculateUsedTime,
            _.chain(stats)
              .filter((stats) =>
              (stats !== undefined ) && stats.checklist_items.some(
                (ci: any) => ci.common_step_name && (ci.common_step_name != null) && (ci.common_step_name == key)
              )
              )
              .reduce((pV, cV) => pV + cV.loggedTimes, 0)
              .value(),
            loggedTimes: _.reduce(
              items,
              (pV, cV) => (cV.loggedTimes ? pV + cV.loggedTimes : pV),
              0
            ),
            notApplicableTimes: _.reduce(
              items,
              (pV, cV) => (cV.notApplicableTimes ? pV + cV.notApplicableTimes : pV),
              0
            ),
          };
        })
        .value()
    }
        ,
    [stats]
  );

  const handleCommonInterventionStepStatsView = () => {
    setSelectedStats({
      loggedTimes:
        _.chain(stats)
          .filter((s) =>
            (s !== undefined) && s.intervention?.checklist_items?.some(
              (ci: ChecklistItem) => !!ci.common_step_name
            )
          )
          .value().length || 0,
      checklist_items: commonChecklistItems,
      groupsIds: _.chain(stats)
        .flatMap((s) => (s !== undefined) && s.groupsIds)
        .uniq()
        .value(),
      teachersIds: _.chain(stats)
        .flatMap((s) => (s !== undefined) && s.teachersIds)
        .uniq()
        .value(),
    });
  };

  return (
    <div>
      {stats.length ? (
        <div className="intervention-stats-container">
          <div>
            <h3 className="mb-2">Interventions</h3>
            <ListGroup className="mb-2">
              {stats.map((stats) => ( (stats !== undefined ) &&
                <ListGroup.Item
                  key={stats.intervention.id}
                  action
                  className="m-0 d-flex align-items-center justify-content-between"
                  onClick={() => {
                    setSelectedStats(stats);
                  }}
                  style={{
                    backgroundColor:
                      selectedStats &&
                      selectedStats.intervention &&
                      selectedStats.intervention.id === stats.intervention.id
                        ? "#cbdcf0"
                        : undefined,
                  }}
                >
                   <>{(stats !== undefined ) && stats.intervention.name}</>
                  <FidelityValueSpan fidelity={(stats !== undefined ) && stats.totalFidelity} />
                </ListGroup.Item>
              ))}
            </ListGroup>
            <h3 className="mb-2">Other Stats</h3>
            <ListGroup>
              <ListGroup.Item
                action
                className="m-0 d-flex align-items-center justify-content-between"
                onClick={handleCommonInterventionStepStatsView}
                style={{
                  backgroundColor:
                    selectedStats && !selectedStats.intervention
                      ? "#cbdcf0"
                      : undefined,
                }}
              >
                <OverlayTrigger
                  overlay={
                    <Popover id="common-steps-popover">
                      <Popover.Content>
                        Some interventions share common steps. These statistics 
                        represent fidelity across multiple 
                        interventions where the steps were common.
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <div>Common Research-Based Instructional Components</div>
                </OverlayTrigger>
                <FidelityValueSpan fidelity={null} />
              </ListGroup.Item>
            </ListGroup>
          </div>

          {selectedStats ? (
            <InterventionStatsTable stats={selectedStats} />
          ) : (
            <i className="text-center m-auto">
              Click on an intervention to show detailed statistics
            </i>
          )}
        </div>
      ) : (
        <i>No Data</i>
      )}
    </div>
  );
};

export default InterventionStats;
