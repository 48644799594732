import React, { FunctionComponent } from "react";
import InterventionFidelityChart from "./implementation-check/InterventionFidelityChart";
import CollapsedContainer from "./common/CollapsedContainer";

type OwnProps = {
  onImpCheckDisabled?: (implCheck: boolean) => void
};

type Props = OwnProps;

const InterventionFidelityCollapse: FunctionComponent<Props> = (props) => {
  return (
    <CollapsedContainer label="Intervention Fidelity And Goal Progress">
      <InterventionFidelityChart onImpCheckDisabled={props.onImpCheckDisabled}/>
    </CollapsedContainer>
  );
};

export default InterventionFidelityCollapse;
