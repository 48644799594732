import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { Student, StudentClass } from "../../../../../../store/onboarding/types";

type Props = {
  interventionGroups: Array<InterventionGroup>;
  student: Student;
};

const StudentClassTeacherOverlay = ({ interventionGroups, student }: Props) => {
  let classes:any[] = [];
  for (let index = 0; index < student.teachers!.length; index++) {
    const teacher = student.teachers![index];
    if((teacher.classes !== undefined) && (teacher.classes !== null)) {
      classes.push(Array.from(new Set(teacher.classes!)).map((classInfo:any) => classInfo.name)) 
    }
  }
  // const studentInterventionGroups = interventionGroups.filter((x) =>
  //   x.students.some((s) => s.id === student.id)
  // );
  // if (!studentInterventionGroups.length) return null;
  return (
    <>
    <OverlayTrigger
      overlay={
        <Popover id={"studentUniqueIdPopover"}>
          <Popover.Content className="d-flex flex-column">
            <div className="table-responsive">
              <table className="table">
                <tr>
                  <th>Teacher</th>
                  <th>Class(es)</th>
                </tr>
                <tbody>
                  {student.teachers!.map((teacher:any, index: number)=>
                    <tr key={index}>
                      <td>{teacher.first_name +' '+ teacher.last_name }</td>
                      <td>{(teacher.classes != undefined) && (teacher.classes != null) ? teacher.classes!.map((classInfo:StudentClass)=> classInfo.name).join(', ').replace(/(^,)|(,$)/g, ""): '' }</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

          </Popover.Content>
        </Popover>
      }
    >
      <div className="d-inline-block text-truncate pl-4" style={{ fontSize: "10pt", width: "80px" }}>
      {classes.length > 1 ? classes.join(', ') : classes}
      </div>
    </OverlayTrigger>
  </>
  );
};
export default StudentClassTeacherOverlay;
