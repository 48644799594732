import React, { FunctionComponent, useMemo } from "react";
import Select from "react-select";
import { ReactSelectOption } from "../../../../../store/onboarding/types";
import { ValueType } from "react-select/src/types";

type OwnProps = {
  skills: ReactSelectOption<string>[];
  value?: string;
  onChange: (value?: string) => void;
};

type Props = OwnProps;

const ReadingSkillSelector: FunctionComponent<Props> = (props) => {
  const { skills, value, onChange } = props;

  const selectedOption = useMemo(() => {
    return skills.find((s) => s.value === value);
  }, [skills, value]);

  const handleReadingSkillChange = (
    value: ValueType<ReactSelectOption<string>, false>
  ) => {
    onChange(value?.value);
  };

  return (
    <Select
      menuPlacement="auto"
      menuPortalTarget={document.body}
      value={selectedOption}
      options={skills}
      onChange={handleReadingSkillChange}
      styles={{
        menuPortal: (styles) => ({ ...styles, zIndex: 1051 }),
      }}
    />
  );
};

export default ReadingSkillSelector;
