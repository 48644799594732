import React, { FunctionComponent, useState } from "react";
import Modal from "react-bootstrap/Modal";
import GroupInfoModalTitle from "./common/GroupInfoModalTitle";
import {
  Intervention,
  InterventionGroup,
} from "../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { hideInterventionsResourcesModal } from "../../../../../store/onboarding/meeting-module/actions";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faChevronRight,
  faDownload,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import ModalCloseButton from "../../../../common/onboarding/third-step/group-students/common/ModalCloseButton";

type StateProps = {
  showModal: boolean;
  showOnlyMaterials?: boolean;
  interventionGroup?: InterventionGroup;
};

type DispatchProps = {
  onModalHide: () => any;
};

type OwnProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const InterventionsResourcesModal: FunctionComponent<Props> = ({
  showModal,
  showOnlyMaterials,
  interventionGroup,

  onModalHide,
}) => {
  const [selectedIntervention, setSelectedIntervention] = useState<
    Intervention | undefined
  >(undefined);
  if (!interventionGroup) return null;

  const onModalShow = () => {
    if (interventionGroup.interventions) {
      setSelectedIntervention(interventionGroup.interventions[0]);
    }
  };

  return (
    //@ts-ignore
    <Modal
      show={showModal}
      onHide={onModalHide}
      //@ts-ignore
      onShow={onModalShow}
      animation={false}
      size="lg"
      backdropClassName={"customDarkModalBackdrop in"}
    >
      <Modal.Header className="purpleModalHeader centerModalHeader">
        <GroupInfoModalTitle />
        <Modal.Title>
          <div className="interventionSelectContainer">
            Behavior Contacts
            <span className="pointer">
              <FontAwesomeIcon icon={faCaretDown} size="2x" />
            </span>
          </div>
        </Modal.Title>
        <ModalCloseButton onClose={onModalHide} />
      </Modal.Header>
      <Modal.Body>
        <h3 className="mb-3">
          <span className="font-weight-bold">Group: </span>
          {interventionGroup.name}
        </h3>
        {showOnlyMaterials ? (
          <div>
            <h2 className="font-weight-bold purpleText">
              Intervention materials
            </h2>
            <hr />
            <div>
              {
                //todo add materials
              }
            </div>
            <hr />
            <div className="modalActions">
              <div />
              <button className="blueBtnSm" onClick={onModalHide}>
                Back
              </button>
            </div>
          </div>
        ) : (
          <div>
            <hr />
            <h4 className="font-weight-bold mb-3">
              Click a step to see more details and tips.
            </h4>
            <Row>
              <Col sm={5}>
                <div className="interventionDetailsContainer">
                  <h4 className="font-weight-bold mb-2">Considerations</h4>
                  <ul>
                    <li>
                      Make sure the student takes part in drawing up the
                      contract.
                    </li>
                    <li> Make sure the student understands the contract.</li>
                    <li>
                      Make sure the students has the ability to achieve what is
                      agreed upon in the contract.
                    </li>
                    <li> Make sure the contract is clear and to-the-point.</li>
                    <li>
                      When trying to reduce instances of inappropriate behavior,
                      try to focus on what you WANT for the student to do
                      instead of what you don't want for them to do.
                    </li>
                    <li>
                      The reward is always given after the behavior is produced.
                    </li>
                  </ul>
                </div>

                <div className="interventionDetailsContainer">
                  <h4 className="font-weight-bold">Additional Resources</h4>
                </div>
              </Col>
              <Col>
                {selectedIntervention &&
                  selectedIntervention.checklist_items.map(
                    (checkListItem, index) => (
                      <div
                        className="interventionStepsBtnWithPlaceholder"
                        key={checkListItem.id}
                      >
                        <div>Step {index + 1}</div>
                        <div>{checkListItem.title}</div>
                        <FontAwesomeIcon icon={faChevronRight} size={"lg"} />
                      </div>
                    )
                  )}
              </Col>
            </Row>
            <hr />
            <div className="spaceBetween">
              <div className="d-flex align-items-center">
                <div className="font-weight-bold">
                  Click a step to see more details and tips.
                </div>
                <button className="blueBtnSm ml-3" disabled>
                  <FontAwesomeIcon
                    icon={faDownload}
                    style={{ color: "#ffffff", marginRight: "5px" }}
                  />
                  Pdf
                </button>
                <button className="blueBtnSm ml-3" disabled>
                  <FontAwesomeIcon
                    icon={faPrint}
                    style={{ color: "#ffffff", marginRight: "5px" }}
                  />
                  Print
                </button>
              </div>
              <button className="blueBtnSm" onClick={onModalHide}>
                Back
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({
  cases,
  meetingModuleReducer,
}: ApplicationState): StateProps => {
  return {
    showModal: meetingModuleReducer.modalsState.interventionsBriefModal,
    showOnlyMaterials: meetingModuleReducer.modalsState.showOnlyMaterials,
    interventionGroup: cases.selectedInterventionGroup,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      onModalHide: hideInterventionsResourcesModal,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterventionsResourcesModal);
