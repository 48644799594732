import React, {
  FormEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import Modal from "react-bootstrap/Modal";
import GroupInfoModalTitle from "../common/GroupInfoModalTitle";
import ModalCloseButton from "../../../../../common/onboarding/third-step/group-students/common/ModalCloseButton";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  InterventionGroup,
  StudentGoal,
} from "../../../../../../store/onboarding/cases/types";
import { Form, Spinner } from "react-bootstrap";
import { Student } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import {
  hideEndInterventionModal,
  openModifyInterventionModal,
} from "../../../../../../store/onboarding/meeting-module/actions";
import { discontinueIntGroupInt } from "../../../../../../store/onboarding/cases/actions";
import { toastr } from "react-redux-toastr";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";

type StateProps = {
  showModal: boolean;
  preselectedStudentGoal?: any;
  interventionGroup?: InterventionGroup;
  isLoading: {
    discontinueIntGroupInt: boolean;
  };
};

type DispatchProps = {
  openModifyInterventionModal: () => any;
  hideEndInterventionModal: () => any;
  discontinueIntGroupInt: (
    interventionGroupId: number,
    // interventionId: number,
    students: Array<number>,
    reason: string | null,
    allStudentSelected: boolean | null,
    studentGoalId: number | undefined,
  ) => any;
};

type Props = StateProps & DispatchProps;

enum Content {
  SelectStudents,
  Reason,
}

enum Reasons {
  OTHER,
  EDUCATOR_LEAVE_INTERVENTION,
  STUDENT_LEFT_SCHOOL,
  ASSIGNED_TO_ANOTHER_EDUCATOR,
  EDUCATOR_LEFT_SCHOOL,
  PARENT_STOP_INTERVENTION,
  SCHOOL_YEAR_ENDING,
}

const ReasonsData = [
  {
    type: Reasons.EDUCATOR_LEAVE_INTERVENTION,
    label:
      "Educator decided she/he did not want to continue with the intervention",
  },
  { type: Reasons.STUDENT_LEFT_SCHOOL, label: "Student left the school" },
  {
    type: Reasons.ASSIGNED_TO_ANOTHER_EDUCATOR,
    label: "Student assigned to another educator for support",
  },
  { type: Reasons.EDUCATOR_LEFT_SCHOOL, label: "Educator left the school" },
  {
    type: Reasons.PARENT_STOP_INTERVENTION,
    label: "Parent requested the intervention stop",
  },
  {
    type: Reasons.SCHOOL_YEAR_ENDING,
    label: "School year ending and a new educator will be assigned next year",
  },
  { type: Reasons.OTHER, label: "Other" },
];

const filterStudents = (
  selectedInterventionGroup: InterventionGroup | undefined
) => {
  if (!selectedInterventionGroup) return [];
  const students = selectedInterventionGroup?.students.filter(
    (student, index) => {
      let studentGoal: StudentGoal | undefined;
      if (selectedInterventionGroup?.student_goals) {
        studentGoal = selectedInterventionGroup?.student_goals.find(
          (x) => x.student.id === student.id && !x.archived
        );
      }
      return !!studentGoal;
    }
  );
  return students;
};

const EndInterventionModal: FunctionComponent<Props> = ({
  showModal,
  interventionGroup,
  isLoading,
  preselectedStudentGoal,
  discontinueIntGroupInt,
  openModifyInterventionModal,
  hideEndInterventionModal,
}) => {
  const dispatch = useDispatch();
  const [currentContent, setCurrentContent] = useState<Content>(
    Content.SelectStudents
  );
  const [selectedStudents, setSelectedStudents] = useState<Array<Student>>([]);
  const [selectedReason, setSelectedReason] = useState<Reasons>();
  const [otherReason, setOtherReason] = useState<string>();
  const [currentStudents, setCurrentStudents] = useState([]);
  const [allStudentSelected, setAllStudentSelectd] = useState<boolean | null>(false);

  const selectedStudentGoal = useSelector<
        ApplicationState,
        StudentGoal | undefined
      >((s) => s.cases.selectedStudentGoal);

  const onModalShow = () => {
    setCurrentContent(Content.SelectStudents);
    setSelectedReason(undefined);
    setOtherReason(undefined);
    if (preselectedStudentGoal?.student) {
      setSelectedStudents([preselectedStudentGoal.student]);
    } else {
      setSelectedStudents([]);
    }
  };

  const showInterventionPlanModal = useSelector(
    (s: ApplicationState) => s.cases.modalsState.interventionPlan.show
  );

  const onModalHide = () => {
    hideEndInterventionModal();
    // if (!showInterventionPlanModal) {
    //   openModifyInterventionModal();
    // }
  };

  useEffect(() => {
    if(selectedStudents.length === currentStudents?.length) {
      setAllStudentSelectd(true);
    } else {
      setAllStudentSelectd(false);
    }
  }, [selectedStudents, currentStudents])

  useEffect(() => {
    (() => {
      const value: any = filterStudents(interventionGroup);
      setCurrentStudents(value);
    })();
  }, [interventionGroup]);

  if (!interventionGroup) return null;

  const handleAllStudentsSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedStudents([...(currentStudents ? currentStudents : [])]);
      setAllStudentSelectd(true);
    } else {
      setSelectedStudents([]);
      setAllStudentSelectd(false);
    }
  };

  const handleStudentSelect = (student: Student) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedStudents((students) => [...students, student]);
    } else {
      setSelectedStudents((students) =>
        students.filter((s) => s.id !== student.id)
      );
    }
  };

  const handleChangeOther = (event: FormEvent<any>) => {
    const target = event.target as HTMLInputElement;
    setOtherReason(target.value);
  };

  const handleRemoveFromGroup = () => {
    dispatch(
      showConfirmDialog({
        title: "Confirmation",
        text:
          "Primary and secondary goals will be archived. Are you sure you want to continue? ",
        onConfirm: () => {
          setCurrentContent(Content.Reason);
        },
        confirmButtonText: "Continue",
      })
    );
  }

  const onEndIntervention = (event: FormEvent) => {
    event.preventDefault();
    //console.log('allStudentSelected', allStudentSelected); 
    discontinueIntGroupInt(
      interventionGroup.id!,
      selectedStudents.map((s) => s.id!),
      preselectedStudentGoal?.is_primary_goal 
      ? 
        selectedReason
          ? ReasonsData.find((rd) => rd.type === selectedReason)!.label
          : otherReason!
      : null,
      preselectedStudentGoal?.is_primary_goal ? allStudentSelected : null,
      !preselectedStudentGoal?.is_primary_goal ? preselectedStudentGoal?.goal_id : undefined
    ).then(
      () => {
        hideEndInterventionModal();
      },
      (err: string) =>
        toastr.error("Failed to discontinue the intervention", err)
    );
  };

  const getModalBody = () => {
    switch (currentContent) {
      case Content.SelectStudents: {
        
        return (
          <>
            <h1 className="text-center mb-4">
              Which student(s) do you want to remove from this group?
            </h1>
            {selectedStudents.length === currentStudents?.length && (
              <div className="alert alert-warning ml-5" role="alert">
                Exiting all students will archive this group.
              </div>
            )}
            <h3 className="font-weight-bold ml-5">Select student(s):</h3>
            <div className="ml-5">
              <div className="d-flex mt-2">
                <Form.Check
                  id="select-all"
                  custom
                  onChange={handleAllStudentsSelect}
                  className="customDefaultCheckbox"
                  type="checkbox"
                  label
                  checked={selectedStudents.length === currentStudents?.length}
                />
                <h3>All of the students in this case</h3>
              </div>
              {currentStudents?.map((student: any) => (
                <div key={student.id} className="d-flex mt-2">
                  <Form.Check
                    id={student.id!.toString()}
                    custom
                    onChange={handleStudentSelect(student)}
                    className="customDefaultCheckbox"
                    type="checkbox"
                    label
                    checked={selectedStudents.some((s) => s.id === student.id)}
                  />
                  <h3>{getFullName(student)}</h3>
                </div>
              ))}
            </div>
            <hr />
            <div className="modalActions">
              <button className="blueBtnSm" onClick={onModalHide}>
                Back
              </button>
              <button
                className="blueBtnSm"
                disabled={selectedStudents.length === 0}
                onClick={handleRemoveFromGroup}
              >
                Remove from Group
              </button>
            </div>
          </>
        );
      }
      case Content.Reason: {
        return (
          <>
            <h1 className="text-center mb-4">
              Please indicate why you would like to end this intervention.
            </h1>
            <h3 className="font-weight-bold ml-5">
              Reasons to discontinue case
            </h3>
            <Form
              id="endInterventionForm"
              onSubmit={onEndIntervention}
              className="ml-5"
            >
              {ReasonsData.map((reason) => (
                <div
                  key={reason.type}
                  className="d-flex align-items-center mt-2"
                >
                  <Form.Check
                    id={reason.type.toString()}
                    custom
                    onChange={() => setSelectedReason(reason.type)}
                    type="radio"
                    label
                    checked={selectedReason === reason.type}
                  />
                  <h3>{reason.label}</h3>
                  {reason.type === Reasons.OTHER && (
                    <Form.Control
                      required
                      disabled={selectedReason !== Reasons.OTHER}
                      type="text"
                      className="ml-2 fullWidthInput"
                      onChange={handleChangeOther}
                      value={otherReason || ""}
                    />
                  )}
                </div>
              ))}
            </Form>
            <hr />
            <div className="modalActions">
              <button
                className="blueBtnSm"
                onClick={() => setCurrentContent(Content.SelectStudents)}
              >
                Back
              </button>
              <button
                className="blueBtnSm"
                disabled={!selectedReason && !otherReason?.length}
                type="submit"
                form="endInterventionForm"
              >
                End Intervention{" "}
                {isLoading.discontinueIntGroupInt && (
                  <Spinner animation="border" size="sm" />
                )}
              </button>
            </div>
          </>
        );
      }
    }
  };

  const getModalBodyForSecondaryGoal = () => {
    return (
      <>
        <h4 className="text-center">
        {`Once archived, you will be able to view archived secondary goal in to exit student section,
        not into the current student section.This action cannot be undone. 
        Are you sure you want to continue?`}
        </h4>
        <hr />
        <div className="modalActions">
          <button
            className="blueBtnSm"
            onClick={onModalHide}
          >
            Cancel
          </button>
          <button
            className="blueBtnSm"
            onClick={onEndIntervention}
          >
            Yes{" "}
            {isLoading.discontinueIntGroupInt && (
              <Spinner animation="border" size="sm" />
            )}
          </button>
        </div>
      </>
    );
  };

  return (
    <Modal
      show={showModal}
      onHide={onModalHide}
      onShow={onModalShow}
      animation={false}
      size={preselectedStudentGoal?.is_primary_goal ? "lg" : undefined}
      backdropClassName={"customDarkModalBackdrop in"}
    >
      <Modal.Header className="purpleModalHeader centerModalHeader">
      {preselectedStudentGoal?.is_primary_goal ? <GroupInfoModalTitle /> : '' }
        <Modal.Title>
            <div className={`col-12 ${preselectedStudentGoal?.is_primary_goal ? 'text-left' : 'text-center'}`}>
              {preselectedStudentGoal?.is_primary_goal ? `Exit student(s) from Group` : `Archive Goal` }</div>
        </Modal.Title>
            <div className="col-1 text-right"><ModalCloseButton onClose={onModalHide}/></div>
      </Modal.Header>
      <Modal.Body>
      {preselectedStudentGoal?.is_primary_goal 
      ?
        <>
          <h3 className="mb-3">
            <span className="font-weight-bold">Group: </span>
            {interventionGroup.name}
          </h3>
          {getModalBody()} 
        </>
      : getModalBodyForSecondaryGoal()}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({
  cases,
  meetingModuleReducer,
}: ApplicationState): StateProps => {
  return {
    showModal: meetingModuleReducer.modalsState.endInterventionModal,
    preselectedStudentGoal: meetingModuleReducer.preselectedStudentGoal,
    interventionGroup: cases.selectedInterventionGroup,
    // intervention: cases.selectedIntervention,
    isLoading: {
      discontinueIntGroupInt: cases.isLoading.discontinueIntGroupInt,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      discontinueIntGroupInt: discontinueIntGroupInt,
      openModifyInterventionModal: openModifyInterventionModal,
      hideEndInterventionModal: hideEndInterventionModal,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EndInterventionModal);
