import React, { FunctionComponent } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import BehaviorDataTooltipContent from "./BehaviorDataTooltipContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faRedo } from "@fortawesome/free-solid-svg-icons";
import ReadingDataTooltipContent from "./ReadingDataTooltipContent";
import useUserRole from "../../../../../utils/hooks/useUserRole";

type OwnProps = {
  className?: string;
  getDataPeriodsByDistrict?: () => any;
};

type Props = OwnProps;

const AddMoreData: FunctionComponent<Props> = (props) => {
  const { className, getDataPeriodsByDistrict } = props;
  const { isDistrictOwner } = useUserRole();
  return (
    <div className={`dataTooltip ${className ?? ""}`}>
      {!isDistrictOwner ? <div className="dataTooltipHeader">Add More Data</div> : ''}
      <div className="dataTooltipBody">
      {isDistrictOwner ? <label className="dataTooltipHeaderForAddMoreData">Add More Data: </label> : ''}
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="right"
          overlay={
            <Popover
              id="add-new-column-popover"
              className="add-new-column-popover"
              style={{ maxWidth: "600px" }}
            >
              <Popover.Content>
                {/*<NewColumnTooltipContent />*/}
                <BehaviorDataTooltipContent />
              </Popover.Content>
            </Popover>
          }
        >
          <button className="whiteBtnSm text-left text-nowrap my-2">
            <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
            Behavior Data
          </button>
        </OverlayTrigger>

        <OverlayTrigger
          rootClose
          trigger="click"
          placement="right"
          overlay={
            <Popover
              id="add-new-column-popover"
              className="add-new-column-popover"
              style={{ maxWidth: "600px" }}
            >
              <Popover.Content>
                <ReadingDataTooltipContent />
              </Popover.Content>
            </Popover>
          }
        >
          <button className="whiteBtnSm text-left text-nowrap my-2">
            <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
            Reading Data
          </button>
        </OverlayTrigger>
        {isDistrictOwner 
        ? 
        <button
            className="whiteBtnSm text-left text-nowrap my-2"
            type="button"
            onClick={getDataPeriodsByDistrict}
          ><FontAwesomeIcon icon={faRedo} className="mr-1" />
          Refresh Data Table</button>
        : ''}
      </div>
    </div>
  );
};

export default AddMoreData;
