import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faFile,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { AttachedResource } from "../../../../../../../../store/onboarding/cases/types";

type OwnProps = {
  attachment: AttachedResource;
  onAttachmentRemove?: Function;
};

type Props = OwnProps;

const AttachedResourceItem: FunctionComponent<Props> = (props) => {
  const { attachment, onAttachmentRemove } = props;

  return (
    <div className="fileCell">
      <div className="fileCellName">
        <a
          className="interventionStepFile"
          href={attachment.attachment_url || attachment.url}
          title={attachment.title}
        >
          <FontAwesomeIcon
            icon={attachment.url ? faExternalLinkAlt : faFile}
            size="1x"
          />
          &nbsp; {attachment.title}
        </a>
      </div>
      {onAttachmentRemove && (
        <div
          className="fileCellRemove"
          onClick={() => onAttachmentRemove(attachment.id!)}
        >
          <FontAwesomeIcon
            icon={faTimes}
            size="1x"
            style={{ color: "#ffffff" }}
          />
        </div>
      )}
    </div>
  );
};

export default AttachedResourceItem;
