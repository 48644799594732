import React, { FunctionComponent, useMemo } from "react";
import { pickTextColorBasedOnBgColor } from "../../../../../utils";
import {
  ColorCriteria,
  MeasurementType,
} from "../../../../../../store/onboarding/types";

type Props = {
  measureType?: MeasurementType;
  colorCriteria: ColorCriteria;
  totalStudents: number | null;
  getStudentsNumByColorCriteria:
    | ((colorCriteria: ColorCriteria) => number)
    | null;
  withoutStats?: boolean;
};

const MAX_DISPLAYED_VALUE = 10000;

const ColorColorCriteriaRow: FunctionComponent<Props> = (props) => {
  const {
    measureType,
    totalStudents,
    colorCriteria,
    getStudentsNumByColorCriteria,
    withoutStats,
  } = props;

  const displayRange = useMemo(() => {
    if(colorCriteria){
      if (measureType === MeasurementType.PERCENTAGE) {
        return `${colorCriteria.range_min}% - ${colorCriteria.range_max}%`;
      } else {
        if (colorCriteria.range_max! > MAX_DISPLAYED_VALUE) {
          return `${colorCriteria.range_min}+`;
        }
        return `${colorCriteria.range_min} - ${colorCriteria.range_max}`;
      }
    }
  }, [measureType, colorCriteria]);

  const studentsNumber = useMemo(() => {
    if (!getStudentsNumByColorCriteria) {
      return null;
    }
    return getStudentsNumByColorCriteria(colorCriteria);
  }, [colorCriteria]);

  const studentsPercent = useMemo(() => {
    if (studentsNumber === null) {
      return null;
    }
    return (((studentsNumber ?? 0) / (totalStudents || 1)) * 100)
      .toFixed(0)
      .concat("%");
  }, [totalStudents, studentsNumber]);

  return (colorCriteria &&
    <tr
      style={{
        backgroundColor: colorCriteria.color,
        color: pickTextColorBasedOnBgColor(colorCriteria.color),
      }}
    >
      <td width={120}>{colorCriteria.display_name}</td>
      {measureType !== MeasurementType.CATEGORY && <td>{displayRange}</td>}

      {!withoutStats && (
        <>
          <td>{studentsNumber}</td>
          <td>{studentsPercent}</td>
        </>
      )}
    </tr>
  );
};

export default ColorColorCriteriaRow;
