import React, { FunctionComponent, useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  addCustomBehavior,
  addStudentBehaviorsToDataPeriod,
  changeCustomBehaviorName,
} from "../../../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { CategoryBehavior } from "../../../../../../../store/onboarding/types";

type StateProps = {
  pCustomBehaviorName?: string;
  isLoading: {
    addCustomBehavior?: boolean;
  };
};

type DispatchProps = {
  addCustomBehavior: (behaviorName: string) => any;
  changeCustomBehaviorName: (name?: string) => any;
  addStudentBehaviorsToDataPeriod: (
    behaviors: Array<CategoryBehavior>,
    studentId: number,
    selectedDataPeriodId?: number
  ) => any;
};

type OwnProps = {
  isForAll?: boolean;
  studentIds?: number[];
  selectedBehaviors?: CategoryBehavior[];
  studentId?: number;
  onHighlightBehavior: (id: number) => any;
};

type Props = StateProps & DispatchProps & OwnProps;

const CustomBehaviorInputForm: FunctionComponent<Props> = ({
  isForAll,
  studentIds,
  selectedBehaviors,
  studentId,
  pCustomBehaviorName,
  addCustomBehavior,
  changeCustomBehaviorName,
  addStudentBehaviorsToDataPeriod,
  isLoading,
  onHighlightBehavior,
}) => {
  const [showInputForm, setShowInputForm] = useState<boolean>(false);
  const [customBehaviorName, setCustomBehaviorName] = useState<string>("");

  const handleCustomBehaviorNameChange = (event: React.FormEvent<any>) => {
    const { value } = event.target as HTMLInputElement;
    setCustomBehaviorName(value);
  };

  const handleCustomBehaviorAdd = (e: React.FormEvent<any>) => {
    e.preventDefault();
    addCustomBehavior(customBehaviorName).then(
      (customBehavior: CategoryBehavior) => {
        setShowInputForm(false);
        setCustomBehaviorName("");

        const students =
          (isForAll ? studentIds : studentId != null ? [studentId] : []) ?? [];

        Promise.all(
          students.map((id) =>
            addStudentBehaviorsToDataPeriod(
              [...(selectedBehaviors || []), customBehavior],
              id
            )
          )
        ).then(
          () => {
            onHighlightBehavior(customBehavior.id!);
            toastr.success("Saved!", "", { timeOut: 3000 });
          },
          (error: string) => toastr.error("Failed to update behaviors", error)
        );
      },
      (err: string) => toastr.error("Failed to add the custom behavior", err)
    );
  };

  useEffect(() => {
    if (pCustomBehaviorName) {
      setShowInputForm(true);
      setCustomBehaviorName(pCustomBehaviorName);
      changeCustomBehaviorName();
    }
  }, [pCustomBehaviorName]);

  if (showInputForm) {
    return (
      <Form onSubmit={handleCustomBehaviorAdd}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="font-weight-bold">
            Custom Behavior Name
          </Form.Label>
          <Form.Control
            autoFocus
            required
            type="text"
            placeholder="Enter custom behavior..."
            value={customBehaviorName}
            onChange={handleCustomBehaviorNameChange}
          />
        </Form.Group>
        <div className="d-flex align-items-center justify-content-between">
          <button
            className="blueBtnSm"
            onClick={() => {
              setShowInputForm(false);
              setCustomBehaviorName("");
            }}
          >
            Cancel
          </button>
          <button
            className="blueBtnSm"
            type="submit"
            disabled={isLoading.addCustomBehavior}
          >
            Add custom behavior{" "}
            {isLoading.addCustomBehavior && (
              <Spinner animation="border" size="sm" />
            )}
          </button>
        </div>
      </Form>
    );
  }

  return (
    <div
      className="d-flex justify-content-center"
      onClick={() => setShowInputForm(true)}
    >
      <button className="blueBtnSm">Type in Custom Behavior</button>
    </div>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    pCustomBehaviorName: onboarding.customBehaviorName,
    isLoading: {
      addCustomBehavior: onboarding.isLoading.addCustomBehavior,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      addCustomBehavior: addCustomBehavior,
      changeCustomBehaviorName: changeCustomBehaviorName,
      addStudentBehaviorsToDataPeriod: addStudentBehaviorsToDataPeriod,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomBehaviorInputForm);
