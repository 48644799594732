import React, { FunctionComponent, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { hidePageHelperModal } from "../../../store/help/actions";
import { ApplicationState } from "../../../store";
import { CoachDashboardTabKeys } from "../../pages/coach-dashboard/CoachDashboard";

type OwnProps = {};

type Props = OwnProps;

const PageHelperModal: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const show = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.help.modalsState.pageHelperModal
  );

  const routesNames: { [key in CoachDashboardTabKeys]: string } = useMemo(
    () => ({
      [CoachDashboardTabKeys.YOUR_ASSIGNMENTS]: "Active Groups",
      [CoachDashboardTabKeys.YOUR_BEHAVIOUR_ASSIGNMENTS]: "Active Behavior Groups",
      [CoachDashboardTabKeys.YOUR_READING_ASSIGNMENTS]: "Active Reading Groups",
      [CoachDashboardTabKeys.GROUPS_IN_PROGRESS]: "Pre-Intervention Groups",
      [CoachDashboardTabKeys.YOUR_DATA_AND_INTERVIEWS]:
        "Your Data & Interviews",
      [CoachDashboardTabKeys.ROSTERS_STUDENTS]: "Students",
      [CoachDashboardTabKeys.ROSTERS_TEACHERS]: "Educators & Sharing",
      [CoachDashboardTabKeys.FBAs]: "ABC Data Collection",
      [CoachDashboardTabKeys.BADGES]: "Badges",
      [CoachDashboardTabKeys.INTERVENTION_LIBRARY]: "Intervention Library",
      [CoachDashboardTabKeys.TUTORIAL_VIDEOS]: "Video Tutorials",
      [CoachDashboardTabKeys.RELEASE_NOTES]: "Release Notes",
      [CoachDashboardTabKeys.YOUR_PROFILE]: "Your Profile",
      [CoachDashboardTabKeys.NOTIFICATIONS]: "Notifications",
      [CoachDashboardTabKeys.PARENTS]: "Parent/Guardian Access",
      [CoachDashboardTabKeys.SPELLING_TEST]: "Spelling Test",
    }),
    []
  );

  const { tabName } = useParams<{
    tabName: CoachDashboardTabKeys | undefined;
  }>();

  const onClose = () => {
    dispatch(hidePageHelperModal());
  };

  const content = useMemo(() => {
    switch (tabName) {
      case CoachDashboardTabKeys.YOUR_ASSIGNMENTS:
        return (
          <>
            <h2>Active Groups</h2>
            <p>
              The <strong>Active Groups</strong> page is your primary dashboard
              for interacting with groups that already have student goals and
              assigned interventions.
            </p>
            <p>
              Clicking on a group's Intervention Plan opens the workspace for
              that group, allowing you to view detailed graphs, review the goal
              progress data, view and log interventions, write notes to your
              collaborators, and more.
            </p>
            <p>
              <strong>
                If you created a group, and don't see it here, then it is most
                likely in the <u>Pre-Intervention Groups</u> page, because it
                has not been assigned student goals or interventions yet.
              </strong>
            </p>
            <hr />
            <h3>Adding New Groups</h3>
            <p>
              ReadyCoach is a student data focuses intervention platform. New
              groups are created from the{" "}
              <strong>Your Data &amp; Evidence</strong> page.
            </p>
            <hr />
            <h3>Sorting</h3>
            <ul>
              <li>
                <strong>Goal Date:</strong> Sorts by the date of the nearest
                student goal target date in the group
              </li>
              <li>
                <strong>Progress Trend:</strong> Sorts groups by whether they
                are progressing positively or negatively towards their goal
              </li>
              <li>
                <strong>Activity: </strong> Sorts by the date of the most recent
                interaction with the group (e.g. logged intervention, logged
                goal progress, etc)
              </li>
              <li>
                <strong>Intervention Schedule: </strong> Sorts the groups by
                their schedule, with the nearest upcoming interventions first
              </li>
            </ul>

            <h3>Displayed Groups</h3>
            <ul>
              <li>
                <strong>Active Groups:</strong> Shows groups that have active
                intervention plans that you are still working on
              </li>
              <li>
                <strong>Archived Groups:</strong> Shows groups that are no
                longer being worked on (e.g. discontinued, goal was met)
              </li>
            </ul>

            <h2>Searching</h2>
            <p>
              The search text box allows you to filter groups by searching
              student names, teacher names, and intervention names.
            </p>
          </>
        );
      case CoachDashboardTabKeys.GROUPS_IN_PROGRESS:
        return (
          <>
            <h2>Pre-Intervention Groups</h2>
            <p>
              The <strong>Pre-Intervention Groups</strong> page is where you
              will find your groups that have not been made{" "}
              <strong>Active</strong> yet. A group is made{" "}
              <strong>Active</strong> by clicking the{" "}
              <u>Start Working on Group</u> button on the bottom of the group
              cards. This button appears after the group has been assigned goals
              and interventions.
            </p>
            <p>
              Performing interventions in ReadyCoach requires you to set a goal
              first, then choose your intervention strategy, before activating
              your group and tracking progress.
            </p>
            <hr />
            <h3>Recommendations</h3>
            <p>
              This page will also show you recommended groups based on the data
              in your <strong>Your Data &amp; Interviews</strong> page. You can
              enable or disable this using the "Show Recommendations" switch at
              the top of this page.
            </p>
            <strong>Note:</strong>
            <ul>
              <li>
                Recommendations are data-specific. You must be viewing the data
                on the <strong>Your Data &amp; Interviews</strong> page for
                which you want recommendations for them to appear here.
              </li>
              <li>
                ReadyCoach only makes recommendations for Reading groups, based
                on screening or diagnostic data for the Beginning or Middle of
                the year.{" "}
                <strong>
                  It does not recommend groups for End of Year data.
                </strong>
              </li>
            </ul>
          </>
        );
      case CoachDashboardTabKeys.YOUR_DATA_AND_INTERVIEWS:
        return (
          <>
            <h2>Your Data &amp; Interviews</h2>
            <p>
              ReadyCoach is a student data focused intervention platform. It
              requires you to analyze and use data for grouping and intervention
              decisions.
            </p>
            <p>
              The platform provides an Excel-like data table for uploading and
              entering data.
            </p>
            <hr />
            <h3>Data Files</h3>
            <ul>
              <li>
                All data files require First Name, Last Name, and a Student ID
                for each student.
              </li>
              <li>
                ReadyCoach recognizes and automatically imports files with
                certain assessment data in them such as DIBELS screening data.
              </li>
              <li>
                You will be asked to describe the columns in files that the
                platform does not automatically import.
              </li>
            </ul>
            <h3>Data Periods</h3>
            <p>
              A Data Period is equivalent to single Excel spreadsheet. It holds data specific
              to a certain time period (Beginning of Year, Middle of Year, or
              End of Year).
            </p>
            <p>
              You can create as many Data Periods as you want. For files that
              contain time period information, the platform will automatically
              create the appropriate Data Periods (e.g. DIBELS).
            </p>
            <h3>Reading Diagnostics</h3>
            <p>
              You may enter reading diagnostic data for students whose reading
              level is between Kindergarten and 3rd Grade. For Beginning and
              Middle of Year Data Periods, the platform will provide
              diagnostic-specific recommendations.
            </p>
          </>
        );
      case CoachDashboardTabKeys.ROSTERS_STUDENTS:
        return null; //todo
      case CoachDashboardTabKeys.ROSTERS_TEACHERS:
        return null; //todo
      case CoachDashboardTabKeys.FBAs:
        return null; //todo
      case CoachDashboardTabKeys.BADGES:
        return null; //todo
      case CoachDashboardTabKeys.INTERVENTION_LIBRARY:
        return null; //todo
      case CoachDashboardTabKeys.TUTORIAL_VIDEOS:
        return null; //todo
      case CoachDashboardTabKeys.YOUR_PROFILE:
        return null; //todo
      case CoachDashboardTabKeys.NOTIFICATIONS:
        return null; //todo
      case CoachDashboardTabKeys.PARENTS:
        return null; //todo
      case CoachDashboardTabKeys.SPELLING_TEST:
        return null; //todo
      default:
        return null;
    }
  }, [tabName]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      animation={false}
      size="lg"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header className="purpleModalHeader" closeButton>
        <Modal.Title>{tabName ? routesNames[tabName] : null}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
    </Modal>
  );
};

export default PageHelperModal;
