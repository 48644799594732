import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
// @ts-ignore
import { bindActionCreators, Dispatch } from "redux";
import {
  addStudents,
  assignStudentsToTeacher,
  clearStoredCsvFile,
  getEvidenceInDataPeriod,
  getTeachers,
  hidePickDataSourceModal,
  hideUploadStudentDataModal,
  openSearchForYourAssessmentModal,
  updateCsvFileRawData,
  uploadAssessmentData,
  uploadCsvFile,
  uploadMultipleCsvFile,
  uploadEvidenceData,
} from "../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import {
  Assessment,
  BenchmarkStatus,
  BenchmarkStatusDefaultColors,
  BenchmarkStatusDisplayedNames,
  ColorCriteria,
  DataPeriod,
  EvidenceData,
  EvidencePeriodData,
  Measurement,
  MeasurementType,
  PossibleValue,
  Student,
} from "../../../../../store/onboarding/types";
import UploadInfo from "./UploadInfo";
import Table from "react-bootstrap/Table";
import UploadingDataGuide from "./UploadingDataGuide";
import ColumnHeadersSelectorPanel from "./ColumnHeadersSelectorPanel";
import DataMetricColumnsPreview from "./DataMetricColumnsPreview";
import { ApplicationState } from "../../../../../store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Papa from "papaparse";
import EditMeasureColorCriteriaModal from "../../../color-picker/EditMeasureColorCriteriaModal";
import { Form, Spinner } from "react-bootstrap";
import { uploadedAssessment, UserAssignmentRole, UserInfo } from "../../../../../store/auth/types";
import { toastr } from "react-redux-toastr";
import SearchForYourAssessmentModal from "./SearchForYourAssessmentModal";
import EditPossibleValuesModal from "../evidence-tab/EditPossibleValuesModal";
import PreviewDataCell from "./PreviewDataCell";
import FileRecognizedContent from "./FileRecognizedContent";
import FileUnrecognizedContent from "./FileUnrecognizedContent";
import MeasurementsTable from "./MeasurementsTable";
import { IS_READY_COACH } from "../../../../../constants";
import { addStudentMapToLocalStore } from "../../../../../utils/StudentMapUtils";
import { Grade, SkillFocusArea, TimePeriod } from "../../../../../store/groups/types";
import { GoalTypes } from "../../../../../store/onboarding/cases/types";
import { getSkillFocuses } from "../../../../../store/groups/actions";
import _ from "lodash";


library.add(faTimesCircle, faPlusCircle);

type PropsFromState = {
  showModal: boolean;
  originalFileName: string;
  columnsNames: Array<string>;
  currentDataPeriod?: DataPeriod;
  rawData: Array<any>;
  uploadCSVArray: any;
  isLoading: {
    uploadAssessmentData: boolean;
    assignStudentsToTeacher: Array<number>;
    uploadEvidenceData: boolean;
    addStudents: boolean;
    detectAssessment: boolean;
  };
  assessments: Array<Assessment>;
  evidencePeriodData?: EvidencePeriodData;
  selectedAssessment?: Assessment;
  teachers: Array<UserInfo>;
  errors: {
    uploadAssessmentData?: string;
    assignStudentsToTeacher: Array<{ teacherId: number; message: string }>;
    uploadEvidenceData?: string;
    addStudents?: string;
    detectAssessment?: string;
  };

  uploadedAssessment: uploadedAssessment;
  dataPeriods: Array<DataPeriod>;
  currentRole: UserAssignmentRole;
  currentAssignmentRole: UserAssignmentRole;
  myId: number;
  enterDataManuallyType?: GoalTypes;
  areas: SkillFocusArea[];
};

type DispatchProps = {
  uploadCsvFile: (
    fileName: string,
    columnsNames: Array<string>,
    rawData: Array<any>
  ) => any;
  uploadMultipleCsvFile: (uploadCSVArray:any) => any
  getEvidenceInDataPeriod: (dataPeriodId?: number) => any;
  clearStoredCsvFile: () => any;
  uploadEvidenceData: (evidence: EvidenceData) => any;
  assignStudentsToTeacher: (id: number, students: Array<Student>) => any;
  addStudents: (students: Array<Student>) => any;
  getTeachers: () => any;
  uploadAssessmentData: (
    assessmentId: number,
    rawCsvData: any,
    csvFileName: string,
    target_evidence_column_group_id?: number,
    dataPeriodId?: number,
    source?: string,
    grade?: Grade,
    timePeriod?: TimePeriod,
    year?: string,
    sheetRawData?: any,
    isDistrictOwner?: boolean,
    upload_in_existing_shared_dp?: boolean,
    shared_data_period_selected?: number[],
    new_students_only?: string,
    currentDataPeriodIds?: number[]
  ) => any;
  updateRawDataOfCsvFile: (rawData: any) => any;
  openSearchForYourAssessmentModal: () => any;
  onHide: () => void;
  hidePickDataSourceModal: () => any;
  getSkillFocuses: () => any;
};

type OwnProps = {
  storybookInitialState?: State;
  isDistrictOwner?: boolean;
};

type State = {
  selectedMeasurement?: Measurement;
  numPreviewRows: number;
  basicColumnsHeadersNum: Readonly<number>;
  showEditMeasureColorCriteriaModal: boolean;
  showEditPossibleValuesModal: boolean;
  fileAccepted: boolean;
  assessmentName: string;
  selectedStudentIdColumn: string;
  selectedStudentFirstNameColumn: string;
  selectedStudentLastNameColumn: string;
  selectedStudentTeacherColumn: string;
  selectedStudentGradeColumn: string;
  selectedStudentRaceColumn: string;
  selectedColumnNames: Array<string>;
  measurements: Array<Measurement>;
  selectedRows: Array<SelectedRow>;
  loadingTeacherIds: Array<number>;
  isMergeData: boolean;
  showConfirmation: boolean;
  useGradeSpecificColor: boolean;
  withReadingAlgorithm: boolean;
  supportedGrades: Grade[];
  timePeriods: TimePeriod[];
  benchmarkStatuses: BenchmarkStatus[];
  raBenchmarkForNumericType: ColorCriteria[];
  raBenchmarkForCategoryType: Pick<
    Measurement,
    "possible_values" | "color_criteria" | "type"
  >;
  newMeasurement: Measurement,
  // selectedSearchFilter: any;
  // searchFilter: any[];
  skill_areas: any;
  editMeasureIndex: number;
};

type SelectedRow = {
  isSelected: boolean;
  willBeImported: boolean;
  student: Student;
  teacher?: UserInfo;
};

type Props = PropsFromState & DispatchProps & OwnProps;

class UploadStudentDataModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRows: [],
      fileAccepted: false,
      // selectedAssessment: undefined,
      showEditMeasureColorCriteriaModal: false,
      showEditPossibleValuesModal: false,
      assessmentName: "",
      selectedStudentIdColumn: "",
      selectedStudentFirstNameColumn: "",
      selectedStudentLastNameColumn: "",
      selectedStudentTeacherColumn: "",
      selectedStudentGradeColumn: "",
      selectedStudentRaceColumn: "",
      selectedColumnNames: [],
      numPreviewRows: 3,
      basicColumnsHeadersNum: 3,
      measurements: [],
      loadingTeacherIds: [],
      isMergeData: false,
      showConfirmation: false,
      useGradeSpecificColor: false,
      withReadingAlgorithm: false,
      supportedGrades: ["K", "G1", "G2", "G3"],
      timePeriods: Object.values(TimePeriod),
      benchmarkStatuses: Object.values(BenchmarkStatus),
      raBenchmarkForNumericType: [],
      raBenchmarkForCategoryType: {
        possible_values: [],
        color_criteria: []
      },
      newMeasurement: {
        display_name: "",
        column_index: 0,
        column_name: "",
        type: MeasurementType.NUMBER,
        reading_skill: undefined,
        color_criteria: undefined
      },
      skill_areas: [],
      editMeasureIndex: 0
      // searchFilter: [{
      //   filterIndex: 0,
      //   filterGrade: [],
      //   filterAdditionalBanchmark: true,
      //   selectForK: [],
      //   selectForG1: [],
      //   selectForG2: [],
      //   selectForG3: [],
      // }],

      // selectedSearchFilter: {
      //   filterIndex: 0,
      //   filterGrade: [],
      //   filterAdditionalBanchmark: true,
      //   selectForK: [],
      //   selectForG1: [],
      //   selectForG2: [],
      //   selectForG3: [],
      // }

    };

    if (props.storybookInitialState) {
      this.state = Object.assign(this.state, props.storybookInitialState);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      prevProps.isLoading.uploadEvidenceData &&
      !this.props.isLoading.uploadEvidenceData
    ) {
      if (!this.props.errors.uploadEvidenceData) {
        this.props.hidePickDataSourceModal();
        this.props.getEvidenceInDataPeriod();
        const { rawData } = this.props;

        const studentMap = new Map<string, Student>(
          rawData.map((row) => [
            row[this.state.selectedStudentIdColumn],
            {
              first_name: row[this.state.selectedStudentFirstNameColumn],
              last_name: row[this.state.selectedStudentLastNameColumn],
            },
          ])
        );
        addStudentMapToLocalStore(studentMap);
      } else {
        toastr.error("Error", this.props.errors.uploadEvidenceData);
      }
    }

    if (
      prevProps.isLoading.uploadAssessmentData &&
      !this.props.isLoading.uploadAssessmentData
    ) {
      if (this.props.errors.uploadAssessmentData) {
        //toastr.error("Error", this.props.errors.uploadAssessmentData);
      } else {
        const { rawData, assessments } = this.props;
        if (assessments[0]) {
          const studentMap = new Map<string, Student>(
            rawData.map((row) => [
              row[assessments[0].unique_id_column],
              {
                // The below is a hack to work with Acadience
                first_name:
                  row[assessments[0].first_name_column] || row["first_name"],
                last_name:
                  row[assessments[0].last_name_column] || row["last_name"],
              },
            ])
          );
          addStudentMapToLocalStore(studentMap);
        }
        this.props.hidePickDataSourceModal();
        // RC-1557 stop the call 
        // if(this.props.currentAssignmentRole
        //   !== UserAssignmentRole.DISTRICT_OWNER) { // RC-1247 stop the calls
        //     this.props.getEvidenceInDataPeriod();
        //   }
      }
    }

    if (!prevState.fileAccepted && this.state.fileAccepted) {
      this.checkColumnNamesForAutoselection();
      this.initialSetState();
    }

    //MODAL OVERLAY CLASS HACKS

    // if (!prevProps.showModal && this.props.showModal) {
    //   document.body.style.overflow = "hidden";
    //   document.body.classList.add("modal-open");
    // }
    // if (prevProps.showModal && !this.props.showModal) {
    //   document.body.style.overflow = "";
    //   document.body.classList.remove("modal-open");
    // }
  }

  initialSetState = () => {

    this.props.getSkillFocuses();

    this.setState({
      skill_areas : this.state.useGradeSpecificColor
                      ? this.props.areas?.map((sf) => ({
                          label: sf.display_name,
                          value: sf.identifier,
                        })) ?? []
                      : []
    });

    const colorCriteriaList: ColorCriteria[] = [];
    this.state.supportedGrades.forEach((grade) => {
      this.state.timePeriods.forEach((timePeriod) => {
        this.state.benchmarkStatuses.forEach((benchmarkStatus) => {
          const colorCriteria: ColorCriteria = {
            display_name: BenchmarkStatusDisplayedNames[benchmarkStatus],
            color: BenchmarkStatusDefaultColors[benchmarkStatus],
            grade: grade,
            time_period: timePeriod,
            benchmark_status: benchmarkStatus,
          };
          colorCriteriaList.push(colorCriteria);
        });
      });
    });

    this.setState({ raBenchmarkForNumericType: colorCriteriaList}) ;

    this.setState({
      newMeasurement: {
          display_name: "",
          column_index: 0,
          column_name: "",
          type: MeasurementType.NUMBER,
          reading_skill: undefined,
          color_criteria: this.state.useGradeSpecificColor
            ? colorCriteriaList
            : undefined,
      }
    })

    this.setState((prevState: State) => ({
      ...prevState,
      measurements: []
    }), () =>
    this.setState((prevState: State) => ({
      ...prevState,
      measurements: [
        ...prevState.measurements,
        {
          display_name: "",
          column_index: 0,
          column_name: "",
          type: MeasurementType.NUMBER,
          reading_skill: undefined,
          color_criteria: this.state.useGradeSpecificColor
            ? colorCriteriaList
            : undefined,
      }
      ],
    })));


  this.setState(prevState => ({
    ...prevState,
    possible_values: this.state.benchmarkStatuses.map((benchmarkStatus, index) => ({
      display_name: BenchmarkStatusDisplayedNames[benchmarkStatus],
      order: index + 1,
    })),
    color_criteria: this.state.benchmarkStatuses.map((benchmarkStatus, index) => ({
      order: index + 1,
      selected_value_order: index + 1,
      display_name: BenchmarkStatusDisplayedNames[benchmarkStatus],
      color: BenchmarkStatusDefaultColors[benchmarkStatus],
    })),
  }));

  }

  checkColumnNamesForAutoselection = () => {
    const commonStudentIdRegex = /^(?:Student(?:\s|[_-])?)?id$/gi;
    const commonStudentIdColumn = this.props.columnsNames.find((columnName) =>
      columnName.match(commonStudentIdRegex)
    );
    const commonStudentFirstNameRegex = /^(?:Student(?:\s|[_-])?)?First(?:(?:\s|[_-])?Name)?$/gi;
    const commonStudentFirstNameColumn = this.props.columnsNames.find(
      (columnName) => columnName.match(commonStudentFirstNameRegex)
    );

    const commonStudentLastNameRegex = /^(?:Student(?:\s|[_-])?)?Last(?:(?:\s|[_-])?Name)?$/gi;
    const commonStudentLastNameColumn = this.props.columnsNames.find(
      (columnName) => columnName.match(commonStudentLastNameRegex)
    );

    const commonTeacherRegex = /^Teacher(?:(?:(?:\s|[_-])?Last)?(?:(?:\s|[_-])?Name)?)?$/gi;
    const commonTeacherColumn = this.props.columnsNames.find((columnName) =>
      columnName.match(commonTeacherRegex)
    );

    const commonGradeRegex = /^(?:Student(?:\s|[_-])?)?Grade$/gi;
    const commonGradeColumn = this.props.columnsNames.find((columnName) =>
      columnName.match(commonGradeRegex)
    );

    const commonRaceRegex = /^(?:Student(?:\s|[_-])?)?Race$/gi;
    const commonRaceColumn = this.props.columnsNames.find((columnName) =>
      columnName.match(commonRaceRegex)
    );

    const selectedColumnNames: string[] = [
      commonStudentIdColumn,
      commonStudentFirstNameColumn,
      commonStudentLastNameColumn,
      commonTeacherColumn,
      commonGradeColumn,
      commonRaceColumn,
    ].filter((s) => s !== undefined) as string[];

    this.setState((state) => ({
      ...state,
      selectedColumnNames: [
        ...state.selectedColumnNames,
        ...selectedColumnNames,
      ],
      selectedStudentIdColumn: commonStudentIdColumn
        ? commonStudentIdColumn
        : state.selectedStudentIdColumn,
      selectedStudentFirstNameColumn: commonStudentFirstNameColumn
        ? commonStudentFirstNameColumn
        : state.selectedStudentFirstNameColumn,
      selectedStudentLastNameColumn: commonStudentLastNameColumn
        ? commonStudentLastNameColumn
        : state.selectedStudentLastNameColumn,
      selectedStudentTeacherColumn: commonTeacherColumn
        ? commonTeacherColumn
        : state.selectedStudentTeacherColumn,
      selectedStudentGradeColumn: commonGradeColumn
        ? commonGradeColumn
        : state.selectedStudentGradeColumn,
      selectedStudentRaceColumn: commonRaceColumn
        ? commonRaceColumn
        : state.selectedStudentRaceColumn,
    }));
  };

  handleAcceptFile = () => {
    if (this.props.originalFileName) this.setState({ fileAccepted: true });
  };

  handleChange = (event: React.FormEvent) => {
    const { name, value } = event.target as HTMLInputElement;
    this.setState((prevState: State) => {
      const selectedColumnNames =
        name !== "assessmentName"
          ? [
              ...prevState.selectedColumnNames.filter(
                (columnName: string) => (prevState as any)[name] !== columnName
              ),
              value,
            ]
          : prevState.selectedColumnNames;
      return {
        ...prevState,
        [name]: value,
        selectedColumnNames: selectedColumnNames,
      };
    });
  };

  handleMeasurementAdd = () => {
    this.setState((prevState: State) => ({
      ...prevState,
      measurements: [
        ...prevState.measurements,
        // { display_name: "", column_name: "", type: MeasurementType.NUMBER },
        this.state.newMeasurement
      ],
    }));
  };

  handleMeasurementsChange = (id: number, columnNamesSelector?: boolean) => (
    event: React.SyntheticEvent
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    this.setState((prevState: State) => {
      const measurements = prevState.measurements.map(
        (measurement: Measurement, index: number) => {
          if (index === id) {
            if (name === "type") {
              const defTypeValues = this.getDefaultTypeValues(
                +value,
                measurement
              );
              return {
                ...measurement,
                ...defTypeValues,
                [name]: +value,
              };
            }
            if (name === "column_name") {
              return {
                ...measurement,
                [name]: value,
                display_name: measurement.display_name || value,
              };
            }
            return {
              ...measurement,
              [name]: value,
            };
          }
          return measurement;
        }
      );
      return {
        ...prevState,
        measurements: measurements,
        selectedColumnNames: columnNamesSelector
          ? [
              ...prevState.selectedColumnNames.filter(
                (columnName) =>
                  columnName !== prevState.measurements[id].column_name
              ),
              value,
            ]
          : prevState.selectedColumnNames,
      };
    });
  };

  handleSkillAreasChange = (index:number, skill: string) => {
    const updatedMeasurement:any = [...this.state.measurements.map((m, mIndex) =>
      index === mIndex
        ? { ...m, reading_skill: skill }
        : m
    )]
    this.setState({measurements: updatedMeasurement})
  }

  handlePossibleValuesChange = (measurement: Measurement) => (index: number) => {
    
    this.setState({
      selectedMeasurement: {...measurement, column_index: index},
      showEditPossibleValuesModal: true,
    });
  };

  getDefaultTypeValues(measureType: MeasurementType, measurement: Measurement) {
    let defaultValues: Partial<Measurement>;
    switch (measureType) {
      case MeasurementType.NUMBER:
        defaultValues = {
          range_min: 200,
          range_max: 800,
          possible_values: undefined,
          color_criteria: undefined,
        };
        break;
      case MeasurementType.PERCENTAGE:
        defaultValues = {
          range_min: 0,
          range_max: 100,
          possible_values: undefined,
          color_criteria: undefined,
        };
        break;
      case MeasurementType.CATEGORY: {
        const uniqueValues = this.props.rawData
          .map((rawRow) => rawRow[measurement.column_name!])
          .filter((item, index, arr) => arr.indexOf(item) >= index);
        const possible_values: Array<PossibleValue> = uniqueValues.map(
          (value: string, index: number) => ({
            order: index + 1, //on backend start at 1
            display_name: value,
          })
        );
        defaultValues = {
          range_min: undefined,
          range_max: undefined,
          color_criteria: undefined,
          possible_values: possible_values,
        };
        break;
      }
      default:
        defaultValues = {};
        break;
    }
    return defaultValues;
  }

  handleMeasureEdit = (measurement: Measurement, index: number) => () => {
    // if(!this.state.searchFilter.some((search:any)=> search.filterIndex === index)) {
    //   if(this.state.selectedSearchFilter.filterIndex !== index) {
    //     this.setState({ 
    //       searchFilter: [
    //       ...this.state.searchFilter, 
    //       {
    //         filterIndex: index,
    //         filterGrade: [],
    //         filterAdditionalBanchmark: true,
    //         selectForK: [],
    //         selectForG1: [],
    //         selectForG2: [],
    //         selectForG3: [],
    //       }
    //     ]}, () => this.setState({selectedSearchFilter: this.state.searchFilter[index]}))
    //   }
    // } else {
    //   this.setState({selectedSearchFilter: this.state.searchFilter[index]});
    // } 

    
    this.setState({
      editMeasureIndex: index,
      selectedMeasurement: measurement,
      showEditMeasureColorCriteriaModal: true,
    });
  };

  handleMeasurementRemove = (changingIndex: number) => () => {
    this.setState((prevState: State) => {
      const columnName = prevState.measurements[changingIndex].column_name;

      const selectedColumnNames = prevState.selectedColumnNames.filter(
        (val) => val != columnName
      );

      const measurements = prevState.measurements.filter(
        (measurement: Measurement, index: number) => !(index === changingIndex)
      );
      return {
        ...prevState,
        selectedColumnNames,
        measurements: measurements,
      };
    });
  };

  handleEditMeasureColorCriteriaModalHide = () => {
    this.setState({ showEditMeasureColorCriteriaModal: false });
  };

  handleEditPossibleValuesModalHide = () => {
    this.setState({ showEditPossibleValuesModal: false });
  };

  handleColorCriteriaChangesApply = (updatedMeasurement: Measurement) => {
    this.setState((prevState: State) => ({
      showEditMeasureColorCriteriaModal: false,
      selectedMeasurement: undefined,
      measurements: prevState.measurements.map(
        (measurement: Measurement): Measurement => {
          if (measurement.column_name === updatedMeasurement!.column_name) {
            return {
              ...measurement,
              color_criteria: updatedMeasurement!.color_criteria,
            };
          }
          return measurement;
        }
      ),
    }));
  };

  handlePossibleValuesChangesApply = (updatedMeasurement: Measurement) => (
    event: React.FormEvent
  ) => {
    event.preventDefault();
    this.setState((prevState: State) => ({
      showEditPossibleValuesModal: false,
      selectedMeasurement: undefined,
      measurements: prevState.measurements.map(
        (measurement: Measurement): Measurement => {
          if (measurement.column_name === updatedMeasurement!.column_name) {
            return updatedMeasurement;
          }
          return measurement;
        }
      ),
    }));
  };

  handleDisplayAllRows = () => {
    this.setState({
      numPreviewRows: this.props.rawData.length,
    });
  };

  ensureDataHasUniqueIDs = (data: any, studentIdColumn: string): any => {
    const timeStamp = new Date().getTime();
    return data.map((row: any, index: number) => {
      let studentId: string | undefined;
      if (row[studentIdColumn]) {
        studentId = (row[studentIdColumn].toString() || "").trim();
        // Hack for Acadience
      } else if (row["student_id"]) {
        studentId = (row["student_id"].toString() || "").trim();
      } else if (row["StudentId"]) {
        // Hack for dessa_main. Becuase originally The studentIdColumn is Student ID 
        // but some files may have StudentId
        studentId = (row["StudentId"].toString() || "").trim();
      }  else if (row["Student ID#"]) {
        // Hack for dessa_main. Becuase originally The studentIdColumn is Student ID 
        // but some files may have StudentId
        studentId = row["Student ID#"];
        studentIdColumn = "Student ID#";
      }

      // if (studentId === undefined || (studentId && studentId.length === 0)) {
      //   studentId = `${timeStamp}_auto_${index}`;
      // }

      const copy = row;
      copy[studentIdColumn] = studentId;

      return copy;
    });
  };

  handleUploadCustomData = (event: React.FormEvent<any>) => {
    event.preventDefault();

    const first_name_column = this.state.selectedStudentFirstNameColumn;
    const last_name_column = this.state.selectedStudentLastNameColumn;
    const student_id_column = this.state.selectedStudentIdColumn;
    const {
      selectedStudentTeacherColumn,
      selectedStudentGradeColumn,
      selectedStudentRaceColumn
    } = this.state;

    let data = this.props.rawData;

    data = this.ensureDataHasUniqueIDs(data, student_id_column);
    this.props.updateRawDataOfCsvFile(data);

    if (!IS_READY_COACH) {
      data = data.map((row) => ({
        ...row,
        [first_name_column]: undefined,
        [last_name_column]: undefined,
      }));
    }

    event.preventDefault();
    const measurementArray = [...this.state.measurements.map((measurement)=> {
      return {
        ...measurement,
        color_criteria: measurement.color_criteria?.filter((data:any)=> (data.order != undefined))
      }
    })];
    

    let isScoreRangeExist: boolean = true;
    for (let i = 0; i < measurementArray.length; i++) {
      const measurement = measurementArray[i];
      if(!measurement.color_criteria?.length){
        isScoreRangeExist = false;
        break;
      }
    }
    //dataColumnFormEl.current?.reportValidity();
    if (isScoreRangeExist) {
      const evidence: EvidenceData = {
        raw_csv: Papa.unparse(data),
        csv_file_name: this.props.originalFileName,
        data_period_id: this.props.currentDataPeriod!.id,
        first_name_column: first_name_column,
        last_name_column: last_name_column,
        unique_id_column: student_id_column,
        teacher_column: selectedStudentTeacherColumn,
        grade_column: selectedStudentGradeColumn,
        race_column: selectedStudentRaceColumn,
        assessment: {
          with_reading_algorithm: this.state.withReadingAlgorithm,
          use_grade_specific_color_criteria: this.state.useGradeSpecificColor,
         // is_behavior: this.props.enterDataManuallyType == "academic" ? false : true,
          name: this.state.assessmentName,
          data_columns: this.state.useGradeSpecificColor
            ? this.state.measurements.map((m) => ({
                ...m,
                color_criteria: [],
                grade_specific_color_criteria: _.chain(m.color_criteria || [])
                  .filter((data:any)=> (data.range_max !== undefined) || (data.range_min !== undefined))
                  .groupBy((cc) => cc.grade)
                  .mapValues((cList) =>
                    _.chain(cList)
                      .groupBy((cc) => cc.time_period)
                      .value()
                  )
                  .value(),
              }))
            : this.state.measurements,
        },
      };
      this.props.uploadEvidenceData(evidence);
    } else {
      toastr.error("Unable to upload evidence data", "Color criteria(s) missing for one of the Data Column(s)");   
      return; 
    }
  };

  handleSaveStudentData = (
    assessment: Assessment,
    grade?: Grade,
    timePeriod?: TimePeriod,
    year?: string,
    upload_in_existing_shared_dp?: boolean,
    shared_data_period_selected?: number[],
    new_students_only?: string
  ) => {
    let target_evidence_column_group_id;
    if (this.props.evidencePeriodData) {
      const ecg = this.props.evidencePeriodData.evidence_column_groups.find(
        (ecg) => ecg.assessment && ecg.assessment.id === assessment.id
      );
      target_evidence_column_group_id = ecg ? ecg.id : undefined;
    }

    let data = this.props.rawData;
    // Problem with Dessa having two kinds of student id 
    const unique_id = assessment.source == 'dessa_main'
    data = this.ensureDataHasUniqueIDs(data, assessment.unique_id_column);

    this.props.updateRawDataOfCsvFile(data);

    if (!IS_READY_COACH) {
      data = data.map((row) => ({
        ...row,
        [assessment.first_name_column]: undefined,
        [assessment.last_name_column]: undefined,
      }));
    }

    let sheetRawData:any[] = [];
    let uploadCSVArray = this.props.uploadCSVArray;
    let isAtLeastOneSheetExist = false;
    for (const key in uploadCSVArray) {
      if(!Object.prototype.isPrototypeOf.call(uploadCSVArray, key)) {
        isAtLeastOneSheetExist = true;
        if(uploadCSVArray[key]?.rawData?.length) {
          let time_period:string = '';
          if(key == 'Fall Data Entry') {
            time_period = 'boy';
          } else if(key == 'Winter Data Entry') {
            time_period = 'moy';
          } else if(key == 'Spring Data Entry') {
            time_period = 'eoy';
          }
          // Clean up the data
          uploadCSVArray[key].rawData.forEach((row: any) => {
            for (let prop in row) {
              // Delete any empty fields
              if (prop === "") {
                delete row[prop];
              }
            }
          });
          const element = {
            raw_csv: Papa.unparse(uploadCSVArray[key].rawData),
            grade: uploadCSVArray[key].grade,
            time_period: time_period,
            year: uploadCSVArray[key].year
          }
          sheetRawData.push(element);
        }
      }
    }

    if(isAtLeastOneSheetExist && !sheetRawData.length) {
      toastr.error("Alert","Data not found in njtss file");
      return;
    }


    this.props.uploadAssessmentData(
      assessment.id,
      data,
      this.props.originalFileName,
      target_evidence_column_group_id,
      this.props.currentDataPeriod
        ? this.props.currentDataPeriod.id
        : (this.props.currentRole == 'district_owner') && this.props.dataPeriods.length 
            ? this.props.dataPeriods[0]['id'] :undefined,
      assessment.source,
      grade,
      timePeriod,
      year,
      sheetRawData,
      this.props.isDistrictOwner || false,
      upload_in_existing_shared_dp,
      shared_data_period_selected,
      new_students_only,
      this.props.dataPeriods.map((dp) => dp?.id)
    ).then((result:any)=> {
      this.setState({showConfirmation: true});
      if(result) {
        toastr.success('', result)
      }
    },
    (err: string) => {
      toastr.error("Failed", err);
    }
    );
  };

  // handleSelectedValueChange = (unique_id: string) => (
  //   value: ValueType<UserInfo, false>
  // ) => {
  //   this.setState((prevState) => ({
  //     selectedRows: prevState.selectedRows.map((selectedRow) => {
  //       if (unique_id === selectedRow.student.unique_id) {
  //         return {
  //           ...selectedRow,
  //           teacher: value as UserInfo,
  //         };
  //       }
  //       return selectedRow;
  //     }),
  //   }));
  // };

  handleSelectedChange = (unique_id: string) => (
    event: React.ChangeEvent<any>
  ) => {
    const { checked } = event.target;
    this.setState((prevState) => ({
      selectedRows: prevState.selectedRows.map((selectedRow) => {
        if (unique_id === selectedRow.student.unique_id) {
          return {
            ...selectedRow,
            isSelected: checked,
          };
        }
        return selectedRow;
      }),
    }));
  };

  handleAllSelectedChange = (event: React.ChangeEvent<any>) => {
    const { checked } = event.target;
    this.setState((prevState) => ({
      selectedRows: prevState.selectedRows.map((selectedRow) => ({
        ...selectedRow,
        isSelected: checked,
      })),
    }));
  };

  checkLoading = (props: Props) => {
    return props.isLoading.assignStudentsToTeacher.some((x) =>
      this.state.loadingTeacherIds.includes(x)
    );
  };

  handleNotImportRow = (selectedRow: SelectedRow) => () => {
    this.setState((prevState) => ({
      ...prevState,
      selectedRows: prevState.selectedRows.map((row) => {
        if (row.student.unique_id === selectedRow.student.unique_id) {
          return {
            ...row,
            isSelected: false,
            willBeImported: false,
          };
        }
        return row;
      }),
    }));
  };

  handleAssignSelectedStudentToTeacher = (teacher: UserInfo) => () => {
    const notSelected: boolean = this.state.selectedRows.some(
      (row) => row.isSelected
    );
    this.setState((prevState) => ({
      ...prevState,
      selectedRows: prevState.selectedRows.map((row) => {
        if (!notSelected || row.isSelected) {
          return { ...row, teacher: teacher };
        }
        return row;
      }),
    }));
  };

  handleModalShow = () => {
    if (!this.state.isMergeData) {
      
      //add condition
      this.setState({
        selectedRows: [],
        fileAccepted: false,
        showEditMeasureColorCriteriaModal: false,
        assessmentName: "",
        selectedStudentIdColumn: "",
        selectedStudentFirstNameColumn: "",
        selectedStudentLastNameColumn: "",
        selectedColumnNames: [],
        numPreviewRows: 3,
        measurements: [],

        showEditPossibleValuesModal: false,
        selectedMeasurement: undefined,
        //useGradeSpecificColor: this.props.enterDataManuallyType == 'academic' ? true : false,
        useGradeSpecificColor: true,
        withReadingAlgorithm: false,
        // searchFilter: [{
        //   filterIndex: 0,
        //   filterGrade: [],
        //   filterAdditionalBanchmark: true,
        //   selectForK: [],
        //   selectForG1: [],
        //   selectForG2: [],
        //   selectForG3: [],
        // }]
      });
      //(this.props.enterDataManuallyType == 'academic') && this.refreshColorCriteria(true);
      this.refreshColorCriteria(true);
      this.props.clearStoredCsvFile();
    }
  };

   refreshColorCriteria = (checked: boolean) => {
    this.setState({
      measurements: 
        [...this.state.measurements.map((m) => ({
          ...m,
          ...(m.type === MeasurementType.CATEGORY
            ? this.state.raBenchmarkForCategoryType
            : { color_criteria: checked ? this.state.raBenchmarkForNumericType : [] }),
        }
    ))]
    })
  };

  handleModalHide = () => {
    this.setState({ measurements: []}, 
      () => this.props.onHide());
  };

  handleAlertModalHide = () => {
   this.setState({showConfirmation: false})
  }

  // handleSearchFilter = (selectedSearchFilter:any) => {
  //   if(this.state.searchFilter.some((search:any)=> search.filterIndex === selectedSearchFilter.filterIndex)) {
  //     let copyObj = {...selectedSearchFilter};
  //     this.setState(searchFilter => ({
  //       ...searchFilter,
  //       [copyObj.filterIndex]: copyObj
  //     }), () =>  this.setState(selectedSearchFilter => ({
  //       ...selectedSearchFilter,
  //       [copyObj.filterIndex]: copyObj
  //     })));
  //     //this.props.setSearchFilterState(this.state.searchFilter)
  //   } 
  // }

  render() {
    const { originalFileName, columnsNames, rawData, myId, currentRole } = this.props;
    if(rawData.length > 10000) {
      toastr.error('Alert!!', 'Number of rows in the file should not be more than 10000')
    }
    const {
      selectedStudentIdColumn,
      selectedStudentFirstNameColumn,
      measurements,
      selectedMeasurement,
      selectedStudentLastNameColumn,
      selectedStudentTeacherColumn,
      selectedStudentGradeColumn,
      selectedStudentRaceColumn,
      selectedColumnNames,
      assessmentName,
      showConfirmation,
      useGradeSpecificColor,
      withReadingAlgorithm,
      fileAccepted,
      basicColumnsHeadersNum,
      skill_areas,
      showEditMeasureColorCriteriaModal,
      //selectedSearchFilter,
      showEditPossibleValuesModal,
      newMeasurement
    } = this.state;
    const basicColumnsHeadersSelected = 
      selectedStudentIdColumn &&
      selectedStudentFirstNameColumn &&
      selectedStudentLastNameColumn;

    return (
      <>
        {(!this.props.errors.uploadAssessmentData) && !!this.props.uploadedAssessment.data_period_id && showConfirmation && 
          <Modal
            id={"updated-data-period-model"}
            animation={false}
            backdropClassName="customDarkModalBackdrop in"
            show={showConfirmation}
            size="sm"
            onHide={() => this.handleAlertModalHide()}
          >
            <Modal.Header closeButton className="modalHeader">
              <Modal.Title className="modalTitle">
              File Uploaded
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>The file has been uploaded into the data period(s) listed below:</p>
                {(this.props.uploadedAssessment.all_data_periods != undefined)
                 ? 
                 <>
                    <ul className="row">
                    {this.props.uploadedAssessment.all_data_periods.map((data)=>
                      <li className="col-12">
                        {data.name}  
                        {
                        [ UserAssignmentRole.COACH,
                          UserAssignmentRole.TEACHER
                        ].includes(currentRole) && myId !== data.user_id
                        ? ` (Shared by ${
                            data.user_name ? data.user_name : data.user_email
                          })`
                        : ""
                        }
                      </li>
                    )}
                    </ul>
                 </>
                  :
                 <ul className="row">
                  {this.props.dataPeriods.filter((data)=> data.id == this.props.uploadedAssessment.data_period_id)
                  .map((dataPeriod)=> <li className="col-12">{dataPeriod.name}</li>)}
                 </ul>
                 
                }
              <hr />
              <div className="btnActions d-flex justify-content-center">
                <button onClick={() => this.handleAlertModalHide()} className="blueBtnSm">Ok</button>
              </div>
            </Modal.Body>
          </Modal>
        }
        {
          <Modal
            id={"upload-student-data-modal"}
            animation={false}
            backdropClassName="customDarkModalBackdrop in"
            show={this.props.showModal}
            size="lg"
            onShow={this.handleModalShow}
            onHide={this.handleModalHide}
          >
            <Modal.Header closeButton className="modalHeader">
              <Modal.Title className="modalTitle">
                Upload Student Data from a File
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {originalFileName && (
                <UploadInfo
                  originalFileName={originalFileName}
                  rawData={rawData}
                />
              )}
              {!fileAccepted ? (
                !originalFileName ? (
                  <UploadingDataGuide />
                ) : (
                  <>
                    <br />
                    {this.props.isLoading.detectAssessment ? (
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" />
                      </div>
                    ) : !this.props.assessments ? (
                      <FileUnrecognizedContent
                        openSearchForYourAssessmentModal={
                          this.props.openSearchForYourAssessmentModal
                        }
                        handleAcceptFile={this.handleAcceptFile}
                        onHide={this.handleModalHide}
                      />
                    ) : (
                      <FileRecognizedContent
                        handleSaveStudentData={this.handleSaveStudentData}
                        handleAcceptFile={this.handleAcceptFile}
                        assessments={this.props.assessments}
                        uploadAssessmentData={
                          this.props.isLoading.uploadAssessmentData
                        }
                        rawData={rawData}
                      />
                    )}
                  </>
                )
              ) : (
                <>
                  <Form
                    id="uploadStudentDataForm"
                    onSubmit={this.handleUploadCustomData}
                  >
                    <ColumnHeadersSelectorPanel
                      assessmentName={assessmentName}
                      onChange={this.handleChange}
                      studentIdColumnHeader={selectedStudentIdColumn}
                      selectedColumnNames={selectedColumnNames}
                      columnNames={columnsNames}
                      studentFNameColumnHeader={selectedStudentFirstNameColumn}
                      studentLNameColumnHeader={selectedStudentLastNameColumn}
                      studentTeacherColumnHeader={selectedStudentTeacherColumn}
                      studentGradeColumnHeader={selectedStudentGradeColumn}
                      studentRaceColumnHeader={selectedStudentRaceColumn}
                    />
                    {//this.props.enterDataManuallyType == "academic" ? 
                    (
                      <>
                        <p className="assessmentCopyText">
                        Click the first checkbox below if you would like to use grade and time period specific color criteria.
                        </p>
                        <p className="assessmentCopyText">
                          Click the second checkbox if you you would like to use this assessment with the ReadyCoach reading algorithms, 
                          if checked, you will be asked to map your assessment’s Data Columns to reading skills in the table below.
                        </p>
                        <div className="row mt-3">
                          <div className="col-5">
                            <Form.Check
                              id="use_grade_specific_color"
                              className="mb-3 font-weight-bold"
                              checked={useGradeSpecificColor}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                this.refreshColorCriteria(checked);
                                this.setState({useGradeSpecificColor: checked});
                                if(!checked) {
                                  this.setState({withReadingAlgorithm:false});
                                }
                              }}
                              label="Use Grade and Time Period Specific Color Criteria"
                              custom
                              type="checkbox"
                            />
                          </div>
                          <div className="col-7 pl-0">
                            {/* (this.props.enterDataManuallyType == "academic") &&   */}
                            {this.state.useGradeSpecificColor == false && (
                              <div className="alert alert-warning py-1" role="alert">
                                Benchmarks will apply to all grades and time periods.
                              </div>
                            )}
                          </div>
                        </div>
                        {useGradeSpecificColor ?
                          <div className="row">
                            <div className="col-4">
                              <Form.Check
                                id="with_reading_algorithm"
                                className="mb-3 font-weight-bold"
                                checked={withReadingAlgorithm}
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  this.setState({withReadingAlgorithm: checked});
                                  if(!checked) {
                                    this.setState((measurements) =>({
                                      ...measurements,
                                      measurements: this.state.measurements.map((measure) => {
                                        return {
                                          ...measure,
                                          reading_skill: ""
                                        }
                                      })
                                      })
                                    );
                                  }
                                }}
                                label="Use with Reading Algorithm"
                                custom
                                type="checkbox"
                              />
                            </div>
                          </div>
                        : ''
                        }
                      </>
                    ) 
                    // : (
                    //   ""
                    // )
                    }
                     <h6 className="font-weight-bold"> Data columns</h6>
                    <hr className="m-1" />
                    {/* <DataMetricColumnsPreview
                      showTips={measurements.length === 0}
                      basicColumnsHeadersSelected={basicColumnsHeadersSelected}
                      onClick={this.handleMeasurementAdd}
                    /> */}
                    {measurements.length > 0 && (
                      <MeasurementsTable
                        basicColumnsHeadersNum={
                          basicColumnsHeadersNum
                        }
                        columnsNames={columnsNames}
                        handleMeasureEdit={this.handleMeasureEdit}
                        handleMeasurementAdd={this.handleMeasurementAdd}
                        handleMeasurementRemove={this.handleMeasurementRemove}
                        handleMeasurementsChange={this.handleMeasurementsChange}
                        handlePossibleValuesChange={
                          this.handlePossibleValuesChange
                        }
                        measurements={measurements}
                        selectedColumnNames={selectedColumnNames}
                        useGradeSpecificColor={useGradeSpecificColor}
                        withReadingAlgorithm={withReadingAlgorithm}
                        skill_areas={skill_areas}
                        handleSkillAreaChange={this.handleSkillAreasChange}
                        newMeasurement={newMeasurement}
                      />
                    )}
                  </Form>

                  {basicColumnsHeadersSelected && (
                    <>
                      <h6 className="font-weight-bold"> Data preview</h6>
                      <Table className="stdTable">
                        <thead>
                          <tr>
                            <th>Student</th>
                            <th>ID</th>
                            {measurements.length > 0 &&
                              measurements.map(
                                (measurement: Measurement, index: number) => (
                                  <th
                                    key={index}
                                    style={{ borderLeft: "3px #ffffff solid" }}
                                  >
                                    {measurement.display_name}
                                  </th>
                                )
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {rawData
                            .slice(0, this.state.numPreviewRows)
                            .map((element: any) => (
                              <tr key={element[selectedStudentIdColumn]}>
                                <td>{`${element[selectedStudentFirstNameColumn]} ${element[selectedStudentLastNameColumn]}`}</td>
                                <td>{element[selectedStudentIdColumn]}</td>
                                {measurements.length > 0 &&
                                  measurements.map(
                                    (
                                      measurement: Measurement,
                                      index: number
                                    ) => (
                                      <PreviewDataCell
                                        key={measurement.id}
                                        index={index}
                                        element={element}
                                        measurement={measurement}
                                      />
                                    )
                                  )}
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      {rawData.length > this.state.numPreviewRows && (
                        <h6
                          className="font-weight-bold"
                          onClick={this.handleDisplayAllRows}
                        >
                          And {rawData.length - this.state.numPreviewRows}{" "}
                          more...
                        </h6>
                      )}
                    </>
                  )}

                  {showEditMeasureColorCriteriaModal && <EditMeasureColorCriteriaModal
                    withGrades={useGradeSpecificColor}
                    useGradeSpecificColor={useGradeSpecificColor}
                    withReadingAlgorithm = {withReadingAlgorithm}
                    onApplyChanges={this.handleColorCriteriaChangesApply}
                    onHide={this.handleEditMeasureColorCriteriaModalHide}
                    measurement={selectedMeasurement}
                    showModal={showEditMeasureColorCriteriaModal}
                    // searchFilter={selectedSearchFilter}
                    // onHandleSearchFilter={() => this.handleSearchFilter}
                    editMeasureColorIndex={this.state.editMeasureIndex}
                  />}
               

                  <EditPossibleValuesModal
                    showModal={showEditPossibleValuesModal}
                    measurement={selectedMeasurement}
                    onApplyChanges={this.handlePossibleValuesChangesApply}
                    onHide={this.handleEditPossibleValuesModalHide}
                  />

                  <div className={"text-right"}>
                    <button
                      type="submit"
                      form="uploadStudentDataForm"
                      disabled={measurements.length === 0}
                      className="blueBtnMd"
                    >
                      {`SAVE AND IMPORT ${rawData.length} ROWS OF ${assessmentName} DATA`}{" "}
                      {this.props.isLoading.uploadEvidenceData && (
                        <Spinner animation="border" size="sm" />
                      )}
                    </button>
                  </div>
                </>
              )}
            </Modal.Body>
          </Modal>
        }
        <SearchForYourAssessmentModal />
        
      </>

    );
  }
}

const mapStateToProps = ({
  onboarding,
  groups,
  auth
}: ApplicationState): PropsFromState => {
  const { loadableFile } = onboarding;
  return {
    originalFileName: loadableFile ? loadableFile.originalFileName! : "",
    rawData: loadableFile ? loadableFile.rawData : [],
    currentDataPeriod: onboarding.currentDataPeriod,
    currentRole: auth.userInfo?.profile.current_role!,
    currentAssignmentRole: auth.userInfo?.profile.current_assignment?.role!,
    myId: auth.userInfo?.id!,
    columnsNames: loadableFile ? loadableFile.columnsNames : [],
    uploadCSVArray: loadableFile ? loadableFile.uploadCSVArray : [],
    showModal: onboarding.modalsState.uploadStudentDataModal,
    enterDataManuallyType: onboarding.modalsState.enterDataManuallyType,
    assessments: onboarding.assessments,
    evidencePeriodData: onboarding.evidencePeriodData,
    selectedAssessment: groups.selectedAssessment,
    teachers: onboarding.teachersRoster.map((teacher) => teacher.user),
    uploadedAssessment: onboarding.uploadedAssessment,
    dataPeriods: onboarding.dataPeriods,
    areas: groups.skillFocusInfo,
    isLoading: {
      uploadAssessmentData: onboarding.isLoading.uploadAssessmentData,
      assignStudentsToTeacher: onboarding.isLoading.assignStudentsToTeacher,
      addStudents: onboarding.isLoading.addStudents,
      uploadEvidenceData: onboarding.isLoading.uploadEvidenceData,
      detectAssessment: onboarding.isLoading.detectAssessment,
    },
    errors: {
      uploadAssessmentData: onboarding.errors.uploadAssessmentData,
      assignStudentsToTeacher: onboarding.errors.assignStudentsToTeacher,
      addStudents: onboarding.errors.addStudents,
      uploadEvidenceData: onboarding.errors.uploadEvidenceData,
      detectAssessment: onboarding.errors.detectAssessment,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getEvidenceInDataPeriod: getEvidenceInDataPeriod,
      uploadCsvFile: uploadCsvFile,
      uploadMultipleCsvFile: uploadMultipleCsvFile,
      clearStoredCsvFile: clearStoredCsvFile,
      assignStudentsToTeacher: assignStudentsToTeacher,
      addStudents: addStudents,
      getTeachers: getTeachers,
      updateRawDataOfCsvFile: updateCsvFileRawData,
      uploadEvidenceData: uploadEvidenceData,
      uploadAssessmentData: uploadAssessmentData,
      openSearchForYourAssessmentModal: openSearchForYourAssessmentModal,
      onHide: hideUploadStudentDataModal,
      hidePickDataSourceModal: hidePickDataSourceModal,
      getSkillFocuses: getSkillFocuses
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadStudentDataModal);
