import React, { FunctionComponent } from "react";
import { Form } from "react-bootstrap";
import { showRecommendations } from "../../../../../../store/onboarding/cases/actions";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { hideConfirmDialog, showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";
import { isUrlBlocked } from "../../../../../../store/onboarding/actions";

type OwnProps = {};

type Props = OwnProps;

const ShowRecommendationsCheckBox: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const isURLBlocked = useSelector((s: ApplicationState) => s.onboarding.isUrlBlocked);
  
  const showArchivedGroups = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.dataFilter.showArchivedGroups
  );

  const handleShowRecommendedCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    
    if (isURLBlocked && (window.location.pathname.includes('groups'))) {
      dispatch(
        showConfirmDialog({
          title: "Confirm Navigation",
          text:
            "If you leave/refresh this page, any actions taken with the recommended groups will not be retained. Are you sure you want to leave? ",
          onConfirm: () => {
            dispatch(isUrlBlocked(false));
            showRecommendationsCheckBox(checked)
            dispatch(hideConfirmDialog());
          },
          confirmButtonText: "Leave this Page",
          cancelButtonText: "Stay on this page",
          modalClassName: "navigation-confirm"
        })
      );
    } else {
      showRecommendationsCheckBox(checked)
    }
  };
  
  const showRecommendationsCheckBox = (checked:any) => {
    //const { checked } = e.target;
    dispatch(showRecommendations(checked));
  }

  const showRecommendedGroups = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.cases.showRecommendedGroups);

  if (showArchivedGroups) {
    return null;
  }

  return (
    <div className="recommendations-checkbox">
      <Form.Check
        type="switch"
        id="show-recommendation-switch"
        label="Show Recommendations"
        checked={showRecommendedGroups}
        onChange={handleShowRecommendedCheck}
      />
    </div>
  );
};

export default ShowRecommendationsCheckBox;
