import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { setShowNoAbcDataCollectionIntro } from "../../../../../store/onboarding/cases/actions";
import FbaTable from "./FbaTable";
import IntroPanel from "./IntroPanel";
import LoadingIndicator from "../../../../common/onboarding/LoadingIndicator";
import { Fba } from "../../../../../store/onboarding/cases/types";

type OwnProps = {};

type Props = OwnProps;

const FbaDashboardContainer: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const showIntro = useSelector<ApplicationState>(
    (s) => s.cases.showNoAbcDataCollectionIntro
  );
  const fbas = useSelector<ApplicationState, Fba[]>((s) => s.cases.fbas);
  const isLoading = useSelector<ApplicationState>(
    (s) => s.cases.isLoading.getFbas
  );

  const handleGotItClick = () => {
    dispatch(setShowNoAbcDataCollectionIntro(false));
  };

  const handleQuestionClick = () => {
    dispatch(setShowNoAbcDataCollectionIntro(true));
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return showIntro && !fbas.length ? (
    <IntroPanel onClick={handleGotItClick} />
  ) : (
    <FbaTable onQuestionClick={handleQuestionClick} />
  );
};

export default FbaDashboardContainer;
