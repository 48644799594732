import React, { FunctionComponent } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDateInput from "./common/CustomDateInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

type OwnProps = {
  targetDate?: Date;
  setTargetDate: React.Dispatch<React.SetStateAction<Date | any>>;
};

type Props = OwnProps;

const SetGoalDeadlinePanel: FunctionComponent<Props> = ({
  targetDate,
  setTargetDate,
}) => {
  return (
    <>
      <div className="goalDeadlineContainer">
        <h4 className="font-weight-bold mb-2">
          When do you hope to achieve this by?
        </h4>

        <DatePicker
          customInput={<CustomDateInput />}
          selected={targetDate}
          minDate={new Date()}
          onChange={(d) => setTargetDate(d)}
        />
        <p className="mt-2">
          This information helps us suggest intervention strategies, and can be
          adjusted later.
        </p>
      </div>

      <p className="my-3">
        <FontAwesomeIcon icon={faInfoCircle} /> Student goal details are visible
        to you, relevant educators, and supervisors.
      </p>
    </>
  );
};

export default SetGoalDeadlinePanel;
