import React, { FunctionComponent } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { changeStudentsFilter } from "../../../../../../../store/onboarding/actions";
import { connect } from "react-redux";

type StateProps = {
  studentsFilter: string;
};

type DispatchProps = {
  changeStudentsFilter: (studentFilter: string) => any;
};

type Props = StateProps & DispatchProps;

const StudentsFilter: FunctionComponent<Props> = (props) => {
  let timeout: any = null;

  const handleStudentsFilterChange = (event: React.FormEvent<any>) => {
    event.preventDefault();
    if (timeout) clearTimeout(timeout);
    const { value } = event.target as HTMLInputElement;
    timeout = setTimeout(() => {
      props.changeStudentsFilter(value);
    }, 500);
  };

  return (
    <InputGroup className="filterSearchDesktop">
      <InputGroup.Prepend>
        <InputGroup.Text>
          <FontAwesomeIcon icon={faSearch} />
        </InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control
        type="text"
        placeholder="Filter by student"
        defaultValue={props.studentsFilter}
        onChange={handleStudentsFilterChange}
        className="filterSearchHeight"
      />
    </InputGroup>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    studentsFilter: onboarding.studentsFilter,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      changeStudentsFilter: changeStudentsFilter,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StudentsFilter);
