import React, { useEffect, useMemo, useState } from "react";
import { Specific } from "../../../../../../../store/groups/types";
import MatrixStudentFocusesTable from "./student-needs-overview/MatrixStudentFocusesTable";
import Modal from "react-bootstrap/Modal";
import FocusInterventionsTable from "./focus-interventions/FocusInterventionsTable";
import {
  Intervention,
  InterventionGroup,
} from "../../../../../../../store/onboarding/cases/types";
import { useDispatch, useSelector } from "react-redux";
import {
  addInterventionsToGroup,
  changeSelectedInterventionGroup,
  openInterventionLibraryModal,
} from "../../../../../../../store/onboarding/cases/actions";
import { ApplicationState } from "../../../../../../../store";

enum ModalContent {
  OVERVIEW,
  INTERVENTIONS,
}

type Props = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  interventionGroup: InterventionGroup;
};

const StudentFocusesModal = ({
  showModal,
  setShowModal,
  interventionGroup,
}: Props) => {
  const { students } = interventionGroup;
  const focuses = interventionGroup.focuses ?? [];

  const [activeContent, setActiveContent] = useState<ModalContent>(
    ModalContent.OVERVIEW
  );
  const [selectedFocus, setSelectedFocus] = useState<Specific | undefined>();

  const interventions = useSelector<ApplicationState, Intervention[]>(
    (s) => s.cases.interventions
  );

  useEffect(() => {
    if (showModal) {
      setActiveContent(ModalContent.OVERVIEW);
      setSelectedFocus(undefined);
    }
  }, [showModal]);

  const dispatch = useDispatch();

  const onSelectFocus = (focus: Specific) => {
    setSelectedFocus(focus);
    setActiveContent(ModalContent.INTERVENTIONS);
  };

  const onSelectIntervention = (intervention: Intervention) => {
    if (selectedInterventions.some((item) => item.id === intervention.id)) {
      const ids = interventionGroup.interventions
        .map((item) => item.id)
        .filter((id) => id !== intervention.id);
      dispatch(addInterventionsToGroup(interventionGroup.id, ids));
    } else {
      const ids = interventionGroup.interventions.map((item) => item.id);
      dispatch(
        addInterventionsToGroup(interventionGroup.id, [...ids, intervention.id])
      );
    }
  };

  const onOpenInterventionLibrary = () => {
    dispatch(changeSelectedInterventionGroup(interventionGroup));
    dispatch(openInterventionLibraryModal());
    setShowModal(false);
  };

  /*  const selectedInterventions = useMemo(
    () =>
      interventionGroup.interventions.filter(
        intervention =>
          !selectedFocus ||
          intervention.focuses?.some(
            focus => focus === selectedFocus.identifier,
          ),
      ),
    [interventionGroup, selectedFocus],
  );*/
  //todo uncomment
  const selectedInterventions = interventionGroup.interventions;

  const modalTitle = useMemo(() => {
    switch (activeContent) {
      case ModalContent.OVERVIEW:
        return "Student Needs Overview";
      case ModalContent.INTERVENTIONS:
        return (
          <div className="d-flex justify-content-between align-items-center">
            <button
              className="whiteBtnSm mr-5"
              style={{ width: 180 }}
              onClick={() => setActiveContent(ModalContent.OVERVIEW)}
            >
              Back to Overview
            </button>
            {selectedFocus?.display_name} Interventions
            <div style={{ width: 180 }} />
          </div>
        );
      default:
        return "";
    }
  }, [activeContent, selectedFocus]);

  const modalBody = useMemo(() => {
    switch (activeContent) {
      case ModalContent.OVERVIEW:
        return (
          <MatrixStudentFocusesTable
            students={students}
            focuses={focuses}
            onSelectFocus={onSelectFocus}
          />
        );
      case ModalContent.INTERVENTIONS:
        return (
          <FocusInterventionsTable
            focus={selectedFocus}
            interventions={interventions}
            students={students}
            selectedInterventions={selectedInterventions}
            onSelectIntervention={onSelectIntervention}
            onOpenInterventionLibrary={onOpenInterventionLibrary}
          />
        );
      default:
        return "";
    }
  }, [activeContent, students, focuses, selectedFocus]);

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      className="students-focuses-modal"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBody}</Modal.Body>
    </Modal>
  );
};

export default StudentFocusesModal;
