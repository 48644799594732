import {
  FbaAntecedentTally,
  FbaBehavior,
  FbaConsequenceTally,
  FbaObservation,
  FbaObservationTally,
} from "../../../../../../store/onboarding/cases/types";
import { useMemo } from "react";
import _ from "lodash";
import {
  useAntecedentNaming,
  useBehaviorsNaming,
  useConsequenceNaming,
} from "../hooks";

const useTopAbc = (observations: FbaObservation[]) => {
  const {
    antecedent: allAntecedent,
    behavior: allBehavior,
    consequence: allConsequence,
  } = useMemo(
    () =>
      observations
        .flatMap((obs) => obs.tally)
        .reduce(
          (pV, cV) => {
            return {
              antecedent: [...pV.antecedent, ...cV.antecedent],
              behavior: [...pV.behavior, ...cV.behavior],
              consequence: [...pV.consequence, ...cV.consequence],
            };
          },
          {
            antecedent: [],
            behavior: [],
            consequence: [],
          } as FbaObservationTally
        ),
    [observations]
  );

  const topAntecedentTally = useMemo(
    () =>
      _.chain(allAntecedent)
        .groupBy((aTally) =>
          aTally.other_antecedents?.length
            ? `${aTally.antecedents.join(
                ","
              )}:::${aTally.other_antecedents.join(",")}`
            : aTally.antecedents
        )
        .values()
        .map<FbaAntecedentTally>((aTallies) => {
          return {
            ...aTallies[0],
            count: _.reduce(aTallies, (sum, v) => sum + v.count, 0),
          };
        })
        .maxBy((v) => v.count)
        .value(),
    [allAntecedent]
  );

  const topAntecedent = useAntecedentNaming(
    topAntecedentTally?.antecedents ?? [],
    topAntecedentTally?.other_antecedents
  );

  const topBehaviorsTallies = useMemo(() => {
    const behaviors = _.chain(allBehavior)
      .flatMap((bTally) =>
        bTally.behaviors.map((beh: number) => ({
          behavior: beh,
          count: bTally.count,
          other_behavior:
            beh === FbaBehavior.BEHAVIOR_OTHER
              ? bTally.other_behavior
              : undefined,
        }))
      )
      .groupBy((bTally) =>
        bTally.other_behavior
          ? `${bTally.behavior}:::${bTally.other_behavior}`
          : bTally.behavior
      )
      .values()
      .map((bTallies) => {
        return {
          ...bTallies[0],
          count: _.reduce(bTallies, (sum, v) => sum + v.count, 0),
        };
      })
      .value();

    const maxCount = _.maxBy(behaviors, (bTally) => bTally.count)?.count;

    return _.filter(behaviors, (bTally) => bTally.count === maxCount);
  }, [allBehavior]);

  const topBehavior = useBehaviorsNaming(
    (topBehaviorsTallies?.map((bTally) => bTally.behavior) as number[]) ?? []
  );

  const topConsequenceTally = useMemo(() => {
    const consequences = _.chain(allConsequence)
      .groupBy((a) => a.consequence)
      .values()
      .map<FbaConsequenceTally>((values) => {
        return {
          consequence: values[0].consequence,
          count: _.reduce(values, (sum, v) => sum + v.count, 0),
        };
      })
      .value();

    const maxCount = _.maxBy(consequences, (cTally) => cTally.count)?.count;

    return _.filter(consequences, (cTally) => cTally.count === maxCount);
  }, [allConsequence]);

  const topConsequence = useConsequenceNaming(
    (topConsequenceTally?.map((cTally) => cTally.consequence) as number[]) ?? []
  );

  return {
    topAntecedent,
    topBehavior,
    topConsequence,
  };
};

export default useTopAbc;
