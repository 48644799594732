import React, { FunctionComponent, useMemo } from "react";
import { useIntl } from "react-intl";
import { ChatThread } from "../../../../store/chat/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { UserInfo } from "../../../../store/auth/types";
import { getFullName } from "../../../../utils/NamesUtils";

type OwnProps = {
  chatThread: ChatThread;
  onCloseChatThread: Function;
};

type Props = OwnProps;

const ChatIndividualThreadName: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const { chatThread, onCloseChatThread } = props;

  const currentUser = useSelector<ApplicationState, UserInfo | undefined>(
    (s) => s.auth.userInfo
  );

  const interlocutor = useMemo(() => {
    return chatThread.participants.find((user) => user.id !== currentUser?.id);
  }, [chatThread.participants, currentUser?.id]);

  return (
    <>
      <div className="spaceBetween">
        <span className="chatThreadTypeLabel">
          {intl.formatMessage({
            id: "app.chats.thread.type.individual.label",
          })}
        </span>
        <div
          className="chatThreadDeleteButton"
          onClick={(e) => {
            e.stopPropagation();
            onCloseChatThread();
          }}
        >
          x
        </div>
      </div>
      <div>{getFullName(interlocutor)}</div>
    </>
  );
};

export default ChatIndividualThreadName;
