import React, { FunctionComponent, useCallback, useRef, useState } from "react";
import { Overlay, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import BehaviorTableContainer from "../../../../../common/onboarding/third-step/set-goal-modal/subpages/do/BehaviorTableContainer";
import { CategoryBehavior } from "../../../../../../store/onboarding/types";
import BRSInput from "../../../../../common/onboarding/third-step/set-goal-modal/subpages/brs/BRSInput";

type Props = {
  behavior: string;
  hypothesis: string;
  onInterventionPrepare: (withoutReplacement?: boolean) => void;
};

const DecreaseBehaviorTab: FunctionComponent<Props> = (props) => {
  const { hypothesis, behavior, onInterventionPrepare } = props;

  const handleInterventionPrepare = useCallback(() => {
    onInterventionPrepare(true);
  }, []);

  return (
    <>
      <div>
        <h3 className="text-center mb-3">
          Based on your hypothesis, we'll help you develop a goal and
          intervention plan to decrease <>{behavior}</>.
        </h3>

        <div className="mb-3">
          <h4 className="text-center font-weight-bold">
            Here's your hypothesis:
          </h4>
          <p className="text-center">{hypothesis}</p>
        </div>

        <div className="text-center">
          <button className="blueBtnSm" onClick={handleInterventionPrepare}>
            Prepare for Intervention
          </button>
        </div>
      </div>
    </>
  );
};

export default DecreaseBehaviorTab;
