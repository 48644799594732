import React, { FunctionComponent, useRef, useState } from "react";
import BehaviorsDropDown from "../behavior-dropdown/BehaviorsDropDown";
import {
  Student,
  StudentEntry,
} from "../../../../../../../store/onboarding/types";
import {
  changeSelectedStudent,
  changeSelectedStudentEntry,
} from "../../../../../../../store/onboarding/actions";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import BehaviorTags from "../../../../third-step/set-goal-modal/subpages/rc-progress/BehaviorTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

type DispatchProps = {
  changeSelectedStudent: (student?: Student) => any;
  changeSelectedStudentEntry: (studentEntry?: StudentEntry) => any;
};

type OwnProps = {
  isReadonly?: boolean;
  displayValue?: string;
  student?: Student;
  studentEntry?: StudentEntry;
};

type Props = OwnProps & DispatchProps;

const BehaviorDataCell: FunctionComponent<Props> = ({
  isReadonly,
  student,
  studentEntry,
  changeSelectedStudent,
  changeSelectedStudentEntry,
}) => {
  const handleClick = () => {
    if (studentEntry) {
      changeSelectedStudentEntry(studentEntry);
    } else {
      changeSelectedStudentEntry();
      changeSelectedStudent(student);
    }

    setShowDropdown(true);
  };

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const refObject = useRef(null);

  return (
    <div
      className={
        studentEntry?.behavior_tags?.length
          ? "dataCell"
          : isReadonly
          ? ""
          : "dataTableCellEmpty d-flex align-items-center justify-content-center"
      }
      onClick={handleClick}
    >
      <div
        ref={refObject}
        className={
          studentEntry?.behavior_tags?.length ? `behaviorDataCell px-2` : ""
        }
      >
        {studentEntry && studentEntry.behavior_tags?.length ? (
          <BehaviorTags studentEntry={studentEntry} isReadonly={isReadonly} />
        ) : (
          !isReadonly && <FontAwesomeIcon icon={faPlusCircle} />
        )}

        {!isReadonly && (
          <BehaviorsDropDown
            refObject={refObject}
            setShowDropdown={setShowDropdown}
            showDropdown={showDropdown}
          />
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      changeSelectedStudent: changeSelectedStudent,
      changeSelectedStudentEntry: changeSelectedStudentEntry,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(BehaviorDataCell);
