import React, { FunctionComponent, useMemo } from "react";
import { ImplementationCheck } from "../../../../../store/onboarding/meeting-module/types";
import _ from "lodash";
import { InterventionSummary } from "../../../../../store/onboarding/types";
import InterventionStats from "../../../coach-dashboard/tabs/your-assigments/intervention-group-list/InterventionStats";
import { makeStatisticObject } from "../../../coach-dashboard/tabs/your-assigments/assigment/intervention-plan-modal/workspaces/inteventions-stats/utils";

type OwnProps = {
  allLoggedInterventions: ImplementationCheck[];
  interventions: InterventionSummary[];
};

type Props = OwnProps;

const InterventionsStats: FunctionComponent<Props> = (props) => {
  const { allLoggedInterventions = [], interventions } = props;

  const interventionList = useMemo(() => {
    return _.chain(interventions)
      .flatMap((intStats) =>
        intStats.interventions.map((int) => int.intervention)
      )
      .uniqBy((int) => int.id)
      .value();
  }, [interventions]);

  const stats = useMemo(() => {
    return _.chain(allLoggedInterventions)
      .groupBy((item) => item.intervention)
      .toPairs()
      .map(([interventionId, items]) => {
        const intervention = interventionList.find(
          (int) => int.id == +interventionId
        );
        if (!intervention) {
          return;
        }

        const dictionary = _.chain(items)
          .flatMap((ic) => ic.logged_items || [])
          .groupBy((item) => item.item)
          .mapValues((items) => items.length)
          .value();

        const notPlannedDictionary = _.chain(items)
          .flatMap((ic) => ic.not_applicable_items || [])
          .groupBy((item) => item.item)
          .mapValues((items) => items.length)
          .value();

        const checklistItems = _.chain(intervention.checklist_items)
          .map((ci) => {
            return {
              ...ci,
              loggedTimes: dictionary[ci.id!] ?? 0,
              notApplicableTimes: notPlannedDictionary[ci.id!] ?? 0,
            };
          }).sortBy('order')
          .value();

        return makeStatisticObject({
          implChecks: items,
          intervention: intervention,
          checklistItems: checklistItems,
        });
      })
      .value();
  }, [allLoggedInterventions, interventionList]);

  return (
    <div className="p-2">
      <InterventionStats stats={stats} />
    </div>
  );
};

export default InterventionsStats;
