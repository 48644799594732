import React, { FunctionComponent, useMemo } from "react";
import InterventionFidelityChart from "../../../implementation-check/InterventionFidelityChart";
import { GroupWorkSpace } from "./WorkspacesContainer";
import WorkspaceHeader from "./WorkspaceHeader";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../../store";
import _ from "lodash";
import moment from "moment";

type Props = {
  expanded?: boolean;
  workspace: GroupWorkSpace;
  setExpandedWorkSpace: React.Dispatch<
    React.SetStateAction<GroupWorkSpace | undefined>
  >;
};

const ChartWorkspace: FunctionComponent<Props> = (props) => {
  const { expanded, workspace, setExpandedWorkSpace } = props;

  const lessonNotes = useSelector((s: ApplicationState) => s.cases.lessonNotes);
  const attendances = useSelector((s: ApplicationState) => s.cases.attendances);

  const lessonNotesObservations = useMemo(
    () =>
      lessonNotes
        .map((ls) => ({
          date: ls.date,
          interventions: ls.interventions,
        }))
        .filter((obs) => obs.interventions.length),
    [lessonNotes]
  );

  const attendanceObservations = useMemo(
    () =>
      attendances
        .map((a) => ({
          date: a.date,
          interventions: a.interventions,
        }))
        .filter((obs) => obs.interventions.length),
    [attendances]
  );

  const allObservations = useMemo(() => {
    return _.chain<{ date: string; interventions: number[] }>([])
      .concat(lessonNotesObservations, attendanceObservations)
      .groupBy((intDate) => moment(intDate.date).format(moment.HTML5_FMT.DATE))
      .values()
      .map((intDates) => ({
        date: intDates[0].date,
        interventions: _.chain(intDates)
          .flatMap((intDate) => intDate.interventions)
          .uniq()
          .value(),
      }))
      .value();
  }, [lessonNotesObservations, attendanceObservations]);

  return (
    <div className="intervention-plan-chart">
      <WorkspaceHeader
        expanded={expanded}
        workspace={workspace}
        setExpandedWorkSpace={setExpandedWorkSpace}
        header={"Intervention Fidelity and Student Progress Chart"}
      />
      <div
        className="workspace-body text-center"
        style={{ maxHeight: "unset" }}
      >
        <InterventionFidelityChart observations={allObservations} />
      </div>
    </div>
  );
};

export default ChartWorkspace;
