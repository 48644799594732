import { ActionType } from "typesafe-actions";

import * as usersManagementsActions from "./actions";
import { UsersManagementActionTypes, UsersManagementState } from "./types";
import { Reducer } from "redux";

export type UsersManagementAction = ActionType<typeof usersManagementsActions>;

const initialState: UsersManagementState = {
  schoolFilter: undefined,
  roleFilter: undefined,
  searchFilter: '',
};

const reducer: Reducer<UsersManagementState> = (
  state: UsersManagementState = initialState,
  action: any
): UsersManagementState => {
  switch (action.type) {
    case UsersManagementActionTypes.CHANGE_SCHOOL_FILTER:
      return {
        ...state,
        schoolFilter: action.payload.school,
      };
    case UsersManagementActionTypes.CHANGE_ROLE_FILTER:
      return {
        ...state,
        roleFilter: action.payload.role,
      };
    case UsersManagementActionTypes.CHANGE_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: action.payload.searchFilter,
      };
    default:
      return state;
  }
};

export { reducer as usersManagementReducer };
