import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt, faCompressAlt } from "@fortawesome/free-solid-svg-icons";
import { GroupWorkSpace } from "./WorkspacesContainer";
import { Spinner } from "react-bootstrap";

type Props = {
  expanded?: boolean;
  isLoading?: boolean;
  workspace: GroupWorkSpace;
  header: React.ReactNode | string;
  setExpandedWorkSpace: React.Dispatch<
    React.SetStateAction<GroupWorkSpace | undefined>
  >;
};

const WorkspaceHeader: FunctionComponent<Props> = (props) => {
  const {
    expanded,
    isLoading,
    workspace,
    header,
    setExpandedWorkSpace,
  } = props;

  const handleClick = () => {
    setExpandedWorkSpace(expanded ? undefined : workspace);
  };

  return (
    <div className="purpleModalHeader">
      <>{header}</>
      {isLoading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <span onClick={handleClick}>
          <FontAwesomeIcon
            icon={expanded ? faCompressAlt : faExpandAlt}
            title={expanded ? "Collapse Workspace" : "Expand Workspace"}
          />
        </span>
      )}
    </div>
  );
};

export default WorkspaceHeader;
