import React from "react";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

type Props = {};

const PopUpAlert = (props: Props) => {
  return (
    <ReduxToastr
      timeOut={0}
      newestOnTop={false}
      preventDuplicates
      position="top-left"
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      closeOnToastrClick
    />
  );
};

export default PopUpAlert;
