import React, { FunctionComponent } from "react";
import WhiteSplitBtn from "./WhiteSplitBtn";
import { Dropdown } from "react-bootstrap";
import { bindActionCreators, Dispatch } from "redux";
import { openInterventionsResourcesModal } from "../../../../../../store/onboarding/meeting-module/actions";
import { connect } from "react-redux";

type DispatchProps = {
  openInterventionsResourcesModal: (showOnlyMaterials?: boolean) => any;
};

type OwnProps = {};

type Props = OwnProps & DispatchProps;

const InterventionsBar: FunctionComponent<Props> = ({
  openInterventionsResourcesModal,
  children,
}) => {
  return (
    <div className="d-flex align-items-start justify-content-between">
      {children}
      {/* <WhiteSplitBtn title={"Resources"}>
        <Dropdown.Item
          as="button"
          onClick={() => openInterventionsResourcesModal()}
        >
          Intervention Brief and Checklist
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          onClick={() => openInterventionsResourcesModal(true)}
        >
          Intervention Materials
        </Dropdown.Item>
      </WhiteSplitBtn> */}
    </div>
  );
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      openInterventionsResourcesModal: openInterventionsResourcesModal,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(InterventionsBar);
