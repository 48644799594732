import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faKeyboard,
  faStar,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import {
  openEnterDataManuallyModal,
  openUploadStudentDataModal,
} from "../../../../../store/onboarding/actions";
import { GoalTypes } from "../../../../../store/onboarding/cases/types";
import useDiagnoseSkillIssues from "../../../../../utils/hooks/useDiagnoseSkillIssues";
import { openPermissionSettingModal } from "../../../../../store/help/actions";
import useUserRole from "../../../../../utils/hooks/useUserRole";

type PropsFromState = {};

type DispatchProps = {
  openUploadStudentDataModal: () => any;
  openPermissionSettingModal: (isGlobal: boolean) => any;
  openEnterDataManuallyModal: (type?: GoalTypes) => any;
};

type Props = PropsFromState & DispatchProps;

const ReadingDataTooltipContent: FunctionComponent<Props> = (props) => {
  const { onAdd } = useDiagnoseSkillIssues();
  const { isDistrictOwner } = useUserRole();
  return (
    <div>
      <div className="mx-4 my-2">
        <h3 className="font-weight-bold">Add Reading Data to your table</h3>
        Each column represents evidence for grouping your students
      </div>

      <div className="mt-2">
        <table className="addMoreDataTooltipTable">
          <thead>
            <tr>
              <th colSpan={2}>Here are some common ways to add data</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Upload a screening data file (e.g. DIBELS in CSV or Excel
                formats)
              </td>

              <td>
                <div
                  className="addColumnBtn"
                  onClick={() => {
                    isDistrictOwner ? 
                      props.openUploadStudentDataModal()
                    :
                      props.openPermissionSettingModal(false);

                    document.body.click();
                  }}
                >
                  <span className="customCircleSpan">
                    <FontAwesomeIcon icon={faUpload} size="lg" />
                  </span>
                  <div className="d-flex flex-column">
                    <strong>Upload a File</strong>
                    <small>
                      We support many reading and behavior assessments
                    </small>
                  </div>
                </div>
              </td>
            </tr>      
            <tr>
              <td>
                If you want to enter data manually by typing in scores for a
                known assessment, you can select this option and search for the
                assessment. You can then type student data directly into the
                table.
              </td>

              <td>
                <div
                  className="addColumnBtn"
                  onClick={() => {
                    props.openEnterDataManuallyModal(GoalTypes.ACADEMIC);
                    document.body.click();
                  }}
                >
                  <span className="customCircleSpan">
                    <FontAwesomeIcon icon={faKeyboard} size="lg" />
                  </span>
                  <div className="d-flex flex-column">
                    <strong>Reading Assessments</strong>
                    <small>
                      Pick a reading assessment or screener and type in student
                      scores
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                If you want to immediately diagnose reading skill issues by
                selecting skills from different categories, select this option.
              </td>
              <td>
                <div className="addColumnBtn" onClick={() => onAdd()}>
                  <span className="customCircleSpan">
                    <FontAwesomeIcon
                      icon={faStar}
                      size="lg"
                      className="customStarIcon position-absolute"
                      transform={"left-12 up-8"}
                    />
                    <FontAwesomeIcon icon={faKeyboard} size="lg" />
                  </span>
                  <div className="d-flex flex-column">
                    <strong>Diagnose Skill Issues</strong>
                    <small>
                      Select which reading skills students are focusing from a
                      list
                    </small>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <p className="mx-4 my-2">
          <FontAwesomeIcon icon={faStar} className="customStarIcon mr-1" />
          Starred options support automatic grouping for some grade levels and
          time periods
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openUploadStudentDataModal: openUploadStudentDataModal,
      openPermissionSettingModal: openPermissionSettingModal,
      openEnterDataManuallyModal: openEnterDataManuallyModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadingDataTooltipContent);
