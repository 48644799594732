import React, { Component } from "react";
import { Cell } from "fixed-data-table-2";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

class DiagnosticSkillHeaderCell extends Component {
  render() {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Popover id="diagSkillCellPopover">
            <Popover.Content>
              <p>
                For each student, diagnostic information is needed for each
                identified Content Area of Need.
              </p>
            </Popover.Content>
          </Popover>
        }
      >
        <Cell className="testLabel">
          <h5 className="contentAreasHeader">DIAGNOSTIC SKILL-SPECIFIC DATA</h5>
        </Cell>
      </OverlayTrigger>
    );
  }
}

export default DiagnosticSkillHeaderCell;
