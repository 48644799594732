import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Form, ListGroup, Row, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  createFeedbackMeeting,
  hideProvidingFeedbackModal,
  openPraiseEducatorModal,
  updateFeedbackMeeting,
} from "../../../../../../store/onboarding/meeting-module/actions";
import { connect } from "react-redux";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { FeedbackMeeting } from "../../../../../../store/onboarding/meeting-module/types";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";
import { formatDate } from "../../../../../utils/DateTimeUtils";

type StateProps = {
  selectedPraise?: string;
  feedbackMeetings: Array<FeedbackMeeting>;
  isLoading: {
    getFeedbackMeetings: boolean;
    createFeedbackMeeting: boolean;
    updateFeedbackMeeting: boolean;
  };
  interventionGroup?: InterventionGroup;
  selectedFeedbackMeeting?: FeedbackMeeting;
};

type DispatchProps = {
  hideProvidingFeedbackModal: () => any;
  openPraiseEducatorModal: () => any;
  createFeedbackMeeting: (feedbackMeeting: Partial<FeedbackMeeting>) => any;
  updateFeedbackMeeting: (feedbackMeeting: Partial<FeedbackMeeting>) => any;
};

type OwnProps = {
  onReadyToMeetMow: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const PlanningModalBody: FunctionComponent<Props> = ({
  selectedFeedbackMeeting,
  feedbackMeetings,
  interventionGroup,
  selectedPraise,
  isLoading: {
    getFeedbackMeetings: loadingGetFeedbackMeetings,
    createFeedbackMeeting: loadingCreateFeedbackMeeting,
    updateFeedbackMeeting: loadingUpdateFeedbackMeeting,
  },

  onReadyToMeetMow,
  hideProvidingFeedbackModal,
  openPraiseEducatorModal,
  createFeedbackMeeting,
  updateFeedbackMeeting,
}) => {
  const [strengths, setStrengths] = useState("");
  const [improvements, setImprovements] = useState("");
  const [additionalModeling, setAdditionalModeling] = useState("");
  const [additionalPractice, setAdditionalPractice] = useState("");
  const [readyToMeetNow, setReadyToMeetNow] = useState(false);
  const [isFirstRender, setFirstRender] = useState(true);
  const [editing, setEditing] = useState(false);
  const [selectedPrevMeeting, selectPrevMeeting] = useState<
    FeedbackMeeting | undefined
  >(undefined);

  useEffect(() => {
    if (!isFirstRender) {
      if (readyToMeetNow) {
        onReadyToMeetMow();
      } else {
        hideProvidingFeedbackModal();
      }
      return;
    }
    setFirstRender(false);
  }, [selectedFeedbackMeeting]);
  useEffect(() => {
    if (
      !isFirstRender &&
      !loadingUpdateFeedbackMeeting &&
      selectedPrevMeeting
    ) {
      selectPrevMeeting(
        feedbackMeetings.find(
          (meeting) => meeting.id === selectedPrevMeeting.id
        )
      );
      setEditing(false);
    }
  }, [loadingUpdateFeedbackMeeting]);

  const saveBtnDisabled =
    !strengths || !improvements || !additionalModeling || !additionalPractice;

  const handleEditBtnClick = (meeting?: FeedbackMeeting) => (
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    selectPrevMeeting(meeting);
    setEditing(!!meeting);
    if (meeting) {
      setStrengths(meeting.strengths);
      setImprovements(meeting.improvements);
      setAdditionalModeling(meeting.additional_modeling);
      setAdditionalPractice(meeting.additional_practice);
    } else {
      setStrengths("");
      setImprovements("");
      setAdditionalModeling("");
      setAdditionalPractice("");
    }
  };

  const onFeedbackMeetingSave = (readyToMeet: boolean) => () => {
    setReadyToMeetNow(readyToMeet);
    const feedbackMeeting: Partial<FeedbackMeeting> = {
      meeting_type: 0,
      strengths: strengths,
      improvements: improvements,
      additional_modeling: additionalModeling,
      additional_practice: additionalPractice,
      praise: selectedPraise,
      intervention_group: interventionGroup!.id,
    };
    createFeedbackMeeting(feedbackMeeting);
  };

  const onFeedbackMeetingUpdate = () => {
    if (selectedPrevMeeting) {
      const feedbackMeeting: Partial<FeedbackMeeting> = {
        ...selectedPrevMeeting,
        planning_meeting: selectedPrevMeeting.planning_meeting
          ? (selectedPrevMeeting.planning_meeting as FeedbackMeeting).id
          : selectedPrevMeeting.planning_meeting,
        strengths: strengths,
        improvements: improvements,
        additional_modeling: additionalModeling,
        additional_practice: additionalPractice,
        praise: selectedPraise,
      };
      updateFeedbackMeeting(feedbackMeeting);
    }
  };

  return (
    <>
      <Row>
        <Col sm={5} className="d-flex align-items-center">
          {loadingGetFeedbackMeetings ? (
            <LoadingIndicator />
          ) : (
            <div style={{ flexGrow: 1 }}>
              <h4 className="mb-2">Previous Meetings</h4>
              <div className="notesListContainer">
                <ListGroup>
                  <ListGroup.Item
                    action
                    active={!selectedPrevMeeting}
                    onClick={handleEditBtnClick()}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h4>Today</h4>
                        <h3 className="font-weight-bold">Plan for a Meeting</h3>
                      </div>
                      <span>
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </span>
                    </div>
                  </ListGroup.Item>
                  {feedbackMeetings.map((meeting) => (
                    <ListGroup.Item
                      key={meeting.id}
                      action
                      active={
                        selectedPrevMeeting &&
                        selectedPrevMeeting.id === meeting.id
                      }
                      onClick={() => {
                        selectPrevMeeting(meeting);
                        setEditing(false);
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h4>
                            {formatDate(meeting.created, "YYYY-MM-DD hh:mm")}
                          </h4>
                          <h3 className="font-weight-bold">
                            Plan for a Meeting
                          </h3>
                        </div>
                        <span onClick={handleEditBtnClick(meeting)}>
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </span>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            </div>
          )}
        </Col>

        <Col>
          <h3 className="font-weight-bold">
            Planning Notes with{" "}
            {getFullName(interventionGroup!.teacher_assignment!.user)}
          </h3>
          {(!selectedPrevMeeting || editing) && (
            <div className="greyContainer my-2">
              <button
                className="blueBtnSm"
                onClick={() => openPraiseEducatorModal()}
              >
                Praise educator
              </button>
            </div>
          )}
          <Form>
            <Form.Group>
              <Form.Label>Strength</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.strengths}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={strengths}
                  onChange={(e) =>
                    setStrengths((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Improvement areas</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.improvements}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={improvements}
                  onChange={(e) =>
                    setImprovements((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Additional Modeling Needs</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.additional_modeling}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={additionalModeling}
                  onChange={(e) =>
                    setAdditionalModeling((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Additional Practice Needs</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.additional_practice}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={additionalPractice}
                  onChange={(e) =>
                    setAdditionalPractice((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
          </Form>
          <p>
            <FontAwesomeIcon icon={faInfoCircle} /> Your interview notes are
            visible to relevant educators and supervisors.
          </p>
        </Col>
      </Row>

      <hr />
      <div className="btnActions mt-3">
        <button className="whiteBtnSm" onClick={hideProvidingFeedbackModal}>
          Cancel
        </button>
        {!selectedPrevMeeting ? (
          <div>
            <button
              className="blueBtnSm mr-3"
              onClick={onFeedbackMeetingSave(false)}
              disabled={saveBtnDisabled}
            >
              Save{" "}
              {!readyToMeetNow && loadingCreateFeedbackMeeting && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
            <button
              className="blueBtnSm"
              onClick={onFeedbackMeetingSave(true)}
              disabled={saveBtnDisabled}
            >
              Ready to meet now{" "}
              {readyToMeetNow && loadingCreateFeedbackMeeting && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          </div>
        ) : (
          editing && (
            <button
              className="blueBtnSm"
              onClick={onFeedbackMeetingUpdate}
              disabled={saveBtnDisabled}
            >
              Update{" "}
              {loadingUpdateFeedbackMeeting && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          )
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  cases,
  meetingModuleReducer,
}: ApplicationState): StateProps => {
  return {
    interventionGroup: cases.selectedInterventionGroup,
    feedbackMeetings: meetingModuleReducer.feedbackMeetings,
    isLoading: {
      getFeedbackMeetings: meetingModuleReducer.isLoading.getFeedbackMeetings,
      createFeedbackMeeting:
        meetingModuleReducer.isLoading.createFeedbackMeeting,
      updateFeedbackMeeting:
        meetingModuleReducer.isLoading.updateFeedbackMeeting,
    },
    selectedPraise: meetingModuleReducer.selectedPraise,
    selectedFeedbackMeeting: meetingModuleReducer.selectedMeetingOld as FeedbackMeeting,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hideProvidingFeedbackModal: hideProvidingFeedbackModal,
      openPraiseEducatorModal: openPraiseEducatorModal,
      createFeedbackMeeting: createFeedbackMeeting,
      updateFeedbackMeeting: updateFeedbackMeeting,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanningModalBody);
