import React, { Component, FunctionComponent, useEffect, useMemo, useState } from "react";
import { Form, FormGroup, FormLabel, Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import {
  DistrictAccessDataSetting,
  GradeLevels,
  School,
  TeacherInfo,
} from "../../../../../store/onboarding/types";
import {
  addDistrictAccessDataSetting,
  getSchools,
  hideDistrictAccessDataSettingUpsertModal,
  updateDistrictAccessDataSetting,
} from "../../../../../store/onboarding/actions";
import Select,{ components } from "react-select";
import { getFullName } from "../../../../../utils/NamesUtils";
import { Grade } from "../../../../../store/groups/types";
import { UserAssignmentRole, UserInfo } from "../../../../../store/auth/types";
import { ValueType } from "react-select/src/types";
import { useLoading } from "../../../../../utils/hooks/useLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

type OwnProps = {
  isShowDisabled: boolean;
};

type Props = OwnProps;

const MultiValueRemove = (props:any) => {
  const selectedDistrictAccessDataSetting = useSelector<
  ApplicationState,
  Partial<DistrictAccessDataSetting<number, UserInfo, number>> | undefined
>((s) => s.onboarding.selectedDistrictAccessDataSetting);

  if (props.data.id == selectedDistrictAccessDataSetting?.user?.id) {
    return null;
  }
  return <components.MultiValueRemove {...props} />;
};


const DistrictAccessDataSettingUpsertModal: FunctionComponent<Props> = (
  {isShowDisabled}
) => {
  const dispatch = useDispatch();

  const showModal = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.onboarding.modalsState.districtAccessDataSettingUpsertModal
  );

  const selectedUsers = useSelector<ApplicationState>(
    (s) => s.onboarding.selectedUsers
  );

  const selectedDistrictAccessDataSetting = useSelector<
    ApplicationState,
    Partial<DistrictAccessDataSetting<number, UserInfo, number>> | undefined
  >((s) => s.onboarding.selectedDistrictAccessDataSetting);

  const teachersRoster = useSelector<ApplicationState, TeacherInfo[]>(
    (s) => s.onboarding.teachersRoster
  );
  const schools = useSelector<ApplicationState, School[]>(
    (s) => s.onboarding.schools
  );
  const allTeachers = useMemo(() => {
    return teachersRoster
      .filter(
        (t) =>
          t.user.profile.current_role === UserAssignmentRole.TEACHER ||
          t.user.profile.current_role === UserAssignmentRole.NJTSS_IMPORTED_USER 
          // t.user.id !== selectedDistrictAccessDataSetting?.user?.id
      )
      .map((t) => t.user);
  }, [teachersRoster, selectedDistrictAccessDataSetting?.user?.id]);

  const [allowedGrades, setAllowedGrades] = useState<Grade[]>([]);
  const [allowedNoGrade, setAllowedNoGrade] = useState<boolean>(false);

  const handleAllowNoGrade = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setAllowedNoGrade(checked);
  };
  const [assignedTeachersIds, setAssignedTeachersIds] = useState<number[]>([]);

  const [allowedNoTeacher, setAllowedNoTeacher] = useState<boolean>(false);

  const handleAllowedNoTeacher = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setAllowedNoTeacher(checked);
  };

  const [allowedSchoolsIds, setAllowedSchoolsIds] = useState<number[]>([]);
  const selectedSchools = useMemo(() => {
    return allowedSchoolsIds.map((id) => schools.find((s) => s.id === id)!);
  }, [allowedSchoolsIds, schools]);
  // const [readonly, setReadonly] = useState<boolean>(false);
  // const handleReadonlyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const checked = e.target.checked;
  //   setReadonly(checked);
  // };
  const [editBenchmarks, setEditBenchmarks] = useState<boolean>(false);
  const handleEditBenchmarksChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setEditBenchmarks(checked);
  };
  const [shareAllData, setShareAllData] = useState<boolean>(false);
  const handleShareAllDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setShareAllData(checked);
  };
  const [blockAccessToAllData, setBlockAccessToAllData] = useState<boolean>(false);
  const handleBlockAccessToAllDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setBlockAccessToAllData(checked);
  };

  const [allGradeCheck, setAllGradeCheck] = useState<boolean>(true);
  const [isGradeInvalid, setIsGradeInvalid] = useState<boolean>(false);
  const handleAllGradeCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setAllGradeCheck(checked);
  };

  const [allTeacherCheck, setAllTeacherCheck] = useState<boolean>(true);
  const [isTeacherInvalid, setIsTeacherInvalid] = useState<boolean>(false);
  const handleAllTeacherCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setAllTeacherCheck(checked);
  };

  const [allSchoolCheck, setAllSchoolCheck] = useState<boolean>(true);
  const [isSchoolInvalid, setIsSchoolInvalid] = useState<boolean>(false);
  const handleAllSchoolCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setAllSchoolCheck(checked);
  };
  
  const selectedGrades = useMemo(() => {
    return GradeLevels.filter((gl) => allowedGrades.includes(gl.value));
  }, [allowedGrades]);
  const selectedTeachers = useMemo(() => {
    return assignedTeachersIds.map(
      (id) => allTeachers.find((t) => t.id === id)!
    );
  }, [assignedTeachersIds, allTeachers]);

  const educatorRoleCoach = useMemo(() => {
    if(!Array.isArray(selectedDistrictAccessDataSetting?.user)) {
      return selectedDistrictAccessDataSetting?.user?.profile.current_role.toLocaleLowerCase() == UserAssignmentRole.COACH;
    } else {
      return selectedDistrictAccessDataSetting?.user.every((user) => user.role.toLocaleLowerCase() == UserAssignmentRole.COACH)
    }
  }, [selectedDistrictAccessDataSetting?.user]);

  const handleShowModal = () => {
    dispatch(getSchools());
    
    if(educatorRoleCoach) {
      setAllowedNoGrade(true);
      setAllowedNoTeacher(true);
    } else {
       setAllowedNoGrade(false);
       setAllowedNoTeacher(false);
    } 

    if (selectedDistrictAccessDataSetting?.id) {
      if(selectedDistrictAccessDataSetting?.allowed_grades?.length) {
        setAllGradeCheck(false);
      }
      if(selectedDistrictAccessDataSetting?.allowed_assigned_teachers?.length) {
        setAllTeacherCheck(false);
      }
      if(selectedDistrictAccessDataSetting?.allowed_schools?.length) {
        setAllSchoolCheck(false);
      }
      setAllowedGrades(selectedDistrictAccessDataSetting?.allowed_grades ?? []);
      setAllowedNoGrade(!!selectedDistrictAccessDataSetting?.allowed_no_grade);
      setAssignedTeachersIds(
        selectedDistrictAccessDataSetting?.allowed_assigned_teachers ?? []
      );
      setAllowedNoTeacher(
        !!selectedDistrictAccessDataSetting?.allowed_no_teacher
      );
      setAllowedSchoolsIds(
        selectedDistrictAccessDataSetting?.allowed_schools ?? []
      );
      //setReadonly(!!selectedDistrictAccessDataSetting?.readonly);
      setEditBenchmarks(!!selectedDistrictAccessDataSetting?.allowed_edit_benchmarks);
      setShareAllData(
        !!selectedDistrictAccessDataSetting?.share_all_data
      );
      setBlockAccessToAllData(
        !!selectedDistrictAccessDataSetting?.block_access_to_all_data
      );
    } else {
      setAllGradeCheck(true);
      setAllTeacherCheck(true);
      setAllSchoolCheck(true);
      setAllowedGrades([]);
      //setAllowedNoGrade(false);
      setAssignedTeachersIds([]);
      //setAllowedNoTeacher(false);
      setAllowedSchoolsIds([]);
      //setReadonly(false);
      setEditBenchmarks(false);
      setShareAllData(false);
      setBlockAccessToAllData(false);

    }
  };
  useEffect(()=>{
    console.log(allowedNoGrade, allowedNoTeacher)
  }, [allowedNoGrade, allowedNoTeacher])

  const handleHideModal = () => {
    dispatch(hideDistrictAccessDataSettingUpsertModal());
  };
  const educatorName = useMemo(() => {
    return (!Array.isArray(selectedDistrictAccessDataSetting?.user)) ?  getFullName(selectedDistrictAccessDataSetting?.user) : 'Selected User';
  }, [selectedDistrictAccessDataSetting?.user]);

  

  const loadingGetSchools = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.onboarding.isLoading.getSchoolsList
  );

  const loading = useSelector<ApplicationState, boolean | undefined>((s) =>
    selectedDistrictAccessDataSetting?.id
      ? s.onboarding.isLoading.updateDistrictAccessDataSetting
      : s.onboarding.isLoading.addDistrictAccessDataSetting
  );
  const error = useSelector<ApplicationState, string | undefined>((s) =>
    selectedDistrictAccessDataSetting?.id
      ? s.onboarding.errors.updateDistrictAccessDataSetting
      : s.onboarding.errors.addDistrictAccessDataSetting
  );
  const handleSuccessSave = () => {
    handleHideModal();
  };
  useLoading(loading, error, handleSuccessSave);

  const districtId = useSelector<ApplicationState, number | undefined>(
    (s) => s.auth.userInfo?.profile.district?.id
  );

  const handleSave = (e: React.FormEvent<any>) => {
    e.preventDefault();
  if(!blockAccessToAllData && !shareAllData) {
    let isInvalidFlag:boolean = false;
    if(!allGradeCheck && !selectedGrades.length){
      setIsGradeInvalid(true);
      isInvalidFlag = true;
    }
    if(!allTeacherCheck && !selectedTeachers.length){
      setIsTeacherInvalid(true);
      isInvalidFlag = true;
    }
    if(!allSchoolCheck && !selectedSchools.length){
      setIsSchoolInvalid(true);
      isInvalidFlag = true;
    }
    if(isInvalidFlag) {
      return;
    }
  }
    if (selectedDistrictAccessDataSetting?.id) {
      dispatch(
        updateDistrictAccessDataSetting({
          id: selectedDistrictAccessDataSetting.id,
          district: districtId!,
          user: selectedDistrictAccessDataSetting?.user?.id!,
          allowed_grades: !allGradeCheck && !shareAllData ? allowedGrades : [],
          allowed_no_grade: !shareAllData ? allowedNoGrade : false,
          allowed_assigned_teachers: !allTeacherCheck && !shareAllData ? assignedTeachersIds : [],
          allowed_no_teacher: !shareAllData ? allowedNoTeacher : false,
          allowed_schools: !allSchoolCheck && !shareAllData ? allowedSchoolsIds : [],
          //readonly: readonly,
          allowed_edit_benchmarks: editBenchmarks,
          share_all_data: shareAllData,
          user_ids: selectedUsers,
          block_access_to_all_data: blockAccessToAllData
        })
      );
    } else {
      dispatch(
        addDistrictAccessDataSetting({
          district: districtId!,
          user: selectedDistrictAccessDataSetting?.user?.id!,
          allowed_grades: !allGradeCheck && !shareAllData ? allowedGrades : [],
          allowed_no_grade: !shareAllData ? allowedNoGrade : false,
          allowed_assigned_teachers: !allTeacherCheck && !shareAllData ? assignedTeachersIds : [],
          allowed_no_teacher: !shareAllData ? allowedNoTeacher : false,
          allowed_schools: !allSchoolCheck && !shareAllData ? allowedSchoolsIds : [],
          //readonly: readonly,
          allowed_edit_benchmarks: editBenchmarks,
          share_all_data: shareAllData,
          user_ids: selectedUsers,
          block_access_to_all_data: blockAccessToAllData
        })
      );
    }
  };

  const customStyles = { multiValueRemove: (base:any) => ({ ...base, display: isShowDisabled ? 'none' : 'block' }) }
  const getPopOver = () => {
    return <OverlayTrigger
        overlay={
          <Tooltip id="edit-user-btn" className="customInfoTooltip">
            For set access setting the SHARE ALL DATA needs to be turned off
          </Tooltip>
        }
      >
        <FontAwesomeIcon icon={faQuestionCircle} style={{color: '#00265b'}} />
    </OverlayTrigger>
  }

  useEffect(() => {
    setIsGradeInvalid(false);
    setIsTeacherInvalid(false);
    setIsSchoolInvalid(false);
  },[])

  useMemo(() => {
    if(allGradeCheck || selectedGrades.length) {
      setIsGradeInvalid(false);
    }
    if(allTeacherCheck || selectedTeachers.length) {
      setIsTeacherInvalid(false);
    }
    if(allSchoolCheck || selectedSchools.length) {
      setIsSchoolInvalid(false);
    }

    if(blockAccessToAllData || shareAllData) {
      setIsGradeInvalid(false);
      setIsTeacherInvalid(false);
      setIsSchoolInvalid(false);
    }

  },[
    allGradeCheck, 
    selectedGrades, 
    allTeacherCheck, 
    selectedTeachers, 
    allSchoolCheck, 
    selectedSchools,
    blockAccessToAllData,
    shareAllData
  ])
  return (
    <Modal
      animation={false}
      backdropClassName="customDarkModalBackdrop in"
      show={showModal}
      onShow={handleShowModal}
      onHide={handleHideModal}
      size="lg"
    >
      <Modal.Header className="purpleModalHeader" closeButton>
        <Modal.Title>
          {selectedDistrictAccessDataSetting?.id
            ? `${isShowDisabled ? `View` : `Edit`} District Access Data Settings for ${educatorName}`
            : `Set Up District Access Data Settings for ${educatorName}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!selectedDistrictAccessDataSetting?.id && isShowDisabled ? 
          <div className="text-center">No data access settings available</div>
        :
        <form id="district-access-data-settings" onSubmit={handleSave}>
           {!blockAccessToAllData&&
          <> <FormGroup>
                  <FormLabel><div className="alert-warning p-1">Grade access settings will not affect active intervention groups.</div></FormLabel>
                </FormGroup>
                <FormGroup>
                  <FormLabel><div className="alert-warning p-1">Once set, data access settings will be applied to both behavior and reading data for this user. </div></FormLabel>
                </FormGroup>
                </>}
           <FormGroup>
           <div>
              <Form.Check
                className="my-3"
                style={{color: blockAccessToAllData ? '#00265b': '', fontWeight: blockAccessToAllData ? 'bold' : '' }}
                checked={blockAccessToAllData}
                type="switch"
                id={`block-access-to-all-data`}
                onChange={handleBlockAccessToAllDataChange}
                label={`Block Access to All Data`}
                disabled={isShowDisabled}
              />
            </div>
          </FormGroup>
          {!blockAccessToAllData && 
            <>
              
              <FormGroup>
              <div className="d-flex">
                <Form.Check
                  className="my-3"
                  checked={editBenchmarks}
                  onChange={handleEditBenchmarksChange}
                  type="switch"
                  id={`allowed-edit-benchmarks`}
                  label={`${educatorName} can edit district benchmarks`}
                  disabled={isShowDisabled}
                />
              </div>
              <div>
                <Form.Check
                  className="my-3"
                  style={{color: shareAllData ? '#00265b': '', fontWeight: shareAllData ? 'bold' : '' }}
                  checked={shareAllData}
                  type="switch"
                  id={`share-all-data`}
                  onChange={handleShareAllDataChange}
                  label={`SHARE ALL DATA`}
                  disabled={isShowDisabled}
                />
              </div>
              </FormGroup>
            {!shareAllData &&
              <>
                
                <FormGroup>
                  <div className={isGradeInvalid ? "row isGradeInvalid" : "row"}>
                    <div className="col-2 pr-0">
                      <Form.Check
                        className="my-3"
                        checked={allGradeCheck}
                        type="checkbox"
                        id={`all-grade`}
                        onChange={handleAllGradeCheckChange}
                        label={`All Grades`}
                        disabled={isShowDisabled || !!selectedGrades.length}
                      />
                    </div>
                    <div className="col-10 pl-0">
                      <FormLabel>
                        Select the grades to which {educatorName} has access {" "} {shareAllData ? getPopOver() : ''}
                      </FormLabel>
                      <Select
                        isMulti
                        isClearable
                        placeholder="Select Grade(s)..."
                        className="dataFiltersSelect"
                        value={selectedGrades}
                        options={GradeLevels}
                        onChange={(value: any) => {
                          setAllowedGrades(
                            value ? value.map((option: any) => option.value) : []
                          );
                        }}
                        isDisabled={isShowDisabled || allGradeCheck}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  { isGradeInvalid ? <div style={{color:"#853422"}}>Please either select <span className="font-weight-bold">All Grades</span> option or <span className="font-weight-bold">grade(s)</span> from the select box</div> : ''}
                </FormGroup>
                <div className="d-flex">    
                  <Form.Check
                    className="my-3"
                    checked={allowedNoGrade}
                    onChange={handleAllowNoGrade}
                    type="switch"
                    id={`allowed-no-grade-switch`}
                    label={`${educatorName} sees students that don't have an assigned grade`}
                    disabled={isShowDisabled || shareAllData}
                  />
                  <span className="my-3 ml-1">{shareAllData ? getPopOver() : ''}</span>
                </div>

                <FormGroup>
                  <div className={isTeacherInvalid ? "row isTeacherInvalid" : "row"}>
                    <div className="col-2 pr-0">
                      <Form.Check
                        className="my-3"
                        checked={allTeacherCheck}
                        type="checkbox"
                        id={`all-teacher`}
                        onChange={handleAllTeacherCheckChange}
                        label={`All Teachers`}
                        disabled={isShowDisabled || !!selectedTeachers.length}
                      />
                    </div>
                    <div className="col-10 pl-0">
                      <FormLabel>
                        Select teachers to which {educatorName} has access{" "} {shareAllData ? getPopOver() : ''}
                      </FormLabel>
                      <Select
                        isMulti
                        isClearable
                        placeholder="Select Teacher(s)..."
                        className="dataFiltersSelect"
                        value={selectedTeachers}
                        options={allTeachers}
                        getOptionValue={(t) => t.id.toString()}
                        getOptionLabel={(t) => getFullName(t)}
                        onChange={(values: ValueType<UserInfo, true>) => {
                          setAssignedTeachersIds(
                            values ? values.map((teacher) => teacher.id) : []
                          );
                        }}
                        isDisabled={isShowDisabled || allTeacherCheck}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  { isTeacherInvalid ? <div style={{color:"#853422"}}>Please either select <span className="font-weight-bold">All Teachers</span> option or <span className="font-weight-bold">teacher(s)</span> from the select box</div> : ''}
                </FormGroup>
                <div className="d-flex">
                  <Form.Check
                    className="my-3"
                    checked={allowedNoTeacher}
                    onChange={handleAllowedNoTeacher}
                    type="switch"
                    id={`allowed-no-teacher-switch`}
                    label={`${educatorName} sees students that don't have an assigned teacher`}
                    disabled={isShowDisabled || shareAllData}
                  />
                  <span className="my-3 ml-1">{shareAllData ? getPopOver() : ''}</span>
                </div>    

                <FormGroup>
                  <div className={isSchoolInvalid ? "row isSchoolInvalid" : "row"}>
                    <div className="col-2 pr-0">
                      <Form.Check
                        className="my-3"
                        checked={allSchoolCheck}
                        type="checkbox"
                        id={`all-schools`}
                        onChange={handleAllSchoolCheckChange}
                        label={`All Schools`}
                        disabled={isShowDisabled || !!selectedSchools.length}
                      />
                    </div>
                    <div className="col-10 pl-0">
                      <FormLabel>
                        Select schools to which {educatorName} has access{" "} {shareAllData ? getPopOver() : ''}
                      </FormLabel>
                      <Select
                        isClearable
                        isMulti
                        isLoading={loadingGetSchools}
                        value={selectedSchools}
                        getOptionLabel={({ name }: School) => name}
                        getOptionValue={(school: School) => school.id.toString()}
                        options={schools}
                        onChange={(values: ValueType<School, true>) => {
                          setAllowedSchoolsIds(
                            values ? values.map((school) => school.id) : []
                          );
                        }}
                        className="schoolSelect"
                        placeholder="Select school(s)..."
                        isDisabled={isShowDisabled || allSchoolCheck}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  { isSchoolInvalid ? <div style={{color:"#853422"}}>Please either select <span className="font-weight-bold">All Schools</span> option or <span className="font-weight-bold">school(s)</span> from the select box</div> : ''}
                </FormGroup>

                {/* <Form.Check
                  className="my-3"
                  checked={readonly}
                  onChange={handleReadonlyChange}
                  type="switch"
                  id={`readonly-switch`}
                  label={`${educatorName}'s access is read-only`}
                /> */}
              </>
            
            }
            </>
          }
          
        </form>

      }

        <div className="modalActions">
          <div />
          {isShowDisabled && <button
            className="blueBtnSm"
            onClick={handleHideModal}
          >
            close
          </button>}
          {!isShowDisabled && <button
            className="blueBtnSm"
            type="submit"
            form="district-access-data-settings"
          >
            Save {loading && <Spinner animation="border" size="sm" />}
          </button>}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DistrictAccessDataSettingUpsertModal;
