import React, { FunctionComponent, useEffect, useMemo } from "react";
import {
  Fba,
  FbaAntecedentTally,
  FbaBehavior,
  FbaConsequenceTally,
  FbaObservationTally,
} from "../../../../../../store/onboarding/cases/types";
import FbaGeneralInfo from "./FbaGeneralInfo";
import FbaItemActions from "./FbaItemActions";
import _ from "lodash";
import {
  useAntecedentNaming,
  useBehaviorsNaming,
  useConsequenceNaming,
} from "../hooks";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import useTopAbc from "./useTopAbc";

type Props = {
  fba: Fba;
};

const FbaItem: FunctionComponent<Props> = (props) => {
  const { fba } = props;

  const highlightedFba = useSelector(
    (s: ApplicationState) => s.cases.highlightedFba
  );

  const highlight = useMemo(() => {
    return highlightedFba === fba.id;
  }, [fba.id, highlightedFba]);

  const { topAntecedent, topBehavior, topConsequence } = useTopAbc(
    fba.observations
  );
  const noTopMessage = useMemo(() => <i>Not logged</i>, []);

  return (
    <div className={`fba-item ${highlight ? "new" : ""}`}>
      <FbaGeneralInfo fba={fba} />

      <div className="fba-top antecedent">
        <div>Top Antecedent</div>
        <div>{topAntecedent ? <p>{topAntecedent}</p> : noTopMessage}</div>
      </div>

      <div className="fba-top behavior">
        <div>Top Behavior</div>
        <div>{topBehavior ? <strong>{topBehavior}</strong> : noTopMessage}</div>
      </div>

      <div className="fba-top consequence">
        <div>Top Consequence</div>
        <div>
          <div>
            {topConsequence && topConsequence.length > 0
              ? topConsequence.map((tc, index) => (
                  <>
                    <strong>{tc.consequence}</strong>&nbsp;
                    {tc.function && `(${tc.function.join(", ")})`}
                    {index !== topConsequence.length - 1 && <>,&nbsp;</>}
                  </>
                ))
              : noTopMessage}
          </div>
        </div>
      </div>

      <hr
        style={{
          border: "none",
          borderLeft: "1px solid lightgray",
          width: "1px",
          height: "auto",
          margin: "1rem",
        }}
      />

      <FbaItemActions fba={fba} />
    </div>
  );
};

export default FbaItem;
