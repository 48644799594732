import React, { useMemo, useState } from "react";
import { Modal, Nav, Tab } from "react-bootstrap";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useSelector } from "react-redux";
import { hideSetGoalChooseConcernModal } from "../../../../../store/onboarding/cases/actions";
import {
  GoalTypes,
  InterventionGroup,
  StudentGoal,
} from "../../../../../store/onboarding/cases/types";
import BrsTab from "./tabs/BrsTab";
import ObservationTab from "./tabs/ObservationTab";
import FocusOn from "./subpages/FocusOn";
import RcProgressTab from "./tabs/RcProgressTab";
import { EventKey } from "react-bootstrap/esm/types";

type PropsFromState = {
  interventionGroup?: InterventionGroup;
  studentGoal?: StudentGoal;
  showModal: boolean;
};
type DispatchProps = {
  hideSetGoalChooseConcernModal: () => any;
};

type Props = PropsFromState & DispatchProps;

enum TabKeys {
  PLAN_TO_FOCUS_ON = "PLAN_TO_FOCUS_ON",
  RC_PROGRESS = "RC_PROGRESS",
  OBSERVATION = "OBSERVATION",
  ASSESSMENTS = "ASSESSMENTS",
}

const SetGoalChooseConcernModal: React.FunctionComponent<Props> = ({
  interventionGroup,
  studentGoal,
  showModal,
  hideSetGoalChooseConcernModal,
}) => {
  const [activeTab, setActiveTab] = useState<string | null>(
    TabKeys.PLAN_TO_FOCUS_ON
  );

  const [isDiffMeasurement, setIsDiffMeasurement] = useState<boolean>(false);
  const [isStudentGoalExist, setIsStudentGoalExist] = useState<boolean>(false);

  const studentGoalOptions = useSelector(
    (s: ApplicationState) => s.cases.modalsState.studentGoalOptions
  );

  const handleHide = () => {
    hideSetGoalChooseConcernModal();
  };

  // const hasBehavioralGoalType: boolean = useMemo( //original 
  //   () => interventionGroup?.goal_type === GoalTypes.BEHAVIOR,
  //   [interventionGroup]
  // );
  const hasBehavioralGoalType: boolean = useMemo(
    () => { return false;},
    [interventionGroup]
  );

  const handleShow = () => {
    if (interventionGroup) {
      if (studentGoalOptions) {
        setActiveTab(TabKeys.OBSERVATION);
      } else {
        setActiveTab(
          //hasBehavioralGoalType ? TabKeys.PLAN_TO_FOCUS_ON : TabKeys.ASSESSMENTS
          TabKeys.ASSESSMENTS
        );
      }
      setIsDiffMeasurement(false); //initially different measurement set to false
    }
  };

  const modalTitle = useMemo(() => {
    if (studentGoal && !isDiffMeasurement) {
      setIsStudentGoalExist(true);
      return `${studentGoal.id ? "Edit" : "Add"} ${
        studentGoal.student.first_name
      }'s Goal for ${interventionGroup?.name}`;
    }
    return `Set Goal for ${interventionGroup?.name}`;
  }, [studentGoal, interventionGroup, isDiffMeasurement]);

  const handleFocusOnContinue = () => {
    setActiveTab(TabKeys.RC_PROGRESS); //todo
  };

  const findDifferentAssessment = studentGoalOptions
    ? () => {
        setActiveTab(TabKeys.ASSESSMENTS);
      }
    : undefined;

  if (!interventionGroup) return null;

  return (
    <Modal
      id="set-goal-modal"
      enforceFocus={false}
      animation={false}
      backdropClassName="customDarkModalBackdrop in"
      show={showModal}
      onHide={handleHide}
      onShow={handleShow}
      size="lg"
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {activeTab === TabKeys.PLAN_TO_FOCUS_ON ? (
          <FocusOn
            onClickContinue={handleFocusOnContinue}
            studentGoal={studentGoal}
          />
        ) : (
          <>
            {hasBehavioralGoalType && !studentGoalOptions && (
              <h3 style={{ marginBottom: 10 }}>
                How do you want to measure your goal progress?
              </h3>
            )}
            <Tab.Container activeKey={activeTab as EventKey} onSelect={setActiveTab}>
              {hasBehavioralGoalType && !studentGoalOptions && (
                <Nav
                  variant="pills"
                  className="justify-content-center text-center"
                >
                  <Nav.Item>
                    <Nav.Link eventKey={TabKeys.RC_PROGRESS}>
                      <div>ReadyCoach Indicators</div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={TabKeys.OBSERVATION}>
                      <div>Direct Observation</div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={TabKeys.ASSESSMENTS}>
                      <div>Behavior Rating Scales</div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              )}
              <Tab.Content>
                {/*{hasBehavioralGoalType && (*/}
                {/*  <>*/}
                <Tab.Pane eventKey={TabKeys.RC_PROGRESS}>
                  <RcProgressTab />
                </Tab.Pane>
                <Tab.Pane eventKey={TabKeys.OBSERVATION}>
                  <ObservationTab
                    findDifferentAssessment={findDifferentAssessment}
                  />
                </Tab.Pane>
                {/*  </>*/}
                {/*)}*/}
                <Tab.Pane eventKey={TabKeys.ASSESSMENTS}>
                  <BrsTab 
                   isDiffMeasurement={isDiffMeasurement}
                   setIsDiffMeasurement={setIsDiffMeasurement}
                   isStudentGoalExist={isStudentGoalExist}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ cases }: ApplicationState): PropsFromState => {
  return {
    showModal: cases.modalsState.setGoalModal,
    interventionGroup: cases.selectedInterventionGroup,
    studentGoal: cases.selectedStudentGoal,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideSetGoalChooseConcernModal: hideSetGoalChooseConcernModal,
      // openSetGoalModal: openSetGoalModal
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetGoalChooseConcernModal);
