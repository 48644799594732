import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faPen, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { changeUserStatus, createUserInDistrict, sendInviteToTeachers } from "../../../../../../store/onboarding/actions";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { AddUserToDistrictRequest, SendInviteRequest, TeacherInfo } from "../../../../../../store/onboarding/types";
import useUserRole from "../../../../../../utils/hooks/useUserRole";

type OwnProps = {
  className?: string;
  isPending?: boolean;
  employee: TeacherInfo;
};

type Props = OwnProps;

const ResendInviteButton: FunctionComponent<Props> = (props) => {
  const { isPending, employee, className } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { isDistrictOwner} = useUserRole();

  const resendTeacherInvite = () => {
    let employeeName = (employee.user.first_name+' '+employee.user.last_name).trim().split(' ');
    let invitee: any = {
      first_name: employeeName[0],
      last_name: employeeName[1],
      email: employee.user.email,
      role: employee.user.profile.current_assignment?.role, 
      resend_invite: true
    };
    if(isDistrictOwner) {
      dispatch<any>(createUserInDistrict([{...invitee}])).then(
        () => {
        setLoading(false)
        toastr.success(
          "Success",
          `You have successfully resent the invite to ${invitee.first_name} ${invitee.last_name}.`
        );
        },
        (err: string) => {
          setLoading(false)
          toastr.error("Failed to send the invite", err)
        }
      );

    } else {
      dispatch<any>(sendInviteToTeachers([{...invitee}])).then(
        () => {
        setLoading(false)
        toastr.success(
          "Success",
          `You have successfully resent the invite to ${invitee.first_name} ${invitee.last_name}.`
        );
        },
        (err: string) => {
          setLoading(false)
          toastr.error("Failed to send the invite", err)
        }
      );

    }
    
    
  }
   
  return isPending ? (
    <div>
      {loading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <OverlayTrigger
          overlay={
            <Tooltip id="change-user-status-btn" className="customInfoTooltip">
              Resend the invite
            </Tooltip>
          }
        >
          <FontAwesomeIcon
            icon={faPaperPlane}
            size="lg"
            onClick={(e) => {
              e.stopPropagation();
              setLoading(true);
              resendTeacherInvite()
            }}
          />
        </OverlayTrigger>
      )}
    </div>
  ): null;
};

export default ResendInviteButton;
