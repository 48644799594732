import React from "react";
import { Cell } from "fixed-data-table-2";
import { Specific } from "../../../../../../../../../store/groups/types";

type Props = {
  focus: Specific;
  onSelectFocus: (focus: Specific) => void;
};

const MatrixFocusCell = ({ focus, onSelectFocus }: Props) => {
  return (
    <Cell className="first-column-cell">
      <button onClick={() => onSelectFocus(focus)}>{focus.display_name}</button>
    </Cell>
  );
};

export default MatrixFocusCell;
