import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";
import * as helpActions from "./actions";
import { HelpActionTypes, HelpState, LOCALE } from "./types";
import axios from "axios";
import {
  getBooleanFromLocalStorage,
  LocalStorageKeys,
} from "../../utils/LocalStorageUtils";

export type HelpAction = ActionType<typeof helpActions>;

const initialState: HelpState = {
  locale: localStorage.getItem(LocalStorageKeys.Locale) as LOCALE,
  showTopBar: getBooleanFromLocalStorage(LocalStorageKeys.ShowTopBar),
  modalsState: {
    helpVideoModal: false,
    contactSupportModal: false,
  },
  isLoading: {
    contactSupport: false,
    getLatestRelease: false,
  },
  errors: {},
  isGlobal: false,
  latestRelease: undefined
};

const reducer: Reducer<HelpState> = (
  state: HelpState = initialState,
  action: HelpAction
): HelpState => {
  switch (action.type) {
    case HelpActionTypes.OPEN_HELP_VIDEO_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, helpVideoModal: true },
      };
    case HelpActionTypes.HIDE_HELP_VIDEO_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, helpVideoModal: false },
      };
    case HelpActionTypes.OPEN_CONTACT_SUPPORT_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, contactSupportModal: true },
      };
    case HelpActionTypes.HIDE_CONTACT_SUPPORT_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, contactSupportModal: false },
      };

    case HelpActionTypes.GET_CONTACT_SUPPORT_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, contactSupport: true },
      };
    case HelpActionTypes.GET_CONTACT_SUPPORT_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, contactSupport: false },
        errors: { ...state.errors, contactSupport: undefined },
      };
    case HelpActionTypes.GET_CONTACT_SUPPORT_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, contactSupport: false },
        errors: { ...state.errors, contactSupport: action.payload.error },
      };
    case HelpActionTypes.CHANGE_DISPLAYED_BAR: {
      localStorage.setItem(
        LocalStorageKeys.ShowTopBar,
        JSON.stringify(!state.showTopBar)
      );
      return {
        ...state,
        showTopBar: !state.showTopBar,
      };
    }
    case HelpActionTypes.CHANGE_VIEW_OF_DATA_TABLE: {
      return {
        ...state,
        isCompressedViewOfDataTable: !state.isCompressedViewOfDataTable,
      };
    }

    case getType(helpActions.changeLocale): {
      localStorage.setItem(LocalStorageKeys.Locale, action.payload);
      axios.defaults.headers.common["Accept-Language"] = action.payload;
      return {
        ...state,
        locale: action.payload,
      };
    }

    case getType(helpActions.openSelectLanguageModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          selectLanguageModal: true,
        },
      };
    case getType(helpActions.hideSelectLanguageModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          selectLanguageModal: false,
        },
      };

    case getType(helpActions.openPageHelperModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          pageHelperModal: true,
        },
      };
    case getType(helpActions.hidePageHelperModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          pageHelperModal: false,
        },
      };

    case HelpActionTypes.OPEN_PERMISSION_SETTING_MODAL:
        return {
          ...state,
          modalsState: {
            ...state.modalsState,
            permissionSettingModal: true,
          },
          isGlobal: action.payload.isGlobal
        };
    case HelpActionTypes.HIDE_PERMISSION_SETTING_MODAL:
        return {
          ...state,
          modalsState: {
            ...state.modalsState,
            permissionSettingModal: false,
          },
        };
      case HelpActionTypes.GET_LATEST_RELEASE_REQUEST:
        return {
          ...state,
          isLoading: {
            ...state.isLoading,
            getLatestRelease: true,
          },
        };
      case HelpActionTypes.GET_LATEST_RELEASE_SUCCESS:
        return {
          ...state,
          latestRelease: action.payload.latestRelease,
          isLoading: {
            ...state.isLoading,
            getLatestRelease: false,
          },
          errors: {
            ...state.errors,
            getLatestRelease: undefined,
          },
        };
      case HelpActionTypes.GET_LATEST_RELEASE_FAILURE:
        return {
          ...state,
          isLoading: {
            ...state.isLoading,
            getLatestRelease: false,
          },
          errors: {
            ...state.errors,
            getLatestRelease: action.payload.error,
          },
        }; 
    case HelpActionTypes.OPEN_RELEASE_MODAL:
        return {
          ...state,
          modalsState: {
            ...state.modalsState,
            latestReleaseModal: true,
          },
        };
    case HelpActionTypes.HIDE_RELEASE_MODAL:
        return {
          ...state,
          modalsState: {
            ...state.modalsState,
            latestReleaseModal: false,
          },
        };

    default:
      return state;
  }
};

export { reducer as helpReducer };
