import React, { FunctionComponent } from "react";
import LoggedProgressTable from "../../../implementation-check/LoggedProgressTable";
import { Student } from "../../../../../../../../store/onboarding/types";
import { InterventionGroup } from "../../../../../../../../store/onboarding/cases/types";

type OwnProps = {
  selectedStudents: Student[];
  interventionGroup: InterventionGroup;
  isStudentDetail?: boolean;
  isGroupDetail?: boolean;
};

type Props = OwnProps;

const LoggedProgressTab: FunctionComponent<Props> = (props) => {
  const { selectedStudents, interventionGroup, isStudentDetail, isGroupDetail } = props;

  return (
    <>
    <LoggedProgressTable
      //studentsFilter={selectedStudents} RC-1405
      interventionGroup={interventionGroup}
      isStudentDetail={isStudentDetail}
      isGroupDetail={isGroupDetail}
    />
    </>
  );
};

export default LoggedProgressTab;
