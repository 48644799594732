import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../store";
import { InterventionGroup } from "../../../../../../../store/onboarding/cases/types";
import useUserRole from "../../../../../../../utils/hooks/useUserRole";

const useUserAssignedToGroup = (interventionGroup: any) => {
     
    const {isCoach, isTeacher} = useUserRole();

    const currentUserInfo = useSelector(
        (s: ApplicationState) => s.auth.userInfo
    );

    const isStudentDetail =  useSelector(
        (s: ApplicationState) => s.cases.isStudentDetail
      );

    //if LoggedIn User Assign into group, will show fidility data.
    const isUserAssignedToGroup = useMemo(()=> {
        let loggedInUser = false;
        let isArr = Object.prototype.toString.call(interventionGroup) == '[object Array]';
        if(!isArr && typeof(interventionGroup) == 'object') {
            if (currentUserInfo?.id == interventionGroup?.coach_assignment?.user.id) { 
                loggedInUser = currentUserInfo?.id == interventionGroup?.coach_assignment?.user.id 
            } 
            else  if (currentUserInfo?.id == interventionGroup?.teacher_assignment?.user.id) { 
                loggedInUser = currentUserInfo?.id == interventionGroup?.teacher_assignment?.user.id 
            }
            else if (interventionGroup.additional_educator_plan != null) { 
                loggedInUser = interventionGroup.additional_educator_plan.some((addEduPlan: any) => addEduPlan.user_id == currentUserInfo?.id) 
            }
        }
        return loggedInUser;
    },[interventionGroup])

    //if LoggedIn User Assign into group, will show on the show overview tab.
    const groupsAssignedToLoggedInUser = useMemo(()=> {
        let interventionGroups:InterventionGroup[] = [];
        let isArr = Object.prototype.toString.call(interventionGroup) == '[object Array]';
        if(isArr && interventionGroup.length) {
            interventionGroup.forEach((interventionGroup:InterventionGroup) => {
                if(currentUserInfo?.id == interventionGroup?.coach_assignment?.user.id ) {
                    interventionGroups.push(interventionGroup);
                } else if(currentUserInfo?.id == interventionGroup?.teacher_assignment?.user.id ) {
                    interventionGroups.push(interventionGroup);
                } else if(interventionGroup.additional_educator_plan != null) {
                    let isUserExistInAddEduPlan = interventionGroup.additional_educator_plan.some((addEduPlan:any) => addEduPlan.user_id == currentUserInfo?.id)
                    if(isUserExistInAddEduPlan) {
                        interventionGroups.push(interventionGroup);
                    }
                }
            })
        }
        return interventionGroups;
    },[interventionGroup]);

    //if LoggedIn User Assign into group, will show fidility data.
    const userIconAssignedToGroup = useMemo(()=> {
        let loggedInUserIconWithHoverTitle = {
            icon:'',
            title:'',
            imgSize: 0
        };
        let isArr = Object.prototype.toString.call(interventionGroup) == '[object Array]';
        if(!isArr && typeof(interventionGroup) == 'object') {
            if(currentUserInfo?.id == interventionGroup?.coach_assignment.user.id) {
                loggedInUserIconWithHoverTitle = {
                    ...loggedInUserIconWithHoverTitle,
                    icon: 'interventionistOrCoach',
                    title: 'You are the coach for this group.',
                    imgSize: 30
                }
            } else if (currentUserInfo?.id == interventionGroup?.teacher_assignment?.user.id) { 
                loggedInUserIconWithHoverTitle = {
                    ...loggedInUserIconWithHoverTitle,
                    icon: 'interventionistOrCoach',
                    title: 'You are the primary interventionist for this group.',
                    imgSize: 30
                }
            } 
            else if (interventionGroup.additional_educator_plan != null) { 
                if(interventionGroup.additional_educator_plan
                    .some((addEduPlan: any) => addEduPlan.user_id == currentUserInfo?.id)){
                    loggedInUserIconWithHoverTitle = {
                        ...loggedInUserIconWithHoverTitle,
                        icon: 'additionalEducator',
                        title: 'You are an additional educator for this group.',
                        imgSize: 60
                    }      
                } 
            }  
        }
        return loggedInUserIconWithHoverTitle;
    },[interventionGroup])

    const isCoachOrTeacharAssignedToGroup = useMemo(()=>{
        let loggedInUser = false;
        if(isCoach) {
          loggedInUser = currentUserInfo?.id == interventionGroup?.coach_assignment?.user.id 
        }
        
        if(isTeacher) {
          loggedInUser = currentUserInfo?.id == interventionGroup?.teacher_assignment?.user.id 
        }
        return loggedInUser;
      },[])

    return {
        isUserAssignedToGroup,
        groupsAssignedToLoggedInUser,
        userIconAssignedToGroup,
        isCoachOrTeacharAssignedToGroup,
        isStudentDetail
    }
};

export default useUserAssignedToGroup;