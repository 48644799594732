import React, { FunctionComponent, useMemo } from "react";
import { GoalAttainment } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import VerticalBarChart from "./VerticalBarChart";

type OwnProps = {
  label?: string;
  goalAttainment: Array<GoalAttainment>;
};

type Props = OwnProps;

const AverageGoalAttainmentChart: FunctionComponent<Props> = (props) => {
  const { label, goalAttainment } = props;
  const labels = useMemo(
    () =>
      goalAttainment.map((goalAttainment) => getFullName(goalAttainment.coach)),
    [goalAttainment]
  );
  const data = useMemo(
    () =>
      goalAttainment.map(
        (goalAttainment) => +(goalAttainment.avg_attainment * 100).toFixed(2)
      ),
    [goalAttainment]
  );

  if (!data.length) {
    return null;
  }

  return (
    <div className="district-summary-chart-container">
      {label && <h3 className="text-center">{label}</h3>}
      <div>
        <VerticalBarChart labels={labels} data={data} />
      </div>
    </div>
  );
};

export default AverageGoalAttainmentChart;
