import React, { useState } from "react";
import { Form, FormControl, FormGroup, Image, Spinner } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import {
  faFileDownload,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { School } from "../../../../../../store/onboarding/types";
import {
  hideEditProfileModal,
  updateProfile,
} from "../../../../../../store/auth/actions";
import { getSchools } from "../../../../../../store/onboarding/actions";
import { ValueType } from "react-select/src/types";
import {
  UserAssignmentRoleDisplayedNames,
  UserInfo,
} from "../../../../../../store/auth/types";
import { IS_READY_COACH } from "../../../../../../constants";
import { FormattedMessage } from "react-intl";
import { useLoading } from "../../../../../../utils/hooks/useLoading";
import PictureReader from "../../../../file-reader/PictureReader";

library.add(faFileDownload, faPlusCircle);

type PropsFromState = {
  userInfo?: UserInfo;
  schools: Array<School>;
  showModal: boolean;
  isLoading: {
    updateProfile: boolean;
    getSchoolsList: boolean;
    uploadUserProfileImage: boolean;
  };
  errors: {
    getSchoolsList?: string;
    updateProfile?: string;
  };
};

type DispatchProps = {
  hideEditProfileModal: () => any;
  getSchools: () => any;
  updateProfile: (userInfo: UserInfo) => any;
};

type Props = PropsFromState & DispatchProps;

const EditProfileModal: React.FC<Props> = (props) => {
  const { showModal, schools, userInfo, isLoading, errors } = props;

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [chosenSchools, setChosenSchools] = useState<number[]>([]);
  const [customRoleTitle, setCustomRoleTitle] = useState<string | undefined>(
    undefined
  );

  useLoading(showModal && isLoading.updateProfile, errors.updateProfile);
  useLoading(showModal && isLoading.getSchoolsList, errors.getSchoolsList);

  const handleShowModal = () => {
    props.getSchools();
    if (userInfo) {
      setFirstName(userInfo.first_name);
      setLastName(userInfo.last_name);
      setEmail(userInfo.email);
      setChosenSchools(
        userInfo.profile.current_assignment
          ? userInfo.profile.current_assignment.schools.map(
              (school) => school.id
            )
          : []
      );
      setCustomRoleTitle(userInfo?.profile.custom_role_title);
    }
  };

  const handleHideModal = () => {
    props.hideEditProfileModal();
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setFirstName(value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setLastName(value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleCustomRoleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setCustomRoleTitle(value);
  };

  const handleSelectedSchoolChange = (values: ValueType<School, true>) => {
    setChosenSchools(values ? (values as Array<School>).map((t) => t.id!) : []);
  };

  const handleSubmit = (event: React.FormEvent<any>) => {
    event.preventDefault();
    if (!userInfo) return;
    const updatedUserInfo: UserInfo = {
      ...userInfo,
      first_name: firstName,
      last_name: lastName,
      email: email,
      profile: {
        ...userInfo.profile,
        current_assignment: {
          ...userInfo.profile.current_assignment!,
          schools: props.schools.filter((school) =>
            chosenSchools.includes(school.id)
          ),
        },
        custom_role_title: customRoleTitle,
      },
      available_grades: userInfo.available_grades,
    };
    props.updateProfile(updatedUserInfo);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleHideModal}
      onShow={handleShowModal}
      animation={false}
      backdropClassName={"customDarkModalBackdrop in"}
    >
      <Modal.Header
        closeButton
        className={IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"}
      >
        <Modal.Title>
          <FormattedMessage id="app.dashboard.profile.modal.editingInfo" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center justify-content-between">
          <div className="d-flex">
            <Image
              style={{
                objectFit: "cover",
                objectPosition: "top center",
                border: "1px solid rgb(97, 97, 97)",
              }}
              src={userInfo && userInfo.profile.profile_image_url}
              width={100}
              height={100}
              roundedCircle
            />
            {isLoading.uploadUserProfileImage && (
              <div
                style={{
                  width: 100,
                  height: 100,
                }}
                className="loadImageSpinnerContainer rounded-circle"
              >
                <Spinner animation="border" />
              </div>
            )}
          </div>
          <label className="blueBtnSm mt-2">
            Change Profile Picture
            <PictureReader />
          </label>
        </div>

        <Form onSubmit={handleSubmit}>
          <FormGroup controlId="validation_first_name">
            <div className="input-group my-3">
              <div className="input-group-prepend">
                <span
                  id="basic-addon1"
                  className="input-group-text justify-content-center"
                  style={{ width: "110px" }}
                >
                  <FormattedMessage id="app.forms.firstName.label" />
                </span>
              </div>
              <FormControl
                required
                autoFocus
                className="fullWidthInput"
                name={"first_name"}
                type="string"
                value={firstName}
                onChange={handleFirstNameChange}
              />
            </div>
          </FormGroup>
          <FormGroup controlId="validation_last_name">
            <div className="input-group my-3">
              <div className="input-group-prepend">
                <span
                  id="basic-addon2"
                  className="input-group-text justify-content-center"
                  style={{ width: "110px" }}
                >
                  <FormattedMessage id="app.forms.lastName.label" />
                </span>
              </div>
              <FormControl
                required
                className="fullWidthInput"
                name={"last_name"}
                type="string"
                value={lastName}
                onChange={handleLastNameChange}
              />
            </div>
          </FormGroup>
          <FormGroup controlId="validation_email">
            <div className="input-group my-3">
              <div className="input-group-prepend">
                <span
                  id="basic-addon3"
                  className="input-group-text justify-content-center"
                  style={{ width: "110px" }}
                >
                  <FormattedMessage id="app.forms.email.label" />
                </span>
              </div>
              <FormControl
                required
                className="fullWidthInput"
                name={"email"}
                type="email"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="input-group my-3">
              <div className="input-group-prepend">
                <span
                  id="basic-addon3"
                  className="input-group-text justify-content-center"
                >
                  Customize Your Role
                </span>
              </div>
              <FormControl
                className="fullWidthInput"
                value={customRoleTitle ?? ""}
                onChange={handleCustomRoleTitleChange}
                placeholder={
                  userInfo?.profile.current_role
                    ? UserAssignmentRoleDisplayedNames[
                        userInfo.profile.current_role
                      ]
                    : undefined
                }
              />
            </div>
          </FormGroup>
          <h3 className="mt-4 mb-2">
            <FormattedMessage id="app.dashboard.profile.modal.questionAboutSchools" />
          </h3>
          <Select
            isMulti
            isClearable
            isLoading={isLoading.getSchoolsList}
            placeholder={
              <FormattedMessage id="app.forms.selectors.placeholders.selectSchools" />
            }
            value={schools.filter((school) =>
              chosenSchools.includes(school.id)
            )}
            options={schools}
            getOptionLabel={(school: School) => school.name}
            getOptionValue={(school: School) => school.id.toString()}
            onChange={handleSelectedSchoolChange}
          />
          <div className="d-flex justify-content-center mt-4">
            <button className="blueBtnLg" type="submit">
              <FormattedMessage id="app.forms.buttons.saveAndClose" />
              {isLoading.updateProfile && (
                <Spinner animation="border" size="sm" className="ml-1" />
              )}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({
  auth,
  onboarding,
}: ApplicationState): PropsFromState => {
  return {
    userInfo: auth.userInfo,
    schools: onboarding.schools,
    isLoading: {
      updateProfile: auth.isLoading.updateProfile,
      uploadUserProfileImage: auth.isLoading.uploadUserProfileImage,
      getSchoolsList: onboarding.isLoading.getSchoolsList,
    },
    errors: {
      getSchoolsList: onboarding.errors.getSchoolsList,
      updateProfile: auth.errors.updateProfile,
    },
    showModal: auth.modalsState.editProfileModal,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideEditProfileModal: hideEditProfileModal,
      getSchools: getSchools,
      updateProfile: updateProfile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileModal);
