import React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { License } from "../../store/superuser/types";

const NotActiveLicensePage = () => {
  const currentLicense = useSelector<ApplicationState, License | undefined>(
    (s) => s.auth.userInfo?.current_license
  );

  return (
    <div className="onboardingContainer">
      <h3>Current license is not active.</h3>
      <hr />
      {currentLicense && (
        <div>
          <h4>
            <strong>Type:</strong> {`${currentLicense.license_type}`}
          </h4>
          <h4>
            <strong>Status:</strong> {`${currentLicense.status}`}
          </h4>
          <h4>
            <strong>Started:</strong> {`${currentLicense.started_at}`}
          </h4>
          <h4>
            <strong>Expires:</strong> {`${currentLicense.expires_at}`}
          </h4>
        </div>
      )}
    </div>
  );
};

export default NotActiveLicensePage;
