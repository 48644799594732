import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";
import BootstrapTable, { SortOrder, SearchProps } from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { push } from "connected-react-router";
import {
  deleteUser,
  getAllUsers,
  setShowUserModal,
} from "../../../../../../store/superuser/actions";
import {
  UserAssignmentRoleDisplayedNames,
  UserInfo,
} from "../../../../../../store/auth/types";
import UsersTableActions from "./UsersTableActions";
import { SuperuserState } from "../../../../../../store/superuser/types";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";
import { useLoading } from "../../../../../../utils/hooks/useLoading";
import { toastr } from "react-redux-toastr";
import UserStatusButton from "../../../../district-owner-dashboard/tabs/your-staff/manage-users-page/UserStatusButton";

const columns = [
  {
    dataField: "id",
    text: "User ID",
    sort: true,
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
  },
  {
    dataField: "account_disabled",
    text: "Account Disabled",
    sort: true,
  },
  {
    dataField: "is_staff",
    text: "Is Staff",
    sort: true,
  },
  {
    dataField: "district_name",
    text: "District Name",
    sort: true,
  },
  {
    dataField: "current_role",
    text: "Current Role",
    sort: true,
  },
  {
    dataField: "actions",
    text: "Actions",
    sort: false,
  },
];

const { SearchBar } = Search;

const ClearButton = (props:any) => {
  const handleClick = () => {
    props.onSearch("");
  };
  return (
    <button className="blueBtnSm" onClick={handleClick}>
      <FontAwesomeIcon icon={faTimesCircle} style={{ color: "white" }} />{" "}
      Clear
    </button>
  );
};

const defaultSorted: [{ dataField: string; order: SortOrder }] = [
  {
    dataField: "id",
    order: "asc",
  },
];

const options = {
  sizePerPageList: [
    {
      text: "20",
      value: 20,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
  ],
  hideSizePerPage: false,
};

type TableRow = {
  id: number;
  name: string;
  email: string;
  is_active?: boolean;
  is_staff?: boolean;
  district_name?: string;
  current_role?: string;
  object: UserInfo;
};

const UsersTable: FunctionComponent = () => {
  const { users, isLoading } = useSelector<ApplicationState, SuperuserState>(
    (s) => s.superuser
  );
  const [isFrontEmpty, setIsFrontEmpty] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const handleShowConfirmDialog = (userId: number) => (e: React.MouseEvent) => {
    e.stopPropagation();
    const onConfirm = () => {
      dispatch(deleteUser(userId));
    };
    dispatch(showConfirmDialog({ onConfirm: onConfirm }));
  };
  const loadingDeleteUser = useSelector<ApplicationState, boolean>(
    (s) => s.superuser.isLoading.deleteUser
  );
  const errorDeleteUser = useSelector<ApplicationState, string | undefined>(
    (s) => s.superuser.errors.deleteUser
  );
  const onDeleteUserSuccess = () => {
    toastr.success("Success", "User is deleted successfully!");
  };
  useLoading(loadingDeleteUser, errorDeleteUser, onDeleteUserSuccess);

  const tableData: TableRow[] = useMemo(
    () =>
      users.map((user) => ({
        id: user.id,
        name: `${user.last_name} ${user.first_name}`,
        email: user.email,
        account_disabled: user.account_disabled,
        object: user,
        is_staff: user.is_staff,
        district_name: user.profile.district?.name,
        current_role:
          UserAssignmentRoleDisplayedNames[
            user.profile.current_role ?? user.profile.current_assignment?.role
          ] ?? "N/A",
        actions: (
          <div>
            <OverlayTrigger
              overlay={
                <Tooltip id="editIcon" className="customInfoTooltip">
                  Edit
                </Tooltip>
              }
            >
              <span
                className="mr-2 pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setShowUserModal({ user: user, show: true }));
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </span>
            </OverlayTrigger>
            <UserStatusButton
              className="mr-2 pointer"
              userId={user.id}
              isActive={!user.account_disabled}
            />
            <OverlayTrigger
              overlay={
                <Tooltip id="deleteIcon" className="customInfoTooltip">
                  Delete
                </Tooltip>
              }
            >
              <span
                className="pointer"
                onClick={handleShowConfirmDialog(user.id)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </OverlayTrigger>
          </div>
        ),
      })),
    [users, handleShowConfirmDialog]
  );

  // const tableRowEvents = {
  //   onClick: (e: React.FormEvent, row: TableRow) =>
  //     dispatch(push(`/superuser/users/${row.id}`)),
  // };

  const isEmpty = users.length === 0 && !isLoading.getAllUsers;

  const searchedResult = (newResult:any) => {
    if(newResult.length == 0) {
      setIsFrontEmpty(true);
    } else {
      setIsFrontEmpty(false);
    }
  }

  return (
    <div className="onboardingContainer">
      

  <ToolkitProvider
    keyField="id"
    data={ tableData }
    columns={ columns }
    search = { {
      afterSearch: (newResult) => searchedResult(newResult)
    }}
  >
    {
      (props:any) => (
        <div>
          <div className="row">
            <div className="col-3 pr-0"><UsersTableActions /></div>
            <div className="col-9 text-right pl-0"><SearchBar className="h-31" { ...props.searchProps } /> <ClearButton {...props.searchProps} /></div>
          </div>

          <BootstrapTable
            { ...props.baseProps }
            filter={filterFactory()}
            classes="rosterTable"
            keyField="id"
            bordered={false}
            bootstrap4
            data={tableData}
            columns={columns}
            pagination={paginationFactory({...options, hideSizePerPage: isFrontEmpty ? true : false})}
            defaultSorted={defaultSorted}
            noDataIndication={isEmpty || isFrontEmpty ? "No matching users" : <LoadingIndicator />}
            // rowEvents={tableRowEvents}
          />
        </div>
      )
    }
  </ToolkitProvider>
      
      
    </div>
  );
};

export default UsersTable;
