import { action } from "typesafe-actions";
import { TourActionTypes } from "./types";

export const markFirstUploadTourAsShown = () =>
  action(TourActionTypes.MARK_FIRST_UPLOAD_TOUR_AS_SHOWN);

export const markSidebarPositionTourAsShown = () =>
  action(TourActionTypes.MARK_SIDEBAR_POSITION_TOUR_AS_SHOWN);

export const markPrepareMeetingTourAsShown = () =>
  action(TourActionTypes.MARK_PREPARE_MEETING_TOUR_AS_SHOWN);

export const markImplementMeetingTourAsShown = () =>
  action(TourActionTypes.MARK_IMPLEMENT_MEETING_TOUR_AS_SHOWN);

export const markInterventionFidelityTourAsShown = () =>
  action(TourActionTypes.MARK_INTERVENTION_FIDELITY_TOUR_AS_SHOWN);

export const markFidelityChartTourAsShown = () =>
  action(TourActionTypes.MARK_FIDELITY_CHART_TOUR_AS_SHOWN);

export const markEmptyDataPeriodViewTourAsShown = () =>
  action(TourActionTypes.MARK_EMPTY_DATA_PERIOD_VIEW_TOUR_AS_SHOWN);

export const changeSelectedInterventionGroupId = (id?: number) =>
  action(TourActionTypes.CHANGE_SELECTED_INTERVENTION_GROUP_ID, id);

export const markAddSecondaryGoalAsShown = (isShown?: boolean) =>
  action(TourActionTypes.MARK_ADD_SECONDARY_GOAL_TOUR_AS_SHOWN, isShown);
