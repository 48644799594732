import React, { FunctionComponent } from "react";
import { Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../../../../store";
import { connect } from "react-redux";
import { Intervention } from "../../../../../../../../store/onboarding/cases/types";
import InterventionShared from "../intervention-shared/InterventionShared";

type OwnProps = {
  editing?: boolean;
  selectedIntervention?: Intervention;
  interventionName: string;
  onSave: () => void;
};

type StateProps = {
  isLoading: {
    createIntervention: boolean;
    updateIntervention?: boolean;
  };
};

type Props = OwnProps & StateProps;

const InterventionReview: FunctionComponent<Props> = ({
  editing,
  selectedIntervention,
  interventionName,
  onSave,
  isLoading
}) => {
  return (
    <div className="interventionModalContent">
      <div className="text-center my-4 py-4">
        <h3 className="purpleText">
          {selectedIntervention ? (
            editing ? (
              "We’ve gathered everything we need to update the intervention."
            ) : (
              <>
                We’ve gathered everything we need for your copy of the{" "}
                <strong>{selectedIntervention.name}</strong> intervention,{" "}
                <strong>{interventionName}</strong>.
              </>
            )
          ) : (
            <>
              We’ve gathered everything we need to make{" "}
              <strong>{interventionName}</strong> a ReadyCoach intervention.
            </>
          )}
        </h3>
        <br />
        <h4>
          Take a look at the details on the left.
          <br />
          Make sure they look correct.
        </h4>
        <p className="mt-3">
          You can click on any of the options on the left to return to those
          pages and edit them.
        </p>
        <h4>
          Once you’re satisfied, you can add it to your Intervention Library.
        </h4>
        <br />
        <button className="blueBtnMd d-inline" onClick={onSave}>
          {selectedIntervention
            ? editing
              ? "Save"
              : `Create Copy of ${interventionName} `
            : `Create ${interventionName} `}
          {(isLoading.createIntervention || isLoading.updateIntervention) && (
            <Spinner animation="border" size="sm" className="ml-1" />
          )}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ cases }: ApplicationState): StateProps => {
  return {
    isLoading: {
      createIntervention: cases.isLoading.createIntervention,
      updateIntervention: cases.isLoading.updateIntervention,
    },
  };
};

export default connect(mapStateToProps)(InterventionReview);
