import React from "react";
import { GoalsData } from "../NewSetGoalsModal";
import { dateToFormatString } from "../../../../utils/DateTimeUtils";
import MeasureInfoCell from "./MeasureInfoCell";

type Props = {
  goalsData: GoalsData;
  onChangeGoalsData: (changedData: GoalsData) => void;
};

const MeasureTableRow = ({ goalsData, onChangeGoalsData }: Props) => {
  const { student, targetDate, goal_statement } = goalsData;
  return (
    <tr>
      <td>
        <strong>
          {student.first_name} {student.last_name}
        </strong>{" "}
        will {goal_statement} <strong>by</strong>{" "}
        <span className="text-nowrap">
          {targetDate && dateToFormatString(targetDate)}
        </span>
      </td>
      <td>
        <MeasureInfoCell
          goalsData={goalsData}
          onChangeGoalsData={onChangeGoalsData}
        />
      </td>
    </tr>
  );
};

export default MeasureTableRow;
