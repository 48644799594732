import React, { Component } from "react";
import {
  Measurement,
  PossibleValue,
} from "../../../../../store/onboarding/types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CreatableSelect from "react-select";
import { ValueType } from "react-select/src/types";
import Form from "react-bootstrap/Form";
import { InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

type Props = {
  showModal: boolean;
  measurement?: Measurement;
  onHide: () => any;
  onApplyChanges: (measurement: Measurement) => any;
};

type State = {
  measurement?: Measurement;
  newPossibleValue: string;
};

class EditPossibleValuesModal extends Component<Props, State> {
  state: Readonly<State> = {
    newPossibleValue: "",
  };

  handleModalShow = () => {
    this.setState({
      measurement:
        this.props.measurement && this.props.measurement.possible_values
          ? this.props.measurement!
          : { ...this.props.measurement!, possible_values: [] },
    });
  };

  handleNewPossibleValueChange = (event: React.FormEvent<any>) => {
    const { value } = event.target as HTMLInputElement;
    this.setState({
      // newPossibleValue: value.replace(/\s/, ""),
      newPossibleValue: value,
    });
  };

  handleSelectOptionChange = (
    value: ValueType<PossibleValue, true>,
    action: any
  ) => {
    switch (action.action) {
      case "remove-value":
        this.setState((prevState) => {
          const colorCriteria = prevState.measurement!.color_criteria
            ? prevState.measurement!.color_criteria.filter(
                (colorCriteria) =>
                  colorCriteria.order !== action.removedValue.order
              )
            : prevState.measurement!.color_criteria;
          return {
            ...prevState,
            measurement: {
              ...prevState.measurement!,
              color_criteria: colorCriteria,
              possible_values: prevState.measurement!.possible_values!.filter(
                (possibleValue) => possibleValue !== action.removedValue
              ),
            },
          };
        });
        break;
      case "clear":
        this.setState((prevState) => {
          return {
            ...prevState,
            measurement: {
              ...prevState.measurement!,
              possible_values: [],
              color_criteria: [],
            },
          };
        });
        break;
      default:
        break;
    }
  };

  generateOrder = (possibleValues: Array<PossibleValue>) => {
    // order starts from 1 on backend,
    const maxOrder = Math.max(
      ...possibleValues.map((possibleValue) => possibleValue.order)
    );
    return possibleValues.length > 0 ? maxOrder + 1 : 1;
  };

  handleAddPossibleValue = (event: React.FormEvent<any>) => {
    event.preventDefault();
    this.setState((prevState) => {
      const possibleValues = prevState.measurement!.possible_values;
      if (possibleValues) {
        return {
          newPossibleValue: "",
          measurement: {
            ...prevState.measurement!,
            possible_values: [
              ...possibleValues,
              {
                order: this.generateOrder(possibleValues),
                display_name: prevState.newPossibleValue,
              },
            ],
          },
        };
      }
      return prevState;
    });
  };

  render() {
    const { measurement } = this.state;
    return (
      <Modal
        size="lg"
        show={this.props.showModal}
        style={{ backgroundColor: "rgb(255,255,255,0.8)" }}
        className="colorPickerModal"
        centered
        dialogClassName="colorPickerDialog"
        onHide={this.props.onHide}
        //@ts-ignore
        onShow={this.handleModalShow}
      >
        <Modal.Body>
          <div className="float-right">
            <Button onClick={this.props.onHide}>
              <FontAwesomeIcon icon={faTimes} className="pointer" />
            </Button>
          </div>
          <h6>Edit Possible Values for</h6>
          <h3 className="font-weight-bold">{measurement?.display_name}</h3>
          <h3 className="font-weight-normal mb-4">
            You can add/remove possible values.
          </h3>
          <p>
            Edit the possible values in this category below. Type a possible
            value into the text box and hit enter. These values become options
            in the drop down menu when entering data of this type.
          </p>
          <CreatableSelect
            isSearchable={false}
            isMulti
            name="possible_values"
            value={measurement?.possible_values}
            getOptionLabel={({ display_name }: PossibleValue) => display_name}
            onChange={this.handleSelectOptionChange}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            noOptionsMessage={() => null}
            placeholder="No Values Entered Yet"
          />

          <hr className="narrowMargin" />

          <div className="inviteContainer">
            <Form
              id="addPossibleValueForm"
              onSubmit={this.handleAddPossibleValue}
            >
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>New Value</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  required
                  placeholder="Displayed name..."
                  name="newPossibleValue"
                  onChange={this.handleNewPossibleValueChange}
                  value={this.state.newPossibleValue}
                />
              </InputGroup>
            </Form>
          </div>
          <div className="btnActions">
            <div />
            <Button
              bsPrefix="b"
              className="blueBtnSm"
              form="addPossibleValueForm"
              type="submit"
            >
              Add another
            </Button>
          </div>

          <label className="blueBtnSm">
            <Button onClick={this.props.onApplyChanges(measurement!)}>
              Apply
            </Button>
          </label>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditPossibleValuesModal;
