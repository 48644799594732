import React, { FunctionComponent } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import ReactDOM from "react-dom/server";

type OwnProps = {
  areaKey: string;
  title: React.ReactNode;
};

type Props = OwnProps;

const SummarySection: FunctionComponent<Props> = (props) => {
  const { areaKey, title } = props;

  const districtSummaryOptions = useSelector(
    (s: ApplicationState) => s.onboarding.districtSummaryOptions
  );

  const displayCharts = useSelector<ApplicationState, boolean>(
    (s) => s.onboarding.districtSummaryOptions.displayCharts
  );

  const children = React.Children.toArray(props.children);

  if (!districtSummaryOptions.collapsedAreas[areaKey]) {
    return null;
  }

  const hasChartContent =
    children[1] && !!ReactDOM.renderToStaticMarkup(children[1] as any);

  return (
    <>
      <hr className="blueHr" />
      <div>
        <div
          className="tableSectionHeader"
          style={{ background: "#00265b", borderRadius: 4 }}
        >
          {title}
        </div>
        <div>
          <Row id="assignments-in-district-collapse">
            <Col>{children?.[0]}</Col>
            {districtSummaryOptions.collapsedAreas[areaKey] &&
              displayCharts &&
              hasChartContent && <Col sm={5}>{children[1]}</Col>}
          </Row>
        </div>
      </div>
    </>
  );
};

export default SummarySection;
