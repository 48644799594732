import React, { Component } from "react";
import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Race,
  RaceDisplayedNames,
  Student,
  StudentInfo,
  StudentsDisplaySettings,
  TeacherInfo,
} from "../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  assignStudentsToTeacher,
  changeStudentsDisplaySetting,
  getStudentsByInterventionGroup,
  getStudents,
  getTeachers,
  openStudentEditModal,
  showLoadingIndicator,
} from "../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import LoadingIndicator from "../../LoadingIndicator";
import { IS_READY_COACH } from "../../../../../constants";
import RostersCreateClassModal from "../../../reading-interventions/educators-and-sharing/modals/RostersCreateClassModal";
import { UserAssignmentRole, UserInfo } from "../../../../../store/auth/types";
import Select, { ValueType } from "react-select";
import { getFullName } from "../../../../../utils/NamesUtils";
import { toastr } from "react-redux-toastr";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faEdit } from "@fortawesome/free-solid-svg-icons";
import { push } from "connected-react-router";
import useUserRole from "../../../../../utils/hooks/useUserRole";
import { hideLoadingIndicator } from "../../../../../store/onboarding/actions";
import { InterventionGroup } from "../../../../../store/onboarding/cases/types";
import _ from "lodash";
import { AxiosError } from "axios";

type PropsFromState = {
  studentsRoster: Array<StudentInfo>;
  teachersRoster: Array<TeacherInfo>;
  studentsByInterventionGroupRoster: Array<any>;
  studentsFilter: string;
  studentsDisplaySettings: StudentsDisplaySettings;
  currentRole?: string;
  errors: {
    getStudents?: string;
    addStudents?: string;
    updateStudentDetails?: string;
  };
  isLoading: {
    getStudents: boolean;
    addStudents: boolean;
    getTeachers: boolean;
    assignStudentsToTeacher: number[];
    isLoadingIndicator: boolean;
    updateStudentDetails: boolean;
  };
  interventionGroups: Array<InterventionGroup>;
};

type DispatchProps = {
  getStudents: () => any;
  getTeachers: () => any;
  getStudentsByInterventionGroup: () => any;
  changeStudentsDisplaySetting: (
    studentsDisplaySettings: StudentsDisplaySettings
  ) => any;
  assignStudentsToTeacher: (
    teacherId: number,
    students: Array<Student>,
    teacherInfo?: UserInfo
  ) => any;
  push: (path: string) => void;
  openStudentEditModal: (student: Student) => void;
  showLoadingIndicator: () => void;
  hideLoadingIndicator: () => void;
};

type OwnProps = {
  isDistrictOwner?: boolean;
};

type Props = PropsFromState & DispatchProps & OwnProps;

type State = {
  studentsRoster: Array<StudentInfo>;
  selectedStudents: Array<StudentInfo>;
  selectedTeacher?: UserInfo;
  showCreateClassModal: boolean;
};

const columns = [
  {
    dataField: "id",
    text: "Student ID",
    hidden: true,
  },
  {
    dataField: "checkbox",
    text: "",
  },
  {
    dataField: "first_name",
    text: "First Name",
    sort: true,
    headerStyle: { width: "18%" }
  },
  {
    dataField: "last_name",
    text: "Last Name",
    sort: true,
    headerStyle: { width: "14%" }
  },
  {
    dataField: "race",
    text: "Race",
    sort: true,
    headerStyle: { width: "22%" }
  },
  {
    dataField: "unique_id",
    text: "Student ID",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "teachers",
    text: "Teachers",
    sort: true,
    headerStyle: { width: "26%" }
  },
  {
    dataField: "actions",
    text: "Actions",
    sort: false,
    headerStyle: { width: "10%" }
  },
];

const defaultSorted: [{ dataField: string; order: SortOrder }] = [
  {
    dataField: "educator",
    order: "asc",
  },
];

const options = {
  sizePerPageList: [
    {
      text: "20",
      value: 20,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
  ],
};

function withMyHook(Component:any) {
  return function WrappedComponent(props:Props) {
    const { isTeacher, isDistrictOwner} = useUserRole();
     return <Component {...props} isTeacher={isTeacher} isDistrictOwner={isDistrictOwner} />;
  }
}


class StudentsTable extends Component<Props, State> {
  state: Readonly<State> = {
    selectedTeacher: undefined,
    studentsRoster: this.props.studentsRoster,
    selectedStudents: [],
    showCreateClassModal: false,
  };

  studentsFilter = (student: StudentInfo) => {
    return (
      student.last_name
        .toLowerCase()
        .includes(this.props.studentsFilter.toLowerCase()) ||
      student.first_name
        .toLowerCase()
        .includes(this.props.studentsFilter.toLowerCase()) ||
      getFullName(student)
        .toLowerCase()
          .includes(this.props.studentsFilter.toLowerCase()) ||
      student
        .unique_id!.toLowerCase()
        .includes(this.props.studentsFilter.toLowerCase())
    );
  };

  studentDisplayFilter = (student: StudentInfo) => {
    if (
      this.props.studentsDisplaySettings ===
      StudentsDisplaySettings.DISPLAY_ALL_STUDENTS
    ){
      return true;
    } else {
      return this.props.studentsByInterventionGroupRoster.includes(student.id);
    }

    // const teachersIds = this.props.teachersRoster.map(
    //   (teacherInfo) => teacherInfo.user.id
    // );
    // return teachersIds.some((teacherId) => {
    //   return student.teachers.some((teacher) => teacherId === teacher.id);
    // });
  };

  componentDidMount() {
    if (IS_READY_COACH) {
      this.props.getTeachers();
      this.props.showLoadingIndicator();
      this.props.getStudentsByInterventionGroup().then(() => {
        const students = this.props.studentsRoster.filter(
          this.studentDisplayFilter
        );
        if (students.length > 0) {
          this.setState({
            studentsRoster: students.filter(this.studentsFilter),
          }, () => this.props.hideLoadingIndicator());
        }
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (
      prevProps.isLoading.addStudents &&
      !this.props.isLoading.addStudents &&
      !this.props.errors.addStudents
    ) {
      this.props.getStudents();
    }
    if ((prevProps.studentsByInterventionGroupRoster !== this.props.studentsByInterventionGroupRoster) ||
        (prevProps.isLoading.updateStudentDetails && 
        !this.props.isLoading.updateStudentDetails &&
        !this.props.errors.updateStudentDetails)) 
    {
      this.props.showLoadingIndicator();
      const students = this.props.studentsRoster.filter(
        this.studentDisplayFilter
      );
      if (students.length > 0) {
        this.setState({
          studentsRoster: students.filter(this.studentsFilter),
        }, () => this.props.hideLoadingIndicator());
      } else {
        this.props.changeStudentsDisplaySetting(
          StudentsDisplaySettings.DISPLAY_ONLY_MY_STUDENTS
        );
      }
    }
    if (
      prevProps.studentsFilter !== this.props.studentsFilter ||
      prevProps.studentsDisplaySettings !== this.props.studentsDisplaySettings
      ) {
        if (
          this.props.studentsDisplaySettings ===
          StudentsDisplaySettings.DISPLAY_ONLY_MY_STUDENTS
          ) {
            this.setState((state) => ({
              ...state,
              studentsRoster: this.props.studentsRoster
              .filter(this.studentDisplayFilter)
              .filter(this.studentsFilter),
            }), () => this.props.hideLoadingIndicator());
          } else {
            this.setState((state, props) => ({
              ...state,
              studentsRoster: props.studentsRoster.filter(this.studentsFilter),
            }), () => this.props.hideLoadingIndicator());
          }
          
    }
  }

  handleCheckStudent = (student: StudentInfo) => {
    const selectedStudents = this.state.selectedStudents;
    if (selectedStudents.includes(student)) {
      this.setState({
        selectedStudents: selectedStudents.filter((st) => st !== student),
      });
    } else {
      selectedStudents.push(student);
      this.setState({ selectedStudents: selectedStudents });
    }
  };

  onStudentDetails = (student: StudentInfo) => {
    if (student.unique_id) {
      this.props.push(`${this.props.isDistrictOwner ? '/district' : ''}/rosters/students/${student.unique_id}`);
    }
  };

  handleShowCreateClassModal = () => {
    this.setState({ showCreateClassModal: true });
  };

  handleHideCreateClassModal = () => {
    this.setState({ showCreateClassModal: false });
  };

  handleChangeSelectedTeacher = (teacher: ValueType<UserInfo, false>) => {
    this.setState((s) => ({ ...s, selectedTeacher: teacher as UserInfo }));
  };

  handleAssignStudentToTeacher = () => {
    const { selectedTeacher, selectedStudents } = this.state;
    if (selectedTeacher) {
      this.props
        .assignStudentsToTeacher(
          selectedTeacher.id,
          selectedStudents,
          selectedTeacher
        )
        .then(
          () => {
            this.handleUnselectStudents();
          },
          (err: any) => {
            return  (err.response.status == 400) ?
                  toastr.error("Failed to assign the students to the teacher", 
                  (JSON.stringify(err.response.data).includes('last_name') 
                    || JSON.stringify(err.response.data).includes('first_name'))
                    ? `First name and Last name can not be blank, 
                    Please check the selected student(s).` : err.message)
                : err.message;
          }
        );
    }
  };

  handleUnselectStudents = () => {
    this.setState((s) => ({
      ...s,
      selectedStudents: [],
      selectedTeacher: undefined,
    }));
  };

  render() {
    const {
      selectedTeacher,
      studentsRoster,
      selectedStudents,
      showCreateClassModal,
    } = this.state;
    const isEmpty =
      studentsRoster.length === 0 && !this.props.isLoading.getStudents;
    return (
      <>
        {IS_READY_COACH
          ? !!selectedStudents.length && (
              <div className="d-flex mt-2">
                <div style={{ minWidth: "250px" }}>
                  <Select
                    className="mr-3"
                    isLoading={
                      this.props.isLoading.getTeachers &&
                      !this.props.teachersRoster.length
                    }
                    isClearable
                    placeholder="Select teacher..."
                    value={selectedTeacher}
                    getOptionValue={(u) => u.id.toString()}
                    getOptionLabel={(u) => getFullName(u)}
                    onChange={this.handleChangeSelectedTeacher}
                    options={this.props.teachersRoster.map((tr) => tr.user)}
                  />
                </div>

                <button
                  className="blueBtnSm"
                  disabled={!selectedTeacher}
                  onClick={this.handleAssignStudentToTeacher}
                >
                  Assign to teacher{" "}
                  {this.props.isLoading.assignStudentsToTeacher.includes(
                    selectedTeacher?.id!
                  ) && <Spinner animation="border" size="sm" />}
                </button>

                <button
                  className="whiteBtnSm ml-3"
                  onClick={this.handleUnselectStudents}
                >
                  Unselect Students
                </button>
              </div>
            )
          : selectedStudents.length > 1 && (
              <button
                className="blueBtnSm w-100 mt-2"
                onClick={this.handleShowCreateClassModal}
              >
                Create a Class
              </button>
            )}
        {this.props.isLoading.isLoadingIndicator 
          ? <LoadingIndicator/>
          : 
        <BootstrapTable
          classes="rosterTable"
          wrapperClasses={studentsRoster.length === 0 ? "rosterTableEmpty" : ""}
          keyField="id"
          bordered={false}
          bootstrap4
          data={studentsRoster.map((student) => ({
            id: student.id,
            checkbox: (
              <div>
                {(this.props.currentRole != UserAssignmentRole.TEACHER) && (
                  <input
                    type="checkbox"
                    checked={selectedStudents.some((st) => st.id === student.id)}
                    onChange={() => this.handleCheckStudent(student)}
                  />
                )}
              </div>
            ),
            first_name: student.first_name,
            last_name: student.last_name,
            race:
              student.race !== null && student.race !== undefined
                ? student.race === Race.Other
                  ? student.race_other_value || RaceDisplayedNames[Race.Other]
                  : RaceDisplayedNames[student.race]
                : "N/A",
            unique_id: student.unique_id,
            teachers: student.teachers
              .map((teacher) => `${teacher.first_name} ${teacher.last_name}`)
              .join(", "),
            actions: (
              <div>
                <span
                  className="mr-2"
                  onClick={() => {
                    this.props.openStudentEditModal(student);
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} size="1x" />
                </span>

                <OverlayTrigger
                  overlay={
                    <Tooltip id="studentDetails" className="customInfoTooltip">
                      Click for Student Profile
                    </Tooltip>
                  }
                >
                  <span onClick={() => this.onStudentDetails(student)}>
                    <FontAwesomeIcon icon={faChevronRight} size="1x" />
                  </span>
                </OverlayTrigger>
              </div>
            ),
          }))}
          columns={columns}
          pagination={paginationFactory(options)}
          defaultSorted={defaultSorted}
          noDataIndication={
            isEmpty ? "No matching students" : <LoadingIndicator />
          }
        />
        }

        {!IS_READY_COACH && (
          <RostersCreateClassModal
            students={selectedStudents}
            showModal={showCreateClassModal}
            hideModal={this.handleHideCreateClassModal}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ onboarding, auth, cases }: ApplicationState): PropsFromState => {
  return {
    studentsRoster: onboarding.studentsRoster,
    teachersRoster: onboarding.teachersRoster,
    studentsByInterventionGroupRoster: onboarding.studentsByInterventionGroupRoster,
    studentsFilter: onboarding.studentsFilter,
    studentsDisplaySettings: onboarding.studentsDisplaySettings,
    currentRole: auth.userInfo?.profile.current_role,
    errors: {
      getStudents: onboarding.errors.getStudents,
      addStudents: onboarding.errors.addStudents,
      updateStudentDetails: onboarding.errors.updateStudentDetails
    },
    isLoading: {
      getStudents: onboarding.isLoading.getStudents,
      addStudents: onboarding.isLoading.addStudents,
      getTeachers: onboarding.isLoading.getTeachers,
      assignStudentsToTeacher: onboarding.isLoading.assignStudentsToTeacher,
      isLoadingIndicator: onboarding.isLoading.isLoadingIndicator,
      updateStudentDetails: onboarding.isLoading.updateStudentDetails
    },
    interventionGroups: cases.interventionGroups
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      getStudents: getStudents,
      getTeachers: getTeachers,
      getStudentsByInterventionGroup: getStudentsByInterventionGroup,
      changeStudentsDisplaySetting: changeStudentsDisplaySetting,
      assignStudentsToTeacher: assignStudentsToTeacher,
      openStudentEditModal: openStudentEditModal,
      showLoadingIndicator: showLoadingIndicator,
      hideLoadingIndicator: hideLoadingIndicator,
      push: push,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withMyHook(StudentsTable));
