import {
  CategoryBehavior,
  CategoryDomain,
  TargetBehaviorCategoryType,
} from "../../../../../../store/onboarding/types";

export type TargetBehaviorGroup = {
  domain: CategoryDomain;
  behaviors: Array<CategoryBehavior>;
};

export type TargetBehaviorType = {
  negative: Array<TargetBehaviorGroup>;
  positive: Array<TargetBehaviorGroup>;
  custom: Array<TargetBehaviorGroup>;
};

export const mapStaticDataCategoryDomains = (
  staticDataCategoryDomains: Array<CategoryBehavior>
): TargetBehaviorType => {
  const getCategoryType = (categoryType: TargetBehaviorCategoryType) => {
    switch (categoryType) {
      case TargetBehaviorCategoryType.POSITIVE:
        return "positive";
      case TargetBehaviorCategoryType.NEGATIVE:
        return "negative";
      case TargetBehaviorCategoryType.CUSTOM:
        return "custom";
    }
  };

  return staticDataCategoryDomains.reduce(
    (previousValue: TargetBehaviorType, currentValue: CategoryBehavior) => {
      const type: "negative" | "positive" | "custom" = getCategoryType(
        currentValue.domain.category_type
      );
      if (
        previousValue[type].some(
          (behavior: TargetBehaviorGroup) =>
            behavior.domain.id === currentValue.domain.id
        )
      ) {
        return {
          ...previousValue,
          [type]: previousValue[type].map((behavior: TargetBehaviorGroup) => {
            if (behavior.domain.id === currentValue.domain.id) {
              return {
                ...behavior,
                behaviors: [...behavior.behaviors, currentValue],
              };
            }
            return behavior;
          }),
        };
      } else {
        return {
          ...previousValue,
          [type]: [
            ...previousValue[type],
            { domain: currentValue.domain, behaviors: [currentValue] },
          ],
        };
      }
    },
    { negative: [], positive: [], custom: [] }
  );
};
