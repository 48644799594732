import {
  AssessmentData,
  CategoryBehavior,
  EvidenceAssessment,
  Measurement,
  PossibleValue,
  ReactSelectOption,
  StudentInfo,
} from "../types";
import { BasicUserInfo, School, Student } from "../types";
import { UserInfo } from "../../auth/types";
import { SkillFocus, Specific } from "../../groups/types";
import { Meeting, ProgressOverview, StudentGoalProgress } from "../meeting-module/types";

export type StudentGoalOptions = {
  fbaId: number;
  goalStatement: string;

  problemBehavior?: BehaviorTally | undefined;
  selectedAntecedent:
    | { abcId: number; antecedents: number[]; other_antecedents?: string[] }
    | undefined;
  selectedConsequence:
    | { abcId: number; consequence: number; other_sequence?: string }
    | undefined;
  replacementBehavior: CategoryBehavior | undefined;

  context: string;
  desiredChange: string;
  targetDate: Date | undefined;
  measuredBy: string;
};

export type CasesState = {
  highlightedFba?: number;
  modalsState: {
    groupingStudentsModal: boolean;
    groupDetailsModal: boolean;
    setGoalsModal: boolean;
    setGoalModal: boolean;
    setGoalChooseConcernModel: boolean;
    findInterventionModal: boolean;
    changePasswordModal: boolean;
    addInterventionModal: boolean;
    interventionLibraryModal: boolean;
    interventionCalledFromFBA: boolean;
    interventionLibraryModalCallbacks?: {
      onBack: Function;
    };
    interventionGroupId: number | undefined;
    createNewInterventionModal: boolean;
    createNewInterventionModalConfig?: {
      onBack?: Function;
      editing?: boolean;
    };
    implementStepModal: boolean;
    editGroupModal: boolean;
    isSaveInState: boolean;
    fbaModal: boolean;
    fbaModalTab: FbaModalTabs | null;
    interpretFbaModal: boolean;
    learnMoreModal: boolean;
    upsertAssessmentModal: boolean;
    groupInterventionsModal?: boolean;
    fbaObservationModal?: boolean;
    showAnalyzeFbaResultsModal?: boolean;
    studentGoalOptions?: StudentGoalOptions;
    setMissedGoal?: boolean;
    interventionPlan: {
      show?: boolean;
      selectedStudents: Student[];
      selectedStudentGoals: StudentGoalProgress[];
      selectedStudentForWorkSpacePDF: Student[];
      selectedPDFForWorkSpacePDF: any[];
      showOnlyActiveInterventions: boolean;
      showGoalLines: boolean;
      showStudentTrendsLines: boolean;
      selectedStudent?: any;
      selectedStudentGoalMeasure?: any;
    };
    deleteInterventionLog: boolean;
    isRedirectToSetGoal?: boolean;
  };
  isLoading: {
    getInterventionGroups: boolean;
    createInterventionGroup: boolean;
    createGasMeasurement: boolean;
    getAssessmentStaticData: boolean;
    getMeasurementStaticData: boolean;
    createStudentGoalForInterventionGroup: boolean;
    createStudentGoalForStudent: boolean;
    deleteStudentGoal: boolean;
    createIntervention: boolean;
    updateIntervention?: boolean;
    getInterventions: boolean;
    addInterventionsToGroup: boolean;
    addInterventionSchoolResources: boolean;
    updateInterventionGroup: boolean;
    deleteLoggedProgress: boolean;
    patchInterventionGroup: boolean;
    deleteInterventionGroup: boolean;
    getRecommendations: boolean;
    getNotFinishedGroups: boolean;
    finishInterventionGroup: boolean;
    unsaveInterventionGroup: boolean;
    createFba: boolean;
    deleteFba: boolean;
    updateFba: boolean;
    createFbaABC: boolean;
    getFbas: boolean;
    getFbaObservation?: boolean;
    createFbaObservation: boolean;
    completeFbaObservation: boolean;
    discontinueIntGroupInt: boolean;
    getLessonNotes?: boolean;
    createLessonNotes?: boolean;
    updateLessonNotes?: boolean;
    deleteLessonNotes?: boolean;
    getAttendances?: boolean;
    createAttendance?: boolean;
    updateAttendance?: boolean;
    deleteAttendance?: boolean;
    printInterventionGroupReport?: boolean;
    deleteInterventionLog?: boolean;

    loaderIntGroupBehavior?: boolean;
    loaderIntGroupBehaviorArchived?: boolean;
    loaderIntGroupReading?: boolean;
    loaderIntGroupReadingArchived?: boolean;
    loaderIntGroupStudentDetail?: boolean;
    loaderIntGroupStudentDetailArchived?: boolean;
  };
  errors: {
    getInterventionGroups?: string;
    createInterventionGroup?: string;
    createGasMeasurement?: string;
    getAssessmentStaticData?: string;
    getMeasurementStaticData?: string;
    createStudentGoalForInterventionGroup?: string;
    createStudentGoalForStudent?: string;
    deleteStudentGoal?: string;
    createIntervention?: string;
    updateIntervention?: string;
    getInterventions?: string;
    addInterventionsToGroup?: string;
    addInterventionSchoolResources?: string;
    updateInterventionGroup?: string;
    deleteLoggedProgress?: string;
    patchInterventionGroup?: string;
    deleteInterventionGroup?: string;
    getRecommendations?: string;
    getNotFinishedGroups?: string;
    finishInterventionGroup?: string;
    unsaveInterventionGroup?: string;
    createFba?: string;
    deleteFba?: string;
    updateFba?: string;
    createFbaABC?: string;
    getFbas?: string;
    getFbaObservation?: string;
    createFbaObservation?: string;
    completeFbaObservation?: string;
    getLessonNotes?: string;
    createLessonNotes?: string;
    updateLessonNotes?: string;
    deleteLessonNotes?: string;
    getAttendances?: string;
    createAttendance?: string;
    updateAttendance?: string;
    deleteAttendance?: string;
    printInterventionGroupReport?: string;
    loaderIntGroupBehavior?: string;
    loaderIntGroupBehaviorArchived?: string;
    loaderIntGroupReading?: string;
    loaderIntGroupReadingArchived?: string;
    loaderIntGroupStudentDetail?: string;
    loaderIntGroupStudentDetailArchived?: string;
  };
  isEnterSpecificSkillActive: boolean;
  isCreateGroupManually: boolean;
  showWelcomePanel: boolean;
  showThreeStepProgress?: boolean;
  showStartWorkingIntro?: boolean;
  showNoGroupsIntro?: boolean;
  showNoUnsavedGroupsIntro?: boolean;
  showNoAbcDataCollectionIntro?: boolean;
  showGroupingStudentInfo?: boolean;
  showRecommendedGroups?: boolean;
  listOrderSwitch?: boolean;
  activeGroupsView: "list" | "grid"; //todo remove
  selectedInterventionGroup?: InterventionGroup;
  createdIntervention?: Intervention;
  selectedIntervention?: Intervention;
  selectedAssessment?: EvidenceAssessment;
  selectedStudentGoal?: StudentGoal;
  newGroupStudents: Array<Student>;
  interventionGroups: Array<InterventionGroup>;
  archivedInterventionGroups: Array<InterventionGroup>;
  gasMeasurement?: GasMeasurement;
  interventions: Array<Intervention>;
  recommendedGroups: GroupRecommendation[];
  recommendedGroupsFromAPI: GroupRecommendation[];
  selectedStudent?: StudentInfo;
  notFinishedGroups: InterventionGroup[];
  staticData: {
    measurements: Array<Measurement>;
    assessments: Array<AssessmentData>;
  };
  selectedFba?: Fba;
  isSingleAbc?: boolean;
  selectedFbaObservation?: FbaObservation;
  fbas: Array<Fba>;
  assessmentFilter?: string;
  lessonNotes: LessonNotes[];
  attendances: Attendance[];
  groupCardFocusTab: "matrix" | "common";
  silentGroupCreation?: boolean;
  tabName: string;
  isStudentDetail: boolean;
  isGroupDetail: boolean;
  shareDataWith: boolean;
  notShareDataWith: boolean;
  displayGroupName: string;
  loggedProgressData: any;
  isClickedFromAlert: boolean;
  openRecommendationGroupFromData: boolean;
  //bothStudentGroupUpdated?: boolean;
};

export enum CasesActionTypes {
  ACTIVE_TAB_URL = "@@onboarding/cases/SET_ACTIVE_TAB_URL",

  ADD_STUDENTS_TO_NEW_GROUP = "@@onboarding/cases/ADD_STUDENTS_TO_NEW_GROUP",
  REMOVE_STUDENT_FROM_NEW_GROUP = "@@onboarding/cases/REMOVE_STUDENT_FROM_NEW_GROUP",
  REMOVE_ALL_STUDENT_FROM_NEW_GROUP = "@@onboarding/cases/REMOVE_ALL_STUDENT_FROM_NEW_GROUP",

  GET_RECOMMENDATIONS_START = "@@onboarding/GET_RECOMMENDATIONS_START",
  GET_RECOMMENDATIONS_SUCCESS = "@@onboarding/GET_RECOMMENDATIONS_SUCCESS",
  GET_RECOMMENDATIONS_FAILURE = "@@onboarding/GET_RECOMMENDATIONS_FAILURE",

  GET_NOT_FINISHED_GROUPS_START = "@@onboarding/GET_NOT_FINISHED_GROUPS_START",
  GET_NOT_FINISHED_GROUPS_SUCCESS = "@@onboarding/GET_NOT_FINISHED_GROUPS_SUCCESS",
  GET_NOT_FINISHED_GROUPS_FAILURE = "@@onboarding/GET_NOT_FINISHED_GROUPS_FAILURE",

  OPEN_GROUPING_STUDENTS_MODAL = "@@onboarding/cases/OPEN_GROUPING_STUDENTS_MODAL",
  HIDE_GROUPING_STUDENTS_MODAL = "@@onboarding/cases/HIDE_GROUPING_STUDENTS_MODAL",

  OPEN_GROUP_DETAILS_MODAL = "@@onboarding/cases/OPEN_GROUP_DETAILS_MODAL",
  HIDE_GROUP_DETAILS_MODAL = "@@onboarding/cases/HIDE_GROUP_DETAILS_MODAL",

  CHANGE_SET_GOALS_MODAL = "@@onboarding/cases/CHANGE_SET_GOALS_MODAL",

  OPEN_SET_GOAL_CHOOSE_CONCERN_MODAL = "@@onboarding/cases/OPEN_SET_GOAL_CHOOSE_CONCERN_MODAL",
  HIDE_SET_GOAL_CHOOSE_CONCERN_MODAL = "@@onboarding/cases/HIDE_SET_GOAL_CHOOSE_CONCERN_MODAL",

  OPEN_FIND_INTERVENTION_MODAL = "@@onboarding/cases/OPEN_FIND_INTERVENTION_MODAL",
  HIDE_FIND_INTERVENTION_MODAL = "@@onboarding/cases/HIDE_FIND_INTERVENTION_MODAL",

  OPEN_CHANGE_PASSWORD_MODAL = "@@onboarding/cases/OPEN_CHANGE_PASSWORD_MODAL",
  HIDE_CHANGE_PASSWORD_MODAL = "@@onboarding/cases/HIDE_CHANGE_PASSWORD_MODAL",

  OPEN_EDIT_GROUP_MODAL = "@@uploadStudentData/OPEN_EDIT_GROUP_MODAL",
  HIDE_EDIT_GROUP_MODAL = "@@uploadStudentData/HIDE_EDIT_GROUP_MODAL",

  OPEN_ADD_INTERVENTION_MODAL = "@@onboarding/cases/OPEN_ADD_INTERVENTION_MODAL",
  HIDE_ADD_INTERVENTION_MODAL = "@@onboarding/cases/HIDE_ADD_INTERVENTION_MODAL",

  OPEN_INTERVENTION_LIBRARY_MODAL = "@@onboarding/cases/OPEN_INTERVENTION_LIBRARY_MODAL",
  HIDE_INTERVENTION_LIBRARY_MODAL = "@@onboarding/cases/HIDE_INTERVENTION_LIBRARY_MODAL",

  OPEN_IMPLEMENT_STEP_MODAL = "@@onboarding/cases/OPEN_IMPLEMENT_STEP_MODAL",
  HIDE_IMPLEMENT_STEP_MODAL = "@@onboarding/cases/HIDE_IMPLEMENT_STEP_MODAL",

  HIDE_ALL_MODAL = "@@onboarding/cases/HIDE_ALL_MODAL",

  ON_ENTER_SPECIFIC_SKILL = "@@onboarding/cases/ON_ENTER_SPECIFIC_SKILL",
  CLICK_GOT_IT = "@@onboarding/cases/CLICK_GOT_IT",

  GET_INTERVENTION_GROUPS_START = "@@onboarding/cases/GET_INTERVENTION_GROUPS_START",
  GET_INTERVENTION_GROUPS_SUCCESS = "@@onboarding/cases/GET_INTERVENTION_GROUPS_SUCCESS",
  GET_INTERVENTION_GROUPS_FAILURE = "@@onboarding/cases/GET_INTERVENTION_GROUPS_FAILURE",

  GET_INTERVENTION_GROUPS_PROGRESS_OVERVIEW_START = "@@onboarding/cases/GET_INTERVENTION_GROUPS_PROGRESS_OVERVIEW_START",
  GET_INTERVENTION_GROUPS_PROGRESS_OVERVIEW_SUCCESS = "@@onboarding/cases/GET_INTERVENTION_GROUPS_PROGRESS_OVERVIEW_SUCCESS",
  GET_INTERVENTION_GROUPS_PROGRESS_OVERVIEW_FAILURE = "@@onboarding/cases/GET_INTERVENTION_GROUPS_PROGRESS_OVERVIEW_FAILURE",

  CHANGE_INTERVENTION_GROUP_LATEST_MEETING = "@@onboarding/cases/CHANGE_INTERVENTION_GROUP_LATEST_MEETING",

  CREATE_INTERVENTION_GROUPS_START = "@@onboarding/cases/CREATE_INTERVENTION_GROUPS_START",
  CREATE_INTERVENTION_GROUPS_SUCCESS = "@@onboarding/cases/CREATE_INTERVENTION_GROUPS_SUCCESS",
  CREATE_INTERVENTION_GROUPS_FAILURE = "@@onboarding/cases/CREATE_INTERVENTION_GROUPS_FAILURE",

  CHANGE_SELECTED_INTERVENTION_GROUP = "@@onboarding/cases/CHANGE_SELECTED_INTERVENTION_GROUP",

  CHANGE_SELECTED_STUDENT_GOAL = "@@onboarding/cases/CHANGE_SELECTED_STUDENT_GOAL",

  CREATE_GROUP_MANUALLY = "@@onboarding/cases/CREATE_GROUP_MANUALLY",
  CANCEL_CREATE_GROUP_MANUALLY = "@@onboarding/cases/CANCEL_CREATE_GROUP_MANUALLY",

  GET_ASSESSMENT_STATIC_DATA_START = "@@onboarding/cases/GET_ASSESSMENT_STATIC_DATA_START",
  GET_ASSESSMENT_STATIC_DATA_SUCCESS = "@@onboarding/cases/GET_ASSESSMENT_STATIC_DATA_SUCCESS",
  GET_ASSESSMENT_STATIC_DATA_FAILURE = "@@onboarding/cases/GET_ASSESSMENT_STATIC_DATA_FAILURE",

  GET_MEASUREMENT_STATIC_DATA_START = "@@onboarding/cases/GET_MEASUREMENT_STATIC_DATA_START",
  GET_MEASUREMENT_STATIC_DATA_SUCCESS = "@@onboarding/cases/GET_MEASUREMENT_STATIC_DATA_SUCCESS",
  GET_MEASUREMENT_STATIC_DATA_FAILURE = "@@onboarding/cases/GET_MEASUREMENT_STATIC_DATA_FAILURE",

  CREATE_STUDENT_GOAL_FOR_STUDENT_START = "@@onboarding/cases/CREATE_STUDENT_GOAL_FOR_STUDENT_START",
  CREATE_STUDENT_GOAL_FOR_STUDENT_SUCCESS = "@@onboarding/cases/CREATE_STUDENT_GOAL_FOR_STUDENT_SUCCESS",
  CREATE_STUDENT_GOAL_FOR_STUDENT_FAILURE = "@@onboarding/cases/CREATE_STUDENT_GOAL_FOR_STUDENT_FAILURE",

  DELETE_STUDENT_GOAL_START = "@@onboarding/cases/DELETE_STUDENT_GOAL_START",
  DELETE_STUDENT_GOAL_SUCCESS = "@@onboarding/cases/DELETE_STUDENT_GOAL_SUCCESS",
  DELETE_STUDENT_GOAL_FAILURE = "@@onboarding/cases/DELETE_STUDENT_GOAL_FAILURE",

  CREATE_STUDENT_GOAL_FOR_INTERVENTION_GROUP_START = "@@onboarding/cases/CREATE_STUDENT_GOAL_FOR_INTERVENTION_GROUP_START",
  CREATE_STUDENT_GOAL_FOR_INTERVENTION_GROUP_SUCCESS = "@@onboarding/cases/CREATE_STUDENT_GOAL_FOR_INTERVENTION_GROUP_SUCCESS",
  CREATE_STUDENT_GOAL_FOR_INTERVENTION_GROUP_FAILURE = "@@onboarding/cases/CREATE_STUDENT_GOAL_FOR_INTERVENTION_GROUP_FAILURE",

  CREATE_GAS_MEASUREMENT_START = "@@onboarding/cases/CREATE_GAS_MEASUREMENT_START",
  CREATE_GAS_MEASUREMENT_SUCCESS = "@@onboarding/cases/CREATE_GAS_MEASUREMENT_SUCCESS",
  CREATE_GAS_MEASUREMENT_FAILURE = "@@onboarding/cases/CREATE_GAS_MEASUREMENT_FAILURE",

  GET_INTERVENTION_GROUP_DETAILS_START = "@@onboarding/cases/GET_INTERVENTION_GROUP_DETAILS_START",
  GET_INTERVENTION_GROUP_DETAILS_SUCCESS = "@@onboarding/cases/GET_INTERVENTION_GROUP_DETAILS_SUCCESS",
  GET_INTERVENTION_GROUP_DETAILS_FAILURE = "@@onboarding/cases/GET_INTERVENTION_GROUP_DETAILS_FAILURE",

  DISCONTINUE_INTERVENTION_GROUP_INTERVENTION_START = "@@onboarding/cases/DISCONTINUE_INTERVENTION_GROUP_INTERVENTION_START",
  DISCONTINUE_INTERVENTION_GROUP_INTERVENTION_SUCCESS = "@@onboarding/cases/DISCONTINUE_INTERVENTION_GROUP_INTERVENTION_SUCCESS",
  DISCONTINUE_INTERVENTION_GROUP_INTERVENTION_FAILURE = "@@onboarding/cases/DISCONTINUE_INTERVENTION_GROUP_INTERVENTION_FAILURE",

  CREATE_INTERVENTION_START = "@@onboarding/cases/CREATE_INTERVENTION_START",
  CREATE_INTERVENTION_SUCCESS = "@@onboarding/cases/CREATE_INTERVENTION_SUCCESS",
  CREATE_INTERVENTION_FAILURE = "@@onboarding/cases/CREATE_INTERVENTION_FAILURE",

  GET_INTERVENTIONS_START = "@@onboarding/cases/GET_INTERVENTIONS_START",
  GET_INTERVENTIONS_SUCCESS = "@@onboarding/cases/GET_INTERVENTIONS_SUCCESS",
  GET_INTERVENTIONS_FAILURE = "@@onboarding/cases/GET_INTERVENTIONS_FAILURE",

  ADD_INTERVENTIONS_TO_GROUP_START = "@@onboarding/cases/ADD_INTERVENTIONS_TO_GROUP_START",
  ADD_INTERVENTIONS_TO_GROUP_SUCCESS = "@@onboarding/cases/ADD_INTERVENTIONS_TO_GROUP_SUCCESS",
  ADD_INTERVENTIONS_TO_GROUP_FAILURE = "@@onboarding/cases/ADD_INTERVENTIONS_TO_GROUP_FAILURE",

  ADD_INTERVENTIONS_SCHOOL_RESOURCES_START = "@@onboarding/cases/ADD_INTERVENTIONS_SCHOOL_RESOURCES_START",
  ADD_INTERVENTIONS_SCHOOL_RESOURCES_SUCCESS = "@@onboarding/cases/ADD_INTERVENTIONS_SCHOOL_RESOURCES_SUCCESS",
  ADD_INTERVENTIONS_SCHOOL_RESOURCES_FAILURE = "@@onboarding/cases/ADD_INTERVENTIONS_SCHOOL_RESOURCES_FAILURE",

  UPDATE_INTERVENTION_GROUP_START = "@@onboarding/cases/UPDATE_INTERVENTION_GROUP_START",
  UPDATE_INTERVENTION_GROUP_SUCCESS = "@@onboarding/cases/UPDATE_INTERVENTION_GROUP_SUCCESS",
  UPDATE_INTERVENTION_GROUP_FAILURE = "@@onboarding/cases/UPDATE_INTERVENTION_GROUP_FAILURE",

  PROGRESS_TOWARDS_GOAL_DELETE_START = "@@onboarding/cases/PROGRESS_TOWARDS_GOAL_DELETE_START",
  PROGRESS_TOWARDS_GOAL_DELETE_SUCCESS = "@@onboarding/cases/PROGRESS_TOWARDS_GOAL_DELETE_SUCCESS",
  PROGRESS_TOWARDS_GOAL_DELETE_FAILURE = "@@onboarding/cases/PROGRESS_TOWARDS_GOAL_DELETE_FAILURE",

  PATCH_INTERVENTION_GROUP_START = "@@onboarding/cases/PATCH_INTERVENTION_GROUP_START",
  PATCH_INTERVENTION_GROUP_SUCCESS = "@@onboarding/cases/PATCH_INTERVENTION_GROUP_SUCCESS",
  PATCH_INTERVENTION_GROUP_FAILURE = "@@onboarding/cases/PATCH_INTERVENTION_GROUP_FAILURE",

  FINISH_INTERVENTION_GROUP_START = "@@onboarding/cases/FINISH_INTERVENTION_GROUP_START",
  FINISH_INTERVENTION_GROUP_SUCCESS = "@@onboarding/cases/FINISH_INTERVENTION_GROUP_SUCCESS",
  FINISH_INTERVENTION_GROUP_FAILURE = "@@onboarding/cases/FINISH_INTERVENTION_GROUP_FAILURE",

  CHANGE_RECOMMENDED_GROUP = "@@onboarding/cases/CHANGE_RECOMMENDED_GROUP",

  DISCARD_RECOMMENDED_GROUP = "@@onboarding/cases/DISCARD_RECOMMENDED_GROUP",

  ADD_BLANK_RECOMMENDED_GROUP = "@@onboarding/cases/ADD_BLANK_RECOMMENDED_GROUP",

  GET_STUDENT_FROM_EDUCATOR = "@@onboarding/cases/GET_STUDENT_FROM_EDUCATOR",

  DELETE_INTERVENTION_GROUP_START = "@@onboarding/cases/DELETE_INTERVENTION_GROUP_START",
  DELETE_INTERVENTION_GROUP_SUCCESS = "@@onboarding/cases/DELETE_INTERVENTION_GROUP_SUCCESS",
  DELETE_INTERVENTION_GROUP_FAILURE = "@@onboarding/cases/DELETE_INTERVENTION_GROUP_FAILURE",

  UNSAVE_INTERVENTION_GROUP_START = "@@onboarding/cases/UNSAVE_INTERVENTION_GROUP_START",
  UNSAVE_INTERVENTION_GROUP_SUCCESS = "@@onboarding/cases/UNSAVE_INTERVENTION_GROUP_SUCCESS",
  UNSAVE_INTERVENTION_GROUP_FAILURE = "@@onboarding/cases/UNSAVE_INTERVENTION_GROUP_FAILURE",

  DISPLAY_GROUP_NAME = "@@onboarding/cases/DISPLAY_GROUP_NAME",

  SET_FBA_MODAL = "@@fba/SET_FBA_MODAL",

  OPEN_CREATE_NEW_INTERVENTION_MODAL = "@@cases/OPEN_CREATE_NEW_INTERVENTION_MODAL",
  HIDE_CREATE_NEW_INTERVENTION_MODAL = "@@cases/HIDE_CREATE_NEW_INTERVENTION_MODAL",

  GET_FBAS_START = "@@fba/GET_FBAS_START",
  GET_FBAS_SUCCESS = "@@fba/GET_FBAS_SUCCESS",
  GET_FBAS_FAILURE = "@@fba/GET_FBAS_FAILURE",

  CREATE_FBA_START = "@@fba/CREATE_FBA_START",
  CREATE_FBA_SUCCESS = "@@fba/CREATE_FBA_SUCCESS",
  CREATE_FBA_FAILURE = "@@fba/CREATE_FBA_FAILURE",

  UPDATE_FBA_START = "@@fba/UPDATE_FBA_START",
  UPDATE_FBA_SUCCESS = "@@fba/UPDATE_FBA_SUCCESS",
  UPDATE_FBA_FAILURE = "@@fba/UPDATE_FBA_FAILURE",

  CREATE_FBA_ABC_START = "@@fba/CREATE_FBA_ABC_START",
  CREATE_FBA_ABC_SUCCESS = "@@fba/CREATE_FBA_ABC_SUCCESS",
  CREATE_FBA_ABC_FAILURE = "@@fba/CREATE_FBA_ABC_FAILURE",

  CREATE_FBA_OBSERVATION_START = "@@fba/CREATE_FBA_OBSERVATION_START",
  CREATE_FBA_OBSERVATION_SUCCESS = "@@fba/CREATE_FBA_OBSERVATION_SUCCESS",
  CREATE_FBA_OBSERVATION_FAILURE = "@@fba/CREATE_FBA_OBSERVATION_FAILURE",

  CHANGE_SELECTED_OBSERVATION = "@@fba/CHANGE_SELECTED_OBSERVATION",

  CHANGE_SELECTED_INTERVENTION = "@@onboarding/CHANGE_SELECTED_INTERVENTION",

  CHANGE_SELECTED_FBA = "@@fba/CHANGE_SELECTED_FBA",

  COMPLETE_FBA_OBSERVATION_START = "@@fba/COMPLETE_FBA_OBSERVATION_START",
  COMPLETE_FBA_OBSERVATION_SUCCESS = "@@fba/COMPLETE_FBA_OBSERVATION_SUCCESS",
  COMPLETE_FBA_OBSERVATION_FAILURE = "@@fba/COMPLETE_FBA_OBSERVATION_FAILURE",

  DELETE_FBA_START = "@@fba/DELETE_FBA_START",
  DELETE_FBA_SUCCESS = "@@fba/DELETE_FBA_SUCCESS",
  DELETE_FBA_FAILURE = "@@fba/DELETE_FBA_FAILURE",

  OPEN_INTERPRET_FBA_MODAL = "@@fba/OPEN_INTERPRET_FBA_MODAL",
  HIDE_INTERPRET_FBA_MODAL = "@@fba/HIDE_INTERPRET_FBA_MODAL",

  OPEN_LEARN_MORE_MODAL = "@@onboarding/LEARN_MORE_MODAL",
  HIDE_LEARN_MORE_MODAL = "@@onboarding/HIDE_LEARN_MORE_MODAL",

  OPEN_UPSERT_ASSESSMENT_MODAL = "@@cases/OPEN_UPSERT_ASSESSMENT_MODAL",
  HIDE_UPSERT_ASSESSMENT_MODAL = "@@cases/HIDE_UPSERT_ASSESSMENT_MODAL",

  OPEN_GROUP_INTERVENTIONS_MODAL = "@@cases/OPEN_GROUP_INTERVENTIONS_MODAL",
  HIDE_GROUP_INTERVENTIONS_MODAL = "@@cases/HIDE_GROUP_INTERVENTIONS_MODAL",

  HIDE_WELCOME_PANEL = "@@onboarding/HIDE_WELCOME_PANEL",

  HIDE_GROUPING_STUDENT_INFO_PANEL = "@@onboarding/HIDE_GROUPING_STUDENT_INFO_PANEL",

  HIDE_THREE_STEP_PROGRESS = "@@onboarding/HIDE_THREE_STEP_PROGRESS",
  SET_SHOW_START_WORKING_INTRO = "@@onboarding/SET_SHOW_START_WORKING_INTRO",
  SET_SHOW_NO_GROUPS_INTRO = "@@onboarding/SET_SHOW_NO_GROUPS_INTRO",
  SET_SHOW_NO_UNSAVED_GROUPS_INTRO = "@@onboarding/SET_SHOW_NO_UNSAVED_GROUPS_INTRO",
  SET_SHOW_NO_ABC_DATA_COLLECTION_INTRO = "@@onboarding/SET_SHOW_NO_ABC_DATA_COLLECTION_INTRO",

  CHANGE_ASSESSMENT_FILTER = "@@cases/CHANGE_ASSESSMENT_FILTER",

  UPDATE_PROGRESS_OVERVIEW = "@@groups/UPDATE_PROGRESS_OVERVIEW",

  OPEN_DELETE_INTERVENTION_LOG_MODAL = "@@cases/OPEN_DELETE_INTERVENTION_LOG_MODAL",
  CLOSE_DELETE_INTERVENTION_LOG_MODAL = "@@cases/CLOSE_DELETE_INTERVENTION_LOG_MODAL",
  
  CALL_FROM_STUDENT_DETAIL = "@@onboarding/cases/CALL_FROM_STUDENT_DETAIL",
  
  CALL_FROM_GROUP_DETAIL = "@@onboarding/cases/CALL_FROM_GROUP_DETAIL",
  
  RESET_SELECTED_PDF_INTERVENTION_PLAN_FILTERS = "@@cases/RESET_SELECTED_PDF_INTERVENTION_PLAN_FILTERS",
  RESET_GOAL_CHOOSE_CONCERN_MODAL = "@@cases/RESET_GOAL_CHOOSE_CONCERN_MODAL",
  SEND_SHARE_DATA_WITH = "@@onboarding/cases/SEND_SHARE_DATA_WITH",
  SEND_NOT_SHARE_DATA_WITH = "@@onboarding/cases/SEND_NOT_SHARE_DATA_WITH",
  SEND_LOG_PROGRESS_DATA_TO_GRAPH = "@@groups/cases/SEND_LOG_PROGRESS_DATA_TO_GRAPH",
  LIST_ORDER_SWITCH = "@@onboarding/cases/LIST_ORDER_SWITCH",
}


export type LatestActivity = {
  display_label: string;
  date: string;
};

export type InterventionGroup = {
  id: number;
  name: string;
  oldId?: number;
  stage?: InterventionGroupStage;
  recommendation_group_key?: string;
  goal_type?: GoalTypes;
  mtss_tier?: 1 | 2 | 3;
  created?: string;
  updated?: string;
  finished?: boolean;
  finished_date?: Date;
  archived?: boolean;
  skipped_prep?: boolean;
  skipped_meeting?: boolean;
  last_logged?: InterventionLog;
  notes?: string;
  coach_assignment?: InterventionGroupAssignment;
  teacher_assignment?: InterventionGroupAssignment;
  associated_assignments: Array<InterventionGroupAssignment>;
  average_fidelity?: number;
  additional_educator_plan: Array<AdditionalEducatorAssignment>;
  students: Array<Student>;
  focuses: Array<Specific> | null;
  interventions: Array<Intervention>;
  student_goals: Array<StudentGoal>;
  needs_areas?: Map<number, Specific[]>;

  time_monday_start: string | null;
  time_tuesday_start: string | null;
  time_wednesday_start: string | null;
  time_thursday_start: string | null;
  time_friday_start: string | null;
  time_monday_end: string | null;
  time_tuesday_end: string | null;
  time_wednesday_end: string | null;
  time_thursday_end: string | null;
  time_friday_end: string | null;

  alert_reason?: any;
  alert_reason_text?: any;
  progress_overview?: ProgressOverview;
  trend_status?: ProgressTrend;
  latest_activity?: LatestActivity;
  latest_meeting?: Meeting;
  intervention_plan: InterventionPlan | null;

  is_sample?: boolean;
  share_data_with: boolean;
  archived_interventions: Array<Intervention>;
  session_duration?: number | null ;
  session_frequency?: number | null;
};

export enum ProgressTrend {
  UNKNOWN = "unknown",
  FLAT = "flat",
  POSITIVE = "positive",
  NEGATIVE = "negative",
}

export const ProgressTrendSortValues = {
  [ProgressTrend.UNKNOWN]: 3,
  [ProgressTrend.NEGATIVE]: 2,
  [ProgressTrend.FLAT]: 1,
  [ProgressTrend.POSITIVE]: 0,
};

export enum GoalTypes {
  ACADEMIC = "academic",
  BEHAVIOR = "behavior",
}

export const GoalTypesDisplayedNames = {
  [GoalTypes.ACADEMIC]: "Academic",
  [GoalTypes.BEHAVIOR]: "Behavior",
};

export type InterventionLog = {
  display_name: string;
  user: UserInfo;
  date: string;
};

export type InterventionGroupPatchRequest = {
  goal_type?: GoalTypes;
  name?: string;
  notes?: string;
  stage?: InterventionGroupStage;
  recommendation_group_key?: string;
  mtss_tier?: 1 | 2 | 3;
  created?: string;
  updated?: string;
  skipped_prep?: boolean;
  skipped_meeting?: boolean;
  coach_assignment?: number;
  teacher_assignment?: number;
  associated_assignments?: Array<InterventionGroupAssignment>;
  additional_educator_plan?: Array<AdditionalEducatorAssignment>;
  students?: number[];
  focuses?: string[] | null;
  interventions?: number[];
  student_goals?: number[];
  needs_areas?: Map<number, Specific[]>;
  students_with_focuses?: Array<{focuses:  SkillFocus[] | undefined, student_id: number | undefined}>;
  intervention_plan?: InterventionPlan | null;

  time_monday_start?: string | null;
  time_tuesday_start?: string | null;
  time_wednesday_start?: string | null;
  time_thursday_start?: string | null;
  time_friday_start?: string | null;
  time_monday_end?: string | null;
  time_tuesday_end?: string | null;
  time_wednesday_end?: string | null;
  time_thursday_end?: string | null;
  time_friday_end?: string | null;
};

export enum InterventionGroupStage {
  STAGE_PREPARATION = 0,
  STAGE_IMPLEMENTATION = 1,
  STAGE_MONITORING = 2,
}

export type GroupRecommendation = {
  id?: number;
  oldId?: number; //store local recommendation groupID
  name: string;
  group_key?: string;
  tier?: 1 | 2 | 3;
  default_interventionist_id?: number;
  default_coach_id?: number;
  students: Student[];
  focuses: Specific[];
  interventions: Intervention[];
  goal_type?: GoalTypes;
  student_goals: Array<StudentGoal>;
  intervention_plan: InterventionPlan | null;
  associated_assignments?: any[];
  additional_educator_plan?: Array<AdditionalEducatorAssignment>;

  time_monday_start: string | null;
  time_tuesday_start: string | null;
  time_wednesday_start: string | null;
  time_thursday_start: string | null;
  time_friday_start: string | null;
  time_monday_end: string | null;
  time_tuesday_end: string | null;
  time_wednesday_end: string | null;
  time_thursday_end: string | null;
  time_friday_end: string | null;

  group_copied_from?: number;

  session_duration?: number | null ;
  session_frequency?: number | null;
};

export enum AlertReason {
  None = "none",
  SupportNeeded = "support_needed",
  InsufficientGoalProgress = "insufficient_goal_progress",
  MetGoal = 'met_goal',
  HighFidelityInsufficientGoal = "high_fidelity_insufficient_goal",
  LowFidelityInsufficientGoal = "low_fidelity_insufficient_goal",
}

export const getAlertReasonDisplayString = (
  reason: AlertReason
): string | undefined => {
  switch (reason) {
    case undefined:
    case AlertReason.None:
      return undefined;
    case AlertReason.SupportNeeded:
      return "Support Intervention Implementation!";
  }
};

export type InterventionGroupRequest = {
  id?: number;
  name: string;
  goal_type?: GoalTypes;
  mtss_tier?: number;
  recommendation_group_key?: string;
  stage?: number;
  created?: string;
  updated?: string;
  coach_assignment?: number;
  teacher_assignment?: number;
  focuses?: Array<string>;
  associated_assignments?: Array<number>;
  additional_educator_plan?: Array<AdditionalEducatorAssignment>;
  students: Array<number>;
  alert_reason?: AlertReason;
  student_focuses?: any[];
  finished?: boolean;
  group_copied_from?: number;
};

export type AddInterventionSchoolRequest = {
  name: string;
  description: string;
  attachments: number[];
  generic_tags: Partial<GenericTag>[];
};

export type GenericTag = {
  id: number;
  tag: string;
};

export type Intervention = {
  id: number;
  setup_steps: Array<SetupStep>;
  checklist_items: Array<ChecklistItem>;
  created: string;
  updated: string;
  is_active: boolean;
  is_system_intervention: boolean;
  is_modification: boolean;
  name: string;
  abbreviation?: string;
  description: string;
  purpose: string;
  preparation?: string;
  prerequisites?: string;
  specific_group: any; //todo
  considerations: string;
  steps_count: number;
  concern_area: ConcernArea;
  concern_type: ConcernType;
  skill_areas: Array<string>;
  focuses: Array<string> | null;
  generic_tags?: Array<GenericTag>;
  expected_change: ExpectedChange;
  target: InterventionTarget;
  forked_from?: Intervention;
  created_by_user: UserInfo;
  attachments: Array<AttachedResource>;
  district: number;
  intervention_plan: InterventionPlan | null;
};

export type InterventionRequest = {
  id?: number;
  checklist_items: Array<{
    title: string;
    details: string;
    order: number;
    attachments: Array<number>;
  }>;
  source_intervention_id?: number;
  source_group_id?: number;
  is_system_intervention?: boolean;
  is_modification?: boolean;
  name: string;
  description: string;
  purpose: string;
  considerations: string;
  concern_area: ConcernArea;
  concern_type?: ConcernType;
  // steps_count: 2;
  expected_change?: ExpectedChange;
  target?: InterventionTarget;

  focuses?: string[];
  skill_areas?: string[];
  district?: number;
};

export type InterventionPlan = {
  plan_items?: InterventionPlanItem[];
};

export type InterventionPlanItem = {
  intervention_id: number;
  order: number;
  state: InterventionPlanStates;
  discontinue_reason?: string;
};

export enum InterventionPlanStates {
  ACTIVE = "active",
  COMPLETED = "completed",
  DISCONTINUED = "discontinued",
}

export const InterventionPlanStatesLabels = {
  [InterventionPlanStates.ACTIVE]: "Active",
  [InterventionPlanStates.COMPLETED]: "Completed",
  [InterventionPlanStates.DISCONTINUED]: "Discontinued",
};

export type ChecklistItem = {
  id?: number;
  created?: string;
  updated?: string;
  is_active: boolean;
  header?: string;
  title: string;
  details: string;
  common_step_name?: string;
  order: number;
  attachments: Array<AttachedResource>;
  intervention?: number;
};

export type SetupStep = {
  id?: number;
  created?: string;
  updated?: string;
  is_active: boolean;
  title: string;
  details?: string;
  order: number;
  intervention?: number;
};

export type AttachedResource = {
  id?: number;
  attached_to_type: AttachedToType;
  resource_type: AttachedResourceType;
  attachment?: File;
  title: string;
  url?: string;
  attachment_url?: string;
  mime_type?: string;
};

export enum AttachedToType {
  CHECKLIST_ITEM = 0,
  INTERVIEW = 1,
  CHAT_MESSAGE = 2,
  INTERVENTION = 3,
  MEASUREMENT,
}

export enum AttachedResourceType {
  FILE = 0,
  URL = 1,
}

export enum ConcernArea {
  ACADEMIC = 0,
  BEHAVIOR = 1,
  BOTH = 2,
}

export const ConcernAreaDisplayedNames = {
  [ConcernArea.ACADEMIC]: "Academic",
  [ConcernArea.BEHAVIOR]: "Behavioral",
  [ConcernArea.BOTH]: "Both",
};

export const ConcernAreaFullDisplayedNames = {
  [ConcernArea.ACADEMIC]: "Academic",
  [ConcernArea.BEHAVIOR]: "Behavioral",
  [ConcernArea.BOTH]: "Academic and Behavioral",
};

export enum ConcernType {
  NEW = 0,
  EXISTING = 1,
  BOTH = 2,
}

export const ConcernTypeValue = {
  [ConcernType.NEW]: "new",
  [ConcernType.EXISTING]: "existing",
  [ConcernType.BOTH]: undefined,
};

export const ConcernTypeDisplayedNames = {
  [ConcernType.NEW]: "New",
  [ConcernType.EXISTING]: "Existing",
  [ConcernType.BOTH]: "Both",
};

export const AcademicConcernTypeInterventionDisplayedNames = {
  [ConcernType.NEW]: "New Skill",
  [ConcernType.EXISTING]: "Existing Skill",
  [ConcernType.BOTH]: "Existing and New Skills",
};

export const ConcernTypeInterventionDisplayedNames = {
  [ConcernType.NEW]: "New Behavior",
  [ConcernType.EXISTING]: "Existing Behavior",
  [ConcernType.BOTH]: "Existing and New Behaviors",
};

export enum ExpectedChange {
  MAINTAIN = 0,
  INCREASE = 1,
  DECREASE = 2,
}

export const ExpectedChangeDisplayedNames = {
  [ExpectedChange.MAINTAIN]: "Maintaining",
  [ExpectedChange.INCREASE]: "Increasing",
  [ExpectedChange.DECREASE]: "Decreasing",
};

export enum InterventionTarget {
  INDIVIDUALS = 0,
  SMALL_GROUP = 1,
  WHOLE_CLASS = 2,
  ALL = 3
}

export enum ExtendedInterventionTarget {
  INDIVIDUALS_AND_GROUPS = 4,
  WHOLE_CLASS_AND_GROUPS = 5,
  WHOLE_CLASS_AND_INDIVIDUALS = 6
}

export const InterventionTargetDisplayedNames = {
  [InterventionTarget.INDIVIDUALS]: "Individuals",
  [InterventionTarget.SMALL_GROUP]: "Small Groups",
  [InterventionTarget.WHOLE_CLASS]: "Whole Class",
  [InterventionTarget.ALL]: "All"
};

export enum InterventionDuration {
  SHORT_TERM = 0,
  MODERATE = 1,
  LONG_TERM = 2,
}

export const InterventionDurationDisplayedNames = {
  [InterventionDuration.SHORT_TERM]: "Short Term (less than a month)",
  [InterventionDuration.MODERATE]: "Moderate (2 - 4 months)",
  [InterventionDuration.LONG_TERM]: "Long Term (more than 6 months)",
};

export enum SetGoalSteps {
  // SELECT_ASSESSMENT = 1,
  TARGET_BEHAVIOR = 2,
  SELECT_DETAILED_METRIC = 3,
  SET_UP_DETAILED_METRIC = 4,
  SET_OUTCOME = 5,
  SET_OUTCOME_NOW = 6,
  SET_DEAD_LINE = 7,
  INPUT_GAS = 8,
  INPUT_GAS_DEAD_LINE = 9,
  BRS_SET_OUTCOME = 10,
  BRS_SET_DEAD_LINE = 11,
}

export enum AssessmentMethods {
  DIRECT_OBSERVATION = 1,
  GOAL_ATTAINMENT_SCALE = 2,
  BEHAVIOR_RATING_SCALES = 3,
}

export const AssessmentMethodsDisplayedNames = {
  [AssessmentMethods.DIRECT_OBSERVATION]: "Direct Observation",
  [AssessmentMethods.GOAL_ATTAINMENT_SCALE]: "Goal Attainment Scale",
  [AssessmentMethods.BEHAVIOR_RATING_SCALES]: "Behavior Rating Scales",
};

export enum ObservationTypes {
  FREQUENCY_OR_EVENT = 1,
  INTERVAL = 2,
  DURATION = 3,
  LATENCY = 4,
}

export const ObservationTypesDisplayedNames = {
  [ObservationTypes.FREQUENCY_OR_EVENT]: "Frequency",
  [ObservationTypes.INTERVAL]: "Interval",
  [ObservationTypes.DURATION]: "Duration",
  [ObservationTypes.LATENCY]: "Latency",
};

export enum PurposeTypes {
  DO_OBSERVATION_TO_ESTABLISH_BASELINE = 1,
  SET_OUTCOME_CRITERION_NOW = 2,
}

export enum StaticMeasurementTypes {
  TOTAL_COUNT = 1,
  RATE = 2,
  PARTIAL_INTERVAL_RECORDING = 3,
  FULL_INTERVAL_RECORDING = 4,
  MOMENTARY_TIME_SAMPLING = 5,
  DURATION = 6,
  LATENCY = 7,
}

export const StaticMeasurementTypesDisplayedNames = {
  [StaticMeasurementTypes.TOTAL_COUNT]: "Total Count",
  [StaticMeasurementTypes.RATE]: "Rate",
  [StaticMeasurementTypes.PARTIAL_INTERVAL_RECORDING]:
    "Partial-Interval Recording",
  [StaticMeasurementTypes.FULL_INTERVAL_RECORDING]: "Whole-Interval Recording",
  [StaticMeasurementTypes.MOMENTARY_TIME_SAMPLING]: "Momentary Time Sampling",
  [StaticMeasurementTypes.DURATION]: "Measure Duration of Target Behavior",
  [StaticMeasurementTypes.LATENCY]: "Measure Latency of Target Behavior",
};

export type TargetDescription = {
  studentId: number;
  description?: string;
  active: boolean;
};

export type GasMeasurement = {
  id?: number;
  assessment?: number;
  column_name?: string | null;
  display_name: string;
  type: number;
  possible_values: Array<PossibleValue>;
  is_gas: boolean;
  is_active?: boolean;
  baseline_order: number;
  target_goal_order: number;
  worst_level_order: number;
};

export type InterventionGroupAssignment = {
  id: number;
  schools: Array<School>;
  user: UserInfo;
  // user: UserAssignmentInfo; todo checl for RI
  created: string;
  updated: string;
  is_active: boolean;
  role: string;
  school_year: number;
};

export type AdditionalEducatorAssignment = {
  current_assignment_id: number;
  user_id: number;
  can_edit: boolean;
};

export type StudentGoalForGroup = {
  concern_area: ConcernArea;
  target_date?: string;
  target_value?: number;
  measurement_id: number;
  observation_unit_description?: string;
  state?: number;
  goal_statement?: string;
  current_baseline?: string;
  goal_id?: number;
  is_primary_goal?: boolean;
  is_general_outcome?: boolean;
  create_new_goal?: boolean;
};

export type StudentGoal = {
  id: number;
  archived: boolean;
  concern_area: ConcernArea;
  created: string;
  updated: string;
  discontinue_reason?: string;
  discontinue_reason_other?: string;
  observation_unit_description?: string;
  measurement: Measurement;
  state: number;
  student: Student;
  target_behavior_description: string;
  target_date: string;
  target_value: string;
  goal_statement: string;
  is_met_goal?: boolean;
  is_primary_goal: boolean;
  is_general_outcome: boolean;
  insufficient_goal_progress?: boolean;
};

export type Fba = {
  id: number;
  created: string;
  updated: string;
  is_active: boolean;
  target_behavior?: number | null; // todo
  can_perform_replacement: boolean;
  antecedents_effective: boolean;
  observer: UserInfo;
  teacher: BasicUserInfo;
  student: BasicUserInfo;
  intervention_group?: InterventionGroup;
  replacement_behavior?: number | null;
  replacement_behavior_other?: string;
  observations: Array<FbaObservation>;
  quick_log_observation: FbaObservation;
};

export type FbaABC = {
  id: number;
  created: string;
  updated: string;
  is_active: boolean;
  antecedents: number[];
  behaviors: number[];
  consequence: number;
  other_antecedents?: string[];
  other_behavior?: string;
  other_sequence?: string;
  behaviors_display: string;
  antecedent_display: string;
  consequence_display: string;
  observation?: number;
  behavior_descriptions?: {
    [behaviorId: number]: string | undefined;
  };
};

export type FbaObservation = {
  id: number;
  completed_at: string | null; // "yyyy/mm/dd hh:mmA"
  created: string;
  updated: string;
  is_active: boolean;
  order: 0 | 1;
  fba: number;
  tally: FbaObservationTally;
  observationabc: Array<FbaABC>;
};

export type FbaObservationTally = {
  antecedent: FbaAntecedentTally[];
  behavior: FbaBehaviorTally[];
  consequence: FbaConsequenceTally[];
};

export type FbaAntecedentTally = {
  antecedents: number[];
  count: number;
  other_antecedents?: string[];
};

export type FbaBehaviorTally = {
  behaviors: number[];
  count: number;
  other_behavior?: string;
};

export type FbaConsequenceTally = {
  consequence: number;
  count: number;
  other_sequence?: string;
};

export type BehaviorTally = {
  behavior: number;
  count: number;
  other_behavior?: string;
};

export enum AbcTypes {
  behavior = "behavior",
  behaviors = "behaviors",
  antecedent = "antecedent",
  antecedents = "antecedents",
  consequence = "consequence",
}

export const FbaBehavior = {
  BEHAVIOR_OTHER: 0,
  BEHAVIOR_OFF_TASK: 1,
  BEHAVIOR_NON_COMPLIANCE: 2,
  BEHAVIOR_INAPPROPRIATE_LANGUAGE: 3,
  BEHAVIOR_YELLING: 4,
  BEHAVIOR_TANTRUM: 5,
  BEHAVIOR_VERBAL_AGGRESSION: 6,
  BEHAVIOR_PHYSICAL_AGGRESSION: 7,
  BEHAVIOR_PROPERTY_DESTRUCTION: 8,
  BEHAVIOR_SELF_INJURIOUS: 9,
};

export const FbaBehaviorDisplayed = {
  [FbaBehavior.BEHAVIOR_OTHER]: "Other",
  [FbaBehavior.BEHAVIOR_OFF_TASK]: "Off-task",
  [FbaBehavior.BEHAVIOR_NON_COMPLIANCE]: "Non-compliance",
  [FbaBehavior.BEHAVIOR_INAPPROPRIATE_LANGUAGE]: "Inappropriate Language",
  [FbaBehavior.BEHAVIOR_YELLING]: "Yelling",
  [FbaBehavior.BEHAVIOR_TANTRUM]: "Tantrum",
  [FbaBehavior.BEHAVIOR_VERBAL_AGGRESSION]: "Verbal Aggression",
  [FbaBehavior.BEHAVIOR_PHYSICAL_AGGRESSION]: "Physical Aggression",
  [FbaBehavior.BEHAVIOR_PROPERTY_DESTRUCTION]: "Property Destruction",
  [FbaBehavior.BEHAVIOR_SELF_INJURIOUS]: "Self Injurious",
};

export const FbaConsequence = {
  CONSEQUENCE_RECEIVED_OTHER: 0,
  CONSEQUENCE_RECEIVED_ATTENTION: 1,
  CONSEQUENCE_RECEIVED_UNDESIRED_ACTIVITY: 2,
  CONSEQUENCE_RECEIVED_SENSORY_STIMULATION: 3,
  CONSEQUENCE_AVOIDED_OTHER: 4,
  CONSEQUENCE_AVOIDED_ATTENTION: 5,
  CONSEQUENCE_AVOIDED_UNDESIRED_ACTIVITY: 6,
  CONSEQUENCE_AVOIDED_SENSORY_STIMULATION: 7,
  N1: 8,
  N2: 9,
  N3: 10,
  N4: 11,
  N5: 12,
  N6: 13,
  N7: 14,
  N8: 15,
  N9: 16,
  N10: 17,
  N11: 18,
  N12: 19,
  N13: 20,
  N14: 21,
};

export const FbaConsequenceDisplayed = {
  [FbaConsequence.CONSEQUENCE_RECEIVED_OTHER]: "Other",
  [FbaConsequence.CONSEQUENCE_RECEIVED_ATTENTION]: "Received Attention",
  [FbaConsequence.CONSEQUENCE_RECEIVED_UNDESIRED_ACTIVITY]:
    "Received Undesired Activity",
  [FbaConsequence.CONSEQUENCE_RECEIVED_SENSORY_STIMULATION]:
    "Received Sensory Stimulation",
  [FbaConsequence.CONSEQUENCE_AVOIDED_OTHER]: "Avoided Other",
  [FbaConsequence.CONSEQUENCE_AVOIDED_ATTENTION]: "Avoided Attention",
  [FbaConsequence.CONSEQUENCE_AVOIDED_UNDESIRED_ACTIVITY]:
    "Avoided Undesired Activity",
  [FbaConsequence.CONSEQUENCE_AVOIDED_SENSORY_STIMULATION]:
    "Avoided Sensory Stimulation",
  [FbaConsequence.N1]: "Removed from situation or setting",
  [FbaConsequence.N2]: "Denied access to item or activity",
  [FbaConsequence.N3]: "Verbally re-directed or corrected",
  [FbaConsequence.N4]: "Given access to desired object or activity",
  [FbaConsequence.N5]: "Verbal reminder",
  [FbaConsequence.N6]: "Interaction with peer",
  [FbaConsequence.N7]: "Demand withdrawn",
  [FbaConsequence.N8]: "Positive praise",
  [FbaConsequence.N9]: "Received the “look”",
  [FbaConsequence.N10]:
    "Adult interaction explaining or reasoning with student",
  [FbaConsequence.N11]: "Asked to change seating",
  [FbaConsequence.N12]: "Announced consequence/loss of privilege",
  [FbaConsequence.N13]: "Moved to time-out",
  [FbaConsequence.N14]: "Ignored",
};

export const FbaConsequenceFunctions = {
  [FbaConsequence.N1]: ["Escape from Demand/Activity"],
  [FbaConsequence.N2]: ["Escape from Demand/Activity"],
  [FbaConsequence.N3]: ["Access to Attention"],
  [FbaConsequence.N4]: ["Access to Object/Activity"],
  [FbaConsequence.N5]: ["Access to Attention"],
  [FbaConsequence.N6]: ["Access to Attention"],
  [FbaConsequence.N7]: null,
  [FbaConsequence.N8]: ["Access to Attention"],
  [FbaConsequence.N9]: ["Access to Attention"],
  [FbaConsequence.N10]: ["Access to Attention"],
  [FbaConsequence.N11]: ["Access to Attention", "Escape from Attention"],
  [FbaConsequence.N12]: ["Access to Attention"],
  [FbaConsequence.N13]: ["Access to Attention", "Escape from Demand/Activity"],
  [FbaConsequence.N14]: null,
};

export enum FbaAntecedent {
  TargetTeacher,
  TargetParaprofessional,
  TargetOtherSchoolStaff,
  TargetPeers,
  TargetAlone,
  TargetOther,

  PlaceClassroomWholeClass,
  PlaceClassroomSmallGroup,
  PlaceClassroomIndividual,
  PlaceClassroomTransition,
  PlaceHallway,
  PlaceRecess,
  PlaceLunch,
  PlaceRestroom,
  PlaceOther,

  ActionEngagedInActivity,
  ActionEngagedInInstruction,
  ActionAskedToStopActivity,
  ActionWasNotGivenActivity,
  ActionWorkedOnAssigment,
  ActionWasPlaying,
  ActionWasWaiting,
  ActionWasProvoked,
  ActionEngagedInUnstructuredTime,
  ActionOther,
}
export const FbaAntecedentDisplayed: { [key: number]: string } = {
  [FbaAntecedent.TargetTeacher]: "Teacher",
  [FbaAntecedent.TargetParaprofessional]: "Paraprofessional",
  [FbaAntecedent.TargetOtherSchoolStaff]: "Other School Staff",
  [FbaAntecedent.TargetPeers]: "Peer(s)",
  [FbaAntecedent.TargetAlone]: "Alone",
  [FbaAntecedent.TargetOther]: "Other",

  [FbaAntecedent.PlaceClassroomWholeClass]: "Classroom (Whole Class)",
  [FbaAntecedent.PlaceClassroomSmallGroup]: "Classroom (Small Group)",
  [FbaAntecedent.PlaceClassroomIndividual]: "Classroom (Individual)",
  [FbaAntecedent.PlaceClassroomTransition]: "Classroom (Transition)",
  [FbaAntecedent.PlaceHallway]: "Hallway",
  [FbaAntecedent.PlaceRecess]: "Recess",
  [FbaAntecedent.PlaceLunch]: "Lunch",
  [FbaAntecedent.PlaceRestroom]: "Restroom",
  [FbaAntecedent.PlaceOther]: "Other",

  [FbaAntecedent.ActionEngagedInActivity]: "Engaged in an Activity",
  [FbaAntecedent.ActionEngagedInInstruction]: "Given instruction or prompt",
  [FbaAntecedent.ActionAskedToStopActivity]:
    "Asked/required to stop an activity",
  [FbaAntecedent.ActionWasNotGivenActivity]:
    "Was not given requested activity/item",
  [FbaAntecedent.ActionWorkedOnAssigment]: "Working on an assigment",
  [FbaAntecedent.ActionWasPlaying]: "Playing",
  [FbaAntecedent.ActionWasWaiting]: "Waiting",
  [FbaAntecedent.ActionWasProvoked]: "Provoked",
  [FbaAntecedent.ActionEngagedInUnstructuredTime]:
    "Engaged in unstructured time",
  [FbaAntecedent.ActionOther]: "Other",
};

export const FbaConsequenceColors = {
  [FbaConsequence.CONSEQUENCE_RECEIVED_OTHER]: "#a804d9",
  [FbaConsequence.CONSEQUENCE_RECEIVED_ATTENTION]: "#f7a79f",
  [FbaConsequence.CONSEQUENCE_RECEIVED_UNDESIRED_ACTIVITY]: "#c13959",
  [FbaConsequence.CONSEQUENCE_RECEIVED_SENSORY_STIMULATION]: "#0d1449",
  [FbaConsequence.CONSEQUENCE_AVOIDED_OTHER]: "#0d1449",
  [FbaConsequence.CONSEQUENCE_AVOIDED_ATTENTION]: "#e2e7d7",
  [FbaConsequence.CONSEQUENCE_AVOIDED_UNDESIRED_ACTIVITY]: "#a804d9",
  [FbaConsequence.CONSEQUENCE_AVOIDED_SENSORY_STIMULATION]: "#f7a79f",
};

export enum FbaModalTabs {
  SUMMARY = "summary",
  CHART = "chart",
}

export type LessonNotes = {
  id: number;
  intervention_group_id: number;
  date: string;
  notes: string;
  group?: InterventionGroup;
  interventions: number[]; // FK: Intervention
  is_general: boolean;
  record_tags: LessonNoteRecordTag[];
  viewable_by: number[];
  user: UserInfo;
  attachments?: AttachedResource[];
  pertaining_students?: number[];
};

export type LessonNoteRecordTag = {
  order: number;
  type: LessonNoteRecordTagType;
  text: string;
};

export enum LessonNoteRecordTagType {
  RcModeled = "rc_modeled",
  RcPracticed = "rc_practiced",
  RcNeedsPractice = "rc_needs_practice",
  RcNeedsModeling = "rc_needs_modeling",
  Custom = "custom",
}

export const LessonNoteRecordTagTypeDisplayedValues = {
  [LessonNoteRecordTagType.RcModeled]: "We modeled",
  [LessonNoteRecordTagType.RcPracticed]: "We practiced",
  [LessonNoteRecordTagType.RcNeedsPractice]: "Need to model",
  [LessonNoteRecordTagType.RcNeedsModeling]: "Need to practice",
  [LessonNoteRecordTagType.Custom]: "Custom",
};

export type Attendance = {
  id: number;
  group: number;
  interventionGroup?: InterventionGroup;
  date: string;
  interventions: number[];
  present_students: number[];
  absent_students: number[];
};

export const CommonSteps: ReactSelectOption<any>[] = [
  {value: 'Connect to Prior Knowledge', label:	'Connect to Prior Knowledge'},
  {value: 'Direct & Explicit Instruction of Concept/Skill', label:	'Direct & Explicit Instruction of Concept/Skill'},
  {value: 'Provide Modeling', label:	'Provide Modeling'},
  {value: 'Provide Guided Practice', label:	'Provide Guided Practice'},
  {value: 'Performance Feedback', label:	'Performance Feedback'},
  {value: 'Provide Independent Practice', label:	'Provide Independent Practice'},
  {value: 'Check for Understanding', label:	'Check for Understanding'}
]