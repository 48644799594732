import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Link } from "react-router-dom";

const RostersInfoBar = () => {
  return (
    <div className="actionInfoContainer">
      <div className="d-flex align-items-center w-100">
        <div className="actionInfoBigIcon">
          <FontAwesomeIcon
            icon={faThumbsUp}
            size="2x"
            style={{ color: "#8bc34a" }}
          />
        </div>
        <div className="align-items-center">
          <div className="actionInfoSmallIcon">
            <FontAwesomeIcon icon={faThumbsUp} style={{ color: "#8bc34a" }} />
          </div>
          <h3 className="font-weight-bold d-inline">
            Great! You invited some educators!
          </h3>
          <h4 className="mb-0">
            You can invite more educators or roster students into the
            application. Once you are done, you can begin gathering evidence for
            student needs.
          </h4>
        </div>
      </div>
      <div>
        <Link to="/data">
          <Button bsPrefix="b" className="blueBtnMd text-nowrap">
            GATHER EVIDENCE FOR STUDENT NEEDS
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default RostersInfoBar;
