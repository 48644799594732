import React, { FunctionComponent, useMemo } from "react";
import {
  Intervention,
  InterventionGroup,
} from "../../../../../../../../../store/onboarding/cases/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../../../store";
import useInterventionPlan from "../../../../implementation-check/hooks/useInterventionPlan";
import { getFullName } from "../../../../../../../../../utils/NamesUtils";
import _ from "lodash";
import moment from "moment";

type OwnProps = {
  interventionGroup: InterventionGroup;
  onlyActive?: boolean;
};

type Props = OwnProps;

const AttendanceSummary: FunctionComponent<Props> = (props) => {
  const { interventionGroup, onlyActive } = props;

  // const { implementationChecks } = useInterventionPlan(
  //   interventionGroup,
  //   onlyActive
  // );
  //const lessonNotes = useSelector((s: ApplicationState) => s.cases.lessonNotes);
  const attendances = useSelector((s: ApplicationState) => s.cases.attendances);

  const showArchivedGroups = useSelector(
    (s: ApplicationState) => s.dataFilter.showArchivedGroups
  );

  const displayGroupName = useSelector((s: ApplicationState) => s.cases.displayGroupName);

  // const implCheckObservations = useMemo(() => {
  //   return implementationChecks.map((ic) => ({
  //     date: ic.observed_date,
  //     interventions: [ic.intervention],
  //   }));
  // }, [implementationChecks]);

  // const lessonNotesObservations = useMemo(
  //   () =>
  //     lessonNotes.map((ls) => ({
  //       date: ls.date,
  //       interventions: ls.interventions,
  //     })),
  //   [lessonNotes]
  // );

  const attendanceObservations = useMemo(
    () =>
      attendances.map((a) => ({
        date: a.date,
        interventions: a.interventions,
      })),
    [attendances]
  );

  const allObservations = useMemo(() => {
    return _.chain<{ date: string; interventions: number[] }>([])
      .concat(
        //implCheckObservations,
        //lessonNotesObservations,
        attendanceObservations
      )
      .groupBy((intDate) => moment(intDate.date).format(moment.HTML5_FMT.DATE))
      .values()
      .map((intDates) => ({
        date: intDates[0].date,
        interventions: _.chain(intDates)
          .flatMap((intDate) => intDate.interventions)
          .uniq()
          .value(),
      }))
      .value();
  }, [attendanceObservations]);

  const summary = useMemo(() => {
    return interventionGroup.interventions.length || interventionGroup.archived_interventions.length
      ? _.chain(interventionGroup.students)
          .filter((std) => interventionGroup.student_goals
                        .some((sg) => (sg.student.id == std.id) && ((displayGroupName == 'archived') || showArchivedGroups ? sg.archived : !sg.archived)))
          .map((s) => ({
            student: s,
            interventions: interventionGroup.interventions.map((int) => {
              const filtered = allObservations.filter((m) =>
                m.interventions.includes(int.id)
              );
              return {
                intervention: int,
                attendanceRate:
                  filtered
                    .map((m) => {
                      return attendances
                        .find((a) => moment(a.date).isSame(m.date, "day"))
                        ?.present_students?.includes(s.id!);
                    })
                    .filter((present) => present).length /
                  (filtered.length || 1),
              };
            }),
            archived_interventions: interventionGroup.archived_interventions.map((int) => {
              const filtered = allObservations.filter((m) =>
                m.interventions.includes(int.id)
              );
              return {
                intervention: int,
                attendanceRate:
                  filtered
                    .map((m) => {
                      return attendances
                        .find((a) => moment(a.date).isSame(m.date, "day"))
                        ?.present_students?.includes(s.id!);
                    })
                    .filter((present) => present).length /
                  (filtered.length || 1),
              };
            }),
          }))
          .value()
      : [];
  }, [interventionGroup, allObservations]);

  
  let inter: Intervention[] = [];

  summary.map((int) =>
    int.interventions.filter((f) => {
      if (f.attendanceRate !== 0) {
        inter.push(f.intervention);
      }
    })
  );

  summary.map((int) =>
    int.archived_interventions.filter((f) => {
      if (f.attendanceRate !== 0) {
        inter.push(f.intervention);
      }
    })
  );


  inter = inter.filter(function(x, i, a) {
    return a.indexOf(x) === i;
  });

  let interIds = inter.map((i) => i.id);

  return (
    <div>
      {summary.length > 0 && (
        <div className="mb-3 font-weight-bold ">
          Attendance summary reflects interventions for which attendance was
          recorded.
        </div>
      )}
      {summary.map((s) => {
        let interventions = s.interventions;
        if(s.archived_interventions.filter((archInt) => !s.interventions.some((int) => int.intervention.id == archInt.intervention.id)).length) {
          s.archived_interventions.forEach((arInt) => interventions.push(arInt))
        }
        return <p key={s.student.id} className="mb-2">
          <strong>{getFullName(s.student)}</strong>
          {" was present "}
          {interventions.map((int, index) => {
            if (interIds.includes(int.intervention.id)) {
              return `, ${(int.attendanceRate * 100).toFixed()}% ${
                index ? "of" : "for"
              } ${int.intervention.name}`;
            }
          })}
        </p>
      })}
    </div>
  );
};

export default AttendanceSummary;
