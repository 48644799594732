import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import ObservationIntro from "../subpages/do/ObservationIntro";
import ObservationSetOutcome from "../subpages/do/ObservationSetOutcome";
import { ApplicationState } from "../../../../../../store";
import {
  Assessment,
  Measurement,
} from "../../../../../../store/onboarding/types";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  ConcernArea,
  InterventionGroup,
  StudentGoal,
  StudentGoalForGroup,
} from "../../../../../../store/onboarding/cases/types";
import { dateToFormatString } from "../../../../../utils/DateTimeUtils";
import {
  addInterventionImmediately,
  createStudentGoalForInterventionGroup,
  createStudentGoalForStudent,
  hideSetGoalChooseConcernModal,
  resetGoalChooseConcernModel,
} from "../../../../../../store/onboarding/cases/actions";
import SetDeadLine from "../subpages/SetDeadLine";
import { bindActionCreators, Dispatch } from "redux";
import { toastr } from "react-redux-toastr";
import moment from "moment";

type StateProps = {
  staticData: {
    measurements: Array<Measurement>;
    assessments: Array<Assessment>;
  };
  interventionGroup: InterventionGroup;
  studentGoal?: StudentGoal;
};

type DispatchProps = {
  createStudentGoalForInterventionGroup: (
    interventionGroupId: number,
    goal: StudentGoalForGroup
  ) => any;
  createStudentGoalForStudent: (
    interventionGroupId: number,
    studentId: number,
    goal: StudentGoalForGroup
  ) => any;
  hideSetGoalChooseConcernModal: () => any;
};

type OwnProps = {
  findDifferentAssessment?: Function;
};

type Props = OwnProps & StateProps & DispatchProps;

enum ObservationSteps {
  INTRO,
  SET_OUTCOME,
  // SET_OUTCOME_NOW,
  SET_DEAD_LINE,
}

const ObservationTab: FunctionComponent<Props> = ({
  staticData,
  studentGoal: pStudentGoal,
  interventionGroup,
  createStudentGoalForStudent,
  createStudentGoalForInterventionGroup,
  hideSetGoalChooseConcernModal,
  findDifferentAssessment,
}) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [selectedMeasurement, setSelectedMeasurement] = useState<
    Measurement | undefined
  >();

  const [targetValue, setTargetValue] = useState<string>("");
  const [targetDate, setTargetDate] = useState<Date | undefined>(undefined);
  const [observationUnitDescription, setObservationUnitDescription] = useState<
    string
  >("");

  useEffect(() => {
    if (pStudentGoal?.id) {
      setSelectedMeasurement(
        staticData.measurements.find(
          (m) => m.id === pStudentGoal.measurement.id
        )
      );
      setTargetValue(pStudentGoal.target_value ?? "");
      setTargetDate(
        pStudentGoal.target_date
          ? moment(pStudentGoal.target_date, "YYYY-MM-DD").toDate()
          : undefined
      );
      setObservationUnitDescription(
        pStudentGoal.observation_unit_description ?? ""
      );
    }
  }, [pStudentGoal, staticData]);

  const studentGoalOptions = useSelector(
    (s: ApplicationState) => s.cases.modalsState.studentGoalOptions
  );

  const [isSameGoalForAllStudents, setIsSameGoalForAllStudents] = useState(
    false
  );

  const [customTargetOutcome, setCustomTargetOutcome] = useState<{
    [studentId: number]: string;
  }>({});

  const content = useMemo(() => {
    switch (step) {
      case ObservationSteps.INTRO:
        return (
          <ObservationIntro
            selectedMeasurement={selectedMeasurement}
            setSelectedMeasurement={setSelectedMeasurement}
            observationUnitDescription={observationUnitDescription}
            setObservationUnitDescription={setObservationUnitDescription}
            onClickNext={() => {
              setStep(
                studentGoalOptions
                  ? ObservationSteps.SET_DEAD_LINE
                  : ObservationSteps.SET_OUTCOME
              );
            }}
            findDifferentAssessment={findDifferentAssessment}
          />
        );
      case ObservationSteps.SET_OUTCOME:
        return (
          <ObservationSetOutcome
            selectedMeasurement={selectedMeasurement!}
            onClickBack={() => setStep(ObservationSteps.INTRO)}
            onClickNext={() => setStep(ObservationSteps.SET_DEAD_LINE)}
          />
        );
      case ObservationSteps.SET_DEAD_LINE: {
        const setGoal = (currentBaseline?: string) => {
          const studentGoal: StudentGoalForGroup = {
            concern_area: ConcernArea.BEHAVIOR,
            measurement_id: selectedMeasurement!.id!,
            target_date: dateToFormatString(targetDate!),
            observation_unit_description: observationUnitDescription,
            target_value: +targetValue,
            current_baseline: currentBaseline,
            goal_statement: studentGoalOptions?.goalStatement
          };
          if (pStudentGoal) {
            createStudentGoalForStudent(
              interventionGroup.id!,
              pStudentGoal.student.id!,
              studentGoal
            ).then(
              () => hideSetGoalChooseConcernModal(),
              (err: any) => toastr.error("Error", err)
            );
          } else {
            dispatch(resetGoalChooseConcernModel(false));
            if (isSameGoalForAllStudents) {
              createStudentGoalForInterventionGroup(
                interventionGroup.id!,
                studentGoal
              ).then(
                (updatedStudentGroup: InterventionGroup) => {
                  if (studentGoalOptions) {
                    dispatch(addInterventionImmediately(updatedStudentGroup));
                  } else {
                    hideSetGoalChooseConcernModal();
                  }
                },
                (err: any) => toastr.error("Error", err)
              );
            } else {
              Promise.all(
                interventionGroup.students.map((student) =>
                  createStudentGoalForStudent(
                    interventionGroup.id!,
                    student.id!,
                    {
                      ...studentGoal,
                      target_value: +customTargetOutcome[student.id!],
                    }
                  )
                )
              ).then(
                (data) => {
                  if (data?.length) {
                    if (studentGoalOptions) {
                      dispatch(
                        addInterventionImmediately(
                          data[data.length].intervention_group
                        )
                      );
                    } else {
                      hideSetGoalChooseConcernModal();
                    }
                  }
                },
                (err: any) => toastr.error("Error", err)
              );
            }
          }
        };
        return (
          <SetDeadLine
            existingGoal={!!pStudentGoal?.id}
            targetDate={targetDate}
            setTargetDate={setTargetDate}
            selectedMeasurement={selectedMeasurement!}
            observationUnitDescription={observationUnitDescription}
            targetValue={targetValue}
            setTargetValue={setTargetValue}
            onBackClick={() =>
              setStep(
                studentGoalOptions
                  ? ObservationSteps.INTRO
                  : ObservationSteps.SET_OUTCOME
              )
            }
            setGoal={setGoal}
            isSameGoalForAllStudents={isSameGoalForAllStudents}
            setIsSameGoalForAllStudents={setIsSameGoalForAllStudents}
            customTargetOutcome={customTargetOutcome}
            setCustomTargetOutcome={setCustomTargetOutcome}
          />
        );
      }
    }
  }, [
    step,
    pStudentGoal,
    interventionGroup,
    targetDate,
    targetValue,
    selectedMeasurement,
    observationUnitDescription,
    studentGoalOptions,
    customTargetOutcome,
    isSameGoalForAllStudents,
  ]);

  return <div>{content}</div>;
};

const mapStateToProps = ({ cases }: ApplicationState): StateProps => {
  return {
    staticData: cases.staticData,
    interventionGroup: cases.selectedInterventionGroup!,
    studentGoal: cases.selectedStudentGoal,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      createStudentGoalForInterventionGroup: createStudentGoalForInterventionGroup,
      createStudentGoalForStudent: createStudentGoalForStudent,
      hideSetGoalChooseConcernModal: hideSetGoalChooseConcernModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ObservationTab);
