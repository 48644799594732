import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { hideAnalyzeFbaResultsModal } from "../../../../../../store/onboarding/cases/actions";
import { getFullName } from "../../../../../../utils/NamesUtils";
import ListBehaviorsBody from "./ListBehaviorsBody";
import AbcTableBody from "./AbcTableBody";
import {
  BehaviorTally,
  StudentGoalOptions,
} from "../../../../../../store/onboarding/cases/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ModalCloseButton from "../../../../../common/onboarding/third-step/group-students/common/ModalCloseButton";
import DecisionBody from "./DecisionBody";
import { CategoryBehavior } from "../../../../../../store/onboarding/types";
import SetGoalBody from "./SetGoalBody";

type Props = {};

const AnalyzeFbaResultsModal: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const show = useSelector(
    (s: ApplicationState) => s.cases.modalsState.showAnalyzeFbaResultsModal
  );
  const handleModalHide = useCallback(() => {
    dispatch(hideAnalyzeFbaResultsModal());
  }, []);

  const fba = useSelector((s: ApplicationState) => s.cases.selectedFba);

  const [step, setStep] = useState<number>(0);

  const handleNextStep = useCallback(() => {
    setStep((s) => s + 1);
  }, []);
  const handlePrevStep = useCallback(() => {
    setStep((s) => s - 1);
  }, []);

  const [selectedBehTally, setSelectedBehTally] = useState<
    BehaviorTally | undefined
  >();

  const listBehaviorsBody = useMemo(
    () => (
      <ListBehaviorsBody
        onContinue={handleNextStep}
        observations={fba?.observations ?? []}
        selectedBehTally={selectedBehTally}
        setSelectedBehTally={setSelectedBehTally}
      />
    ),
    [selectedBehTally, handleNextStep, fba?.observations]
  );

  const [selectedAntecedent, setSelectedAntecedent] = useState<
    | { abcId: number; antecedents: number[]; other_antecedents?: string[] }
    | undefined
  >();
  const [selectedConsequence, setSelectedConsequence] = useState<
    { abcId: number; consequence: number; other_sequence?: string } | undefined
  >();

  const [defaultValues, setDefaultValues] = useState<
    | Pick<
        StudentGoalOptions,
        "context" | "desiredChange" | "measuredBy" | "targetDate"
      >
    | undefined
  >();

  const studentGoalOptions = useSelector<
    ApplicationState,
    StudentGoalOptions | undefined
  >((s) => s.cases.modalsState.studentGoalOptions);

  const handleModalShow = useCallback(() => {
    if (studentGoalOptions && studentGoalOptions.fbaId === fba?.id) {
      setStep(3);
      setSelectedBehTally(studentGoalOptions.problemBehavior);
      setSelectedAntecedent(studentGoalOptions.selectedAntecedent);
      setSelectedConsequence(studentGoalOptions.selectedConsequence);
      setDefaultValues({
        context: studentGoalOptions.context,
        desiredChange: studentGoalOptions.desiredChange,
        measuredBy: studentGoalOptions.measuredBy,
        targetDate: studentGoalOptions.targetDate,
      });
    } else {
      setStep(0);
      setSelectedBehTally(undefined);
      setSelectedAntecedent(undefined);
      setSelectedConsequence(undefined);
      setDefaultValues(undefined);
    }
  }, [fba, studentGoalOptions]);

  const abcTableBody = useMemo(() => {
    return (
      <AbcTableBody
        fba={fba!}
        selectedBehTally={selectedBehTally!}
        selectedAntecedent={selectedAntecedent}
        selectedConsequence={selectedConsequence}
        setSelectedAntecedent={setSelectedAntecedent}
        setSelectedConsequence={setSelectedConsequence}
        onContinue={handleNextStep}
      />
    );
  }, [
    selectedBehTally,
    fba,
    selectedAntecedent,
    setSelectedAntecedent,
    selectedConsequence,
    setSelectedConsequence,
  ]);

  const [replacementBehavior, setReplacementBehavior] = useState<
    CategoryBehavior | undefined
  >();

  const decisionBody = useMemo(() => {
    return (
      <DecisionBody
        replacementBehavior={replacementBehavior}
        setReplacementBehavior={setReplacementBehavior}
        selectedBehTally={selectedBehTally!}
        selectedAntecedent={selectedAntecedent}
        selectedConsequence={selectedConsequence}
        onContinue={handleNextStep}
      />
    );
  }, [
    selectedBehTally,
    selectedAntecedent,
    selectedConsequence,
    replacementBehavior,
    setReplacementBehavior,
  ]);

  const setGoalBody = useMemo(() => {
    return (
      <SetGoalBody
        fba={fba!}
        student={fba?.student}
        problemBehavior={selectedBehTally}
        selectedAntecedent={selectedAntecedent}
        selectedConsequence={selectedConsequence}
        replacementBehavior={replacementBehavior}
        defaultValues={defaultValues}
      />
    );
  }, [
    fba?.student,
    selectedBehTally,
    selectedAntecedent,
    selectedConsequence,
    replacementBehavior,
    defaultValues,
  ]);

  const body = useMemo(() => {
    switch (step) {
      case 0:
        return listBehaviorsBody;
      case 1:
        return abcTableBody;
      case 2:
        return decisionBody;
      case 3:
        return setGoalBody;
      default:
        return null;
    }
  }, [step, listBehaviorsBody, abcTableBody, decisionBody, setGoalBody]);

  const title = useMemo(() => {
    switch (step) {
      case 3:
        return "Set Goal: Describe Desired Behavior";
      default:
        return "Review ABC Data";
    }
  }, [step]);

  const analyzingDataLabel = useMemo(() => {
    if (step === 3) {
      return null;
    }

    return (
      <h3 className="text-center font-weight-bold mb-3">
        Analyzing Data for {getFullName(fba?.student)}
      </h3>
    );
  }, [step, fba?.student]);
  return (
    <Modal
      enforceFocus={false}
      show={show}
      size="lg"
      onShow={handleModalShow}
      onHide={handleModalHide}
      animation={false}
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header className="purpleModalHeader centerModalHeader">
        <div>
          <button
            className={`btnModalBack ${!step ? "d-none" : ""}`}
            onClick={handlePrevStep}
          >
            <FontAwesomeIcon icon={faChevronLeft} size={"lg"} />
            Back
          </button>
        </div>
        <Modal.Title>{title}</Modal.Title>
        <ModalCloseButton onClose={handleModalHide} />
      </Modal.Header>
      <Modal.Body>
        {analyzingDataLabel}
        {body}
      </Modal.Body>
    </Modal>
  );
};

export default AnalyzeFbaResultsModal;
