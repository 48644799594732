import React, { useEffect, useRef, useState } from "react";
import LoadingIndicator from "../../../../LoadingIndicator";
import { CustomCheckbox } from "../CustomCheckbox";
import { UserInfo } from "../../../../../../../store/auth/types";
import {
  Student,
  StudentInfo,
} from "../../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { getStudents } from "../../../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import { getFullName } from "../../../../../../../utils/NamesUtils";
import { toastr } from "react-redux-toastr";
import { Overlay, OverlayTrigger, Popover } from "react-bootstrap";
import AddStudentTooltip from "./AddStudentTooltip";

type StateProps = {
  studentsRoster: Array<StudentInfo>;
  errors: {
    getStudents?: string;
  };
  isLoading: {
    getStudents: boolean;
  };
};

type DispatchProps = {
  getStudents: () => any;
};

type OwnProps = {
  selectedTeacher: UserInfo;
  selectedStudents: Array<Student>;
  onSelectedStudentsConfirm: (students: Array<Student>) => () => void;
  isNewStudentAdd?: boolean;
};

type Props = OwnProps & StateProps & DispatchProps;

const SelectStudentsModalBody = ({
  selectedTeacher,
  selectedStudents: students,
  studentsRoster,
  onSelectedStudentsConfirm,

  getStudents,
  isLoading: { getStudents: isLoading },
  errors: { getStudents: error },
  isNewStudentAdd
}: Props) => {
  const [selectedStudents, setSelectedStudents] = useState(students);
  const [studentsFilter, setStudentsFilter] = useState("");
  const [filteredStudentsRoster, setFilteredStudentsRoster] = useState<
    Array<StudentInfo>
  >([]);

  useEffect(() => {
    getStudents();
  }, [getStudents]);

  useEffect(() => {
    if (error) {
      toastr.error("Error", error);
    } else {
      setFilteredStudentsRoster(
        studentsRoster.filter(filterTeacherStudents).filter(filterStudents())
      );
    }
  }, [studentsRoster]);

  const filterTeacherStudents = (student: StudentInfo) =>
    student.teachers.some((t) => t.id === selectedTeacher!.id);

  const filterStudents = (value: string = studentsFilter || "") => (
    student: StudentInfo
  ) => {
    return getFullName(student)
      .toLowerCase()
      .includes(value.toLowerCase());
  };

  const onStudentsFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setStudentsFilter(value);
    setFilteredStudentsRoster(
      studentsRoster.filter(filterTeacherStudents).filter(filterStudents(value))
    );
  };

  const onSelectedStudentsChange = (student: StudentInfo) => () => {
    setSelectedStudents((selectedStudents) => {
      if (!selectedStudents.some((s) => s.id === student.id)) {
        return [...selectedStudents, student];
      } else {
        return selectedStudents.filter((s) => s.id !== student.id);
      }
    });
  };

  const targetRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <>
      <h3 className="font-weight-bold">
        Select the student(s) that will be discussed in the interview.
      </h3>
      <p>
        If you know which of their students you are going to talk about, let us
        know now so we can customize the printable materials.
      </p>

      <div>
        <div className="mb-3 d-flex justify-content-between align-items-end">
          <h4 className="font-italic">
            {selectedStudents.length} students selected
          </h4>
          <div className="d-flex">
            <button
              className="blueBtnSm mr-3"
              ref={targetRef}
              onClick={() => setShowTooltip(true)}
            >
              Add student
            </button>

            <Overlay
              target={targetRef.current}
              show={showTooltip}
              rootClose
              onHide={() => setShowTooltip(false)}
              placement="left"
            >
              <Popover id="add-student-tooltip">
                <Popover.Content>
                  <AddStudentTooltip
                    selectedTeacher={selectedTeacher}
                    onClose={() => setShowTooltip(false)}
                    isNewStudentAdd={isNewStudentAdd}
                  />
                </Popover.Content>
              </Popover>
            </Overlay>

            <input
              type="text"
              placeholder={`Search ${selectedTeacher.first_name}'s students...`}
              name="studentsFilter"
              value={studentsFilter || ""}
              onChange={onStudentsFilterChange}
              className="stdInput"
            />
          </div>
        </div>
        <table className="scrollableTable">
          <thead>
            <tr>
              <th />
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={2}>
                  <LoadingIndicator />
                </td>
              </tr>
            ) : filteredStudentsRoster.length ? (
              filteredStudentsRoster.map((student) => (
                <tr
                  key={student.id}
                  onClick={onSelectedStudentsChange(student)}
                >
                  <td className="d-flex">
                    <CustomCheckbox
                      checked={selectedStudents.some(
                        (s) => s.id === student.id
                      )}
                    />
                  </td>
                  <td>
                    {student.first_name} {student.last_name}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2}>No matching students</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <hr />
      <div className="modalActions">
        <div />
        <button
          className="blueBtnSm"
          disabled={!selectedStudents.length}
          onClick={onSelectedStudentsConfirm(selectedStudents)}
        >
          Continue
        </button>
      </div>
    </>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    errors: {
      getStudents: onboarding.errors.getStudents,
    },
    isLoading: {
      getStudents: onboarding.isLoading.getStudents,
    },
    studentsRoster: onboarding.studentsRoster,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getStudents: getStudents,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectStudentsModalBody);
