import React, { FunctionComponent, useEffect, useMemo } from "react";
import { IntlProvider } from "react-intl";

import en from "./translations/en.json";
import es from "./translations/es.json";
import { LOCALE } from "../../../store/help/types";
import { ApplicationState } from "../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  changeLocale,
  openSelectLanguageModal,
} from "../../../store/help/actions";
import SelectLanguageModal from "./SelectLanguageModal";

type StateProps = {
  locale?: LOCALE;
};

type DispatchProps = {
  changeLocale: (locale: LOCALE) => any;
  openSelectLanguageModal: () => any;
};

type Props = StateProps & DispatchProps;

const Localization: FunctionComponent<Props> = (props) => {
  const messages: any = useMemo(() => ({ en, es }), []);

  useEffect(() => {
    if (!props.locale) {
      props.openSelectLanguageModal();
    }
  }, [props.locale]);

  const locale = props.locale || LOCALE.ENGLISH;

  return (
    <>
      <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
        {props.children}
        {/* <SelectLanguageModal />  RC-1000*/}
      </IntlProvider>
    </>
  );
};

const mapStateToProps = ({ help }: ApplicationState): StateProps => {
  return {
    locale: help.locale,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      changeLocale: changeLocale,
      openSelectLanguageModal: openSelectLanguageModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Localization);
