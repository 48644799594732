import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import DistrictsTable from "./districts-table/DistrictsTable";
import { Tab } from "react-bootstrap";
import {
  getDistrict,
  selectDistrict,
} from "../../../../../store/superuser/actions";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
import CreateDistrictModal from "./dialogs/CreateDistrictModal";
import DistrictDetailsEdit from "./district-details/DistrictDetailsEdit";
import DistrictDetailsRead from "./district-details/DistrictDetailsRead";
import { ApplicationState } from "../../../../../store";
import LoadingIndicator from "../../../../common/onboarding/LoadingIndicator";
import { useLoading } from "../../../../../utils/hooks/useLoading";
import { EventKey } from "react-bootstrap/esm/types";

enum TabContent {
  TABLE = "table",
  DETAILS_READ = "details_read",
  DETAILS_EDIT = "details_edit",
}

type MatchParams = {
  districtId?: string;
  isEdit?: string;
};

type Props = RouteComponentProps<MatchParams>;

const DistrictsTab: FunctionComponent<Props> = ({
  match: {
    params: { districtId, isEdit },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (districtId) {
      dispatch(getDistrict(+districtId));
    } else {
      dispatch(selectDistrict(null));
    }
  }, [districtId]);

  const loadingGetDistrict = useSelector<ApplicationState, boolean>(
    (s) => s.superuser.isLoading.getDistrict
  );
  const errorGetDistrict = useSelector<ApplicationState, string | undefined>(
    (s) => s.superuser.errors.getDistrict
  );
  useLoading(loadingGetDistrict, errorGetDistrict, undefined, () =>
    history.push("/superuser/districts")
  );

  const activeKey = useMemo(() => {
    return districtId != null
      ? isEdit
        ? TabContent.DETAILS_EDIT
        : TabContent.DETAILS_READ
      : TabContent.TABLE;
  }, [districtId, isEdit]);

  return (
    <Tab.Container
      defaultActiveKey={TabContent.TABLE}
      activeKey={activeKey as EventKey}
      unmountOnExit
    >
      <Tab.Content>
        <Tab.Pane eventKey={TabContent.TABLE}>
          <DistrictsTable />
        </Tab.Pane>
        <Tab.Pane eventKey={TabContent.DETAILS_READ}>
          <DistrictDetailsRead />
        </Tab.Pane>
        <Tab.Pane eventKey={TabContent.DETAILS_EDIT}>
          <DistrictDetailsEdit />
        </Tab.Pane>
      </Tab.Content>
      <CreateDistrictModal />
    </Tab.Container>
  );
};

export default withRouter(DistrictsTab);
