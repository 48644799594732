import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import RcProgressIntro from "../subpages/rc-progress/RcProgressIntro";
import RcProgressSetGoal from "../subpages/rc-progress/RcProgressSetGoal";
import {
  createStudentGoalForInterventionGroup,
  hideSetGoalChooseConcernModal,
} from "../../../../../../store/onboarding/cases/actions";
import {
  ConcernArea,
  InterventionGroup,
  StudentGoal,
  StudentGoalForGroup,
} from "../../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { dateToFormatString } from "../../../../../utils/DateTimeUtils";
import { getDefaultProgressMonitoring } from "../../../../../../store/onboarding/actions";
import { toastr } from "react-redux-toastr";
import {
  KnownAssessment,
  Measurement,
} from "../../../../../../store/onboarding/types";
import moment from "moment";

type StateProps = {
  interventionGroup: InterventionGroup;
  studentGoal?: StudentGoal;
  defaultProgressMonitoring?: Measurement;
};

type DispatchProps = {
  hideSetGoalChooseConcernModal: () => any;
  getDefaultProgressMonitoring: () => any;
  createStudentGoalForInterventionGroup: (
    interventionGroupId: number,
    goal: StudentGoalForGroup
  ) => any;
};

type OwnProps = {};

type Props = OwnProps & StateProps & DispatchProps;

enum RcProgressTabSteps {
  INTRO,
  SET_GOAL,
}

const RcProgressTab: FunctionComponent<Props> = ({
  studentGoal,
  defaultProgressMonitoring,
  interventionGroup,
  createStudentGoalForInterventionGroup,
  hideSetGoalChooseConcernModal,
  getDefaultProgressMonitoring,
}) => {
  const [targetDate, setTargetDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    handleDefaultProgressMonitoringGet();
  }, []);

  const handleDefaultProgressMonitoringGet = () => {
    getDefaultProgressMonitoring().then(null, (err: string) =>
      toastr.error("Error", err)
    );
  };

  useEffect(() => {
    if (
      studentGoal?.id &&
      studentGoal.measurement.known_assessment ===
        KnownAssessment.ReadyCoach_Indicator
    ) {
      setStep(RcProgressTabSteps.SET_GOAL);
      setTargetDate(moment(studentGoal.target_date, "YYYY-MM-DD").toDate());
    }
  }, [studentGoal]);

  const [step, setStep] = useState(0);
  const content = useMemo(() => {
    switch (step) {
      case RcProgressTabSteps.INTRO:
        return (
          <RcProgressIntro
            onClickContinue={() => setStep(RcProgressTabSteps.SET_GOAL)}
          />
        );
      case RcProgressTabSteps.SET_GOAL: {
        const setGoal = () => {
          if (!defaultProgressMonitoring) {
            handleDefaultProgressMonitoringGet();
            return;
          }
          const goal: StudentGoalForGroup = {
            concern_area: ConcernArea.BEHAVIOR,
            measurement_id: defaultProgressMonitoring.id!,
            target_date: dateToFormatString(targetDate!),
          };
          createStudentGoalForInterventionGroup(
            interventionGroup.id!,
            goal
          ).then(
            () => hideSetGoalChooseConcernModal(),
            (err: any) => toastr.error("Error", err)
          );
        };
        return (
          <RcProgressSetGoal
            studentGoal={studentGoal}
            targetDate={targetDate}
            handleGoalSet={setGoal}
            setTargetDate={setTargetDate}
            onBackClick={() => setStep(RcProgressTabSteps.INTRO)}
          />
        );
      }
    }
  }, [step, targetDate]);

  return <>{content}</>;
};

const mapStateToProps = ({
  cases,
  onboarding,
}: ApplicationState): StateProps => {
  return {
    interventionGroup: cases.selectedInterventionGroup!,
    studentGoal: cases.selectedStudentGoal,
    defaultProgressMonitoring: onboarding.defaultProgressMonitoring,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      createStudentGoalForInterventionGroup: createStudentGoalForInterventionGroup,
      hideSetGoalChooseConcernModal: hideSetGoalChooseConcernModal,
      getDefaultProgressMonitoring: getDefaultProgressMonitoring,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RcProgressTab);
