import React, { FunctionComponent, useMemo } from "react";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { IS_READY_COACH } from "../../../../../../constants";
import {
  UserAssignmentRole,
  UserInfo,
} from "../../../../../../store/auth/types";
import { ApplicationState } from "../../../../../../store";
import { connect } from "react-redux";
import { getFullName } from "../../../../../../utils/NamesUtils";
import UserAvatarSmall from "../../../../../common/UserAvatarSmall";

type StateProps = {
  userInfo?: UserInfo;
};

type OwnProps = {
  withPhoto?: boolean;
  withInterventionName?: boolean;
  interventionGroup?: InterventionGroup;
};

type Props = OwnProps & StateProps;

const InterventionGroupTeacher: FunctionComponent<Props> = ({
  withPhoto,
  withInterventionName,
  interventionGroup,
  userInfo,
}) => {
  const isCoach = useMemo(
    () =>
      IS_READY_COACH &&
      userInfo?.profile.current_assignment?.role === UserAssignmentRole.COACH,
    [userInfo]
  );

  const employee = useMemo(() => {
    return isCoach
      ? interventionGroup?.teacher_assignment?.user
      : interventionGroup?.coach_assignment?.user;
  }, [isCoach, interventionGroup]);

  return (
    <>
      {withPhoto && <UserAvatarSmall userInfo={employee} size={35} />}
      {getFullName(employee) +
        (withInterventionName ? ` : ${interventionGroup?.name}` : "")}
    </>
  );
};

const mapStateToProps = ({ auth }: ApplicationState): StateProps => {
  return {
    userInfo: auth.userInfo,
  };
};

export default connect(mapStateToProps)(InterventionGroupTeacher);
