import React, { FunctionComponent, useMemo } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { ApplicationState } from "../../../store";
import LoadingIndicator from "../../common/onboarding/LoadingIndicator";
import useUserRole from "../../../utils/hooks/useUserRole";
import { Release_Notes, SuperuserState } from "../../../store/superuser/types";


const ReleaseNotesTable: FunctionComponent = () => {
  const dispatch = useDispatch();

  const { isDistrictOwner } = useUserRole();

  const { releaseNotes, isLoading } = useSelector<ApplicationState, SuperuserState>(
    (s) => s.superuser
  );

  const numColumns = 3;

  const publishedReleseNotes = useMemo(() => 
    releaseNotes.filter((release) => release.is_published).sort((a, b) => a.publish_date! > b.publish_date! ? -1: 1)
  ,[releaseNotes])
    
  const columns = useMemo(() => {
    const colSize = Math.ceil(publishedReleseNotes.length / 3);
    return [
      publishedReleseNotes.slice(0, colSize),
      publishedReleseNotes.slice(colSize, colSize * 2),
      publishedReleseNotes.slice(colSize * 2)
  ];
  }, [publishedReleseNotes]);

  const handleReleaseNoteDetail = (event:React.MouseEvent,noteTag: string) => {
    event.stopPropagation();
    if (noteTag) {
      dispatch(push(`${isDistrictOwner ? '/district' : ''}/release-notes/${noteTag}`));
    }
  };

  return (
    <div className="onboardingContainer" style={{ padding: 20 }}>
      <h1>Release Notes</h1>
      <h4 className="py-3">ReadyCoach product release notes include descriptions about new and enhanced features, 
        changes to existing features, and resolved issues. Browse by date to find 
        out what's new in the latest version of ReadyCoach.</h4>
      <hr/>
      {publishedReleseNotes.length 
      ?
      <>
        <h3 className="py-3">ReadyCoach Releases by Date</h3>
        {isLoading.getAllReleaseNotes ? (
          <LoadingIndicator />
        ) : 
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {columns.map((col, colIndex) => (
                <ul className="list-unstyled" key={colIndex} style={{ flex: 1, marginRight: colIndex < numColumns - 1 ? '20px' : '0' }}>
                    {col.map((item, rowIndex) => (
                        <li key={item.id} style={{ marginBottom: '10px' }} className="font-weight-bold">
                            <a href="javascript:void(0)" onClick={(e: React.MouseEvent) => handleReleaseNoteDetail(e, ''+item.id)}>{item.title}</a>
                        </li>
                    ))}
                </ul>
            ))}
        </div>
        }
      </>
      :
        <p className="font-weight-bold text-center">No Release Note(s) Available Yet.</p>
      }
    </div>
  );
};

export default ReleaseNotesTable;
