import React, { FunctionComponent } from "react";
import { ChecklistItem, CommonSteps } from "../../../../../../../../store/onboarding/cases/types";
import AddResourcesForm from "./AddResourcesForm";
import Select, { ValueType } from "react-select";
import { ReactSelectOption } from "../../../../../../../../store/onboarding/types";

type OwnProps = {
  interventionName: string;
  setCheckListItems: React.Dispatch<React.SetStateAction<Array<ChecklistItem>>>;
  setSelectedCheckListItem: React.Dispatch<
    React.SetStateAction<
      (Partial<ChecklistItem> & { index?: number }) | undefined
    >
  >;
  selectedCheckListItem:
    | (Partial<ChecklistItem> & { index?: number })
    | undefined;
  onOpenHideStepForm: () => void;
};

type Props = OwnProps;

const InterventionStepDetails: FunctionComponent<Props> = ({
  interventionName,
  selectedCheckListItem,
  setCheckListItems,
  setSelectedCheckListItem,
  onOpenHideStepForm,
}) => {
  const options = CommonSteps.map((data) => {
    return { 'label': data.label, 'value': data.value }
  });
  const handleSelectedCheckListItemChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target as HTMLInputElement;
    setSelectedCheckListItem((item) => ({ ...item, [name]: value }));
  };

  const handleCommonStepChange = (
    event: ValueType<ReactSelectOption<string>, false>
  ) => {
    setSelectedCheckListItem((item) => ({ ...item, 'common_step_name': event?.value }));
  };

  const handleCheckListItemSave = () => {
    setCheckListItems((items) => {
      const index = items.findIndex(
        (el) => el.order === selectedCheckListItem?.order
      );
      return index === -1
        ? [...items, selectedCheckListItem]
        : items.map((ci, i) =>
            i === index ? (selectedCheckListItem as any) : ci
          );
    });
    onOpenHideStepForm();
  };

  return (
    <div className="interventionStepDetailContainer">
      <h2>
        Implementation Step{" "}
        <strong>
          #{selectedCheckListItem && selectedCheckListItem.index! + 1}
        </strong>{" "}
        for <strong>{interventionName}</strong>.
      </h2>
      <hr />
      <h3 className="font-weight-bold">Common Research-Based Instructional Components</h3>
      <Select
        placeholder="Select Step"
        options={options}
        onChange={(event) => handleCommonStepChange(event)}
        value={options.find((option) => option.value == selectedCheckListItem?.common_step_name)}
      />
      <br />
      <br />
      <h3 className="font-weight-bold">Step Title</h3>
      <p>
        This should be a short description of the step, without the details.
      </p>
      <input
        name="title"
        className="fullWidthInput"
        placeholder="Title..."
        value={(selectedCheckListItem && selectedCheckListItem.title) || ""}
        onChange={handleSelectedCheckListItemChange}
      />
      <br />
      <br />
      <h3 className="font-weight-bold">Step Details</h3>
      <p>
        Here you can get into the nitty-gritty of how to implement this step.
      </p>
      <textarea
        name="details"
        className="fullWidthTextarea"
        placeholder="Step details..."
        value={(selectedCheckListItem && selectedCheckListItem.details) || ""}
        onChange={handleSelectedCheckListItemChange}
      />
      <br />
      <br />

      <AddResourcesForm
        attachments={selectedCheckListItem?.attachments}
        onAttachmentsAdd={(resources) =>
          setSelectedCheckListItem((ci) => ({
            ...ci!,
            attachments: [...(ci?.attachments || []), ...resources],
          }))
        }
      />

      <div className="modalActions">
        <button className="whiteBtnMd2" onClick={() => onOpenHideStepForm()}>
          CANCEL
        </button>
        <button 
          className="blueBtnMd" 
          disabled={
            selectedCheckListItem?.title?.trim() == ''
            || selectedCheckListItem?.common_step_name?.trim() == undefined
          } 
          onClick={handleCheckListItemSave}>
          SAVE CHANGES
        </button>
      </div>
    </div>
  );
};

export default InterventionStepDetails;
