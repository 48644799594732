import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import Table from "react-bootstrap/Table";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { getGoalTitle } from "../../../../../utils";
import LoggedProgressRow from "./LoggedProgressRow";
import { Student } from "../../../../../../store/onboarding/types";
import { StudentGoalProgress } from "../../../../../../store/onboarding/meeting-module/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "react-bootstrap";
import { faCaretDown, faCaretRight, faChartLine, faCheckCircle, faList } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { hideInterventionPlanModal, sendLogProgressDataToGraph, showInterventionPlanModal } from "../../../../../../store/onboarding/cases/actions";
import { openGoalProgressModal } from "../../../../../../store/onboarding/meeting-module/actions";
import useInterventionGroupAccess from "../../../../../../utils/hooks/useInterventionGroupAccess";

type OwnProps = {
  interventionGroup: InterventionGroup;
  hideActions?: boolean;
  studentsFilter?: any[];
  isStudentDetail?: boolean;
  isGroupDetail?: boolean;
  selectedMeasurement?: any;
  showExitStudents?: boolean;
  fromInterventionFidelityChart?: boolean;
};

type Props = OwnProps;

const LoggedProgressTable: FunctionComponent<Props> = (props) => {
  const { 
    interventionGroup, 
    hideActions, 
    studentsFilter, 
    selectedMeasurement, 
    isStudentDetail, 
    isGroupDetail,
    showExitStudents,
    fromInterventionFidelityChart
  } = props;
  const dispatch = useDispatch();
  const [openId, setOpenId] = useState<number | null>(null);
  const elementRef = useRef<(HTMLTableCellElement | null)[]>([]);

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(interventionGroup.id);

  const toggleHandler = (id: number) => () => {
    setOpenId(openId => openId === id ? null : id);
  };


  const handleLogGoalProgress = useCallback(() => {
    dispatch(hideInterventionPlanModal());
    dispatch(
      openGoalProgressModal({
        onClose: () => dispatch(showInterventionPlanModal()),
      })
    );
  }, []);


  const logGoalProgressBtn = useMemo(() => {
    return (
      <button className="blueBtnSm " onClick={handleLogGoalProgress}>
        <FontAwesomeIcon
          icon={faChartLine}
          size={"lg"}
          style={{ color: "white", marginRight: "0.5rem" }}
        />{" "}
        log goal progress
      </button>
    );
  }, [handleLogGoalProgress]);

  let allActiveGoalsArray = useMemo(() => {
    let allGoals = new Set();
    let allActiveGoals = new Set();

    
    interventionGroup?.student_goals
    .filter((sg) => fromInterventionFidelityChart ? !sg.is_general_outcome : true)
    .map((goal, index) => {
      allGoals.add(goal.id);
      if (!goal.archived) {
        allActiveGoals.add(goal.id);
      }
    });
    return  Array.from(allActiveGoals);
  },[]);

  let allArchivedGoalsArray = useMemo(() => {
    let allGoals = new Set();
    let allArchivedGoals = new Set();
  
    interventionGroup?.student_goals
    .filter((sg) => fromInterventionFidelityChart ? !sg.is_general_outcome : true)
    .map((goal, index) => {
      allGoals.add(goal.id);
      if (goal.archived) {
        allArchivedGoals.add(goal.id);
      }
    });
    return  Array.from(allArchivedGoals);
  },[]);

  const filtered = useMemo(() => {
    let filterData: StudentGoalProgress[] = [];
    let modifiedData:any[] = [];
    if(fromInterventionFidelityChart) {
      let progressOverviewGoalProgress: StudentGoalProgress[] = 
      interventionGroup.progress_overview?.student_goal_progress
      .filter((element) =>!element.is_general_outcome && allActiveGoalsArray.includes(element.student_goal_id)
                ) || [];
      if(showExitStudents) {
          const archivedResult = interventionGroup.progress_overview?.student_goal_progress
          .filter((element) => !element.is_general_outcome && allArchivedGoalsArray.includes(element.student_goal_id)
        ) || [];
        progressOverviewGoalProgress = progressOverviewGoalProgress.concat(archivedResult)
      }

      if (selectedMeasurement?.value) {
        filterData =  (
          progressOverviewGoalProgress ?? []
        ).filter((sgp) => 
            sgp.measurement.id == +selectedMeasurement?.value
        ).sort((a, b) => a.is_primary_goal ? -1 : 1);
      }
      if (studentsFilter?.length) {
        filterData =  (
          progressOverviewGoalProgress ?? []
        ).filter((sgp) => studentsFilter?.some((sf) => +sf.value === sgp.student.id)
        ).sort((a, b) => a.is_primary_goal ? -1 : 1);
      } 
     if (studentsFilter?.length && selectedMeasurement?.value) {
        filterData =  (
          progressOverviewGoalProgress ?? []
        ).filter((sgp) => 
            ((sgp.measurement.id == +selectedMeasurement?.value) 
                && studentsFilter?.some((sf) => +sf.value === sgp.student.id)
                )
        ).sort((a, b) => a.is_primary_goal ? -1 : 1);
      } 
      if(!studentsFilter?.length && !selectedMeasurement?.value) {
        filterData = progressOverviewGoalProgress.sort((a, b) => a.is_primary_goal ? -1 : 1) ?? [];
      }
    } else {
      filterData = interventionGroup.progress_overview?.student_goal_progress.sort((a, b) => a.is_primary_goal ? -1 : 1) ?? [];
    }

    for (let index = 0; index < filterData.length; index++) {
      const element = filterData[index];
      if(filterData.some((fd) => fd.student.id == element.student.id)){
        modifiedData = {
          ...modifiedData,
          [element.student.id as number]: {
            ...modifiedData[element.student.id as number],
            [`${element.student_goal_id}_${element.is_primary_goal}`]: {
              ...element
            }
          }
        }
      }
    }
    dispatch(sendLogProgressDataToGraph(filterData));
    return modifiedData;
  }, [
    interventionGroup.progress_overview?.student_goal_progress,
    studentsFilter,
    selectedMeasurement,
    showExitStudents,
  ]);

  const getModalBody = () => {
    if(Object.keys(filtered).length) {
      let studentWithGoalProgressArray: any[] = [];
      let index:number = 0;
      for (const studentId in filtered) {
        if (Object.prototype.hasOwnProperty.call(filtered, studentId)) {
          let studentWithGoalProgress: any;
          const sgp = filtered[studentId];
          let student: any;
          let loggedProgressCount:number = 0
          let loggedProgressRow: any[] = [];
          for (const innerKey in sgp) {
            const sgpObj: StudentGoalProgress = sgp[innerKey];
            let loggedProgressData;
            if (Object.prototype.hasOwnProperty.call(sgp, innerKey)) {
              let studentGoals = interventionGroup.student_goals?.filter(
                (sg) => sg.id == sgpObj.student_goal_id
                );
              const latestStudentGoal = studentGoals.sort(
                (a, b) => Date.parse(b.created) - Date.parse(a.created)
                );
              student = sgpObj.student;
              loggedProgressData =  
                  (<tr className="tr-border">
                    <td className="" style={{minHeight: sgpObj.logged_progress.length > 1 ? '96px' : '71px', minWidth: '41px', borderRight:'1px solid #ccc'}}>
                    { sgpObj.is_primary_goal ? 
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    size="1x"
                                    className="blueText"
                                    title="Primary Goal"
                                  />
                                : ''
                              }
                    </td>
                    <td className="py-1 td-border-t" style={{minHeight: sgpObj.logged_progress.length > 1 ? '96px' : '71px'}}>
                      <div
                        className="d-flex align-items-center"
                        aria-controls={`common-collapse-${sgpObj.student_goal_id}`}
                        aria-expanded={openId === sgpObj.student_goal_id} 
                      >
                        <div className="mr-3" role="button" onClick={toggleHandler(sgpObj.student_goal_id)}>
                          <FontAwesomeIcon
                            size={"1x"}
                            icon={
                              openId === sgpObj.student_goal_id
                                ? faCaretDown
                                : openId !== sgpObj.student_goal_id ? faCaretRight : faCaretDown
                            }
                          />
                        </div>
                      {latestStudentGoal && getGoalTitle(latestStudentGoal[0])}
                      </div>
                    </td>
                    <td colSpan={3} role="button" style={{width:"25%"}} className="p-0 td-border-t">
                      <Collapse in={openId === sgpObj.student_goal_id}>
                        <div id={`common-collapse-${sgpObj.student_goal_id}`} style={{height:"90px"}}>
                          {sgpObj.logged_progress.map((lp:any, innerIndex:number) => (
                            <LoggedProgressRow
                              key={`lp_${lp.id}`}
                              lp={lp}
                              sgp={sgpObj}
                              interventionGroup={interventionGroup}
                              hideActions={hideActions}
                              isStudentDetail={isStudentDetail}
                              isGroupDetail={isGroupDetail}
                              innerIndex={innerIndex}
                              fromInterventionFidelityChart={fromInterventionFidelityChart}
                            />
                          ))}
                        </div>
                      </Collapse>             
                    </td>
                  </tr>)
              loggedProgressCount++;
              loggedProgressRow.push(loggedProgressData)
            }
          }
         // console.log(activeKey)
          index++;
          // if(loggedProgressCount > 1) {
          //   elementRef.current[index]?.setAttribute('rowSpan', `${loggedProgressCount - 1}`)
          // }
          studentWithGoalProgress = 
              <tr key={index}>
                <td ref={ref => {
                      elementRef.current[index] = ref
                    }} 
                    style={{width:"20%"}}
                >
                  {getFullName(student)}
                </td>
                <td colSpan={3} className="p-0" style={{width:"55%"}}>
                  {loggedProgressRow}
                </td>
                {!loggedProgressCount && <td colSpan={3} />}
              </tr>;
         
          studentWithGoalProgressArray.push(studentWithGoalProgress);
        }
      }
      return studentWithGoalProgressArray;

    } else {
      return (
        <>
            <tr>
              <td colSpan={5} className="text-center">No Goal Progress Logged.</td>
            </tr>
            {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess ?
            <tr style={{ backgroundColor:'#fff'}}>
              <td colSpan={6} className="text-center border-top-0 pt-1">{logGoalProgressBtn}</td> 
            </tr> : ''}
        </>
      )
    }
  }

  const getModalTable = () => {
    if(fromInterventionFidelityChart) {

      if(Object.keys(filtered).length) {
        return (
          <Table className="goalTable mb-0 secondryGoalTable" responsive>
            <thead>
              <tr>
                <th>Student</th>
                <th className="text-left"></th>
                <th className="text-left">Goal</th>
                <th className="text-center" colSpan={3}>Progress</th>
              </tr>
            </thead>
            <tbody>
              {getModalBody()}
            </tbody>
          </Table>
        )
      } else {
        return null;
      }
    } else {
      return (
        <Table className="goalTable mb-0 secondryGoalTable" responsive>
          <thead>
            <tr>
              <th>Student</th>
              <th className="text-left"></th>
              <th className="text-left">Goal</th>
              <th className="text-center" colSpan={3}>Progress</th>
            </tr>
          </thead>
          <tbody>
            {getModalBody()}
          </tbody>
        </Table>
      )
    }
  }
  return (getModalTable());
};

export default LoggedProgressTable;
