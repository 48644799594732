import React, { useRef, useState } from "react";
import ObservationsPopover from "./observations/ObservationsPopover";
import { BehaviorsMeasureType, GoalsData } from "../NewSetGoalsModal";
import {
  ObservationTypes,
  ObservationTypesDisplayedNames,
  StaticMeasurementTypes,
  StaticMeasurementTypesDisplayedNames,
} from "../../../../../store/onboarding/cases/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BRSPopover from "./behavior-rating-scale/BRSPopover";
import RCIndicatorsPopover from "./rc-indicators/RCIndicatorsPopover";
import { Measurement } from "../../../../../store/onboarding/types";
import GoalInputGroup from "../../../../pages/coach-dashboard/tabs/your-assigments/goal-progress/GoalInputGroup";
import { FormControl, InputGroup } from "react-bootstrap";

type Props = {
  goalsData: GoalsData;
  onChangeGoalsData: (changedData: GoalsData) => void;
};

const MeasureInfoCell = ({ goalsData, onChangeGoalsData }: Props) => {
  const [showRcIndicators, setShowRcIndicators] = useState<boolean>(false);
  const [showObservation, setShowObservation] = useState<boolean>(false);
  const [showBrs, setShowBrs] = useState<boolean>(false);

  const rcIndicatorsBtnRef = useRef(null);
  const observationBtnRef = useRef(null);
  const brsBtnRef = useRef(null);

  const onSubmitRcIndicatorsPopover = () => {
    onChangeGoalsData({
      ...goalsData,
      behaviorsMeasureType: BehaviorsMeasureType.RC_INDICATORS,
      targetValue: null,
      measurementType: null,
      observationType: null,
      brsMeasurement: null,
    });
    setShowRcIndicators(false);
  };

  const onSubmitObservationPopover = (
    measurementType: StaticMeasurementTypes,
    observationType: ObservationTypes
  ) => {
    onChangeGoalsData({
      ...goalsData,
      behaviorsMeasureType: BehaviorsMeasureType.OBSERVATION,
      measurementType,
      observationType,
      targetValue: null,
      brsMeasurement: null,
    });
    setShowObservation(false);
  };

  const onSubmitBrsPopover = (selectedBrsMeasurement: Measurement) => {
    onChangeGoalsData({
      ...goalsData,
      behaviorsMeasureType: BehaviorsMeasureType.BEHAVIOR_RATING_SCALES,
      brsMeasurement: selectedBrsMeasurement,
      targetValue: null,
      measurementType: null,
      observationType: null,
    });
    setShowBrs(false);
  };

  const handleClickRCIndicators = () => {
    setShowRcIndicators(true);
  };

  const handleClickObservations = () => {
    setShowObservation(true);
  };

  const handleClickBRS = () => {
    setShowBrs(true);
  };

  const handleRemove = () => {
    onChangeGoalsData({
      ...goalsData,
      behaviorsMeasureType: null,
      targetValue: null,
      measurementType: null,
      observationType: null,
      brsMeasurement: null,
    });
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    if (isFinite(Number(value))) {
      onChangeGoalsData({
        ...goalsData,
        targetValue: value,
      });
    }
  };

  const handleChangeBaseline = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    if (isFinite(Number(value))) {
      onChangeGoalsData({
        ...goalsData,
        currentBaseline: value,
      });
    }
  };

  const {
    observationType,
    measurementType,
    brsMeasurement,
    targetValue,
    currentBaseline,
    isNew,
  } = goalsData;

  switch (goalsData.behaviorsMeasureType) {
    case BehaviorsMeasureType.RC_INDICATORS:
      return (
        <div className="d-flex justify-content-between">
          <strong>RC INDICATORS</strong>
          <FontAwesomeIcon
            onClick={handleRemove}
            icon="times-circle"
            style={{
              color: "#222222",
              cursor: "pointer",
            }}
            size="lg"
          />
        </div>
      );
    case BehaviorsMeasureType.OBSERVATION:
      if (observationType && measurementType) {
        return (
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column mr-3">
              <strong>
                {ObservationTypesDisplayedNames[observationType]} -{" "}
                {StaticMeasurementTypesDisplayedNames[measurementType]}
              </strong>
              <input
                className="stdInput"
                type="text"
                placeholder="E.g. outburts during the class"
              />
            </div>
            {isNew && (
              <div className="d-flex flex-column mr-3">
                <strong>Current Baseline</strong>
                <InputGroup>
                  <FormControl
                    type="number"
                    value={currentBaseline ?? ""}
                    name="value"
                    onChange={handleChangeBaseline}
                    aria-describedby="measurement-type"
                    style={{ fontSize: "1rem" }}
                  />
                </InputGroup>
              </div>
            )}
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column mr-3">
                <strong>
                  Target {StaticMeasurementTypesDisplayedNames[measurementType]}
                </strong>
                <InputGroup>
                  <FormControl
                    required
                    type="number"
                    value={targetValue ?? ""}
                    name="value"
                    onChange={handleChangeValue}
                    aria-describedby="measurement-type"
                    style={{ fontSize: "1rem" }}
                  />
                </InputGroup>
              </div>
              <FontAwesomeIcon
                onClick={handleRemove}
                icon="times-circle"
                style={{
                  color: "#222222",
                  cursor: "pointer",
                }}
                size="lg"
              />
            </div>
          </div>
        );
      }
      break;
    case BehaviorsMeasureType.BEHAVIOR_RATING_SCALES:
      if (brsMeasurement) {
        return (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <div>
                <strong>Behavior Rating Scales</strong>
              </div>
              <div>
                <strong>Selected Assessment:</strong>{" "}
                {brsMeasurement.display_name}
              </div>
            </div>
            {isNew && (
              <div className="d-flex flex-column mr-3">
                <div>
                  <strong>Current Baseline</strong>{" "}
                  {(brsMeasurement.range_min != null ||
                    brsMeasurement.range_max != null) && (
                    <span>
                      (
                      {brsMeasurement.range_min != null &&
                        brsMeasurement.range_min + " ≤ "}
                      value
                      {brsMeasurement.range_max != null &&
                        " ≤ " + brsMeasurement.range_max}
                      )
                    </span>
                  )}
                </div>
                <GoalInputGroup
                  required
                  measurement={brsMeasurement}
                  value={currentBaseline ?? ""}
                  onChange={handleChangeBaseline}
                />
              </div>
            )}
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column mr-3">
                <div>
                  <strong>Target value</strong>{" "}
                  {(brsMeasurement.range_min != null ||
                    brsMeasurement.range_max != null) && (
                    <span>
                      (
                      {brsMeasurement.range_min != null &&
                        brsMeasurement.range_min + " ≤ "}
                      value
                      {brsMeasurement.range_max != null &&
                        " ≤ " + brsMeasurement.range_max}
                      )
                    </span>
                  )}
                </div>
                <GoalInputGroup
                  required
                  measurement={brsMeasurement}
                  value={targetValue ?? ""}
                  onChange={handleChangeValue}
                />
              </div>
              <FontAwesomeIcon
                onClick={handleRemove}
                icon="times-circle"
                style={{
                  color: "#222222",
                  cursor: "pointer",
                }}
                size="lg"
              />
            </div>
          </div>
        );
      }
  }
  return (
    <div className="d-flex justify-content-around">
      <button
        className="blueBtnSm mr-2"
        ref={rcIndicatorsBtnRef}
        type="button"
        onClick={handleClickRCIndicators}
      >
        ReadyCoach Indicators
      </button>
      <RCIndicatorsPopover
        objectRef={rcIndicatorsBtnRef}
        showDropdown={showRcIndicators}
        setShowDropdown={setShowRcIndicators}
        onSubmitPopover={onSubmitRcIndicatorsPopover}
      />
      <button
        className="blueBtnSm mr-2"
        ref={observationBtnRef}
        type="button"
        onClick={handleClickObservations}
      >
        Observations
      </button>
      <ObservationsPopover
        objectRef={observationBtnRef}
        showDropdown={showObservation}
        goalObservation={goalsData.observationType}
        goalMeasurement={goalsData.measurementType}
        setShowDropdown={setShowObservation}
        onSubmitPopover={onSubmitObservationPopover}
      />
      <button
        className="blueBtnSm"
        ref={brsBtnRef}
        type="button"
        onClick={handleClickBRS}
      >
        Behavior Rating Scale
      </button>
      <BRSPopover
        objectRef={brsBtnRef}
        showDropdown={showBrs}
        setShowDropdown={setShowBrs}
        onSubmitPopover={onSubmitBrsPopover}
      />
      {(showRcIndicators || showObservation || showBrs) && (
        <div className="customDarkPopoverBackdrop" />
      )}
    </div>
  );
};

export default MeasureInfoCell;
