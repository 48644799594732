import React from "react";
import { Student } from "../../../../../../../../store/onboarding/types";
import { Specific } from "../../../../../../../../store/groups/types";
import { Column, Table } from "fixed-data-table-2";
import MatrixFocusHeader from "./matrix-focus-column/MatrixFocusHeader";
import MatrixFocusCell from "./matrix-focus-column/MatrixFocusCell";
import MatrixStudentHeader from "./metrix-student-column/MatrixStudentHeader";
import MatrixStudentCell from "./metrix-student-column/MatrixStudentCell";

type Props = {
  students: Student[];
  focuses: Specific[];
  onSelectFocus: (focus: Specific) => void;
};

const MatrixStudentFocusesTable = ({
  students,
  focuses,
  onSelectFocus,
}: Props) => {
  const tableWidth = 900;
  const fixedColumnWidth = 240;
  const columnWidth = 200;

  const tableHeight =
    window.innerHeight * 0.5 > 450 ? window.innerHeight * 0.5 : 450;
  const rowHeight = 70;
  const headerHeight = 50;

  return (
    <Table
      className="student-focuses-table"
      rowHeight={rowHeight}
      headerHeight={headerHeight}
      rowsCount={focuses.length}
      width={tableWidth}
      height={tableHeight}
    >
      <Column
        fixed
        header={<MatrixFocusHeader />}
        cell={(props) => (
          <MatrixFocusCell
            focus={focuses[props.rowIndex]}
            onSelectFocus={onSelectFocus}
          />
        )}
        width={fixedColumnWidth}
      />
      {students.map((student) => (
        <Column
          key={student.id}
          header={<MatrixStudentHeader student={student} />}
          cell={(props) => (
            <MatrixStudentCell
              focus={focuses[props.rowIndex]}
              student={student}
            />
          )}
          width={columnWidth}
        />
      ))}
    </Table>
  );
};

export default MatrixStudentFocusesTable;
