import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import Select, { ValueType } from "react-select";
import InviteUsersDropDown from "./InviteUsersDropDown";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import {
  changeRoleFilter,
  changeSchoolFilter,
  changeSearchFilter,
} from "../../../../../../store/onboarding/users-management/actions";
import {
  ReactSelectOption,
  School,
  TeacherInfo,
} from "../../../../../../store/onboarding/types";
import { UserAssignmentRole } from "../../../../../../store/auth/types";
import { districtOwnerAssignableRoles } from "../../../../../../store/onboarding/users-management/types";
import { Form, InputGroup } from "react-bootstrap";
import { getAllUsers } from "../../../../../../store/superuser/actions";
import {
  getTeachers,
  setShowDisabledUsers,
} from "../../../../../../store/onboarding/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

type StateProps = {
  schools: Array<School>;
  schoolFilter?: School;
  roleFilter?: UserAssignmentRole;
  searchFilter?: string;
  isLoading: {
    getSchoolsList: boolean;
  };
};
type DispatchProps = {
  changeSchoolFilter: (school?: School) => any;
  changeRoleFilter: (role?: UserAssignmentRole) => any;
  changeSearchFilter: (searchFilter?: string) => any;
};
type OwnProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const FilterPanel: FunctionComponent<Props> = ({
  schools,
  roleFilter,
  schoolFilter,
  searchFilter,
  changeRoleFilter,
  changeSearchFilter,
  changeSchoolFilter,
  isLoading: { getSchoolsList: loadingSchoolsList },
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeSearchFilter(''));
  },[])

  const selectStyles = useMemo(
    () => ({
      container: (base: any) => ({ ...base, minWidth: 250, marginRight: 20 }),
    }),
    []
  );

  const handleSchoolFilterChange = (value: ValueType<School, false>) => {
    changeSchoolFilter(value as School);
  };

  const handleSearchFilterChange = (event: React.FormEvent<any>) => {
    event.preventDefault();
    const { value } = event.target as HTMLInputElement;
    changeSearchFilter(value);
  };

  const handleRoleFilterChange = (
    role: ValueType<ReactSelectOption<string>, false>
  ) => {
    changeRoleFilter(
      (role as ReactSelectOption<string>)?.value as UserAssignmentRole
    );
  };

  const showDisabledUsers = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.onboarding.showDisabledUsers
  );

  const handleShowDisabledUsersChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = e.target.checked;
    dispatch(setShowDisabledUsers(checked));
    dispatch(getTeachers(checked));
  };

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex align-items-center w-90">
        <InputGroup className="filterSearchDesktop mr-2">
          <InputGroup.Prepend>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchFilter as any}
            onChange={handleSearchFilterChange}
            className="filterSearchHeight"
          />
        </InputGroup>
        <Select
          isClearable
          isLoading={loadingSchoolsList}
          name="schools"
          options={schools}
          value={schoolFilter}
          onChange={handleSchoolFilterChange}
          getOptionLabel={(school: School) => school.name}
          getOptionValue={(school: School) => school.id.toString()}
          placeholder="Filter by school..."
          styles={selectStyles}
        />
        <Select
          isClearable
          name="roles"
          placeholder="Filter by role..."
          options={districtOwnerAssignableRoles}
          value={districtOwnerAssignableRoles.find(
            (role) => role.value === roleFilter
          )}
          onChange={handleRoleFilterChange}
          styles={selectStyles}
        />
        <Form.Check
          type="switch"
          id="show-disabled-users-switch"
          label="Show Disabled Users"
          checked={showDisabledUsers}
          onChange={handleShowDisabledUsersChange}
          className="w-75"
        />
      </div>
      <InviteUsersDropDown />
    </div>
  );
};

const mapStateToProps = ({
  onboarding,
  usersManagement,
}: ApplicationState): StateProps => {
  return {
    schools: onboarding.schools,
    schoolFilter: usersManagement.schoolFilter,
    roleFilter: usersManagement.roleFilter,
    searchFilter: usersManagement.searchFilter,
    isLoading: {
      getSchoolsList: onboarding.isLoading.getSchoolsList,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      changeSchoolFilter: changeSchoolFilter,
      changeRoleFilter: changeRoleFilter,
      changeSearchFilter: changeSearchFilter,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel);
