import React, { useMemo } from "react";
import { Specific } from "../../../../../../../../store/groups/types";
import { Intervention } from "../../../../../../../../store/onboarding/cases/types";
import { Student } from "../../../../../../../../store/onboarding/types";
import InterventionItem from "../../../../../../../pages/coach-dashboard/tabs/explore-and-learn/intervention-library/InterventionItem";

type Props = {
  focus?: Specific;
  interventions: Intervention[];
  students: Student[];
  selectedInterventions: Intervention[];
  onSelectIntervention: (int: Intervention) => void;
  onOpenInterventionLibrary: () => void;
};

const FocusInterventionsTable = ({
  focus,
  interventions,
  students,
  selectedInterventions,
  onSelectIntervention,
  onOpenInterventionLibrary,
}: Props) => {
  const tableWidth = 900;
  const firstColumnWidth = 240;
  const secondColumnWidth = tableWidth - firstColumnWidth;

  const tableHeight =
    window.innerHeight * 0.5 < 450 ? 450 : window.innerHeight * 0.5;
  const headerHeight = 50;
  const cellsHeight = tableHeight - headerHeight;
  const firstColumnFooterHeight = 100;
  const firstColumnCellHeight = cellsHeight - 100;

  const focusInterventionsAmount = useMemo(
    () =>
      selectedInterventions.filter(
        (item) => focus && item.focuses?.some((f) => f === focus.identifier)
      ).length,
    [selectedInterventions, focus]
  );

  return focus ? (
    <div
      className="student-focuses-table"
      style={{ width: tableWidth, height: tableHeight }}
    >
      <div className="d-flex w-100" style={{ height: headerHeight }}>
        <div
          className="first-column-header"
          style={{ width: firstColumnWidth }}
        >
          Skill/Students
        </div>
        <div
          className="second-column-header"
          style={{ width: secondColumnWidth }}
        >
          Interventions
        </div>
      </div>
      <div className="d-flex w-100" style={{ height: cellsHeight }}>
        <div className="d-flex flex-column" style={{ width: firstColumnWidth }}>
          <div
            className="first-column-cell"
            style={{ height: firstColumnCellHeight }}
          >
            <div className="focus-info p-4">
              <span className="font-weight-bold">{focus.display_name}</span>
              <span className="number-in-circle my-2">
                {focusInterventionsAmount}
              </span>
              <span>intervention(s) added for this skill</span>
              <hr />
              {students.map((student) => (
                <span key={student.id}>
                  {student.first_name} {student.last_name}
                </span>
              ))}
            </div>
          </div>
          <div
            className="first-column-cell"
            style={{ height: firstColumnFooterHeight }}
          >
            <div className="d-flex flex-column">
              Looking for something else?
              <button className="mt-2" onClick={onOpenInterventionLibrary}>
                Intervention Library
              </button>
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-column"
          style={{ width: secondColumnWidth }}
        >
          <div className="second-column-cell">
            <div className="interventionList">
              {interventions.map((intervention) => (
                <InterventionItem
                  key={intervention.id}
                  isSelected={selectedInterventions.some(
                    (item) => item.id === intervention.id
                  )}
                  intervention={intervention}
                  onSelect={onSelectIntervention}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default FocusInterventionsTable;
