import { ReactSelectOption } from "../onboarding/types";
import { Release_Notes } from "../superuser/types";

export type HelpState = {
  showTopBar?: boolean;
  isCompressedViewOfDataTable?: boolean;
  latestRelease?: Release_Notes;
  modalsState: {
    helpVideoModal: boolean;
    contactSupportModal: boolean;
    selectLanguageModal?: boolean;
    pageHelperModal?: boolean;
    permissionSettingModal?: boolean;
    latestReleaseModal?: boolean;
  };
  isLoading: {
    contactSupport: boolean;
    getLatestRelease: boolean;
  };
  errors: {
    contactSupport?: string;
    getLatestRelease?: string;
  };
  locale?: LOCALE;
  isGlobal?: boolean;
};

export enum HelpActionTypes {
  OPEN_HELP_VIDEO_MODAL = "@@help/OPEN_HELP_VIDEO_MODAL",
  HIDE_HELP_VIDEO_MODAL = "@@help/HIDE_HELP_VIDEO_MODAL",

  OPEN_CONTACT_SUPPORT_MODAL = "@@help/OPEN_CONTACT_SUPPORT_MODAL",
  HIDE_CONTACT_SUPPORT_MODAL = "@@help/HIDE_CONTACT_SUPPORT_MODAL",

  GET_CONTACT_SUPPORT_START = "@@help/GET_CONTACT_SUPPORT_START",
  GET_CONTACT_SUPPORT_SUCCESS = "@@help/GET_CONTACT_SUPPORT_SUCCESS",
  GET_CONTACT_SUPPORT_FAILURE = "@@help/GET_CONTACT_SUPPORT_FAILURE",

  CHANGE_DISPLAYED_BAR = "@@help/CHANGE_DISPLAYED_BAR",
  CHANGE_VIEW_OF_DATA_TABLE = "@@help/CHANGE_VIEW_OF_DATA_TABLE",
  OPEN_PERMISSION_SETTING_MODAL = "@@help/OPEN_PERMISSION_SETTING_MODAL",
  HIDE_PERMISSION_SETTING_MODAL = "@@help/HIDE_PERMISSION_SETTING_MODAL",
  GET_LATEST_RELEASE_REQUEST = "@@help/GET_LATEST_RELEASE_REQUEST",
  GET_LATEST_RELEASE_SUCCESS = "@@help/GET_LATEST_RELEASE_SUCCESS",
  GET_LATEST_RELEASE_FAILURE = "@@help/GET_LATEST_RELEASE_FAILURE",
  OPEN_RELEASE_MODAL = "@@help/OPEN_RELEASE_MODAL",
  HIDE_RELEASE_MODAL = "@@help/HIDE_RELEASE_MODAL",
}

export type UserRole =
  | "teacher"
  | "coach";
  // | "Interventionist"
  // | "State Level Coach"
  // | "Other";

export const TeacherRoles: ReactSelectOption<UserRole>[] = [
  { value: "teacher", label: "Teacher" },
  { value: "coach", label: "Coach" },
  // { value: "Interventionist", label: "Interventionist" },
  // { value: "State Level Coach", label: "State Level Coach" },
  // { value: "Other", label: "Other" },
];

export enum LOCALE {
  ENGLISH = "en",
  SPANISH = "es",
}

export const LocaleDisplayed = {
  [LOCALE.ENGLISH]: "English",
  [LOCALE.SPANISH]: "Español",
};
