import React, { FunctionComponent, useEffect, useRef } from "react";
import SelectStudentsModalBody from "./SelectStudentsModalBody";
import { UserInfo } from "../../../../../../../store/auth/types";
import { Student } from "../../../../../../../store/onboarding/types";

type OwnProps = {
  selectedTeacher: UserInfo;
  selectedStudents: Array<Student>;
  onModalHide: () => void;
  onSelectedStudentsConfirm: (students: Array<Student>) => void;
};

type Props = OwnProps;

const AddStudentsToInterviewModal: FunctionComponent<Props> = ({
  selectedTeacher,
  selectedStudents,
  onModalHide,
  onSelectedStudentsConfirm,
}) => {
  const popupRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () =>
      document.removeEventListener("click", handleClickOutside, false);
  }, []);

  const handleClickOutside = (event: any) => {
    if (event.target === popupRef.current) {
      onModalHide();
    }
  };

  const handleConfirm = (students: Array<Student>) => () => {
    onSelectedStudentsConfirm(students);
    onModalHide();
  };

  return (
    <div className="modalDialog" ref={popupRef}>
      <div>
        <SelectStudentsModalBody
          selectedTeacher={selectedTeacher}
          selectedStudents={selectedStudents}
          onSelectedStudentsConfirm={handleConfirm}
          isNewStudentAdd
        />
      </div>
    </div>
  );
};

export default AddStudentsToInterviewModal;
