import React, { useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { IS_READY_COACH } from "../../../../../../constants";

type OwnProps = {
  showModal: boolean;
  onConfirm: () => any;
  onHide: () => any;
  isLoading: boolean;
};

type Props = OwnProps;

const UnsaveConfirmModal = (props: Props) => {
  useEffect(() => {
    if (props.showModal && !props.isLoading) {
      props.onHide();
    }
  }, [props.isLoading]);

  const handleConfirm = () => {
    if (!props.isLoading) {
      props.onConfirm();
    }
  };

  return (
    <Modal
      show={props.showModal}
      onHide={props.onHide}
      animation={false}
      size="sm"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header
        closeButton
        className={`${
          IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"
        } font-weight-bold`}
      >
        <Modal.Title>Confirm Unsave</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center m-3">
          <h4>
            The group will be removed from Your New Groups and moved to
            Recommendations.
          </h4>
        </div>
        <div className="d-flex">
          <button
            className="blueBtnSm w-100 mr-2"
            disabled={props.isLoading}
            onClick={props.onHide}
          >
            Cancel
          </button>
          <button className="blueBtnSm w-100" onClick={handleConfirm}>
            Confirm{" "}
            {props.isLoading && <Spinner animation="border" size="sm" />}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UnsaveConfirmModal;
