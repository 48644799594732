import React from "react";
import { useDispatch } from "react-redux";
import { setShowDistrictModal } from "../../../../../../store/superuser/actions";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DistrictsTableActions = () => {
  const dispatch = useDispatch();

  const onCreateDistrict = () => {
    dispatch(setShowDistrictModal({ show: true, district: null }));
  };

  return (
    <div>
      <button className="blueBtnSm mr-3" onClick={onCreateDistrict}>
        <FontAwesomeIcon icon={faPlusCircle} style={{ color: "white" }} />{" "}
        Create District
      </button>
    </div>
  );
};

export default DistrictsTableActions;
