import React, { FunctionComponent, useEffect } from "react";
import EvidenceTable from "./EvidenceTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomBehaviors,
  getDataPeriods,
  getStaticDataCategoryDomains,
  getStudentsData,
} from "../../../../../store/onboarding/actions";
import { ApplicationState } from "../../../../../store";
import { getInterventionGroupsByParent } from "../../../../../store/onboarding/cases/actions";
import LoadingIndicator from "../../LoadingIndicator";
import { Alert } from "react-bootstrap";
import { toastr } from "react-redux-toastr";

type OwnProps = {};

type Props = OwnProps;

const ParentEvidenceTab: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const dataPeriod = useSelector(
    (s: ApplicationState) => s.onboarding.currentDataPeriod
  );

  const hasEvidencePeriodData = useSelector(
    (s: ApplicationState) =>
      s.onboarding.evidencePeriodData?.evidence_column_groups?.length! > 0
  );

  const isLoading = useSelector(
    (s: ApplicationState) =>
      s.onboarding.isLoading.getDataPeriods ||
      s.onboarding.isLoading.getEvidenceInDatePeriod
  );

  useEffect(() => {
    dispatch(getDataPeriods());
    dispatch(getStaticDataCategoryDomains());
    dispatch(getCustomBehaviors());
  }, []);

  useEffect(() => {
    if (dataPeriod) {
      (dispatch(getStudentsData(dataPeriod.id)) as any)?.then(
        null,
        (err: string) => toastr.error("Error", err)
      );
      (dispatch(getInterventionGroupsByParent()) as any)?.then(
        null,
        (err: string) => toastr.error("Error", err)
      );
    }
  }, [dataPeriod]);

  return (
    <div className="dataTableContainer">
      {!hasEvidencePeriodData ? (
        <div className="p-3">
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <Alert variant="warning">
              No data available on the selected data period
            </Alert>
          )}
        </div>
      ) : (
        <EvidenceTable isReadonly />
      )}
    </div>
  );
};

export default ParentEvidenceTab;
