import React, { FunctionComponent, useMemo } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import AbcItem from "./AbcItem";
import { FbaABC } from "../../../../../../store/onboarding/cases/types";

type Props = {};

const ObservedBehaviorsPanel: FunctionComponent<Props> = (props) => {
  const observation = useSelector(
    (s: ApplicationState) => s.cases.selectedFbaObservation
  );

  const observationabc = useMemo(() => {
    return observation?.observationabc ?? [];
  }, [observation]);

  return (
    <div className="observed-behaviors-container">
      <div>Observed Behaviors</div>
      <div>
        {observationabc.length ? (
          (observationabc as FbaABC[]).map((abc) => (
            <AbcItem key={abc.id} abc={abc} />
          ))
        ) : (
          <i>You have not logged any behavior yet</i>
        )}
      </div>
    </div>
  );
};

export default ObservedBehaviorsPanel;
