import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getMasterDataTypes } from "../../../../../store/superuser/actions";
import { toastr } from "react-redux-toastr";
import MasterDataDetails from "./MasterDataDetails";
import { EventKey } from "react-bootstrap/esm/types";

export enum MasterTabKeys {
  MEASUREMENTS = "measurements",
  RACES = "races",
}

const MasterData = () => {
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [masterDataTypes, setMasterDataTypes] = useState<any[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch<any>(getMasterDataTypes()).then(
      (typesResponse: any) => {
        setActiveTab(MasterTabKeys.MEASUREMENTS);
        setMasterDataTypes(typesResponse)
      },
      (error: string) => {
        toastr.error("Error", error);
      }
    );
  },[])


  return (
    <div className="onboardingContainer">
      <div className="mb-3 row col-12">
          <h2 className="font-weight-bold text-nowrap">Master Data</h2>
      </div>  
      <Tabs
        id="master-data-tabs"
        activeKey={activeTab as EventKey}
        onSelect={(k) => setActiveTab(k)}
      >
        <Tab eventKey={MasterTabKeys.MEASUREMENTS} title="Measurements">
          {activeTab == MasterTabKeys.MEASUREMENTS ?
            <MasterDataDetails masterDataType={masterDataTypes[0]}/>
          : ''}
        </Tab>
        <Tab eventKey={MasterTabKeys.RACES} title="Races">
          {activeTab == MasterTabKeys.RACES 
            ? <MasterDataDetails masterDataType={masterDataTypes[1]}/>
            : ''
          }
        </Tab>
      </Tabs>
    </div>
  );
};

export default MasterData;
