import React, { FormEvent, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  Student,
  StudentInfo,
  TeacherInfo,
} from "../../../../../store/onboarding/types";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  assignClassToTeacher,
  hideMergeTeachersModal,
} from "../../../../../store/onboarding/actions";
import { toastr } from "react-redux-toastr";
import { FormControl, FormGroup, FormLabel, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";

type PropsFromState = {
  teachers: TeacherInfo[];
  isLoading: boolean;
  errors?: string;
};

type DispatchProps = {
  assignClassToTeacher: (
    id: number,
    className: string,
    students: Array<Student>,
    grade?: string
  ) => any;
};

type OwnProps = {
  showModal: boolean;
  hideModal: () => any;
  students: StudentInfo[];
};

type Props = PropsFromState & DispatchProps & OwnProps;

const RostersCreateClassModal = (props: Props) => {
  const { teachers, students, isLoading, errors } = props;
  const [selectedTeacher, setTeacher] = useState<number>();
  const [className, setClassName] = useState<string>();

  useEffect(() => {
    if (!isLoading) {
      if (errors) {
        toastr.error("Error", errors);
      } else {
        props.hideModal();
      }
    }
  }, [isLoading]);

  const handleCreateClass = (event: React.FormEvent) => {
    event.preventDefault();
    if (selectedTeacher && className) {
      props.assignClassToTeacher(selectedTeacher, className, students);
    }
  };

  return (
    <Modal
      show={props.showModal}
      onHide={props.hideModal}
      animation={false}
      backdropClassName={"customDarkModalBackdrop in"}
    >
      <Modal.Header closeButton className="orangeModalHeader">
        <Modal.Title>Create Class</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <h3 className="mb-1">Students</h3>
          <div className="d-flex flex-wrap">
            {students.map((student) => (
              <div key={student.id} className="studentGroupCell mr-2">
                {`${student.first_name} ${student.last_name}`}
              </div>
            ))}
          </div>
        </div>
        <Form id="classForm" onSubmit={handleCreateClass}>
          <FormGroup controlId="validation_class_name" className="w-100 mb-3">
            <FormLabel>
              <h3>Name Class</h3>
            </FormLabel>
            <FormControl
              required
              name={"className"}
              placeholder="Enter class name..."
              type="string"
              onChange={({ target }: FormEvent<any>) =>
                setClassName((target as HTMLInputElement).value)
              }
            />
          </FormGroup>
          <FormGroup
            controlId="validation_selected_teacher"
            className="w-100 mb-4"
          >
            <FormLabel>
              <h3 className="mb-2">Select the teacher</h3>
            </FormLabel>
            <Select
              key={selectedTeacher}
              isClearable
              className="dataFiltersSelect"
              value={teachers.find((x) => selectedTeacher === x.user.id)}
              options={teachers}
              getOptionLabel={(teacher: TeacherInfo) =>
                `${teacher.user.first_name} ${teacher.user.last_name}`
              }
              getOptionValue={(teacher: TeacherInfo) =>
                teacher.user.id.toString()
              }
              onChange={(teacher: ValueType<TeacherInfo, false>) =>
                setTeacher(
                  teacher ? (teacher as TeacherInfo).user.id : undefined
                )
              }
            />
          </FormGroup>
        </Form>
        <div className="d-flex justify-content-center">
          <button
            disabled={isLoading}
            className="blueBtnMd mr-2"
            onClick={props.hideModal}
          >
            Cancel
          </button>
          <button className="blueBtnMd" type="submit" form="classForm">
            Create Class {isLoading && <Spinner animation="border" size="sm" />}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    teachers: onboarding.teachersRoster,
    isLoading: onboarding.isLoading.assignClassToTeacher,
    errors: onboarding.errors.assignClassToTeacher,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      assignClassToTeacher: assignClassToTeacher,
      hideMergeTeachersModal: hideMergeTeachersModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RostersCreateClassModal);
