import React, { FunctionComponent } from "react";
import { Form } from "react-bootstrap";
import { changeGraphsDisplayedAreas } from "../../../../../../store/onboarding/actions";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import SectionToggleButton from "./SectionToggleButton";

type OwnProps = {};

type Props = OwnProps;

const DistrictSectionsToggleButtons: FunctionComponent<Props> = (props) => {
  const displayCharts = useSelector<ApplicationState, boolean>(
    (s) => s.onboarding.districtSummaryOptions.displayCharts
  );

  const dispatch = useDispatch();

  const handleShowGraphs = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    dispatch(changeGraphsDisplayedAreas(checked));
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <h3>Click Buttons to show/hide data tables and graphs </h3>

      <Form.Check
        className="my-3"
        checked={displayCharts}
        onChange={handleShowGraphs}
        type="switch"
        id={`show-graphs-switch`}
        label="Show Charts if Available"
      />

      <div className="district-sections-toggle-buttons">
        <SectionToggleButton
          title={"Assignments in District"}
          sectionKey={"assignments-in-district"}
        />
        <SectionToggleButton
          title={"Goals in District"}
          sectionKey={"goals-in-district"}
        />
        <SectionToggleButton
          title={"Interventions used"}
          sectionKey={"interventions-in-district"}
        />
        <SectionToggleButton
          title={"Interventions Stats"}
          sectionKey={"interventions-stats"}
        />
        <SectionToggleButton
          title={"Goal Progress"}
          sectionKey={"goal-progress-in-district"}
        />
        <SectionToggleButton
          title={"Decisions Made"}
          sectionKey={"decisions-in-district"}
        />
        <SectionToggleButton
          title={"Average Goal Attainment"}
          sectionKey={"goal-attainment-in-district"}
        />
        <SectionToggleButton
          title={"Average Intervention Fidelity"}
          sectionKey={"intervention-fidelity-in-district"}
        />
        <SectionToggleButton
          title={"Fidelity by Coaching Relationship"}
          sectionKey={"fidelity-by-teacher-in-district"}
        />
        <SectionToggleButton
          title={"Fidelity by Intervention"}
          sectionKey={"fidelity-by-intervention-in-district"}
        />
        <SectionToggleButton
          title={"Intervention Group Progress"}
          sectionKey={"group-progress-in-district"}
        />
        <SectionToggleButton
          title={"Alerts per Coach"}
          sectionKey={"alerts-per-coach-in-district"}
        />
      </div>
    </div>
  );
};

export default DistrictSectionsToggleButtons;
