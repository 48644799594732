import React, { Component } from "react";
import { Cell } from "fixed-data-table-2";
import Popover from "react-bootstrap/Popover";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { OverlayTrigger } from "react-bootstrap";
import {
  EvidenceColumn,
  EvidencePeriodData,
  EvidenceType,
  Student,
  StudentEntry,
} from "../../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../../store";
import {
  openBehaviorScreenerModal,
} from "../../../../../../../store/groups/actions";

type OwnProps = {
  //studentEntry?: StudentEntry;
  showHint?: boolean;
  evidenceColumnGroupId: number;
  evidenceColumn: EvidenceColumn;
  student: Student;
};

type PropsFromState = {
  evidencePeriodData?: EvidencePeriodData;
  isEnterSpecificSkillActive: boolean;
};

type DispatchProps = {
  openBehaviorScreenerModal: (
    evidenceColumnGroupId: number,
    evidenceColumn: EvidenceColumn,
    student: Student,
    //studentEntry?: StudentEntry,
    ) => any;
};

type Props = OwnProps & PropsFromState & DispatchProps;

class BehaviorScreenerDataEntryCell extends Component<Props> {
  overlay: any;
  handleScreenerModalOpen = () => {
    if (this.overlay) this.overlay.hide();
    const { 
      //studentEntry, 
      evidenceColumnGroupId, 
      evidenceColumn, 
      student
    } = this.props;
    this.props.openBehaviorScreenerModal(
      evidenceColumnGroupId,
      evidenceColumn,
      student,
      //studentEntry,
    );
  };

  render() {
    return (
      <Cell
        className="dataTableCellEmpty"
        onClick={this.handleScreenerModalOpen}
      >
        <div>
          {this.props.showHint ? (
            <OverlayTrigger
              ref={(ref: any) => (this.overlay = ref)}
              placement="bottom"
              defaultShow
              //@ts-ignore
              trigger={null}
              overlay={
                <Popover id="diagSkillCellPopover" className="text-center">
                  <Popover.Content>
                    <p>
                      To produce better behavior screening, you can select
                      specific problem which students are struggling with.
                    </p>
                    <button
                      className="blueBtnMd"
                      onClick={() => this.overlay.hide()}
                    >
                      GOT IT
                    </button>
                  </Popover.Content>
                </Popover>
              }
            >
              <button className="blueBtnSm">SCREEN</button>
            </OverlayTrigger>
          ) : (
            <button className="blueBtnSm">SCREEN</button>
          )}
        </div>
      </Cell>
    );
  }
}

const mapStateToProps = ({
  cases,
  onboarding,
}: ApplicationState): PropsFromState => {
  return {
    isEnterSpecificSkillActive: cases.isEnterSpecificSkillActive,
    evidencePeriodData: onboarding.evidencePeriodData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openBehaviorScreenerModal: openBehaviorScreenerModal
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BehaviorScreenerDataEntryCell);
