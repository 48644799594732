import React, { FunctionComponent } from "react";
import { Modal } from "react-bootstrap";
import {
  ConcernArea,
  Intervention,
} from "../../../../../../../../store/onboarding/cases/types";

type OwnProps = {
  editing?: boolean;
  concernArea?: ConcernArea;
  selectedIntervention?: Intervention;
  interventionName: string;
  interventionDescription: string;
  setConcernArea: React.Dispatch<React.SetStateAction<ConcernArea>>;
  setInterventionName: React.Dispatch<React.SetStateAction<string>>;
  setInterventionDescription: React.Dispatch<React.SetStateAction<string>>;
  onClickNext: () => void;
};

type Props = OwnProps;

const InterventionStrategyModalBody: FunctionComponent<Props> = ({
  editing,
  concernArea,
  interventionName,
  interventionDescription,
  selectedIntervention,
  onClickNext,
  setConcernArea,
  setInterventionName,
  setInterventionDescription,
}) => {
  const handleInterventionNameChange = (event: React.ChangeEvent<any>) => {
    const { value } = event.target as HTMLInputElement;
    setInterventionName(value);
  };

  const handleInterventionDescriptionChange = (
    event: React.ChangeEvent<any>
  ) => {
    const { value } = event.target as HTMLInputElement;
    setInterventionDescription(value);
  };

  return (
    <Modal.Body>
      <div className="text-center">
        <h3 className="purpleText">
          {selectedIntervention
            ? "What kind of intervention is this and what should we call it?"
            : "You want to create a new intervention strategy."}
        </h3>
        <br />
        {(!selectedIntervention || editing) && (
          <>
            <h4 className="font-weight-bold">Indicate the type.</h4>
            <p className="mb-1">
              Your intervention will be included in your personal Intervention
              Library for future use and providing us the type of intervention
              lets us suggest it to you for relevant cases.
            </p>
            <div
              className={
                "purplePillBtnLg" +
                (concernArea === ConcernArea.BEHAVIOR ? " active" : "")
              }
              onClick={() => setConcernArea(ConcernArea.BEHAVIOR)}
            >
              Behavior
            </div>
            <div
              className={
                "purplePillBtnLg" +
                (concernArea === ConcernArea.ACADEMIC ? " active" : "")
              }
              onClick={() => setConcernArea(ConcernArea.ACADEMIC)}
            >
              Academic
            </div>
            <div
              className={
                "purplePillBtnLg" +
                (concernArea === ConcernArea.BOTH ? " active" : "")
              }
              onClick={() => setConcernArea(ConcernArea.BOTH)}
            >
              Both
            </div>
            <br />
          </>
        )}

        <h4 className="font-weight-bold">Name it.</h4>
        <p className="mb-1">
          We show the name throughout the ReadyCoach application so make sure
          you pick a name that is descriptive and meaningful to you
          <i>(ex. “Do, Show, Tell”).</i>
        </p>
        <input
          required
          value={interventionName}
          onChange={handleInterventionNameChange}
          className="fullWidthInput"
          type="text"
          placeholder="ex. Do, Show, Tell"
        />
        <br />
        <br />
        <h4 className="font-weight-bold">Briefly describe it.</h4>
        <p className="mb-1">
          Give us a few sentences about the intervention that that will motivate
          potential implementors.
        </p>
        <textarea
          required
          value={interventionDescription}
          onChange={handleInterventionDescriptionChange}
          className="fullWidthTextarea"
          placeholder="Your motivating description..."
        />
        <br />
      </div>
      <hr />
      <div className="modalActions">
        <div />
        <button
          className="blueBtnSm"
          onClick={onClickNext}
          disabled={!interventionName.length || !interventionDescription.length}
        >
          Continue
        </button>
      </div>
    </Modal.Body>
  );
};

export default InterventionStrategyModalBody;
