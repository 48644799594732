// @flow
import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import CreatableSelect from "react-select";
import {
  BasicUserInfo,
  EvidencePeriodData,
  InvitesResponse,
  ReactSelectOption,
  TeacherClass,
  TeacherInfo,
} from "../../../../../../../store/onboarding/types";
import { ValueType } from "react-select/src/types";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  changeTeachersFilter,
  clearClassesAssignedToTeacher,
  getClassFromStudentRowsTeachers,
  getClassesAssignedToTeacher,
  getClassesAssignedToTeacherSuccess,
  getTeachers,
  openTeacherInviteIndividuallyModal,
} from "../../../../../../../store/onboarding/actions";
import { connect, useSelector } from "react-redux";
import { getFullName } from "../../../../../../../utils/NamesUtils";
import { getBasicUserInfoFromString } from "../../../../../../../utils/StudentMapUtils";
import { USState } from "../../../../../States";
import { Grade } from "../../../../../../../store/groups/types";

type PropsFromState = {
  teacherRoster: Array<TeacherInfo>;
  teacherIdFilter?: number[];
  dataGradesFilter: Grade[];
  invites: InvitesResponse;
  errors: {
    getTeachers?: string;
  };
  isLoading: {
    getTeachers: boolean;
  };
};

type DispatchProps = {
  getTeachers: () => any;
  clearClassesAssignedToTeacher: () => any;
  changeTeachersFilter: (teacherIdFilter?: number[]) => any;
  //getClassesAssignedToTeacher: (teacherId: number) => any;
  getClassFromStudentRowsTeachers: (gradesFilter: Grade[], teacherFilter: any[]) => any;
  openTeacherInviteIndividuallyModal: (newTeacher?: BasicUserInfo) => any;
  getClassesAssignedToTeacherSuccess: (teachersClasses: Array<TeacherClass>) => any;
};

type Props = PropsFromState & DispatchProps;

const TeachersFilter: FunctionComponent<Props> = ({
  isLoading,
  getTeachers,
  teacherRoster,
  invites,
  teacherIdFilter,
  dataGradesFilter,
  changeTeachersFilter,
  //getClassesAssignedToTeacher,
  getClassesAssignedToTeacherSuccess,
  getClassFromStudentRowsTeachers,
  openTeacherInviteIndividuallyModal,
  clearClassesAssignedToTeacher,
}) => {
  const [isNewInvite, setIsNewInvite] = useState(false);

  const evidencePeriodData = useSelector<
    ApplicationState,
    EvidencePeriodData
  >((s) => s.onboarding.evidencePeriodData!);

  useEffect(() => {
    getTeachers();
  }, []);

  useEffect(() => {
    if (isNewInvite) {
      const { successful_invites } = invites;
      if (successful_invites.length) {
        const { user } = successful_invites[0];
        changeTeachersFilter([user.id]);
      }
      setIsNewInvite(false);
    }
  }, [teacherRoster]);

  const handleSelectedTeacherChange = (
    option: ValueType<ReactSelectOption<BasicUserInfo>, any>
  ) => {
    if (option) {
      const teacherIds: any[] = (option as ReactSelectOption<BasicUserInfo>[])?.map((teacher) => teacher.value.id) ?? [];
      changeTeachersFilter(teacherIds);
      //getClassesAssignedToTeacher(teacherIds);
      getClassesAssignedToTeacherSuccess(getClassFromStudentRowsTeachers(dataGradesFilter, teacherIds));
    } else {
      changeTeachersFilter();
      clearClassesAssignedToTeacher();
    }
  };

  const teachersByCurrentDataPeriod: any[] = useMemo(() => 
    evidencePeriodData?.student_rows.flatMap((std) => std.student?.teachers || []) || []
  ,[evidencePeriodData])

  const uniqueByKey = (array:BasicUserInfo[], key:any ='id') => {
    return Array.from(new Map(array.map((x:any) => [x[key], x])).values());
  }

  const options: Array<ReactSelectOption<BasicUserInfo>> = useMemo(() => {
    return (uniqueByKey(teachersByCurrentDataPeriod) || []).map((teacherInfo: BasicUserInfo) => ({
      label: getFullName(teacherInfo),
      value: teacherInfo,
    }));
  }, [teacherRoster, teachersByCurrentDataPeriod]);

  const handleInviteTeacher = (input: string) => {
    setIsNewInvite(true);
    openTeacherInviteIndividuallyModal(getBasicUserInfoFromString(input));
  };

  
  return (
    <>
      <h4 className="font-weight-bold m-0">Educator(s)</h4>
      <CreatableSelect
        isMulti
        isClearable
        placeholder="All"
        name="teachersFilter"
        styles={{ menu: (styles) => ({ ...styles, zIndex: 100 }) }}
        isLoading={isLoading.getTeachers}
        options={options}
        value={options.filter((o) => teacherIdFilter?.some((teacherId) =>  o.value.id === teacherId))}
        onChange={handleSelectedTeacherChange}
        onCreateOption={handleInviteTeacher}
        formatCreateLabel={(input: string) => `Invite '${input}'`}
      />
    </>
  );
};

const mapStateToProps = ({ onboarding, dataFilter }: ApplicationState): PropsFromState => {
  return {
    teacherRoster: onboarding.teachersRoster,
    teacherIdFilter: onboarding.teacherIdFilter,
    dataGradesFilter: dataFilter.dataGradesFilter,
    invites: onboarding.invites,
    errors: {
      getTeachers: onboarding.errors.getTeachers,
    },
    isLoading: {
      getTeachers: onboarding.isLoading.getTeachers,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getTeachers: getTeachers,
      changeTeachersFilter: changeTeachersFilter,
      openTeacherInviteIndividuallyModal: openTeacherInviteIndividuallyModal,
      //getClassesAssignedToTeacher: getClassesAssignedToTeacher,
      getClassesAssignedToTeacherSuccess: getClassesAssignedToTeacherSuccess,
      getClassFromStudentRowsTeachers: getClassFromStudentRowsTeachers,
      clearClassesAssignedToTeacher: clearClassesAssignedToTeacher,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TeachersFilter);
