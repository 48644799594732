import React, { FunctionComponent, useMemo } from "react";
import { FidelityByTeacher } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import VerticalBarChart from "./VerticalBarChart";

type OwnProps = {
  fidelityByTeacher: FidelityByTeacher[];
};

type Props = OwnProps;

const FidelityByCoachingRelationshipChart: FunctionComponent<Props> = (
  props
) => {
  const { fidelityByTeacher = [] } = props;

  const labels = useMemo(
    () =>
      fidelityByTeacher.map(
        (item) => `${getFullName(item.coach)} / ${getFullName(item.teacher)}`
      ),
    [fidelityByTeacher]
  );
  const data = useMemo(
    () => fidelityByTeacher.map((item) => +(item.fidelity * 100).toFixed(0)),
    [fidelityByTeacher]
  );

  if (!data.length) {
    return null;
  }

  return (
    <div className="district-summary-chart-container">
      <div>
        <VerticalBarChart labels={labels} data={data} />
      </div>
    </div>
  );
};

export default FidelityByCoachingRelationshipChart;
