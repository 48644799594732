import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

type Props = {
  isClearable?: boolean;
  position?: "left" | "right";
  onClick?: any;
  value?: string;
};
const CustomDateInput = React.forwardRef<any, Props>(
  (props: Props, ref: any) => {
    const { position, isClearable } = props;
    return (
      <div
        data-cy="date-picker-btn"
        className={`datePickerBtn ${position ? position : ""} ${
          isClearable ? "pr-4" : ""
        }`}
        onClick={props.onClick}
      >
        <FontAwesomeIcon icon={faCalendarAlt} /> {props.value || "Pick a date"}
      </div>
    );
  }
);

export default CustomDateInput;
