import _ from "lodash";
import { UserInfo } from "../../../../../../../../../store/auth/types";
import {
  ChecklistItem,
  Intervention,
  InterventionGroup,
} from "../../../../../../../../../store/onboarding/cases/types";
import { ImplementationCheck } from "../../../../../../../../../store/onboarding/meeting-module/types";

export const makeStatisticObject = ({
  implChecks,
  checklistItems,
  intervention,
  teacherAssignment
}: {
  implChecks: ImplementationCheck[];
  checklistItems: (ChecklistItem & {
    loggedTimes: number;
    notApplicableTimes?: number;
    usedTimes?: number;
  })[];
  intervention: Intervention;
  teacherAssignment?: any;
}) => {
  // const teachersIds = _.chain(implChecks)
  //   .map((ic) => (ic.logged_by_user as UserInfo)?.id ?? ic.logged_by_user)
  //   .uniq()
  //   .value();


  const teachersIds = _.chain(teacherAssignment)
 .filter((ta) => ta != undefined)
 .map((ta) => ta!.id)
 .uniq()
 .value();

  const groupsIds = _.chain(implChecks)
    .map((ic) => ic.intervention_group)
    .uniq()
    .value();

  
  const uniqInterventionIds = _.chain(implChecks)
    .map((ic) => ic.intervention)
    .uniq()
    .value();

  return {
    teachersIds: teachersIds,
    groupsIds: groupsIds,
    loggedTimes: implChecks.length,
    intervention: intervention,
    checklist_items: checklistItems,
    implChecks: implChecks.length,
    totalFidelity:
      checklistItems.length && implChecks.length
        ? _.chain(implChecks)
            .map((ic) => ic.fidelity)
            .mean()
            .round()
            .value()
        : 0,
  };
};
