import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import Joyride, { CallBackProps, Step } from "react-joyride";
import { ApplicationState } from "../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { markEmptyDataPeriodViewTourAsShown } from "../../../../store/tour/actions";
import { connect } from "react-redux";
import useUserRole from "../../../../utils/hooks/useUserRole";

type StateProps = {
  isTourShown?: boolean;
};
type DispatchProps = {
  markEmptyDataPeriodViewTourAsShown: () => any;
};

type Props = StateProps & DispatchProps;

const EmptyDataPeriodViewTour: FunctionComponent<Props> = (props) => {
  const steps: Step[] = useMemo(
    () => [
      {
        disableBeacon: true,
        target: "#addColumnTooltip tbody > tr:nth-child(1) > td:nth-child(2)",
        content: (
          <>
            If you have assessment data or screening data saved as a CSV or
            Excel file, you can upload it here. We support many file types
            automatically, and you describe the columns of ones that we don't
            support out of the box. Or you can start by interviewing teachers.
            We'll guide you through the process and provide a form for you to
            collect notes about the interview.
          </>
        ),
      },
      {
        disableBeacon: true,
        target: "#addColumnTooltip tbody > tr:nth-child(2)",
        content: (
          <>
            Behavior Rating Scales are a form of behavioral assessment. If you
            don't have them saved as a file, you can search for the assessment
            and type in the results manually with this option. Or you can start
            by directly selecting students' Target Behaviors from our Behavior
            Library. You can also customize any behavior or create new
            behaviors.
          </>
        ),
      },
      {
        disableBeacon: true,
        target: "#addColumnTooltip tbody > tr:nth-child(3)",
        content: (
          <>
            If you'd like to type in the scores for reading or other academic
            assessments, choose this option. Usually this is because you do not
            have access to a file of the data which you could upload instead.
            You can also directly enter diagnostics data for reading skills from
            our Skill Library. For some grade levels and time periods, we also
            support grouping recommendations when you choose this option.
          </>
        ),
      },
    ],
    []
  );

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!props.isTourShown) {
      setShow(true);
    }
  }, [props.isTourShown]);

  const handleTourChange = (data: CallBackProps) => {
    if (["skip", "reset"].includes(data.action)) {
      props.markEmptyDataPeriodViewTourAsShown();
      setShow(false);
    }
  };

  const { isTeacher, isSupervisor } = useUserRole();

  if (props.isTourShown || isTeacher || isSupervisor) {
    return null;
  }

  return (
    <Joyride
      steps={steps}
      callback={handleTourChange}
      showSkipButton
      floaterProps={{ disableAnimation: true }}
      showProgress
      continuous
      run={show}
      styles={{
        options: {
          primaryColor: "#00265B",
        },
      }}
    />
  );
};

const mapStateToProps = ({ tour }: ApplicationState): StateProps => {
  return {
    isTourShown: tour.emptyDataPeriodViewTourShown,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      markEmptyDataPeriodViewTourAsShown: markEmptyDataPeriodViewTourAsShown,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmptyDataPeriodViewTour);
