import React from "react";
import { useDispatch } from "react-redux";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setShowReleaseModal } from "../../../../../../store/superuser/actions";
import { Release_Notes } from "../../../../../../store/superuser/types";

const defaultValue: Release_Notes = {
  id: 0,
  title: "What's new",
  description: "",
  is_published: false
};

const ReleaseNotesTableActions = () => {
  const dispatch = useDispatch();

  const onCreateReleaseNote = () => {
    dispatch(setShowReleaseModal({ show: true, releaseNote: defaultValue }));
  };

  return (
    <div>
      <button className="blueBtnSm mr-3" onClick={onCreateReleaseNote}>
        <FontAwesomeIcon icon={faPlusCircle} style={{ color: "white" }} />{" "}
        Create Release Note
      </button>
    </div>
  );
};

export default ReleaseNotesTableActions;
