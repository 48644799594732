import React, { FunctionComponent } from "react";
import FbaItem from "./fba-item/FbaItem";
import { Fba } from "../../../../../store/onboarding/cases/types";
import NewDataCollectionPopover from "./NewDataCollectionPopover";

type OwnProps = {
  fbas: Fba[];
  usingFilters?: boolean;
  forActive?: boolean;
  activeCallback?: () => void;
};

type Props = OwnProps;

const FbaTab: FunctionComponent<Props> = (props) => {
  const { fbas, usingFilters, forActive, activeCallback } = props;

  return (
    <div className="mt-2">
      {fbas.length ? (
        fbas.map((fba) => <FbaItem fba={fba} key={fba.id} />)
      ) : usingFilters ? (
        <i>
          No matching ABC Data Collections. Try to change the search options
        </i>
      ) : forActive ? (
        <div className="d-flex flex-column align-items-center justify-content-between">
          <i className="mb-2">
            You haven’t completed collecting data for any students yet. Collect
            ABC data then analyze your results to set set a goal, find
            interventions, and begin progress monitoring.
          </i>
          <NewDataCollectionPopover onComplete={activeCallback} />
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-between">
          <i className="mb-2">
            You haven’t started collecting ABC Data for any students yet.
          </i>
          <NewDataCollectionPopover />
        </div>
      )}
    </div>
  );
};

export default FbaTab;
