import React, { FunctionComponent, useMemo } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import {
  GrammarType,
  SpellingTestAnswer,
  SpellingTestAnswerGrammarPart,
  SpellingTestQuestion,
} from "../../../../../store/spelling-tests/types";
import { changeSpellingTestAnswerGrammarPart } from "../../../../../store/spelling-tests/actions";
import { useMinInputWidth } from "./hooks/useMinInputWidth";
import useSpellingTestCellOnFocus from "./hooks/useSpellingTestCellOnFocus";

type Props = {
  correctPart: string;
  question: SpellingTestQuestion;
  answer?: SpellingTestAnswer;
};

const VcePatternCell: FunctionComponent<Props> = (props) => {
  const { correctPart, answer, question } = props;
  const dispatch = useDispatch();

  const grammarPart: SpellingTestAnswerGrammarPart | undefined = useMemo(() => {
    return answer?.grammar_parts.find(
      (gp) => gp.grammar_type === GrammarType.VcePattern
    );
  }, [answer?.grammar_parts]);

  const handleVceGrammarPartChange = (
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    const correct = value === correctPart;
    if (value !== answer?.word && answer && grammarPart) {
      dispatch(
        changeSpellingTestAnswerGrammarPart({
          word: question.word,
          type: GrammarType.VcePattern,
          student_answer: value,
          correct: correct,
        })
      );
    }
  };

  const inputMinWidth = useMinInputWidth(correctPart);
  const onFocus = useSpellingTestCellOnFocus(question.word);

  return (
    <>
      <td>
        <InputGroup>
          <FormControl
            tabIndex={-1}
            readOnly
            className="source-part"
            value={correctPart}
            style={{ minWidth: inputMinWidth }}
          />
        </InputGroup>
      </td>
      <td>
        <InputGroup>
          <InputMask
            mask={"a_a"}
            maskChar={" "}
            defaultValue={grammarPart?.student_answer || ""}
            onBlur={handleVceGrammarPartChange}
            onFocus={onFocus}
          >
            {(inputProps: any) => (
              <FormControl
                {...inputProps}
                className={`${
                  grammarPart?.student_answer !== undefined
                    ? grammarPart.correct
                      ? "correct-part"
                      : "wrong-part"
                    : "empty-part"
                }`}
                style={{ minWidth: inputMinWidth }}
              />
            )}
          </InputMask>
        </InputGroup>
      </td>
    </>
  );
};

export default VcePatternCell;
