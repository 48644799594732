import React, { useMemo } from "react";
import { Cell } from "fixed-data-table-2";
import { Dropdown, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCog,
  faFileSignature,
} from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../../../../../../utils/DateTimeUtils";
import {
  EvidenceColumn,
  EvidenceColumnGroup,
  EvidenceType,
} from "../../../../../../../store/onboarding/types";
import FontAwesomeIconDropDown from "../drop-down/FontAwesomeIconDropDown";
import CustomMenuItem from "../drop-down/CustomMenuItem";
import { Portal } from "react-portal";
import { getDisplayedLabel } from "./AssessmentLabelTooltip";
import {
  evidenceColumnDisplayTitle,
  evidenceColumnGroupDisplayTitle,
} from "../Helpers";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { IS_READY_COACH } from "../../../../../../../constants";
import {
  openEquityBenchmarksModal,
  openEvidenceConfirmDeletionModal,
} from "../../../../../../../store/onboarding/actions";
import { useDispatch, useSelector } from "react-redux";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import useUserRole from "../../../../../../../utils/hooks/useUserRole";
import { ApplicationState } from "../../../../../../../store";

type Props = {
  isReadonly?: boolean;
  evidenceColumnGroup: EvidenceColumnGroup;
  numberDisplayedColumns: number;
  onDisplayedColumnChange: (
    evidenceColumnGroupId: number,
    evidenceColumnId: number
  ) => any;
  checkDisplayedColumn: (
    evidenceColumnGroupId: number,
    evidenceColumnId: number
  ) => boolean;
  deleteEvidenceColumnsGroup: (evidenceColumnGroupId: number) => boolean;
};

const ASSESSMENT_DISPLAYED_CHARACTERS_NUM = 35;
const replaceStringLength = 3;

const AssessmentHeaderCell = (props: Props) => {
  const {
    evidenceColumnGroup,
    numberDisplayedColumns,
    checkDisplayedColumn,
    onDisplayedColumnChange,
    deleteEvidenceColumnsGroup,
  } = props;

  const partialColumnsDisplay =
    evidenceColumnGroup.evidence_columns.some(
      (ec) => ec.evidence_type === EvidenceType.Data
    ) &&
    evidenceColumnGroup.evidence_columns.length > 1 &&
    evidenceColumnGroup.evidence_columns.length !== numberDisplayedColumns;

  const dispatch = useDispatch();

  //const currentDataPeriod = useSelector((s: ApplicationState) => s.onboarding.currentDataPeriod);
  const currentUser = useSelector((s: ApplicationState) => s.auth.userInfo);
  
  const isAccessToDelete = useMemo(() => { //RC-1411
    return evidenceColumnGroup?.user_id == currentUser?.id 
  },[evidenceColumnGroup])

  const handleDeleteEvidenceCG = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(openEvidenceConfirmDeletionModal({
      evidenceColumnGroup : evidenceColumnGroup, 
      timeElipse: true
    }));
  };

  const assessemntLabelDropdown = (evidenceColumnGroup: EvidenceColumnGroup) => {
    return <div className="assessmentLabelDropdown">
            <Dropdown drop="down">
              <Dropdown.Toggle
                as={FontAwesomeIconDropDown(
                  <div className="d-flex align-items-center">
                    <div className="assessmentLabelDetails">
                      <div className="assessmentLabelName">
                        <div>
                          {!IS_READY_COACH &&
                            evidenceColumnGroup.is_primary_screening && (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Popover id="primaryDataPopover">
                                    <Popover.Content>
                                      <p>
                                        This is the Primary Screening Data Set
                                        that is used to generate the Content Areas
                                        on Need that are considered in the
                                        grouping recommendations generated by
                                        NJTSS Early Reading.
                                      </p>
                                    </Popover.Content>
                                  </Popover>
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  className="mr-1"
                                />
                              </OverlayTrigger>
                            )}
                          <span className="mr-1">
                            <FontAwesomeIcon icon={faCog} />
                          </span>
                          {getDisplayedLabel(
                            evidenceColumnGroupDisplayTitle(evidenceColumnGroup),
                            ASSESSMENT_DISPLAYED_CHARACTERS_NUM
                          )}
                        </div>
                        {partialColumnsDisplay && (
                          <div className="partialColumnsDisplay">
                            (Displaying {numberDisplayedColumns} of{" "}
                            {evidenceColumnGroup.evidence_columns.length} columns)
                          </div>
                        )}
                      </div>

                      <div className="assessmentLabelDate">
                          <FontAwesomeIcon icon={faFileSignature} />
                          {formatDate(evidenceColumnGroup.created)}
                        </div>
                    </div>
                    
                  </div>
                )}
                id={`dropdown${evidenceColumnGroup.id}`}
              />

              {!props.isReadonly && (
                <Portal>
                  <div className="col-3">
                    <Dropdown.Menu style={{ maxHeight: "60vh", overflowY: 'auto'}}>
                      {IS_READY_COACH && (
                        <Dropdown.Item as={CustomMenuItem}>
                          <button
                            className="whiteBtnSm w-100"
                            onClick={() => {
                              dispatch(
                                openEquityBenchmarksModal(evidenceColumnGroup.id)
                              );
                            }}
                          >
                            Show Equity Benchmarks
                          </button>
                        </Dropdown.Item>
                      )}

                      {!IS_READY_COACH && (
                        <Dropdown.Item as={CustomMenuItem}>
                          <button
                            className="redBtnSm"
                            onClick={() =>
                              deleteEvidenceColumnsGroup(evidenceColumnGroup.id)
                            }
                          >
                            Delete Evidence
                          </button>
                        </Dropdown.Item>
                      )}
                      {evidenceColumnGroup.evidence_columns
                      .sort((a: EvidenceColumn, b: EvidenceColumn) => (a.id < b.id) ? -1 : 1)
                      .map(
                        (evidenceColumn: EvidenceColumn, index) => (
                          <Dropdown.Item key={index} as={CustomMenuItem}>
                            <div
                              onClick={onDisplayedColumnChange(
                                evidenceColumnGroup.id,
                                evidenceColumn.id
                              )}
                            
                            >
                              <input
                                readOnly
                                type="checkbox"
                                checked={checkDisplayedColumn(
                                  evidenceColumnGroup.id,
                                  evidenceColumn.id
                                )}
                              />
                              &nbsp;
                              {evidenceColumnDisplayTitle(evidenceColumn)}{" "}
                            </div>
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </div>
                </Portal>
              )}
            </Dropdown>
        </div>
  }

  return (
    <Cell className="assessmentLabel">
      {
        evidenceColumnGroupDisplayTitle(evidenceColumnGroup).length <= ASSESSMENT_DISPLAYED_CHARACTERS_NUM + replaceStringLength
        ?
          <OverlayTrigger
            overlay={<Tooltip id="editIcon">Click to show/hide columns</Tooltip>}
          >
            {assessemntLabelDropdown(evidenceColumnGroup)}
          </OverlayTrigger>
        :
          assessemntLabelDropdown(evidenceColumnGroup)
      }
      {IS_READY_COACH && !props.isReadonly && isAccessToDelete &&(
        <span
          className="mx-2 removeIconBtn"
          onClick={handleDeleteEvidenceCG}
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            color="red"
            size="lg"
          />
        </span>
      )}
    </Cell>
  );
};

export default AssessmentHeaderCell;
