import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useIntl } from "react-intl";
import UserAgreement from "./UserAgreement";
import PrivacyPolicy from "./PrivacyPolicy";

type Props = {};

const RCTermsAndConditions: React.FC<Props> = () => {
  const intl = useIntl();
  return (
    <>
        <UserAgreement />
      {/* <Tabs id="terms-and-conditions-tabs">
        <Tab
          eventKey={"user-agreement"}
          title={intl.formatMessage({
            id: "app.terms-and-conditions.user-agreement",
          })}
        >
        </Tab>
        <Tab
          eventKey={"privacy-policy"}
          title={intl.formatMessage({
            id: "app.terms-and-conditions.privacy-policy",
          })}
        >
          <PrivacyPolicy />
        </Tab>
      </Tabs> */}
    </>
  );
};

export default RCTermsAndConditions;
