import React, { FunctionComponent, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import SchoolsTable from "./SchoolsTable";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { UserInfo } from "../../../../../store/auth/types";
import {
  getSchools,
  openUpsertSchoolModal,
} from "../../../../../store/onboarding/actions";

type StateProps = {
  userInfo?: UserInfo;
};

type DispatchProps = {
  openUpsertSchoolModal: () => any;
  getSchools: () => any;
};

type Props = StateProps & DispatchProps;

const SchoolsManagementTab: FunctionComponent<Props> = ({
  userInfo,
  openUpsertSchoolModal,
  getSchools,
}) => {
  useEffect(() => {
    getSchools();
  }, []);

  return (
    <div className="manageDistrictContainer">
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <h2 className="font-weight-bold">Manage Schools</h2>
          <h3 className="blueTitle">{userInfo?.profile.district?.name}</h3>
        </div>
        <button
          className="blueBtnSm withInnerWhiteSvg"
          onClick={() => openUpsertSchoolModal()}
        >
          <FontAwesomeIcon icon={faPlusCircle} />
          New school
        </button>
      </div>
      <SchoolsTable />
    </div>
  );
};

const mapStateToProps = ({ auth }: ApplicationState): StateProps => {
  return {
    userInfo: auth.userInfo,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openUpsertSchoolModal: openUpsertSchoolModal,
      getSchools: getSchools,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolsManagementTab);
