import React from "react";
import { Modal } from "react-bootstrap";
import { IS_READY_COACH } from "../../../../../../constants";

type OwnProps = {
  showModal: boolean;
  onConfirm: () => any;
  onHide: () => any;
};

type Props = OwnProps;

class DiscardConfirmModal extends React.Component<Props> {
  render() {
    const { showModal } = this.props;
    return (
      <Modal
        show={showModal}
        onHide={this.props.onHide}
        animation={false}
        centered
        size="sm"
        backdropClassName="customDarkModalBackdrop in"
      >
        <Modal.Header
          closeButton
          className={
            IS_READY_COACH
              ? "purpleModalHeader font-weight-bold"
              : "orangeModalHeader font-weight-bold"
          }
        >
          Confirm Discard
        </Modal.Header>
        <Modal.Body>
          <div className="text-center m-3">
            <h4>The group will be removed from the list of recommendations.</h4>
          </div>
          <div className="text-center">
            <button className="blueBtnSm mr-2" onClick={this.props.onHide}>
              Cancel
            </button>
            <button className="blueBtnSm" onClick={this.props.onConfirm}>
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default DiscardConfirmModal;
