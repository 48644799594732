import { useMemo } from "react";
import { EvidenceType } from "../../store/onboarding/types";
import { toastr } from "react-redux-toastr";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { createDiagnosticInfoColumnRequest } from "../../store/onboarding/actions";

const useDiagnoseSkillIssues = () => {
  const dispatch = useDispatch();

  const evidencePeriodData = useSelector(
    (s: ApplicationState) => s.onboarding.evidencePeriodData
  );

  const ecg = useMemo(
    () =>
      evidencePeriodData
        ? evidencePeriodData.evidence_column_groups.find((ecg) =>
            ecg.evidence_columns.some(
              (ec) => ec.evidence_type === EvidenceType.DiagnosticInfo
            )
          )
        : undefined,
    [evidencePeriodData]
  );

  const onAdd = () => {
    if (ecg) {
      toastr.info(
        "Diagnostic Info",
        "You already have Diagnostic Info in this data period."
      );
    } else {
      (dispatch(createDiagnosticInfoColumnRequest()) as any)?.then(() =>
        toastr.success(
          "Diagnostic Info",
          "A new column has been added in this data period."
        )
      );
    }
  };

  return {
    ecg,
    onAdd,
  };
};

export default useDiagnoseSkillIssues;
