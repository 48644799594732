import React, { FunctionComponent, useMemo } from "react";
import { IS_READY_COACH } from "../../../../../../constants";
import PdfBtn from "./PdfBtn";
import {
  ConcernArea,
  Intervention,
} from "../../../../../../store/onboarding/cases/types";
import { useIntl } from "react-intl";
import useCreatedBy from "../../../../../../utils/hooks/useCreatedBy";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type OwnProps = {
  intervention: Intervention;
  onSelect: (intervention: Intervention) => any;
  isSelected?: boolean;
};

type Props = OwnProps;

const InterventionItem: FunctionComponent<Props> = ({
  intervention,
  onSelect,
  isSelected,
}) => {
  const intl = useIntl();

  const isAcademic = useMemo(
    () => intervention.concern_area === ConcernArea.ACADEMIC,
    [intervention.concern_area]
  );

  const { createdBy } = useCreatedBy(intervention.created_by_user);

  return (
    <div
      data-cy="intervention-item"
      className="interventionItem"
      onClick={() => onSelect(intervention)}
      key={intervention.id}
      style={isSelected ? { border: "2px solid limegreen" } : undefined}
    >
      {isSelected && (
        <div className="align-self-start ml-2 mt-2 color-limegreen">
          <FontAwesomeIcon icon={faCheckCircle} /> Added to Intervention Plan
        </div>
      )}
      <div className="interventionItemBody">
        <div className="interventionItemDescription">
          <div className="d-flex align-items-center">
            {IS_READY_COACH && (
              <div className="interventionItemIcon">
                {intervention.concern_area === 0 ? "A" : "B"}
              </div>
            )}
            <h3>{intervention.name}</h3>
          </div>
          <p className="mb-0">{intervention.description}</p>
        </div>
        {IS_READY_COACH &&
          ((!isAcademic && intervention.expected_change != undefined) ||
            intervention.concern_type != undefined ||
            intervention.target != undefined) && (
            <div
              className={`interventionItemDetails ${!IS_READY_COACH && "w-25"}`}
            >
              <h4 className="font-weight-bold">
                {intl.formatMessage({
                  id:
                    "app.dashboard.interventionLibraryTab.interventionItem.idealFor.title",
                })}
              </h4>
              <ul className="m-0">
                {!isAcademic && intervention.expected_change != undefined && (
                  <li className="font-weight-bold">
                    {intl.formatMessage(
                      {
                        id:
                          "app.dashboard.interventionLibraryTab.interventionItem.idealFor.expectedChange.label",
                      },
                      {
                        expectedChange: intervention.expected_change,
                      }
                    )}
                  </li>
                )}
                {intervention.concern_type != undefined && (
                  <li>
                    {intl.formatMessage(
                      {
                        id: isAcademic
                          ? "app.dashboard.interventionLibraryTab.interventionItem.idealFor.concernType.academic.label"
                          : "app.dashboard.interventionLibraryTab.interventionItem.idealFor.concernType.behavior.label",
                      },
                      {
                        concernType: intervention.concern_type,
                      }
                    )}
                  </li>
                )}
                {intervention.target != undefined && (
                  <li>
                    {intl.formatMessage(
                      {
                        id: "app.dashboard.interventions.filters.target.option",
                      },
                      {
                        target: intervention.target,
                      }
                    )}
                  </li>
                )}
              </ul>
            </div>
          )}
      </div>
      <div className="d-flex justify-content-between align-items-end ml-3">
        <div>
          <p className="m-0 py-2">
            {intl.formatMessage({
              id: "app.dashboard.interventions.filters.createdBy.label",
            })}
            &nbsp;
            <strong>{createdBy}</strong>
          </p>
        </div>
        <PdfBtn intervention={intervention} />
      </div>
    </div>
  );
};

export default InterventionItem;
