import React, { FunctionComponent, useMemo } from "react";
import { getFullName } from "../../../../../../utils/NamesUtils";
import {
  Fba,
  FbaModalTabs,
} from "../../../../../../store/onboarding/cases/types";
import { useDispatch } from "react-redux";
import {
  changeSelectedFba,
  setFbaModal,
} from "../../../../../../store/onboarding/cases/actions";

type Props = {
  fba: Fba;
};

const FbaGeneralInfo: FunctionComponent<Props> = (props) => {
  const { fba } = props;

  const dispatch = useDispatch();

  const abcsTotal = useMemo(() => {
    return fba.observations.flatMap((obs) => obs.observationabc).length;
  }, [fba.observations, fba.quick_log_observation]);
  const observationsTotal = useMemo(() => {
    return (
      (fba.quick_log_observation
        ? fba.observations.length - 1
        : fba.observations.length) ?? 0
    );
  }, [fba.observations, fba.quick_log_observation]);

  const handleFbaChartModalOpen = () => {
    dispatch(changeSelectedFba(fba));
    dispatch(setFbaModal(true, FbaModalTabs.CHART));
  };

  return (
    <div>
      <div className="mb-3">
        <strong>{getFullName(fba.student)}</strong>
        <p>{getFullName(fba.teacher)}</p>
      </div>
      <div className="mb-3">
        <p>
          <strong>{abcsTotal}</strong> Total ABCs
        </p>
        <p className="text-nowrap">
          <strong>{observationsTotal}</strong> Observation Session
        </p>
      </div>

      <a
        className="blueLink font-weight-bold text-underline"
        onClick={handleFbaChartModalOpen}
      >
        ABC Chart
      </a>
    </div>
  );
};

export default FbaGeneralInfo;
