import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

type OwnProps = {
  selectedValue: any;
  value: any;
  label: string;
  setValue: (newValue: any) => void;
};

type Props = OwnProps;

const InterventionDurationOption: FunctionComponent<Props> = ({
  value,
  label,
  selectedValue,
  setValue,
}) => {
  const active = selectedValue === value;

  return (
    <div
      onClick={() => setValue(value)}
      className={"widePill" + (active ? " active" : "")}
    >
      {active ? (
        <FontAwesomeIcon
          size="lg"
          icon={faDotCircle}
          style={{ color: "#673ab7" }}
          inverse={true}
        />
      ) : (
        <FontAwesomeIcon size="lg" icon={faCircle} />
      )}
      <div>
        <h4 className="font-weight-bold m-0">{label}</h4>
      </div>
    </div>
  );
};

export default InterventionDurationOption;
