import React, { FunctionComponent, useCallback } from "react";
import {
  changeSelectedInterventionGroup,
  deleteFba,
  openInterventionLibraryModal,
  showAnalyzeFbaResultsModal,
  showInterventionPlanModal,
} from "../../../../../../store/onboarding/cases/actions";
import { useDispatch } from "react-redux";
import { Fba } from "../../../../../../store/onboarding/cases/types";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddAbcBtn from "./AddAbcBtn";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";

type Props = {
  fba: Fba;
};

const FbaItemActions: FunctionComponent<Props> = (props) => {
  const { fba } = props;
  const dispatch = useDispatch();

  const handleFbaInterpret = useCallback(() => {
    dispatch(showAnalyzeFbaResultsModal(fba));
  }, [fba]);

  const handleConfirmDialogShow = useCallback(() => {
    const onConfirm = () => dispatch(deleteFba(fba.id));
    dispatch(showConfirmDialog({ onConfirm, centered: true }));
  }, [fba.id]);

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex flex-column justify-content-center">
        {fba.intervention_group?.student_goals.length ? (
          <button
            className="blueBtnMd"
            style={{ width: "12rem" }}
            onClick={() => {
              if (fba.intervention_group?.interventions.length) {
                dispatch(showInterventionPlanModal(fba.intervention_group));
              } else {
                dispatch(
                  changeSelectedInterventionGroup(fba.intervention_group)
                );
                dispatch(
                  openInterventionLibraryModal(undefined, undefined, {
                    onBack: () => {
                      dispatch(showInterventionPlanModal());
                    },
                  })
                );
              }
            }}
          >
            {fba.intervention_group.interventions.length
              ? "Open Group Workspace"
              : "Add Interventions"}
          </button>
        ) : (
          <>
            <button
              className="blueBtnMd my-2 text-nowrap"
              onClick={handleFbaInterpret}
              style={{ width: "12rem" }}
            >
              Analyze Results
            </button>
            <AddAbcBtn fba={fba} />
          </>
        )}
      </div>

      <span className="removeIcon mx-3" onClick={handleConfirmDialogShow}>
        <FontAwesomeIcon icon={faTimesCircle} size="2x" />
      </span>
    </div>
  );
};

export default FbaItemActions;
