import React, { FunctionComponent } from "react";
import { Dropdown, Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  openTeacherInviteIndividuallyModal,
  openUploadTeachersRosterModal,
} from "../../../../../store/onboarding/actions";

type OwnProps = {};

type Props = OwnProps;

const InviteEducatorDropDown: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const handleInviteIndividually = () => {
    dispatch(openTeacherInviteIndividuallyModal());
  };

  const handleUploadRoster = () => {
    dispatch(openUploadTeachersRosterModal());
  };

  return (
    <Nav.Item>
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-basic"
          variant="secondary"
          className="whiteDropdown"
        >
          INVITE EDUCATOR
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as="button" onClick={handleInviteIndividually}>
            Invite Individually
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={handleUploadRoster}>
            Upload Roster
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Nav.Item>
  );
};

export default InviteEducatorDropDown;
