import React, { FunctionComponent, useMemo, useState } from "react";
import {
  BehaviorTally,
  FbaBehavior,
  FbaBehaviorDisplayed,
  FbaObservation,
} from "../../../../../../store/onboarding/cases/types";
import _ from "lodash";
import BehaviorItem from "./BehaviorItem";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  onContinue: Function;
  observations: FbaObservation[];
  selectedBehTally?: BehaviorTally;
  setSelectedBehTally: React.Dispatch<
    React.SetStateAction<BehaviorTally | undefined>
  >;
};

const ListBehaviorsBody: FunctionComponent<Props> = (props) => {
  const {
    selectedBehTally,
    setSelectedBehTally,
    onContinue,
    observations,
  } = props;

  const behaviors = useMemo(() => {
    return _.chain(observations)
      .flatMap((obs) => obs.tally.behavior)
      .flatMap((bTally) =>
        bTally.behaviors.map((beh, index) => ({
          behavior: beh,
          count: bTally.count,
          other_behavior:
            beh === FbaBehavior.BEHAVIOR_OTHER
              ? bTally.other_behavior
              : undefined,
        }))
      )
      .groupBy((bTally) =>
        bTally.other_behavior
          ? `${bTally.behavior}:::${bTally.other_behavior}`
          : bTally.behavior
      )
      .values()
      .map((bTallies) => ({
        ...bTallies[0],
        count: _.reduce(bTallies, (sum, v) => sum + v.count, 0),
      }))
      .orderBy(["count"], ["desc"])
      .value();
  }, [observations]);

  const isUncompleted = !selectedBehTally;

  return (
    <div>
      <p className="text-center">
        Before we take a closer look at the data, let's identify the most
        pressing behavior. Usually, the problem behavior is the behavior most
        frequently observed during the ABC data collection. If the frequency
        counts are very close, feel free to consider other behavior aspects such
        as intensity.
      </p>

      <div className="review-abc-behaviors mb-3">
        <div>
          <h3>Behaviors</h3>
          <small>Check which one you'd like to focus on:</small>
        </div>
        <div>
          {behaviors.map((bTally, index) => (
            <BehaviorItem
              key={index}
              bTally={bTally}
              activeTally={selectedBehTally}
              onSelect={setSelectedBehTally}
            />
          ))}
        </div>
      </div>

      <div className="text-center">
        <OverlayTrigger
          trigger={isUncompleted ? ["hover", "focus"] : []}
          overlay={
            <Tooltip id="continue" className="customInfoTooltip">
              Please select a behavior.
            </Tooltip>
          }
        >
          <div style={{ display: "inline-block", cursor: "not-allowed" }}>
            <button
              className={`blueBtnSm ${
                isUncompleted ? "disabledWithPopover" : ""
              }`}
              onClick={() => onContinue()}
              disabled={isUncompleted}
            >
              Continue
            </button>
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default ListBehaviorsBody;
