import React, { FunctionComponent, useRef } from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { openTeacherInviteIndividuallyModal } from "../../../../../../store/onboarding/actions";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { TeacherInfo,CoachInfo } from "../../../../../../store/onboarding/types";
import { getBasicUserInfoFromString } from "../../../../../../utils/StudentMapUtils";
import { ActionMeta, ValueType } from "react-select/src/types";
import { UserInfo } from "../../../../../../store/auth/types";
import useUserRole from "../../../../../../utils/hooks/useUserRole";

type OwnProps = {
  isCoachSelector?:boolean;
  Interventionists: TeacherInfo[]|CoachInfo[];
  isTeacher: boolean|undefined;
  defaultInterventionistId?: number;
  onInterventionistChange: (
    value: ValueType<TeacherInfo, false>,
    action: ActionMeta<TeacherInfo>
  ) => void;
};

type Props = OwnProps;

const InterventionistSelector: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const { isCoach } = useUserRole()
  const { Interventionists, defaultInterventionistId, isTeacher, onInterventionistChange, isCoachSelector } = props;
  const loadingTeachersList = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.onboarding.isLoading.getTeachers);

  const handleInviteTeacher = (input: string) => {
    dispatch(
      openTeacherInviteIndividuallyModal(getBasicUserInfoFromString(input))
    );
  };



  let selectedValue = Interventionists.find(
    (x) => x.user?.id === defaultInterventionistId
  );

  if (isCoachSelector) {
    selectedValue = Interventionists.find(
      (x) => x.user.profile.current_assignment?.id === defaultInterventionistId
    );
  }
  const selectRef = useRef<any>();

  return (
    <div className="position-relative">
      {isCoach ? 
        <CreatableSelect
          id="interventionist-selector"
          ref={selectRef}
          isLoading={loadingTeachersList}
          className="dataFiltersSelect"
          value={selectedValue}
          options={Interventionists}
          noOptionsMessage={() =>
            (<button
              className="blueBtnSm"
              onClick={() => dispatch(openTeacherInviteIndividuallyModal())}
            >
              Invite Interventionist
            </button>) as any
          }
          getOptionLabel={({ user, value }: any) =>
            value ? `Invite '${value}'` : `${user.first_name} ${user.last_name}`
          }
          getOptionValue={({ user, value }: any) => value || user.id.toString()}
          onChange={onInterventionistChange}
          onCreateOption={handleInviteTeacher}
        />
      :
      <Select
        id="interventionist-selector"
        ref={selectRef}
        isLoading={loadingTeachersList}
        className="dataFiltersSelect"
        value={selectedValue}
        options={Interventionists}
        noOptionsMessage={() => Interventionists.length ? "No options" : "A coach has not yet been assigned to you. Please reach out to your District Admin to have a coach assigned to you."}
        getOptionLabel={({ user }: any) => `${user.first_name} ${user.last_name}`
        }
        getOptionValue={({ user }: any) => user.id.toString()}
        onChange={onInterventionistChange}
      />
      }
      <input
        tabIndex={-1}
        autoComplete="off"
        style={{
          opacity: 0,
          width: "100%",
          height: 0,
          position: "absolute",
        }}
        value={isCoachSelector? (selectedValue?.user?.id ?? undefined):(selectedValue?.user?.profile?.current_assignment?.id ?? undefined)}
        onChange={() => { }}
        onFocus={() => selectRef.current?.focus()}
        required
      />
    </div>
  );
};

export default InterventionistSelector;
