import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab } from "react-bootstrap";
import {
  getAllReleaseNotes,
} from "../../../store/superuser/actions";
import { RouteComponentProps, withRouter } from "react-router";
import ReleaseNotesDetail from "./ReleaseNotesDetail";
import ReleaseNotesTable from "./ReleaseNotesTable";

enum TabContent {
  TABLE = "table",
  DETAILS_READ = "details_read"
}

type MatchParams = {
  releaseNoteId?: string
};

type Props = RouteComponentProps<MatchParams>;

const ReleaseNotesTab: FunctionComponent<Props> = ({
  match: {
    params: { releaseNoteId },
  },
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllReleaseNotes());
  }, []);

  const activeKey = useMemo(() => {
    return releaseNoteId != null
      ? TabContent.DETAILS_READ
      : TabContent.TABLE;
  }, [releaseNoteId]);

  return (
    <Tab.Container
      defaultActiveKey={TabContent.TABLE}
      activeKey={activeKey}
      unmountOnExit
    >
      <Tab.Content>
        <Tab.Pane eventKey={TabContent.TABLE}>
          <ReleaseNotesTable />
        </Tab.Pane>
        <Tab.Pane eventKey={TabContent.DETAILS_READ}>
          <ReleaseNotesDetail />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

export default withRouter(ReleaseNotesTab);
