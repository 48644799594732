import React, { FunctionComponent } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

type OwnProps = {
  info: {
    description?: string;
    advantage?: string;
    disadvantage?: string;
  };
};

type Props = OwnProps;

const InfoButton: FunctionComponent<Props> = ({
  info: { description, advantage, disadvantage },
}) => {
  return (
    <OverlayTrigger
      placement={"bottom"}
      overlay={
        <Popover id={"info"}>
          <Popover.Content>
            {description && (
              <>
                <strong>Description:</strong> {description} <br />
              </>
            )}
            {advantage && (
              <>
                <strong>Advantage:</strong> {advantage} <br />
              </>
            )}
            {disadvantage && (
              <>
                <strong>Disadvantage:</strong> {disadvantage} <br />
              </>
            )}
          </Popover.Content>
        </Popover>
      }
    >
      <FontAwesomeIcon
        icon={faInfoCircle}
        size="sm"
        style={{ alignSelf: "start", marginLeft: "10px" }}
      />
    </OverlayTrigger>
  );
};

export default InfoButton;
