import React, { FunctionComponent, useState } from "react";
import LoadingIndicator from "../../LoadingIndicator";
import { Button, Nav, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import EditStudentForm from "./StudentEditForm";
import { getFullNameForStudent } from "../../../../../utils/StudentMapUtils";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  changeSelectedClass,
  changeSelectedStudent,
  openCreateClassModal,
  openStudentEditForm,
} from "../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import {
  Student,
  TeacherClass,
  TeacherInfo,
} from "../../../../../store/onboarding/types";
import { EventKey } from "react-bootstrap/esm/types";

type StateProps = {
  selectedTeacher?: TeacherInfo;
  teachersClasses: Array<TeacherClass>;
  teachersStudents: Array<Student>;
  isLoading: {
    getStudentsAssignedToTeacher: boolean;
    getClassesAssignedToTeacher: boolean;
  };
};

type DispatchProps = {
  openStudentEditForm: () => any;
  openCreateClassModal: () => any;
  changeSelectedStudent: (selectedStudent?: Student) => any;
  changeSelectedClass: (selectedClass?: TeacherClass) => any;
};

type Props = StateProps & DispatchProps;

enum TabKeys {
  FIRST = "classes",
  SECOND = "students",
}

const TeacherTab: FunctionComponent<Props> = ({
  selectedTeacher,
  teachersClasses,
  teachersStudents,
  isLoading,
  openCreateClassModal,
  changeSelectedStudent,
  changeSelectedClass,
  openStudentEditForm,
}) => {
  const [activeTab, setActiveTab] = useState<string | null>(TabKeys.SECOND);

  if (
    isLoading.getStudentsAssignedToTeacher ||
    isLoading.getClassesAssignedToTeacher
  ) {
    return <LoadingIndicator />;
  }

  const handleSelectedStudentSelect = (student: Student) => () => {
    changeSelectedStudent(student);
    openStudentEditForm();
  };

  const handleEditStudentFormOpen = () => {
    changeSelectedStudent();
    openStudentEditForm();
  };

  const handleEditClassModalOpen = (teachersClass: TeacherClass) => () => {
    changeSelectedClass(teachersClass);
    openCreateClassModal();
  };

  return (
    <Tab.Container activeKey={activeTab as EventKey} onSelect={setActiveTab}>
      <div className="d-flex justify-content-between align-items-center">
        <Nav className="d-inline">
          {/* <Nav.Item className="teacherDetailsTab">
            <Nav.Link eventKey={TabKeys.FIRST}>
              {`Classes (${teachersClasses.length})`}
            </Nav.Link>
          </Nav.Item> */}
          <Nav.Item className="teacherDetailsTab">
            <Nav.Link eventKey={TabKeys.SECOND}>
              {`Students (${teachersStudents.length})`}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {activeTab === TabKeys.FIRST ? (
          teachersClasses.length > 0 && (
            <Button onClick={openCreateClassModal} title="Add Class">
              <FontAwesomeIcon icon={faPlusCircle} className="pointer" />
            </Button>
          )
        ) : (
          <>
            <Button onClick={handleEditStudentFormOpen} title="Add Student">
              <FontAwesomeIcon icon={faPlusCircle} className="pointer" />
            </Button>

            <EditStudentForm />
          </>
        )}
      </div>

      <Tab.Content>
        {/* <Tab.Pane eventKey={TabKeys.FIRST}>
          {teachersClasses.length > 0 ? (
            <div
              style={{
                marginTop: 10,
                maxHeight: 250,
                overflowY: "scroll",
              }}
            >
              {teachersClasses.map((teachersClass, index) => (
                <div key={index}>
                  <div>
                    <strong className="d-inline">{`${teachersClass.name} (${teachersClass.students.length})`}</strong>{" "}
                    <div
                      className="d-inline"
                      onClick={handleEditClassModalOpen(teachersClass)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} className="pointer" />
                    </div>
                  </div>
                  <p>
                    {teachersClass.students
                      .map((student) => getFullNameForStudent(student))
                      .join(", ")}
                  </p>
                  <hr className="narrowMargin" />
                </div>
              ))}
            </div>
          ) : (
            <div className="classSetupContainerEmpty">
              <div>
                <h4 className="mb-3">{`${selectedTeacher?.user.first_name} doesn't have any classes set up yet`}</h4>
                <Button
                  onClick={openCreateClassModal}
                  className="blueBtnSm"
                  bsPrefix="b"
                >
                  Set up a class
                </Button>
              </div>
            </div>
          )}
        </Tab.Pane> */}
        <Tab.Pane eventKey={TabKeys.SECOND}>
          {teachersStudents.length > 0 ? (
            <div
              style={{
                maxHeight: 250,
                overflowY: "scroll",
                marginTop: 10,
              }}
            >
              <div className="onboardingSummaryStepContainer">
                {teachersStudents.map((student) => (
                  <p
                    key={student.unique_id}
                    onClick={handleSelectedStudentSelect(student)}
                  >
                    {getFullNameForStudent(student)}{" "}
                    <FontAwesomeIcon icon={faPencilAlt} className="pointer" />
                  </p>
                ))}
              </div>
            </div>
          ) : (
            <div className="classSetupContainerEmpty">
              <div>
                <h4 className="mb-3">{`${selectedTeacher?.user.first_name} doesn't have any associated students yet`}</h4>
                <Button
                  onClick={handleEditStudentFormOpen}
                  className="blueBtnSm"
                  bsPrefix="b"
                >
                  Add a Student
                </Button>
              </div>
            </div>
          )}
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    selectedTeacher: onboarding.selectedTeacher,
    teachersClasses: onboarding.teachersClasses,
    teachersStudents: onboarding.teachersStudents,
    isLoading: {
      getClassesAssignedToTeacher:
        onboarding.isLoading.getClassesAssignedToTeacher,
      getStudentsAssignedToTeacher:
        onboarding.isLoading.getStudentsAssignedToTeacher,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      changeSelectedStudent: changeSelectedStudent,
      changeSelectedClass: changeSelectedClass,
      openStudentEditForm: openStudentEditForm,
      openCreateClassModal: openCreateClassModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherTab);
