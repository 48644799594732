import React, { FunctionComponent, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNewFba } from "../../../../../store/onboarding/cases/actions";
import AddStudentOverlay from "../../../../common/reading-interventions/intervention-tools/recommend-groups/group-card/AddStudentOverlay";
import { Student } from "../../../../../store/onboarding/types";
import { useLoading } from "../../../../../utils/hooks/useLoading";
import { ApplicationState } from "../../../../../store";
import { UserInfo } from "../../../../../store/auth/types";

type OwnProps = {
  onComplete?: Function;
};

type Props = OwnProps;

const NewDataCollectionPopover: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const { onComplete } = props;

  const onAdd = (student: Student, teacher?: UserInfo) => {
    dispatch(createNewFba(student.id!, teacher!.id));
  };

  const target = useRef<any>();
  const [show, setShow] = useState<boolean>(false);

  const loading = useSelector(
    (s: ApplicationState) => s.cases.isLoading.createFba
  );
  const error = useSelector((s: ApplicationState) => s.cases.errors.createFba);
  useLoading(loading, error, () => {
    setShow(false);
    onComplete && onComplete();
  });

  return (
    <>
      {show && <div className="customDarkPopoverBackdrop" />}
      <AddStudentOverlay
        target={target}
        show={show}
        setShow={setShow}
        selectedStudents={[]}
        onAdd={onAdd}
        title={"Choose a student to observe"}
        btnText={"Create ABC Data Collection"}
        placement="left"
        loading={loading}
        withTeacher
      />
      <button
        ref={target}
        className="blueBtnMd text-nowrap"
        onClick={() => setShow((f) => !f)}
      >
        Collect Data for New Student
      </button>
    </>
  );
};

export default NewDataCollectionPopover;
