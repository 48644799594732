import React, { Component, useCallback, useEffect, useMemo } from "react";
import {
  InviteStatuses,
  RenewedTeacherInfo,
  Student,
  TeacherClass,
  TeacherInfo,
} from "../../../../../store/onboarding/types";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import CreateClassModal from "../../../../../containers/onboarding/second-step/teacher-details/CreateClassModal";
import UploadStudentsRosterToTeacherModal from "../../../../../containers/onboarding/second-step/students/UploadStudentsRosterToTeacherModal";
import { IS_READY_COACH } from "../../../../../constants";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  assignStudentsToTeacher,
  changeSelectedTeacher,
  getClassesAssignedToTeacher,
  getStudentsAssignedToTeacher,
  getTeachers,
  hideTeacherDetailsModal,
  setShowDisabledUsers,
  updateTeacher,
} from "../../../../../store/onboarding/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import TeacherTab from "./TeacherTab";
import TeacherInfoForm from "./TeacherInfoForm";
import { UserAssignmentRole } from "../../../../../store/auth/types";
import EducatorsManagementTab from "./EducatorsManagementTab";
import { useLoading } from "../../../../../utils/hooks/useLoading";
import { getFullName } from "../../../../../utils/NamesUtils";
import useUserRole from "../../../../../utils/hooks/useUserRole";

type PropsFromState = {
  selectedTeacher?: TeacherInfo;
  teachersClasses: Array<TeacherClass>;
  teachersStudents: Array<Student>;
  isLoading: {
    assignClassToTeacher: boolean;
    updateTeacherInfo: boolean;
    updateTeacherClass: boolean;
    assignStudentsToTeacher: boolean;
  };
  errors?: {
    assignClassToTeacher?: string;
    getClassesAssignedToTeacher?: string;
    getStudentsAssignedToTeacher?: string;
    updateTeacherInfo?: string;
    updateTeacherClass?: string;
    assignStudentsToTeacher?: string;
  };
  showModal: boolean;
};

type DispatchProps = {
  getClassesAssignedToTeacher: (id: number) => any;
  getStudentsAssignedToTeacher: (id: number) => any;
  assignStudentsToTeacher: (id: number, students: Array<Student>) => any;
  getTeachers: (withDisabled?: boolean) => any;
  updateTeacher: (id: number, updatedInfo: RenewedTeacherInfo) => any;
  changeSelectedTeacher: (selectedTeacher?: TeacherInfo) => any;
  hideTeacherDetailsModal: () => any;
  setShowDisabledUsers: (showDisabledUsers: boolean) => any;
};
type OwnProps = {
  fromManageUsers?: boolean;
}
type Props = PropsFromState & DispatchProps & OwnProps;

const TeacherDetailsModal: React.FC<Props> = (props) => {
  const { showModal, selectedTeacher, fromManageUsers } = props;

  const reloadData = () => {
    if (selectedTeacher) {
      props.getClassesAssignedToTeacher(selectedTeacher.user.id);
      props.getStudentsAssignedToTeacher(selectedTeacher.user.id);
    }
  };

  useEffect(() => {
    reloadData();
  }, [selectedTeacher]);

  useLoading(
    props.isLoading.assignClassToTeacher,
    props.errors?.assignClassToTeacher,
    reloadData
  );
  useLoading(
    props.isLoading.updateTeacherClass,
    props.errors?.updateTeacherClass,
    reloadData
  );

  const showDisabledUsers = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.onboarding.showDisabledUsers
  );
  const onUpdateInfoSuccess = useCallback(() => {
    props.changeSelectedTeacher(undefined);
    if(!fromManageUsers) { //RC-1457
      props.getTeachers();
    }
  }, []);
  useLoading(
    props.isLoading.updateTeacherInfo,
    props.errors?.updateTeacherInfo,
    onUpdateInfoSuccess
  );


  const handleHide = () => {
    if (!props.isLoading.updateTeacherInfo) {
      props.changeSelectedTeacher(undefined);
      props.getTeachers(showDisabledUsers);
      if (!IS_READY_COACH) {
        props.hideTeacherDetailsModal();
      }
    }
  };

  const handleSubmit = (renewedTeacherInfo: RenewedTeacherInfo) => (
    event: React.FormEvent
  ) => {
    event.preventDefault();
    //const selectedTeacher = props.selectedTeacher;
    if (selectedTeacher) {
      props.updateTeacher(selectedTeacher.user.id, renewedTeacherInfo);
      if(fromManageUsers) { //RC-1457
        props.setShowDisabledUsers(showDisabledUsers!);
        props.getTeachers(showDisabledUsers);
      }
    }
  };

  const infoByTargetRole = useMemo(() => {
    switch (selectedTeacher?.user.profile.current_assignment?.role) {
      case UserAssignmentRole.TEACHER:
        return "In the meantime, you can roster students and set up classes.";
      case UserAssignmentRole.COACH:
      case UserAssignmentRole.SUPERVISOR:
        return "In the meantime, you can assign educators below.";
      default:
        return null;
    }
  }, [selectedTeacher?.user.profile.current_assignment?.role]);

  const getTabByTargetRole = useMemo(() => {
    switch (selectedTeacher?.user.profile.current_assignment?.role) {
      case UserAssignmentRole.COACH:
      case UserAssignmentRole.SUPERVISOR:
        return <EducatorsManagementTab />;
      case UserAssignmentRole.TEACHER:
      default:
        return <TeacherTab />;
    }
  }, [selectedTeacher?.user.profile.current_assignment?.role]);

  const inviteIsAccepted = useMemo(() => {
    return selectedTeacher && selectedTeacher.invite
      ? selectedTeacher.invite.status === InviteStatuses.INVITE_ACCEPTED
      : false;
  }, [selectedTeacher]);

  const fullName = useMemo(() => {
    return getFullName(selectedTeacher?.user) ?? "User";
  }, [selectedTeacher?.user]);

  const { isHighLevelRole } = useUserRole();

  const youCanMessage = useMemo(() => {
    if (!selectedTeacher) {
      return null;
    }

    if (isHighLevelRole) {
      return `You can edit ${fullName}'s information
                below if it's incorrect.`;
    }

    return selectedTeacher.user.profile.is_active
      ? `You can't edit ${fullName}'s information. He/she able to manage it.`
      : `You can edit ${fullName}'s information
                below if it's incorrect. He/she will be able to manage it after
                accepting his/her invite.`;
  }, [fullName, isHighLevelRole, selectedTeacher?.user.profile.is_active]);

  return selectedTeacher ? (
    <>
      <Modal
        animation={false}
        backdropClassName="customDarkModalBackdrop in"
        show={showModal}
        onHide={handleHide}
        size="lg"
      >
        <Modal.Header
          closeButton
          className="purpleModalHeader orangeModalHeader"
        >
          <Modal.Title>{`${fullName}'s Details`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className="font-weight-bold">{`${fullName} ${
            inviteIsAccepted
              ? "accepted the invite"
              : "hasn't accepted the invite yet."
          }`}</h2>
          <h4>{youCanMessage}</h4>
          <h4>{infoByTargetRole}</h4>
          <br />
          <Row>
            <Col md>
              <div className="pt-4 mt-3">
                <TeacherInfoForm
                  handleSubmit={handleSubmit}
                  isHighLevelRole={isHighLevelRole}
                />
              </div>
            </Col>
            <Col md>{getTabByTargetRole}</Col>
          </Row>
          <div className="text-center mt-3">
            <button className="blueBtnLg" type="submit" form="teacherInfoForm">
              Save and close{" "}
              {props.isLoading.updateTeacherInfo && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <UploadStudentsRosterToTeacherModal />
      <CreateClassModal />
    </>
  ) : null;
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  const selectedTeacher = onboarding.selectedTeacher;
  const assignStudentsToTeacherLoading = selectedTeacher
    ? onboarding.isLoading.assignStudentsToTeacher.includes(
        selectedTeacher.user.id
      )
    : false;
  let assignStudentsToTeacherError;
  if (selectedTeacher) {
    const error = onboarding.errors.assignStudentsToTeacher.find(
      (x) => x.teacherId === selectedTeacher.user.id
    );
    if (error) {
      assignStudentsToTeacherError = error.message;
    }
  }
  return {
    showModal: onboarding.modalsState.teacherDetailModal,
    selectedTeacher: selectedTeacher,
    teachersClasses: onboarding.teachersClasses,
    teachersStudents: onboarding.teachersStudents,
    isLoading: {
      assignClassToTeacher: onboarding.isLoading.assignClassToTeacher,
      updateTeacherInfo: onboarding.isLoading.updateTeacherInfo,
      updateTeacherClass: onboarding.isLoading.updateTeacherClass,
      assignStudentsToTeacher:
        assignStudentsToTeacherLoading ||
        onboarding.isLoading.updateStudentDetails,
    },
    errors: {
      assignClassToTeacher: onboarding.errors.assignClassToTeacher,
      getClassesAssignedToTeacher:
        onboarding.errors.getClassesAssignedToTeacher,
      getStudentsAssignedToTeacher:
        onboarding.errors.getStudentsAssignedToTeacher,
      updateTeacherInfo: onboarding.errors.updateTeacherInfo,
      updateTeacherClass: onboarding.errors.updateTeacherClass,
      assignStudentsToTeacher: assignStudentsToTeacherError,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      getClassesAssignedToTeacher: getClassesAssignedToTeacher,
      getStudentsAssignedToTeacher: getStudentsAssignedToTeacher,
      assignStudentsToTeacher: assignStudentsToTeacher,
      getTeachers: getTeachers,
      updateTeacher: updateTeacher,
      changeSelectedTeacher: changeSelectedTeacher,
      hideTeacherDetailsModal: hideTeacherDetailsModal,
      setShowDisabledUsers: setShowDisabledUsers
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeacherDetailsModal);
