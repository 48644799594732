import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { TeacherInfo } from "../../../../../store/onboarding/types";
import { Button, Nav, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  getDataSharingTableData,
  getDistrictRelationships,
  hideTeacherDetailsModal,
  openAssignEducatorsToEmployeeModal,
  openDataSharingEditModal,
} from "../../../../../store/onboarding/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { getFullName } from "../../../../../utils/NamesUtils";
import EducatorListItem from "./EducatorListItem";
import {
  StaffRelationship,
  UserAssignmentRole,
} from "../../../../../store/auth/types";
import { useLoading } from "../../../../../utils/hooks/useLoading";
import DataSharingEditModal from "../../../reading-interventions/educators-and-sharing/modals/DataSharingEditModal";
import LoadingIndicator from "../../LoadingIndicator";
import { EventKey } from "react-bootstrap/esm/types";

type StateProps = {
  selectedEmployee?: TeacherInfo;
  districtRelationships: Array<StaffRelationship>;
  showModal: boolean;
  isLoading: {
    getDistrictRelationships?: boolean;
  };
  // currentEducators: Array<TeacherInfo>;
  // previousEducators: Array<TeacherInfo>;
};

type DispatchProps = {
  hideTeacherDetailsModal: () => any;
  openAssignEducatorsToCoachModal: () => any;
  getDataSharingTableData: (userId: number) => any;
  getDistrictRelationships: (
    role: UserAssignmentRole.COACH | UserAssignmentRole.SUPERVISOR,
    userId: number
  ) => any;
};

type Props = StateProps & DispatchProps;

enum TabKeys {
  FIRST = "current_educators",
  SECOND = "previous_educators",
}

const EducatorsManagementTab: FunctionComponent<Props> = ({
  districtRelationships,
  selectedEmployee,
  showModal,
  isLoading,
  hideTeacherDetailsModal,
  getDistrictRelationships,
 // getDataSharingTableData,
  openAssignEducatorsToCoachModal,
}) => {
  const [activeTab, setActiveTab] = useState<string | null>(TabKeys.FIRST);
  const dispatch = useDispatch();
  
  const [selectedUser, setSelectedUser] = useState<any>();

  const handleEditRow = async (esd: any) => {
     await setSelectedUser(esd);
     dispatch(openDataSharingEditModal());
  };

  useEffect(() => {
    if (
      selectedEmployee &&
      selectedEmployee.user.profile.current_assignment?.role &&
      [UserAssignmentRole.COACH, UserAssignmentRole.SUPERVISOR].includes(
        selectedEmployee.user.profile.current_assignment.role
      )
    ) {
      getDistrictRelationships(
        selectedEmployee.user.profile.current_assignment.role as
          | UserAssignmentRole.COACH
          | UserAssignmentRole.SUPERVISOR,
        selectedEmployee.user.id
      );
    }
   // if(selectedEmployee) getDataSharingTableData(selectedEmployee.user.id)
  }, [selectedEmployee]);

  const handleAssignEducators = () => {
    hideTeacherDetailsModal();
    openAssignEducatorsToCoachModal();
  };

  const { currentEducators, previousEducators } = useMemo(() => {
    return districtRelationships.reduce(
      (pV, cV) => {
        if (cV.is_active) {
          return {
            ...pV,
            currentEducators: [...pV.currentEducators, cV],
          };
        } else {
          return {
            ...pV,
            previousEducators: [...pV.previousEducators, cV],
          };
        }
      },
      {
        currentEducators: [] as Array<StaffRelationship>,
        previousEducators: [] as Array<StaffRelationship>,
      }
    );
  }, [districtRelationships]);

  return (
    <Tab.Container activeKey={activeTab as EventKey} onSelect={setActiveTab}>
      <div className="d-flex justify-content-between align-items-center">
        <Nav className="d-inline">
          <Nav.Item className="teacherDetailsTab">
            <Nav.Link eventKey={TabKeys.FIRST}>Current Educators</Nav.Link>
          </Nav.Item>
          <Nav.Item className="teacherDetailsTab">
            <Nav.Link eventKey={TabKeys.SECOND}>Previous Educators</Nav.Link>
          </Nav.Item>
        </Nav>
        {activeTab === TabKeys.FIRST && currentEducators.length > 0 && (
          <Button onClick={handleAssignEducators} title="Assign Teacher">
            <FontAwesomeIcon icon={faPlusCircle} className="pointer" />
          </Button>
        )}
      </div>

      <Tab.Content>
        <Tab.Pane eventKey={TabKeys.FIRST}>
          {isLoading.getDistrictRelationships ? (
            <LoadingIndicator />
          ) : currentEducators.length ? (
            <div
              style={{
                marginTop: 10,
                maxHeight: 250,
                overflowY: "scroll",
              }}
            >
              <div className="onboardingSummaryStepContainer">
                { currentEducators.map((educator, index) => (
                  <EducatorListItem
                    educator={educator.child.user}
                    eductorStaffRelation = {educator}
                    key={index}
                    onHandleEditRow={handleEditRow}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="classSetupContainerEmpty">
              <div>
                <h4 className="mb-3">{`${selectedEmployee?.user.first_name} hasn't been assigned any educators yet`}</h4>
                <button onClick={handleAssignEducators} className="blueBtnSm">
                  Assign an educator
                </button>
              </div>
            </div>
          )}
          {(showModal && (selectedUser != undefined)) ?
             <DataSharingEditModal selectedUser={selectedUser} /> 
          : ''}
        </Tab.Pane>
        <Tab.Pane eventKey={TabKeys.SECOND}>
          {previousEducators.length > 0 ? (
            <div
              style={{
                maxHeight: 250,
                overflowY: "scroll",
                marginTop: 10,
              }}
            >
              <div className="onboardingSummaryStepContainer">
                {previousEducators.map((educator) => (
                  <p key={educator.id}>{getFullName(educator.child.user)}</p>
                ))}
              </div>
            </div>
          ) : (
            <div className="classSetupContainerEmpty">
              <h4>{`${selectedEmployee?.user.first_name} hasn't had previous educators`}</h4>
            </div>
          )}
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    selectedEmployee: onboarding.selectedTeacher,
    districtRelationships: onboarding.districtRelationships,
    showModal: onboarding.modalsState.dataSharingEditModal,
    isLoading: {
      getDistrictRelationships: onboarding.isLoading.getDistrictRelationships
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hideTeacherDetailsModal: hideTeacherDetailsModal,
      openAssignEducatorsToCoachModal: openAssignEducatorsToEmployeeModal,
      getDistrictRelationships: getDistrictRelationships,
      getDataSharingTableData: getDataSharingTableData
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EducatorsManagementTab);
