import { ActionType, getType } from "typesafe-actions";

import * as meetingModuleActions from "./actions";
import { MeetingModuleActionTypes, MeetingModuleState } from "./types";
import { Reducer } from "redux";

export type MeetingModuleAction = ActionType<typeof meetingModuleActions>;

const initialState: MeetingModuleState = {
  selectedPraise: undefined,
  selectedMeetingOld: undefined,
  meetings: [],
  prepMeetings: [],
  supportMeetings: [],
  feedbackMeetings: [],
  praises: [],

  modalsState: {
    prepareInterventionModal: false,
    raiseEducatorModal: false,
    interventionsBriefModal: false,
    showOnlyMaterials: false,
    supportInterventionModal: false,
    providingFeedbackModal: false,
    meetingPromptModal: false,
    supportImplFeedbackModal: false,
    implementationCheckModal: false,
    goalProgressModal: false,
    endInterventionModal: false,
    modifyInterventionModal: false,
  },
  isLoading: {
    getMeetings: false,
    createMeeting: false,
    updateMeeting: false,

    getPrepMeetings: false,
    createPrepMeeting: false,
    updatePrepMeeting: false,

    getFeedbackMeetings: false,
    createFeedbackMeeting: false,
    updateFeedbackMeeting: false,

    getSupportMeetings: false,
    createSupportMeeting: false,
    updateSupportMeeting: false,

    getProgressOverviewData: false,
    logProgressTowardsGoal: false,
    logIntervention: false,
  },
  errors: {},
};

const reducer: Reducer<MeetingModuleState> = (
  state: MeetingModuleState = initialState,
  action: MeetingModuleAction
): MeetingModuleState => {
  switch (action.type) {
    case MeetingModuleActionTypes.OPEN_PREPARE_INTERVENTION_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          prepareInterventionModal: true,
        },
        selectedMeetingOld: undefined,
      };
    case MeetingModuleActionTypes.HIDE_PREPARE_INTERVENTION_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          prepareInterventionModal: false,
        },
      };

    case MeetingModuleActionTypes.OPEN_PRAISE_EDUCATOR_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          raiseEducatorModal: true,
        },
      };
    case MeetingModuleActionTypes.HIDE_PRAISE_EDUCATOR_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          raiseEducatorModal: false,
        },
      };

    case MeetingModuleActionTypes.OPEN_INTERVENTIONS_RESOURCES_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          interventionsBriefModal: true,
          showOnlyMaterials: action.payload.showOnlyMaterials,
        },
      };
    case MeetingModuleActionTypes.HIDE_INTERVENTIONS_RESOURCES_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          interventionsBriefModal: false,
          showOnlyMaterials: false,
        },
      };

    case MeetingModuleActionTypes.OPEN_SUPPORT_INTERVENTION_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          supportInterventionModal: true,
        },
      };
    case MeetingModuleActionTypes.HIDE_SUPPORT_INTERVENTION_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          supportInterventionModal: false,
        },
      };

    case MeetingModuleActionTypes.OPEN_PROVIDING_FEEDBACK_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          providingFeedbackModal: true,
        },
      };
    case MeetingModuleActionTypes.HIDE_PROVIDING_FEEDBACK_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          providingFeedbackModal: false,
          meetingPromptModal: false,
        },
      };

    case MeetingModuleActionTypes.OPEN_MEETING_PROMPT_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          meetingPromptModal: true,
        },
      };
    case MeetingModuleActionTypes.HIDE_MEETING_PROMPT_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          meetingPromptModal: false,
        },
      };

    case MeetingModuleActionTypes.OPEN_SUPPORT_IMPL_FEEDBACK_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          supportImplFeedbackModal: true,
        },
      };
    case MeetingModuleActionTypes.HIDE_SUPPORT_IMPL_FEEDBACK_MODAL_OPEN:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          supportImplFeedbackModal: false,
        },
      };

    case MeetingModuleActionTypes.OPEN_IMPLEMENTATION_CHECK_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          implementationCheckModal: true,
          implementationCheckModalCallbacks: action.payload,
        },
      };
    case MeetingModuleActionTypes.HIDE_IMPLEMENTATION_CHECK_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          implementationCheckModal: false,
        },
      };

    case MeetingModuleActionTypes.OPEN_GOAL_PROGRESS_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          goalProgressModal: true,
          goalProgressModalCallbacks: action.payload,
        },
      };
    case MeetingModuleActionTypes.HIDE_GOAL_PROGRESS_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          goalProgressModal: false,
          goalProgressModalCallbacks: undefined,
        },
      };

    case MeetingModuleActionTypes.OPEN_MODIFY_INTERVENTION_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          modifyInterventionModal: true,
        },
      };
    case MeetingModuleActionTypes.HIDE_MODIFY_INTERVENTION_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          modifyInterventionModal: false,
        },
      };

    case MeetingModuleActionTypes.OPEN_END_INTERVENTION_MODAL:
      return {
        ...state,
        preselectedStudentGoal: action.payload.preselectedStudentGoal,
        modalsState: {
          ...state.modalsState,
          endInterventionModal: true,
        },
      };
    case MeetingModuleActionTypes.HIDE_END_INTERVENTION_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          endInterventionModal: false,
        },
      };

    case MeetingModuleActionTypes.OPEN_INTERVENTION_FIDELITY_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          interventionFidelityModal: true,
        },
      };
    case MeetingModuleActionTypes.HIDE_INTERVENTION_FIDELITY_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          interventionFidelityModal: false,
        },
      };

    case MeetingModuleActionTypes.CHANGE_SELECTED_MEETING:
      return {
        ...state,
        selectedMeeting: action.payload.meeting,
      };

    case MeetingModuleActionTypes.GET_MEETINGS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getMeetings: true,
        },
      };
    case MeetingModuleActionTypes.GET_MEETINGS_SUCCESS:
      return {
        ...state,
        meetings: action.payload.meetings,
        isLoading: {
          ...state.isLoading,
          getMeetings: false,
        },
        errors: {
          ...state.errors,
          getMeetings: undefined,
        },
      };
    case MeetingModuleActionTypes.GET_MEETINGS_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getMeetings: false,
        },
        errors: {
          ...state.errors,
          getMeetings: action.payload.error,
        },
      };

    case MeetingModuleActionTypes.CREATE_MEETING_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createMeeting: true,
        },
      };
    case MeetingModuleActionTypes.CREATE_MEETING_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createMeeting: false,
        },
        errors: {
          ...state.errors,
          createMeeting: undefined,
        },
        meetings: [...state.meetings, action.payload.meeting],
      };
    case MeetingModuleActionTypes.CREATE_MEETING_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createMeeting: false,
        },
        errors: {
          ...state.errors,
          createMeeting: action.payload.error,
        },
      };

    case MeetingModuleActionTypes.UPDATE_MEETING_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateMeeting: true,
        },
      };
    case MeetingModuleActionTypes.UPDATE_MEETING_SUCCESS:
      return {
        ...state,
        meetings: state.meetings.map((meeting) => {
          if (meeting.id === action.payload.meeting.id) {
            return action.payload.meeting;
          }
          return meeting;
        }),
        isLoading: {
          ...state.isLoading,
          updateMeeting: false,
        },
        errors: {
          ...state.errors,
          updateMeeting: undefined,
        },
      };
    case MeetingModuleActionTypes.UPDATE_MEETING_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateMeeting: false,
        },
        errors: {
          ...state.errors,
          updateMeeting: action.payload.error,
        },
      };

    case MeetingModuleActionTypes.GET_PREP_MEETINGS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getPrepMeetings: true,
        },
      };
    case MeetingModuleActionTypes.GET_PREP_MEETINGS_SUCCESS:
      return {
        ...state,
        prepMeetings: action.payload.prepMeetings,
        isLoading: {
          ...state.isLoading,
          getPrepMeetings: false,
        },
        errors: {
          ...state.errors,
          getPrepMeetings: undefined,
        },
      };
    case MeetingModuleActionTypes.GET_PREP_MEETINGS_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getPrepMeetings: false,
        },
        errors: {
          ...state.errors,
          getPrepMeetings: action.payload.error,
        },
      };

    case MeetingModuleActionTypes.CREATE_PREP_MEETING_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createPrepMeeting: true,
        },
      };
    case MeetingModuleActionTypes.CREATE_PREP_MEETING_SUCCESS:
      return {
        ...state,
        selectedMeetingOld: action.payload.meeting,
        isLoading: {
          ...state.isLoading,
          createPrepMeeting: false,
        },
        errors: {
          ...state.errors,
          createPrepMeeting: undefined,
        },
      };
    case MeetingModuleActionTypes.CREATE_PREP_MEETING_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createPrepMeeting: false,
        },
        errors: {
          ...state.errors,
          createPrepMeeting: action.payload.error,
        },
      };

    case MeetingModuleActionTypes.UPDATE_PREP_MEETING_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updatePrepMeeting: true,
        },
      };
    case MeetingModuleActionTypes.UPDATE_PREP_MEETING_SUCCESS:
      return {
        ...state,
        prepMeetings: state.prepMeetings.map((meeting) => {
          if (meeting.id === action.payload.meeting.id) {
            return action.payload.meeting;
          }
          return meeting;
        }),
        isLoading: {
          ...state.isLoading,
          updatePrepMeeting: false,
        },
        errors: {
          ...state.errors,
          updatePrepMeeting: undefined,
        },
      };
    case MeetingModuleActionTypes.UPDATE_PREP_MEETING_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updatePrepMeeting: false,
        },
        errors: {
          ...state.errors,
          updatePrepMeeting: action.payload.error,
        },
      };

    case MeetingModuleActionTypes.GET_FEEDBACK_MEETINGS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getFeedbackMeetings: true,
        },
      };
    case MeetingModuleActionTypes.GET_FEEDBACK_MEETINGS_SUCCESS:
      return {
        ...state,
        feedbackMeetings: action.payload.feedbackMeetings,
        isLoading: {
          ...state.isLoading,
          getFeedbackMeetings: false,
        },
        errors: {
          ...state.errors,
          getFeedbackMeetings: undefined,
        },
      };
    case MeetingModuleActionTypes.GET_FEEDBACK_MEETINGS_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getFeedbackMeetings: false,
        },
        errors: {
          ...state.errors,
          getFeedbackMeetings: action.payload.error,
        },
      };

    case MeetingModuleActionTypes.CREATE_FEEDBACK_MEETING_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createFeedbackMeeting: true,
        },
      };
    case MeetingModuleActionTypes.CREATE_FEEDBACK_MEETING_SUCCESS:
      return {
        ...state,
        selectedMeetingOld: action.payload.meeting,
        isLoading: {
          ...state.isLoading,
          createFeedbackMeeting: false,
        },
        errors: {
          ...state.errors,
          createFeedbackMeeting: undefined,
        },
      };
    case MeetingModuleActionTypes.CREATE_FEEDBACK_MEETING_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createFeedbackMeeting: false,
        },
        errors: {
          ...state.errors,
          createFeedbackMeeting: action.payload.error,
        },
      };

    case MeetingModuleActionTypes.UPDATE_FEEDBACK_MEETING_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateFeedbackMeeting: true,
        },
      };
    case MeetingModuleActionTypes.UPDATE_FEEDBACK_MEETING_SUCCESS:
      return {
        ...state,
        feedbackMeetings: state.feedbackMeetings.map((meeting) => {
          if (meeting.id === action.payload.meeting.id) {
            return action.payload.meeting;
          }
          return meeting;
        }),
        isLoading: {
          ...state.isLoading,
          updateFeedbackMeeting: false,
        },
        errors: {
          ...state.errors,
          updateFeedbackMeeting: undefined,
        },
      };
    case MeetingModuleActionTypes.UPDATE_FEEDBACK_MEETING_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateFeedbackMeeting: false,
        },
        errors: {
          ...state.errors,
          updateFeedbackMeeting: action.payload.error,
        },
      };

    case MeetingModuleActionTypes.GET_SUPPORT_MEETINGS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSupportMeetings: true,
        },
      };
    case MeetingModuleActionTypes.GET_SUPPORT_MEETINGS_SUCCESS:
      return {
        ...state,
        supportMeetings: action.payload.supportMeetings,
        isLoading: {
          ...state.isLoading,
          getSupportMeetings: false,
        },
        errors: {
          ...state.errors,
          getSupportMeetings: undefined,
        },
      };
    case MeetingModuleActionTypes.GET_SUPPORT_MEETINGS_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSupportMeetings: false,
        },
        errors: {
          ...state.errors,
          getSupportMeetings: action.payload.error,
        },
      };

    case MeetingModuleActionTypes.CREATE_SUPPORT_MEETING_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createSupportMeeting: true,
        },
      };
    case MeetingModuleActionTypes.CREATE_SUPPORT_MEETING_SUCCESS:
      return {
        ...state,
        selectedMeetingOld: action.payload.meeting,
        isLoading: {
          ...state.isLoading,
          createSupportMeeting: false,
        },
        errors: {
          ...state.errors,
          createSupportMeeting: undefined,
        },
      };
    case MeetingModuleActionTypes.CREATE_SUPPORT_MEETING_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createSupportMeeting: false,
        },
        errors: {
          ...state.errors,
          createSupportMeeting: action.payload.error,
        },
      };

    case MeetingModuleActionTypes.UPDATE_SUPPORT_MEETING_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateSupportMeeting: true,
        },
      };
    case MeetingModuleActionTypes.UPDATE_SUPPORT_MEETING_SUCCESS:
      return {
        ...state,
        supportMeetings: state.supportMeetings.map((meeting) => {
          if (meeting.id === action.payload.meeting.id) {
            return action.payload.meeting;
          }
          return meeting;
        }),
        isLoading: {
          ...state.isLoading,
          updateSupportMeeting: false,
        },
        errors: {
          ...state.errors,
          updateSupportMeeting: undefined,
        },
      };
    case MeetingModuleActionTypes.UPDATE_SUPPORT_MEETING_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateSupportMeeting: false,
        },
        errors: {
          ...state.errors,
          updateSupportMeeting: action.payload.error,
        },
      };

    case MeetingModuleActionTypes.CHANGE_SELECTED_PRAISE:
      return {
        ...state,
        selectedPraise: action.payload.selectedPraise,
      };

    case MeetingModuleActionTypes.GET_PROGRESS_OVERVIEW_DATA_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getProgressOverviewData: true,
        },
      };
    case MeetingModuleActionTypes.GET_PROGRESS_OVERVIEW_DATA_SUCCESS:
      return {
        ...state,
        progressOverview: action.payload.progressOverview,
        isLoading: {
          ...state.isLoading,
          getProgressOverviewData: false,
        },
        errors: {
          ...state.errors,
          getProgressOverviewData: undefined,
        },
      };
    case MeetingModuleActionTypes.GET_PROGRESS_OVERVIEW_DATA_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getProgressOverviewData: false,
        },
        errors: {
          ...state.errors,
          getProgressOverviewData: action.payload.error,
        },
      };

    case MeetingModuleActionTypes.LOG_PROGRESS_TOWARDS_GOAL_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          logProgressTowardsGoal: true,
        },
      };
    case MeetingModuleActionTypes.LOG_PROGRESS_TOWARDS_GOAL_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          logProgressTowardsGoal: false,
        },
        errors: {
          ...state.errors,
          logProgressTowardsGoal: undefined,
        },
      };
    case MeetingModuleActionTypes.LOG_PROGRESS_TOWARDS_GOAL_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          logProgressTowardsGoal: false,
        },
        errors: {
          ...state.errors,
          logProgressTowardsGoal: action.payload.error,
        },
      };

    case MeetingModuleActionTypes.LOG_INTERVENTION_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          logIntervention: true,
        },
      };
    case MeetingModuleActionTypes.LOG_INTERVENTION_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          logIntervention: false,
        },
        errors: {
          ...state.errors,
          logIntervention: undefined,
        },
      };
    case MeetingModuleActionTypes.LOG_INTERVENTION_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          logIntervention: false,
        },
        errors: {
          ...state.errors,
          logIntervention: action.payload.error,
        },
      };

    case MeetingModuleActionTypes.CHANGE_PREDEFINED_MEETING_TYPE:
      return {
        ...state,
        predefinedMeetingType: action.payload.meetingType,
      };

    case getType(meetingModuleActions.getPrisesActions.request):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getPrises: true,
        },
      };
    case getType(meetingModuleActions.getPrisesActions.success):
      return {
        ...state,
        praises: action.payload,
        isLoading: {
          ...state.isLoading,
          getPrises: false,
        },
      };
    case getType(meetingModuleActions.getPrisesActions.failure):
      return {
        ...state,
        // praises: praiseList,
        isLoading: {
          ...state.isLoading,
          getPrises: false,
        },
      };

    default:
      return state;
  }
};

export { reducer as meetingModuleReducer };
