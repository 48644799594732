import React, { FunctionComponent, useMemo } from "react";
import { GroupProgress } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

type OwnProps = {
  groupProgress: GroupProgress[];
};

type Props = OwnProps;

const InterventionGroupProgressTable: FunctionComponent<Props> = (props) => {
  const { groupProgress = [] } = props;

  const columns = useMemo(
    () => [
      {
        text: "Index",
        dataField: "index",
        hidden: true,
      },
      {
        dataField: "coach",
        text: "Coach Name",
        sort: true,
      },
      {
        dataField: "interventionGroup",
        text: "Intervention Group",
        sort: true,
      },
      {
        dataField: "progress",
        text: "Progress",
        sort: true,
      },
    ],
    []
  );

  const pagination = useMemo(() => {
    if (groupProgress.length > 10) {
      return {
        sizePerPageList: [
          {
            text: "10",
            value: 10,
          },
          {
            text: "20",
            value: 20,
          },
          {
            text: "50",
            value: 50,
          },
        ],
      };
    }
    return null;
  }, [groupProgress.length]);

  const data = useMemo(
    () =>
      groupProgress.map((item, index) => ({
        index: index,
        coach: getFullName(item.coach),
        interventionGroup: item.intervention_group.name,
        progress:
          (item.progress_percentage * 100).toFixed(2).replace(".00", "") + "%",
      })),
    [groupProgress]
  );

  return (
    <BootstrapTable
      classes="rosterTable"
      keyField="index"
      bordered={false}
      bootstrap4
      data={data}
      columns={columns}
      noDataIndication={"No data"}
      pagination={pagination ? paginationFactory(pagination) : undefined}
    />
  );
};

export default InterventionGroupProgressTable;
