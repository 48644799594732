import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../../../store";
import {
  Attendance,
  InterventionGroup,
} from "../../../../../../../../../store/onboarding/cases/types";
import { getAttendances } from "../../../../../../../../../store/onboarding/cases/actions";
import AttendanceUpsertForm from "./AttendanceUpsertForm";
import LoadingIndicator from "../../../../../../../../common/onboarding/LoadingIndicator";
import { useLoading } from "../../../../../../../../../utils/hooks/useLoading";
import AttendanceTable from "./AttendanceTable";
import AttendanceSummary from "./AttendanceSummary";
import useInterventionGroupAccess from "../../../../../../../../../utils/hooks/useInterventionGroupAccess";

type OwnProps = {
  interventionGroup: InterventionGroup;
  onlyActive?: boolean;
  showUpsertForm: boolean;
  studentId?: number;
  setShowUpsertForm: React.Dispatch<React.SetStateAction<boolean>>;
  isStudentDetail?: boolean;
  isGroupDetail?: boolean;
};

type Props = OwnProps;

const AttendanceTab: FunctionComponent<Props> = (props) => {
  const {
    interventionGroup,
    onlyActive,
    showUpsertForm,
    studentId,
    setShowUpsertForm,
    isStudentDetail,
    isGroupDetail
  } = props;
  const dispatch = useDispatch();

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(interventionGroup.id);

  const attendances = useSelector((s: ApplicationState) => s.cases.attendances);
  //const selectedStudent = useSelector((s: ApplicationState) => s.cases.selectedStudent);
  useEffect(() => {
    dispatch(getAttendances(interventionGroup.id, +studentId!));
  }, [interventionGroup.id]);
  const getAttendancesLoading = useSelector(
    (s: ApplicationState) => s.cases.isLoading.getAttendances
  );
  const getAttendancesError = useSelector(
    (s: ApplicationState) => s.cases.errors.getAttendances
  );
  useLoading(getAttendancesLoading, getAttendancesError);

  const handleShowUpsertForm = useCallback(() => {
    setShowUpsertForm(true);
  }, []);

  const [selectedAttendance, setSelectedAttendance] = useState<
    Attendance | undefined
  >();

  const handleAttendanceEdit = (attendance: Attendance) => {
    setShowUpsertForm(true);
    setSelectedAttendance(attendance);
  };

  if (getAttendancesLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <AttendanceUpsertForm
        show={showUpsertForm}
        onHideForm={() => setShowUpsertForm(false)}
        selectedGroup={interventionGroup}
        selectedAttendance={selectedAttendance}
      />
      <div style={showUpsertForm ? { display: "none" } : undefined}>
        {attendances.length ? (
          <div>
            <AttendanceSummary
              interventionGroup={interventionGroup}
              onlyActive={onlyActive}
            />
            <AttendanceTable
              group={interventionGroup}
              attendances={attendances}
              onEdit={handleAttendanceEdit}
              isStudentDetail={isStudentDetail}
              isGroupDetail={isGroupDetail}
            />
          </div>
        ) : (
          <div className="text-center">
            {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess ? 
              <>
                <p>
                No Attendance Data.
                </p>
                <button className="blueBtnSm" onClick={handleShowUpsertForm}>
                  Add New Attendance
                </button>
              </>
            : 
            <p className="mb-0">
              No Attendance Data.
            </p>
            }
          </div>
        )}
      </div>
    </>
  );
};

export default AttendanceTab;
