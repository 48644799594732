import React, { FunctionComponent, useEffect, useMemo, useRef } from "react";
import LoadingIndicator from "../../../common/onboarding/LoadingIndicator";
import { Row } from "react-bootstrap";
import MyChatMessage from "./MyChatMessage";
import UserChatMessage from "./UserChatMessage";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { ChatMessage, ChatThread } from "../../../../store/chat/types";
import { UserInfo } from "../../../../store/auth/types";

type OwnProps = {
  chatThread: ChatThread;
  onReportDialogOpen: (options: { id: number; userName: string }) => any;
};

type Props = OwnProps;

const ChatMainWindow: FunctionComponent<Props> = (props) => {
  const { chatThread, onReportDialogOpen } = props;

  const messagesEndRef = useRef<any>(null);

  const chatMessages = useSelector<ApplicationState, ChatMessage[]>(
    (s) => s.chat.chatMessages
  );

  const isLoadingGetChatMessages = useSelector<ApplicationState, boolean>(
    (s) => s.chat.isLoading.chatMessages
  );

  const currentUser = useSelector<ApplicationState, UserInfo | undefined>(
    (s) => s.auth.userInfo
  );

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      scrollToBottom();
    }
  }, [chatMessages]);

  const mappedChatMessages = useMemo(() => {
    return chatMessages.map((message) => {
      const isYourMessage = currentUser?.id === message.user;
      return {
        ...message,
        userInfo: isYourMessage
          ? currentUser!
          : chatThread.participants.find((d) => d.id === message.user)!,
        isYourMessage,
      };
    });
  }, [chatMessages, chatThread.participants]);

  const handleReportMessage = (
    message: ChatMessage,
    firstName: string
  ) => () => {
    onReportDialogOpen({
      id: message.id!,
      userName: firstName,
    });
  };

  return (
    <div className="chatMainWindow">
      {isLoadingGetChatMessages ? (
        <LoadingIndicator />
      ) : (
        mappedChatMessages.map(({ userInfo, isYourMessage, ...message }) => (
          <Row key={message.id + message.text}>
            {isYourMessage ? (
              <MyChatMessage userInfo={userInfo} message={message} />
            ) : (
              <UserChatMessage
                userInfo={userInfo}
                message={message}
                onReportMessage={handleReportMessage(
                  message,
                  userInfo?.first_name
                )}
              />
            )}
          </Row>
        ))
      )}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default ChatMainWindow;
