import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import InterventionElementContainer from "../InterventionElementContainer";
import InterventionElement from "../InterventionElement";
import { useDispatch, useSelector } from "react-redux";
import {
  changeInterventionPlanOrder,
  checkedInterventionPlan,
  sortInterventionsByInterventionPlan,
} from "../../../../../../../utils";
import {
  hideInterventionPlanModal,
  openInterventionLibraryModal,
  patchInterventionGroup,
  showInterventionPlanModal,
} from "../../../../../../../../store/onboarding/cases/actions";
import {
  InterventionGroup,
  InterventionPlan,
} from "../../../../../../../../store/onboarding/cases/types";
import WorkspaceHeader from "./WorkspaceHeader";
import { ApplicationState } from "../../../../../../../../store";
import { GroupWorkSpace } from "./WorkspacesContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import useInterventionGroupAccess from "../../../../../../../../utils/hooks/useInterventionGroupAccess";

type Props = {
  expanded?: boolean;
  workspace: GroupWorkSpace;
  interventionGroup: InterventionGroup;
  setExpandedWorkSpace: React.Dispatch<
    React.SetStateAction<GroupWorkSpace | undefined>
  >;
};

const InterventionPlanWorkspace: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    expanded,
    workspace,
    interventionGroup,
    setExpandedWorkSpace,
  } = props;
  const { interventions } = interventionGroup;
  const isLoading = useSelector<ApplicationState, boolean>(
    (s) =>
      s.cases.isLoading.patchInterventionGroup ||
      s.cases.isLoading.addInterventionsToGroup
  );

  const isStudentDetail =  useSelector(
    (s: ApplicationState) => s.cases.isStudentDetail
  );

  const isGroupDetail =  useSelector(
    (s: ApplicationState) => s.cases.isGroupDetail
  );

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(interventionGroup.id);

  const [statePlan, setStatePlan] = useState<InterventionPlan>(
    checkedInterventionPlan(interventions, interventionGroup.intervention_plan)
  );

  useEffect(() => {
    const updatedPlan = checkedInterventionPlan(
      interventions,
      interventionGroup.intervention_plan
    );

    setStatePlan(updatedPlan);
  }, [interventions, interventionGroup.intervention_plan]);

  const hideParentDelete = (interventionId:number) => {
    for (let i = 0; i < interventions.length; i++) {
      if(interventionId == interventions[i].forked_from?.id) return true
    }
    return false
  }

  const onDragInterventionUpdate = (result: DropResult) => {
    const { source, destination, draggableId } = result;
    if (destination && destination.droppableId === source.droppableId) {
      const changedPlan = changeInterventionPlanOrder(
        interventionGroup.intervention_plan,
        interventions,
        source.index,
        destination.index,
        draggableId
      );
      setStatePlan(changedPlan);
    }
  };

  const onDragInterventionEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (
      destination &&
      destination.droppableId === source.droppableId &&
      destination.index !== source.index
    ) {
      dispatch(
        patchInterventionGroup(interventionGroup.id, {
          intervention_plan: statePlan,
        })
      );
    }
  };

  const sortedInterventions = useMemo(
    () => sortInterventionsByInterventionPlan(interventions, statePlan),
    [interventions, statePlan]
  );

  const handleInterventionAdd = () => {
    dispatch(hideInterventionPlanModal());
    dispatch(
      openInterventionLibraryModal(undefined, undefined, {
        onBack: () => {
          dispatch(showInterventionPlanModal());
        },
      })
    );
  };

  return (
    <div
      className="d-flex flex-column intervention-plan-list "
      style={isLoading ? { opacity: ".4" } : undefined}
    >
      <WorkspaceHeader
        expanded={expanded}
        workspace={workspace}
        setExpandedWorkSpace={setExpandedWorkSpace}
        isLoading={isLoading}
        header={
          <div>
            Selected Interventions{" "}
            {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess && <span onClick={handleInterventionAdd}>
              <FontAwesomeIcon icon={faPlusCircle} />
            </span>}
          </div>
        }
      />
      <DragDropContext
        onDragUpdate={onDragInterventionUpdate}
        onDragEnd={onDragInterventionEnd}
      >
        <Droppable
          droppableId={interventionGroup.id.toString()}
          isDropDisabled={isLoading}
        >
          {(provided) => (
            <div
              className="intervention-plan-active"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {sortedInterventions.map((intervention, index) => (
                <Draggable
                  key={intervention.id}
                  draggableId={intervention.id.toString()}
                  isDragDisabled={isLoading}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <InterventionElementContainer
                      usePortal={snapshot.isDragging}
                    >
                      <InterventionElement
                        index={index}
                        isLoading={isLoading}
                        provided={provided}
                        statePlan={statePlan}
                        intervention={intervention}
                        interventionGroup={interventionGroup}
                        setStatePlan={setStatePlan}
                        hideDelete={hideParentDelete(intervention.id)}
                        isStudentDetail = {isStudentDetail}
                        isGroupDetail = {isGroupDetail}
                      />
                    </InterventionElementContainer>
                  )}
                </Draggable>
              ))}
              {sortedInterventions.length === 0 && !isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess &&(
                <div className="workspace-body text-center">
                  <div>
                    <button
                      className="blueBtnSm"
                      onClick={handleInterventionAdd}
                    >
                      Add First Intervention
                    </button>
                  </div>
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default InterventionPlanWorkspace;
