//@ts-nocheck
import {
  AbcTypes,
  Fba,
  FbaObservation,
  FbaObservationTally,
  FbaTally,
} from "../../../../../store/onboarding/cases/types";
import { formatDate } from "../../../../utils/DateTimeUtils";

export const getDateInterval = (fba: Fba) => {
  const created = formatDate(fba.created);
  const updated = formatDate(fba.updated);
  if (created === updated) {
    return created;
  }
  return `${created} - ${updated}`;
};

export const updateAbc = (
  pV: FbaObservationTally,
  cV: FbaObservation,
  fbaTallyAbcType: AbcTypes,
  tallyPropertyName?: AbcTypes
) => {
  //@ts-ignore
  const fbaTallies: Array<FbaTally<abcType>> = pV[fbaTallyAbcType];
  //@ts-ignore
  cV.tally[fbaTallyAbcType].forEach((abc: FbaTally<abcType>) => {
    if (tallyPropertyName) {
      (abc[tallyPropertyName] as number[]).forEach((value) => {
        const index = fbaTallies.findIndex(
          //@ts-ignore
          (pAbc: FbaTally<abcType>) => pAbc[fbaTallyAbcType] === value
        );
        if (index >= 0) {
          fbaTallies[index] = {
            [fbaTallyAbcType]: fbaTallies[index][fbaTallyAbcType],
            count: fbaTallies[index].count + abc.count,
          };
        } else {
          fbaTallies.push({
            [fbaTallyAbcType]: value,
            count: abc.count,
          });
        }
      });
    } else {
      const index = fbaTallies.findIndex(
        //@ts-ignore
        (pAbc: FbaTally<abcType>) =>
          pAbc[fbaTallyAbcType] === abc[fbaTallyAbcType]
      );
      if (index >= 0) {
        fbaTallies[index] = {
          [fbaTallyAbcType]: fbaTallies[index][fbaTallyAbcType],
          count: fbaTallies[index].count + abc.count,
        };
      } else {
        fbaTallies.push(abc);
      }
    }
  });
  return fbaTallies;
};

export const getSummaryTally = (fba: Fba): FbaObservationTally => {
  return fba.observations.reduce(
    // @ts-ignore todo
    (pV: FbaObservationTally, cV: FbaObservation) => {
      return {
        antecedent: updateAbc(pV, cV, AbcTypes.antecedent),
        behavior: updateAbc(pV, cV, AbcTypes.behavior, AbcTypes.behaviors),
        consequence: updateAbc(pV, cV, AbcTypes.consequence),
      };
    },
    {
      antecedent: [],
      behavior: [],
      consequence: [],
    }
  );
};

export const getTally = (
  summaryTally: FbaObservationTally,
  abcType: AbcTypes
): any => {
  // @ts-ignore
  return summaryTally[abcType].reduce(
    (
      pV: FbaTally<typeof abcType> | undefined,
      cV: FbaTally<typeof abcType>
    ) => {
      if (!pV) return cV;
      if (pV.count < cV.count) {
        return cV;
      }
      return pV;
    },
    undefined
  );
};

export const restatementBehaviors = (fbaTally: Array<FbaTally<any>>) => {
  return fbaTally.reduce((pV: any, cV) => {
    (cV.behaviors as number[]).forEach((b) => {
      const index = pV.findIndex(
        //@ts-ignore
        (pAbc: FbaTally<AbcTypes.behavior>) => pAbc.behavior === b
      );
      if (index >= 0) {
        pV[index] = {
          behavior: pV[index].behavior,
          count: pV[index].count + cV.count,
        };
      } else {
        pV.push({
          behavior: b,
          count: cV.count,
        });
      }
    });
    return pV;
  }, []);
};

export const getOverallMax = (fbaTallies: Array<FbaTally<any>>) => {
  const maxFbaTally: FbaTally<any> | undefined = fbaTallies.reduce(
    (pV: FbaTally<any> | undefined, cV: FbaTally<any>) => {
      if (!pV) return cV;
      if (cV.count > pV.count) return cV;
      return pV;
    },
    undefined
  );
  return maxFbaTally;
};

export const getOverallABC = (fba: Fba) => {
  const fbaTally = getSummaryTally(fba);
  const bMax = getOverallMax(fbaTally.behavior);
  const aMax = getOverallMax(fbaTally.antecedent);
  const cMax = getOverallMax(fbaTally.consequence);
  return {
    b: bMax ? fbaTally.behavior.filter((b) => b.count === bMax.count) : [],
    a: aMax ? fbaTally.antecedent.filter((a) => a.count === aMax.count) : [],
    c: cMax ? fbaTally.consequence.filter((b) => b.count === cMax.count) : [],
  };
};
