import React, { FunctionComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router";

type OwnProps = {
  page: string;
};

type MatchParams = {
  tabName: string;
};

type Props = OwnProps & RouteComponentProps<MatchParams>;

const ActivePageProvider: FunctionComponent<Props> = (props) => {
  return props.match.params.tabName === props.page ? (
    <>{props.children}</>
  ) : null;
};

export default withRouter(ActivePageProvider);
