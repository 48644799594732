import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import {
  DistrictAccessDataSetting,
  School,
  TeacherInfo,
} from "../../../../../store/onboarding/types";
import { UserAssignmentRole, UserInfo } from "../../../../../store/auth/types";
import {
  getFullName,
  getUserAssigmentRoleDisplayedName,
} from "../../../../../utils/NamesUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEdit,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { showConfirmDialog } from "../../../../../store/confirm-dialog/actions";
import {
  deleteDistrictAccessDataSetting,
  openDistrictAccessDataSettingUpsertModal,
} from "../../../../../store/onboarding/actions";
import { Badge } from "react-bootstrap";

type OwnProps = {
  setSelectedUsers: (selectedUsers:any) => any
  selectedUsers: any;
};

type Props = OwnProps;

const DataAccessTable: FunctionComponent<Props> = ({selectedUsers, setSelectedUsers }) => {
  const dispatch = useDispatch();
  
  const columns: ColumnDescription[] = useMemo(
    () => [
      {
        dataField: "id",
        text: "key",
        hidden: true,
      },
      {
        dataField: "name",
        text: "Name",
      },
      {
        dataField: "block_access_to_all_data",
        text: "Block Access All Data",
        //hidden: true,
        formatter: (value, row) => (
          <FontAwesomeIcon
            size="lg"
            icon={value ? faCheckCircle : faTimes}
          />
        )
      },
      {
        dataField: "share_all_data",
        text: "Share All Data",
        sort: true,
        formatter: (value, row) => (
          <FontAwesomeIcon
            size="lg"
            icon={value && !row.block_access_to_all_data ? faCheckCircle : faTimes}
          />
        )
      },
      {
        dataField: "role",
        text: "Role",
        sort: true,
      },
      {
        dataField: "allowed_grades",
        text: "Allowed Grades",
        sort: true, 
      },
      {
        dataField: "allowed_no_grade",
        text: "Students with No Grade",
        sort: true,
        formatter: (value, row) => (
          <FontAwesomeIcon
            size="lg"
            icon={(value || row.share_all_data) && !row.block_access_to_all_data ? faCheckCircle : faTimes}
          />
        )
      },
      {
        dataField: "allowed_assigned_teachers",
        text: "Allowed Assigned Teachers",
        sort: false,
      },
      {
        dataField: "allowed_no_teacher",
        text: "Students with No Teacher",
        sort: true,
        formatter: (value, row) => (
          <FontAwesomeIcon
            size="lg"
            icon={(value || row.share_all_data) && !row.block_access_to_all_data ? faCheckCircle : faTimes}
          />
        )
      },
      {
        dataField: "allowed_schools",
        text: "Allowed Schools",
        sort: true,
      },
      {
        dataField: "allowed_edit_benchmarks",
        text: "Benchmark Permissions",
        sort: false,
        formatter: (value, row) => (
          <FontAwesomeIcon
            size="lg"
            icon={value ? faCheckCircle : faTimes}
          />
        )
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        formatter: (value, row) => (
          value ?  <Badge variant="success">Set</Badge> : <Badge variant="danger">Not Set</Badge>
        )
      },
      {
        dataField: "actions",
        text: "Actions",
        sort: false,
      },
    ],
    []
  );

  const teachersRoster = useSelector<ApplicationState, TeacherInfo[]>(
    (s) => s.onboarding.teachersRoster
  );
  const schools = useSelector<ApplicationState, School[]>(
    (s) => s.onboarding.schools
  );
  const districtAccessDataSettings = useSelector<
    ApplicationState,
    DistrictAccessDataSetting[]
  >((s) => s.onboarding.districtAccessDataSettings);

  const searchFilter = useSelector<ApplicationState, string>(
    (s) => s.usersManagement.searchFilter!
  );

  useEffect(() => {
    setSelectedUsers([]);
  }, [districtAccessDataSettings])

  const educators = useMemo(() => {
    return teachersRoster
      .filter(
        (t) =>
          (t.user.profile.current_role == UserAssignmentRole.TEACHER) 
          || (t.user.profile.current_role == UserAssignmentRole.NJTSS_IMPORTED_USER)
          || t.user.profile.current_role === UserAssignmentRole.COACH
      )
      .map((t) => t.user);
  }, [teachersRoster]);

  const handleEdit = (
    educator: UserInfo,
    setting?: DistrictAccessDataSetting
  ) => () => {
    dispatch(
      openDistrictAccessDataSettingUpsertModal(
        setting ? { ...(setting as any), user: educator } : { user: educator }
      )
    );
  };

  const handleDelete = (settingId: number) => () => {
    const onConfirm = () => {
      dispatch(deleteDistrictAccessDataSetting(settingId));
    };
    dispatch(showConfirmDialog({ onConfirm, centered: true }));
  };

  const filterBySearch = (educator: UserInfo) => 
        educator.first_name.toLowerCase().includes(searchFilter!.toLowerCase()) ||
        educator.last_name.toLowerCase().includes(searchFilter!.toLowerCase()) ||
        getFullName(educator).toLowerCase().includes(searchFilter!.toLowerCase()) ||
        educator.profile.current_role.toLowerCase().includes(searchFilter!.toLowerCase());

  const data = useMemo(
    () =>  educators
    .filter((educator) => filterBySearch(educator))
    .map((educator) => {
      let settings = districtAccessDataSettings.find(
        (s) => s.user === educator.id
      )
     
      if(settings) {
        settings.status = true;
      } 
      

      return {
        id: educator.id,
        name: <h4 className="blueTitle"> {getFullName(educator)}</h4>,
        block_access_to_all_data: settings?.block_access_to_all_data,
        share_all_data: settings?.share_all_data,
        role: getUserAssigmentRoleDisplayedName(
          educator.profile.current_assignment?.role
        ),
        allowed_grades: settings?.allowed_grades?.length
          ? settings.allowed_grades.join(", ")
          : (settings?.share_all_data || settings?.status) && !settings.block_access_to_all_data ? "ALL": "N/A",
        allowed_no_grade: settings?.allowed_no_grade
        ,
        allowed_assigned_teachers: settings?.allowed_assigned_teachers?.length
          ? settings.allowed_assigned_teachers
              ?.map((teacherId) => {
                const teacher = educators.find((e) => e.id === teacherId);
                return getFullName(teacher) ?? "N/A";
              })
              .join(", ")
          : (settings?.share_all_data || settings?.status) && !settings.block_access_to_all_data ? "ALL": "N/A",
        allowed_no_teacher: settings?.allowed_no_teacher,
        allowed_schools: settings?.allowed_schools?.length
          ? settings.allowed_schools
              .map((schoolId) => {
                const school = schools.find((s) => s.id === schoolId);
                return school?.name ?? "N/A";
              })
              .join(", ")
          : (settings?.share_all_data || settings?.status) && !settings.block_access_to_all_data ? "ALL": "N/A",
        allowed_edit_benchmarks:settings?.allowed_edit_benchmarks,
        status: settings?.status,
        actions: (
          <div className="entry-actions my-0">
            {settings && (<span className="edit">
              <FontAwesomeIcon
                size="lg"
                icon={faEdit}
                onClick={handleEdit(educator, settings)}
              />
            </span>)
            }
            {settings && (
              <span className="delete" onClick={handleDelete(settings.id)}>
                <FontAwesomeIcon size="lg" icon={faTimesCircle} />
              </span>
            )}
          </div>
        ),
      };
    })
  , [educators, districtAccessDataSettings, schools, searchFilter]);

  const options = useMemo(
    () => ({
      sizePerPageList: [
        {
          text: "20",
          value: 20,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
    }),
    []
  );

  return (
    <>
      <BootstrapTable
        classes="rosterTable"
        keyField="id"
        bootstrap4
        data={data}
        columns={columns}
        selectRow={{ 
          mode: 'checkbox', 
          onSelect: (row, isSelect, rowIndex, e) => {
            if (!(selectedUsers.some((item:any) => row.id === item.id))) {
              setSelectedUsers((selectedUsers:any) => [...selectedUsers, row]);
            } else {
              setSelectedUsers(selectedUsers.filter((item:any) => item.id !== row.id));
            }
          },
          onSelectAll: (isSelect, rows, e) => {
            if(isSelect) {
              setSelectedUsers((selectedUsers:any) => [...selectedUsers, ...rows]);
            } else{
              setSelectedUsers([])
            }
          }  
        }}
        pagination={paginationFactory(options)}
        noDataIndication={"No Data"}
      />
    </>
  );
};

export default DataAccessTable;
