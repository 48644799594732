import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

type Props = {
  faIcon: IconDefinition;
  title: string;
  text?: string;
  onClick: () => any;
  style?: React.CSSProperties;
};

const CardButton = (props: Props) => {
  return (
    <div style={props.style} className="whiteBtnXL" onClick={props.onClick}>
      <FontAwesomeIcon icon={props.faIcon} size="4x" />
      <p className="whiteBtnXLTitle">{props.title}</p>
      {props.text && <p className="whiteBtnXLText">{props.text}</p>}
    </div>
  );
};

export default CardButton;
