import React, { FunctionComponent, useMemo } from "react";
import { Decision } from "../../../../../../store/onboarding/types";
import { Doughnut } from "react-chartjs-2";
import { colors } from "./index";

type OwnProps = {
  label?: string;
  decisions: Array<Decision>;
};

type Props = OwnProps;

const DecisionsMadeInDistrict: FunctionComponent<Props> = (props) => {
  const { label, decisions } = props;
  const labels = useMemo(() => decisions.map((decision) => decision.title), [
    decisions,
  ]);
  const data = useMemo(() => decisions.map((decision) => decision.count), [
    decisions,
  ]);

  if (!data.length) {
    return null;
  }

  const chartData = useMemo(() => {
    return {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: data.map(
            (d, index) => colors[index % colors.length]
          ),
          datalabels: {
            display: false,
          },
        },
      ],
    };
  }, [data, labels]);

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
    }),
    []
  );

  return (
    <div className="district-summary-chart-container">
      {label && <h3 className="text-center">{label}</h3>}
      <div>
        <Doughnut height={250} data={chartData} options={options} />
      </div>
    </div>
  );
};

export default DecisionsMadeInDistrict;
