import { useEffect } from "react";
import usePrevProps from "./usePrevProps";
import { toastr } from "react-redux-toastr";

export const useLoading = (
  isLoading: boolean | undefined,
  error: string | undefined,
  onSuccess?: () => void,
  onError?: () => void
) => {
  const prevProps = usePrevProps({ isLoading });

  useEffect(() => {
    if (prevProps?.isLoading && !isLoading) {
      if (error) {
        toastr.error("Failed", error);
        onError && onError();
      } else {
        onSuccess && onSuccess();
      }
    }
  }, [isLoading, prevProps?.isLoading]);
};
