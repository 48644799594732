import {
  EvidenceColumnGroup,
  EvidenceType,
  StudentEntry,
} from "../store/onboarding/types";
import { ApplicationState } from "../store";

export const getTargetBehaviorStudentEntries = (
  state: ApplicationState
): StudentEntry[] => {
  const targetBehaviorECG: EvidenceColumnGroup | undefined = state.onboarding
    .evidencePeriodData
    ? state.onboarding.evidencePeriodData.evidence_column_groups.find(
        (ecg) =>
          !ecg.assessment &&
          ecg.evidence_columns.some(
            (ec) => ec.evidence_type === EvidenceType.ExistingBehavior
          )
      )
    : undefined;
  return targetBehaviorECG && state.cases.selectedInterventionGroup
    ? targetBehaviorECG.evidence_columns[0].student_entries.filter((se) =>
        state.cases.selectedInterventionGroup!.students.some(
          (s) => s.id === se.student_id
        )
      )
    : [];
};
