import React, { FunctionComponent } from "react";
import { Col, Image } from "react-bootstrap";
import { getDifferenceWithTime } from "../../../utils/DateTimeUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFlag } from "@fortawesome/free-solid-svg-icons";
import { UserInfo } from "../../../../store/auth/types";
import { ChatMessage } from "../../../../store/chat/types";

type OwnProps = {
  userInfo: UserInfo;
  message: ChatMessage;
  onReportMessage: () => void;
};

type Props = OwnProps;

const UserChatMessage: FunctionComponent<Props> = ({
  userInfo,
  message,
  onReportMessage,
}) => {
  return (
    <>
      <Col className="mb-1 pr-1" lg={1}>
        <Image
          className="mt-2"
          src={userInfo?.profile?.profile_image_url}
          width={30}
          height={30}
          roundedCircle
        />
      </Col>
      <Col className="mb-1 px-0" lg={10}>
        <div className="chatMessageDate">
          {`${userInfo?.first_name} ${
            userInfo?.last_name
          } ∙ ${getDifferenceWithTime(message.created)}`}
          {/* <span className="ml-1 pointer" onClick={onReportMessage}>
            <FontAwesomeIcon icon={faFlag} style={{ color: "#f5a623" }} />
          </span> */}
        </div>
        <div className="otherChatRow">
          <div className="otherChatMessage">
            {message.text}
            <div>
              {message.attachments?.map((att) => (
                <a
                  className="blueTitle mr-3"
                  href={att.attachment_url}
                  key={att.id}
                >
                  <FontAwesomeIcon icon={faFile} className="mr-1" />
                  {att.title}
                </a>
              ))}
            </div>
          </div>
        </div>
      </Col>
      <Col className="mb-1" lg={1} />
    </>
  );
};

export default UserChatMessage;
