import React from "react";
import { Overlay, Popover } from "react-bootstrap";
import {
  ObservationTypes,
  StaticMeasurementTypes,
} from "../../../../../../store/onboarding/cases/types";
import BoxButton from "../../../third-step/set-goal-modal/common/BoxButton";

type Props = {
  objectRef: React.MutableRefObject<any>;
  showDropdown: boolean;
  observationType: ObservationTypes | null;
  goalMeasurement: StaticMeasurementTypes | null;
  setShowDropdown: (show: boolean) => void;
  onPopoverBack: () => void;
  onSubmitPopover: (measurementType: StaticMeasurementTypes) => void;
};

const MeasurementTypePopover = ({
  objectRef,
  showDropdown,
  observationType,
  setShowDropdown,
  onPopoverBack,
  onSubmitPopover,
}: Props) => {
  const handleMeasurementTypeChange = (
    measurementType: StaticMeasurementTypes
  ) => () => {
    onSubmitPopover(measurementType);
  };

  const handleBack = () => {
    onPopoverBack();
  };

  switch (observationType) {
    case ObservationTypes.FREQUENCY_OR_EVENT:
      return (
        <Overlay
          placement="auto"
          flip
          rootClose={true}
          onHide={() => setShowDropdown(false)}
          show={showDropdown}
          target={objectRef.current}
        >
          <Popover
            id="popover-observation-foe"
            className="w-100 observations-popover"
          >
            <Popover.Title>Which type of Measurement?</Popover.Title>
            <Popover.Content>
              <div className="d-flex flex-column">
                <h4 className="mb-3">
                  You can measure the frequency of a target behavior as a total
                  count (6 occurrences) or rate (e.g., 2 times per minute).
                </h4>
                <div className="w-100">
                  <BoxButton
                    className="mb-1"
                    value={StaticMeasurementTypes.TOTAL_COUNT}
                    isNotRadio
                    title={"Total Count"}
                    description={
                      "e.g., the behavior occurred 6 times during the observation session"
                    }
                    selectedValue={null}
                    onSelect={handleMeasurementTypeChange}
                  />
                  <BoxButton
                    value={StaticMeasurementTypes.RATE}
                    isNotRadio
                    title={"Rate"}
                    description={
                      "e.g., the behavior occurred 2 times per minute during the observation session"
                    }
                    selectedValue={null}
                    onSelect={handleMeasurementTypeChange}
                  />
                </div>

                <hr />
                <div className="btnActions">
                  <button className="blueBtnSm" onClick={handleBack}>
                    Back
                  </button>
                </div>
              </div>
            </Popover.Content>
          </Popover>
        </Overlay>
      );
    case ObservationTypes.DURATION:
      return (
        <Overlay
          placement="auto"
          flip
          rootClose={true}
          transition={false}
          onHide={() => setShowDropdown(false)}
          show={showDropdown}
          target={objectRef.current}
        >
          <Popover
            id="popover-observation-duration"
            className="w-100 observations-popover"
          >
            <Popover.Title>Which type of Measurement?</Popover.Title>
            <Popover.Content>
              <div className="d-flex flex-column">
                <h4 className="mb-3">
                  Duration recording measures the total amount of time that a
                  student exhibits the target behavior.
                </h4>
                <div className="w-100">
                  <BoxButton
                    value={StaticMeasurementTypes.DURATION}
                    isNotRadio
                    title={"Measure Duration of Target Behavior"}
                    description={"(e.g., task for 4 minutes)"}
                    selectedValue={null}
                    onSelect={handleMeasurementTypeChange}
                  />
                </div>

                <hr />
                <div className="btnActions">
                  <button className="blueBtnSm" onClick={handleBack}>
                    Back
                  </button>
                </div>
              </div>
            </Popover.Content>
          </Popover>
        </Overlay>
      );
    case ObservationTypes.INTERVAL:
      return (
        <Overlay
          placement="auto"
          flip
          rootClose={true}
          transition={false}
          onHide={() => setShowDropdown(false)}
          show={showDropdown}
          target={objectRef.current}
        >
          <Popover
            id="popover-observation-interval"
            className="w-100 observations-popover"
          >
            <Popover.Title>Which type of Measurement?</Popover.Title>
            <Popover.Content>
              <div className="d-flex flex-column">
                <h4 className="mb-3">
                  You can record the percentage of intervals during which a
                  target behavior occurs using partial- or whole-interval
                  recording or momentary time sampling.
                </h4>
                <div className="w-100">
                  <BoxButton
                    className="mb-1"
                    isNotRadio
                    value={StaticMeasurementTypes.PARTIAL_INTERVAL_RECORDING}
                    title={"Partial-Interval Recording"}
                    description={
                      "Record whether the behavior occurred at least once during an interval."
                    }
                    info={{
                      advantage:
                        "Appropriate for measuring short duration behaviors",
                      disadvantage:
                        "Not good for measuring behaviors that should be maintained (e.g., staying on task)",
                    }}
                    selectedValue={null}
                    onSelect={handleMeasurementTypeChange}
                  />
                  <BoxButton
                    className="mb-1"
                    isNotRadio
                    value={StaticMeasurementTypes.FULL_INTERVAL_RECORDING}
                    title={"Full-Interval Recording"}
                    description={
                      "Record whether behavior occurred throughout the entire interval."
                    }
                    info={{
                      advantage:
                        "Appropriate for measuring short duration behaviors",
                      disadvantage:
                        "Not good for measuring behaviors that should be maintained (e.g., staying on task)",
                    }}
                    selectedValue={null}
                    onSelect={handleMeasurementTypeChange}
                  />
                  <BoxButton
                    value={StaticMeasurementTypes.MOMENTARY_TIME_SAMPLING}
                    isNotRadio
                    title={"Momentary Time Sampling"}
                    description={
                      "Record whether behavior occurred exactly at the end of each interval."
                    }
                    info={{
                      advantage:
                        "Appropriate for measuring short duration behaviors",
                      disadvantage:
                        "Not good for measuring behaviors that should be maintained (e.g., staying on task)",
                    }}
                    selectedValue={null}
                    onSelect={handleMeasurementTypeChange}
                  />
                </div>
                <hr />
                <div className="btnActions">
                  <button className="blueBtnSm" onClick={handleBack}>
                    Back
                  </button>
                </div>
              </div>
            </Popover.Content>
          </Popover>
        </Overlay>
      );
    case ObservationTypes.LATENCY:
      return (
        <Overlay
          placement="auto"
          flip
          rootClose={true}
          transition={false}
          onHide={() => setShowDropdown(false)}
          show={showDropdown}
          target={objectRef.current}
        >
          <Popover
            id="popover-observation-latency"
            className="w-100 observations-popover"
          >
            <Popover.Title>Which type of Measurement?</Popover.Title>
            <Popover.Content>
              <div className="d-flex flex-column">
                <h4 className="mb-3">
                  Latency recording measures the amount of time between when a
                  student is directed and when they exhibit the target behavior.
                </h4>
                <div className="w-100">
                  <BoxButton
                    value={StaticMeasurementTypes.LATENCY}
                    isNotRadio
                    title={"Measure Latency of Target Behavior"}
                    description={
                      "(e.g., initiated on-task behavior 2 minutes after instructed to do work)"
                    }
                    selectedValue={null}
                    onSelect={handleMeasurementTypeChange}
                  />
                </div>
                <hr />
                <div className="btnActions">
                  <button className="blueBtnSm" onClick={handleBack}>
                    Back
                  </button>
                </div>
              </div>
            </Popover.Content>
          </Popover>
        </Overlay>
      );
    default:
      return null;
  }
};

export default MeasurementTypePopover;
