import React, { ChangeEvent, FormEvent } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  addInterventionSchoolResources,
  addInterventionsToGroup,
  changeSelectedInterventionGroup,
  hideAddInterventionModal,
  patchInterventionGroup,
} from "../../../../../store/onboarding/cases/actions";
import { connect } from "react-redux";
import {
  AddInterventionSchoolRequest,
  AttachedResource,
  AttachedResourceType,
  AttachedToType,
  GenericTag,
  Intervention,
  InterventionGroup,
  InterventionGroupPatchRequest,
  InterventionPlan,
} from "../../../../../store/onboarding/cases/types";
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import { uploadDocument } from "../../../../../store/onboarding/actions";
import { IS_READY_COACH } from "../../../../../constants";
import CreatableSelect from "react-select/creatable";
import { ValueType } from "react-select";
import { addInterventionToPlan } from "../../../../utils";

type PropsFromState = {
  showModal: boolean;
  interventionGroup: InterventionGroup;
  createdIntervention?: Intervention;
  isSaveInState: boolean;
  isLoading: {
    addInterventionSchoolResources: boolean;
    addInterventionsToGroup: boolean;
  };
  errors: {
    addInterventionSchoolResources?: string;
    addInterventionsToGroup?: string;
  };
};

type DispatchProps = {
  addInterventionsToGroup: (
    interventionGroupId: number,
    interventionIds: Array<number>
  ) => any;
  patchInterventionGroup: (
    groupId: number,
    request: InterventionGroupPatchRequest
  ) => any;
  uploadDocument: (attachment: AttachedResource) => any;
  addInterventionSchoolResources: (
    request: AddInterventionSchoolRequest
  ) => any;
  hideAddInterventionModal: () => any;
  changeSelectedInterventionGroup: (group: InterventionGroup) => any;
};

type Props = PropsFromState & DispatchProps;

type State = {
  request: AddInterventionSchoolRequest;
  genericTags: Partial<GenericTag>[];
  attachments: AttachedResource[];
  isUploadDocument: boolean;
};

class AddInterventionModal extends React.Component<Props, State> {
  defState: Readonly<State> = {
    request: {
      name: "",
      description: "",
      attachments: [],
      generic_tags: [],
    },
    attachments: [],
    genericTags: [],
    isUploadDocument: false,
  };

  fileInputRef: any = null;

  state: Readonly<State> = this.defState;

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (
      this.props.createdIntervention &&
      prevProps.isLoading.addInterventionSchoolResources &&
      !this.props.isLoading.addInterventionSchoolResources
    ) {
      if (this.props.errors.addInterventionSchoolResources) {
        toastr.error("Error", this.props.errors.addInterventionSchoolResources);
      } else {
        const {
          isSaveInState,
          createdIntervention,
          interventionGroup,
        } = this.props;
        if (isSaveInState) {
          this.props.changeSelectedInterventionGroup({
            ...interventionGroup,
            interventions: (interventionGroup.interventions || []).concat(
              createdIntervention
            ),
          });
          this.props.hideAddInterventionModal();
        } else {
          const ids = (interventionGroup.interventions || []).map(
            (intervention) => intervention.id
          );
          this.props.addInterventionsToGroup(
            interventionGroup.id!,
            ids.concat([createdIntervention.id])
          );
        }
      }
    }
    if (
      this.props.createdIntervention &&
      prevProps.isLoading.addInterventionsToGroup &&
      !this.props.isLoading.addInterventionsToGroup
    ) {
      if (this.props.errors.addInterventionsToGroup) {
        toastr.error("Error", this.props.errors.addInterventionsToGroup);
      } else {
        const { interventionGroup, createdIntervention } = this.props;

        const changedPlan = addInterventionToPlan(
          interventionGroup.intervention_plan,
          createdIntervention,
          interventionGroup.interventions
        );
        this.props.patchInterventionGroup(interventionGroup.id, {
          intervention_plan: changedPlan,
        });
      }
    }
    if (
      this.props.showModal &&
      prevProps.isLoading.addInterventionsToGroup &&
      !this.props.isLoading.addInterventionsToGroup
    ) {
      if (this.props.errors.addInterventionsToGroup) {
        toastr.error("Error", this.props.errors.addInterventionsToGroup);
      } else {
        this.props.hideAddInterventionModal();
      }
    }
  }

  handleModalClose = () => {
    this.props.hideAddInterventionModal();
  };

  handleModalShow = () => {
    this.setState({
      ...this.defState,
    });
  };

  handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const target = event.target;
    this.setState((prevState) => ({
      request: {
        ...prevState.request,
        [target.name]: target.value,
      },
    }));
  };

  onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList) {
      const files: Array<File> = Array.from(fileList);
      const attachedResources: Array<AttachedResource> = files.map((file) => ({
        attached_to_type: AttachedToType.CHECKLIST_ITEM,
        resource_type: AttachedResourceType.FILE,
        attachment: file,
        title: file.name,
        mime_type: file.type,
      }));
      this.setState((prevState) => ({
        ...prevState,
        attachments: [...prevState.attachments, ...attachedResources],
      }));
    }
  };

  onAttachmentRemove = (attach: AttachedResource) => () => {
    this.setState((prevState) => ({
      ...prevState,
      attachments: [...prevState.attachments.filter((a) => a !== attach)],
    }));
  };

  handlePickFile = () => {
    this.fileInputRef.click();
  };

  handleSave = async (event: FormEvent) => {
    event.preventDefault();
    const { isLoading } = this.props;
    const { attachments, genericTags, isUploadDocument } = this.state;
    if (
      !isLoading.addInterventionSchoolResources &&
      !isLoading.addInterventionsToGroup &&
      !isUploadDocument
    ) {
      const attachedResources: Array<AttachedResource> = attachments.filter(
        (a) => a.id
      );
      let hasAttachedResourcesError: boolean = false;
      this.setState({ isUploadDocument: true });
      for (const attachedResource of attachments.filter((a) => !a.id)) {
        await this.props
          .uploadDocument(attachedResource)
          .then((a: AttachedResource | string) => {
            if (typeof a === "string") {
              toastr.error("Error", a);
              hasAttachedResourcesError = true;
            } else {
              attachedResources.push(a);
            }
          });
      }
      this.setState({ isUploadDocument: false });
      if (!hasAttachedResourcesError) {
        const request: AddInterventionSchoolRequest = {
          ...this.state.request,
          attachments: attachedResources.map((a) => a.id!),
          generic_tags: genericTags,
        };
        this.props.addInterventionSchoolResources(request);
      }
    }
  };

  handleCreateTag = (input: string) => {
    this.setState((pS) => ({
      ...pS,
      genericTags: [{ tag: input }, ...pS.genericTags],
    }));
  };

  handleChangeTags = (options: ValueType<Partial<GenericTag>, true>) => {
    this.setState({
      genericTags: (options as Array<Partial<GenericTag>>) ?? [],
    });
  };

  render() {
    const { interventionGroup, showModal, isLoading } = this.props;
    const { request, attachments, genericTags, isUploadDocument } = this.state;
    if (!interventionGroup) return null;
    return (
      <Modal
        onShow={this.handleModalShow}
        show={showModal}
        onHide={this.handleModalClose}
        animation={false}
        backdropClassName="customDarkModalBackdrop in"
      >
        <Modal.Header
          closeButton
          className={`${
            IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"
          } font-weight-bold`}
        >
          Add Intervention
        </Modal.Header>
        <Modal.Body>
          <Form
            id="addInterventionForm"
            className="mb-3"
            onSubmit={this.handleSave}
          >
            <Form.Group className="mb-3">
              <Form.Label className="font-weight-bold mb-2" column={false}>
                Intervention Name
              </Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={3}
                className="fullWidthTextarea"
                value={request.name}
                name="name"
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="font-weight-bold mb-2" column={false}>
                Description
              </Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={3}
                className="fullWidthTextarea"
                value={request.description}
                name="description"
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form>
          <h4 className="font-weight-bold ">Generic tags: </h4>
          <CreatableSelect
            isMulti
            isClearable
            createOptionPosition="first"
            onCreateOption={this.handleCreateTag}
            onChange={this.handleChangeTags}
            placeholder="Select or type tags..."
            formatCreateLabel={(input: string) => `Add new tag '${input}'`}
            value={genericTags}
            options={request.generic_tags}
            getOptionLabel={(a: any) => a.label ?? a.tag}
            getOptionValue={(a: any) => a.value ?? a.tag}
            getNewOptionData={(inputValue, optionLabel) =>
              ({
                label: optionLabel,
                value: inputValue,
              } as any)
            }
          />
          <div className="mb-4 mt-3">
            <h3 className="font-weight-bolder">Attach files</h3>
            <div>
              {attachments.map((attachment, index) => (
                <div className="fileCell" key={attachment.id || index}>
                  <div className="fileCellName">{attachment.title}</div>
                  <div
                    className="fileCellRemove"
                    onClick={this.onAttachmentRemove(attachment)}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      size="1x"
                      style={{ color: "#525252" }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <hr />
            <button className="whiteBtnMd2" onClick={this.handlePickFile}>
              <FontAwesomeIcon icon={faUpload} size="lg" /> Pick a file
            </button>
            <input
              type="file"
              style={{ display: "none" }}
              ref={(ref) => (this.fileInputRef = ref)}
              onChange={this.onFileChange}
            />
          </div>
          <div className="text-center">
            <button
              className="blueBtnMd"
              type="submit"
              form="addInterventionForm"
            >
              SAVE AND ADD INTERVENTION{" "}
              {(isLoading.addInterventionSchoolResources ||
                isLoading.addInterventionsToGroup ||
                isUploadDocument) && <Spinner animation="border" size="sm" />}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({ cases }: ApplicationState): PropsFromState => {
  return {
    interventionGroup: cases.selectedInterventionGroup!,
    createdIntervention: cases.createdIntervention,
    showModal: cases.modalsState.addInterventionModal,
    isSaveInState: cases.modalsState.isSaveInState,
    isLoading: {
      addInterventionSchoolResources:
        cases.isLoading.addInterventionSchoolResources,
      addInterventionsToGroup: cases.isLoading.addInterventionsToGroup,
    },
    errors: {
      addInterventionSchoolResources:
        cases.errors.addInterventionSchoolResources,
      addInterventionsToGroup: cases.errors.addInterventionsToGroup,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      uploadDocument: uploadDocument,
      addInterventionSchoolResources: addInterventionSchoolResources,
      addInterventionsToGroup: addInterventionsToGroup,
      hideAddInterventionModal: hideAddInterventionModal,
      changeSelectedInterventionGroup: changeSelectedInterventionGroup,
      patchInterventionGroup: patchInterventionGroup,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInterventionModal);
