import React, { FunctionComponent, useMemo } from "react";
import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  changeSelectedTeacher,
  deleteSchool,
  openTeacherDetailsModal,
  openUpsertSchoolModal,
} from "../../../../../store/onboarding/actions";
import { connect } from "react-redux";
import {
  School,
  SchoolTypesDisplayedNames,
} from "../../../../../store/onboarding/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import LoadingIndicator from "../../../../common/onboarding/LoadingIndicator";
import { formatDate } from "../../../../utils/DateTimeUtils";
import { toastr } from "react-redux-toastr";
import useUserRole from "../../../../../utils/hooks/useUserRole";
import { UserAssignmentRole } from "../../../../../store/auth/types";

type StateProps = {
  schools: Array<School>;
  isLoading: {
    getSchools: boolean;
  };
};

type DispatchProps = {
  openUpsertSchoolModal: (school?: School) => any;
  deleteSchool: (schoolId: number) => any;
};

type Props = StateProps & DispatchProps;

const SchoolsTable: FunctionComponent<Props> = ({
  schools,
  isLoading,
  deleteSchool,
  openUpsertSchoolModal,
}) => {
  const { isDistrictOwner } = useUserRole();
  const columns = useMemo(
    () => [
      {
        dataField: "name",
        text: "Name",
        sort: true,
      },
      // {
      //   dataField: "type",
      //   text: "Type",
      //   sort: true
      // },
      // {
      //   dataField: "school_year",
      //   text: "School Year",
      //   sort: true
      // },
      // {
      //   dataField: "faculty",
      //   text: "Faculty",
      //   sort: true
      // },
      {
        dataField: "actions",
        headerStyle: { width: "10%" },
        text: "Actions",
      },
    ],
    []
  );

  const options = useMemo(
    () => ({
      sizePerPageList: [
        {
          text: "20",
          value: 20,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
    }),
    []
  );

  const defaultSorted: [{ dataField: string; order: SortOrder }] = useMemo(
    () => [
      {
        dataField: "name",
        order: "asc",
      },
    ],
    []
  );

  const handleSchoolDelete = (schoolId: number) => {
    deleteSchool(schoolId).then(null, (err: string) =>
      toastr.error("Failed to delete the school", err)
    );
  };

  const data = useMemo(
    () =>
      schools.map((school) => ({
        id: school.id,
        name: school.name,
        type:
          school.school_type !== undefined ? (
            SchoolTypesDisplayedNames[school.school_type]
          ) : (
            <i>Not present</i>
          ),
        school_year: school.current_year ? (
          `${formatDate(
            school.current_year.start_date,
            "M/d/YYYY"
          )} to ${formatDate(school.current_year.end_date, "M/d/YYYY")}`
        ) : (
          <i>Not present</i>
        ),
        faculty: school.faculty?.length ? (
          <h4 className="blueTitle">View {school.faculty.length} users</h4>
        ) : (
          <i>Empty</i>
        ),
        actions: (
          <div className="d-flex">
            <span onClick={() => openUpsertSchoolModal(school)}>
              <FontAwesomeIcon icon={faPen} size="lg" />
            </span>

            {!isDistrictOwner ? <span
              onClick={() => handleSchoolDelete(school.id)}
              className="ml-4"
            >
              <FontAwesomeIcon
                icon={faTrashAlt}
                size="lg"
                style={{ color: "red" }}
              />
            </span> : ''}
          </div>
        ),
      })),
    [schools]
  );

  const noDataIndicator = useMemo(() => {
    if (!data.length && isLoading.getSchools) {
      return <LoadingIndicator />;
    }
    return "No Schools";
  }, [isLoading.getSchools, data]);

  return (
    <BootstrapTable
      classes="rosterTable"
      keyField="id"
      bordered={false}
      bootstrap4
      // rowEvents={rowEvents}
      data={data}
      columns={columns}
      defaultSorted={defaultSorted}
      pagination={paginationFactory(options)}
      noDataIndication={noDataIndicator}
    />
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    schools: onboarding.schools,
    isLoading: {
      getSchools: onboarding.isLoading.getSchoolsList,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      setSelectedTeacher: changeSelectedTeacher,
      openTeacherDetailsModal: openTeacherDetailsModal,
      openUpsertSchoolModal: openUpsertSchoolModal,
      deleteSchool: deleteSchool,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SchoolsTable);
