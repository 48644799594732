import {
  EvidenceColumn,
  EvidenceColumnGroup,
  EvidenceType,
} from "../../../../../../store/onboarding/types";
import { Grade } from "../../../../../../store/groups/types";

export const evidenceColumnGroupDisplayTitle = (
  evidenceColumnGroup: EvidenceColumnGroup
): string => {
  if (evidenceColumnGroup.evidence_columns.length > 0) {
    const evidence_type = evidenceColumnGroup.evidence_columns[0].evidence_type;
    switch (evidence_type) {
      case EvidenceType.Data:
        return `Assessment - ${evidenceColumnGroup.assessment.name}`;
      case EvidenceType.Interview:
        return "Interviews";
      case EvidenceType.BehaviorScreener:
          return "SRSS Data";
      case EvidenceType.TargetBehavior:
      case EvidenceType.ExistingBehavior:
        return "Target Behaviors";
      case EvidenceType.NeedAreas:{
          if(evidenceColumnGroup.meta_group_type == 'meta_group_needs'){
            return "Reading Content Areas of Need";
          } else {
            return "Behavior Content Areas of Need";
          }

      }
      case EvidenceType.DiagnosticInfo:
      case EvidenceType.DiagnosticFollowUp:
        return "Reading Content Areas of Need";
    }
  }

  return "Unknown";
};

export const evidenceColumnDisplayTitle = (
  evidenceColumn: EvidenceColumn
): string => {
  const evidence_type = evidenceColumn.evidence_type;
  switch (evidence_type) {
    case EvidenceType.Data:
      return evidenceColumn.measurement.display_name;
    case EvidenceType.Interview:
      return "Notes";
    case EvidenceType.TargetBehavior:
    case EvidenceType.ExistingBehavior:
      return "Behavior";
    case EvidenceType.BehaviorScreener:
        return "Behavior Screener";
    case EvidenceType.NeedAreas:
      return "Areas of Need";
    case EvidenceType.DiagnosticInfo:
      return "Diagnostic Info";
    case EvidenceType.DiagnosticFollowUp:
      return "Diagnostic Follow-Up";
    }

  return "Unknown";
};

export const getStudentGradeFromEcg = (
  evidenceColumnGroups: EvidenceColumnGroup[],
  studentId: number
): Grade | undefined => {
  for (const ecg of evidenceColumnGroups) {
    for (const ec of ecg.evidence_columns) {
      for (const se of ec.student_entries) {
        if (se.student_id === studentId) {
          return se.grade;
        }
      }
    }
  }
  return undefined;
};

export const getStudentSpacialEducationFromEcg = (
  evidenceColumnGroups: EvidenceColumnGroup[],
  studentId: number
): Grade | undefined => {
  for (const ecg of evidenceColumnGroups) {
    for (const ec of ecg.evidence_columns) {
      for (const se of ec.student_entries) {
        if (se.student_id === studentId) {
          return se.grade;
        }
      }
    }
  }
  return undefined;
};
