import React, { FunctionComponent, useMemo } from "react";
import { getBehaviorTallyName } from "../abc-utils";
import { BehaviorTally } from "../../../../../../store/onboarding/cases/types";

type Props = {
  bTally: BehaviorTally;
};

const FocusPanel: FunctionComponent<Props> = (props) => {
  const { bTally } = props;

  const text = useMemo(() => {
    return `${getBehaviorTallyName(bTally)} - ${bTally.count} ${
      bTally.count === 1 ? "time" : "times"
    }`;
  }, [bTally]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="abc-focus-container">Focus: {text}</div>
    </div>
  );
};

export default FocusPanel;
