import {
  ColorCriteria,
  EvidenceColumn,
  MeasurementType,
} from "../../../../../../store/onboarding/types";
import { Grade } from "../../../../../../store/groups/types";

export const getStudentsNum = (
  evidenceColumn: EvidenceColumn,
  colorCriteria: ColorCriteria,
  selectedGrade?: Grade
) => {
  if (evidenceColumn.measurement.type !== MeasurementType.CATEGORY) {
    return evidenceColumn.student_entries.filter(
      (student_entry) => {
        if (colorCriteria) {
          if(selectedGrade) {
            return +student_entry.value! >= +colorCriteria.range_min! &&
               +student_entry.value! <= +colorCriteria.range_max! &&
               student_entry.grade === selectedGrade
          } else {
            return +student_entry.value! >= +colorCriteria.range_min! &&
               +student_entry.value! <= +colorCriteria.range_max!
          }
        }
      }
    ).length;
  } else {
    return evidenceColumn.student_entries.filter(
      (student_entry) => +student_entry.value! === colorCriteria.order
    ).length;
  }
};
