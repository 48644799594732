import React, { FunctionComponent } from "react";
import StartWorkingPanel from "./StartWorkingPanel";
import IntroPanel from "./IntroPanel";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { setShowStartWorkingIntro } from "../../../../../store/onboarding/cases/actions";
import ActivePageProvider from "../../ActivePageProvider";
import { CoachDashboardTabKeys } from "../../../../pages/coach-dashboard/CoachDashboard";
import EmptyDataPeriodViewTour from "../../../../pages/coach-dashboard/tours/EmptyDataPeriodViewTour";
import useUserRole from "../../../../../utils/hooks/useUserRole";

type OwnProps = {};

type Props = OwnProps;

const StartWorkingContainer: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const showStartWorkingIntro = useSelector<ApplicationState>(
    (s) => s.cases.showStartWorkingIntro
  );

  const { isTeacher, isSupervisor } = useUserRole();

  return isSupervisor || showStartWorkingIntro ? ( //todo
    <IntroPanel onClick={() => dispatch(setShowStartWorkingIntro(false))} />
  ) : (
    <>
      <StartWorkingPanel
        onQuestionClick={() => dispatch(setShowStartWorkingIntro(true))}
      />
      <ActivePageProvider page={CoachDashboardTabKeys.YOUR_DATA_AND_INTERVIEWS}>
        <EmptyDataPeriodViewTour />
      </ActivePageProvider>
    </>
  );
};

export default StartWorkingContainer;
