import React, { FunctionComponent, useState } from "react";
import {
  AddSchoolRequest,
  School,
} from "../../../../../store/onboarding/types";
import { toastr } from "react-redux-toastr";
import {
  Alert,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Spinner,
} from "react-bootstrap";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  addSchool,
  hideUpsertSchoolModal,
  updateSchool,
} from "../../../../../store/onboarding/actions";
import { connect, useSelector } from "react-redux";

type PropsFromState = {
  // teachers: Array<TeacherInfo>;
  showModal: boolean;
  selectedSchool?: School;
  isLoading: {
    addSchool: boolean;
    updateSchool: boolean;
    getTeachers: boolean;
  };
};

type DispatchProps = {
  hideUpsertSchoolModal: () => any;
  addSchool: (school: AddSchoolRequest, districtId?: number) => any;
  updateSchool: (school: School) => any;
};

type Props = PropsFromState & DispatchProps;

const UpsertSchoolModal: FunctionComponent<Props> = ({
  showModal,
  isLoading,
  selectedSchool,
  updateSchool,
  addSchool,
  hideUpsertSchoolModal,
}) => {
  const [schoolName, setSchoolName] = useState("");
  // const [schoolType, setSchoolType] = useState<
  //   ReactSelectOption<any> | undefined
  // >(undefined);
  // const [startDate, setStartDate] = useState<Date | null>(null);
  // const [endDate, setEndDate] = useState<Date | null>(null);
  // const [faculty, setFaculty] = useState<Array<UserInfo>>([]);

  // const schoolsOptions = useMemo(
  //   () =>
  //     Object.entries(SchoolTypesDisplayedNames).map(([key, value]) => ({
  //       value: key,
  //       label: value
  //     })),
  //   []
  // );

  const handleShowModal = () => {
    if (selectedSchool) {
      setSchoolName(selectedSchool.name);
      // setSchoolType(
      //   schoolsOptions.find(so => +so.value === selectedSchool.school_type)
      // );
      // setStartDate(
      //   selectedSchool.current_year?.start_date
      //     ? moment.utc(selectedSchool.current_year.start_date).toDate()
      //     : null
      // );
      // setEndDate(
      //   selectedSchool.current_year?.end_date
      //     ? moment.utc(selectedSchool.current_year?.end_date).toDate()
      //     : null
      // );
      // setFaculty(selectedSchool.faculty);
    } else {
      setSchoolName("");
      // setSchoolType(undefined);
      // setStartDate(null);
      // setEndDate(null);
      // setFaculty([]);
    }
  };

  const districtId = useSelector<ApplicationState, number | undefined>(
    (s) => s.onboarding.modalsState.upsertSchoolModalConfig?.districtId
  );

  const handleSubmit = (event: React.FormEvent<any>) => {
    event.preventDefault();
    if (!isLoading.addSchool && !isLoading.updateSchool) {
      if (selectedSchool) {
        updateSchool({
          ...selectedSchool,
          name: schoolName,
        }).then(
          () => hideUpsertSchoolModal(),
          (err: string) => toastr.error("Failed to update the school", err)
        );
      } else {
        addSchool({
          name: schoolName,
          district: districtId,
        }).then(
          () => hideUpsertSchoolModal(),
          (err: string) => toastr.error("Failed to create the school", err)
        );
      }
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={hideUpsertSchoolModal}
      onShow={handleShowModal}
      centered
      animation={false}
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="purpleModalHeader font-weight-bold">
        <Modal.Title>
          {selectedSchool ? "Edit School" : "Add School"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <Alert variant="warning">
            The school name {selectedSchool ? "edited" : "created"} here must match the school name in your district’s data uploads exactly, 
            or the system will see the schools as different.
          </Alert>
        <Form onSubmit={handleSubmit}>
          <FormGroup controlId="name">
            <FormLabel>
              <h4>School Name</h4>
            </FormLabel>
            <FormControl
              autoFocus
              className="fullWidthInput"
              name={"name"}
              type="text"
              value={schoolName}
              onChange={(e: any) =>
                setSchoolName((e.target as HTMLInputElement).value)
              }
              required
            />
          </FormGroup>

          {/*<h4>School Type</h4>*/}
          {/*<Select*/}
          {/*  placeholder={"Select..."}*/}
          {/*  isClearable*/}
          {/*  value={schoolType}*/}
          {/*  options={schoolsOptions}*/}
          {/*  onChange={(schoolOption: any) => {*/}
          {/*    setSchoolType(schoolOption);*/}
          {/*  }}*/}
          {/*/>*/}

          {/*<h4 className="mt-3">Start of the Year</h4>*/}
          {/*<DatePicker*/}
          {/*  customInput={<CustomDateInput />}*/}
          {/*  maxDate={endDate ?? null}*/}
          {/*  selected={startDate}*/}
          {/*  onChange={date => setStartDate(date)}*/}
          {/*/>*/}

          {/*<h4 className="mt-3">End of the Year</h4>*/}
          {/*<DatePicker*/}
          {/*  customInput={<CustomDateInput />}*/}
          {/*  minDate={startDate ?? null}*/}
          {/*  selected={endDate}*/}
          {/*  onChange={date => setEndDate(date)}*/}
          {/*/>*/}

          {/*<h4 className="mt-3">Faculty</h4>*/}
          {/*<Select*/}
          {/*  isMulti*/}
          {/*  isClearable*/}
          {/*  isLoading={isLoading.getTeachers}*/}
          {/*  placeholder={"Select..."}*/}
          {/*  value={faculty}*/}
          {/*  options={teachers.map((t: TeacherInfo) => t.user)}*/}
          {/*  getOptionValue={teacher => teacher.id.toString()}*/}
          {/*  getOptionLabel={teacher => getFullName(teacher)}*/}
          {/*  onChange={(faculty: any) => {*/}
          {/*    setFaculty(faculty ?? []);*/}
          {/*  }}*/}
          {/*/>*/}

          <div className="modalActions mt-3">
            <button
              type="reset"
              className="whiteBtnMd2"
              disabled={isLoading.addSchool || isLoading.updateSchool}
              onClick={hideUpsertSchoolModal}
            >
              Cancel
            </button>
            <button className="blueBtnMd" type="submit" disabled={!schoolName}>
              {selectedSchool ? "Update School " : "Add School "}
              {(isLoading.addSchool || isLoading.updateSchool) && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    // teachers: onboarding.teachersRoster,
    showModal: onboarding.modalsState.upsertSchoolModal,
    selectedSchool: onboarding.selectedSchool,
    isLoading: {
      addSchool: onboarding.isLoading.addSchool,
      updateSchool: onboarding.isLoading.updateSchool,
      getTeachers: onboarding.isLoading.getTeachers,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideUpsertSchoolModal: hideUpsertSchoolModal,
      addSchool: addSchool,
      updateSchool: updateSchool,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UpsertSchoolModal);
