import React, { FunctionComponent, useMemo } from "react";
import { DistrictSummary } from "../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import VerticalBarChart from "./VerticalBarChart";

type OwnProps = {
  label?: string;
  goalsWithLoggedProgress: DistrictSummary;
};

type Props = OwnProps;

const GoalsWithLoggedProgressBySchoolChart: FunctionComponent<Props> = (props) => {
  const { label, goalsWithLoggedProgress } = props;
  let uniqueSchools:any[] = [];

  for (let i = 0; i < goalsWithLoggedProgress.goals.length; i++) {
    const element = goalsWithLoggedProgress.goals[i];
    if(element!.coach!.profile!.current_assignment!.schools.length > 0) {
        for (let j = 0; j < element!.coach!.profile!.current_assignment!.schools.length; j++) {
            const ele = element!.coach!.profile!.current_assignment!.schools[j];
            if(!uniqueSchools.includes(ele.id)) {
                uniqueSchools.push(ele.id)
            }  
        }
    }
  }
  
  let goalCountsBySchool:any = {};
  for (let k = 0; k < uniqueSchools.length; k++) {
      let coachCountBySchool = 0;
      for (let i = 0; i < goalsWithLoggedProgress.goals.length; i++) {
        const element = goalsWithLoggedProgress.goals[i];
        if(element!.coach!.profile!.current_assignment!.schools.length > 0) {
            for (let j = 0; j < element!.coach!.profile!.current_assignment!.schools.length; j++) {
                const ele = element!.coach!.profile!.current_assignment!.schools[j];
                if(uniqueSchools[k] == ele.id) {
                    goalCountsBySchool[ele.name] = ++coachCountBySchool;
                }  
            }
        }
      }
  }

  //console.log('goalCountsBySchool', goalCountsBySchool);

  // denumertor
let uniqueGoalSchools:any[] = [];

for (let i = 0; i < goalsWithLoggedProgress.goal_progress.length; i++) {
  const element = goalsWithLoggedProgress.goal_progress[i];
  if(element!.coach!.profile!.current_assignment!.schools.length > 0) {
      for (let j = 0; j < element!.coach!.profile!.current_assignment!.schools.length; j++) {
          const ele = element!.coach!.profile!.current_assignment!.schools[j];
          if(!uniqueGoalSchools.includes(ele.id)) {
              uniqueGoalSchools.push(ele.id)
          }  
      }
  }
}
//console.log('uniqueGoalSchools',uniqueGoalSchools)

let uniqueGoalCountsBySchool:any = {};
for (let k = 0; k < uniqueGoalSchools.length; k++) {
    let fediltyByInterventionCount = 0;
    for (let i = 0; i < goalsWithLoggedProgress.goal_progress.length; i++) {
      const element = goalsWithLoggedProgress.goal_progress[i];
      if(element!.coach!.profile!.current_assignment!.schools.length > 0) {
          for (let j = 0; j < element!.coach!.profile!.current_assignment!.schools.length; j++) {
              const ele = element!.coach!.profile!.current_assignment!.schools[j];
              if(uniqueGoalSchools[k] == ele.id) {
                  fediltyByInterventionCount = fediltyByInterventionCount + element.unique_goal_count
                  uniqueGoalCountsBySchool[ele.name] = fediltyByInterventionCount;
              }  
          }
      }
    }
}
//console.log(uniqueGoalCountsBySchool, goalCountsBySchool)
for (const iKey in goalCountsBySchool) {
  if (Object.prototype.hasOwnProperty.call(goalCountsBySchool, iKey)) {
    for (const fKey in uniqueGoalCountsBySchool) {
      if(fKey == iKey){
        goalCountsBySchool[iKey] = +((uniqueGoalCountsBySchool[fKey]/goalCountsBySchool[iKey]) * 100).toFixed(2)
        
      }
    }
    
  }
}
//console.log('goalCountsBySchool',goalCountsBySchool)
let labels:any = [];
let data:any = [];

for (const key in goalCountsBySchool) {
  labels.push(key);
  data.push(goalCountsBySchool[key]);
}

  if (!data.length) {
    return null;
  }

  return (
    <div className="district-summary-chart-container">
      {label && <h3 className="text-center">{label}</h3>}
      <div>
        <VerticalBarChart labels={labels} data={data}/>
      </div>
    </div>
  );
};

export default GoalsWithLoggedProgressBySchoolChart;
