import React, { Component, CSSProperties } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import UserAvatarSmall from "../../../UserAvatarSmall";
import GroupCountIcon from "./common/GroupCountIcon";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";

import { connect } from "react-redux";
import {
  changeSelectedInterventionGroup,
  changeSetGoalsModal,
  hideGroupingStudentsModal,
  openSetGoalChooseConcernModal,
} from "../../../../../store/onboarding/cases/actions";
import {
  GoalTypes,
  InterventionGroup,
  StudentGoal,
} from "../../../../../store/onboarding/cases/types";
import { getFullNameForStudent } from "../../../../../utils/StudentMapUtils";
import { push } from "connected-react-router";
import { IS_READY_COACH } from "../../../../../constants";
import { RouteComponentProps, withRouter } from "react-router";
import { Student } from "../../../../../store/onboarding/types";
import { showConfirmDialog } from "../../../../../store/confirm-dialog/actions";
import { CoachDashboardTabKeys } from "../../../../pages/coach-dashboard/CoachDashboard";
import useActiveTab from "../../../../pages/parent-dashboard/useActiveTab";

type PropsFromState = {
  showModal: boolean;
  interventionGroup?: InterventionGroup;
  tabName: string;
};

type DispatchProps = {
  changeSelectedInterventionGroup: (
    interventionGroupInfo?: InterventionGroup
  ) => any;
  hideGroupingStudentsModal: () => any;
  push: (location: string) => any;
  changeSetGoalsModal: (
    show: boolean,
    interventionGroup?: InterventionGroup,
    studentGoal?: StudentGoal
  ) => void;
  openSetGoalChooseConcernModal: (
    interventionGroup?: InterventionGroup,
    studentGoal?: StudentGoal
  ) => void;
  showConfirmDialog: (confirmObj: any) => any;
};

type OwnProps = {
  changeActiveTab?: (activeTab: any) => any
}

type Props = PropsFromState & DispatchProps & RouteComponentProps<any> & OwnProps;

type State = {
  dialogClassName: string;
  dialogStyle: CSSProperties;
  modalBackdrop: boolean;
};

const useActiveTabWrapper = (Component:any) => (props:Props) => {
  const {changeActiveTab} = useActiveTab()
  return <Component {...props} changeActiveTab={changeActiveTab} />;
};
class GroupingStudentsModal extends Component<Props, State> {
  state: Readonly<State> = {
    dialogClassName: "",
    dialogStyle: {},
    modalBackdrop: true,
  };

  handleSetGoalsClick = (student?: Student) => {
    const { interventionGroup } = this.props;
    //this.handleHide();
    if (
      IS_READY_COACH &&
      !this.props.location.pathname?.includes("/cases/groups", 0)
    ) {
      if(interventionGroup?.goal_type == GoalTypes.BEHAVIOR) {
        this.props.changeActiveTab!(CoachDashboardTabKeys.YOUR_BEHAVIOUR_ASSIGNMENTS)
        this.props.push('/cases/'+ CoachDashboardTabKeys.YOUR_BEHAVIOUR_ASSIGNMENTS);
      } else {
        this.props.changeActiveTab!(CoachDashboardTabKeys.YOUR_READING_ASSIGNMENTS);
        this.props.push('/cases/'+ CoachDashboardTabKeys.YOUR_READING_ASSIGNMENTS);
      }
    }

    this.props.showConfirmDialog({
      onConfirm: () => {
        this.props.hideGroupingStudentsModal();
        this.props.openSetGoalChooseConcernModal(interventionGroup)
      },
      centered: true,
      title: "Confirmation",
      text: (
        <>
          The first goal set for a group is the primary goal. 
          Progress towards the primary goal is used to determine a student’s response to intervention, 
          with automated alerts providing guidance for decision making. 
        </>
      ),
      confirmButtonText: "Confirm",
      timerElipseLeft: true,
      countDownTimer: 3,
    });
    
    // if (interventionGroup?.goal_type === GoalTypes.ACADEMIC) {
    // }else if (interventionGroup?.goal_type === GoalTypes.BEHAVIOR) {
    //   this.props.openSetGoalChooseConcernModal(interventionGroup);
    // } else { // show old behavior screen
    //   this.props.changeSetGoalsModal(true, interventionGroup);
    // }
  };

  handleHide = () => {
    this.props.hideGroupingStudentsModal();
  };

  // hideModalDialog = () => {
  //   const elemTab = document
  //     .getElementsByClassName("onboardingSecondStepTab nav-item")
  //     .item(1);
  //   const elemModal = document.getElementsByClassName("modal-dialog").item(0);
  //   if (elemTab && elemModal) {
  //     const tabRects = elemTab.getBoundingClientRect();
  //     const modalRects = elemModal.getBoundingClientRect();
  //     const modalCenterTop =
  //       modalRects.top > 0
  //         ? modalRects.height / 2 + modalRects.top
  //         : modalRects.height / 2 + 28; //28 - star modal top position (before scrolling)
  //     const topPos = tabRects.height / 2 + tabRects.top - modalCenterTop + 20;
  //     const style = {
  //       "--left-pos": "120px",
  //       "--top-pos": topPos + "px",
  //       "--start-left-pos": modalRects.left + "px",
  //       "--start-top-pos": modalRects.top + "px"
  //     } as CSSProperties;
  //     this.setState({
  //       dialogClassName: "zoomOut",
  //       dialogStyle: style,
  //       modalBackdrop: false
  //     });
  //   }
  // };

  clearDialogClassName = () => {
    this.setState({
      dialogClassName: "newGroupModal",
      dialogStyle: {},
      modalBackdrop: true,
    });
  };

  render() {
    const { showModal, interventionGroup } = this.props;
    if (!interventionGroup) return null;
    return (
      <Modal
        id={`new-group-modal`}
        dialogClassName={this.state.dialogClassName}
        style={this.state.dialogStyle}
        backdrop={this.state.modalBackdrop}
        onAnimationEnd={this.handleHide}
        onShow={this.clearDialogClassName}
        animation={false}
        backdropClassName="customDarkModalBackdrop in"
        show={showModal}
        onHide={this.handleHide}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "rgb(255, 171, 0)" }}
        >
          <Modal.Title className="text-center">
            <h6>New group</h6>
            <h3 className="font-weight-bold purpleText">
              {interventionGroup.name}
            </h3>
            <h6>
              <UserAvatarSmall
                userInfo={
                  interventionGroup.teacher_assignment &&
                  interventionGroup.teacher_assignment.user
                }
              />{" "}
              {interventionGroup.teacher_assignment &&
                `${interventionGroup.teacher_assignment.user.first_name} ${interventionGroup.teacher_assignment.user.last_name}`}
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-center">
            <Col style={{ textAlign: "right" }}>
              <GroupCountIcon
                svgSize={150}
                studentsNum={interventionGroup.students.length}
              />
            </Col>
            <Col>
              <div className="newGroupModalNames">
                <div>
                  {interventionGroup.students.map((student) => (
                    <div key={student.id}>{getFullNameForStudent(student)}</div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
          <div
            className="d-flex flex-column align-items-center m-auto"
            style={{ width: "60%" }}
          >
            <Button
              data-cy="set-goals-btn"
              bsPrefix="b"
              className="blueBtnSm"
              onClick={() => this.handleSetGoalsClick()}
              style={{
                marginBottom: "10px",
                width: "100%",
                fontSize: "0.9rem",
              }}
            >
              Set goals
            </Button>
            <Button
              bsPrefix="b"
              className="whiteBtnSm"
              onClick={this.handleHide}
              style={{ width: "100%", fontSize: "0.9rem" }}
            >
              Create another group
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({ cases }: ApplicationState): PropsFromState => {
  return {
    showModal: cases.modalsState.groupingStudentsModal,
    interventionGroup: cases.selectedInterventionGroup,
    tabName: cases.tabName
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      changeSelectedInterventionGroup: changeSelectedInterventionGroup,
      hideGroupingStudentsModal: hideGroupingStudentsModal,
      changeSetGoalsModal: changeSetGoalsModal,
      openSetGoalChooseConcernModal: openSetGoalChooseConcernModal,
      showConfirmDialog: showConfirmDialog,
      push: push,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(useActiveTabWrapper(GroupingStudentsModal)));
