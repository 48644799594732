import React, { FunctionComponent, useMemo } from "react";
import Select, { ValueType } from "react-select";
import { BasicUserInfo, EvidencePeriodData, ReactSelectOption, StudentClass, TeacherClass } from "../../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { changeSelectedClass } from "../../../../../../../store/onboarding/actions";
import { connect, useSelector } from "react-redux";
import { changeClassIdFilter } from "../../../../../../../store/dataFilter/actions";
import { OverlayTrigger, Popover } from "react-bootstrap";

type StateProps = {
  classIdFilter?: number[];
  teacherIdFilter?: number[];
  teachersClasses: Array<TeacherClass>;
  isLoading: {
    getClassesAssignedToTeacher: boolean;
  };
};

type DispatchProps = {
  changeSelectedClass: (selectedClass?: TeacherClass) => any;
  changeClassFilter: (id?: number[]) => any;
};

type Props = StateProps & DispatchProps;

const ClassesFilter: FunctionComponent<Props> = ({
  teachersClasses,
  classIdFilter,
  isLoading,
  changeClassFilter,
  changeSelectedClass,
  teacherIdFilter
}) => {
  const evidencePeriodData = useSelector<
    ApplicationState,
    EvidencePeriodData
  >((s) => s.onboarding.evidencePeriodData!);

  const teacherClassesByDataPeriod: any[] = useMemo(() => 
    evidencePeriodData!.student_rows.flatMap((std) => std.student?.teachers || []) || []
  ,[evidencePeriodData])

  const teacherClassesOptions: Array<ReactSelectOption<TeacherClass>> = useMemo(() => {
    const teacherClassesDp: StudentClass[] = teacherClassesByDataPeriod.flatMap((teacher:BasicUserInfo) => teacher.classes || []);
    let filterTeacherClasses =  teachersClasses.filter((teacherClass: TeacherClass) => teacherClassesDp?.some((dpClass) => teacherClass.id == dpClass.id)) || [];
    if(!filterTeacherClasses.length) {
      changeClassFilter();
    }
    return (filterTeacherClasses || []).map((classInfo: TeacherClass) => ({
      label: classInfo.name,
      value: classInfo,
    }));
  },[teacherClassesByDataPeriod, teachersClasses])
  
  const handleTeacherClassChange = (option: ValueType<ReactSelectOption<TeacherClass>, any>) => {
    const classIds: any[] = (option as ReactSelectOption<TeacherClass>[])?.map((classes) => classes.value.id) ?? [];
    // RC-1697 Data table filter- allow more than one option in a single filter
    // currently we are passing only single teacher class frome here
      const teacherClass: any[] = (option as ReactSelectOption<TeacherClass>[])?.map((classes) => classes.value) ?? [];
      if(teacherClass.length) changeSelectedClass(teacherClass[0]);
    //end RC-1697
    if (classIds.length) {
      changeClassFilter(classIds);
    } else {
      changeClassFilter();
    }
  };

  return (
    <>
      <h4 className="font-weight-bold m-0">Class(es)</h4>
      {!teacherIdFilter?.length ? 
        <OverlayTrigger
              trigger="hover"
              placement="top"
              overlay={
                <Popover id="classData" style={{ width: 300 }}>
                  <Popover.Content>
                    <div className="dataFiltersSelect">
                      To filter by class, select an educator who has a class.
                    </div>
                  </Popover.Content>
                </Popover>
              }
            >
              <div>
                <Select
                isMulti
                isClearable
                isLoading={isLoading.getClassesAssignedToTeacher}
                styles={{ menu: (styles) => ({ ...styles, zIndex: 100 }) }}
                value={teacherClassesOptions.filter((x) => classIdFilter?.includes(x.value.id!))}
                options={teacherClassesOptions}
                onChange={handleTeacherClassChange}
                isDisabled={!teacherIdFilter?.length || !teacherClassesOptions.length}
              />
              </div>
            </OverlayTrigger>
      :
        <Select
          isMulti
          isClearable
          isLoading={isLoading.getClassesAssignedToTeacher}
          styles={{ menu: (styles) => ({ ...styles, zIndex: 100 }) }}
          value={teacherClassesOptions.filter((x) => classIdFilter?.includes(x.value.id!))}
          options={teacherClassesOptions}
          onChange={handleTeacherClassChange}
          isDisabled={!teacherIdFilter.length || !teacherClassesOptions.length}
        />
      }
    </>
  );
};

const mapStateToProps = ({
  dataFilter,
  onboarding,
}: ApplicationState): StateProps => {
  return {
    isLoading: {
      getClassesAssignedToTeacher:
        onboarding.isLoading.getClassesAssignedToTeacher,
    },
    classIdFilter: dataFilter.classIdFilter,
    teacherIdFilter: onboarding.teacherIdFilter,
    teachersClasses: onboarding.teachersClasses,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      changeClassFilter: changeClassIdFilter,
      changeSelectedClass: changeSelectedClass,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ClassesFilter);
