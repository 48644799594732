import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";
import BootstrapTable, { SortOrder, SearchProps, ColumnDescription } from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { push } from "connected-react-router";
import {
  publishReleaseNote,
  getAllReleaseNotes,
  setShowReleaseModal,
  updateReleaseNote,
} from "../../../../../../store/superuser/actions";
import ReleaseNotesTableActions from "./ReleaseNotesTableActions";
import { Release_Notes, SuperuserState } from "../../../../../../store/superuser/types";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEdit, faTimes, faTimesCircle, faUpload } from "@fortawesome/free-solid-svg-icons";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";
import { useLoading } from "../../../../../../utils/hooks/useLoading";
import { toastr } from "react-redux-toastr";
import {
  formatDate
} from "../../../../../utils/DateTimeUtils";

const columns: ColumnDescription[] = [
  {
    dataField: "title",
    text: "Title",
    sort: true,
  },
  {
    dataField: "is_published",
    text: "Published",
    sort: true,
    formatter: (value, row) => (
      <FontAwesomeIcon
        size="lg"
        icon={value ? faCheckCircle : faTimes}
      />
    )
  },
  {
    dataField: "publish_date",
    text: "Published Date",
    sort: true,
  },
  {
    dataField: "actions",
    text: "Actions",
    sort: false,
  },
];

const { SearchBar } = Search;

const ClearButton = (props:any) => {
  const handleClick = () => {
    props.onSearch("");
  };
  return (
    <button className="blueBtnSm" onClick={handleClick}>
      <FontAwesomeIcon icon={faTimesCircle} style={{ color: "white" }} />{" "}
      Clear
    </button>
  );
};

const defaultSorted: [{ dataField: string; order: SortOrder }] = [
  {
    dataField: "title",
    order: "asc",
  },
];

const options = {
  sizePerPageList: [
    {
      text: "20",
      value: 20,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
  ],
  hideSizePerPage: false,
};

type TableRow = {
  title: string;
  description: string;
  is_published?: boolean;
};

const ReleaseNotesTable: FunctionComponent = () => {
  const { releaseNotes, isLoading } = useSelector<ApplicationState, SuperuserState>(
    (s) => s.superuser
  );
  const [isFrontEmpty, setIsFrontEmpty] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllReleaseNotes());
  }, []);

  const publishReleaseNote = (releaseNote: Release_Notes) => {
    dispatch<any>(
      updateReleaseNote({
        ...releaseNote,
        is_published: true
      })
    ).then(
      (releaseNote: Release_Notes) => {
        toastr.success('Publish Release: ', 'Email Publish process has started.');
      }
    );
  }

  const handlePublish = (releaseNote: Release_Notes) => {
    if(!releaseNote.is_published) {
        dispatch(showConfirmDialog({
          title: "Publish Confirmation",
          text:
            "Are you sure want to publish and send release notes to all users ?",
          onConfirm: () => {
            publishReleaseNote(releaseNote);
          },
          confirmButtonText: "Continue",
        }))
      } 
  }

  const tableData: TableRow[] = useMemo(
    () =>
      releaseNotes.map((release) => ({
        title: release.title,
        description: release.description,
        is_published: release.is_published,
        publish_date: release.publish_date ? formatDate(release.publish_date!) : '',
        actions: (
          <div>
            {!release.is_published ? 
            <>
              <OverlayTrigger
                overlay={
                  <Tooltip id="editIcon" className="customInfoTooltip">
                    Edit
                  </Tooltip>
                }
              >
                <span
                  className="mr-2 pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(setShowReleaseModal({ show: true, releaseNote: release }));
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </span>
              </OverlayTrigger> 
              <OverlayTrigger
                overlay={
                  <Tooltip id="deleteIcon" className="customInfoTooltip">
                    Publish Release Note
                  </Tooltip>
                }
              >
                <span
                  className="badge badge-success pointer"
                  onClick={() => handlePublish(release)}
                >
                  Publish
                </span>
              </OverlayTrigger>
            </>
            : ''}
            {/* <UserStatusButton
              className="mr-2 pointer"
              userId={user.id}
              isActive={!user.account_disabled}
            /> */}
            {/* <OverlayTrigger
              overlay={
                <Tooltip id="deleteIcon" className="customInfoTooltip">
                  Delete
                </Tooltip>
              }
            >
              <span
                className="pointer"
                onClick={handleShowConfirmDialog(user.id)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </OverlayTrigger> */}
          </div>
        ),
      })),
    [releaseNotes]
  );

  const isEmpty = releaseNotes.length === 0 && !isLoading.getAllReleaseNotes;

  const searchedResult = (newResult:any) => {
    if(newResult.length == 0) {
      setIsFrontEmpty(true);
    } else {
      setIsFrontEmpty(false);
    }
  }

  return (
    <div className="onboardingContainer">
      

  <ToolkitProvider
    keyField="id"
    data={ tableData }
    columns={ columns }
    search = { {
      afterSearch: (newResult) => searchedResult(newResult)
    }}
  >
    {
      (props:any) => (
        <div>
          <div className="row">
            <div className="col-3 pr-0"><ReleaseNotesTableActions /></div>
            <div className="col-9 text-right pl-0"><SearchBar className="h-31" { ...props.searchProps } /> <ClearButton {...props.searchProps} /></div>
          </div>

          <BootstrapTable
            { ...props.baseProps }
            filter={filterFactory()}
            classes="rosterTable"
            keyField="id"
            bordered={false}
            bootstrap4
            data={tableData}
            columns={columns}
            pagination={paginationFactory({...options, hideSizePerPage: isFrontEmpty ? true : false})}
            defaultSorted={defaultSorted}
            noDataIndication={isEmpty || isFrontEmpty ? "No release notes available" : <LoadingIndicator />}
            // rowEvents={tableRowEvents}
          />
        </div>
      )
    }
  </ToolkitProvider>
      
      
    </div>
  );
};

export default ReleaseNotesTable;
