import React, { FunctionComponent, useMemo } from "react";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { hideReleaseModal } from "../../../store/help/actions";
import { ApplicationState } from "../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

type OwnProps = {};

type Props = OwnProps;

const LatestReleaseModal: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const show = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.help.modalsState.latestReleaseModal
  );

  const latestReleaseNotes = useSelector(
    (s:ApplicationState) => s.help.latestRelease
  );

  const onClose = () => {
    dispatch(hideReleaseModal());
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      animation={false}
      size="lg"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header className="purpleModalHeader">
        <Modal.Title>Latest Release</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered striped>
            <tr>
              <td colSpan={2} className="font-weight-bold">Latest Release</td>
            </tr>
        </Table>
        <div className="modalActions">
          <div />
          <button
            className="blueBtnSm"
            type="button"
            onClick={onClose}
          >
            {'I understand'}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LatestReleaseModal;
