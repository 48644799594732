import { bindActionCreators, Dispatch } from "redux";
import { hideUploadStudentDataModal, openUploadStudentDataModal, uploadCsvFile, uploadMultipleCsvFile } from "../../store/onboarding/actions";
import { connect } from "react-redux";
import FileReader from "../../components/common/file-reader/FileReader";

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      uploadCsvFile: uploadCsvFile,
      uploadMultipleCsvFile: uploadMultipleCsvFile
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(FileReader);
