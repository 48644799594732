import React, { FunctionComponent } from "react";
import { InterventionGroup } from "../../../../../../../../store/onboarding/cases/types";
import InterventionGroupLoggingTable from "../../../implementation-check/InterventionGroupLoggingTable";

type OwnProps = {
  interventionGroup: InterventionGroup;
  studentId?: number;
};

type Props = OwnProps;

const InterventionGroupLoggingTab: FunctionComponent<Props> = (props) => {
  const { interventionGroup, studentId } = props;
  return (
    <>
      <InterventionGroupLoggingTable interventionGroup={interventionGroup} studentId={studentId}/>
    </>
  );
};

export default InterventionGroupLoggingTab;
