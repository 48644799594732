import React, { ChangeEvent, FunctionComponent, useMemo } from "react";
import { ImplementationCheck } from "../../../../../../store/onboarding/meeting-module/types";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { Alert, Form, OverlayTrigger, Popover } from "react-bootstrap";
import moment from "moment";

type OwnProps = {
  implCheck: ImplementationCheck;
  interventionGroup: InterventionGroup;
  onClose: Function;
};

type Props = OwnProps;

const ImplementationCheckAlert: FunctionComponent<Props> = (props) => {
  const { interventionGroup, implCheck, onClose } = props;

  const intervention = useMemo(() => {
    return interventionGroup.interventions.find(
      (int) => int.id === implCheck.intervention
    );
  }, [interventionGroup.interventions, implCheck.intervention]);

  if (!intervention) {
    return null;
  }

  return (
    <div className="implementation-check-alert" onClick={() => onClose()}>
      <div onClick={(e) => e.stopPropagation()}>
        <h2>
          {intervention.name} (
          {moment(implCheck.observed_date).format("MM-DD-YYYY")})
        </h2>
        <h3 className="mb-2">Steps:</h3>
        <Alert
        variant={"info"}
        >
          <p className="font-weight-bold text-left mb-1">Indicators: </p>
          <div className="d-flex mb-2">
            <Form.Check
              label
              custom
              className="observedItemCheckbox"
              type="checkbox"
              checked={true}
            /> P : Step was implemented as planned.
          </div>
          <div className="d-flex">
            <Form.Check
              label
              custom
              className="notPlannedItemCheckbox"
              type="checkbox"
              checked={true}
            /> NP : A decision was made not to include the step in the intervention (i.e., not planned).
          </div>
        </Alert>
        <div className="text-left">
          <div className="d-flex mb-1 stepHeader">
            <div className="col-auto font-weight-bold">P</div>
            <div className="col-auto font-weight-bold pl-1">NP</div>
          </div>
          {intervention.checklist_items.sort((a,b) => a.order < b.order ? -1 : 1).map((ci, index) => {
            const isChecked = implCheck.logged_items?.some(
              (li) => li.item == ci.id
            );
            const isNotPlannedChecked = implCheck.not_applicable_items?.some(
              (li) => li.item == ci.id
            );
            return (
              <Alert
                key={ci.id}
                variant={isChecked ? "success" : isNotPlannedChecked ? "secondary" : ''}
                className="p-1 d-flex"
              >
                <div className="d-flex justify-content-between">
                <OverlayTrigger
                  overlay={
                    <Popover id='Planned'>
                      <Popover.Content>Planned</Popover.Content>
                    </Popover>
                  }
                >
                  <Form.Check
                    label
                    custom
                    className="observedItemCheckbox"
                    type="checkbox"
                    checked={isChecked}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Popover id='NotPlanned'>
                      <Popover.Content>Not Planned</Popover.Content>
                    </Popover>
                  }
                >
                  <Form.Check
                    label
                    custom
                    className="notPlannedItemCheckbox"
                    type="checkbox"
                    checked={isNotPlannedChecked}
                  />
                </OverlayTrigger>
                </div>
                <div>
                  <strong>
                    Step {index + 1}: {ci.title}
                  </strong>
                  <p className="mb-0">{ci.details}</p>
                </div>
              </Alert>
            );
          })}
          {implCheck?.not_planned_reason 
          ? <p><span className="font-weight-bold">Not Planned Reason: </span>{implCheck.not_planned_reason}</p> 
          : ''
          }
          {implCheck?.fidelity_notes 
          ? <p><span className="font-weight-bold">Notes: </span>{implCheck.fidelity_notes}</p> 
          : ''
          }
        </div>
        <div className="text-right">
          <button className="blueBtnSm" onClick={() => onClose()}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImplementationCheckAlert;
