import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import ProfileHeader from "../ProfileHeader";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  getNotificationSettings,
  updateNotificationSettings,
} from "../../../../../store/notifications/actions";
import LoadingIndicator from "../../../onboarding/LoadingIndicator";
import { NotificationSettings } from "../../../../../store/notifications/types";
import { toastr } from "react-redux-toastr";
import { useIntl } from "react-intl";

type PropsFromState = {
  notificationSettings: Array<NotificationSettings>;
  isLoading: {
    getNotificationSettings: boolean;
    updateNotificationSettings: boolean;
  };
  errors: {
    getNotificationSettings?: string;
    updateNotificationSettings?: string;
  };
};

type DispatchProps = {
  getNotificationSettings: () => any;
  updateNotificationSettings: (preferences: {
    [id: number]: {
      email: boolean;
      in_app: boolean;
    };
  }) => any;
};

type Props = PropsFromState & DispatchProps;

const NotificationsTab: FunctionComponent<Props> = ({
  notificationSettings,
  errors,
  isLoading,
  getNotificationSettings,
  updateNotificationSettings,
}) => {
  const intl = useIntl();

  useEffect(() => {
    getNotificationSettings();
  }, []);
  useEffect(() => {
    if (errors.getNotificationSettings) {
      toastr.error("Error", errors.getNotificationSettings);
    }
  }, [isLoading.getNotificationSettings]);

  useEffect(() => {
    if (errors.updateNotificationSettings) {
      toastr.error("Error", errors.updateNotificationSettings);
    }
  }, [isLoading.updateNotificationSettings]);

  const handleInAppPreferenceChange = (settings: NotificationSettings) => {
    updateNotificationSettings({
      [settings.id]: {
        ...settings.preferences,
        in_app: !settings.preferences.in_app,
      },
    });
  };

  const handleEmailPreferenceChange = (settings: NotificationSettings) => {
    updateNotificationSettings({
      [settings.id]: {
        ...settings.preferences,
        email: !settings.preferences.email,
      },
    });
  };

  return (
    <div className="dataContainer">
      <ProfileHeader isNotificationsTab />
      <div>
        <h2 className="font-weight-bold mt-2">
          {intl.formatMessage({
            id: "app.dashboard.notifications.title",
          })}
        </h2>
        {intl.formatMessage({
          id: "app.dashboard.notifications.tab.info",
        })}
      </div>
      {isLoading.getNotificationSettings ? (
        <LoadingIndicator />
      ) : (
        <>
          <Row className="mt-4">
            <Col md={8}>
              <span className="font-weight-bold">
                {intl.formatMessage({
                  id: "app.dashboard.notifications.tab.whichNotification.label",
                })}
              </span>
            </Col>
            <Col md={2} className="notificationsHeader">
              {intl.formatMessage({
                id: "app.dashboard.notifications.notification.title",
              })}
            </Col>
            <Col md={2} className="notificationsHeader">
              {intl.formatMessage({
                id: "app.forms.email.label",
              })}
            </Col>
          </Row>
          <hr />
          <>
            {notificationSettings.map((notification) => (
              <Row key={notification.id} className="mt-4">
                <Col md={8}>{notification.label}</Col>
                <Col md={2} className="displayCentered">
                  <div
                    onClick={() => handleInAppPreferenceChange(notification)}
                  >
                    <FontAwesomeIcon
                      icon={faBell}
                      style={
                        notification.preferences.in_app
                          ? { color: "#e65101" }
                          : { color: "#b5bdc1" }
                      }
                      className={"fontAwesomeIcon pointer"}
                      size={"lg"}
                    />
                  </div>
                </Col>
                <Col md={2} className="displayCentered">
                  <div
                    onClick={() => handleEmailPreferenceChange(notification)}
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={
                        notification.preferences.email
                          ? { color: "#e65101" }
                          : { color: "#b5bdc1" }
                      }
                      className={"fontAwesomeIcon pointer"}
                      size={"lg"}
                    />
                  </div>
                </Col>
              </Row>
            ))}
          </>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({
  notifications,
}: ApplicationState): PropsFromState => {
  return {
    notificationSettings: notifications.notificationSettings,
    isLoading: {
      getNotificationSettings: notifications.isLoading.getNotificationSettings,
      updateNotificationSettings:
        notifications.isLoading.updateNotificationSettings,
    },
    errors: {
      getNotificationSettings: notifications.errors.getNotificationSettings,
      updateNotificationSettings:
        notifications.errors.updateNotificationSettings,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getNotificationSettings: getNotificationSettings,
      updateNotificationSettings: updateNotificationSettings,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsTab);
