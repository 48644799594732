import { OverlayTrigger, Popover } from "react-bootstrap";
import React, { useMemo } from "react";
import { getFullNameForStudent } from "../../../../../../utils/StudentMapUtils";
import { Student } from "../../../../../../store/onboarding/types";
import { useIntl } from "react-intl";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  GroupRecommendation,
  InterventionGroup,
} from "../../../../../../store/onboarding/cases/types";
import useGroupCardDragging from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/hooks/useGroupCardDragging";
import { ApplicationState } from "../../../../../../store";

type Props = {
  interventionGroup: InterventionGroup;
  student: Student;
  isReadonly?: boolean;
  isIntGrpIDsWithReadAccess?: boolean;
};

const NAME_DISPLAYED_CHARACTERS_NUM = 16;
const replacedString = "...";

const GroupStudentName = (props: Props) => {
  const intl = useIntl();

  const displayGroup = useSelector((s: ApplicationState) => s.cases.displayGroupName);

  const isStudentDetail = useSelector<
  ApplicationState
  >((s) => s.cases.isStudentDetail);

  const { student, interventionGroup } = props;

  const { onDragStart, onDragEnd } = useGroupCardDragging();

  const dispatch = useDispatch();

  const studentName = useMemo(() => {
    const name = getFullNameForStudent(student);
    if (name.length > NAME_DISPLAYED_CHARACTERS_NUM)
      return (
        name.slice(0, NAME_DISPLAYED_CHARACTERS_NUM - replacedString.length) +
        replacedString
      );
    return name;
  }, [student]);

  const handleClickName = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (student.unique_id) {
      dispatch(push(`/rosters/students/${student.unique_id}`));
    }
  };

  return (
    <OverlayTrigger
      overlay={
        <Popover id={"studentUniqueIdPopover"}>
          <Popover.Content className="d-flex flex-column">
            <h4>{getFullNameForStudent(student)}</h4>
            <span>
              {intl.formatMessage(
                {
                  id: "app.forms.uniqueId.label",
                },
                {
                  count: <strong key={student.id}>{student.unique_id}</strong>,
                }
              )}
            </span>
            {intl.formatMessage({
              id: "app.navigation.clickToStudentProfile",
            })}
          </Popover.Content>
        </Popover>
      }
    >
      <div
        className="studentGroupCell"
        onClick={handleClickName}
        draggable={(displayGroup=='archived' || props.isReadonly || props.isIntGrpIDsWithReadAccess || isStudentDetail) ? false : true}
        onDragStart={onDragStart(student, interventionGroup as any)}
        onDragEnd={onDragEnd}
      >
        <div
          className="studentGroupCellName text-nowrap"
          style={{ fontSize: "10pt" }}
        >
          {studentName}
        </div>
      </div>
    </OverlayTrigger>
  );
};

export default GroupStudentName;
