import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "react-bootstrap";
import { TargetBehaviorGroup } from "../utils";

type OwnProps = {
  isNegative?: boolean;
  targetBehaviorGroups: Array<TargetBehaviorGroup>;
  selectedBehavior?: TargetBehaviorGroup;
  handleBehaviorSelect: (behavior: TargetBehaviorGroup) => any;
};

type Props = OwnProps;

type State = {
  collapsed: boolean;
};

class BehaviorDomainsTable extends Component<Props, State> {
  //todo remove
  state: Readonly<State> = {
    collapsed: true,
  };

  handlePositiveBehaviorsCollapse = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  render() {
    const { isNegative, targetBehaviorGroups, selectedBehavior } = this.props;
    return (
      <>
        <div
          className="findTargetTableHeader"
          aria-controls="target-behavior-groups"
          aria-expanded={this.state.collapsed}
          onClick={this.handlePositiveBehaviorsCollapse}
        >
          <FontAwesomeIcon
            icon={this.state.collapsed ? faCaretDown : faCaretRight}
            size="3x"
          />{" "}
          {isNegative
            ? "Negative behaviors to decrease"
            : "Positive behaviors to increase"}
        </div>
        <Collapse in={this.state.collapsed}>
          <div id="target-behavior-groups" className="findTargetTableBody">
            {targetBehaviorGroups.map((behavior, index) => {
              const active =
                selectedBehavior &&
                selectedBehavior.domain.id === behavior.domain.id;
              return (
                <div
                  key={index}
                  className={active ? "findTargetTableBodyActiveItem" : ""}
                  onClick={this.props.handleBehaviorSelect(behavior)}
                >
                  {behavior.domain.name}
                </div>
              );
            })}
          </div>
        </Collapse>
      </>
    );
  }
}

export default BehaviorDomainsTable;
