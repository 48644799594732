import { useState, useEffect } from "react";

export enum WindowSize {
  SM = "sm",
  MD = "md",
  LG = "LG",
  XLG = "xlg",
}

function getBreakPoint(windowWidth: number) {
  if (windowWidth) {
    if (windowWidth < 480) {
      return WindowSize.SM;
    } else if (windowWidth < 1024) {
      return WindowSize.MD;
    } else if (windowWidth < 1200) {
      return WindowSize.LG;
    } else {
      return WindowSize.XLG;
    }
  } else {
    return undefined;
  }
}

function useWindowSize() {
  const isWindowClient = typeof window === "object";

  const [windowSize, setWindowSize] = useState(
    isWindowClient ? getBreakPoint(window.innerWidth) : undefined
  );

  useEffect(() => {
    function setSize() {
      setWindowSize(getBreakPoint(window.innerWidth));
    }

    if (isWindowClient) {
      window.addEventListener("resize", setSize);
      return () => window.removeEventListener("resize", setSize);
    }
  }, [isWindowClient, setWindowSize]);

  return windowSize;
}

export default useWindowSize;
