import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { ListGroup, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import CreatableSelect from "react-select/creatable";
import Select, { ValueType } from "react-select";
import { getFullName } from "../../../../../../../utils/NamesUtils";
import {
  ReactSelectOption,
  Student,
  StudentClass,
  TeacherClass,
  TeacherInfo,
  TeacherStudentClass,
} from "../../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  assignClassToTeacher,
  updateClassesLinkedToStudent,
} from "../../../../../../../store/onboarding/actions";
import { connect, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { USState } from "../../../../../States";

type OwnProps = {
  students: Array<Student>;
  onBackClick: () => void;
  onReset: () => void;
};

type PropsFromState = {
  allClasses: Array<TeacherStudentClass>;
  classesLinkedToStudent: Array<TeacherStudentClass>;
  teachersRoster: Array<TeacherInfo>;
  isLoading: {
    getTeachers: boolean;
    getClassesLinkedToStudent: boolean;
    assignClassToTeacher: boolean;
    updateClassesLinkedToStudent: boolean;
  };
};

type DispatchProps = {
  assignClassToTeacher: (
    teacherId: number,
    className: string,
    students: Array<Student>,
    grade?: string,
    dataPeriodId?: number,
  ) => any;
  updateClassesLinkedToStudent: (
    students: Array<Student>,
    classesIds: Array<number>,
    student?: any,
    dataPeriodId?: number,
  ) => any;
};

type Props = OwnProps & PropsFromState & DispatchProps;

const StudentClassesTab: FunctionComponent<Props> = ({
  teachersRoster,
  allClasses,
  classesLinkedToStudent,
  updateClassesLinkedToStudent,
  assignClassToTeacher,
  isLoading,
  students,
  onBackClick,
  onReset
}) => {
  const [selectedClasses, setSelectedClasses] = useState<
    Array<TeacherStudentClass>
  >([]);

  const [newClassName, setNewClassName] = useState<string | undefined>(
    undefined
  );

  const [selectedTeacher, setSelectedTeacher] = useState<
    ReactSelectOption<number> | undefined
  >(undefined);

  const dataPeriod = useSelector(
    (s: ApplicationState) => s.onboarding.currentDataPeriod
  );

  const teachersRosterAsOptions: Array<ReactSelectOption<number>> = useMemo(
    () => {
      //console.log('students', students)
      // teachersRoster
      //   .filter((tr) =>
      //     (student.teachers || []).some((st) => st.id === tr.user.id)
      //   )
      const keys = ['id'];
     
      return students
      .map((item:any) => item.teachers)
      .reduce((prev, curr) => prev.concat(curr), [])
      .filter((item:any, i:number, arr:any) => arr.indexOf(item) === i)
      .filter((value:any, index:number, self:any) => 
        self.findIndex((v:any) => keys.every(k => v[k] === value[k])) === index
      ).map((teacher:any) => {
          return {
            value: teacher.id,
            label: teacher.first_name
              ? teacher.first_name + " " + teacher.last_name
              : teacher.last_name,
          };
        })
  }  ,
    [teachersRoster, students]
  );

  useEffect(() => {
    if (students) {
      //setSelectedClasses(classesLinkedToStudent);
      setNewClassName(undefined);
    }
  }, [students, classesLinkedToStudent]);

  const handleChangeClasses = (
    options: ValueType<TeacherStudentClass, true>
  ) => {
    setSelectedClasses((options as Array<TeacherStudentClass>) ?? []);
  };

  const handleCreateClass = (value: string) => {
    setNewClassName(value);
  };

  const handleSaveClasses = () => {
    if (newClassName) {
      if (selectedTeacher) {
        assignClassToTeacher(selectedTeacher.value, newClassName, 
          students, students[0].grade!, dataPeriod?.id
        ).then(
          (addedClass: TeacherClass) => {
            updateClassesLinkedToStudent(students, [
              ...selectedClasses.map((c) => c.class.id),
              addedClass.id!
            ],0, dataPeriod?.id)
            .then(onBackClick())
            .then(() => {
              setNewClassName(undefined);
              setSelectedTeacher(undefined);
              onReset();
            });
            setNewClassName(undefined);
          },
          (err: string) => toastr.error("Failed to create the class", err)
        ).catch((error: Error) => console.log('1',error));
      }
    } else {
      updateClassesLinkedToStudent(
        students,
        Array.isArray(selectedClasses) ? selectedClasses.map((c) => c.class.id) 
        : [selectedClasses['class']['id']],
        0,
        dataPeriod?.id
      ).then(() => {
        setSelectedClasses([]);
        setSelectedTeacher(undefined);
        onReset(); 
      }).then(onBackClick()).catch((error: Error) => console.log('2', error));
    }
  };

  const handleChangeTeacher = (
    option: ValueType<ReactSelectOption<number>, false>
  ) => {
    setSelectedTeacher((option as ReactSelectOption<number>) ?? undefined);
  };

  const handleClickBack = () => {
    if (newClassName) {
      setNewClassName(undefined);
      setSelectedTeacher(undefined);
    } else {
      //onBackClick();
    }
  };

  return (
    <ListGroup.Item>
      <div className="d-flex mb-2">
        {/* <div className="mr-3" onClick={handleClickBack}>
          <FontAwesomeIcon icon={faArrowLeft} size={"lg"} className="pointer" />
        </div> */}
        <span className="font-weight-bold">
          {newClassName
            ? "Which teacher the class is for?"
            : "Assign to Classes:"}
        </span>
      </div>
      <div style={{ width: "330px" }}>
        {newClassName ? (
          <Select
            isLoading={isLoading.getTeachers}
            isClearable
            placeholder="Select teacher..."
            value={selectedTeacher}
            onChange={handleChangeTeacher}
            options={teachersRosterAsOptions}
          />
        ) : (
          <CreatableSelect
            isClearable
            //isMulti
            isLoading={
              isLoading.getClassesLinkedToStudent ||
              isLoading.updateClassesLinkedToStudent
            }
            placeholder="Select or type class..."
            onChange={handleChangeClasses}
            onCreateOption={handleCreateClass}
            options={allClasses}
            value={selectedClasses}
            getOptionLabel={(a: any) =>
              a.label ?? `${a.class.name} - ${getFullName(a.teacher)}`
            }
            getOptionValue={(a: any) => a.value ?? a.class.id.toString()}
            getNewOptionData={(inputValue, optionLabel) =>
              ({
                label: optionLabel,
                value: inputValue,
              } as any)
            }
          />
        )}
      </div>
      <div className="displayCentered mt-2">
        <div className="blueBtnMd" onClick={handleSaveClasses}>
          {newClassName ? "Create Class and Save" : "Save"}
          {(isLoading.assignClassToTeacher ||
            isLoading.updateClassesLinkedToStudent) && (
            <Spinner animation="border" size="sm" className="ml-3" />
          )}
        </div>
      </div>
    </ListGroup.Item>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    allClasses: onboarding.allClasses,
    classesLinkedToStudent: onboarding.classesLinkedToStudent,
    teachersRoster: onboarding.teachersRoster,
    isLoading: {
      getTeachers: onboarding.isLoading.getTeachers,
      getClassesLinkedToStudent: onboarding.isLoading.getClassesLinkedToStudent,
      assignClassToTeacher: onboarding.isLoading.assignClassToTeacher,
      updateClassesLinkedToStudent:
        onboarding.isLoading.updateClassesLinkedToStudent,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      assignClassToTeacher: assignClassToTeacher,
      updateClassesLinkedToStudent: updateClassesLinkedToStudent,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StudentClassesTab);
