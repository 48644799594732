import React, { FunctionComponent } from "react";
import { useHistory } from 'react-router';
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faDatabase,
  faGlasses,
  faRestroom,
  faSpellCheck,
  faStickyNote,
  faTable,
  faUniversalAccess,
  faUserCheck,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import GroupsInProgressNavLink from "./tabs/groups-in-progress/GroupsInProgressNavLink";
import BadgesNavLink from "../badges/BadgesNavLink";
import { CoachDashboardTabKeys } from "./CoachDashboard";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { changeDisplayedBar } from "../../../store/help/actions";
import useUserRole from "../../../utils/hooks/useUserRole";
import { useIntl } from "react-intl";
import ActiveBehaviorGroupsNavLink from "./tabs/your-assigments/ActiveBehaviorGroupsNavLink";
import ActiveReadingGroupsNavLink from "./tabs/your-assigments/ActiveReadingGroupsNavLink";

type OwnProps = {
  isCoach?: boolean;
  activeTab?: string;
};

type Props = OwnProps;

const DashboardMenuPanel: FunctionComponent<Props> = ({ activeTab }) => {
  const showTopBar = useSelector((s: ApplicationState) => s.help.showTopBar);

  const { isCoach, isParent, isTeacher, isSupervisor } = useUserRole();
  const intl = useIntl();

  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <>
      <h4 className="font-weight-bold">
        {intl.formatMessage({
          id: isParent
            ? "app.navigation.dashboardMenuPanel.title.parent"
            : "app.navigation.dashboardMenuPanel.title.default",
        })}
      </h4>
      <Nav.Item>
        <Nav.Link eventKey={CoachDashboardTabKeys.YOUR_BEHAVIOUR_ASSIGNMENTS}>
          <ActiveBehaviorGroupsNavLink/>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey={CoachDashboardTabKeys.YOUR_READING_ASSIGNMENTS}>
          <ActiveReadingGroupsNavLink />
        </Nav.Link>
      </Nav.Item>
      {/*{isCoach && (*/}
      {/*  <Nav.Item>*/}
      {/*    <Nav.Link eventKey={CoachDashboardTabKeys.GROUPS_IN_PROGRESS}>*/}
      {/*      <GroupsInProgressNavLink />*/}
      {/*    </Nav.Link>*/}
      {/*  </Nav.Item>*/}
      {/*)}*/}
      {(isCoach || isTeacher || isSupervisor) && (
        <>
          <h4 className="font-weight-bold" style={{ marginTop: 15 }}>
            {intl.formatMessage({
              id: "app.navigation.dashboardMenuPanel.dataAndCollaborationTitle",
            })}
          </h4>
          <Nav.Item>
            <Nav.Link eventKey={CoachDashboardTabKeys.YOUR_DATA_AND_INTERVIEWS}>
              <FontAwesomeIcon icon={faTable} />
              &nbsp;
              {intl.formatMessage({
                id:
                  "app.navigation.dashboardMenuPanel.yourDataAndInterviewLabel",
              })}
            </Nav.Link>
          </Nav.Item>
        </>
      )}
      {isTeacher && (
        <Nav.Item>
        <Nav.Link
          eventKey={CoachDashboardTabKeys.ROSTERS_STUDENTS}
          active={
            activeTab === CoachDashboardTabKeys.ROSTERS_STUDENTS
          }
        >
          <FontAwesomeIcon icon={faRestroom} />
          &nbsp;
          {intl.formatMessage({
            id:
              "app.navigation.dashboardMenuPanel.studentDataLabel",
          })}
        </Nav.Link>
      </Nav.Item>
      )}

      {isCoach && (
        <>
          <Nav.Item>
            <Nav.Link eventKey={CoachDashboardTabKeys.FBAs}>
              <FontAwesomeIcon icon={faGlasses} />
              &nbsp;
              {intl.formatMessage({
                id: "app.navigation.dashboardMenuPanel.abcDataCollectionLabel",
              })}
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey={CoachDashboardTabKeys.ROSTERS_TEACHERS}
              active={
                activeTab === CoachDashboardTabKeys.ROSTERS_STUDENTS ||
                activeTab === CoachDashboardTabKeys.ROSTERS_TEACHERS
              }
            >
              <FontAwesomeIcon icon={faRestroom} />
              &nbsp;
              {intl.formatMessage({
                id:
                  "app.navigation.dashboardMenuPanel.educatorsAndSharingLabel",
              })}
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link
              eventKey={CoachDashboardTabKeys.PARENTS}
              active={activeTab === CoachDashboardTabKeys.PARENTS}
            >
              <FontAwesomeIcon icon={faUniversalAccess} />
              &nbsp;
              {intl.formatMessage({
                id: "app.navigation.dashboardMenuPanel.parentAccessLabel",
              })}
            </Nav.Link>
          </Nav.Item> */}
          <Nav.Item>
            <Nav.Link eventKey={CoachDashboardTabKeys.BADGES}>
              <BadgesNavLink />
            </Nav.Link>
          </Nav.Item>
        </>
      )}

      {/* <h4 className="font-weight-bold" style={{ marginTop: 15 }}>
        {intl.formatMessage({
          id: "app.navigation.dashboardMenuPanel.assessments",
        })}
      </h4>
      <Nav.Item>
        <Nav.Link eventKey={CoachDashboardTabKeys.SPELLING_TEST}>
          <FontAwesomeIcon icon={faSpellCheck} />
          &nbsp;
          {intl.formatMessage({
            id: "app.navigation.dashboardMenuPanel.spellingTest",
          })}
        </Nav.Link>
      </Nav.Item> */}

      <h4 className="font-weight-bold" style={{ marginTop: 15 }}>
        {intl.formatMessage({
          id: "app.navigation.dashboardMenuPanel.exploreAndLearnTitle",
        })}
      </h4>
      <Nav.Item>
        <Nav.Link eventKey={CoachDashboardTabKeys.INTERVENTION_LIBRARY}>
          <FontAwesomeIcon icon={faDatabase} />
          &nbsp;
          {intl.formatMessage({
            id: "app.navigation.dashboardMenuPanel.interventionLibraryLabel",
          })}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey={CoachDashboardTabKeys.TUTORIAL_VIDEOS}>
          <FontAwesomeIcon icon={faVideo} />
          &nbsp;
          {intl.formatMessage({
            id: "app.navigation.dashboardMenuPanel.videoTutorialLabel",
          })}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey={CoachDashboardTabKeys.RELEASE_NOTES}>
          <FontAwesomeIcon icon={faStickyNote} />
          &nbsp;
          Release Notes
        </Nav.Link>
      </Nav.Item>
      <h4 className="font-weight-bold" style={{ marginTop: 15 }}>
        {intl.formatMessage({
          id: "app.navigation.dashboardMenuPanel.managementTitle",
        })}
      </h4>
      <Nav.Item>
        <Nav.Link eventKey={CoachDashboardTabKeys.YOUR_PROFILE}>
          <FontAwesomeIcon icon={faUserCheck} />
          &nbsp;
          {intl.formatMessage({
            id: "app.navigation.dashboardMenuPanel.yourProfileLabel",
          })}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey={CoachDashboardTabKeys.NOTIFICATIONS}>
          <FontAwesomeIcon icon={faBell} />
          &nbsp;
          {intl.formatMessage({
            id: "app.navigation.dashboardMenuPanel.notificationsLabel",
          })}
        </Nav.Link>
      </Nav.Item>
      <div className="d-flex justify-content-center mt-2">
        <button
          id="changeMenuBarPositionBtn"
          className="blueBtnSm"
          style={{ width: "100%" }}
          onClick={() => dispatch(changeDisplayedBar())}
        >
          {intl.formatMessage({
            id: showTopBar
              ? "app.navigation.dashboardMenuPanel.openSidebarMenuLabel"
              : "app.navigation.dashboardMenuPanel.hideSidebarMenuLabel",
          })}
        </button>
      </div>
    </>
  );
};

export default DashboardMenuPanel;
