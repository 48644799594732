import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faCheckCircle,
  faCheckSquare,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FbaItem } from "./types";
import { FormControl, OverlayTrigger, Popover } from "react-bootstrap";
import OtherOptionSelect from "./OtherOptionSelect";

type Props = {
  activeItem?: number | number[];
  fbaItem: FbaItem;
  index?: number;
  previousCustomRecords?: string[];
  onChange: (fbaItem: number, index?: number) => any;
  customOption?: string;
  onCustomOptionChange: (value?: string, index?: number) => any;
  behaviorDescription?: string;
  onBehaviorDescriptionChange?: (behaviorId: number, value?: string) => any;
};

const FbaListItem = ({
  index,
  onChange,
  fbaItem,
  activeItem,
  customOption,
  behaviorDescription,
  onCustomOptionChange,
  onBehaviorDescriptionChange,
  previousCustomRecords,
}: Props) => {
  const isActiveItem = useMemo(
    () =>
      (activeItem as number[])?.length
        ? (activeItem as number[])!.some((ii) => ii === fbaItem.value)
        : fbaItem.value === activeItem,
    [activeItem, fbaItem.value]
  );

  const isMulti = useMemo(() => Array.isArray(activeItem), [activeItem]);

  return (
    <div
      className={`d-flex align-items-start ${
        isActiveItem && onBehaviorDescriptionChange ? "activeFbaItem" : ""
      }`}
    >
      <span onClick={onChange(fbaItem.value, index)} className="pointer">
        <FontAwesomeIcon
          className="faWhiteBackground m-1"
          icon={
            isActiveItem
              ? isMulti
                ? faCheckSquare
                : faCheckCircle
              : isMulti
              ? faSquare
              : faCircle
          }
          style={{ color: isActiveItem ? "#2a74d1" : "#cfd8dc" }}
        />
      </span>
      <div className="w-100">
        <div>
          {fbaItem.title}
          {!!fbaItem.info && (
            <OverlayTrigger
              overlay={
                <Popover id="fbaItem-definition">
                  {/*<Popover.Title>Definition</Popover.Title>*/}
                  <Popover.Content>{fbaItem.info}</Popover.Content>
                </Popover>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} className="ml-1" />
            </OverlayTrigger>
          )}
        </div>

        {isActiveItem && (
          <>
            {fbaItem.isOther && previousCustomRecords && (
              <OtherOptionSelect
                index={index}
                onChange={onCustomOptionChange}
                options={previousCustomRecords}
                value={customOption}
              />
            )}

            {onBehaviorDescriptionChange && (
              <>
                <small>
                  Describe <i>(optional)</i>:
                </small>
                <FormControl
                  // className="mb-3"
                  as="textarea"
                  value={behaviorDescription || ""}
                  onChange={(e: any) => {
                    e.stopPropagation();
                    onBehaviorDescriptionChange(fbaItem.value, e.target.value);
                  }}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FbaListItem;
