import { ActionType, createReducer } from "typesafe-actions";
import { hideConfirmDialog, showConfirmDialog } from "./actions";
import { ConfirmDialogConfig } from "./types";
import * as spellingTestsActions from "./actions";

export type ConfirmDialogActions = ActionType<typeof spellingTestsActions>;

export type ConfirmDialogState = {
  confirmDialog: ConfirmDialogConfig;
};

const initialState: ConfirmDialogState = {
  confirmDialog: {}
};

const reducer = createReducer<ConfirmDialogState, ConfirmDialogActions>(
  initialState
)
  .handleAction(
    showConfirmDialog,
    (state: ConfirmDialogState, action): ConfirmDialogState => {
      return {
        ...state,
        confirmDialog: {
          ...action.payload,
          showLastStudentWarning: action.payload.showLastStudentWarning ?? false,
          timerElipseLeft: action.payload.timerElipseLeft ?? false,
          show: true,
        },
      };
    }
  )
  .handleAction(
    hideConfirmDialog,
    (state: ConfirmDialogState, action): ConfirmDialogState => {
      return {
        ...state,
        confirmDialog: {},
      };
    }
  );

export { reducer as confirmDialogReducer };
