import {
  Intervention,
  InterventionGroup,
} from "../../../../../store/onboarding/cases/types";
import React, { FunctionComponent, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ModalCloseButton from "../../../../common/onboarding/third-step/group-students/common/ModalCloseButton";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import InterventionFidelityCollapse from "./InterventionFidelityCollapse";
import CollapsedContainer from "./common/CollapsedContainer";
import {
  getMeetings,
  hideModifyInterventionModal,
  openEndInterventionModal,
} from "../../../../../store/onboarding/meeting-module/actions";
import {
  openCreateNewInterventionModal,
  openGroupInterventionsModal,
} from "../../../../../store/onboarding/cases/actions";
import StudentGoalTable from "../../../intervention-group-page/StudentGoalTable";
import EvidenceTable from "../../../../common/onboarding/second-step/evidence-tab/EvidenceTable";
import MeetingsTable from "../../../intervention-group-page/MeetingsTable";
import { toastr } from "react-redux-toastr";
import Select from "react-select";

type StateProps = {
  showModal: boolean;
  showSetGoalModal: boolean;
  showEndInterventionModal: boolean;
  interventionGroup?: InterventionGroup;
};

type DispatchProps = {
  hidModifyInterventionModal: () => any;
  openEndInterventionModal: () => any;
  openGroupInterventionsModal: () => any;
  openCreateNewInterventionModal: (
    selectedIntervention?: Intervention,
    interventionGroupId?: number
  ) => any;
  getMeetings: (meetingId?: number, interventionGroupId?: number) => any;
};

type OwnProps = {};

type Props = StateProps & DispatchProps & OwnProps;

const ModifyInterventionModal: FunctionComponent<Props> = ({
  showModal,
  showSetGoalModal,
  showEndInterventionModal,
  interventionGroup,
  hidModifyInterventionModal,
  openCreateNewInterventionModal,
  openGroupInterventionsModal,
  openEndInterventionModal,
  getMeetings,
}) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [intervention, setIntervention] = useState<Intervention | undefined>(
    undefined
  );

  const onModalShow = () => {
    getMeetings(undefined, interventionGroup?.id).then(null, (err: string) =>
      toastr.error("Error", err)
    );
    setShowAlert(false);
  };

  const onModalHide = () => {
    hidModifyInterventionModal();
  };

  const handleInterventionEnd = () => {
    hidModifyInterventionModal();
    //openEndInterventionModal();
  };

  return (
    <Modal
      // enforceFocus={false}
      show={showModal && !showSetGoalModal && !showEndInterventionModal}
      onHide={onModalHide}
      onShow={onModalShow}
      animation={false}
      size="lg"
      backdropClassName={"customDarkModalBackdrop in"}
    >
      <Modal.Header className="purpleModalHeader centerModalHeader">
        <div>
          <button className={"btnModalBack"} onClick={onModalHide}>
            <FontAwesomeIcon icon={faChevronLeft} size={"lg"} />
            Back
          </button>
        </div>
        <Modal.Title>Modify Intervention</Modal.Title>
        <ModalCloseButton onClose={onModalHide} />
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <h2 className="font-weight-bold">What do you want to do?</h2>

          <div className="modifyInterventionContainer">
            <button
              className="blueBtnMdFull"
              onClick={() => {
                setShowAlert(true);
              }}
            >
              I WANT TO MODIFY THIS INTERVENTION
            </button>
            <button
              className="blueBtnMdFull"
              onClick={() => {
                onModalHide();
                openGroupInterventionsModal();
              }}
            >
              I WANT A DIFFERENT INTERVENTION
            </button>

            {/* <button className="whiteBtnMd2" onClick={handleInterventionEnd}>
              Exit student(s) from Group
            </button> */}
          </div>
        </div>

        <div>
          <InterventionFidelityCollapse />
          <CollapsedContainer label="Student Goals">
            <StudentGoalTable />
          </CollapsedContainer>
          <CollapsedContainer label="Data & Evidence Used to Group Students">
            <EvidenceTable interventionGroup={interventionGroup} />
          </CollapsedContainer>
          {/* <CollapsedContainer label="Meetings">
            <MeetingsTable />
          </CollapsedContainer> */}
        </div>

        {showAlert && (
          <div className="selectInterventionPopup">
            <div>
              <h3 className="font-weight-bold mb-3 text-center">
                Which intervention do you want to modify?
              </h3>
              <Select
                isClearable
                placeholder="Select Intervention..."
                name="intervention"
                value={intervention}
                options={interventionGroup?.interventions}
                getOptionLabel={(intervention: Intervention) =>
                  intervention.name
                }
                getOptionValue={(intervention: Intervention) =>
                  intervention.id.toString()
                }
                onChange={(intervention) =>
                  setIntervention(intervention as any)
                }
              />
              <div className="spaceBetween mt-3">
                <button
                  className="blueBtnSm"
                  onClick={() => setShowAlert(false)}
                >
                  Cancel
                </button>
                <button
                  className="blueBtnSm"
                  disabled={!intervention}
                  onClick={() => {
                    setShowAlert(false);
                    onModalHide();
                    openCreateNewInterventionModal(
                      intervention,
                      interventionGroup?.id
                    );
                  }}
                >
                  Modify
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({
  cases,
  meetingModuleReducer,
}: ApplicationState): StateProps => {
  return {
    showModal: meetingModuleReducer.modalsState.modifyInterventionModal,
    showSetGoalModal: cases.modalsState.setGoalModal,
    showEndInterventionModal:
      meetingModuleReducer.modalsState.endInterventionModal,
    interventionGroup: cases.selectedInterventionGroup,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hidModifyInterventionModal: hideModifyInterventionModal,
      openEndInterventionModal: openEndInterventionModal,
      openCreateNewInterventionModal: openCreateNewInterventionModal,
      openGroupInterventionsModal: openGroupInterventionsModal,
      getMeetings: getMeetings,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifyInterventionModal);
