import { UserInfo } from "../auth/types";
import { AttachedResource } from "../onboarding/cases/types";

export type ChatState = {
  selectedThread?: ChatThread;
  chatThreads: Array<ChatThread>;
  chatMessages: Array<ChatMessage>;
  chatReports: Array<ChatReport>;
  modalsState: {
    chatModal: boolean;
  };
  isLoading: {
    chatThreads: boolean;
    chatMessages: boolean;
    startChatThread: boolean;
    deleteChatThread: boolean;
    reportChatMessage: boolean;
    getChatReports: boolean;
  };
  errors: {
    chatThreads?: string;
    chatMessages?: string;
    startChatThread?: string;
    deleteChatThread?: string;
  };
};

export enum ChatActionTypes {
  OPEN_CHAT_MODAL = "@@chat/OPEN_ADD_CHAT_MODAL",
  HIDE_CHAT_MODAL = "@@chat/HIDE_ADD_CHAT_MODAL",

  GET_CHAT_THREADS_START = "@@chat/GET_CHAT_THREADS_START",
  GET_CHAT_THREADS_SUCCESS = "@@chat/GET_CHAT_THREADS_SUCCESS",
  GET_CHAT_THREADS_FAILURE = "@@chat/GET_CHAT_THREADS_FAILURE",

  GET_CHAT_MESSAGES_START = "@@chat/GET_CHAT_MESSAGES_START",
  GET_CHAT_MESSAGES_SUCCESS = "@@chat/GET_CHAT_MESSAGES_SUCCESS",
  GET_CHAT_MESSAGES_FAILURE = "@@chat/GET_CHAT_MESSAGES_FAILURE",

  START_CHAT_THREAD_START = "@@chat/START_CHAT_THREAD_START",
  START_CHAT_THREAD_SUCCESS = "@@chat/START_CHAT_THREAD_SUCCESS",
  START_CHAT_THREAD_FAILURE = "@@chat/START_CHAT_THREAD_FAILURE",

  DELETE_CHAT_THREAD_START = "@@chat/DELETE_CHAT_THREAD_START",
  DELETE_CHAT_THREAD_SUCCESS = "@@chat/DELETE_CHAT_THREAD_SUCCESS",
  DELETE_CHAT_THREAD_FAILURE = "@@chat/DELETE_CHAT_THREAD_FAILURE",

  ADD_MESSAGE_START = "@@chat/ADD_MESSAGE_START",
  ADD_MESSAGE_SUCCESS = "@@chat/ADD_MESSAGE_SUCCESS",
  ADD_MESSAGE_FAILURE = "@@chat/ADD_MESSAGE_FAILURE",

  REPORT_CHAT_MESSAGE_START = "@@chat/REPORT_CHAT_MESSAGE_START",
  REPORT_CHAT_MESSAGE_SUCCESS = "@@chat/REPORT_CHAT_MESSAGE_SUCCESS",
  REPORT_CHAT_MESSAGE_FAILURE = "@@chat/REPORT_CHAT_MESSAGE_FAILURE",

  GET_CHAT_REPORTS_START = "@@chat/GET_CHAT_REPORTS_START",
  GET_CHAT_REPORTS_SUCCESS = "@@chat/GET_CHAT_REPORTS_SUCCESS",
  GET_CHAT_REPORTS_FAILURE = "@@chat/GET_CHAT_REPORTS_FAILURE",

  DELETE_CHAT_REPORT_START = "@@chat/DELETE_CHAT_REPORT_START",
  DELETE_CHAT_REPORT_SUCCESS = "@@chat/DELETE_CHAT_REPORT_SUCCESS",
  DELETE_CHAT_REPORT_FAILURE = "@@chat/DELETE_CHAT_REPORT_FAILURE",

  PRINT_CHAT_REPORT_START = "@@chat/PRINT_CHAT_REPORT_START",
  PRINT_CHAT_REPORT_SUCCESS = "@@chat/PRINT_CHAT_REPORT_SUCCESS",
  PRINT_CHAT_REPORT_FAILURE = "@@chat/PRINT_CHAT_REPORT_FAILURE",
}

export type ChatThread = {
  id?: number;
  created_by_user: UserInfo;
  participants: Array<UserInfo>;
  intervention_group?: number;
  created?: string;
  updated?: string;
  is_active: boolean;
  is_archived: boolean;
  quarantined: boolean;
};

export type ChatThreadRequest = {
  created_by_user: UserInfo;
  participants: Array<number>;
  intervention_group?: number;
  is_active: boolean;
  is_archived: boolean;
  quarantined: boolean;
};

export type ChatMessage = {
  id?: number;
  thread: number;
  user: number;
  created?: string;
  updated?: string;
  is_active: boolean;
  text: string;
  attachments?: Array<AttachedResource>;
};

export type ChatReport = {
  id: number;
  reported_by: UserInfo;
  user_reported: UserInfo;
  date: string;
  message: ChatMessage;
};

export enum ModalSteps {
  CHAT,
  NEW_CHAT,
}
