import React, { FunctionComponent, useState } from "react";
import { useDispatch } from "react-redux";
import { openPermissionSettingModal } from "../../../store/help/actions";
import useUserRole from "../../../utils/hooks/useUserRole";

type OwnProps = {};

type Props = OwnProps;

const SettingButton: FunctionComponent<Props> = (props) => {
   const dispatch = useDispatch();

  const { isCoach, isTeacher, isSupervisor } = useUserRole();

  const handleShowPermissionModal = () => {
    dispatch(openPermissionSettingModal(true));
  };

  return isCoach || isTeacher || isSupervisor ? (
    <a
      className="marigoldBtnSm mr-2 text-decoration-none"
      onClick={handleShowPermissionModal}
    >
      Your Data Permissions
    </a>
  ) : null;
};

export default SettingButton;
