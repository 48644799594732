import React, { FunctionComponent, useRef, useState } from "react";
import BehaviorsDropDown from "../../../second-step/evidence-tab/student-data-table/behavior-dropdown/BehaviorsDropDown";

type OwnProps = {
  isForAll?: boolean;
  onClick: () => any;
  hasValues?: boolean;
  btnClassName?: string;
};

type Props = OwnProps;

const SelectBehaviorBtn: FunctionComponent<Props> = ({
  isForAll,
  onClick,
  hasValues,
  btnClassName,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const refObject = useRef(null);

  const btnLabel = isForAll
    ? hasValues
      ? "Set/Overwrite Behaviors For All Students"
      : "Set Behaviors For All Students"
    : hasValues
    ? "Change Behaviors"
    : "Select Behavior";

  return (
    <>
      <button
        className={btnClassName + " whiteBtnSm text-nowrap"}
        onClick={() => {
          onClick();
          setShowDropdown(true);
        }}
        ref={refObject}
      >
        {btnLabel}
      </button>
      <BehaviorsDropDown
        isForAll={isForAll}
        refObject={refObject}
        setShowDropdown={setShowDropdown}
        showDropdown={showDropdown}
      />
    </>
  );
};

export default SelectBehaviorBtn;
