import React, { FunctionComponent } from "react";
import { FbaABC } from "../../../../../../store/onboarding/cases/types";
import moment from "moment";
import { useAbcNaming } from "../hooks";

type Props = {
  abc: FbaABC;
};

const AbcItem: FunctionComponent<Props> = (props) => {
  const { abc } = props;

  const { antecedent, behaviors, consequence } = useAbcNaming(abc);

  return (
    <div className={"abc-item"}>
      <p>{moment(abc.created).fromNow()}</p>
      <p>
        {`A: `}
        {antecedent}
      </p>
      <p>{`B: ${behaviors}`}</p>
      <p>{`C: ${consequence}`}</p>
    </div>
  );
};

export default AbcItem;
