import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useMemo,
  useState
} from "react";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";

import {
  ChecklistItem,
  Intervention,
  InterventionGroup,
} from "../../../../../../store/onboarding/cases/types";
import { ImplementationCheck } from "../../../../../../store/onboarding/meeting-module/types";
import DatePicker from "react-datepicker";
import CustomDateBoxInput from "../common/CustomDateBoxInput";
import { Form, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { bindActionCreators, Dispatch } from "redux";
import { updateImplementationCheck } from "../../../../../../store/onboarding/meeting-module/actions";
import { connect, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { ApplicationState } from "../../../../../../store";
import { getLoggedBy } from "../../../../../../utils/NamesUtils";
import { deleteInterventionLog } from "../../../../../../store/onboarding/cases/actions";
import ConfirmDialog from "../../../../../common/confirm-dialog/ConfirmDialog";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";
import useInterventionGroupAccess from "../../../../../../utils/hooks/useInterventionGroupAccess";

type OwnProps = {
  hideActions?: boolean;
  interventionGroup: InterventionGroup;
  intervention?: Intervention;
  implementationCheck: ImplementationCheck;
  isStudentDetail?:boolean;
  isGroupDetail?: boolean;
};

type DispatchProps = {
  updateImplementationCheck: (
    interventionGroupId: number,
    implCheckId: number,
    checklistItems: number[],
    notPlannedItemIds: number[],
    observedDate: Date,
    notPlannedReason?: string,
    notes?: string
  ) => any;
  deleteInterventionLog?: any;
};

type Props = OwnProps & DispatchProps;

const InterventionFidelityRow: FunctionComponent<Props> = ({
  hideActions,
  intervention,
  interventionGroup,
  implementationCheck,
  updateImplementationCheck,
  deleteInterventionLog,
  isStudentDetail,
  isGroupDetail
}) => {
  const dispatch = useDispatch();
  const cases = useSelector((s: ApplicationState) => s.cases);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [observedDate, setObservedDate] = useState<Date | undefined>(undefined);
  const [selectedChecklistItems, setSelectedChecklistItems] = useState<
    Array<number>
  >([]);
  const [selectedNotPlannedItems, setSelectedNotPlannedItems] = useState<
    Array<number>
  >([]);
  const [notPlannedReason, setNotPlannedReason] = useState('');
  const [notes, setNotes] = useState('');
  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(interventionGroup.id);

  const handleShow = (id: any) => {
    dispatch(
      showConfirmDialog({
        centered: true,
        onConfirm: () => {
          deleteInterventionLog(interventionGroup.id, id);
        },
      })
    );
  }

  useEffect(() => {
    if(!selectedNotPlannedItems.length) {
      setNotPlannedReason('');
    } else {
      setNotPlannedReason(implementationCheck?.not_planned_reason!)
    }
  },[selectedNotPlannedItems])

  useEffect(() => {
    if (editing) {
      setObservedDate(
        moment(
          new Date(
            moment(implementationCheck.observed_date)
            .utc()
            .format('YYYY/MM/DD')
          )
        ).toDate()
      );
      setSelectedChecklistItems(
        implementationCheck.logged_items?.map((li) => li.item) ?? []
      );
      setNotes(implementationCheck?.fidelity_notes!)
      setSelectedNotPlannedItems(
        implementationCheck.not_applicable_items?.map((li) => li.item) ?? []
      );
      setNotPlannedReason(implementationCheck?.not_planned_reason!)
    }
  }, [editing]);

  const handleNotPlannedChecked = (item: ChecklistItem, checked: boolean) => {
    if (checked) {
      setSelectedNotPlannedItems((items) => [...items, item.id!]);
      setSelectedChecklistItems((items) => items.filter((i) => i !== item.id));
    } else {
      setSelectedNotPlannedItems((items) => items.filter((i) => i !== item.id));
    }
  };

  const handleChecked = (item: ChecklistItem, checked: boolean) => {
    if (checked) {
      setSelectedChecklistItems((items) => [...items, item.id!]);
      setSelectedNotPlannedItems((items) => items.filter((i) => i !== item.id));
    } else {
      setSelectedChecklistItems((items) => items.filter((i) => i !== item.id));
    }
  };

  const handleImplCheckUpdate = () => {
    if(selectedNotPlannedItems.length && (notPlannedReason == '')) {
      toastr.error("Alert!!","Must provide a reason for marking steps Not Planned.");
      return;
    }
    setLoading(true);
    updateImplementationCheck(
      interventionGroup.id!,
      implementationCheck.id,
      selectedChecklistItems,
      selectedNotPlannedItems,
      observedDate!,
      notPlannedReason,
      notes,
    ).then(
      () => {
        setEditing(false);
        setLoading(false);
      },
      (err: string) => {
        toastr.error("Failed to update the implementation check", err);
        setLoading(false);
      }
    );
  };

  const myId = useSelector((s: ApplicationState) => s.auth.userInfo?.id);

  const createBy = useMemo(() => {
    return getLoggedBy(implementationCheck.logged_by_user, myId);
  }, [implementationCheck.logged_by_user, myId]);


  return <>{editing ? (
    <>
    <tr>
      <td colSpan={5}>
        <div className="d-flex justify-content-center">
          <button
            className="whiteBtnSm ml-3"
            onClick={() => setEditing(false)}
          >
            Cancel
          </button>
          <button
            className="blueBtnSm ml-3 d-flex align-items-center"
            onClick={handleImplCheckUpdate}
          >
            Save
            {loading && (
              <Spinner animation="border" size="sm" className="ml-3" />
            )}
          </button>
        </div>

      </td>
    </tr>
    <tr>
      <td className="text-nowrap">
        <DatePicker
          customInput={<CustomDateBoxInput />}
          selected={observedDate}
          onChange={(date: Date) => setObservedDate(date)}
          maxDate={new Date()}
        />
      </td>
      <td>{intervention?.name}</td>
      <td>
        {intervention && (
          <>
            <div className="d-flex stepHeader">
              <div className="col-auto font-weight-bold">P</div>
              <div className="col-auto font-weight-bold pl-2">NP</div>
            </div>
            <div className="observedItemsSmallContainer">
              {intervention.checklist_items
                .sort((a, b) => a.order - b.order)
                .map((item, index) => (
                  <div
                    className="observedInterventionItemContainer"
                    key={item.id}
                  >
                    <OverlayTrigger
                      overlay={
                        <Popover id='Planned'>
                          <Popover.Content>Planned</Popover.Content>
                        </Popover>
                      }
                    >
                      <Form.Check
                        id={(item.id || index).toString()}
                        label
                        custom
                        className="observedItemCheckbox"
                        type="checkbox"
                        checked={selectedChecklistItems.includes(item.id!)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleChecked(item, e.target.checked)
                        }
                      />
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Popover id='NotPlanned'>
                          <Popover.Content>Not Planned</Popover.Content>
                        </Popover>
                      }
                    >
                      <Form.Check
                        id={(item.id || index).toString()}
                        label
                        custom
                        className="notPlannedItemCheckbox"
                        type="checkbox"
                        checked={selectedNotPlannedItems.includes(item.id!)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleNotPlannedChecked(item, e.target.checked)
                        }
                      />
                    </OverlayTrigger>
                    <p className="mb-0">
                      <strong>Step {index + 1}:</strong> {item.title}
                    </p>
                  </div>
                ))}
              {selectedNotPlannedItems.length
              ?
                <Form.Group className="my-2">
                  <Form.Label>Not Planned Reason (Required)</Form.Label>
                  <Form.Control
                    as={"textarea"}
                    value={notPlannedReason}
                    onChange={(e) => setNotPlannedReason((e.target as HTMLInputElement).value)}
                    type="text"
                    placeholder="Type here..."
                    maxLength={800}
                    />
                    <small>Character limit: 800</small>
                </Form.Group>  
              : ''} 

                <Form.Group className="my-2">
                  <Form.Label>Notes (Optional)</Form.Label>
                  <Form.Control
                    as={"textarea"}
                    // required={showAntecedentForm ? false : true}
                    value={notes}
                    onChange={(e) => setNotes((e.target as HTMLInputElement).value)}
                    type="text"
                    placeholder="Type here..."
                    maxLength={800}
                  />
                  <small>Character limit: 800</small>
                </Form.Group>    
            </div>
          </>
        )}
      </td>
      <td>{createBy}</td>
      <td/>
    </tr>
    <tr>
      <td colSpan={5}>
        <div className="d-flex justify-content-center">
          <button
            className="whiteBtnSm ml-3"
            onClick={() => setEditing(false)}
          >
            Cancel
          </button>
          <button
            className="blueBtnSm ml-3 d-flex align-items-center"
            onClick={handleImplCheckUpdate}
          >
            Save
            {loading && (
              <Spinner animation="border" size="sm" className="ml-3" />
            )}
          </button>
        </div>

      </td>
    </tr>
    </>
  ) : (
    <tr>
      <td>
        {moment.utc(implementationCheck.observed_date).format("MM/DD/YYYY")}
      </td>
      <td>{intervention?.name}</td>
      <td>{`${parseFloat(implementationCheck.fidelity?.toFixed(2))}%`}</td>
      <td>{createBy}</td>
      <td>
        {!hideActions && !isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess &&(
          <>
            <span className="pointer mr-1" onClick={() => setEditing(true)}>
              <FontAwesomeIcon icon={faEdit} size="1x" className="purpleText" />
            </span>
            <span className="pointer" onClick={() => handleShow(implementationCheck?.id)}>
              <FontAwesomeIcon icon={faTrashAlt} size="1x" className="purpleText" />
            </span>
          </>
        )}
      </td>

    </tr>
  )}
    <ConfirmDialog />
  </>
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      updateImplementationCheck: updateImplementationCheck,
      deleteInterventionLog: deleteInterventionLog
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(InterventionFidelityRow);
