import { OverlayTrigger, Tooltip } from "react-bootstrap";
import React from "react";

const replaceStringLength = 3;

export const getDisplayedLabel = (
  name: string,
  displayedCharactersNum: number
): React.ReactNode => {
  if (name.length <= displayedCharactersNum + replaceStringLength) {
    return <>{name}</>;
  }
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id="fullName" className="customInfoTooltip">
          {name}
          <br/>
          Click to show/hide columns
        </Tooltip>
      }
    >
      <span>{`${name.substring(0, displayedCharactersNum)}...`}</span>
    </OverlayTrigger>
  );
};
