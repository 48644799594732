import React from "react";
import { Form } from "react-bootstrap";

type Props = {
  required?: boolean;
  name: string;
  title?: string;
  value: string;
  onChange: (event: React.FormEvent<any>) => void;
  columnNames: Array<string>;
  selectedColumnNames: Array<string>;
  isOptional?: boolean;
};

const ColumnHeaderSelector = (props: Props) => {
  return props.title ? (
    <Form.Group>
      <Form.Label className="font-weight-bold text-left">
        {props.title}{" "}
        {props.isOptional && <i className="font-weight-normal">(optional)</i>}
      </Form.Label>
      <Form.Control
        required={props.required}
        name={props.name}
        as="select"
        value={props.value || ""}
        onChange={props.onChange}
      >
        <option key={-1} value={""}>
          Select...
        </option>
        {props.columnNames
          .filter(
            (columnName: string) =>
              columnName === props.value ||
              !props.selectedColumnNames.includes(columnName)
          )
          .map((columnName: string, index: number) => (
            <option key={index}> {columnName}</option>
          ))}
      </Form.Control>
    </Form.Group>
  ) : (
    <Form.Control
      required={props.required}
      name={props.name}
      as="select"
      value={props.value}
      onChange={props.onChange}
    >
      <option key={-1} value={""}>
        Select...
      </option>
      {props.columnNames
        .filter(
          (columnName: string) =>
            columnName === props.value ||
            !props.selectedColumnNames.includes(columnName)
        )
        .map((columnName: string, index: number) => (
          <option key={index}> {columnName}</option>
        ))}
    </Form.Control>
  );
};

export default ColumnHeaderSelector;
