import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select, { ValueType } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useSelector } from "react-redux";
import {
  getInterventions,
  openCreateNewInterventionModal,
  openInterventionLibraryModal,
} from "../../../../../../store/onboarding/cases/actions";
import {
  ConcernArea,
  ConcernType,
  ExpectedChange,
  ExtendedInterventionTarget,
  Intervention,
  InterventionTarget,
} from "../../../../../../store/onboarding/cases/types";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";
import { IS_READY_COACH } from "../../../../../../constants";
import PdfBtn from "./PdfBtn";
import { UserInfo } from "../../../../../../store/auth/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { ReactSelectOption } from "../../../../../../store/onboarding/types";
import { useIntl } from "react-intl";
import InterventionItem from "./InterventionItem";
import { values } from "lodash";
import { SkillFocusArea } from "../../../../../../store/groups/types";

type StateProps = {
  userInfo?: UserInfo;
  interventions: Array<Intervention>;
  isLoading: {
    getInterventions: boolean;
  };
  errors: {
    getInterventions?: string;
  };
};

type DispatchProps = {
  getInterventions: (concernType?: ConcernType) => any;
  openCreateNewInterventionModal: () => any;
  openInterventionLibraryModal: (
    isSaveInState?: boolean,
    selectedIntervention?: Intervention
  ) => any;
};

type OwnProps = {
  isReadonly?: boolean;
};

type Props = OwnProps & StateProps & DispatchProps;

const InterventionLibraryTab: FunctionComponent<Props> = ({
  isReadonly,
  userInfo,
  interventions,
  getInterventions,
  isLoading: { getInterventions: loadingGetInterventions },
  openCreateNewInterventionModal,
  openInterventionLibraryModal,
}) => {
  const intl = useIntl();

  const platformName = useMemo(
    () => (IS_READY_COACH ? "ReadyCoach" : "NJTSS Early Reading"),
    []
  );

  const areas = useSelector<ApplicationState, SkillFocusArea[]>(
    (s) => s.groups.skillFocusInfo
  );

  const skills = useMemo(() => {
    return areas.flatMap(
      (a) =>({
        label: a.display_name,
        value: a.identifier,
      })
    ) ?? [];
  }, [areas]);
  const concernAreaOptions = useMemo(
    () =>
      Object.keys(ConcernArea)
        .filter((value) => !isNaN(+value))
        .map((value: string): any => ({
          label: intl.formatMessage(
            {
              id: "app.dashboard.interventions.filters.concernArea.option",
            },
            { concernArea: value }
          ),
          value: value,
        })),
    []
  );

  const expectedChangeOptions = useMemo(
    () =>
      Object.keys(ExpectedChange)
        .filter((value) => !isNaN(+value))
        .map((value: string): any => ({
          label: intl.formatMessage(
            {
              id: "app.dashboard.interventions.filters.expectedChange.option",
            },
            { expectedChange: value }
          ),
          value: value,
        })),
    []
  );

  const targetOptions = useMemo(
    () =>
      Object.keys(InterventionTarget)
        .filter((value) => !isNaN(+value))
        .map((value: string): any => ({
          label: intl.formatMessage(
            {
              id: "app.dashboard.interventions.filters.target.option",
            },
            { target: value }
          ),
          // InterventionTargetDisplayedNames[+value as InterventionTarget],
          value: value,
        })),
    []
  );

  const concernTypeOptions = useMemo(
    () =>
      Object.keys(ConcernType)
        .filter((value) => !isNaN(+value))
        .map((value: string): any => ({
          label: intl.formatMessage(
            {
              id:
                "app.dashboard.interventionLibraryTab.interventionItem.idealFor.concernType.behavior.label",
            },
            { concernType: value }
          ),
          value: value,
        })),
    []
  );

  // const interventionsCreators = useMemo(
  //   () =>
  //     interventions.reduce(
  //       (pV, cV) => {
  //         if (
  //           !cV.created_by_user ||
  //           pV.some((a) => +a.value === cV.created_by_user.id)
  //         )
  //           return pV;
  //         return [
  //           ...pV,
  //           {
  //             value: cV.created_by_user.id,
  //             label: getFullName(cV.created_by_user),
  //           },
  //         ];
  //       },
  //       [
  //         {
  //           value: 1,
  //           label: platformName,
  //         },
  //       ]
  //     ),
  //   [interventions]
  // );

  let interventionsCreators = useMemo(
    () =>
      interventions.map((e) => {
        if (e.is_system_intervention) {
          return {
            value: 0,
            label: platformName,
          };
        } else if(e.district) {
          return {
            value: e.district,
            label: 'District'
          }
        } else {
          return {
            value: e.created_by_user.id,
            label: getFullName(e.created_by_user),
          };
        }
      }),
    [interventions]
  );

  const ids = interventionsCreators.map((o) => o.value);

  interventionsCreators = interventionsCreators.filter(
    ({ value }, index) => !ids.includes(value, index + 1)
  );

  const [concernAreaFilter, setConcernAreaFilter] = useState<
    ReactSelectOption<ConcernArea>
  >(concernAreaOptions[2]);
  const [expectedChangeFilter, setExpectedChangeFilter] = useState<
    ReactSelectOption<ExpectedChange> | undefined
  >(undefined);
  const [targetFilter, setTargetFilter] = useState<
    ReactSelectOption<InterventionTarget> | undefined
  >(undefined);
  const [concernTypeFilter, setConcernTypeFilter] = useState<
    ReactSelectOption<ConcernType> | undefined
  >(undefined);
  const [createdByFilter, setCreatedByFilter] = useState<
    ReactSelectOption<number> | undefined
  >(undefined);
  const [areaOfNeedFilter, setAreaOfNeedFilter] = useState<
    ReactSelectOption<any> | undefined
  >(undefined);
  const [searchStringFilter, setSearchStringFilter] = useState<string>("");

  useEffect(() => {
    getInterventions();
  }, []);

  const filteredInterventions = useMemo(() => {
    const filterConcernArea = (intervention: Intervention) => {
      if (+concernAreaFilter.value === ConcernType.BOTH) return true;
      return intervention.concern_area === +concernAreaFilter.value;
    };

    const filterExpectedChange = (intervention: Intervention) => {
      if (!expectedChangeFilter) return true;
      return intervention.expected_change === +expectedChangeFilter.value;
    };

    const filterTarget = (intervention: Intervention) => {
      if (!targetFilter) return true;
      if(InterventionTarget.INDIVIDUALS === +targetFilter.value){
        return (
          intervention.target === +ExtendedInterventionTarget.INDIVIDUALS_AND_GROUPS ||
          intervention.target === +ExtendedInterventionTarget.WHOLE_CLASS_AND_INDIVIDUALS ||
          intervention.target === +InterventionTarget.INDIVIDUALS || 
          intervention.target === InterventionTarget.ALL
        );
      } else if(InterventionTarget.SMALL_GROUP === +targetFilter.value){
        return (
          intervention.target === +ExtendedInterventionTarget.INDIVIDUALS_AND_GROUPS ||
          intervention.target === +ExtendedInterventionTarget.WHOLE_CLASS_AND_GROUPS ||
          intervention.target === +InterventionTarget.SMALL_GROUP || 
          intervention.target === InterventionTarget.ALL
        );
      }  else if(InterventionTarget.WHOLE_CLASS === +targetFilter.value){
        return (
          intervention.target === +ExtendedInterventionTarget.WHOLE_CLASS_AND_INDIVIDUALS ||
          intervention.target === +ExtendedInterventionTarget.WHOLE_CLASS_AND_GROUPS ||
          intervention.target === +InterventionTarget.WHOLE_CLASS || 
          intervention.target === InterventionTarget.ALL
        );
      }  else if(InterventionTarget.ALL === +targetFilter.value){
        return (
          intervention.target === InterventionTarget.ALL
        );
      }
    };

    const filterConcernTypeFilter = (intervention: Intervention) => {
      if (!concernTypeFilter) return true;
      return intervention.concern_type === +concernTypeFilter.value;
    };

    const filterCreatedBy = (intervention: Intervention) => {
      if (
        !createdByFilter ||
        (!intervention.created_by_user && !createdByFilter.value)
      ) {
        return true;
      }

      if (+createdByFilter.value === 0 && intervention.is_system_intervention)
        return true;

      if (!createdByFilter.value && intervention.district)
        return true;

      if(+createdByFilter.value && intervention.district) {
        return intervention.district === +createdByFilter.value
      } else {
        return intervention.created_by_user?.id === +createdByFilter.value;
      }
    };

    const filterSearchString = (intervention: Intervention) => {
      if (!searchStringFilter || (!intervention.name && !searchStringFilter)) {
        return true;
      }
      return intervention.name
        .toLowerCase()
        .includes(searchStringFilter.toLowerCase());
    };

    const filterAreaOfNeedFilter = (intervention: Intervention) => {
      if (!areaOfNeedFilter) return true;
      if (intervention.skill_areas) {
        if (areaOfNeedFilter.value.toString() === 'diag_area_early_phonemic_awareness') {
          return intervention.skill_areas?.includes('diag_area_early_phonemic_awarene') 
          || intervention.skill_areas?.includes('diag_area_early_phonemic_awareness');
        } else {
          return intervention.skill_areas?.includes(areaOfNeedFilter.value.toString());
        }
      } else {
        return false;
      }
      //return (intervention.skill_areas !== null) && (intervention.skill_areas.length > 0) && (intervention.skill_areas?.includes(areaOfNeedFilter.value.toString()));
    };

    return interventions
      .filter(filterConcernArea)
      .filter(filterExpectedChange)
      .filter(filterTarget)
      .filter(filterConcernTypeFilter)
      .filter(filterCreatedBy)
      .filter(filterAreaOfNeedFilter)
      .filter(filterSearchString);
  }, [
    interventions,
    concernAreaFilter,
    expectedChangeFilter,
    targetFilter,
    concernTypeFilter,
    createdByFilter,
    areaOfNeedFilter,
    searchStringFilter,
  ]);

  const handleInterventionView = (intervention: Intervention) => {
    openInterventionLibraryModal(false, intervention);
  };

  return (
    <>
      <div className="onboardingContainer" style={{ padding: 20 }}>
        <Row>
          <Col sm={9}>
            <h2 className="font-weight-bold">
              {intl.formatMessage({
                id: "app.dashboard.interventionLibraryTab.description.title",
              })}
            </h2>
            <h4>
              {intl.formatMessage(
                {
                  id: "app.dashboard.interventionLibraryTab.description.info",
                },
                {
                  platformName: platformName,
                  linebreak: <br />,
                }
              )}
            </h4>
          </Col>
          {IS_READY_COACH && !isReadonly && (
            <Col sm={3}>
              <button
                className="blueBtnLg"
                onClick={() => openCreateNewInterventionModal()}
              >
                {intl.formatMessage({
                  id:
                    "app.dashboard.interventionLibraryTab.createNewInterventionBtnLabel",
                })}
              </button>
            </Col>
          )}
          <br />
          <br />
        </Row>
        <Row className="mt-4">
          <Col sm={3} style={{ borderRight: "1px solid #dadada" }}>
            <div className=" css-2b097c-container">
              <input
                type="text"
                defaultValue={searchStringFilter}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchStringFilter(event.target.value)
                }
                placeholder="Search an Intervention..."
                className="stdInput"
                style={{ width: "100%", marginBottom: 10 }}
              />
            </div>
            <div className="libraryLabel">
              {intl.formatMessage({
                id: "app.dashboard.interventions.filters.goalType.title",
              })}
            </div>
            <div>
              <h5 className="mb-0 mt-2 mb-1 font-weight-bold">
                {intl.formatMessage({
                  id:
                    "app.dashboard.interventions.filters.goalType.concernArea.label",
                })}
              </h5>
              <Select
                value={concernAreaFilter}
                options={concernAreaOptions}
                onChange={(
                  a: ValueType<ReactSelectOption<ConcernArea>, false>
                ) => setConcernAreaFilter(a as ReactSelectOption<ConcernArea>)}
              />
            </div>
            {(+concernAreaFilter.value !== ConcernArea.BEHAVIOR) && 
              
            <div>
              <h5 className="mb-0 mt-2 mb-1 font-weight-bold">
                {intl.formatMessage({
                  id:
                    "app.dashboard.interventions.filters.goalType.expectedChange.label",
                })}
              </h5>
              <Select
                isClearable
                value={expectedChangeFilter}
                options={expectedChangeOptions}
                onChange={(
                  a: ValueType<ReactSelectOption<ExpectedChange>, false>
                ) =>
                  setExpectedChangeFilter(
                    a as ReactSelectOption<ExpectedChange>
                  )
                }
              />
            </div>
            }
            <br />
            <div className="libraryLabel">
              {intl.formatMessage({
                id: "app.dashboard.interventions.filters.title",
              })}
              {/*<FontAwesomeIcon icon={faPlusCircle} />*/}
            </div>
            <h5 className="mb-0 mt-2 mb-1 font-weight-bold">
              {intl.formatMessage({
                id: "app.dashboard.interventions.filters.target.label",
              })}
            </h5>
            <Select
              isClearable
              value={targetFilter}
              options={targetOptions}
              onChange={(
                a: ValueType<ReactSelectOption<InterventionTarget>, false>
              ) => setTargetFilter(a as ReactSelectOption<InterventionTarget>)}
            />
            {/* <h5 className="mb-0 mt-2 mb-1 font-weight-bold">
              {intl.formatMessage({
                id: "app.dashboard.interventions.filters.concernType.label",
              })}
            </h5>
            <Select
              isClearable
              value={concernTypeFilter}
              options={concernTypeOptions}
              onChange={(a: ValueType<ReactSelectOption<ConcernType>, false>) =>
                setConcernTypeFilter(a as ReactSelectOption<ConcernType>)
              }
            /> */}
            {(+concernAreaFilter.value !== ConcernArea.BEHAVIOR) && 
            <>
              <h5 className="mb-0 mt-2 mb-1 font-weight-bold">
                {intl.formatMessage({
                  id: "app.dashboard.interventions.filters.areaOfNeed.label",
                })}
              </h5>
              <Select
                isClearable
                value={areaOfNeedFilter}
                options={skills}
                onChange={(a: any) => setAreaOfNeedFilter(a)}
              />
            </>
            }
            <h5 className="mb-0 mt-2 mb-1 font-weight-bold">
              {intl.formatMessage({
                id: "app.dashboard.interventions.filters.createdBy.label",
              })}
            </h5>
            <Select
              isClearable
              value={createdByFilter}
              options={interventionsCreators}
              onChange={(a: any) => setCreatedByFilter(a)}
            />
            <br />
          </Col>
          <Col sm={9}>
            {loadingGetInterventions ? (
              <LoadingIndicator />
            ) : !interventions.length ? (
              <h2>
                {intl.formatMessage({
                  id: "app.dashboard.interventionLibraryTab.noDataMessage",
                })}
              </h2>
            ) : !filteredInterventions.length ? (
              <h2>
                {intl.formatMessage({
                  id:
                    "app.dashboard.interventionLibraryTab.noDataMessage.byFilters",
                })}
              </h2>
            ) : (
              <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
                {filteredInterventions.map((intervention) => (
                  <InterventionItem
                    key={`intervention_${intervention.id}`}
                    intervention={intervention}
                    onSelect={handleInterventionView}
                  />
                ))}
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = ({ auth, cases }: ApplicationState): StateProps => {
  return {
    userInfo: auth.userInfo,
    interventions: cases.interventions,
    isLoading: {
      getInterventions: cases.isLoading.getInterventions,
    },
    errors: {
      getInterventions: cases.errors.getInterventions,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getInterventions: getInterventions,
      openCreateNewInterventionModal: openCreateNewInterventionModal,
      openInterventionLibraryModal: openInterventionLibraryModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterventionLibraryTab);
