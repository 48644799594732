import { RouteComponentProps } from "react-router";
import React, { FunctionComponent, useEffect } from "react";
import { Container, Tab } from "react-bootstrap";
import useActiveTab from "../parent-dashboard/useActiveTab";
import SuperuserDashboardMenuPanel from "./SuperuserDashboardMenuPanel";
import DistrictsTab from "./tabs/districts/DistrictsTab";
import { getAllUsers } from "../../../store/superuser/actions";
import { useDispatch } from "react-redux";
import ConfirmDialog from "../../common/confirm-dialog/ConfirmDialog";
import UpsertUserModal from "./tabs/users/dialogs/UpsertUserModal";
import UsersTable from "./tabs/users/users-table/UsersTable";
import UpsertReleaseModal from "./tabs/release-notes/dialogs/UpsertReleaseModal";
import ReleaseNotesTable from "./tabs/release-notes/release-notes-table/ReleaseNotesTable";
import DistrictSummaryTab from "../district-owner-dashboard/tabs/district-summary/tables/DistrictSummaryTab";
import DataExtractForm from "./tabs/data-extract/DataExtractForm";
import MasterData from "./tabs/master-data/MasterData";
import { EventKey } from "react-bootstrap/esm/types";

export enum SuperuserDashboardTabKeys {
  DISTRICTS = "districts",
  DISTRICT_SUMMARY = "summary",
  USERS = "users",
  ADMIN_RELEASE_NOTES = 'admin-release-notes',
  DATA_EXTRACT = 'data-extract',
  MASTER_DATA = 'master-data',
}

type MatchParams = {
  tabName: string;
};

type Props = RouteComponentProps<MatchParams>;

const SuperuserDashboard: FunctionComponent<Props> = ({
  match: {
    params: { tabName: activeTab },
  },
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getAllDistricts());
    dispatch(getAllUsers());
  }, []);

  const { changeActiveTab } = useActiveTab();

  
  return (
    <Container fluid>
      <Tab.Container
        defaultActiveKey={SuperuserDashboardTabKeys.DISTRICTS}
        activeKey={activeTab as EventKey}
        onSelect={changeActiveTab}
        unmountOnExit
      >
        <div className="d-flex" id="user-dashboard">
          <div
            className="coachDashboardSidebar mr-3"
            id="user-dashboard-sidebar"
          >
            <h4 className="font-weight-bold">Super Admin Menu</h4>
            <hr />
            <SuperuserDashboardMenuPanel />
          </div>
          <div className="coachDashboardContentContainer">
            <Tab.Content>
              <Tab.Pane eventKey={SuperuserDashboardTabKeys.DISTRICTS}>
                <DistrictsTab />
              </Tab.Pane>
              <Tab.Pane eventKey={SuperuserDashboardTabKeys.DISTRICT_SUMMARY}>
                <DistrictSummaryTab />
              </Tab.Pane>
              <Tab.Pane eventKey={SuperuserDashboardTabKeys.USERS}>
                <UsersTable />
              </Tab.Pane>
              <Tab.Pane eventKey={SuperuserDashboardTabKeys.ADMIN_RELEASE_NOTES}>
                <ReleaseNotesTable />
              </Tab.Pane>
              <Tab.Pane eventKey={SuperuserDashboardTabKeys.DATA_EXTRACT}>
                <DataExtractForm />
              </Tab.Pane>
              <Tab.Pane eventKey={SuperuserDashboardTabKeys.MASTER_DATA}>
                <MasterData />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>

      <UpsertUserModal />
      <ConfirmDialog />
      <UpsertReleaseModal />
    </Container>
  );
};

export default SuperuserDashboard;
