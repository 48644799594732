import React, { FunctionComponent, useMemo } from "react";
import { GoalSummary } from "../../../../../../store/onboarding/types";
import _ from "lodash";
import moment from "moment";
import { colors } from "./index";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { Bar, HorizontalBar } from "react-chartjs-2";

type OwnProps = { goals: GoalSummary[] };

type Props = OwnProps;

const GoalsByWeekBarChart: FunctionComponent<Props> = (props) => {
  const { goals } = props;

  const labels = useMemo(() => {
    return _.chain(goals)
      .orderBy([(d) => new Date(d.goal.target_date)], ["asc"])
      .map((g) => moment(g.goal.target_date).format("W/YY"))
      .uniq()
      .filter((l) => l !== "Invalid date")
      .value();
  }, [goals]);

  const datasets = useMemo(
    () =>
      _.chain(goals)
        .groupBy((g) => g.coach.id)
        .map((goals) => {
          return {
            coach: goals[0].coach,
            data: labels.map((l) =>
              goals.filter(
                (g) => moment(g.goal.target_date).format("W/YY") === l
              )
            ),
          };
        })
        .value(),
    [goals, labels]
  );

  const chartData = useMemo(() => {
    return {
      labels: labels.map((l) => `Wk. of ${l}`),
      datasets: datasets.map((dataset, index) => ({
        label: `coach_${dataset.coach.id}_dataset`,
        backgroundColor: colors[index % colors.length],
        borderWidth: 1,
        data: dataset.data.map((d) => d.length),
        datalabels: {
          display: false,
        },
      })),
    };
  }, [labels]);

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: (tooltipItem: any, data: any) => {
            return getFullName(datasets[tooltipItem.datasetIndex]?.coach);
          },
          footer: (tooltipItem: any, data: any) => {
            return _.chain(
              datasets[tooltipItem[0].datasetIndex]?.data[tooltipItem[0].index]
            )
              .map(
                (g) =>
                  `${getFullName(g.teacher)} / ${getFullName(g.goal.student)}`
              )
              .uniq()
              .join("\n");
          },
        },
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            min: 0,
          },
        ],
        xAxes: [{}],
      },
    }),
    [datasets]
  );

  return (
    <div className="position-relative district-summary-chart-container">
      <HorizontalBar height={250} data={chartData} options={options} />
    </div>
  );
};

export default GoalsByWeekBarChart;
