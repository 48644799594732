import React, { FunctionComponent, useMemo } from "react";
import VerticalBarChart from "./VerticalBarChart";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { GoalSummary } from "../../../../../../store/onboarding/types";
import _ from "lodash";

type OwnProps = {
  label: string;
  goals: GoalSummary[];
};

type Props = OwnProps;

const GoalsPerCoachChart: FunctionComponent<Props> = (props) => {
  const { label, goals } = props;
  const xAxis = useMemo(
    () =>
      _.chain(goals)
        .map((g) => g.coach)
        .uniqBy((q) => q.id)
        .value(),
    [goals]
  );
  const labels = useMemo(() => xAxis.map((x) => getFullName(x)), [xAxis]);
  const data = useMemo(() => {
    const groupsByCoach = _.chain(goals)
      .groupBy((g) => g.coach.id)
      .value();
    return xAxis.map((coach) => groupsByCoach[coach.id]?.length ?? 0);
  }, [xAxis, goals]);

  if (!data.length) {
    return null;
  }

  return (
    <div className="district-summary-chart-container">
      <h3 className="text-center">{label}</h3>
      <div>
        <VerticalBarChart labels={labels} data={data} isNumeric />
      </div>
    </div>
  );
};

export default GoalsPerCoachChart;
