import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { uploadUserProfileImage } from "../../../store/auth/actions";
import { toastr } from "react-redux-toastr";

type Props = {
  uploadUserProfileImage: (file: File) => any;
};

const PictureReader = (props: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null) {
      const file = event.target.files[0];
      const fileSizeInBytes = file?.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024); // Convert bytes to MB
  
      if (fileSizeInMB > 1) {
        toastr.error("Alert!!", "Image "+file.name+" size exceeds the maximum limit of 1MB.");
        return; // Exit the function if the file size exceeds the limit
      }
      props.uploadUserProfileImage(file);
    }
  };

  return (
    <input
      style={{ display: "none" }}
      type="file"
      accept={"image/*"}
      onChange={handleChange}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      uploadUserProfileImage: uploadUserProfileImage,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(PictureReader);
