import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { reportChatMessage } from "../../../../store/chat/actions";
import { Spinner } from "react-bootstrap";
import { toastr } from "react-redux-toastr";

type StateProps = {
  isLoading: {
    reportChatMessage: boolean;
  };
};

type DispatchProps = {
  reportChatMessage: (messageId: number) => any;
};

type OwnProps = {
  reportedMessage: {
    id: number;
    userName: string;
  };
  onClose: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const ReportMessageAlert: FunctionComponent<Props> = ({
  onClose,
  isLoading,
  reportedMessage,
  reportChatMessage,
}) => {
  const handleReport = () => {
    reportChatMessage(reportedMessage.id).then(
      () => {
        toastr.success("Success!", "The message has been reported!");
        onClose();
      },
      (err: string) => toastr.error("Failed to report the chat message", err)
    );
  };

  return (
    <div className="reportMessageAlert">
      <div>
        <h3 className={"mb-2"} style={{ color: "#f5a623" }}>
          <FontAwesomeIcon
            icon={faFlag}
            style={{ color: "#f5a623" }}
            className="mr-1"
          />
          Report Message
        </h3>
        <p>
          Are you sure you want to report{" "}
          <strong>{reportedMessage.userName}'s</strong> message?
        </p>

        <p>
          Your district administrator will be notified in order to handle this
          situation offline.
        </p>

        <div className="spaceBetween">
          <button className="whiteBtnSm" onClick={onClose}>
            Never mind
          </button>
          <button className="blueBtnSm" onClick={handleReport}>
            Report it{" "}
            {isLoading.reportChatMessage && (
              <Spinner animation="border" size="sm" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ chat }: ApplicationState): StateProps => {
  return {
    isLoading: {
      reportChatMessage: chat.isLoading.reportChatMessage,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      reportChatMessage: reportChatMessage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReportMessageAlert);
