import { UserInfo } from "../auth/types";

export type NotificationsState = {
  notifications: Array<Notification>;
  notificationSettings: Array<NotificationSettings>;
  modalsState: {};
  isLoading: {
    getNotifications: boolean;
    markAsReadNotification: boolean;
    getNotificationSettings: boolean;
    updateNotificationSettings: boolean;
  };
  errors: {
    getNotifications?: string;
    markAsReadNotification?: string;
    getNotificationSettings?: string;
    updateNotificationSettings?: string;
  };
};

export type Notification = {
  id: number;
  is_active: boolean;
  read: boolean;
  body: string;
  from_user: UserInfo;
  to_user?: UserInfo;
  email_sent_at?: string;
  scheduled_date?: string;
  noti_type?: NotificationType;
};

export enum NotificationType {
  OTHER = "other",
  MESSAGE = "chat_message",
  REMINDER = "chat_message",
}

export type NotificationSettings = {
  id: string;
  label: string;
  preferences: {
    email: true;
    in_app: true;
  };
};

export enum NotificationsActionTypes {
  GET_NOTIFICATIONS_START = "@@onboarding/GET_NOTIFICATIONS_START",
  GET_NOTIFICATIONS_SUCCESS = "@@onboarding/GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILURE = "@@onboarding/GET_NOTIFICATIONS_FAILURE",

  MARK_AS_READ_NOTIFICATION_START = "@@onboarding/MARK_AS_READ_NOTIFICATION_START",
  MARK_AS_READ_NOTIFICATION_SUCCESS = "@@onboarding/MARK_AS_READ_NOTIFICATION_SUCCESS",
  MARK_AS_READ_NOTIFICATION_FAILURE = "@@onboarding/MARK_AS_READ_NOTIFICATION_FAILURE",

  GET_NOTIFICATION_SETTINGS_START = "@@onboarding/GET_NOTIFICATION_SETTINGS_START",
  GET_NOTIFICATION_SETTINGS_SUCCESS = "@@onboarding/GET_NOTIFICATION_SETTINGS_SUCCESS",
  GET_NOTIFICATION_SETTINGS_FAILURE = "@@onboarding/GET_NOTIFICATION_SETTINGS_FAILURE",

  UPDATE_NOTIFICATION_SETTINGS_START = "@@onboarding/UPDATE_NOTIFICATION_SETTINGS_START",
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS = "@@onboarding/UPDATE_NOTIFICATION_SETTINGS_SUCCESS",
  UPDATE_NOTIFICATION_SETTINGS_FAILURE = "@@onboarding/UPDATE_NOTIFICATION_SETTINGS_FAILURE",
}
