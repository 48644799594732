import React, { FunctionComponent, useEffect, useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { push } from "connected-react-router";

import { ApplicationState } from "../../../store";
import { getTutorialVideosRequest } from "../../../store/onboarding/actions";
import { TutorialVideo } from "../../../store/onboarding/types";
import LoadingIndicator from "../../common/onboarding/LoadingIndicator";
import TutorialItem from "./TutorialItem";
import { Accordion } from "react-bootstrap";
import { useIntl } from "react-intl";

type DispatchProps = {
  getTutorialVideosRequest: () => any;
  push: (location: string) => any;
};

type StateProps = {
  tutorials: TutorialVideo[];
  isLoading: {
    getTutorialVideosRequest?: boolean;
  };
};

type OwnProps = {};

type Props = OwnProps & DispatchProps & StateProps;

const TutorialVideosPage: FunctionComponent<Props> = ({
  getTutorialVideosRequest,
  tutorials,
  isLoading,
}) => {
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);

  const handleTutorialChange = (newActiveKey: any) => {
    setActiveKey(newActiveKey);
  };

  const intl = useIntl();

  useEffect(() => {
    getTutorialVideosRequest();
  }, []);

  return (
    <div className="onboardingContainer" style={{ padding: 20 }}>
      <h1>
        {intl.formatMessage({
          id: "app.dashboard.tutorials.videos.title",
        })}
      </h1>
      <h2>
        {intl.formatMessage({
          id: "app.dashboard.tutorials.videos.info",
        })}
      </h2>
      {isLoading.getTutorialVideosRequest ? (
        <LoadingIndicator />
      ) : (
        <Accordion
          className="tutorialsAccordion"
          activeKey={activeKey}
          onSelect={handleTutorialChange}
        >
          {tutorials.map((tutorial) => (
            <TutorialItem
              tutorial={tutorial}
              key={`tutorial_${tutorial.id}`}
              activeKey={activeKey}
            />
          ))}
        </Accordion>
      )}
    </div>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): StateProps => {
  return {
    tutorials: onboarding.tutorialVideos,
    isLoading: {
      getTutorialVideosRequest: onboarding.isLoading.getTutorialVideos,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      getTutorialVideosRequest: getTutorialVideosRequest,
      push: push,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TutorialVideosPage);
