import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Overlay, Popover, Spinner } from "react-bootstrap";
import moment, { Moment } from "moment";
import TimePicker from "rc-time-picker";
import { toastr } from "react-redux-toastr";
import "rc-time-picker/assets/index.css";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { patchInterventionGroup } from "../../../../../../store/onboarding/cases/actions";
import { connect, useSelector } from "react-redux";
import { InterventionGroupPatchRequest } from "../../../../../../store/onboarding/cases/types";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { scheduleReminder } from "../../../../../../store/onboarding/actions";
import useInterventionGroupAccess from "../../../../../../utils/hooks/useInterventionGroupAccess";

type StateProps = {
  isLoading: {
    patchInterventionGroup?: boolean;
  };
};

type DispatchProps = {
  patchInterventionGroup: (
    groupId: number,
    request: InterventionGroupPatchRequest
  ) => any;
};

type OwnProps = {
  title?: string;
  startTime: string | null;
  endTime: string | null;
  interventionGroupId: number;
  fieldPrefix: string;
  container?: any;
  schedule?: any;
  setSchedule?: any;
  isNewGroup?: boolean;
};

type Props = StateProps & DispatchProps & OwnProps;

const InterventionDayItem: FunctionComponent<Props> = (props) => {
  const isStudentDetail =  useSelector(
    (s: ApplicationState) => s.cases.isStudentDetail
  );

  const isGroupDetail =  useSelector(
    (s: ApplicationState) => s.cases.isGroupDetail
  );

  const startMoment = useMemo(() => {
    return props.startTime ? moment(props.startTime, "h:m a") : undefined;
  }, [props.startTime]);

  const [newStartMoment, setNewStartMoment] = useState<Moment | undefined>(
    startMoment
  );

  const endMoment = useMemo(() => {
    return props.endTime ? moment(props.endTime, "h:m a") : undefined;
  }, [props.endTime]);

  const [newEndMoment, setNewEndMoment] = useState<Moment | undefined>(
    endMoment
  );

  const [startHr, setStartHr] = useState<number>();
  const [startMn, setStartMn] = useState<number>();
  const [endHr, setEndHr] = useState<number>();
  const [endMn, setEndMn] = useState<number>();
  const [startTimeLocal, setStartTimeLocal] = useState<any>(props.startTime);
  const [endTimeLocal, setEndTimeLocal] = useState<any>(props.endTime);
  const targetRef = useRef<any>(null);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const [isNewGroup, setIsNewGroup] = useState<boolean>(
    props.isNewGroup ? true : false
  );

  const handleSave = () => {
    if (
      endHr?.toString() &&
      startHr?.toString() &&
      endMn?.toString() &&
      startMn?.toString()
    ) {
      if (
        endHr < startHr ||
        (!(endHr < startHr) && endHr === startHr && endMn < startMn)
      )
        return toastr.error(
          "Invalid start & end time",
          "End time must be greater than start time."
        );
      if (props.setSchedule)
        props.setSchedule({
          [`${props.fieldPrefix}_start`]: startTimeLocal ?? null,
          [`${props.fieldPrefix}_end`]: endTimeLocal ?? null,
        });
      setShowOverlay(false);
    } else {
      props.setSchedule({
        [`${props.fieldPrefix}_start`]: null,
        [`${props.fieldPrefix}_end`]: null,
      });
      setShowOverlay(false);
    }
  };

  const handleSingleDayScheduleSave = () => {
    if (
      endHr?.toString() &&
      startHr?.toString() &&
      endMn?.toString() &&
      startMn?.toString()
    ) {
      if (
        endHr < startHr ||
        (!(endHr < startHr) && endHr === startHr && endMn < startMn)
      )
        return toastr.error(
          "Invalid start & end time",
          "End time must be greater than start time."
        );

      let newFormattedDate = {
        [`${props.fieldPrefix}_start`]:
          moment(startTimeLocal, "h:m a").format("HH:mm") ?? null,
        [`${props.fieldPrefix}_end`]:
          moment(endTimeLocal, "h:m a").format("HH:mm") ?? null,
      };

      props.patchInterventionGroup(props.interventionGroupId, newFormattedDate);
      setShowOverlay(false);
    } else {
      let newFormattedDate = {
        [`${props.fieldPrefix}_start`]: null,
        [`${props.fieldPrefix}_end`]: null,
      };

      props.patchInterventionGroup(props.interventionGroupId, newFormattedDate);
      setShowOverlay(false);
    }
  };

  function generateOptions(length: number) {
    const arr = [];
    for (let value = 0; value < length; value++) {
      arr.push(value);
    }
    return arr;
  }

  const setupStartAndEndTime = (startTime: any, endTime: any) => {
    const start = startTime?.format("HH:mm");
    const end = endTime?.format("HH:mm");
    const startHrs = start ? parseInt(start.split(":")[0]) : undefined;
    const startMin = start ? parseInt(start.split(":")[1]) : undefined;
    const endHrs = end ? parseInt(end.split(":")[0]) : undefined;
    const endMin = end ? parseInt(end.split(":")[1]) : undefined;
    setStartHr(startHrs);
    setStartMn(startMin);
    setEndHr(endHrs);
    setEndMn(endMin);
  };

  const resetSetHrsMins = () => {
    setNewStartMoment(
      props.startTime ? moment(props.startTime, "h:m a") : undefined
    );
    setNewEndMoment(props.endTime ? moment(props.endTime, "h:m a") : undefined);
    setStartTimeLocal(props.startTime);
    setEndTimeLocal(props.endTime);
  };

  useEffect(() => {
    setupStartAndEndTime(newStartMoment, newEndMoment);
  }, [startTimeLocal, endTimeLocal]);

  useEffect(() => {
    if (showOverlay) {
      setupStartAndEndTime(newStartMoment, newEndMoment);
    }
  }, [showOverlay]);

  function onChange(value: any, type: string) {
    if (type) {
      switch (type) {
        case "start": {
          setStartTimeLocal(value ? value.format("hh:mm A") : undefined);
          break;
        }
        case "end": {
          setEndTimeLocal(value ? value.format("hh:mm A") : undefined);
          break;
        }
      }
    }
  }

  function disabledMinutes(h: any, type: string): Array<number> {
    const mins = generateOptions(60);
    if (type == "start" && h === endHr) {
      const result = mins.filter((et) => !!endMn && et > endMn);
      return result;
    } else if (type == "end" && h === startHr) {
      const result = mins.filter((et) => !!startMn && et < startMn);
      return result;
    } else return [];
  }

  function disabledHours(type: string): Array<number> {
    const hrs = generateOptions(24);

    if (type == "start") {
      const result = hrs.filter((et) => !!endHr && et > endHr);
      return result;
    } else if (type == "end") {
      const result = hrs.filter((et) => !!startHr && et < startHr);
      return result;
    } else return [];
  }

  function disabledStartHrs(): Array<number> {
    const result = disabledHours("start");
    return result;
  }

  function disabledEndHrs(): Array<number> {
    const result = disabledHours("end");
    return result;
  }

  function disabledStartMn(h: any): Array<number> {
    const result = disabledMinutes(h, "start");
    return result;
  }

  function disabledEndMn(h: any): Array<number> {
    const result = disabledMinutes(h, "end");
    return result;
  }

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(props.interventionGroupId)
  
  return (
    <div
      className="d-flex justify-content-center"
      onClick={(e) => {
        e.stopPropagation();
        console.log("<<<<");
      }}
    >
      <div
        data-cy="schedule-day"
        ref={targetRef}
        onClick={() => setShowOverlay(true)}
      >
        {props.title ? (
          <div className="groupListItemHeaderDay">
            <span className="font-weight-bold">{props.title}</span>
            {startMoment && <span>{startMoment.format("h:mm a")}</span>}
            {endMoment && <span>{endMoment.format("h:mm a")}</span>}
          </div>
        ) : startMoment || endMoment ? (
          <div className="groupListTime">
            {startMoment && <div>{startMoment.format("h:mm a")}</div>}
            {endMoment && <div>{endMoment.format("h:mm a")}</div>}
          </div>
        ) : (
          <FontAwesomeIcon icon={faClock} className="m-auto pointer" />
        )}
      </div>

      {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess && <Overlay
        placement="bottom"
        rootClose
        onHide={() => {
          resetSetHrsMins();
          console.log("<<<< end");
          setShowOverlay(false);
        }}
        show={showOverlay}
        target={targetRef.current}
        container={props.container}
      >
        <Popover id="schedule-day-overlay">
          <Popover.Content>
            <div>
              <div>
                <h4>Start Time:</h4>
                <TimePicker
                  focusOnOpen
                  defaultValue={startMoment}
                  showSecond={false}
                  format={"h:mm a"}
                  placeholder={"h:mm a"}
                  disabledHours={disabledStartHrs}
                  disabledMinutes={disabledStartMn}
                  // use12Hours
                  onChange={(v) => {
                    setNewStartMoment(v);
                    onChange(v, "start");
                  }}
                  //@ts-ignore
                  getPopupContainer={(node: any) => node}
                />
                <div className="row col-12"><small className="text-muted"> Example: 10:00 am</small></div>
              </div>
              <div className="mt-2">
                <h4>End Time:</h4>
                <TimePicker
                  focusOnOpen
                  defaultValue={endMoment}
                  showSecond={false}
                  format={"h:mm a"}
                  placeholder={"h:mm a"}
                  disabledHours={disabledEndHrs}
                  disabledMinutes={disabledEndMn}
                  // use12Hours
                  onChange={(v) => {
                    setNewEndMoment(v);
                    onChange(v, "end");
                  }}
                  //@ts-ignore
                  getPopupContainer={(node: any) => node}
                />
                <div className="row col-12"><small className="text-muted"> Example: 04:00 pm</small></div>
              </div>
            </div>
            <div className="modalActions mt-2">
              <button
                data-cy="save-time-btn"
                className="blueBtnSm"
                onClick={isNewGroup ? handleSingleDayScheduleSave : handleSave}
                disabled={
                  (!endTimeLocal && startTimeLocal) ||
                  (endTimeLocal && !startTimeLocal)
                }
              >
                Save{" "}
                {props.isLoading.patchInterventionGroup && (
                  <Spinner animation="border" size="sm" />
                )}
              </button>
              <button
                className="whiteBtnSm"
                onClick={() => {
                  resetSetHrsMins();
                  setShowOverlay(false);
                }}
              >
                Close
              </button>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>}
    </div>
  );
};

const mapStateToProps = ({ cases }: ApplicationState): StateProps => {
  return {
    isLoading: {
      patchInterventionGroup: cases.isLoading.patchInterventionGroup,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      patchInterventionGroup: patchInterventionGroup,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterventionDayItem);
