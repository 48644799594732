import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { Tab } from "react-bootstrap";

type OwnProps = {};

type Props = OwnProps;

const UserAgreement: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  return (
    <div className="terms-and-conditions-body">
      <h3 className="text-center text-uppercase my-3">
        {intl.formatMessage({
          id: "app.terms-and-conditions.user-agreement",
        })}
      </h3>
      <p>
        {intl.formatMessage({
          id: "app.terms-and-conditions.user-agreement.intro",
        })}
      </p>
      <h3 className="text-uppercase mb-3">
        {intl.formatMessage({
          id: "app.terms-and-conditions.user-agreement.background",
        })}
      </h3>
      <p>
        {intl.formatMessage(
          {
            id: "app.terms-and-conditions.user-agreement.background.content",
          },
          { linebreak: <br /> }
        )}
      </p>

      <ol>
        <li>
          {intl.formatMessage({
            id: "app.terms-and-conditions.user-agreement.your-rights",
          })}
        </li>
        <p>
          {intl.formatMessage(
            {
              id: "app.terms-and-conditions.user-agreement.your-rights.content",
            },
            { linebreak: <br /> }
          )}
        </p>
        <li>
          {intl.formatMessage({
            id: "app.terms-and-conditions.user-agreement.rc-corp’s-rights",
          })}
        </li>
        <ol>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.rc-corp’s-rights.retained-rights",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.rc-corp’s-rights.retained-rights.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.rc-corp’s-rights.uses-of-data",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.rc-corp’s-rights.uses-of-data.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.rc-corp’s-rights.third-party-access",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.rc-corp’s-rights.third-party-access.content",
            })}
          </li>
        </ol>
        <li>
          {intl.formatMessage({
            id: "app.terms-and-conditions.user-agreement.your-obligations",
          })}
        </li>
        <ol>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.your-obligations.fees",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.your-obligations.fees.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.your-obligations.protection",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.your-obligations.protection.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.your-obligations.consent",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.your-obligations.consent.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.your-obligations.data-collection-and-entry",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.your-obligations.data-collection-and-entry.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.your-obligations.confidential-information",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.your-obligations.confidential-information.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.your-obligations.derivative-works",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.your-obligations.derivative-works.content",
            })}
          </li>
        </ol>

        <li>
          {intl.formatMessage({
            id: "app.terms-and-conditions.user-agreement.storage-of-data",
          })}
        </li>
        <ol>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.storage-of-data.release-of-data",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.storage-of-data.release-of-data.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.storage-of-data.access-to-data-for-maintenance",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.storage-of-data.access-to-data-for-maintenance.content",
            })}
          </li>
        </ol>

        <li>
          {intl.formatMessage({
            id:
              "app.terms-and-conditions.user-agreement.indemnification-and-other",
          })}
        </li>
        <ol>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.indemnification-and-other.indemnification",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.indemnification-and-other.indemnification.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.indemnification-and-other.no-warranty",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.indemnification-and-other.no-warranty.content",
            })}
          </li>
        </ol>

        <li>
          {intl.formatMessage({
            id: "app.terms-and-conditions.user-agreement.term-and-termination",
          })}
        </li>
        <ol>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.term-and-termination.term",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.term-and-termination.term.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.term-and-termination.termination",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.term-and-termination.termination.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.term-and-termination.no-use-after-termination",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.term-and-termination.no-use-after-termination.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.term-and-termination.your-obligations-after-termination",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.term-and-termination.your-obligations-after-termination.content",
            })}
          </li>
        </ol>

        <li className="text-justify">
          <strong>
            {intl.formatMessage({
              id: "app.terms-and-conditions.user-agreement.arbitration",
            })}
          </strong>
          &nbsp;
          <em style={{ fontWeight: 300, fontStyle: "normal" }}>
            {intl.formatMessage(
              {
                id:
                  "app.terms-and-conditions.user-agreement.arbitration.content",
              },
              { linebreak: <br /> }
            )}
          </em>
        </li>

        <li>
          {intl.formatMessage({
            id: "app.terms-and-conditions.user-agreement.general-provisions",
          })}
        </li>
        <ol>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.general-provisions.assignment",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.general-provisions.assignment.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.general-provisions.entire-agreement",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.general-provisions.entire-agreement.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.general-provisions.your-authority",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.general-provisions.your-authority.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.general-provisions.no-third-party-beneficiary",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.general-provisions.no-third-party-beneficiary.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.general-provisions.unsolicited-information",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.general-provisions.unsolicited-information.content",
            })}
          </li>
          <li>
            <strong>
              {intl.formatMessage({
                id:
                  "app.terms-and-conditions.user-agreement.general-provisions.force-majeure",
              })}
            </strong>
            &nbsp;
            {intl.formatMessage({
              id:
                "app.terms-and-conditions.user-agreement.general-provisions.force-majeure.content",
            })}
          </li>
        </ol>
      </ol>
    </div>
  );
};

export default UserAgreement;
