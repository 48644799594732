import { action, createAction } from "typesafe-actions";
import {
  ChatActionTypes,
  ChatMessage,
  ChatReport,
  ChatThread,
  ChatThreadRequest,
} from "./types";
import { Dispatch } from "redux";
import axios, { AxiosError } from "axios";
import {
  API_CHAT_MESSAGES,
  API_CHAT_THREADS,
  API_DISTRICT_CHAT_REPORT,
} from "../../constants";
import printJS from "print-js";

export const openChatModal = () => action(ChatActionTypes.OPEN_CHAT_MODAL);
export const hideChatModal = () => action(ChatActionTypes.HIDE_CHAT_MODAL);

export const addMessageRequest = () =>
  action(ChatActionTypes.ADD_MESSAGE_START);
export const addMessageSuccess = (chatMessage: ChatMessage) =>
  action(ChatActionTypes.ADD_MESSAGE_SUCCESS, {
    chatMessage,
  });
export const addMessageFailure = (message: string) =>
  action(ChatActionTypes.ADD_MESSAGE_FAILURE, {
    error: message,
  });

export const getChatThreadsRequest = () =>
  action(ChatActionTypes.GET_CHAT_THREADS_START);
export const getChatThreadsSuccess = (chatThreads: Array<ChatThread>) =>
  action(ChatActionTypes.GET_CHAT_THREADS_SUCCESS, {
    chatThreads,
  });
export const getChatThreadsFailure = (message: string) =>
  action(ChatActionTypes.GET_CHAT_THREADS_FAILURE, {
    error: message,
  });

export const getChatMessagesRequest = () =>
  action(ChatActionTypes.GET_CHAT_MESSAGES_START);
export const getChatMessagesSuccess = (chatMessages: Array<ChatMessage>) =>
  action(ChatActionTypes.GET_CHAT_MESSAGES_SUCCESS, {
    chatMessages,
  });
export const getChatMessagesFailure = (message: string) =>
  action(ChatActionTypes.GET_CHAT_MESSAGES_FAILURE, {
    error: message,
  });

export const startChatThreadRequest = () =>
  action(ChatActionTypes.START_CHAT_THREAD_START);
export const startChatThreadSuccess = (chatThread: ChatThread) =>
  action(ChatActionTypes.START_CHAT_THREAD_SUCCESS, {
    chatThread,
  });
export const startChatThreadFailure = (message: string) =>
  action(ChatActionTypes.START_CHAT_THREAD_FAILURE, {
    error: message,
  });

export const deleteChatThreadRequest = () =>
  action(ChatActionTypes.DELETE_CHAT_THREAD_START);
export const deleteChatThreadSuccess = (chatThreadId: number) =>
  action(ChatActionTypes.DELETE_CHAT_THREAD_SUCCESS, { chatThreadId });
export const deleteChatThreadFailure = (message: string) =>
  action(ChatActionTypes.DELETE_CHAT_THREAD_FAILURE, {
    error: message,
  });

export const getChatThreads = (id?: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getChatThreadsRequest());
    return axios
      .get(API_CHAT_THREADS(id))
      .then((res) => {
        const chatThreads: Array<ChatThread> = res.data;
        dispatch(getChatThreadsSuccess(chatThreads));
      })
      .catch((err: AxiosError) => {
        dispatch(getChatThreadsFailure(err.message));
      });
  };
};

export const getChatMessages = (threadId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getChatMessagesRequest());
    return axios
      .get(API_CHAT_THREADS(threadId) + "/all_messages")
      .then((res) => {
        const chatMessages: Array<ChatMessage> = res.data;
        dispatch(getChatMessagesSuccess(chatMessages));
      })
      .catch((err: AxiosError) => {
        dispatch(getChatMessagesFailure(err.message));
      });
  };
};

export const startChatThread = (chatThread: ChatThreadRequest) => {
  return (dispatch: Dispatch) => {
    dispatch(startChatThreadRequest());
    return axios
      .post(API_CHAT_THREADS(), chatThread)
      .then((res) => {
        const chatThread: ChatThread = res.data;
        dispatch(startChatThreadSuccess(chatThread));
        return chatThread;
      })
      .catch((err: AxiosError) => {
        dispatch(startChatThreadFailure(err.message));
      });
  };
};

export const deleteChatThread = (chatThreadId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteChatThreadRequest());
    return axios
      .delete(API_CHAT_THREADS(chatThreadId))
      .then(() => {
        dispatch(deleteChatThreadSuccess(chatThreadId));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteChatThreadFailure(err.message));
      });
  };
};

export const addChatMessage = (message: ChatMessage) => {
  return (dispatch: Dispatch) => {
    dispatch(addMessageRequest());
    return axios
      .post(API_CHAT_THREADS(message.thread) + "/send_message", message)
      .then((res) => {
        const chatMessage: ChatMessage = res.data;
        dispatch(addMessageSuccess(chatMessage));
      })
      .catch((err: AxiosError) => {
        dispatch(addMessageFailure(err.message));
      });
  };
};

export const reportChatMessageRequest = () =>
  action(ChatActionTypes.REPORT_CHAT_MESSAGE_START);
export const reportChatMessageSuccess = (chatMessage: ChatMessage) =>
  action(ChatActionTypes.REPORT_CHAT_MESSAGE_SUCCESS, {
    chatMessage,
  });
export const reportChatMessageFailure = (message: string) =>
  action(ChatActionTypes.REPORT_CHAT_MESSAGE_FAILURE, {
    error: message,
  });

export const reportChatMessage = (messageId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(reportChatMessageRequest());
    return axios
      .post(API_CHAT_MESSAGES(messageId, true))
      .then((res) => {
        const chatMessage: ChatMessage = res.data;
        dispatch(reportChatMessageSuccess(chatMessage));
      })
      .catch((err: AxiosError) => {
        dispatch(reportChatMessageFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const getChatReportsRequest = () =>
  action(ChatActionTypes.GET_CHAT_REPORTS_START);
export const getChatReportsSuccess = (chatReports: Array<ChatReport>) =>
  action(ChatActionTypes.GET_CHAT_REPORTS_SUCCESS, {
    chatReports,
  });
export const getChatReportsFailure = (message: string) =>
  action(ChatActionTypes.GET_CHAT_REPORTS_FAILURE, {
    error: message,
  });

export const getChatReports = () => {
  return (dispatch: Dispatch) => {
    dispatch(getChatReportsRequest());
    return axios
      .get(API_DISTRICT_CHAT_REPORT())
      .then((res) => {
        const chatReports: Array<ChatReport> = res.data;
        dispatch(getChatReportsSuccess(chatReports));
      })
      .catch((err: AxiosError) => {
        dispatch(getChatReportsFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const deleteChatReportRequest = () =>
  action(ChatActionTypes.DELETE_CHAT_REPORT_START);
export const deleteChatReportSuccess = (chatReportId: number) =>
  action(ChatActionTypes.DELETE_CHAT_REPORT_SUCCESS, {
    chatReportId,
  });
export const deleteChatReportFailure = (message: string) =>
  action(ChatActionTypes.DELETE_CHAT_REPORT_FAILURE, {
    error: message,
  });

export const deleteChatReport = (chatReportId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteChatReportRequest());
    return axios
      .delete(API_DISTRICT_CHAT_REPORT(chatReportId))
      .then((res) => {
        dispatch(deleteChatReportSuccess(chatReportId));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteChatReportFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const printChatReport = (chatReportId: number) => {
  return () => {
    return axios
      .get(API_DISTRICT_CHAT_REPORT(chatReportId, true), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
        responseType: "blob",
      })
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(blob);
        printJS(pdfUrl);
      })
      .catch((err: AxiosError) => {
        return Promise.reject(err.message);
      });
  };
};

export const changeSelectedChatThread = createAction(
  "@chat:CHANGE_SELECTED_CHAT_THREAD"
)<ChatThread | undefined>();
