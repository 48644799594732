import React, { FunctionComponent, useMemo, useState } from "react";
import Select from "react-select";
import {
  TierDisplayValues,
  TierValues,
} from "../../../../../../store/groups/types";
import { ValueType } from "react-select/src/types";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import useInterventionGroupAccess from "../../../../../../utils/hooks/useInterventionGroupAccess";

type OwnProps = {
  loading?: boolean;
  groupTier?: 1 | 2 | 3;
  onChangeTier: (tier: 1 | 2 | 3) => void;
  className?: string;
  isStudentDetail?: boolean;
  isGroupDetail?: boolean;
  interventionGroupId?: number;
};

type Props = OwnProps;

const TierSelector: FunctionComponent<Props> = (props) => {
  const { loading, groupTier, onChangeTier, className, interventionGroupId } = props;

  const handleChangeTier = (value: ValueType<any, false>) => {
    if (value) {
      onChangeTier(value.value);
    }
  };

  const [show, setShow] = useState<boolean>(false);
  const isStudentDetail =  useSelector(
    (s: ApplicationState) => s.cases.isStudentDetail
  );
  const isGroupDetail =  useSelector(
    (s: ApplicationState) => s.cases.isGroupDetail
  );

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(interventionGroupId)

  const handleShowSelector = () => {
    if(!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess) {
      setShow(true);
    }
  };

  return show ? (
    <div
      className={`groupRecommendationTierSelect ${className ? className : ""}`}
    >
      <Select
        defaultMenuIsOpen
        onMenuClose={() => setShow(false)}
        className="dataFiltersSelect"
        value={TierValues.find((tier: any) => tier.value === groupTier)}
        onChange={handleChangeTier}
        options={TierValues}
      />
    </div>
  ) : (
    <span className="text-white pointer" onClick={handleShowSelector}>
      {loading ? (
        <Spinner animation="border" size="sm" />
      ) : groupTier ? (
        TierDisplayValues[groupTier]
      ) : (
        <i>None</i>
      )}
    </span>
  );
};

export default TierSelector;
