import React, { FunctionComponent } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";


type OwnProps = {};

type Props = OwnProps;

const RankSpan: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const me = useSelector<ApplicationState, any>((s) => s.auth.userInfo);

  const handleClick = () => {
    history.push("/badges");
  };

  if (!me?.profile?.xp) {
    return null;
  }

  return (
    <span className="mr-2 pointer" onClick={handleClick}>
      {me.profile.xp.rank_display_name} — {me.profile.xp.rank_points}
    </span>
  );
};

export default RankSpan;
