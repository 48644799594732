import { ActionType, createReducer } from "typesafe-actions";
import * as superuserActions from "./actions";
import { SuperuserState } from "./types";

export type SuperuserAction = ActionType<typeof superuserActions>;

const initialState: SuperuserState = {
  districts: [],
  schools: [],
  selectedDistrict: null,
  selectedDistrictLicense: null,
  users: [],
  selectedUser: null,
  releaseNotes: [],
  masterDataTypes: [],
  masterDataDisplay: [],
  masterDataVariation: [],
  selectedReleaseNote: null,
  isLoading: {
    getAllDistricts: false,
    getDistrict: false,
    createDistrict: false,
    updateDistrict: false,
    deleteDistrict: false,
    getDistrictLicenses: false,
    createDistrictLicense: false,
    updateDistrictLicense: false,
    deleteDistrictLicense: false,
    getAllUsers: false,
    getUser: false,
    createUser: false,
    updateUser: false,
    deleteUser: false,
    getAllReleaseNotes: false,
    getReleaseNote: false,
    createReleaseNote: false,
    updateReleaseNote: false,
    deleteReleaseNote: false,
    getMasterDataTypes: false,
    getMasterDisplayNames: false,
    getMasterVariations: false,
    createVariations: false,
    updateVariations: false,
    deleteVariations: false,
  },
  modalsState: {
    showDistrictModal: false,
    showDistrictLicenseModal: false,
    upsertUpsertModal: {},
    upsertReleaseModal: {},
  },
  errors: {},
};

const reducer = createReducer<SuperuserState, SuperuserAction>(initialState)
  .handleAction(
    superuserActions.getAllDistrictsAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getAllDistricts: true,
      },
      errors: {
        ...state.errors,
        getAllDistricts: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.getAllDistrictsAction.success,
    (state, action): SuperuserState => ({
      ...state,
      districts: action.payload,
      isLoading: {
        ...state.isLoading,
        getAllDistricts: false,
      },
      errors: {
        ...state.errors,
        getAllDistricts: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.getAllDistrictsAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getAllDistricts: false,
      },
      errors: {
        ...state.errors,
        getAllDistricts: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.getDistrictAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getDistrict: true,
      },
      errors: {
        ...state.errors,
        getDistrict: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.getDistrictAction.success,
    (state, { payload: district }): SuperuserState => ({
      ...state,
      districts: state.districts.map((item) =>
        item.id === district.id ? district : item
      ),
      selectedDistrict:
        !state.selectedDistrict || state.selectedDistrict?.id === district.id
          ? district
          : state.selectedDistrict,
      isLoading: {
        ...state.isLoading,
        getDistrict: false,
      },
      errors: {
        ...state.errors,
        getDistrict: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.getDistrictAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getDistrict: false,
      },
      errors: {
        ...state.errors,
        getDistrict: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.createDistrictAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        createDistrict: true,
      },
      errors: {
        ...state.errors,
        createDistrict: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.createDistrictAction.success,
    (state, action): SuperuserState => ({
      ...state,
      districts: [...state.districts, action.payload],
      isLoading: {
        ...state.isLoading,
        createDistrict: false,
      },
      errors: {
        ...state.errors,
        createDistrict: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.createDistrictAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        createDistrict: false,
      },
      errors: {
        ...state.errors,
        createDistrict: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.updateDistrictAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        updateDistrict: true,
      },
      errors: {
        ...state.errors,
        updateDistrict: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.updateDistrictAction.success,
    (state, action): SuperuserState => ({
      ...state,
      districts: state.districts.map((item) =>
        item.id === action.payload.id ? action.payload : item
      ),
      selectedDistrict: action.payload,
      isLoading: {
        ...state.isLoading,
        updateDistrict: false,
      },
      errors: {
        ...state.errors,
        updateDistrict: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.updateDistrictAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        updateDistrict: false,
      },
      errors: {
        ...state.errors,
        updateDistrict: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.deleteDistrictAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        deleteDistrict: true,
      },
      errors: {
        ...state.errors,
        deleteDistrict: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.deleteDistrictAction.success,
    (state, { payload: districtId }): SuperuserState => ({
      ...state,
      districts: state.districts.filter((item) => item.id !== districtId),
      selectedDistrict:
        state.selectedDistrict?.id === districtId
          ? null
          : state.selectedDistrict,
      isLoading: {
        ...state.isLoading,
        deleteDistrict: false,
      },
      errors: {
        ...state.errors,
        deleteDistrict: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.deleteDistrictAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        deleteDistrict: false,
      },
      errors: {
        ...state.errors,
        deleteDistrict: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.getDistrictLicensesAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getDistrictLicenses: true,
      },
      errors: {
        ...state.errors,
        getDistrictLicenses: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.getDistrictLicensesAction.success,
    (state, { payload: { districtId, licenses } }): SuperuserState => ({
      ...state,
      selectedDistrict:
        state.selectedDistrict?.id === districtId
          ? {
              ...state.selectedDistrict,
              licenses: licenses,
              current_license:
                licenses.find(
                  (lic) =>
                    lic.id === state.selectedDistrict?.current_license?.id
                ) ?? state.selectedDistrict?.current_license,
            }
          : state.selectedDistrict,
      isLoading: {
        ...state.isLoading,
        getDistrictLicenses: false,
      },
      errors: {
        ...state.errors,
        getDistrictLicenses: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.getDistrictLicensesAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getDistrictLicenses: false,
      },
      errors: {
        ...state.errors,
        getDistrictLicenses: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.createDistrictLicenseAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        createDistrictLicense: true,
      },
      errors: {
        ...state.errors,
        createDistrictLicense: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.createDistrictLicenseAction.success,
    (state, { payload: { districtId, license } }): SuperuserState => ({
      ...state,
      selectedDistrict:
        state.selectedDistrict?.id === districtId
          ? {
              ...state.selectedDistrict,
              licenses: state.selectedDistrict?.licenses
                ? [...state.selectedDistrict?.licenses, license]
                : [license],
            }
          : state.selectedDistrict,
      isLoading: {
        ...state.isLoading,
        createDistrictLicense: false,
      },
      errors: {
        ...state.errors,
        createDistrictLicense: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.createDistrictLicenseAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        createDistrictLicense: false,
      },
      errors: {
        ...state.errors,
        createDistrictLicense: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.updateDistrictLicenseAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        updateDistrictLicense: true,
      },
      errors: {
        ...state.errors,
        updateDistrictLicense: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.updateDistrictLicenseAction.success,
    (state, { payload: { districtId, license } }): SuperuserState => ({
      ...state,
      selectedDistrict:
        state.selectedDistrict?.id === districtId
          ? {
              ...state.selectedDistrict,
              licenses: state.selectedDistrict?.licenses?.map((lic) =>
                lic.id === license.id ? license : lic
              ),
              current_license:
                state.selectedDistrict?.current_license?.id === license.id
                  ? license
                  : state.selectedDistrict?.current_license,
            }
          : state.selectedDistrict,
      isLoading: {
        ...state.isLoading,
        updateDistrictLicense: false,
      },
      errors: {
        ...state.errors,
        updateDistrictLicense: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.updateDistrictLicenseAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        updateDistrictLicense: false,
      },
      errors: {
        ...state.errors,
        updateDistrictLicense: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.deleteDistrictLicenseAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        deleteDistrictLicense: true,
      },
      errors: {
        ...state.errors,
        deleteDistrictLicense: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.deleteDistrictLicenseAction.success,
    (state, { payload: { districtId, licenseId } }): SuperuserState => ({
      ...state,
      selectedDistrict:
        state.selectedDistrict?.id === districtId
          ? {
              ...state.selectedDistrict,
              current_license:
                state.selectedDistrict.current_license?.id === licenseId
                  ? undefined
                  : state.selectedDistrict.current_license,
              licenses: state.selectedDistrict.licenses
                ? state.selectedDistrict.licenses.filter(
                    (lic) => lic.id !== licenseId
                  )
                : [],
            }
          : state.selectedDistrict,
      isLoading: {
        ...state.isLoading,
        deleteDistrictLicense: false,
      },
      errors: {
        ...state.errors,
        deleteDistrictLicense: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.deleteDistrictLicenseAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        deleteDistrictLicense: false,
      },
      errors: {
        ...state.errors,
        deleteDistrictLicense: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.getAllUsersAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getAllUsers: true,
      },
      errors: {
        ...state.errors,
        getAllUsers: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.getAllUsersAction.success,
    (state, action): SuperuserState => ({
      ...state,
      users: action.payload,
      isLoading: {
        ...state.isLoading,
        getAllUsers: false,
      },
      errors: {
        ...state.errors,
        getAllUsers: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.getAllUsersAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getAllUsers: false,
      },
      errors: {
        ...state.errors,
        getAllUsers: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.getUserAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getUser: true,
      },
      errors: {
        ...state.errors,
        getUser: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.getUserAction.success,
    (state, { payload: user }): SuperuserState => ({
      ...state,
      users: state.users.map((item) => (item.id === user.id ? user : item)),
      selectedUser:
        state.selectedUser?.id === user.id ? user : state.selectedUser,
      isLoading: {
        ...state.isLoading,
        getUser: false,
      },
      errors: {
        ...state.errors,
        getUser: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.getUserAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getUser: false,
      },
      errors: {
        ...state.errors,
        getUser: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.createUserAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        createUser: true,
      },
      errors: {
        ...state.errors,
        createUser: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.createUserAction.success,
    (state, action): SuperuserState => ({
      ...state,
      users: [...state.users, action.payload],
      isLoading: {
        ...state.isLoading,
        createUser: false,
      },
      errors: {
        ...state.errors,
        createUser: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.createUserAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        createUser: false,
      },
      errors: {
        ...state.errors,
        createUser: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.updateUserAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        updateUser: true,
      },
      errors: {
        ...state.errors,
        updateUser: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.updateUserAction.success,
    (state, action): SuperuserState => ({
      ...state,
      users: state.users.map((item) =>
        item.id === action.payload.id ? action.payload : item
      ),
      selectedUser: action.payload,
      isLoading: {
        ...state.isLoading,
        updateUser: false,
      },
      errors: {
        ...state.errors,
        updateUser: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.updateUserAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        updateUser: false,
      },
      errors: {
        ...state.errors,
        updateUser: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.deleteUserAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        deleteUser: true,
      },
      errors: {
        ...state.errors,
        deleteUser: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.deleteUserAction.success,
    (state, { payload: userId }): SuperuserState => ({
      ...state,
      users: state.users.filter((item) => item.id !== userId),
      selectedUser:
        state.selectedUser?.id === userId ? null : state.selectedUser,
      isLoading: {
        ...state.isLoading,
        deleteUser: false,
      },
      errors: {
        ...state.errors,
        deleteUser: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.deleteUserAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        deleteUser: false,
      },
      errors: {
        ...state.errors,
        deleteUser: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.selectDistrict,
    (state, action): SuperuserState => ({
      ...state,
      selectedDistrict: action.payload,
    })
  )
  .handleAction(
    superuserActions.setShowDistrictModal,
    (state, { payload: { show, district } }): SuperuserState => ({
      ...state,
      selectedDistrict: district,
      modalsState: {
        ...state.modalsState,
        showDistrictModal: show,
      },
    })
  )
  .handleAction(
    superuserActions.selectDistrictLicense,
    (state, action): SuperuserState => ({
      ...state,
      selectedDistrictLicense: action.payload,
    })
  )
  .handleAction(
    superuserActions.setShowDistrictLicenseModal,
    (state, { payload: { show, license } }): SuperuserState => ({
      ...state,
      selectedDistrictLicense: license,
      modalsState: {
        ...state.modalsState,
        showDistrictLicenseModal: show,
      },
    })
  )
  .handleAction(
    superuserActions.selectUser,
    (state, action): SuperuserState => ({
      ...state,
      selectedUser: action.payload,
    })
  )
  .handleAction(
    superuserActions.setShowUserModal,
    (state, { payload: { show, user, ...rest } }): SuperuserState => ({
      ...state,
      selectedUser: show ? user : null,
      modalsState: {
        ...state.modalsState,
        upsertUpsertModal: show ? { show: show, ...rest } : {},
      },
    })
  )
  .handleAction(
    superuserActions.selectReleaseNote,
    (state, action): SuperuserState => ({
      ...state,
      selectedReleaseNote: action.payload,
    })
  )
  .handleAction(
    superuserActions.setShowReleaseModal,
    (state, { payload: { show, releaseNote, ...rest } }): SuperuserState => {
      return {
      ...state,
      selectedReleaseNote: releaseNote,
      modalsState: {
        ...state.modalsState,
        upsertReleaseModal: {show: show, ...rest },
      },
    }}
  )
  .handleAction(
    superuserActions.createReleaseNoteAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        createReleaseNote: true,
      },
      errors: {
        ...state.errors,
        createReleaseNote: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.createReleaseNoteAction.success,
    (state, { payload: releaseNote }): SuperuserState => {
      let stateReleaseNotes = state.releaseNotes;
      if(!state.releaseNotes.some((stateReleaseNote)=> stateReleaseNote.id == releaseNote.id)) {
        stateReleaseNotes = [...state.releaseNotes, releaseNote];
      }

      return {
      ...state,
      releaseNotes: stateReleaseNotes,
      selectedReleaseNote:
        state.selectedReleaseNote?.id === releaseNote.id ? null : state.selectedReleaseNote,
      isLoading: {
        ...state.isLoading,
        createReleaseNote: false,
      },
      errors: {
        ...state.errors,
        createReleaseNote: undefined,
      },
    }}
  )
  .handleAction(
    superuserActions.createReleaseNoteAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        createReleaseNote: false,
      },
      errors: {
        ...state.errors,
        createReleaseNote: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.updateReleaseNoteAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        createReleaseNote: true,
      },
      errors: {
        ...state.errors,
        createReleaseNote: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.updateReleaseNoteAction.success,
    (state, action): SuperuserState => ({
      ...state,
      releaseNotes: [...state.releaseNotes.map((item) =>
        item = item.id === action.payload.id ? action.payload : item
      )],
      selectedReleaseNote: action.payload,
      isLoading: {
        ...state.isLoading,
        updateUser: false,
      },
      errors: {
        ...state.errors,
        updateUser: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.updateReleaseNoteAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        createReleaseNote: false,
      },
      errors: {
        ...state.errors,
        createReleaseNote: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.getAllReleaseNotesAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getAllReleaseNotes: true,
      },
      errors: {
        ...state.errors,
        getAllReleaseNotes: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.getAllReleaseNotesAction.success,
    (state, action): SuperuserState => ({
      ...state,
      releaseNotes: action.payload,
      isLoading: {
        ...state.isLoading,
        getAllReleaseNotes: false,
      },
      errors: {
        ...state.errors,
        getAllReleaseNotes: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.getAllReleaseNotesAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getAllReleaseNotes: false,
      },
      errors: {
        ...state.errors,
        getAllReleaseNotes: action.payload.message,
      },
    })
  )
  .handleAction(
    superuserActions.masterDataTypesAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getMasterDataTypes: true,
      },
      errors: {
        ...state.errors,
        getMasterDataTypes: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.masterDataTypesAction.success,
    (state, action): SuperuserState => ({
      ...state,
      masterDataTypes: action.payload,
      isLoading: {
        ...state.isLoading,
        getMasterDataTypes: false,
      },
      errors: {
        ...state.errors,
        getMasterDataTypes: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.masterDataTypesAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getMasterDataTypes: false,
      },
      errors: {
        ...state.errors,
        getMasterDataTypes: action.payload.message,
      },
    })
  ).handleAction(
    superuserActions.masterDataDisplayAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getMasterDisplayNames: true,
      },
      errors: {
        ...state.errors,
        getMasterDisplayNames: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.masterDataDisplayAction.success,
    (state, action): SuperuserState => ({
      ...state,
      masterDataDisplay: action.payload,
      isLoading: {
        ...state.isLoading,
        getMasterDisplayNames: false,
      },
      errors: {
        ...state.errors,
        getMasterDisplayNames: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.masterDataDisplayAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getMasterDisplayNames: false,
      },
      errors: {
        ...state.errors,
        getMasterDisplayNames: action.payload.message,
      },
    })
  ).handleAction(
    superuserActions.masterDataVariationAction.request,
    (state): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getMasterVariations: true,
      },
      errors: {
        ...state.errors,
        getMasterVariations: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.masterDataVariationAction.success,
    (state, action): SuperuserState => ({
      ...state,
      masterDataVariation: action.payload,
      isLoading: {
        ...state.isLoading,
        getMasterVariations: false,
      },
      errors: {
        ...state.errors,
        getMasterVariations: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.masterDataVariationAction.failure,
    (state, action): SuperuserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        getMasterVariations: false,
      },
      errors: {
        ...state.errors,
        getMasterVariations: action.payload.message,
      },
    })
  ).handleAction(
    superuserActions.createMasterDataVariationAction.success,
    (state, action): SuperuserState => {
      let masterDataVariations = state.masterDataVariation;
      if(!state.masterDataVariation.some((stateMasterDataVariation)=> stateMasterDataVariation.id == action.payload.created_master_value.id)) {
        masterDataVariations = [...state.masterDataVariation, action.payload.created_master_value];
      }

      return {
      ...state,
      masterDataVariation: masterDataVariations,
      isLoading: {
        ...state.isLoading,
        createVariations: false,
      },
      errors: {
        ...state.errors,
        createVariations: undefined,
      },
    }}
  )
  .handleAction(
    superuserActions.updateMasterDataVariationAction.success,
    (state, action): SuperuserState => ({
      ...state,
      masterDataVariation: [...state.masterDataVariation.map((item) =>
        item = item.id === action.payload.updated_master_value.id ? action.payload.updated_master_value : item
      )],
      isLoading: {
        ...state.isLoading,
        updateVariations: false,
      },
      errors: {
        ...state.errors,
        updateVariations: undefined,
      },
    })
  )
  .handleAction(
    superuserActions.deleteMasterDataVariationAction.success,
    (state, { payload: id }): SuperuserState => ({
      ...state,
      masterDataVariation: state.masterDataVariation.filter((item) => item.id !== id),
      isLoading: {
        ...state.isLoading,
        deleteVariations: false,
      },
      errors: {
        ...state.errors,
        deleteVariations: undefined,
      },
    })
  );

export { reducer as superuserReducer };
