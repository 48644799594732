import React, { FunctionComponent, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Student } from "../../../../../../store/onboarding/types";
import AddStudentOverlay from "./AddStudentOverlay";
import { GroupRecommendation } from "../../../../../../store/onboarding/cases/types";

type OwnProps = {
  selectedStudents: Student[];
  onAdd: (student: Student) => void;
  groupRecommendation?: GroupRecommendation,
  fromRecommendation?: boolean;
};

type Props = OwnProps;

const AddStudentsPopover: FunctionComponent<Props> = (props) => {
  const { selectedStudents, onAdd, groupRecommendation, fromRecommendation } = props;
  const target = useRef<any>();
  const [show, setShow] = useState<boolean>(false);

  const handleAdd = (student: Student) => {
    onAdd(student);
    setShow(false);
  };

  return (
    <>
      <AddStudentOverlay
        target={target}
        show={show}
        setShow={setShow}
        selectedStudents={selectedStudents}
        onAdd={handleAdd}
        title={"Select student you want to add"}
        btnText={"ADD STUDENT"}
        placement={"right"}
        group={groupRecommendation}
        fromRecommendation={fromRecommendation}
      />
      <span className="pointer" ref={target} onClick={() => setShow(true)}>
        <FontAwesomeIcon icon={faPlusCircle} size="lg" />
      </span>
    </>
  );
};

export default AddStudentsPopover;
