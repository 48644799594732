import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { Intervention } from "../../../../../../store/onboarding/cases/types";

type Props = {
  intervention: Intervention;
  index: number;
  isLoading?: boolean;
  onRemove?: (intervention: Intervention) => void;
};

const InterventionRow = ({
  intervention,
  index,
  isLoading,
  onRemove,
}: Props) => (
  <Draggable
    draggableId={intervention.id.toString()}
    index={index}
    isDragDisabled={isLoading}
  >
    {(provided) => (
      <div
        className="d-flex align-items-center justify-content-between"
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <div className="flex-grow-1 d-flex align-items-center m-1">
          <FontAwesomeIcon icon={faGripVertical} className="mr-2" />
          <h4 className="sequence-list-row d-flex flex-grow-1 ">
            <span>{index + 1}.</span> <span>{intervention.name}</span>
          </h4>
        </div>
        {/* <div className="m-1" onClick={() => onRemove && onRemove(intervention)}>
          <FontAwesomeIcon icon={faTimesCircle} size="lg" className="pointer" />
        </div> */}
      </div>
    )}
  </Draggable>
);

export default InterventionRow;
