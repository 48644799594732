import { ApplicationState } from "../../store";
import { bindActionCreators, Dispatch } from "redux";
import { hideEditMeasureColorCriteriaModal } from "../../store/onboarding/actions";
import { connect } from "react-redux";
import EditMeasureColorCriteriaModal from "../../components/common/color-picker/EditMeasureColorCriteriaModal";

type PropsFromState = {
  showModal: boolean;
};

type DispatchProps = {
  onHide: () => any;
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    showModal: onboarding.modalsState.editMeasureColorCriteriaModal,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      onHide: hideEditMeasureColorCriteriaModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMeasureColorCriteriaModal);
