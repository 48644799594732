import React, { FunctionComponent, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { AssignmentSummary } from "../../../../../store/onboarding/types";
import { getFullName } from "../../../../../utils/NamesUtils";

type OwnProps = {
  assignments: Array<AssignmentSummary>;
};

type Props = OwnProps;

const AssigmentTable: FunctionComponent<Props> = ({ assignments }) => {
  const columns = useMemo(
    () => [
      {
        dataField: "key",
        text: "key",
        hidden: true,
      },
      {
        dataField: "coach",
        text: "Coach Name",
        headerStyle: { width: "20%" },
        sort: true,
      },
      {
        dataField: "teachers",
        text: "Teachers",
        sort: true,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      assignments.map((a) => ({
        key: a.coach.id,
        coach: getFullName(a.coach),
        teachers: a.teachers
          .map((a) => `${getFullName(a.teacher)} (${a.student_count})`)
          .join(", "),
      })),
    [assignments]
  );

  const pagination = useMemo(() => {
    if (assignments.length > 10) {
      return {
        sizePerPageList: [
          {
            text: "10",
            value: 10,
          },
          {
            text: "20",
            value: 20,
          },
          {
            text: "50",
            value: 50,
          },
        ],
      };
    }
  }, [assignments]);

  return (
    <BootstrapTable
      classes="rosterTable"
      keyField="key"
      bordered={false}
      bootstrap4
      data={data}
      columns={columns}
      noDataIndication={"No data"}
      pagination={pagination && paginationFactory(pagination)}
    />
  );
};

export default AssigmentTable;
