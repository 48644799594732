import { createAction, createAsyncAction } from "typesafe-actions";
import {
  GrammarType,
  SpellingTest,
  SpellingTestAnswer,
  SpellingTestGroup,
  SpellingTestQuestion,
  SpellingTestWordGroup,
  UpsertSpellingTestGroupActionRequest,
} from "./types";
import { Dispatch } from "redux";
import axios, { AxiosError } from "axios";
import {
  API_SPELLING_TEST_GENERATE_DIAGNOSTIC,
  API_SPELLING_TEST_GROUPS,
  API_SPELLING_TEST_GROUPS_ADD_STUDENT,
  API_SPELLING_TEST_GROUPS_REMOVE_STUDENT,
  API_SPELLING_TEST_QUESTIONS,
  API_SPELLING_TESTS,
  API_STUDENTS_BY_GRADE,
} from "../../constants";
import { Grade } from "../groups/types";
import { Student } from "../onboarding/types";
import { getBadgesRequest } from "../badges/actions";

export const getSpellingTestGroupsAction = createAsyncAction(
  "@spelling_test/GET_SPELLING_TEST_GROUPS_REQUEST",
  "@spelling_test/GET_SPELLING_TEST_GROUPS_SUCCESS",
  "@spelling_test/GET_SPELLING_TEST_GROUPS_FAILED"
)<void, SpellingTestGroup[], Error>();

export const getSpellingTestGroups = () => {
  return (dispatch: Dispatch) => {
    dispatch(getSpellingTestGroupsAction.request());
    return axios
      .get(API_SPELLING_TEST_GROUPS())
      .then((res) => {
        const groups: SpellingTestGroup[] = res.data;
        dispatch(getSpellingTestGroupsAction.success(groups));
      })
      .catch((err: AxiosError) => {
        dispatch(getSpellingTestGroupsAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const getSpellingTestGroupAction = createAsyncAction(
  "@spelling_test/GET_SPELLING_TEST_GROUP_REQUEST",
  "@spelling_test/GET_SPELLING_TEST_GROUP_SUCCESS",
  "@spelling_test/GET_SPELLING_TEST_GROUP_FAILED"
)<void, SpellingTestGroup, Error>();

export const getSpellingTestGroup = (testGroupId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getSpellingTestGroupAction.request());
    return axios
      .get(API_SPELLING_TEST_GROUPS(testGroupId))
      .then((res) => {
        const groups: SpellingTestGroup = res.data;
        dispatch(getSpellingTestGroupAction.success(groups));
      })
      .catch((err: AxiosError) => {
        dispatch(getSpellingTestGroupAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const createSpellingTestGroupAction = createAsyncAction(
  "@spelling_test/CREATE_SPELLING_TEST_GROUP_REQUEST",
  "@spelling_test/CREATE_SPELLING_TEST_GROUP_SUCCESS",
  "@spelling_test/CREATE_SPELLING_TEST_GROUP_FAILED"
)<void, SpellingTestGroup, Error>();

export const createSpellingTestGroup = (
  req: UpsertSpellingTestGroupActionRequest
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(createSpellingTestGroupAction.request());
    return axios
      .post(API_SPELLING_TEST_GROUPS(), req)
      .then((res) => {
        const group: SpellingTestGroup = res.data;
        dispatch(createSpellingTestGroupAction.success(group));
        dispatch(getBadgesRequest());
        return Promise.resolve(group);
      })
      .catch((err: AxiosError) => {
        dispatch(createSpellingTestGroupAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const addStudentToSpellingTestGroupAction = createAsyncAction(
  "@spelling_test/ADD_STUDENT_TO_SPELLING_TEST_GROUP_REQUEST",
  "@spelling_test/ADD_STUDENT_TO_SPELLING_TEST_GROUP_SUCCESS",
  "@spelling_test/ADD_STUDENT_TO_SPELLING_TEST_GROUP_FAILED"
)<void, SpellingTestGroup, Error>();

export const addStudentToSpellingTestGroup = (
  groupId: number,
  studentId: number
) => {
  return (dispatch: Dispatch) => {
    dispatch(addStudentToSpellingTestGroupAction.request());
    return axios
      .post(API_SPELLING_TEST_GROUPS_ADD_STUDENT(groupId), {
        student_id: studentId,
      })
      .then((res) => {
        const group: SpellingTestGroup = res.data;
        dispatch(addStudentToSpellingTestGroupAction.success(group));
      })
      .catch((err: AxiosError) => {
        dispatch(addStudentToSpellingTestGroupAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const removeStudentFromSpellingTestGroupAction = createAsyncAction(
  "@spelling_test/REMOVE_STUDENT_FROM_SPELLING_TEST_GROUP_REQUEST",
  "@spelling_test/REMOVE_STUDENT_FROM_SPELLING_TEST_GROUP_SUCCESS",
  "@spelling_test/REMOVE_STUDENT_FROM_SPELLING_TEST_GROUP_FAILED"
)<void, SpellingTestGroup, Error>();

export const removeStudentFromSpellingTestGroup = (
  groupId: number,
  studentId: number
) => {
  return (dispatch: Dispatch) => {
    dispatch(removeStudentFromSpellingTestGroupAction.request());
    return axios
      .post(API_SPELLING_TEST_GROUPS_REMOVE_STUDENT(groupId), {
        student_id: studentId,
      })
      .then((res) => {
        const group: SpellingTestGroup = res.data;
        dispatch(removeStudentFromSpellingTestGroupAction.success(group));
      })
      .catch((err: AxiosError) => {
        dispatch(removeStudentFromSpellingTestGroupAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const updateSpellingTestGroupAction = createAsyncAction(
  "@spelling_test/UPDATE_SPELLING_TEST_GROUP_REQUEST",
  "@spelling_test/UPDATE_SPELLING_TEST_GROUP_SUCCESS",
  "@spelling_test/UPDATE_SPELLING_TEST_GROUP_FAILED"
)<void, SpellingTestGroup, Error>();

export const updateSpellingTestGroup = (
  spellingTestGroupId: number,
  req: UpsertSpellingTestGroupActionRequest
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(updateSpellingTestGroupAction.request());
    return axios
      .put(API_SPELLING_TEST_GROUPS(spellingTestGroupId), req)
      .then((res) => {
        const group: SpellingTestGroup = res.data;
        dispatch(updateSpellingTestGroupAction.success(group));
        dispatch(getBadgesRequest());
        return Promise.resolve(group);
      })
      .catch((err: AxiosError) => {
        dispatch(updateSpellingTestGroupAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const deleteSpellingTestGroupAction = createAsyncAction(
  "@spelling_test/DELETE_SPELLING_TEST_GROUP_REQUEST",
  "@spelling_test/DELETE_SPELLING_TEST_GROUP_SUCCESS",
  "@spelling_test/DELETE_SPELLING_TEST_GROUP_FAILED"
)<void, number, Error>();

export const deleteSpellingTestGroup = (spellingTestGroupId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteSpellingTestGroupAction.request());
    return axios
      .delete(API_SPELLING_TEST_GROUPS(spellingTestGroupId))
      .then((res) => {
        dispatch(deleteSpellingTestGroupAction.success(spellingTestGroupId));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteSpellingTestGroupAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const showSpellingTestGroupUpsertModal = createAction(
  "@spelling_test/SHOW_SPELLING_TEST_GROUP_UPSERT_MODAL"
)<undefined | SpellingTestGroup>();

export const hideSpellingTestGroupUpsertModal = createAction(
  "@spelling_test/HIDE_SPELLING_TEST_GROUP_UPSERT_MODAL"
)();

export const getSpellingTestAction = createAsyncAction(
  "@spelling_test/GET_SPELLING_TEST_REQUEST",
  "@spelling_test/GET_SPELLING_TEST_SUCCESS",
  "@spelling_test/GET_SPELLING_TEST_FAILED"
)<void, SpellingTest, Error>();

export const getSpellingTest = (testId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getSpellingTestAction.request());
    return axios
      .get(API_SPELLING_TESTS(testId))
      .then((res) => {
        const spellingTest: SpellingTest = res.data;
        dispatch(getSpellingTestAction.success(spellingTest));
      })
      .catch((err: AxiosError) => {
        dispatch(getSpellingTestAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const createSpellingTestAction = createAsyncAction(
  "@spelling_test/CREATE_SPELLING_TEST_REQUEST",
  "@spelling_test/CREATE_SPELLING_TEST_SUCCESS",
  "@spelling_test/CREATE_SPELLING_TEST_FAILED"
)<void, SpellingTest, Error>();

export const createSpellingTest = (
  groupId: number,
  studentId: number,
  answers: SpellingTestAnswer[]
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(createSpellingTestAction.request());
    return axios
      .post(API_SPELLING_TESTS(), {
        student: studentId,
        group: groupId,
        answers: answers,
      })
      .then((res) => {
        const spellingTest: SpellingTest = res.data;
        dispatch(createSpellingTestAction.success(spellingTest));
        dispatch(getBadgesRequest());
      })
      .catch((err: AxiosError) => {
        dispatch(createSpellingTestAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const updateSpellingTestAction = createAsyncAction(
  "@spelling_test/UPDATE_SPELLING_TEST_REQUEST",
  "@spelling_test/UPDATE_SPELLING_TEST_SUCCESS",
  "@spelling_test/UPDATE_SPELLING_TEST_FAILED"
)<void, SpellingTest, Error>();

export const updateSpellingTest = (
  testId: number,
  groupId: number,
  studentId: number,
  answers: SpellingTestAnswer[]
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(updateSpellingTestAction.request());
    return axios
      .put(API_SPELLING_TESTS(testId), {
        student: studentId,
        group: groupId,
        answers: answers,
      })
      .then((res) => {
        const spellingTest: SpellingTest = res.data;
        dispatch(updateSpellingTestAction.success(spellingTest));
        dispatch(getBadgesRequest());
      })
      .catch((err: AxiosError) => {
        dispatch(updateSpellingTestAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const deleteSpellingTestAction = createAsyncAction(
  "@spelling_test/DELETE_SPELLING_TEST_REQUEST",
  "@spelling_test/DELETE_SPELLING_TEST_SUCCESS",
  "@spelling_test/DELETE_SPELLING_TEST_FAILED"
)<void, { testId: number; groupId: number }, Error>();

export const deleteSpellingTest = (testId: number, groupId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteSpellingTestAction.request());
    return axios
      .delete(API_SPELLING_TESTS(testId))
      .then(() => {
        dispatch(deleteSpellingTestAction.success({ testId, groupId }));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteSpellingTestAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const getSpellingTestQuestionsAction = createAsyncAction(
  "@spelling_test/GET_SPELLING_TEST_QUESTIONS_REQUEST",
  "@spelling_test/GET_SPELLING_TEST_QUESTIONS_SUCCESS",
  "@spelling_test/GET_SPELLING_TEST_QUESTIONS_FAILED"
)<void, SpellingTestWordGroup[], Error>();

export const getSpellingTestQuestions = (grade: Grade) => {
  return (dispatch: Dispatch) => {
    dispatch(getSpellingTestQuestionsAction.request());
    return axios
      .get(API_SPELLING_TEST_QUESTIONS(grade))
      .then((res) => {
        const wordGroups: SpellingTestWordGroup[] = res.data.word_groups;
        dispatch(getSpellingTestQuestionsAction.success(wordGroups));
      })
      .catch((err: AxiosError) => {
        dispatch(getSpellingTestQuestionsAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const getStudentsByGradeAction = createAsyncAction(
  "@spelling_test/GET_STUDENTS_BY_GRADE_REQUEST",
  "@spelling_test/GET_STUDENTS_BY_GRADE_SUCCESS",
  "@spelling_test/GET_STUDENTS_BY_GRADE_FAILED"
)<void, Student[], Error>();

export const getStudentsByGrade = (grade: Grade) => {
  return (dispatch: Dispatch) => {
    dispatch(getStudentsByGradeAction.request());
    return axios
      .get(API_STUDENTS_BY_GRADE(grade))
      .then((res) => {
        const students: Student[] = res.data;
        dispatch(getStudentsByGradeAction.success(students));
      })
      .catch((err: AxiosError) => {
        dispatch(getStudentsByGradeAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const generateStudentReadingDiagnosticsAction = createAsyncAction(
  "@spelling_test/GENERATE_STUDENT_READING_DIAGNOSTICS_REQUEST",
  "@spelling_test/GENERATE_STUDENT_READING_DIAGNOSTICS_SUCCESS",
  "@spelling_test/GENERATE_STUDENT_READING_DIAGNOSTICS_FAILED"
)<void, void, Error>();

export const generateStudentReadingDiagnostics = (spellingTestId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(generateStudentReadingDiagnosticsAction.request());
    return axios
      .get(API_SPELLING_TEST_GENERATE_DIAGNOSTIC(spellingTestId))
      .then(() => {
        dispatch(generateStudentReadingDiagnosticsAction.success());
      })
      .catch((err: AxiosError) => {
        dispatch(generateStudentReadingDiagnosticsAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const changeSelectedStudent = createAction(
  "@spelling_test/CHANGE_SELECTED_STUDENT"
)<Student | undefined>();

export const changeSelectedSpellingTest = createAction(
  "@spelling_test/CHANGE_SELECTED_SPELLING_TEST"
)<SpellingTest | undefined>();

export const generateSpellingTestAnswers = createAction(
  "@spelling_test/GENERATE_SPELLING_TEST_ANSWERS"
)<{ questions: SpellingTestQuestion[]; answers?: SpellingTestAnswer[] }>();

export const changeSpellingTestStudentAnswer = createAction(
  "@spelling_test/CHANGE_SPELLING_TEST_STUDENT_ANSWER"
)<{ word: string; student_answer: string }>();

export const markSpellingTestStudentAnswer = createAction(
  "@spelling_test/MARK_SPELLING_TEST_STUDENT_ANSWER"
)<{ word: string; correct: boolean }>();

export const markEmptySpellingTestStudentAnswerPartAsCorrect = createAction(
  "@spelling_test/MARK_EMPTY_SPELLING_TEST_STUDENT_ANSWER_PART_AS_CORRECT"
)<string>();

export const changeSpellingTestAnswerGrammarPart = createAction(
  "@spelling_test/CHANGE_SPELLING_TEST_ANSWER_GRAMMAR_PART"
)<{
  word: string;
  type: GrammarType;
  student_answer: string;
  correct: boolean;
}>();

export const showSpellingTestSummaryModal = createAction(
  "@spelling_test/SHOW_SPELLING_TEST_SUMMARY_MODAL"
)<SpellingTestGroup>();

export const hideSpellingTestSummaryModal = createAction(
  "@spelling_test/HIDE_SPELLING_TEST_SUMMARY_MODAL"
)();
