import React, { FunctionComponent, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { antecedentTypes } from "./types";
import FbaListItem from "./FbaListItem";
import { FbaABC } from "../../../../../../store/onboarding/cases/types";
import _ from "lodash";

type Props = {
  observationabc: FbaABC[];
  antecedents: (number | undefined)[];
  setAntecedents: React.Dispatch<React.SetStateAction<(number | undefined)[]>>;
  customNotes: (string | undefined)[];
  setCustomNotes: React.Dispatch<React.SetStateAction<(string | undefined)[]>>;
};

const AntecedentEvents: FunctionComponent<Props> = (props) => {
  const {
    observationabc,
    antecedents,
    setAntecedents,
    customNotes,
    setCustomNotes,
  } = props;

  const onCustomOptionChange = (value?: string, index?: number) => {
    setCustomNotes((cn) =>
      antecedentTypes.map((at, ind) => (index === ind ? value : cn[ind]))
    );
  };

  const handleAntecedentsChange = (
    fbaAntecedent: number,
    index?: number
  ) => () => {
    setAntecedents((s) =>
      antecedentTypes.map((fCg, ind) =>
        index === ind ? fbaAntecedent : s[ind]
      )
    );
    setCustomNotes((cn) =>
      antecedentTypes.map((at, ind) => (index === ind ? undefined : cn[ind]))
    );
  };

  const previousCustomRecords: string[][] = useMemo(
    () =>
      _.chain(observationabc)
        .reduce<string[][]>(
          (pV, cV) => {
            return pV.map((arr, index) =>
              cV.other_antecedents && cV.other_antecedents[index]
                ? [...arr, cV.other_antecedents[index] as string]
                : arr
            );
          },
          [[], [], []]
        )
        .map(_.sortedUniq)
        .value(),
    [observationabc]
  );

  const allCustomRecords: string[][] = useMemo(() => {
    return antecedentTypes.map((fbaCg, index) => {
      const customNote = customNotes[index];
      const prevNotes = previousCustomRecords[index] ?? [];
      return customNote ? [customNote, ...prevNotes] : [...prevNotes];
    });
  }, [customNotes, previousCustomRecords]);

  return (
    <div>
      <h4 className="text-center font-weight-bold text-underline mb-3">
        Select one option from each column below
      </h4>
      <Row>
        {antecedentTypes.map((at, index) => (
          <Col
            key={`header_column_${index}`}
            sm={4}
            className="font-weight-bold"
          >
            {at.title}
          </Col>
        ))}
        {antecedentTypes.map((at, index) => (
          <Col key={`options_column_${index}`} sm={4}>
            {at.items.map((item) => (
              <FbaListItem
                key={item.value}
                index={index}
                onChange={handleAntecedentsChange}
                fbaItem={item}
                activeItem={antecedents[index]}
                customOption={customNotes[index]}
                previousCustomRecords={allCustomRecords[index]}
                onCustomOptionChange={onCustomOptionChange}
              />
            ))}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default AntecedentEvents;
