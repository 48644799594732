import React, { FunctionComponent, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import { Intervention } from "../../../../../../store/onboarding/cases/types";
import { Portal } from "react-portal";

type OwnProps = {
  intervention: Intervention;
};

type Props = OwnProps;

const PdfBtn: FunctionComponent<Props> = ({ intervention }) => {
  const attachments = [...intervention.attachments, ...intervention.checklist_items.flatMap((ci) => ci.attachments)];
  const attachmentUrl = useMemo(() => {
    let attachmentURL = '';
    if(window.location.host.includes('localhost')) {
      attachmentURL = 'http://localhost:8000'
    } else if (window.location.host === 'staging.readycoach.net'){
      attachmentURL = 'https://stage-api.readycoach.net'
    }
    return attachmentURL;
  },[attachments])
  // const downloadFile = (attachment_url: string) => (event: React.FormEvent) => {
  //   event.preventDefault();
  //   if (attachment_url && attachment_url.startsWith('/media')){
  //     let attachmentURL = 'http://localhost:8000' + attachment_url
  //     if (window.location.host === 'staging.readycoach.net'){
  //       attachmentURL = 'https://stage-api.readycoach.net' + attachment_url
  //     } else if (window.location.host === 'app.readycoach.net'){
  //       attachmentURL = 'https://api.readycoach.net' + attachment_url
  //     } else if (window.location.host === 'pre-prod.readycoach.net'){
  //       attachmentURL = 'https://pre-prod-api.readycoach.net' + attachment_url
  //     }
  //     window.open(attachmentURL,"_blank")
  //   }
  // }
 
 
  if (!attachments.length) {
    return <div />;
  } else if (attachments.length === 1) {
    return (
      <a
        href={attachmentUrl + attachments[0].attachment_url!}
        target="_blank"
        rel="noopener noreferrer"
        className="interventionPdfButton"
        onClick={(event) => {
          event.stopPropagation();
        }}
        // onClick={downloadFile(attachments[0].attachment_url!)}
      >
        <FontAwesomeIcon icon={faDownload} />
        PDF
      </a>
    );
  } else {
    return (
      <Dropdown
        // show
        drop="left"
        className="pdfDropdown"
        onClick={(e: any) => e.stopPropagation()}
      >
        <Dropdown.Toggle id="pdf-files-dropdown">
          <div className="interventionPdfButton">
            <FontAwesomeIcon icon={faDownload} />
            PDF
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {attachments.map((attachment) => (
            <Dropdown.Item as="button" key={attachment.id}>
              <a
                href={attachmentUrl + (attachment.attachment_url! || attachment.url!)}
                target="_blank"
                rel="noopener noreferrer"
                //onClick={downloadFile(attachment.attachment_url! || attachment.url!)}
              >
                {attachment.title}
              </a>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
};

export default PdfBtn;
