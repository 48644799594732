import React, { FunctionComponent, useCallback, useMemo } from "react";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { generateAbbrev } from "../../../../../../../utils/NamesUtils";

type OwnProps = {
  name: string;
  areaName?: string;
  showFullNames?: boolean;
  focusType?: string;
};

type Props = OwnProps;

const FocusCell: FunctionComponent<Props> = (props) => {
  const { name, areaName, showFullNames, focusType } = props;

  const focusInitials = useMemo(() => {
    const abbrev = name.replace(/(?:\s?\(.*\))|(?::.*$)/gm, "");
    return abbrev.startsWith("-") ? abbrev : generateAbbrev(abbrev);
  }, [name]);

  const areaInitials = useMemo(() => {
    return areaName ? generateAbbrev(areaName) : null;
  }, [areaName]);

  const fullName = useMemo(() => {
    return areaName && ['area','behavior_rec','read_rec'].includes(focusType!) ? name : `${areaName}: ${name}`;
  }, [areaName, name, focusType]);

  const tooltip = useCallback(
    (props: any) => (
      <Tooltip {...props} className="customTooltip" id={"focus"}>
        {fullName}
      </Tooltip>
    ),
    [fullName]
  );

  return (
    <th className="test">
      <OverlayTrigger
        trigger={showFullNames ? [] : ["hover", "focus"]}
        overlay={tooltip}
      >
        <div className="text-nowrap">
          {showFullNames
            ? fullName
            : areaInitials && ['area','behavior_rec','read_rec'].includes(focusType!)
            ? focusInitials
            : `${areaInitials}: ${focusInitials}`}
        </div>
      </OverlayTrigger>
    </th>
  );
};

export default FocusCell;
