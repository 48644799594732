import React, { FunctionComponent } from "react";
import InterventionDurationOption from "./InterventionDurationOption";
import { InterventionDuration } from "../../../../../../../../store/onboarding/cases/types";

type OwnProps = {
  duration: InterventionDuration;
  setDuration: any;
  onClickNext: () => void;
};

type Props = OwnProps;

const SetDurationForm: FunctionComponent<Props> = ({
  duration,
  setDuration,
  onClickNext,
}) => {
  return (
    <div className="interventionModalContent">
      <div className="text-center">
        <h3 className="font-weight-bold">
          Generally how long is the intervention meant to be implemented for?
        </h3>
        <br />
        <div className="mx-4 px-4">
          <InterventionDurationOption
            value={InterventionDuration.SHORT_TERM}
            setValue={setDuration}
            selectedValue={duration}
            label={"Short Term (less than a month)"}
          />
          <InterventionDurationOption
            value={InterventionDuration.MODERATE}
            setValue={setDuration}
            selectedValue={duration}
            label={"Moderate (2 - 4 months)"}
          />
          <InterventionDurationOption
            value={InterventionDuration.LONG_TERM}
            setValue={setDuration}
            selectedValue={duration}
            label={"Long Term (more than 6 months)"}
          />
        </div>
      </div>
      <hr />
      <div className="modalActions">
        <div />
        <button className="blueBtnSm" onClick={onClickNext}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default SetDurationForm;
