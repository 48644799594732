import React, { useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";

type Props = {
  showModal: boolean;
  onConfirm: () => any;
  onHide: () => any;
  isLoading: boolean;
  deleteModal: boolean;
};

const ConfirmModal = (props: Props) => {
  useEffect(() => {
    if (props.showModal && !props.isLoading) {
      props.onHide();
    }
  }, [props.isLoading]);

  const handleConfirm = () => {
    if (!props.isLoading) {
      props.onConfirm();
    }
  };

  return (
    <Modal
      show={props.showModal}
      onHide={props.isLoading ? undefined : props.onHide}
      animation={false}
      size="sm"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="orangeModalHeader font-weight-bold">
        <Modal.Title>
          {props.deleteModal ? "Confirm Deletion" : "Confirm"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center m-3">
          <h4>
            {props.deleteModal
              ? "Are you sure you want to delete this data?"
              : "Are you sure?"}
          </h4>
        </div>
        <div className="d-flex mt-4">
          <button
            className="blueBtnSm w-100 mr-2"
            disabled={props.isLoading}
            onClick={props.onHide}
          >
            Cancel
          </button>
          <button className="blueBtnSm w-100" onClick={handleConfirm}>
            Confirm{" "}
            {props.isLoading && <Spinner animation="border" size="sm" />}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
