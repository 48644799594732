import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { ApplicationState } from "../../../../../../store";
import {
  deleteDistrict,
  getDistrictLicenses,
  selectDistrict,
  setShowDistrictLicenseModal,
} from "../../../../../../store/superuser/actions";
import { push } from "connected-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPen,
  faPlusCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { SuperuserState } from "../../../../../../store/superuser/types";
import { USStateNamesValues } from "../../../../../common/States";
import DistrictLicensesTable from "./common/DistrictLicensesTable";
import DistrictLicenseModal from "../dialogs/DistrictLicenseModal";
import ConfirmModal from "../../../../../common/reading-interventions/ConfirmModal";
import { getFullName } from "../../../../../../utils/NamesUtils";
import UpsertSchoolModal from "../../../../district-owner-dashboard/tabs/schools/UpsertSchoolModal";
import {
  changeSchoolsList,
  openUpsertSchoolModal,
} from "../../../../../../store/onboarding/actions";
import SchoolsTable from "../../../../district-owner-dashboard/tabs/schools/SchoolsTable";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";

type MatchParams = {
  districtId?: string;
};

type Props = RouteComponentProps<MatchParams>;

const DistrictDetailsRead: FunctionComponent<Props> = ({
  match: {
    params: { districtId },
  },
}) => {
  const { districts, selectedDistrict, isLoading } = useSelector<
    ApplicationState,
    SuperuserState
  >((s) => s.superuser);

  const dispatch = useDispatch();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    const foundedDistrict = districts.find(
      (item) => item.id.toString() === districtId
    );
    if (foundedDistrict) {
      dispatch(selectDistrict(foundedDistrict));
      dispatch(getDistrictLicenses(foundedDistrict.id));
    }
  }, [districts, districtId]);

  const handleBack = () => {
    dispatch(push("/superuser/districts"));
  };

  const handleEdit = () => {
    if (selectedDistrict) {
      dispatch(push(`/superuser/districts/${selectedDistrict.id}/edit`));
    }
  };

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const onConfirmDeleteModal = async () => {
    if (selectedDistrict) {
      await dispatch(deleteDistrict(selectedDistrict.id));
      handleBack();
    }
  };

  const onHideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const currentLicenseInfo = useMemo(
    () =>
      selectedDistrict?.current_license &&
      selectedDistrict.current_license.id != null
        ? `ID: ${selectedDistrict.current_license?.id}. ` +
          `Type: ${selectedDistrict.current_license?.license_type}. ` +
          `Status: ${selectedDistrict.current_license?.status}. ` +
          `Started: ${selectedDistrict.current_license?.started_at} ` +
          `Expires: ${selectedDistrict.current_license?.expires_at}`
        : "No Active Current License",
    [selectedDistrict?.current_license]
  );

  useEffect(() => {
    dispatch(changeSchoolsList(selectedDistrict?.schools ?? []));
  }, [selectedDistrict?.schools]);

  const loading = useSelector<ApplicationState, boolean>(
    (s) => s.superuser.isLoading.getDistrict
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    selectedDistrict && (
      <div className="onboardingContainer">
        <DistrictLicenseModal />
        <ConfirmModal
          showModal={showDeleteModal}
          onHide={onHideDeleteModal}
          onConfirm={onConfirmDeleteModal}
          isLoading={isLoading.deleteDistrict}
          deleteModal={true}
        />
        <div className="w-100 d-flex justify-content-between">
          <button
            className="blueBtnSm"
            onClick={handleBack}
            disabled={isLoading.updateDistrict}
          >
            <FontAwesomeIcon icon={faArrowLeft} style={{ color: "white" }} />{" "}
            Back
          </button>
          <div>
            <button
              className="blueBtnSm mr-3"
              onClick={handleDelete}
              disabled={isLoading.updateDistrict}
            >
              <FontAwesomeIcon icon={faTimes} style={{ color: "white" }} />{" "}
              Delete District
            </button>
            <button
              className="blueBtnSm"
              onClick={handleEdit}
              disabled={isLoading.updateDistrict}
            >
              <FontAwesomeIcon icon={faPen} style={{ color: "white" }} /> Edit
              District
            </button>
          </div>
        </div>
        <div className="mt-3">
          <h4 className="mt-3">
            <strong>District Name:</strong> {selectedDistrict.name}
          </h4>
          <hr />
          <h4 className="mt-3">
            <strong>District State:</strong>{" "}
            {USStateNamesValues?.find(
              (st) => st.value === selectedDistrict.state
            )?.display_name ?? selectedDistrict.name}
          </h4>
          <hr />
          <h4 className="mt-3">
            <strong>District Owner:</strong>{" "}
            {`${getFullName(selectedDistrict.owner)} (${
              selectedDistrict.owner.email
            })`}
          </h4>
          <hr />
          <h4 className="mt-3">
            <strong>Current License:</strong> {currentLicenseInfo}
          </h4>
          <hr />
          <h4 className="mt-3 w-100 d-flex justify-content-between">
            <strong>Licenses:</strong>
            <button
              className="blueBtnSm"
              onClick={() =>
                dispatch(
                  setShowDistrictLicenseModal({ show: true, license: null })
                )
              }
            >
              <FontAwesomeIcon icon={faPlusCircle} style={{ color: "white" }} />{" "}
              Create License
            </button>
          </h4>
          <DistrictLicensesTable district={selectedDistrict} />
          <hr />
          <h4 className="mt-3 d-flex justify-content-between">
            <strong>Schools:</strong>
            <button
              className="blueBtnSm"
              onClick={() =>
                dispatch(openUpsertSchoolModal(undefined, selectedDistrict?.id))
              }
            >
              <FontAwesomeIcon icon={faPlusCircle} style={{ color: "white" }} />{" "}
              Setup New School
            </button>
          </h4>
          <SchoolsTable />
          <UpsertSchoolModal />
        </div>
      </div>
    )
  );
};

export default withRouter(DistrictDetailsRead);
