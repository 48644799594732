import React, { FunctionComponent } from "react";
import {
  GrammarType,
  SpellingTestWordGroup,
} from "../../../../store/spelling-tests/types";
import WordRow from "./WordRow";

type Props = {
  wordGroup: SpellingTestWordGroup;
  selectedGrammarTypes: GrammarType[];
  colSpan: number;
};

const WordGroup: FunctionComponent<Props> = (props) => {
  const { wordGroup, selectedGrammarTypes, colSpan } = props;

  return (
    <tbody>
      <tr className="eng-lang-standard-row">
        <td colSpan={colSpan}>
          <strong>{wordGroup.header_label_prefix}:</strong>{" "}
          {wordGroup.header_label_text}
        </td>
      </tr>
      {wordGroup.words.map((q) => (
        <WordRow
          key={`question_${q.order}`}
          question={q}
          selectedGrammarTypes={selectedGrammarTypes}
        />
      ))}
    </tbody>
  );
};

export default WordGroup;
