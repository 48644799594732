import React, { useEffect, useState } from "react";
import {
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Spinner,
} from "react-bootstrap";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  addSchool,
  hideSchoolModal,
  updateSchool,
} from "../../../../../store/onboarding/actions";
import {
  AddSchoolRequest,
  School,
} from "../../../../../store/onboarding/types";
import { toastr } from "react-redux-toastr";

type PropsFromState = {
  showModal: boolean;
  selectedSchool?: School;
  isLoading: {
    addSchool: boolean;
    updateSchool: boolean;
  };
  errors: {
    addSchool?: string;
    updateSchool?: string;
  };
};

type DispatchProps = {
  hideSchoolModal: () => any;
  addSchool: (school: AddSchoolRequest) => any;
  updateSchool: (school: School) => any;
};

type Props = PropsFromState & DispatchProps;

const SchoolModal = (props: Props) => {
  const {
    showModal,
    isLoading,
    selectedSchool,
    hideSchoolModal,
    errors,
  } = props;
  const [schoolName, setSchoolName] = useState("");

  useEffect(() => {
    setSchoolName(selectedSchool ? selectedSchool.name : "");
  }, [selectedSchool]);

  useEffect(() => {
    if (!isLoading.addSchool) {
      if (errors.addSchool) {
        toastr.error("Error", errors.addSchool);
      } else {
        hideSchoolModal();
      }
    }
  }, [isLoading.addSchool]);

  useEffect(() => {
    if (!isLoading.updateSchool) {
      if (errors.updateSchool) {
        toastr.error("Error", errors.updateSchool);
      } else {
        hideSchoolModal();
      }
    }
  }, [isLoading.updateSchool]);

  const handleSubmit = (event: React.FormEvent<any>) => {
    event.preventDefault();
    if (!isLoading.addSchool && !isLoading.updateSchool) {
      if (selectedSchool) {
        props.updateSchool({
          ...selectedSchool,
          name: schoolName,
        });
      } else {
        props.addSchool({
          name: schoolName,
        });
      }
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={hideSchoolModal}
      centered
      animation={false}
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="orangeModalHeader font-weight-bold">
        <Modal.Title>
          {selectedSchool ? "Edit School" : "Add School"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <FormGroup controlId="name">
            <FormLabel>
              <h4>School Name</h4>
            </FormLabel>
            <FormControl
              autoFocus
              className="fullWidthInput"
              name={"name"}
              type="text"
              value={schoolName}
              onChange={(e: any) =>
                setSchoolName((e.target as HTMLInputElement).value)
              }
              required
            />
          </FormGroup>
          <div className="d-flex justify-content-center">
            <button
              className="blueBtnMd mr-2"
              disabled={isLoading.addSchool || isLoading.updateSchool}
              onClick={hideSchoolModal}
            >
              Cancel
            </button>
            <button className="blueBtnMd" type="submit" disabled={!schoolName}>
              {selectedSchool ? "Update School " : "Add School "}
              {(isLoading.addSchool || isLoading.updateSchool) && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    showModal: onboarding.modalsState.schoolModal,
    selectedSchool: onboarding.selectedSchool,
    isLoading: {
      addSchool: onboarding.isLoading.addSchool,
      updateSchool: onboarding.isLoading.updateSchool,
    },
    errors: {
      addSchool: onboarding.errors.addSchool,
      updateSchool: onboarding.errors.updateSchool,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideSchoolModal: hideSchoolModal,
      addSchool: addSchool,
      updateSchool: updateSchool,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SchoolModal);
