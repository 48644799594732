import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import BootstrapTable, {
  SelectRowProps,
  SortOrder,
} from "react-bootstrap-table-next";
import { Button, Modal, Spinner } from "react-bootstrap";
import {
  addAssessmentToDataPeriod,
  getEvidenceAssessments,
  hideEnterDataManuallyModal,
  hidePickDataSourceModal,
  openAddAssessmentModal,
} from "../../../../../store/onboarding/actions";
import LoadingIndicator from "../../LoadingIndicator";
import {
  EvidenceAssessment,
  ManuallyAssessment,
} from "../../../../../store/onboarding/types";
import { toastr } from "react-redux-toastr";
import { GoalTypes } from "../../../../../store/onboarding/cases/types";
import AssessmentSuggestionsModal from "./AssessmentSuggestionsModal";

const columns = [
  {
    dataField: "id",
    text: "Assessment Id",
    hidden: true,
  },
  {
    dataField: "name",
    text: "Assessment",
    sort: true,
  },
  {
    dataField: "dataColumns",
    text: "DATA COLUMNS/SUBSCALES",
    sort: true,
  },
];

const defaultSorted: [{ dataField: string; order: SortOrder }] = [
  {
    dataField: "name",
    order: "asc",
  },
];

type PropsFromState = {
  showModal: boolean;
  enterDataManuallyType?: GoalTypes;
  evidenceAssessments: Array<EvidenceAssessment>;
  isLoading: {
    getEvidenceAssessments: boolean;
    addAssessmentToDataPeriod: boolean;
  };
  errors: {
    getEvidenceAssessments?: string;
    addAssessmentToDataPeriod?: string;
  };
};

type DispatchProps = {
  getEvidenceAssessments: (type?: GoalTypes) => any;
  addAssessmentToDataPeriod: (assessment: ManuallyAssessment) => any;
  hideEnterDataManuallyModal: () => any;
  hidePickDataSourceModal: () => any;
  openAddAssessmentModal: () => any;
};

type Props = PropsFromState & DispatchProps;

type State = {
  assessmentsFilter: string;
  selectedAssessmentId?: number;
  evidenceAssessments: Array<EvidenceAssessment>;
  showSuggestionModal: boolean;
};

class EnterDataManuallyModal extends Component<Props, State> {
  state: Readonly<State> = {
    evidenceAssessments: [],
    assessmentsFilter: "",
    selectedAssessmentId: undefined,
    showSuggestionModal: false,
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (
      prevProps.isLoading.getEvidenceAssessments &&
      !this.props.isLoading.getEvidenceAssessments &&
      !this.props.errors.getEvidenceAssessments
    ) {
      this.setState({ evidenceAssessments: this.props.evidenceAssessments });
    }
    if (
      prevProps.isLoading.addAssessmentToDataPeriod &&
      !this.props.isLoading.addAssessmentToDataPeriod
    ) {
      if (this.props.errors.addAssessmentToDataPeriod) {
        toastr.error("Error", this.props.errors.addAssessmentToDataPeriod);
      } else {
        this.props.hidePickDataSourceModal();
      }
    }
  }

  handleModalShow = () => {
    this.props.getEvidenceAssessments(this.props.enterDataManuallyType);
    this.setState({
      assessmentsFilter: "",
      selectedAssessmentId: undefined,
    });
  };

  handleOnSelect = (row: any, isSelect: boolean) => {
    if (isSelect) {
      this.setState({
        selectedAssessmentId: row.id,
      });
    }
  };

  handleAssessmentsFilterChange = (event: React.ChangeEvent<any>) => {
    event.preventDefault();
    const { value } = event.target as HTMLInputElement;
    this.setState({
      assessmentsFilter: value,
      evidenceAssessments: this.props.evidenceAssessments.filter(
        (evidenceAssessment) =>
          evidenceAssessment.name
            .toLowerCase()
            .includes(value.trim().toLowerCase())
      ),
    });
  };

  handleAddAssessment = () => {
    const { selectedAssessmentId } = this.state;
    if (selectedAssessmentId) {
      const evidenceAssessment = this.props.evidenceAssessments.find(
        (evidenceAssessment) => evidenceAssessment.id === selectedAssessmentId
      );
      if (!evidenceAssessment) {
        return;
      }
      const manuallyAssessment: ManuallyAssessment = {
        assessment_id: selectedAssessmentId,
        measurement_ids: evidenceAssessment.measurements.map(
          (measurement) => measurement.id!
        ),
      };
      this.props.addAssessmentToDataPeriod(manuallyAssessment);
    }
  };

  handleModalHide = () => {
    if (
      !this.props.isLoading.getEvidenceAssessments &&
      !this.props.isLoading.addAssessmentToDataPeriod
    ) {
      setTimeout(() => {
        this.props.hideEnterDataManuallyModal();
      }, 1);
      this.setState({ showSuggestionModal: false });
    }
  };

  render() {
    const selectRow: SelectRowProps<any> = {
      mode: "radio",
      clickToSelect: true,
      selected: this.state.selectedAssessmentId
        ? [this.state.selectedAssessmentId]
        : [],
      onSelect: this.handleOnSelect,
    };
    const { selectedAssessmentId, evidenceAssessments } = this.state;

    const isLoading =
      this.props.isLoading.getEvidenceAssessments ||
      this.props.isLoading.addAssessmentToDataPeriod;
    const isEmpty =
      evidenceAssessments.length === 0 &&
      !this.props.isLoading.getEvidenceAssessments;

    return (
      <Modal
        animation={false}
        size="lg"
        backdropClassName="customDarkModalBackdrop in"
        show={this.props.showModal}
        onShow={this.handleModalShow}
        onHide={this.handleModalHide}
      >
        <Modal.Header closeButton className="purpleModalHeader">
          <Modal.Title>Enter Data Manually</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-between mb-xl-3 mb-2">
            <div>
              <h3 className="font-weight-bold">Please select an assessment to add in the current data period table.</h3>
              {/* <h3 className="font-weight-bold">
                If you need suggestions on assessment please{" "}
                <u>
                  <a
                    className="blueLink"
                    onClick={() => this.setState({ showSuggestionModal: true })}
                  >
                    email us.
                  </a>
                </u>
              </h3> */}
            </div>
            {/* <div>
              <button
                className="blueBtnMd"
                disabled={isLoading || !selectedAssessmentId}
                onClick={this.handleAddAssessment}
              >
                Select{" "}
                {this.props.isLoading.addAssessmentToDataPeriod && (
                  <Spinner animation="border" size="sm" />
                )}
              </button>
            </div> */}
          </div>
          {/*<br />*/}
          <div className="spaceBetween mb-xl-3 mb-2">
            <Button
              bsPrefix="b"
              className="blueBtnSm"
              onClick={() => this.props.openAddAssessmentModal()}
            >
              Create a new assessment
            </Button>
            <input
              type="text"
              placeholder="Search for Assessments..."
              value={this.state.assessmentsFilter}
              onChange={this.handleAssessmentsFilterChange}
              className="stdInput"
            />
          </div>
          {/*<br />*/}
          {isLoading && <LoadingIndicator/>}
          {!isLoading 
            ?
              <>
              <BootstrapTable
                wrapperClasses={
                  evidenceAssessments.length === 0
                    ? "rosterTableEmpty"
                    : "fixedHeightTable"
                }
                classes="stdTable"
                keyField="id"
                bordered={false}
                bootstrap4
                columns={columns}
                data={evidenceAssessments.map((evidenceAssessment) => ({
                  id: evidenceAssessment.id,
                  name: evidenceAssessment.name,
                  dataColumns: evidenceAssessment.measurements
                    .map((measurement) => measurement.display_name)
                    .join(", "),
                }))}
                defaultSorted={defaultSorted}
                noDataIndication={
                  isEmpty ? "No matching assessments" : <LoadingIndicator />
                }
                selectRow={selectRow}
              />
              <hr className="narrowMargin" />

              <div className="btnActions" style={{ marginTop: "20px" }}>
                <div>&nbsp;</div>
                <div>
                  <Button
                    disabled={isLoading || !selectedAssessmentId}
                    bsPrefix="b"
                    className="blueBtnSm"
                    onClick={this.handleAddAssessment}
                  >
                    Select{" "}
                    {this.props.isLoading.addAssessmentToDataPeriod && (
                      <Spinner animation="border" size="sm" />
                    )}
                  </Button>
                </div>
              </div>
              </>
            : ''
          }          
          {/* <AssessmentSuggestionsModal
            show={this.state.showSuggestionModal}
            onClose={() => this.setState({ showSuggestionModal: false })}
          /> */}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    showModal: onboarding.modalsState.enterDataManuallyModal,
    enterDataManuallyType: onboarding.modalsState.enterDataManuallyType,
    evidenceAssessments: onboarding.evidenceAssessments,
    isLoading: {
      getEvidenceAssessments: onboarding.isLoading.getEvidenceAssessments,
      addAssessmentToDataPeriod: onboarding.isLoading.addAssessmentToDataPeriod,
    },
    errors: {
      getEvidenceAssessments: onboarding.errors.getEvidenceAssessments,
      addAssessmentToDataPeriod: onboarding.errors.addAssessmentToDataPeriod,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getEvidenceAssessments: getEvidenceAssessments,
      addAssessmentToDataPeriod: addAssessmentToDataPeriod,
      hideEnterDataManuallyModal: hideEnterDataManuallyModal,
      hidePickDataSourceModal: hidePickDataSourceModal,
      openAddAssessmentModal: openAddAssessmentModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnterDataManuallyModal);
