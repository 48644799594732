import React, { FunctionComponent } from "react";
import LoggedInterventionsTable from "../../../implementation-check/LoggedInterventionsTable";
import { InterventionGroup } from "../../../../../../../../store/onboarding/cases/types";

type OwnProps = {
  interventionGroup: InterventionGroup;
  onlyActive?: boolean;
  isStudentDetail?: boolean;
  isGroupDetail?: boolean;
};

type Props = OwnProps;

const LoggedInterventionsTab: FunctionComponent<Props> = (props) => {
  const { interventionGroup, onlyActive, isStudentDetail, isGroupDetail } = props;

  return (
    <>
      <LoggedInterventionsTable
        interventionGroup={interventionGroup}
        onlyActive={onlyActive}
        isStudentDetail={isStudentDetail}
        isGroupDetail={isGroupDetail}
      />
    </>
  );
};

export default LoggedInterventionsTab;
