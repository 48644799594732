import { faEdit, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { RefObject, useMemo, useState } from "react";
import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../../../../store";
import { UserInfo } from "../../../../../store/auth/types";
import {
  getParents,
  openInviteParentModal,
  setSelectedParent,
} from "../../../../../store/onboarding/actions";
import LoadingIndicator from "../../LoadingIndicator";
import AddStudentToTableOverlay from "../evidence-tab/student-data-table/AddStudentToTableOverlay";
import {
  ReactSelectOption,
  StudentInfo,
} from "../../../../../store/onboarding/types";
import { getFullName } from "../../../../../utils/NamesUtils";

type PropsFromState = {
  parents: Array<UserInfo>;
  studentsRoster: StudentInfo[];
  selectedParent?: UserInfo;
};

type DispatchProps = {
  getParents: () => any;
  openParentDetailsModal: () => any;
  setSelectedParent: (selectedParent?: UserInfo) => any;
};

type Props = PropsFromState & DispatchProps;

const defaultSorted: [{ dataField: string; order: SortOrder }] = [
  {
    dataField: "educator",
    order: "asc",
  },
];

const options = {
  sizePerPageList: [
    {
      text: "20",
      value: 20,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
  ],
};

const ParentsTable: React.FunctionComponent<Props> = (props) => {
  const [selectedParent, setSelectedParent] = useState<UserInfo | undefined>(
    undefined
  );
  const [activeRef, setActiveRef] = useState<any>(undefined);

  const parents = useMemo(() => {
    return props.parents.map((parent) => {
      return {
        parent: parent,
        ref: React.createRef(),
      };
    });
  }, [props.parents]);

  const columnEvents = {
    onClick: (
      e: React.MouseEvent,
      column: any,
      columnIndex: number,
      row: any,
      rowIndex: number
    ) => {
      const selectedParent = parents.find(
        (parent) => parent.parent.id === row.id
      );
      props.setSelectedParent(selectedParent?.parent!);
      props.openParentDetailsModal();
    },
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      events: columnEvents,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      events: columnEvents,
    },
    {
      dataField: "children",
      text: "Children",
    },
  ];

  const getEmailDisplay = (email: string): string => {
    if (email.indexOf("njtss_user_imported") >= 0) {
      return "Imported From File";
    } else {
      return email;
    }
  };

  const getChildCell = (parent: UserInfo, ref: RefObject<any>) => {
    return (
      <div className="d-flex align-items-center" style={{ overflowX: "unset" }}>
        <span
          ref={ref}
          onClick={(e) => {
            // console.log(showAddStudent);
            setSelectedParent(parent);
            setActiveRef(ref);
          }}
        >
          {parent.children?.length ? (
            <>
              {parent.children
                ?.map((student) => getFullName(student))
                .join(", ")}
              <FontAwesomeIcon icon={faEdit} className="ml-1" />
            </>
          ) : (
            <FontAwesomeIcon icon={faPlusCircle} />
          )}
        </span>
      </div>
    );
  };

  const data = useMemo(
    () =>
      parents.map((obj) => ({
        id: obj.parent.id,
        name: `${obj.parent.first_name} ${obj.parent.last_name}`,
        email: getEmailDisplay(obj.parent.email),
        children: getChildCell(obj.parent, obj.ref),
      })),
    [parents, props.studentsRoster]
  );

  return (
    <>
      <BootstrapTable
        classes="rosterTable"
        wrapperClasses={parents.length === 0 ? "rosterTableEmpty" : ""}
        keyField="id"
        bordered={false}
        bootstrap4
        data={data}
        columns={columns}
        defaultSorted={defaultSorted}
        pagination={paginationFactory(options)}
        noDataIndication={<LoadingIndicator />}
      />

      <AddStudentToTableOverlay
        selectedParent={selectedParent}
        showOverlay={!!selectedParent}
        buttonRef={activeRef}
        onHide={() => {
          setSelectedParent(undefined);
        }}
      />
    </>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    parents: onboarding.parents,
    studentsRoster: onboarding.studentsRoster,
    selectedParent: onboarding.selectedParent,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getParents,

      openParentDetailsModal: openInviteParentModal,
      setSelectedParent: setSelectedParent,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParentsTable);
