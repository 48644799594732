import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  DataPeriod,
  StudentInfo,
  TeacherInfo,
  CoachInfo,
} from "../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { CasesState, InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import InterventionsGroup from "../assigment/InterventionsGroup";
import useUserRole from "../../../../../../utils/hooks/useUserRole";
import { DataFilterState } from "../../../../../../store/dataFilter/types";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";
import NoGroupsContainer from "../../../../../common/reading-interventions/intervention-tools/groups-tab/NoGroupsContainer";
import InterventionFidelityTour from "../../../tours/InterventionFidelityTour";
import FidelityChartTour from "../../../tours/FidelityChartTour";
import { Accordion, Alert, Button, Card } from "react-bootstrap";
import ToolBar from "../common/ToolBar";
import useInterventionGroupsMap from "./hooks/useInterventionGroupsMap";
import RecommendationsSection from "./RecommendationsSection";
import {
  getNotFinishedGroups,
  getRecommendations,
  callFromStudentDetail,
  sendNotSharedWithData,
  sendSharedWithData,
  hideInterventionPlanModal,
} from "../../../../../../store/onboarding/cases/actions";
import useUnsavedGroupCards from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/hooks/useUnsavedGroupCards";
import SetTimeModal from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/modals/SetTimeModal";
import UnsaveConfirmModal from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/modals/UnsaveConfirmModal";
import useGroupCardDragging from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/hooks/useGroupCardDragging";
import AddInterventionModal from "../../../../../common/onboarding/third-step/find-intervention/AddInterventionModal";
import { getSkillFocuses } from "../../../../../../store/groups/actions";
import InterventionStatsModal from "./InterventionStatsModal";
import {  useParams } from "react-router";
import { createBrowserHistory } from "history";
import { UserInfo } from "../../../../../../store/auth/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { InterventionGroupMap } from "../../../../../../utils/Mappers";
import useActiveTab from "../../../../parent-dashboard/useActiveTab";
import { getCoaches, getTeachers, interventionGroupAccess } from "../../../../../../store/onboarding/actions";

type Props = {
  student?: StudentInfo;
  isYourAssignments?: boolean;
  activeTab?: string;
};

const InterventionGroupList = ({ student, isYourAssignments, activeTab }: Props) => {
  const { isParent, isSupervisor, isTeacher, isCoach, isDistrictOwner } = useUserRole();
  const dispatch = useDispatch();
  const tabName = useSelector<ApplicationState>((s)=> s.cases.tabName);
  const history:any = useParams();
  
  const [ isStudentDetail, setIsStudentDetail] = useState<boolean>(false);
  const [openShareData, setOpenShareData] = useState(false);
  const [openCaseLoadPage, setOpenCaseLoadPage] = useState(false);
  const { groupSearch, showArchivedGroups, groupSort } = useSelector<
    ApplicationState,
    DataFilterState
  >((s) => s.dataFilter);

  const { isLoading } = useSelector<ApplicationState, CasesState>(
    (s) => s.cases
  );

  const displayGroupChange = useSelector<
    ApplicationState
  >((s) => s.cases.displayGroupName);

  const loggedInUserId = useSelector<
    ApplicationState
  >((s) => s.auth.userInfo?.id);

  const {
    interventionGroupsMap,
    interventionGroupsMapWithNotFinished,
  } = useInterventionGroupsMap({ student });

  const intGroupsMap = groupSort
  ? interventionGroupsMap
  : interventionGroupsMapWithNotFinished;
  
  const getCoachShareDataWith = () => {
    const mergedGroups: InterventionGroupMap[] = Array.from(new Set([...interventionGroupsMap]))
    let refrenceArray:any = {};

    let shareDataWithTrue:InterventionGroup[] = [];
    mergedGroups.forEach((mergedGroup)=> 
      mergedGroup.interventionGroups.forEach((intGroups) => {
        let refObj = {};
        refObj = {
          ...refObj,
          [intGroups.coach_assignment!.id]: intGroups.coach_assignment
        }
        refrenceArray = {
          ...refrenceArray, ...refObj
        };
        if(intGroups.share_data_with) {
          shareDataWithTrue.push(intGroups)
        }
      }));
    
    const shareDataWithTrueGroupBy:any = {};
    shareDataWithTrue.forEach((sdwt:InterventionGroup) => {
      let isExist = shareDataWithTrueGroupBy[sdwt.coach_assignment!.id];
      if(isExist) {
          isExist.push(sdwt)
      } else {
        shareDataWithTrueGroupBy[sdwt.coach_assignment!.id] = [sdwt];
      }
    });
    
    let shareDataWith = [];
    for (const sdwtgbKey in shareDataWithTrueGroupBy) {
      if (Object.prototype.hasOwnProperty.call(shareDataWithTrueGroupBy, sdwtgbKey)) {
        shareDataWith.push({
            employeeAssignment: refrenceArray[sdwtgbKey],
            interventionGroups: shareDataWithTrueGroupBy[sdwtgbKey]
        })
        
      }
    }

    return shareDataWith;
  }
  
  const getTeacherShareDataWith = () => {
    const mergedGroups: InterventionGroupMap[] = Array.from(new Set([...interventionGroupsMap]))
    // console.log("collectiveGroup", collectiveGroup);
    // const mergedGroups: InterventionGroupMap[] = Array.from(new Set([...collectiveGroup].map((data)=> data)))
    //console.log('mergedGroups',mergedGroups)
    let refrenceArray:any = {};

    let shareDataWithTrue:InterventionGroup[] = [];
    mergedGroups.forEach((mergedGroup)=> 
      mergedGroup.interventionGroups.forEach((intGroups) => {
        let refObj = {};
        refObj = {
          ...refObj,
          [intGroups.teacher_assignment!.id]: intGroups.teacher_assignment
        }
        refrenceArray = {
          ...refrenceArray, ...refObj
        };
        if(intGroups.share_data_with) {
          shareDataWithTrue.push(intGroups)
        }
      }));
      
    
    const shareDataWithTrueGroupBy:any = {};
    shareDataWithTrue.forEach((sdwt:InterventionGroup) => {
      let isExist = shareDataWithTrueGroupBy[sdwt.teacher_assignment!.id];
      if(isExist) {
          isExist.push(sdwt)
      } else {
        shareDataWithTrueGroupBy[sdwt.teacher_assignment!.id] = [sdwt];
      }
    });
    let shareDataWith = [];
    for (const sdwtgbKey in shareDataWithTrueGroupBy) {
      if (Object.prototype.hasOwnProperty.call(shareDataWithTrueGroupBy, sdwtgbKey)) {
        shareDataWith.push({
            employeeAssignment: refrenceArray[sdwtgbKey],
            interventionGroups: shareDataWithTrueGroupBy[sdwtgbKey]
        })
        
      }
    }
    return shareDataWith;
  }
  const shareDataWithIntGroupMap = useMemo(() => {
    if(isTeacher) {
      return getCoachShareDataWith()
    } else if(isCoach) {
      const shareData =  getTeacherShareDataWith();
      return shareData;
    } 
  }, [intGroupsMap]);

  const getCoachNotShareDataWith = () => {
    const mergedGroups: InterventionGroupMap[] = Array.from(new Set([...interventionGroupsMap]))
    let refrenceArray:any = {};

    let shareDataWithFalse:InterventionGroup[] = [];
    mergedGroups.forEach((mergedGroup)=> 
      mergedGroup.interventionGroups.forEach((intGroups) => {
        let refObj = {};
        refObj = {
          ...refObj,
          [intGroups.coach_assignment!.id]: intGroups.coach_assignment
        }
        refrenceArray = {
          ...refrenceArray, ...refObj
        };
        if(!intGroups.share_data_with) {
          shareDataWithFalse.push(intGroups)
        }
      }));
      
    const shareDataWithFalseGroupBy:any = {};
    shareDataWithFalse.forEach((sdwf:InterventionGroup) => {
      let isExist = shareDataWithFalseGroupBy[sdwf.coach_assignment!.id];
      if(isExist) {
        isExist.push(sdwf)
      } else {
        shareDataWithFalseGroupBy[sdwf.coach_assignment!.id] = [sdwf];
      }
    })
    

    let notShareDataWith = [];
    for (const sdwfgbKey in shareDataWithFalseGroupBy) {
      if (Object.prototype.hasOwnProperty.call(shareDataWithFalseGroupBy, sdwfgbKey)) {
        notShareDataWith.push({
            employeeAssignment: refrenceArray[sdwfgbKey],
            interventionGroups: shareDataWithFalseGroupBy[sdwfgbKey]
        })
        
      }
    }
    return notShareDataWith;
  }

  const getTeacherNotShareDataWith = () => {
    const mergedGroups: InterventionGroupMap[] = Array.from(new Set([...interventionGroupsMap]))
    let refrenceArray:any = {};

    let shareDataWithFalse:InterventionGroup[] = [];
    mergedGroups.forEach((mergedGroup)=> 
      mergedGroup.interventionGroups.forEach((intGroups) => {
        let refObj = {};
        refObj = {
          ...refObj,
          [intGroups.teacher_assignment!.id]: intGroups.teacher_assignment
        }
        refrenceArray = {
          ...refrenceArray, ...refObj
        };
        if(!intGroups.share_data_with) {
          shareDataWithFalse.push(intGroups)
        }
      }));
      
    const shareDataWithFalseGroupBy:any = {};
    shareDataWithFalse.forEach((sdwf:InterventionGroup) => {
      let isExist = shareDataWithFalseGroupBy[sdwf.teacher_assignment!.id];
      if(isExist) {
        isExist.push(sdwf)
      } else {
        shareDataWithFalseGroupBy[sdwf.teacher_assignment!.id] = [sdwf];
      }
    })

    let notShareDataWith = [];
    for (const sdwfgbKey in shareDataWithFalseGroupBy) {
      if (Object.prototype.hasOwnProperty.call(shareDataWithFalseGroupBy, sdwfgbKey)) {
        notShareDataWith.push({
            employeeAssignment: refrenceArray[sdwfgbKey],
            interventionGroups: shareDataWithFalseGroupBy[sdwfgbKey]
        })
        
      }
    }
    return notShareDataWith;
  }

  const notShareDataWithIntGroupMap = useMemo(() => {
    if(isTeacher) {
      return getCoachNotShareDataWith();
    } else if(isCoach) {
      return getTeacherNotShareDataWith();
    }
   
  }, [intGroupsMap]);

   

  const currentDataPeriod = useSelector<
    ApplicationState,
    DataPeriod | undefined
  >((s) => s.onboarding.currentDataPeriod);

  

  const isStudentDetailReducer = useSelector<
  ApplicationState
  >((s) => s.cases.isStudentDetail);

  useEffect(() => {
    setIsStudentDetail(!!isStudentDetailReducer);
  },[displayGroupChange,isStudentDetailReducer])


  useEffect(() => {
    if (isYourAssignments && (tabName === "groups") && !isStudentDetail) {
      dispatch(getRecommendations());
    }
    // if (isYourAssignments && (tabName === "groups/behavior" || tabName === "groups/reading")) {
    //   dispatch(getRecommendations());
    // }
  }, [currentDataPeriod?.id, tabName]);

  const historyURL = createBrowserHistory();
  const { changeActiveTab } = useActiveTab();
  
  useEffect(() => {
    return historyURL.listen((location, action) => {
      if(isYourAssignments && (tabName === "groups/behavior" || tabName === "groups/reading")) {
        if(action == 'POP') {
          changeActiveTab(tabName);
          dispatch(hideInterventionPlanModal());
        }  
      }
    })
  },[tabName])

  useEffect(() => {
    // if (isYourAssignments && tabName === "groups") {
    //   dispatch(getSkillFocuses());
    //   dispatch(getNotFinishedGroups());
    // }
    if (isYourAssignments || isDistrictOwner) {
      dispatch(getSkillFocuses());
        if(!showArchivedGroups) {
          dispatch(getNotFinishedGroups());
        }
    }
  }, [isYourAssignments, tabName, displayGroupChange]);
  

  const {
    onConfirmUnsaveGroup,
    onHideUnsaveGroup,
    onHideSetTime,
    onSaveSetTime,
    showUnsaveModal,
    showTimeModal,
    dayTime,
    isLoadingPatchInterventionGroup,
    isLoadingUnsaveInterventionGroup,
    ...rest
  } = useUnsavedGroupCards();

  const teachers = useSelector<ApplicationState, TeacherInfo[]>(
    (s) => s.onboarding.teachersRosterWithDummyUser
  );

  const coaches = useSelector<ApplicationState, CoachInfo[]>(
    (s) => s.onboarding.coachesRosterWithDummyUser
  );

  useEffect(() => {
    if (!coaches.length) {
      dispatch(getCoaches());
    }
    if (!teachers.length) {
      dispatch(getTeachers());
    }
  }, []);

  const { onDragStart, onDragEnd } = useGroupCardDragging();
  
  let interventionGroupsArray = useMemo(() => {
    if(isCoach || isTeacher) {
      return  !isStudentDetail ? intGroupsMap.filter(
        (interventionGroupMap) => isStudentDetail ? interventionGroupMap.interventionGroups
          .find((interventionGroup)=> interventionGroup.finished) : interventionGroupMap
        ): [];
    } else {
      return isStudentDetail && isDistrictOwner ? intGroupsMap.filter((interventionGroupMap) => interventionGroupMap.interventionGroups
      .find((interventionGroup)=> interventionGroup.finished)) : [];
    }
  },[intGroupsMap])

  let intGrpAccess = useMemo(() => {
    let intGrpIDsWithReadAccess:any[] = [];
    let intGrpIDsWithEditAccess:any[] = [];
        for (let i = 0; i < interventionGroupsArray.length; i++) {
          const interventionGroups = interventionGroupsArray[i].interventionGroups;
          const unfinishedGroup = interventionGroupsArray[i].unfinishedGroupsIds;
          if(unfinishedGroup?.length) {
            for (let i = 0; i < unfinishedGroup.length; i++) {
              const unFin = unfinishedGroup[i];
              if(!intGrpIDsWithEditAccess.includes(unFin)) {
                intGrpIDsWithEditAccess.push(unFin);
              }
            }
          }
          for (let j = 0; j < interventionGroups.length; j++) {
            const interventionGroup = interventionGroups[j];

              if (interventionGroup.coach_assignment?.user.id == loggedInUserId) { 
                intGrpIDsWithEditAccess.push(interventionGroup.id);
              } 
              else if (interventionGroup.teacher_assignment?.user.id == loggedInUserId) { 
                intGrpIDsWithEditAccess.push(interventionGroup.id);
              } 
              else if (interventionGroup.additional_educator_plan != null) { 
                if(interventionGroup.additional_educator_plan.some((addEduPlan) =>(addEduPlan.user_id == loggedInUserId) && addEduPlan.can_edit)
                ) {
                  intGrpIDsWithEditAccess.push(interventionGroup.id);
                }
                if(interventionGroup.additional_educator_plan.some((addEduPlan) =>(addEduPlan.user_id == loggedInUserId) && !addEduPlan.can_edit)
                ) {
                  intGrpIDsWithReadAccess.push(interventionGroup.id);
                }
              }

          }
            
          }
        return {
          intGrpIDsWithEditAccess,
          intGrpIDsWithReadAccess
        }

  },[interventionGroupsArray]);

  let filteredReadonlyInterventionGroup = useMemo(() => {
    return [...interventionGroupsArray.map((interventionGroup) => {
      let readonlyGrp = [];
      for (let i = 0; i < interventionGroup.interventionGroups.length; i++) {
        const intGrp = interventionGroup.interventionGroups[i];
        if(intGrpAccess.intGrpIDsWithReadAccess.includes(intGrp.id)) {
          readonlyGrp.push(intGrp);
        }
      }
      let filteredGrp = {
        ...interventionGroup,
        interventionGroups: readonlyGrp
      }
      return filteredGrp;
    })]
  },[interventionGroupsArray, intGrpAccess])


    useEffect(() => {
      const { intGrpIDsWithEditAccess, intGrpIDsWithReadAccess } = intGrpAccess;
      if(intGrpIDsWithReadAccess.length || intGrpIDsWithEditAccess.length) {
        dispatch(interventionGroupAccess({
          intGrpIDsWithReadAccess,
          intGrpIDsWithEditAccess
        }))
      }
    }, [intGrpAccess]);

  return (
    <>
      <div id="groups-container">
        {!isParent && (
          <>
            <div className="mb-3">
              <ToolBar
                isYourAssignments={isYourAssignments}
                student={student}
                activeTab={activeTab}
              />
            </div>

            {isYourAssignments &&
              !showArchivedGroups &&
              !isLoading.getInterventionGroups && !isLoading.getNotFinishedGroups && <RecommendationsSection intGroupsMap = {intGroupsMap}/>}
          </>
        )}

        {isLoading.getInterventionGroups || isLoading.getNotFinishedGroups || openCaseLoadPage ? (
          <LoadingIndicator />
        ) : intGroupsMap.length ? (
          <div className={groupSort ? "sorted-groups-container" : undefined}>

            {!isStudentDetail && (isCoach || isTeacher) && interventionGroupsArray.filter((intGrpMap) => {
              if(intGrpAccess.intGrpIDsWithEditAccess.length) {
                let isGroupExist = false;
                if(intGrpMap.interventionGroups.length) {
                  isGroupExist = intGrpMap.interventionGroups.some((interventionGroup) => intGrpAccess.intGrpIDsWithEditAccess.includes(interventionGroup.id))
                } 
                if(intGrpMap.unfinishedGroupsIds?.length) {
                  isGroupExist =  intGrpMap.unfinishedGroupsIds!.some((unfinishedGroup) => intGrpAccess.intGrpIDsWithEditAccess.includes(unfinishedGroup)) 
                }
                return isGroupExist;
              }
            }).map((interventionGroupMap, index) => {
                return <InterventionsGroup
                  isReadonly={isParent}
                  interventionGroupMap={interventionGroupMap}
                  student={student}
                  key={`teacher_${index}`}
                  {...rest}
                  isLoadingPatchInterventionGroup={
                    isLoadingPatchInterventionGroup
                  }
                  teachers={teachers}
                  coaches={coaches}
                  isTeacher={isTeacher}
                  isCoach={isCoach}
                  isDistrictOwner={isDistrictOwner}
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                  //shareDataWith={false}
                  notShareDataWith={false}
                  isYourAssignments={isYourAssignments}
                  intGrpIDsWithEditAccess={intGrpAccess.intGrpIDsWithEditAccess}
                />
              })
            }

            {isStudentDetail && (isCoach || isTeacher) && shareDataWithIntGroupMap?.length ? 
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="" eventKey="0" onClick={() => setOpenShareData(!openShareData)}>
                      {openShareData ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} /> }
                      {isStudentDetail && isCoach && shareDataWithIntGroupMap?.length ? ` Teachers you share data with OR groups for whom you are a Coach.`  : ''}
                      {isStudentDetail && isTeacher && shareDataWithIntGroupMap?.length ? ` Coaches you share data with OR groups for whom you are an Interventionist.`  : ''}
                    </Accordion.Toggle>
                  </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <div className="ml-2">
                    {shareDataWithIntGroupMap?.filter(
                      (interventionGroupMap) => isStudentDetail ? interventionGroupMap.interventionGroups
                        .find((interventionGroup: any)=> interventionGroup.finished && interventionGroup.share_data_with) : interventionGroupMap
                      ).map((interventionGroupMap, index) => {
                        return  <InterventionsGroup
                            isReadonly={isParent}
                            interventionGroupMap={interventionGroupMap}
                            student={student}
                            key={`teacher_${index}`}
                            {...rest}
                            isLoadingPatchInterventionGroup={
                              isLoadingPatchInterventionGroup
                            }
                            teachers={teachers}
                            coaches={coaches}
                            isTeacher={isTeacher}
                            isCoach={isCoach}
                            isDistrictOwner={isDistrictOwner}
                            onDragStart={onDragStart}
                            onDragEnd={onDragEnd}
                            //shareDataWith={true}
                            notShareDataWith={false}
                            isYourAssignments={false}
                          />

                        
                      }
                        
                    )}
                  </div>

                </Accordion.Collapse>
              </Card>
              </Accordion>
            : ''
            }

            {isStudentDetail && (isCoach || isTeacher) && notShareDataWithIntGroupMap?.length ?  
                <Card>
                  <Card.Header>
                      {isStudentDetail && isCoach && notShareDataWithIntGroupMap?.length ? ` Teachers you do not share data with OR groups for whom you are not a Coach.` : ''}
                      {isStudentDetail && isTeacher && notShareDataWithIntGroupMap?.length ? ` Coaches you do not share data with OR groups for whom you are not an Interventionist.` : ''}
                  </Card.Header>
                  <Card.Body>
                    <div className="px-2">
                      {notShareDataWithIntGroupMap?.filter(
                        (interventionGroupMap) => isStudentDetail ? interventionGroupMap.interventionGroups
                          .find((interventionGroup: any)=> interventionGroup.finished) : interventionGroupMap
                        ).map((interventionGroupMap, index) => (
                          <InterventionsGroup
                            isReadonly={isParent}
                            interventionGroupMap={interventionGroupMap}
                            student={student}
                            key={`teacher_${index}`}
                            {...rest}
                            isLoadingPatchInterventionGroup={
                              isLoadingPatchInterventionGroup
                            }
                            teachers={teachers}
                            coaches={coaches}
                            isTeacher={isTeacher}
                            isCoach={isCoach}
                            isDistrictOwner={isDistrictOwner}
                            onDragStart={onDragStart}
                            onDragEnd={onDragEnd}
                            //shareDataWith={false}
                            notShareDataWith={isStudentDetail}
                            isYourAssignments={false}
                          />)
                      )}  
                    </div>          
                  </Card.Body>
                </Card>
            : ''
            }
            {!isStudentDetail  && (isCoach || isTeacher) && intGrpAccess.intGrpIDsWithReadAccess.length ? 
              <>
                <hr/>  
                <h3>Groups with Read-Only Access</h3> 
                {filteredReadonlyInterventionGroup.map((interventionGroupMap, index) => {
                  if(interventionGroupMap.interventionGroups.length) {
                    return <InterventionsGroup
                      isReadonly={isParent}
                      interventionGroupMap={interventionGroupMap}
                      student={student}
                      key={`teacher_${index}`}
                      {...rest}
                      isLoadingPatchInterventionGroup={
                        isLoadingPatchInterventionGroup
                      }
                      teachers={teachers}
                      coaches={coaches}
                      isTeacher={isTeacher}
                      isCoach={isCoach}
                      isDistrictOwner={isDistrictOwner}
                      onDragStart={onDragStart}
                      onDragEnd={onDragEnd}
                      //shareDataWith={false}
                      notShareDataWith={false}
                      isYourAssignments={isYourAssignments}
                      intGrpIDsWithReadAccess={intGrpAccess.intGrpIDsWithReadAccess}
                    />
                  } else {
                    return;
                  }

                  })
                } 
              </>
            : ''}

            {isStudentDetail && isDistrictOwner && interventionGroupsArray.map((interventionGroupMap, index) => {
                return <InterventionsGroup
                  isReadonly={isParent}
                  interventionGroupMap={interventionGroupMap}
                  student={student}
                  key={`teacher_${index}`}
                  {...rest}
                  isLoadingPatchInterventionGroup={
                    isLoadingPatchInterventionGroup
                  }
                  teachers={teachers}
                  coaches={coaches}
                  isTeacher={isTeacher}
                  isCoach={isCoach}
                  isDistrictOwner={isDistrictOwner}
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                  //shareDataWith={false}
                  notShareDataWith={false}
                  isYourAssignments={isYourAssignments}
                  intGrpIDsWithEditAccess={intGrpAccess.intGrpIDsWithEditAccess}
                />
              })
            }
            


            {!(isParent || isSupervisor || isTeacher) && isYourAssignments && (
              <>
                <InterventionFidelityTour />
                <FidelityChartTour />
              </>
            )}
          </div>
        ) : groupSearch.length ? (
          <Alert variant="warning">
            No matching interventions groups. Try to change the search options
          </Alert>
        ) : showArchivedGroups ? (
          <Alert variant="warning">
            You don&apos;t have archived groups{student && " with this student"}
            .
          </Alert>
        ) : (student || !interventionGroupsMap.length) && (
          <Alert variant="warning">
            Student doesn&apos;t have any groups yet.
          </Alert>
        )}
      </div>

      <SetTimeModal
        showModal={showTimeModal}
        time={dayTime}
        onHide={onHideSetTime}
        onSave={onSaveSetTime}
        isLoading={isLoadingPatchInterventionGroup}
      />
      <UnsaveConfirmModal
        showModal={showUnsaveModal}
        onHide={onHideUnsaveGroup}
        onConfirm={onConfirmUnsaveGroup}
        isLoading={isLoadingUnsaveInterventionGroup}
      />

      <InterventionStatsModal />

      <AddInterventionModal />
    </>
  );
};

export default InterventionGroupList;
