import { bindActionCreators, Dispatch } from "redux";
import { getCsvFile, getMultiCsvFile, hideUploadStudentDataModal, openUploadStudentDataModal } from "../../store/onboarding/actions";
import { connect } from "react-redux";
import DataFileReader from "../../components/common/file-reader/FileReader";

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      uploadCsvFile: getCsvFile,
      uploadMultipleCsvFile: getMultiCsvFile,
      
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(DataFileReader);
