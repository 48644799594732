import React, { FunctionComponent, useMemo } from "react";
import { HorizontalBar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  generateInterventionAbbrev,
  getTitleByMeasurement,
} from "../../../../../../utils/NamesUtils";

type OwnProps = {
  labels: string[];
  data: number[];
  isNumeric?: boolean;
};

type Props = OwnProps;

const VerticalBarChart: FunctionComponent<Props> = (props) => {
  const { data, labels, isNumeric } = props;

  const chartData = useMemo(() => {
    return {
      labels: labels,
      datasets: [
        {
          indexAxis: 'y',
          backgroundColor: "rgb(203,220,240)",
          borderColor: "rgb(77,145,226)",
          borderWidth: 1,
          hoverBackgroundColor: "rgb(203,220,240,0.4)",
          hoverBorderColor: "rgb(77,145,226)",
          data: data,
          datalabels: {
            color: "rgb(30,136,229)",
            formatter: (value: number) => {
              if (!value) {
                return "";
              }
              return isNumeric ? value : value + "%";
            },
            font: {
              weight: "bold",
            },
          },
        },
      ],
    };
  }, [data, labels, isNumeric]);

  const options = useMemo(
    () => ({
        
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: isNumeric
              ? {}
              : {
                  max: 100,
                  min: 0,
                  stepSize: 10,
                },
            display: true,
          },
        ],
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
            },
            ticks: {
                beginAtZero: true
            },
            display: true,
          },
        ],
      },
      plugins: {
        datalabels: {
          // anchor: (context: any) => {
          //   const value = context.dataset.data[context.dataIndex];
          //   return value < 10 ? "end" : "center";
          // },
          // align: (context: any) => {
          //   const value = context.dataset.data[context.dataIndex];
          //   return value < 10 ? "top" : "center";
          // },
          anchor: "center",
          align: "center",
          offset: 2,
          textAlign: "center",
          display: "auto",
          clamp: "auto",
        },
      },
    }),
    [isNumeric]
  );

  return (
    <HorizontalBar
      height={250}
      data={chartData}
      options={options}
      plugins={[ChartDataLabels]}
    />
  );
};

export default VerticalBarChart;
