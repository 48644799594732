import Table from "react-bootstrap/Table";
import { Card } from "react-bootstrap";
import DataFileReader from "../../../../../containers/onboarding/FileReader";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openTeacherInviteIndividuallyModal } from "../../../../../store/onboarding/actions";
import { useDispatch } from "react-redux";


type Props = {
  hasDistrictPermission?: boolean;
}
const UploadTeachersRosterManual = (props: Props) => {
  const { hasDistrictPermission } = props;

  const dispatch = useDispatch();

  const handleInviteIndividually = () => {
    dispatch(openTeacherInviteIndividuallyModal());
  };
  return (
    <>
    <div>
      <h4 className="text-center mb-2">
        The roster upload is currently unavailable. Please use the <strong>Invite Individually</strong> option
        (click button below) to invite <strong>multiple</strong> users at a time.
      </h4>
    </div>
      <div className="text-center">
        <label className="blueBtnMd" onClick={handleInviteIndividually}>
          Invite Individually
        </label>
      </div>
      {/* <div>
        <h4 className="text-center">
          To invite staff from a roster, it needs to be in a CSV format, and
          include a header row. It must include at least { hasDistrictPermission ? 'four': 'three'} columns; one for
          First Name, Last Name, { hasDistrictPermission ? 'Email, and Role': 'and Email'}.
        </h4>
        <br />
        <div>
          <h4>
            <strong>Example format</strong>
          </h4>
          <Table className="uploadExampleTable">
            <thead>
              <tr>
                <th>first_name</th>
                <th>last_name</th>
                <th>email</th>
                {hasDistrictPermission ? <th>role</th> : ''}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mary</td>
                <td>Smith</td>
                <td>mssmith@yahoo.com</td>
                {hasDistrictPermission ? <td>Coach</td> : ''}
              </tr>
            </tbody>
          </Table>
        </div>

        <Card>
          <Card.Header>
            <FontAwesomeIcon icon="info-circle" /> What is a CSV file?{" "}
          </Card.Header>
          <Card.Body>
            <Card.Text>
              <p className="smallerText">
                A CSV file is a structured data file that organizes information
                with rows, and columns separated by a comma. Opening a CSV file
                in Excel would display the data in a format like shown above.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>

      <div className="text-center">
        <label className="blueBtnXL">
          Upload roster csv file
          <DataFileReader />
        </label>
      </div> */}
    </>
  );
};

export default UploadTeachersRosterManual;
