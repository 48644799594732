import React, { Component } from "react";
import CardButton from "../../CardButton";
import { faKeyboard, faUpload } from "@fortawesome/free-solid-svg-icons";
import { bindActionCreators, Dispatch } from "redux";
import { openUploadStudentDataModal } from "../../../../../store/onboarding/actions";
import { connect } from "react-redux";

type DispatchProps = {
  openUploadStudentDataModal: () => any;
};

type Props = DispatchProps;

class EmptyDataContent extends Component<Props> {
  render() {
    return (
      <div className="interiorDataContainer" style={{ marginTop: "20px" }}>
        <h1 className="text-center font-weight-semibold">
          Upload your data to get started.
        </h1>
        <h3 className="text-center font-weight-semibold">
          We support DIBELS and many other reading assessments.
        </h3>
        <br />
        <h3 className="orangeText text-center font-weight-semibold">
          Student reading data can be uploaded or entered manually.
        </h3>
        <h3 className="orangeText text-center font-weight-bold">
          Where do you want to start?
        </h3>
        <div className="cardButtons">
          <CardButton
            faIcon={faUpload}
            title="Upload a File"
            text="Upload a CSV file with a header row that includes Student ID
                  and data for at least one assessment."
            onClick={this.props.openUploadStudentDataModal}
          />
          <CardButton
            faIcon={faKeyboard}
            title="Enter Manually"
            text="Enter student data for an assessment manually."
            onClick={() => console.log("Enter Manually")}
          />
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openUploadStudentDataModal: openUploadStudentDataModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(EmptyDataContent);
