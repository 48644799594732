import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useSelector } from "react-redux";
import Select from "react-select";
import {
  BasicUserInfo,
  DataPeriod,
  EvidencePeriodData,
  GradeLevels,
  RaceInfo,
  ReactSelectOption,
  School,
  StudentClass,
  TeacherClass,
  TeacherInfo,
  genderList,
} from "../../../../../store/onboarding/types";
import { ValueType } from "react-select/src/types";
import { ApplicationState } from "../../../../../store";
import {
  getInterventionGroups,
  getRecommendations,
} from "../../../../../store/onboarding/cases/actions";
import { Overlay, OverlayTrigger, Popover, Spinner, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSync } from "@fortawesome/free-solid-svg-icons";
import { Grade } from "../../../../../store/groups/types";
import {
  changeStudentsFilter,
  getClassFromStudentRowsTeachers,
  getSchools,
  getTeachersFromStudentRows,
  sendDataFilterMessage,
} from "../../../../../store/onboarding/actions";
import {
  changeMaxSizeTierThreeFilter,
  changeMaxSizeTierTwoFilter,
  changeRecommendClassFilter,
  changeGroupByClassFilter,
  changeGroupByGradeFilter,
  changeRecommendGradesFilter,
  changeRecommendSchoolsFilter,
  changeRecommendTeachersFilter,
  changeRaceFilter,
  changeRaceOtherFilter,
  changeGenderFilter,
  changeSpecialEducationFilter,
  changeSLDFilter,
  changeELLFilter,
  changeFreeOrReducedLunchFilter,
  changeShowDiagnosedStudentFilter,
} from "../../../../../store/dataFilter/actions";
import { IS_READY_COACH } from "../../../../../constants";
import { getFullName } from "../../../../../utils/NamesUtils";
import { GroupRecommendation } from "../../../../../store/onboarding/cases/types";
import { showConfirmDialog } from "../../../../../store/confirm-dialog/actions";
import StudentsFilter from "../../../onboarding/second-step/evidence-tab/student-data-table/filters/StudentsFilter";
import DemographicsFilter from "../../../onboarding/second-step/evidence-tab/student-data-table/filters/DemographicsFilter";
import { includes } from "lodash";

type PropsFromState = {
  schools: Array<School>;
  currentDataPeriod?: DataPeriod;
  evidencePeriodData?: EvidencePeriodData;
  teachersRoster: Array<TeacherInfo>;
  recommendGradesFilter: Grade[];
  recommendTeachersFilter: number[];
  recommendClassFilter: number[];
  recommendSchoolsFilter: number[];
  maxSizeTierTwo?: number;
  maxSizeTierThree?: number;
  groupByGrade?: boolean,
  groupByClass?: boolean,
  recommendedGroups: GroupRecommendation[],
  studentsFilter: string;
  specialEducationFilter: boolean | undefined;
  ellFilter: boolean | undefined;
  sldFilter: boolean | undefined;
  freeOrReducedLunchFilter: boolean | undefined;
  raceInfo: RaceInfo;
  isLoading: {
    getSchools: boolean;
    getRecommendations: boolean;
    getInterventionGroups: boolean;
    getEvidenceInDataPeriod: boolean;
    getRaceInfo: boolean;
  };
  errors: {
    getRaceInfo?: string;
  };
};

type DispatchProps = {
  getSchools: () => void;
  getRecommendations: () => void;
  getTeachersFromStudentRows: (gradesFilter: Grade[]) => any;
  getClassFromStudentRowsTeachers: (gradesFilter: Grade[], teacherFilter: any[]) => any;
  getInterventionGroups: () => any;
  changeMaxSizeTierTwoFilter: (value?: number) => any;
  changeMaxSizeTierThreeFilter: (value?: number) => any;
  changeGroupByGradeFilter: (value?: boolean) => any;
  changeGroupByClassFilter: (value?: boolean) => any;
  changeRecommendGradesFilter: (grade?: Grade[]) => any;
  changeRecommendTeachersFilter: (id?: number[]) => any;
  changeRecommendClassFilter: (id?: number[]) => any;
  changeRecommendSchoolsFilter: (id?: number[]) => any;
  sendDataFilterMessage: (messageParts: string[]) => any;
  showConfirmDialog: (confirmObj: any) => any;
  changeStudentsFilter: (studentFilter: string) => any;
  changeSpecialEducationFilter: (specialEducation:boolean | undefined) => any;
  changeELLFilter: (ELL:boolean | undefined) => any;
  changeSLDFilter: (SLD:boolean | undefined) => any;
  changeShowDiagnosedStudentFilter: (showDiagnosedStudentFilter: boolean) => any;
};

type Props = PropsFromState & DispatchProps;

const RecommendationSettings: React.FunctionComponent<Props> = (props) => {
  const [teachers, setTeachers] = useState<BasicUserInfo[]>([]);
  const [teacherClass, setTeacherClass] = useState<TeacherClass[]>([]);
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  //const [filterClosed, setFilterClosed] = useState<boolean>(false);
  const [filterDataExist, setFilterDataExist] = useState<boolean>(false);
  const [showFiltersOverlay, setShowFiltersOverlay] = useState<boolean>(false);

   const filtersBtnRef = useRef(null);

  const handleFiltersClick = () => {
    setShowFiltersOverlay((prevState) => !prevState);
  };

  const onHideFiltersOverlay = () => {
    setShowFiltersOverlay(false);
  };


  const isStudentDetail = useSelector<
  ApplicationState
  >((s) => s.cases.isStudentDetail);
  
  const numbers = useMemo(
    () =>
      Array(100)
        .fill(undefined)
        .map((n, i) => ({ value: i, label: i })),
    []
  );
  
  const tabName: string = useSelector(
    (s: ApplicationState) => s.cases.tabName
  );



  useEffect(() => {
    props.changeMaxSizeTierThreeFilter();
    props.changeMaxSizeTierTwoFilter();
    props.changeRecommendClassFilter();
    props.changeGroupByClassFilter();
    props.changeGroupByGradeFilter();
    props.changeRecommendGradesFilter();
    props.changeRecommendSchoolsFilter();
    props.changeRecommendTeachersFilter();
    props.changeStudentsFilter('');
    props.changeSpecialEducationFilter(undefined);
    props.changeSLDFilter(undefined);
    props.changeELLFilter(undefined);
    props.changeShowDiagnosedStudentFilter(false);
    setFilterApplied(false);
  },[])

  useEffect(() => {
    setTeachers(props.getTeachersFromStudentRows(props.recommendGradesFilter));
    setTeacherClass(props.getClassFromStudentRowsTeachers(props.recommendGradesFilter, props.recommendTeachersFilter));
    if(!props.recommendTeachersFilter.length) {
      props.changeRecommendClassFilter();
    }
    
  }, [props.recommendGradesFilter, props.recommendTeachersFilter, props.evidencePeriodData]);

  useEffect(() => {
    props.getSchools();
  }, []);

  useEffect(() => {
    setTeachers(props.getTeachersFromStudentRows(props.recommendGradesFilter));
  }, [props.evidencePeriodData, props.recommendGradesFilter]);

  const resetFilterAction = () => {
    props.changeMaxSizeTierThreeFilter();
    props.changeMaxSizeTierTwoFilter();
    props.changeRecommendClassFilter();
    props.changeGroupByClassFilter();
    props.changeGroupByGradeFilter();
    props.changeRecommendGradesFilter();
    props.changeRecommendSchoolsFilter();
    props.changeRecommendTeachersFilter();
    props.changeStudentsFilter('');
    props.changeSpecialEducationFilter(undefined);
    props.changeSLDFilter(undefined);
    props.changeELLFilter(undefined);
    props.getRecommendations();
    setFilterApplied(false);
  }

  const handleResetFilters = () => {
    props.showConfirmDialog({
      title: "Confirm",
      text:
        "Are you sure want to clear all filter(s)?",
      onConfirm: () => {
        resetFilterAction();
      },
      confirmButtonText: "Continue"
    })
}

  const handleMaxSizeTierTwo = (option: ValueType<any, false>) => {
    props.changeMaxSizeTierTwoFilter(option ? option.value : undefined);
  };

  const handleMaxSizeTierThree = (option: ValueType<any, false>) => {
    props.changeMaxSizeTierThreeFilter(option ? option.value : undefined);
  };

  const handleGradeLevelChange = (
    options: ValueType<ReactSelectOption<Grade>, true>
  ) => {
    if (options && (options as ReactSelectOption<Grade>[]).length > 0) {
      props.changeRecommendGradesFilter(
        (options as ReactSelectOption<Grade>[]).map((v) => v.value)
      );
    } else {
      props.changeRecommendGradesFilter();
    }
  };

  const handleTeacherChange = (options: ValueType<BasicUserInfo, true>) => {
    if (options && (options as BasicUserInfo[]).length > 0) {
      props.changeRecommendTeachersFilter(
        (options as BasicUserInfo[]).map((t) => t.id!)
      );
    } else {
      props.changeRecommendTeachersFilter();
    }
  };

  const handleClassChange = (options: ValueType<TeacherClass, true>) => {
    if (options && (options as TeacherClass[]).length > 0) {
      props.changeRecommendClassFilter(
        (options as TeacherClass[]).map((t) => t.id!)
      );
    } else {
      props.changeRecommendClassFilter();
    }
  };

  const handleSchoolsFilterChange = (options: any) => {
    if (options && (options as School[]).length > 0) {
      props.changeRecommendSchoolsFilter(
        (options as School[]).map((t) => t.id)
      );
    } else {
      props.changeRecommendSchoolsFilter();
    }
  };

  const onUpdateRecommendations = () => {
    props.getRecommendations();
    props.getInterventionGroups(); // JP: 6. This Refresh button should reload the groups API call for easier testing
  };

  const customStyles: any = useMemo(
    () => ({
      control: (base: any) => ({
        ...base,
        minHeight: 30,
      }),
      dropdownIndicator: (base: any) => ({
        ...base,
        padding: 4,
      }),
      clearIndicator: (base: any) => ({
        ...base,
        padding: 4,
      }),
      valueContainer: (base: any) => ({
        ...base,
        padding: "0px 6px",
      }),
      input: (base: any) => ({
        ...base,
        margin: 0,
        padding: 0,
      }),
    }),
    []
  );

  const {
    maxSizeTierTwo,
    maxSizeTierThree,
    recommendTeachersFilter,
    recommendClassFilter,
    recommendGradesFilter,
    recommendSchoolsFilter,
    schools,
    isLoading,
  } = props;

  const generalFilter = useMemo(() => {
    const messageParts = [];

    if (maxSizeTierTwo !== undefined) {
      messageParts.push(`Max group size for Tier 2 - ${maxSizeTierTwo}`);
    }

    if (maxSizeTierThree !== undefined) {
      messageParts.push(`Max group size for Tier 3 - ${maxSizeTierThree}`);
    }

    if (recommendGradesFilter.length) {
      const more  = recommendGradesFilter.length > 1 
      ? ' and '+ (recommendGradesFilter.length - 1)+ '+'
      : '';
      let gradeLevelFiltered = GradeLevels.filter((gl) => gl.value == recommendGradesFilter![0])
      messageParts.push(`Grade Level(s) - ${gradeLevelFiltered.length ? gradeLevelFiltered[0]?.label + more : ''}`)
    }

    if (recommendTeachersFilter.length) {
      const more  = recommendTeachersFilter.length > 1 
      ? ' and '+ (recommendTeachersFilter.length - 1)+ '+'
      : '';
      let filteredTeacher = recommendTeachersFilter
          .map((tid) => getFullName(teachers.find((t) => t.id === tid)))
      messageParts.push(
        `Educator(s) - ${filteredTeacher.length ? filteredTeacher[0] + more : ''}`
      );
    }

    if (recommendClassFilter.length) {
      const more  = recommendClassFilter.length > 1 
      ? ' and '+ (recommendClassFilter.length - 1)+ '+'
      : '';
      let classFilter = recommendClassFilter
          .map((tid) => teacherClass.find((t) => t.id === tid)).map((tc)=> tc != undefined ? tc!.name : '')
      messageParts.push(
        `Class(es) - ${classFilter.length ? classFilter[0] + more : ''}`
      );
    }

    if (recommendSchoolsFilter?.length) {
      let schoolNames = recommendSchoolsFilter
      .map((id) => props.schools.find((school) => school.id === id)?.name);
      const more  = recommendSchoolsFilter.length > 1 
      ? ' and '+ (recommendSchoolsFilter.length - 1)+ '+'
      : ''
      messageParts.push(`School(s) - ${schoolNames.length ? schoolNames[0] + more : ''}`)
    }


    if(messageParts.length) {
      setFilterDataExist(true);
      props.sendDataFilterMessage(messageParts);
    } else {
      setFilterDataExist(false);
      props.sendDataFilterMessage([]);
    }

    return messageParts;
  }, [
    filterApplied,
    props.recommendedGroups
  ]);

  const demoGraphicsFilter = useMemo(() => {
    const messageParts = [];
    if (props.studentsFilter?.length) {
      messageParts.push(`Student - ${props.studentsFilter}`)
    }
    if(props.specialEducationFilter != undefined) {
      messageParts.push(`Special Education - ${props.specialEducationFilter ? 'Y' : 'N' }`)
    }
    if(props.ellFilter != undefined) {
      messageParts.push(`ELL - ${props.ellFilter ? 'Y' : 'N' }`)
    }
    if(props.sldFilter != undefined) {
      messageParts.push(`SLD - ${props.sldFilter ? 'Y' : 'N' }`)
    }

    if(messageParts.length) {
      setFilterDataExist(true);
      props.sendDataFilterMessage(messageParts);
    } else {
      setFilterDataExist(false);
      props.sendDataFilterMessage([]);
    }

    return messageParts;
  }, [
    filterApplied,
    props.recommendedGroups
  ]);

  useEffect(() => { //fire on load
    if(!isStudentDetail) {
      props.getRecommendations();
    }
  }, []);

  // useEffect(() => console.log(filterClosed),[filterClosed])

  // useEffect(() => { //filter information changed
  //   if(
  //      maxSizeTierTwo == undefined
  //     && maxSizeTierThree== undefined
  //     && !recommendGradesFilter.length
  //     && !recommendTeachersFilter.length
  //     && !recommendClassFilter.length
  //     && !recommendSchoolsFilter.length
  //     && filterApplied
  //   ){
  //     setFilterApplied(false);
  //     if(!filterDataExist) {
  //      setFilterClosed(true);
  //     } else {
  //       setFilterClosed(false);
  //     }
  //   }
    
  // }, [
  //   maxSizeTierTwo,
  //   maxSizeTierThree,
  //   groupByGrade,
  //   groupByClass,
  //   recommendGradesFilter,
  //   recommendTeachersFilter,
  //   recommendClassFilter,
  //   recommendSchoolsFilter,
  //   filterApplied
  // ]);


  const applyFilter = () => {
    setFilterApplied(true);
    //setFilterClosed(true);
    props.getRecommendations();
    if(!filterDataExist) {
      setTimeout(() => {
        document.body.click();
      }, 100);
      props.sendDataFilterMessage([]);
    }
    return Promise.resolve(1);
  }


  if (IS_READY_COACH) {
    return (
      <>
      <Overlay
        placement="bottom"
        target={filtersBtnRef?.current as any}
        show={showFiltersOverlay}
        rootClose
        rootCloseEvent={"click"}
        onHide={onHideFiltersOverlay}
      >
          <Popover id="recommendations-setting-popover">
            <Popover.Content>
              <div className="row">
                <div className='col-6'>
                  <div className="recommendationSettingsFilter">
                    {tabName == 'groups/reading' ? 
                      <>
                        <div className="mb-2">
                          <h4 className="font-weight-bold m-0">
                            Max Group Size For Tier 2
                          </h4>
                          <Select
                            isClearable
                            className="flex-1"
                            value={numbers.find((num) => num.value === maxSizeTierTwo)}
                            options={numbers}
                            onChange={handleMaxSizeTierTwo}
                          />
                        </div>
                        <div className="mb-2">
                          <h4 className="font-weight-bold m-0">
                            Max Group Size For Tier 3
                          </h4>
                          <Select
                            isClearable
                            className="flex-1"
                            value={numbers.find((num) => num.value === maxSizeTierThree)}
                            options={numbers}
                            onChange={handleMaxSizeTierThree}
                          />
                        </div>
                      </>
                    : ""
                    }

                  </div>
                  <div className="mb-2">
                    <h4 className="font-weight-bold m-0">Grade Level(s)</h4>
                    <Select
                      isMulti
                      isClearable
                      className="flex-1"
                      value={GradeLevels.filter((gl) =>
                        recommendGradesFilter.includes(gl.value)
                      )}
                      options={GradeLevels}
                      onChange={handleGradeLevelChange}
                    />
                  </div>
                  <div className="mb-2">
                    <h4 className="font-weight-bold m-0">Educator(s)</h4>
                    <Select
                      isMulti
                      isClearable
                      isLoading={isLoading.getEvidenceInDataPeriod}
                      className="flex-1"
                      value={teachers.filter((x) =>
                        recommendTeachersFilter.includes(x.id!)
                      )}
                      options={teachers}
                      getOptionLabel={(teacher: BasicUserInfo) =>
                        `${teacher.first_name} ${teacher.last_name}`
                      }
                      getOptionValue={(teacher: BasicUserInfo) =>
                        teacher.id!.toString()
                      }
                      onChange={handleTeacherChange}
                    />
                  </div>
                  <div className="mb-2">
                  <h4 className="font-weight-bold m-0">Class(es)</h4>
                    {!teacherClass.length 
                    ?
                      <OverlayTrigger
                        trigger="hover"
                        placement="top"
                        overlay={teacherClass.length?<div></div>:<Popover id="" style={{ width: 300 }}>
                          <Popover.Content>
                            <div className="flex-1">
                              To filter by class, select an educator who has a class.
                            </div>
                          </Popover.Content>
                        </Popover>}
                        rootClose
                      >
                      <div>
                        <Select
                          isMulti
                          isClearable
                          isLoading={isLoading.getEvidenceInDataPeriod}
                          isDisabled={!teacherClass.length}
                          className="flex-1"
                          value={teacherClass.filter((x) =>
                            recommendClassFilter.includes(x.id!)
                          )}
                          options={teacherClass}
                          getOptionLabel={(classes: TeacherClass) => 
                            `${classes.name}`
                          }
                          getOptionValue={(classes: TeacherClass) =>
                            classes.id!.toString()
                          }
                          onChange={handleClassChange}
                        />
                      </div>
                      </OverlayTrigger>
                    :
                      <div>
                        <Select
                          isMulti
                          isClearable
                          isLoading={isLoading.getEvidenceInDataPeriod}
                          isDisabled={!teacherClass.length}
                          className="flex-1"
                          value={teacherClass.filter((x) =>
                            recommendClassFilter.includes(x.id!)
                          )}
                          options={teacherClass}
                          getOptionLabel={(classes: TeacherClass) => 
                            `${classes.name}`
                          }
                          getOptionValue={(classes: TeacherClass) =>
                            classes.id!.toString()
                          }
                          onChange={handleClassChange}
                        />
                      </div>
                    }
                  </div>
                  <div className="mb-2">
                    <h4 className="font-weight-bold m-0">School(s)</h4>
                    <Select
                      isMulti
                      isClearable
                      isLoading={isLoading.getSchools}
                      className="flex-1"
                      value={schools.filter((x) =>
                        recommendSchoolsFilter.includes(x.id)
                      )}
                      options={schools}
                      getOptionLabel={(school: School) => school.name}
                      getOptionValue={(school: School) => school.id.toString()}
                      onChange={handleSchoolsFilterChange}
                    />
                  </div>
                </div>
                <div className="col-6 mt-3 pt-1">
                  <div className="mb-2">
                    <StudentsFilter />
                  </div>
                  <DemographicsFilter />
                </div>
              </div>
              <div className="mt-1 text-center">
                <button className="blueBtnSm" onClick={() => applyFilter().then(() => document.body.click())}>Apply</button>
              </div>
            </Popover.Content>
          </Popover>
      </Overlay>
        <div className="recommendationsSettingRow my-3" ref={filtersBtnRef} >
          {/*<div className="d-flex">*/}
          {/*  <CoachDataPeriodSelector small />*/}
          {/*  <DataPeriodsPanel />*/}
          {/*</div>*/}

          {`Showing ${(generalFilter.length || demoGraphicsFilter.length) && filterApplied ? 'filtered recommendations' : 'default recommendations. Filter it now'} `}
          <span className="mx-1">
            {(generalFilter.length || demoGraphicsFilter.length) && filterApplied ?
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Popover id="showFilters" className="showFilterClass">
                      <Popover.Title>Applied Filter(s)</Popover.Title>
                      <Popover.Content>
                        <div className="d-flex">
                        {generalFilter.length ? 
                          <Table className="table-bordered table-sm">
                            <tbody>
                              {generalFilter.map((generalFilterData: any) => 
                                <tr>
                                  <td style={{background:"#00c6f7"}}>
                                    <h5 style={{color:"#00265b", fontWeight: 600}}>{generalFilterData}</h5>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        : ''}
                        {demoGraphicsFilter.length
                        ?
                          <Table className="table-bordered table-sm">
                            <tbody>
                              {demoGraphicsFilter.map((demoGraphicsFilterData: any) => 
                                <tr>
                                  <td style={{background:"#00c6f7"}}>
                                    <h5 style={{color:"#00265b", fontWeight: 600}}>{demoGraphicsFilterData}</h5>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        : ""}
                        </div>
                      </Popover.Content>
                    </Popover>
                }
              >
                <span className="marigoldBtnSmFilter ml-1" onClick={handleFiltersClick}><FontAwesomeIcon icon={faFilter}/></span>
              </OverlayTrigger>
            :
              <span onClick={handleFiltersClick}><FontAwesomeIcon icon={faFilter}/></span>
            }
            {(generalFilter.length || demoGraphicsFilter.length) && filterApplied ? 
              <span className="redBtnSmFilter ml-1" title="Clear Filter(s)" onClick={handleResetFilters}>
                <FontAwesomeIcon 
                  icon={faSync}  
                  color="red"
                  size="1x"
                  
                  className="pointer"
                />
              </span>
            : ''}
          </span>
          {/*<span*/}
          {/*  // className="recommendIconButton"*/}
          {/*  onClick={e => {*/}
          {/*    e.stopPropagation();*/}
          {/*    onUpdateRecommendations();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {props.isLoading.getRecommendations ? (*/}
          {/*    <Spinner animation="border" size="sm" />*/}
          {/*  ) : (*/}
          {/*    <FontAwesomeIcon icon={faSync} style={{ cursor: "pointer" }} />*/}
          {/*  )}*/}
          {/*</span>*/}
        </div>
      </>

    );
  }

  return (
    <div className="recommendationSettingsContainer">
      <div className="recommendationSettings">
        <div className="recommendationSettingsFilter mr-2">
          <span className="font-weight-bold">MAX GROUP SIZE FOR TIER 2</span>
          <Select
            isClearable
            styles={customStyles}
            className="dataFiltersSelect"
            value={numbers.find((num) => num.value === maxSizeTierTwo)}
            options={numbers}
            onChange={handleMaxSizeTierTwo}
          />
        </div>
        <div className="recommendationSettingsFilter mr-2">
          <span className="font-weight-bold">MAX GROUP SIZE FOR TIER 3</span>
          <Select
            isClearable
            styles={customStyles}
            className="dataFiltersSelect"
            value={numbers.find((num) => num.value === maxSizeTierThree)}
            options={numbers}
            onChange={handleMaxSizeTierThree}
          />
        </div>
        <div className="recommendationSettingsFilter mr-2">
          <span className="font-weight-bold">GRADE LEVEL(S)</span>
          <Select
            isMulti
            isClearable
            styles={customStyles}
            className="dataFiltersSelect"
            value={GradeLevels.filter((gl) =>
              recommendGradesFilter.includes(gl.value)
            )}
            options={GradeLevels}
            onChange={handleGradeLevelChange}
          />
        </div>
        <div className="recommendationSettingsFilter mr-2">
          <span className="font-weight-bold">TEACHER(S)</span>
          <Select
            isMulti
            isClearable
            styles={customStyles}
            isLoading={isLoading.getEvidenceInDataPeriod}
            className="dataFiltersSelect"
            value={teachers.filter((x) =>
              recommendTeachersFilter.includes(x.id!)
            )}
            options={teachers}
            getOptionLabel={(teacher: BasicUserInfo) =>
              `${teacher.first_name} ${teacher.last_name}`
            }
            getOptionValue={(teacher: BasicUserInfo) => teacher.id!.toString()}
            onChange={handleTeacherChange}
          />
        </div>
        <div>
          <span className="font-weight-bold">CLASS(ES)</span>
          <Select
            isMulti
            isClearable
            isDisabled={!teacherClass.length}
            styles={customStyles}
            isLoading={isLoading.getEvidenceInDataPeriod}
            className="dataFiltersSelect"
            value={teacherClass.filter((x) =>
              recommendClassFilter.includes(x.id!)
            )}
            options={teacherClass}
            getOptionLabel={(classes: TeacherClass) => 
              `${classes.name}`
            }
            getOptionValue={(classes: TeacherClass) =>
              classes.id!.toString()
            }
            onChange={handleClassChange}
          />
        </div>
        {IS_READY_COACH && (
          <div className="recommendationSettingsFilter mr-2">
            <span className="font-weight-bold">SCHOOL(S)</span>
            <Select
              isMulti
              isClearable
              isLoading={isLoading.getSchools}
              styles={customStyles}
              className="dataFiltersSelect"
              value={schools.filter((x) =>
                recommendSchoolsFilter.includes(x.id)
              )}
              options={schools}
              getOptionLabel={(school: School) => school.name}
              getOptionValue={(school: School) => school.id.toString()}
              onChange={handleSchoolsFilterChange}
            />
          </div>
        )}
      </div>
      <div className="recommendIconButton" onClick={onUpdateRecommendations}>
        {props.isLoading.getRecommendations ? (
          <Spinner animation="border" />
        ) : (
          <FontAwesomeIcon icon={faSync} style={{ cursor: "pointer" }} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  onboarding,
  dataFilter,
  cases,
}: ApplicationState): PropsFromState => {
  return {
    schools: onboarding.schools,
    currentDataPeriod: onboarding.currentDataPeriod,
    evidencePeriodData: onboarding.evidencePeriodData,
    teachersRoster: onboarding.teachersRoster,
    recommendGradesFilter: dataFilter.recommendGradesFilter,
    recommendTeachersFilter: dataFilter.recommendTeachersFilter,
    recommendClassFilter: dataFilter.recommendClassFilter,
    recommendSchoolsFilter: dataFilter.recommendSchoolsFilter,
    maxSizeTierTwo: dataFilter.maxSizeTierTwo,
    maxSizeTierThree: dataFilter.maxSizeTierThree,
    groupByClass: dataFilter.groupByClass,
    groupByGrade: dataFilter.groupByGrade,
    recommendedGroups: cases.recommendedGroups,
    studentsFilter: onboarding.studentsFilter,
    specialEducationFilter: dataFilter.specialEducationFilter,
    ellFilter: dataFilter.ellFilter,
    sldFilter: dataFilter.sldFilter,
    freeOrReducedLunchFilter: dataFilter.freeOrReducedLunchFilter,
    raceInfo: onboarding.raceInfo,
    isLoading: {
      getSchools: onboarding.isLoading.getSchoolsList,
      getRecommendations: cases.isLoading.getRecommendations,
      getInterventionGroups: cases.isLoading.getInterventionGroups,
      getEvidenceInDataPeriod: onboarding.isLoading.getEvidenceInDatePeriod,
      getRaceInfo: onboarding.isLoading.getRaceInfo
    },
    errors: {
      getRaceInfo: onboarding.errors.getRaceInfo
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getSchools: getSchools,
      getRecommendations: getRecommendations,
      getTeachersFromStudentRows: getTeachersFromStudentRows,
      getClassFromStudentRowsTeachers: getClassFromStudentRowsTeachers,
      getInterventionGroups: getInterventionGroups,
      changeMaxSizeTierTwoFilter: changeMaxSizeTierTwoFilter,
      changeMaxSizeTierThreeFilter: changeMaxSizeTierThreeFilter,
      changeGroupByGradeFilter: changeGroupByGradeFilter,
      changeGroupByClassFilter: changeGroupByClassFilter,
      changeRecommendGradesFilter: changeRecommendGradesFilter,
      changeRecommendTeachersFilter: changeRecommendTeachersFilter,
      changeRecommendClassFilter: changeRecommendClassFilter,
      changeRecommendSchoolsFilter: changeRecommendSchoolsFilter,
      sendDataFilterMessage: sendDataFilterMessage,
      showConfirmDialog: showConfirmDialog,
      changeStudentsFilter: changeStudentsFilter,
      changeRaceFilter: changeRaceFilter,
      changeRaceOtherFilter: changeRaceOtherFilter,
      changeGenderFilter: changeGenderFilter,
      changeSpecialEducationFilter: changeSpecialEducationFilter,
      changeSLDFilter: changeSLDFilter,
      changeELLFilter: changeELLFilter,
      changeFreeOrReducedLunchFilter: changeFreeOrReducedLunchFilter,
      changeShowDiagnosedStudentFilter: changeShowDiagnosedStudentFilter
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendationSettings);
