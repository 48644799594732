import React, { FunctionComponent, useEffect, useState } from "react";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { SupportMeeting } from "../../../../../../store/onboarding/meeting-module/types";
import { Col, Form, ListGroup, Row, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  createSupportMeeting,
  hideSupportImplFeedbackModal,
  updateSupportMeeting,
} from "../../../../../../store/onboarding/meeting-module/actions";
import { connect } from "react-redux";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";
import { formatDate } from "../../../../../utils/DateTimeUtils";

type StateProps = {
  isLoading: {
    getSupportMeetings: boolean;
    createSupportMeeting: boolean;
    updateSupportMeeting: boolean;
  };
  interventionGroup?: InterventionGroup;
  selectedSupportMeeting?: SupportMeeting;
  supportMeetings: Array<SupportMeeting>;
};

type DispatchProps = {
  hideSupportImplFeedbackModal: () => any;
  createSupportMeeting: (supportMeeting: Partial<SupportMeeting>) => any;
  updateSupportMeeting: (supportMeeting: Partial<SupportMeeting>) => any;
};

type OwnProps = {
  onReadyToMeetMow: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const PlanningModalBody: FunctionComponent<Props> = ({
  interventionGroup,
  isLoading: {
    getSupportMeetings: loadingGetSupportMeetings,
    createSupportMeeting: loadingCreateSupportMeeting,
    updateSupportMeeting: loadingUpdateSupportMeeting,
  },
  supportMeetings,
  selectedSupportMeeting,

  onReadyToMeetMow,
  hideSupportImplFeedbackModal,
  createSupportMeeting,
  updateSupportMeeting,
}) => {
  const [strengths, setStrengths] = useState("");
  const [barriers, setBarriers] = useState("");
  const [improvements, setImprovements] = useState("");
  const [additionalModeling, setAdditionalModeling] = useState("");
  const [additionalPractice, setAdditionalPractice] = useState("");

  const [readyToMeetNow, setReadyToMeetNow] = useState(false);

  const [isFirstRender, setFirstRender] = useState(true);
  const [editing, setEditing] = useState(false);
  const [selectedPrevMeeting, selectPrevMeeting] = useState<
    SupportMeeting | undefined
  >(undefined);

  useEffect(() => {
    if (!isFirstRender) {
      if (readyToMeetNow) {
        onReadyToMeetMow();
      } else {
        hideSupportImplFeedbackModal();
      }
      return;
    }
    setFirstRender(false);
  }, [selectedSupportMeeting]);
  useEffect(() => {
    if (!isFirstRender && !loadingUpdateSupportMeeting && selectedPrevMeeting) {
      selectPrevMeeting(
        supportMeetings.find((meeting) => meeting.id === selectedPrevMeeting.id)
      );
      setEditing(false);
    }
  }, [loadingUpdateSupportMeeting]);

  const handleEditBtnClick = (meeting?: SupportMeeting) => (
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    selectPrevMeeting(meeting);
    setEditing(!!meeting);
    if (meeting) {
      setStrengths(meeting.strengths);
      setBarriers(meeting.barriers);
      setImprovements(meeting.improvements);
      setAdditionalModeling(meeting.additional_modeling);
      setAdditionalPractice(meeting.additional_practice);
    } else {
      setStrengths("");
      setBarriers("");
      setImprovements("");
      setAdditionalModeling("");
      setAdditionalPractice("");
    }
  };

  const saveBtnDisabled =
    !strengths ||
    !improvements ||
    !barriers ||
    !additionalModeling ||
    !additionalPractice;

  const onSupportMeetingSave = (readyToMeet: boolean) => () => {
    setReadyToMeetNow(readyToMeet);
    const supportMeeting: Partial<SupportMeeting> = {
      meeting_type: 0,
      strengths: strengths,
      improvements: improvements,
      barriers: barriers,
      additional_modeling: additionalModeling,
      additional_practice: additionalPractice,
      intervention_group: interventionGroup!.id,
    };
    createSupportMeeting(supportMeeting);
  };

  const onSupportMeetingUpdate = () => {
    if (selectedPrevMeeting) {
      const supportMeeting: Partial<SupportMeeting> = {
        ...selectedPrevMeeting,
        planning_meeting: selectedPrevMeeting.planning_meeting
          ? (selectedPrevMeeting.planning_meeting as SupportMeeting).id
          : selectedPrevMeeting.planning_meeting,
        strengths: strengths,
        improvements: improvements,
        barriers: barriers,
        additional_modeling: additionalModeling,
        additional_practice: additionalPractice,
      };
      updateSupportMeeting(supportMeeting);
    }
  };

  return (
    <>
      <Row>
        <Col sm={5} className="d-flex align-items-center">
          <div style={{ flexGrow: 1 }}>
            {loadingGetSupportMeetings ? (
              <LoadingIndicator />
            ) : (
              <>
                <h4 className="mb-2">Previous Meetings</h4>
                <div className="notesListContainer">
                  <ListGroup>
                    <ListGroup.Item
                      action
                      active={!selectedPrevMeeting}
                      onClick={handleEditBtnClick()}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h4>Today</h4>
                          <h3 className="font-weight-bold">
                            Plan for a Meeting
                          </h3>
                        </div>
                        <span>
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </span>
                      </div>
                    </ListGroup.Item>
                    {supportMeetings.map((meeting) => (
                      <ListGroup.Item
                        key={meeting.id}
                        action
                        active={
                          selectedPrevMeeting &&
                          selectedPrevMeeting.id === meeting.id
                        }
                        onClick={() => {
                          selectPrevMeeting(meeting);
                          setEditing(false);
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h4>
                              {formatDate(meeting.created, "YYYY-MM-DD hh:mm")}
                            </h4>
                            <h3 className="font-weight-bold">
                              Plan for a Meeting
                            </h3>
                          </div>
                          <span onClick={handleEditBtnClick(meeting)}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </span>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </>
            )}
          </div>
        </Col>

        <Col>
          <h3 className="font-weight-bold mb-2">
            Planning Notes with{" "}
            {getFullName(interventionGroup!.teacher_assignment!.user)}
          </h3>
          <Form>
            <Form.Group>
              <Form.Label>Strength</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.strengths}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={strengths}
                  onChange={(e) =>
                    setStrengths((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Potential Barriers</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.barriers}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={barriers}
                  onChange={(e) =>
                    setBarriers((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Implementation Improvement</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.improvements}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={improvements}
                  onChange={(e) =>
                    setImprovements((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Modeling Needed</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.additional_modeling}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={additionalModeling}
                  onChange={(e) =>
                    setAdditionalModeling((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Practice Needed</Form.Label>
              {selectedPrevMeeting && !editing ? (
                <h4>{selectedPrevMeeting.additional_practice}</h4>
              ) : (
                <Form.Control
                  as={"textarea"}
                  required
                  value={additionalPractice}
                  onChange={(e) =>
                    setAdditionalPractice((e.target as HTMLInputElement).value)
                  }
                  type="text"
                  placeholder="Type here..."
                />
              )}
            </Form.Group>
          </Form>
          <p>
            <FontAwesomeIcon icon={faInfoCircle} /> Only you and your educators
            will see these notes.
          </p>
        </Col>
      </Row>

      <hr />
      <div className="btnActions mt-3">
        <button className="whiteBtnSm" onClick={hideSupportImplFeedbackModal}>
          Cancel
        </button>
        {!selectedPrevMeeting ? (
          <div>
            <button
              className="blueBtnSm mr-3"
              onClick={onSupportMeetingSave(false)}
              disabled={saveBtnDisabled}
            >
              Save{" "}
              {!readyToMeetNow && loadingCreateSupportMeeting && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
            <button
              className="blueBtnSm"
              onClick={onSupportMeetingSave(true)}
              disabled={saveBtnDisabled}
            >
              Meet with {interventionGroup!.teacher_assignment!.user.first_name}{" "}
              {readyToMeetNow && loadingCreateSupportMeeting && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          </div>
        ) : (
          editing && (
            <button
              className="blueBtnSm"
              onClick={onSupportMeetingUpdate}
              disabled={saveBtnDisabled}
            >
              Update{" "}
              {loadingUpdateSupportMeeting && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          )
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  cases,
  meetingModuleReducer,
}: ApplicationState): StateProps => {
  return {
    interventionGroup: cases.selectedInterventionGroup,
    isLoading: {
      getSupportMeetings: meetingModuleReducer.isLoading.getSupportMeetings,
      createSupportMeeting: meetingModuleReducer.isLoading.createSupportMeeting,
      updateSupportMeeting: meetingModuleReducer.isLoading.updateSupportMeeting,
    },
    selectedSupportMeeting: meetingModuleReducer.selectedMeetingOld as SupportMeeting,
    supportMeetings: meetingModuleReducer.supportMeetings,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hideSupportImplFeedbackModal: hideSupportImplFeedbackModal,
      createSupportMeeting: createSupportMeeting,
      updateSupportMeeting: updateSupportMeeting,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanningModalBody);
