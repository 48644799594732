import React, { FunctionComponent, useMemo } from "react";
import {
  GoalTypes,
  InterventionGroup,
  StudentGoal,
  StudentGoalOptions,
} from "../../../../../store/onboarding/cases/types";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useDispatch } from "react-redux";
import {
  changeSetGoalsModal,
  openSetGoalChooseConcernModal,
} from "../../../../../store/onboarding/cases/actions";
import StudentGoalRecord from "./StudentGoalRecord";
import { showConfirmDialog } from "../../../../../store/confirm-dialog/actions";

type OwnProps = {
  group: InterventionGroup;
};

type DispatchProps = {
  changeSetGoalsModal: (
    show: boolean,
    interventionGroup?: InterventionGroup,
    studentGoal?: StudentGoal,
    isCreate?: boolean
  ) => any;
  openSetGoalChooseConcernModal: (
    interventionGroup?: InterventionGroup,
    studentGoal?: StudentGoal,
    studentGoalOptions?: StudentGoalOptions,
    setMissedGoal?: boolean,
  ) => any;
};

type Props = OwnProps & DispatchProps;

const GroupCardGoalsSection: FunctionComponent<Props> = ({
  group,
  changeSetGoalsModal,
  openSetGoalChooseConcernModal,
}) => {
  const dispatch = useDispatch();
  const handleSetGoal = (setMissedGoal?: boolean) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          openSetGoalChooseConcernModal(group, undefined, undefined, setMissedGoal)
        },
        centered: true,
        title: "Confirmation",
        text: (
          <>
            The first goal set for a group is the primary goal. 
            Progress towards the primary goal is used to determine a student’s response to intervention, 
            with automated alerts providing guidance for decision making. 
          </>
        ),
        confirmButtonText: "Confirm",
        timerElipseLeft: true,
        countDownTimer: 3,
      })
    );
  }

  const handleEditGoal = (studentGoal?: StudentGoal) => {
    //console.log('group', group, studentGoal)
    //if (group.goal_type === GoalTypes.ACADEMIC) {
      openSetGoalChooseConcernModal(group, studentGoal);
    // } else {
    //   changeSetGoalsModal(true, group, studentGoal);
    // }
  };

  const notAllStudentsHaveGoals = useMemo(
    () =>
      group.students.some(
        (st) => !group.student_goals.some((sg) => sg.student.id === st.id)
      ),
    [group]
  );

  const everyoneHasSameGoals = useMemo(() => {
    return (
      group.student_goals.length > 1 &&
      group.student_goals.filter((sg) => !sg.archived).every(
        (sg, index, arr) =>
          sg.target_date === arr[0].target_date &&
          sg.target_value === arr[0].target_value &&
          sg.measurement.id === arr[0].measurement.id
      )
    );
  }, [group.student_goals, group.students]);

  return (
    <div>
      <h5 className="font-weight-bold">GOALS</h5>
      {group.student_goals?.length ? (
        <div>
          {everyoneHasSameGoals ? (
            <StudentGoalRecord group={group} onGoalEdit={handleEditGoal} />
          ) : (
            group.students.map((student, index) => {
              let studentGoal: StudentGoal | undefined;
                if (group.student_goals) {
                  studentGoal = group.student_goals
                    .sort((a,b) => new Date(a.updated).getTime() - new Date(b.updated).getTime())
                    .find((x) => (x.student.id === student.id) && !x.archived);
                }
                if(studentGoal) {
                  return (<StudentGoalRecord
                    key={studentGoal?.id}
                    goal={studentGoal}
                    group={group}
                    onGoalEdit={handleEditGoal}
                  />)
                }
            })
          )}
          {notAllStudentsHaveGoals && (
            <div className="m-2 d-flex flex-column align-items-center">
              <h4 className="font-weight-bold">Not all students have goals</h4>
              <button className="blueBtnSm text-nowrap"  onClick={() => handleSetGoal(true)}>
                Set missed goals
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center my-1">
          <i className="small">This group doesn&apos;t have any goals yet</i>
          <button
            className="blueBtnMd text-nowrap mt-2"
             onClick={() => handleSetGoal(false)}
          >
            Set goals
          </button>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeSetGoalsModal: changeSetGoalsModal,
      openSetGoalChooseConcernModal: openSetGoalChooseConcernModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(GroupCardGoalsSection);
