import React, { Component } from "react";
import Papa from "papaparse";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import { ApplicationState } from "../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { hideUploadStudentDataModal, openUploadStudentDataModal } from "../../../store/onboarding/actions";
import { Spinner } from "react-bootstrap";
import { resolve } from "dns";
import _ from "lodash";

type DispatchProps = {
  onHide: () => void;
  openUploadStudentDataModal: () => void;
};

type OwnProps = {
  uploadCsvFile: (
    fileName: string,
    columnsNames: Array<string>,
    rawData: Array<any>
  ) => any;
  uploadMultipleCsvFile?: (uploadCSVArray:any) => any
};

type Props = OwnProps & DispatchProps;

type State = {
  isLinkIt: boolean;
  isDoubleHeaderLinkIt: boolean;
  fileName?: string;
  headers?: string[];
  originalData?: string;
  originalMultipleCSVData?: any;
  multiCSVheaders?: any;
  uploadCSVArray: any;
  workSheetLength: number;
  isAllProcessDone: boolean;
  showLoader: boolean;
};

const CSV_FILE_TYPES = [
  "text/comma-separated-values",
  "text/csv",
  "application/csv",
  "application/vnd.ms-excel",
  "application/vnd.msexcel",
  "csv",
];

const EXCEL_FILE_TYPES = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "xlsx",
  "application/wps-office.xlsx"
];

class DataFileReader extends Component<Props, State> {
  state: State = { isLinkIt: false, isDoubleHeaderLinkIt: false, uploadCSVArray: {}, originalMultipleCSVData: {}, multiCSVheaders:{}, workSheetLength:0, showLoader: false, isAllProcessDone: false };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null) {
      const file: File = event.target.files[0];

      const fileType = file
        ? file.type
          ? file.type
          : file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2)
        : "";

      this.handleFile(file, fileType);
    }
  };

  handleCSVData = async (csvData: string, fileType: string) => {
    //RC-927 New format accommodate for linkit file
      let csvDataArray = csvData.split('\n');
      let csvRowStrArray = [];
      let isStringFound: boolean = false;
      let studentIDColumn: any[] = [
        'ID',
        'Student ID',
        'Student ID#',
        'student_id',
        'StudentId',
        'Student_ID',
        'first_name',
        'last_name',
        'First',
        'Last'
      ];
      let isIsteepFileDetected = false;
      for (let i = 0; i < csvDataArray.length; i++) {
        let csvRowStr = csvDataArray[i];
        if(csvRowStr.includes('"ID"')) {
          let csvRow = csvRowStr.split(',')
          let title = csvRow.find((row) => row.match('DIBELS'));
          csvRow = [...csvRow.map((rowData:any) => {
            if((rowData == "") && (title != undefined)) {
              rowData = title;
            }
            return rowData;
          })]
          csvRowStr = csvRow.join(',');
        }
        if(csvRowStr.toLowerCase().includes('Test Date'.toLowerCase())) {
          isIsteepFileDetected = true;
        }
        //Skip Blank rows 
        if(!studentIDColumn.some((sid) => csvRowStr.includes(sid)) && !isStringFound) {
          continue;
        }
        if((csvDataArray[0] !== '') 
          && !csvDataArray[0].includes('StudentSummary')
          && !csvDataArray[0].includes('District_Name') //RC1537 NJTSS cohart file error
        ) { // RC-1258 mClass issue
          const doubleHeaderColumnHave = ["lnf", "psf", "nwf","orf", "wrc", "cls", "wrf", "composite", "maze"];
          if(csvDataArray[i+1] 
              && (!isIsteepFileDetected)
              && doubleHeaderColumnHave
                  .some((double) => csvDataArray[i+1].toLowerCase().includes(double))
              && !this.state.isDoubleHeaderLinkIt) { //linkit double header merged
              
                let firstHeader = 
                  csvDataArray[i]
                  .split(',')
                  .filter((data) => data)  //Remove all blank comma
                  .map((str) => {
                    const fourDigitRegex = /\b\d{4}\b/g;
                    const twoDigitRegex = /\b\d{2}\b/g;
                    const oneDigitRegex = /\b\d{1}\b/g;
                    if(fourDigitRegex.test(str) == true || twoDigitRegex.test(str) == true || oneDigitRegex.test(str) == true){
                      return ""
                    } else {
                      return str;
                    }
                  })
                  .filter((data) => data)  //Remove all blank comma
                  .join(',');
                csvDataArray[i+1] = csvDataArray[i+1].split(',').filter((data) => data).join(',');
                csvRowStr = firstHeader.concat(',').concat(csvDataArray[i+1]);
                csvDataArray.splice(i+1, 1)
                this.setState({isDoubleHeaderLinkIt: true});
          }
        }
        isStringFound = true;
        // let csvRowSubString = csvRowStr.substring(
        //   csvRowStr.indexOf(","), 
        //   csvRowStr.lastIndexOf(",") + 1
        // );
        let csvRowSubStringArray =  csvRowStr.split(',');
        let isArrayBlank = false;
        if(csvRowSubStringArray.every((data) => data.trim() == '')) {
          isArrayBlank = true;
        }
        if(!isArrayBlank) {
          csvRowStrArray.push(csvRowStr)
        }
        // End Skip blank rows
      }
      
      csvData = csvRowStrArray.join('\n')

    await this.setState({ originalData: csvData });

    Papa.parse(csvData, {
      complete: this.updateData,
      header: true,
      dynamicTyping: true,
      trimHeaders: true,
      skipEmptyLines: true,
      transform(value: string, field: string | number): any {
        return value.trim();
      },
      transformHeader(header: string): any {
        return header.trim();
      },
    });
  };

  handleMultipleCSVData =  (csvData: string, fileType: string, workSheetName?:string, sheetIndex?:number) => {
    
      let csvDataArray = csvData.split('\n');
      let csvDataObj: any = {};
      // set year and grade
      let yearGradeArray = csvDataArray?.slice(0, 1)[0].split(',');
      csvDataObj[workSheetName!] = {
        year :yearGradeArray[2],
        grade: yearGradeArray[3]
      };
    
      let csvRowStrArray = [];
      let isStringFound: boolean = false;
      let headerColumns: any[] = [
        'Student ID#'
      ];
      for (let i = 0; i < csvDataArray.length; i++) {
        let csvRowStr = csvDataArray[i];
        //Skip Blank rows 
        if(!headerColumns.some((sid) => csvRowStr.includes(sid)) && !isStringFound) {
          continue;
        }
        isStringFound = true;

        let csvRowSubStringArray =  csvRowStr.split(',');
        let isArrayBlank = false;
        if(csvRowSubStringArray.every((data) => data.trim() == '')) {
          isArrayBlank = true;
        }
        if(!isArrayBlank) {
          csvRowStrArray.push(csvRowStr)
        }
        // End Skip blank rows
      }
      
      csvData = csvRowStrArray.join('\n');
      this.setState((prevState: State) => {
        // Create a new object by spreading the existing data and adding new data
        const updatedData = {
          ...prevState.originalMultipleCSVData,
          ...csvDataObj
        };
    
        // Return the updated state
        return {
          originalMultipleCSVData: updatedData
        };
      },
    () =>
      Papa.parse(csvData, {
        complete: (results, file) => {
          this.updateMultipleData(results, file,  workSheetName, sheetIndex)
        } ,
        header: true,
        dynamicTyping: false,
        trimHeaders: true,
        skipEmptyLines: "greedy",
        transform(value: string, field: string | number): any {
          if(field != '') {
            return value.trim();
          }
        },
        transformHeader(header: string): any {
          return header.trim();
        },
      }));
  };

  getDataRange = (data: any) => {
    const dataWithValues = _.pickBy(data, (value, key) => !!value.v)
    const cellNamesWithValues = _.keys(dataWithValues)
    const cellsWithValues = cellNamesWithValues.map(cell => XLSX.utils.decode_cell(cell))
    const maxRow = _.max(cellsWithValues.map(cell => cell.r))
    const maxColumn = _.max(cellsWithValues.map(cell => cell.c))
    const lastCellName = XLSX.utils.encode_cell({ c: maxColumn!, r: maxRow! })
    return `A1:${lastCellName}`
  }

  handleFile =  (file: File, fileType: string) => {
    this.setState({ fileName: file.name });
    const reader = new FileReader();
    const readAsArrayBuffer = EXCEL_FILE_TYPES.includes(fileType);
    reader.onload = (e) => {
      if (reader.result) {
        if (CSV_FILE_TYPES.includes(fileType)) {
          this.handleCSVData(reader.result as string, fileType);
        } else if (EXCEL_FILE_TYPES.includes(fileType)) {
          const workbook = XLSX.read(reader.result, { type: "array" });
          if(workbook.SheetNames.length > 1) {
            let totalDataEntrySheets = 0  ;
            for (let i = 0; i < workbook.SheetNames.length; i++) {
              const sheetName = workbook.SheetNames[i];
              if(sheetName.includes('Data Entry')){
                totalDataEntrySheets++;
              }
            }
            this.setState({ workSheetLength: totalDataEntrySheets});
            for (let i = 0; i < workbook.SheetNames.length; i++) {
              const sheetName = workbook.SheetNames[i];
              if(sheetName.includes('Data Entry')) {
                const sheet = workbook.Sheets[sheetName];
                sheet['!ref'] = this.getDataRange(sheet);
                const asCSV = XLSX.utils.sheet_to_csv(sheet);
                this.handleMultipleCSVData(asCSV, "csv", sheetName, totalDataEntrySheets--); 
              }
            }
          } else {
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            sheet['!ref'] = this.getDataRange(sheet);
            const asCSV = XLSX.utils.sheet_to_csv(sheet);
            this.handleCSVData(asCSV, "csv");
          }
        } else {
          this.showError();
        }
      } else {
        this.showError();
      }
    };
    if (readAsArrayBuffer) {
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsText(file);
    }
  };

  showError = () => {
    toastr.error("Unable to upload a file:", "This file type is not supported");
  };

  
  updateData = async (result: any, file: File) => {
    const columnsNames = result.meta.fields;
    const rawData = result.data;

    if (rawData.length && rawData[0]!.Student === null) {
      rawData.splice(0, 1);
    }

    //await this.setState({ headers: columnsNames });
    if (!this.state.isLinkIt) {
      const firstCellValueForLinkIt = "Selected tests";
      const linkitColumnMustHave = ["student", "id", '"id"'];
      const doubleHeaderColumnHave = ["lnf", "psf", "nwf","orf", "wrc", "cls", "wrf"];
      if (columnsNames[0] === firstCellValueForLinkIt) {
        if (rawData.length > 3) {
          if (
            rawData[0][firstCellValueForLinkIt] === "Period" &&
            rawData[1][firstCellValueForLinkIt] === "Filters"
          ) {
            await this.setState({ isLinkIt: true });

            const { originalData } = this.state;

            let count = 0;
            let i = 0;
            for (; i < originalData!.length; i++) {
              const char = originalData!.charAt(i);
              if (char === "\n") {
                count++;
              }

              if (count === 4) {
                break;
              }
            }

            const dataWithoutUpperSection = originalData!.substring(i);
            Papa.parse(dataWithoutUpperSection, {
              complete: this.updateData,
              header: true,
              dynamicTyping: true,
              trimHeaders: true,
              skipEmptyLines: true,
              transform(value: string, field: string | number): any {
                return value.trim();
              },
            });
            return;
          }
        }
      }

      const linkitConstants = ["Export Date", "Selected Tests"];
      const fifthCellValueForLinkIt = "Student";

      // .replace('-21','-2021')// solution because firefox is not detecting year which has two number as date;

      if (
        (rawData.length > 3 &&
          // !isNaN(
          //   new Date(
          //     columnsNames[0].split(" - ")[0].replace("-21", "-2021")
          //   ).getTime()
          // ) &&
          (rawData[3][columnsNames[0]] === fifthCellValueForLinkIt ||
            rawData[4][columnsNames[0]] === fifthCellValueForLinkIt)) ||
        (linkitConstants.indexOf(columnsNames[0]) != -1 &&
          linkitConstants.indexOf(rawData[0][columnsNames[0]]) != -1)
      ) {
        await this.setState({ isLinkIt: true });

        const { originalData } = this.state;

        let i = 0;
        let originalDataArr = originalData!.split("\n");
        let singleRow = [];
        let columnsCount = 0;

        for (; i < originalDataArr!.length; i++) {
          singleRow = originalDataArr[i].split(",");
          if (!columnsCount) columnsCount = singleRow.length;

          let matchCount = 0;
          for (let j = 0; j < singleRow.length; j++) {
            if (
              linkitColumnMustHave.indexOf(singleRow[j].toLocaleLowerCase()) !=
              -1
            )
              matchCount++;
          }
          if (matchCount == linkitColumnMustHave.length - 1) {
            break;
          } //atleast 2 match
        }
        originalDataArr.splice(0, i);

        console.log(originalDataArr)        

        // fix multiple grade in one sheet- start
        let dibelsHeaderFirstValue;
        let deleteOtherGradeData = [];
        for (i = 0; i < originalDataArr!.length; i++) {
          singleRow = originalDataArr[i].split(",");
          for (let j = 0; j < singleRow.length; j++) {
            if (singleRow[j].includes("DIBELS")) {
              if (!dibelsHeaderFirstValue)
                dibelsHeaderFirstValue = singleRow[j];
              if (
                dibelsHeaderFirstValue &&
                dibelsHeaderFirstValue != singleRow[j]
              ) {
                deleteOtherGradeData.push(j);
              }
            }
          }
        }

        if (deleteOtherGradeData.length > 0) {
          for (i = 0; i < originalDataArr!.length; i++) {
            singleRow = originalDataArr[i].split(",");
            singleRow.splice(
              deleteOtherGradeData[0],
              deleteOtherGradeData.length
            );
            originalDataArr[i] = singleRow.join(",");
          }
          columnsCount -= deleteOtherGradeData.length;
        }
        // fix multiple grade in one sheet- end
    

        let dataWithoutUpperSection:any = originalDataArr.join("\n");

        //fix double headers start
        let dataWithoutUpperSectionArr:any = dataWithoutUpperSection.split(",");

        let j = 2 * (columnsCount - 1);
        if(!dataWithoutUpperSectionArr[j] && !dataWithoutUpperSectionArr.includes('"ID"') 
        && !dataWithoutUpperSectionArr.includes('"id"')
        && !dataWithoutUpperSectionArr.includes('ID')
        && !dataWithoutUpperSectionArr.includes('id')) 
        {
          toastr.error("Alert!!", "The ID Header is missing in the uploaded file. Please add ID Header.");
         
            this.props.onHide();
            this.props.openUploadStudentDataModal();
            document.body.click();
          return;
        } 

        if (doubleHeaderColumnHave.some((headerData) => (originalDataArr[1].toLocaleLowerCase()).includes(headerData))){
          // JIRA- 447 single header files found first blank cell in first row. 
          for (; j >= 0; j--) {
            if (
              (dataWithoutUpperSectionArr[j].includes("DIBELS") ||
              dataWithoutUpperSectionArr[j] == "")
              ) {
               dataWithoutUpperSectionArr.splice(j, 1);
              } 
              
            }
        }
        
          

        
       
        dataWithoutUpperSection = dataWithoutUpperSectionArr.join(",").replace('ORF (Accuracy)\n', 'ORF (Accuracy),');
          //fix double headers end
          Papa.parse(dataWithoutUpperSection, {
            complete: this.updateData,
            header: true,
            dynamicTyping: true,
            trimHeaders: true,
            skipEmptyLines: true,
            transform(value: string, field: string | number): any {
              return value.trim();
            },
          });
          return;
      }
      this.props.uploadCsvFile(
        this.state.fileName!,
        columnsNames,
        rawData
        );
      } else {
      this.props.uploadCsvFile(
        this.state.fileName!,
        columnsNames,
        rawData
      );
    }
  };

  updateMultipleData =  (result: any, file: File | undefined, workSheetName:string | undefined, sheetIndex: number | undefined) => {
    const columnsNames = result.meta.fields.filter((data:any) => data != "");
    const rawData = result.data;

    if (rawData.length && rawData[0]!.Student === null) {
      rawData.splice(0, 1);
    }
    let csvHeaderObj: any = {};
    csvHeaderObj[workSheetName!] =  columnsNames;
    this.setState((prevState: State) => {
      // Create a new object by spreading the existing data and adding new data
      const updatedData = {
        ...prevState.multiCSVheaders,
        ...csvHeaderObj
      };

      // Return the updated state
      return {
        multiCSVheaders: updatedData
      };
    }, () => {
        const { originalMultipleCSVData, multiCSVheaders } = this.state;
        let csvDataObj: any = {};
        csvDataObj[workSheetName!] = {
          year: originalMultipleCSVData[workSheetName!].year,
          grade: originalMultipleCSVData[workSheetName!].grade,
          fileName: this.state.fileName!,
          headers: multiCSVheaders[workSheetName!],
          rawData,
        };
  
        this.setState((prevState: State) => {
          // Create a new object by spreading the existing data and adding new data
          const updatedData = {
            ...prevState.uploadCSVArray,
            ...csvDataObj
          };
      
          // Return the updated state
          return {
            uploadCSVArray: updatedData
          };
        }, () => {
          if(sheetIndex == 1) {
            this.setState({
              isAllProcessDone: true
            }, () => {
              this.setState({ showLoader: true})
              if(this.state.isAllProcessDone) {
                this.setState({showLoader: false})
                this.props.uploadMultipleCsvFile?.(this.state.uploadCSVArray);
              }
            })
          }
        });
    })
  };

  render() {
    return (
      <>
      <input
        style={{ display: "none" }}
        type="file"
        accept={".csv, text/csv, .xlsx"}
        onChange={this.handleChange}
      />
      {this.state.showLoader ? <Spinner animation="border" size="sm" className="ml-2" /> : ''}
      </>
    );
  }
}



const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      onHide: hideUploadStudentDataModal,
      openUploadStudentDataModal: openUploadStudentDataModal,
    },
    dispatch
  );
export default connect(null, mapDispatchToProps)(DataFileReader);

