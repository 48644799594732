import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { getOverallMax, restatementBehaviors } from "../utils";
import {
  Fba,
  FbaAntecedentDisplayed,
  FbaBehaviorDisplayed,
  FbaConsequenceDisplayed,
  FbaModalTabs,
} from "../../../../../../store/onboarding/cases/types";
import { formatDate } from "../../../../../utils/DateTimeUtils";
import { bindActionCreators, Dispatch } from "redux";
import {
  changeSelectedFba,
  setFbaModal,
} from "../../../../../../store/onboarding/cases/actions";
import { connect } from "react-redux";
import { getFullName } from "../../../../../../utils/NamesUtils";

type DispatchProps = {
  changeSelectedFba: (fba?: Fba) => any;
  setFbaModal: (show: boolean, tab: FbaModalTabs | null) => void;
};

type OwProps = {
  selectedFba: Fba;
};

type Props = OwProps & DispatchProps;

const FbaTable = ({ selectedFba, changeSelectedFba, setFbaModal }: Props) => {
  const onOpenFbaChartModal = () => {
    changeSelectedFba(selectedFba);
    setFbaModal(true, FbaModalTabs.CHART);
  };
  return (
    <table className="fbaTable">
      <thead>
        <tr>
          <th />
          <th>Student</th>
          <th>Date</th>
          <th>Most Frequency Antecedent</th>
          <th>Most Frequency Behavior</th>
          <th>Most Frequency Consequence</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowSpan={selectedFba.observations.length + 1}>
            <div className="fbaUserInfoContainer">
              <div className="fbaUserInfo font-weight-bold">
                {`${selectedFba.observer.first_name} ${selectedFba.observer.last_name}`}
                <small className="font-weight-bold">{`${selectedFba.teacher.first_name} ${selectedFba.teacher.last_name}`}</small>
                <small>({selectedFba.observations.length} observation)</small>
              </div>
              <span onClick={onOpenFbaChartModal}>
                <FontAwesomeIcon
                  icon={faChartBar}
                  size="lg"
                  style={{ color: "#00265B" }}
                />
              </span>
            </div>
          </td>
          <td rowSpan={selectedFba.observations.length + 1}>
            {getFullName(selectedFba.student)}
          </td>
        </tr>
        {selectedFba.observations.map((obs) => {
          const a = getOverallMax(obs.tally.antecedent);
          const b = getOverallMax(restatementBehaviors(obs.tally.behavior));
          const c = getOverallMax(obs.tally.consequence);
          return (
            <tr key={obs.id}>
              <td className="text-nowrap">{formatDate(obs.created)}</td>
              <td>
                {a
                  ? `${FbaAntecedentDisplayed[a.antecedent as number]} (${
                      a.count
                    })`
                  : ""}
              </td>
              <td>
                {b
                  ? `${FbaBehaviorDisplayed[b.behavior as number]} (${b.count})`
                  : ""}
              </td>
              <td>
                {c
                  ? `${FbaConsequenceDisplayed[c.consequence as number]} (${
                      c.count
                    })`
                  : ""}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      changeSelectedFba: changeSelectedFba,
      setFbaModal: setFbaModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(FbaTable);
