import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { useMemo } from "react";
import { UserAssignmentRole } from "../../store/auth/types";

const useUserRole = () => {
  const userInfo = useSelector((s: ApplicationState) => s.auth.userInfo);

  const role = useMemo(() => {
    return userInfo?.profile.current_assignment?.role;
  }, [userInfo]);

  const isCoach = useMemo(() => role === UserAssignmentRole.COACH, [role]);

  const isTeacher = useMemo(() => role === UserAssignmentRole.TEACHER, [role]);

  const isSupervisor = useMemo(() => role === UserAssignmentRole.SUPERVISOR, [
    role,
  ]);

  const isDistrictOwner = useMemo(
    () => role === UserAssignmentRole.DISTRICT_OWNER,
    [role]
  );

  const isSuperuser = useMemo(() => role === UserAssignmentRole.SUPERUSER, [
    role,
  ]);

  const isParent = useMemo(() => role === UserAssignmentRole.PARENT, [role]);
  const isDataManager = useMemo(
    () => role === UserAssignmentRole.DATA_MANAGER,
    [role]
  );

  const isHighLevelRole = useMemo(() => {
    return isSuperuser || isDistrictOwner || isDataManager || isSupervisor;
  }, [isSuperuser, isDistrictOwner, isDataManager, isSupervisor]);

  return {
    role,
    isCoach,
    isTeacher,
    isSupervisor,
    isParent,
    isSuperuser,
    isDataManager,
    isDistrictOwner,
    isHighLevelRole,
    userInfo,
  };
};

export default useUserRole;
