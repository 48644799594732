import { ReactSelectOption, School } from "../types";
import {
  UserAssignmentRole,
  UserAssignmentRoleDisplayedNames,
} from "../../auth/types";

export type UsersManagementState = {
  schoolFilter?: School;
  roleFilter?: UserAssignmentRole;
  searchFilter?: string
};

export enum UsersManagementActionTypes {
  CHANGE_SCHOOL_FILTER = "@@onboarding/CHANGE_SCHOOL_FILTER",
  CHANGE_ROLE_FILTER = "@@onboarding/CHANGE_ROLE_FILTER",
  CHANGE_SEARCH_FILTER = "@@onboarding/CHANGE_SEARCH_FILTER",
}

export const districtOwnerAssignableRoles: Array<ReactSelectOption<
  string
>> = Object.entries(UserAssignmentRoleDisplayedNames).map(([key, value]) => ({
  label: value,
  value: key,
}));
