import React, { FunctionComponent, useMemo } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import _ from "lodash";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { UserInfo } from "../../../../../../store/auth/types";
import InterventionStats from "./InterventionStats";
import { makeStatisticObject } from "../assigment/intervention-plan-modal/workspaces/inteventions-stats/utils";
import { DataFilterState } from "../../../../../../store/dataFilter/types";
import useUserAssignedToGroup from "../assigment/hooks/useUserAssignedToGroup";

type OwnProps = {};

type Props = OwnProps;

const InterventionStatsModalContent: FunctionComponent<Props> = (props) => {
  const {
    showArchivedGroups,
    exitedStudentsGroup
  } = useSelector<ApplicationState, DataFilterState>((s) => s.dataFilter);

  const isStudentDetail = useSelector((s: ApplicationState) => s.cases.isStudentDetail);

  const interventionGroups = useSelector<ApplicationState, InterventionGroup[]>(
    (s) =>
      showArchivedGroups
        ? s.cases.archivedInterventionGroups
        : (exitedStudentsGroup && isStudentDetail ? s.cases.interventionGroups.filter((intervention) => {
          if(!intervention.archived) {
            return intervention.student_goals.every((goals) => goals.archived);
          }
        }) : s.cases.interventionGroups)
  );
  
  const { groupsAssignedToLoggedInUser } = useUserAssignedToGroup(interventionGroups);

  const educator = useSelector<ApplicationState, UserInfo | undefined>(
    (s) => s.onboarding.selectedEducator
  );

  const filteredInterventionGroups = useMemo(() => {
    return educator
      ? _.chain(isStudentDetail ? groupsAssignedToLoggedInUser : interventionGroups)
        .filter(
          (ig) =>
            (showArchivedGroups ? ig.archived! : !ig.archived) &&
            !!ig.finished
            && (ig.coach_assignment?.user.profile.current_assignment ===
              educator.profile.current_assignment ||
              ig.teacher_assignment?.user.profile.current_assignment ===
              educator.profile.current_assignment)
        )
        .value()
      : _.chain(isStudentDetail ? groupsAssignedToLoggedInUser : interventionGroups)
        .filter((ig) => (showArchivedGroups ? ig.archived! : !ig.archived) && !!ig.finished)
        .value();
  }, [educator, interventionGroups, groupsAssignedToLoggedInUser]);

  const stats = useMemo(() => {
    return _.chain(filteredInterventionGroups)
      .flatMap((ig) => {
        return ig.interventions.map((int) => {
          return {
            intervention: int,
            checklist_items:
              ig.progress_overview?.implementation_checks.filter(
                (ic) => ic.intervention === int.id
              ) ?? [],
            teacher_assignment:
              ig.progress_overview?.implementation_checks.flatMap(
                (ic) => (ic.intervention === int.id) ? ig.teacher_assignment?.user : []
              ),
          };
        });
      })
      .groupBy((item) => item.intervention.id)
      .toPairs()
      .map(([interventionId, items]) => {

        const teacherAssignment = _.chain(items)
          .flatMap((item) => item.teacher_assignment)
          .value();

        const intervention = items[0].intervention;

        const implChecks = _.chain(items)
          .flatMap((item) => item.checklist_items)
          .value();
        const dictionary = _.chain(implChecks)
          .flatMap((ic) => ic.logged_items || [])
          .groupBy((item) => item.item)
          .mapValues((items) => items.length)
          .value();
        const notPlannedDictionary = _.chain(implChecks)
          .flatMap((ic) => ic.not_applicable_items || [])
          .groupBy((item) => item.item)
          .mapValues((items) => items.length)
          .value();
        const checklistItems = _.chain(intervention.checklist_items)
          .map((ci) => {
            return {
              ...ci,
              loggedTimes: dictionary[ci.id!] ?? 0,
              notApplicableTimes: notPlannedDictionary[ci.id!] ?? 0,
            };
          }).sortBy('order')
          .value();

        return makeStatisticObject({
          implChecks: implChecks,
          intervention: intervention,
          checklistItems: checklistItems,
          teacherAssignment: teacherAssignment,
        });
      })
      .value();
  }, [filteredInterventionGroups]);

  return <InterventionStats stats={stats}/>;
};

export default InterventionStatsModalContent;
