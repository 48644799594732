import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import InfoButton from "../../common/InfoButton";
import { Measurement } from "../../../../../../../store/onboarding/types";
import { FormControl, InputGroup } from "react-bootstrap";

type OwnProps = {
  observationUnitDescription: string;
  setObservationUnitDescription: React.Dispatch<React.SetStateAction<string>>;
  selectedMeasurement?: Measurement;
  measurement: Measurement;
  setSelectedMeasurement: React.Dispatch<
    React.SetStateAction<Measurement | undefined>
  >;
};

type Props = OwnProps;

const displayedMeasurementValues = [
  {
    name: "Total Count",
    description:
      "e.g., the behavior occurred 6 times during the observation session",
  },
  {
    name: "Rate",
    description:
      "e.g., the behavior occurred 2 times per minute during the observation session",
  },
  {
    name: "Measure Duration of Target Behavior",
    description: "e.g., task for 4 minutes",
  },
  {
    name: "Measure Latency of Target Behavior",
    description:
      "e.g., initiated on-task behavior 2 minutes after instructed to do work",
  },
  {
    name: "Partial-Interval Recording",
    description:
      "Record whether the behavior occurred at least once during an interval.",
    advantage: "Appropriate for measuring short duration behaviors",
    disadvantage:
      "Not good for measuring behaviors that should be maintained (e.g., staying on task)",
  },
  {
    name: "Whole-Interval Recording",
    description:
      "Record whether behavior occurred throughout the entire interval.",
    advantage: "Appropriate for measuring short duration behaviors",
    disadvantage:
      "Not good for measuring behaviors that should be maintained (e.g., staying on task)",
  },
  {
    name: "Momentary Time Sampling",
    description:
      "Record whether behavior occurred exactly at the end of each interval.",
    advantage: "Appropriate for measuring short duration behaviors",
    disadvantage:
      "Not good for measuring behaviors that should be maintained (e.g., staying on task)",
  },
];

const MeasurementItem: FunctionComponent<Props> = ({
  observationUnitDescription,
  setObservationUnitDescription,
  selectedMeasurement,
  measurement,
  setSelectedMeasurement,
}) => {
  const active =
    selectedMeasurement && selectedMeasurement.id === measurement.id;

  const handleObservationUnitDescriptionChange = (e: React.FormEvent) => {
    e.preventDefault();
    const { value } = e.target as HTMLInputElement;
    setObservationUnitDescription(value);
  };

  const handleMeasurementChange = () => {
    setSelectedMeasurement(measurement);
    setObservationUnitDescription("");
  };

  return (
    <div key={measurement.id} onClick={handleMeasurementChange}>
      <FontAwesomeIcon
        size="lg"
        icon={active ? faDotCircle : faCircle}
        style={active ? { color: "#00265B" } : undefined}
        inverse={active}
      />
      <div className="d-flex flex-column w-100">
        <div className="d-flex">
          <div className="text-nowrap">{measurement.display_name}</div>
          <InfoButton
            info={
              displayedMeasurementValues.find(
                (x) => x.name === measurement.display_name
              ) || {}
            }
          />
        </div>
        {active && (
          <>
            <InputGroup size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroup-sizing-sm">{`${measurement.display_name} of What?`}</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                required
                value={observationUnitDescription}
                onChange={handleObservationUnitDescriptionChange}
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
              />
            </InputGroup>
          </>
        )}
      </div>
    </div>
  );
};

export default MeasurementItem;
