import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { useMemo } from "react";
import { EvidenceType } from "../../store/onboarding/types";
import { toastr } from "react-redux-toastr";
import { addExistingBehaviorEcgToDataPeriod } from "../../store/onboarding/actions";

const useTargetBehaviorECG = () => {
  const dispatch = useDispatch();

  const evidencePeriodData = useSelector(
    (s: ApplicationState) => s.onboarding.evidencePeriodData
  );

  const ecg = useMemo(
    () =>
      evidencePeriodData
        ? evidencePeriodData.evidence_column_groups.find(
            (ecg) =>
              !ecg.assessment &&
              ecg.evidence_columns.some(
                (ec) => ec.evidence_type === EvidenceType.ExistingBehavior
              )
          )
        : undefined,
    [evidencePeriodData]
  );

  const onAdd = () => {
    if (ecg) {
      toastr.info(
        "Select Target",
        "You already have target behaviors in this data period. Click the student cell in that column for which you want to add target behaviors."
      );
    } else {
      (dispatch(addExistingBehaviorEcgToDataPeriod(0, "target_behaviors")) as any)?.then(() =>
        toastr.success(
          "Select Target",
          "A new column has been added in this data period. Click the student cell in that column for which you want to add target behaviors."
        )
      );
    }
  };

  return {
    ecg,
    onAdd,
  };
};

export default useTargetBehaviorECG;
