import { District, UserAssignmentRole, UserInfo } from "../auth/types";
import { TimePeriod } from "../groups/types";
import { School } from "../onboarding/types";

export type SuperuserState = {
  districts: DistrictInfo[];
  schools: School[];
  selectedDistrict: DistrictInfo | null;
  selectedDistrictLicense: License | null;
  users: UserInfo[];
  selectedUser: UserInfo | null;
  releaseNotes: Release_Notes[];
  masterDataTypes: [];
  masterDataDisplay: Master_Data[];
  masterDataVariation: Master_Data[];
  selectedReleaseNote: Release_Notes | null;
  isLoading: {
    getAllDistricts: boolean;
    getDistrict: boolean;
    createDistrict: boolean;
    updateDistrict: boolean;
    deleteDistrict: boolean;
    getDistrictLicenses: boolean;
    createDistrictLicense: boolean;
    updateDistrictLicense: boolean;
    deleteDistrictLicense: boolean;
    getAllUsers: boolean;
    getUser: boolean;
    createUser: boolean;
    updateUser: boolean;
    deleteUser: boolean;
    getAllReleaseNotes: boolean;
    getReleaseNote: boolean;
    createReleaseNote: boolean;
    updateReleaseNote: boolean;
    deleteReleaseNote: boolean;
    getMasterDataTypes: boolean;
    getMasterDisplayNames: boolean;
    getMasterVariations: boolean;
    createVariations: boolean;
    updateVariations: boolean;
    deleteVariations: boolean;
  };
  errors: {
    getAllDistricts?: string;
    getDistrict?: string;
    createDistrict?: string;
    updateDistrict?: string;
    deleteDistrict?: string;
    getDistrictLicenses?: string;
    createDistrictLicense?: string;
    updateDistrictLicense?: string;
    deleteDistrictLicense?: string;
    getAllUsers?: string;
    getUser?: string;
    createUser?: string;
    updateUser?: string;
    deleteUser?: string;
    getAllReleaseNotes?: string;
    getReleaseNote?: string;
    createReleaseNote?: string;
    updateReleaseNote?: string;
    deleteReleaseNote?: string;
    getMasterDataTypes?: string;
    getMasterDisplayNames?: string;
    getMasterVariations?: string;
    createVariations?: string;
    updateVariations?: string;
    deleteVariations?: string;
  };
  modalsState: {
    showDistrictModal: boolean;
    showDistrictLicenseModal: boolean;
    upsertUpsertModal: {
      show?: boolean;
      district?: number;
      role?: UserAssignmentRole;
      name?: string;
      onSuccess?: Function;
    };
    upsertReleaseModal: {
      show?: boolean;
      onSuccess?: Function;
    };
  };
};

export type DistrictInfo = District & {
  current_license?: License;
  licenses?: License[];
  can_use_commercial_tools?: boolean,
  individual_view_permission?: boolean,
};

export type District_Request = {
  id?: number;
  name: string;
  state: string;
  owner?: number;
  schools?: School[];
  current_license?: number;
  licenses?: License[];
  can_use_commercial_tools?: boolean;
  individual_view_permission?: boolean;
};

export type Data_Extract_Request = {
  id?: number;
  district: number;
  file_type: string;
  time_period: any;
  year: any;
};

export type License = {
  id: number;
  license_type: string;
  started_at: string;
  expires_at: string;
  status: string;
};

export type License_Request = {
  id?: number;
  license_type?: string;
  started_at?: string; //YYYY-MM-DD
  expires_at?: string; //YYYY-MM-DD
  status?: string;
};

export type ValueLabel = {
  value: string;
  display_name: string;
};

export const LicenseTypes: ValueLabel[] = [
  { value: "standard", display_name: "Standard" },
  { value: "trial", display_name: "Trial" },
];

export const LicenseStatuses: ValueLabel[] = [
  { value: "requested", display_name: "Requested" },
  { value: "active", display_name: "Active" },
  { value: "denied", display_name: "Denied" },
  { value: "expired", display_name: "Expired" },
  { value: "revoked", display_name: "Revoked" },
];

export type User_Request = {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
};

export type Release_Notes = {
  id?: number;
  title: string;
  description: string;
  is_published?: boolean;
  publish_date?: string;
};

export type Master_Data = {
  id?: number
  masterDataId?: string;
  variation: string;
  display_name?: string;
  target_value?: string;
  isEditble?: boolean;
  isAddNew?: boolean;
}

export type Master_Data_Variation = {
  id?: number,
  type: string,
  target_value: string,
  variation: string
}